import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { fetchLevels } from "src/api/containers/level";
import { fetchTagsWithFiltering } from "src/api/containers/tag";
import { fetchLanguages } from "src/api/containers/language";
import { questionTypes } from "src/api/containers/question";
import useValues from "src/hooks/useValues";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";

const filterMenu = ({
    filterParams = [],
    willFormBeCleared = false,
    handleFetchQuestionList = () => {},
    handleCloseFilterMenu = () => {},
    excludeFields = [],
}) => {
    const user = useSelector((state) => state.auth.user);
    const [form] = useForm();
    const [values, setValues] = useValues({
        languages: [],
        levels: [],
        tags: [],
        isFetchingLevels: false,
        isFetchingTags: false,
        isLoadingMoreTags: false,
        currPageForTags: 1,
    });
    const [currFilterParams, setCurrFilterParams] = useState([]); // Current applied filter values.
    const timeoutIdGetLevels = useRef(null);
    const timeoutIdGetTags = useRef(null);

    const getTypeName = (type) => {
        return t(`q.${type}`);
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            ...(!excludeFields.includes("owner_id")
                ? { owner_id: formData.owner_id === "all" ? undefined : formData.owner_id }
                : {}),
            level_id: formData.level_id,
            tag_name: formData.tag_name,
            type: formData.type,
            ...(!excludeFields.includes("is_publish") ? { is_publish: formData.is_publish } : {}),
            is_public: formData.is_public,
            language_id: formData.language_id,
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.owner_id &&
            newFilterParams.push({
                name: "owner_id",
                value: fetchParams.owner_id,
                labelName: t("question.owner"),
                labelValue: t(`question.owner_${fetchParams.owner_id === "all" ? "all" : "me"}`),
            });
        fetchParams.language_id &&
            newFilterParams.push({
                name: "language_id",
                value: fetchParams.language_id,
                labelName: t("language.language"),
                labelValue:
                    values.languages.filter((language) => {
                        return language.id === fetchParams.language_id;
                    })[0].name || "",
            });
        fetchParams.level_id &&
            newFilterParams.push({
                name: "level_id",
                value: fetchParams.level_id,
                labelName: t("shared.level"),
                labelValue:
                    values.levels.filter((lv) => {
                        return lv.id === fetchParams.level_id;
                    })[0].name || "",
            });
        fetchParams.tag_name &&
            newFilterParams.push({
                name: "tag_name",
                value: fetchParams.tag_name,
                labelName: t("question.tags"),
                labelValue: fetchParams.tag_name,
            });
        fetchParams.type &&
            newFilterParams.push({
                name: "type",
                value: fetchParams.type,
                labelName: t("question.type"),
                labelValue: getTypeName(fetchParams.type),
            });
        fetchParams.is_publish !== undefined &&
            newFilterParams.push({
                name: "is_publish",
                value: fetchParams.is_publish,
                labelValue: fetchParams.is_publish ? t("question.done") : t("question.draft"),
            });
        fetchParams.is_public !== undefined &&
            newFilterParams.push({
                name: "is_public",
                value: fetchParams.is_public,
                labelValue: fetchParams.is_public ? t("question.public") : t("question.only_me"),
            });
        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleFetchQuestionList({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    const handleSearchLevels = (keyword) => {
        clearTimeout(timeoutIdGetLevels.current);
        if (typeof keyword === "string") {
            setValues({ ...values, levels: [], isFetchingLevels: true });
            timeoutIdGetLevels.current = setTimeout(() => {
                fetchLevels({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, levels: res.data, isFetchingLevels: false });
                    }
                });
            }, 500);
        }
    };

    const handleSearchTags = (keyword) => {
        clearTimeout(timeoutIdGetTags.current);
        if (typeof keyword === "string") {
            setValues({ ...values, tags: [], isFetchingTags: true });
            const fetchParams = {
                ...(form.getFieldValue("owner_id") && form.getFieldValue("owner_id") !== "all"
                    ? { owner_id: user.id || "" }
                    : {}),
            };
            timeoutIdGetTags.current = setTimeout(() => {
                fetchTagsWithFiltering({ slug: keyword, page: 1, ...fetchParams }).then((res) => {
                    if (res.status && res.data) {
                        setValues({
                            ...values,
                            tags: res.data,
                            isFetchingTags: false,
                            isLoadingMoreTags: false,
                            currPageForTags: 1,
                        });
                    }
                });
            }, 500);
        }
    };

    const handleScroll = (e) => {
        if (values.isLoadingMoreTags === true || values.isLoadingMoreTags === "disabled") {
            return;
        }

        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            setValues({ ...values, isLoadingMoreTags: true });
            const nextPageToLoad = values.currPageForTags + 1;
            const fetchParams = {
                ...(form.getFieldValue("owner_id") && form.getFieldValue("owner_id") !== "all"
                    ? { owner_id: user.id || "" }
                    : {}),
            };
            fetchTagsWithFiltering({ slug: "", page: nextPageToLoad, ...fetchParams }).then((res) => {
                if (res.status === true) {
                    if (res.data?.length === 0) {
                        setValues({
                            ...values,
                            isLoadingMoreTags: "disabled",
                        });
                    } else {
                        setValues({
                            ...values,
                            isLoadingMoreTags: false,
                            currPageForTags: res.pagination?.current || nextPageToLoad,
                            tags: [...values.tags, ...res.data],
                        });
                    }
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreTags: false,
                    });
                }
            });
        }
    };

    const handleChangeSelectedOwner = (val) => {
        const newPage = 1;
        setValues({
            ...values,
            isFetchingTags: true,
            currPageForTags: newPage,
            tags: [],
        });
        fetchTagsWithFiltering({
            slug: "",
            page: newPage,
            ...(val && val !== "all" ? { owner_id: user.id || "" } : {}),
        }).then((res) => {
            if (res.status === true) {
                setValues({
                    ...values,
                    isFetchingTags: false,
                    isLoadingMoreTags: false,
                    currPageForTags: res.pagination?.current || newPage,
                    tags: res.data || [],
                });
            } else {
                setValues({
                    ...values,
                    isFetchingTags: false,
                });
            }
        });
    };

    useEffect(() => {
        fetchLanguages({ slug: "", noPagination: true }).then((res) => {
            setValues({
                languages: res.data,
            });
        });
        fetchLevels({ slug: "", noPagination: true }).then((res) => {
            setValues({
                levels: res.data,
            });
        });

        fetchTagsWithFiltering({ slug: "", page: values.currPageForTags }).then((res) => {
            setValues({
                tags: res.data,
            });
        });
    }, []);

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            const fetchParams = {
                ...(!excludeFields.includes("owner_id") ? { owner_id: newFormData.owner_id } : {}),
                level_id: newFormData.level_id,
                tag_name: newFormData.tag_name,
                language_id: newFormData.language_id,
                type: newFormData.type,
                ...(!excludeFields.includes("is_publish") ? { is_publish: newFormData.is_publish } : {}),
                is_public: newFormData.is_public,
            };
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            setCurrFilterParams(filterParams);
            handleFetchQuestionList({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid" layout="vertical">
            <Row gutter={[24, 12]}>
                {!excludeFields.includes("owner_id") && (
                    <Col xs={24} sm={12}>
                        <Form.Item name="owner_id" label={t("question.owner")}>
                            <Select
                                className="app-select"
                                placeholder={t("question.select_owner")}
                                allowClear
                                onChange={handleChangeSelectedOwner}
                            >
                                <Select.Option value={user.id || "me"}>{t("question.owner_me")}</Select.Option>
                                <Select.Option value="all">{t("question.owner_all")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12}>
                    <Form.Item name="type" label={t("question.type")}>
                        <Select className="app-select" placeholder={t("question.choose_type")} allowClear>
                            {Object.keys(questionTypes).map((item, itemIndex) => {
                                return (
                                    <Select.Option key={`q-${itemIndex}`} value={item}>
                                        {getTypeName(item)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item name="language_id" label={t("language.language")}>
                        <Select
                            className="app-select"
                            placeholder={t("language.select_language")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                        >
                            {values.languages.map((item, index) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item name="level_id" label={t("shared.level")}>
                        <Select
                            className="app-select"
                            placeholder={t("question.search_and_select_level")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            // onSearch={(keyword) => {
                            //     handleSearchLevels(keyword);
                            // }}
                            // notFoundContent={
                            //     values.isFetchingLevels === true ? (
                            //         <i>
                            //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                            //         </i>
                            //     ) : (
                            //         undefined
                            //     )
                            // }
                        >
                            {values.levels.map((item, index) => (
                                <Select.Option key={`lv-${index}`} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item name="tag_name" label={t("question.tags")}>
                        <Select
                            className="app-select"
                            placeholder={t("question.search_and_select_tags")}
                            optionFilterProp="children"
                            showSearch
                            onSearch={(keyword) => {
                                handleSearchTags(keyword);
                            }}
                            allowClear
                            notFoundContent={
                                values.isFetchingTags === true ? (
                                    <i>
                                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                    </i>
                                ) : undefined
                            }
                            onPopupScroll={(e) => handleScroll(e)}
                        >
                            <>
                                {values.tags.map((item, index) => (
                                    <Select.Option key={`tag-${index}`} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                                {values.isLoadingMoreTags === true ? (
                                    <Select.Option key={"load-more-tags"} value={""}>
                                        <Spin spinning={values.isLoadingMoreTags} style={{ marginRight: "10px" }} />
                                        {t("shared.loading")}...
                                    </Select.Option>
                                ) : null}
                            </>
                        </Select>
                    </Form.Item>
                </Col>

                {!excludeFields.includes("is_publish") && (
                    <Col xs={24} sm={12}>
                        <Form.Item name="is_publish" label={t("question.status")}>
                            <Select className="app-select" placeholder={t("question.choose_status")} allowClear>
                                <Select.Option value={true}>{t("question.done")}</Select.Option>
                                <Select.Option value={false}>{t("question.draft")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12}>
                    <Form.Item name="is_public" label={t("question.public")}>
                        <Select className="app-select" placeholder={t("question.choose_public")} allowClear>
                            <Select.Option value={true}>{t("question.public")}</Select.Option>
                            <Select.Option value={false}>{t("question.only_me")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("action.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("action.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default filterMenu;
