import { t } from "i18next";
import { notification } from "antd";

tinymce.PluginManager.add("highlight", function (editor, url) {
    editor.ui.registry.addIcon(
        "highlight",
        `
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 17L6.75 14.25L6.72 14.23C6.14 13.64 6.14 12.69 6.72 12.11L11.46 7.37L15.7 11.61L10.96 16.35C10.39 16.93 9.46 16.93 8.87 16.37L8.24 17H4ZM15.91 2.91C16.5 2.33 17.45 2.33 18.03 2.91L20.16 5.03C20.74 5.62 20.74 6.57 20.16 7.16L16.86 10.45L12.62 6.21L15.91 2.91V2.91Z" fill="black"/>
            </svg>
        `
    );

    const reloadIndexes = function () {
        function locations(substring, string) {
            var a = [],
                i = -1;
            while ((i = string.indexOf(substring, i + 1)) >= 0) a.push(i);
            return a;
        }

        let items = editor.contentDocument.querySelectorAll("highlight");

        items.forEach(function (item, index) {
            // Handle set data-index
            item.setAttribute("data-index", index + 1);

            // Handle set data-position
            let text = item.innerText;
            let html = item.outerHTML;
            let content = editor.getContent();
            let content_after = content.replace(html, text + "!").replace(/<\/?([^>]+)?>/gi, "");
            let all_indexes = locations(text, content_after);
            let position = all_indexes.indexOf(content_after.indexOf(text + "!"));

            if (position === -1) position = 0;

            item.setAttribute("data-position", position);
        });
        editor.fire("change");
    };

    editor.ui.registry.addToggleButton("highlight", {
        text: "Highlight",
        icon: "highlight",
        tooltip: t("question.help_highlight"),
        onSetup: function (api) {
            api.setDisabled(true);
            const callback = function (e) {
                api.setActive(editor.selection.getNode().tagName === "HIGHLIGHT");
                let text = editor.selection.getContent({ format: "text" });
                api.setDisabled(!text);
            };
            editor.on("SelectionChange", callback);
            return () => {
                editor.off("SelectionChange", callback);
            };
        },
        onAction: function (api) {
            if (api.isActive()) {
                let text = editor.selection.getContent({ format: "text" });
                editor.insertContent(text);
            } else {
                const selectionText = editor.selection.getContent({ format: "text" });
                const selectionNode = editor.selection.getNode();
                const selectionNodeInnerText = selectionNode.innerText;

                // Validation:
                let fragment = document.createElement("span");

                fragment.innerHTML = editor.selection.getContent();

                const includesNodeNames = ["#text", "SPAN", "STRONG"];

                const isValidNodeName = Array.prototype.every.call(fragment.childNodes, (cNode) => {
                    return includesNodeNames.includes(cNode.nodeName);
                });

                // Insertion:
                if (isValidNodeName) {
                    if (selectionText === selectionNodeInnerText) {
                        const selectionNodeClone = selectionNode.cloneNode(false);
                        selectionNodeClone.innerHTML = `<highlight contenteditable="false" data-index="1">${selectionText}</highlight>`;
                        editor.insertContent(selectionNodeClone.outerHTML);
                    } else {
                        editor.insertContent(
                            `<highlight contenteditable="false" data-index="1">${selectionText}</highlight>`
                        );
                    }
                } else {
                    notification.warn({
                        message: t("message.content_highlight_not_valid"),
                    });
                }
            }
        },
    });

    editor.on("Cut", function () {
        setTimeout(reloadIndexes);
    });

    editor.on("Paste", function () {
        setTimeout(reloadIndexes);
    });

    editor.on("input", function () {
        setTimeout(reloadIndexes);
    });

    return {
        getMetadata: function () {
            return {
                name: "Highlight",
                url: "",
            };
        },
    };
});
