import { distance } from "fastest-levenshtein";

export const caclulateAccuracy = (answer, userAnswer) => {
    /* eslint-disable */
    let answ = answer;
    answ = answ ? answ?.toLowerCase() : answer;
    answ = answ?.replace(/[。：，、「『』」？》《……·’`~!&():\[\]{}|;,“”".?¿¡\'\/\\]/gi, "");
    answ = answ ? answ.trim() : "";
    let ftranscript = userAnswer ? userAnswer?.toLowerCase?.() : userAnswer;
    ftranscript = ftranscript?.replace(/[。：，、「『』」？》《……·’`~!&():\[\]{}|;,“”".?¿¡\'\/\\]/gi, "");
    ftranscript = ftranscript ? ftranscript.trim() : "";

    const accuracy2 = answ?.normalize("NFC") === ftranscript?.normalize("NFC");
    if (accuracy2) return 100;

    const distance1 = distance(answ, ftranscript);
    let accuracy = 1 - distance1 / Math.max(answ.length, ftranscript.length);
    if (accuracy) accuracy = Math.floor(accuracy * 100);

    return accuracy;
    /* eslint-enable */
};
