import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { skipHelpModal } from "src/api/containers/user";
import { t } from "i18next";
import EmptyImg from "src/assets/images/Empty_1.png";
import Icon from "src/modules/components/Icon";

import "./HelpModal.scss";
import { useSelector } from "react-redux";
import { fetchGuides } from "src/api/containers/guide";
import HelpMenuLayout from "src/modules/components/Help/components/HelpMenuLayout";

const HelpModal = ({ visible = false, page, onChangeVisible, data }) => {
    const user = useSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(false);
    const [helpData, setHelpData] = useState([]);

    const handleOk = () => {
        onChangeVisible(false);
    };

    const handleCancel = () => {
        onChangeVisible(false);
        if (user?.is_new_user !== false) {
            skipHelpModal().then(({ is_new_user }) => {
                // if (is_new_user) {
                //     consoloe.log(is_new_user);
                // }
            });
        }
    };

    useEffect(() => {
        if (visible) {
            setLoading(true);
            fetchGuides({ type: page }).then((res) => {
                const { data, status } = res;
                if (status) {
                    setHelpData(data);
                }
                setLoading(false);
            });
        }
    }, [visible]);

    return (
        <Modal
            className="help-modal"
            title={t("help.help")}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            centered
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
            style={{
                maxWidth: "1200px",
                maxHeight: "1200px",
                margin: "20px 0",
            }}
            bodyStyle={{
                // overflowY: "auto",
                height: "calc(100vh - 100px)",
                maxHeight: "1200px",
                backgroundColor: "#F0F6FB",
            }}
            closeIcon={<Icon name="icon-cross-thin" />}
        >
            <Spin spinning={loading} style={{ height: "100%" }}>
                <div className="modal-wraper">
                    {helpData?.length > 0 ? (
                        <div>
                            <HelpMenuLayout data={helpData} />
                        </div>
                    ) : (
                        <div>
                            <div className="no-data">
                                <img src={EmptyImg} />
                                <div>{t("help.nodata")}</div>
                            </div>
                        </div>
                    )}
                </div>
            </Spin>
        </Modal>
    );
};

export default HelpModal;
