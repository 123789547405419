import chineseToPinyin from "chinese-to-pinyin";
import clsx from "clsx";
import "./Answer.scss";
import LatexDangerously from "src/modules/components/LatexDangerously";

const Answer = (props) => {
    const { disabled, letter = "a", answer, handleChooseAnswer = () => {}, isShowPinyin } = props;

    const generateClassName = () => {
        if (answer?.chosen) {
            return " chosen";
        } else {
            return "";
        }
    };

    return (
        <div
            className={`answer-for-multiple-choice${generateClassName()} ${disabled ? "disabled" : ""}`}
            onClick={() => handleChooseAnswer()}
        >
            <div className="answer-wrapper">
                <div className="answer-content">
                    <span className="answer-letter">{letter}</span>

                    <div>
                        <div className={clsx({ " answer-text-pinyin": true, show: isShowPinyin })}>
                            <LatexDangerously innerHTML={chineseToPinyin(answer?.text || "", { keepRest: true })} />
                        </div>
                        <div className="answer-text">
                            <LatexDangerously innerHTML={answer?.text} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Answer;
