import { t } from "i18next";

tinymce.PluginManager.add("paste-options", function (editor, url) {
    const pasteOptions = {
        opt1_keep_text_only: {
            title: t("editor.paste_option_keep_text_only"),
            onPaste: (e) => {
                const htmlContent = e.clipboardData.getData("text/html");
                if (htmlContent) {
                    e.preventDefault();

                    // 1. Handle formatting htmlContent using html element:
                    // - Create a temporary element:
                    let elementTemp = document.createElement("div");
                    elementTemp.innerHTML = htmlContent;
                    // - Get all elements with attributes:
                    let elementsWithAttributes = elementTemp.querySelectorAll("*[id], *[class], *[style]");
                    // - Loop through each element and remove attributes:
                    for (let i = 0; i < elementsWithAttributes.length; i++) {
                        let element = elementsWithAttributes[i];
                        element.removeAttribute("id");
                        element.removeAttribute("class");
                        element.removeAttribute("style");
                    }

                    // 2. Handle formatting htmlContent using string:
                    let htmlContentToBePasted = elementTemp.innerHTML;
                    // - Remove unnecessary tags:
                    htmlContentToBePasted = htmlContentToBePasted.replace(/<!--(.*?)-->/g, "");

                    // 3. Insert to the editor:
                    editor.insertContent(`${htmlContentToBePasted}`);
                } else {
                    // const textContent = e.clipboardData.getData("text/plain");
                    // editor.insertContent(`${textContent}`);
                }
            },
        },
        opt2_keep_source_formatting: {
            title: t("editor.paste_option_keep_source_formatting"),
            onPaste: (e) => {},
        },
    };

    let pasteOptionsButton = null;
    let pasteOptionSelected = "opt1_keep_text_only";

    const getPasteOptionsButton = () => {
        const buttons = editor.editorContainer.querySelectorAll(".tox-toolbar__group .tox-tbtn") || [];
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].getAttribute("aria-label") === t("editor.paste_options")) {
                return buttons[i];
            }
        }
        return null;
    };

    const setPasteOptionsButtonContent = (content) => {
        pasteOptionsButton.querySelector(".tox-tbtn__select-label").innerHTML = content;
    };

    editor.ui.registry.addIcon(
        "paste-options",
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 32 32" xml:space="preserve">
                <style type="text/css">
                    .st0 {
                        fill: none;
                        stroke: currentColor;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                    }
                </style>
                <polyline class="st0" points="23,12 23,18 29,18 23,12 15,12 15,30 29,30 29,18 " />
                <path class="st0" d="M20,6h-8V4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V6z" />
                <path class="st0" d="M15,28h-5c-2.2,0-4-1.8-4-4V8c0-2.2,1.8-4,4-4h2" />
                <path class="st0" d="M20,4h2c2.2,0,4,1.8,4,4v7" />
            </svg>
        `
    );

    editor.ui.registry.addMenuButton("paste-options", {
        text: t("editor.paste_options"),
        icon: "paste-options",
        tooltip: t("editor.paste_options"),
        onSetup: function (api) {
            pasteOptionsButton = getPasteOptionsButton();
            if (pasteOptionsButton) {
                pasteOptionsButton.id = "custom-tox-tbtn--paste-options";
                // Set button custom states:
                setPasteOptionsButtonContent(pasteOptions[pasteOptionSelected].title);
            }
        },
        fetch: function (callback) {
            const items = Object.keys(pasteOptions).map((item) => {
                return {
                    type: "togglemenuitem",
                    text: pasteOptions[item].title,
                    onSetup: function (api) {
                        // Set button state:
                        api.setActive(pasteOptionSelected === item);
                        // Return function that's invoked when the button is rendered.
                        return function () {};
                    },
                    onAction: function (api) {
                        // Set button state:
                        pasteOptionSelected = item;
                        // Set button custom states:
                        setPasteOptionsButtonContent(pasteOptions[item].title);
                    },
                };
            });
            callback(items);
        },
    });

    editor.on("Paste", function (e) {
        pasteOptions[pasteOptionSelected]?.onPaste?.(e);
    });

    return {
        getMetadata: function () {
            return {
                name: "Paste options",
                url: "",
            };
        },
    };
});
