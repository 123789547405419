import { useEffect, useId, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAudioIdPlaying, setAudioIdPlaying } from "src/reducers/general";
import { AudioIcon, AudioIconPlay } from "src/utils/drawer";

const Audio = (props) => {
    const { src } = props;
    const audio = useRef(null);
    const audioIdPlaying = useSelector((state) => state.general.audioIdPlaying);
    const dispatch = useDispatch();
    const audioId = useId();

    const handleToggleAudio = () => {
        if (audioIdPlaying !== audioId) {
            // Pause all video isPlaying
            const audios = document.querySelectorAll("audio");

            audios.forEach((audio) => {
                audio.pause();
                audio.currentTime = 0;
            });

            audio.current.play();
            dispatch(setAudioIdPlaying(audioId));
            audio.current.addEventListener("ended", () => {
                dispatch(removeAudioIdPlaying());
            });
        } else {
            audio.current.pause();
            audio.current.currentTime = 0;
            dispatch(removeAudioIdPlaying());
        }
    };

    return (
        <span style={{ cursor: "pointer" }}>
            <audio data-id={audioId} src={src} ref={audio} controls hidden></audio>
            <span onClick={handleToggleAudio}>{audioIdPlaying === audioId ? <AudioIconPlay /> : <AudioIcon />}</span>
        </span>
    );
};

export default Audio;
