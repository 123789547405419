import { DatePicker, Dropdown, Select, Space, Tag } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { fetchSubscriptions } from "src/api/containers/subscription";
import { ReactComponent as SvgFilter } from "src/assets/images/filter-icon.svg";
import { useFetch, useValues } from "src/hooks";
import CustomButton from "src/modules/components/Button";
import FilterMenu from "src/modules/components/FilterMenu";
import InputSearch from "src/modules/components/Input/InputSearch";
import Table from "src/modules/components/Table";

import "./PaymentHistory.scss";

export const paid = {
    true: "payment.paid",
    false: "payment.not_paid",
};

export const paymentStatus = {
    paid: { label: "payment.paid", queryValue: "paid" },
    not_paid: { label: "payment.not_paid", queryValue: "not_paid" },
    pay_error: { label: "payment.pay_error", queryValue: "pay_error" },
};

function PaymentHistory({ isWithUrlQueryString = false, fixedFetchParams, excludedFilterFields, planList }) {
    // Date format:
    const formatDate = useSelector((state) => state.general.formatDate);
    const formatDateTime = useSelector((state) => state.general.formatDateTime);

    // FILTER SETTINGS:
    const [filterInfo, setFilterInfo] = useValues({
        isFirstTimeFetchDone: false,
        // Search box:
        inputSearch: "",
        // Filter menu:
        isVisible: false,
        plans: [],
    });
    const [filterValuesInitial, filterValuesConfig] = useMemo(() => {
        // Flter values and config:
        const _filterValuesInitial = {
            page: 1,
            slug: undefined,
        };
        const _filterValuesConfig = {
            page: {
                queryKey: "p",
            },
            slug: {
                queryKey: "s",
            },
            valid_from: {
                label: t("setting.effective_date"),
                queryKey: "vfrom",
                getValueLabel: (value) => {
                    return value ? moment(value).format(formatDate) : "";
                },
            },
            valid_to: {
                label: t("setting.expiration_date"),
                queryKey: "vto",
                getValueLabel: (value) => {
                    return value ? moment(value).format(formatDate) : "";
                },
            },
            status: {
                label: t("setting.payment_status"),
                queryKey: "status",
                values: Object.keys(paymentStatus),
                valuesInfo: paymentStatus,
            },
            plan_id: {
                label: t("setting.subscription"),
                queryKey: "plan",
                getValueLabel: (value) => {
                    for (let i = 0; i < filterInfo.plans.length; i++) {
                        if (value === filterInfo.plans[i].id) {
                            return filterInfo.plans[i].name;
                        }
                    }
                    return "Unknown";
                },
            },
        };
        // Remove excluded filter values and config:
        const filterValuesInitial = {};
        const filterValuesConfig = {};
        Object.keys(_filterValuesInitial).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesInitial[elem] = _filterValuesInitial[elem];
            }
        });
        Object.keys(_filterValuesConfig).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesConfig[elem] = _filterValuesConfig[elem];
            }
        });
        // Result:
        return [filterValuesInitial, filterValuesConfig];
    }, [filterInfo]);
    const [currFilterValues, setCurrFilterValues] = useState(filterValuesInitial || {});

    // FILTER MENU:
    const formFields = useMemo(() => {
        const _formFields = {
            ...(filterValuesConfig.valid_from
                ? {
                      valid_from: {
                          label: filterValuesConfig.valid_from.label,
                          element: (
                              <DatePicker
                                  className="app-date-picker"
                                  format="DD-MM-YYYY"
                                  placeholder={t("setting.effective_date")}
                              />
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.valid_to
                ? {
                      valid_to: {
                          label: filterValuesConfig.valid_to.label,
                          element: (
                              <DatePicker
                                  className="app-date-picker"
                                  format="DD-MM-YYYY"
                                  placeholder={t("setting.expiration_date")}
                              />
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.status
                ? {
                      status: {
                          label: filterValuesConfig.status.label,
                          element: (
                              <Select className="app-select" placeholder={t("setting.payment_status")} allowClear>
                                  {filterValuesConfig.status.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`status${itemIndex}`} value={item}>
                                              {t(filterValuesConfig.status.valuesInfo[item].label)}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.plan_id
                ? {
                      plan_id: {
                          label: filterValuesConfig.plan_id.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("setting.select_plan")}
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                              >
                                  {filterInfo.plans.map((item, itemIndex) => (
                                      <Select.Option key={`plan${item.id}`} value={item.id}>
                                          {item.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
        };
        return _formFields;
    }, [filterInfo]);
    const formFieldGroups = useMemo(() => {
        return [
            {
                formFields: ["plan_id", "status", "valid_from", "valid_to"],
                rowProps: {
                    gutter: [24, 12],
                },
            },
        ];
    }, []);

    // API CALL:
    const [loadingPayments, payments, paymentsPagination, fetchPayments, refetchPayments] = useFetch(
        {},
        fetchSubscriptions
    );

    // PAGE DATA:
    const paymentColumns = [
        {
            title: t("setting.code"),
            dataIndex: "code",
            key: "code",
            width: "auto",
        },
        {
            title: t("setting.subscription"),
            dataIndex: "plan",
            key: "plan",
            width: "auto",
            render: (plan) => {
                return plan?.name;
            },
        },
        {
            title: t("setting.amount"),
            dataIndex: "amount",
            key: "amount",
            width: "auto",
            render: (amount) => (amount && !isNaN(amount) ? `${amount.toLocaleString()}${t("price.vnd")}` : amount),
        },
        {
            title: t("shared.created_at"),
            dataIndex: "created_at",
            key: "created_at",
            width: "auto",
            render: (text) => {
                return text ? moment(text).format(formatDateTime) : "";
            },
        },
        {
            title: t("setting.effective_date"),
            dataIndex: "valid_from",
            key: "valid_from",
            width: "auto",
            render: (text) => {
                return text ? moment(text).format(formatDate) : "";
            },
        },
        {
            title: t("setting.expiration_date"),
            dataIndex: "valid_to",
            key: "valid_to",
            width: "auto",
            render: (text) => {
                return text ? moment(text).format(formatDate) : "";
            },
        },
        {
            title: t("setting.payment_method"),
            dataIndex: "payment_method",
            key: "payment_method",
            width: "auto",
            render: (text, record) => {
                return t(`payment.method_${text}`);
            },
        },
        {
            title: t("setting.payment_status"),
            key: "status",
            dataIndex: "status",
            render: (text, record) => {
                if (Object.keys(paymentStatus).includes(record?.transaction?.status)) {
                    return t(paymentStatus[record.transaction.status].label);
                }
                return t(paid[record?.status || "false"]);
            },
        },
    ];

    const handleFetchDataList = (fetchParams = {}, isReload = true) => {
        const fParams = {
            page: fetchParams.page,
            slug: fetchParams.slug,
            valid_from: moment.isMoment(fetchParams.valid_from)
                ? moment(fetchParams.valid_from).format("YYYY-MM-DD")
                : undefined,
            valid_to: moment.isMoment(fetchParams.valid_to)
                ? moment(fetchParams.valid_to).format("YYYY-MM-DD")
                : undefined,
            status: fetchParams.status,
            plan_id: fetchParams.plan_id,
            ...(Object.keys(fixedFetchParams || {})?.length ? fixedFetchParams : {}),
        };
        fetchPayments({ ...fParams }, isReload);
        // Update search box's value:
        if (fParams.slug) {
            setFilterInfo({ inputSearch: fParams.slug });
        }
    };

    const handleSearch = () => {
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            slug: filterInfo.inputSearch,
        };
        if (isWithUrlQueryString) {
            // Use other logic.
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handlePagination = (page) => {
        const newFilterValues = {
            ...currFilterValues,
            page: page,
        };
        if (isWithUrlQueryString) {
            // Use other logic.
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleRemoveFilterParam = (e, fieldName) => {
        e.preventDefault();
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            [fieldName]: undefined,
        };
        if (isWithUrlQueryString) {
            // Use other logic.
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    useEffect(() => {
        setFilterInfo({ plans: planList });
    }, [planList]);

    useEffect(() => {
        if (isWithUrlQueryString) {
            // Use other logic.
        } else {
            // Update data list whenever currFilterValues is changed:
            handleFetchDataList(currFilterValues, true);
        }
    }, [currFilterValues]);

    return (
        <div className="payment-history">
            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("shared.find_question")}
                            value={filterInfo.inputSearch}
                            onChange={(e) => setFilterInfo({ ...filterInfo, inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {(!isWithUrlQueryString || filterInfo.isFirstTimeFetchDone) &&
                                    Object.keys(currFilterValues).map((fKey, i) => {
                                        const fValue = currFilterValues[fKey];
                                        const fValueLabel =
                                            filterValuesConfig[fKey]?.valuesInfo?.[fValue]?.label ||
                                            (filterValuesConfig[fKey]?.getValueLabel instanceof Function
                                                ? filterValuesConfig[fKey].getValueLabel(fValue)
                                                : undefined);
                                        if (fValue && fValueLabel) {
                                            return (
                                                <Tag
                                                    className="app-tag"
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey)}
                                                >
                                                    {`${fValueLabel ? t(fValueLabel) : ""}`}
                                                </Tag>
                                            );
                                        }
                                        return null;
                                    })}
                            </Space>
                        </div>
                        <Dropdown
                            forceRender
                            visible={filterInfo.isVisible}
                            overlay={
                                <FilterMenu
                                    formLayout={"vertical"}
                                    formFields={formFields}
                                    formFieldGroups={formFieldGroups}
                                    formData={currFilterValues}
                                    willResetForm={filterInfo.isVisible === false}
                                    onCancel={() => {
                                        setFilterInfo({
                                            ...filterInfo,
                                            isVisible: false,
                                        });
                                    }}
                                    onSubmit={(newFilterValues) => {
                                        newFilterValues.page = 1;
                                        if (isWithUrlQueryString) {
                                            // Use other logic.
                                        } else {
                                            setCurrFilterValues(newFilterValues);
                                        }
                                    }}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => {
                                setFilterInfo({ ...filterInfo, isVisible: val });
                            }}
                        >
                            <div className="filter-button">
                                <CustomButton
                                    type="primary"
                                    icon={<SvgFilter />}
                                    title={t("shared.option_filter")}
                                ></CustomButton>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div>
                <Table
                    className="app-table"
                    columns={paymentColumns}
                    dataSource={payments}
                    nodata_title={t("shared.no_data")}
                    loading={loadingPayments}
                    pagination={{
                        showSizeChanger: false,
                        ...paymentsPagination,
                        position: ["bottomCenter"],
                        onChange: handlePagination,
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                    responsiveOnMobile={true}
                    mobileHeaderColumns={["code", "plan", "amount"]}
                />
            </div>
        </div>
    );
}

export default PaymentHistory;
