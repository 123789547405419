import { useTranslation } from "react-i18next";

import { ReactComponent as SvgVipCrown } from "src/assets/images/account/vip-crown.svg";

import "./ButtonSubscription.scss";

const ButtonSubscription = ({ className, ...rest }) => {
    const { t } = useTranslation();

    return (
        <span className={"subsc-btn" + (className ? ` ${className}` : "")} {...rest}>
            <span className="subsc-btn-icon">
                <SvgVipCrown />
            </span>
            <span className="subsc-section">
                <span className="subsc-btn-name">{t(`account.upgrade`)}</span>
            </span>
        </span>
    );
};

export default ButtonSubscription;
