import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import api from "src/api";
import { useAuth } from "src/modules/auth";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";
import { setCurrentPage } from "src/reducers/general";
import { pages } from "src/routes/pages";

import ExamAccess from "./components/ExamAccess";
import ExamCheckInSteps from "./components/ExamCheckInSteps";

function ExamCheckIn() {
    const proctoring = useContext(ProctoringContext);

    const auth = useAuth();

    const dispatch = useDispatch();

    const [currentView, setCurrentView] = useState("");

    const resetCandidateInformation = () => {
        api.defaults.headers.common["Authorization"] = undefined;

        auth.logout();
    };

    const storeExamCheckInInfo = (examAccessCode, examCheckInInfo) => {
        if (proctoring?.setExamAccessCode instanceof Function) {
            proctoring?.setExamAccessCode(examAccessCode || "");
        }
        if (proctoring?.setExamCheckInInfo instanceof Function) {
            proctoring?.setExamCheckInInfo({
                assignment: examCheckInInfo || {},
            });
        }
    };

    const handleChangeExamAccess = (examAccessCode, examRes) => {
        storeExamCheckInInfo(examAccessCode, examRes);

        if (examAccessCode) {
            const examAccessCodeCurr = localStorage.getItem("examAccessCode");
            const examAccessCodeNew = examAccessCode;
            // If examAccessCode is new, store it and log out to access Exam again.
            if (examAccessCodeCurr !== examAccessCodeNew) {
                localStorage.setItem("examAccessCode", examAccessCodeNew);
                resetCandidateInformation();
            }
        } else {
            // Clear examAccessCode and candidate information:
            localStorage.removeItem("examAccessCode");
            resetCandidateInformation();
        }
    };

    useEffect(() => {
        auth.pickRoleByGuest("student");
        dispatch(setCurrentPage(pages.exam_checkin.name));
    }, []);

    useEffect(() => {
        if (proctoring?.examCheckInInfo?.assignment?.id) {
            setCurrentView("ExamCheckInSteps");
        } else {
            setCurrentView("ExamAccess");
        }
    }, [proctoring?.examCheckInInfo?.assignment?.id]);

    return (
        <div className="exam-checkin">
            {currentView === "ExamAccess" && <ExamAccess onChange={handleChangeExamAccess} />}
            {currentView === "ExamCheckInSteps" && <ExamCheckInSteps />} {/* Check thiết bị,... */}
        </div>
    );
}

export default ExamCheckIn;
