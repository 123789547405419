import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown, notification, Tooltip } from "antd";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteGuide, fetchGuides } from "src/api/containers/guide";
import FilterIcon from "src/assets/images/filter-icon.svg";
import Pencil from "src/assets/images/pencil-white.svg";
import { useFetch, useValues } from "src/hooks";
import InputSearch from "src/modules/components/Input/InputSearch";
import Breadcrumb from "src/modules/components/Breadcrumb";
import Button from "src/modules/components/Button";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import Table from "src/modules/components/Table";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import FilterMenu from "../../components/FilterMenu";
import GuideModal from "../../components/ModalAddGuide";

const Guide = () => {
    const dispatch = useDispatch();
    const animate = useSelector((state) => state.animate.animation);
    const idRow = animate?.clone || animate?.add || animate?.update;
    const user = useSelector((state) => state.auth.user);

    const [loading, dataSource, pagination, fetch, refetch] = useFetch({ page: 1, slug: "", is_split: 1 }, fetchGuides);
    const [values, setValues] = useValues({
        isAddGuideModalVisible: false,
        isConfirmModalVisible: false,
        isFilterMenuRendered: false,
        selectedGuide: null,
        isUpdating: false,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearch: "",
        isFilterMenuVisible: false,
        filterParams: [],
    });
    const scrollRef = useRef(null);

    const columns = [
        {
            title: t("guide.type"),
            dataIndex: "type",
            key: "type",
            // width: 200,
            render: (text, record) => t(`guide.${record?.type}`) || "_",
        },
        {
            title: t("guide.order"),
            dataIndex: "order",
            key: "order",
            render: (text, record) => record?.order || "_",
        },
        {
            title: t("guide.user"),
            dataIndex: "roles",
            key: "roles",
            render: (text, record) => record?.roles?.join(", ") || "_",
        },
        {
            title: t("guide.title"),
            dataIndex: "title",
            key: "title",
            render: (text, record) => <> {record?.title} </>,
        },
        {
            title: t("guide.description"),
            dataIndex: "description",
            key: "description",
            render: (text, record) => (
                <div
                    dangerouslySetInnerHTML={{ __html: record?.description || "" }}
                    style={{ overflowY: "auto", maxHeight: 200, maxWidth: 300 }}
                ></div>
            ),
        },
        {
            title: t("guide.image"),
            dataIndex: "image",
            key: "image",
            render: (text, record) => <img src={record?.image?.src} style={{ maxWidth: 120 }} />,
        },
        {
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
                <div className="question-bank-action">
                    <Tooltip placement="top" title={t("action.update")}>
                        <span onClick={() => handleUpdateGuide(record)}>
                            <EditOutlined />
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title={t("action.delete")}>
                        <span onClick={() => handleShowConfirmModal(record)} className="question-bank-delete">
                            <DeleteOutlined />
                        </span>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleSearchExam = () => {
        if (filterSettings && filterSettings.inputSearch) {
            fetch({ page: 1, slug: filterSettings.inputSearch });
        } else {
            fetch({ page: 1, slug: "" });
        }
    };

    const handleUpdateGuide = (item) => {
        setValues({ isAddGuideModalVisible: true, selectedGuide: item, isUpdating: true });
    };

    const handleShowConfirmModal = (item) => {
        setValues({ isConfirmModalVisible: true, selectedGuide: item });
    };

    const handleDeleteGuide = () => {
        const item = values.selectedGuide;
        deleteGuide(item.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ selectedGuide: null, isConfirmModalVisible: false });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
        // Fetch data if the filter menu has never been rendered:
        if (values.isFilterMenuRendered === false) {
            setValues({ ...values, isFilterMenuRendered: true });
            const newData = {};
            for (let i = 0; i < newFilterParams.length; i++) {
                newData[newFilterParams[i].name] = newFilterParams[i].value;
            }
            const fetchParams = {
                owner_id: newData.owner_id,
                category_id: newData.category_id,
                tag_name: newData.tag_name,
                type: newData.type,
                is_publish: newData.is_publish,
                is_public: newData.is_public,
            };
            fetch({ page: 1, ...fetchParams });
        }
    };

    useEffect(() => {
        dispatch(setCurrentPage("guide"));
        fetch({}, true);
    }, []);

    useEffect(() => {
        //animation when clone a new question
        if (animate?.name == "question") {
            const rowAnimation = (id, action, timeout = 3000) => {
                setTimeout(() => {
                    const str = "[data-row-key=" + "'" + id + "']";
                    const eln = document.querySelector(str);

                    eln?.classList?.add(`row-${action}-animate`);

                    setTimeout(() => {
                        // setIdRowClone();
                        dispatch(animationId({ name: "", [action]: "" }));
                        eln?.classList?.remove(`row-${action}-animate`);
                    }, timeout);
                }, 200);
            };

            if (animate?.clone) {
                rowAnimation(idRow, "clone");
            }
            if (animate?.add) {
                rowAnimation(idRow, "add");
            }
            if (animate?.update) {
                rowAnimation(idRow, "update", 3000);
            }
        }
    }, [dataSource]);

    return (
        <div className="question-bank">
            <Breadcrumb
                routes={[
                    {
                        name: t("header.guide"),
                    },
                ]}
            />

            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("guide.find")}
                            onChange={(e) => setFilterSettings({ ...filterSettings, inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearchExam();
                                }
                            }}
                            onClickIconSearch={handleSearchExam}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            {
                                //  <Space className="filter-keys-bar" align="center" wrap size={4}>
                                //         {filterSettings.filterParams?.length > 0
                                //             ? filterSettings.filterParams.map((fKey, i) => {
                                //                   console.log(fKey);
                                //                   return (
                                //                       <Tag
                                //                           key={`filter-key${i}`}
                                //                           closable
                                //                           onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                //                       >
                                //                           {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                //                               fKey.labelValue || ""
                                //                           }`}
                                //                           {t(`${fKey.labelValue}`) || ""}
                                //                       </Tag>
                                //                   );
                                //               })
                                //             : // <>{t("question.option_filter")}</>
                                //               null}
                                //     </Space>
                            }
                        </div>
                        <Dropdown
                            visible={filterSettings.isFilterMenuVisible}
                            overlay={
                                <FilterMenu
                                    filterParams={filterSettings.filterParams}
                                    handleFetchGuideList={fetch}
                                    handleCloseFilterMenu={(newFilterParams) =>
                                        setFilterSettings({
                                            ...filterSettings,
                                            isFilterMenuVisible: false,
                                            ...(newFilterParams && { filterParams: newFilterParams }),
                                        })
                                    }
                                    willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) =>
                                setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                            }
                        >
                            <div className="filter-button">
                                <Button type="primary" icon={FilterIcon} title={t("question.option_filter")}></Button>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="filter-toolbar-actions">
                    <Button
                        type="primary"
                        icon={Pencil}
                        title={t("guide.add_new")}
                        onClick={() => setValues({ isAddGuideModalVisible: true })}
                    ></Button>
                </div>
            </div>

            <div className="table question-bank-table" ref={scrollRef}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                />
            </div>

            {/* Modal box select type to create question */}
            <GuideModal
                refetch={refetch}
                values={values}
                setValues={setValues}
                onchangeValues={setValues}
                onCancel={() => setValues({ isAddGuideModalVisible: false })}
            ></GuideModal>
            {/* End modal box  */}

            <PopConfirm
                type="delete"
                visible={values.isConfirmModalVisible}
                onOk={handleDeleteGuide}
                onCancel={() => setValues({ isConfirmModalVisible: false })}
            />
        </div>
    );
};

export default Guide;
