import { useSelector } from "react-redux";
import QuestionBank from "../../QuestionBank";

const MyQuestion = () => {
    const { user } = useSelector((state) => state.auth);

    const fixedFetchParams = {
        user_id: user.id,
    };

    return <QuestionBank fixedFetchParams={fixedFetchParams} excludedFilterFields={["user_id"]} />;
};

export default MyQuestion;
