import { Spin, Tabs } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getTargetURLQueryString } from "src/modules/auth/helpers";

import SignInForm from "../SignInForm";
import SignUpForm from "../SignUpForm";
import "./LoginTabs.scss";

const { TabPane } = Tabs;

const LoginTabs = ({ tab, getData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(tab || "1");
    const param = useParams();
    const page = param["*"];

    const onTabChange = (key) => {
        setTabIndex(key);
        const nextPage = key == "1" ? `/register${getTargetURLQueryString()}` : `/login${getTargetURLQueryString()}`;
        window.history.replaceState(null, "", nextPage);
    };

    useEffect(() => {
        if (page === "login") {
            setTabIndex("2");
        } else {
            setTabIndex("1");
        }
    }, []);

    return (
        <Spin spinning={isLoading}>
            <div className="login-tabs-wrapper">
                <div className="login-tabs">
                    <Tabs activeKey={tabIndex} onTabClick={onTabChange}>
                        <TabPane tab={t("login.sign_up")} key="1" forceRender>
                            <SignUpForm setLoading={setIsLoading} getData={getData} />
                        </TabPane>
                        <TabPane tab={t("login.sign_in")} key="2" forceRender>
                            <SignInForm setLoading={setIsLoading} getData={getData} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </Spin>
    );
};

export default LoginTabs;
