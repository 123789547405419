import {
    Checkbox,
    Collapse,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Select,
    Space,
    Spin,
    notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { createAssignment, updateAssignment } from "src/api/containers/assignment";
import { assignmentTypes } from "src/api/containers/assignment";
import { fetchExams } from "src/api/containers/exam";
import iconClose from "src/assets/images/action-close.svg";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";
import BaiKiemtraGif from "src/assets/images/gif/baitap.gif";
import { useMediaQuery, useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { animationId } from "src/reducers/animation";
import ROLES from "src/utils/roles";

import "./ModalDetailExam.scss";
import ExamCard from "./components/ExamCard";

const antiCheatingCategoies = [
    {
        title: "id_verification",
        key: "id_verification",
    },
    {
        title: "secured_recording",
        key: "secured_recording",

        children: [
            {
                key: "record_screen_share",
                type: "checkbox",
                allChilds: [
                    "record_system_audio",
                    "record_microphone",
                    "record_camera",
                    "detect_many_people_in_camera",
                    "times_allowed_many_people_in_camera",
                    "not_appear_on_camera",
                    "times_allowed_not_appear_on_camera",
                ],
                children: [
                    {
                        key: "record_system_audio",
                        type: "checkbox",
                        label: "record_system_audio",
                    },
                    {
                        key: "record_microphone",
                        type: "checkbox",
                        label: "record_microphone",
                    },
                    {
                        key: "record_camera",
                        type: "checkbox",
                        label: "record_camera",

                        children: [
                            {
                                key: "detect_many_people_in_camera",
                                type: "checkbox",
                                style: { marginLeft: 52 },
                                children: [
                                    {
                                        key: "times_allowed_many_people_in_camera",
                                        type: "input",
                                        label: "times_allowed_many_people_in_camera",
                                        style: { marginLeft: 76 },
                                    },
                                ],
                                allChilds: ["times_allowed_many_people_in_camera"],
                            },
                            {
                                key: "not_appear_on_camera",
                                type: "checkbox",
                                style: { marginLeft: 52 },
                                children: [
                                    {
                                        key: "times_allowed_not_appear_on_camera",
                                        type: "input",
                                        label: "times_allowed_not_appear_on_camera",
                                        style: { marginLeft: 76 },
                                    },
                                ],
                                allChilds: ["times_allowed_not_appear_on_camera"],
                            },
                        ],
                        allChilds: [
                            "detect_many_people_in_camera",
                            "times_allowed_many_people_in_camera",
                            "not_appear_on_camera",
                            "times_allowed_not_appear_on_camera",
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: "lockdown_browser",
        key: "lockdown_browser",
        children: [
            {
                key: "disable_coppy_paste",
                type: "checkbox",
                value: true,
            },
            {
                key: "open_devtool",
                type: "checkbox",
                value: true,
            },
            {
                key: "alert_user_leave_page",
                type: "checkbox",
                value: true,
                children: [
                    {
                        key: "open_tab",
                        type: "input",
                        label: "limit_exit_tab",
                    },
                ],
            },
            {
                key: "screen_shrinking_disable",
                type: "checkbox",
                value: true,
                children: [
                    {
                        key: "exit_full_screen",
                        type: "input",
                        label: "count_exit_full_screen",
                    },
                ],
            },
        ],
    },
    {
        title: "proctoring",
        key: "proctoring",
        children: [
            {
                key: "extend_monitor",
                type: "checkbox",
                value: true,
            },
        ],
    },
];

const antiCheatingOptionsInit = {
    screen_shrinking_disable: true,
    alert_user_leave_page: true,
    disable_coppy_paste: true,
    extend_monitor: true,
    open_devtool: true,
};

const ModalDetailExam = (props) => {
    const {
        courseId,
        categoryList = [], //list level
        editingAssignment = {},
        isLoading = false,
        isOnMobile,
        setValuesExam,
        examType,
        isShowFormSelect,
        onlyExam = false,
        onlyAssignment = false,
        language_id,
        isHaveLanguage,
        ...rest
    } = props;

    const { t } = useTranslation();
    const [form] = useForm();
    const user = useSelector((state) => state.auth.user);
    const examsTimeoutId = useRef();

    const [values, setValues] = useValues({
        loading: false,
        optionListCourse: [],
        optionListLevel: [],
        optionListExams: [],
        loaddingExams: false,
        optionListExamsDefault: [],
        exam_question_id: "",
        levels: [],
        is_public: undefined,
        antiCheating: antiCheatingOptionsInit,
    });

    const currMedia = useMediaQuery(["(max-width: 768px)"]);

    const dispatch = useDispatch();

    const isTypeExam = examType === "exam";

    const assignmentTypeSkills = useMemo(() => {
        return assignmentTypes.slice(0, 4);
    }, []);

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();
        // form.validateFields().then((formData) => {

        if (formData?.begin_date > formData?.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }

        if (formData.exam_question_id?.value) {
            formData.exam_question_id = formData.exam_question_id?.value;
        }
        formData.begin_date = formData.begin_date?.format("YYYY-MM-DD HH:mm:ss");
        formData.end_date = formData.end_date?.format("YYYY-MM-DD HH:mm:ss");
        formData.options = values.antiCheating;

        if (!Object.keys(editingAssignment || {}).length) {
            createAssignment(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", add: id }));
                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
            });
        } else {
            updateAssignment(editingAssignment.id, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", update: editingAssignment.id }));

                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
            });
        }
        // });
    };

    // const handleChangeLevel = (levelId) => {
    //     form.setFieldsValue({
    //         ...form.getFieldsValue(),
    //         exam_question_id: undefined,
    //         name: undefined,
    //     });
    //     setValues({ ...values, loading: true, level_id: levelId });
    //     fetchExams({
    //         level_id: levelId,
    //         language_id: language_id || values?.language_id || "",
    //         ...(user.role !== "employee" ? { user_id: user.id } : {}),
    //         noPagination: true,
    //     }).then((res) => {
    //         setValues({
    //             ...values,
    //             loading: false,
    //             optionListExams: res.data || [],
    //         });
    //     });
    // };

    const handleChangeExam = (value) => {
        const { label, value: examId } = value || {};
        const data = {
            name: label,
        };

        form.setFieldsValue(data);
        setValues({
            exam_question_id: examId,
        });
    };

    const handleSearchExams = (keyword) => {
        if (examsTimeoutId.current) {
            clearTimeout(examsTimeoutId.current);
        }
        setValues({ loaddingExams: true });
        examsTimeoutId.current = setTimeout(() => {
            fetchExams({
                ...(ROLES.ADMIN_EMPLOYEE.includes(user.role) ? {} : { user_id: user.id }),
                language_id: language_id,
                slug: keyword,
                page: 1,
            }).then(({ status, data }) => {
                if (status) {
                    setValues({ optionListExams: data || [], loaddingExams: false });
                } else {
                    setValues({ optionListExams: [], loaddingExams: false });
                }
            });
        }, 500);
    };

    useEffect(() => {
        /**
         * Get courses and level of each course.
         * Get all exams created by the user.
         */
        if ((isHaveLanguage && language_id) || !isHaveLanguage) {
            Promise.all([
                fetchExams({
                    ...(ROLES.ADMIN_EMPLOYEE.includes(user.role) ? {} : { user_id: user.id }),
                    language_id: language_id,
                }),
                // fetchCourses({ relations: ["level"], noPagination: true }),
            ]).then(([resExams]) => {
                if (resExams.status) {
                    setValues({
                        optionListExams: resExams.data || [],
                        optionListExamsDefault: resExams.data || [],
                    });
                }
            });
        }
    }, [language_id]);

    // useEffect(() => {
    //     fetchLevels({ slug: "", noPagination: true }).then((res) => {
    //         setValues({
    //             levels: res.data,
    //         });
    //     });
    // }, []);

    useEffect(() => {
        if (isLoading !== values.loading) {
            setValues({ ...values, loading: isLoading });
        }
    }, [isLoading]);

    useEffect(() => {
        if (Object.keys(editingAssignment || {}).length) {
            const courseId = editingAssignment?.course?.id;
            const levelId = editingAssignment?.level?.id;
            // Get level list:
            const newLevelList = [];
            if (courseId) {
                for (let i = 0; i < values.optionListCourse.length; i++) {
                    if (courseId === values.optionListCourse[i].id) {
                        newLevelList.push(values.optionListCourse[i].level);
                        break;
                    }
                }
            }
            // Get exam list for the selected level:
            setValues({ loading: true });
            fetchExams({
                ...(levelId ? { level_id: levelId } : {}),
                ...(ROLES.ADMIN_EMPLOYEE.includes(user.role) ? {} : { user_id: user.id }),
            }).then((res) => {
                setValues({
                    loading: false,
                    // optionListLevel: newLevelList,
                    optionListExams: res.data || [],
                });
                form.setFieldsValue({
                    level_id: editingAssignment?.level?.id || undefined,
                    name: editingAssignment?.name,
                    exam_question_id: { label: editingAssignment?.exam?.name, value: editingAssignment?.exam?.id },
                    begin_date: editingAssignment?.begin_date ? moment(editingAssignment?.begin_date) : undefined,
                    end_date: editingAssignment?.end_date ? moment(editingAssignment?.end_date) : undefined,
                    homework_time_minute: editingAssignment?.homework_time_minute,
                    type: editingAssignment?.type || undefined,
                    gradebook: editingAssignment?.gradebook || undefined,
                    is_public: editingAssignment?.is_public ?? undefined,
                    limit_submission: editingAssignment?.limit_submission || undefined,
                    late_start_minutes: editingAssignment?.late_start_minutes,
                });
            });
        } else {
            if (values.optionListCourse.length) {
                form.setFieldsValue({
                    level_id: undefined,
                    name: undefined,
                    exam_question_id: undefined,
                    begin_date: undefined,
                    end_date: undefined,
                    homework_time_minute: undefined,
                    type: undefined,
                    gradebook: undefined,
                    is_public: undefined,
                    limit_submission: undefined,
                    late_start_minutes: undefined,
                });
            }
        }
    }, [editingAssignment]);

    useEffect(() => {
        if (props.visible === false) {
            setValues({
                ...values,
                // optionListLevel: [],
                optionListExams: values.optionListExamsDefault,
                exam_question_id: "",
                antiCheating: antiCheatingOptionsInit,
            });
            form.resetFields();
        }
    }, [props.visible]);

    useEffect(() => {
        if (currMedia.breakpointValue !== -1 && !isOnMobile) {
            setValuesExam({ isOnMobile: true });
        } else if (currMedia.breakpointValue === -1 && isOnMobile) {
            setValuesExam({ isOnMobile: false });
        }
    }, [currMedia, isOnMobile]);

    const onChangeAssignmentType = (e) => {
        setValues({ is_public: e.target.value });
    };
    const resetAllChilds = (childs) => {
        const newChilds = {};
        childs?.forEach((item) => {
            newChilds[item] = undefined;
        });
        return newChilds;
    };

    const renderChild = (childItem) => {
        return childItem?.map((item, idx) => {
            switch (item?.type) {
                case "input":
                    return (
                        <div key={idx} className="anti_cheating-child-item" style={item?.style}>
                            <label>{t(`anti_cheating.${item?.label}`)}</label>
                            {item.type === "input" && (
                                <InputNumber
                                    min={0}
                                    onChange={(v) => {
                                        setValues({
                                            antiCheating: {
                                                ...values.antiCheating,
                                                [item.key]: v,
                                            },
                                        });
                                    }}
                                    value={values.antiCheating?.[item?.key]}
                                />
                            )}
                        </div>
                    );
                case "checkbox":
                    return (
                        <div key={idx}>
                            <Checkbox
                                // style={{ marginLeft: 26 }}
                                style={{ marginLeft: 26, ...(item?.style || {}) }}
                                name={t(`anti_cheating.${item.key}`)}
                                onChange={(e) => {
                                    if ([true, false].includes(item?.value)) {
                                        return;
                                    }

                                    let options = {
                                        ...values.antiCheating,
                                        [item.key]: antiCheatingCategoies[idx]?.[item.key]?.value || e.target.checked,
                                    };
                                    if (item?.allChilds?.length) {
                                        const opt = resetAllChilds(item.allChilds);

                                        Object.assign(options, opt);
                                    }

                                    setValues({
                                        antiCheating: options,
                                    });
                                }}
                                checked={item?.value || values.antiCheating?.[item.key]}
                                className={clsx({ "hide-check": !!item?.value })}
                            >
                                {t(`anti_cheating.${item.key}`)}
                            </Checkbox>
                            {item?.children && values.antiCheating?.[item.key] && renderChild(item.children)}
                        </div>
                    );
                default:
                    return null;
            }
        }, []);
    };

    const rederCheckboxsParent = (items, idx) => {
        if (Array.isArray(items)) {
            return (
                <Space direction="vertical">
                    {items.map((item) => {
                        const childItem = item?.children;
                        return (
                            <div key={item.key} className="anti_cheating-checkbox">
                                <Checkbox
                                    name={t(`anti_cheating.${item.key}`)}
                                    onChange={(e) => {
                                        if ([true, false].includes(item?.value)) {
                                            return;
                                        }
                                        setValues({
                                            antiCheating: {
                                                ...values.antiCheating,
                                                [item.key]:
                                                    antiCheatingCategoies[idx]?.[item.key]?.value || e.target.checked,
                                            },
                                        });

                                        if (e.target.checked === false) {
                                            // uncheck all child when parent uncheck
                                            updateChildsCheckbox(childItem, false, item.key);
                                        }
                                    }}
                                    checked={item?.value || values.antiCheating?.[item.key]}
                                    className={clsx({ "hide-check": !!item?.value })}
                                >
                                    {t(`anti_cheating.${item.key}`)}
                                </Checkbox>
                                {childItem?.length && values.antiCheating?.[item.key] && renderChild(childItem)}
                            </div>
                        );
                    })}
                </Space>
            );
        }
        return <></>;
    };

    useEffect(() => {
        if (editingAssignment.options) {
            setValues({ antiCheating: editingAssignment.options ?? editingAssignment.options });
        }
    }, [editingAssignment?.options]);

    function updateChildsCheckbox(childs, checked, parentKey) {
        const hasChilds = !!childs?.length;

        const anticheatingOptions = { ...values.antiCheating };
        anticheatingOptions[parentKey] = checked;
        if (hasChilds) {
            for (let i = 0; i < childs.length; i++) {
                anticheatingOptions[childs?.[i]?.key] = checked;
            }

            setValues({
                antiCheating: anticheatingOptions,
            });
        }
    }

    const renderChildOptions = (idx, item) => {
        switch (idx) {
            case 0:
                return (
                    <Space direction="vertical">
                        <div name="portrait_image">{t("anti_cheating.portrait_image")}</div>
                        <Radio.Group
                            name="portrait_image"
                            onChange={(e) => {
                                setValues({
                                    antiCheating: {
                                        ...values.antiCheating,
                                        portrait_image: e.target.value,
                                    },
                                });
                            }}
                            value={values.antiCheating?.["portrait_image"]}
                            style={{ marginBottom: 5 }}
                        >
                            <Radio name="webcam_picture" value="webcam_picture">
                                {t("anti_cheating.webcam_picture")}
                            </Radio>
                            <Radio name="upload_picture" value="upload_picture">
                                {t("anti_cheating.upload_picture")}
                            </Radio>
                        </Radio.Group>

                        <Checkbox
                            name="upload_identity_card"
                            onChange={(e) => {
                                setValues({
                                    antiCheating: { ...values.antiCheating, upload_identity_card: e.target.checked },
                                });
                            }}
                            checked={values.antiCheating?.["upload_identity_card"]}
                        >
                            {t("anti_cheating.upload_identity_card")}
                        </Checkbox>
                    </Space>
                );
            case 1:
                return rederCheckboxsParent(item, idx);

            case 2:
                return rederCheckboxsParent(item, idx);

            case 3:
                return rederCheckboxsParent(item, idx);
        }
    };

    return (
        <Modal
            centered
            // title={t("course.add_exam")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            {...rest}
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            }  modal-detail-exam`}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <div className="modal-detail-exam__skeleton">
                        {(!isOnMobile || !isShowFormSelect) && (
                            <div className="modal-detail-exam__left">
                                <div className="modal-detail-exam__left-header">{t("course.add_exam")}</div>
                                <div className="modal-detail-exam_list">
                                    {!onlyExam && (
                                        <ExamCard
                                            active={isTypeExam}
                                            title={t("course.exam")}
                                            src={BaiKiemtraGif}
                                            onClick={() => setValuesExam({ examType: "exam", isShowForm: true })}
                                        />
                                    )}
                                    {/* {!onlyAssignment && (
                                        <ExamCard
                                            active={examType === "assignment"}
                                            title={t("course.assignment")}
                                            src={BaiTapGif}
                                            onClick={() => setValuesExam({ examType: "assignment", isShowForm: true })}
                                        />
                                    )} */}
                                </div>
                            </div>
                        )}
                        {/* Right section */}
                        {(!isOnMobile || isShowFormSelect) && (
                            <div className="modal-detail-exam__right">
                                <div className="detail-exam-form">
                                    <Form form={form} onFinish={handleSubmitForm}>
                                        <Form.Item
                                            label={t("course.select_exam")}
                                            name="exam_question_id"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("course.select_exam")}
                                                onChange={(value) => handleChangeExam(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                                onSearch={handleSearchExams}
                                                loading={values.loaddingExams}
                                                notFoundContent={
                                                    values.loaddingExams ? (
                                                        <span style={{ display: "flex" }}>
                                                            <Spin style={{ marginRight: "10px" }} />
                                                            {`${t("shared.loading")}...`}
                                                        </span>
                                                    ) : undefined
                                                }
                                                labelInValue
                                            >
                                                {values.optionListExams.map((exam) => (
                                                    <Select.Option key={exam.id} value={exam.id}>
                                                        {exam.name || t("shared.unknown")}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label={
                                                <>
                                                    <span>{t("course.exam_name")}</span>
                                                    <CustomTooltip
                                                        type="question-mark"
                                                        placement="right"
                                                        title={t("assignment.exam_name_description")}
                                                        style={{ order: "4", marginLeft: "4px" }}
                                                    />
                                                </>
                                            }
                                            name="name"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Input
                                                className="app-input"
                                                placeholder={t("course.input_exam_name")}
                                                maxLength={100}
                                            />
                                        </Form.Item>

                                        {/* <Form.Item
                                            label={t("assignment.type")}
                                            name="type"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("assignment.select_type")}
                                                onChange={(value) => {}}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {assignmentTypeSkills.map((item, index) => (
                                                    <Select.Option key={`type-${index}`} value={item.value}>
                                                        {t(`assignment.type_${item.name}`)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item> */}

                                        {/* <Form.Item
                                            label={t("assignment.select_gradebook_type")}
                                            name="gradebook"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("assignment.select_gradebook_type")}
                                                onChange={(value) => {}}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {assignmentGradebookTypes.map((item, index) => (
                                                    <Select.Option key={`gradebook-type-${index}`} value={item.value}>
                                                        {t(`assignment.gradebook_type_${item.name}`)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item> */}

                                        <Form.Item
                                            label={t("course.begin_date")}
                                            name="begin_date"
                                            rules={[{ required: isTypeExam, message: t("course.input_begin_date") }]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: "HH:mm" }}
                                                placeholder={t("course.input_begin_date")}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("course.end_date")}
                                            name="end_date"
                                            rules={[{ required: isTypeExam, message: t("course.input_end_date") }]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: "HH:mm" }}
                                                placeholder={t("course.input_end_date")}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("course.duration")}
                                            name="homework_time_minute"
                                            rules={[{ required: isTypeExam, message: t("message.required") }]}
                                        >
                                            <InputNumber
                                                className="app-input"
                                                placeholder={t("course.input_time")}
                                                min={0}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("anti_cheating.late_start_minutes")}
                                            name="late_start_minutes"
                                        >
                                            <InputNumber
                                                className="app-input"
                                                placeholder={t("course.input_time")}
                                                min={0}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={t("status")}
                                            name="is_public"
                                            rules={[{ required: isTypeExam, message: t("message.required") }]}
                                        >
                                            <Radio.Group
                                                onChange={onChangeAssignmentType}
                                                disabled={editingAssignment?.id}
                                            >
                                                <Radio value={true}>{t("assignment.public")}</Radio>
                                                <Radio value={false}>{t("assignment.private")}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {values.is_public === true && (
                                            <Form.Item
                                                label={t("assignment.limit_submission")}
                                                name="limit_submission"
                                                rules={[{ required: isTypeExam, message: t("message.required") }]}
                                            >
                                                <InputNumber
                                                    className="app-input"
                                                    placeholder={t("assignment.count_submission")}
                                                    min={0}
                                                />
                                            </Form.Item>
                                        )}

                                        <Form.Item label={t("Chống gian lận")}>
                                            <Collapse
                                                defaultActiveKey={[
                                                    "id_verification",
                                                    "secured_recording",
                                                    "lockdown_browser",
                                                ]}
                                                className="security-collapse"
                                            >
                                                {antiCheatingCategoies.map((item, idx) => {
                                                    return (
                                                        <Collapse.Panel
                                                            header={t(`anti_cheating.${item.title}`)}
                                                            key={item.key}
                                                        >
                                                            {renderChildOptions(idx, item?.children)}
                                                        </Collapse.Panel>
                                                    );
                                                })}
                                            </Collapse>
                                        </Form.Item>

                                        <div className="btn-group">
                                            <CustomButton
                                                type="green"
                                                htmlType="submit"
                                                icon={<SendIcon />}
                                                title={t("action.save")}
                                                style={{ width: "100%", maxWidth: "unset" }}
                                            ></CustomButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailExam;
