import { DoubleLeftOutlined, InfoCircleOutlined, MailOutlined } from "@ant-design/icons";
import { Form, Input, Tooltip, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getCodeVerifyEmail, verifyCodeEmail } from "src/api/containers/examCheckin";
import { useValues } from "src/hooks";
import CustomButton from "src/modules/components/Button";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";

import "./EmailVerify.scss";

const COUNDOWN = 60;

const EmailForm = () => {
    const { t } = useTranslation();

    return (
        <div className="email-form">
            <Form.Item
                name="email"
                label={`${t("email")}`}
                rules={[
                    { required: true, message: t("shared.input_email") },
                    {
                        type: "email",
                        message: t("message.invalid_email"),
                    },
                ]}
            >
                <Input className="app-input has-rd" placeholder={t("login.enter_your_email")} />
            </Form.Item>

            <Form.Item className="form-actions">
                <CustomButton htmlType="submit" title={t("next")} />
            </Form.Item>
        </div>
    );
};

const CodeForm = ({ is_code_sent = true, countdown, is_running_count, handleGetCodeVerify, currentEmail }) => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state?.general?.lang);

    const renderInputNotice = (countdown) => {
        if (lang === "en") {
            return `You haven't received the code yet? Request new code after ${countdown} seconds. The code will expire after 5 minutes.`;
        }
        return `Bạn chưa nhận được mã? Yêu cầu mã mới sau ${countdown} giây. Mã sẽ hết hạn sau 5 phút.`;
    };
    const renderSuffix = () => {
        return (
            <span>
                {is_code_sent ? (
                    <span>
                        <span>
                            {is_running_count ? (
                                <span className="code-sent-btn">
                                    {t("code_sent")}{" "}
                                    <Tooltip title={renderInputNotice(countdown)}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </span>
                            ) : (
                                <span
                                    onClick={() => handleGetCodeVerify({ email: currentEmail })}
                                    className="resent-code-btn"
                                >
                                    {t("resent_code")}
                                </span>
                            )}
                        </span>
                    </span>
                ) : (
                    <span></span>
                )}
            </span>
        );
    };

    return (
        <div className="email-form code-form">
            <Form.Item
                name="code_verify"
                label={t("email_erification_code")}
                rules={[{ required: true, message: t("message.required") }]}
            >
                <Input className="app-input has-rd" placeholder={t("login.enter_code")} suffix={renderSuffix()} />
            </Form.Item>

            <Form.Item className="form-actions">
                <CustomButton htmlType="submit" title={t("shared.submit")} />
            </Form.Item>
        </div>
    );
};

export default function EmailVerify({ setTempUserInfo, setFormData }) {
    const [form] = useForm();
    const { t } = useTranslation();
    const lang = useSelector((state) => state.general?.lang);
    const examProctoringData = useContext(ProctoringContext);

    const assignmentInfo = examProctoringData?.examCheckInInfo?.assignment;

    const [countdown, setCountdown] = useState(COUNDOWN);
    const [values, setValues] = useValues({
        email: "",
        code_verify: "",
        tab: 0,
        is_sent_mail: false,
        is_running_count: false,
    });

    useEffect(() => {
        let coutDownInterVal;
        if (values.email && values.is_sent_mail) {
            if (values.is_running_count && countdown > 0) {
                coutDownInterVal = setInterval(() => {
                    setCountdown((pre) => pre - 1);
                }, 1000);
            } else if (countdown === 0) {
                clearInterval(coutDownInterVal);
                setValues({ is_running_count: false });
            }
        }

        return () => {
            if (coutDownInterVal) {
                clearInterval(coutDownInterVal);
            }
        };
    }, [values.is_code_sent, values.email, values.tab, countdown]);

    const handleGetCodeVerify = (data) => {
        getCodeVerifyEmail(data.email).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("code_sent_success") });
                setValues({ email: data.email, tab: 1, is_sent_mail: true, is_running_count: true });
                setCountdown(COUNDOWN);
            } else {
                notification.error({ message: message || t("an_error_occurred") });
            }
        });
    };

    const handleSubmit = (fData) => {
        if (values.tab === 0) {
            if (fData?.email && values.is_running_count) {
                //case: đang đếm được mà user back về để submit form get code, vì lý do như lỡ nhập sai email
                if (fData?.email !== values.email) {
                    handleGetCodeVerify(fData);
                } else {
                    setValues({ tab: 1 });
                }
            } else if (fData?.email && !values.is_running_count) {
                handleGetCodeVerify(fData);
            }
        } else if (values.tab === 1 && fData?.code_verify) {
            verifyCodeEmail(values.email, +fData.code_verify, assignmentInfo?.id).then(({ status, message, data }) => {
                if (status) {
                    notification.success({ message: t("code_sent_success") });
                    setValues({ email: fData.email, tab: 1, code_verify: fData?.code_verify });
                    setTempUserInfo?.({
                        code_verify: fData?.code_verify,
                        email: fData.email,
                        avatar: data?.avatar_src,
                        personal_number: data?.personal_number,
                    });

                    if (data) {
                        setFormData({
                            code_verify: fData?.code_verify || undefined,
                            email: data?.username || undefined,
                            name: data?.name || undefined,
                            avatar: data?.avatar_src || undefined,
                            personal_number: data?.personal_number || undefined,
                        });
                    }
                } else {
                    notification.error({ message: message || t("an_error_occurred") });
                }
            });
        }
    };

    const getFormNotices = () => {
        let text = "";

        if (values.tab == 1) {
            switch (lang) {
                case "en":
                    text = (
                        <span>
                            Please enter the verification code sent to <strong>{values.email}</strong>. This code is
                            only valid for 5 minutes.
                        </span>
                    );
                    break;
                default:
                    text = (
                        <span>
                            Hãy nhập mã xác minh đã được gửi đến <strong>{values.email}</strong>. Mã này chỉ có giá trị
                            trong vòng 5 phút.
                        </span>
                    );
                    break;
            }
        }

        return text;
    };

    return (
        <div className="email-verify">
            {values.tab > 0 && (
                <div className="email-verify_navigate">
                    <div className="navigate-wrapper" onClick={() => setValues({ tab: 0 })}>
                        <DoubleLeftOutlined /> <span>{t("breadcumb.goback")}</span>
                    </div>
                </div>
            )}
            <div className="email-verify_content">
                <div className="candidate-info-title">
                    <div className="title-icon">
                        <MailOutlined />
                    </div>
                    <div className="title-content">
                        <div className="content-main">{t("login.verify_email")}</div>
                    </div>
                </div>

                <div className="email-verify_form-notice">{getFormNotices()}</div>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    {values.tab === 0 ? (
                        <EmailForm />
                    ) : (
                        <CodeForm
                            countdown={countdown}
                            is_running_count={values.is_running_count}
                            handleGetCodeVerify={handleGetCodeVerify}
                            currentEmail={values.email}
                        />
                    )}
                </Form>
            </div>
        </div>
    );
}
