import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { useDispatch, useSelector } from "react-redux";
import { Form, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { cloneQuestion, findQuestion, questionTypes } from "src/api/containers/question";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import Header from "src/modules/components/Header";
import Breadcrumb from "src/modules/components/Breadcrumb";
import Icon from "src/modules/components/Icon";
import QuestionPanel from "src/modules/components/QuestionPanel";
import QuestionContent from "src/modules/components/QuestionContent";
import { getQuestionTypeByKey, getQuestionDataPreview } from "src/api/helpers/question";
import { animationId } from "src/reducers/animation";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import "./QuestionPreview.scss";

function QuestionPreview(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // User and scope:
    const { user } = useSelector((state) => state.auth);
    const fParamsOrgMember = user.paramsForOrgOrTeam || {};

    // Form:
    const [form] = Form.useForm();
    const [values, setValues] = useValues({
        loading: false,
    });
    const [isShowResult, setIsShowResult] = useState(false);
    const [qDataPreview, setQDataPreview] = useState({});
    const [qDataAnswered, setQDataAnswered] = useState(undefined);

    // Routing & navigating:
    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();

    // Page info:
    const [currQType, currQKey, isScoring] = useMemo(() => {
        let currQType = "";
        let currQKey = "";
        let isScoring = null;

        currQType = getQuestionTypeByKey(params.questionKey);
        currQKey = params.questionKey;
        isScoring = questionTypes[currQType]?.isScoring;

        return [currQType, currQKey, isScoring];
    }, [params]);

    const handleGoBack = () => {
        navigate(generate("question_bank"));
    };

    const handleCancel = (e) => {
        e.preventDefault();
        navigate(generate("question_bank"));
    };

    const handleCloneQuestion = (e) => {
        e.preventDefault();
        if (params.id) {
            cloneQuestion(params.id, fParamsOrgMember).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "question", clone: id }));
                    notification.success({
                        message: t("message.clone_success"),
                    });
                    navigate(generate("question_bank"));
                } else {
                    notification.error({
                        message: message || t("message.clone_error"),
                    });
                }
            });
        }
    };

    const handleToggleShowResult = () => {
        setIsShowResult(!isShowResult);
    };

    const handleFakeAnswering = (newValue) => {
        if (!newValue) {
            return;
        }
        // Tạm thời câu highlight bắn answer khác sau sẽ cập nhật lại
        if (newValue.passage_highlighted) {
            setQDataAnswered({
                answered: {
                    answered: newValue.answered,
                    passage_highlighted: newValue.passage_highlighted,
                },
            });
        } else {
            setQDataAnswered({
                answered: newValue?.answered,
            });
        }
    };

    useEffect(() => {
        if (params.id) {
            setValues({ ...values, loading: true });
            if (currQType) {
                findQuestion(params.id, currQType).then((res) => {
                    if (res.status) {
                        setQDataPreview(getQuestionDataPreview(res?.data || {}));
                    }
                    setValues({
                        ...values,
                        loading: false,
                    });
                });
            }
        } else {
            alert('Param "id" not found!');
        }
    }, []);

    const renderActionList = () => {
        return (
            <div className="btn-group">
                <Form.Item>
                    <CustomButton
                        type="grey"
                        icon={<SvgCross />}
                        title={params.id ? t("shared.back") : t("shared.cancel")}
                        onClick={handleCancel}
                    ></CustomButton>
                    {checkIfActionIsAllowed("question", "clone") === true ? (
                        <CustomButton
                            type="primary"
                            icon={<Icon name="icon-save" />}
                            title={t("shared.clone")}
                            onClick={handleCloneQuestion}
                        ></CustomButton>
                    ) : null}
                </Form.Item>
            </div>
        );
    };

    return (
        <div className="question-preview-wrapper">
            {/* <Header
                backTitle={t(`q_detail.header_${currQType}`)}
                backFunc={handleGoBack}
                actions={[
                    {
                        title: t("header.preview"),
                        icon: <Icon name="icon-preview" fill="#0077FF" />,
                        link: "",
                        type: "primary",
                    },
                ]}
            /> */}
            <Breadcrumb
                isBack
                routes={[
                    {
                        name: t(`q_detail.header_${currQType}`),
                    },
                ]}
                actions={[
                    {
                        title: t("header.preview"),
                        icon: <Icon name="icon-preview" fill="#0077FF" />,
                        className: "action-button--primary",
                    },
                ]}
            />

            <div className="question-preview">
                <Spin spinning={values.loading}>
                    <Form form={form}>
                        {!isScoring && (
                            <button className="question-show-answer" onClick={handleToggleShowResult}>
                                {isShowResult ? t("q_detail.hide_answer") : t("q_detail.show_answer")}
                            </button>
                        )}

                        {!isShowResult ? (
                            <QuestionPanel
                                showQuestionNumber={false}
                                showBottomNav={false}
                                questionInfo={{
                                    ...qDataPreview,
                                    ...qDataAnswered,
                                }}
                                onChange={handleFakeAnswering}
                            />
                        ) : (
                            <QuestionContent
                                type="review"
                                questionInfo={{
                                    ...qDataPreview,
                                    ...qDataAnswered,
                                }}
                                showResults={true}
                                // Question display type:
                                displayType="question-panel"
                            />
                        )}

                        {renderActionList()}
                    </Form>
                </Spin>
            </div>
        </div>
    );
}

export default QuestionPreview;
