import { t } from "i18next";

const HISTORY_FIELDS = [];

const getHistoryFields = (field, t, sub_field) => {
    const fields = {
        begin_date: t("course.begin_date"),
        end_date: t("course.end_date"),
        name: t("unit.name"),
        title: t("unit.title"),
        order: t("unit.order"),
        type: t("course.type"),
        level: t("course.level"),
        start_date: t("course.start_date"),
        email: t("course.email"),
        score: t("course.score"),
        role: t("course.role"),
        gender: t("course.gender"),
        phone_number: t("course.phone_number"),
        password: t("course.password"),
        updater: t("question.updater"),
        owner: t("question.owner"),
        answer: t("question.correct_answer"),
        answer1: t("shared.answer"),
        answer2: t("shared.answer"),
        exam_question: t("history.exam_question"),
        max_score: t("course.max_score"),
        language: t("language.language"),
        question: t("question.question"),
        content: t("question.question"),
        is_public: t("question.public"),
        gradebook: t("assignment.gradebook_type"),
        height: t("shared.height"),
        width: t("shared.width"),
        image: t("guide.image"),
        file: t("guide.image"),
        homework_time_minute: t("assignment.duration"),
        exam_question_list_ids: t("exam_bank.list_question"),
        admin: t("admin"),
        employee_crm: t("employee_crm"),
        employee: t("employee"),
        teacher: t("teacher"),
        description: t("course.assignment_description"),
        word_limit: t("question_writing.word_limit"),
        speaker: t("speech_to_text.speaker"),
        is_require_uppercase: t("question_fillblanks.is_require_uppercase"),
        passage: t("question.passage"),
        match: t("question.correct_answer"),
        tag_ids: t("tag.tag"),
        coordinates: t("history.coordinates"),
        path: t("history.coordinates"),
        is_publish: t("shared.public"),
        questions: t("exam_bank.list_question"),

        //questions
        multiple_choice: t("question.multiple_choice"),
        multiple_choice_boolean: t("question.multiple_choice_boolean"),
        multiple_choice_table: t("question.multiple_choice_table"),
        writing: t("question.writing"),
        writing_short: t("question.writing_short"),
        fill_in_the_blank: t("question. fill_in_the_blank"),
        fill_in_the_blank_text: t("question.fill_in_the_blank_text"),
        fill_in_the_blank_drag_drop: t("question.fill_in_the_blank_drag_drop"),
        sort: t("question.sort"),
        drag_drop: t("question.drag_drop"),
        highlight: t("question.highlight"),
        highlight_image: t("question.highlight_image"),
        hotspot: t("question.hotspot"),
        fill_in_the_blank_image: t("question.fill_in_the_blank_image"),
        fill_in_the_blank_text_image: t("question.fill_in_the_blank_text_image"),
        fill_in_the_blank_drag_drop_image: t("question.fill_in_the_blank_drag_drop_image"),
        speech_to_text: t("question.speech_to_text"),
        speech_to_text_conversation_audio: t("question.speech_to_text_conversation_audio"),
        speech_to_text_conversation_text: t("question.speech_to_text_conversation_text"),
        phonetic_table: t("question.phonetic_table"),
        heading: t("question_passage.heading"),

        assignment_fields: {
            middle: t("assignment.gradebook_type_middle"),
            final: t("assignment.gradebook_type_final"),
            name: t("assignment.exam_name"),
            listening: t("course.listening"),
            speaking: t("course.speaking"),
            reading: t("course.reading"),
        },
        exam_question_fields: {},
        question_fields: {
            passage: t("shared.answer"),
        },
        course_fields: {},
    };

    if (sub_field) {
        return fields?.[field]?.[sub_field];
    }
    return fields?.[field];
};

const getModalName = (model) => {
    const name = {
        assignment: t("history.assignment"),
        Assignment: t("history.assignment"),
        examQuestion: t("history.exam_question"),
        ExamQuestion: t("history.exam_question"),
        question: t("history.question"),
        Question: t("history.question"),
        course: t("history.course"),
        Course: t("history.course"),
    };
    return name[model] || t("shared.unknown");
};

const listModels = {
    Assignment: "assignment",
    ExamQuestion: "examQuestion",
    Question: "question",
    Course: "course",
};

const getDataRelation = (type, historyObj) => {
    if (type && historyObj) {
        return historyObj?.[listModels?.[type]];
    }
    return {};
};

const getActionType = (action) => {
    const name = {
        create: t("shared.add_new"),
        update: t("shared.update"),
        delete: t("shared.delete"),
    };
    return name[action] || t("shared.unknown");
};

const renderFieldName = (key, t, sub_field) => {
    return getHistoryFields(key, t, sub_field) || key || null;
};

const specialFieldsOfQuestion = ["answer", "answer1", "answer2", "path"];

export {
    getActionType,
    getModalName,
    renderFieldName,
    getDataRelation,
    specialFieldsOfQuestion,
    HISTORY_FIELDS,
    getHistoryFields,
};
