/**
 * Check if the user has permission to edit question:
 * @param {string} checkingType
 * @param {any} userData { id: "", role: "" }
 * @param {any} questionData { employee_can_edit: true||false }
 * @param {any} questionOwnerData { id: "", role: role of question owner }
 * @returns (boolean) true if yes, false if no.
 */
export function checkIf_User_CanEditQuestion(checkingType, userData, questionData, questionOwnerData) {
    if (!["employee", "employee_crm", "teacher", "admin"].includes(userData?.role)) {
        return false;
    }
    switch (checkingType) {
        case "type-1":
            // 1. Only the owner of the question can edit:
            if (userData.id === questionOwnerData.id) {
                return true;
            }
            return false;
        case "type-2":
            // 2. There are two cases:
            // - If the user is "teacher": The employee can only edit his/her question.
            // - If the user is "employee": The employee can edit his/her and other employee's questions.

            //admin employee employee_crm can't update teacher questions
            if (
                !(userData.id === questionOwnerData.id) &&
                questionOwnerData?.role === "teacher" &&
                ["employee", "employee_crm", "admin"].includes(userData?.role)
            ) {
                return false;
            }
            if (userData?.role === "teacher") {
                if (userData.id === questionOwnerData.id) {
                    return true;
                }
                return false;
            } else if (["employee", "employee_crm"].includes(userData?.role)) {
                if (userData.id === questionOwnerData.id || questionData.employee_can_edit === true) {
                    return true;
                }
                return false;
            } else if (userData?.role === "admin") {
                return true;
            } else {
                return false;
            }
        default:
            break;
    }
    return false;
}
