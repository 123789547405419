import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import "./MenuCustomHorizontal.scss";

const MenuCustomHorizontal = ({ className = "", selectedKeys = [], items = [], ...rest }) => {
    const menuRef = useRef(null);
    const menuPaddingLR = 20; // Padding's width of ul.app-nav-menu.
    const [hiddenMenuItems, setHiddenMenuItems] = useState([]);

    const menuActualWidth = useMemo(() => {
        if (menuRef.current) {
            const liArr = menuRef.current.menu.list.querySelectorAll("li");
            let r = 0;
            for (let i = 0; i < liArr.length; i++) {
                r += liArr[i].getBoundingClientRect().width || 0;
            }
            return r + menuPaddingLR;
        }
        return -1;
    }, [menuRef.current]);

    const menuShowMore = useMemo(() => {
        return <Menu className="app-nav-menu" items={hiddenMenuItems} selectedKeys={selectedKeys} />;
    }, [selectedKeys, hiddenMenuItems]);

    const handleResize = useCallback(
        (e) => {
            const liArr = menuRef.current.menu.list.querySelectorAll("li");
            const menuVisisblePart = menuRef.current.menu.list.offsetWidth;
            let widthVisible = menuPaddingLR;
            let widthEndIndex = -1;

            for (let i = 0; i < liArr.length; i++) {
                widthVisible += liArr[i].getBoundingClientRect().width || 0;
                if (Math.floor(widthVisible) <= menuVisisblePart) {
                    widthEndIndex = i;
                } else {
                    break;
                }
            }

            if (widthEndIndex >= items.length - 1) {
                if (hiddenMenuItems.length !== 0) {
                    setHiddenMenuItems([]);
                }
            } else if (widthEndIndex < items.length && widthEndIndex >= 0) {
                const arr = items.slice(widthEndIndex + 1);
                if (arr.length !== hiddenMenuItems.length) {
                    setHiddenMenuItems(arr);
                }
            } else {
                //
            }
        },
        [menuRef.current, hiddenMenuItems]
    );

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        handleResize();
    }, [menuRef.current]);

    return (
        <div className="custom-horizontal-navmenu">
            <Menu
                ref={menuRef}
                className={`app-nav-menu horizontal${className ? " " + className : ""}`}
                items={items}
                selectedKeys={selectedKeys}
                {...rest}
            />
            {hiddenMenuItems.length > 0 ? (
                <Dropdown overlay={menuShowMore} trigger={["click"]} placement="bottomRight">
                    <Button className="dropdown-btn-show-more">
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            ) : null}
        </div>
    );
};

export default MenuCustomHorizontal;
