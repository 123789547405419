import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Dropdown, Select, Space, Spin, Tag, Tooltip, notification } from "antd";
import { t } from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchLanguages } from "src/api/containers/language";
import { fetchLevels } from "src/api/containers/level";
import { cloneQuestion, deleteQuestion, fetchQuestionList, questionTypes } from "src/api/containers/question";
import { fetchTagsWithFiltering } from "src/api/containers/tag";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import { getQuestionKeyByType } from "src/api/helpers/question";
import { checkIf_User_CanEditQuestion } from "src/api/utils/question";
import { ReactComponent as SvgFilter } from "src/assets/images/filter-icon.svg";
import { ReactComponent as SvgPencil } from "src/assets/images/pencil.svg";
import { useFetch, useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
import FilterMenu from "src/modules/components/FilterMenu";
import Header from "src/modules/components/Header";
import InputSearch from "src/modules/components/Input/InputSearch";
import ModalSelectQuestionType from "src/modules/components/Modal/ModalSelectQuestionType";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import Table from "src/modules/components/Table";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import { compareObjects } from "src/utils/helpers/comparison";
import { getFilterValuesByURLSearchParams, getURLSearchParamsByFilterValues } from "src/utils/helpers/urlQueryString";
import { useRouting } from "src/utils/router";

import "./QuestionBank.scss";

const QuestionBank = ({ isWithUrlQueryString = true, fixedFetchParams, excludedFilterFields }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { generate } = useRouting();

    // User:
    const { user } = useSelector((state) => state.auth);
    // Date format:
    const formatDate = useSelector((state) => state.general.formatDate);
    // Table row animation:
    const animate = useSelector((state) => state.animate.animation);
    const idRow = animate?.clone || animate?.add || animate?.update;

    // FILTER SETTINGS:
    const [filterInfo, setFilterInfo] = useValues({
        isFirstTimeFetchDone: false,
        // Search box:
        inputSearch: "",
        // Filter menu:
        isVisible: false,
        languages: [],
        levels: [],
        tags: [],
        isFetchingTags: false, // Values: "searching", true, false.
        isLoadingMoreTags: false,
        currPageForTags: 1,
    });
    const [filterValuesInitial, filterValuesConfig] = useMemo(() => {
        // Flter values and config:
        const _filterValuesInitial = {
            page: 1,
            slug: undefined,
            user_id: user.id,
        };
        const _filterValuesConfig = {
            page: {
                queryKey: "p",
            },
            slug: {
                queryKey: "s",
            },
            user_id: {
                label: t("question.owner"),
                queryKey: "own",
                values: [user.id],
                valuesInfo: {
                    [user.id]: { label: t("question.owner_me"), queryValue: "me" },
                },
            },
            type: {
                label: t("shared.type"),
                queryKey: "type",
                getValueLabel: (value) => {
                    return t(`q.${value}`);
                },
            },
            language_id: {
                label: t("language.language"),
                queryKey: "lang",
                getValueLabel: (value) => {
                    for (let i = 0; i < filterInfo.languages.length; i++) {
                        if (value === filterInfo.languages[i].id) {
                            return filterInfo.languages[i].name;
                        }
                    }
                    return "Unknown";
                },
            },
            level_id: {
                label: t("shared.level"),
                queryKey: "lvl",
                getValueLabel: (value) => {
                    for (let i = 0; i < filterInfo.levels.length; i++) {
                        if (value === filterInfo.levels[i].id) {
                            return filterInfo.levels[i].name;
                        }
                    }
                    return "Unknown";
                },
            },
            tag_name: {
                label: t("shared.tags"),
                queryKey: "tag",
                getValueLabel: (value) => {
                    return value;
                },
            },
            is_public: {
                label: t("shared.scope"),
                queryKey: "public",
                values: ["true", "false"],
                valuesInfo: {
                    true: { label: t("shared.public"), queryValue: "1" },
                    false: { label: t("shared.only_me"), queryValue: "0" },
                },
            },
            is_publish: {
                label: t("shared.status"),
                queryKey: "publish",
                values: ["true", "false"],
                valuesInfo: {
                    true: { label: t("question_bank.done"), queryValue: "1" },
                    false: { label: t("question_bank.draft"), queryValue: "0" },
                },
            },
        };
        // Remove excluded filter values and config:
        const filterValuesInitial = {};
        const filterValuesConfig = {};
        Object.keys(_filterValuesInitial).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesInitial[elem] = _filterValuesInitial[elem];
            }
        });
        Object.keys(_filterValuesConfig).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesConfig[elem] = _filterValuesConfig[elem];
            }
        });
        // Result:
        return [filterValuesInitial, filterValuesConfig];
    }, [filterInfo]);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [currFilterValues, setCurrFilterValues] = useState(filterValuesInitial || {});

    // FILTER MENU:
    const formFields = useMemo(() => {
        const handleSearchTags = (keyword) => {
            clearTimeout(timeoutIdGetTags.current);
            if (typeof keyword === "string") {
                setFilterInfo({
                    ...filterInfo,
                    isFetchingTags: "searching",
                });
                const fetchParams = {
                    slug: keyword,
                    page: 1,
                };
                timeoutIdGetTags.current = setTimeout(() => {
                    fetchTagsWithFiltering(fetchParams).then((res) => {
                        if (res.status && res.data) {
                            setFilterInfo({
                                ...filterInfo,
                                tags: res.data,
                                isFetchingTags: false,
                                isLoadingMoreTags: false,
                                currPageForTags: 1,
                            });
                        }
                    });
                }, 500);
            }
        };
        const handleScroll = (e) => {
            if (filterInfo.isLoadingMoreTags === true || filterInfo.isLoadingMoreTags === "disabled") {
                return;
            }
            if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
                setFilterInfo({ ...filterInfo, isLoadingMoreTags: true });
                const nextPageToLoad = filterInfo.currPageForTags + 1;
                const fetchParams = {
                    slug: "",
                    page: nextPageToLoad,
                };
                fetchTagsWithFiltering(fetchParams).then((res) => {
                    if (res.status === true) {
                        if (res.data?.length === 0) {
                            setFilterInfo({
                                ...filterInfo,
                                isLoadingMoreTags: "disabled",
                            });
                        } else {
                            setFilterInfo({
                                ...filterInfo,
                                isLoadingMoreTags: false,
                                currPageForTags: res.pagination?.current || nextPageToLoad,
                                tags: [...filterInfo.tags, ...res.data],
                            });
                        }
                    } else {
                        setFilterInfo({
                            ...filterInfo,
                            isLoadingMoreTags: false,
                        });
                    }
                });
            }
        };
        const _formFields = {
            ...(filterValuesConfig.user_id
                ? {
                      user_id: {
                          label: filterValuesConfig.user_id.label,
                          element: (
                              <Select className="app-select" placeholder={t("question.select_owner")} allowClear>
                                  {filterValuesConfig.user_id.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`own${itemIndex}`} value={item}>
                                              {filterValuesConfig.user_id.valuesInfo[item].label}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.type
                ? {
                      type: {
                          label: filterValuesConfig.type.label,
                          element: (
                              <Select className="app-select" placeholder={t("shared.choose_type")} allowClear>
                                  {Object.keys(questionTypes).map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`type${itemIndex}`} value={item}>
                                              {filterValuesConfig.type.getValueLabel(item)}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.language_id
                ? {
                      language_id: {
                          label: filterValuesConfig.language_id.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("language.select_language")}
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                              >
                                  {filterInfo.languages.map((item, index) => (
                                      <Select.Option key={`lang${index}`} value={item.id}>
                                          {item.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.level_id
                ? {
                      level_id: {
                          label: filterValuesConfig.level_id.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("question.search_and_select_level")}
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                              >
                                  {filterInfo.levels.map((item, index) => (
                                      <Select.Option key={`lvl${index}`} value={item.id}>
                                          {item.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.tag_name
                ? {
                      tag_name: {
                          label: filterValuesConfig.tag_name.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("shared.search_and_select_tags")}
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                                  onSearch={(keyword) => handleSearchTags(keyword)}
                                  onPopupScroll={(e) => handleScroll(e)}
                                  notFoundContent={
                                      filterInfo.isFetchingTags === true ? (
                                          <i>
                                              <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                          </i>
                                      ) : filterInfo.isFetchingTags === "searching" ? (
                                          <React.Fragment></React.Fragment>
                                      ) : undefined
                                  }
                                  dropdownClassName="app-select-dropdown"
                                  dropdownRender={(menu) => (
                                      <>
                                          {filterInfo.isFetchingTags === "searching" ? (
                                              <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                  <Spin style={{ marginRight: "10px" }} />{" "}
                                                  {`${t("shared.searching")}...`}
                                              </div>
                                          ) : null}
                                          {menu}
                                      </>
                                  )}
                              >
                                  <>
                                      {filterInfo.tags.map((item, index) => (
                                          <Select.Option key={`tag-${index}`} value={item.name}>
                                              {item.name}
                                          </Select.Option>
                                      ))}
                                      {filterInfo.isLoadingMoreTags === true ? (
                                          <Select.Option key={"load-more-tags"} value={""}>
                                              <Spin
                                                  spinning={filterInfo.isLoadingMoreTags}
                                                  style={{ marginRight: "10px" }}
                                              />
                                              {t("shared.loading")}...
                                          </Select.Option>
                                      ) : null}
                                  </>
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.is_public
                ? {
                      is_public: {
                          label: filterValuesConfig.is_public.label,
                          element: (
                              <Select className="app-select" placeholder={t("shared.select_scope")} allowClear>
                                  {filterValuesConfig.is_public.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`scope${itemIndex}`} value={item}>
                                              {filterValuesConfig.is_public.valuesInfo[item].label}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.is_publish
                ? {
                      is_publish: {
                          label: filterValuesConfig.is_publish.label,
                          element: (
                              <Select className="app-select" placeholder={t("shared.choose_status")} allowClear>
                                  {filterValuesConfig.is_publish.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`scope${itemIndex}`} value={item}>
                                              {filterValuesConfig.is_publish.valuesInfo[item].label}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { xs: 24, sm: 12 },
                      },
                  }
                : {}),
        };
        return _formFields;
    }, [filterInfo]);
    const formFieldGroups = useMemo(() => {
        return [
            {
                formFields: ["user_id", "type", "language_id", "level_id", "tag_name", "is_public", "is_publish"],
                rowProps: {
                    gutter: [24, 12],
                },
            },
        ];
    }, []);

    // API CALL:
    const [loading, dataSource, pagination, fetch, refetch] = useFetch({}, fetchQuestionList);

    // PAGE DATA:
    const scrollRef = useRef(null);
    const timeoutIdGetTags = useRef(null);
    const [values, setValues] = useValues({
        isConfirmModalVisible: false,
        isAddQuestionModalVisible: false,
        selectedQuestion: null,
    });
    const columns = [
        {
            title: t("language.language"),
            dataIndex: "language",
            key: "language",
            width: "auto",
            render: (text, record) => record?.language?.name,
        },
        {
            title: t("shared.level"),
            dataIndex: "level",
            key: "level",
            width: "auto",
            render: (text, record) => record?.level?.name,
        },
        {
            title: t("shared.tags"),
            dataIndex: "tags",
            key: "tags",
            width: "auto",
            render: (text, record) => {
                return (
                    <div className="tags-wrapper">
                        {record?.tags.map((tag) => (
                            <Tooltip key={tag.id} title={tag.name.toUpperCase()} color={tag.color}>
                                <Tag className="app-tag-label" key={tag.id} color={tag.color}>
                                    {tag.name.toUpperCase()}
                                </Tag>
                            </Tooltip>
                        ))}
                    </div>
                );
            },
        },
        {
            title: t("shared.owner"),
            dataIndex: "owner",
            key: "owner",
            width: "auto",
            render: (text, record) => record?.user?.name,
        },
        {
            title: t("q.question"),
            dataIndex: "question",
            key: "question",
            width: "auto",
            render: (text, record) => {
                let htmlStr = record?.question;
                htmlStr = (htmlStr || "").replace(/<img(.*?)>/g, "");
                return (
                    <div className="table-content-format">
                        <span className="text-limit-lines" dangerouslySetInnerHTML={{ __html: htmlStr }}></span>
                    </div>
                );
            },
        },
        {
            title: t("shared.type"),
            dataIndex: "type",
            key: "type",
            width: "auto",
            render: (text, record) => record?.question_type?.name,
        },
        {
            title: t("shared.status"),
            dataIndex: "is_publish",
            key: "is_publish",
            width: "auto",
            render: (text, record) => (record?.is_publish ? t("question_bank.done") : t("question_bank.draft")),
        },
        {
            title: t("shared.public"),
            dataIndex: "public",
            key: "public",
            width: "auto",
            render: (text, record) => {
                return record?.is_public ? t("shared.public") : t("shared.only_me");
            },
        },
        {
            title: t("question.updater"),
            dataIndex: "updater",
            key: "updater",
            width: "auto",
            render: (text, record) => record?.updater?.name,
        },
        {
            title: t("shared.created_at"),
            dataIndex: "created_at",
            key: "created_at",
            width: "auto",
            render: (text, record) => moment(record?.created_at).format(formatDate),
        },
        {
            title: t("table.actions"),
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => {
                const navLink = getNavLinkForDetailPage(record);
                if (record.employee_can_edit) {
                    return (
                        <div className="list-actions">
                            <Tooltip placement="top" title={t("shared.update")}>
                                <CustomButton
                                    className="list-btn"
                                    type="simple"
                                    icon={<EditOutlined />}
                                    linkTo={navLink.status ? navLink.link : undefined}
                                    onClick={() => {
                                        if (navLink.status) {
                                            // return navigate(navLink.link);
                                        } else {
                                            notification.error({ message: navLink.msg });
                                        }
                                    }}
                                />
                            </Tooltip>
                            <Tooltip placement="top" title={t("shared.delete")}>
                                <CustomButton
                                    className="list-btn btn-danger"
                                    type="simple"
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleShowConfirmModal(record)}
                                />
                            </Tooltip>
                        </div>
                    );
                } else {
                    return (
                        <div className="list-actions">
                            <Tooltip placement="top" title={t("question_bank.view")}>
                                <CustomButton
                                    className="list-btn"
                                    type="simple"
                                    icon={<EyeOutlined />}
                                    linkTo={navLink.status ? navLink.link : undefined}
                                    onClick={() => {
                                        if (navLink.status) {
                                            // return navigate(navLink.link);
                                        } else {
                                            notification.error({ message: navLink.msg });
                                        }
                                    }}
                                />
                            </Tooltip>
                            {checkIfActionIsAllowed("question", "clone") === true ? (
                                <Tooltip placement="top" title={t("question_bank.copy")}>
                                    <CustomButton
                                        className="list-btn"
                                        type="simple"
                                        icon={<CopyOutlined />}
                                        onClick={() => handleCloneQuestion(record)}
                                    />
                                </Tooltip>
                            ) : null}
                        </div>
                    );
                }
                // if (checkIf_User_CanEditQuestion("type-2", user, record, record.user)) {
                //     const navLink = getNavLinkForDetailPage(record);
                //     return (
                //         <div className="list-actions">
                //             <Tooltip placement="top" title={t("shared.update")}>
                //                 <CustomButton
                //                     className="list-btn"
                //                     type="simple"
                //                     icon={<EditOutlined />}
                //                     linkTo={navLink.status ? navLink.link : undefined}
                //                     onClick={() => {
                //                         if (navLink.status) {
                //                             // return navigate(navLink.link);
                //                         } else {
                //                             notification.error({ message: navLink.msg });
                //                         }
                //                     }}
                //                 />
                //             </Tooltip>
                //             <Tooltip placement="top" title={t("shared.delete")}>
                //                 <CustomButton
                //                     className="list-btn btn-danger"
                //                     type="simple"
                //                     icon={<DeleteOutlined />}
                //                     onClick={() => handleShowConfirmModal(record)}
                //                 />
                //             </Tooltip>
                //         </div>
                //     );
                // } else {
                //     const navLink = getNavLinkForPreviewPage(record);
                //     return (
                //         <div className="list-actions">
                //             <Tooltip placement="top" title={t("question_bank.view")}>
                //                 <CustomButton
                //                     className="list-btn"
                //                     type="simple"
                //                     icon={<EyeOutlined />}
                //                     linkTo={navLink.status ? navLink.link : undefined}
                //                     onClick={() => {
                //                         if (navLink.status) {
                //                             // return navigate(navLink.link);
                //                         } else {
                //                             notification.error({ message: navLink.msg });
                //                         }
                //                     }}
                //                 />
                //             </Tooltip>
                //             {checkIfActionIsAllowed("question", "clone") === true ? (
                //                 <Tooltip placement="top" title={t("question_bank.copy")}>
                //                     <CustomButton
                //                         className="list-btn"
                //                         type="simple"
                //                         icon={<CopyOutlined />}
                //                         onClick={() => handleCloneQuestion(record)}
                //                     />
                //                 </Tooltip>
                //             ) : null}
                //         </div>
                //     );
                // }
            },
        },
    ];

    const handleUpdateQueryString = (urlQueries = {}) => {
        const sParams = {};
        urlQueries?.p && (sParams.p = urlQueries.p);
        urlQueries?.s && (sParams.s = urlQueries.s);
        urlQueries?.own && (sParams.own = urlQueries.own);
        urlQueries?.type && (sParams.type = urlQueries.type);
        urlQueries?.lang && (sParams.lang = urlQueries.lang);
        urlQueries?.lvl && (sParams.lvl = urlQueries.lvl);
        urlQueries?.tag && (sParams.tag = urlQueries.tag);
        urlQueries?.public && (sParams.public = urlQueries.public);
        urlQueries?.publish && (sParams.publish = urlQueries.publish);
        setUrlSearchParams(sParams);
    };

    const handleFetchDataList = (fetchParams = {}, isReload = true) => {
        const fParams = {
            page: fetchParams.page,
            slug: fetchParams.slug,
            user_id: fetchParams.user_id,
            type: fetchParams.type,
            language_id: fetchParams.language_id,
            level_id: fetchParams.level_id,
            tag_name: fetchParams.tag_name,
            is_public: ["true", "false"].includes(fetchParams.is_public) ? fetchParams.is_public : undefined,
            is_publish: fetchParams.is_publish,
            ...(Object.keys(fixedFetchParams || {})?.length ? fixedFetchParams : {}),
        };
        fetch({ ...fParams }, isReload);
        // Update search box's value:
        if (fParams.slug) {
            setFilterInfo({ inputSearch: fParams.slug });
        }
    };

    const handleSearch = () => {
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            slug: filterInfo.inputSearch,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handlePagination = (page) => {
        const newFilterValues = {
            ...currFilterValues,
            page: page,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleRemoveFilterParam = (e, fieldName) => {
        e.preventDefault();
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            [fieldName]: undefined,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleCloseModalSelectQuestionType = useCallback(() => {
        setValues({ isAddQuestionModalVisible: false });
    }, []);

    const getNavLinkForPreviewPage = (item) => {
        if (item?.type) {
            const questionKey = getQuestionKeyByType(item.type);
            if (questionKey) {
                return {
                    status: true,
                    link: generate("preview_question", { questionKey: questionKey, id: item.id }),
                };
            } else if (item.type === "passage") {
                return {
                    status: true,
                    link: generate("preview_question", { id: item.id }),
                };
            } else {
                return { status: false, msg: "Question key not found!" };
            }
        } else {
            return { status: false, msg: "Question type not found!" };
        }
    };

    const getNavLinkForDetailPage = (item) => {
        if (item?.type) {
            const questionKey = getQuestionKeyByType(item.type);
            if (questionKey) {
                return {
                    status: true,
                    link: generate("update_question", { questionKey: questionKey, id: item.id }),
                };
            } else if (item.type === "passage") {
                return {
                    status: true,
                    link: generate("update_passage", { id: item.id }),
                };
            } else {
                return { status: false, msg: "Question key not found!" };
            }
        } else {
            return { status: false, msg: "Question type not found!" };
        }
    };

    const handleCloneQuestion = (item) => {
        cloneQuestion(item.id).then(({ status, message, id }) => {
            if (status) {
                notification.success({ message: t("message.clone_success") });
                setValues({ selectedQuestion: null, isConfirmModalVisible: false });
                // Handle fetching:
                if (parseInt(currFilterValues.page) === 1) {
                    fetch({ page: 1 }, true);
                } else {
                    handlePagination(1);
                }
                // Animation:
                dispatch(animationId({ name: "question", clone: id }));
                scrollRef.current.scrollIntoView();
            } else {
                notification.error({ message: message || t("message.clone_error") });
            }
        });
    };

    const handleDeleteQuestion = () => {
        const item = values.selectedQuestion;
        deleteQuestion(item.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ selectedQuestion: null, isConfirmModalVisible: false });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleShowConfirmModal = (item) => {
        setValues({ isConfirmModalVisible: true, selectedQuestion: item });
    };

    useEffect(() => {
        dispatch(setCurrentPage("question_bank"));

        fetchLanguages({ slug: "", noPagination: true }).then((res) => {
            setFilterInfo({ languages: res.data });
        });
        fetchLevels({ slug: "", noPagination: true }).then((res) => {
            setFilterInfo({ levels: res.data });
        });
        fetchTagsWithFiltering({ slug: "", page: values.currPageForTags }).then((res) => {
            setFilterInfo({ tags: res.data });
        });
    }, []);

    useEffect(() => {
        if (isWithUrlQueryString) {
            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());
            // Deal with one of these cases:
            // 1. Handle update query string whenever:
            // - The URL query string is empty and it's the first time fetch.
            // 2. Handle fetching whenever:
            // - The URL query string is not empty.
            // - The URL query string is empty but it's not the first time fetch.
            const isUrlQSEmpty = Object.keys(currUrlSearchParams).length === 0;
            const isFirstTimeFetch = filterInfo.isFirstTimeFetchDone === false;
            if (isUrlQSEmpty && isFirstTimeFetch) {
                const newUrlSearchParams = getURLSearchParamsByFilterValues(currFilterValues, filterValuesConfig);
                handleUpdateQueryString(newUrlSearchParams);
            } else {
                if (!isUrlQSEmpty || (isUrlQSEmpty && !isFirstTimeFetch)) {
                    const newFilterValues = getFilterValuesByURLSearchParams(currUrlSearchParams, filterValuesConfig);
                    if (compareObjects(currFilterValues, newFilterValues) === false) {
                        setCurrFilterValues(newFilterValues);
                        setFilterInfo({ ...filterInfo, isFirstTimeFetchDone: true });
                        // Update data list whenever the URL query string is changed:
                        handleFetchDataList(newFilterValues, true);
                    }
                }
            }
        } else {
            // Update data list whenever currFilterValues is changed:
            handleFetchDataList(currFilterValues, true);
        }
    }, [urlSearchParams, currFilterValues]);

    useEffect(() => {
        // Animation happens whenever question is added/updated/cloned:
        if (animate?.name == "question") {
            const rowAnimation = (id, action, timeout = 4000) => {
                setTimeout(() => {
                    const str = "[data-row-key=" + "'" + id + "']";
                    const eln = document.querySelector(str);

                    eln?.classList?.add(`row-${action}-animate`);

                    setTimeout(() => {
                        // setIdRowClone();
                        dispatch(animationId({ name: "", [action]: "" }));
                        eln?.classList?.remove(`row-${action}-animate`);
                    }, timeout);
                }, 200);
            };
            if (animate?.clone) {
                rowAnimation(idRow, "clone");
            }
            if (animate?.add) {
                rowAnimation(idRow, "add");
            }
            if (animate?.update) {
                rowAnimation(idRow, "update", 3000);
            }
        }
    }, [dataSource]);

    return (
        <div className="question-bank">
            {/* <Header backEnable={false} backTitle={t("header.question_bank")} actions={[]} /> */}
            <Breadcrumb
                routes={[
                    {
                        name: t("header.question_bank"),
                    },
                ]}
            />

            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("shared.find_question")}
                            value={filterInfo.inputSearch}
                            onChange={(e) => setFilterInfo({ ...filterInfo, inputSearch: e.target.value })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            onClickIconSearch={handleSearch}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {(!isWithUrlQueryString || filterInfo.isFirstTimeFetchDone) &&
                                    Object.keys(currFilterValues).map((fKey, i) => {
                                        const fValue = currFilterValues[fKey];
                                        const fValueLabel =
                                            filterValuesConfig[fKey]?.valuesInfo?.[fValue]?.label ||
                                            (filterValuesConfig[fKey]?.getValueLabel instanceof Function
                                                ? filterValuesConfig[fKey].getValueLabel(fValue)
                                                : undefined);
                                        if (fValue && fValueLabel) {
                                            return (
                                                <Tag
                                                    className="app-tag"
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterParam(e, fKey)}
                                                >
                                                    {`${fValueLabel ? t(fValueLabel) : ""}`}
                                                </Tag>
                                            );
                                        }
                                        return null;
                                    })}
                            </Space>
                        </div>
                        <Dropdown
                            forceRender
                            visible={filterInfo.isVisible}
                            overlay={
                                <FilterMenu
                                    formLayout={"vertical"}
                                    formFields={formFields}
                                    formFieldGroups={formFieldGroups}
                                    formData={currFilterValues}
                                    willResetForm={filterInfo.isVisible === false}
                                    onCancel={() => {
                                        setFilterInfo({
                                            ...filterInfo,
                                            isVisible: false,
                                        });
                                    }}
                                    onSubmit={(newFilterValues) => {
                                        newFilterValues.page = 1;
                                        if (isWithUrlQueryString) {
                                            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());
                                            const newUrlSearchParams = getURLSearchParamsByFilterValues(
                                                newFilterValues,
                                                filterValuesConfig
                                            );
                                            if (compareObjects(currUrlSearchParams, newUrlSearchParams) === false) {
                                                handleUpdateQueryString(newUrlSearchParams);
                                            }
                                        } else {
                                            setCurrFilterValues(newFilterValues);
                                        }
                                    }}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) => {
                                setFilterInfo({ ...filterInfo, isVisible: val });
                            }}
                        >
                            <div className="filter-button">
                                <CustomButton
                                    type="primary"
                                    icon={<SvgFilter />}
                                    title={t("shared.option_filter")}
                                ></CustomButton>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="filter-toolbar-actions">
                    <CustomButton
                        type="primary"
                        icon={<SvgPencil />}
                        title={t("shared.add_new_question")}
                        onClick={() => setValues({ isAddQuestionModalVisible: true })}
                    ></CustomButton>
                </div>
            </div>

            <div className="table question-bank-table" ref={scrollRef}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    nodata_title={t("shared.no_data")}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: handlePagination,
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                    responsiveOnMobile={true}
                    mobileHeaderColumns={["question", "type", "level"]}
                />
            </div>

            <ModalSelectQuestionType
                visible={values.isAddQuestionModalVisible}
                onCancel={handleCloseModalSelectQuestionType}
            ></ModalSelectQuestionType>

            <PopConfirm
                type="delete"
                visible={values.isConfirmModalVisible}
                onOk={handleDeleteQuestion}
                onCancel={() => setValues({ isConfirmModalVisible: false })}
            />
        </div>
    );
};

QuestionBank.propTypes = {
    isWithUrlQueryString: PropTypes.bool,
    fixedFetchParams: PropTypes.object,
    excludedFilterFields: PropTypes.array,
};

export default QuestionBank;
