// GLOBAL CSS (DON'T CHANGE!):
import "antd/dist/antd.min.css";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "src/assets/styles/index.scss";
import { AuthProvider, RequireAuth, RequireGuest, RequireGuestTarget } from "src/modules/auth";
import LoadingScreen from "src/modules/components/LoadingScreen";
import Entrance from "src/modules/containers/Entrance";
import ExamAssetReupload from "src/modules/containers/ExamAssetReupload";
import ExamCheckIn from "src/modules/containers/ExamCheckIn";
import ExamProctoring from "src/modules/containers/ExamProctoring";
import Authed from "src/modules/containers/Layout/Authed";
import BasicLayout from "src/modules/containers/Layout/BasicLayout";
import Login from "src/modules/containers/LoginV2";
import ForgetPasswordFirstTimePage from "src/modules/containers/LoginV2/components/ForgetPasswordFirstTime/pages/ForgetPasswordFirstTime";
import StudentViewResult from "src/modules/containers/StudentViewResult";
import { LanguageProvider } from "src/modules/lang";

const Root = () => {
    const lang = useSelector((state) => state.general.lang);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/:lang/*"
                    element={
                        <LanguageProvider>
                            <AuthProvider>
                                <ExamProctoring>
                                    <Routes>
                                        <Route
                                            path="/entrance"
                                            element={
                                                <BasicLayout>
                                                    <Entrance />
                                                </BasicLayout>
                                            }
                                        />
                                        <Route
                                            path="/exam-checkin"
                                            element={
                                                <BasicLayout>
                                                    <ExamCheckIn />
                                                </BasicLayout>
                                            }
                                        />
                                        <Route
                                            path="/view-result"
                                            element={
                                                <BasicLayout>
                                                    <StudentViewResult />
                                                </BasicLayout>
                                            }
                                        />
                                        <Route
                                            path="/upload"
                                            element={
                                                <BasicLayout>
                                                    <ExamAssetReupload />
                                                </BasicLayout>
                                            }
                                        />
                                        <Route path="/callback/google" element={<LoadingScreen />} />
                                        <Route path="/callback/facebook" element={<LoadingScreen />} />
                                        <Route
                                            path="/login"
                                            element={
                                                <RequireGuestTarget>
                                                    <RequireGuest>
                                                        <BasicLayout>
                                                            <Login />
                                                        </BasicLayout>
                                                    </RequireGuest>
                                                </RequireGuestTarget>
                                            }
                                        />
                                        <Route
                                            path="/register"
                                            element={
                                                <RequireGuestTarget>
                                                    <RequireGuest>
                                                        <BasicLayout>
                                                            <Login register={true} />
                                                        </BasicLayout>
                                                    </RequireGuest>
                                                </RequireGuestTarget>
                                            }
                                        />
                                        <Route
                                            path="/forget-password"
                                            element={
                                                <RequireGuestTarget>
                                                    <RequireGuest>
                                                        <BasicLayout>
                                                            <Login forget={true} />
                                                        </BasicLayout>
                                                    </RequireGuest>
                                                </RequireGuestTarget>
                                            }
                                        />
                                        <Route
                                            path="/active-user"
                                            element={
                                                <RequireGuestTarget>
                                                    <RequireGuest>
                                                        <BasicLayout>
                                                            <ForgetPasswordFirstTimePage />
                                                        </BasicLayout>
                                                    </RequireGuest>
                                                </RequireGuestTarget>
                                            }
                                        />
                                        <Route
                                            path="/choose-role"
                                            element={
                                                <RequireAuth>
                                                    <BasicLayout>
                                                        <Login role={true} />
                                                    </BasicLayout>
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <RequireAuth>
                                                    <Authed />
                                                </RequireAuth>
                                            }
                                        />
                                    </Routes>
                                </ExamProctoring>
                            </AuthProvider>
                        </LanguageProvider>
                    }
                />
                <Route path="*" element={<Navigate to={`/${lang}`} replace />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Root;
