import { DownOutlined } from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import api from "src/api";
import { logOut } from "src/api/containers/auth";
import { ReactComponent as SvgHelp } from "src/assets/images/app-icons/app-help.svg";
import usFlag from "src/assets/images/language/flag_en.svg";
import vietnamFlag from "src/assets/images/language/flag_vi.svg";
import { ReactComponent as SvgFeedback } from "src/assets/images/nav/feedback.svg";
import { ReactComponent as SvgPowerGradient } from "src/assets/images/nav/power-gradient.svg";
import NoAvatar from "src/assets/images/no-avatar.png";
import configs from "src/configs";
import { useValues } from "src/hooks";
import FeedbackDrawer from "src/modules/components/Feedback/FeedbackDrawer";
import FeedbackModal from "src/modules/components/Feedback/FeedbackModal";
import HelpModal from "src/modules/components/Help/Screens/HelpModal";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { logout } from "src/reducers/auth";
import { changeLang, toggleCollapsed, toggleFeedbackModalVisible } from "src/reducers/general";
import { adminNav, employeeNav, generalNav, studentNav, teacherNav } from "src/routes/navs";
import { getCookie } from "src/utils/helpers";
import ROLES from "src/utils/roles";
import { useRouting } from "src/utils/router";

import "./NavSidebar.scss";

function NavSidebar({ sidebarRef }) {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { generate } = useRouting();
    const navigate = useNavigate();

    const collapsed = useSelector((state) => state.general.collapsed);
    const user = useSelector((state) => state.auth.user);
    const lang = useSelector((state) => state.general.lang);
    const currentPage = useSelector((state) => state.general.page);
    const [values, setValues] = useValues({ selectedKeys: [], countUnreadNotifs: 0 });
    const [subnavActive, setSubnavActive] = useState("");
    const [showSubnav, setShowSubnav] = useState({ name: "", show: false });
    const [showModal, setShowModal] = useState({
        modal: "",
        isVisible: false,
    });
    const accountType = localStorage.getItem("currentAccount");

    const suitableNav = useMemo(() => {
        if (user.role === "student") {
            return studentNav;
        }
        if (user.role === "teacher") {
            return teacherNav;
        }
        if (["employee", "employee_crm"].includes(user.role)) {
            return employeeNav;
        }

        if (user.role === "admin") {
            return adminNav;
        }
        return [];
    }, [user]);

    // Sidebar:
    const handleToggleCollapsed = (e, actionType) => {
        if (actionType === "hide") {
            if (collapsed === false) {
                dispatch(toggleCollapsed(true));
            }
        } else if (actionType === "show") {
            if (collapsed === true) {
                dispatch(toggleCollapsed(false));
            }
        }
    };
    const handleShowSubnav = (name) => {
        setShowSubnav((pre) => ({ name: name, show: !pre.show }));
    };

    // Auth:
    const handleLogOut = () => {
        const currAccType = localStorage.getItem("currentAccount");
        logOut().then((res) => {
            if (res?.status) {
                if (currAccType === "demo") {
                    // Reset the base URL of all API requests:
                    api.defaults.baseURL = configs.API_ENDPOINT;
                    localStorage.removeItem("currentAccount");
                    // Log out:
                    dispatch(logout());
                } else {
                    notification.success({
                        message: t("message.logout_success"),
                    });
                    // Log out:
                    dispatch(logout());
                }
            }
        });
    };
    const handleChangeAccountType = () => {
        const currAccType = localStorage.getItem("currentAccount");
        if (currAccType === "demo") {
            // Set up an action to be handled after the user is logged out:
            const nextAct = { action: "login-main-account" };
            localStorage.setItem("nextAction", JSON.stringify(nextAct));
            // Log out:
            dispatch(logout());
        } else {
            // Store the current account before logging with demo account:
            const token = localStorage.getItem("token") || getCookie("token");
            localStorage.setItem("previousAccount", JSON.stringify({ user, token }));
            // Set up an action to be handled after the user is logged out:
            const nextAct = { action: "login-demo-account" };
            localStorage.setItem("nextAction", JSON.stringify(nextAct));
            // Log out:
            dispatch(logout());
        }
    };

    // Modal:
    const handleShowModal = (modalName, modalVisible) => {
        setShowModal({
            modal: modalName,
            isVisible: modalVisible,
        });
    };
    const handleCancelModal = () => {
        setShowModal({
            modal: "",
            isVisible: false,
        });
    };

    // Language:
    const handleChangeLang = (newLang) => {
        dispatch(changeLang(newLang));
    };

    useEffect(() => {
        const path = location.pathname.replace(`/${lang}`, "");
        const nav = [...suitableNav, ...generalNav].find((n) => {
            if (n.active instanceof Array) {
                for (let i = 0; i < n.active.length; i++) {
                    const m = n.active[i];
                    if (typeof m.test === "function" && m.test(path)) {
                        return true;
                    } else if (m === path) {
                        return true;
                    }
                }
            }
            return false;
        });
        if (subnavActive && subnavActive !== path) {
            setSubnavActive("");
        }
        if (nav) {
            setValues({ selectedKeys: [nav.name] });
            if (nav?.subnav?.length > 0) {
                for (let v of nav.subnav) {
                    if (v?.active?.includes(path)) {
                        setSubnavActive(v.active[0]);
                    }
                }
            }
        }
    }, [location.pathname]);

    // For render React elements:
    const renderNavItemsBy = (navItemList = []) => {
        return navItemList.map((navItem) => {
            const NavIcon = navItem.icon;
            let navItemClassName = "";
            if (collapsed) {
                navItemClassName = "collapsed";
            }
            if (navItem?.subnav) {
                navItemClassName += " has-subnav";
                if (showSubnav?.name == navItem.name && showSubnav.show) {
                    navItemClassName += " show-subnav";
                }
            }

            return {
                key: `${navItem.name}`,
                title: t(`nav.${navItem.name}`),
                className: navItemClassName,
                label:
                    navItem?.subnav?.length > 0 ? (
                        <div className="nav-menu-item-wrapper has-subnav">
                            <span className="nav-menu-item" onClick={() => handleShowSubnav(navItem.name)}>
                                <span className="nav-icon">
                                    <NavIcon />
                                </span>
                                <span className="item-section">
                                    <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                                    <DownOutlined style={{ color: "#777 !important" }} />
                                </span>
                            </span>
                            {/* <Menu
                                className="app-nav-menu"
                                items={renderNavItemsBy(navItem.subnav)}
                                selectedKeys={values.selectedKeys}
                            /> */}
                            {renderSubnav(navItem.subnav)}
                        </div>
                    ) : (
                        <Link to={generate(navItem.routeName, navItem.routeParams || {})}>
                            <span className="nav-menu-item">
                                <span className="nav-icon">
                                    <NavIcon />
                                </span>
                                <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                            </span>
                        </Link>
                    ),
                // disabled: values.selectedKeys.includes(navItem.name) ? true : false,
                // icon: <NavIcon />,
                // onClick: () => { },
                // onClick: navItem?.subnav?.length > 0 ? () => handleShowSubnav(navItem.name) : undefined,
                // children:  navItem?.subnav?.length > 0 ? renderNavItemsBy(navItem.subnav):undefined,
            };
        });
    };

    const getNavsForTopSection = () => {
        return [
            {
                key: "help",
                title: t(`header.help`),
                label: (
                    <span className="nav-menu-item">
                        <span className="nav-icon">
                            <SvgHelp />
                        </span>
                        <span className="nav-name">{t(`header.help`)}</span>
                    </span>
                ),
                // disabled: false,
                // icon: null,
                onClick: () => handleShowModal("help", true),
            },
        ];
    };

    const getNavsForMidSection = () => {
        return renderNavItemsBy(suitableNav);
    };

    const getNavsForBottomSection = () => {
        return renderNavItemsBy(generalNav);
    };

    const getExtraNavs = () => {
        return [
            {
                key: "feedback",
                title: t(`nav.feedback`),
                label: (
                    <span className="nav-menu-item">
                        <span className="nav-icon" style={{ color: "#4fe3c1" }}>
                            <SvgFeedback />
                        </span>
                        <span className="nav-name">{t(`nav.feedback`)}</span>
                    </span>
                ),
                onClick: () => {
                    if (ROLES.ADMIN_EMPLOYEE.includes(user?.role)) {
                        navigate(generate("feedback"));
                    } else {
                        dispatch(toggleFeedbackModalVisible(true));
                        // handleShowModal("feedback", true);
                    }
                },
            },
            {
                key: "logout",
                title: t(`nav.logout`),
                label: (
                    <span className="nav-menu-item">
                        <span className="nav-icon">
                            <SvgPowerGradient />
                        </span>
                        <span className="nav-name">{t(`nav.logout`)}</span>
                    </span>
                ),
                onClick: handleLogOut,
            },
        ];
    };

    // React elements:
    const renderSubnav = (subnav) => {
        return (
            <div className="subnav">
                {subnav &&
                    subnav.map((nav) => {
                        let active = "";
                        active = subnavActive === nav.active[0] ? "active" : "";
                        return (
                            <div key={nav.key} className={`subnav-item ${active}`}>
                                <Link to={generate(nav.routeName, nav.routeParams || {})}>
                                    <div>
                                        <span className="subnav-icon">{React.createElement(nav.icon)}</span>
                                        <span className="subnav-title">{t(`nav.${nav.name}`)}</span>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
            </div>
        );
    };

    return (
        <div className="sidebar-menu-wrapper">
            <div
                className={`sidebar-menu-overlay${collapsed ? "" : " visible"}`}
                onMouseOver={(e) => handleToggleCollapsed(e, "hide")}
            ></div>
            <div
                className={`sidebar-menu-overlay for-small-device${collapsed ? "" : " visible"}`}
                onClick={(e) => handleToggleCollapsed(e, "hide")}
            ></div>

            <Layout.Sider
                className="sidebar-menu sidebar_scroll scroll_grey"
                collapsible
                collapsed={collapsed}
                collapsedWidth={85}
                width={280}
                onCollapse={(e) => handleToggleCollapsed(e, "show")}
                onMouseOver={(e) => handleToggleCollapsed(e, "show")}
                theme="light"
                defaultCollapsed="fixed"
                ref={sidebarRef}
                trigger={null}
            >
                <div className="sidebar-container">
                    <div className={collapsed ? "avatar" : "avatar avatar-show"}>
                        <span className="avatar-img-wrapper">
                            <span className="avatar-img">
                                <img src={user.avatar || NoAvatar} alt="55x55" />
                            </span>
                        </span>
                        {!collapsed && (
                            <p>
                                <span className="avatar-email"></span>
                                <span className="avatar-welcome">{t("header.say_hi")}</span>
                                <span className="avatar-name">{user.name}</span>
                            </p>
                        )}
                    </div>

                    <div className="sidebar-scrollable-section">
                        <div className="sidebar-section">
                            <Menu
                                className="app-nav-menu"
                                items={[...getNavsForTopSection()]}
                                selectedKeys={values.selectedKeys}
                            />
                        </div>
                        <div className="sidebar-section">
                            <Menu
                                className="app-nav-menu"
                                items={[...getNavsForMidSection()]}
                                selectedKeys={values.selectedKeys}
                            />
                        </div>
                        <div className="sidebar-section">
                            <Menu
                                className="app-nav-menu"
                                items={[...getNavsForBottomSection(), ...getExtraNavs()]}
                                selectedKeys={values.selectedKeys}
                            />
                        </div>
                        <div className="sidebar-section">
                            <div className="language-box">
                                {lang === "en" ? (
                                    <a onClick={() => handleChangeLang("vi")}>
                                        <span className="flag">
                                            <img src={vietnamFlag} />
                                        </span>
                                        <span className="language-text">{t("language.vi")}</span>
                                    </a>
                                ) : (
                                    <a onClick={() => handleChangeLang("en")}>
                                        <span className="flag">
                                            <img src={usFlag} />
                                        </span>
                                        <span className="language-text">{t("language.en")}</span>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout.Sider>

            <PopConfirm
                type="action"
                visible={showModal.modal === "demo_account" && showModal.isVisible}
                onOk={handleChangeAccountType}
                onCancel={handleCancelModal}
                title={`${
                    accountType === "demo"
                        ? t("shared.msg_confirm_action_of_main_account")
                        : t("shared.msg_confirm_action_of_demo_account")
                }?`}
            />

            <HelpModal
                visible={showModal.modal === "help" && showModal.isVisible}
                page={currentPage}
                onChangeVisible={(val) => {
                    if (val) {
                        handleShowModal("help", val);
                    } else {
                        handleCancelModal();
                    }
                }}
            />
        </div>
    );
}

export default NavSidebar;
