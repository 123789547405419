import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import clsx from "clsx";
import "./InputSearch.scss";

const InputSearch = ({
    className,
    displayType,
    placeholder,
    style,
    value,
    // Event handlers:
    onChange,
    onKeyDown,
    onClickIconSearch,
}) => {
    const handleChange = (_e) => {
        if (onChange instanceof Function) {
            onChange(_e);
        }
    };

    return (
        <div className={clsx("app-input-search", className, displayType)} style={style}>
            <Input
                className="app-input has-rd input-search-input"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onKeyDown={onKeyDown}
            />
            <SearchOutlined className="input-search-icon" onClick={onClickIconSearch} />
        </div>
    );
};

export default InputSearch;
