import { pages } from "src/routes/pages";

/**
 * If the page path matches with the pattern, return path info, otherwise return {}.
 * @returns Page information.
 */
export function checkProctoringReportForCurrentPage() {
    let str = window.location.pathname;
    if (str[str.length - 1] === "/") {
        str = str.slice(0, str.length - 1);
    }

    const pattern = /\/([a-z]{2})\/test\/(\w{1,})/;
    const r = str.match(pattern);

    if (r) {
        return {
            page: pages.test.name,
            id: r[2],
        };
    }

    return {};
}
