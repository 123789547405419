import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import InputLatex from "src/modules/components/Input/InputLatex";
import LatexDangerously from "src/modules/components/LatexDangerously";
import { TXT_RPLC_ANS } from "src/modules/containers/QuestionDetail/containers/DetailMathTextDropdown/config";
import parser from "src/utils/parser";

import QuestionContentDetail from "../components/ContentDetail";
import "./MathTextDropdown.scss";

function MathTextDropdown({
    isReadonly = false,
    // Question & answers:
    question = "" /** @param {string} question */,
    answer /** @param {string[]} answer - list of provided answers. */,
    defaultAnswer /** @param {string[]} defaultAnswered - list of selected answers */,
    // Event handlers:
    onChange,
}) {
    const { t } = useTranslation();

    const timeoutIds = useRef({});
    const cb_handleSetAnswered = useRef(() => {});
    const [providedAnswers, setProvidedAnswers] = useState([]);

    const value_mathcharOptionList = ["numbers_only", "numbers_n_basic_symbols"];
    const value_mathcharOptionSelected = "numbers_only";

    const handleChange = (_selectedAnswers) => {
        if (onChange instanceof Function) {
            onChange({ answered: _selectedAnswers });
        }
    };

    const handleSetAnswered = (_answerIndex, _answerText) => {
        if (isNaN(_answerIndex)) {
            return;
        }
        if (providedAnswers.length > _answerIndex) {
            // Set answered:
            const _answerItem = providedAnswers[_answerIndex];
            const _answeredItem = {
                ..._answerItem,
                rhs_operand: {
                    value: _answerText ? `${_answerText}` : undefined,
                    unit: _answerItem.rhs_operand.unit,
                },
            };
            // Update form:
            const providedAnswersClone = [...providedAnswers];
            providedAnswersClone[_answerIndex] = _answeredItem;
            setProvidedAnswers(providedAnswersClone);
            // Trigger onChange prop:
            handleChange(
                providedAnswersClone.map((item) => {
                    const l1 = item.lhs_operand.value || TXT_RPLC_ANS;
                    const l2 = item.lhs_operand.unit || TXT_RPLC_ANS;
                    const r1 = item.rhs_operand.value || TXT_RPLC_ANS;
                    const r2 = item.rhs_operand.unit || TXT_RPLC_ANS;
                    return `${l1} ${l2} = ${r1} ${r2}`;
                })
            );
        }
    };

    /**
     * Side effects:
     */
    useEffect(() => {
        // Preparation:
        const _answer = answer || [];
        const _answered = defaultAnswer || [];
        // Data:
        const _answerList = _answer
            .map((item, itemIndex) => {
                const operandArr = item.split(" ");
                const operandArrAnswered = _answered.length > itemIndex ? _answered[itemIndex].split(" ") : null;
                if (operandArr.length === 5 && operandArr[2] === "=") {
                    return {
                        lhs_operand: {
                            value: `${operandArr[0]}`,
                            unit: `${operandArr[1]}`,
                        },
                        rhs_operand: {
                            value:
                                operandArrAnswered?.length === 5 &&
                                operandArrAnswered[2] === "=" &&
                                operandArrAnswered[3] !== TXT_RPLC_ANS
                                    ? `${operandArrAnswered[3]}`
                                    : null,
                            unit: `${operandArr[4]}`,
                        },
                    };
                } else {
                    return null;
                }
            })
            .filter((item) => !!item);
        // Update values:
        setProvidedAnswers(_answerList);
    }, []);

    useEffect(() => {
        // Renew `handleSetAnswered` to deal with 'InputLatex onChange' closure:
        cb_handleSetAnswered.current = handleSetAnswered;
    }, [providedAnswers]);

    /**
     * Rendering:
     */
    return (
        <div className={`q-mathtextdropdown`}>
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            <div className="q-content-section">
                <div className="view-mathtextdropdown">
                    {providedAnswers.map((item, itemIndex) => {
                        return (
                            <div key={`mathunit-${itemIndex}`} className="view-unitcv">
                                {/* Left-hand side: */}
                                <LatexDangerously innerHTML={parser.generateMathStatic(item?.lhs_operand?.value)} />

                                <LatexDangerously innerHTML={parser.generateMathStatic(item?.lhs_operand?.unit)} />
                                {/* Equal character: */}
                                <span>=</span>

                                {/* Right-hand side: */}
                                <InputLatex
                                    filterOptions={value_mathcharOptionList}
                                    filterOptionSelected={value_mathcharOptionSelected}
                                    placeholder={`${t("q_math_text_dropdown.value")} (${t("question.answer")})`}
                                    value={item?.rhs_operand?.value}
                                    onChange={(ansVal) => {
                                        // 'InputLatex onChange' closure happens here.
                                        // So, we we shouldn't call `handleSetAnswered` directly!
                                        clearTimeout(timeoutIds.current[`f${itemIndex}_l2`]);
                                        timeoutIds.current[`f${itemIndex}_l2`] = setTimeout(() => {
                                            cb_handleSetAnswered.current(itemIndex, ansVal);
                                        }, 200);
                                    }}
                                    className="view-unitcv-blank"
                                />
                                <LatexDangerously innerHTML={parser.generateMathStatic(item?.rhs_operand?.unit)} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default MathTextDropdown;
