import { t } from "i18next";
import { useEffect } from "react";
import MyPhoneticTable from "./components/MyPhoneticTable";
import QuestionContentDetail from "../components/ContentDetail";
import "./PhoneticTable.scss";

const PhoneticTable = (props) => {
    const {
        isReadonly,
        answer, // string[string[]]
        question,
        onChange,
    } = props;

    useEffect(() => {
        // Phonetic table always have the answer
        if (onChange && onChange instanceof Function) {
            onChange({
                answered: ["watched"],
            });
        }
    }, []);

    return (
        <div className="qp_phonetic-table">
            <div className="question_title">{t("q.question")}</div>
            <div className="content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                />
            </div>

            <div className="content-phonetic-table">
                <MyPhoneticTable value={answer} />
            </div>
        </div>
    );
};

export default PhoneticTable;
