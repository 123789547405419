import { CloudUploadOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Spin, Tooltip, notification } from "antd";
import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { uploadExamProctoringAssets } from "src/api/containers/record";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { useValues } from "src/hooks";
import { useAuth } from "src/modules/auth";
import { default as CustomButton } from "src/modules/components/Button";
import FeedbackDrawer from "src/modules/components/Feedback/FeedbackDrawer";
import { pages } from "src/routes/pages";

import { ProctoringContext } from "../ExamProctoring";
import { ScreenCamMicRecorderContext } from "../ExamProctoring/components/ScreenCamMicRecorder";
import "./ExamAsset.scss";

function ExamAsset() {
    const proctoring = useContext(ProctoringContext);
    const recording = useContext(ScreenCamMicRecorderContext);

    const navigate = useNavigate();

    const auth = useAuth();
    const lang = useSelector((state) => state.general.lang);

    const htmlElementProgressRef = useRef(null);
    const htmlElementProgressFulfilledRef = useRef(null);
    const htmlElementProgressPercentRef = useRef(null);

    const [values, setValues] = useValues({
        uploading: false, // Values: true, false.
        uploaded: false, // Values: true, false, "error".
        isModalVisible: false, // Values: "feedback", false.
        redirecting: false,
    });
    const [videoOfScreenRecording, setVideoOfScreenRecording] = useState(null); // abbr: VOSR.

    const recordId = proctoring?.examRecordData?.data?.record_id;
    const isEnabledUpload_VOSR = recordId && recording?.blob && videoOfScreenRecording && values.uploaded !== true;

    const handleExit = () => {
        auth.logout();
        navigate(`${lang}${pages.entrance.routePath}`);
    };

    const handleSubmit = () => {
        if (!isEnabledUpload_VOSR) {
            return;
        }

        /**
         * Create FormData instance, and set the Content-Type header to multipart/form-data.
         * To get the formData values, use:
         * console.log(formData.getAll("files"));
         */
        const formData = new FormData();
        formData.append("file0", videoOfScreenRecording, recording.mediaName);

        /**
         * Config.
         */
        if (htmlElementProgressFulfilledRef.current) {
            htmlElementProgressFulfilledRef.current.style.setProperty("--width", "0");
        }
        const config = {
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (htmlElementProgressRef.current && htmlElementProgressFulfilledRef.current) {
                    const progressWidth = htmlElementProgressRef.current.offsetWidth;
                    const progressFulfilledWidth = (progressWidth * percent) / 100;
                    htmlElementProgressFulfilledRef.current.style.setProperty("--width", `${progressFulfilledWidth}px`);
                    htmlElementProgressPercentRef.current.innerHTML = `${percent}%`;
                }
            },
        };

        setValues({ uploading: true, uploaded: false });
        uploadExamProctoringAssets(recordId, formData, config)
            .then((res) => {
                if (res.status) {
                    setValues({ uploading: false, uploaded: true, redirecting: true });
                    notification.success({
                        message: res.message || t("message.upload_success"),
                    });

                    setTimeout(() => {
                        handleExit();
                    }, 3000);
                } else {
                    setValues({ uploading: false, uploaded: "error" });
                    notification.error({
                        message: res.message || t("message.sth_wrong"),
                    });
                }
            })
            .catch((err) => {
                setValues({ uploading: false, uploaded: "error" });
                notification.error({
                    message: err ? err.toString() : t("message.sth_wrong"),
                });
            });
    };

    const handleToggleFeedback = (value = undefined) => {
        if (value !== undefined) {
            setValues({ isModalVisible: values.isModalVisible ? false : "feedback" });
        } else {
            setValues({ isModalVisible: value });
        }
    };

    useEffect(() => {
        if (recording?.blob && recording?.mediaURL) {
            // File:
            const _file = new File([recording.blob], recording.mediaName, { type: "video/webm" });
            setVideoOfScreenRecording(_file);

            // Download:
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = recording.mediaURL;
            a.target = "_blank";
            a.download = recording.mediaName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else {
            setVideoOfScreenRecording(null);
        }
    }, [recording]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.returnValue = "Cảnh báo những thay đổi của bạn sẽ không được lưu!"; // this text not show in UI, because chrome dissable
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <div className="exam-asset-wrapper">
            <div className="exam-congrat">
                <Alert
                    message={t("exam_asset.exam_complete")}
                    description={t("exam_asset.descr_exam_complete")}
                    type="info"
                    showIcon
                />
            </div>

            <div className="exam-asset">
                <div className="exam-asset-title">
                    <div className="title-icon">
                        <CloudUploadOutlined />
                    </div>
                    <div className="title-content">
                        <div className="content-main">{t("exam_asset.exam_asset")}</div>
                        <div className="content-descr">{t("exam_asset.descr_exam_asset")}</div>
                    </div>
                </div>
                <div className="exam-asset-content">
                    <div className="exam-asset-save">
                        <div className="exam-asset-saveitems">
                            <div className="saveitem">
                                <div className="saveitem-content">
                                    {recording?.mediaURL ? (
                                        <>{recording.mediaName}</>
                                    ) : (
                                        t("exam_asset.descr_exam_asset_maybelost")
                                    )}
                                </div>
                                {recording?.mediaURL && (
                                    <div className="saveitem-actions">
                                        <Tooltip title={t("shared.save")} placement="left">
                                            <CustomButton
                                                type="simple"
                                                icon={<SaveOutlined />}
                                                linkTo={recording.mediaURL}
                                                linkToIsLocal={false}
                                                target="_blank"
                                                download={recording.mediaName}
                                            ></CustomButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="exam-asset-upload-progress">
                        <div ref={htmlElementProgressRef} className="progress-bar">
                            <div
                                ref={htmlElementProgressFulfilledRef}
                                className={`progress-fulfilled ${
                                    values.uploaded === false
                                        ? "pending"
                                        : values.uploaded === true
                                        ? "success"
                                        : "danger"
                                }`}
                            ></div>
                        </div>
                        {(values.uploaded === true || values.uploading) && (
                            <div ref={htmlElementProgressPercentRef} className="progress-percent">
                                0%
                            </div>
                        )}
                    </div>
                    <div className="exam-asset-redirecting">
                        {values.redirecting ? (
                            <>
                                <Spin spinning={true} />
                                {`${t("shared.redirecting")}...`}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="exam-asset-actions">
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<SvgTick />}
                            title={
                                values.uploaded === true
                                    ? t("shared.uploaded")
                                    : values.uploading
                                    ? `${t("shared.uploading")}...`
                                    : t("shared.upload")
                            }
                            disabled={!isEnabledUpload_VOSR || values.uploading}
                            isLoading={values.uploading}
                            onClick={handleSubmit}
                        ></CustomButton>
                        <CustomButton
                            type="ghost"
                            title={t("feedback.feedback")}
                            onClick={handleToggleFeedback}
                        ></CustomButton>
                    </div>
                </div>
            </div>

            <FeedbackDrawer
                visible={values.isModalVisible === "feedback"}
                onOk={handleToggleFeedback}
                onCancel={handleToggleFeedback}
            ></FeedbackDrawer>
        </div>
    );
}

export default ExamAsset;
