import {
    AudioMutedOutlined,
    AudioOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ControlOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";

import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";

import "./MicrophoneCheck.scss";

class VoiceDisplayer {
    constructor(x, y, width, height, color) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.color = color;
    }

    drawByVolume(canvasContext, volume) {
        canvasContext.fillStyle = this.color;
        canvasContext.fillRect(this.x, this.y, volume ? volume * this.width : 0, this.height);
    }
}

class Microphone {
    constructor(onPending, onSuccess, onError) {
        if (onPending instanceof Function) {
            onPending();
        }

        this.initialized = false;

        const constraints = { audio: true };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                this.audioContext = new AudioContext();

                // Convert raw audio data into audio nodes to work with Web Audio API:
                this.microphone = this.audioContext.createMediaStreamSource(stream);

                // Create analyser node, which can be used to expose audio time and frequency data to create visualisations:
                this.analyser = this.audioContext.createAnalyser();
                this.analyser.fftSize = 512; // 2^5, 2^6, ..., 2^15.
                const bufferLength = this.analyser.frequencyBinCount;
                this.dataArray = new Uint8Array(bufferLength);

                this.microphone.connect(this.analyser);

                this.initialized = true;

                if (onSuccess instanceof Function) {
                    onSuccess(this);
                }
            })
            .catch((err) => {
                if (onError instanceof Function) {
                    onError(err);
                }
            });
    }

    convertDataArrayItemValueIntoRateValue(value) {
        /**
         * Convert [0, 255] to [-1, 1]:
         *
         * First, we have:
         * 0     ~ -1
         * 127.5 ~ 0
         * 255   ~ 1
         *
         * To make it easy, we should do:
         * 0     ~ 0
         * 127.5 ~ 1
         * 255   ~ 2
         *
         * Solution:
         * => 0 / 127.5 = 0
         * => 127.5 / 127.5 = 1
         * => 255 / 127.5 = 2
         * So, we devide the number to 127.5 to get rate value of [0, 2]. Then minus 1 to get rate value of [-1, 1].
         */
        return value / 127.5 - 1;
    }

    getSamples() {
        this.analyser.getByteTimeDomainData(this.dataArray);
        const samples = [...this.dataArray].map(this.convertDataArrayItemValueIntoRateValue);
        return samples;
    }

    getVolume() {
        this.analyser.getByteTimeDomainData(this.dataArray);
        const samples = [...this.dataArray].map(this.convertDataArrayItemValueIntoRateValue);

        let sum = 0;
        for (let i = 0; i < samples.length; i++) {
            sum += samples[i] * samples[i];
        }
        let volume = Math.sqrt(sum / samples.length);

        return volume;
    }
}

function MicrophoneCheck({
    disabled = false,
    value, // Values: undefined, "pending", true, false.
    onChange,
}) {
    const htmlElementVoiceRef = useRef(null);
    const htmlElementVoiceCanvasRef = useRef(null);

    const isTriggeredChangeToTrue = useRef(false);

    const microMediaStreamRef = useRef(null);

    const [values, setValues] = useValues({
        loading: false,
        isFirstTime: true,
        // Connection:
        conn_status: false,
        conn_message: "",
        conn_description: "",
        // Volume:
        volume_status: false,
        volume_description: "",
        // Microphone data:
        microphoneData: null,
    });

    const microConnectionStatus = {
        fail: t("exam_checkin.microphone_info.fail"),
        success: t("exam_checkin.microphone_info.success"),
    };

    const microConnectionDescription = {
        connecting: t("exam_checkin.microphone_info.descr_connecting"),
        fail: t("exam_checkin.microphone_info.descr_fail"),
        "NotAllowedError: Permission dismissed": t("exam_checkin.microphone_info.descr_permission_dismissed"),
        "NotAllowedError: Permission denied": t("exam_checkin.microphone_info.descr_permission_denied"),
        say_sth: t("exam_checkin.microphone_info.descr_say_sth"),
    };

    const permissionStatusRef = useRef(null);
    const checkingChangeRef = useRef(() => {});

    const handleChange = (newValue) => {
        if (onChange instanceof Function) {
            onChange(newValue);
        }
    };

    const handleCloseCurrentMicroMediaStream = () => {
        if (microMediaStreamRef.current?.mediaStream instanceof MediaStream) {
            microMediaStreamRef.current.mediaStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        microMediaStreamRef.current = null;
    };

    const handleStartCheckingChange = () => {
        if ("permissions" in navigator) {
            navigator.permissions
                .query({ name: "microphone" })
                .then((permissionStatus) => {
                    permissionStatusRef.current = permissionStatus;
                    checkingChangeRef.current = () => {
                        switch (permissionStatus.state) {
                            case "granted": {
                                break;
                            }
                            case "denied": {
                                setValues({
                                    loading: false,
                                    conn_status: false,
                                    conn_message: microConnectionStatus.fail,
                                    conn_description: microConnectionDescription["NotAllowedError: Permission denied"],
                                    volume_status: false,
                                    volume_description: "",
                                    microphoneData: null,
                                });
                                handleChange(false);
                                break;
                            }
                            case "prompt": {
                                setValues({
                                    loading: false,
                                    conn_status: false,
                                    conn_message: microConnectionStatus.fail,
                                    conn_description:
                                        microConnectionDescription["NotAllowedError: Permission dismissed"],
                                    volume_status: false,
                                    volume_description: "",
                                    microphoneData: null,
                                });
                                handleChange(false);
                                break;
                            }
                            default:
                                break;
                        }
                    };

                    permissionStatus.addEventListener("change", checkingChangeRef.current);
                })
                .catch((err) => {
                    notification.error({
                        message: err.toString(),
                    });
                });
        }
    };

    const handleStopCheckingChange = () => {
        if (permissionStatusRef.current) {
            permissionStatusRef.current.removeEventListener("change", checkingChangeRef.current);
        }
    };

    const handleStartChecking = () => {
        const onPending = () => {
            setValues({
                loading: true,
                isFirstTime: false,
                conn_status: false,
                conn_message: "",
                conn_description: microConnectionDescription.connecting,
                volume_status: false,
                volume_description: "",
                microphoneData: null,
            });
            handleChange("pending");
            handleCloseCurrentMicroMediaStream();
            handleStopCheckingChange();
            isTriggeredChangeToTrue.current = false;
        };
        const onSuccess = (micro) => {
            setValues({
                loading: false,
                conn_status: true,
                conn_message: "",
                conn_description: "",
                volume_status: false,
                volume_description: microConnectionDescription.say_sth,
                microphoneData: micro,
            });
            // handleChange(true); // DO NOT CALL HERE! ONLY CALL IF BOTH CONNECTION AND VOLUME PASSED!
            microMediaStreamRef.current = micro.microphone;

            handleStartCheckingChange();
        };
        const onError = (err) => {
            const errMsg = err.toString();
            setValues({
                loading: false,
                conn_status: false,
                conn_message: microConnectionStatus.fail,
                conn_description: microConnectionDescription[errMsg] || errMsg,
                volume_status: false,
                volume_description: "",
                microphoneData: null,
            });
            handleChange(false);
            handleCloseCurrentMicroMediaStream();
            isTriggeredChangeToTrue.current = false;
        };

        const microphone = new Microphone(onPending, onSuccess, onError);
    };

    const handleClickCheck = () => {
        handleStartChecking();
    };

    useEffect(() => {
        return () => {
            handleCloseCurrentMicroMediaStream();
            handleStopCheckingChange();
        };
    }, []);

    useEffect(() => {
        if (!disabled && !values.loading && values.isFirstTime) {
            handleStartChecking();
        }
    }, [disabled, values.loading, values.isFirstTime]);

    useEffect(() => {
        if (values.conn_status) {
            const canvas = htmlElementVoiceCanvasRef.current;
            const ctx = canvas.getContext("2d");

            canvas.width = htmlElementVoiceRef.current.offsetWidth;
            canvas.height = htmlElementVoiceRef.current.offsetHeight;
            const voiceDisplayer = new VoiceDisplayer(0, 0, canvas.width, canvas.height, "#445cfe");

            const animateVoice = () => {
                if (values.microphoneData) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    const volume = values.microphoneData.getVolume();
                    voiceDisplayer.drawByVolume(ctx, volume);

                    if (!isTriggeredChangeToTrue.current && volume > 0.14) {
                        setValues({
                            conn_message: microConnectionStatus.success,
                            volume_status: true,
                            volume_description: "",
                        });
                        handleChange(true);
                        isTriggeredChangeToTrue.current = true;
                    }
                }
                requestAnimationFrame(animateVoice);
            };
            animateVoice();

            return () => {
                cancelAnimationFrame(animateVoice);
            };
        }
    }, [values.conn_status]);

    return (
        <div className="microphone-check">
            <div className="result-display">
                <div className="micro-icon">{values.conn_status ? <AudioOutlined /> : <AudioMutedOutlined />}</div>
                <div ref={htmlElementVoiceRef} className="voice-check-displayer">
                    <canvas ref={htmlElementVoiceCanvasRef}></canvas>
                </div>
            </div>
            <div className="check-text">
                <div className="check-title">{t("exam_checkin.microphone_check")}</div>
                <div className="check-result-info">
                    {values.conn_message && (
                        <div className="check-result-message">
                            <span>{t("exam_checkin.check_result")}: </span>
                            <span className={"result-tag" + (values.conn_status ? " success" : " danger")}>
                                {values.conn_status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
                                {values.conn_message}
                            </span>
                        </div>
                    )}
                    {values.conn_description && (
                        <div className={"check-result-desctiption" + (values.conn_status ? " success" : " danger")}>
                            {values.conn_description}
                        </div>
                    )}
                    {values.volume_description && (
                        <div className={"check-result-desctiption" + (values.volume_status ? " success" : " danger")}>
                            {values.volume_description}
                        </div>
                    )}
                </div>
            </div>
            <div className="check-actions">
                <CustomButton
                    htmlType="submit"
                    type="ghost"
                    icon={values.isFirstTime ? <ControlOutlined /> : <ReloadOutlined />}
                    title={
                        values.isFirstTime
                            ? t("shared.check")
                            : values.loading
                            ? `${t("shared.checking")}...`
                            : t("shared.try_again")
                    }
                    isLoading={values.loading}
                    isDisabled={disabled}
                    onClick={handleClickCheck}
                ></CustomButton>
            </div>
        </div>
    );
}

export default MicrophoneCheck;
