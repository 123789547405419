import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    secondTotal: 0,
    secondLeft: 0,
    isLoadingClock: false,
    isReadonly: false,
    type: null,
};

export const clockSlice = createSlice({
    name: "clock",
    initialState,
    reducers: {
        ticktacIncrease: (state, action) => {
            state.secondLeft = state.secondLeft + 1;
        },
        ticktacDecrease: (state, action) => {
            state.secondLeft = state.secondLeft - 1;

            if (state.secondLeft <= 0) {
                state.isReadonly = true;
            }
        },
        initialClock: (state, action) => {
            state.secondTotal = action.payload.secondTotal;
            state.secondLeft = Math.floor(action.payload.secondLeft);
            state.isReadonly = false;
            state.type = action.payload.type;
        },
        setIsLoadingClock: (state, action) => {
            state.isLoadingClock = action.payload;
        },
    },
});

export const { ticktacIncrease, ticktacDecrease, initialClock, setIsLoadingClock } = clockSlice.actions;

export default clockSlice.reducer;
