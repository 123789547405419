import React from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import "./HighlightImage.scss";
import DrawingImage from "../../DrawingImage";

function HighlightImage(props) {
    const {
        isReadonly = false,
        id,
        /**  Type of defaultAnswer
         *   @param {String} defaultAnswer: student's answer
         */
        defaultAnswer,
        question = "", // tiêu đề câu hỏi
        answer = [], //nội dung câu hỏi
        type,
        onChange = () => {},
    } = props;

    const { t } = useTranslation();

    return (
        <div className="q-writing q-highlight-image">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question || "",
                    }}
                />
            </div>

            <div className="view-highlight-image">
                <DrawingImage
                    isReadonly={isReadonly}
                    onChange={onChange}
                    qestionData={{
                        defaultAnswer,
                        question, // tiêu đề câu hỏi
                        answer, //nội dung câu hỏi
                        type,
                    }}
                />
            </div>
        </div>
    );
}

export default HighlightImage;
