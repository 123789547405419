const { Steps } = require("antd");

function RenderSteps({ steps, ...rest }) {
    return (
        <Steps direction="vertical" current={2}>
            {steps?.map((item, idx) => {
                return <Steps.Step key={idx} title={item?.title} icon={item?.icon} status={item?.status} {...rest} />;
            })}
        </Steps>
    );
}

export default RenderSteps;
