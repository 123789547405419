import { useEffect, useRef } from "react";

const useRestrictCopyPaste = ({ actions, condition, eventhandler, window = window }) => {
    const isAddedEvents = useRef(false);

    useEffect(() => {
        if (condition) {
            actions?.forEach((action) => {
                action && !isAddedEvents.current && window.addEventListener(action, preventPaste);
            });
            isAddedEvents.current = true;
        }

        return () => {
            actions.forEach((action) => {
                action && isAddedEvents.current && window.removeEventListener(action, preventPaste);
            });
        };
    }, [window, actions, condition]);

    const preventPaste = (e) => {
        eventhandler?.();
        e.preventDefault();
        return false;
    };
};

export default useRestrictCopyPaste;
