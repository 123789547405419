import Button from "../Button";
import {
    Group8395,
    Group8396,
    Group8394,
    Group8393,
    Group8860,
    Group8861,
    Group3378,
    Group8862,
} from "../../icons/tab2";
import { Group8397, Group8398, Group8863, Group8864, Path3530, Path3532, Path3531, Group8865 } from "../../icons/tab2";
import {
    Group8866,
    Group8867,
    Group8868,
    Group8869,
    Group8878,
    Group8558,
    Group8559,
    Group8557,
} from "../../icons/tab2";
import { Path3534, Path3535, Path3536, Path3537, Path3538, Path3539, Path3540, Group8870 } from "../../icons/tab2";
import { Path3541, Group8871, Group8872, Group8873, Group8874, Group8876, Group8877, Path3512 } from "../../icons/tab2";
import { Path3548, Path3549, acre, bytes, mb, gb, mol, g_ml } from "../../icons/tab2";
import { g_mol } from "../../icons/tab2";

const Tab2 = ({ onClick }) => {
    let datas = [
        { icon: Group8395, text: "\\text{mm}^2" },
        { icon: Group8396, text: "\\text{cm}^2" },
        { icon: Group8394, text: "\\text{m}^2" },
        { icon: Group8393, text: "\\text{km}^2" },
        { icon: Group8860, text: "\\text{µg}" },
        { icon: Group8861, text: "\\text{mg}" },
        { icon: Group3378, text: "\\text{g}" },
        { icon: Group8862, text: "\\text{kg}" },

        { icon: Group8397, text: "\\text{m}" },
        { icon: Group8398, text: "\\text{km}" },
        { icon: Group8863, text: "\\text{µs}" },
        { icon: Group8864, text: "\\text{ms}" },
        { icon: Path3530, text: "\\text{s}" },
        { icon: Path3532, text: "\\text{mL}" },
        { icon: Path3531, text: "\\text{L}" },
        { icon: Group8865, text: "\\text{m/s}" },

        { icon: Group8866, text: "\\text{m/}s^2" },
        { icon: Group8867, text: "\\text{mm}^3" },
        { icon: Group8868, text: "\\text{cm}^3" },
        { icon: Group8869, text: "\\text{m}^3" },
        { icon: Group8878, text: "\\text{km}^3" },
        { icon: Group8558, text: "\\text{kg/}m^3" },
        { icon: Group8559, text: "\\text{g/}cm^3" },
        { icon: Group8557, text: "\\text{in}^2" },

        { icon: Path3534, text: "\\text{oz}" },
        { icon: Path3535, text: "\\text{lb}" },
        { icon: Path3536, text: "\\text{in}" },
        { icon: Path3537, text: "\\text{ft}" },
        { icon: Path3538, text: "\\text{mi}" },
        { icon: Path3539, text: "\\text{fl oz}" },
        { icon: Path3540, text: "\\text{pt}" },
        { icon: Group8870, text: "\\text{µm}" },

        { icon: Path3541, text: "\\text{gal}" },
        { icon: Group8871, text: "\\text{mm}" },
        { icon: Group8872, text: "\\text{cm}" },
        { icon: Group8873, text: "\\text{cm/s}" },
        { icon: Group8874, text: "\\text{km/s}" },
        { icon: Group8876, text: "\\text{mi}^2" },
        { icon: Group8877, text: "\\text{ft}^2" },
        { icon: Path3512, text: "\\text{second}" },

        { icon: Path3548, text: "\\text{min}" },
        { icon: Path3549, text: "\\text{hour}" },
        { icon: acre, text: "\\text{acre}" },
        { icon: bytes, text: "\\text{bytes}" },
        { icon: mb, text: "\\text{MB}" },
        { icon: gb, text: "\\text{GB}" },
        { icon: mol, text: "\\text{mol}" },
        { icon: g_ml, text: "\\text{g/mL}" },

        { icon: g_mol, text: "\\text{g/mol}" },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab2;
