import { EditOutlined, EyeOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import { fetchStudentListByAssignmentID, findRecord } from "src/api/containers/record";
import { ReactComponent as ContactIcon } from "src/assets/images/contact.svg";
import { useValues } from "src/hooks";
import useFetch from "src/hooks/useFetch";
import Table from "src/modules/components/Table";
import VideoWithListViolation from "src/modules/components/VideoWithListViolation";
import ViewScoreStudent from "src/modules/containers/ViewScoreStudent";

import "./ModalMarkAssignment.scss";

const ModalMarkAssignment = (props) => {
    const {
        visible = false,
        onCancel = () => {},
        title = "",
        markingAssignmentId = "",
        setIsPersonalLearningProgress = () => {},
        setStudentInfo = () => {},
        hideCandidateInfo = true,
        ...rest
    } = props;

    const [loading, dataSource, pagination, fetch, refetch] = useFetch({ page: 1 }, fetchStudentListByAssignmentID);
    const cloneDataSource = [...dataSource];

    const [isQuestionResultModal, setIsQuestionResultModal] = useState(false);
    const [markingRecord, setMarkingRecord] = useState({});
    const [isMarking, setIsMarking] = useState(false);

    const [values, setValues] = useValues({
        isModalViewVideoRecord: "",
        current_record: null,
    });

    const dateFormat = "DD/MM/YYYY HH:mm:ss";

    const columns = [
        {
            title: t("course.student_name"),
            dataIndex: "name",
            key: "name",
            render: (text, record) => record?.courseUser?.name || record?.user?.name,
        },
        {
            title: t("username"),
            dataIndex: "username",
            key: "username",
            render: (text, record) => record?.courseUser?.username || record?.user?.username || t("unknown"),
        },
        {
            title: t("personal_number"),
            dataIndex: "personal_number",
            key: "personal_number",
            render: (text, record) =>
                record?.courseUser?.personal_number || record?.user?.personal_number || t("unknown"),
        },
        {
            title: t("course.start_time"),
            dataIndex: "start_time",
            key: "start_time",
            render: (text, record) => moment(record?.start_time).format(dateFormat),
        },
        {
            title: t("course.end_time"),
            dataIndex: "end_time",
            key: "end_time",
            render: (text, record) => moment(record?.end_time).format(dateFormat),
        },
        {
            title: t("course.count_correct_sentences"),
            dataIndex: "count_correct_sentences",
            key: "count_correct_sentences",
            render: (text, record) => `${record?.count_correct_sentences || 0}/${record?.count_sentences || 0}`,
        },
        {
            title: t("assignment.score"),
            dataIndex: "score",
            key: "score",
        },
    ];

    const actions = useMemo(() => {
        const _actions = [];
        if (!hideCandidateInfo) {
            _actions.push({
                tooltip: t("question.personal_learning_progress"),
                icon: <ContactIcon />,
                onClick: (record) => {
                    setStudentInfo(record.user);
                    setIsPersonalLearningProgress(true);
                },
            });
        }

        _actions.push({
            tooltip: t("shared.mark"),
            icon: <EditOutlined />,
            onClick: (record) => {
                setMarkingRecord(record);
                setIsMarking(true);
                setIsQuestionResultModal(true);
            },
        });

        _actions.push({
            tooltip: t("question.view"),
            icon: <EyeOutlined />,
            onClick: (record) => {
                setMarkingRecord(record);
                setIsMarking(false);
                setIsQuestionResultModal(true);
            },
        });

        _actions.push({
            tooltip: t("anti_cheating.video_record"),
            icon: <VideoCameraOutlined />,
            onClick: (record) => {
                setValues({ isModalViewVideoRecord: true, current_record: record });
            },
        });

        return _actions;
    }, [hideCandidateInfo]);

    useEffect(() => {
        if (markingAssignmentId) {
            const fetchParams = {
                filter: `assignment_id:${markingAssignmentId}`,
                is_show_user_info: hideCandidateInfo ? 0 : 1,
            };
            fetch(fetchParams, true);
        }
    }, [markingAssignmentId, hideCandidateInfo]);

    // function findRecordOfStudent(record_id) {
    //     setValues({ loadingStudentRecord:true });
    //     findRecord(record_id).then(({ status, data, message }) => {
    //         if (status) {
    //             setValues({ current_record: data,loadingStudentRecord:false });
    //         }
    //     });
    // }

    return (
        <>
            <Modal
                title={title}
                visible={visible}
                footer={null}
                onCancel={onCancel}
                width={"80%"}
                bodyStyle={{ padding: 20 }}
                destroyOnClose
                {...rest}
                className={`add-exam-question-modal modal-mark-assignment-table${
                    props.className ? " " + props.className : ""
                }`}
            >
                <Table
                    className="app-table mark-assignment-table"
                    dataSource={cloneDataSource}
                    columns={columns}
                    actions={actions}
                    loading={loading}
                    pagination={pagination}
                    scroll={{ x: "auto" }}
                    responsiveOnMobile={true}
                    mobileHeaderColumns={["name", "personal_number", "start_time"]}
                />
            </Modal>

            <Modal
                className="add-exam-question-modal modal-mark-assignment"
                visible={isQuestionResultModal}
                onCancel={() => setIsQuestionResultModal(false)}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose
                width={"80%"}
            >
                <ViewScoreStudent
                    markingRecord={{ ...markingRecord }}
                    isMarking={isMarking}
                    closeModal={() => {
                        refetch();
                        setIsQuestionResultModal(false);
                    }}
                    isModalBox={true}
                />
            </Modal>

            {/* {values.isModalViewVideoRecord && ( */}
            <Modal
                className="add-exam-question-modal modal-mark-assignment"
                visible={values.isModalViewVideoRecord}
                onCancel={() => setValues({ isModalViewVideoRecord: false })}
                width={"fit-content"}
                footer={null}
                bodyStyle={{ padding: "20px", height: "74vh", overflowY: "auto" }}
                destroyOnClose
                title={t("anti_cheating.video_record")}
                closable
                style={{ maxWidth: "80vw" }}
            >
                <VideoWithListViolation
                    width="100%"
                    height="100%"
                    videoData={values.current_record?.files?.[0]}
                    recorData={values.current_record}
                />
            </Modal>
            {/* )} */}
        </>
    );
};

export default ModalMarkAssignment;
