import { useEffect } from "react";
import { Form, Input, Modal, Spin, notification } from "antd";
import CustomButton from "src/modules/components/Button";
import { SendingIcon } from "src/utils/drawer";
import { t } from "i18next";
import { useValues } from "src/hooks";
import { createFeedback } from "src/api/containers/feedback";
import { CheckCircleFilled } from "@ant-design/icons";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import "./FeedbackModal.scss";

function FeedbackModal({ visible, className, onCancel = () => {}, ...rest }) {
    const [form] = Form.useForm();

    const [values, setValues] = useValues({
        feedbackStatus: "notsubmit", //notsubmit, submited
        loading: false,
    });

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmit = () => {
        const content = form.getFieldValue("content");
        if (content) {
            if (!values.loading) {
                setValues({
                    loading: true,
                });
                createFeedback({ content }).then(({ status, data, message }) => {
                    if (status) {
                        setValues({
                            loading: false,
                            feedbackStatus: "submited",
                        });
                    } else {
                        notification.warning({ message });
                        setValues({
                            loading: false,
                        });
                    }
                });
            }
        }
    };

    const renderTitle = () => {
        return <div className="feedback-title">{t("feedback.subtitle")}</div>;
    };

    const renderActions = () => {
        return (
            <>
                {values.feedbackStatus === "notsubmit" ? (
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<SendingIcon />}
                        title={t("feedback.send_feedback")}
                        onClick={handleSubmit}
                    />
                ) : (
                    <CustomButton
                        htmlType="button"
                        type="primary"
                        // icon={<SendingIcon />}
                        title={t("shared.close_modal")}
                        onClick={handleCancel}
                    />
                )}
            </>
        );
    };

    useEffect(() => {
        if (values.feedbackStatus !== "notsubmit") {
            setValues({
                feedbackStatus: "notsubmit",
            });
        }
    }, [visible]);

    return (
        <Modal
            centered
            destroyOnClose
            visible={visible}
            title={t("feedback.title")}
            closeIcon={<SvgClose />}
            footer={null}
            className={"app-modal flexible-height type-primary modal-feedback" + (className ? ` ${className}` : "")}
            wrapClassName="modal-graphelemconfig-wrap"
            onOk={handleSubmit}
            onCancel={handleCancel}
            {...rest}
        >
            <Spin spinning={values.loading}>
                {values.feedbackStatus === "notsubmit" ? (
                    <Form
                        form={form}
                        layout="vertical"
                        className="app-form type-primary has-rd"
                        onFinish={handleSubmit}
                    >
                        {renderTitle()}

                        <Form.Item
                            // label={t("shared.message")}
                            name="content"
                            rules={[{ required: true, message: t("message.required") }]}
                        >
                            <Input.TextArea className="app-input has-rd" placeholder={t("shared.message")} rows={8} />
                        </Form.Item>

                        <div className="form-actions">{renderActions()}</div>
                    </Form>
                ) : (
                    <div className="feedback-result">
                        <span className="feedback-result_icon">
                            <CheckCircleFilled />
                        </span>
                        <span className="feedback-result_desc">{t("feedback.thanks")}</span>
                        {renderActions()}
                    </div>
                )}
            </Spin>
        </Modal>
    );
}

export default FeedbackModal;
