import { Col, Form, notification, Row } from "antd";
import clsx from "clsx";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as Bin } from "src/assets/images/bin.svg";
import InputEditor from "src/modules/components/InputEditor";
import "./Answers.scss";

const Answers = (props) => {
    const { value: answer, onChange = () => {}, name = "", match, form } = props;
    const [quantityQuestion, setQuantityQuestion] = useState(0);

    const answeredArr = useMemo(() => {
        return Object.values(match || {})
            .flat()
            .map((aswr) => aswr?.value);
    });

    useEffect(() => {
        setQuantityQuestion(Object.keys(answer || {}).length);
    }, [answer]);

    const handleAddAnswer = () => {
        if (quantityQuestion <= 10) {
            setQuantityQuestion(quantityQuestion + 1);
        }

        // Handle add default value is empty string for new item
        const newListAnswer = [...(answer || [])];
        newListAnswer[answer?.length || 0] = "";
        onChange(newListAnswer);
        // End handle
    };

    const handleDeleteAnswer = (index) => {
        if (quantityQuestion <= 2) {
            notification.error({
                message: t(`message.require_less_than_${name}`),
            });
            return;
        }

        setQuantityQuestion(quantityQuestion - 1);

        // Convert object to array for splice
        const listAnswer = [...answer];
        // End convert object to array for splice

        // Delete index
        listAnswer.splice(index, 1);

        // Convert array to object
        const newListAnswer = {};

        // form.setFieldValue()
        if (name === "groups") {
            const newMatch = match;

            if (newMatch) delete newMatch[`key-${index}`];
            form.setFieldsValue({
                match: newMatch,
            });
        }

        listAnswer.forEach((answerValue, answerIndex) => {
            newListAnswer[answerIndex] = answerValue;
        });

        // End convert array to object
        onChange(listAnswer);
    };

    const onChangeInput = (data, index) => {
        const listAnswer = [...answer];

        if (name === "groups") {
            listAnswer[index] = {
                name: data,
                key: `key-${index}`,
            };
        }

        if (name === "answers") {
            listAnswer[index] = data;
        }

        onChange(listAnswer);
    };

    return (
        <div className="dragdropgroup-answers">
            {/* Button add drag drop answer */}
            <Row gutter={[20, 10]}>
                {quantityQuestion <= 10 && (
                    <Col className="sort-answers-flex">
                        <div className="sort-answers__add ">
                            <button type="button" onClick={handleAddAnswer}>
                                +
                            </button>
                        </div>
                    </Col>
                )}
            </Row>
            {/* End button add drag drop answer */}

            {/* List answer drag drop */}
            <div className="dragdrop-listAnswers">
                {answer?.map((aswr, index) => {
                    const isDisable = answeredArr.includes(index);
                    return (
                        <div className="sort_item" key={index}>
                            <Form.Item
                                label={index + 1}
                                colon={false}
                                name={name === "groups" ? [name, index, "name"] : [name, index]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                        validator: (_, value) => {
                                            if (value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Answer is require!"));
                                        },
                                    },
                                ]}
                            >
                                <InputEditor
                                    disabled={isDisable && name === "answers"}
                                    placeholder={t(`q.please_input_${name}`)}
                                    isSuffix
                                    value={answer}
                                    key={index}
                                    onChange={(data) => onChangeInput(data, index)}
                                    {...((name === "answers" && !isDisable) || name === "groups"
                                        ? {
                                              suffix: (
                                                  <div className={clsx("delete_button")}>
                                                      <Bin onClick={() => handleDeleteAnswer(index)} />
                                                  </div>
                                              ),
                                          }
                                        : {})}
                                />
                            </Form.Item>
                        </div>
                    );
                })}
            </div>
            {/* End list answer drag drop */}
        </div>
    );
};

export default Answers;
