import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, InputNumber, notification, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Editor from "src/modules/components/Editor";
import ImageWithLabels from "../../components/ImageWithLabels";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import { uuid4 } from "@sentry/utils";
import parser from "src/utils/parser";
import configEditor from "src/utils/configEditor";
import "./DetailImageWithLabelsText.scss";

const editorConfigQuestion = configEditor.question();

function DetailImageWithLabelsText({
    form,
    formStates,
    currQType,
    // Question id:
    questionId,
    questionParentId,
    // Fetch:
    extraQuestionFetchParams,
    // Fill in the form:
    isFormFilled,
    onFillFormQuestionData,
    handleFormAction,
}) {
    const { t } = useTranslation();
    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });
    const [dataImage, setDataImage] = useState(undefined); // Values: { id, src, currentWidth, currentHeight }.
    const [dataLabels, setDataLabels] = useState([]);

    const validateFormInDetail = (formData) => {
        // Preparation:
        const lblAnswers = dataLabels;
        // Check image:
        if (!dataImage?.id) {
            notification.error({
                message: `${t("message.warning_require_field")}: "${t("q_imglbls.img_and_lbls")}"`,
            });
            return false;
        }
        // Find duplication:
        let encountered = [];
        for (let i = 0; i < lblAnswers.length; i++) {
            if (encountered.includes(lblAnswers[i].content)) {
                notification.error({
                    message: `${t("message.warning_answer_duplication_found")}: "${lblAnswers[i].content}"`,
                });
                return false;
            } else {
                encountered.push(lblAnswers[i].content);
            }
        }
        return true;
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            const lblAnswersCorrect = dataLabels.map((item) => {
                return item.content || "";
            });
            // Data:
            const _questionData = {
                type: currQType,
                coordinates: dataLabels.map((item) => {
                    return {
                        x: item.left,
                        y: item.top,
                    };
                }),
                answer: lblAnswersCorrect,
                is_require_uppercase: _formData.is_require_uppercase,
            };
            if (dataImage) {
                _questionData.file_id = dataImage.id;
                _questionData.width = dataImage.currentWidth;
                _questionData.height = dataImage.currentHeight;
            }
            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                correct_answer: _questionData.answer,
                answer: [], // Must be an empty array!
                // Special fields:
                type: _questionData.type,
                coordinates: _questionData.coordinates,
                file: { src: dataImage?.src },
                width: _questionData.width,
                height: _questionData.height,
                is_require_uppercase: _questionData.is_require_uppercase,
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    if (validateFormInDetail(fValues) === true) {
                                        resolve(prepareFormDataForAction(true));
                                    }
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const handleChangeImage = (_image) => {
        setDataImage(_image);
    };

    const handleChangeLabels = (_labels) => {
        setDataLabels(_labels);
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            // setDataImage(formStates.extraQuestionData?.dataImage);
            // setDataLabels(formStates.extraQuestionData?.dataLabels || []);
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            // Preparation:
                            const localLabels = (qData?.coordinates || []).map((item, index) => {
                                return {
                                    _local_id: uuid4(),
                                    left: item.x,
                                    top: item.y,
                                    content: qData?.answer[index],
                                };
                            });
                            const localAnswersCorrect = localLabels.map((item) => {
                                return {
                                    label_local_id: item._local_id,
                                    content: item.content,
                                };
                            });

                            // Set form data:
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "file_id", "src", "coordinates", "width", "height", "answer", "is_require_uppercase".
                                is_require_uppercase: qData?.is_require_uppercase || false,
                            };
                            setDataImage({
                                id: qData?.file_id,
                                src: qData?.file?.src,
                                currentWidth: qData?.width,
                                currentHeight: qData?.height,
                            });
                            setDataLabels(localLabels);
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res[1].data;

                            // Preparation:
                            const localLabels = (qData?.coordinates || []).map((item, index) => {
                                return {
                                    _local_id: uuid4(),
                                    left: item.x,
                                    top: item.y,
                                    content: qData?.answer[index],
                                };
                            });
                            const localAnswersCorrect = localLabels.map((item) => {
                                return {
                                    label_local_id: item._local_id,
                                    content: item.content,
                                };
                            });

                            // Set form data:
                            const questionFormData = {
                                question: qData?.question || "",
                                score: qData?.score || undefined,
                                // Special fields: "file_id", "src", "coordinates", "width", "height", "answer", "is_require_uppercase".
                                is_require_uppercase: qData?.is_require_uppercase || false,
                            };
                            setDataImage({
                                id: qData?.file_id,
                                src: qData?.file?.src,
                                currentWidth: qData?.width,
                                currentHeight: qData?.height,
                            });
                            setDataLabels(localLabels);
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData, { dataImage, dataLabels });
        }
    }, [isFormFilled, detailData]);

    return (
        <React.Fragment>
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion}></Editor>
                </Form.Item>
            </Row>

            <Row className="question-imgwithlbls">
                <Typography className="question-imgwithlbls-title">{t("q_imglbls.img_and_lbls")}</Typography>
                <div className="question-formfield-msg">
                    <span className="msg-header">{t("shared.note")}:</span>
                    <span>{t("q_imglbls_dragdrop.msg_set_label_for_image")}.</span>
                </div>
                <ImageWithLabels
                    labelType="text"
                    labelListData={dataLabels}
                    imageData={dataImage}
                    onChangeImage={handleChangeImage}
                    onChangeLabels={handleChangeLabels}
                />
            </Row>

            <Row className="question-formitems question-setcheck">
                <Col>
                    <Form.Item name="is_require_uppercase" valuePropName="checked">
                        <Checkbox className="app-checkbox">{t("question_fillblanks.is_require_uppercase")}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row className="question-formitems">
                <Col>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DetailImageWithLabelsText;
