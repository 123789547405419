/**
 * Get unit to used with MathJS.
 * @param {string} strLatex Unit (Latex string).
 * @returns Unit (MathJS string).
 */
export const getUnitToUsedWithMathJS = (strLatex) => {
    if (strLatex) {
        let strMathjs = strLatex;

        // 1. Replace all unexpected:
        // \text{}:
        strMathjs = strMathjs.replace(/\\text{(.*?)}/g, "$1");
        // \\\s:
        strMathjs = strMathjs.replace(/\\\s/g, " ");

        // 2. Change '&micro;' to 'u':
        strMathjs = strMathjs.replace("&micro;", "u");
        strMathjs = strMathjs.replace("µ", "u");

        // 3.
        return strMathjs;
    }
    return false;
};

/**
 * Get value to used with MathJS.
 * @param {string} strLatex Value (Latex string).
 * @returns Value (MathJS string).
 */
export const getValueToUsedWithMathJS = (strLatex) => {
    if (strLatex) {
        let strMathjs = strLatex;

        // 1. Replace all unexpected:
        // \text{}:
        strMathjs = strMathjs.replace(/\\text{(.*?)}/g, "$1");
        // \\\s:
        strMathjs = strMathjs.replace(/\\\s/g, " ");

        // 2. Convert LaTeX value to MathJS value:
        // \frac{1}{2}:
        strMathjs = strMathjs.replace(/\\frac{(.*?)}{(.*?)}/g, "$1/$2");
        // \sqrt{1}
        strMathjs = strMathjs.replace(/\\sqrt{(.*?)}/g, "sqrt($1)");
        // \sqrt[3]{1}
        strMathjs = strMathjs.replace(/\\sqrt\[3\]{(.*?)}/g, "cbrt($1)");
        // \sqrt[4]{1}
        strMathjs = strMathjs.replace(/\\sqrt\[(.*?)\]{(.*?)}/g, "nthRoot($1, $2)");
        // 2^{2}
        strMathjs = strMathjs.replace(/(.*?)\^{(.*?)}/g, "$1^$2");

        // 3.
        return strMathjs;
    }
    return false;
};
