import { CloudUploadOutlined } from "@ant-design/icons";
import { Form, Progress, Space, Spin, Upload, notification } from "antd";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { uploadFileUsers } from "src/api/containers/course";
import configs from "src/configs";
import { default as CustomButton } from "src/modules/components/Button";
import { getCookie } from "src/utils/helpers";

import "./UploadBtn.scss";

const UploadBtn = ({ userRole, fileName, course_id, refetch }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const uploadFile = async (options) => {
        const { onSuccess, onError, file } = options;

        let fmData = new FormData();

        fmData.append("files", file);
        fmData.append("course_id", course_id);
        setLoading(true);

        uploadFileUsers(userRole, fmData).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("import_user_success") });
                // refetch({}, true);
            } else {
                notification.error({ message: message || t("add_user_fail") });
            }
            setLoading(false);
        });
    };

    const handleDownload = () => {
        const token = getCookie("token") || localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };

        axios
            .get(`${configs.API_ENDPOINT}/manage/${role}/export`, {
                responseType: "blob",
                params,
                headers,
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${fileName}_${moment().format("YYMMDDHHmm")}.xlsx`); //or any other extension
                link.click();
            });
    };

    const handleDownloadSampleFile = () => {
        if (!userRole) {
            return;
        }

        const token = getCookie("token") || localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };

        const _file = {
            student: "import_student",
            teacher: "import_teacher",
        };
        let url = `${configs.API_ENDPOINT}/file/${_file[userRole]}.xlsx`;

        axios
            .get(url, {
                responseType: "blob",
                headers,
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${userRole}_sample_${moment().format("YYMMDDHHmm")}.xlsx`); //or any other extension
                link.click();
            });
    };

    return (
        <div className="button-import-export">
            <div className="button-import">
                <Upload
                    name="file"
                    accept=".xls,.xlsx,.csv"
                    // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    customRequest={uploadFile}
                    className="image-upload-grid"
                    multiple={false}
                    showUploadList={false}
                    maxCount={1}
                >
                    <Spin spinning={loading}>
                        <CustomButton
                            type="ghost"
                            title={t(`batch_import${userRole ? `_${userRole}` : ""}`)}
                            icon={<CloudUploadOutlined />}
                        ></CustomButton>
                    </Spin>
                </Upload>
                <div className="button-import_sub-btn" onClick={handleDownloadSampleFile}>
                    ({t("download_sample_file")})
                </div>
            </div>
        </div>
    );
};

export default UploadBtn;
