export default {
    /**
     * Chuyển các `<math-static>` element từ dạng HTML đầy đủ về dạng HTML ngắn gọn.
     * @param {string} HTML string.
     * @returns HTML string in short.
     */
    compactMathField: (string) => {
        if (!string) {
            return "";
        }

        const parser = new DOMParser();
        const questionElement = parser.parseFromString(string, "text/html");

        questionElement.body.querySelectorAll("math-static").forEach((mathItem) => {
            mathItem.removeAttribute("class");
            mathItem.removeAttribute("contenteditable");
            mathItem.innerHTML = "";
        });

        return questionElement.body.innerHTML || string || "";
    },

    /**
     * Chuyển các `<math-static>` element từ dạng HTML ngắn gọn về dạng HTML phù hợp để sử dụng cho MQ.StaticMath().
     * @param {string} HTML string.
     * @returns HTML string in short.
     */
    parseMathField: (string) => {
        const parser = new DOMParser();
        const questionElement = parser.parseFromString(string, "text/html");

        questionElement.body.querySelectorAll("math-static").forEach((mathItem) => {
            mathItem.innerHTML = mathItem.dataset.latex;
            mathItem.classList.add("latex-math");
            mathItem.setAttribute("contenteditable", false);
        });

        return questionElement.body.innerHTML !== "undefined" ? questionElement.body.innerHTML : string || "";
    },

    /**
     * Generate <math-static> HTML string from an expression.
     * @param {string} strExpr Expression string: Number, LaTeX string.
     */
    generateMathStatic: (strExpr) => {
        return `<math-static data-latex="${strExpr}"></math-static>`;
    },
};
