import { Col, Row } from "antd";
import clsx from "clsx";
import { t } from "i18next";
import React from "react";
import InputEditor from "src/modules/components/InputEditor";
import { Bin } from "src/utils/drawer.js";
import "./Answers.scss";

const MultipleChoiceAnswers = ({ value = [], isMultiple = false, onChange = () => {} }) => {
    const listAnswer = [];

    if (value instanceof Array) {
        value.forEach((item) => {
            listAnswer.push({
                text: item.text || "",
                result: item.result || false,
            });
        });
    }

    const handleChangeResult = (index, newValue) => {
        if (!isMultiple) {
            const hasResult = listAnswer.findIndex((item) => item.result);
            if (hasResult !== -1) {
                listAnswer[hasResult].result = false;
            }
            listAnswer[index].result = newValue;
            onChange(listAnswer);
            return;
        }

        let item = listAnswer[index];
        if (item) {
            item.result = newValue;
            onChange(listAnswer);
        }
    };
    const handleChangeText = (index, newValue) => {
        let item = listAnswer[index];
        if (item) {
            item.text = newValue;
            onChange(listAnswer);
        }
    };
    const handleAddAnswer = () => {
        if (listAnswer.length <= 10) {
            listAnswer.push({
                text: "",
                result: false,
            });
            onChange(listAnswer);
        }
    };

    const handleDeleteAnswer = (index) => {
        let newListAnswer = [...listAnswer];
        newListAnswer.splice(index, 1);
        onChange(newListAnswer);
    };

    return (
        <div className="multi-choice-answers">
            <Row gutter={[20, 10]}>
                {listAnswer.map((value, index) => (
                    <Col key={index} span={12}>
                        <Answer
                            letter={String.fromCharCode(97 + index)}
                            value={value}
                            index={index}
                            handleChangeText={handleChangeText}
                            handleChangeResult={handleChangeResult}
                            handleDeleteAnswer={handleDeleteAnswer}
                        />
                    </Col>
                ))}

                {listAnswer.length <= 10 && (
                    <Col className="multi-choice-answers-flex">
                        <div className="multi-choice-answers__add ">
                            <button type="button" onClick={handleAddAnswer}>
                                +
                            </button>
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};

const Answer = (props) => {
    const {
        letter = "",
        value = { text: "", result: false },
        index = 0,
        handleChangeText = () => {},
        handleChangeResult = () => {},
        handleDeleteAnswer = () => {},
    } = props;

    return (
        <div className={clsx("multi-choice-answer", value.result && "multi-choice-answer-result")}>
            <div className="multi-choice-answer__content">
                <div className="multi-choice-answer__letter" onClick={() => handleChangeResult(index, !value.result)}>
                    {letter}
                </div>

                <div className="multi-choice-answer__text">
                    <InputEditor
                        className="multiple-choice-answer__editor"
                        value={value.text}
                        onChange={(data) => handleChangeText(index, data)}
                        placeholder={t("q.answer_placeholder")}
                    />
                </div>

                <div onClick={() => handleDeleteAnswer(index)} className="multi-choice-answer__delete">
                    <Bin />
                </div>
            </div>
        </div>
    );
};

export default MultipleChoiceAnswers;
