import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import listRoutes from "src/routes";

/**
 *
 * @param {*} role
 * @returns routes[]
 */
function useDefaultRoutes() {
    const [routes, setRoutes] = useState([]);
    const user = useSelector((state) => state.auth?.user);
    const role = user?.role;

    useEffect(() => {
        if (role) {
            const routeByUser = listRoutes.filter((item) => item.role.includes(role));
            setRoutes(routeByUser);
        }
    }, [role]);

    return routes;
}

export default useDefaultRoutes;
