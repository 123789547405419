import React from "react";
import clsx from "clsx";
import { useState } from "react";
import "./ImageWidthSkeleton.scss";

function ImageWidthSkeleton({ width, height, src, imgId, style, className, ...rest }) {
    const [loading, setLoading] = useState(true);
    return (
        <div
            className={clsx({ "image-skeleton-item": true, [className]: true, skeleton: loading })}
            width={width}
            height={height}
            style={style}
        >
            <img
                id={imgId}
                src={src}
                width={width}
                height={height}
                onLoad={() => {
                    setLoading(false);
                }}
                {...rest}
            />
        </div>
    );
}

export default ImageWidthSkeleton;
