import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Answer from "./components/Answer";
import "./DragDropGroup.scss";
import { useMemo } from "react";
import QuestionContentDetail from "../ContentDetail";
import Group from "./components/Group";
import { checkIsObject } from "src/utils/helpers";

function DragDropGroup(props) {
    const {
        question /** @param question: string */,
        /**
         * @interface IgroupHaveKey {
         *      @property key: string
         *      @property name: string
         * }
         *
         * @interface IAnswer {
         *      @property [key-[i]]: string[]
         *      @property [key-[i + 1]]: string[]
         *      @property [key-[i + 2]]: string[]
         *          ......
         *      @property [key-[i + n]]: string[]
         * }
         *
         * @interface IanswerSubmit {
         *      @property correct_answer: @interface IAnswer
         * }
         */

        answer1 /** @param answer1: string[] */,
        answer2 /**  @param answer2: IgroupHaveKey[]  */,
        answered /** @param answered: { match: @interface IanswerSubmit } */,
        correct_answer /** @param correct_answer: @interface IAnswer */,
        is_history /** @param is_history: boolean, use to render history */,
    } = props;

    const groups = useMemo(() => {
        const result = [];

        Object.entries(answered?.match || {}).forEach(([key, answers]) => {
            const groupName = answer2.find((groupItem) => {
                return groupItem.key === key;
            }).name;

            result.push({
                name: groupName,
                answer_submit: answers.map((aswr) => {
                    return {
                        text: aswr,
                        isCorrect: correct_answer[key]?.includes(aswr),
                    };
                }),
            });
        });

        return result;
    }, []);

    const correctGroups = useMemo(() => {
        const result = [];

        Object.values(answer2 || {}).forEach(({ key, name }) => {
            result.push({
                name: name,
                answer_submit: correct_answer[key]?.map((value) => ({ text: value, isCorrect: true })),
            });
        });

        return result;
    }, [answer2, correct_answer]);

    return !is_history ? (
        <div className="q-result q-drag-drop-group">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail isReadonly={true} value={{ question }} />
            </div>

            <div className="q-content-section">
                <h3>{t("score.your_answers")}</h3>
                <div className="qc_selected-answers">
                    {groups?.map((group) => {
                        return (
                            <Group title={group.name} key={group.key} answers={group.answer_submit} isShowIcon={true} />
                        );
                    })}
                </div>
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="qc_correct-answers">
                    {correctGroups?.map((group) => {
                        return <Group title={group.name} key={group.key} answers={group.answer_submit} />;
                    })}
                </div>
            </div>
        </div>
    ) : (
        <div className="drag-drop-group">
            <div className="list-drag_drop_group">
                {correctGroups?.map((group) => {
                    return <Group title={group.name} key={group.key} answers={group.answer_submit} />;
                })}
            </div>
        </div>
    );
}

export default DragDropGroup;
