import Button from "../Button";
import { _2740, _Path3437, _942, _fraction, _993, _sqrt, _2479, _2481 } from "../../icons/tab3";
import { _3484, _3485, _2482, _2483, _2251, _2247, _2249, _2245 } from "../../icons/tab3";
import { _2243, _2248, _8924, _8925, _8923, _8922, _8921, _8920 } from "../../icons/tab3";
import { _8939, _8940, _8941, _8942, _8943, _8944, _3171, _3163 } from "../../icons/tab3";
import { _Path2744, _6101 } from "../../icons/tab3";

const Tab3 = ({ onClick }) => {
    const datas = [
        { icon: _2740, text: "\\degree", keys: ["Left"] },
        { icon: _Path3437, text: "\\pi" },
        { icon: _942, text: "^{ }", keys: ["Left", "Left"] },
        { icon: _fraction, text: "\\frac{ }{ }", keys: ["Left", "Up"] },
        { icon: _993, text: "\\left|\\right|", keys: ["Left"] },
        { icon: _sqrt, text: "\\sqrt{}", keys: ["Left"] },
        { icon: _2479, text: "\\sin\\left(\\right)", keys: ["Left"] },
        { icon: _2481, text: "\\cos\\left(\\right)", keys: ["Left"] },

        { icon: _3484, text: "\\sec\\left(\\right)", keys: ["Left"] },
        { icon: _3485, text: "\\csc\\left(\\right)", keys: ["Left"] },
        { icon: _2482, text: "\\tan\\left(\\right)", keys: ["Left"] },
        { icon: _2483, text: "\\cot\\left(\\right)", keys: ["Left"] },
        { icon: _2251, text: "\\sin^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _2247, text: "\\csc^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _2249, text: "\\sec^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _2245, text: "\\csc^{-1}\\left(\\right)", keys: ["Left"] },

        { icon: _2243, text: "\\tan^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _2248, text: "\\cot^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8924, text: "\\sinh\\left(\\right)", keys: ["Left"] },
        { icon: _8925, text: "\\cosh\\left(\\right)", keys: ["Left"] },
        { icon: _8923, text: "\\sech^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8922, text: "\\csch^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8921, text: "\\tanh^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8920, text: "\\coth^{-1}\\left(\\right)", keys: ["Left"] },

        { icon: _8939, text: "\\sinh^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8940, text: "\\cosh^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8941, text: "\\sech^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8942, text: "\\csch^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8943, text: "\\tanh^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _8944, text: "\\coth^{-1}\\left(\\right)", keys: ["Left"] },
        { icon: _3171, text: "\\perp" },
        { icon: _3163, text: "\\parallel" },

        { icon: _Path2744, text: "\\angle" },
        { icon: _6101, text: "\\text{m}\\angle" },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab3;
