import { Alert, Col, Input, Row, Select, Spin, Tag, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { applyPromoCode, findSubscriptionPlan, purchasePlan } from "src/api/containers/plan";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import Header from "src/modules/components/Header";
import Options, { getOptionInfo } from "src/modules/components/Payment/Options";
import { getCardInfoByField } from "src/modules/components/Payment/settings";
import { useRouting } from "src/utils/router";

import "./Payment.scss";
import PaymentMethods from "./components/PaymentMethods";
import PaymentOrderPanel from "./components/PaymentOrderPanel";
import { checkIfPlanIsInUsed } from "./helpers";

const Payment = () => {
    const { t } = useTranslation();

    // User info:
    const { user } = useSelector((state) => state.auth);
    // Scope info:
    const orgInfo = user?.organization;

    const [loading, setLoading] = useState(false); // "plan", "purchase", true||false.
    const [data, setData] = useValues({
        plan: undefined,
        planIsInUsed: false,
        options: [],
        selectedPlanPriceId: undefined,
        selectedPaymentMethod: undefined,
        selectedPromoCodes: [], // Each item is an object: { code: "", moneyMinus: 0 }.
    });
    const [amount, setAmount] = useValues({
        total: undefined,
        final: undefined,
    });
    const [inputPromoCode, setInputPromoCode] = useState(undefined);
    const { generate } = useRouting();
    const navigate = useNavigate();
    const currOrder = JSON.parse(sessionStorage.getItem("currentOrder")) || { planId: undefined };

    // Type of view.
    // Values: "default", "extend_subscription", "buy_options".
    const [typeOfView, viewTitle, viewDescription] = useMemo(() => {
        let _type = "default";
        let _title = "payment.payment";
        let _desc = "";
        const { is_extend, is_tobuy_opts } = currOrder;
        if (is_extend) {
            _type = "extend_subscription";
            _title = "payment.extend_subscription";
            _desc = `${t("payment.viewtype_extend_desc")} ${data.plan?.name || orgInfo?.plan_type}.`;
        }
        if (is_tobuy_opts) {
            _type = "buy_options";
            _title = "payment.buy_options";
            _desc = `${t("payment.viewtype_opts_desc")} ${data.plan?.name || orgInfo?.plan_type}.`;
        }
        return [_type, _title, _desc];
    }, [currOrder]);

    const showPlanInOrder =
        (!data.planIsInUsed && typeOfView === "default") || (data.planIsInUsed && typeOfView === "extend_subscription");
    const showPlanPrices =
        (!data.planIsInUsed && typeOfView === "default") || (data.planIsInUsed && typeOfView === "extend_subscription");
    const showOptions =
        (!data.planIsInUsed && typeOfView === "default") || (data.planIsInUsed && typeOfView === "buy_options");

    // Get all order items:
    const getOrderItems = () => {
        const items = [];

        // Plan:
        if (showPlanInOrder) {
            if (data.plan?.prices?.length) {
                // Get the selected plan's price:
                for (let i = 0; i < data.plan.prices.length; i++) {
                    if (data.plan.prices[i].id === data.selectedPlanPriceId) {
                        let priceCurr = data.plan.prices[i].current_price || 0;
                        let priceUsual = data.plan.prices[i].original_price || 0;
                        items.push({
                            name: data.plan.name,
                            priceCurrent: priceCurr,
                            priceUsual: priceUsual,
                        });
                        break;
                    }
                }
            }
        }
        // Options:
        for (let i = 0; i < data.options.length; i++) {
            const info = getOptionInfo(data.options[i]);
            items.push({
                name: `(${info.quantity}) ` + info.options.map((itm) => `${t(itm.name)}: ${itm.value}`).join(","),
                priceCurrent: info.finalPrice,
                priceUsual: undefined,
            });
        }

        return items;
    };

    // Calculate total amount of all order items:
    const getTotalAmount = () => {
        let amount = 0;

        // Plan:
        if (showPlanInOrder) {
            if (data.plan?.prices?.length) {
                // Get the selected plan's price:
                for (let i = 0; i < data.plan.prices.length; i++) {
                    if (data.plan.prices[i].id === data.selectedPlanPriceId) {
                        amount += data.plan.prices[i].current_price || 0;
                        break;
                    }
                }
            }
        }
        // Options:
        for (let i = 0; i < data.options.length; i++) {
            const info = getOptionInfo(data.options[i]);
            amount += info.finalPrice || 0;
        }

        return amount;
    };

    const handleAddPromoCode = () => {
        if (!data.plan?.id) {
            notification.error({
                message: `${t("payment.please_select_a_plan")}!`,
            });
            return;
        }
        // if (data.selectedPromoCodes.length > 0) {
        //     notification.error({
        //         message: `${t("payment.cannot_apply_more_promocodes")}!`,
        //     });
        //     return;
        // }
        if (data.selectedPlanPriceId && inputPromoCode) {
            for (let i = 0; i < data.selectedPromoCodes.length; i++) {
                if (inputPromoCode === data.selectedPromoCodes[i].code) {
                    notification.error({
                        message: `${t("payment.this_code_is_already_used")} (${inputPromoCode})`,
                    });
                    return;
                }
            }

            setLoading("add-promo");
            applyPromoCode({
                plan_price_id: data.selectedPlanPriceId,
                promotion_codes: inputPromoCode,
            }).then((res) => {
                if (res.status && res.data) {
                    setInputPromoCode(undefined);
                    setData({
                        ...data,
                        selectedPromoCodes: [
                            ...data.selectedPromoCodes,
                            {
                                code: inputPromoCode,
                                moneyMinus: res.data.money_minus || 0,
                            },
                        ],
                    });
                } else {
                    notification.error({
                        message: res.message || t("payment.err_can_not_apply_promocode"),
                    });
                }
                setLoading(false);
            });
        } else {
            notification.error({
                message: t("payment.please_input_promocode"),
            });
        }
    };

    const handleRemovePromoCode = (promoCode) => {
        const newArr = data.selectedPromoCodes.filter((item) => {
            return item.code !== promoCode;
        });
        setData({
            ...data,
            selectedPromoCodes: newArr,
        });
    };

    const handlePurchasePlan = () => {
        if (!data.plan?.id) {
            notification.error({
                message: `${t("payment.please_select_a_plan")}!`,
            });
            return;
        }
        if (!data.selectedPaymentMethod) {
            notification.error({
                message: `${t("payment.please_select_a_payment_method")}!`,
            });
            return;
        }

        const _optionIds = [];
        for (let i = 0; i < data.options.length; i++) {
            const _opt = data.options[i];
            for (let t = _opt._quantity; t >= 1; t--) {
                _optionIds.push(data.options[i].id);
            }
        }

        setLoading("purchase");
        const paymentInfo = {
            plan_price_id: data.selectedPlanPriceId,
            option_ids: _optionIds?.length ? _optionIds : undefined,
            payment_method: data.selectedPaymentMethod?.type,
            promotion_codes: data.selectedPromoCodes.map((item) => item.code),
        };
        purchasePlan(paymentInfo).then((res) => {
            if (res.status && res.data) {
                if (res.data.url) {
                    sessionStorage.removeItem("currentOrder");
                    setLoading("redirect");
                    window.location.href = res.data.url;
                    return;
                } else if (res.data.plan && res.data.bill) {
                    sessionStorage.removeItem("currentOrder");
                    notification.success({
                        message: res.message || res.success || t("payment.sucess_make_payment_transaction"),
                    });
                    navigate("/");
                }
            } else {
                notification.error({
                    message: res.message || res.success || t("payment.err_can_not_purchase"),
                });
            }
            setLoading(false);
        });
    };

    const handlePurchaseOptions = () => {
        if (!orgInfo?.plan_id) {
            notification.error({
                message: `${t("payment.please_select_a_plan")}!`,
            });
            return;
        }
        if (!data.selectedPaymentMethod) {
            notification.error({
                message: `${t("payment.please_select_a_payment_method")}!`,
            });
            return;
        }

        const _optionIds = [];
        for (let i = 0; i < data.options.length; i++) {
            const _opt = data.options[i];
            for (let t = _opt._quantity; t >= 1; t--) {
                _optionIds.push(data.options[i].id);
            }
        }

        setLoading("purchase");
        const paymentInfo = {
            option_ids: _optionIds?.length ? _optionIds : undefined,
            payment_method: data.selectedPaymentMethod?.type,
            promotion_codes: data.selectedPromoCodes.map((item) => item.code),
            organization_id: orgInfo?.organization_id,
        };
        purchasePlan(paymentInfo).then((res) => {
            if (res.status && res.data) {
                if (res.data.url) {
                    sessionStorage.removeItem("currentOrder");
                    setLoading("redirect");
                    window.location.href = res.data.url;
                    return;
                } else if (res.data.plan && res.data.bill) {
                    sessionStorage.removeItem("currentOrder");
                    notification.success({
                        message: res.message || res.success || t("payment.sucess_make_payment_transaction"),
                    });
                    navigate("/");
                }
            } else {
                notification.error({
                    message: res.message || res.success || t("payment.err_can_not_purchase"),
                });
            }
            setLoading(false);
        });
    };

    const handlePurchase = () => {
        if (data.planIsInUsed) {
            switch (typeOfView) {
                case "extend_subscription": {
                    handlePurchasePlan();
                    break;
                }
                case "buy_options": {
                    handlePurchaseOptions();
                    break;
                }
                default:
                    break;
            }
        } else {
            handlePurchasePlan();
        }
    };

    // Prices:
    const handleChangePlanPrice = (priceId) => {
        setData({
            ...data,
            selectedPlanPriceId: priceId,
            selectedPromoCodes: [],
        });
    };

    // Options:
    const handleChangeOptions = (opts) => {
        setData({
            options: opts,
        });
    };

    useEffect(() => {
        if (currOrder && currOrder.planId) {
            setLoading("plan");
            findSubscriptionPlan(currOrder.planId).then((res) => {
                if (res.status && res.data) {
                    setData({
                        ...data,
                        plan: res.data,
                        planIsInUsed: checkIfPlanIsInUsed(user, res.data),
                        selectedPlanPriceId: currOrder.planPriceId,
                    });
                }
                setLoading(false);
            });
        } else {
            if (loading !== "redirect") {
                navigate(generate("not-found"));
            }
        }
    }, [currOrder.planId, currOrder.planPriceId]);

    useEffect(() => {
        const total = getTotalAmount();
        let final = total;
        for (let i = 0; i < data.selectedPromoCodes.length; i++) {
            final = final - data.selectedPromoCodes[i].moneyMinus || 0;
        }
        if (final < 0) {
            final = 0;
        }
        setAmount({
            ...amount,
            total: total,
            final: final,
        });
    }, [data]);

    if (!currOrder) {
        return null;
    }

    return (
        <div className="payment-wrapper">
            <div className="payment-header">
                <Header backEnable={false} backTitle={t(viewTitle)} showHelp={false} actions={[]} />
            </div>

            <div style={{ marginBottom: "8px" }}>
                <Alert type="info" message={viewDescription} showIcon />
            </div>

            <Row gutter={[30, 30]}>
                <Col xs={24} sm={24} lg={14}>
                    <PaymentMethods
                        onChangePaymentMethod={(val) => {
                            setData({ ...data, selectedPaymentMethod: val });
                        }}
                    />
                </Col>
                <Col xs={24} sm={24} lg={10}>
                    {showPlanPrices && (
                        <div className="promotion-section">
                            <div className="section section-title">{t("payment.select_plan_price")}</div>
                            <Select
                                className="app-select select-plan-price-wrapper"
                                placeholder={t("payment.select_plan_price")}
                                onChange={handleChangePlanPrice}
                                value={data?.selectedPlanPriceId}
                                optionFilterProp="children"
                                showSearch
                                // allowClear
                            >
                                {data?.plan?.prices?.map((item, index) => (
                                    <Select.Option key={`class-${index}`} value={item.id}>
                                        {item.months} {t("price.month")}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {showOptions && <Options onSelectOptions={handleChangeOptions} />}
                    <PaymentOrderPanel
                        isLoading={loading === "plan"}
                        disableActions={loading === "purchase"}
                        orderItems={getOrderItems()}
                        amountCurrent={amount.final}
                        amountUsual={amount.total !== amount.final ? amount.total : undefined}
                        showActions={false}
                    />
                    <div className="payment-actions">
                        <div className="promotion-section">
                            <div className="section section-title">{t("payment.promotion")}</div>
                            {data.selectedPromoCodes.length ? (
                                <div className="section section-applied-codes">
                                    {data.selectedPromoCodes.map((promo, i) => {
                                        return (
                                            <Tag
                                                key={`promo-${promo.code}`}
                                                closable
                                                onClose={() => handleRemovePromoCode(promo.code)}
                                                className="app-tag"
                                            >
                                                {promo.code}
                                            </Tag>
                                        );
                                    })}
                                </div>
                            ) : null}
                            <div className="input-promotion-wrapper">
                                <Input
                                    className="app-input"
                                    placeholder={t("payment.input_promotion_code_or_not")}
                                    value={inputPromoCode}
                                    onChange={(e) => setInputPromoCode(e.target.value)}
                                />
                                <CustomButton
                                    type="ghost"
                                    title={t("shared.apply")}
                                    onClick={handleAddPromoCode}
                                    disabled={loading === "add-promo" || !inputPromoCode}
                                    className="btn-apply-promotion"
                                ></CustomButton>
                            </div>
                        </div>
                        <CustomButton
                            type="primary"
                            icon={
                                loading == "purchase" ? <Spin spinning={true} style={{ display: "flex" }} /> : undefined
                            }
                            title={loading === "redirect" ? t("shared.redirecting") : t("payment.purchase")}
                            onClick={handlePurchase}
                            disabled={["plan", "purchase", "redirect"].includes(loading) || !data.selectedPaymentMethod}
                            className="btn-purchase"
                        ></CustomButton>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Payment;
