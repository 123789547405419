import React, { useRef } from "react";
import Slider from "react-slick";
import { t } from "i18next";
import PrevIcon from "src/assets/images/prevIcon.svg";
import NextIcon from "src/assets/images/nextIcon.svg";
import "./NumberQuestion.scss";

const NextArrow = (props) => {
    const { slider } = props;
    const handleNextPageQuestion = () => {
        slider.current.slickNext();
    };
    return (
        <div onClick={handleNextPageQuestion} className="NumberQuestion_sliderArrow NumberQuestion_sliderArrow--next">
            <img src={NextIcon} />
        </div>
    );
};

const PrevArrow = (props) => {
    const { slider } = props;
    const handlePrevPageQuestion = () => {
        slider.current.slickPrev();
    };
    return (
        <div onClick={handlePrevPageQuestion} className="NumberQuestion_sliderArrow NumberQuestion_sliderArrow--prev">
            <img src={PrevIcon} />
        </div>
    );
};

const NumberQuestion = (props) => {
    const { amount = 0, isSlider = false, listOrderAnswered = [], onChangeQuestionOrder = () => {} } = props;
    const slider = useRef();

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: false,
        draggable: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <NextArrow slider={slider} />,
        prevArrow: <PrevArrow slider={slider} />,
    };

    return (
        <div className="NumberQuestion">
            <div className="NumberQuestion_title">{t("question.question_number_list")}</div>
            {isSlider && (
                <div className="NumberQuestion_list NumberQuestion_list--slider">
                    <Slider ref={slider} {...settings}>
                        {Array(amount)
                            .fill(0)
                            .map((item, index) => {
                                return (
                                    <div key={index}>
                                        <button
                                            onClick={() => onChangeQuestionOrder(index + 1)}
                                            className={
                                                "NumberQuestion_item " +
                                                (listOrderAnswered.includes(index + 1) ? "active" : "")
                                            }
                                        >
                                            {index + 1}
                                        </button>
                                    </div>
                                );
                            })}
                    </Slider>
                </div>
            )}
            {!isSlider && (
                <div className="NumberQuestion_list">
                    {Array(amount)
                        .fill(0)
                        .map((item, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={() => onChangeQuestionOrder(index + 1)}
                                    className={
                                        "NumberQuestion_item " + (listOrderAnswered.includes(index + 1) ? "active" : "")
                                    }
                                >
                                    {index + 1}
                                </button>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default React.memo(NumberQuestion);
