import { t } from "i18next";

import QuestionContentDetail from "../ContentDetail";
import "./Matrix.scss";
import MathAnswerResult from "./components/MathAnswerResult";

const Matrix = (props) => {
    const { question, answered, template_latex, correct_answer } = props;

    return (
        <div className="qc_matrix">
            <QuestionContentDetail
                isReadonly={true}
                value={{
                    question: question,
                }}
            />
            <div className="qc_matrix">
                <div className="matrix-group">
                    <h5 className="title-danger">{t("score.your_answers")}</h5>
                    <MathAnswerResult
                        correct_answer={correct_answer}
                        template_latex={template_latex}
                        value={answered}
                        isShowResult
                    />
                </div>
                <div className="matrix-group">
                    <h5 className="title-success">{t("score.correct_answers")}</h5>
                    <MathAnswerResult
                        correct_answer={correct_answer}
                        template_latex={template_latex}
                        value={correct_answer}
                    />
                </div>
            </div>
        </div>
    );
};

export default Matrix;
