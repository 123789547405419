import api from "..";

/**
 * Create a folder.
 * @param {string} folderName Folder's name.
 * @returns Folder info.
 */
export const createFolder = (data, fetchHeaders = {}) => {
    const { name, parent_id } = data;

    if (!name) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    const headers = {
        ...(fetchHeaders.token ? { Authorization: `Bearer ${fetchHeaders.token}` } : {}),
    };

    return api
        .post(`/file/make_directory`, data, {}, headers)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * Get the list of folders and files.
 * @param {string} parentFolderId Folder's id.
 * @param {object} fetchParams Fetch conditions.
 * @returns List info.
 */
export const listFoldersAndFiles = (parentFolderId, fetchParams = {}, fetchHeaders = {}) => {
    const filters = [];
    fetchParams.type && filters.push(`type:${fetchParams.type}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _file_fields: `${["_id", "parent_id", "name", "type", "src", "size", "user_id", "user_ids"]}`,
        ...(parentFolderId ? { parent_id: parentFolderId } : {}),
        _relations: `${["parent", "childs"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    const headers = {
        ...(fetchHeaders.token ? { Authorization: `Bearer ${fetchHeaders.token}` } : {}),
    };
    return api
        .get("/file/list", params, headers)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

/**
 * Add files to a directory.
 * @param {object} data It contains these properties:
 * - files (binary): List of files to be added.
 * - parent_id (string): Folder's id.
 * @returns Files.
 */
export const addFiles = (data, fetchHeaders = {}) => {
    const headers = {
        ...(fetchHeaders.token ? { Authorization: `Bearer ${fetchHeaders.token}` } : {}),
    };

    return api
        .post(
            `/file/upload_files`,
            data,
            {},
            {
                "Content-Type": "multipart/form-data",
                ...headers,
            }
        )
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * Delete files.
 * @param {array} fileIds List of file ids.
 * @returns Status.
 */
export const deleteFiles = (fileIds = [], fetchHeaders = {}) => {
    if (!fileIds.length) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    const data = {
        ids: fileIds,
    };

    const headers = {
        ...(fetchHeaders.token ? { Authorization: `Bearer ${fetchHeaders.token}` } : {}),
    };

    return api
        .deleteWithConditions(`/file/delete_files`, data, {}, headers)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

/**
 * Move files to a directory.
 * @param {object} data It contains these properties:
 * - child_ids (Array of strings): List of files to be added.
 * - parent_id (string): Folder's id.
 * @returns Status.
 */
export const moveFilesToFolder = (data, fetchHeaders = {}) => {
    const { child_ids, parent_id } = data;

    if (!child_ids?.length) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    const headers = {
        ...(fetchHeaders.token ? { Authorization: `Bearer ${fetchHeaders.token}` } : {}),
    };

    return api
        .post(`/file/move`, data, {}, headers)
        .then(({ status, success }) => ({ status, message: success }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 * Get disk used statistics.
 * @returns List info.
 */
export const getStorageSpaceUsed = (fetchParams) => {
    // const filters = [];
    const params = {
        // _filter: filters.join(";") || undefined,
    };
    return api
        .get("/statistic/data_stored", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: {} }));
};

/**
 * Rename file/folder.
 * @returns Status.
 */
export const renameFile = (fileId, data, fetchHeaders = {}) => {
    const headers = {
        ...(fetchHeaders.token ? { Authorization: `Bearer ${fetchHeaders.token}` } : {}),
    };

    return api
        .put(
            `/file/rename/${fileId}`,
            data,
            {},
            {
                "Content-Type": "multipart/form-data",
                ...headers,
            }
        )
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};
