import { PlusOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Spin, notification } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchLevels } from "src/api/containers/level";
import { createTag, fetchTags } from "src/api/containers/tag";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import ColorPicker from "src/modules/components/ColorPicker";
import Icon from "src/modules/components/Icon";
import { convertHexAToRGBA, convertRGBAToHexA } from "src/utils/helpers";

import "./SettingTopBar.scss";

function SettingTopBar({
    optionListLevel = [],
    optionListTag = [],
    optionListLanguage = [],
    onChangeLevelList = () => {},
    onChangeTagList = () => {},
    handleAddTagPending = () => {},
    handleAddTagFulfilled = () => {},
    onDeleteTag = () => {},
    handleChangeLanguage, //old
    onChangeLang, //new
    form,
}) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const [values, setValues] = useValues({
        isFetchingLevels: false,
        isFetchingTags: false,
    });
    const [inputTag, setInputTag] = useState({
        tagName: "",
        tagColorHex: "",
        tagColorRGBA: {},
    });
    const [isTagSelectOpen, setIsTagSelectOpen] = useState(false);
    const tagSelectRef = useRef(null);
    const timeoutIdGetLevels = useRef(null);
    const timeoutIdGetTags = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const handleAddNewTag = (e) => {
        e.preventDefault();
        if (user.id && inputTag.tagName) {
            handleAddTagPending();
            createTag({ name: inputTag.tagName, color: inputTag.tagColorHex || "", owner_id: user.id }).then((res) => {
                if (res.status && res.id) {
                    const newTagList = [{ id: res.id, name: inputTag.tagName }, ...optionListTag];
                    setInputTag({
                        tagName: "",
                        tagColorHex: "",
                        tagColorRGBA: {},
                    });
                    handleAddTagFulfilled(newTagList);
                    notification.success({
                        message: `${t("tag.add_tag_success")}: "${inputTag.tagName}"`,
                    });
                }
            });
        }
    };

    const handleSearchLevels = (keyword) => {
        clearTimeout(timeoutIdGetLevels.current);
        if (typeof keyword === "string") {
            setValues({ ...values, isFetchingLevels: true });
            onChangeLevelList([]);
            timeoutIdGetLevels.current = setTimeout(() => {
                fetchLevels({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, isFetchingLevels: false });
                        onChangeLevelList(res.data);
                    }
                });
            }, 500);
        }
    };

    const handleSearchTags = (keyword) => {
        clearTimeout(timeoutIdGetTags.current);
        if (typeof keyword === "string") {
            if (!values.isFetchingTags) {
                setValues({ isFetchingTags: true });
            }
            if (optionListTag?.length !== 0) {
                onChangeTagList([]);
            }
            const fetchParams = {
                ...(keyword ? { noPagination: true } : { page: 1 }),
            };
            timeoutIdGetTags.current = setTimeout(() => {
                fetchTags({ slug: keyword, ...fetchParams }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ isFetchingTags: false });
                        onChangeTagList(res.data);
                    }
                });
            }, 500);
        }
    };

    const handleChangeTagColorUsingInput = useCallback(
        (newColor) => {
            if (newColor.length === 4 || newColor.length === 5 || newColor.length == 7 || newColor.length === 9) {
                // Preparation:
                let param = newColor;
                if (param.length === 4) {
                    param = `#${param[1]}${param[1]}${param[2]}${param[2]}${param[3]}${param[3]}ff`;
                }
                if (param.length === 7) {
                    param = `${param}ff`;
                }
                // Handling:
                const newColorInRGBA = convertHexAToRGBA(param);
                setInputTag({
                    ...inputTag,
                    tagColorHex: newColor,
                    tagColorRGBA:
                        {
                            hex: newColor,
                            rgb: { ...newColorInRGBA.rgb, a: newColorInRGBA.rgb?.a?.toFixed(2) || 0 },
                        } || {},
                });
            } else {
                setInputTag({
                    ...inputTag,
                    tagColorHex: newColor,
                    tagColorRGBA: {
                        hex: "#00000000",
                        rgb: { r: 0, g: 0, b: 0, a: 0 },
                    },
                });
            }
        },
        [inputTag]
    );

    const handleSelectLang = (id) => {
        let item = optionListLanguage?.find((item) => item.id === id);

        // console.log(optionListLanguage);
        if (handleChangeLanguage instanceof Function) {
            handleChangeLanguage(item.code);
        }
        if (onChangeLang instanceof Function) {
            onChangeLang(item);
        }
        setSearchParams({ lang: item.code, language_id: id });
    };

    const handleChangeTagColorUsingPicker = useCallback(
        (newColor, from) => {
            if (from === "rgba") {
                const newColorInHex = convertRGBAToHexA(newColor.rgb.r, newColor.rgb.g, newColor.rgb.b, newColor.rgb.a);
                setInputTag({
                    ...inputTag,
                    tagColorHex: newColorInHex || "",
                });
            } else if (from === "hex") {
                if (newColor.hex.length === 5 || newColor.hex.length === 9) {
                    const newColorInRGBA = convertHexAToRGBA(newColor.hex);
                    setInputTag({
                        ...inputTag,
                        tagColorHex: newColorInRGBA.rgb || {},
                    });
                }
            }
        },
        [inputTag]
    );

    const lang = searchParams.get("lang");
    useEffect(() => {
        const lang_id = form.getFieldValue("language_id");
        if (lang_id) {
            if (lang === "undefined") {
                handleSelectLang(lang_id);
            }
        }
    }, [lang]);

    return (
        <>
            <Row className="question-formitems top-bar-setting">
                <Col xs={24} md={8}>
                    <Form.Item
                        name="language_id"
                        label={t("language.language")}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: t("message.required"),
                        //     },
                        // ]}
                    >
                        <Select
                            className="app-select show-arrow has-rd"
                            placeholder={t("language.select_language")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            showArrow={false}
                            onChange={(e) => {
                                handleSelectLang(e);
                            }}
                        >
                            {optionListLanguage?.map((opt, i) => {
                                if (opt) {
                                    return (
                                        <Select.Option key={`lg-${i}`} value={opt.id}>
                                            {opt.name}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                    <Form.Item
                        name="level_id"
                        label={t("shared.level")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <Select
                            className="app-select show-arrow has-rd"
                            placeholder={t("question.select_level")}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            showArrow={false}
                            // onSearch={(keyword) => {
                            //     handleSearchLevels(keyword);
                            // }}
                            // notFoundContent={
                            //     values.isFetchingLevels === true ? (
                            //         <i>
                            //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                            //         </i>
                            //     ) : (
                            //         undefined
                            //     )
                            // }
                        >
                            {optionListLevel.map((opt, i) => {
                                if (opt) {
                                    return (
                                        <Select.Option key={`lvl${i}`} value={opt.id}>
                                            {opt.name}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                    <Form.Item name="tag_ids" label={t("question.tags")}>
                        <Select
                            ref={tagSelectRef}
                            className="app-select show-arrow has-rd set-tags"
                            mode="multiple"
                            placeholder={t("question.select_tags")}
                            optionFilterProp="children"
                            showArrow={false}
                            showSearch
                            allowClear
                            filterOption={(input, option) => {
                                return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
                            }}
                            onSearch={(keyword) => {
                                handleSearchTags(keyword);
                            }}
                            notFoundContent={
                                values.isFetchingTags === true ? (
                                    <i>
                                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                    </i>
                                ) : undefined
                            }
                            open={isTagSelectOpen}
                            onDropdownVisibleChange={(isOpen) => setIsTagSelectOpen(isOpen)}
                            // onFocus={() => setIsTagSelectOpen(true)}
                            // onBlur={() => setIsTagSelectOpen(false)}
                            dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
                            dropdownClassName="set-tags-dropdown"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <div
                                        className="tag-collection-dropdown from-top-bar-setting"
                                        onKeyDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        // onMouseDown={(e) => {
                                        //     // e.preventDefault();
                                        //     e.stopPropagation();
                                        //     e.target.focus();
                                        //     setIsTagSelectOpen(true);
                                        // }}
                                    >
                                        <Divider style={{ margin: "8px 0" }} />
                                        <div className="dropdown-bottom-title">{t("tag.create_tag")}</div>
                                        <div className="app-select-dropdown-input">
                                            <Input
                                                className="app-input has-rd"
                                                placeholder={t("question.input_new_tag")}
                                                value={inputTag.tagName}
                                                onChange={(e) =>
                                                    setInputTag({
                                                        ...inputTag,
                                                        tagName: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="app-select-dropdown-input tag-color">
                                            <Input
                                                className="app-input has-rd"
                                                placeholder={
                                                    t("question.input_new_tag_color") +
                                                    ": #fd0, #fd0f, #ffdd00, #ffdd00ff"
                                                }
                                                value={inputTag.tagColorHex}
                                                onChange={(e) => handleChangeTagColorUsingInput(e.target.value)}
                                            />
                                            <ColorPicker
                                                currColor={
                                                    Object.keys(inputTag.tagColorRGBA).length
                                                        ? inputTag.tagColorRGBA
                                                        : "unset"
                                                }
                                                onChangeColor={(val) => handleChangeTagColorUsingPicker(val, "rgba")}
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </div>
                                        <div className="dropdown-bottom-action-list">
                                            <CustomButton
                                                className="btn-add-tag"
                                                type="ghost"
                                                icon={<PlusOutlined />}
                                                title={t("tag.add_tag")}
                                                onClick={handleAddNewTag}
                                                isDisabled={!inputTag.tagName}
                                            ></CustomButton>
                                        </div>
                                    </div>
                                </>
                            )}
                        >
                            {optionListTag.map((opt, i) => {
                                if (opt) {
                                    //phải có label phía dưới để fix case search tag ko ra
                                    return (
                                        <Select.Option key={`tag${i}`} value={opt.id || opt._id || ""} label={opt.name}>
                                            <div className="tag-container">
                                                <div className="tag-name">{opt.name}</div>
                                                <CustomButton
                                                    className="tag-btn-del"
                                                    type="simple"
                                                    icon={<Icon name="icon-delete" />}
                                                    onClick={(e) => onDeleteTag(e, opt.id || opt._id)}
                                                ></CustomButton>
                                            </div>
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default SettingTopBar;
