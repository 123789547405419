import { Progress, Spin } from "antd";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ticktacDecrease, ticktacIncrease } from "src/reducers/clock";
import "./Clock.scss";

const Clock = (props) => {
    const { secondLeft, secondTotal, isLoadingClock, type } = useSelector((state) => state.clock);
    const { isTitle = false, strokeColor = "0077FF", trailColor = "fff", width = 200, theme = "clock" } = props;
    const intervalId = useRef(null);
    const dispatch = useDispatch();

    const percentSecondLeft = Math.ceil((secondLeft / secondTotal) * 100);

    const pad = (num) => {
        if (num < 100) {
            return ("0" + num).slice(-2);
        }
        return num;
    };

    const mmss = (secs) => {
        var minutes = Math.floor(secs / 60);

        if (secs <= 0) {
            return `00:00`;
        }

        secs = secs % 60;
        return `${pad(minutes)}:${pad(secs)}`;
    };

    const mm = (secs) => {
        const minutes = Math.floor(secs / 60);

        if (minutes <= 0) {
            return `00`;
        }

        return pad(minutes);
    };

    const ss = (secs) => {
        secs = secs % 60;

        if (secs <= 0) {
            return `00`;
        }
        return pad(secs);
    };

    useEffect(() => {
        intervalId.current = setInterval(() => {
            switch (type) {
                case "increase":
                    dispatch(ticktacIncrease());
                    break;
                case "decrease":
                    dispatch(ticktacDecrease());
                    break;
                default:
                    console.log("Type not found");
            }
        }, 1000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, [isLoadingClock]);

    return (
        <div className="ClockCountdown">
            {theme === "clock" && (
                <>
                    {isTitle && <div className="ClockCountdown_title">{t("test.time_remaining")}</div>}
                    {!isLoadingClock && (
                        <Progress
                            type="circle"
                            className="Clock"
                            width={width}
                            percent={percentSecondLeft}
                            status="normal"
                            strokeColor={strokeColor}
                            trailColor={trailColor}
                            format={(percent, successPercent) => {
                                return mmss(secondLeft);
                            }}
                        />
                    )}
                    {isLoadingClock && <Spin size="large" className="Clock" />}
                </>
            )}

            {theme === "eloctronicClock" && (
                <div className="TestHeader_Clock">
                    <div className="TestHeader_ClockPanel">{mm(secondLeft)}</div>
                    <div className="TestHeader_ClockSeparate">:</div>
                    <div className="TestHeader_ClockPanel">{ss(secondLeft)}</div>
                </div>
            )}
        </div>
    );
};

export default Clock;
