import { Spin, notification } from "antd";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getDashboadData } from "src/api/containers/statistic";
import AssignmentImg from "src/assets/images/dashboard/assignments.png";
import ClassImg from "src/assets/images/dashboard/class.png";
import CompetitionsImg from "src/assets/images/dashboard/competitions.png";
import CourseImg from "src/assets/images/dashboard/courses.png";
import QuestionsImg from "src/assets/images/dashboard/questions.png";
import StudentsImg from "src/assets/images/dashboard/students.png";
import TestsImg from "src/assets/images/dashboard/test.png";
import { useValues } from "src/hooks";
import useDefaultRoutes from "src/hooks/useDefaultRoutes";
import Header from "src/modules/components/Header";
import NumberCard from "src/modules/components/NumberCard";
import { formatTimeDuration } from "src/utils/helpers/timer";
import { useRouting } from "src/utils/router";

import "./Dashboard.scss";

const LIST_ITEMS = [
    {
        key: "total_created_question",
        avatar: QuestionsImg,
        color_avt: "#26bf94",
        linkTo: ["question_bank"],
    },
    {
        key: "total_created_exam",
        avatar: TestsImg,
        color_avt: "#ff4d4f",
        linkTo: ["exam_bank"],
    },
    {
        key: "total_private_assignment",
        avatar: AssignmentImg,
        color_avt: "#845adf",
        linkTo: ["assignment"],
    },

    {
        key: "total_student_in_private_assignment",
        avatar: StudentsImg,
        color_avt: "#935edd",
        linkTo: [""],
    },
    {
        key: "total_public_assignment",
        avatar: AssignmentImg,
        color_avt: "#c49c6b",
        linkTo: ["assignment"],
    },
    {
        key: "total_student_in_public_assignment",
        avatar: StudentsImg,
        color_avt: "#5ed267",
        linkTo: [""],
    },
    // {
    //     key: "total_class",
    //     avatar: ClassImg,
    //     color_avt: "#c49c6b",
    //     linkTo: ["classes"],
    // },
    // {
    //     key: "total_student_in_class",
    //     avatar: StudentsImg,
    //     color_avt: "#e791bc",
    //     linkTo: [""],
    // },
];

function Dashboard() {
    console.log(formatTimeDuration(20233));
    const { t } = useTranslation();
    const { generate } = useRouting();
    const user = useSelector((state) => state.auth?.user);

    const [valaues, setValues] = useValues({ dashboardData: {}, loading: false });

    const defaultRoutes = useDefaultRoutes();

    useEffect(() => {
        if (user?.id) {
            setValues({ loading: true });

            getDashboadData()
                .then(({ status, data }) => {
                    if (status) {
                        setValues({ dashboardData: data, loading: false });
                    }
                })
                .catch((err) => {
                    notification.error({ message: t("shared.something_went_wrong") });
                    setValues({ loading: false });
                });
        }
    }, [user]);

    return (
        <div className="dashboard page-padding">
            {/* <Header backEnable={false} backTitle={t("header.dashboard")} actions={[]} /> */}
            <div className="dashboard-container">
                <div className="dashboard-inner">
                    <Spin spinning={valaues.loading}>
                        <div className="dashboard-cards">
                            <div className="list-card">
                                {LIST_ITEMS.map((item, key) => (
                                    <NumberCard
                                        key={item.key}
                                        image={item.avatar}
                                        count={valaues.dashboardData?.[item?.key]}
                                        color_avt={item.color_avt}
                                        linkTo={generate(item.linkTo?.[0])}
                                        isAllowLinkTo={defaultRoutes.some((x) => {
                                            return item.linkTo.includes(x?.name);
                                        })}
                                        titleKey={item.key}
                                    />
                                ))}
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
