import React from "react";

import styles from "./CheckMark.module.scss";

const CHECK_TYPES = {
    HIDE: "HIDE",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
};

/**
 *
 * @param {*} type value: HIDE || SUCCESS || FAILED
 * @returns
 */
const Checkmark = ({ type = CHECK_TYPES.HIDE, text, duration, children, isDisableClick = false }) => {
    const handleClick = (e) => {
        if (isDisableClick) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    return (
        <div className={styles.checkmark} onClick={handleClick}>
            {type !== CHECK_TYPES.HIDE && (
                <div className={styles.checkmarkWrapper}>
                    <div className={styles.checkmarkInner}>
                        <svg className={styles.svgIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle
                                className={`${styles.path} ${styles.circle}`}
                                fill="none"
                                stroke={type === CHECK_TYPES.SUCCESS ? "#73AF55" : "#D06079"}
                                strokeWidth="6"
                                strokeMiterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                            />
                            {type === CHECK_TYPES.SUCCESS ? (
                                <polyline
                                    className={`${styles.path} ${styles.check}`}
                                    fill="none"
                                    stroke="#73AF55"
                                    strokeWidth="6"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    points="100.2,40.2 51.5,88.8 29.8,67.5"
                                />
                            ) : (
                                <g>
                                    <line
                                        className={`${styles.path} ${styles.line}`}
                                        fill="none"
                                        stroke="#D06079"
                                        strokeWidth="6"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        x1="34.4"
                                        y1="37.9"
                                        x2="95.8"
                                        y2="92.3"
                                    />
                                    <line
                                        className={`${styles.path} ${styles.line}`}
                                        fill="none"
                                        stroke="#D06079"
                                        strokeWidth="6"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        x1="95.8"
                                        y1="38"
                                        x2="34.4"
                                        y2="92.2"
                                    />
                                </g>
                            )}
                        </svg>
                        <p className={`${styles.desc} ${type === CHECK_TYPES.SUCCESS ? styles.success : styles.error}`}>
                            {text}
                        </p>
                    </div>
                </div>
            )}
            {children ? children : null}
        </div>
    );
};

export default Checkmark;
