import { Select } from "antd";
import clsx from "clsx";
import parse from "html-react-parser";
import React, { useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as AudioIconPlay } from "src/assets/images/media/audio-speaker-high.svg";
import { ReactComponent as AudioIcon } from "src/assets/images/media/audio-speaker-medium.svg";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import InputEditor from "src/modules/components/InputEditor";

import "./QuestionContentDetail.scss";

function QuestionContentDetail(props) {
    const {
        isReadonly,
        isDisabledSelectedAnswers,
        blankVisibleType = "input", // "input", "select", "drag_drop"
        value, // { question: "", answerList: [] }.
        selectedAnswer,
        handleSelectAnswer = () => {}, // Used for blank (select).
        handleChangeAnswer = () => {}, // Used for blank (input).
        handleChangeDragDrop, // Used for blank (drag drop).
        isShowPinyin,
    } = props;

    const { t } = useTranslation();
    const audioIds = useRef({});
    const audioTimeoutId = useRef(null);
    const [audioSettings, setAudioSettings] = useState({
        state: "stop", // "play", "pause", "stop".
        targetId: "",
    });

    // Just valid when blankVisibleType is drag drop
    const [listAnswer, setListAnswer] = useState(() => {
        if (selectedAnswer && blankVisibleType === "drag_drop") {
            const tempSelectedAnswer = [...selectedAnswer];
            const listAnswerNew = [...value?.answerList] || [];

            removeOnceDuplicateItemInArray(listAnswerNew, tempSelectedAnswer);

            return listAnswerNew;
        }
        return value?.answerList || [];
    });

    const [listAnswered, setListAnswered] = useState(selectedAnswer || []);

    function removeOnceDuplicateItemInArray(arr, duplicateArr) {
        duplicateArr.forEach((danswer, i) => {
            if (danswer) {
                const indexOfAnswer = arr.findIndex((aswr) => {
                    return aswr === danswer;
                });

                arr.splice(indexOfAnswer, 1);
            }
        });
    }

    const handlePauseAllAudioPlaying = () => {
        const audios = document.querySelectorAll("audio");
        audios.forEach(function (audio) {
            audio.pause();
        });
    };

    const handleSetAudio = (audioElementId, audioState) => {
        const audioElem = audioElementId ? document.getElementById(audioElementId) : undefined;
        if (audioElem) {
            clearTimeout(audioTimeoutId.current);
            if (audioState === "play") {
                audioElem.play();
                audioTimeoutId.current = setTimeout(() => {
                    setAudioSettings({ state: "stop", targetId: "" });
                }, Math.ceil(audioElem.duration) * 1000);
            } else if (audioState === "pause") {
                audioElem.pause();
            } else {
                audioElem.pause();
                audioElem.currentTime = 0;
            }
        }
    };

    const handleTogglePlayPauseAudio = (audioId) => {
        const targetId = audioId;
        if (audioSettings.targetId === "" || audioSettings.state !== "play") {
            // PLAY:
            handleSetAudio(targetId, "play");
            setAudioSettings({ state: "play", targetId: targetId });
        } else if (audioSettings.targetId !== targetId && audioSettings.state === "play") {
            // STOP CURRENT AUDIO:
            handleSetAudio(audioSettings.targetId, "stop");
            // PLAY SELECTED AUDIO:
            handleSetAudio(targetId, "play");
            setAudioSettings({ state: "play", targetId: targetId });
        } else {
            // STOP:
            handleSetAudio(targetId, "stop");
            setAudioSettings({ state: "stop", targetId: "" });
        }
    };

    const handleDragEnd = (value) => {
        if (!value.destination) {
            return;
        }

        const destinationNameOfList = value.destination.droppableId.split("-")[0];
        const destinationIndexOfList = +value.destination.droppableId.split("-")[1] || value.destination.index;

        const sourceNameOfList = value.source.droppableId.split("-")[0];
        const sourceIndexOfList = +value.source.droppableId.split("-")[1] || value.source.index;

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        // Case list answer to list answered
        if (sourceNameOfList === "answer" && destinationNameOfList === "answered") {
            let newListAnswered = [];
            let newListAnswer = [];

            // Handle add item in answered list
            if (listAnswered) {
                const result = [...listAnswered];
                result[destinationIndexOfList] = listAnswer[sourceIndexOfList];

                newListAnswered = result;
            }

            setListAnswered(newListAnswered);

            // Handle remove item in listAnswer list
            if (listAnswer) {
                const result = [...listAnswer];
                result.splice(value?.source?.index, 1);

                newListAnswer = result;
            }

            setListAnswer(newListAnswer);

            if (handleChangeDragDrop && handleChangeDragDrop instanceof Function) {
                handleChangeDragDrop(newListAnswered);
            }
        }

        // Case list answered to list answer
        if (sourceNameOfList === "answered" && destinationNameOfList === "answer") {
            let newListAnswered = [];
            let newListAnswer = [];
            // Handle add item in answer list
            if (listAnswer) {
                const result = [...listAnswer];
                result.splice(destinationIndexOfList, 0, listAnswered[sourceIndexOfList]);
                newListAnswer = result;
            }

            setListAnswer(newListAnswer);
            // Handle remove item in listAnswered list
            if (listAnswered) {
                const result = [...listAnswered];

                result[sourceIndexOfList] = undefined;
                // result.splice(sourceIndexOfList, 1);
                // result[sourceIndexOfList] = { title: listAnswered[sourceIndexOfList].title };
                newListAnswered = result;
            }

            setListAnswered(newListAnswered);

            if (handleChangeDragDrop && handleChangeDragDrop instanceof Function) {
                handleChangeDragDrop(newListAnswered);
            }
        }

        // Case list answer to list answer
        if (destinationNameOfList === "answer" && sourceNameOfList == "answer") {
            const result = reorder(listAnswer, sourceIndexOfList, destinationIndexOfList);

            setListAnswer(result);
        }

        // Case list answered to list answered
        if (destinationNameOfList === "answered" && sourceNameOfList == "answered") {
            const result = [...listAnswered];
            const temp = result[sourceIndexOfList]; // Data type
            result[sourceIndexOfList] = undefined;
            result[destinationIndexOfList] = temp;

            setListAnswered(result);

            if (handleChangeDragDrop && handleChangeDragDrop instanceof Function) {
                handleChangeDragDrop(result);
            }
        }
    };

    // Generate component:
    const generateAnswerInput = (inputIndex) => {
        return (
            <InputEditor
                className="app-input"
                disabled={isReadonly}
                placeholder={t("question.input_answer")}
                value={selectedAnswer?.length ? selectedAnswer[inputIndex] : ""}
                onChange={(data) => handleChangeAnswer(inputIndex, data)}
            />
        );
    };

    // Generate component:
    const generateAnswerSelector = (selectorIndex) => {
        return (
            <Select
                className="app-select show-arrow has-rd"
                disabled={isReadonly}
                showSearch
                allowClear
                showArrow={false}
                placeholder={t("question.select_answer")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(inputValue) => handleSelectAnswer(selectorIndex, inputValue)}
                value={
                    selectedAnswer?.length && selectedAnswer[selectorIndex] ? selectedAnswer[selectorIndex] : undefined
                }
                style={{ margin: "4px 0" }}
                popupClassName="select-latex"
                dropdownClassName="app-select-dropdown type-wordwrap"
                dropdownMatchSelectWidth={false}
            >
                {value.answerList?.map((answer, i) => {
                    return (
                        <Select.Option
                            key={`answer${i}`}
                            value={answer}
                            disabled={isDisabledSelectedAnswers && selectedAnswer.includes(answer) ? true : false}
                        >
                            <HTMLDisplayer rootType="span" htmlString={answer} />
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };

    // Generate component:
    const generateAnswerDragDrop = (inputIndex) => {
        return (
            <Droppable isDropDisabled={listAnswered[inputIndex] !== undefined} droppableId={`answered-${inputIndex}`}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            backgroundColor: snapshot.isDraggingOver ? "rgba(0, 119, 255, 0.4)" : "",
                        }}
                        className="fill-blank-drag-drop__box-item"
                    >
                        <div style={{ display: "inline-block" }}>
                            {listAnswered[inputIndex] !== undefined && (
                                <Draggable
                                    draggableId={`answered-${inputIndex}`}
                                    index={inputIndex}
                                    isDragDisabled={isReadonly}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            className={clsx(
                                                "fill-blank-drag-drop__drag-item",
                                                snapshot?.isDragging && "dragging",
                                                isReadonly && "fill-blank-drag-drop__drag-item--disable"
                                            )}
                                        >
                                            <HTMLDisplayer
                                                rootType="span"
                                                htmlString={listAnswered[inputIndex]}
                                                className="fill-blank-drag-drop__drag-item--answer"
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            )}
                        </div>

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    const generateListAnswer = () => {
        return (
            <Droppable isDropDisabled={false} direction="horizontal" droppableId={"answer"}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            backgroundColor: snapshot.isDraggingOver ? "rgba(0, 119, 255, 0.4)" : "",
                        }}
                        className="question-content__list-dragdrop"
                    >
                        {listAnswer?.map((aswr, index) => {
                            return (
                                <Draggable
                                    key={index}
                                    draggableId={`answer-${index}`}
                                    index={index}
                                    isDragDisabled={isReadonly}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            className="fill-blank-drag-drop__drag-item__skin"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <div
                                                className={clsx(
                                                    "fill-blank-drag-drop__drag-item",
                                                    snapshot?.isDragging && "dragging",
                                                    isReadonly && "fill-blank-drag-drop__drag-item--disable"
                                                )}
                                            >
                                                <HTMLDisplayer
                                                    rootType="span"
                                                    htmlString={aswr}
                                                    className="fill-blank-drag-drop__drag-item--answer"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    // Generate component:
    const generateAudioButton = (audioId) => {
        return (
            <span
                className={`audio-icon-button${
                    audioSettings.targetId === audioId && audioSettings.state === "play" ? " audio-play" : ""
                }`}
                onClick={() => {
                    handleTogglePlayPauseAudio(audioId);
                }}
            >
                <span className="audio-icon-wrapper">
                    {audioSettings.targetId === audioId && audioSettings.state === "play" ? (
                        <AudioIconPlay />
                    ) : (
                        <AudioIcon />
                    )}
                </span>
            </span>
        );
    };

    // Generate component:
    const qContent = useMemo(() => {
        if (value.question && typeof value.question === "string") {
            let indexOfSelector = -1; // Used for <fillbox>.
            let audioIconIndex = -1,
                audioIconId = ""; // Used for <audio> (type: icon).
            let audioPlayerIndex = -1,
                audioPlayerId = ""; // Used for <audio> (type: player).

            // Replace <p> tags by <div style="margin-bottom: 1em;"> tags:
            let htmlString = value.question.replace(/<p>/g, `<div class="div-as-p">`);
            htmlString = htmlString.replace(/<p /g, `<div class="div-as-p" `);
            htmlString = htmlString.replace(/<\/p>/g, "</div>");

            // Replace all <fillbox> tag with easy-to-be-identified tag:
            htmlString = htmlString.replace(/<fillbox(.*?)<\/fillbox>/g, '<img class="input-blank"></img>');

            // Remove all invalid:
            // - html-react-parser doesn't accept '!important', so we should remove it or the CSS attribute won't work.
            htmlString = htmlString.replace(/!important/g, "");

            // Convert to valid React elements:
            const elements = parse(htmlString, {
                replace: (domNode) => {
                    // Replace all <img> by Select component from Antd:
                    if (domNode.attribs && domNode.attribs.class === "input-blank") {
                        indexOfSelector++;
                        return (
                            <>
                                {`(${indexOfSelector + 1}) `}

                                {blankVisibleType === "input" && generateAnswerInput(indexOfSelector)}
                                {blankVisibleType === "select" && generateAnswerSelector(indexOfSelector)}
                                {blankVisibleType === "drag_drop" && generateAnswerDragDrop(indexOfSelector)}
                            </>
                        );
                    } else if (domNode.name === "math-static") {
                        return <HTMLDisplayer rootType="span" exprString={domNode.attribs["data-latex"]} />;
                    }

                    // Unset contenteditable:
                    else if (domNode.attribs && domNode.attribs.class === "media-audio-action") {
                        domNode.attribs.contenteditable = undefined;
                    } else if (domNode.attribs && domNode.attribs.class === "mce-preview-object mce-object-audio") {
                        domNode.attribs.contenteditable = undefined;
                    }
                    // Audio icon - Set event handler for <audio>:
                    else if (domNode.attribs && domNode.attribs.class === "media-audio-element--type-player") {
                        // Specify audio ID:
                        audioPlayerIndex++;
                        audioPlayerId =
                            audioIds.current[`audioPlayerId${audioPlayerIndex}`] || `audio-player-elem-${uuidv4()}`;
                        audioIds.current[`audioPlayerId${audioPlayerIndex}`] = audioPlayerId;
                        // Set up audio:
                        domNode.attribs.id = audioPlayerId;
                        domNode.attribs.onPlay = () => {
                            if (audioSettings.targetId === "" || audioSettings.state !== "play") {
                                setAudioSettings({ state: "play", targetId: domNode.attribs.id });
                            } else if (
                                audioSettings.targetId !== domNode.attribs.id &&
                                audioSettings.state === "play"
                            ) {
                                // STOP CURRENT AUDIO:
                                handleSetAudio(audioSettings.targetId, "stop");
                                // PLAY SELECTED AUDIO:
                                setAudioSettings({ state: "play", targetId: domNode.attribs.id });
                            }
                        };
                    }
                    // Audio player - Replace all <audio>'s icon/image button by button component with event handler:
                    else if (domNode.attribs && domNode.attribs.class === "media-audio-element--type-icon") {
                        // Specify audio ID:
                        audioIconIndex++;
                        audioIconId = audioIds.current[`audioIconId${audioIconIndex}`] || `audio-icon-elem-${uuidv4()}`;
                        audioIds.current[`audioIconId${audioIconIndex}`] = audioIconId;
                        // Set up audio:
                        domNode.attribs.id = audioIconId;
                    } else if (domNode.attribs && domNode.attribs.class === "media-audio-icon") {
                        return <>{generateAudioButton(audioIconId)}</>;
                    } else {
                        // console.log("not found", domNode);
                    }
                },
            });

            if (blankVisibleType === "drag_drop") {
                return (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {elements}
                        {generateListAnswer()}
                    </DragDropContext>
                );
            }

            return elements;
        }
        return null;
    }, [value, selectedAnswer, audioSettings, listAnswer, listAnswered]); // You can add "selectedAnswer" to dependency array to activate isDisabledSelectedAnswers.

    return <React.Fragment>{qContent}</React.Fragment>;
}

export default React.memo(QuestionContentDetail);
