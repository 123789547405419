import api from "..";

const ALL_REALTIONS = ["examQuestion", "question", "assignment", "course", "user"];

const fetchHistoryModels = (fetchParams) => {
    return api
        .get("/history/list_model")
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: [] }));
};

const fetchHistories = (fetchParams) => {
    const filters = [];
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`);
    fetchParams.type && filters.push(`type:${fetchParams.type}`);
    fetchParams.model_type && filters.push(`model_type:${fetchParams.model_type}`);
    fetchParams.begin_date && filters.push(`begin_date:${fetchParams.begin_date}`);
    fetchParams.end_date && filters.push(`end_date:${fetchParams.end_date}`);
    !fetchParams.courseUser_course_id && fetchParams.model_id && filters.push(`model_id:${fetchParams.model_id}`);
    fetchParams.courseUser_course_id &&
        filters.push(`courseUser_course_id:${fetchParams.courseUser_course_id};model_type:courseUser`);

    const params = {
        _page: fetchParams.page,
        _perPage: fetchParams.perPage || 20,
        _filter: filters.join(";") || undefined,
        _history_fields: `${[
            "type",
            "historiable_type",
            "new_value",
            "old_value",
            "value",
            "field_name",
            "historiable_id",
            "user_id",
        ]}`,
        _course_fields: `${["name", "is_class"]}`,
        _question_fields: `${[
            "question",
            "type",
            "answer",
            "is_multiple",
            "coordinates",
            "answer1",
            "answer2",
            "match",
        ]}`,
        _assignment_fields: `${["name"]}`,
        _examquestion_fields: `${["name"]}`,
        _user_fields: `${["name", "avatar", "role", "username"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
        _relations: fetchParams.relations
            ? `${fetchParams.relations}`
            : fetchParams.model_id
            ? `${["user"]}`
            : `${ALL_REALTIONS}`,
    };
    return api
        .get("/history", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                    lastPage: meta.pagination.lastPage,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export { fetchHistoryModels, fetchHistories };
