import { t } from "i18next";
import { useTranslation } from "react-i18next";
import ShadeGrid from "src/modules/containers/QuestionDetail/containers/DetailShading/components/ShadeGrid";
import QuestionContentDetail from "../ContentDetail";
import "./Shading.scss";

const ShadingPreview = (props) => {
    const { question, qInfo, isShowLabel, correct_answer = [], answered = [] } = props;

    const {
        width,
        height,
        rowCount,
        columnCount,
        lock_cell,
        shaded,
        by_location,
        score,
        score_submit,
        examBankPrevew,
    } = qInfo || {};

    const { t } = useTranslation();

    const checkIndexIfItemExists = (i, j, arr) => {
        return Array.isArray(arr) && arr.findIndex((shade) => shade[0] === i && shade[1] === j);
    };

    const uniq1 = "id1" + new Date().getTime(); //fore re-render
    const uniq2 = "id2" + new Date().getTime(); //fore re-render

    const removeDuplicateItems = (arr) => {
        let result = [];
        if (Array.isArray(arr)) {
            arr.forEach((item) => {
                if (checkIndexIfItemExists(item[0], item[1], result) === -1) {
                    result.push(item);
                }
            });
        }
        return result;
    };

    const getListActive = (arr1, arr2) => {
        const newArr = [...structuredClone(arr1), ...structuredClone(arr2)];
        return removeDuplicateItems(newArr);
    };

    //Trường hợp preview bên question bank thì chưa merge shaded với correct_answer
    // nên phải dùng hàm này để merge 2 arr.
    const getCorrectAnswer = () => {
        if (examBankPrevew && Array.isArray(correct_answer) && Array.isArray(shaded)) {
            return getListActive(correct_answer, shaded);
        } else {
            return correct_answer;
        }
    };

    return (
        <div className="viewScoreStudent_shading">
            {isShowLabel && <div className="question-label">{t("q.question")}</div>}
            <QuestionContentDetail
                isReadonly={true}
                value={{
                    question: question,
                }}
            />

            <div className="content-shading">
                <div className="content-shading_title">{t("question.your_answer")}</div>
                <ShadeGrid
                    cellHeight={height}
                    cellWidth={width}
                    rowCount={rowCount}
                    colCount={columnCount}
                    onCellClick={() => {}}
                    lockedCells={lock_cell ? shaded : []}
                    // listActive={answered}
                    isReadonly={true}
                    listActive={(() => {
                        if (!lock_cell) {
                            return answered?.length > 0 ? answered : shaded;
                        } else {
                            return getListActive(shaded, answered);
                        }
                    })()}
                    mode={"previewResult"}
                    isShowCheckAnswers={true}
                    method={by_location ? "by_location" : "by_count"}
                    correctAnswersPreview={getCorrectAnswer()}
                    isCorrectSubmited={(() => {
                        //check dap an submit dung sai
                        if (score && (score_submit || score_submit === 0)) {
                            // console.log(score === score_submit, score, score_submit);
                            if (score === score_submit && score > 0) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        return "notsubmit";
                    })()}
                    uniq={uniq1}
                />

                {/* {by_location && ( */}
                <>
                    <br />
                    <div className="content-shading_title">{t("question.correct_answer")}</div>
                    <ShadeGrid
                        cellHeight={height}
                        cellWidth={width}
                        rowCount={rowCount}
                        colCount={columnCount}
                        mode={"doing"}
                        onCellClick={() => {}}
                        lockedCells={lock_cell ? shaded : []}
                        correctAnswers={getCorrectAnswer()}
                        isReadonly={true}
                        uniq={uniq2}
                    />
                </>
                {/* )} */}
            </div>
        </div>
    );
};

export default ShadingPreview;
