/* Import TinyMCE */
import tinymce from "tinymce";

/* Default icons are required for TinyMCE 5.3 or above */
import "tinymce/icons/default";

/* A theme is also required */
import "tinymce/themes/silver";

/* Import the skin */
// import "tinymce/skins/ui/oxide/skin.css";
import "./styles/skin.css";

/* Import plugins */
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/paste";
import "tinymce/plugins/charmap";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/preview";
import "tinymce/plugins/save";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/anchor";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/help";
import "./plugins/math";
import "./plugins/input";
import "./plugins/highlight";
import "./plugins/file-manager";
import "./plugins/paste-options";

// Language:
import "./langs/vi";

// Style:
import "./editor.scss";
/* Import content css */
// import contentUiCss from "tinymce/skins/ui/oxide/content.css";
// import contentCss from "tinymce/skins/content/default/content.css";

import MathQuill from "mathquill-node";
import { convertBlobToBase64 } from "src/utils/helpers";

const MQ = MathQuill.getInterface(2);
// const abc = require('src/assets/styles/timymce_content.css')

/* Initialize TinyMCE */
export default function render(target, config) {
    const { setup, ...rest } = config || {};
    return tinymce.init({
        target,
        ...rest,
        setup: (editor) => {
            // 1. Handle adding config's setup:
            if (setup instanceof Function) {
                setup(editor);
            }

            // 2. Convert from latex to html:
            editor.on("setcontent", (e) => {
                const staticMathElements = e.target.targetElm.querySelectorAll(`.latex-math`);
                staticMathElements.forEach((mathItem) => {
                    const staticMathEle = document.createElement("div");
                    staticMathEle.innerHTML = mathItem.innerHTML;

                    // Node type 3 is text
                    if (staticMathEle.childNodes?.[0]?.nodeType === 3) {
                        mathItem.innerHTML = mathItem.dataset.latex;
                        MQ.StaticMath(mathItem);
                    }
                });
            });
        },

        images_dataimg_filter: function (img) {
            /**
             * Fix case: immage src = base64 -> blob -> khi submit lưu db là blob nên ko lưu đc ảnh. phải lưu base64/
             * Bug này do editor ko chuyển blob thành base64 khi submit
             * Nhưng nếu để base64 thì khi dán 1 bức ảnh nặng vào sẽ bị lag,...
             * => Fix tạm thời, sẽ sửa lại sau
             * handle được cho những case: resize, gõ chữ làm đổi vị trí bức ảnh, xoay ảnh. các case cắt ảnh chưa handle được
             */
            setTimeout(() => {
                var dataUrl = img.getAttribute("src");

                function getBlobFromURL(url) {
                    return fetch(url).then((response) => response.blob());
                }

                if (dataUrl && dataUrl?.startsWith("blob:http")) {
                    getBlobFromURL(dataUrl)
                        .then((blob) => {
                            convertBlobToBase64(blob)
                                .then((base64String) => {
                                    // eslint-disable-next-line no-console
                                    if (base64String) {
                                        img.setAttribute("src", base64String);
                                    }
                                })
                                .catch((error) => {
                                    // eslint-disable-next-line no-console
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            // eslint-disable-next-line no-console
                            console.error(error);
                        });
                }
            }, 200);

            return false;
        },
    });
}
