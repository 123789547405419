import { Spin, Upload, notification } from "antd";
import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { isPromise } from "src/utils/helpers";

/**
 *
 * @param uploadApi promise
 * @returns
 */
const UploadImageBtn = function ({
    uploadApi,
    onUploading,
    onUploadError,
    onUploadSuccess,
    children,
    beforeUpload = () => {},
    disabled = false,
    ...rest
}) {
    const { t } = useTranslation();

    const uploadImage = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        fmData.append("file0", file);

        if (!isPromise(uploadApi())) {
            notification.error({ message: t("message.update_error") });
            return;
        }

        if (onUploading instanceof Function) {
            onUploading();
        }
        uploadApi(fmData, file)
            .then((res) => {
                if (res?.status) {
                    onSuccess("Ok");
                    if (onUploadSuccess instanceof Function) {
                        onUploadSuccess();
                    }
                } else {
                    onError({ message: "Some error" });
                    if (onUploadError instanceof Function) {
                        onUploadError();
                    }
                }
            })
            .catch((err) => {
                // eslint-disable-next-line
                console.error(err);
                onError({ message: err.message || "ERROR" });
                if (onUploadError instanceof Function) {
                    onUploadError();
                }
            });
    };

    // console.log(ref);

    return (
        <Upload
            name="avatar"
            listType="picture-circle"
            className="avatar-uploader"
            accept="image/*"
            showUploadList={false}
            customRequest={uploadImage}
            beforeUpload={beforeUpload}
            disabled={disabled}
            maxCount={1}
            {...rest}
        >
            {children}
        </Upload>
    );
};

export default UploadImageBtn;
