import { Col, Row, Tabs, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ReactComponent as ArrowIcon } from "src/assets/images/icon-select-chevron-down-currentColor.svg";
import { useValues } from "src/hooks";
import {
    gotoQuestionOrderInPassage,
    gotoQuestionOrderInPassagePreview,
    gotoQuestionOrderTempTime,
} from "src/reducers/test";

import DragDrop from "../DragDrop";
import DragDropGroup from "../DragDropGroup";
import FillBlanks from "../FillBlanks";
import FillBlanksDragDrop from "../FillBlanksDragDrop";
import FillBlanksInput from "../FillBlanksInput";
import Graph from "../Graph";
import GraphNumberLine from "../GraphNumberLine";
import HighlightImage from "../HighlightImage";
import Hotspot from "../Hotspot";
import ImageWithLabels from "../ImageWithLabels";
import MathTextDropdown from "../MathTextDropdown";
import Matrix from "../Matrix";
import MultipleChoice from "../MultipleChoice";
import MultipleChoiceBoolean from "../MultipleChoiceBoolean";
import MultipleChoiceSentenceQuiz from "../MultipleChoiceSentenceQuiz";
import MultipleChoiceTable from "../MultipleChoiceTable";
import PhoneticTable from "../PhoneticTable";
import Shading from "../Shading";
import Sort from "../Sort";
import SpeechToText from "../SpeechToText";
import SpeechToTextConversationAudio from "../SpeechToTextConversationAudio";
import SpeechToTextConversation from "../SpeechToTextConversationText";
import Writing from "../Writing";
import WritingShort from "../WritingShort";
import QuestionContentDetail from "../components/ContentDetail";
import "./Passage.scss";

function Passage(props) {
    const { TabPane } = Tabs;
    const {
        isReadonly = false,
        showOrdinalNumber = false,
        paragraphs,
        recordId,
        defaultAnswer,
        onChange = () => {},
        questionInfo = {},
        id /** @param{string} id: id of passage question */,
        isShowPinyin,
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [answered, setAnswered] = useState(defaultAnswer || {});
    const [isShowPassageQuestion, setIsShowPassageQuestion] = useState(true);
    const [tabOrder, setTabOrder] = useState([0]); // dung cho cau speech to text
    const [currentParagraphTab, setCurrentParagraphTab] = useState(0);
    const [values, setValues] = useValues({
        hasSpeechToText: false,
    });

    const handleChangeTab = (data) => {
        try {
            const { order, id, passageId, qIndex } = JSON.parse(data) || {};

            // If dont have recordId then this is preview mode
            if (recordId) {
                dispatch(
                    gotoQuestionOrderTempTime({
                        order,
                        recordId,
                        questionId: id,
                        passageId,
                    })
                );
            } else {
                // Dont save localStorage in preview mode
                dispatch(
                    gotoQuestionOrderInPassagePreview({
                        questionOrder: order,
                    })
                );
            }
            // luc thi order la 0,1,2. co luc lai la 1.1, 1.2,...,2.1,...
            if (values.hasSpeechToText) {
                setTabOrder(() => {
                    const newTabOrder = [...tabOrder];
                    newTabOrder[currentParagraphTab] = qIndex;
                    return newTabOrder;
                });
            }
        } catch (err) {
            console.log("Error", err);
        }
    };
    const handleChangeParagraph = (pIndex) => {
        try {
            const infoOfFirstQuestionInParagraph = paragraphs[pIndex].question_details[0];

            if (recordId) {
                dispatch(
                    gotoQuestionOrderTempTime({
                        order: infoOfFirstQuestionInParagraph.order,
                        recordId,
                        questionId: infoOfFirstQuestionInParagraph.id,
                        passageId: id,
                    })
                );
            } else {
                // Dont save localStorage in preview mode
                dispatch(
                    gotoQuestionOrderInPassagePreview({
                        questionOrder: infoOfFirstQuestionInParagraph.order,
                    })
                );
            }

            if (values.hasSpeechToText) {
                setCurrentParagraphTab(pIndex);
            }
        } catch (err) {
            console.log("Error", err);
        }
    };

    const handleToggleShowPassage = () => {
        setIsShowPassageQuestion(!isShowPassageQuestion);
    };

    const handleChangeAnswerInPassage = (newValue, id) => {
        const newAnswered = structuredClone(answered);
        newAnswered[id] = newValue.answered;

        setAnswered(newAnswered);

        onChange({
            answered: newAnswered,
            type: "passage",
        });
    };
    const renderQuestionsInParagraph = (questionInfo, order, paragraphTab) => {
        const generalProps = {
            question: `${showOrdinalNumber ? qIndex + 1 + "." : ""} ${questionInfo.question || ""}`,
            answer: questionInfo?.answer,
            defaultAnswer: answered[questionInfo?._id || questionInfo?.id],
            isReadonly,
            isShowPinyin: isShowPinyin,
            // API trong tạo và cập nhật passage trả về _id còn trong tạo và cập nhật đề thì trả về id
            onChange: (newValue) => handleChangeAnswerInPassage(newValue, questionInfo._id || questionInfo.id),
        };
        switch (questionInfo?.type) {
            case "writing": {
                generalProps.wordLimit = questionInfo?.word_limit;
                return <Writing {...generalProps} />;
            }
            case "writing_short": {
                return <WritingShort {...generalProps} />;
            }
            case "fill_in_the_blank": {
                return <FillBlanks {...generalProps} />;
            }
            case "fill_in_the_blank_drag_drop": {
                return <FillBlanksDragDrop {...generalProps} />;
            }
            case "fill_in_the_blank_text": {
                return <FillBlanksInput {...generalProps} />;
            }
            case "fill_in_the_blank_image": {
                const { coordinates, answer, file, src, width, height } = questionInfo;
                generalProps.coordinates = coordinates;
                generalProps.answer = answer;
                generalProps.src = file?.src || src;
                generalProps.width = width;
                generalProps.height = height;
                return <ImageWithLabels labelType="dropdown" {...generalProps} />;
            }
            case "fill_in_the_blank_drag_drop_image": {
                const { coordinates, answer, file, src, width, height } = questionInfo;
                generalProps.coordinates = coordinates;
                generalProps.answer = answer;
                generalProps.src = file?.src || src;
                generalProps.width = width;
                generalProps.height = height;
                return <ImageWithLabels labelType="drag&drop" {...generalProps} />;
            }
            case "fill_in_the_blank_text_image": {
                const { coordinates, answer, file, src, width, height } = questionInfo;
                generalProps.coordinates = coordinates;
                generalProps.answer = answer;
                generalProps.src = file?.src || src;
                generalProps.width = width;
                generalProps.height = height;
                return <ImageWithLabels labelType="text" {...generalProps} />;
            }

            case "multiple_choice":
                // generalProps.answer = questionInfo.answer.map((aswr) => aswr.text);
                return <MultipleChoice {...generalProps} />;
            case "multiple_choice_sentence_quiz":
                return <MultipleChoiceSentenceQuiz {...generalProps} />;
            case "multiple_choice_boolean":
                return <MultipleChoiceBoolean {...generalProps} />;
            case "multiple_choice_table":
                return (
                    <MultipleChoiceTable
                        {...generalProps}
                        answer1={questionInfo.answer1}
                        answer2={questionInfo.answer2}
                        correct_answer={questionInfo.match}
                    />
                );
            case "sort":
                return <Sort {...generalProps} />;
            case "drag_drop":
                generalProps.answer = {
                    answer1: questionInfo.answer1,
                    answer2: questionInfo.answer2,
                };
                return <DragDrop {...generalProps} />;
            case "drag_drop_group_order_by":
            case "drag_drop_group":
                generalProps.answers = questionInfo.answer1;
                generalProps.groups = questionInfo.answer2;
                return <DragDropGroup {...generalProps} />;
            case "highlight_image":
                generalProps.answer = [
                    {
                        src: questionInfo?.file?.src,
                        width: questionInfo?.width,
                        height: questionInfo?.height,
                    },
                ];
                return <HighlightImage {...generalProps} />;
            case "hot_spot":
                generalProps.qestionData = {
                    paths: questionInfo.path,
                    width: questionInfo.width,
                    height: questionInfo.height,
                    src: questionInfo?.file?.src,
                };

                return <Hotspot {...generalProps} />;
            case "highlight_square":
                generalProps.qInfo = questionInfo;

                return <Shading {...generalProps} />;
            case "speech_to_text":
                generalProps.is_sentence = questionInfo.is_sentence;
                generalProps.speaker = questionInfo.speaker;
                generalProps.lang = questionInfo?.language?.code;
                generalProps.order = order;
                generalProps.isRenderInPassage =
                    order == tabOrder[currentParagraphTab] && currentParagraphTab == paragraphTab;
                return <SpeechToText {...generalProps} tabOrder={tabOrder} />;
            case "speech_to_text_conversation_text":
                const isRenderInPassage = order == tabOrder[currentParagraphTab] && currentParagraphTab == paragraphTab;
                generalProps.speaker = questionInfo.speaker;
                generalProps.lang = questionInfo?.language?.code;
                generalProps.voice = questionInfo?.voice;
                generalProps.isRenderInPassage = isRenderInPassage;

                return isRenderInPassage ? (
                    <SpeechToTextConversation {...generalProps} />
                ) : (
                    <div style={{ width: 40, height: 40 }}></div>
                );
            case "phonetic_table":
                return <PhoneticTable {...generalProps} />;
            case "speech_to_text_conversation_audio":
                const isRenderInPassage2 =
                    order == tabOrder[currentParagraphTab] && currentParagraphTab == paragraphTab;
                generalProps.speaker = questionInfo.speaker;
                generalProps.lang = questionInfo?.language?.code;
                generalProps.isRenderInPassage = isRenderInPassage2;

                return isRenderInPassage2 ? <SpeechToTextConversationAudio {...generalProps} /> : null;
            case "convert_measurement":
                return <MathTextDropdown {...generalProps} />;
            case "fill_in_the_blank_latex":
            case "matrix":
                return <Matrix template_latex={questionInfo?.template_latex} {...generalProps} />;
            case "draw_graph":
                return <Graph {...generalProps} />;
            case "number_line": {
                const { max_value, min_value, jump_value } = questionInfo;
                generalProps.max_value = max_value;
                generalProps.min_value = min_value;
                generalProps.jump_value = jump_value;
                return <GraphNumberLine {...generalProps} />;
            }
            default:
                return <div>Question type not found in passage</div>;
        }
    };

    const checkHasPeechToText = (data) => {
        if (Array.isArray(data)) {
            let result = data.find((item) =>
                ["speech_to_text", "speech_to_text_conversation_text", "speech_to_text_conversation_audio"]?.includes(
                    item.type
                )
            );
            return result ? true : false;
        }
        return false;
    };

    useEffect(() => {
        if (Array.isArray(paragraphs)) {
            let isHadSpeechToText = false;
            paragraphs.forEach((value) => {
                if (checkHasPeechToText(value?.question_details)) {
                    isHadSpeechToText = true;
                }
            });
            if (isHadSpeechToText) {
                const newArr = Array(paragraphs.length).fill(0);
                setTabOrder(newArr);
                setValues({
                    hasSpeechToText: true,
                });
            }
        }
    }, []);

    return (
        <div className="qp_passage">
            <Tabs defaultActiveKey="0" tabPosition={"top"} onChange={handleChangeParagraph}>
                {paragraphs?.map((p, pIndex) => (
                    <Tabs.TabPane key={pIndex} tab={`${t("question.header_passage")} ${pIndex + 1}`}>
                        <div className="content-passage">
                            <div className="passage-title">{p.title || ""}</div>
                            <div className="passage-heading">{p.heading || ""}</div>
                            <Row className="passage_skeleton" gutter={[20, 20]} style={{ marginTop: "20px" }}>
                                <div className="passage_toggle" onClick={handleToggleShowPassage}>
                                    <div className="passage_toggle-btn-wrapper">
                                        <ArrowIcon
                                            className={
                                                "passage_toggle-btn " + (isShowPassageQuestion ? "backward" : "forward")
                                            }
                                        />
                                    </div>
                                    <div className="passage_toggle-note">
                                        {isShowPassageQuestion
                                            ? t("question_passage.btn_toggle_note_extend")
                                            : t("question_passage.btn_toggle_note_down")}
                                    </div>
                                </div>
                                <Col
                                    className={"passage_transition " + (!isShowPassageQuestion ? "invisible" : "")}
                                    md={isShowPassageQuestion ? 14 : 1}
                                    xs={24}
                                >
                                    <div className="passage-content-wrapper">
                                        <div className="passage-content scroll_primary">
                                            <QuestionContentDetail
                                                isReadonly={isReadonly}
                                                value={{ question: p.content }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col className="passage_transition" md={isShowPassageQuestion ? 10 : 23} xs={24}>
                                    <div className="passage-question-list-wrapper">
                                        <div className="passage-question-list__childNumberQuestion"></div>
                                        <div className="passage-question-list scroll_primary">
                                            <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
                                                {p.question_details?.map((question, qIndex) => (
                                                    <TabPane
                                                        tab={`${t("question_panel.question")} ${qIndex + 1}`}
                                                        key={JSON.stringify({
                                                            id: question.id,
                                                            order: `${question?.order || qIndex}`,
                                                            passageId: id,
                                                            qIndex: qIndex,
                                                        })}
                                                    >
                                                        <div className="passage-question-wrapper">
                                                            {renderQuestionsInParagraph(question, qIndex, pIndex)}
                                                        </div>
                                                    </TabPane>
                                                ))}
                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
}

export default Passage;
