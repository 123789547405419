import { Drawer, Modal, Space, Spin, notification } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "src/api";
import { checkCandidateInformation, checkExamAccessCode } from "src/api/containers/examCheckin";
import { fetchRecordsOfStudent } from "src/api/containers/record";
import { useValues } from "src/hooks";
import Button from "src/modules/components/Button";
import Empty from "src/modules/components/Empty";
import VideoWithListViolation from "src/modules/components/VideoWithListViolation";
import { getCookie, parse_jwt, setCookie } from "src/utils/helpers";
import { useRouting } from "src/utils/router";

import EmailVerify from "../ExamCheckIn/components/EmailVerify";
import ViewScoreStudent from "../ViewScoreStudent";
import "./StudentViewResult.scss";
import ViewResultSelection from "./ViewResultSelection";

function StudentViewResult() {
    const { t } = useTranslation();
    const [urlSearchParams] = useSearchParams();
    const assignmentCode = useMemo(() => {
        return urlSearchParams.get("code");
    }, []);

    const [values, setValues] = useValues({
        loading: false,
        accessStatus: false,
        assignmentData: null,
        code_verify: null,
        isModal: false,
        modalType: "", //'result' | 'violation'
        recordData: null,
        codeAccesChecked: "",
        user: null,
        isRecordFetched: false,
    });

    const [recordData, setRecordData] = useState(null);

    const navigate = useNavigate();
    const { generate } = useRouting();

    function checkAccessCode() {
        checkExamAccessCode(assignmentCode).then((res) => {
            if (res.status) {
                setValues({
                    loading: false,
                    accessStatus: res.status,
                    assignmentData: res.assignment,
                    codeAccesChecked: assignmentCode,
                });

                // check xem hết hạn thì redirect qua trang thông baois hết hạn
                ///code here
            } else {
                setValues({ loading: false });

                notification.error({
                    message: res.message || t("message.sth_wrong"),
                });
            }
        });
    }

    const handleCheckInWithToken = (token) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + token;

        const user = parse_jwt(`${token}`);

        if (user?.avatar_src) {
            user.avatar = user.avatar_src;
        }
        // setCookie("token", token);
        // localStorage.setItem("reivew_code", assignmentCode);

        setValues({ user: user });
    };

    function checkStudentInfo(data) {
        const _formData = {
            name: data.name,
            username: data.email,
            personal_number: data.personal_number,
            device_id: values.assignmentData?.id,
            files: undefined,
            code_verify: data.code_verify,
            is_view_result: true,
        };

        const fmData = new FormData();
        for (const key in _formData) {
            if (_formData.hasOwnProperty(key) && _formData[key] !== undefined) {
                fmData.append(key, _formData[key]);
            }
        }

        setValues({ loading: true });
        checkCandidateInformation(fmData).then((res) => {
            if (res.status) {
                handleCheckInWithToken(res.token);
            } else {
                setValues({ loading: false });
                notification.error({
                    message: res.message || t("message.sth_wrong"),
                });
            }
        });
    }

    function fetcthAssignmentRecord() {
        setValues({ loading: true });
        fetchRecordsOfStudent({ code_assignment: assignmentCode }).then(({ status, data }) => {
            if (status && data) {
                setValues({ loading: false, isRecordFetched: true });
                setRecordData(data?.[0]);
            }
        });
    }

    // useEffect(() => {
    //     const reviewCode = localStorage.getItem("reivew_code");
    //     // const token = getCookie("token");
    //     if (token && reviewCode === assignmentCode) {
    //         const user = parse_jwt(`${token}`);

    //         setValues({ user: user });
    //     }
    //     return false;
    // }, [assignmentCode]);

    useEffect(() => {
        if (!values.codeAccesChecked) {
            checkAccessCode();
        }
    }, [assignmentCode]);

    useEffect(() => {
        if (values.user && !recordData) {
            fetcthAssignmentRecord();
        }
    }, [values.user]);

    if (!assignmentCode) {
        navigate(generate("not-found"));
    }

    if (values.isRecordFetched && !recordData) {
        return (
            <div className="student-view-result">
                <Space direction="vertical" size={"middle"} style={{ alignItems: "center" }}>
                    <Empty description={`Oops! ${t("exam_result.your_assignment_not_found")}`} />
                    <Button
                        type="primary"
                        title={t("html_status_code.action_go_back_home")}
                        onClick={() => navigate("/")}
                    />
                </Space>
            </div>
        );
    }

    return (
        <>
            <div className="student-view-result">
                <Spin spinning={values.loading} style={{ height: "100%" }}>
                    <div className="candidate-information">
                        {!values.user ? (
                            <EmailVerify
                                setTempUserInfo={({ name, code_verify, email, personal_number }) => {}}
                                setFormData={({ name, code_verify, email, personal_number }) => {
                                    checkStudentInfo({ name, code_verify, email, personal_number });
                                    setValues({ code_verify: code_verify });
                                }}
                            />
                        ) : (
                            <ViewResultSelection
                                onSelectViewResult={(callback) => {
                                    setValues({ isModal: true, modalType: "result" });
                                    callback?.();
                                }}
                                onSelectViewViolation={(callback) => {
                                    setValues({ isModal: true, modalType: "violation" });
                                    callback?.();
                                }}
                            />
                        )}
                    </div>
                </Spin>
            </div>

            <Modal
                className="add-exam-question-modal modal-mark-assignment"
                visible={values.isModal && values.modalType === "result"}
                onCancel={() => setValues({ isModal: false, modalType: "" })}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose
                width={"80%"}
            >
                <ViewScoreStudent
                    markingRecord={{ ...(recordData || {}) }}
                    isMarking={false}
                    closeModal={() => {
                        // refetch();
                        // setIsQuestionResultModal(false);
                    }}
                    isModalBox={true}
                />
            </Modal>

            <Modal
                className="add-exam-question-modal modal-mark-assignment"
                visible={values.isModal && values.modalType === "violation"}
                onCancel={() => setValues({ isModal: false, modalType: "" })}
                width={"fit-content"}
                footer={null}
                bodyStyle={{ padding: "20px", height: "74vh", overflowY: "auto" }}
                destroyOnClose
                title={t("anti_cheating.video_record")}
                closable
                style={{ maxWidth: "80vw" }}
            >
                <VideoWithListViolation
                    width="100%"
                    height="100%"
                    videoData={recordData?.files?.[0]}
                    recorData={recordData}
                />
            </Modal>
        </>
    );
}

export default StudentViewResult;
