import { notification } from "antd";
import * as faceapi from "face-api.js";
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import useSound from "use-sound";

import { countUserViolations, uploadViolationImage } from "src/api/containers/record";
import configs from "src/configs";
import { useValues } from "src/hooks";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";
import { alertOnTop } from "src/reducers/general";
import { pages } from "src/routes/pages";
import { closeFullscreen } from "src/utils/AntiCheating";
import { checkIsModalsFaceApiLoaded, dataURIToBlob } from "src/utils/helpers";
import { useRouting } from "src/utils/router";

import "./WebcamFaceDetect.scss";

const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;

//224,320,480
const inputSize = configs.EXAM_PROCTORING.FACES_DETECT_REALTIME || 256;
const scoreThreshold = configs.EXAM_PROCTORING.SCORE_THRES_HOLD || 0.45;
// const inputSize = 512;
// const scoreThreshold = 0.45;
let withBoxes = true;
let withFaceLandmarks = false;
// console.log({ inputSize, scoreThreshold });

const countTimes = 1;

function dataURIToFile(dataURI, fileName) {
    const blob = dataURIToBlob(dataURI);

    const formData = new FormData();
    formData.append(fileName, blob, "img.jpeg");

    return formData;
}

// detect face realtime in webcam
const FaceDetection = ({ width = 250, height = 150, className }) => {
    const webcamRef = useRef(null);
    const interval1 = useRef();
    const [imgCapture, setImgCapture] = useState();
    const count1 = useRef(0);
    const count2 = useRef(0);
    const not_appear_on_camera = useRef(0);
    const detect_many_people_in_camera = useRef(0);
    const detected_noface_uploaded = useRef(false);
    const detected_multiface_uploaded = useRef(false);
    const playRef = useRef(false);
    const { recording_time_start } = useSelector((state) => state.test);

    const { examRecordData } = useContext(ProctoringContext);

    const _examRecordData = examRecordData?.data;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const [values, setValues] = useValues({
        isModelsLoaded: false,
        imgCapture: "",
        isSentImg: false,
        is_detected_face_uploaded: false,
    });

    const [playOff] = useSound("https://assets.mixkit.co/active_storage/sfx/2574/2574-preview.mp3", {
        volume: 1,
    });
    playRef.current = playOff;

    const captureImg = () => {
        const imageSrc = webcamRef.current?.getScreenshot?.();
        setImgCapture(imageSrc);
        return imageSrc;
    };

    async function countViolationsAction(key, time_violation) {
        const { status, warning, is_load } = await countUserViolations(
            examRecordData.data.record_id,
            key,
            time_violation
        );
        if (is_load) {
            navigate(generate(pages.exam_asset.name));
        }
        if (warning) {
            // notification.warning({ message: warning });
        }
    }

    async function onPlay(videoEl) {
        const options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.4 });

        const drawBoxes = withBoxes;
        const drawLandmarks = withFaceLandmarks;
        // console.time("nnn");
        let task = faceapi.detectAllFaces(videoEl, options);
        task = withFaceLandmarks ? task.withFaceLandmarks() : task;
        const results = await task;

        const rules = _examRecordData?.options;

        if (results.length < 1) {
            //case ko có khuôn mặt trong ảnh

            if (count1.current < countTimes) {
                count1.current = count1.current + 1;
            } else if (count1.current >= countTimes) {
                count1.current = 0;

                if (
                    rules?.times_allowed_not_appear_on_camera === undefined ||
                    (rules?.times_allowed_not_appear_on_camera &&
                        not_appear_on_camera.current < rules?.times_allowed_not_appear_on_camera)
                ) {
                    // chỉ cảnh báo
                    not_appear_on_camera.current += 1;
                    notification.warning({
                        message: t("violation_warning"),
                        description: t(`anti_cheating.alert_not_appear_on_camera`),
                    });
                    playRef.current(); // phát âm thanh cảnh báo

                    let time_violation = new Date().getTime();

                    if (recording_time_start) {
                        time_violation = new Date() - recording_time_start;
                    }

                    await countViolationsAction(ANTI_CHEATING_KEYS.NOT_APPEAR_ON_CAMERA, time_violation);

                    // if (detected_noface_uploaded.current) return;

                    const imgCaptute = captureImg();
                    const file = dataURIToFile(imgCaptute, "file0");
                    file.append("action", "no_face_on_screen");
                    file.append("time", time_violation);
                    uploadViolationImage(_examRecordData?.record_id, file).then(({ status }) => {
                        detected_noface_uploaded.current = status;
                    });

                    return;
                } else if (
                    rules?.times_allowed_not_appear_on_camera &&
                    not_appear_on_camera.current >= rules?.times_allowed_not_appear_on_camera
                ) {
                    not_appear_on_camera.current = 0;

                    notification.warning({
                        message: t("violation_warning"),
                        description: t("anti_cheating.violated_exam_contact_teacher"),
                    });
                    playRef.current(); // âm thanh cảnh báo

                    navigate(generate(pages.exam_asset.name));
                    closeFullscreen();

                    return;
                }
            }
        } else if (results.length === 1) {
            count1.current = 0;
            count2.current = 0;
        } else if (results.length > 1) {
            //case > 1 khuôn mặt trong ảnh

            if (count2.current < countTimes) {
                count2.current = count2.current + 1;
            } else if (count2.current >= countTimes) {
                count2.current = 0;
                if (
                    rules?.times_allowed_many_people_in_camera === undefined ||
                    (rules?.times_allowed_many_people_in_camera &&
                        detect_many_people_in_camera.current < rules?.times_allowed_many_people_in_camera)
                ) {
                    // chỉ cảnh báo
                    detect_many_people_in_camera.current += 1;
                    notification.warning({
                        message: t("violation_warning"),
                        description: t(`anti_cheating.detect_many_people_in_camera`),
                    });
                    playRef.current(); // âm thanh cảnh báo

                    let time_violation = new Date().getTime();

                    if (recording_time_start) {
                        time_violation = new Date() - recording_time_start;
                    }

                    await countViolationsAction(ANTI_CHEATING_KEYS.MULTIPLE_PEOPLE_IN_CAMERA, time_violation);

                    // if (detected_multiface_uploaded.current) return;

                    const imgCaptute = captureImg();
                    const file = dataURIToFile(imgCaptute, "file0");
                    file.append("action", "many_faces_on_screen");
                    file.append("time", time_violation);

                    uploadViolationImage(_examRecordData?.record_id, file).then(({ status }) => {
                        detected_multiface_uploaded.current = status;
                    });

                    return;
                } else if (
                    rules?.times_allowed_many_people_in_camera &&
                    detect_many_people_in_camera.current >= rules?.times_allowed_many_people_in_camera
                ) {
                    detect_many_people_in_camera.current = 0;

                    notification.warning({
                        message: t("violation_warning"),
                        description: t("anti_cheating.violated_exam_contact_teacher"),
                    });
                    playRef.current();
                    navigate(generate(pages.exam_asset.name));
                    closeFullscreen();

                    return;
                }
            }
        }

        // console.timeEnd("nnn");

        const canvas = document.getElementById("overlay");
        const dims = faceapi.matchDimensions(canvas, videoEl, true);

        const resizedResults = faceapi.resizeResults(results, dims);
        if (drawBoxes) {
            faceapi.draw.drawDetections(canvas, resizedResults);
        }
        if (drawLandmarks) {
            faceapi.draw.drawFaceLandmarks(canvas, resizedResults);
        }
    }

    const loadModels = async () => {
        const MODEL_URL = process.env.PUBLIC_URL + "/models";

        Promise.all([
            faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        ])
            .then(() => {
                setValues({ isModelsLoaded: true });
            })
            .catch(() => {
                notification.error({
                    message: t("error_occurred_please_reload"),
                });
                dispatch(
                    alertOnTop({
                        message: t("error_occurred_please_reload"),
                        type: "error",
                        action: "reload_page",
                    })
                );
            });
    };

    useEffect(() => {
        if (!checkIsModalsFaceApiLoaded(faceapi)) {
            loadModels();
        } else {
            setValues({ isModelsLoaded: true });
        }
    }, []);

    useEffect(() => {
        let rules = _examRecordData?.data?.options || _examRecordData?.options;
        if ((rules?.not_appear_on_camera || rules?.detect_many_people_in_camera) && values.isModelsLoaded) {
            interval1.current = setInterval(() => {
                onPlay(document.getElementById("webcam-capture"));
            }, 1000);
        }

        return () => clearInterval(interval1.current);
    }, [values.isModelsLoaded]);

    return (
        <div className={className}>
            <div className="face-detection">
                <Webcam
                    ref={webcamRef}
                    id="webcam-capture"
                    screenshotFormat="image/jpeg"
                    width={width}
                    height={height}
                    screenshotQuality={1}
                    mirrored={false}
                    minScreenshotHeight={400}
                    minScreenshotWidth={400}
                />
                <canvas id="overlay" />
            </div>
            {/* <div>{imgCapture && <img src={imgCapture} alt="iii" />}</div> */}
        </div>
    );
};

export default FaceDetection;
