import { Col, Form, InputNumber, notification, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import Editor from "src/modules/components/Editor";
import configEditor from "src/utils/configEditor";
import "./DetailWritingShort.scss";

const editorConfigQuestion = configEditor.question();

function DetailWritingShort({
    form,
    formStates,
    currQType,
    // Question id:
    questionId,
    questionParentId,
    // Fetch:
    extraQuestionFetchParams,
    // Fill in the form:
    isFormFilled,
    onFillFormQuestionData,
    handleFormAction,
}) {
    const { t } = useTranslation();
    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            const _questionData = {
                type: currQType,
            };
            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: "",
                // Special fields:
                type: _questionData.type,
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "word_limit".
                                word_limit: qData?.word_limit || 0,
                            };
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const questionFormData = {
                                question: res[1].data?.question || "",
                                score: res[1].data?.score || 0,
                            };
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <React.Fragment>
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion}></Editor>
                </Form.Item>
            </Row>

            <Row className="question-formitems set-input-numbers">
                <Col>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DetailWritingShort;
