import { TeamOutlined } from "@ant-design/icons";
import { Avatar, Col, Collapse, Row, Spin, notification } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchSubscriptions } from "src/api/containers/subscription";
import { useFetch } from "src/hooks";
import Button from "src/modules/components/Button";
import CustomButton from "src/modules/components/Button";
import { cardFieldSettings, getCardInfoByField } from "src/modules/components/Payment/settings";
import { useRouting } from "src/utils/router";

import { paid, paymentStatus } from "../PaymentHistory/PaymentHistory";
import "./MyPlan.scss";

const { Panel } = Collapse;

function MyPlan({
    fixedFetchParams = {
        purchased_plan: 1,
        noPagination: true,
    },
    planList,
}) {
    const navigate = useNavigate();
    const { generate } = useRouting();

    // Date format:
    const formatDate = useSelector((state) => state.general.formatDate);
    const formatDateTime = useSelector((state) => state.general.formatDateTime);
    // User info:
    const { user } = useSelector((state) => state.auth);
    // Scope info:
    const orgInfo = user?.organization;

    // API CALL:
    const [loadingPayments, payments, paymentsPagination, fetchPayments, refetchPayments] = useFetch(
        {},
        fetchSubscriptions
    );

    // PAGE DATA:
    const columns = [
        {
            title: t("setting.plan_name"),
            dataIndex: "plan",
            key: "plan",
            width: "auto",
            render: (plan) => {
                return plan?.name;
            },
        },
        {
            title: t("setting.effective_date"),
            dataIndex: "valid_from",
            key: "valid_from",
            width: "auto",
            render: (text) => {
                return moment(text).format(formatDate);
            },
        },
        {
            title: t("setting.expiration_date"),
            dataIndex: "valid_to",
            key: "valid_to",
            width: "auto",
            render: (text) => {
                return moment(text).format(formatDate);
            },
        },
        // {
        //     title: t("setting.plan_status"),
        //     dataIndex: "valid_to",
        //     key: "status",
        //     width: "auto",
        //     render: (text) => {
        //         return moment(text).isBefore(moment()) ? (
        //             <Tag color="red">{t("shared.expired")}</Tag>
        //         ) : (
        //             <Tag color="green">{t("shared.alive")}</Tag>
        //         );
        //     },
        // },
    ];

    const columnsOfPayment = [
        {
            title: t("setting.payment_method"),
            dataIndex: "payment_method",
            key: "payment_method",
            width: "auto",
            render: (text, record) => {
                return text
                    ? t(`payment.method_${text}`)
                    : record?.transaction?.payment_method
                    ? t(`payment.method_${record.transaction.payment_method}`)
                    : "";
            },
        },
        {
            title: t("setting.payment_status"),
            key: "status",
            dataIndex: "status",
            render: (text, record) => {
                if (Object.keys(paymentStatus).includes(record?.transaction?.status)) {
                    return t(paymentStatus[record.transaction.status].label);
                }
                return t(paid[record?.status || "false"]);
            },
        },
    ];

    const columnsOfPlanInfo = [
        {
            title: t("setting.plan_info"),
            dataIndex: "plan_info",
            key: "plan_info",
            width: "auto",
            render: (text, record) => {
                return (
                    <ul className="my-option-list">
                        {Object.keys(cardFieldSettings).map((k, i) => {
                            // Field:
                            const field = cardFieldSettings[k].field;
                            const name = cardFieldSettings[k].name;
                            const renderField = cardFieldSettings[k].renderField;
                            // Value:
                            const value = record?.[field];
                            const content = renderField instanceof Function ? renderField(t, value) : value || "";
                            return (
                                <li key={`opt-${i}`} className="my-option-listitem">
                                    <span>{t(name)}:</span>
                                    <span>{content}</span>
                                </li>
                            );
                        })}
                    </ul>
                );
            },
        },
    ];

    const columnsOfOptions = [
        {
            title: t("setting.buy_more_options"),
            dataIndex: "options",
            key: "options",
            width: "auto",
            render: (options, record) => {
                return (
                    <ul className="my-option-list">
                        {options?.map((item, itemIndex) => {
                            const { key, value } = item;
                            // Field:
                            const info = getCardInfoByField(key);
                            const name = info.name;
                            const renderField = info.renderField;
                            // Value:
                            const content = renderField instanceof Function ? renderField(t, value) : value || "";
                            return (
                                <li key={`moreopt-${itemIndex}`} className="my-option-listitem">
                                    <span>{t(name)}:</span>
                                    <span>{content}</span>
                                </li>
                            );
                        })}
                    </ul>
                );
            },
        },
    ];

    const handleFetchDataList = (fetchParams = {}, isReload = true) => {
        const fParams = {
            page: fetchParams.page,
            slug: fetchParams.slug,
            ...(Object.keys(fixedFetchParams || {})?.length ? fixedFetchParams : {}),
        };
        fetchPayments({ ...fParams }, isReload);
    };

    const handleExtendsubscription = () => {
        if (orgInfo?.plan_id && orgInfo?.is_organization_owner) {
            const { plan_type, plan_id, plan_price_id } = orgInfo;
            const data = {
                planType: plan_type,
                planId: plan_id,
                planPriceId: plan_price_id,
                is_extend: true,
            };
            sessionStorage.setItem("currentOrder", JSON.stringify(data));
            navigate(generate("payment"));
        } else {
            notification.warning({ message: t("message.an_error_occurred") });
        }
    };

    const handleBuyMoreOptions = () => {
        if (orgInfo?.plan_id && orgInfo?.is_organization_owner) {
            const { plan_type, plan_id, plan_price_id } = orgInfo;
            const data = {
                planType: plan_type,
                planId: plan_id,
                planPriceId: plan_price_id,
                is_tobuy_opts: true,
            };
            sessionStorage.setItem("currentOrder", JSON.stringify(data));
            navigate(generate("payment"));
        } else {
            notification.warning({ message: t("message.an_error_occurred") });
        }
    };

    useEffect(() => {
        handleFetchDataList({}, true);
    }, []);

    const collapseItemsOfOrders = useMemo(() => {
        const _items = [];
        for (let i = 0; i < payments.length; i++) {
            const _item = payments[i];
            _items.push({
                key: `order${_item.id}`,
                label: `${t("setting.code")}: ${_item.code}`,
                children: (
                    <div className="order-info">
                        <div className="info-section">
                            {columns.map((col, colIndex) => {
                                const { dataIndex, title, render } = col;
                                return (
                                    <div key={`sec1-${colIndex}`} className="info-text">
                                        <span className="text-label">{title}:</span>
                                        <span className="text-value">{render(_item[dataIndex], _item)}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="info-section">
                            {columnsOfPayment.map((col, colIndex) => {
                                const { dataIndex, title, render } = col;
                                return (
                                    <div key={`sec2-${colIndex}`} className="info-text">
                                        <span className="text-label">{title}:</span>
                                        <span className="text-value">{render(_item[dataIndex], _item)}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="info-section plan-info">
                            {columnsOfPlanInfo.map((col, colIndex) => {
                                const { dataIndex, title, render } = col;
                                return (
                                    <div key={`sec3-${colIndex}`} className="info-text">
                                        <span className="text-label">{title}:</span>
                                        <span className="text-value">{render(_item[dataIndex], _item)}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="info-section options">
                            {columnsOfOptions.map((col, colIndex) => {
                                const { dataIndex, title, render } = col;
                                return (
                                    <div key={`sec4-${colIndex}`} className="info-text">
                                        <span className="text-label">{title}:</span>
                                        <span className="text-value">{render(_item[dataIndex], _item)}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ),
            });
        }
        return _items;
    }, [payments]);

    const renderPlanInfo = () => {
        const _planId = orgInfo?.plan_id;
        let _plan;
        for (let i = 0; i < planList.length; i++) {
            if (_planId === planList[i].id) {
                _plan = planList[i];
                break;
            }
        }
        return (
            <>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.plan_type")}</div>
                    <div className={`item-value`}>{_plan?.name ? _plan.name : orgInfo?.plan_type || "_"}</div>
                </div>
                <div className="form-viewer-item">
                    <div className="item-title">{t("organization.expiration_date")}</div>
                    <div className={`item-value`}>
                        {orgInfo?.expired_date ? moment(orgInfo?.expired_date).format(formatDateTime) : "_"}
                    </div>
                </div>
            </>
        );
    };

    const renderPlanOptions = () => {
        return null;
    };

    const renderPlanView = () => {
        return <div className="plan-detail"></div>;
    };

    const renderPlanViewAsGuest = () => {
        return (
            <div className="plan-detail view-as-guest">
                <div className="organization-detail">
                    <div className="form-viewer layout-horizontal">
                        <Row gutter={[8, 16]} className="form-viewer-section layout-row-center">
                            <Col xs={24} sm={24} md={8}>
                                <div className={`org-avatar placeholder`}>
                                    {orgInfo?.avatar ? (
                                        <Avatar className="app-avatar layout-full" src={orgInfo?.avatar} />
                                    ) : (
                                        <Avatar className="app-avatar layout-full" icon={<TeamOutlined />} />
                                    )}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="container-for-fields">
                                <div className="form-viewer-item">
                                    <div className="item-title">{t("organization.name")}</div>
                                    <div className="item-value">{orgInfo?.organization_name || "_"}</div>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[8, 16]} className="form-viewer-section">
                            <Col xs={24} sm={24} md={8}>
                                <div className="form-viewer-title highlight-with-singlebar">
                                    {t("organization.organization_details")}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="container-for-fields">
                                {renderPlanInfo()}
                            </Col>
                        </Row>

                        {/* <Row gutter={[8, 16]} className="form-viewer-section">
                            <Col xs={24} sm={24} md={8}>
                                <div className="form-viewer-title highlight-with-singlebar">
                                    {t("organization.options")}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="container-for-fields">
                                {renderPlanOptions()}
                            </Col>
                        </Row> */}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="my-plan">
            <div className="plan-detail-wrapper">
                {/* Check plan owner to render suitable view (code this later): */}
                {false ? <>{renderPlanView()}</> : <>{renderPlanViewAsGuest()}</>}
            </div>

            <div className="plan-actions-wrapper">
                <CustomButton
                    type="ghost"
                    title={t("setting.extend_subscription")}
                    onClick={handleExtendsubscription}
                ></CustomButton>
                <CustomButton
                    type="ghost"
                    title={t("setting.buy_more_options")}
                    onClick={handleBuyMoreOptions}
                ></CustomButton>
            </div>

            <Spin spinning={loadingPayments}>
                <div className="order-list">
                    <Collapse defaultActiveKey={["orderitem0"]}>
                        {collapseItemsOfOrders.map((item, itemIndex) => {
                            return (
                                <Panel key={`orderitem${itemIndex}`} header={item.label}>
                                    {item.children}
                                </Panel>
                            );
                        })}
                    </Collapse>
                </div>
            </Spin>
        </div>
    );
}

export default MyPlan;
