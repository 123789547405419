import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Modal, Select, Spin, Switch, Tooltip, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { createUser, updateUser, updateUserExam } from "src/api/containers/user.js";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";

// import "./ModalDetailCourse.scss";

const ModalUpdateUser = ({
    userRole,
    courseId,
    examInfo,
    editingUser,
    visible,
    onCancel,
    onOk,
    refetch,
    className,
    ...rest
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    const handleCancelForm = (willFormBeCleared = false) => {
        if (willFormBeCleared === true) {
            form.resetFields();
        }
        onCancel();
    };

    useEffect(() => {
        if (editingUser) {
            switch (userRole) {
                case "student": {
                    const { personal_number, name, username } = editingUser || {};
                    form.setFieldsValue({
                        personal_number,
                        name,
                        username,
                    });
                    break;
                }
                case "teacher": {
                    const { name, username } = editingUser || {};
                    form.setFieldsValue({
                        name,
                        username,
                    });
                    break;
                }
                default:
                    break;
            }
        } else {
            form.resetFields();
        }
    }, [editingUser]);

    const handleSubmitForm = () => {
        if (!courseId) {
            return;
        }

        form.validateFields()
            .then(async (res) => {
                setLoading(true);
                let formData = form.getFieldsValue();

                const { name, personal_number, username } = formData;

                let payload;
                switch (userRole) {
                    case "student": {
                        payload = {
                            name,
                            personal_number,
                            course_id: courseId,
                            // username,
                        };
                        break;
                    }
                    case "teacher": {
                        payload = {
                            name,
                            course_id: courseId,
                            // username,
                        };
                        break;
                    }
                    default:
                        break;
                }

                try {
                    if (editingUser) {
                        const { status, message } = await updateUserExam(editingUser.id, payload);

                        if (status) {
                            notification.success({
                                message: t("update_success"),
                            });

                            onCancel();
                            refetch();
                        } else {
                            notification.error({
                                message,
                            });
                        }
                    }
                } catch (err) {
                    //eslint-disable-next-line
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            })
            .catch((err) => {
                //eslint-disable-next-line
                console.error(err);
            });
    };

    return (
        <Modal
            visible={visible}
            onOk={handleSubmitForm}
            onCancel={handleCancelForm}
            centered
            title={t("update_user")}
            closeIcon={<IconClose />}
            footer={null}
            width="auto"
            {...rest}
            className={clsx("modal-add-course", className)}
        >
            <Spin spinning={loading}>
                <Form form={form}>
                    <Form.Item label={t("profile.full_name")} name="name">
                        <Input placeholder={t("profile.full_name")} />
                    </Form.Item>

                    {userRole === "student" && (
                        <Form.Item
                            label={t("personal_number")}
                            name="personal_number"
                            rules={[{ required: !examInfo?.is_public, message: t("message.required") }]}
                        >
                            <Input placeholder={t("personal_number")} />
                        </Form.Item>
                    )}

                    <Form.Item
                        label={t("email")}
                        name="username"
                        // rules={[
                        //     { required: true, message: t("message.required") },
                        //     {
                        //         type: "email",
                        //         message: t("require_email"),
                        //     },
                        // ]}
                    >
                        <Input placeholder={t("email")} disabled={true} />
                    </Form.Item>

                    <div className="btn-group">
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={t("action.cancel")}
                            onClick={handleCancelForm}
                        ></CustomButton>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-save" />}
                            title={t("action.save")}
                            onClick={handleSubmitForm}
                        ></CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalUpdateUser;
