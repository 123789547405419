import { Dropdown } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";

import LatexOptions from "../LatexOptions";

// import "./LatexOptionsDropdown.scss";

const LatexOptionsDropdown = ({
    children,
    filterOptions,
    filterOptionSelected,
    onSelectOption,
    // Dropdown:
    placement,
    onDropdownMenuOpen,
    onDropdownMenuClose,
}) => {
    const [settings, setSettings] = useState({
        isOptionsMenuVisible: false,
    });

    const handleVisibleChange = (val) => {
        setSettings({ ...settings, isOptionsMenuVisible: val });
        if (val === true) {
            if (onDropdownMenuOpen instanceof Function) {
                onDropdownMenuOpen();
            }
        } else {
            if (onDropdownMenuClose instanceof Function) {
                onDropdownMenuClose();
            }
        }
    };

    return (
        <Dropdown
            visible={settings.isOptionsMenuVisible}
            overlay={
                <LatexOptions
                    filterOptions={filterOptions}
                    filterOptionSelected={filterOptionSelected}
                    onSelectOption={onSelectOption}
                />
            }
            trigger={["focus"]}
            placement={placement}
            onVisibleChange={handleVisibleChange}
            align={{ overflow: { adjustX: false, adjustY: false } }}
        >
            {children}
        </Dropdown>
    );
};

export default LatexOptionsDropdown;
