const { useRef, useEffect } = require("react");

function useEventListener(eventName, handler, element = window) {
    const saveHandler = useRef();
    const saveEventListener = useRef();

    useEffect(() => {
        saveHandler.current = handler;
    }, [saveHandler]);

    const cleanUp = () => {
        if (element && element.removeEventListener && saveEventListener.current) {
            element.removeEventListener(eventName, saveEventListener.current);
        }
    };

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        saveEventListener.current = (event) => saveHandler.current(event);

        element.addEventListener(eventName, saveEventListener.current);

        return () => {
            element.removeEventListener(eventName, saveEventListener.current);
        };
    }, [eventName, element]);

    return [cleanUp];
}

export default useEventListener;
