import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import Li2 from "../Li";
import { CorrectIcon, InCorrectIcon } from "src/utils/drawer";

import "./ShadeGrid.scss";

const ShadeGrid = ({
    cellWidth = 2,
    cellHeight = 2,
    rowCount = 1,
    colCount = 6,
    onCellClick = () => {},
    listActive = [], //[] active shade
    hidden = false,
    correctAnswers = [],
    correctAnswersPreview = [],
    showAnswers = false,
    lockedCells = [], //[] locked shade
    mode = "doing", // doing/ previewResult
    isReadonly = false,
    method,
    isShowCheckAnswers = false,
    isCorrectSubmited,
    uniq,
}) => {
    // 1 00 sẽ có các status là: visible, active, correct,dissable
    const rowsArray = Array(rowCount).fill(null);

    const columnsArray = Array(colCount).fill(null);
    const [values, setValues] = useValues({
        isAllCorrect: false,
    });

    const { t } = useTranslation();

    //check item(i,j) có nằm trong array lockedCells ko.
    const isLockedIndexExists = (i, j) =>
        Array.isArray(lockedCells) && lockedCells.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;

    const isShadeActive = (i, j) => listActive.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;

    const getActiveShadesCount = (shade) => {
        let count = 0;

        rowsArray.forEach((row, i) => {
            columnsArray.forEach((col, j) => {
                if (isShadeActive(i, j) && !isLockedIndexExists(i, j)) {
                    count++;
                }
            });
        });

        return count <= shade;
    };

    const checkIsItemExists = (i, j, answers) => {
        return Array.isArray(answers) && answers.findIndex((shade) => shade[0] === i && shade[1] === j) !== -1;
    };

    const isCorrectAnswer = (i, j) =>
        correctAnswers.findIndex((shade) =>
            Array.isArray(shade) ? shade[0] === i && shade[1] === j : getActiveShadesCount(shade)
        ) !== -1;

    let count = -1;

    const getVisibility = ({ rowIndex, columnIndex }) => {
        let res = "visible";

        hidden.forEach(([row, column]) => {
            if (row === rowIndex && column === columnIndex) {
                res = "hidden";
            }
        });

        return res;
    };

    const checkActive = (i, j) => {
        if (isReadonly && correctAnswers?.length > 0) {
            return checkIsItemExists(i, j, correctAnswers);
        }
        return checkIsItemExists(i, j, listActive);
    };

    useEffect(() => {
        //hàm check đáp án đúng sai khi nhấn hiện preview đáp án:
        // TH 1: by_count => chỉ cần đếm số lượng ô bằng sl ô của đáp án
        // TH 2: by_location => đếm số ô => nếu đủ thì tiếp tục check vị trí các ô có trùng khớp hay ko
        const CheckResultCorrect = (method, mode, correctAnswers, userAnswers) => {
            if (
                isShowCheckAnswers &&
                mode === "previewResult" &&
                Array.isArray(correctAnswers) &&
                Array.isArray(userAnswers)
            ) {
                if (method === "by_location") {
                    let flag = 0;
                    correctAnswers.map((item) => {
                        if (!checkIsItemExists(item[0], item[1], userAnswers)) {
                            flag = flag + 1;
                        }
                    });
                    return flag > 0 ? false : true;
                } else if (method === "by_count") {
                    return correctAnswers.length === userAnswers.length;
                }
            }
            return false;
        };

        if (isCorrectSubmited !== "notsubmit") {
            setValues({
                isAllCorrect: isCorrectSubmited,
            });
        } else if (CheckResultCorrect(method, mode, correctAnswersPreview, listActive)) {
            setValues({
                isAllCorrect: true,
            });
        } else {
            setValues({
                isAllCorrect: false,
            });
        }
    }, [isCorrectSubmited, uniq]);

    return (
        <>
            <div className="shade-answer-status">
                {isShowCheckAnswers &&
                    (values.isAllCorrect ? (
                        <span className="correct">
                            (<CorrectIcon />
                            <span style={{ marginLeft: 4 }}>{t("question.correct")})</span>
                        </span>
                    ) : (
                        <span className="incorrect">
                            ( <InCorrectIcon />
                            <span style={{ marginLeft: 4 }}>{t("question.incorrect")})</span>
                        </span>
                    ))}
            </div>
            <div className="shade-grid" style={{ width: colCount * cellWidth * 30 + colCount * 1 * 2 }}>
                {/* colCount*1*2: khoảng margin của item */}
                {rowsArray.map((row, i) => (
                    <ul
                        className="shade-grid-ul"
                        key={i}
                        style={{ width: colCount * cellWidth * 30 + colCount * 1 * 2 }}
                    >
                        {columnsArray.map((col, j) => {
                            if (isShadeActive(i, j)) count++;

                            return (
                                <Li2
                                    isShowIncorrect={isShowCheckAnswers}
                                    isAllCorrect={values.isAllCorrect}
                                    correct={
                                        isCorrectAnswer(i, j) ||
                                        (!Array.isArray(correctAnswers[0]) &&
                                            isShadeActive(i, j) &&
                                            correctAnswers[0] > count)
                                    }
                                    // visibility={getVisibility({ rowIndex: i, columnIndex: j })}
                                    showAnswers={showAnswers}
                                    locked={isLockedIndexExists(i, j)}
                                    active={checkActive(i, j)}
                                    // onClick={isLockedIndexExists(i, j) ? undefined : onCellClick(i, j)}
                                    onClick={() => onCellClick(i, j, mode)}
                                    height={cellHeight}
                                    width={cellWidth}
                                    key={j}
                                    value={{ i, j }}
                                    isReadonly={isReadonly}
                                />
                            );
                        })}
                    </ul>
                ))}
            </div>
        </>
    );
};

export default ShadeGrid;
