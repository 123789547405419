import { Table } from "antd";
import { t } from "i18next";
import React, { useMemo } from "react";
import { getColorForPercentChart } from "src/utils/helpers";
import "./ReportTable.scss";

const ReportTable = (props) => {
    const { data = [], isPagination = true, type = "unknown" } = props;

    const newData = useMemo(() => {
        return data.map((record) => {
            return {
                key: record._id,
                nameOfCourse: record.assignment.course.name,
                level: record.assignment.level?.name ?? t("shared.unknown"),
                assignment: record.assignment.exam_question.name,
                grasp: record.grasp,
                averageScore: record.score_percent,
            };
        });
    }, [data]);

    const columns = [
        {
            title: type != "unknown" ? t(`report.name_of_${type}`) : t(`course.name`),
            dataIndex: "nameOfCourse",
            align: "center",
        },
        {
            title: t("report.level"),
            dataIndex: "level",
            align: "center",
        },
        {
            title: type === "class" ? t("report.test") : t("report.exercise"),
            dataIndex: "assignment",
            align: "center",
        },
        // {
        //     title: t("report.grasp"),
        //     dataIndex: "grasp",
        //     align: "center",
        // },
        {
            title: t("report.average_score"),
            dataIndex: "averageScore",
            align: "center",
            sorter: {
                compare: (a, b) => {
                    return a.averageScore - b.averageScore;
                },
            },
            render: (text) => {
                const color = getColorForPercentChart(text);
                return (
                    <div className="reportTable_scoreBar" style={{ backgroundColor: color }}>
                        {text}%
                    </div>
                );
            },
        },
    ];

    return (
        <div className="reportTable">
            <div className="reportTable_header">
                <div className="reportTable_title">{t("report.test_classification")}</div>
            </div>
            <div className="reportTable_table">
                <Table
                    columns={columns}
                    dataSource={newData}
                    pagination={
                        isPagination
                            ? {
                                  pageSize: 9,
                                  position: ["bottomCenter"],
                              }
                            : isPagination
                    }
                />
            </div>
        </div>
    );
};

export default React.memo(ReportTable);
