import { pages } from "src/routes/pages";

import api from "..";

export const accessRoles = {
    guest: {
        homeRoute: pages.entrance.routePath,
    },
    student: {
        homeRoute: pages.exam_checkin.routePath,
        isRolePickerOption: true,
    },
    teacher: {
        homeRoute: "/dashboard",
        isRolePickerOption: true,
    },
};

export const login = (loginData) => {
    return api
        .post("/auth/login", loginData)
        .then((res) => res)
        .catch(({ message }) => ({ status: false, message }));
};

export const logOut = () => {
    return api
        .get("/logout")
        .then((res) => res)
        .catch(({ message }) => ({ status: false, message }));
};

export const getMe = () => {
    return api
        .get("/me")
        .then((res) => res)
        .catch(() => ({ status: false }));
};

//login with google
export const guestLoginWithGoogle = () => {
    return api
        .get("/auth/redirect/google/guest")
        .then(({ url }) => ({ url }))
        .catch((e) => {
            throw new Error(e);
        });
};

export const teacherLoginWithGoogle = () => {
    return api
        .get("/auth/redirect/google/teacher")
        .then(({ url }) => ({ url }))
        .catch((e) => {
            throw new Error(e);
        });
};

export const studentLoginWithGoogle = () => {
    return api
        .get("/auth/redirect/google/student")
        .then(({ url }) => ({ url }))
        .catch((e) => {
            throw new Error(e);
        });
};

export const loginWithGoogle = (roleCheck, search) => {
    return api
        .get(`/callback/google/${roleCheck}/${search}`)
        .then((res) => res)
        .catch((e) => {
            throw new Error(e);
        });
};

//login with facebook
export const guestLoginWithFacebook = () => {
    return api
        .get("/auth/redirect/facebook/guest")
        .then(({ url }) => ({ url }))
        .catch((e) => {
            throw new Error(e);
        });
};

export const teacherLoginWithFacebook = () => {
    return api
        .get("/auth/redirect/facebook/teacher")
        .then(({ url }) => ({ url }))
        .catch((e) => {
            throw new Error(e);
        });
};

export const studentLoginWithFacebook = () => {
    return api
        .get("/auth/redirect/facebook/student")
        .then(({ url }) => ({ url }))
        .catch((e) => {
            throw new Error(e);
        });
};

export const loginWithFacebook = (roleCheck, search) => {
    return api
        .get(`/callback/facebook/${roleCheck}/${search}`)
        .then((res) => res)
        .catch((e) => {
            throw new Error(e);
        });
};

//update role after login first time
export const updateRole = (id, formData) => {
    return api
        .put(`/auth/role/${id}`, formData)
        .then((res) => res)
        .catch((e) => {
            throw new Error(e);
        });
};

export const register = (loginData) => {
    return api
        .post("/auth/register", loginData)
        .then(({ status, message }) => ({ status, message }))
        .catch((e) => ({ status: false }));
};

export const verify = (verifyData) => {
    return api
        .post("/verify", verifyData)
        .then(({ status, message }) => ({ status, message }))
        .catch((e) => ({ status: false }));
};

export const forgotPassword = (username) => {
    return api
        .post("/auth/forgot_password", username)
        .then(({ status, message }) => ({ status, message }))
        .catch((e) => ({ status: false }));
};

export const resetPassword = (formData) => {
    const _formData = { ...(formData || {}) };
    const code_reset = Number(_formData.code_reset);
    _formData.code_reset = isNaN(code_reset) ? undefined : code_reset;
    return api
        .post("/auth/update_password", _formData)
        .then(({ status, message }) => ({ status, message }))
        .catch((e) => ({ status: false }));
};

export const resetPasswordTheFirstTime = (data) => {
    return api
        .post("/auth/active", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message }));
};

export const checkIfUserIsActive = (username) => {
    return api
        .get(`/auth/active/${username}`)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};
