import React, { useCallback, useEffect, useMemo, useRef } from "react";

import CorrectIcon from "src/assets/images/correct-icon.svg";
import InCorrectIcon from "src/assets/images/incorrect-icon.svg";
import StaticMath from "src/modules/components/StaticMath";

import "./MathAnswerResult.scss";

const MathAnswerResult = (props) => {
    const { template_latex: templateStr = "", value, correct_answer, isShowResult } = props;
    const staticRef = useRef(null);
    const StaticMathRef = React.createRef(null);

    const templateAnswer = useMemo(() => {
        const template = templateStr.replaceAll("\\embed{response}", "\\MathQuillMathField{}");

        return template;
    }, [templateStr]);

    useEffect(() => {
        if (staticRef.current) {
            staticRef.current.latex(templateAnswer);
        }
    }, [templateAnswer]);

    useEffect(() => {
        if (staticRef.current) {
            staticRef.current.innerFields.forEach((field, index) => {
                field.latex(value?.[index] || "");
                field.__controller.editable = false;
                const rootBlockEle = field.el().querySelector(".mq-root-block");

                if (isShowResult) {
                    const imageEle = document.createElement("img");

                    if (value?.[index] === correct_answer?.[index]) {
                        field.el().classList.add("correct_latex");
                        imageEle.src = CorrectIcon;
                    } else {
                        field.el().classList.add("incorrect_latex");
                        imageEle.src = InCorrectIcon;
                    }

                    rootBlockEle.append(imageEle);
                }
            });
        }
    }, [value]);

    return <StaticMath ref={StaticMathRef} staticMathRef={staticRef} />;
};

export default React.memo(MathAnswerResult);
