import { CloudUploadOutlined, DeleteOutlined, PlayCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Form, Input, notification, Radio, Row, Select, Space, Tooltip } from "antd";
import InputEditor from "src/modules/components/InputEditor";
import InputSuffix from "src/modules/components/InputSuffix";
import { BinIcon } from "src/utils/drawer";
import { Button as AntdButton } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import "./ListAnswer.scss";
import PopupFileManager from "src/modules/components/FileManager/popup";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";
import Button from "src/modules/components/Button";
import { useValues } from "src/hooks";
import PlayAudioBtn from "src/modules/components/PlayAudioBtn";
import Audio from "src/modules/components/Audio";

const defaultAnswer = [
    {
        actor: "",
        // src: "",
        value: "",
    },
];

const ListAnswer = (props) => {
    const { form, listActor, actorSelected, value, onChange } = props;
    const [values, setValues] = useValues({
        currentFile: null,
    });

    const fmController = PopupFileManager.useController();

    const { t } = useTranslation();

    const handleSelectAudio = (selectedFile) => {
        // console.log(selectedFile);
    };

    const handleAddAnswerItem = () => {
        if (!actorSelected?.actor) {
            notification.warning({
                message: t("speech_to_text_conversation.havent_choose_actor"),
            });
            return;
        }

        if (!value || (Array.isArray(value) && value.length === 0)) {
            // setData({
            //     answer: defaultAnswer,
            // });
            form.setFieldsValue({
                answer: defaultAnswer,
            });
        } else {
            // setData({
            //     answer: [...defaultAnswer, ...answer],
            // });
            form.setFieldsValue({
                answer: [...value, ...defaultAnswer],
            });
        }
    };

    const handleDeleteAnswerItem = (idx) => {
        const newAns = [...value];
        newAns.splice(idx, 1);
        // setData({
        //     answer: newAns,
        // });
        form.setFieldsValue({
            answer: newAns,
        });
    };

    return (
        <div className="conversation_list_answer">
            {value?.map((item, idx) => {
                return (
                    <Col key={idx} span={24}>
                        <Row gutter={[20, 20]} style={{ width: "100%" }}>
                            <Col span={4}>
                                <Form.Item
                                    name={["answer", idx, "actor"]}
                                    rules={[{ required: true, message: t("message.required") }]}
                                >
                                    <Select
                                        placeholder={t("speech_to_text_conversation.select_actor")}
                                        className="app-select how-arrow has-rd"
                                    >
                                        {listActor?.map((item, idx) => {
                                            return (
                                                <Select.Option key={idx} value={item?.actor}>
                                                    {item?.actor}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={20}>
                                <Space style={{ width: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {item?.actor !== actorSelected?.actor ? (
                                            <Space>
                                                <Space>
                                                    {item?.value && <Audio src={item?.value} />}
                                                    <Form.Item>
                                                        <Tooltip title={t("guide.upload")}>
                                                            <AntdButton
                                                                icon={<CloudUploadOutlined />}
                                                                shape="round"
                                                                onClick={() => {
                                                                    fmController.call("open");
                                                                    setValues({ currentFile: idx });
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Form.Item>
                                                </Space>
                                                <Form.Item
                                                    name={["answer", idx, "value"]}
                                                    rules={[{ required: true, message: t("message.required") }]}
                                                    style={{ width: "100%" }}
                                                    hidden={true}
                                                    className="answer-with-audio"
                                                >
                                                    <Input
                                                        className="app-input how-arrow has-rd"
                                                        onChange={() => {
                                                            // onChange([]);
                                                        }}
                                                        suffix={
                                                            item?.value ? (
                                                                <Tooltip title={t("shared.play")}>
                                                                    <PlayAudioBtn src={item.value}>
                                                                        {/* <PlayCircleOutlined /> */}
                                                                    </PlayAudioBtn>
                                                                </Tooltip>
                                                            ) : null
                                                        }
                                                    />
                                                </Form.Item>
                                            </Space>
                                        ) : (
                                            <Form.Item
                                                name={["answer", idx, "value"]}
                                                rules={[{ required: true, message: t("message.required") }]}
                                                style={{ width: "100%" }}
                                            >
                                                <TextArea
                                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                                    placeholder={t("speech_to_text_conversation.enter_sentence")}
                                                    // hidden={item?.actor !== actorSelected?.actor}
                                                    className="app-input"
                                                />
                                            </Form.Item>
                                        )}
                                    </div>
                                    <Tooltip title={t("shared.delete")}>
                                        <span
                                            className="conversation-item_delete"
                                            onClick={() => handleDeleteAnswerItem(idx)}
                                        >
                                            <DeleteOutlined />
                                        </span>
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                        <br />
                    </Col>
                );
            })}

            <Button
                icon={<PlusCircleOutlined />}
                title={t("shared.add_new")}
                type={"ghost"}
                onClick={() => handleAddAnswerItem()}
            />

            <PopupFileManager
                controller={fmController}
                isMultiple={false}
                onSelectFile={(selectedFile) => {
                    if (selectedFile?.id && selectedFile?.src) {
                        handleSelectAudio(selectedFile);
                        if (validateFileBeforeUpload(selectedFile.src).type === "audio") {
                            // form.setFieldValue("answer.0.src", "kkmm");
                            const data = value?.map((item, idx) => {
                                if (idx === values.currentFile) {
                                    return {
                                        ...item,
                                        value: selectedFile.src,
                                    };
                                }
                                return item;
                            });
                            form.setFieldsValue({ answer: data });
                            setValues({ currentFile: "" });
                            fmController.call("close");
                        } else {
                            const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                            notification.error({
                                message: (
                                    <>
                                        <div>{`
                                            ${t("message.err_file_extension_not_valid")}.
                                            ${t("message.please_try_another_file_extension")}!
                                        `}</div>
                                        <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                    </>
                                ),
                            });
                        }
                    }
                }}
            />
        </div>
    );
};

export default ListAnswer;
