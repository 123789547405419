import { vi_questions } from "./questions/vi";

export default {
    ...vi_questions,

    role_option: {
        student: "Học viên",
        teacher: "Giáo viên",
        pick_your_role: "Bạn là ai?",
    },

    account: {
        current_account: "Tài khoản hiện tại",
        // Actions:
        upgrade: "Nâng cấp",
        upgrade_now: "Nâng cấp ngay",
        contact_support: "Liên hệ tư vấn",
        pick_your_suitable_subscription_plan: "Các gói đăng ký",
    },

    plan: {
        plan_name: "Tên gói",
        option: "Tuỳ chọn",
        buy_more_options: "Mua thêm tuỳ chọn",
        select_options: "Thêm tuỳ chọn",
        disabled: "Không kích hoạt",
    },

    price: {
        amount: "Số tiền",
        total: "Tổng tiền",
        paid: "Đã thanh toán",
        vnd: "₫",
        vnd_per_month: "đ/tháng",
        free: "Miễn phí",
        month: "tháng",
    },

    payment: {
        payment: "Thanh toán",
        extend_subscription: "Gia hạn gói",
        buy_options: "Mua thêm tuỳ chọn",
        order: "Đơn hàng",
        promotion: "Phiếu mua hàng",
        payment_result: "Kết quả thanh toán",
        method_CASH: "Thanh toán bằng tiền mặt",
        method_TRANSFER: "Chuyển khoản ngân hàng",
        method_VISA: "Thanh toán VISA",
        method_ATM_VNPAY: "Thẻ ATM và tài khoản ngân hàng",
        method_VNMART: "Thanh toán với ví điện tử VNPAY",
        method_VNPAYQR: "Thanh toán quét mã VNPAY QR",
        method_PORTONE: "Thanh toán Online",
        method_ONEPAY_QR: "Thanh toán Ứng dụng di động ngân hàng và Ví điện tử",
        method_ONEPAY_DOMESTIC: "Thẻ nội địa và tài khoản ngân hàng",
        method_ONEPAY_INTERNATIONAL: "Thẻ thanh toán quốc tế",
        bank: "Ngân hàng",
        account_name: "Tên tài khoản",
        account_number: "Số tài khoản",
        payment_content: "Nội dung chuyển khoản",
        price: "Giá",
        paid: "Đã thanh toán",
        not_paid: "Chưa thanh toán",
        pay_error: "Lỗi thanh toán",
        card_field: {
            price_table: "Bảng giá",
            price_table_desc: "Dùng Stripe cho thanh toán online: thẻ, Paypal",
            ai_function: "Chức năng A.I.",
            ai_credit: "Credit A.I.",
            storage_available: "Dung lượng lưu trữ",
            limitof_asgmt_created: "Giới hạn tạo đề",
            limitof_q_created: "Giới hạn số lượng câu hỏi",
            limitof_q_in_exam: "Giới hạn số lượng câu hỏi trong một đề",
            limitof_candidates: "Giới hạn người làm bài",
            limitof_tests_created_from_an_asgmt:
                "Giới hạn giao bài test trên một đề (giới hạn số lần sử dụng của một đề)",
            limitof_employees: "Giới hạn nhân viên",
            cleanup: "Xóa dung lượng",
        },
        suffix: {
            questions: "Câu hỏi",
            exams: "Đề",
            candidates: "Thí sinh",
            tests_created_from_an_asgmt: "Lượt giao bài/đề",
            contact: "Liên hệ",
            unlimited: "Không giới hạn",
            user_custom: "Người dùng tự xóa",
            auto_30_days: "Tự động xóa sau 30 ngày",
        },
        // Description:
        method_CASH_please_make_payment_at: "Vui lòng đến thanh toán trực tiếp tại Phuong Nam Education.",
        method_CASH_payment_address: "Địa chỉ: 357 Lê Hồng Phong, P.2, Q.10, TP.HCM.",
        method_TRANSFER_description: "Ngay sau khi nhận được thanh toán của bạn, gói bạn chọn sẽ được kích hoạt.",
        method_PORTONE_description: "Hỗ trợ thanh toán qua ATM, VISA, MASTERCARD, ví điện tử.",
        viewtype_extend_desc: "Bạn đang gia hạn cho gói",
        viewtype_opts_desc: "Bạn đang mua thêm tuỳ chọn cho gói",
        // Actions:
        select_to_buy: "Chọn mua",
        unselect_to_buy: "Đã chọn mua",
        purchase: "Thanh toán",
        input_promotion_code_or_not: "Nhập mã giảm giá (nếu có)",
        select_plan_price: "Chọn một gói",
        // Messages:
        err_can_not_purchase: "Lỗi thanh toán",
        err_can_not_apply_promocode: "Lỗi áp dụng mã giảm giá",
        please_input_promocode: "Hãy nhập mã giảm giá",
        please_select_a_plan: "Hãy chọn một gói",
        please_select_a_payment_method: "Hãy chọn phương thức thanh toán",
        cannot_apply_more_promocodes: "Bạn không thể áp dụng thêm mã giảm giá",
        sucess_make_payment_transaction: "Bạn đã hoàn tất thanh toán",
        fail_make_payment_transaction: "Bạn chưa hoàn tất thanh toán",
        this_code_is_already_used: "Code này đã được dùng",
    },

    audio: "Âm thanh",
    phonetic: "Phiên âm",
    username: "Tài khoản",
    password: "Mật khẩu",
    first_name: "Tên",
    last_name: "Họ",
    full_name: "Họ và tên",
    email: "Email",
    active: "Hoạt động",
    inactive: "Không hoạt động",
    role: "Vai trò",
    student: "Học sinh",
    teacher: "Giáo viên",
    employee: "Nhân viên",
    employee_crm: "Nhân viên CRM",
    admin: "Quản trị viên",
    unknown: "Không rõ",
    status: "Trạng thái",
    no_name_yet: "Chưa có tên",
    // Action:
    select_role: "Chọn vai trò",
    add_user: "Thêm người dùng",
    update_user: "Cập nhật người dùng",
    update_success: "Cập nhật thành công",
    find_user: "Tìm người dùng",

    // Message:
    add_new_success: "Thêm mới thành công",
    require_role: "Yêu cầu chọn chọn vài trò",
    require_username: "Yêu cầu nhập tài khoản",
    require_email: "Yêu cầu nhập email",
    require_password: "Yêu cầu nhập mật khẩu",
    delete_error: "Xóa thất bại",
    delete_success: "Xóa thành công",
    choose_role: "Chọn vai trò",
    choose_status: "Chọn trạng thái",
    scored_manually: "Đã chấm tay",

    download_sample_file: "Tải file mẫu để nhập hàng loạt",
    batch_import: "Nhập hàng loạt",
    batch_import_teacher: "Nhập danh sách (giáo viên)",
    batch_import_student: "Nhập danh sách (học viên)",
    import_user_success: "Tải file lên thành công, quá trình import dữ liệu có thể mất vài phút hoặc lâu hơn",
    add_user_fail: "Thêm người dùng không thành công",
    export: "Xuất file",
    an_error_occurred: "Đã có lỗi xảy ra",

    // Tooltip:
    tooltip_update_password_user: "Bỏ trống trường này sẽ giữ lại mật khẫu cũ",

    reset_success: "Reset thành công",

    code_sent_success: "Gửi mã thành công. Vui lòng kiểm tra email",

    code_sent: "Đã gửi mã",
    resent_code: "Gửi lại mã",
    email_verification: "Xác thực email",
    email_erification_code: "Mã xác thực email",
    your_photo_not_valid: "Ảnh của bạn không hợp lệ. Vui lòng thử ảnh khác",
    valid_photo: "Ảnh hợp lệ",
    checking_image: "Đang kiểm tra hình ảnh",
    your_image: "Ảnh của bạn",
    image_form_webcam: "Ảnh chụp từ webcam",
    verified_successfully: "Xác minh thành công",
    verification_failed: "Xác minh thất bại",
    please_choose: "Vui lòng chọn",
    faces_not_match: "Khuôn mặt không khớp",
    loading_resources: "Đang tải tài nguyên",
    image_processing_please_wait: "Đang xử lý hình ảnh, vui lòng đợi trong giây lát",
    take_photo: "Chụp ảnh",
    face_verification: "Xác minh khuôn mặt",
    faces_cannot_detected: "Không phát hiện khuôn mặt trong ảnh",
    please_take_another_photo: "Vui lòng chụp lại ảnh khác",
    next: "Tiếp theo",
    error_occurred_please_reload: "Có lỗi xảy ra khi tải tài nguyên, vui lòng reload lại trang!",
    this_behavior_considered_fraud: "Hành vi này có thể bị coi là gian lận",
    opening_devtool_not_allowed: "Không được phép mở devtool",
    please_use_another_photo: "Vui lòng sử dụng ảnh khác",
    many_faces_detected: "Phát hiện có nhiều khuôn mặt trong ảnh",
    no_face_detected: "Không phát hiện khuôn mặt trong ảnh",
    no_taken_photo_from_webcam: "Bạn chưa chụp ảnh từ webcam",
    please_wait_a_moment: "Vui lòng đợi giây lát",
    personal_number: "Mã số",
    upload_or_drag: "Nhấn để tải ảnh lên hoặc kéo thả vào đây",
    id_photo_verification: "Xác minh ảnh ID",
    warning: "Cảnh báo",
    violation_warning: "Cảnh báo vi phạm",
    times: "Lần",

    breadcumb: {
        goback: "Quay lại",
    },

    help: {
        help: "Hướng dẫn",
        next: "Tiếp tục",
        pre: "Quay lại",
        nodata: "Không có dữ liệu",
    },

    teacher_courses: {
        //Course Card Item
        progress: "Tiến độ",
        assignments: "Bài kiểm tra",
        exercise: "Bài tập",
        list_lesson: "Danh sách bài học",
        empty_courses: "Không có khóa học nào",
        find_courses_by_user: "Tìm khóa học theo người dùng",
        find_class_by_user: "Tìm lớp theo người dùng",
    },

    student_courses: {
        //Course Card Item
        study: "Học bài",
        score: "Điểm số",
        progress: "Tiến độ",
        assignments: "Bài kiểm tra",
        exercises: "Bài tập",
        list_lesson: "Danh sách bài học",
        empty_courses: "Không có khóa học nào",
    },

    shared: {
        from: "Từ",
        to: "Đến",
        from_date: "Từ ngày",
        to_date: "Đến ngày",
        info: "Thông tin",
        // Title:
        warning: "Cảnh báo",
        // Info:
        fullname: "Họ và tên",
        phonenumber: "Số điện thoại",
        message: "Tin nhắn",
        // Scope:
        scope: "Phạm vi",
        public: "Công khai",
        private: "Chỉ mình tôi",
        only_me: "Chỉ mình tôi",
        // Others:
        quantity: "SL",
        quantity_abbr: "SL",
        no_data: "Không có dữ liệu",
        exit: "Thoát ra",
        loading: "Đang tải",
        redirecting: "Đang chuyển hướng",
        searching: "Đang tìm kiếm",
        search: "Tìm kiếm",
        check: "Kiểm tra",
        checking: "Đang kiểm tra",
        preparing: "Đang chuẩn bị",
        try_again: "Thử lại",
        setting: "Thiết lập",
        signout: "Đăng xuất",
        save: "Lưu",
        back: "Quay lại",
        confirm: "Xác nhận",
        clone: "Sao chép",
        apply: "Áp dụng",
        category: "Danh mục",
        tags: "Thẻ",
        level: "Cấp độ",
        owner: "Người tạo",
        question: "Câu hỏi",
        exam: "Đề",
        type: "Loại",
        status: "Trạng thái",

        created_at: "Ngày tạo",
        updated_at: "Ngày cập nhật",
        choose_category: "Chọn danh mục",
        choose_tags: "Chọn tags",
        choose_type: "Chọn loại",
        choose_status: "Chọn trạng thái",
        choose_public: "Chọn công khai",
        score: "Điểm",
        total_score: "Tổng điểm",
        answer: "Đáp án",
        save_draft: "Lưu bản nháp",
        publish: "Xuất bản",
        add_new_question: "Thêm câu hỏi mới",
        add_new_exam: "Thêm đề bài mới",
        find_question: "Tìm câu hỏi",
        find_exam: "Tìm đề thi",
        find_assignment: "Tìm kiếm",
        add_assignment: "Giao bài",
        option_filter: "Bộ lọc",
        unknown: "Không rõ",
        search_and_select_category: "Tìm và chọn danh mục...",
        search_and_select_tags: "Tìm và chọn thẻ...",
        search_and_select_classes: "Tìm và chọn lớp học...",
        mark: "Chấm điểm",
        copy_invite_link: "Sao chép link",
        copy_invite_link_success: "Đã sao chép link",
        or: "hoặc",
        copy_the_link_below: "Sao chép đường link dưới đây",
        active: "Hoạt động",
        inactive: "Không hoạt động",
        all: "Tất cả",
        is_removed: "Đã bị xóa",
        // Fields:
        note: "Ghi chú",
        note2: "Lưu ý",
        // Actions:
        ok: "Đồng ý",
        cancel: "Hủy",
        yes: "Có",
        no: "Không",
        reset: "Đặt lại",
        add: "Thêm",
        add_new: "Thêm mới",
        update: "Cập nhật",
        edit: "Chỉnh sửa",
        delete: "Xóa",
        copy: "Sao chép",
        submit: "Gửi",
        view_detail: "Xem chi tiết",
        upate_image: "Cập nhật ảnh",
        select_scope: "Chọn phạm vi",
        contact_us: "Liên hệ tư vấn",
        select_files: "Chọn tệp",
        download: "Tải xuống",
        upload: "Tải lên",
        uploading: "Đang tải lên",
        uploaded: "Đã tải lên",
        // Confirm:
        confirm_cancel: "Hủy",
        confirm_reset: "Đặt lại",
        confirm_update: "Cập nhật",
        confirm_delete: "Xóa",
        confirm_leave: "Rời",
        confirm_action: "Xác nhận",
        msg_confirm_cancel: "Bạn có chắc chắn muốn hủy",
        msg_confirm_reset: "Bạn có chắc chắn muốn đặt lại?",
        msg_confirm_delete: "Bạn có chắc chắn muốn xóa",
        msg_confirm_leave: "Bạn có chắc chắn muốn rời đi?",
        msg_confirm_action: "Bạn có chắc chắn muốn thực hiện hành động này",
        msg_confirm_action_of: "Bạn có chắc chắn muốn",
        msg_confirm_action_of_demo_account: "Bạn có chắc chắn muốn đăng nhập tài khoản thử nghiệm",
        msg_confirm_action_of_main_account: "Bạn có chắc chắn muốn quay lại tài khoản chính",
        msg_confirm_redo: "Bạn có muốn làm lại bài này?",
        // Message:
        warning_demo_account: "Bạn đang đăng nhập với tài khoản thử nghiệm!",
        you_dont_have_permission: "Bạn không có quyền làm điều này!",
        input_fullname: "Hãy nhập họ và tên của bạn",
        input_phonenumber: "Hãy nhập số điện thoại của bạn",
        input_message: "Hãy nhập tin nhắn",
        input_email: "Hãy nhập địa chỉ email của bạn",
        //user
        gender: "Giới tính",
        male: "Nam",
        female: "Nữ",
        find: "Tìm kiếm...",
        select_image: "Chọn ảnh",

        //qrcode
        download_qr: "Tải xuống mã QR",
        set_correct_answer: "Chọn đáp án đúng",
        close_modal: "Đóng",
        play: "Phát nhạc",
        go: "GO",
        list_end: "Đã hết",

        width: "Chiều rộng",
        height: "Chiều dài",
        show: "Hiển thị",
        showing: "Đang hiển thị",
        hide: "Ẩn",
        is_hiding: "Đang ẩn",
        start_doing: "Bắt đầu làm",
        course_or_class: "Khóa học/Lớp",

        something_went_wrong: "Rất tiếc! Đã xảy ra sự cố",
    },

    html_status_code: {
        page_404_title: "Không tìm thấy trang!",
        page_404_msg: "Xin lỗi, chúng tôi không thể tìm thấy trang bạn đang tìm kiếm",
        action_go_back_home: "Quay lại trang chủ",
    },

    action: {
        add_new: "Thêm mới",
        update: "Cập nhật",
        delete: "Xóa",
        save: "Lưu",
        back: "Quay lại",
        confirm: "Xác nhận",
        apply: "Áp dụng",
        yes: "Có",
        no: "Không",
        ok: "Đồng ý",
        cancel: "Hủy",
        signout: "Đăng xuất",
        clone: "Sao chép",
        copy_invite_link: "Sao chép link",
        copy_invite_link_success: "Đã sao chép link",
        // Confirm:
        confirm_delete: "Xóa",
        confirm_action: "Xác nhận",
        msg_confirm_cancel: "Bạn có chắc chắn muốn hủy",
        msg_confirm_delete: "Bạn có chắc chắn muốn xóa",
        msg_confirm_action: "Bạn có chắc chắn muốn thực hiện hành động này",
        msg_confirm_action_of: "Bạn có chắc chắn muốn",
        verify_again: "Xác minh lại",
        update_info_again: "Cập nhật lại thông tin",
        change_pages_after: "Chuyển trang sau",
        view: "Xem",
    },

    language: {
        language: "Ngôn ngữ",
        vi: "Tiếng Việt",
        en: "Tiếng Anh",
        select_language: "Chọn ngôn ngữ",
    },

    header: {
        // Layout header:
        say_hi: "Xin chào",
        // Page header:
        go_back: "Quay lại",
        modify: "Chỉnh sửa",
        preview: "Xem trước",
        question_bank: "Ngân hàng câu hỏi",
        exam_bank: "Ngân hàng đề bài",
        say_hello: "Xin chào",
        assignment: "Bài tập",
        exam: "Bài kiểm tra",
        organization: "Tổ chức",
        my_exam: "Đề thi của tôi",
        my_questions: "Câu hỏi của tôi",
        help: "Hướng dẫn",
        guide: "Hướng dẫn",
        manage_user: "Quản lý người dùng",
    },

    nav: {
        notification: "Thông báo",
        account: "Tài khoản",
        profile: "Hồ sơ",
        account_info: "Thông tin tài khoản",
        logout: "Đăng xuất",

        // Role - teacher:
        courses: "Khoá học",
        class: "Quản lý lớp",
        question_bank: "Ngân hàng câu hỏi",
        exam_bank: "Ngân hàng đề",
        assignment: "Giao bài kiểm tra",
        learning_progress: "Tiến độ học tập",
        personal_leaning_progress: "Tiến độ học tập cá nhân",
        my_storage: "Lưu trữ",
        organization: "Tổ chức",
        my_resources: "Tài nguyên của tôi",
        my_resources_question: "Câu hỏi",
        my_resources_exam: "Đề thi",

        // Role - student:
        exam_asset: "Tài nguyên bài thi",
        classes: "Lớp học",
        assignments: "Bài được giao",
        scores: "Điểm số",
        gradebook: "Gradebook",
        score_gradebook: "Điểm - gradebook",
        score_assignment: "Điểm - bài kiểm tra",

        guide: "Hướng dẫn",
        manage_user: "Quản lý người dùng",
        feedback: "Feedback",
        history: "Lịch sử",
        action_statistics: "Thống kê hành động",
        dashboard: "Dashboard",
    },

    select_question: {
        // Messages:
        null: "Chưa chọn loại câu hỏi",
        tutorial_tooltip: "Nhấn vào tôi để hiện thị hướng dẫn cho các loại câu hỏi",

        scoring_manual: "Chấm điểm tay",
        scoring_auto: "Chấm điểm tự động",
    },

    question: {
        no_question: "Không có câu hỏi",
        question: "Câu hỏi",
        question_number_list: "Danh sách câu hỏi",
        show_answer: "Hiện đáp án",
        empty_answer: "Đáp án đã bị bỏ trống",
        // Type:
        multiple_choice: "Trắc nghiệm",
        multiple_choice_boolean: "Trắc nghiệm (đúng sai)",
        multiple_choice_table: "Trắc nghiệm (bảng)",
        writing: "Bài tập viết",
        fill_in_the_blank: "Điền vào chỗ trống (chọn)",
        fill_in_the_blank_text: "Điền vào chỗ trống (viết)",
        fill_in_the_blank_drag_drop: "Điền vào chỗ trống (kéo thả)",
        passage: "Đoạn văn",
        sort: "Sắp xếp",
        drag_drop: "Kéo thả",
        drag_drop_group: "Kéo thả nhóm",
        highlight: "Highlight",
        highlight_image: "Đánh dấu  vào hình",
        writing_short: "Viết(câu ngắn)",
        hotspot: "Hotspot",
        fill_in_the_blank_image: "Chọn đáp án đúng trong ảnh",
        fill_in_the_blank_text_image: "Điền vào chỗ trống trong ảnh",
        fill_in_the_blank_drag_drop_image: "Kéo thả vào hình ảnh",
        speech_to_text: "Nói thành văn bản",
        speech_to_text_conversation_audio: "Đoạn hội thoại(audio)",
        speech_to_text_conversation_text: "Đoạn hội thoại(text)",
        phonetic_table: "Bảng phiên âm",

        // Fields:
        owner: "Người sở hữu",
        updater: "Người cập nhật",
        owner_me: "Tôi",
        owner_all: "Tất cả",
        done: "Xong",
        draft: "Bản nháp",
        private: "Chỉ mình tôi",
        public: "Công khai",
        program: "Chương trình",
        level: "Cấp độ",
        tags: "Thẻ",
        answer: "Đáp án",
        group: "Nhóm",
        score: "Điểm",
        publishing_status: "Trạng thái xuất bản",
        only_me: "Chỉ mình tôi",
        type: "Loại",
        status: "Trạng thái",
        total_score: "Tổng điểm",
        created_at: "Ngày tạo",
        updated_at: "Ngày cập nhật",
        // Actions:
        view: "Xem",
        copy: "Sao chép",
        save: "Lưu",
        cancel: "Hủy",
        input_answer: "Nhập đáp án",
        choose_level: "Chọn level",
        choose_tags: "Chọn tags",
        choose_type: "Chọn loại",
        choose_status: "Chọn trạng thái",
        choose_public: "Chọn công khai",
        save_draft: "Lưu bản nháp",
        publish: "Xuất bản",
        message_help_group: "Đây là cột dùng để nhóm các đáp án",
        message_choose_the_correct_answer: "Kéo thả đáp án đúng vào nhóm",
        please_input_groups: "Vui lòng điền tên nhóm",
        choose_option: "Chọn một tùy chọn",
        select_answer: "Chọn đáp án",
        select_question_type: "Chọn loại câu hỏi",
        select_level: "Chọn cấp độ",
        select_tags: "Chọn các thẻ",
        select_owner: "Chọn người sở hữu",
        input_new_tag: "Tên thẻ mới...",
        input_new_tag_color: "Màu thẻ (hex)",
        required_publishing_status: "Bạn chưa chọn trạng thái xuất bản!",
        correct_answer: "Đáp án đúng",
        your_answer: "Đáp án của bạn",
        add_new_question: "Thêm câu hỏi mới",
        find_question: "Tìm câu hỏi",
        option_filter: "Bộ lọc",
        search_and_select_level: "Tìm và chọn cấp độ...",
        search_and_select_tags: "Tìm và chọn thẻ...",
        // Sections:
        header_multiple_choice: "Trắc nghiệm",
        header_highlight: "Tô đậm",
        header_fill_in_the_blanks: "Điền vào chỗ trống (chọn)",
        header_fill_in_the_blanks_text: "Điền vào chỗ trống (viết)",
        header_writing: "Viết",
        header_sort: "Sắp xếp",
        header_drag_drop: "Kéo thả",
        header_drag_drop_group: "Kéo thả nhóm",
        header_passage: "Đoạn văn",
        header_passage_add_child: "Thêm đoạn văn",
        hint_create_blanks_1: "Chọn nút",
        hint_create_blanks_2: "để tạo ô trống",
        hint_highlight_1: "Bôi (từ, câu, đoạn văn) sau đó nhấn vào nút",
        hint_highlight_2: "để chọn đáp án đúng",
        // Others:
        personal_learning_progress: "Tiến độ học tập cá nhân",
        message_shuffle: "Đáp án sẽ được trộn khi học sinh làm bài",
        help_highlight: "Bôi (từ, câu, đoạn văn) sau đó nhấn vào nút Highlight để chọn đáp án đúng",
        question_skipped: "Câu này đã bị bỏ qua",

        method: "Phương thức",
        correct: "Chính xác",
        incorrect: "Không chính xác",
        show_pinyin: "Hiển thị Pinyin",
        hide_pinyin: "Ẩn Pinyin",
    },

    question_panel: {
        question: "Câu",
        write_here: "Viết bài ở đây",
    },

    question_writing: {
        word_limit: "Giới hạn số từ",
    },

    question_fillblanks: {
        add_answer: "Thêm đáp án",
        require_answers: "Hãy điền danh sách đáp án",
        is_require_uppercase: "Kiểm tra viết hoa/viết thường",
    },

    question_multiple_choice: {
        index: "Câu",
        answer: "Đáp án",
        blank_answer: "Vui lòng điền đáp án",
        blank_result: "Vui lòng chọn ít nhất một kết quả",
        save_as_draft_success: "Lưu thành công",
        save_as_draft_error: "Lưu bản nháp thất bại",
        must_choose_two_or_more_answers: "Phải chọn hai đáp án trở lên",
    },

    question_passage: {
        add_paragraph: "Thêm đoạn văn",
        add_question: "Thêm câu hỏi",
        quick_add_question: "Thêm câu hỏi nhanh",
        heading: "Mô tả",
        title: "Tiêu đề",
        placeholder_heading: "Mô tả đoạn văn",
        placeholder_title: "Tiêu đề đoạn văn",
        require_question_data: "Dữ liệu câu hỏi không được để trống!",
        select_language_first: "Bạn cần phải chọn ngôn ngữ trước!",
        btn_toggle_note_extend: "Bấm vào mũi tên để mở rộng khung làm bài",
        btn_toggle_note_down: "Bấm vào mũi tên để thu hẹp khung làm bài",
    },

    question_dragdrop: {
        add_answer: "Thêm đáp án",
        require_answers: "Hãy điền danh sách đáp án",
    },

    question_dragdrop_group: {
        add_answer: "Thêm đáp án",
        require_corresponding_answers: "Vui lòng điền đáp án tương ứng",
        require_answers: "Vui lòng điền đáp án",
    },

    question_sort: {
        add_answer: "Thêm đáp án",
        require_answers: "Hãy điền danh sách đáp án",
    },

    create_multiple_choice: {
        answer: "Đáp án",
        blank_answer: "Vui lòng điền đáp án",
        blank_result: "Vui lòng chọn ít nhất một kết quả",
        save_as_draft_success: "Lưu thành công",
        save_as_draft_error: "Lưu bản nháp thất bại",
    },

    gradebook: {
        my_learning_path: "My Learning Path",
        unknown: "Không rõ",
        //table header
        activity: "Hoạt động",
        level: "Cấp độ",
        score: "Điểm số",
        last_taken: "Lần làm cuối",
        attempts: "Số lần nộp",
        time_spend: "Thời gian",
        re_do: "Làm lại",
        do_continue: "Làm tiếp",
        view_score: "Xem điểm",
        do: "Làm bài",
        expired: "Hết hạn",
        begin_date: "Ngày bắt đầu",
        end_date: "Ngày kết thúc",
        max_score: "Điểm tối đa",
        count_submit: "Đã nộp",
        count_marked: "Đã chấm điểm",
        percent: "Phần trăm",
        loadmore: "Tải thêm...",
        empty_exam: "Không có bài tập nào",

        //filter
        unit: "Unit",
        course: "Khóa học",
        filter: "Lọc",
        assigned: "Đã nộp",
        all: "Tất cả",
        clear: "Xóa bộ lọc",
        apply: "Áp dụng",
        sort: "Sắp xếp",
        asc: "Tăng dần",
        desc: "Giảm dần",
        please_select_sort_type: "Vui lòng chọn loại sắp xếp",
        please_select_level: "Vui lòng chọn cấp độ",
        please_select_unit: "Vui lòng chọn unit",
        please_select_course: "Vui lòng chọn khóa học",

        doing: "Đang làm",
        donot: "Chưa làm",
        marked: "Đã chấm",
        submited: "Đã nộp",
        redo: "Làm lại",
        review: "Xem lại",
    },

    course: {
        name: "Tên",
        course_name: "Tên khóa học",
        type: "Loại",
        level: "Cấp độ",
        start_date: "Ngày bắt đầu",
        end_date: "Ngày kết thúc",
        complete: "Hoàn thành",
        teacher: "Giáo viên",
        responsible: "Người phụ trách",
        employee: "Nhân viên",
        student: "Học sinh",
        bgImage: "Ảnh nền",
        // Messages:
        student_no_courses: "Bạn chưa tham gia khóa học nào",
        teacher_no_courses: "Bạn không có khóa học nào",
        courses_empty: "Không có khóa học nào",
        classes_empty: "Không có lớp học nào",
        // Titles:
        // course_list: "Danh sách khóa học",
        courses: "Các khóa học",
        classes: "Các lớp học",
        your_courses: "Khóa học của bạn",
        your_classes: "Lớp học của bạn",
        // Status:
        all: "Tất cả",
        not_started_yet: "Chưa bắt đầu",
        active: "Hoạt động",
        finished: "Kết thúc",
        // Actions:
        learn: "Học bài",
        score: "Điểm số",
        add_course: "Thêm khóa học",
        load_more_courses: "Xem thêm khóa học",
        select_level: "Chọn cấp độ",
        input_course_bgImage: "Chọn ảnh nền",
        // Others:
        course: "Khóa học",
        assignment: "Bài tập",
        list_course: "Danh sách khóa học",
        active_course: "Khóa học đang hoạt động",
        finished_course: "Khóa học đã kết thúc",
        code: "Mã khóa học",
        begin_date: "Ngày bắt đầu",
        no_teacher: "Chưa có giáo viên",
        load_more_course: "Xem thêm",
        update_course: "Cập nhật thông tin khóa học",
        add_student: "Thêm học viên",
        add_teacher: "Thêm giáo viên",
        invite_student: "Mời học viên",
        invite_teacher: "Mời giáo viên",
        invite_teacher_marking: "Mời giáo viên chấm chung",
        select_student: "Chọn học viên",
        select_teacher: "Chọn giáo viên",
        upload_student_list: "Tải lên danh sách học sinh",
        manage_course: "Quản lý Khóa học",
        manage_user: "Quản lý người dùng",
        manage_student: "Quản lý học sinh",
        manage_exam: "Quản lý bài kiểm tra",
        student_name: "Tên học sinh",
        phone_number: "Số điện thoại",
        email: "Email",
        gender: "Giới tính",
        role: "Vai trò",
        male: "Nam",
        female: "Nữ",
        unknown: "Không rõ",
        status: "Trạng thái",
        password: "Mật khẩu",
        add_exam: "Tạo bài kiểm tra mới",
        assign_exam: "Giao bài kiểm tra",
        exam_name: "Tên bài kiểm tra",
        max_score: "Điểm tối đa",
        submission: "Đã nộp bài",
        graded: "Đã chấm điểm",
        edit_list: "Chỉnh sửa danh sách",
        done: "Xong",
        student_amount: "Tổng số học sinh",
        confirm_delete: "Bạn có chắc chắn xóa?",
        will_delete: "sẽ bị xóa khỏi danh sách. Bạn có chắc chắn?",
        input_course_code: "Nhập mã khóa học",
        input_course_name: "Nhập tên khóa học",
        input_begin_date: "Nhập ngày bắt đầu",
        input_end_date: "Nhập ngày kết thúc",
        input_teacher_name: "Nhập tên giáo viên",
        input_exam_name: "Nhập tên bài kiểm tra",
        select_exam: "Chọn bài kiểm tra",
        select_assignment: "Chọn bài tập",
        input_assignment_name: "Nhập tên mới cho bài tập",
        input_assignment_description: "Nhập mô tả",
        select_course: "Chọn khóa học",
        duration: "Thời gian làm bài",
        input_time: "Phút",
        exam: "Bài kiểm tra",
        assignment_name: "Tên bài tập",
        assignment_description: "Mô tả",
        ownership: "Quyền sở hữu",
        me: "Tôi",
        mark_assignment: "Thao tác",
        assignment_result: "Kết quả",
        learning_progress: "Tiến độ học tập",
        report: "Báo cáo",
        start_time: "Thời gian bắt đầu",
        end_time: "Thời gian nộp bài",
        count_correct_sentences: "Số câu đúng",
        // Invitation:
        send_invitation: "Mời",
        send_invitation_success: "Gửi lời mời thành công",
        send_invitation_error: "Gửi lời mời thất bại",
        you_are_not_teacher: "Bạn không phải giáo viên",
        you_are_not_student: "Bạn không phải học viên",
        // Invite teacher:
        teacher_email: "Email giáo viên",
        add_teacher_not_others: "Chỉ giáo viên mới được tham gia",
        add_teacher_success: "Thêm giáo viên thành công",
        add_teacher_error: "Không thể thêm giáo viên",
        // Invite student:
        add_student_not_others: "Chỉ học viên mới được tham gia",
        add_student_success: "Thêm học viên thành công",
        add_student_error: "Không thể thêm học viên",
        // Assignment type:
        listening: "Nghe",
        speaking: "Nói",
        reading: "Đọc",
        writing: "Viết",
        vocabulary: "Từ vựng",
        grammar: "Ngữ pháp",
        review: "Ôn tập",

        load_more: "Tải thêm",
        my_class: "Lớp bạn phụ trách",
        class_code: "Mã lớp",
        update_class: "Cập nhật thông tin lớp học",
        select_course_or_class: "Chọn khóa học hoặc lớp học",
        student_id: "Mã học viên",
    },

    unit: {
        // Fields:
        name: "Tên",
        title: "Tiêu đề",
        order: "Số thứ tự",
        // Actions:
        manage_unit: "Quản lý chủ đề",
        add_unit: "Thêm chủ đề",
        update_unit: "Cập nhật chủ đề",
        input_unit_name: "Nhập tên chủ đề",
        input_unit_title: "Nhập tiêu đề cho chủ đề",
        input_unit_order: "Nhập số thứ tự",
        input_course_order: "Nhập số thứ tự",
        add_error_missing_course_id: "Không rõ khóa học để thêm chủ đề",
        add_error_missing_fields: "Bạn phải nhập đủ thông tin chủ đề",
        load_more_units: "Xem thêm chủ đề",
        // Messages:
        unit_list_empty: "Không có chủ đề nào",
        do_homework: "Làm bài",
        empty_unit_assignment: "Không có bài học nào",
        redo: "Làm lại bài",
    },

    login: {
        welcome_back: "Chào mừng bạn quay lại!",
        login_to_experience: "Đăng nhập để trải nghiệm ngay",
        enter_your_email: "Nhập email của bạn",
        password: "Mật khẩu",
        retype_password: "Nhập lại mật khẩu",
        remember: "Ghi nhớ",
        forget_password: "Quên mật khẩu?",
        sign_in: "Đăng nhập",
        sign_up: "Đăng ký",
        or_login_with: "Hoặc đăng nhập bằng tài khoản",
        or_register_with: "Hoặc đăng ký bằng tài khoản",
        login_with_facebook: "Facebook",
        login_with_google: "Google",
        dont_have_account: "Bạn chưa có tài khoản?",
        free_register: "Đăng ký miễn phí",
        start_signup_free: "Bắt đầu tạo tài khoản miễn phí",
        intro:
            "Tham gia cộng đồng giáo viên đang phát triển đang sử dụng phương pháp đánh giá Test Online để thúc đẩy học tập",
        who_are_you: "Bạn là ai?",
        teacher: "Giáo viên",
        student: "Học sinh",
        sign_up_now: "Đăng ký ngay",
        please_fill_in_the_form: "Vui lòng điền các thông tin bên dưới",
        still_remember: "Bạn vẫn nhớ mật khẩu?",
        send: "Gửi",
        enter_mail_to_reset_password: "Vui lòng nhập email đăng ký bên dưới để nhận được hướng dẫn lấy lại mật khẩu",
        email_sent: "Email đã được gửi!",
        send_error: "Gửi email không thành công!",
        reset_password: "Tạo mật khẩu mới",
        rule_new_password: "Mật khẩu mới phải khác mật khẩu trước đây mà bạn từng tạo",
        receive_code: "Nhận mã xác thực trong email",
        new_password: "Mật khẩu mới",
        retype_new_password: "Nhập lại mật khẩu mới",
        save_password: "Lưu mật khẩu",
        verify_email: "Xác thực email!",
        please_verify: "Vui lòng nhập mã xác thực được gửi đến email của bạn",
        enter_code: "Nhập mã xác thực",
        verify: "Xác thực",
        verify_code: "Mã xác thực",
        open_account_for: "Đăng ký cho",
        account_activated: "Tài khoản này đã được kích hoạt rồi",
        please_login_to_mark: "Vui lòng đăng nhập để chấm bài",
        invalid_path: "Đường dẫn không hợp lệ",
        chanage_password: "Đổi mật khẩu",
        update_your_password: "Cập nhật mật khẩu mới của bạn",
        update_password: "Cập nhật mật khẩu",
        username: "Tài khoản",
        forgot_username: "Quên tên đăng nhập?",
        guide: "Hướng dẫn",
        introducing_ELearning: "Giới thiệu E-Learning",
        create_student_account: "Tạo tài khoản học viên",
        create_teacher_account: "Tạo tài khoản giáo viên",
        documentation_guidelines: "Tài liệu hướng dẫn người dùng",
        required: "Trường này không được bỏ trống!",
        password_rule: "Mật khẩu phải có ít nhất 4 ký tự",
    },

    assigmment_topic: {
        view_detail: "Chi tiết",
    },

    // login: {
    //     welcome_back: "Chào mừng bạn quay lại!",
    //     login_to_experience: "Đăng nhập để trải nghiệm ngay",
    //     enter_your_email: "Nhập email của bạn",
    //     password: "Mật khẩu",
    //     retype_password: "Nhập lại mật khẩu",
    //     remember: "Ghi nhớ",
    //     forget_password: "Quên mật khẩu",
    //     sign_in: "Đăng nhập",
    //     sign_up: "Đăng ký",
    //     or_login_with: "Hoặc đăng nhập bằng tài khoản",
    //     or_register_with: "Hoặc đăng ký bằng tài khoản",
    //     login_with_facebook: "Facebook",
    //     login_with_google: "Google",
    //     dont_have_account: "Bạn chưa có tài khoản?",
    //     free_register: "Đăng ký miễn phí",
    //     start_signup_free: "Bắt đầu tạo tài khoản miễn phí",
    //     intro:
    //         "Tham gia cộng đồng giáo viên đang phát triển đang sử dụng phương pháp đánh giá Test Online để thúc đẩy học tập",
    //     who_are_you: "Bạn là ai?",
    //     teacher: "Giáo viên",
    //     student: "Học sinh",
    //     sign_up_now: "Đăng ký miễn phí ngay!",
    //     please_fill_in_the_form: "Vui lòng điền các thông tin bên dưới",
    //     still_remember: "Bạn vẫn nhớ mật khẩu?",
    //     send: "Gửi",
    //     enter_mail_to_reset_password: "Vui lòng nhập email đăng ký bên dưới để nhận được hướng dẫn lấy lại mật khẩu",
    //     email_sent: "Email đã được gửi!",
    //     send_error: "Gửi email không thành công!",
    //     reset_password: "Tạo mật khẩu mới",
    //     rule_new_password: "Mật khẩu mới phải khác mật khẩu trước đây mà bạn từng tạo",
    //     receive_code: "Nhận mã xác thực trong email",
    //     new_password: "Mật khẩu mới",
    //     retype_new_password: "Nhập lại mật khẩu mới",
    //     save_password: "Lưu mật khẩu",
    //     verify_email: "Xác thực email!",
    //     please_verify: "Vui lòng nhập mã xác thực được gửi đến email của bạn",
    //     enter_code: "Nhập mã xác thực",
    //     verify: "Xác thực",
    //     verify_code: "Mã xác thực",
    //     open_account_for: "Đăng ký tài khoản cho",
    // },

    statistical: {
        overview: "Tổng quan",
        student: "Học sinh",
        question: "Câu hỏi",
        choose_all: "CHỌN TẤT CẢ",
        filter_by_status: "LỌC THEO TRẠNG THÁI",
        assigned_student: "Học sinh được giao",
        not_submit: "Chưa nộp bài",
        doing: "Đang làm",
        submitted: "Đã nộp bài",
        graded: "Đã chấm điểm",
        progress: "Bài làm",
        submit_at: "Nộp bài lúc",
        question_grade: "Điểm của câu",
    },

    exam: {
        name: "Tên",
        // Actions:
        add_new_exam: "Thêm đề bài mới",
        add_assignment: "Thêm bài kiểm tra",
        find_assignment: "Tìm bài kiểm tra",
        find_exam: "Tìm đề thi",
        option_filter: "Bộ lọc",
        reset: "Reset bài làm",
    },

    question_bank: {
        done: "Xong",
        draft: "Bản nháp",
        view: "Xem",
        copy: "Sao chép",
        choose_option: "Chọn một tùy chọn",
        personal_learning_progress: "Tiến độ học tập cá nhân",
    },

    exam_bank: {
        name: "Tên",
        owner: "Người tạo",
        list_question: "Danh sách câu hỏi",
        add_question: "Thêm câu hỏi",
        total_score: "Tổng điểm",
        add_question_to_exam: "Thêm câu hỏi vào đề bài",
        question_list_is_empty: "Danh sách câu hỏi trống",
        score_is_empty: "Điểm câu hỏi không được để trống",
        create_question: "Tạo câu hỏi mới",
        up: "Lên",
        down: "Xuống",
    },

    exam_result: {
        count_correct_answers: "Tổng số câu đúng",
        your_assignment: "Bài làm của bạn",
        comment: "Nhận xét",
        teacher_comment: "Nhận xét của giáo viên",
        no_comment: "Chưa có nhận xét",
        your_assignment_not_found: "Không tìm thấy bài làm của bạn",
    },

    message: {
        content_highlight_not_valid: "Nội dung highlight không hợp lệ",
        you_have_chosen_this_correct_answer: "Bạn đã chọn đúng đáp án",
        empty_data: "Không có dữ liệu",
        warning_missing_fields: "Bạn phải nhập đầy đủ thông tin",
        warning_duplicate_answer: "Bạn đang sử dụng hai đáp án giống hệt nhau",
        warning_duplicate_group: "Bạn đang sử dụng hai nhóm giống hệt nhau",
        you_have_not_filled_enough_info: "Bạn chưa nhập đủ thông tin để thực hiện hành động này",
        required: "Trường này không được bỏ trống!",
        free_text: "Vui lòng nhập hoặc xóa trường này.",
        min0: "Giá trị nhập vào phải lớn hơn 0!",
        add_success: "Thêm mới thành công!",
        add_error: "Thêm mới thất bại!",
        update_success: "Cập nhật thành công!",
        upload_success: "Tải lên thành công!",
        update_error: "Cập nhật thất bại!",
        delete_success: "Xóa thành công!",
        delete_error: "Xóa thất bại!",
        submit_success: "Gửi thành công!",
        submit_error: "Gửi thất bại!",
        system_error: "Lỗi hệ thống!",
        sth_wrong: "Đã xảy ra lỗi không xác định!",
        clone_success: "Sao chép thành công!",
        clone_error: "Sao chép thất bại!",
        password_rule: "Mật khẩu phải có ít nhất 4 ký tự",
        password_not_match: "Mật khẩu không khớp",
        invalid_email: "Email không hợp lệ",
        register_success: "Đăng ký thành công!",
        register_error: "Đăng ký thất bại!",
        verify_success: "Xác nhận thành công!",
        verify_error: "Xác nhận thất bại!",
        login_fail: "Sai tài khoản hoặc mật khẩu!",
        login_error: "Lỗi đăng nhập!",
        third_party_login_error: "Đăng nhập thất bại!",
        err_begin_date_is_greater: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc!",
        email_sent: "Email đã được gửi!",
        reset_success: "Đặt lại mật khẩu thành công!",
        reset_error: "Đặt lại mật khẩu thất bại!",
        mark_success: "Chấm điểm thành công!",
        mark_error: "Chấm điểm thất bại!",
        login_success: "Đăng nhập thành công!",
        logout_success: "Đăng xuất thành công!",
        logout_success_passive: "Bạn đã được đăng xuất!",
        require_less_than_anwers: "Đáp án phải có ít nhất là 2",
        require_less_than_groups: "Nhóm phải có ít nhất là 2",
        // Question:
        answer_empty: "Câu trả lời đã bị bỏ trống",
        // File manager:
        file_extension_supported: "Định dạng file được hỗ trợ",
        please_try_again_later: "Xin hãy thử lại sau",
        please_try_another_file_extension: "Xin hãy thử file có định dạng khác",
        err_can_not_upload_files: "Lỗi không thể tải tệp lên",
        err_file_extension_not_valid: "File không hợp lệ",
    },

    table: {
        actions: "Hành động",
    },

    profile: {
        profile: "Hồ sơ",
        full_name: "Họ và tên",
        gender: "Giới tính",
        gender_male: "Nam",
        gender_female: "Nữ",
        role: "Vai trò",
        role_student: "Học sinh",
        role_teacher: "Giáo viên",
        phone: "Số điện thoại",
        active: "Trạng thái",
        active_false: "Không hoạt động",
        active_true: "Hoạt động",
        update_profile: "Chỉnh sửa profile",
        first_name: "Tên",
        last_name: "Họ",
        phone_number: "Số điện thoại",
        email: "Email",
        address: "Địa chỉ",
        password: "Mật khẩu",
        new_password: "Mật khẩu mới",
        confirm_newPassword: "Nhập lại mật khẩu mới",
        cancel: "Hủy",
        save_profile: "Lưu profile",
        update_success: "Cập nhật profile thành công",
        update_error: "Cập nhật profile thất bại",
        please_input_password: "Vui lòng nhập vào mật khẩu",
        please_input_password_new: "Vui lòng nhập vào mật khẩu mới",
        please_input_password_confirm: "Vui lòng nhập vào xác nhận mật khẩu mới",
        please_input_phone: "Vui lòng nhập vào số điện thoại",
        wrong_password: "Sai mật khẩu",
        select_gender: "Chọn giới tính",
        select_active: "Chọn trạng thái tài khoản",
        please_enter_minimum_character: "Vui lòng nhập ít nhất 4 ký tự",
        field_is_require: "Trường này là bắt buộc",
        change_password: "Đổi mật khẩu",
    },

    userBoard: {
        full_name: "Họ và tên",
        class: "Lớp",
        subject: "Môn",
        course: "Khóa học",
    },

    report: {
        question_number: "Câu hỏi số",
        select_question: "Chọn câu hỏi",
        very_good: "Xếp loại giỏi",
        good: "Xếp loại khá",
        ordinary: "Xếp loại trung bình",
        failed: "Trượt",
        level_completion_score: "Số điểm hoàn thành cấp độ",
        complete_progress: "Tiến độ hoàn thành",
        ratio: "Tỉ lệ",
        dateFrom: "Từ ngày",
        dateTo: "Đến ngày",
        subject: "Môn",
        class: "Khóa học",
        test_name: "Tên bài kiểm tra",
        name_of_course: "Tên khóa học",
        student_list: "Danh sách học sinh",
        please_select_question: "Vui lòng chọn câu hỏi",
        please_select_date: "Vui lòng chọn ngày",
        please_select_level: "Vui lòng chọn cấp độ",
        please_select_class: "Vui lòng chọn lớp",
        please_select_student: "Vui lòng chọn học sinh",
        please_select_test_name: "Vui lòng chọn tên bài kiểm tra",
        apply: "Áp dụng",
        cancel: "Hủy",
        filter: "Bộ lọc",
        test_classification: "Phân loại bài kiểm tra",
        rate_tests_taken: "Xếp loại các bài kiểm tra đã làm",
        name_of_class: "Tên lớp học",
        subject2: "Môn học",
        test: "Bài kiểm tra",
        exercise: "Bài tập",
        average_score: "Điểm trung bình",
        grasp: "Nắm vững",
        all_classes: "Tất cả các lớp",
        all_subject: "Tất cả các môn",
        all: "Tất cả",
        progress: "Tiến độ",
        personal_learning: "Học tập cá nhân",
        general_learning: "Học tập chung",
        student_name: "Tên học sinh",
        score: "Điểm số",
        select_student: "Chọn học sinh",
        is_student_submitted: "Đã nộp bài",
        is_student_not_submitted: "Chưa nộp bài",
        is_student_not_started: "Chưa bắt đầu",
        is_teacher_submitted: "Đã chấm điểm",
        is_teacher_not_submitted: "Chưa chấm điểm",
        student_submitted: "Học sinh đã nộp bài",
        student_not_submitted: "Học sinh chưa nộp bài",
        warning_select_student: "Chưa chọn học sinh cần thống kê hoặc học sinh bạn chọn chưa có dữ liệu.",
        student: "Học sinh",
        empty_data: "Không có dữ liệu",
        number_of_students: "Số học sinh",
        total_time_spent_in_seconds: "Tổng thời gian (giây)",
        average_time_spent_in_seconds: "Thời gian trung bình (giây)",
        grade_of_question: "Điểm câu hỏi",
        assignment: "Bài kiểm tra",
        exam: "Bài kiểm tra",
        export_pdf: "Xuất PDF",
        message_no_one_has_done_the_homework: "Chưa có học sinh nào làm bài, không thể thống kê",
        message_student_have_not_selected: "Chưa chọn học sinh để thống kê hoặc học sinh cần thống kê không có dữ liệu",
        message_general_data_not_found:
            "Chưa chọn lớp/khóa học cần thống kê hoặc lớp/khóa học cần thống kê chưa có dữ liệu",
        level: "Cấp độ",
        course: "Khóa học",
        please_select_course: "Vui lòng chọn khóa học",
        type_of_exercise: "Loại bài kiểm tra",
        please_select_type_of_exercise: "Vui lòng chọn loại bài kiểm tra",
        unit: "Bài tập",
        // Actions:
        view_detail: "Xem chi tiết",
        // Fields:
        asgmt_datefrom: "Ngày bắt đầu bài kiểm tra",
        asgmt_dateto: "Ngày kết thúc bài kiểm tra",
        // Titles:
        learning_progress_report: "Báo cáo tiến độ học tập",
        learning_progress_report_general: "Tiến độ học tập chung",
        learning_progress_report_personal: "Tiến độ học tập cá nhân",
        personal_learning_progress: "Tiến độ học tập cá nhân",
        general_learning_progress: "Tiến độ học tập chung",
        type_of_excercise: "Loại",
        select_course_or_class: "Chọn loại khóa học hoặc lớp học",
        need_select_class_or_course: "Bạn cần chọn một lớp hoặc một khóa học để xem thống kê!",
        need_select_student: "Bạn cần chọn một học sinh để xem thống kê!",
        need_select_type_before: "Cần chọn loại khóa học hoặc lớp học trước",
    },

    assignment: {
        donot: "Chưa làm",
        submited: "Đã làm",
        marked: "Đã có điểm",
        expired: "Đã hết hạn",
        course: "Khóa học",
        subject: "Môn",
        start_from: "Bắt đầu từ",
        deadline: "Hạn nộp",
        status: "Trạng thái",
        teachers: "Danh sách giáo viên",
        listAssignment: "Danh sách bài được giao",
        assignments: "Bài tập được giao",
        do_homework: "Làm bài",
        review: "Xem lại",
        doing: "Đang làm",
        score: "Điểm",
        invite_link: "Link mời",
        invite_via_link: "Mời qua link",
        add_user_successfully: "Bạn đã được thêm vào bài kiểm tra!",
        assignment_teacher_not_allowed: "Giáo viên không được tham dự bài kiểm tra!",
        assignment_not_existed: "Bài kiểm tra không tồn tại!",
        choose_subject: "Chọn môn học",
        choose_course: "Chọn khóa học",
        choose_status: "Chọn trạng thái",
        filter_option: "Tùy chọn bộ lọc",
        filter: "Bộ lọc",
        choose_owner: "Chọn chủ nhiệm",
        owner: "Chủ nhiệm",
        correct: "Đúng",
        correct_nearly: "Đúng một phần",
        incorrect: "Sai",
        mark_by_hand: "Chấm tay",
        skip: "Bỏ qua",
        time_spent: "Thời gian",
        // Fields:
        name: "Tên",
        class: "Lớp",
        begin_date: "Ngày bắt đầu",
        end_date: "Ngày kết thúc",
        max_score: "Điểm tối đa",
        duration: "Thời gian làm bài",
        exam_name: "Tên bài kiểm tra",
        exam_name_description: "Là tên mới mà bạn muốn đặt cho đề, hoặc tên mặc định của đề",
        type: "Loại",
        type_listening: "Nghe",
        type_speaking: "Nói",
        type_reading: "Đọc",
        type_writing: "Viết",
        type_vocabulary: "Từ vựng",
        type_grammar: "Ngữ pháp",
        type_review: "Ôn tập",
        // Messages:
        time_not_limited: "Không thời hạn",
        assignment_list_empty: "Không có bài tập nào",
        // Actions:
        add_teachers: "Thêm giáo viên",
        mark: "Chấm điểm",
        add_assignment: "Giao bài tập",
        find_assignment: "Tìm bài tập",
        option_filter: "Bộ lọc",
        load_more_assignments: "Xem thêm bài tập",
        select_type: "Chọn loại",

        //filter
        choose_class: "Chọn lớp học",

        your_score: "Điểm của bạn",
        load_more: "Tải thêm",
        unknown: "Không rõ",
        gradebook_type_middle: "Giữa kì",
        gradebook_type_final: "Cuối kì",
        select_gradebook_type: "Chọn kì thi",
        gradebook_type: "Kỳ thi",

        limit_submission: "Số lượng bài nộp tối đa",
        count_submission: "Số lượng bài nộp",

        public: "Công khai",
        private: "Không công khai",
        copied_code: "Đã sao chép mã code",
    },

    classes: {
        your_class_list: "Danh sách lớp của bạn",
        view_detail: "Xem chi tiết",
        owner: "Chủ nhiệm",
    },

    test: {
        submit_record_success: "Nộp bài thành công",
        submit_record_error: "Có lỗi xảy ra khi nộp bài",
        submit: "Nộp bài",
        confirm_submit: "Bạn có chắc muốn nộp bài ?",
        time_remaining: "Thời gian còn lại",
        cancel: "Hủy bỏ",
        exit: "Thoát",
        confirm_exit: "Bạn có chắc muốn thoát ?",
        timeout_test: "Đã hết thời giàn làm bài, bạn không thể thay đổi đáp án.",
    },

    tag: {
        tag: "Thẻ",
        add_tag: "Thêm thẻ",
        create_tag: "Tạo thẻ mới",
        add_tag_success: "Thêm thẻ thành công",
        add_tag_error: "Thêm thẻ thất bại",
        remove_tag_success: "Xóa thẻ thành công",
        remove_tag_error: "Xóa thẻ thất bại",
    },

    editor: {
        attach_files: "Đính kèm tệp",
        create_blank: "Tạo ô trống",
        blank: "Ô trống",
        highlight: "Highlight",
        select_audio_visible_type: "Chọn loại hiển thị cho tệp âm thanh",
        audio_type_icon: "Biểu tượng",
        audio_type_player: "Thanh công cụ",
        insert_or_edit_image: "Chèn/sửa ảnh",
        insert_or_edit_media: "Chèn/sửa đa phương tiện",
        insert_or_edit_image_1: "Nhúng ảnh",
        insert_or_edit_media_1: "Nhúng video",
        // Plugins - paste-options:
        paste_options: "Tùy chọn dán",
        paste_option_keep_text_only: "Chỉ giữ văn bản",
        paste_option_keep_source_formatting: "Giữ định dạng nguồn",
        // Plugins - math:
        standard_keypads: "Standard keypads",
        numbers_only: "Numbers only",
        numbers_n_basic_symbols: "Numbers and basic symbols",
        basic: "Basic",
        basic_wo_numbers: "Basic without numbers",
        intermediate: "Intermediate",
        intermediate_wo_numbers: "Intermediate without numbers",
        matrices: "Matrices",
        matrices_basic: "Matrices (basic)",
        trignometry: "Trignometry",
        geometry: "Geometry",
        chemistry: "Chemistry",
        units_SI: "Units (SI)",
        units_US: "Units (US)",
        full: "Full",
        // Plugins - wordcount:
        wordcountmax_reached: "Bạn đã nhập quá giới hạn số từ được cho phép",
    },

    file_manager: {
        // Fields:
        folder: "Thư mục",
        // Actions:
        rename: "Đổi tên",
        add_folder: "Thêm thư mục",
        add_files: "Thêm files",
        move_to: "Di chuyển tới",
        delete: "Xóa",
        // Messages:
        require_folder: "Bạn phải chọn một thư mục!",
        rename_file_success: "Đã thay đổi tên tệp",
        rename_folder_success: "Đã thay đổi tên thư mục",
    },

    my_storage: {
        my_storage: "Quản lý lưu trữ",
        new_folder: "Thư mục mới",
        view_file: "Xem tệp",
        view_file_error: "Không thể xem được tệp!",
        space_used: "Đang dùng",
        space_left: "Còn lại",
        space_usage: "Dung lượng lưu trữ",
        total: "Tổng",
        used: "Dùng",
        left: "Khả dụng",
        filemanager_loading: "Đang tải",
        filemanager_uploading: "Đang tải lên",
        filemanager_removing: "Đang xóa",
    },

    organization: {
        personal: "Cá nhân",
        organization: "Tổ chức",
        tablecol_user: "Người dùng",
        find_user: "Tìm người dùng",
        add_user: "Thêm người dùng",

        name: "Tên",
        organization_details: "Chi tiết",
        options: "Các tuỳ chọn",
        plan_type: "Loại gói",
        expiration_date: "Ngày hết hạn",
        // Invite teacher:
        user_email: "Email người dùng",
        send_invitation: "Mời",
        send_invitation_success: "Gửi lời mời thành công",
        send_invitation_error: "Gửi lời mời thất bại",
        add_teacher_not_others: "Chỉ giáo viên mới được tham gia",
        add_teacher_success: "Thêm giáo viên thành công",
        add_teacher_error: "Không thể thêm giáo viên",
    },

    guide: {
        title: "Tên chức năng",
        choose: "Chọn",
        help: "Hỗ trợ",
        add_feature: "Thêm chức năng",
        add_feature_desc: "Thêm mô tả cho chức năng",
        type: "Trang",
        order: "Thứ tự",
        description: "Mô tả",
        upload: "Tải lên",
        image: "Hình ảnh",
        create_guide: "Tạo hướng dẫn",
        delete: "Xóa",
        upload_success: "Tải ảnh lên thành công",
        update_guide: " Cập nhật hướng dẫn",
        add_new: "Tạo mới",
        find: "Tìm kiếm",
        sort: "Sắp xếp",
        asc: "Tăng dần",
        desc: "Giảm dần",
        select_type: "Chọn trang",
        user: "Người dùng",
        select_user_role: "Chọn loại người dùng",
        student: "Học sinh",
        teacher: "Giáo viên",
        employee: "Nhân viên",

        assignment: "Trang bài tập",
        class: "Trang lớp học",
        question_bank: "Trang ngân hàng câu hỏi",
        statistic: "Trang tiến độ học tập",
        storage: "Trang quản lý lưu trữ",
        account: "Trang quản lý tài khoản",
        exam_bank: "Trang ngân hàng đề",
        my_storage: "Trang tài nguyên của tôi",
        organization: "Trang tổ chức",

        course: "Khóa học",
        my_question_resource: "Tài nguyên của tôi(câu hỏi)",
        my_exam_resource: "Tài nguyên của tôi(đề thi)",
        questions_bank: "Ngân hàng câu hỏi",

        multiplechoice_question: "Trang soạn bài tập trắc nghiệm",
        fill_blank_question: "Trang soạn bài tập điền vào chỗ trống",
        writing_question: "Trang soạn bài tập viết",
        passage_question: "Trang soạn bài tập đoạn văn",
        sort_question: "Trang soạn bài tập sắp xếp",
        drag_drop_question: "Trang soạn bài tập kéo thả",

        learning_progress: "Tiến độ học tập",
        guide: "Hướng dẫn sử dụng",
        profile: "Tài khoản cá nhân",

        score: "Trang điểm số",
        gradebook: "Trang báo cáo",

        category: "Danh mục",
        collapse_sidebar: "Thu nhỏ",
    },
    score: {
        unknown: "Không rõ",
        correct_answers: "Đáp án đúng",
        your_answers: "Đáp án của bạn",
    },
    hotspot: {
        set_correct_answer: "Chọn đáp án đúng",
        drawing_areas: "Vẽ hình",
        node_must_be_closed: "Hình vẽ phải là hình khép kín",
        missing_correct_answer: "Bạn chưa chọn đáp án đúng",
    },
    zwibbler_toolbar: {
        helper: "Hướng dẫn",
        pick_tool_helper: "Chọn nút phù hợp để sử dụng chức năng.",
        brush_tool_helper: "Nhấn giữ chuột và kéo để vẽ hình.",
        line_tool_helper: "Nhấn để bắt đầu vẽ, nhấn đúp để kết thúc",
        shape_tool_helper: "Nhấn để bắt đầu vẽ, giữ chuột và kéo thả để vẽ hình.",
    },
    shade: {
        row_count: "Số dòng",
        column_count: "Số cột",
        cell_width: "Chiều rộng của ô",
        cell_height: "Chiều cao của ô",
        lock_shade_cells: "Khóa ô đã chọn",
        by_location: "Theo vị trí",
        by_count: "Theo số lượng",
        unlockcell_note: "Chú ý: Bạn chỉ cần chọn số lượng ô vuông tương ứng với đáp án",
    },
    speech_to_text: {
        sentence: "Câu",
        word: "Từ",
        please_allow_microphone: "Vui lòng cho phép trình duyệt truy cập microphone",
        no_browser_support:
            "Trình duyệt của bạn không hỗ trợ chức năng này, vui lòng sử dụng trình duyệt khác(Chrome/Edge)",
        question: "Đề:",
        solution: "Đáp án của bạn:",
        speaker: "Giọng đọc",
        select_speaker: "Chọn giọng đọc",
        tap_to_open_mic: "Nhấn để bật mic",
        tap_to_close_mic: "Nhấn để tắt mic",
    },
    speech_to_text_conversation: {
        speaker: "Giọng đọc (người)",
        voice: "Giọng đọc (máy)",
        actor: "Nhân vật",
        start: "Bắt đầu",
        havent_choose_actor: "Bạn chưa chọn một nhân vật",
        select_actor: "Chọn nhân vật",
        select_voice: "Chọn giọng đọc",
        enter_sentence: "Nhập câu nói",
        choose_actor: "Nhấn dấu tick để chọn nhân vật nói",
        choose_actor1: "Nhấn dấu ",
        choose_actor2: " để chọn nhân vật nói",
        click_micro_to_answer: "Nhấn vào micro để nhập đáp án của bạn",
        havent_entered_answer: "Bạn chưa nhập câu trả lời",
        voice_not_support: "Ngôn ngữ này không được hỗ trợ vui lòng chọn một ngôn ngữ khác",
        click_to_speech: "Bấm để nói",
        click_to_stop_speech: "Bấm để ngừng nói",
        click_to_speech_again: "Bấm để nói lại",
        note_when_doing_homework1: "Bấm ",
        note_when_doing_homework2: " để nói, để dừng lại bấm ",
        note_when_doing_homework3: ", nếu nói sai thì bấm nút ",
        note_when_doing_homework4: " để nói lại (chỉ cho nói lại tối đa 3 lần)",
        notAllow_change_actor: "Bạn không thể thay đổi nhân vật khi đã có đáp án",
    },

    feedback: {
        title: "Đóng góp ý kiến",
        subtitle:
            "Vui lòng nhập ý kiến đóng góp của bạn vào ô bên dưới. Cảm ơn bạn đã dành thời gian để góp ý cho sản phẩm của chúng tôi.",
        thanks:
            "Cảm ơn bạn đã dành thời gian để đóng góp ý kiến. Chúng tôi sẽ xem xét góp ý này để cải thiện sản phẩm và phản hồi cho bạn sớm nhất có thể.",
        send_feedback: "Gửi feedback",

        solved: "Đã xử lý",
        unresolved: "Chưa xử lý",
        handler: "Người xử lý",
        select_handler: "Chọn người xử lý",
        feedback: "Feedback",
    },

    history: {
        history: "Lịch sử",
        editing_history: "Lịch sử chỉnh sửa",
        choose_action_type: "Chọn loại hành động",
        assignment: "Giao bài",
        exam_question: "Đề",
        question: "Câu hỏi",
        course: "Khóa học",
        model: "Trang",
        choose_model: "Chọn trang",
        old_value: "Giá trị cũ",
        new_value: "Giá trị mới",
        old_question: "Câu hỏi cũ",
        new_question: "Câu hỏi mới",
        old_answer: "Đáp án cũ",
        new_answer: "Đáp án mới",
        coordinates: "Tọa độ đáp án",
        update_questions_in_passage: "Cập nhật danh sách câu hỏi trong câu passge",

        add_user: "thêm",
        to_class: "vào",
        update_user: "cập nhật người dùng ",
        delete_user: "xóa người dùng ",
        out_of: "ra khỏi ",
        into: "vào",
        course1: "khóa học",
        class1: "lớp",
        action_statistics: "Thống kê hành động",
    },

    exam_checkin: {
        access_exam: "Truy cập bài thi",
        exam_access_code: "Mã truy cập bài thi",
        descr_exam_access_code: "Mã truy cập được cấp bởi người hướng dẫn hoặc giám thị của bạn",
        system_check: "Kiểm tra hệ thống",
        descr_system_check: "Vui lòng đợi để kiểm tra thiết bị của bạn có đáp ứng yêu cầu hệ thống hay không",
        internet_connection_check: "Kết nối internet",
        webcam_check: "Webcam",
        microphone_check: "Microphone",
        displays_check: "Màn hình",
        internet_connection_speed: {
            slow: "Chậm",
            stable: "Đạt",
            descr_slow: "Kết nối chậm sẽ ảnh hưởng đến các tác vụ của bạn. Vui lòng kiểm tra lại cài đặt kết nối mạng.",
        },
        webcam_info: {
            fail: "Lỗi",
            success: "OK",
            descr_connecting: "Đang kết nối webcam...",
            descr_fail: "Kết nối webcam thất bại.",
            descr_permission_dismissed: "Kết nối webcam thất bại, vui lòng cấp quyền truy cập vào camera của bạn.",
            descr_permission_denied: "Kết nối webcam thất bại, vui lòng cấp quyền truy cập vào camera của bạn.",
        },
        microphone_info: {
            fail: "Lỗi",
            success: "OK",
            descr_connecting: "Đang kết nối microphone...",
            descr_fail: "Kết nối microphone thất bại.",
            descr_permission_dismissed:
                "Kết nối microphone thất bại, vui lòng cấp quyền truy cập vào microphone của bạn.",
            descr_permission_denied: "Kết nối microphone thất bại, vui lòng cấp quyền truy cập vào microphone của bạn.",
            descr_say_sth: "Hãy nói gì đó để kiểm tra âm lượng microphone",
        },
        displays_info: {
            what: "Kiểm tra màn hình",
            why:
                "Chúng tôi đang kiểm tra xem bạn có sử dụng nhiều màn hình ở chế độ Mở Rộng (Extended) không. Vì vậy, chúng tôi cần quyền quản lý cửa sổ trên các màn hình của bạn.",
            fail: "Lỗi",
            success: "OK",
            descr_connecting: "Đang kiểm tra màn hình...",
            descr_fail: "Kiểm tra màn hình thất bại.",
            descr_invalid_screen_count: "Vui lòng không sử dụng nhiều màn hình cùng lúc.",
            descr_permission_dismissed:
                "Kiểm tra màn hình thất bại, vui lòng cấp quyền quản lý cửa sổ trên các màn hình của bạn.",
            descr_permission_denied:
                "Kiểm tra màn hình thất bại, vui lòng cấp quyền quản lý cửa sổ trên các màn hình của bạn.",
        },
        check_result: "Kết quả",
        candidate_information: "Thông tin thí sinh",
        identity_verification: "Xác minh danh tính",
        taking_exam: "Tham dự bài thi",
        candidate_number: "Mã số thí sinh",
        human_verification: "Tôi không phải robot",
        start_taking_exam: "Bắt đầu làm bài",
        input_internet_connection_check: "Hãy kiểm tra kết nối internet",
        input_microphone_check: "Hãy kiểm tra microphone",
        input_webcam_check: "Hãy kiểm tra webcam",
        input_exam_access_code: "Hãy nhập mã truy cập bài thi",
        input_candidate_number: "Hãy nhập mã số của bạn",
        input_human_verification: "Hãy xác minh bạn không phải robot",
        internet_connection_check_failed: "Kiểm tra thất bại",
        avatar_upload: "Ảnh chân dung của bạn",
        only_upload_img: "Chỉ cho phép tải lên hình ảnh!",
    },

    exam_asset: {
        input_upload_files: "Vui lòng chọn tệp cần tải lên",
        exam_complete: "Bạn đã hoàn tất bài thi.",
        descr_exam_complete:
            "Vui lòng tải lên tài nguyên giám sát bài thi để chắc chắn rằng bạn đã tuân thủ quy định của kỳ thi.",
        exam_asset: "Tài nguyên giám sát bài thi",
        descr_exam_asset:
            "Bạn cần phải tải lên các tệp cần thiết, như là video quay lại màn hình trong lúc làm bài thi.",
        exam_asset_done: "Mọi thứ đã hoàn tất!",
        descr_exam_asset_maybelost: "Không tìm thấy tệp nào.",
    },

    exam_proctoring: {
        screen_size_check_invalid: "Kích cỡ màn hình không phù hợp",
        descr_screen_size_check_invalid:
            "Kích cỡ màn hình không phù hợp để chạy ứng dụng. Vui lòng thay đổi thiết bị hoặc thay đổi cài đặt kích cỡ màn hình và chạy ứng dụng trong cửa sổ lớn hoặc toàn màn hình.",
        displays_check_invalid: "Sử dụng màn hình không hợp lệ",
        descr_displays_check_invalid: "Vui lòng không sử dụng nhiều màn hình cùng lúc ở chế độ Mở Rộng (Extended).",
        webcam_info: {
            permission_dismissed: "Kết nối webcam thất bại",
            permission_denied: "Kết nối webcam thất bại",
            descr_permission_dismissed: "Vui lòng cấp quyền truy cập vào camera của bạn.",
            descr_permission_denied: "Vui lòng cấp quyền truy cập vào camera của bạn.",
        },
        microphone_info: {
            permission_dismissed: "Kết nối microphone thất bại",
            permission_denied: "Kết nối microphone thất bại",
            descr_permission_dismissed: "Vui lòng cấp quyền truy cập vào microphone của bạn.",
            descr_permission_denied: "Vui lòng cấp quyền truy cập vào microphone của bạn.",
        },
        displays_info: {
            permission_dismissed: "Kiểm tra màn hình thất bại",
            permission_denied: "Kiểm tra màn hình thất bại",
            descr_permission_dismissed: "Vui lòng cấp quyền quản lý cửa sổ trên các màn hình của bạn.",
            descr_permission_denied: "Vui lòng cấp quyền quản lý cửa sổ trên các màn hình của bạn.",
        },
        this_platform_is_only_available_on_pc: "Platform này chỉ sử dụng trên PC.",
        device_not_available: "Thiết bị không khả dụng.",
        start_recording: "Bắt đầu ghi hình",
        stop_recording: "Dừng ghi hình",
        recording_info: {
            started: "Đã bắt đầu ghi hình...",
            stopped: "Đã kết thúc ghi hình!",
            displaySurface_must_be_monitor: "Vui lòng chia sẻ toàn bộ màn hình của bạn!",
            systemAudio_mustbe_turnedon: "Vui lòng chia sẻ âm thanh hệ thống!",
            descr_permission_dismissed:
                "Ghi hình thất bại, vui lòng cấp quyền truy cập vào microphone và chia sẻ màn hình của bạn.",
            descr_permission_denied:
                "Ghi hình thất bại, vui lòng cấp quyền truy cập vào microphone và chia sẻ màn hình của bạn.",
        },
        ask_recording: "Hệ thống cần giám sát bài thi bằng cách ghi hình.",
        descr_ask_recording: "Vui lòng xác nhận và cấp các quyền được yêu cầu.",
        rules_reminder: "Quy định bài thi",
        descr_rules_reminder: "Bạn phải tuân thủ quy định bài thi được liệt kê dưới đây",
        agree_rules: "Tôi đồng ý rằng việc vi phạm các quy định trên sẽ dẫn đến việc hủy bỏ bài thi của tôi.",
        notes: {
            record_screen_share: "Ghi hình",
            record_camera: "Ghi lại camera",
            record_microphone: "Ghi lại microphone",
            record_system_audio: "Ghi lại âm thanh hệ thống",
            extend_monitor: "Không được sử dụng nhiều màn hình ở chế độ Mở Rộng (Extended)",
            disable_coppy_paste: "Không được cắt và dán văn bản",
            alert_user_leave_page: "Không được rời khỏi trang",
            screen_shrinking_disable: "Không được thu nhỏ màn hình",
            open_tab: "Không được mở tab mới",
            exit_full_screen: "Không được thoát khỏi chế độ toàn màn hình",
            suffix_open_tab: "lần",
            suffix_exit_full_screen: "lần",
        },
        starttime_notreached: "Thời gian dự thi chưa bắt đầu. Vui lòng chờ...",
        starttime_reached: "Thời gian dự thi đã bắt đầu.",
    },

    anti_cheating: {
        webcam_picture: "Chụp ảnh bằng webcam",
        upload_picture: "Upload ảnh chân dung",
        upload_identity_card: "Upload ảnh ID",
        record_screen_share: "Ghi hình màn hình chia sẻ",
        disable_coppy_paste: "Khóa copy-paste",
        alert_user_leave_page: "Cảnh báo khi học viên ra khỏi màn hình làm bài",
        limit_exit_tab: "Số lần được phép thoát khỏi màn hình làm bài",
        screen_shrinking_disable: "Không cho phép thu nhỏ màn hình",
        count_exit_full_screen: "Số lần được phép thoát khỏi chế độ toàn màn hình",
        record_audio: "Ghi lại audio trên máy học sinh",
        portrait_image: "Ảnh chân dung",
        id_verification: "Xác minh danh tính",
        secured_recording: "Bảo mật ghi hình",
        lockdown_browser: "Khóa trình duyệt web",
        exit_fullscreen_warning: "Không được thoát khỏi chế độ toàn màn hình khi làm bài!",
        copy_paste_not_allowed: "Không được phép copy/paste!",
        extend_monitor: "Chặn sử dụng nhiều màn hình ở chế độ Mở Rộng (Extended)",
        multiple_displays_extended: "Chặn sử dụng nhiều màn hình ở chế độ Mở Rộng (Extended)",
        allowed_late_start: "Được làm bài muộn",
        late_start_minutes: "Thời gian được vô trễ (phút)",
        violated_exam_by_opening_devtool: "Bạn đã vi phạm quy chế thi vì mở devtool",
        violated_exam_contact_teacher:
            "Bạn không thể tiếp tục làm bài vì đã vi phạm quy chế thi. Vui lòng liên hệ với giáo viên của bạn để được hỗ trợ",
        record_system_audio: "Ghi lại âm thanh hệ thống",
        record_microphone: "Ghi lại âm thanh microphone",
        record_camera: "Ghi lại camera",
        open_devtool: "Chặn mở Developer Tool",
        detect_many_people_in_camera: "Phát hiện nhiều khuôn mặt trong camera",
        not_appear_on_camera: "Phát hiện thí sinh rời khỏi vị trí trong khi làm bài",
        times_allowed_many_people_in_camera: "Số lần được phép xuất hiện nhiều khuôn mặt trong camera",
        times_allowed_not_appear_on_camera: "Số lần được phép rời khỏi vị trí khi làm bài",
        alert_not_appear_on_camera:
            "Không phát hiện khuôn mặt của bạn, vui lòng không rời khỏi vị trí khi đang làm bài",
        id_verify_success_message: "Xác minh hoàn tất!. Bạn sẽ được chuyển hướng qua trang làm bài.",
        upload_another_ID: "Tải lên một ảnh ID khác",

        id_photo_regulations: "Quy định ảnh ID",
        video_record: "Lịch sử giám sát",
        violating_images: "Ảnh vi phạm",
        list_of_violations: "Danh sách lỗi vi phạm",
        no_student_violation_data: "Không có dữ liệu vi phạm của thí sinh",
    },

    upload: {
        only_image: "Chỉ được phép tải lên file hình ảnh",
        file_size_limit: "Kích thước file không được vượt quá ",
    },

    dashboard: {
        total_course: "Tổng số khóa học",
        total_class: "Tổng số lớp học",
        total_student_in_course: "Tổng số học sinh trong các khóa học",
        total_student_in_class: "Tổng số học sinh trong các lớp học",
        total_created_question: "Tổng số câu hỏi đã tạo",
        total_created_exam: "Tổng số đề đã tạo",
        total_created_assignment: "Tổng bài đã giao",
        total_public_assignment: "Tổng số bài kiểm tra công khai",
        total_private_assignment: "Tổng số bài kiểm tra không công khai",
        total_student_in_public_assignment: "Tổng số thí sinh tham gia bài kiểm tra công khai",
        total_student_in_private_assignment: "Tổng số thí sinh tham gia bài kiểm tra không công khai",
    },

    setting: {
        // Tabs:
        payment_history: "Lịch sử thanh toán",
        my_plan: "Gói của tôi",
        plan_info: "Thông tin gói",
        // Fields:
        code: "Mã",
        subscription: "Gói đăng ký",
        amount: "Thành tiền",
        effective_date: "Ngày có hiệu lực",
        expiration_date: "Ngày hết hạn",
        payment_method: "Phương thức thanh toán",
        payment_status: "Trạng thái thanh toán",
        plan_name: "Tên gói",
        options: "Các tuỳ chọn",
        // Actions:
        select_plan: "Chọn một gói",
        buy_more_options: "Mua thêm lựa chọn",
        extend_subscription: "Gia hạn gói",
    },
};
