import {
    CaretDownOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal, Spin, Tooltip, notification } from "antd";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { findCourse, removeUserFromCourse } from "src/api/containers/course";
import { findRecordBy } from "src/api/containers/record";
import { fetchStudents, resetStudentsToken } from "src/api/containers/user";
import actionClose from "src/assets/images/action-close.svg";
// Others:
import { ReactComponent as Bin } from "src/assets/images/bin.svg";
import { ReactComponent as ContactIcon } from "src/assets/images/contact.svg";
import { ReactComponent as DeleteBin } from "src/assets/images/delete-bin.svg";
import { useFetch, useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import InputSearch from "src/modules/components/Input/InputSearch";
import PopConfirm, { CONFIRM_TYPE } from "src/modules/components/Modal/PopConfirm";
import ModalInviteLink from "src/modules/components/ModalInviteLink";
import Table from "src/modules/components/Table";
import ViewScoreStudent from "src/modules/containers/ViewScoreStudent";
import { setCurrentPage } from "src/reducers/general";
import { CloseIconSVG } from "src/utils/drawer";
import ROLES from "src/utils/roles";

import Header from "../../components/Header";
// Course management tab:
import ModalAddUser from "../../components/ModalAddUser";
// Exam management tab:
import ModalGeneralLearningProgress from "../../components/ModalGeneralLearningProgress";
import ModalMarkAssignment from "../../components/ModalMarkAssignment";
import ModalPersonalLearningProgress from "../../components/ModalPersonalLearningProgress";
import UploadBtn from "../components/UploadBtn";
import "./CourseDetailModal.scss";
import ModalUpdateUser from "./ModalUpdateUser";
import "./TableStudentList/TableStudentList.scss";

const CourseDetailModal = ({ courseId, examInfo, assignmentInfo, code, className = "", assignmentId, ...rest }) => {
    const params = useParams();

    const course_id = useMemo(() => {
        return courseId || params.id;
    }, [params.id, courseId]);

    console.log({ course_id, courseId });

    const [values, setValues] = useValues({
        courseData: {},
        isEdited: false,
        isConfirmModal: false,
        pendingDeleteItem: null,
        loading: true,
        filterStudentTable: { role: undefined, gender: undefined },
        filterExamTable: {},
        markingAssignmentId: "",
        studentNumber: undefined,
        viewProgressAssignment: {},
        viewProgressCourse: {},
        viewProgressLevel: {},
        viewProgressIsUnit: undefined,
        isOnMobile: false,
        isShowForm: false,
        examType: "exam",
        searchUserText: "",
        isModalHistory: false,
        isConfirmModalVisible: false,
        confirm_type: "",
        selectedUser: {},

        isModalViewExamResult: false,
        isMarkingScore: false,

        currentExamResult: {},
        loadingExamResult: false,
    });

    const user = useSelector((state) => state.auth.user);

    const [isAddUserModal, setIsAddUserModal] = useState(false); // Values: "student", "teacher", false.
    const [isUpdateUserModal, setIsUpdateUserModal] = useState(false); // Values: "student", "teacher", false.

    const [isMarkingModal, setIsMarkingModal] = useState(false);

    const [isPersonalLearningProgress, setIsPersonalLearningProgress] = useState(false);
    const [isGeneralLearningProgress, setIsGeneralLearningProgress] = useState(false);
    const [showModalInviteLink, setShowModalInviteLink] = useState({ isShown: false, assignmentId: "" });
    const [studentInfo, setStudentInfo] = useState({});
    const [isModalUpdateUser, setIsModalUpdateUser] = useState(false);

    const dispatch = useDispatch();

    const [studentLoading, studentDataSource, studentPagination, studentFetch, studentRefetch] = useFetch(
        { page: 1 },
        fetchStudents
    );

    const handleShowModal = (id, name) => {
        setValues({
            isConfirmModal: true,
            pendingDeleteItem: { id, name },
        });
    };

    const handleResetStudent = (id) => {
        if (id) {
            resetStudentsToken([id], values.courseData?.assignment?.id)
                .then(({ status, message }) => {
                    if (status) {
                        notification.success({ message: t("reset_success") });
                    } else {
                        notification.error({ message: message || t("an_error_occurred") });
                    }
                    setValues({ isConfirmModalVisible: false });
                })
                .catch((err) => {
                    notification.error({ message: t("an_error_occurred") });
                    setValues({ isConfirmModalVisible: false });
                });
        }
    };

    const studentColumns = [
        {
            title: t("profile.full_name"),
            dataIndex: "name",
            key: "name",
            align: "center",
            render: (text, record) => {
                const name = record?.name;
                return values.isEdited ? (
                    <span>
                        <span className="icon" onClick={() => handleShowModal(record.id, name)}>
                            <Bin />
                        </span>
                        <span>{name}</span>
                    </span>
                ) : (
                    <span>{name}</span>
                );
            },
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlayStyle={{ zIndex: 1000 }}
                    overlay={
                        <TableColumnFilterMenu
                            columnName="role"
                            cateName="course"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "student", value: "student" },
                                { name: "teacher", value: "teacher" },
                            ]}
                            selectedFilterKey={values.filterStudentTable.role}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterStudentTable.role) {
                                    setValues({
                                        filterStudentTable: { ...values.filterStudentTable, role: undefined },
                                    });
                                } else {
                                    setValues({ filterStudentTable: { ...values.filterStudentTable, role: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="role-wrapper">
                        <span>{t("course.role")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "role",
            key: "role",
            align: "center",
            render: (text, record) => {
                return t(`role_option.${text}`);
            },
        },
        {
            title: t("course.email"),
            dataIndex: "username",
            key: "username",
            align: "center",
        },
        {
            title: t("personal_number"),
            dataIndex: "personal_number",
            key: "personal_number",
            align: "center",
            render: (text, record) => {
                if (record?.role === "student") {
                    return record?.personal_number || t("unknown");
                }
                return "";
            },
        },
        {
            mobileTitle: t("course.gender"),
            title: (filters, sortOrder) => (
                <Dropdown
                    overlayStyle={{ zIndex: 1000 }}
                    overlay={
                        <TableColumnFilterMenu
                            columnName="gender"
                            cateName="course"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "female", value: 0 },
                                { name: "male", value: 1 },
                                { name: "unknown", value: null },
                            ]}
                            selectedFilterKey={values.filterStudentTable.gender}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterStudentTable.gender) {
                                    setValues({
                                        filterStudentTable: { ...values.filterStudentTable, gender: undefined },
                                    });
                                } else {
                                    setValues({ filterStudentTable: { ...values.filterStudentTable, gender: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="gender-wrapper">
                        <span>{t("course.gender")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "gender",
            key: "gender",
            align: "center",
            render: (text, record) =>
                record.gender === 0
                    ? t("course.female")
                    : record.gender === 1
                    ? t("course.male")
                    : record.gender === null
                    ? t("course.unknown")
                    : "",
        },
        // {
        //     title: (filters, sortOrder) => (
        //         <Dropdown
        //             overlayStyle={{ zIndex: 1000 }}
        //             overlay={
        //                 <TableColumnFilterMenu
        //                     columnName="role"
        //                     cateName="course"
        //                     filterKeys={[
        //                         { name: "all", value: undefined },
        //                         { name: "teacher", value: "teacher" },
        //                         { name: "student", value: "student" },
        //                         { name: "employee", value: "employee" },
        //                     ]}
        //                     selectedFilterKey={values.filterStudentTable.role}
        //                     onChangeFilterKey={(val) => {
        //                         if (val === values.filterStudentTable.role) {
        //                             setValues({
        //                                 filterStudentTable: { ...values.filterStudentTable, role: undefined },
        //                             });
        //                         } else {
        //                             setValues({ filterStudentTable: { ...values.filterStudentTable, role: val } });
        //                         }
        //                     }}
        //                 />
        //             }
        //             placement="bottom"
        //         >
        //             <div className="role-wrapper">
        //                 <span>{t("course.role")}</span> <CaretDownOutlined />
        //             </div>
        //         </Dropdown>
        //     ),
        //     dataIndex: "role",
        //     key: "role",
        //     render: (text, record) => {
        //         return t(record.role);
        //     },
        // },
        {
            mobileTitle: t("course.status"),
            title: (filters, sortOrder) => (
                <Dropdown
                    overlayStyle={{ zIndex: 1000 }}
                    overlay={
                        <TableColumnFilterMenu
                            columnName="status"
                            cateName="shared"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "active", value: true },
                                { name: "inactive", value: false },
                            ]}
                            selectedFilterKey={values.filterStudentTable.active}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterStudentTable.active) {
                                    setValues({
                                        filterStudentTable: { ...values.filterStudentTable, active: undefined },
                                    });
                                } else {
                                    setValues({ filterStudentTable: { ...values.filterStudentTable, active: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="status-wrapper">
                        <span>{t("course.status")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            align: "center",
            dataIndex: "course_user_status",
            key: "course_user_status",
            render: (text, record) => (record.active === true ? t("shared.active") : t("shared.inactive")),
        },
    ];

    if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role)) {
        studentColumns.push({
            title: t("table.actions"),
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            align: "left",
            render: (text, record) => {
                const _userRole = record?.role;
                return (
                    <div className="table-action">
                        {_userRole === "student" && (
                            <Tooltip placement="top" title={t("exam.reset")}>
                                <CustomButton
                                    className="btn list-btn btn-icon-only"
                                    type="simple"
                                    icon={<SyncOutlined />}
                                    onClick={() => {
                                        setValues({
                                            isConfirmModalVisible: true,
                                            confirm_type: CONFIRM_TYPE.reset,
                                            selectedUser: record,
                                        });
                                    }}
                                />
                            </Tooltip>
                        )}
                        {_userRole === "student" && (
                            <Tooltip placement="top" title={t("question_bank.personal_learning_progress")}>
                                <CustomButton
                                    className="btn list-btn btn-icon-only"
                                    type="simple"
                                    icon={<ContactIcon />}
                                    onClick={() => {
                                        setStudentInfo(record);
                                        setIsPersonalLearningProgress(true);
                                    }}
                                />
                            </Tooltip>
                        )}
                        {_userRole === "student" && (
                            <Tooltip placement="top" title={t("question_bank.view")}>
                                <CustomButton
                                    className="btn list-btn btn-icon-only"
                                    type="simple"
                                    icon={<EyeOutlined />}
                                    onClick={(e) => {
                                        setValues({ isModalViewExamResult: true });
                                        handleFetchExamResult(record?.id, assignmentId);
                                    }}
                                />
                            </Tooltip>
                        )}

                        <Tooltip placement="top" title={t("shared.edit")}>
                            <CustomButton
                                className="btn list-btn btn-icon-only"
                                type="simple"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setIsUpdateUserModal(_userRole);
                                    setValues({ selectedUser: record });
                                }}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={t("shared.delete")}>
                            <CustomButton
                                className="btn list-btn btn-danger btn-icon-only"
                                type="simple"
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                    setValues({
                                        isConfirmModalVisible: true,
                                        confirm_type: CONFIRM_TYPE.delete,
                                        selectedUser: record,
                                    })
                                }
                            />
                        </Tooltip>
                    </div>
                );
            },
        });
    }

    const handleDeleteStudent = () => {
        const data = {
            course_id: values.courseData?.id,
            user_id: values.pendingDeleteItem.id,
        };
        setValues({ loading: true, isConfirmModal: false });
        deleteStudent(data).then(({ status, message }) => {
            if (status) {
                setValues({
                    loading: false,
                    pendingDeleteItem: null,
                });
                studentRefetch();
                notification.success({
                    message: message || t("message.delete_success"),
                });
            } else {
                notification.error({
                    message: message || t("message.delete_error"),
                });
            }
        });
    };

    const handleCloseModalGeneralLearningProgress = useCallback(() => {
        setIsGeneralLearningProgress(false);
    }, []);

    const handleCloseModalPersonalLearningProgress = useCallback(() => {
        setIsPersonalLearningProgress(false);
    }, []);

    const handleSearch = (slug) => {
        setValues({
            filterStudentTable: { ...values.filterStudentTable, slug: values.searchUserText },
        });
    };

    const handleDeleteStudentFromCourse = () => {
        const data = {
            course_id: course_id,
            user_id: values.selectedUser.id,
        };
        removeUserFromCourse(data).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ selectedUser: null, isConfirmModalVisible: false });
                studentRefetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleFetchExamResult = (userId, assignmentId) => {
        if (userId && assignmentId) {
            setValues({ loadingExamResult: true });
            findRecordBy(assignmentId, userId, { is_show_user_info: 1 }).then(({ status, data, message }) => {
                if (status) {
                    setValues({ currentExamResult: data?.[0], loadingExamResult: false });
                } else {
                    setValues({ loadingExamResult: false });
                    notification.error({ message: message || t("an_error_occurred") });
                }
            });
        }
    };

    function handlePopConfirmOk() {
        switch (values.confirm_type) {
            case CONFIRM_TYPE.reset:
                handleResetStudent(values.selectedUser?.id);
                return;
            case CONFIRM_TYPE.delete:
                handleDeleteStudentFromCourse();
                return;
            default:
                return;
        }
    }
    useEffect(() => {
        dispatch(setCurrentPage("course"));
        setValues({ loading: true });
        // Fetch data for course info:
        findCourse(course_id, { relations: ["level", "assignment"] }).then((res) => {
            setValues({
                courseData: res.data,
            });
            setValues({ loading: false });
        });
    }, []);

    useEffect(() => {
        if (Object.keys(values.filterStudentTable).length && course_id) {
            const fetchParams = [`course_id:${course_id}`];

            // Extra params:
            if (values.filterStudentTable.role !== undefined) {
                fetchParams.push(`role:${values.filterStudentTable.role}`);
            }
            if (values.filterStudentTable.gender !== undefined) {
                fetchParams.push(`gender:${values.filterStudentTable.gender}`);
            }
            // if (values.filterStudentTable.role !== undefined) {
            //     fetchParams.push(`role:${values.filterStudentTable.role}`);
            // }
            if (values.filterStudentTable.active !== undefined) {
                fetchParams.push(`active:${values.filterStudentTable.active}`);
            }
            if (values.filterStudentTable.slug !== undefined) {
                fetchParams.push(`slug:${values.filterStudentTable.slug}`);
            }

            if (course_id) {
                studentFetch({ filters: fetchParams.join(";") }, true);
            }
        }
    }, [values.filterStudentTable, course_id]);

    useEffect(() => {
        setValues({ studentNumber: studentDataSource.length });
    }, [studentDataSource]);

    return (
        <Modal
            centered
            // title={t("course.add_exam")}
            closeIcon={<CloseIconSVG />}
            footer={null}
            className={`app-modal type-basic flexible-height${className ? " " + className : ""}  modal-detail-course`}
            {...rest}
        >
            <div className="teacher-course-detail">
                <Spin spinning={values.loading}>
                    <Header
                        courseData={{
                            ...values.courseData,
                            code: code,
                            begin_date: assignmentInfo?.begin_date,
                            end_date: assignmentInfo?.end_date,
                        }}
                        studentNumber={values.studentNumber}
                        style={{ marginTop: 20 }}
                    />
                    <div className="course-detail-top">
                        <p className="course-detail-title">{values.courseData?.name || "_"}</p>
                        <span className="course-detail-actions-top">
                            {ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) ? (
                                <>
                                    {/* <span>
                                        <CustomButton
                                            title={t("course.invite_student")}
                                            icon={<ShareAltOutlined />}
                                            onClick={() => {
                                                setShowModalInviteLink({
                                                    isShown: true,
                                                    assignmentId: `${location.origin}/course-invitation-student/${course_id}?inviter_id=${user?.id}`,
                                                });
                                            }}
                                        />
                                    </span> */}
                                    <span className="adduser-manual-sec">
                                        <span>
                                            <CustomButton
                                                type="primary"
                                                title={t("course.add_teacher")}
                                                icon={<PlusCircleOutlined />}
                                                onClick={() => setIsAddUserModal("teacher")}
                                            />
                                        </span>

                                        {!assignmentInfo?.is_public && (
                                            <span>
                                                <CustomButton
                                                    type="primary"
                                                    title={t("course.add_student")}
                                                    icon={<PlusCircleOutlined />}
                                                    onClick={() => setIsAddUserModal("student")}
                                                />
                                            </span>
                                        )}
                                    </span>
                                    <span className="adduser-auto-sec">
                                        <UploadBtn course_id={course_id} userRole="teacher" />
                                        {!assignmentInfo?.is_public && (
                                            <UploadBtn course_id={course_id} userRole="student" />
                                        )}
                                    </span>
                                </>
                            ) : null}
                        </span>
                    </div>
                    <div className="course-detail-management">
                        <div className="table-student-list">
                            <div className="filter-toolbar-wrapper">
                                <div className="filter-toolbar">
                                    <div className="filter-toolbar-item filterinput-wrapper w-50">
                                        <InputSearch
                                            displayType="style-dream"
                                            placeholder={t("shared.search")}
                                            value={values.searchUserText}
                                            onChange={(e) => setValues({ searchUserText: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSearch(e.target.value);
                                                }
                                            }}
                                            onClickIconSearch={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <Table
                                className="app-table"
                                columns={studentColumns}
                                dataSource={studentDataSource}
                                scroll={{ x: "auto" }}
                                pagination={{
                                    showSizeChanger: false,
                                    ...studentPagination,
                                    onChange: (page) => {
                                        studentFetch({ page: page });
                                    },
                                }}
                                loading={studentLoading}
                                responsiveOnMobile={true}
                                mobileHeaderColumns={["name", "personal_number", "username"]}
                            />
                        </div>
                    </div>
                </Spin>

                {/* Course detail modal: */}
                <Modal
                    className="course-detail-modal"
                    visible={values.isConfirmModal}
                    onOk={handleDeleteStudent}
                    onCancel={() => setValues({ isConfirmModal: false })}
                    okText={t("action.delete")}
                    cancelText={t("action.cancel")}
                >
                    <span className="modal-icon">
                        <DeleteBin />
                    </span>
                    <p className="modal-title">{t("course.confirm_delete")}</p>
                    <p>
                        {values.pendingDeleteItem?.name} {t("course.will_delete")}
                    </p>
                </Modal>

                {["student", "teacher"].includes(isAddUserModal) && (
                    <ModalAddUser
                        userRole={isAddUserModal}
                        courseId={course_id}
                        visible={!!isAddUserModal}
                        onOk={() => {
                            studentRefetch({}, true);
                            setIsAddUserModal(false);
                        }}
                        onCancel={() => setIsAddUserModal(false)}
                    />
                )}

                {["student", "teacher"].includes(isUpdateUserModal) && (
                    <ModalUpdateUser
                        userRole={isUpdateUserModal}
                        courseId={courseId}
                        examInfo={examInfo}
                        editingUser={values.selectedUser}
                        visible={!!isUpdateUserModal}
                        onCancel={() => setIsUpdateUserModal(false)}
                        onOk={() => setIsUpdateUserModal(false)}
                        refetch={studentRefetch}
                    />
                )}

                <ModalInviteLink
                    targetId={showModalInviteLink.assignmentId}
                    visible={showModalInviteLink.isShown}
                    code={code}
                    inviteLink={`${location.origin}/exam-checkin?code=` + code}
                    onOk={() => {
                        setShowModalInviteLink({ ...showModalInviteLink, isShown: false });
                    }}
                    onCancel={() => {
                        setShowModalInviteLink({ ...showModalInviteLink, isShown: false });
                    }}
                />

                {isMarkingModal && (
                    <ModalMarkAssignment
                        className="course-detail-modal detail-marking"
                        centered
                        title={t("course.mark_assignment")}
                        footer={null}
                        visible={isMarkingModal}
                        onCancel={() => setIsMarkingModal(false)}
                        destroyOnClose
                        width="calc(100vw - 40px)"
                        height="calc(100vh - 40px)"
                        style={{
                            maxWidth: "1400px",
                            maxHeight: "1400px",
                            margin: "20px 0",
                        }}
                        closeIcon={<Icon name="icon-cross-thin" />}
                        markingAssignmentId={values.markingAssignmentId}
                        setIsPersonalLearningProgress={setIsPersonalLearningProgress}
                        setStudentInfo={setStudentInfo}
                    />
                )}

                {isGeneralLearningProgress && (
                    <ModalGeneralLearningProgress
                        className="course-detail-modal detail-learning-progress"
                        centered
                        title={t("report.general_learning_progress")}
                        footer={null}
                        visible={isGeneralLearningProgress}
                        onCancel={handleCloseModalGeneralLearningProgress}
                        destroyOnClose
                        width="calc(100vw - 40px)"
                        height="calc(100vh - 40px)"
                        style={{
                            maxWidth: "1400px",
                            maxHeight: "1400px",
                            margin: "20px 0",
                        }}
                        closeIcon={<Icon name="icon-cross-thin" />}
                        viewProgressAssignment={values.viewProgressAssignment}
                        viewProgressLevel={values.viewProgressLevel}
                        viewProgressCourse={values.viewProgressCourse}
                        viewProgressIsUnit={values.viewProgressIsUnit}
                    />
                )}

                {isPersonalLearningProgress && (
                    <ModalPersonalLearningProgress
                        title={t("report.personal_learning_progress")}
                        visible={isPersonalLearningProgress}
                        viewProgressCourse={values.viewProgressCourse}
                        viewProgressLevel={values.viewProgressLevel}
                        viewProgressIsUnit={values.viewProgressIsUnit}
                        onCancel={handleCloseModalPersonalLearningProgress}
                        viewProgressStudent={studentInfo}
                    />
                )}

                {/* <Modal
                title={t("history.editing_history")}
                visible={values.isModalHistory}
                onOk={() => {
                    setValues({ isModalHistory: false });
                }}
                onCancel={() => {
                    setValues({ isModalHistory: false });
                }}
                closeIcon={<CloseIconSVG />}
                footer={null}
                className={"modal-add-course modal_histories"}
                width={"auto"}
                destroyOnClose={true}
                centered={true}
            >
                <HistoryContainer modal_id={course_id} type="course_user_history" />
            </Modal> */}

                <PopConfirm
                    type={values.confirm_type}
                    visible={values.isConfirmModalVisible}
                    onOk={handlePopConfirmOk}
                    onCancel={() => setValues({ isConfirmModalVisible: false })}
                />
            </div>

            {/* modal view exam result */}
            {values.isModalViewExamResult && (
                <Modal
                    className="add-exam-question-modal modal-mark-assignment"
                    visible={values.isModalViewExamResult}
                    onCancel={() => setValues({ isModalViewExamResult: false })}
                    width={"80%"}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose
                >
                    <Spin spinning={values.loadingExamResult}>
                        {values.loadingExamResult ? (
                            <></>
                        ) : (
                            <ViewScoreStudent
                                markingRecord={{ ...values.currentExamResult }}
                                isMarking={ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) ? true : false}
                                closeModal={() => {
                                    setValues({ isModalViewExamResult: false });
                                }}
                                isModalBox={true}
                            />
                        )}
                    </Spin>
                </Modal>
            )}
        </Modal>
    );
};

const TableColumnFilterMenu = (props) => {
    const {
        cateName = "",
        cateNameNotFrom = -1,
        columnName = "",
        filterKeys = [],
        selectedFilterKey = -1,
        onChangeFilterKey = () => {},
    } = props;

    return (
        <ul className="table-column-filter-menu">
            {filterKeys.map((keyItem, keyIndex) => {
                return (
                    <li
                        key={`${columnName}-${keyIndex}`}
                        className={selectedFilterKey === keyItem.value ? "active" : ""}
                        onClick={() => onChangeFilterKey(keyItem.value)}
                    >
                        {cateNameNotFrom === -1 || keyIndex < cateNameNotFrom ? (
                            <>{t(`${cateName}.${keyItem.name}`)}</>
                        ) : (
                            <>{t(`${keyItem.name}`)}</>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default CourseDetailModal;
