import { Col, Form, InputNumber, Row, Typography, notification } from "antd";
import { evaluate, unit } from "mathjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import { default as CustomButton } from "src/modules/components/Button";
import Editor from "src/modules/components/Editor";
import Icon from "src/modules/components/Icon";
// import EditorInput from "src/modules/components/EditorInput";
import InputLatex from "src/modules/components/Input/InputLatex";
import configEditor from "src/utils/configEditor";
import { getUnitToUsedWithMathJS, getValueToUsedWithMathJS } from "src/utils/helpers/unitWithMathjs";

import "./DetailMathTextDropdown.scss";
// import parser from "src/utils/parser";
import { TXT_RPLC_ANS } from "./config";

const editorConfigQuestion = configEditor.question();

function DetailMathTextDropdown({
    form,
    formStates,
    currQType,
    // Question id:
    questionId,
    questionParentId,
    // Fetch:
    extraQuestionFetchParams,
    // Fill in the form:
    isFormFilled,
    onFillFormQuestionData,
    handleFormAction,
}) {
    const { t } = useTranslation();
    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });

    const timeoutIds = useRef({});

    const value_mathcharOptionList = ["numbers_only", "numbers_n_basic_symbols"];
    const unit_mathcharOptionList = ["units_SI", "units_US"];
    const value_mathcharOptionSelected = "numbers_only";
    const unit_mathcharOptionSelected = "units_SI";

    // // Convert array of strings to array of Latex strings:
    // const toOperandStrings = (strings) => {
    //     // Each item of strings can be:
    //     // - a normal string.
    //     // - a string like 'data-latex="123"'.
    //     if (strings?.length) {
    //         for (let t = 0; t < strings.length; t++) {
    //             if (strings[t]?.includes("data-latex")) {
    //                 strings[t] = strings[t].slice(12, strings[t].length - 2 + 1);
    //             }
    //         }
    //     }
    // };

    // Auto-complete for correct-answer field:
    const autocompleteCorrectAnswer = (fieldName) => {
        const formAnswer = form.getFieldsValue()._item_w_answer || [];
        const formAnswerField = formAnswer[fieldName];

        // If required fields are filled, the correct answer field is autocompleted:

        // // - This solution is used for <EditorInput />:
        // if (
        //     formAnswerField?.lhs_operand?.value &&
        //     formAnswerField?.lhs_operand?.unit &&
        //     formAnswerField?.rhs_operand?.unit
        // ) {
        //     // 1. Left-hand side:
        //     // - Value & unit:
        //     const _left_hs_value = formAnswerField.lhs_operand.value?.match(/data-latex="(.*?)"/g);
        //     const _left_hs_unit = formAnswerField.lhs_operand.unit?.match(/data-latex="(.*?)"/g);
        //     toOperandStrings(_left_hs_value);
        //     toOperandStrings(_left_hs_unit);

        //     // 2. Right-hand side:
        //     // - Value & unit:
        //     const _right_hs_unit = formAnswerField.rhs_operand.unit?.match(/data-latex="(.*?)"/g);
        //     toOperandStrings(_right_hs_unit);

        //     // 3. Auto-complete:
        //     const l1 = _left_hs_value?.length ? getValueToUsedWithMathJS(_left_hs_value[0]) : "";
        //     const l2 = _left_hs_unit?.length ? getUnitToUsedWithMathJS(_left_hs_unit[0]) : "";
        //     const r2 = _right_hs_unit?.length ? getUnitToUsedWithMathJS(_right_hs_unit[0]) : "";
        //     // Convert (Use: leftHand.toNumber(r2), leftHand.toNumeric(r2)):
        //     const leftHand = unit(`${l1} ${l2}`);
        //     let rightHand = false;
        //     try {
        //         rightHand = leftHand.toNumeric(r2);
        //     } catch (error) {
        //         notification.error({
        //             message: "Can not convert value!",
        //         });
        //     }
        //     // Update form:
        //     const result = {
        //         ...formAnswerField,
        //         rhs_operand: {
        //             value: rightHand ? parser.generateMathStatic(rightHand) : undefined,
        //             unit: formAnswerField.rhs_operand.unit,
        //         },
        //     };
        //     const formAnswerClone = [...formAnswer];
        //     formAnswerClone[fieldName] = result;
        //     form.setFieldsValue({
        //         ...form.getFieldsValue(),
        //         _item_w_answer: formAnswerClone,
        //     });
        // }

        // - This solution is used for <InputLatex />:
        if (
            formAnswerField?.lhs_operand?.value &&
            formAnswerField?.lhs_operand?.unit &&
            formAnswerField?.rhs_operand?.unit
        ) {
            // 1. Left-hand side:
            // - Value & unit:
            const _left_hs_value = formAnswerField.lhs_operand.value;
            const _left_hs_unit = formAnswerField.lhs_operand.unit;

            // 2. Right-hand side:
            // - Value & unit:
            const _right_hs_unit = formAnswerField.rhs_operand.unit;

            // 3. Auto-calculate and Auto-complete:
            const l1 = getValueToUsedWithMathJS(_left_hs_value);
            const l2 = getUnitToUsedWithMathJS(_left_hs_unit);
            const r2 = getUnitToUsedWithMathJS(_right_hs_unit);

            // Calculate:
            let l1Eval = false;
            try {
                l1Eval = evaluate(l1);
            } catch (error) {
                // notification.error({
                //     message: "Can not calculate value!",
                // });
            }

            // Convert (Use: leftHand.toNumber(r2), leftHand.toNumeric(r2)):
            let leftHand = false;
            let rightHand = false;
            try {
                leftHand = unit(`${l1Eval || l1} ${l2}`);
            } catch (error) {
                // notification.error({
                //     message: "Invalid value/unit!",
                // });
            }
            if (leftHand) {
                try {
                    rightHand = leftHand.toNumeric(r2);
                } catch (error) {
                    notification.error({
                        message: "Can not convert value!",
                    });
                }
            }
            // Update form:
            const result = {
                ...formAnswerField,
                rhs_operand: {
                    value: rightHand ? `${rightHand}` : undefined,
                    unit: formAnswerField.rhs_operand.unit,
                },
            };
            const formAnswerClone = [...formAnswer];
            formAnswerClone[fieldName] = result;
            form.setFieldsValue({
                ...form.getFieldsValue(),
                _item_w_answer: formAnswerClone,
            });
        }
    };

    const prepareAnswerData = () => {
        const answer = [];
        const answerCorrect = [];
        const formAnswer = form.getFieldsValue()._item_w_answer || [];

        // Get values and units:

        // // - This solution is used for <EditorInput />:
        // for (let i = 0; i < formAnswer.length; i++) {
        //     // 1. Left-hand side:
        //     // - Value & unit:
        //     const _left_hs_value = formAnswer[i].lhs_operand?.value?.match(/data-latex="(.*?)"/g);
        //     const _left_hs_unit = formAnswer[i].lhs_operand?.unit?.match(/data-latex="(.*?)"/g);
        //     toOperandStrings(_left_hs_value);
        //     toOperandStrings(_left_hs_unit);

        //     // 2. Right-hand side:
        //     // - Value & unit:
        //     const _right_hs_value = formAnswer[i].rhs_operand?.value?.match(/data-latex="(.*?)"/g);
        //     const _right_hs_unit = formAnswer[i].rhs_operand?.unit?.match(/data-latex="(.*?)"/g);
        //     toOperandStrings(_right_hs_value);
        //     toOperandStrings(_right_hs_unit);

        //     // 3. Connect strings:
        //     const l1 = _left_hs_value?.length ? _left_hs_value[0] : "";
        //     const l2 = _left_hs_unit?.length ? _left_hs_unit[0] : "";
        //     const r1 = _right_hs_value?.length ? _right_hs_value[0] : "";
        //     const r2 = _right_hs_unit?.length ? _right_hs_unit[0] : "";
        //     let _str = `${l1} ${l2} = ? ${r2}`;
        //     let _strCorrect = `${l1} ${l2} = ${r1} ${r2}`;

        //     // 4. Update:
        //     answer.push(_str);
        //     answerCorrect.push(_strCorrect);
        // }

        // - This solution is used for <InputLatex />:
        for (let i = 0; i < formAnswer.length; i++) {
            // 1. Left-hand side:
            // - Value & unit:
            const _left_hs_value = formAnswer[i].lhs_operand?.value;
            const _left_hs_unit = formAnswer[i].lhs_operand?.unit;

            // 2. Right-hand side:
            // - Value & unit:
            const _right_hs_value = formAnswer[i].rhs_operand?.value;
            const _right_hs_unit = formAnswer[i].rhs_operand?.unit;

            // 3. Connect strings:
            const l1 = _left_hs_value;
            const l2 = _left_hs_unit;
            const r1 = _right_hs_value;
            const r2 = _right_hs_unit;
            let _str = `${l1} ${l2} = ${TXT_RPLC_ANS} ${r2}`;
            let _strCorrect = `${l1} ${l2} = ${r1} ${r2}`;

            // 4. Update:
            answer.push(_str);
            answerCorrect.push(_strCorrect);
        }
        return { answer, answerCorrect };
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            const _ansswerData = prepareAnswerData();
            // Data:
            const _questionData = {
                type: currQType,
                answer: _ansswerData.answerCorrect,
                // answer1: _ansswerData.answer,
            };
            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                correct_answer: _questionData.answer,
                answer: _ansswerData.answer,
                // Special fields:
                type: _questionData.type,
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const handleAddNewAnswer = () => {
        const currAnswers = [...(form.getFieldValue("_item_w_answer") || [])];
        // Update form:
        currAnswers.push({});
        form.setFieldsValue({
            ...form.getFieldsValue(),
            _item_w_answer: currAnswers,
        });
    };

    const handleDeleteAnswer = (_formAnswerField) => {
        const currAnswers = [...(form.getFieldValue("_item_w_answer") || [])];
        const indexOfField = _formAnswerField?.name;
        // Update form:
        if (indexOfField >= 0) {
            currAnswers.splice(indexOfField, 1);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                _item_w_answer: currAnswers,
            });
        }
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            // Preparation:
                            const localAnswers = (qData?.answer || []).map((item, index) => {
                                const operandArr = item.split(" ");
                                return {
                                    lhs_operand: {
                                        value: `${operandArr[0]}`,
                                        unit: `${operandArr[1]}`,
                                    },
                                    rhs_operand: {
                                        value: `${operandArr[3]}`,
                                        unit: `${operandArr[4]}`,
                                    },
                                };
                            });

                            // Set form data:
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                language_id: qData?.language?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "answer".
                                _item_w_answer: localAnswers,
                            };
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res[1].data;

                            // Preparation:
                            const localAnswers = (qData?.answer || []).map((item, index) => {
                                const operandArr = item.split(" ");
                                return {
                                    lhs_operand: {
                                        value: `${operandArr[0]}`,
                                        unit: `${operandArr[1]}`,
                                    },
                                    rhs_operand: {
                                        value: `${operandArr[3]}`,
                                        unit: `${operandArr[4]}`,
                                    },
                                };
                            });

                            // Set form data:
                            const questionFormData = {
                                question: qData?.question || "",
                                score: qData?.score || undefined,
                                // Special fields: "answer".
                                _item_w_answer: localAnswers,
                            };
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <React.Fragment>
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion}></Editor>
                </Form.Item>
            </Row>

            <Row className="question-formitems question-setanswers">
                <Typography className="question-setanswers-title">{t("question.answer")}</Typography>
                {/* <div className="question-formfield-msg">
                    <span className="msg-header">{t("shared.note")}:</span>
                    <span>{t("q_imglbls_dragdrop.msg_set_answer_for_label")}.</span>
                </div> */}
                <div className="question-answer-list">
                    <Form.List name="_item_w_answer">
                        {(fields, { add, remove }, { errors }) => (
                            <Row gutter={[32, 16]}>
                                {fields.map((field, index) => (
                                    <Col key={field.key} xs={24} sm={24} xxl={12}>
                                        <div className="list-item">
                                            <div className="list-item-body">
                                                <span className="list-item-order">{`(${index + 1})`}</span>
                                                <div className="list-item-answer item-w-answer">
                                                    {/* Left-hand side operand: */}
                                                    <div className="child-operand">
                                                        <Form.Item
                                                            {...field}
                                                            key="lhs_operand_value"
                                                            name={[field.name, "lhs_operand", "value"]}
                                                            validateTrigger={["onChange", "onBlur"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: t("message.required"),
                                                                },
                                                            ]}
                                                        >
                                                            {/* <Input
                                                            className="app-input"
                                                            placeholder={t("q_math_text_dropdown.value")}
                                                        /> */}
                                                            {/* <EditorInput
                                                            type="mathchars"
                                                            editorName={`editor-mc-val-${field.key}`}
                                                            editorToolbarName={`editor-mc-val-${field.key}-toolbar`}
                                                            editorDialogSettings={{
                                                                mcFltrOptList: value_mathcharOptionList,
                                                                mcFltrOptSelected: value_mathcharOptionSelected,
                                                                isOneOperandOnly: true,
                                                            }}
                                                            editorConfig={{
                                                                keydown: (e) => {
                                                                    if (e?.code === "Backspace") {
                                                                        return;
                                                                    }
                                                                    e.preventDefault();
                                                                },
                                                            }}
                                                            placeholder={t("q_math_text_dropdown.value")}
                                                            onChange={() => {
                                                                autocompleteCorrectAnswer(field.name);
                                                            }}
                                                        /> */}
                                                            <InputLatex
                                                                filterOptions={value_mathcharOptionList}
                                                                filterOptionSelected={value_mathcharOptionSelected}
                                                                placeholder={t("q_math_text_dropdown.value")}
                                                                onChange={() => {
                                                                    clearTimeout(
                                                                        timeoutIds.current[`f${field.name}_l1`]
                                                                    );
                                                                    timeoutIds.current[
                                                                        `f${field.name}_l1`
                                                                    ] = setTimeout(() => {
                                                                        autocompleteCorrectAnswer(field.name);
                                                                    }, 500);
                                                                }}
                                                                dropdownProps={{
                                                                    placement: "bottomLeft",
                                                                    onDropdownMenuClose: () => {
                                                                        autocompleteCorrectAnswer(field.name);
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            key="lhs_operand_unit"
                                                            name={[field.name, "lhs_operand", "unit"]}
                                                            validateTrigger={["onChange", "onBlur"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: t("message.required"),
                                                                },
                                                            ]}
                                                        >
                                                            {/* <Input
                                                            className="app-input"
                                                            placeholder={t("q_math_text_dropdown.unit")}
                                                        /> */}
                                                            {/* <EditorInput
                                                            type="mathchars"
                                                            editorName={`editor-mc-unit-${field.key}`}
                                                            editorToolbarName={`editor-mc-unit-${field.key}-toolbar`}
                                                            editorDialogSettings={{
                                                                mcFltrOptList: unit_mathcharOptionList,
                                                                mcFltrOptSelected: unit_mathcharOptionSelected,
                                                                isOneOperandOnly: true,
                                                            }}
                                                            editorConfig={{
                                                                keydown: (e) => {
                                                                    if (e?.code === "Backspace") {
                                                                        return;
                                                                    }
                                                                    e.preventDefault();
                                                                },
                                                            }}
                                                            placeholder={t("q_math_text_dropdown.unit")}
                                                            className="child-unit"
                                                            onChange={() => {
                                                                autocompleteCorrectAnswer(field.name);
                                                            }}
                                                        /> */}
                                                            <InputLatex
                                                                filterOptions={unit_mathcharOptionList}
                                                                filterOptionSelected={unit_mathcharOptionSelected}
                                                                placeholder={t("q_math_text_dropdown.unit")}
                                                                onChange={() => {
                                                                    clearTimeout(
                                                                        timeoutIds.current[`f${field.name}_l2`]
                                                                    );
                                                                    timeoutIds.current[
                                                                        `f${field.name}_l2`
                                                                    ] = setTimeout(() => {
                                                                        autocompleteCorrectAnswer(field.name);
                                                                    }, 500);
                                                                }}
                                                                dropdownProps={{
                                                                    placement: "bottomLeft",
                                                                    onDropdownMenuClose: () => {
                                                                        autocompleteCorrectAnswer(field.name);
                                                                    },
                                                                }}
                                                                className="child-unit"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <span className="list-item-text">=</span>
                                                    {/* Right-hand side operand: */}
                                                    <div className="child-operand">
                                                        <Form.Item
                                                            {...field}
                                                            key="rhs_operand_value"
                                                            name={[field.name, "rhs_operand", "value"]}
                                                            validateTrigger={["onChange", "onBlur"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: t("message.required"),
                                                                },
                                                            ]}
                                                        >
                                                            {/* <Input
                                                            className="app-input child-val-ans"
                                                            placeholder={`${t("q_math_text_dropdown.value")} (${t(
                                                                "question.answer"
                                                            )})`}
                                                        /> */}
                                                            {/* <EditorInput
                                                            type="mathchars"
                                                            editorName={`editor-mc-val2-${field.key}`}
                                                            editorToolbarName={`editor-mc-val2-${field.key}-toolbar`}
                                                            editorDialogSettings={{
                                                                mcFltrOptList: value_mathcharOptionList,
                                                                mcFltrOptSelected: value_mathcharOptionSelected,
                                                                isOneOperandOnly: true,
                                                            }}
                                                            editorConfig={{
                                                                keydown: (e) => {
                                                                    if (e?.code === "Backspace") {
                                                                        return;
                                                                    }
                                                                    e.preventDefault();
                                                                },
                                                            }}
                                                            placeholder={`${t("q_math_text_dropdown.value")} (${t(
                                                                "question.answer"
                                                            )})`}
                                                            className="child-val-ans"
                                                        /> */}
                                                            <InputLatex
                                                                filterOptions={value_mathcharOptionList}
                                                                filterOptionSelected={value_mathcharOptionSelected}
                                                                placeholder={`${t("q_math_text_dropdown.value")} (${t(
                                                                    "question.answer"
                                                                )})`}
                                                                dropdownProps={{
                                                                    placement: "bottomRight",
                                                                }}
                                                                className="child-val-ans"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            key="rhs_operand_unit"
                                                            name={[field.name, "rhs_operand", "unit"]}
                                                            validateTrigger={["onChange", "onBlur"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: t("message.required"),
                                                                },
                                                            ]}
                                                        >
                                                            {/* <Input
                                                            className="app-input"
                                                            placeholder={t("q_math_text_dropdown.unit")}
                                                        /> */}
                                                            {/* <EditorInput
                                                            type="mathchars"
                                                            editorName={`editor-mc-unit2-${field.key}`}
                                                            editorToolbarName={`editor-mc-unit2-${field.key}-toolbar`}
                                                            editorDialogSettings={{
                                                                mcFltrOptList: unit_mathcharOptionList,
                                                                mcFltrOptSelected: unit_mathcharOptionSelected,
                                                                isOneOperandOnly: true,
                                                            }}
                                                            editorConfig={{
                                                                keydown: (e) => {
                                                                    if (e?.code === "Backspace") {
                                                                        return;
                                                                    }
                                                                    e.preventDefault();
                                                                },
                                                            }}
                                                            placeholder={t("q_math_text_dropdown.unit")}
                                                            className="child-unit"
                                                            onChange={() => {
                                                                autocompleteCorrectAnswer(field.name);
                                                            }}
                                                        /> */}
                                                            <InputLatex
                                                                filterOptions={unit_mathcharOptionList}
                                                                filterOptionSelected={unit_mathcharOptionSelected}
                                                                placeholder={t("q_math_text_dropdown.unit")}
                                                                onChange={() => {
                                                                    clearTimeout(
                                                                        timeoutIds.current[`f${field.name}_r2`]
                                                                    );
                                                                    timeoutIds.current[
                                                                        `f${field.name}_r2`
                                                                    ] = setTimeout(() => {
                                                                        autocompleteCorrectAnswer(field.name);
                                                                    }, 500);
                                                                }}
                                                                dropdownProps={{
                                                                    placement: "bottomRight",
                                                                    onDropdownMenuClose: () => {
                                                                        autocompleteCorrectAnswer(field.name);
                                                                    },
                                                                }}
                                                                className="child-unit"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="list-item-actions">
                                                <span className="action-wrapper">
                                                    <CustomButton
                                                        className="action-btn danger"
                                                        type="simple"
                                                        icon={<Icon name="icon-delete" />}
                                                        onClick={() => handleDeleteAnswer(field)}
                                                    ></CustomButton>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Form.List>
                    <div className="question-answer-actions">
                        <span className="action-add" onClick={handleAddNewAnswer}>
                            <span>{t("question_fillblanks.add_answer")}</span>
                            <button type="button">+</button>
                        </span>
                    </div>
                </div>
            </Row>

            <Row className="question-formitems">
                <Col>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DetailMathTextDropdown;
