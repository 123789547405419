import React from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import "./Hotspot.scss";
import HotspotAnswer from "src/modules/containers/QuestionDetail/containers/DetailHotspot/components/HotspotAnswer";

function Hotspot(props) {
    const {
        isReadonly = false,
        id,
        /**  Type of defaultAnswer
         *   @param {String} defaultAnswer: student's answer || same answred
         */
        defaultAnswer,
        question = "", // tiêu đề câu hỏi
        answer = [], //nội dung câu hỏi
        type,
        onChange = () => {},
        qestionData = {},
    } = props;

    const { t } = useTranslation();
    const { width, height, src, paths, mode } = qestionData || {};

    return (
        <div className="q-writing q-ighlight-image">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question || "",
                    }}
                />
            </div>

            <div className="content-highlight-image">
                <HotspotAnswer
                    isReadonly={isReadonly}
                    onChange={onChange}
                    width={width}
                    height={height}
                    src={src}
                    paths={paths}
                    mode={mode}
                    isLoadZwibbler={true}
                    defaultAnswer={defaultAnswer}
                />
            </div>
        </div>
    );
}

export default Hotspot;
