import { useEffect, useState } from "react";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { ReactComponent as SvgFolder } from "src/modules/components/FileManager/assets/custom/folder.svg";

const Item = ({
    controller,
    activeFolder,
    listFolders = () => {},
    removeFiles = () => {},
    updateFolder = () => {},
    folder,
    flow,
}) => {
    const [collapse, setCollapse] = useState(true);
    const [children, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Values: "load-children" || false.

    const handleOpen = () => {
        setCollapse(false);
    };

    const handleClose = () => {
        setCollapse(true);
    };

    useEffect(() => {
        if (folder.id === null) {
            setTimeout(() => {
                handleOpen();
                handleLoadFiles();
            });
        }

        controller.set(`sidebar_item.${folder.id}.open`, () => {
            handleOpen();
            controller.call("sidebar.set_active_folder", folder);
        });

        return () => {
            controller.remove(`sidebar_item.${folder.id}.open`);
        };
    }, []);

    useEffect(() => {
        controller.set(`sidebar_item.${folder.id}.update`, (itemData) => {
            updateFolder(itemData);
        });
        controller.set(`sidebar_item.${folder.id}.set_is_empty`, (boolState) => {
            /**
             * View README.md to understand this function:
             * src\modules\components\FileManager\README.md
             */
            updateFolder({ ...folder, state: boolState ? "empty" : "not-empty" });
        });

        return () => {
            controller.remove(`sidebar_item.${folder.id}.update`);
            controller.remove(`sidebar_item.${folder.id}.set_is_empty`);
        };
    }, [updateFolder]);

    useEffect(() => {
        controller.set(`sidebar_item.${folder.id}.load`, () => {
            const res = listFolders(folder.id);
            if (res instanceof Promise) {
                setIsLoading("load-children");
                res.then((folders) => {
                    setIsLoading(false);
                    if (folders instanceof Array) {
                        setChildren(folders);
                        if (folders.length === 0) {
                            controller.call(`sidebar_item.${folder.id}.set_is_empty`, true);
                        }
                    }
                }).catch(({ message }) => {
                    setIsLoading(false);
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        });
        controller.set(`sidebar_item.${folder.id}.remove_child_list`, (childList) => {
            if (childList?.length) {
                const newChildren = [...children].filter((f) => {
                    for (let i = 0; i < childList.length; i++) {
                        if (f.id === childList[i].id) {
                            return false;
                        }
                    }
                    return true;
                });
                setChildren(newChildren);
            }
        });
        controller.set(`sidebar_item.${folder.id}.remove_child`, (child) => {
            if (child && child.id) {
                const newChildren = [...children].filter((f) => f.id != child.id);
                setChildren(newChildren);
            }
        });
        controller.set(`sidebar_item.${folder.id}.add_child_list`, (childList) => {
            if (childList?.length) {
                setChildren([...childList, ...children]);
                controller.call(`sidebar_item.${folder.id}.set_is_empty`, false);
            }
        });
        controller.set(`sidebar_item.${folder.id}.add_child`, (child) => {
            if (child && child.id) {
                setChildren([child, ...children]);
                controller.call(`sidebar_item.${folder.id}.set_is_empty`, false);
            }
        });
        controller.set(`sidebar_item.${folder.id}.click_delete`, () => {
            const res = removeFiles([folder]);
            if (res instanceof Promise) {
                res.then(() => {
                    // 1. Remove all files & folders from Content section:
                    controller.call("content.remove_files", []);
                    // 2. Remove selected folder from Sidebar section:
                    let parent_id = folder.parent_id || null;
                    controller.call(`sidebar_item.${parent_id}.remove_child`, folder);
                    // 3. Un-select folder:
                    controller.call("sidebar.set_active_folder", null);
                }).catch(({ message }) => {
                    message && controller.call("notification.show", message, 5000, "error");
                });
            }
        });

        return () => {
            controller.remove(`sidebar_item.${folder.id}.load`);
            controller.remove(`sidebar_item.${folder.id}.remove_child_list`);
            controller.remove(`sidebar_item.${folder.id}.remove_child`);
            controller.remove(`sidebar_item.${folder.id}.add_child_list`);
            controller.remove(`sidebar_item.${folder.id}.add_child`);
            controller.remove(`sidebar_item.${folder.id}.click_delete`);
        };
    }, [children]);

    useEffect(() => {
        if (collapse) {
        } else {
            controller.call(`sidebar_item.${folder.id}.load`);
        }
    }, [collapse]);

    const handleLoadFiles = () => {
        controller.call("content.open_folder", folder);
        controller.call("shared.set_flow_folders", flow);
        controller.call("sidebar.set_active_folder", folder);
    };

    const handleUpdateFolder = (newData, hasPrevState = true) => {
        if (newData?.id) {
            if (hasPrevState === true) {
                setChildren((prevState) => {
                    const newChildren = [...prevState];
                    for (let i = 0; i < newChildren.length; i++) {
                        if (newChildren[i].id === newData.id) {
                            newChildren[i] = { ...newChildren[i], ...newData };
                            break;
                        }
                    }
                    return newChildren;
                });
            } else {
                const newChildren = [...children];
                for (let i = 0; i < newChildren.length; i++) {
                    if (newChildren[i].id === newData.id) {
                        newChildren[i] = { ...newChildren[i], ...newData };
                        break;
                    }
                }
                setChildren(newChildren);
            }
        }
    };

    let className = "file-manager-sidebar-item";
    if (folder.id === activeFolder?.id) {
        className += " active";
    }
    if (!collapse) {
        className += " has-scope-line";
    }

    if (collapse) {
        return (
            <li className={className}>
                <span className="file-manager-sidebar-item-label">
                    {folder.id && (!folder.state || folder.state === "empty") ? (
                        <span style={{ marginRight: "19px" }}></span>
                    ) : (
                        <span className="file-manager-sidebar-item-collapse-target" onClick={handleOpen}>
                            <CaretRightOutlined />
                        </span>
                    )}
                    <span className="file-manager-sidebar-item-name" onClick={handleLoadFiles}>
                        <span className="item-icon">
                            <SvgFolder />
                        </span>
                        <span className="item-name">{folder.name}</span>
                    </span>
                </span>
            </li>
        );
    }

    return (
        <li className={className}>
            <span className="file-manager-sidebar-item-label">
                {folder.id && (!folder.state || folder.state === "empty") ? (
                    <span style={{ marginRight: "19px" }}></span>
                ) : (
                    <span className="file-manager-sidebar-item-collapse-target" onClick={handleClose}>
                        <CaretDownOutlined />
                    </span>
                )}
                <span className="file-manager-sidebar-item-name" onClick={handleLoadFiles}>
                    <span className="item-icon">
                        <SvgFolder />
                    </span>
                    <span className="item-name">{folder.name}</span>
                </span>
            </span>

            {isLoading === "load-children" && children.length === 0 ? (
                <ul>
                    <li className="file-manager-sidebar-item skeleton-loading">
                        <span className="file-manager-sidebar-item-label">
                            <span style={{ marginRight: "19px" }}></span>
                            <span className="file-manager-sidebar-item-name"></span>
                        </span>
                    </li>
                </ul>
            ) : null}

            {children.length > 0 && (
                <ul>
                    {children.map((child) => (
                        <Item
                            key={child.id}
                            activeFolder={activeFolder}
                            controller={controller}
                            listFolders={listFolders}
                            removeFiles={removeFiles}
                            updateFolder={handleUpdateFolder}
                            folder={child}
                            flow={[...flow, child]}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default Item;
