import * as faceapi from "face-api.js";

import configs from "src/configs";

const { MODEL, MIN_CONFIDENCE, INPUT_SIZE, SCORE_THRES_HOLD } = configs.EXAM_PROCTORING.FACE_IN_ID_CARD;

const SSD_MOBILENETV1 = "ssd_mobilenetv1";
const TINY_FACE_DETECTOR = "tiny_face_detector";

function getFaceDetectorOptions({ model, minConfidence, inputSize, scoreThreshold } = {}) {
    const model2 = model || MODEL;
    return model2 === SSD_MOBILENETV1
        ? new faceapi.SsdMobilenetv1Options({ minConfidence: minConfidence || MIN_CONFIDENCE })
        : new faceapi.TinyFaceDetectorOptions({
              inputSize: inputSize || INPUT_SIZE,
              scoreThreshold: scoreThreshold || SCORE_THRES_HOLD,
          });
}

export { getFaceDetectorOptions };
