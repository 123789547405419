import { generateConfig } from "./_default";

/**
 * Editor (input - Plain text format).
 * IMPORTANT NOTES:
 * - Input's value has to contain only HTML elements with 'display: inline;'.
 * - Input's value hasn't to include <br>, <div>, <p> or any HTML tag with with 'display: block | inline-block;'.
 * @param {Object} values Custom config values.
 * @returns Editor's config.
 */
export const generateConfigForInput = (values = {}) => {
    const {
        // Counting & limiting words/characters:
        wordCountMax,
        onCountWord,
    } = values;

    const { setup, ...rest } = generateConfig({
        customTypeName: "input",
        editingMode: "inline",
        isToolbarInsideEditorRoot: false,
        wordCountMax,
        onCountWord,
    });

    return {
        ...rest,

        // Plugins, toolbar, menubar:
        plugins: "paste" + " math",
        toolbar: "undo redo" + " | math",
        toolbar_mode: "sliding",
        menubar: false,
        contextmenu: false,
        quickbars_selection_toolbar: false,
        quickbars_insert_toolbar: false,

        // Settings:
        paste_as_text: true,
        forced_root_block: false, // Setting 'forced_root_block: false' has been deprecated in TinyMCE 5.10.

        // Elements:
        invalid_elements: "br,hr",

        // Custom settings:
        setup: (editor) => {
            if (setup instanceof Function) {
                setup(editor);
            }

            editor.on("keydown", function (e) {
                /**
                 * Prevent inserting new lines and force a single line only.
                 */
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            });
        },
        paste_preprocess: function (plugin, args) {
            // Deal with unexpected `&nbsp;`:
            args.content = args.content.trim();
        },
    };
};
