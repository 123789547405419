import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import Context from "../buttons/Context";
import { Buttons } from "../buttons";
import Button from "../buttons/Button";
import { getMathCharOptionData, mathcharFilterOptions } from "./filterOptions";
import "./index.scss";
import clsx from "clsx";
import { t } from "react-i18next";

const getMathcharFilterOptions = (filterOptions) => {
    const currFltrOpts = filterOptions || window.mcFltrOptList;
    if (currFltrOpts?.length) {
        const _fltrOpts = [];
        for (let i = 0; i < mathcharFilterOptions.length; i++) {
            const _fltrGroup = {};
            _fltrGroup.key = mathcharFilterOptions[i].key;
            _fltrGroup.options = [...mathcharFilterOptions[i].options];
            _fltrGroup.options = _fltrGroup.options.filter((item) => {
                if (currFltrOpts.includes(item.key)) {
                    return true;
                }
                return false;
            });
            if (_fltrGroup.options.length) {
                _fltrOpts.push(_fltrGroup);
            }
        }
        return _fltrOpts;
    }
    return mathcharFilterOptions;
};

export const ButtonsWithFilter = ({
    quill,
    filterOptions,
    filterOptionSelected,
    onSelectOption,
    isFillBox = false,
    sizeRow = 6,
}) => {
    const { t } = useTranslation();

    const fltrOpts = getMathcharFilterOptions(filterOptions);
    const fltrOptSelected = filterOptionSelected || window.mcFltrOptSelected || "full";

    const [selectedFilter, setselectedFilter] = useState(fltrOptSelected);
    const [btnListByFilter, setBtnListByFilter] = useState(null);

    const handleSelectFilter = (fltr) => {
        const { list } = getMathCharOptionData(fltr);
        setselectedFilter(fltr);
        setBtnListByFilter(list);
    };

    useEffect(() => {
        if (fltrOptSelected) {
            handleSelectFilter(fltrOptSelected);
        }
    }, []);

    return (
        <div
            className={clsx("mathchar-btn-list", {
                "mathchar-btns-w-fltr": sizeRow === 6,
                "mathchar-btns-w-fltr-8": sizeRow === 8,
            })}
        >
            <div className="bwf-section">
                <Select
                    mode={undefined}
                    className="app-select show-arrow has-rd mathchar-select"
                    dropdownClassName="mathchar-dropdown"
                    showSearch
                    allowClear
                    showArrow={false}
                    placeholder={"Ant Design - Select"}
                    onChange={handleSelectFilter}
                    defaultValue={fltrOptSelected}
                >
                    {fltrOpts.map((groupItem, groupIndex) => {
                        return (
                            <Select.OptGroup key={`group-${groupIndex}-class`} label={t(`editor.${groupItem.key}`)}>
                                {groupItem.options.length ? (
                                    groupItem.options.map((item, itemIndex) => {
                                        return (
                                            <Select.Option
                                                key={`group-${groupIndex}-item${itemIndex}`}
                                                value={item.key}
                                            >
                                                {t(`editor.${item.key}`)}
                                            </Select.Option>
                                        );
                                    })
                                ) : (
                                    <Select.Option key={`group-${groupIndex}-empty`} disabled>
                                        {t("shared.no_data")}
                                    </Select.Option>
                                )}
                            </Select.OptGroup>
                        );
                    })}
                </Select>
                {isFillBox && (
                    <button onClick={() => onSelectOption("\\embed{response}")} className="bwf-button__fillblanks">
                        {t("editor.blank")}
                    </button>
                )}
            </div>
            <div className="bwf-section">
                {selectedFilter === "full" ? (
                    <Buttons quill={quill} onClickOption={onSelectOption}></Buttons>
                ) : (
                    <Context.Provider value={{ quill }}>
                        {(btnListByFilter || []).map((row, rowIndex) => {
                            return (
                                <div key={rowIndex} className="bwf-button-row">
                                    {row.map((btn, btnIndex) => (
                                        <Button
                                            key={btnIndex}
                                            icon={btn.icon}
                                            iconText={btn.iconText}
                                            write={btn.text}
                                            keystrokes={btn.keys}
                                            onClick={onSelectOption}
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    </Context.Provider>
                )}
            </div>
        </div>
    );
};

export default function renderButtonsWithFilter(element, quill) {
    const root = ReactDOM.createRoot(element);
    root.render(<ButtonsWithFilter quill={quill} />);
}
