import { t } from "i18next";
import QuestionContentDetail from "../ContentDetail";
import "./Hotspot.scss";
import HotspotAnswer from "src/modules/containers/QuestionDetail/containers/DetailHotspot/components/HotspotAnswer";
import { useEffect, useState } from "react";
import { CorrectIcon, InCorrectIcon } from "src/utils/drawer";
import { Space } from "antd";

const Hotspot = (props) => {
    const {
        answered = [],
        question = "",
        width,
        height,
        src,
        isShowLabel,
        paths,
        correct_answer,
        is_history = false,
    } = props;
    const [isCorrectSubmited, setIsCorrectSubmited] = useState(false);

    const formatCorrectAnswer = () => {
        let result = [];
        if (Array.isArray(correct_answer) && correct_answer.length > 0) {
            if (typeof correct_answer[0] !== "string") {
                result = correct_answer
                    .map((item) => {
                        return (
                            item.result &&
                            (item.text && item.text.includes("key-") ? item.text.substring(4) : item.text)
                        );
                    })
                    .filter((item) => item);
            } else {
                result = correct_answer.map((item) => item && (item?.includes("key-") ? item.substring(4) : item));
            }
        }
        return result;
    };

    const formatStudentAnswer = () => {
        let result = [];
        if (Array.isArray(answered) && answered.length > 0) {
            result = answered.map((item) => item && (item?.includes("key-") ? item.substring(4) : item));
        }
        return result;
    };

    const checkisCorrectAnswered = (corrAnsw, answered) => {
        const answered2 = formatStudentAnswer();

        if (Array.isArray(corrAnsw) && Array.isArray(answered) && Array.isArray(answered2)) {
            if (typeof corrAnsw?.[0] === "object") {
                //BE tra ve [{text:"", result:true/false}]
                const newCrrAns = corrAnsw
                    ?.filter((item) => item?.result)
                    ?.map((item) => item?.text && (item.text?.includes("key-") ? item.text.substring(4) : item.text));
                if (newCrrAns.length === answered2.length) {
                    let res = true;
                    answered2.forEach((v) => {
                        if (!newCrrAns.includes(v)) {
                            res = false;
                        }
                    });
                    return res;
                }
            } else {
                //preview bai lam Fe dang truyen vao ["key gi do"]
                if (corrAnsw.length === answered2.length) {
                    let res = true;
                    answered2.forEach((v) => {
                        if (!corrAnsw.includes(v)) {
                            res = false;
                        }
                    });
                    return res;
                }
            }
        }
        return false;
    };

    useEffect(() => {
        const result = checkisCorrectAnswered(correct_answer, answered);
        if (result) {
            setIsCorrectSubmited(true);
        }
    }, [answered]);
    return !is_history ? (
        <div className="viewScoreStudent_hotspot">
            {isShowLabel && <div className="question-label">{t("question.question")}</div>}
            <QuestionContentDetail
                isReadonly={true}
                value={{
                    question: question,
                }}
            />
            {/* <div className="viewScoreStudent_highlightImage-editor">  */}
            <Space>
                <h3 style={{ marginBottom: 0 }}>{t("question.your_answer")}</h3>

                <div className="shade-answer-status">
                    {isCorrectSubmited ? (
                        <span className="correct">
                            (<CorrectIcon />
                            <span style={{ marginLeft: 4 }}>{t("question.correct")})</span>
                        </span>
                    ) : (
                        <span className="incorrect">
                            ( <InCorrectIcon />
                            <span style={{ marginLeft: 4 }}>{t("question.incorrect")})</span>
                        </span>
                    )}
                </div>
            </Space>
            <br />
            <br />

            {/* defaultCorrectAnswer là đáp án của hs hoăc đáp án đúng */}
            <HotspotAnswer
                {...{ defaultCorrectAnswer: formatStudentAnswer(), width, height, src, paths }}
                isReadonly={true}
                mode="result"
                isLoadZwibbler={true}
            />
            {/* </div> */}
            <br />
            <div className="test-correct-answer">
                <h3>{t("question.correct_answer")}</h3>
                <br />
                <HotspotAnswer
                    {...{ defaultCorrectAnswer: formatCorrectAnswer(), width, height, src, paths }}
                    isReadonly={true}
                    mode="result"
                    isLoadZwibbler={true}
                />
            </div>
        </div>
    ) : (
        <HotspotAnswer
            {...{ defaultCorrectAnswer: formatCorrectAnswer(), width, height, src, paths }}
            isReadonly={true}
            mode="result"
            isLoadZwibbler={true}
        />
    );
};

export default Hotspot;
