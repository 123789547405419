import { useEffect } from "react";
import { Spin } from "antd";
import { t } from "i18next";
import { useFetch, useValues } from "src/hooks";
import CourseList from "../../containers/TeacherCourses/components/CourseList";
import { fetchCourses } from "src/api/containers/course";
import { setCurrentPage } from "src/reducers/general";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguages } from "src/api/containers/language";

const Classes = () => {
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, is_active: 1, is_class: 1, relations: ["level", "courseUsers", "image"] },
        fetchCourses
    );
    const [, dataSourceUserLanguages, , fetchUserLanguages, refetchUserLanguages] = useFetch(
        { noPagination: true },
        fetchLanguages
    );

    const dispatch = useDispatch();

    const [values, setValues] = useValues({
        listPages: 0,
        listOfCourses: [],
        fetchStatus: "",
        is_class: 1,
        is_active: 1,
        is_myClass: true,
        slug: "",
        user_id: undefined,
        language_id: undefined,
    });
    const user = useSelector((state) => state.auth.user);

    const handleFilterCourses = ({ language_id, user_id, is_active }) => {
        setValues({
            language_id: language_id == 1 ? undefined : language_id,
            user_id: user_id == 1 ? undefined : user_id,
            is_active: is_active,
        });
    };

    const handleFilterLanguages = (value) => {
        if (value === "-1") {
            setValues({ ...values, language_id: undefined });
        } else {
            setValues({ ...values, language_id: value });
        }
    };
    const handleSearch = () => {
        callFilter();
    };

    const callFilter = () => {
        const filters = [];

        if (values.language_id) {
            filters.push(`language_id:${values.language_id}`);
        }
        if (values.is_active) {
            filters.push(`is_active:${values.is_active}`);
        }
        if (values.is_myClass || user.role === "teacher") {
            filters.push(`owner_id:${user?.id}`);
        }
        if (values.slug) {
            filters.push(`slug:${values.slug}`);
        }
        if (values.user_id) {
            filters.push(`user_id:${values.user_id}`);
        }
        fetch({ page: 1, filter: filters.join(";") || undefined });
    };
    useEffect(() => {
        callFilter();
    }, [values.is_active, values.language_id, values.is_myClass, values.user_id]);

    useEffect(() => {
        dispatch(setCurrentPage("class"));
        fetchUserLanguages({}, true);
    }, []);

    useEffect(() => {
        const extra = {};
        if (loading === true && values.fetchStatus === "") {
            extra.fetchStatus = "fetching";
        }
        if (loading === false && values.fetchStatus === "fetching") {
            extra.fetchStatus = "fetched";
        }

        if (dataSource.length) {
            if (pagination.current === 1) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfCourses: dataSource,
                    ...extra,
                });
            } else if (pagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfCourses: [...values.listOfCourses, ...dataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfCourses: [],
                ...extra,
            });
        }
    }, [loading, dataSource, pagination]);

    return (
        <div className="teacher-courses">
            <Spin className="teacher-courses-spinner" spinning={loading} tip={t("shared.loading")}>
                <CourseList
                    isLoading={loading}
                    courseList={values.listOfCourses}
                    languages={dataSourceUserLanguages}
                    pagination={pagination}
                    handleFilterLanguages={handleFilterLanguages}
                    handleFilterCourses={handleFilterCourses}
                    filterValues={{ language_id: values.language_id, user_id: values.user_id }}
                    handleFilter={setValues}
                    handleFetchCourseList={fetch}
                    handleRefetchCourseList={() => {
                        fetch({ page: 1 }, true);
                    }}
                    handleSearch={handleSearch}
                    mode="classes"
                    is_myClass={values.is_myClass}
                />
                {/* )} */}
            </Spin>
        </div>
    );
};

export default Classes;
