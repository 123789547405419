import { Modal } from "antd";
import React, { useEffect } from "react";
import { t } from "i18next";
import { WarningOutlined } from "@ant-design/icons";
import { useMediaQuery, useValues } from "src/hooks";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import "./ScreenSizeCheck.scss";

const ScreenSizeCheck = ({ className, onVisibleChange, ...rest }) => {
    const breakpointMobile = "(max-width: 1279.98px)";
    const currMedia = useMediaQuery([breakpointMobile]);

    const [values, setValues] = useValues({
        isVisible: false,
        message: "",
        description: "",
    });

    const handleVisibleChange = (visible) => {
        if (onVisibleChange instanceof Function) {
            onVisibleChange(visible);
        }
    };

    const handleCancel = () => {
        setValues({ isVisible: false });
        handleVisibleChange(false);
    };

    const handleDisplay = (message, description) => {
        setValues({ isVisible: true, message, description });
        handleVisibleChange(true);
    };

    const checkDevice = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        } else if (/iPad|Android|tablet/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (checkDevice()) {
            const message = t("exam_proctoring.device_not_available");
            const description = t("exam_proctoring.this_platform_is_only_available_on_pc");
            handleDisplay(message, description);
        } else if (currMedia.media === breakpointMobile) {
            const message = t("exam_proctoring.screen_size_check_invalid");
            const description = t("exam_proctoring.descr_screen_size_check_invalid");
            handleDisplay(message, description);
        } else {
            handleCancel();
        }
    }, [currMedia]);

    return (
        <Modal
            centered
            visible={values.isVisible}
            title={
                <div className="check-info-title">
                    <div className="title-icon">
                        <WarningOutlined />
                    </div>
                    <div className="title-content">
                        <div className="content-main">{values.message}</div>
                    </div>
                </div>
            }
            closeIcon={<SvgClose />}
            closable={false}
            footer={null}
            className={
                "app-modal flexible-height type-primary maxw-520 modal-screensizechk" +
                (className ? ` ${className}` : "")
            }
            wrapClassName="modal-screensizechk-wrap"
            // onOk={handleCancel}
            // onCancel={handleCancel}
            {...rest}
        >
            <div className="modal-wraper">{values.description}</div>
        </Modal>
    );
};

export default ScreenSizeCheck;
