import api from "..";

export const fetchQuestionType = () => {
    return api
        .get(`/question_type/`, {
            _noPagination: 1,
            _relations: `${["childs"]}`,
            _question_type_fields: `${[
                "name",
                "type",
                "status_point",
                "status_question",
                "parent_id",
                "icon_src",
                "gif_src",
                "description",
                "is_new",
                "color",
            ]}`,
            _orderBy: "created_at:asc",
        })
        .then(({ status, data, message }) => ({
            status,
            data,
            message,
        }))
        .catch(() => ({ status: false, data: [] }));
};
