import axios from "axios";
import configs from "src/configs";
import { deleteCookie } from "src/utils/helpers";
import t from "i18next";

const instance = axios.create();

class Api {
    defaults;
    interceptors;
    constructor() {
        this.defaults = instance.defaults;
        this.interceptors = instance.interceptors;
        instance.defaults.headers.common["X-Localization"] = localStorage.getItem("lang") || "vi";
    }
    get(url, params, headers) {
        return new Promise((resolve, reject) => {
            instance
                .get(url, { params, headers })
                .then((data) => resolve(data))
                .catch(reject);
        });
    }
    post(url, data, params, headers, config = {}) {
        return new Promise((resolve, reject) => {
            instance
                .post(url, data, { params, headers, ...config })
                .then((data) => resolve(data))
                .catch(reject);
        });
    }
    put(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            instance
                .put(url, data, { params, headers })
                .then((data) => resolve(data))
                .catch(reject);
        });
    }
    delete(url, params, headers) {
        return new Promise((resolve, reject) => {
            instance
                .delete(url, { params, headers })
                .then((data) => resolve(data))
                .catch(reject);
        });
    }
    deleteWithConditions(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            instance
                .delete(url, { params, headers, data })
                .then((data) => resolve(data))
                .catch(reject);
        });
    }
    patch(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            instance
                .patch(url, data, { params, headers })
                .then((data) => resolve(data))
                .catch(reject);
        });
    }
}

const api = new Api();
api.defaults.baseURL = configs.API_ENDPOINT;
api.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            deleteCookie("token");
            window && window.location.reload();
            return;
        }

        if (error.response) {
            const { status, data } = error.response;
            const message = data.message || t("shared.something_went_wrong");
            return Promise.reject({ status, message });
        }
        return Promise.reject({ status: 500, message: t("shared.something_went_wrong") });
    }
);

export default api;
