import React, { useMemo } from "react";
import Icon from "src/modules/components/Icon";
import "./ResultAnswer.scss";

function ResultAnswer({
    className,
    children,
    resultStatus /** @param {string} resultStatus. Values: "correct", "incorrect". */,
    letter,
}) {
    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (resultStatus) {
            r += ` is-${resultStatus}`;
        }
        return r;
    }, [className]);

    const renderResultStatus = () => {
        switch (resultStatus) {
            case "correct": {
                return (
                    <span className="result-status-icon">
                        <Icon name="icon-tick" fill="#129D29"></Icon>
                    </span>
                );
            }
            case "incorrect": {
                return (
                    <span className="result-status-icon">
                        <Icon name="icon-cross-thick" fill="#FF0000"></Icon>
                    </span>
                );
            }
            default:
                break;
        }
    };

    return (
        <div className={`resultanswer-wrapper${extraClassnames}`}>
            <div className="resultanswer-content">
                {letter ? <span className="answer-letter">{letter}</span> : null}
                <div className="answer-content">{children}</div>
            </div>
            {renderResultStatus()}
        </div>
    );
}

export default ResultAnswer;
