import { Col, Row, Space } from "antd";
import chineseToPinyin from "chinese-to-pinyin";
import clsx from "clsx";
import { ReactComponent as AudioIconPlay } from "src/assets/images/media/audio-speaker-high.svg";
import { ReactComponent as AudioIcon } from "src/assets/images/media/audio-speaker-medium.svg";
import "./ComputerAnswer.scss";

const ComputerAnswer = ({ sentence, onClick, active, isPlaying, actor, isShowPinyin }) => {
    const handleClick = () => {
        if (onClick instanceof Function) {
            onClick();
        }
    };
    return (
        <div className={clsx({ "sptt-conversation_computer-answer": true, active: active })}>
            {/* <span className="computer-answer_inner"> */}
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={4} className="label-col">
                    <span className="label">{actor}:</span>
                </Col>

                <Col xs={24} sm={10}>
                    <div className="computer-answer_wrapper">
                        <Space className="computer-answer_inner">
                            <span onClick={handleClick} className={clsx({ speaker: true, play: isPlaying })}>
                                <span>{isPlaying ? <AudioIconPlay /> : <AudioIcon />}</span>
                            </span>
                            <span>
                                {isShowPinyin && (
                                    <span className="pinyin-text">{chineseToPinyin(sentence, { keepRest: true })}</span>
                                )}
                                <div className="transcript">{sentence}</div>
                            </span>
                        </Space>
                    </div>
                </Col>
                <Col xs={24} sm={10}></Col>
            </Row>
            {/* </span> */}
        </div>
    );
};

export default ComputerAnswer;
