import React, { useContext, useEffect, useRef } from "react";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";
import { t } from "i18next";
import { Modal, notification } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useValues } from "src/hooks";
import DisplaysCheckTrigger from "src/modules/containers/ExamCheckIn/components/SystemCheck/MultipleDisplaysCheck/DisplaysCheckTrigger";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import "./MultipleDisplaysCheck.scss";

const MultipleDisplaysCheck = ({ className, onVisibleChange, ...rest }) => {
    const { displaysCheck, setDisplaysCheck } = useContext(ProctoringContext);
    const { screenDetails, screenCount: screenCountCurr } = displaysCheck;

    const screenDetailsRef = useRef(null);
    const screenDetailsScreenChangeRef = useRef(() => {});
    const screenCount = useRef(null);

    const [chkTrggr, setChkTrggr] = useValues({
        visible: false,
    });
    const [values, setValues] = useValues({
        isVisible: false,
        // Connection:
        conn_status: true,
        conn_message: "",
        conn_description: "",
    });

    const displaysStatus = {
        invalid_screen_count: t("exam_proctoring.displays_check_invalid"),
        "NotAllowedError: Permission dismissed.": t("exam_proctoring.displays_info.permission_dismissed"),
        "NotAllowedError: Permission denied.": t("exam_proctoring.displays_info.permission_denied"),
    };

    const displaysDescription = {
        invalid_screen_count: t("exam_proctoring.descr_displays_check_invalid"),
        "NotAllowedError: Permission dismissed.": t("exam_proctoring.displays_info.descr_permission_dismissed"),
        "NotAllowedError: Permission denied.": t("exam_proctoring.displays_info.descr_permission_denied"),
    };

    // Permission checking with 'change' event:
    const permissionStatusRef = useRef(null);
    const checkingChangeRef = useRef(() => {});

    // Refs to be used inside event handlers (to deal with closure):
    const valuesRef = useRef({});
    const handleCancelRef = useRef(() => {});
    const handleDisplayRef = useRef(() => {});
    const handleStartCheckingRef = useRef(() => {});

    valuesRef.current = values;

    const handleVisibleChange = (visible, why) => {
        if (onVisibleChange instanceof Function) {
            onVisibleChange(visible, why);
        }
    };

    const handleCancel = () => {
        setValues({
            isVisible: false,
            conn_status: true,
            conn_message: "",
            conn_description: "",
        });
        handleVisibleChange(false);
    };
    handleCancelRef.current = handleCancel;

    const handleDisplay = (why) => {
        setValues({
            isVisible: true,
            conn_status: false,
            conn_message: displaysStatus[why],
            conn_description: displaysDescription[why],
        });
        handleVisibleChange(true, why);
    };
    handleDisplayRef.current = handleDisplay;

    const handleCleanUp = () => {
        if (screenDetailsRef.current) {
            screenDetailsRef.current.removeEventListener("screenschange", screenDetailsScreenChangeRef.current);
            screenDetailsRef.current = null;
            screenDetailsScreenChangeRef.current = () => {};
        }
    };

    const handleStartChecking = async () => {
        if ("getScreenDetails" in window) {
            // The Window Management API is supported.

            handleCleanUp();
            handleStopCheckingChange();

            try {
                screenDetailsRef.current = await window.getScreenDetails();
                const screenDetails = screenDetailsRef.current;

                // Handle screens change.
                screenDetailsScreenChangeRef.current = (event) => {
                    if (screenDetails.screens.length !== screenCount.current) {
                        // Store data:
                        screenCount.current = screenDetails.screens.length;
                        // Recall checker:
                        handleStartCheckingRef.current();
                    }
                };
                screenDetails.addEventListener("screenschange", screenDetailsScreenChangeRef.current);

                handleStartCheckingChange();

                // Store data:
                screenCount.current = screenDetails.screens.length;

                // Update states:
                if (setDisplaysCheck instanceof Function) {
                    setDisplaysCheck({
                        screenDetails: screenDetailsRef.current,
                        screenCount: screenCount.current,
                    });
                }
                if (screenCount.current !== 1) {
                    handleDisplay("invalid_screen_count");
                } else {
                    handleCancel();
                }
            } catch (err) {
                const errMsg = err.toString();
                handleDisplay(errMsg);
            }
        } else {
            handleCancel();
            handleCleanUp();
            handleStopCheckingChange();
        }
    };
    handleStartCheckingRef.current = handleStartChecking;

    const handleStartCheckingChange = () => {
        if ("permissions" in navigator) {
            navigator.permissions
                .query({ name: "window-placement" })
                .then((permissionStatus) => {
                    permissionStatusRef.current = permissionStatus;
                    checkingChangeRef.current = () => {
                        switch (permissionStatus.state) {
                            case "granted": {
                                handleStartCheckingRef.current();
                                break;
                            }
                            case "denied": {
                                handleDisplayRef.current("NotAllowedError: Permission denied.");
                                break;
                            }
                            case "prompt": {
                                handleDisplayRef.current("NotAllowedError: Permission dismissed.");
                                break;
                            }
                            default:
                                break;
                        }
                    };

                    permissionStatus.addEventListener("change", checkingChangeRef.current);
                })
                .catch((err) => {
                    notification.error({
                        message: err.toString(),
                    });
                });
        }
    };

    const handleStopCheckingChange = () => {
        if (permissionStatusRef.current) {
            permissionStatusRef.current.removeEventListener("change", checkingChangeRef.current);
        }
    };

    const handleToggleDisplaysCheckTrigger = (value) => {
        const newVisible = value !== undefined ? value?.visible : !chkTrggr.visible;
        setChkTrggr({
            visible: newVisible,
        });
    };

    const handleOkDisplaysCheckTrigger = () => {
        handleToggleDisplaysCheckTrigger({ visible: false });
        handleStartChecking();
    };

    useEffect(() => {
        return () => {
            handleCleanUp();
            handleStopCheckingChange();
        };
    }, []);

    useEffect(() => {
        if (screenDetails) {
            screenDetailsRef.current = screenDetails;
            screenCount.current = screenCountCurr;

            // Handle screens change.
            screenDetailsScreenChangeRef.current = (event) => {
                if (screenDetails.screens.length !== screenCount.current) {
                    // Store data:
                    screenCount.current = screenDetails.screens.length;
                    // Recall checker:
                    handleStartCheckingRef.current();
                }
            };
            screenDetails.addEventListener("screenschange", screenDetailsScreenChangeRef.current);

            handleStartCheckingChange();
        } else {
            handleToggleDisplaysCheckTrigger({ visible: true });
        }
    }, [screenDetails, screenCountCurr]);

    return (
        <>
            <Modal
                centered
                visible={values.isVisible && !chkTrggr.visible}
                title={
                    <div className="check-info-title">
                        <div className="title-icon">
                            <WarningOutlined />
                        </div>
                        <div className="title-content">
                            <div className="content-main">{values.conn_message}</div>
                        </div>
                    </div>
                }
                closeIcon={<SvgClose />}
                closable={false}
                footer={null}
                className={
                    "app-modal flexible-height type-primary maxw-520 modal-displayschk" +
                    (className ? ` ${className}` : "")
                }
                wrapClassName="modal-displayschk-wrap"
                // onOk={handleCancel}
                // onCancel={handleCancel}
                {...rest}
            >
                <div className="modal-wraper">{values.conn_description}</div>
            </Modal>

            <DisplaysCheckTrigger visible={chkTrggr.visible} onOk={handleOkDisplaysCheckTrigger} />
        </>
    );
};

export default MultipleDisplaysCheck;
