import { Space } from "antd";
import clsx from "clsx";
import React, { useState } from "react";
import { ReactComponent as AudioIconPlay } from "src/assets/images/media/audio-speaker-high.svg";
import { ReactComponent as AudioIcon } from "src/assets/images/media/audio-speaker-medium.svg";

export function Speaker({ src, children, onClick, setValues, onSpeakerEnd, speak = () => {} }) {
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = React.createRef(); // create a reference to the audio element

    const handleClick = () => {
        if (onClick instanceof Function) {
            onClick();

            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const onEnd = () => {
        if (onSpeakerEnd instanceof Function) {
            onSpeakerEnd();
        }
        setIsPlaying(false);
    };

    console.log({ src });

    return (
        <div
            onClick={() => {
                handleClick();
            }}
        >
            <audio ref={audioRef} src={src} onEnded={onEnd} />
            <Space className="computer-answer_inner">
                <span className={clsx({ speaker: true, play: isPlaying })}>
                    <span>{isPlaying ? <AudioIconPlay /> : <AudioIcon />}</span>
                </span>
                {/* <p className="transcript">{sentence}</p> */}
            </Space>
        </div>
    );
}
