import { configureStore } from "@reduxjs/toolkit";
import generalReducer from "./reducers/general";
import authReducer from "./reducers/auth";
import clockReducer from "./reducers/clock";
import answerReducer from "./reducers/answer";
import testReducer from "./reducers/test";
import animationReducer from "./reducers/animation";

export const store = configureStore({
    reducer: {
        general: generalReducer,
        auth: authReducer,
        clock: clockReducer,
        answer: answerReducer,
        test: testReducer,
        animate: animationReducer,
    },
});
