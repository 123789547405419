import { Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useValues } from "src/hooks";
import "./Toolbars.scss";
import ColorPickerV2 from "src/modules/components/ColorPicker";

import { ReactComponent as TextImg } from "src/assets/images/drawing-tool/text.svg";
import { ReactComponent as EraserImg } from "src/assets/images/drawing-tool/eraser.svg";
import { ReactComponent as PolygonImg } from "src/assets/images/drawing-tool/polygon.svg";
import { ReactComponent as RectangleImg } from "src/assets/images/drawing-tool/rectangle.svg";
import { ReactComponent as MouseImg } from "src/assets/images/drawing-tool/mouse.svg";
import { ReactComponent as CircleImg } from "src/assets/images/drawing-tool/circle.svg";
import { ReactComponent as BrushImg } from "src/assets/images/drawing-tool/brush.svg";
import { ReactComponent as ToFront } from "src/assets/images/drawing-tool/move-to-front.svg";
import { ReactComponent as ToBack } from "src/assets/images/drawing-tool/send-to-back.svg";
import { ReactComponent as CurveIcon } from "src/assets/images/drawing-tool/curvee.svg";
import { ReactComponent as ArrowIcon } from "src/assets/images/drawing-tool/arrow.svg";
import { ReactComponent as TriangleIcon } from "src/assets/images/drawing-tool/triangle.svg";

import { DeleteOutlined, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const lineWidthOptions = () => {
    const arr = Array.from(Array(31).keys()); //genenrate [1,2,...30]
    return arr.map((item) => ({ label: item, value: item }));
};

// convert rgba in string to Object JS.
const toRGBObj = (rgb) => {
    let temp = [];
    if (typeof rgb == "string") {
        temp = rgb.slice(5, -1).split(",");
    }
    return {
        r: +temp?.[0],
        g: +temp?.[1],
        b: +temp?.[2],
        a: +temp?.[3],
    };
};

const toolName = {
    BRUSH: "brush",
    LINE: "line",
    RECTANGLE: "rectangle",
    CIRCLE: "circle",
    TRIANGLE: "triangle",
    CURVE: "curve",
    ARROW: "arrow",
    PICK: "pick",
    QUAD: "quad", // tứ giác
    TEXT: "text",
};

const ZwibblerToolbars = ({ ctx }) => {
    const defaultColor = {
        stroke: "rgba(236,155,59,1)",
        line: "rgba(236,155,59,1)",
    };

    const { t } = useTranslation();
    const [mode, setMode] = useState("normal"); // normal, deleting,
    const [values, setValues] = useValues({
        lineColor: defaultColor,
        shapeStyle: {
            lineWidth: 4,
            strokeStyle: defaultColor.stroke,
            fillStyle: "rgba(0,0,0,0)",
        },
        linesStyle: {
            lineWidth: 4,
            strokeStyle: defaultColor.stroke,
        },
        textStyle: {
            fontSize: 20,
            textFillStyle: defaultColor.stroke,
            textAlign: "left",
            font: "Arial",
        },
        currentTool: toolName.PICK,
    });

    const useTool = (type) => {
        if (ctx) {
            switch (type) {
                case toolName.BRUSH:
                    ctx.useBrushTool({ ...values.linesStyle });
                    break;
                case toolName.CIRCLE:
                    ctx.useCircleTool({ ...values.shapeStyle });
                    break;
                case toolName.LINE:
                    ctx.useLineTool({ ...values.shapeStyle });
                    break;
                case toolName.RECTANGLE:
                    ctx.useRectangleTool({ ...values.shapeStyle });
                    break;
                case toolName.TRIANGLE:
                    ctx.usePolygonTool(3, 0, 1.0, { ...values.shapeStyle });
                    break;
                case toolName.CURVE:
                    ctx.useCurveTool({ ...values.shapeStyle });
                    break;
                case toolName.ARROW:
                    ctx.useArrowTool({ ...values.linesStyle });
                    break;
                case toolName.TEXT:
                    ctx.useTextTool({ ...values.textStyle });
                    break;
                default:
                    ctx.usePickTool({ ...values.shapeStyle });
            }
        }
    };

    const renderHelper = () => {
        const currentTool = values.currentTool;
        switch (currentTool) {
            case toolName.BRUSH:
                return t("zwibbler_toolbar.brush_tool_helper");
            case toolName.LINE:
            case toolName.CURVE:
                return t("zwibbler_toolbar.line_tool_helper");
            case toolName.PICK:
                t("zwibbler_toolbar.pick_tool_helper");
            case toolName.RECTANGLE:
            case toolName.TRIANGLE:
            case toolName.CIRCLE:
            case toolName.ARROW:
                return t("zwibbler_toolbar.brush_tool_helper");
            default:
                return;
        }
    };

    const handleClear = () => {
        if (ctx) {
            ctx.newDocument();
        }
    };

    const handleSetLineWidth = (width) => {
        setValues({
            shapeStyle: {
                ...values.shapeStyle,
                lineWidth: width,
            },
            linesStyle: {
                ...values.linesStyle,
                lineWidth: width,
            },
            textStyle: {
                ...values.textStyle,
                lineWidth: width,
            },
        });
        if (ctx) {
            ctx.setToolProperty("lineWidth", width);
        }
    };

    let crrTool;
    if (ctx) {
        crrTool = ctx.getCurrentTool();
    }

    useEffect(() => {
        if (ctx) {
            setValues({ currentTool: ctx.getCurrentTool() });
        }
    }, [crrTool, ctx]);

    useEffect(() => {
        if (ctx) {
            // mỗi lần chạy vô đây là nó sẽ tạo ra 1 event listener "node-clicked" mới
            // => hạn chế thêm code khác vô đây
            ctx.on("node-clicked", (node) => {
                if (mode === "delete-items") {
                    ctx.deleteNode(node);
                }
            });
            ctx.setColour(defaultColor.stroke, false);
        }
    }, [ctx]);

    useEffect(() => {
        if (ctx) {
            ctx.setToolProperty("lineWidth", values.linesStyle.lineWidth);
            ctx.setToolProperty("strokeStyle", values.linesStyle.strokeStyle);
            ctx.setToolProperty("fillStyle", values.shapeStyle.fillStyle);
            ctx.setToolProperty("fontName", values.textStyle.font);
            ctx.setNodeProperty(ctx.getSelectedNodes(), "fontSize", values.textStyle.fontSize);

            if (values.currentTool === "text") {
                ctx.setToolProperty("fillStyle", values.textStyle.textFillStyle);
                ctx.setToolProperty("fontSize", values.textStyle.fontSize);
            }
        }
    }, [
        values.linesStyle.lineWidth,
        values.linesStyle.strokeStyle,
        values.shapeStyle.fillStyle,
        values.textStyle.font,
        values.textStyle.fontSize,
        values.textStyle.textFillStyle,
    ]);

    return (
        <div className="zwibbler-toolbars">
            <div className="zwibbler-toolbar-wrapper">
                <div className="zwibbler-toolbar-buttons">
                    <Space style={{ display: "flex", flexWrap: "wrap" }}>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.PICK });
                                useTool(toolName.PICK);
                            }}
                            className={values.currentTool === "pick" ? "active" : ""}
                        >
                            <MouseImg />
                        </button>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.BRUSH });
                                useTool(toolName.BRUSH);
                            }}
                            className={values.currentTool === "brush" ? "active" : ""}
                        >
                            <BrushImg />
                        </button>
                        <button
                            className={values.currentTool === "line" ? "active" : ""}
                            onClick={() => {
                                setValues({ currentTool: toolName.LINE });
                                useTool(toolName.LINE);
                            }}
                        >
                            <PolygonImg />
                        </button>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.CURVE });
                                useTool(toolName.CURVE);
                            }}
                            className={values.currentTool === "curve" ? "active" : ""}
                        >
                            <CurveIcon />
                        </button>
                        <button
                            onC
                            onClick={() => {
                                setValues({ currentTool: toolName.RECTANGLE });
                                useTool(toolName.RECTANGLE);
                            }}
                            className={values.currentTool === "rectangle" ? "active" : ""}
                        >
                            <RectangleImg />
                        </button>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.TRIANGLE });
                                useTool(toolName.TRIANGLE);
                            }}
                            className={values.currentTool === "triangle" ? "active" : ""}
                        >
                            <TriangleIcon />
                        </button>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.ARROW });
                                useTool(toolName.ARROW);
                            }}
                            className={values.currentTool === "arrow" ? "active" : ""}
                        >
                            <ArrowIcon />
                        </button>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.CIRCLE });
                                useTool(toolName.CIRCLE);
                            }}
                            className={values.currentTool === "circle" ? "active" : ""}
                        >
                            <CircleImg />
                        </button>
                        <button
                            onClick={() => {
                                setValues({ currentTool: toolName.TEXT });
                                useTool(toolName.TEXT);
                            }}
                            className={values.currentTool === "text" ? "active" : ""}
                        >
                            <TextImg />
                        </button>
                        {/* <input
                            name="strokeColor"
                            type="color"
                            className="form-control form-control-color"
                            id="strokeColorInput"
                            // value={canvasProps?.brushColor}
                            // title={t("shared.choose_color")}
                            defaultValue={defaultColor.line}
                            onChange={(e) => {
                                if (ctx) {
                                    ctx.setColour(e.target.value);
                                }
                                // handleChangeColor(e.target.value);
                            }}
                        /> */}
                        {/* <SketchPicker
                            onChange={(value) => {
                                if (ctx) {
                                    ctx.setColour(value);
                                }
                            }}
                        /> */}
                        {/* //line color or border-color */}
                        {/* <SimpleColorPicker
                            handleChangeColor={(value) => {
                                if (ctx) {
                                    ctx.setColour(value);
                                }
                            }}
                            color={values.lineColor}
                            onClick={() => alert(",,,")}
                        /> */}

                        {/* //stroke color */}
                        <ColorPickerV2
                            onChangeColor={(value) => {
                                // let rgba = `rgba(${value?.rgb?.r},${value?.rgb?.g},${value?.rgb?.b},${value?.rgb?.a})`;
                                // console.log(rgba);
                                if (ctx) {
                                    ctx.setColour(value.hex, false);
                                    ctx.setToolProperty("strokeStyle", value.hex);
                                    // setValues({
                                    //     lineColor: {
                                    //         ...values.lineColor,
                                    //         stroke: value.hex,
                                    //         line: value.hex,
                                    //     },
                                    //     linesStyle: {
                                    //         ...values.linesStyle,
                                    //         strokeStyle: value.hex,
                                    //     },
                                    //     textStyle: {
                                    //         ...values.textStyle,
                                    //         textFillStyle: value.hex,
                                    //     },
                                    //     shapeStyle: {
                                    //         ...values.shapeStyle,
                                    //         strokeStyle: value.hex,
                                    //     },
                                    // });
                                }
                            }}
                            defaultSelectedColor={{ rgb: toRGBObj(values.linesStyle.strokeStyle) }}
                            // currColor={() => toRGBObj(values.linesStyle.strokeStyle)}
                            width={200}
                        />

                        {/* <------ End Font style --------> */}
                        <div z-has="lineWidth" className="select-size">
                            <Select
                                style={{ minWidth: 48 }}
                                options={lineWidthOptions()}
                                showSearch
                                value={values.linesStyle.lineWidth}
                                onSelect={(value) => handleSetLineWidth(value)}
                            ></Select>
                        </div>
                    </Space>

                    <Space style={{ display: "flex", flexWrap: "wrap" }}>
                        <button onClick={() => ctx.undo()}>
                            <UndoOutlined />
                        </button>
                        <button onClick={() => ctx.redo()}>
                            <RedoOutlined />
                        </button>
                        <button
                            onClick={() =>
                                ctx.ctx.useBrushTool({
                                    lineWidth: 10,
                                    strokeStyle: "erase",
                                    layer: "my_eraser_layer",
                                })
                            }
                        >
                            <EraserImg />
                        </button>
                        <button onClick={handleClear}>Clear</button>
                    </Space>
                </div>

                <div className="zwibbler-toolbar-extension"></div>

                <div className="sub-tool">
                    <div className="sub-tool_tool">
                        {[
                            toolName.CIRCLE,
                            toolName.CURVE,
                            toolName.RECTANGLE,
                            toolName.TRIANGLE,
                            toolName.LINE,
                        ].includes(values.currentTool) && (
                            <div className="fill-color">
                                <h3>Options</h3>
                                <Space>
                                    <span>Fill</span>

                                    {/* Fill shape color */}
                                    <ColorPickerV2
                                        onChangeColor={(value) => {
                                            let rgba = `rgba(${value?.rgb?.r},${value?.rgb?.g},${value?.rgb?.b},${value?.rgb?.a})`;
                                            if (ctx) {
                                                ctx.setToolProperty("fillStyle", rgba);
                                            }
                                        }}
                                        currColor={() => toRGBObj(values.shapeStyle.fillStyle)}
                                        width={200}
                                        defaultSelectedColor={{ rgb: toRGBObj(values.shapeStyle.fillStyle) }}
                                    />
                                </Space>
                            </div>
                        )}
                        {values.currentTool === "pick" && (
                            <div z-has="AnyNode" className="pick-options">
                                <h3>Options</h3>
                                <Space>
                                    <button
                                        onClick={() => {
                                            ctx && ctx.deleteNodes();
                                            // if(mode !=="delete-items")
                                            // setMode();
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </button>
                                    <button
                                        onClick={() => {
                                            ctx && ctx.bringToFront();
                                        }}
                                    >
                                        <ToFront />
                                    </button>
                                    <button
                                        onClick={() => {
                                            ctx && ctx.sendToBack();
                                        }}
                                    >
                                        <ToBack />
                                    </button>
                                    <span>Fill</span>
                                    <ColorPickerV2
                                        onChangeColor={(value) => {
                                            let rgba = `rgba(${value?.rgb?.r},${value?.rgb?.g},${value?.rgb?.b},${value?.rgb?.a})`;
                                            if (ctx) {
                                                ctx.setToolProperty("fillStyle", rgba);
                                            }
                                        }}
                                        currColor={() => toRGBObj(values.shapeStyle.fillStyle)}
                                        width={200}
                                        defaultSelectedColor={{ rgb: toRGBObj(values.shapeStyle.fillStyle) }}
                                    />
                                </Space>
                            </div>
                        )}

                        {/* <------ Font style --------> */}

                        {values.currentTool == "text" && (
                            <>
                                <h3>Options:</h3>
                                <div className="toolbar-text-group">
                                    <Space>
                                        <div>
                                            <h4>Font size</h4>
                                            <Select
                                                style={{
                                                    width: 60,
                                                }}
                                                showSearch
                                                defaultValue="20"
                                                onChange={(value) => {
                                                    // if (ctx) {
                                                    //     ctx.setToolProperty("fontSize", value);
                                                    // }
                                                    setValues({
                                                        textStyle: {
                                                            ...values.textStyle,
                                                            fontSize: value,
                                                        },
                                                    });
                                                }}
                                                options={[
                                                    {
                                                        value: "8",
                                                        label: "8",
                                                    },
                                                    {
                                                        value: "10",
                                                        label: "10",
                                                    },
                                                    {
                                                        value: "12",
                                                        label: "12",
                                                    },
                                                    {
                                                        value: "20",
                                                        label: "20",
                                                    },
                                                    {
                                                        value: "24",
                                                        label: "24",
                                                    },
                                                    {
                                                        value: "50",
                                                        label: "50",
                                                    },
                                                    {
                                                        value: "60",
                                                        label: "60",
                                                    },
                                                    {
                                                        value: "70",
                                                        label: "70",
                                                    },
                                                    {
                                                        value: "80",
                                                        label: "80",
                                                    },
                                                    {
                                                        value: "90",
                                                        label: "90",
                                                    },
                                                    {
                                                        value: "100",
                                                        label: "100",
                                                    },
                                                ]}
                                            ></Select>
                                        </div>
                                        {/* <div z-has="textAlign">
                                        <h4>Text align</h4>
                                        <Select
                                            style={{
                                                width: 70,
                                            }}
                                            onChange={(value) => {
                                                if (ctx) {
                                                    ctx.setToolProperty("textAlign", value);
                                                }
                                            }}
                                            options={[
                                                {
                                                    value: "left",
                                                    label: "left",
                                                },
                                                {
                                                    value: "center",
                                                    label: "center",
                                                },
                                                {
                                                    value: "right",
                                                    label: "right",
                                                },
                                            ]}
                                        ></Select>
                                    </div> */}
                                        <div z-has="fontName">
                                            <h4>Font</h4>
                                            <Select
                                                style={{
                                                    minWidth: 130,
                                                }}
                                                showSearch
                                                defaultValue="Arial"
                                                onChange={(value) => {
                                                    // if (ctx) {
                                                    //     ctx.setToolProperty("fontName", value);
                                                    // }
                                                    setValues({
                                                        textStyle: {
                                                            ...values.textStyle,
                                                            font: value,
                                                        },
                                                    });
                                                }}
                                                options={[
                                                    {
                                                        value: "Alice",
                                                        label: "Alice",
                                                    },
                                                    {
                                                        value: "Arial",
                                                        label: "Arial",
                                                    },
                                                    {
                                                        value: "Times New Roman",
                                                        label: "Times New Roman",
                                                    },
                                                    {
                                                        value: "Pacifico",
                                                        label: "Pacifico",
                                                    },
                                                    {
                                                        value: "Bebas Neue",
                                                        label: "Bebas Neue",
                                                    },
                                                    {
                                                        value: "Goldman",
                                                        label: "Goldman",
                                                    },
                                                    {
                                                        value: "Roboto Condensed",
                                                        label: "Roboto Condensed",
                                                    },
                                                    {
                                                        value: "Spectral",
                                                        label: "Spectral",
                                                    },
                                                    {
                                                        value: "Yeon Sung",
                                                        label: "Yeon Sung",
                                                    },
                                                ]}
                                            ></Select>
                                        </div>
                                    </Space>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <-------Helper--------> */}
                    <div className="sub-tool_helper">
                        <h3>{t("zwibbler_toolbar.helper")}</h3>
                        <span>{renderHelper()}</span>
                    </div>
                    {/* <-------End Helper--------> */}
                </div>
            </div>
        </div>
    );
};

export default ZwibblerToolbars;
