import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { Button, Dropdown, Menu, message, notification, Popconfirm, Space, Spin } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import CourseTopic from "src/modules/components/CoursePanels/CourseTopic";
import ModalDetailUnit from "../../../components/ModalDetailUnit";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./TableUnitList.scss";
import { deleteUnit } from "src/api/containers/unit";
import { useSelector } from "react-redux";

function TableUnitList({
    courseId = "",
    unitLoading = false,
    unitColumns = [],
    unitDataSource = [],
    unitPagination = {},
    modalShow = { isShown: "", params: null },
    onChangeModalShow = () => {},
    unitFetch = () => {},
    unitRefetch = () => {},
    ...rest
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const [isLoading, setIsLoading] = useState("");
    const [modalConfirm, setModalConfirm] = useState({
        isConfirmModalVisible: false,
        params: null,
    });
    const [showModal, setShowModal] = useState({
        isShown: "",
        params: null,
    });
    const [values, setValues] = useState({
        listPages: 0,
        listOfUnits: [],
        status: "",
    });
    const user = useSelector((state) => state.auth.user);

    const goToUnitDetail = (unitId) => {
        if (!courseId || !unitId) {
            alert("Invalid unit!");
        }
        navigate(
            generate("course_detail_unit", {
                courseId: courseId,
                unitId: unitId,
            })
        );
    };

    const handleOk = (willRefetch = false) => {
        if (willRefetch === true) {
            unitRefetch();
        } else {
            unitFetch({ page: 1 }, true);
        }
        setShowModal({ isShown: "", params: null });
        onChangeModalShow({ isShown: "", params: null });
    };

    const handleCancel = () => {
        setShowModal({ isShown: "", params: null });
        onChangeModalShow({ isShown: "", params: null });
    };

    const handleDeleteUnit = (unitId) => {
        setIsLoading("deleting");
        deleteUnit(unitId).then((res) => {
            if (res.status === true) {
                unitFetch({ page: 1 }, true);
                notification.success({ message: t("message.delete_success") });
            } else {
                notification.error({ message: res.message || t("message.delete_error") });
            }
            setIsLoading("");
            setModalConfirm({ ...modalConfirm, isConfirmModalVisible: false, params: null });
        });
    };

    useEffect(() => {
        if (showModal.isShown !== modalShow.isShown) {
            setShowModal(modalShow);
        }
    }, [modalShow]);

    useEffect(() => {
        const extra = {};
        if (unitLoading === true && values.status === "") {
            extra.status = "fetching";
        }
        if (unitLoading === false && values.status === "fetching") {
            extra.status = "fetched";
        }

        if (unitDataSource.length) {
            if (unitPagination.current === 1) {
                setValues({
                    ...values,
                    listPages: unitPagination.current,
                    listOfUnits: unitDataSource,
                    ...extra,
                });
            } else if (unitPagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: unitPagination.current,
                    listOfUnits: [...values.listOfUnits, ...unitDataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfUnits: [],
                ...extra,
            });
        }
    }, [unitLoading, unitDataSource, unitPagination]);

    const renderMenu = (unitId) => {
        return (
            <Menu
                className="unit-list-item-dropdown"
                items={[
                    {
                        key: "unit-mn1",
                        label: (
                            <Space className="item-btn">
                                <Icon name="icon-modify" />
                                {t("action.update")}
                            </Space>
                        ),
                        disabled: showModal.isShown,
                        onClick: () => {
                            setShowModal({ isShown: "add-unit", params: { unitId: unitId } });
                        },
                    },
                    {
                        key: "unit-mn2",
                        label: (
                            // <Popconfirm
                            //     className="item-btn"
                            //     title={`${t("action.msg_confirm_delete")}?`}
                            //     onConfirm={() => {
                            //         handleDeleteUnit(unitId);
                            //     }}
                            //     okText={t("action.yes")}
                            //     cancelText={t("action.no")}
                            // >
                            //     <Space>
                            //         <Icon name="icon-delete" />
                            //         {t("action.delete")}
                            //     </Space>
                            // </Popconfirm>
                            <Space className="item-btn">
                                <Icon name="icon-delete" />
                                {t("action.delete")}
                            </Space>
                        ),
                        disabled: showModal.isShown,
                        onClick: () => {
                            setModalConfirm({ isConfirmModalVisible: true, params: { unitId: unitId } });
                        },
                    },
                ]}
            />
        );
    };

    return (
        <div className="unit-list-table">
            {/* <div className="unit-actions">
                <CustomButton
                    title={t("unit.add_unit")}
                    icon={null}
                    onClick={() => setShowModal({ isShown: "add-unit", params: null })}
                />
            </div> */}
            <Spin spinning={isLoading}>
                <div className="unit-list">
                    {values.listOfUnits.map((unitItem, unitIndex) => {
                        return (
                            <div className="unit-list-item" key={`unit${unitIndex}`}>
                                <CourseTopic
                                    topicInfo={unitItem}
                                    isActive={false}
                                    onClick={() => {
                                        goToUnitDetail(unitItem.id);
                                    }}
                                    actions={
                                        user?.role === "employee" || user?.role === "admin" ? (
                                            <Dropdown
                                                overlay={renderMenu(unitItem.id)}
                                                trigger={["click"]}
                                                placement="bottomRight"
                                            >
                                                <Button className="dropdown-btn">
                                                    <EllipsisOutlined />
                                                </Button>
                                            </Dropdown>
                                        ) : null
                                    }
                                />
                            </div>
                        );
                    })}
                    {values.status === "fetched" && values.listOfUnits.length === 0 ? (
                        <div className="unit-list-msg">{t("unit.unit_list_empty")}!</div>
                    ) : null}
                    {unitPagination.current < unitPagination.lastPage ? (
                        <div className="unit-list-item loadmore-btn">
                            <CustomButton
                                type="grey"
                                title={`${t("unit.load_more_units")}...`}
                                icon={null}
                                onClick={() => unitFetch({ page: unitPagination.current + 1 })}
                            />
                        </div>
                    ) : null}

                    <ModalDetailUnit
                        courseId={courseId}
                        unitId={showModal.params?.unitId || ""}
                        visible={showModal.isShown === "add-unit"}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    />

                    <PopConfirm
                        type="delete"
                        visible={modalConfirm.isConfirmModalVisible}
                        onOk={() => {
                            handleDeleteUnit(modalConfirm.params.unitId);
                        }}
                        onCancel={() =>
                            setModalConfirm({ ...modalConfirm, isConfirmModalVisible: false, params: null })
                        }
                    />
                </div>
            </Spin>
        </div>
    );
}

export default TableUnitList;
