import { Button, Tooltip } from "antd";

import { ReactComponent as SvgFeedback } from "src/assets/images/nav/feedback.svg";

import "./FeedbackButtonFloat.scss";

const FeedbackButtonFloat = ({ tooltip, icon, onClick = () => {} }) => {
    return (
        <span className="feedback_button--float">
            {tooltip ? (
                <Tooltip title={tooltip} color="#e23a33d1" placement="topLeft">
                    <Button shape="circle" icon={icon ? icon : <SvgFeedback />} onClick={onClick} />
                </Tooltip>
            ) : (
                <Button shape="circle" icon={icon ? icon : <SvgFeedback />} onClick={onClick} />
            )}
        </span>
    );
};

export default FeedbackButtonFloat;
