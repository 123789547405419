import { Spin, Tabs } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchSubscriptionPlans } from "src/api/containers/plan";
import { useValues } from "src/hooks";
import Header from "src/modules/components/Header";
import { setCurrentPage } from "src/reducers/general";

import Profile from "../Profile";
import MyPlan from "./MyPlan";
import PaymentHistory from "./PaymentHistory";
import "./Setting.scss";

function Setting() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({ tab: "1" });
    const sTab = searchParams.get("tab");

    const dispatch = useDispatch();

    const [values, setValues] = useValues({
        activeTab: "",
    });

    const [data, setData] = useValues({
        loading: false,
        planList: [],
    });

    const handleChangeTab = (tab) => {
        if (values.activeTab !== tab) {
            setValues({
                activeTab: tab,
            });
            setSearchParams({
                tab: tab,
            });
        }
    };

    useEffect(() => {
        if (sTab && values.activeTab !== sTab) {
            setValues({
                activeTab: sTab,
            });
        } else if (!sTab) {
            setSearchParams({ tab: "1" });
        }
    }, [sTab]);

    useEffect(() => {
        dispatch(setCurrentPage("setting"));
    }, []);

    useEffect(() => {
        if (["2", "3"].includes(values.activeTab) && !data.planList.length) {
            setData({ loading: true });
            fetchSubscriptionPlans({ noPagination: true })
                .then((res) => {
                    setData({ loading: false, planList: res.data || [] });
                })
                .catch((res) => {
                    setData({ loading: false });
                });
        }
    }, [values.activeTab]);

    const headerByTab = {
        1: t("nav.account_info"),
        2: t("setting.payment_history"),
        3: t("setting.my_plan"),
    };

    return (
        <div className="setting-wrapper">
            <div className="setting-header">
                <Header
                    backTitle={headerByTab[values.activeTab] || ""}
                    backFunc={() => {
                        navigate(-1);
                    }}
                />
            </div>

            <div className="account-info-tabs">
                <Tabs
                    defaultActiveKey="1"
                    centered
                    onChange={handleChangeTab}
                    activeKey={values.activeTab}
                    destroyInactiveTabPane={false}
                >
                    <Tabs.TabPane key="1" tab={t("nav.account_info")}>
                        <Profile showHeader={false} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="2" tab={t("setting.payment_history")}>
                        <Spin spinning={data.loading}>
                            <PaymentHistory planList={data.planList} />
                        </Spin>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="3" tab={t("setting.my_plan")}>
                        <Spin spinning={data.loading}>
                            <MyPlan planList={data.planList} />
                        </Spin>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}

export default Setting;
