import { t } from "i18next";
import { useMemo } from "react";
import { checkIsObject } from "src/utils/helpers";
import QuestionContentDetail from "../ContentDetail";
import ResultAnswer from "../components/ResultAnswer";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import "./FillBlanksInput.scss";

const FillBlanksInput = (props) => {
    const {
        correct_answer /** @param {string[] | {text: string, id: number}[]} */,
        answered,
        question = "",
        is_require_uppercase,
    } = props;

    const correctAnswerConverted = useMemo(() => {
        if (checkIsObject(correct_answer?.[0])) {
            return correct_answer.map((craswr) => craswr.text);
        } else {
            return correct_answer;
        }
    }, [correct_answer]);

    return (
        <div className="q-result q-fill-blanks-input">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{ question, answer: correctAnswerConverted, answer_submit: answered }}
                    is_require_uppercase={is_require_uppercase}
                />
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="qc_correct-answers">
                    {(correctAnswerConverted || []).map((item, itemIndex) => {
                        return (
                            <div key={`ans-${itemIndex}`} className="correct-answer-item">
                                <ResultAnswer letter={itemIndex + 1}>
                                    <HTMLDisplayer htmlString={item} />
                                </ResultAnswer>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FillBlanksInput;
