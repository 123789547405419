import { CameraOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Col, Form, Image, Input, Modal, Row, Skeleton, Space, Spin, Tooltip, Typography, notification } from "antd";
import * as faceapi from "face-api.js";
import { t } from "i18next";
import React from "react";
import { useEffect } from "react";

import { detectFaceSpoofing } from "src/api/containers/examCheckin";
import DefaultImg from "src/assets/images/default-thumbnail.jpg";
import configs from "src/configs";
import { useLoadFaceapiModels, useValues } from "src/hooks";
import { useAuth } from "src/modules/auth";
import Button from "src/modules/components/Button";
import ImageWidthSkeleton from "src/modules/components/ImageWithSkeleton";
import WebcamCapture from "src/modules/components/WebcamCapture";
import { checkIsModalsFaceApiLoaded, checkMultipleFaces } from "src/utils/helpers";
import { getFaceDetectorOptions } from "src/utils/helpers/faceapi";

import "./FaceVerification.scss";

const { FACE_MATCHER_DISTANCE_THRESHOLD } = configs.EXAM_PROCTORING.CHECK_FACES;
const { MODEL, MIN_CONFIDENCE, SCORE_THRES_HOLD, INPUT_SIZE } = configs.EXAM_PROCTORING.WEBCAM_CAPTURE;

const IMG_WIDTH = 375;
const IMG_HEIGTH = 500;

function FaceVerification({ onDone, currentStep, setStep, imgWebcamRef }) {
    // const user = useSelector((state) => state.auth?.user);
    const { user } = useAuth();

    const [values, setValues] = useValues({
        loading: false,
        loadingForAct: false,
        imgCapture: "",
        isDetectingFace: false,
        isModalWebcam: false,
        isCheckingFaces: false,
        countFacesDetected: -1,
        webcamFaceDescriptor: null,
        imgUploadFaceDescriptor: null,
        capturedFaceDescriptor: null,
    });
    const { modelsLoaded, reLoadModels } = useLoadFaceapiModels();

    async function getFaceDescriptor(image) {
        // const image = await faceapi.bufferToImage(imageBuffer);

        const faceDescriptor = await faceapi
            .detectSingleFace(image, getFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptor();

        return { status: true, faceDescriptor };
    }

    // so sánh 2 khuôn mặt
    async function handleCheckFaces() {
        try {
            if (!user?.avatar) {
                notification.error({ message: t("an_error_occurred") });
                return;
            }
            if (!values.imgCapture) {
                notification.error({ message: t("no_taken_photo_from_webcam") });
                return;
            }

            const notiErr = renderCheckFaceNotification(values.countFacesDetected);

            if (notiErr) {
                notification.error({ message: notiErr });
                return;
            }

            if (modelsLoaded) {
                const imageElement1 = document.getElementById("img1");
                const imageElement2 = document.getElementById("img2");

                setValues({ isCheckingFaces: true });

                //fix case trình duyệt bị đơ trước khi hiện loading
                setTimeout(async () => {
                    let uploadedFaceDescriptor = values.imgUploadFaceDescriptor;

                    if (!uploadedFaceDescriptor) {
                        const { status: img1Status, faceDescriptor } = await getFaceDescriptor(imageElement1);
                        uploadedFaceDescriptor = faceDescriptor;
                        setValues({ imgUploadFaceDescriptor: uploadedFaceDescriptor });
                    }
                    // const { status: img2Status, faceDescriptor: capturedFaceDescriptor } = await getFaceDescriptor(
                    //     imageElement2
                    // );

                    if (!values.capturedFaceDescriptor) {
                        notification.error({
                            message: `${t("faces_cannot_detected")}. ${t("please_take_another_photo")}`,
                        });
                        setValues({ isCheckingFaces: false });
                        return;
                    }

                    if (!uploadedFaceDescriptor || !values.capturedFaceDescriptor) {
                        notification.error({
                            message: `${t("faces_cannot_detected")}. ${t("please_take_another_photo")}`,
                        });
                        setValues({ isCheckingFaces: false });

                        return;
                    }

                    const faceMatcher = new faceapi.FaceMatcher(
                        values.capturedFaceDescriptor,
                        FACE_MATCHER_DISTANCE_THRESHOLD
                    );

                    const matchResult = faceMatcher.findBestMatch(uploadedFaceDescriptor.descriptor);

                    if (matchResult.label === "unknown") {
                        notification.warning({ message: t("verification_failed"), description: t("faces_not_match") });
                        // alert("No match found.");
                    } else {
                        setTimeout(() => {
                            notification.success({ message: t("verified_successfully") });
                            if (currentStep !== undefined) {
                                if (setStep instanceof Function) {
                                    setStep?.(1);
                                }
                            } else {
                                if (onDone instanceof Function) {
                                    onDone();
                                }
                            }
                        }, 500);
                        // alert(`Match found! Confidence: ${matchResult.distance}`);
                    }

                    setValues({ isCheckingFaces: false });
                }, 350);
            } else {
                notification.warning({ message: t("loading_resources") });
            }
        } catch (error) {
            notification.error({ message: t("an_error_occurred") });
            setValues({ isCheckingFaces: false });
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    // chụp và ccheck ảnh user chụp
    async function detectCapturedWebcamImage(urlBase64) {
        if (!urlBase64) return;
        setValues({ imgCapture: urlBase64, isDetectingFace: true, isModalWebcam: false });
        imgWebcamRef.current = urlBase64;

        if (!checkIsModalsFaceApiLoaded(faceapi)) {
            notification.info({ message: `${t("loading_resources")}. ${t("please_wait_a_moment")}` });
            return;
        }

        //detect multi-face
        const imgEle = createImgElement({ src: urlBase64, width: 400 });

        try {
            if (imgEle) {
                const result = await checkMultipleFaces(imgEle, faceapi);

                const notiErr = renderCheckFaceNotification(result);
                if (notiErr) {
                    notification.error({ message: notiErr });
                    setValues({ isDetectingFace: false, countFacesDetected: result });
                    return false;
                }

                /**
                 * Detect single face:
                 */
                const faceWebcamDescriptor = await faceapi
                    .detectSingleFace(
                        imgEle,
                        getFaceDetectorOptions({
                            model: MODEL,
                            minConfidence: MIN_CONFIDENCE,
                            scoreThreshold: SCORE_THRES_HOLD,
                            inputSize: INPUT_SIZE,
                        })
                    )
                    .withFaceLandmarks()
                    .withFaceDescriptor();

                if (!faceWebcamDescriptor) {
                    notification.error({ message: t("faces_cannot_detected") });
                    setValues({ countFacesDetected: 0, isDetectingFace: false });
                    return false;
                }

                setValues({ countFacesDetected: result, capturedFaceDescriptor: faceWebcamDescriptor });

                detectFaceSpoofing(urlBase64?.split(",")?.[1]).then(({ status, message, data }) => {
                    if (status) {
                        setValues({ detectFaceSpoofingData: data, isDetectingFace: false });

                        if (data?.result) {
                            notification.success({
                                message: t("valid_photo"),
                                description: `${t("please_choose")} "${t("face_verification")}"`,
                            });
                        } else {
                            notification.error({
                                message: t("your_photo_not_valid"),
                            });
                        }
                    } else {
                        notification.error({ message: message });
                        setValues({ isDetectingFace: false });
                    }
                });
            }
        } catch (error) {
            notification.error({ message: t("an_error_occurred") });
            setValues({ isDetectingFace: false });
        }
    }

    function renderCheckFaceNotification(result) {
        if (result > 1) {
            return `${t("many_faces_detected")}`;
        }
        if (result === 0) {
            return `${t("no_face_detected")}`;
        }
        if (result === false) {
            return t("an_error_occurred");
        }
        return "";
    }

    useEffect(() => {
        if (values.imgCapture) {
            setTimeout(() => {
                detectCapturedWebcamImage(values.imgCapture);
            }, 0);
        }
    }, [values.imgCapture]);

    return (
        <Spin spinning={values.isCheckingFaces} size="large" tip={t("image_processing_please_wait")}>
            <div className="face-verification">
                <div className="face-verification_content">
                    <div className="face-verification_images">
                        <Space direction="vertical">
                            {/* <div >Ảnh upload</div> */}
                            <Typography.Title level={3} style={{ textAlign: "center" }}>
                                {t("your_image")}
                            </Typography.Title>
                            <ImageWidthSkeleton
                                src={user?.avatar}
                                width={IMG_WIDTH}
                                height={IMG_HEIGTH}
                                imgId="img1"
                                crossOrigin="anonymous"
                                loading="lazy"
                                className={"image-item"}
                            />
                        </Space>
                        <Space direction="vertical">
                            <Typography.Title level={3} style={{ textAlign: "center" }}>
                                {t("image_form_webcam")}
                            </Typography.Title>

                            <div className="image-item">
                                <Spin spinning={values.isDetectingFace} tip={t("checking_image")}>
                                    <ImageWidthSkeleton
                                        src={values.imgCapture || DefaultImg}
                                        width={IMG_WIDTH}
                                        height={IMG_HEIGTH}
                                        imgId="img2"
                                        crossOrigin="anonymous"
                                        loading="lazy"
                                        className={"image-item"}
                                    />
                                </Spin>
                            </div>
                        </Space>
                    </div>
                    <div className="identity-verification_info">
                        <Space style={{ justifyContent: "center" }}>
                            <Tooltip color="#ccc" title={modelsLoaded ? "" : t("loading_resources")}>
                                <div>
                                    <Button
                                        title={t("face_verification")}
                                        isDisabled={
                                            !modelsLoaded ||
                                            values.isDetectingFace ||
                                            !values.imgCapture ||
                                            ![0, undefined].includes(currentStep) ||
                                            values.countFacesDetected != 1
                                        }
                                        isLoading={!modelsLoaded || values.isDetectingFace}
                                        onClick={handleCheckFaces}
                                        icon={<FileSearchOutlined />}
                                    />
                                </div>
                            </Tooltip>

                            <Button
                                title={t("anti_cheating.webcam_picture")}
                                isDisabled={
                                    !modelsLoaded || values.isDetectingFace || ![0, undefined].includes(currentStep)
                                }
                                // isLoading={!modelsLoaded || values.isDetectingFace}
                                onClick={() => setValues({ isModalWebcam: true })}
                                icon={<CameraOutlined />}
                            />
                        </Space>
                    </div>
                    {/* <Col span={10} className="flex-center">
                           
                        </Col> */}

                    <Modal
                        className="app-modal type-basic"
                        centered
                        visible={values.isModalWebcam}
                        title={"Chụp ảnh từ webcam"}
                        style={{ maxWidth: 600, maxHeight: 780 }}
                        bodyStyle={{
                            height: "calc(100% - 70px)",
                        }}
                        onCancel={() => {
                            setValues({ isModalWebcam: false });
                        }}
                        onOk={() => {
                            setValues({ isModalWebcam: false });
                        }}
                        footer={null}
                    >
                        <WebcamCapture
                            handleCapture={(b64) => {
                                setValues({ imgCapture: b64, isModalWebcam: false });
                            }}
                        />
                    </Modal>
                </div>
            </div>
        </Spin>
    );
}

function createImgElement({ width, height, src }) {
    var virtualImg = document.createElement("img");

    if (!src) return false;

    virtualImg.src = src;
    virtualImg.alt = "Virtual Image";
    virtualImg.width = width || 300;
    virtualImg.display = "block";
    virtualImg.crossorigin = true;

    return virtualImg;
}

export default FaceVerification;
