import { SecurityScanOutlined, SendOutlined, SettingOutlined, SolutionOutlined } from "@ant-design/icons";
import { Spin, Steps } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import api from "src/api";
import { useValues } from "src/hooks";
import { useAuth } from "src/modules/auth";
import { setCurrentPage } from "src/reducers/general";
import { pages } from "src/routes/pages";
import { getCookie, parse_jwt, setCookie } from "src/utils/helpers";

import CandidateInformation from "../CandidateInformation";
import IdentityVerification from "../IdentityVerification";
import StartTakingExam from "../StartTakingExam";
import SystemCheck from "../SystemCheck";
import "./ExamCheckInSteps.scss";

function ExamCheckInSteps() {
    const dispatch = useDispatch();

    const auth = useAuth();

    const [current, setCurrent] = useState(-1);

    const [values, setValues] = useValues({
        loading: false,
    });

    const handleChangeStep = (step) => {
        setCurrent(step);
    };

    const handleCheckInWithToken = (token) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + token;

        const user = parse_jwt(`${token}`);

        if (user?.avatar_src) {
            user.avatar = user.avatar_src;
        }
        auth.login(user, () => {
            setCookie("token", token);
        });
    };

    const handleChangeSystemCheck = (checked) => {
        if (checked === true) {
            handleChangeStep(1);
        }
    };

    const handleChangeCandidateInfo = (data) => {
        if (data.token) {
            handleCheckInWithToken(data.token);
            handleChangeStep(3);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token") || getCookie("token");
        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (!auth.user) {
            if (token) {
                setValues({ loading: true });

                let url = "/me";
                api.get(url).then((res) => {
                    setValues({ loading: false });

                    if (res?.status && res?.data?.user) {
                        auth.login(res.data.user);
                    } else {
                        auth.logout();

                        handleChangeStep(0);
                    }
                });
            } else {
                handleChangeStep(0);
            }
        }
    }, [auth.user]);

    useEffect(() => {
        if (auth.user) {
            handleChangeStep(3);
        }
    }, [auth.user]);

    useEffect(() => {
        if (current > -1) {
            const keys = {
                0: "exam_checkin_system_check",
                1: "exam_checkin_candidate_information",
                2: "exam_checkin_identity_verification",
                3: "exam_checkin_taking_exam",
            };
            const keyName = keys[current];
            dispatch(setCurrentPage(pages[keyName].name));
        } else {
            dispatch(setCurrentPage(pages.exam_checkin.name));
        }
    }, [current]);

    return (
        <div className="exam-checkin-steps">
            <div className="checkin-steps">
                <Steps current={current}>
                    <Steps.Step title={t("exam_checkin.system_check")} icon={<SettingOutlined />} />
                    <Steps.Step title={t("exam_checkin.candidate_information")} icon={<SolutionOutlined />} />
                    <Steps.Step title={t("exam_checkin.identity_verification")} icon={<SecurityScanOutlined />} />
                    <Steps.Step title={t("exam_checkin.taking_exam")} icon={<SendOutlined />} />
                </Steps>
            </div>
            <Spin spinning={values.loading}>
                <div className="checkin-content">
                    {current === 0 && <SystemCheck onChange={handleChangeSystemCheck} />}
                    {current === 1 && <CandidateInformation value={auth.user} onChange={handleChangeCandidateInfo} />}
                    {current === 2 && <IdentityVerification onOk={() => handleChangeStep(3)} />}
                    {current === 3 && <StartTakingExam />}
                </div>
            </Spin>
        </div>
    );
}

export default ExamCheckInSteps;
