import api from "..";

export const fetchTags = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.owner_id && filters.push(`owner_id:${fetchParams.owner_id}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _tag_fields: `${["name", "color", "owner_id"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/tag", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchTagsWithFiltering = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.owner_id && filters.push(`owner_id:${fetchParams.owner_id}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _tag_fields: `${["name", "color", "owner_id"]}`,
        _groupBy: "name",
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/tag/options", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findTag = (id) => {
    const params = {
        _tag_fields: `${["name", "color", "owner_id"]}`,
    };
    return api
        .get(`/tag/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const updateTag = (id, data) => {
    return api
        .put(`/tag/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const createTag = (data) => {
    return api
        .post("/tag", data)
        .then(({ status, id }) => ({ status, id }))
        .catch(() => ({ status: false }));
};

export const deleteTag = (id) => {
    return api
        .delete(`/tag/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
