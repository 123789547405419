import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import Header from "src/modules/components/Header";

import PaymentResult from "../../components/PaymentResult";

const PaymentResultPage = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({
        paymentResultProps: {},
    });
    const location = useLocation();

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();

    const clearURLSearchParams = () => {
        setUrlSearchParams({});
    };

    useEffect(() => {
        if (location.search) {
            const _urlSearchParams = new URLSearchParams(location.search);

            // SOLUTION 1:
            // const urlParams = {};
            // for (const [k, v] of _urlSearchParams.entries()) {
            //     if (v) {
            //         urlParams[k] = v;
            //     }
            // }
            // if (Object.keys(urlParams).length) {
            //     setData({
            //         paymentResultProps: urlParams,
            //     });
            // }

            // SOLUTION 2:
            const urlParams = _urlSearchParams.toString();
            if (urlParams) {
                setData({
                    paymentResultProps: urlParams,
                });
            }
        }
    }, [location]);

    return (
        <div className="payment-wrapper">
            <div className="payment-header">
                <Header backEnable={false} backTitle={t("payment.payment")} showHelp={false} actions={[]} />
            </div>

            <PaymentResult
                urlParams={data.paymentResultProps}
                showPaymentInfo={false}
                clearURLSearchParams={clearURLSearchParams}
            />
        </div>
    );
};

export default PaymentResultPage;
