import MyPhoneticTable from "./components/MyPhoneticTable";
import QuestionContentDetail from "../ContentDetail";
import "./PhoneticTable.scss";

const PhoneticTable = (props) => {
    const {
        correct_answer, // string[string[]]
        question,
    } = props;

    return (
        <div className="qc_sort">
            {question && (
                <div className="content-title">
                    <QuestionContentDetail
                        isReadonly={true}
                        value={{
                            question,
                        }}
                    />
                </div>
            )}
            <div className="content-phonetic-table">
                <MyPhoneticTable value={correct_answer} />
            </div>
        </div>
    );
};

export default PhoneticTable;
