import { useEffect } from "react";
import { Spin } from "antd";
import { t } from "i18next";
import { useFetch, useValues } from "src/hooks";
import CourseList from "../../components/CourseList";
import { fetchCourses } from "src/api/containers/course";
import { setCurrentPage } from "src/reducers/general";
import { fetchLanguages } from "src/api/containers/language";
import "./Courses.scss";
import { useDispatch, useSelector } from "react-redux";

const Courses = () => {
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, relations: ["level", "courseUsers", "image", "language", "creator"] },
        fetchCourses
    );

    const [, dataSourceUserLanguages, , fetchUserLanguages, refetchUserLanguages] = useFetch(
        { noPagination: true },
        fetchLanguages
    );

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const [values, setValues] = useValues({
        listPages: 0,
        listOfCourses: [],
        fetchStatus: "",
        language_id: "",
        user_id: "",
        is_show: -1,
    });

    const handleFilterCourses = ({ language_id, user_id, is_show }) => {
        setValues({
            language_id: language_id == 1 ? undefined : language_id || values.language_id,
            user_id: user_id == 1 ? undefined : user_id,
            is_show: is_show,
        });
    };

    const handleFilterLanguages = (value) => {
        if (value === "-1") {
            setValues({ language_id: undefined });
        } else {
            setValues({ language_id: value });
        }
    };

    useEffect(() => {
        const filters = ["is_class: 0"];
        // if (values.language_id) {
        //     filters.push(`language_id:${values.language_id}`);
        // }
        fetch({
            page: 1,
            filter: filters.join(";") || undefined,
            user_id: values.user_id,
            language_id: values.language_id,
            is_show: values.is_show,
        });
    }, [values.language_id, values.user_id, values.is_show]);

    useEffect(() => {
        fetchUserLanguages({}, true);
        dispatch(setCurrentPage("course"));
    }, []);

    useEffect(() => {
        const extra = {};
        if (loading === true && values.fetchStatus === "") {
            extra.fetchStatus = "fetching";
        }
        if (loading === false && values.fetchStatus === "fetching") {
            extra.fetchStatus = "fetched";
        }

        if (dataSource.length) {
            if (pagination.current === 1) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfCourses: dataSource,
                    ...extra,
                });
            } else if (pagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: pagination.current,
                    listOfCourses: [...values.listOfCourses, ...dataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfCourses: [],
                ...extra,
            });
        }
    }, [loading, dataSource, pagination]);

    return (
        <div className="teacher-courses">
            <Spin className="teacher-courses-spinner" spinning={loading} tip={t("shared.loading")}>
                {/* {values.fetchStatus === "fetched" && values.listOfCourses.length === 0 && user?.role !== "employee" ? (
                    <>
                        <div className="list-header">
                            <h1 className="app-txt-h2 header-title">{t("course.your_courses")}</h1>
                        </div>
                        <div className="list-msg">{t("course.courses_empty")}!</div>
                    </>
                ) : ( */}
                <CourseList
                    isLoading={loading}
                    courseList={values.listOfCourses}
                    languages={dataSourceUserLanguages}
                    pagination={pagination}
                    handleFilterLanguages={handleFilterLanguages}
                    handleFilterCourses={handleFilterCourses}
                    filterValues={{ language_id: values.language_id, user_id: values.user_id }}
                    handleFetchCourseList={fetch}
                    handleRefetchCourseList={() => {
                        fetch({ page: 1 }, true);
                    }}
                />
                {/* )} */}
            </Spin>
        </div>
    );
};

export default Courses;
