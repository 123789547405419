import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Input, Modal, notification, Space, Table, Tag, Tooltip } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFetch, useValues } from "src/hooks";
import { fetchTeachers } from "src/api/containers/user";
import { removeUserFromOrganization } from "src/api/containers/organization";
import FilterMenu from "../FilterMenu";
import ModalAddUser from "../ModalAddUser";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { default as CustomButton } from "src/modules/components/Button";
// Icons from assets:
import FilterIcon from "src/assets/images/filter-icon.svg";
import Pencil from "src/assets/images/pencil-white.svg";
// CSS:
import "./OrganizationUserList.scss";

function OrganizationUserList(props) {
    const user = useSelector((state) => state.auth.user);
    const isFetchUserList = user.organization?.is_organization_owner || false;

    const [loading, dataSource, pagination, fetch, refetch] = useFetch(
        { page: 1, slug: "", user_organization: isFetchUserList },
        fetchTeachers
    );

    const { t } = useTranslation();
    const [values, setValues] = useValues({
        isAddUserModalVisible: false,
        isConfirmDeleteModalVisible: false,
        selectedUser: null,
    });
    const [filterSettings, setFilterSettings] = useState({
        inputSearchUser: "",
        isFilterMenuVisible: false,
        filterParams: [],
    });

    const columns = [
        {
            title: t("organization.tablecol_user"),
            dataIndex: "name",
            key: "name",
            width: "auto",
            render: (text, record) => {
                return record?.name || "_";
            },
        },
        {
            title: t("profile.gender"),
            dataIndex: "gender",
            key: "gender",
            width: "auto",
            render: (text, record) => {
                switch (record?.gender) {
                    case null:
                    case 0:
                        return t(`profile.gender_male`);
                    case 1:
                        return t(`profile.gender_female`);
                    default:
                        return "_";
                }
            },
        },
        {
            title: t("profile.role"),
            dataIndex: "role",
            key: "role",
            width: "auto",
            render: (text, record) => (record?.role ? t(`profile.role_${record?.role}`) : "_"),
        },
        {
            title: t("profile.phone"),
            dataIndex: "phone",
            key: "phone",
            width: "auto",
            render: (text, record) => record?.phone || "_",
        },
        {
            title: t("profile.active"),
            dataIndex: "active",
            key: "active",
            width: "auto",
            render: (text, record) => {
                switch (record?.active) {
                    case false:
                        return t(`profile.active_false`);
                    case true:
                        return t(`profile.active_true`);
                    default:
                        return "_";
                }
            },
        },
        user.organization?.is_organization_owner === true
            ? {
                  dataIndex: "actions",
                  key: "actions",
                  render: (text, record) => (
                      <div className="organization-user-list-actions">
                          <Tooltip placement="top" title={t("shared.delete")}>
                              <span
                                  onClick={() => handleShowModalConfirmDelete(record)}
                                  className="action-btn organization-user-delbtn"
                              >
                                  <DeleteOutlined />
                              </span>
                          </Tooltip>
                      </div>
                  ),
              }
            : undefined,
    ];

    const handleShowModalConfirmDelete = (selectedUser) => {
        setValues({ isConfirmDeleteModalVisible: true, selectedUser: selectedUser });
    };

    const handleDeleteSelectedUser = () => {
        const selectedUser = values.selectedUser;
        removeUserFromOrganization(selectedUser.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isConfirmDeleteModalVisible: false, selectedUser: null });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleSearchUser = () => {
        if (filterSettings) {
            fetch({
                page: 1,
                slug: filterSettings.inputSearchUser,
                user_organization: isFetchUserList,
            });
        }
    };

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    useEffect(() => {
        fetch({ page: 1, slug: "", user_organization: isFetchUserList }, true);
    }, []);

    return (
        <div className="question-bank organization-user-list">
            <div className="organization-user-list__toolbar">
                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <span className="filter-input-search">
                                <Input
                                    className="app-input app-input-search-input"
                                    placeholder={t("organization.find_user")}
                                    onChange={(e) =>
                                        setFilterSettings({ ...filterSettings, inputSearchUser: e.target.value })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.code === "Enter") {
                                            handleSearchUser();
                                        }
                                    }}
                                />
                                <SearchOutlined className="app-input-search-icon" onClick={handleSearchUser} />
                            </span>
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {filterSettings.filterParams?.length > 0
                                        ? filterSettings.filterParams.map((fKey, i) => {
                                              return (
                                                  <Tag
                                                      className="app-tag"
                                                      key={`filter-key${i}`}
                                                      closable
                                                      onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                                  >
                                                      {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                      {`${fKey.labelValue || ""}`}
                                                  </Tag>
                                              );
                                          })
                                        : // <>{t("shared.option_filter")}</>
                                          null}
                                </Space>
                            </div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        handleFetchUserList={fetch}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={FilterIcon}
                                        title={t("question.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filter-toolbar-actions">
                        <CustomButton
                            type="primary"
                            icon={Pencil}
                            title={t("organization.add_user")}
                            onClick={() => setValues({ isAddUserModalVisible: true })}
                        ></CustomButton>
                    </div>
                </div>
            </div>

            <div className="organization-user-list__table">
                <Table
                    className="app-table"
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    rowKey="id"
                />
            </div>

            <ModalAddUser
                organization={user.organization[0] || {}}
                visible={values.isAddUserModalVisible}
                onOk={() => setValues({ isAddUserModalVisible: false })}
                onCancel={() => setValues({ isAddUserModalVisible: false })}
            />

            <PopConfirm
                type="delete"
                visible={values.isConfirmDeleteModalVisible}
                onOk={handleDeleteSelectedUser}
                onCancel={() => setValues({ isConfirmDeleteModalVisible: false })}
            />
        </div>
    );
}

export default OrganizationUserList;
