import {
    BarsOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Tooltip } from "antd";
import { data } from "jquery";
import React, { useEffect, useState } from "react";
import HelpCarousel from "../HelpCarousel";
import "./HelpMenuLayout.scss";
import { t } from "i18next";

const { Header, Content, Footer, Sider } = Layout;

const HelpMenuLayout = ({ data = [] }) => {
    const [slides, setSlides] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [title, setTitle] = useState("");

    const handleClickMenuItem = (e) => {
        if (e?.key) {
            const item = data.find((item) => item.id === e.key);
            const childGuide = item?.childGuides;
            setSlides(childGuide);
            setTitle(item.title);
        }
    };

    useEffect(() => {
        if (data?.length > 0) {
            const childGuide = data[0].childGuides;
            setSlides(childGuide);
            setTitle(data[0].title);
        }
    }, []);

    return (
        <Layout className="help-menu-layout">
            <Sider
                // breakpoint="lg"
                collapsedWidth="46"
                // reverseArrow={true}
                style={{
                    backgroundColor: "#f0f0f0",
                    position: "relative",
                    height: "calc(100vh - 100px)",
                    maxHeight: 1200,
                }}
                collapsible
                collapsed={collapsed}
                // onMouseEnter={() => {
                //     setCollapsed((pre) => !pre);
                // }}
            >
                {collapsed && (
                    <div className="icon_list">
                        <Tooltip title={t("guide.category")} placement="right" color="#07f">
                            <span onClick={() => setCollapsed(false)}>
                                <BarsOutlined />
                            </span>
                        </Tooltip>
                    </div>
                )}
                <div className="help-menu-title">
                    <span>{t("guide.category")}</span>
                </div>
                <Menu
                    style={{
                        backgroundColor: "#f0f0f0",
                    }}
                    mode="inline"
                    defaultSelectedKeys={data ? data[0]?.id : "1"}
                    items={data.map((item) => ({
                        key: item.id,
                        label: (
                            <Tooltip title={item.title} placement="right" color="#07f">
                                <div className="sider-menu-title">{item.title}</div>
                            </Tooltip>
                        ),
                    }))}
                    onClick={handleClickMenuItem}
                    expandIcon={<DoubleRightOutlined />}
                />
                <div className="help-menu-bottom" onClick={() => setCollapsed((pre) => !pre)}>
                    {collapsed ? (
                        <DoubleRightOutlined />
                    ) : (
                        <>
                            <DoubleLeftOutlined />
                            <span className="minimize">{t("guide.collapse_sidebar")}</span>
                        </>
                    )}
                </div>
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        backgroundColor: "#f5f5f5",
                    }}
                >
                    {title}
                </Header>
                <div style={{ position: "relative" }}>
                    <Content
                        style={{
                            paddingTop: 20,
                            height: "calc(100vh - 144px)",
                            overflowY: "auto",
                            maxHeight: "calc(1200px - 44px)",
                        }}
                    >
                        <HelpCarousel data={slides} />
                    </Content>
                </div>
            </Layout>
        </Layout>
    );
};

export default HelpMenuLayout;
