import React, { useEffect, useRef } from "react";
import { t } from "i18next";
import { Modal, notification } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useValues } from "src/hooks";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import "./MicroConnCheck.scss";

const MicroConnCheck = ({ className, onVisibleChange, ...rest }) => {
    const [values, setValues] = useValues({
        isVisible: false,
        // Connection:
        conn_status: true,
        conn_message: "",
        conn_description: "",
    });

    const microStatus = {
        "NotAllowedError: Permission dismissed": t("exam_proctoring.microphone_info.permission_dismissed"),
        "NotAllowedError: Permission denied": t("exam_proctoring.microphone_info.permission_denied"),
    };

    const microDescription = {
        "NotAllowedError: Permission dismissed": t("exam_proctoring.microphone_info.descr_permission_dismissed"),
        "NotAllowedError: Permission denied": t("exam_proctoring.microphone_info.descr_permission_denied"),
    };

    // Permission checking with 'change' event:
    const permissionStatusRef = useRef(null);
    const checkingChangeRef = useRef(() => {});

    // Refs to be used inside event handlers (to deal with closure):
    const valuesRef = useRef({});
    const handleCancelRef = useRef(() => {});
    const handleDisplayRef = useRef(() => {});

    valuesRef.current = values;

    const handleVisibleChange = (visible, why) => {
        if (onVisibleChange instanceof Function) {
            onVisibleChange(visible, why);
        }
    };

    const handleCancel = () => {
        setValues({
            isVisible: false,
            conn_status: true,
            conn_message: "",
            conn_description: "",
        });
        handleVisibleChange(false);
    };
    handleCancelRef.current = handleCancel;

    const handleDisplay = (why) => {
        setValues({
            isVisible: true,
            conn_status: false,
            conn_message: microStatus[why],
            conn_description: microDescription[why],
        });
        handleVisibleChange(true, why);
    };
    handleDisplayRef.current = handleDisplay;

    const handleStartCheckingChange = () => {
        if ("permissions" in navigator) {
            navigator.permissions
                .query({ name: "microphone" })
                .then((permissionStatus) => {
                    permissionStatusRef.current = permissionStatus;
                    checkingChangeRef.current = () => {
                        switch (permissionStatus.state) {
                            case "granted": {
                                handleCancelRef.current();
                                break;
                            }
                            case "denied": {
                                handleDisplayRef.current("NotAllowedError: Permission denied");
                                break;
                            }
                            case "prompt": {
                                handleDisplayRef.current("NotAllowedError: Permission dismissed");
                                break;
                            }
                            default:
                                break;
                        }
                    };

                    permissionStatus.addEventListener("change", checkingChangeRef.current);
                })
                .catch((err) => {
                    notification.error({
                        message: err.toString(),
                    });
                });
        }
    };

    const handleStopCheckingChange = () => {
        if (permissionStatusRef.current) {
            permissionStatusRef.current.removeEventListener("change", checkingChangeRef.current);
        }
    };

    useEffect(() => {
        handleStartCheckingChange();

        return () => {
            handleStopCheckingChange();
        };
    }, []);

    return (
        <>
            <Modal
                centered
                visible={values.isVisible}
                title={
                    <div className="check-info-title">
                        <div className="title-icon">
                            <WarningOutlined />
                        </div>
                        <div className="title-content">
                            <div className="content-main">{values.conn_message}</div>
                        </div>
                    </div>
                }
                closeIcon={<SvgClose />}
                closable={false}
                footer={null}
                className={
                    "app-modal flexible-height type-primary maxw-520 modal-microchk" +
                    (className ? ` ${className}` : "")
                }
                wrapClassName="modal-microchk-wrap"
                // onOk={handleCancel}
                // onCancel={handleCancel}
                {...rest}
            >
                <div className="modal-wraper">{values.conn_description}</div>
            </Modal>
        </>
    );
};

export default MicroConnCheck;
