import React from "react";

function IconNavPrev(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="20" height="33" viewBox="0 0 20 33" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3354 32.2857L19.4287 29.1741L6.7581 16.4286L19.4287 3.68298L16.3354 0.571419L0.571568 16.4286L16.3354 32.2857Z"
            />
        </svg>
    );
}

export default IconNavPrev;
