import React from "react";
import "./ActionButtonStyles.scss";

const ActionButton = (props) => {
    const { className = "", children, isDisabled, onClick = () => {} } = props;

    return (
        <button
            onClick={!isDisabled ? onClick : () => {}}
            className={"action-button" + ` ${className}` + ` ${isDisabled ? "disabled" : ""}`}
        >
            {children}
        </button>
    );
};

export default ActionButton;
