import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import "./ImageLabel.scss";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";

function ImageLabel({
    style,
    localId,
    localOrdinalNumber,
    className,
    type, // Values: "text", "dropdown", "drag&drop".
    isFocused,
    content,
    onClick,
    onClose,
    onChangeConfigs,
    onChangeDragState,
    onFocusContent,
    onBlurContent,
    rules,
    ...rest
}) {
    const { t } = useTranslation();
    const lblRef = useRef(null);
    const lblPos = useRef({ lbl_clientX: 0, lbl_clientY: 0 });
    const timeoutChangeConfigs = useRef(false);
    const [lblContent, setLblContent] = useState();
    const [dragState, setDragState] = useState(false); // Values: "dragging", false.

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (isFocused) {
            r += " is-focused";
        }
        if (dragState) {
            r += ` ${dragState}`;
        }
        if (lblContent) {
            r += " has-val";
        }
        return r;
    }, [className, isFocused, lblContent, dragState]);

    const specialClassname = useMemo(() => {
        return (type || "").replace("&", "-");
    }, [type]);

    const preventEventBubbling = (e) => {
        e.stopPropagation();
    };

    const changeConfigs = (_left, _top, _content) => {
        if (onChangeConfigs instanceof Function) {
            onChangeConfigs({
                left: _left,
                top: _top,
                content: _content,
            });
        }
    };

    const doDrag = (movementX = 0, movementY = 0) => {
        // - STEP 1: Get current position:
        const elemTop = parseFloat(lblRef.current.style.top.replace("px", ""));
        const elemLeft = parseFloat(lblRef.current.style.left.replace("px", ""));
        // - STEP 2: Update position:
        const newX = elemLeft + movementX;
        const newY = elemTop + movementY;
        if (rules) {
            if (newX >= rules.xMin && newY >= rules.yMin && newX <= rules.xMax && newY <= rules.yMax) {
                lblRef.current.style.left = `${newX}px`;
                lblRef.current.style.top = `${newY}px`;
            }
        } else {
            lblRef.current.style.left = `${newX}px`;
            lblRef.current.style.top = `${newY}px`;
        }
    };

    const handleClick = (e) => {
        if (onClick instanceof Function) {
            onClick(e);
        }
    };

    const handleClose = (e) => {
        e.stopPropagation();
        if (onClose instanceof Function) {
            onClose(e);
        }
    };

    const handleChangeDragState = (dragState) => {
        setDragState(dragState);
        if (onChangeDragState instanceof Function) {
            onChangeDragState(dragState);
        }
    };

    const handleStartDrag = (e) => {
        switch (e?.type) {
            case "mousedown":
                break;
            case "touchstart":
                lblPos.current.lbl_clientX = e.touches[0].clientX;
                lblPos.current.lbl_clientY = e.touches[0].clientY;
                break;
            default:
                break;
        }
        // Trigger onChange prop:
        handleChangeDragState("dragging");
    };

    const handleDrag = (e) => {
        switch (e?.type) {
            case "mousemove": {
                // Change position:
                doDrag(e.movementX, e.movementY);
                break;
            }
            case "touchmove":
                // Calculate movementX, movementY:
                const touchCurr = e.touches[0];
                const touchPrev = lblPos.current;
                const movementX = touchCurr.clientX - touchPrev.lbl_clientX;
                const movementY = touchCurr.clientY - touchPrev.lbl_clientY;
                // Store new coordinate:
                lblPos.current.lbl_clientX = touchCurr.clientX;
                lblPos.current.lbl_clientY = touchCurr.clientY;
                // Change position:
                doDrag(movementX, movementY);
                break;
            default:
                break;
        }
    };

    const handleChangeLblContent = (e) => {
        setLblContent(e.target.value);
    };

    const handleFocusLblContent = () => {
        if (onFocusContent instanceof Function) {
            onFocusContent(localId);
        }
    };

    const handleBlurLblContent = () => {
        if (onBlurContent instanceof Function) {
            onBlurContent(localId);
        }
    };

    useEffect(() => {
        if (dragState === "dragging") {
            const doDrag = (_e) => {
                handleDrag(_e);
            };
            const stopDrag = () => {
                window.removeEventListener("mousemove", doDrag);
                window.removeEventListener("mouseup", stopDrag);
                window.removeEventListener("touchmove", doDrag); // Touch devices.
                window.removeEventListener("touchend", stopDrag); // Touch devices.
                // Trigger onChange prop:
                handleChangeDragState(false);
                changeConfigs(
                    parseFloat(lblRef.current.style.left.replace("px", "")),
                    parseFloat(lblRef.current.style.top.replace("px", "")),
                    lblContent
                );
            };
            window.addEventListener("mousemove", doDrag);
            window.addEventListener("mouseup", stopDrag);
            window.addEventListener("touchmove", doDrag); // Touch devices.
            window.addEventListener("touchend", stopDrag); // Touch devices.
        }
    }, [dragState]);

    useEffect(() => {
        setLblContent(content);
    }, [content]);

    useEffect(() => {
        if (lblContent && lblContent !== content) {
            clearTimeout(timeoutChangeConfigs.current);
            timeoutChangeConfigs.current = setTimeout(() => {
                changeConfigs(
                    parseFloat(lblRef.current.style.left.replace("px", "")),
                    parseFloat(lblRef.current.style.top.replace("px", "")),
                    lblContent
                );
            }, 200);
        }
    }, [lblContent]);

    const renderLabelContent = () => {
        switch (type) {
            case "text":
                return (
                    <span className="image-label-content">
                        <Input
                            className="app-input image-label-input"
                            placeholder={t("question.answer")}
                            value={lblContent}
                            onChange={handleChangeLblContent}
                            onFocus={handleFocusLblContent}
                            onBlur={handleBlurLblContent}
                        />
                    </span>
                );
            case "dropdown":
                return <span className="image-label-content">{lblContent}</span>;
            case "drag&drop":
                return <span className="image-label-content">{lblContent}</span>;
            default:
                return null;
        }
    };

    return (
        <span
            ref={lblRef}
            id={localId}
            className={`image-label draggable${extraClassnames} ${specialClassname}`}
            style={style}
            onClick={handleClick}
            onMouseDown={handleStartDrag}
            onTouchStart={handleStartDrag}
            {...rest}
        >
            <span className="image-label-content-wrapper">
                <span className="image-label-pin">{/* Empty! */}</span>
                <span className="image-label-close" onClick={handleClose} onMouseDown={preventEventBubbling}>
                    <IconClose />
                </span>
                {localOrdinalNumber !== undefined ? (
                    <span className="image-label-content-withnum">
                        <span className="image-label-num">{localOrdinalNumber}</span>
                        {renderLabelContent()}
                    </span>
                ) : (
                    renderLabelContent()
                )}
            </span>
        </span>
    );
}

export default ImageLabel;
