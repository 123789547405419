import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { Modal, notification, Spin } from "antd";
import i18n from "src/i18n";
import { I18nextProvider, useTranslation } from "react-i18next";
import FileManager from "src/modules/components/FileManager";
import {
    addFiles,
    createFolder,
    deleteFiles,
    listFoldersAndFiles,
    moveFilesToFolder,
    renameFile,
} from "src/api/containers/storage";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import "./EditorFileManager.scss";

const EDITOR_ROOT_FM = "editor-root-fm";
const EDITOR_FM_CONTAINER = "editor-fm-container";

export const EditorFileManager = ({ opened = false, displayType, selectFile }) => {
    const { t } = useTranslation();
    const fmController = FileManager.useController();

    const lang = localStorage.getItem("lang");

    const [isOpen, setIsOpen] = useState(false);
    const [fmSettings, setFmSettings] = useState({
        isLoading: "none",
    });
    const [modalSettings, setModalSettings] = useState({
        isVisible: "",
        params: {},
    });

    const authToken = useMemo(() => {
        const getAuthToken = () => {
            const cookieArr = document.cookie.split(";");
            for (var i = 0; i < cookieArr.length; i++) {
                var cookiePair = cookieArr[i].split("=");
                if (cookiePair[0].trim() === "token") {
                    return cookiePair[1];
                }
            }
        };
        return getAuthToken();
    }, []);

    // Child modal:
    const handleCloseModal = () => {
        setModalSettings({ isVisible: "", params: {} });
    };

    // File manager container:
    const handleToggleOpenClose = (isOpened) => {
        if (isOpened === true) {
            setIsOpen(true);
            // setTimeout(() => {
            //     fmController.call("open");
            // }, 100);
        } else {
            setIsOpen(false);
            // setTimeout(() => {
            //     fmController.call("close");
            // }, 100);
        }
    };

    // File manager:
    const handleSelectFile = (url, type) => {
        if (selectFile instanceof Function) {
            // Close File manager and Media visible type modal:
            setTimeout(() => {
                handleCloseModal();
            }, 0);
            setTimeout(() => {
                handleToggleOpenClose(false);
            }, 350);

            // Select file:
            setTimeout(() => {
                selectFile(url, type);
            }, 700);
        }
    };

    const handleAddFolder = (folderName, parentInfo) => {
        if (!folderName) {
            folderName = t("my_storage.new_folder");
        }
        const folderData = {
            name: folderName,
            parent_id: parentInfo.id,
        };
        return new Promise((resolve, reject) => {
            createFolder(folderData, { token: authToken }).then((res) => {
                if (res.status) {
                    resolve(res.data);
                } else {
                    // reject(res);
                }
            });
        });
    };

    const handleGetListOfFolders = (parentFolderId) => {
        return new Promise((resolve, reject) => {
            listFoldersAndFiles(
                parentFolderId,
                {
                    noPagination: true,
                    type: "folder",
                },
                {
                    token: authToken,
                }
            ).then((res) => {
                if (res.status && res.data instanceof Array) {
                    // Check if there is any folder inside the selected folder:
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].childs?.length === 0) {
                            res.data[i].state = "empty";
                        } else {
                            let countFolder = 0;
                            for (let j = 0; j < res.data[i].childs.length; j++) {
                                if (res.data[i].childs[j].type === "folder") {
                                    res.data[i].state = "not-empty";
                                    countFolder++;
                                    break;
                                }
                            }
                            if (countFolder === 0) {
                                res.data[i].state = "empty";
                            }
                        }
                    }
                    resolve(res.data);
                }
            });
        });
    };

    const handleAddFiles = (fileList, parentInfo) => {
        /**
         * Create FormData instance, and set the Content-Type header to multipart/form-data.
         * To get the formData values, use:
         * console.log(formData.getAll("files"));
         */
        const formData = new FormData();
        if (fileList.length > 0) {
            [...fileList].forEach((fileItem, fileIndex) => {
                formData.append(`file${fileIndex}`, fileItem, fileItem.name);
            });
        }
        if (parentInfo.id) {
            formData.append("parent_id", parentInfo.id);
        }
        return new Promise((resolve, reject) => {
            setFmSettings({ ...fmSettings, isLoading: "uploading" });
            addFiles(formData, { token: authToken }).then((res) => {
                if (res.status) {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    resolve(res.data);
                } else {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    // reject(res);
                }
            });
        });
    };

    const handleRemoveFiles = (fileList) => {
        return new Promise((resolve, reject) => {
            setFmSettings({ ...fmSettings, isLoading: "removing" });
            deleteFiles(
                fileList.map((fileItem) => fileItem.id),
                { token: authToken }
            ).then((res) => {
                if (res.status) {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    resolve(res.data);
                } else {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    // reject(res);
                }
            });
        });
    };

    const handleDoubleClickFile = (selectedFile) => {
        if (selectedFile.src) {
            const validatedFile = validateFileBeforeUpload(selectedFile.src);
            if (validatedFile.status === true) {
                if (validatedFile.type === "audio") {
                    setModalSettings({ isVisible: "modal_select_audio_visible_type", params: { file: selectedFile } });
                } else if (validatedFile.type === "image") {
                    handleSelectFile(selectedFile.src, "image");
                } else if (validatedFile.type === "video") {
                    handleSelectFile(selectedFile.src, "video");
                }
            } else {
                const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                notification.error({
                    message: (
                        <>
                            <div>{`
                                    ${t("message.err_file_extension_not_valid")}.
                                    ${t("message.please_try_another_file_extension")}!
                                `}</div>
                            <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                        </>
                    ),
                });
            }
        }
    };

    const handleGetChildren = (parentFolderId) => {
        return new Promise((resolve, reject) => {
            listFoldersAndFiles(
                parentFolderId,
                {
                    noPagination: true,
                },
                {
                    token: authToken,
                }
            ).then((res) => {
                if (res.status && res.data instanceof Array) {
                    // Check if the folder contains anything:
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].type === "folder") {
                            if (res.data[i].childs?.length === 0) {
                                res.data[i].state = "empty";
                            } else {
                                res.data[i].state = "not-empty";
                            }
                        }
                    }
                    resolve(res.data);
                }
            });
        });
    };

    const handleRenameFile = (fileId, fileData) => {
        return new Promise((resolve, reject) => {
            renameFile(fileId, fileData, { token: authToken }).then((res) => {
                if (res.status) {
                    resolve(res.data);
                } else {
                    reject(res);
                }
            });
        });
    };

    const handleMoveFilesToFolder = (selectedFiles, selectedFolder) => {
        return new Promise((resolve, reject) => {
            const dataToBeSubmitted = {
                child_ids: (selectedFiles || []).map((item) => item.id || item._id),
                parent_id: selectedFolder.id || selectedFolder._id,
            };
            moveFilesToFolder(dataToBeSubmitted, { token: authToken }).then((res) => {
                if (res.status) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    };

    // const handleRestricted = (action, callback) => {
    //     if (action && callback instanceof Function) {
    //         if (checkDemoAccountAction(accountType, "file", action)) {
    //             return callback();
    //         }
    //     }
    //     notification.error({ message: t("shared.you_dont_have_permission") });
    //     return undefined;
    // };

    useEffect(() => {
        // Language:
        if (lang !== i18n.language) {
            i18n.changeLanguage(lang);
        }

        // ADD EVENTS TO LET THIS COMPONENT BE UNDER CONTROL OF THE EDITOR:
        window.editorFileManager = {
            setOpen: () => {
                handleToggleOpenClose(true);
            },
            setClose: () => {
                handleToggleOpenClose(false);
            },
        };

        // OPEN FILE MANAGER:
        setTimeout(() => {
            fmController.call("open");
        }, 100);

        // ADD FILE MANAGER EVENTS:
        fmController.set("after_close", () => {
            setIsOpen(false);
        });

        // REMOVE FILE MANAGER EVENTS:
        return () => {
            delete window.editorFileManager;
            fmController.remove("after_close");
        };
    }, []);

    useEffect(() => {
        if (opened !== isOpen) {
            handleToggleOpenClose(opened);
        }
    }, [opened]);

    return (
        <I18nextProvider i18n={i18n}>
            <Modal
                wrapClassName={`${EDITOR_FM_CONTAINER}`}
                visible={isOpen}
                onCancel={() => {
                    handleToggleOpenClose(false);
                }}
                title={t("my_storage.my_storage")}
                closeIcon={<IconClose />}
                footer={null}
                centered
                className="app-modal type-basic"
            >
                <Spin
                    spinning={fmSettings.isLoading !== "none"}
                    tip={<>{t(`my_storage.filemanager_${fmSettings.isLoading}`)}</>}
                    wrapperClassName="app-spin-nested-loading layout-full"
                >
                    <FileManager
                        title={t("my_storage.my_storage")}
                        controller={fmController}
                        containerStyle={{ height: "100%" }}
                        isMultiple={true}
                        isHeaderVisible={false}
                        // Interacting with folders:
                        onListFolders={handleGetListOfFolders}
                        onMakeFolder={handleAddFolder}
                        // Interacting with files:
                        onListFiles={handleGetChildren}
                        onUploadFiles={handleAddFiles}
                        onRemoveFiles={handleRemoveFiles}
                        onRenameFile={handleRenameFile}
                        onMoveFilesToFolder={handleMoveFilesToFolder}
                        onSelectFile={handleDoubleClickFile}
                    />
                </Spin>
            </Modal>

            <Modal
                wrapClassName={"editor-fm-audio-visible-type"}
                visible={modalSettings.isVisible === "modal_select_audio_visible_type"}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
                title={t("editor.select_audio_visible_type")}
                closeIcon={<IconClose />}
                footer={null}
                centered
                maskStyle={{ backgroundClip: "rgba(0, 0, 0, 0.2)" }}
                className="app-modal type-basic flexible-height maxw-520"
            >
                <div className="action-list">
                    <button
                        onClick={() => {
                            handleSelectFile(modalSettings.params.file?.src, "audio-icon");
                        }}
                    >
                        {t("editor.audio_type_icon")}
                    </button>
                    <button
                        onClick={() => {
                            handleSelectFile(modalSettings.params.file?.src, "audio-player");
                        }}
                    >
                        {t("editor.audio_type_player")}
                    </button>
                </div>
            </Modal>
        </I18nextProvider>
    );
};

export default function renderEditorFileManager(editor, configs) {
    if (window.editorFileManager) {
        if (window.editorFileManager.setOpen instanceof Function) {
            window.editorFileManager.setOpen();
        }
    } else {
        // Create React root for EditorFileManager:
        const root = document.createElement("div");
        root.setAttribute("class", EDITOR_ROOT_FM);
        const rootElem = document.body.appendChild(root);
        // Render EditorFileManager component inside the React root:
        const rootReact = ReactDOM.createRoot(rootElem);
        rootReact.render(
            <EditorFileManager opened={true} displayType={configs?.displayType} selectFile={configs?.selectFile} />
        );
    }
}
