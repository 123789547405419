const ONLY_ADMIN = ["admin"];
const ONLY_STUDENT = ["student"];
const ONLY_TEACHER = ["teacher"];
const ONLY_EMPLOYEE = ["employee", "employee_crm"];
const ADMIN_EMPLOYEE = ["admin", "employee", "employee_crm"];
const ADMIN_EMPLOYEE_TEACHER = ["admin", "employee", "employee_crm", "teacher"];

const ROLES = {
    ONLY_ADMIN,
    ONLY_STUDENT,
    ONLY_TEACHER,
    ONLY_EMPLOYEE,
    ADMIN_EMPLOYEE,
    ADMIN_EMPLOYEE_TEACHER,
};

export default ROLES;
