import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, InputNumber, Select, Table } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { fetchSubscriptionOptions } from "src/api/containers/plan";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";

import { getCardInfoByField } from "../settings";
import "./Options.scss";

export const getOptionInfo = (optionDetail) => {
    const {
        // Local fields (used locally):
        _quantity,
        // Prices:
        original_price,
        current_price,
        // Options:
        key,
        value,
    } = optionDetail;

    // 1.
    const items = [];
    const info = getCardInfoByField(key);
    const _name = info?.name;
    const _value = info?.renderField(t, value);
    items.push({ name: _name, value: _value });

    return {
        options: items,
        quantity: _quantity,
        unitPriceOriginal: original_price,
        unitPriceCurrent: current_price,
        finalPrice: current_price * (_quantity || 0),
    };
};

const Options = ({ onSelectOptions }) => {
    const { t } = useTranslation();
    const [optionData, setOptionData] = useValues({
        loading: false,
        optionList: [],
        optionListSelected: [], // Selected items (objects).
        optionIdListSelected: [], // Temporary selected items' id (strings).
    });

    const selectOptions = (newOpts) => {
        if (onSelectOptions instanceof Function) {
            onSelectOptions(newOpts);
        }
    };

    const handleSelectOptionIds = (optIds) => {
        setOptionData({
            optionIdListSelected: optIds,
        });
    };

    const handleSelectToBuyOptionIds = () => {
        if (optionData.optionIdListSelected?.length) {
            // Additional options:
            let optsToBeAdded = optionData.optionList.filter((item) => {
                if (optionData.optionIdListSelected.includes(item.id)) {
                    return true;
                }
                return false;
            });

            // Specify default quantity for each additional options:
            optsToBeAdded = optsToBeAdded.map((item) => {
                return {
                    ...item,
                    _quantity: 1,
                };
            });

            // New selected option list:
            const newOpts = [...optionData.optionListSelected, ...optsToBeAdded];
            setOptionData({
                optionListSelected: newOpts,
                optionIdListSelected: [],
            });
            selectOptions(newOpts);
        }
    };

    const handleSelectToBuyOption = (optData, quantity) => {
        if (optData?.id) {
            let existed = -1;
            for (let i = 0; i < optionData.optionListSelected.length; i++) {
                if (optData.id === optionData.optionListSelected[i].id) {
                    existed = i;
                    break;
                }
            }

            if (existed !== -1) {
                const newOpts = [...optionData.optionListSelected];
                if (quantity > 0) {
                    const _optData = {
                        ...newOpts[existed],
                        _quantity: quantity,
                    };
                    newOpts[existed] = _optData;
                    setOptionData({
                        optionListSelected: newOpts,
                    });
                    selectOptions(newOpts);
                } else {
                    newOpts.splice(existed, 1);
                    setOptionData({
                        optionListSelected: newOpts,
                    });
                    selectOptions(newOpts);
                }
            } else {
                const _optData = {
                    ...optData,
                    _quantity: quantity,
                };
                const newOpts = [...optionData.optionListSelected, _optData];
                setOptionData({
                    optionListSelected: newOpts,
                });
                selectOptions(newOpts);
            }
        }
    };

    const columns = [
        {
            title: t("plan.option"),
            dataIndex: "option",
            key: "option",
            width: "auto",
            render: (text, record) => {
                const info = getOptionInfo(record);
                return (
                    <div className="option-names">
                        {info.options.map((item, itemIndex) => {
                            return (
                                <div key={`opt-f-${itemIndex}`} className="option-name">
                                    <span>{t(item.name)}:</span>
                                    <span>{item.value}</span>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            title: t("payment.price"),
            dataIndex: "price",
            key: "price",
            width: "auto",
            render: (text, record) => {
                const { original_price, current_price } = record;
                if (original_price === current_price) {
                    return (
                        <div className="option-price">
                            <div className="curr">
                                {!isNaN(current_price) ? current_price.toLocaleString() : current_price}
                                {t("price.vnd")}
                            </div>
                        </div>
                    );
                }
                return (
                    <div className="option-price">
                        <div className="origin">
                            {!isNaN(original_price) ? original_price.toLocaleString() : original_price}
                            {t("price.vnd")}
                        </div>
                        <div className="curr">
                            {!isNaN(current_price) ? current_price.toLocaleString() : current_price}
                            {t("price.vnd")}
                        </div>
                    </div>
                );
            },
        },
        {
            title: t("shared.quantity"),
            dataIndex: "quantity",
            key: "quantity",
            width: "auto",
            render: (text, record) => {
                const { _quantity } = record;
                return (
                    <div className="option-quantity">
                        <InputNumber
                            min={1}
                            value={_quantity}
                            onChange={(quantity) => {
                                handleSelectToBuyOption(record, quantity);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => {
                return (
                    <div className="option-actions">
                        <CustomButton
                            className="option-btn btn-danger"
                            type={"simple"}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                handleSelectToBuyOption(record, 0);
                            }}
                        ></CustomButton>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        setOptionData({ loading: true });
        fetchSubscriptionOptions()
            .then((res) => {
                if (res.status) {
                    setOptionData({
                        loading: false,
                        optionList: res.data,
                    });
                }
            })
            .catch((err) => {
                setOptionData({ loading: false });
            });
    }, []);

    return (
        <div className="options-panel">
            <div className="options-header">
                <div className="options-title">{t("plan.buy_more_options")}</div>
            </div>
            <div className="options-body">
                <div className="options-select">
                    <Select
                        className="app-select show-arrow has-rd opt-select"
                        mode="multiple"
                        placeholder={t("plan.select_options")}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        showArrow={false}
                        value={optionData.optionIdListSelected}
                        onChange={handleSelectOptionIds}
                    >
                        {optionData.optionList?.map((opt, i) => {
                            const info = getOptionInfo(opt);
                            let isSelected = false;
                            for (let i = 0; i < optionData.optionListSelected.length; i++) {
                                if (opt.id === optionData.optionListSelected[i].id) {
                                    isSelected = true;
                                    break;
                                }
                            }
                            return (
                                <Select.Option key={`lg-${i}`} value={opt.id} disabled={isSelected}>
                                    {info.options.map((item) => `${t(item.name)}: ${item.value}`).join(";")}
                                </Select.Option>
                            );
                        })}
                    </Select>
                    <CustomButton
                        type={"ghost"}
                        icon={<PlusOutlined />}
                        title={t("shared.add")}
                        onClick={() => {
                            handleSelectToBuyOptionIds();
                        }}
                    ></CustomButton>
                </div>
                <Table
                    // className="app-table"
                    columns={columns}
                    dataSource={optionData.optionListSelected}
                    loading={optionData.loading}
                    pagination={false}
                    scroll={optionData.optionListSelected.length ? { x: "auto" } : undefined}
                    rowKey="id"
                />
            </div>
        </div>
    );
};

export default Options;
