import { Table as AntTable, Button, ConfigProvider, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { default as CustomButton } from "src/modules/components/Button";

import CustomizeRenderEmpty from "../CustomizeRenderEmpty";
import MediaView from "../MediaView/MediaView";
import MobileTable from "../MobileTable";

const Table = ({
    loading = false,
    columns = [],
    actions = [],
    dataSource = [],
    pagination = {},
    rowKey = "id",
    nodata_title,
    actionAlign = "center",
    mobileHeaderColumns = [],
    responsiveOnMobile = false,
    mobileHeight,

    ...rest
}) => {
    const { t } = useTranslation();

    if (actions && actions.length > 0) {
        columns.push({
            title: t("table.actions"),
            key: "action",
            width: 250,
            render: (text, record) => (
                <span className="table-action list-actions" style={{ justifyContent: actionAlign }}>
                    {actions.map((action, index) => {
                        const {
                            title,
                            icon,
                            link,
                            onClick,
                            width,
                            tooltip = "",
                            handleHidden = () => {},
                            ...rest
                        } = action;

                        if (handleHidden?.(record)) return;

                        if (link) {
                            return (
                                <Link to={link} key={index} className="table-action__icon list-btn">
                                    {icon && typeof icon === "string" && (
                                        <img src={icon} width={width || "25"} className="btn__content-image" />
                                    )}
                                    {icon && React.isValidElement(icon) && <>{icon}</>}
                                </Link>
                            );
                        }
                        if (title) {
                            return (
                                <CustomButton
                                    key={index}
                                    type="ghost"
                                    title={title}
                                    onClick={() => onClick(record)}
                                    className="table-action__btn list-btn"
                                    {...rest}
                                />
                            );
                        }
                        return (
                            <Tooltip key={index} placement="bottom" title={tooltip}>
                                {icon && typeof icon === "string" && (
                                    <span onClick={() => onClick(record)} className="table-action__icon list-btn">
                                        <img src={icon} width={width || "25"} className="btn__content-image" />
                                        {/* {icon && React.isValidElement(icon) && <>{icon}</>} */}
                                    </span>
                                )}

                                {icon && React.isValidElement(icon) && (
                                    <CustomButton
                                        className="list-btn"
                                        type="simple"
                                        icon={icon}
                                        onClick={() => onClick(record)}
                                    />
                                )}
                            </Tooltip>
                        );
                    })}
                </span>
            ),
        });
    }

    return (
        <ConfigProvider renderEmpty={() => <CustomizeRenderEmpty nodata_title={nodata_title} />}>
            {responsiveOnMobile ? (
                <>
                    <MediaView minWidth={768}>
                        <AntTable
                            className="app-table"
                            loading={loading}
                            dataSource={dataSource}
                            columns={columns}
                            pagination={pagination}
                            rowKey={rowKey}
                            {...rest}
                        />
                    </MediaView>

                    <MediaView maxWidth={767}>
                        <MobileTable
                            loading={loading}
                            columns={columns}
                            headers={mobileHeaderColumns}
                            dataSource={dataSource}
                            pagination={pagination}
                            height={mobileHeight || window.innerHeight - 300}
                            {...rest}
                        />
                    </MediaView>
                </>
            ) : (
                <>
                    <AntTable
                        className="app-table"
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={pagination}
                        rowKey={rowKey}
                        {...rest}
                    />
                </>
            )}
        </ConfigProvider>
    );
};

export default Table;
