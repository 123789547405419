import { Col, Form, Input, InputNumber, Modal, Row, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { sendContactRequest } from "src/api/containers/contact";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { validatePhoneNumber } from "src/utils/helpers/validation";

import "./ModalContactUs.scss";

const ModalContactUs = ({ visible = true, formData, className, onOk, onCancel, ...rest }) => {
    // User:
    const { user } = useSelector((state) => state.auth);

    // Form:
    const [form] = useForm();
    const [values, setValues] = useValues({
        loading: false,
        loadingForAct: false,
    });

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmit = () => {
        let formData = form.getFieldsValue();
        if (!formData.name || !formData.phone || !formData.message) {
            return;
        }

        setValues({ loadingForAct: "submit" });
        sendContactRequest(formData).then((res) => {
            setValues({ loadingForAct: false });
            if (res.status) {
                form.resetFields();
                if (onCancel instanceof Function) {
                    onCancel();
                }
                notification.success({
                    message: t("message.submit_success"),
                });
            } else {
                notification.error({
                    message: res.message || t("message.submit_error"),
                });
            }
        });
    };

    useEffect(() => {
        if (visible && Object.keys(formData || {}).length) {
            form.setFieldsValue({
                plan_id: formData.plan_id || form.getFieldValue("plan_id"),
                name: formData.name || form.getFieldValue("name"),
                phone: formData.phone || form.getFieldValue("phone"),
                message: formData.message || form.getFieldValue("message"),
            });
        }
    }, [visible, formData]);

    return (
        <Modal
            centered
            visible={visible}
            title={t("shared.contact_us")}
            closeIcon={<SvgClose />}
            footer={null}
            className={"app-modal flexible-height type-primary modal-contactus" + (className ? ` ${className}` : "")}
            wrapClassName="modal-contactus-wrap"
            maskStyle={{ zIndex: "1010" }}
            onOk={handleSubmit}
            onCancel={handleCancel}
            {...rest}
        >
            <Spin spinning={values.loading}>
                <Form form={form} layout="vertical" className="app-form type-primary has-rd" onFinish={handleSubmit}>
                    <Form.Item name="plan_id" hidden>
                        <Input />
                    </Form.Item>

                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={t("shared.fullname")}
                                name="name"
                                rules={[{ required: true, message: t("shared.input_fullname") }]}
                                initialValue={user.name}
                            >
                                <Input
                                    className="app-input has-rd"
                                    placeholder={t("shared.fullname")}
                                    maxLength={100}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={t("shared.phonenumber")}
                                name="phone"
                                rules={[
                                    { required: true, message: t("shared.input_phonenumber") },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value) {
                                                callback(validatePhoneNumber(value));
                                            } else {
                                                callback();
                                            }
                                        },
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="app-input has-rd"
                                    placeholder={t("shared.phonenumber")}
                                    minLength={10}
                                    maxLength={10}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label={t("shared.message")}
                        name="message"
                        rules={[{ required: true, message: t("shared.input_message") }]}
                    >
                        <Input.TextArea className="app-input has-rd" placeholder={t("shared.message")} rows={3} />
                    </Form.Item>

                    <div className="form-actions">
                        <CustomButton
                            type="grey"
                            icon={<SvgCross />}
                            title={t("shared.cancel")}
                            onClick={handleCancel}
                        ></CustomButton>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<SvgTick />}
                            title={t("shared.submit")}
                            isDisabled={values.loadingForAct === "submit"}
                        ></CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalContactUs;
