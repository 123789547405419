import tinymce from "tinymce";
// import MathQuill, { BindCmds } from "./mathquill";
import MathQuill from "mathquill-node";
import { Buffer } from "buffer";
import renderButtons from "./buttons";
import renderButtonsWithFilter from "./buttonsWithFilter";
import { getFocussedEditor, getOpenedEditorDialog, toggleFocussedEditor, toggleOpenedEditorDialog } from "./utils";
// import "./mathquill/mathquill.css";
import "mathquill-node/lib/mathquill.css";
import "./math.scss";

const base64_encode = (str) => Buffer.from(str, "utf8").toString("base64");

const MQ = MathQuill.getInterface(2);

let mathquill__ = null;

tinymce.PluginManager.add("math", function (editor, url) {
    const openDialog = () => {
        return editor.windowManager.open({
            title: "Math",
            size: "medium",
            body: {
                type: "panel",
                items: [
                    {
                        type: "htmlpanel",
                        html: `<div id="mathquill-editor"><div class="editor"></div><div class="button-area"></div></div>`,
                    },
                ],
            },
            buttons: [
                {
                    type: "cancel",
                    text: "Close",
                },
                {
                    type: "submit",
                    text: "Insert",
                    primary: true,
                },
            ],
            onSubmit: function (api) {
                const latex = mathquill__.latex();
                const randomID = Math.random();
                editor.insertContent(
                    `<math-static class="latex-math" contenteditable="false" data-latex="${latex}" data-latex-id="${randomID}"> ${latex}</math-static>`
                );

                const staticMathEle = document.querySelector(`[data-latex-id="${randomID}"]`);

                staticMathEle.removeAttribute("data-latex-id");

                MQ.StaticMath(staticMathEle);

                api.close();
            },
            onCancel: function (api) {
                api.close();
            },
        });
    };

    const handleOpenDialog = () => {
        const selectedExpression = editor.selection.getNode();

        const { latex: defaultLatex } = selectedExpression.dataset || {};
        openDialog();

        // Styling:
        const dialogWrap = document.querySelector(".tox-dialog-wrap");
        if (dialogWrap) {
            dialogWrap.id = "mathquill-editor-wrapper";
        }

        // Rendering:
        setTimeout(() => {
            const editorEl = document.querySelector("#mathquill-editor .editor");
            mathquill__ = MQ.MathField(editorEl);

            defaultLatex && mathquill__.latex(defaultLatex);

            const buttonAreaEl = document.querySelector("#mathquill-editor .button-area");
            renderButtons(buttonAreaEl, mathquill__);
        });
    };

    editor.ui.registry.addIcon(
        "math",
        `
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Transformed by: SVG Repo Tools -->
            <svg fill="#000000" width="24px" height="24px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
              <path d="M116,184a12,12,0,0,1-12,12H84v20a12,12,0,0,1-24,0V196H40a12,12,0,0,1,0-24H60V152a12,12,0,0,1,24,0v20h20A12,12,0,0,1,116,184ZM104,60H40a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm48,116.06641h64a12,12,0,0,0,0-24H152a12,12,0,0,0,0,24Zm64,15.86718H152a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm-64.48535-87.44824a12.00033,12.00033,0,0,0,16.9707,0L184,88.9707l15.51465,15.51465a12.0001,12.0001,0,0,0,16.9707-16.9707L200.9707,72l15.51465-15.51465a12.0001,12.0001,0,0,0-16.9707-16.9707L184,55.0293,168.48535,39.51465a12.0001,12.0001,0,0,0-16.9707,16.9707L167.0293,72,151.51465,87.51465A12.00062,12.00062,0,0,0,151.51465,104.48535Z"/>
            </svg>
        `
    );

    editor.ui.registry.addButton("math", {
        tooltip: "Math",
        icon: "math",
        onAction: function () {
            // console.log(editor.selection.getContent());
            handleOpenDialog();
        },
    });

    // editor.ui.registry.addContextToolbar("math", {
    //     items: "math",
    //     predicate: function (elem) {
    //         console.log(elem.nodeName);
    //         return elem.nodeName.toLowerCase() === "img" && elem.classList.contains("math-item");
    //     },
    //     position: "node",
    //     scope: "node",
    // });

    return {
        getMetadata: function () {
            return {
                name: "Math",
                url: "",
            };
        },
    };
});

tinymce.PluginManager.add("math_characters", function (editor, url) {
    const reset = () => {
        // Style of editor and its dialog:
        editor.bodyElement.style.pointerEvents = "";
        // Editor and dialog:
        toggleOpenedEditorDialog(false);
        toggleFocussedEditor(false);
    };

    const openDialog = () => {
        return editor.windowManager.open({
            title: "Math characters",
            size: "medium",
            body: {
                type: "panel",
                items: [
                    {
                        type: "htmlpanel",
                        html: `
                            <div id="mathquill-mathchars">
                                <div class="editor"></div>
                                <div class="button-area"></div>
                            </div>
                        `,
                    },
                ],
            },
            buttons: [
                {
                    type: "cancel",
                    text: "Close",
                },
                {
                    type: "submit",
                    text: "Insert",
                    primary: true,
                },
            ],
            onSubmit: function (api) {
                const latex = mathquill__.latex();
                const randomID = Math.random();
                editor.insertContent(
                    `&nbsp;<math-static class="latex-math" contenteditable="false" data-latex="${latex}" data-latex-id="${randomID}"> ${latex}</math-static>&nbsp;`
                );

                const staticMathEle = document.querySelector(`[data-latex-id="${randomID}"]`);

                staticMathEle.removeAttribute("data-latex-id");

                MQ.StaticMath(staticMathEle);

                toggleOpenedEditorDialog(false);
                api.close();
            },
            onCancel: function (api) {
                toggleOpenedEditorDialog(false);
                api.close();
            },
        });
    };

    const handleOpenDialog = (className, styles, options = { isOneOperandOnly: false }) => {
        // Get selected Latex:
        let defaultLatex;
        if (options.isOneOperandOnly === false) {
            const selectedExpression = editor.selection.getNode();
            const { latex } = selectedExpression.dataset || {};
            defaultLatex = latex;
        } else {
            const expression = editor.getElement().getElementsByTagName("math-static")?.[0];
            if (expression) {
                editor.selection.select(expression);
                const { latex } = expression.dataset || {};
                defaultLatex = latex;
            }
        }

        // Dialog:
        openDialog();

        // Styling:
        const dialogWrap = document.querySelector(".tox-dialog-wrap");
        if (dialogWrap) {
            dialogWrap.id = "mathquill-mathchars-wrapper";
        }
        if (className) {
            dialogWrap.classList.add(className);
        }
        if (styles) {
            const cssKeys = Object.keys(styles);
            for (let i = 0; i < cssKeys.length; i++) {
                dialogWrap.style[cssKeys[i]] = styles[cssKeys[i]];
            }
        }

        setTimeout(() => {
            // Render math-character-editor:
            const editorEl = document.querySelector("#mathquill-mathchars .editor");
            mathquill__ = MQ.MathField(editorEl);

            defaultLatex && mathquill__.latex(defaultLatex);

            // Render math-character-button list:
            const buttonsEl = document.querySelector("#mathquill-mathchars .button-area");
            renderButtonsWithFilter(buttonsEl, mathquill__);
        });
    };

    editor.ui.registry.addIcon(
        "math_characters",
        `
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Transformed by: SVG Repo Tools -->
            <svg fill="#000000" width="24px" height="24px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
              <path d="M116,184a12,12,0,0,1-12,12H84v20a12,12,0,0,1-24,0V196H40a12,12,0,0,1,0-24H60V152a12,12,0,0,1,24,0v20h20A12,12,0,0,1,116,184ZM104,60H40a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm48,116.06641h64a12,12,0,0,0,0-24H152a12,12,0,0,0,0,24Zm64,15.86718H152a12,12,0,0,0,0,24h64a12,12,0,0,0,0-24Zm-64.48535-87.44824a12.00033,12.00033,0,0,0,16.9707,0L184,88.9707l15.51465,15.51465a12.0001,12.0001,0,0,0,16.9707-16.9707L200.9707,72l15.51465-15.51465a12.0001,12.0001,0,0,0-16.9707-16.9707L184,55.0293,168.48535,39.51465a12.0001,12.0001,0,0,0-16.9707,16.9707L167.0293,72,151.51465,87.51465A12.00062,12.00062,0,0,0,151.51465,104.48535Z"/>
            </svg>
        `
    );

    editor.ui.registry.addButton("math_characters", {
        tooltip: "Math characters",
        icon: "math_characters",
        onAction: function () {
            const currEditor = getFocussedEditor();
            if (currEditor.editor === false) {
                return;
            }
            if (getOpenedEditorDialog() === false) {
                toggleOpenedEditorDialog(currEditor.editor);
            } else {
                return;
            }
            handleOpenDialog(undefined, undefined, currEditor.dialogSettings);
        },
    });

    // editor.ui.registry.addContextToolbar("math_characters", {
    //     items: "math_characters",
    //     predicate: function (elem) {
    //         console.log(elem.nodeName);
    //         return elem.nodeName.toLowerCase() === "img" && elem.classList.contains("math-item");
    //     },
    //     position: "node",
    //     scope: "node",
    // });

    editor.on("blur", function () {
        reset();
        editor.windowManager.close();
    });

    editor.on("mouseup", function () {
        // 1. Check if editor is focussed and its dialog is opened:
        const currEditor = getFocussedEditor();
        if (currEditor.editor === false) {
            return;
        }
        if (getOpenedEditorDialog() === false) {
            // Dialog:
            toggleOpenedEditorDialog(currEditor.editor);
            // Do after opening dialog:
            if (currEditor.dialogSettings.isOneOperandOnly === true) {
                editor.bodyElement.style.pointerEvents = "none";
            }
        } else {
            return;
        }
        // 2. Render:
        const className = "relative-to-sth";
        const styles = {};
        // Dialog's position relative to the viewport:
        styles.top = "100%";
        styles.left = "0px";
        styles.right = "unset";
        styles.bottom = "unset";
        // Open dialog with the configs above:
        document.body.style.overflow = "auto";
        handleOpenDialog(className, styles, currEditor.dialogSettings);
        const dialogRoot = document.querySelector(`#${currEditor.toolbar} .tox.tox-tinymce-aux`);
        const dialogElem = document.querySelector(".tox .tox-dialog-wrap");
        if (dialogRoot) {
            dialogRoot.style.position = "unset";
        }
        if (dialogElem) {
            dialogElem.style.position = "absolute";
        }
        // Others:
        editor.focus();
    });

    return {
        getMetadata: function () {
            return {
                name: "Math characters",
                url: "",
            };
        },
    };
});
