import React from "react";

import styles from "./ScanningEffect.module.css";

function ScanningEffect({ children, isDisableClick }) {
    const handleClick = (e) => {
        if (isDisableClick) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    return (
        <div className={styles.scanningEffectContainer} onClick={handleClick}>
            <div className={styles.scanningEffect}>
                <div className={styles.ocrloader}>
                    <p className={styles.ocrloaderText}>Scanning</p>
                    <em className={styles.ocrloaderBorder}></em>
                    <span className={styles.ocrloaderBar}></span>
                </div>
            </div>
            {children}
        </div>
    );
}

export default ScanningEffect;
