import api from "..";

export const fetchUnitList = (fetchParams) => {
    const filters = [];
    fetchParams?.filters && filters.push(`${fetchParams.filters}`);
    fetchParams?.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    const params = {
        _page: fetchParams?.page,
        _filter: filters.join(";") || undefined,
        _unit_fields: `${["name", "title", "order", "progress"]}`,
        _relations: (fetchParams.relations || [])?.join(","),
        _orderBy: fetchParams._orderBy || "order:asc",
        ...(fetchParams?.courseFields ? { _course_fields: fetchParams?.courseFields.join(",") } : {}),
    };
    return api
        .get("/unit", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta?.pagination.lastPage,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findUnit = (id, fetchParams) => {
    const params = {
        _unit_fields: `${["name", "title", "order"]}`,
    };
    return api
        .get(`/unit/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const createUnit = (data) => {
    return api
        .post("/unit", data)
        .then(({ status, id, message }) => ({ status, id, message }))
        .catch(() => ({ status: false }));
};

export const updateUnit = (id, data) => {
    return api
        .put(`/unit/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const fetchUnitByCourse = (courseId) => {
    const filters = [];
    courseId && filters.push(`course_id:${courseId}`);
    const params = {
        _filter: filters.join(";") || undefined,
        _unit_fields: `${["name", "level_id"]}`,
        _noPagination: 1,
    };
    return api
        .get(`/unit`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const deleteUnit = (id) => {
    return api
        .delete(`/unit/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const getRecommendedUnitOrder = (courseId) => {
    const params = {
        course_id: courseId,
    };
    return api
        .get(`/unit/recommend`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const fetchUnitsOfStudent = (fetchParams, relations = []) => {
    const filters = [];
    fetchParams?.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    const params = {
        _page: fetchParams?.page,
        _filter: filters.join(";") || undefined,
        _unit_fields: `${["name", "title", "order"]}`,
        _relations: relations.join(","),
        _orderBy: "created_at:asc",
        ...(fetchParams?.course_fields ? { _course_fields: fetchParams?.course_fields.join(",") } : {}),
    };
    return api
        .get("/unit", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
                meta,
            };
        })
        .catch(() => ({ status: false, data: [] }));
};
