import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Form, Input, InputNumber, Modal, notification, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { fetchLevels } from "src/api/containers/level";
import { fetchTeachers } from "src/api/containers/user";
import { default as CustomButton } from "src/modules/components/Button";
import iconClose from "src/assets/images/action-close.svg";
import Icon from "src/modules/components/Icon";
import { useValues } from "src/hooks";
import { createUnit, findUnit, getRecommendedUnitOrder, updateUnit } from "src/api/containers/unit";
import "./ModalDetailUnit.scss";

const ModalDetailUnit = ({
    courseId = "",
    className = "",
    unitId = "",
    visible = true,
    onOk = () => {},
    onCancel = () => {},
    children,
    ...rest
}) => {
    const user = useSelector((state) => state.auth.user);
    const isAllowFetchTeacherList = user.organization?.is_organization_owner || false;

    const [values, setValues] = useValues({
        loading: false,
        loadingTeachers: false,
        optionListLevel: [],
        optionListTeacher: [],
    });
    const { t } = useTranslation();
    const [form] = useForm();

    const handleCancelForm = () => {
        form.resetFields();
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleAddUnit = (newData) => {
        if (newData) {
            setValues({ ...values, loading: true });
            createUnit(newData).then((res) => {
                setValues({ ...values, loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    onOk();
                    handleCancelForm();
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const handleUpdateUnit = (id, data) => {
        if (id && data) {
            setValues({ ...values, loading: true });
            updateUnit(id, data).then((res) => {
                setValues({ ...values, loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    onOk();
                    handleCancelForm();
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        }
    };

    const handleSubmitForm = () => {
        // Checking data to be submitted:
        if (!courseId) {
            notification.error({
                message: t("unit.add_error_missing_course_id"),
            });
            return;
        }
        let formData = form.getFieldsValue();
        if (!formData.name || !formData.title || isNaN(formData.order)) {
            notification.error({
                message: t("unit.add_error_missing_fields"),
            });
            return;
        }
        // Submit:
        formData = {
            ...formData,
            course_id: courseId,
        };
        if (unitId) {
            handleUpdateUnit(unitId, formData);
        } else {
            handleAddUnit(formData);
        }
    };

    useEffect(() => {
        setValues({ ...values, loading: true });
        Promise.all([
            fetchLevels({ noPagination: true }),
            ...(isAllowFetchTeacherList
                ? [fetchTeachers({ noPagination: true, user_organization: isAllowFetchTeacherList })]
                : []),
        ]).then((res) => {
            if (res[0].status && !res[1]) {
                setValues({
                    ...values,
                    loading: false,
                    optionListLevel: res[0].data ? res[0].data : [],
                });
            } else if (res[0].status && res[1].status) {
                setValues({
                    ...values,
                    loading: false,
                    optionListLevel: res[0].data ? res[0].data : [],
                    optionListTeacher: res[1].data ? [user, ...res[1].data] : [user],
                });
            } else {
                setValues({ ...values, loading: false });
            }
        });
    }, []);

    useEffect(() => {
        if (visible) {
            if (unitId) {
                setValues({ ...values, loading: true });
                findUnit(unitId).then((res) => {
                    if (res.status) {
                        form.setFieldsValue({
                            name: res.data.name || "",
                            title: res.data.title || "",
                            order: res.data.order || undefined,
                        });
                    } else {
                    }
                    setValues({ ...values, loading: false });
                });
            } else {
                form.resetFields();
                setValues({ ...values, loading: true });
                getRecommendedUnitOrder(courseId).then((res) => {
                    if (res.status) {
                        form.setFieldsValue({
                            ...form.getFieldsValue(),
                            order: res.data.recommend_order || undefined,
                        });
                    } else {
                    }
                    setValues({ ...values, loading: false });
                });
            }
        }
    }, [visible, unitId]);

    return (
        <Modal
            visible={visible}
            onOk={handleSubmitForm}
            onCancel={handleCancelForm}
            centered
            title={unitId ? t("unit.update_unit") : t("unit.add_unit")}
            closeIcon={<img src={iconClose}></img>}
            footer={null}
            width="auto"
            {...rest}
            className={`modal-add-unit${className ? " " + className : ""}`}
        >
            <Spin spinning={values.loading}>
                {children ? (
                    children
                ) : (
                    <Form form={form}>
                        <Form.Item
                            label={t("unit.order")}
                            name="order"
                            rules={[{ required: true, message: t("unit.input_unit_order") }]}
                        >
                            <InputNumber placeholder={t("unit.input_unit_order")} />
                        </Form.Item>

                        <Form.Item
                            label={t("unit.name")}
                            name="name"
                            rules={[{ required: true, message: t("unit.input_unit_name") }]}
                        >
                            <Input placeholder={t("unit.input_unit_name")} maxLength={100} />
                        </Form.Item>

                        <Form.Item
                            label={t("unit.title")}
                            name="title"
                            rules={[{ required: true, message: t("unit.input_unit_title") }]}
                        >
                            <Input.TextArea
                                placeholder={t("unit.input_unit_title")}
                                showCount
                                maxLength={200}
                                autoSize={{ minRows: 1, maxRows: 2 }}
                            />
                        </Form.Item>

                        <div className="btn-group">
                            <CustomButton
                                type="grey"
                                icon={<Icon name="icon-delete" />}
                                title={t("action.cancel")}
                                onClick={handleCancelForm}
                            ></CustomButton>
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-save" />}
                                title={t("action.save")}
                                onClick={handleSubmitForm}
                            ></CustomButton>
                        </div>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default ModalDetailUnit;
