import devtoolsDetector from "devtools-detector";
import { useEffect, useState } from "react";
import configs from "src/configs";
const DEVTOOL_CONFIG = configs.EXAM_PROCTORING.DEV_TOOL;

export default function useIsDevToolsOpen(interval = 1000, enabled = true) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!enabled) return;

        devtoolsDetector.addListener(setIsOpen);
        devtoolsDetector.setDetectDelay(interval);
        devtoolsDetector.launch();

        return () => {
            devtoolsDetector.stop();
            devtoolsDetector.removeListener(setIsOpen);
        };
    }, [enabled, interval]);

    useEffect(() => {
        if (!enabled && !DEVTOOL_CONFIG.BLOCK_RIGHT_CLICK) return;

        function preventEvent(event) {
            event.preventDefault();
        }

        if (DEVTOOL_CONFIG.BLOCK_RIGHT_CLICK && window) {
            window.addEventListener("contextmenu", preventEvent);
        }

        return () => {
            window.removeEventListener("contextmenu", preventEvent);
        };
    }, [enabled]);

    return isOpen;
}
