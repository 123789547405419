import { CheckCircleOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Form, Input, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { forgotPassword, resetPassword } from "src/api/containers/auth";
import ForgetPasswordPNG from "src/assets/images/ForgetPassword.png";
import ResetPasswordPNG from "src/assets/images/resetPassword.png";
import { getTargetURLQueryString } from "src/modules/auth/helpers";
import Button from "src/modules/components/Button";
import { validateEmail } from "src/utils/helpers";

import "./ForgetPassword.scss";

const ForgetPassword = () => {
    const [form] = useForm();
    const navigate = useNavigate();
    const [isSent, setIsSent] = useState(false);
    const [username, setUsername] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        setIsLoading(true);
        forgotPassword(formData).then(({ status, message }) => {
            if (status) {
                notification.success({
                    message: t("message.email_sent"),
                    description: t("login.receive_code"),
                });
                setUsername(formData.username);
                setIsSent(true);
                setIsLoading(false);
            } else {
                notification.error({
                    message: message || t("login.send_error"),
                });
                setIsLoading(false);
            }
        });
    };

    const handelResetPassword = () => {
        const formData = form.getFieldValue();
        if (formData.password !== formData.confirm_password) {
            notification.error({
                message: t("login.password_not_match"),
            });
            return;
        }

        formData.username = username;
        setIsLoading(true);
        resetPassword(formData).then(({ status, message }) => {
            if (status) {
                notification.success({
                    message: t("message.reset_success"),
                });
                setIsLoading(false);
                navigate(`/login${getTargetURLQueryString()}`);
            } else {
                notification.error({
                    message: message || t("message.reset_error"),
                });
                setIsLoading(false);
            }
        });
    };

    return (
        <>
            {!isSent ? (
                <div className="sign-in forget-password">
                    <div className="sign-in-wrapper">
                        <Spin spinning={isLoading}>
                            <h1>{t("login.forget_password")}?</h1>
                            <h4>{t("login.enter_mail_to_reset_password")}</h4>

                            <div className="forget-password-wrapper">
                                <div className="forget-password-img">
                                    <img src={ForgetPasswordPNG} alt="" />
                                </div>
                                <Form form={form} onFinish={handleSubmit}>
                                    <h2>Email</h2>
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                            { validator: validateEmail },
                                        ]}
                                    >
                                        <Input
                                            className="app-input"
                                            placeholder={t("login.enter_your_email")}
                                            prefix={<MailOutlined />}
                                        />
                                    </Form.Item>
                                    <p className="sign-in-register">
                                        {t("login.still_remember")}
                                        <Link to={`/login${getTargetURLQueryString()}`}>{t("login.sign_in")}</Link>
                                    </p>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            title={t("login.send")}
                                            className="submit-btn"
                                            htmlType="submit"
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </Spin>
                    </div>
                </div>
            ) : (
                <div className="sign-in forget-password reset-password">
                    <div className="sign-in-wrapper">
                        <Spin spinning={isLoading}>
                            <h1>{t("login.reset_password")}</h1>
                            <h4>{t("login.rule_new_password")}</h4>
                            <div className="forget-password-wrapper">
                                <div className="forget-password-img">
                                    <img src={ResetPasswordPNG} alt="" />
                                </div>
                                <Form form={form} onFinish={handelResetPassword}>
                                    <h2>{t("login.verify")}</h2>
                                    <Form.Item
                                        name="code_reset"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="app-input"
                                            placeholder={t("login.enter_code")}
                                            prefix={<CheckCircleOutlined />}
                                        />
                                    </Form.Item>
                                    <h2>{t("login.new_password")}</h2>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            className="app-input"
                                            placeholder="********************"
                                            prefix={<LockOutlined />}
                                        />
                                    </Form.Item>
                                    <h2>{t("login.retype_new_password")}</h2>
                                    <Form.Item
                                        name="confirm_password"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("message.required"),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t("message.password_not_match")));
                                                },
                                            }),
                                        ]}
                                        dependencies={["password"]}
                                    >
                                        <Input.Password
                                            className="app-input"
                                            placeholder="*****************"
                                            prefix={<LockOutlined />}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            title={t("login.save_password")}
                                            className="submit-btn"
                                            htmlType="submit"
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </Spin>
                    </div>
                </div>
            )}
        </>
    );
};

export default ForgetPassword;
