import { EllipsisOutlined, HistoryOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, notification, Row, Select, Space, Spin } from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { assignmentTypes, deleteAssignment, fetchAssignments } from "src/api/containers/assignment";
import { findCourse } from "src/api/containers/course";
import { findUnit } from "src/api/containers/unit";
import { useFetch, useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
import CourseAssignment from "src/modules/components/CoursePanels/CourseAssignment";
import CourseTopic from "src/modules/components/CoursePanels/CourseTopic";
import Icon from "src/modules/components/Icon";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { useRouting } from "src/utils/router";
import { OutlineButton } from "../../components/Button";
import ModalAssignExam from "../../components/ModalAssignExam";
import "./CourseDetailUnit.scss";
import { CloseIconSVG } from "src/utils/drawer";
import { HistoryContainer } from "src/modules/containers/ManageHistory/HistoryFeature/HistoryContainer";

function CourseDetailUnit(props) {
    const navigate = useNavigate();
    const { generate } = useRouting();
    const user = useSelector((state) => state.auth.user);
    const { courseId, unitId } = useParams();
    const isFechedData = useRef(false);

    const [asgmtLoading, asgmtDataSource, asgmtPagination, asgmtFetch, asgmtRefetch] = useFetch(
        { page: 1, filters: `course_id:${courseId};unit_id:${unitId};is_unit:true`, _orderBy: "order:asc" },
        fetchAssignments
    );

    const [modalConfirm, setModalConfirm] = useState({
        isConfirmModalVisible: false,
        params: null,
    });
    const [showModal, setShowModal] = useState({
        isShown: "",
        params: null,
    });
    const [values, setValues] = useValues({
        isLoading: false,
        courseData: {},
        unitData: {},
        isModalHistory: false,
        assignmentId: undefined,
    });
    const [filterType, setFilterType] = useState(null);
    const [paginatedList, setPaginatedList] = useState({
        listPages: 0,
        listOfAsgmts: [],
        fetchStatus: "",
    });

    const routes = useMemo(() => {
        const result = [{ path: "home" }];
        values?.courseData?.name &&
            result.push({
                path: generate("course_detail", { id: values.courseData.id }),
                name: values?.courseData?.name,
            });
        values?.unit?.name &&
            result.push({
                path: generate("course_detail_unit", { courseId: values.courseData.id, unitId: values.unitData.id }),
                name: values?.unitData?.name,
            });
        return result;
    }, [values]);

    const handleDeleteAsgmt = (asgmtId) => {
        setValues({ ...values, isLoading: true });
        deleteAssignment(asgmtId).then((res) => {
            if (res.status === true) {
                asgmtFetch({ page: 1 }, true);
                notification.success({ message: t("message.delete_success") });
            } else {
                notification.error({ message: res.message || t("message.delete_error") });
            }
            setValues({ ...values, isLoading: false });
            setModalConfirm({ ...modalConfirm, isConfirmModalVisible: false, params: null });
        });
    };

    useEffect(() => {
        setValues({
            isLoading: true,
        });
        Promise.all([findCourse(courseId, { relations: ["level"] }), findUnit(unitId)]).then(([course, unit]) => {
            if (course.status === true && unit.status === true) {
                setValues({
                    isLoading: false,
                    courseData: course.data,
                    unitData: unit.data,
                });
                asgmtFetch({}, true);
                isFechedData.current = true;
            }
        });
    }, [unitId, courseId]);

    useEffect(() => {
        const extra = {};
        if (asgmtLoading === true && paginatedList.fetchStatus === "") {
            extra.fetchStatus = "fetching";
        }
        if (asgmtLoading === false && paginatedList.fetchStatus === "fetching") {
            extra.fetchStatus = "fetched";
        }

        if (asgmtDataSource.length) {
            if (asgmtPagination.current === 1) {
                setPaginatedList({
                    ...paginatedList,
                    listPages: asgmtPagination.current,
                    listOfAsgmts: asgmtDataSource,
                    ...extra,
                });
            } else if (asgmtPagination.current > paginatedList.listPages) {
                setPaginatedList({
                    ...paginatedList,
                    listPages: asgmtPagination.current,
                    listOfAsgmts: [...paginatedList.listOfAsgmts, ...asgmtDataSource],
                });
            }
        } else {
            setPaginatedList({
                ...paginatedList,
                listPages: 0,
                listOfAsgmts: [],
                ...extra,
            });
        }
    }, [asgmtLoading, asgmtDataSource, asgmtPagination]);

    const renderMenu = (asgmtId) => {
        return (
            <Menu
                className="asgmt-list-item-dropdown"
                items={[
                    {
                        key: "asgmt-mn1",
                        label: (
                            <Space className="item-btn">
                                <Icon name="icon-modify" />
                                {t("action.update")}
                            </Space>
                        ),
                        disabled: showModal.isShown,
                        onClick: () => {
                            setShowModal({ isShown: "update-assignment", params: { asgmtId: asgmtId } });
                        },
                    },
                    {
                        key: "asgmt-mn2",
                        label: (
                            // <Popconfirm
                            //     className="item-btn"
                            //     title={`${t("action.msg_confirm_delete")}?`}
                            //     onConfirm={() => {
                            //         handleDeleteAsgmt(asgmtId);
                            //     }}
                            //     okText={t("action.yes")}
                            //     cancelText={t("action.no")}
                            // >
                            //     <Space>
                            //         <Icon name="icon-delete" />
                            //         {t("action.delete")}
                            //     </Space>
                            // </Popconfirm>
                            <Space className="item-btn">
                                <Icon name="icon-delete" />
                                {t("action.delete")}
                            </Space>
                        ),
                        disabled: showModal.isShown,
                        onClick: () => {
                            setModalConfirm({ isConfirmModalVisible: true, params: { asgmtId: asgmtId } });
                        },
                    },
                    {
                        key: "asgmt-mn3",
                        label: (
                            <Space className="item-btn">
                                <HistoryOutlined />
                                {t("nav.history")}
                            </Space>
                        ),
                        onClick: () => {
                            setValues({ isModalHistory: true, assignmentId: asgmtId });
                        },
                    },
                ]}
            />
        );
    };

    return (
        <div className="teacher-detail-unit unit-detail">
            <Breadcrumb routes={routes} isBack className="mobile" />

            <div className="teacher-unit-wrapper">
                <div className="course-list-filter">
                    <div className="course-list-filter_skin">
                        <span>{t(`course.type`)}</span>
                        <span>
                            <Select className="app-select has-rd" defaultValue={null} onChange={setFilterType}>
                                <Select.Option value={null}>{t(`course.all`)}</Select.Option>
                                {assignmentTypes.map((type) => (
                                    <Select.Option key={type.value} value={type.value}>
                                        {t(`course.${type.name}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </span>
                    </div>
                </div>

                <div className="list-action-mobile">
                    <div className="unit-list-title">
                        <span>{t("teacher_courses.list_lesson")}</span>
                    </div>
                    {(user?.role === "employee" || user?.role === "admin") && (
                        <OutlineButton
                            title={t("assignment.add_assignment")}
                            icon={<PlusCircleOutlined />}
                            onClick={() => setShowModal({ isShown: "add-assignment", params: null })}
                        />
                    )}
                </div>

                <Row className="unit-detail-item" gutter={[30, 30]}>
                    <Col span={24}>
                        <div className="unit-item-wrapper">
                            <Spin spinning={values.isLoading}>
                                <CourseTopic isActive={true} topicInfo={values.unitData} showActions={false} />
                            </Spin>
                        </div>
                    </Col>
                </Row>

                <div className="unit-assignment-list">
                    <div className="list-actions">
                        {(user?.role === "employee" || user?.role === "admin") && (
                            <OutlineButton
                                title={t("assignment.add_assignment")}
                                icon={<PlusCircleOutlined />}
                                onClick={() => setShowModal({ isShown: "add-assignment", params: null })}
                            />
                        )}
                    </div>
                    <div className="list-wrapper">
                        <Row
                            gutter={[
                                { xs: 10, sm: 15, lg: 30 },
                                { xs: 10, sm: 15, lg: 30 },
                            ]}
                            style={{ marginTop: "10px", justifyContent: "stretch" }}
                        >
                            {paginatedList.listOfAsgmts
                                .filter((item) => {
                                    if (filterType === null) return true;
                                    return item.type === filterType;
                                })
                                .map((item, index) => (
                                    <Col key={`asgmt${index}`} xs={12} sm={12} md={8} xl={6}>
                                        <div className="assignment-item-wrapper">
                                            <Spin spinning={asgmtLoading}>
                                                <CourseAssignment
                                                    assignmentInfo={item}
                                                    actions={
                                                        user?.role == "employee" || user?.role == "admin" ? (
                                                            <Dropdown
                                                                overlay={renderMenu(item.id)}
                                                                trigger={["click"]}
                                                                placement="bottomRight"
                                                            >
                                                                <Button className="dropdown-btn">
                                                                    <EllipsisOutlined />
                                                                </Button>
                                                            </Dropdown>
                                                        ) : null
                                                    }
                                                    linkTo={
                                                        user?.role !== "employee_crm"
                                                            ? item.exam?.id
                                                                ? generate("update_exam_bank_course", {
                                                                      id: item.exam?.id,
                                                                      assignment_id: item.id,
                                                                  })
                                                                : ""
                                                            : "#"
                                                    }
                                                />
                                            </Spin>
                                        </div>
                                    </Col>
                                ))}
                            {paginatedList.fetchStatus === "fetched" && paginatedList.listOfAsgmts.length === 0 ? (
                                <Col md={24}>
                                    <div className="list-msg">{t("assignment.assignment_list_empty")}!</div>
                                </Col>
                            ) : null}
                            {asgmtPagination.current < asgmtPagination.lastPage ? (
                                <Col xs={12} sm={12} md={8} xl={6}>
                                    <div className="assignment-item-loadmore">
                                        <CustomButton
                                            title={`${t("assignment.load_more")}...`}
                                            icon={null}
                                            onClick={() => asgmtFetch({ page: asgmtPagination.current + 1 })}
                                        />
                                    </div>
                                </Col>
                            ) : null}
                        </Row>
                    </div>
                </div>
            </div>

            <ModalAssignExam
                isFechedData={isFechedData}
                forceRender={false}
                onlyAssignment={true}
                isOnMobile={values.isOnMobile}
                isShowFormSelect={true}
                setValuesExam={setValues}
                type="assignment"
                language_id={values.courseData?.language_id || ""}
                levelId={values.courseData.level?.id || ""}
                extraSubmitData={{ courseId: courseId, unitId: unitId }}
                assignmentId={showModal.params?.asgmtId || undefined}
                isLoading={asgmtLoading}
                visible={showModal.isShown === "add-assignment" || showModal.isShown === "update-assignment"}
                onOk={() => {
                    asgmtRefetch();
                    setShowModal({ isShown: "", params: null });
                }}
                onCancel={() => setShowModal({ isShown: "", params: null })}
                levelList={values.courseData?.level ? [values.courseData?.level] : []}
                destroyOnClose
                width="calc(100vw - 40px)"
                // height="calc(100vh - 40px)"
                style={{
                    maxWidth: "940px",
                    // maxHeight: "940px",
                    margin: "20px 0",
                }}
            />

            {/* Modal history */}
            <Modal
                title={t("history.editing_history")}
                visible={values.isModalHistory}
                onOk={() => {
                    setValues({ isModalHistory: false });
                }}
                onCancel={() => {
                    setValues({ isModalHistory: false });
                }}
                closeIcon={<CloseIconSVG />}
                footer={null}
                className={"modal-add-course modal_histories"}
                width={"auto"}
                destroyOnClose={true}
                centered={true}
            >
                <HistoryContainer modal_id={values.assignmentId} type="assignment_history" />
            </Modal>

            <PopConfirm
                type="delete"
                visible={modalConfirm.isConfirmModalVisible}
                onOk={() => {
                    handleDeleteAsgmt(modalConfirm.params.asgmtId);
                }}
                onCancel={() => setModalConfirm({ ...modalConfirm, isConfirmModalVisible: false, params: null })}
            />
        </div>
    );
}

export default CourseDetailUnit;
