import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Input, InputNumber, Modal, notification, Row, Select, Spin, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { addFiles, deleteFiles } from "src/api/containers/storage";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";

import "./ModalAddGuide.scss";
import { createGuide, fetchGuides, fetchRecommend, findGuide, updateGuide } from "src/api/containers/guide";
import Editor from "src/modules/components/Editor";
import { useValues } from "src/hooks";

const guideTypes = [
    { id: "course" },
    { id: "my_question_resource" },
    { id: "my_exam_resource" },
    { id: "multiplechoice_question" },
    { id: "question_bank" },
    { id: "fill_blank_question" },
    { id: "writing_question" },
    { id: "passage_question" },
    { id: "sort_question" },
    { id: "drag_drop_question" },
    { id: "learning_progress" },
    { id: "my_storage" },
    { id: "guide" },
    { id: "profile" },
    { id: "exam_bank" },
    { id: "assignment" },
    { id: "score" },
    { id: "class" },
    { id: "gradebook" },
];

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

function GuideModal(props) {
    const guide_id = props?.values?.selectedGuide?.id;
    const visible = props?.values?.isAddGuideModalVisible;

    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();
    const [formLoading, setFormLoading] = useState(false);

    const { t } = useTranslation();
    const [selectedGuide, setSelectedGuide] = useState("");
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [imgData, setImgData] = useState();
    const [roles, setRoles] = useState([]);

    const [formValues, setFormValues] = useValues({
        step: "choose", //choose, add_feature, add_feature_desc
        disable_feature: true,
        disable_order: true,
        disable_slect_page: false,
        feature_order: 0,
        desc_feature_order: 0,
        parent_id: "",
        type: "",
        feature: [],
        feature_title: "",
        modal_title: "choose",
    });

    const roleOptions = [
        { label: t("guide.student"), value: "student" },
        { label: t("guide.teacher"), value: "teacher" },
        { label: t("guide.employee"), value: "employee" },
    ];

    const handleSubmitAddFeature = () => {
        let formData = form.getFieldsValue();
        formData = {
            ...formData,
            description: "-",
        };

        setFormLoading(true);
        if (guide_id) {
            let roles = formData?.roles?.map((i) => (i.value ? i.value : i)) || [];

            formData.roles = roles;

            updateGuide(guide_id, formData).then(({ status, message }) => {
                if (status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    props?.refetch();
                    form.resetFields();
                    setRoles([]);
                } else {
                    // setRoles([]);
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
                props?.onchangeValues({ selectedGuide: null, isAddGuideModalVisible: false, isUpdating: false });
                setFormLoading(false);
                setFormValues({ step: "choose" });
            });
        } else {
            createGuide(formData).then(({ status, message, id }) => {
                const parent_id = id;
                if (status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    // props?.onchangeValues({ selectedGuide: null, isAddGuideModalVisible: false });
                    props?.refetch();
                    setRoles([]);

                    fetchGuides({ type: formData.type, noPagination: true }).then((res) => {
                        const { status, data } = res;
                        if (status) {
                            setFormValues({ feature: data, disable_feature: true });
                        }
                    });

                    fetchRecommend("", parent_id).then(({ data, status }) => {
                        if (status) {
                            let order = data?.recommend_order || 0;
                            setFormValues({ order: order });
                            form.setFieldsValue({
                                order: order,
                            });
                        }
                    });

                    form.setFieldsValue({
                        title: formData?.title,
                        type: formData?.type,
                    });

                    setFormValues({ step: "add_feature_desc", parent_id: parent_id, feature_title: formData.title });
                } else {
                    // setRoles([]);
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                    props?.onchangeValues({ selectedGuide: null, isAddGuideModalVisible: false });
                }
                setFormLoading(false);
            });
        }
    };
    const handleSubmitAddFeatureDesc = () => {
        let formData = form.getFieldsValue();
        formData = {
            ...formData,
            image: imgData,
            parent_id: formValues.parent_id || formData.title,
            title: formValues.feature_title,
        };

        setFormLoading(true);
        if (guide_id) {
            updateGuide(guide_id, formData).then(({ status, message }) => {
                if (status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    props?.refetch();
                    form.resetFields();
                    setImgData(null);
                    setRoles([]);
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }

                setFormLoading(false);
                setFormValues({ step: "choose" });
                props?.onchangeValues({ selectedGuide: null, isAddGuideModalVisible: false, isUpdating: false });
            });
        } else {
            createGuide(formData).then(({ status, message }) => {
                if (status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    // props?.onchangeValues({ selectedGuide: null, isAddGuideModalVisible: false });
                    props?.refetch();
                    form.resetFields();
                    setImgData(null);
                    setRoles([]);
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
                setFormLoading(false);
                setFormValues({ step: "choose" });
                props?.onchangeValues({ selectedGuide: null, isAddGuideModalVisible: false });
            });
        }
    };

    const handleUploadImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("files", file, file.name);
        setIsLoadingImage(true);
        addFiles(formData).then((res) => {
            if (res.status && res.data[0]?.src) {
                setIsLoadingImage(false);
                setImgData(res.data[0]);
                notification.success({
                    message: t("message.upload_success"),
                });
            } else {
                notification.error({
                    message: t("message.upload_error"),
                });
                setIsLoadingImage(false);
            }
        });
    };

    const handleCancelForm = () => {
        form.resetFields();
        props?.onCancel();
        setImgData(null);
        setRoles([]);
        setFormValues({ step: "choose" });
        props?.setValues({ selectedGuide: null });
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleRemoveImg = () => {
        if (imgData?.id) {
            deleteFiles(imgData.id).then((res) => {
                setIsLoadingImage(true);
                if (res?.status) {
                    setImgData(null);
                    setIsLoadingImage(false);
                }
            });
        }
    };

    useEffect(() => {
        if (guide_id && props?.values?.isUpdating) {
            setFormLoading(true);
            findGuide(guide_id).then(({ status, data }) => {
                if (status) {
                    setFormLoading(false);
                    if (data?.parent_id) {
                        setFormValues({ step: "add_feature_desc" });
                        let newR = [];
                        if (data?.roles?.length > 0) {
                            newR = data.roles.map((item) => ({ label: t(`guide.${item}`), value: item }));
                        }
                        form.setFieldsValue({
                            name: data?.name || "",
                            title: data?.title,
                            description: data?.description,
                            type: data?.type,
                            order: data?.order,
                            roles: newR,
                        });
                        setImgData(data?.image);
                        data?.name ? setSelectedGuide(data.name) : null;
                    } else {
                        setFormValues({ step: "add_feature" });
                        let newR = [];
                        if (data?.roles?.length > 0) {
                            newR = data.roles.map((item) => ({ label: t(`guide.${item}`), value: item }));
                        }
                        form.setFieldsValue({
                            name: data?.name || "",
                            title: data?.title,
                            type: data?.type,
                            order: data?.order,
                            roles: newR,
                        });
                        setImgData(data?.image);
                        data?.name ? setSelectedGuide(data.name) : null;
                    }
                }
            });
        }
    }, [props?.values?.selectedGuide?.id]);

    const handleSelectRoles = (role) => {
        let newR = role.map((r) => ({ label: t(`guide.${r}`), value: r }));
        setRoles(newR);
    };

    const handleSelectPage = (page) => {
        if (page) {
            fetchRecommend(page).then(({ data }) => {
                setFormValues({
                    disable_order: false,
                    feature_order: data?.recommend_order || 0,
                });
                form.setFieldsValue({
                    order: data?.recommend_order || 0,
                });
            });
        }
    };

    const handleSelectFeature = (id, title) => {
        fetchRecommend("", id).then(({ data, status }) => {
            if (status) {
                const order = data?.recommend_order || 0;
                setFormValues({ parent_id: id, order: order, feature_title: title?.title });
                form.setFieldsValue({ order: order });
            }
        });
    };

    const handleSelectPageDesc = (value) => {
        if (value) {
            fetchGuides({ type: value, noPagination: true }).then((res) => {
                const { status, data } = res;
                if (status) {
                    setFormValues({ feature: data, disable_feature: false });
                }
            });
        }
    };

    return (
        <Modal
            visible={visible}
            className="guide-modal"
            title={t(`guide.help`)}
            onCancel={handleCancelForm}
            footer={null}
            bodyStyle={{ minHeight: 200 }}
        >
            <Spin spinning={formLoading}>
                <div className="guide-modal-wraper">
                    {formValues.step === "choose" ? (
                        // <---- thêm chức năng or thêm mô tả chức năng ----->
                        <Form form={form} className="guide-modal-form" {...layout}>
                            <div className="guide-btn-wrap" style={{ minHeight: 150 }}>
                                <CustomButton
                                    type="primary"
                                    title={t("guide.add_feature")}
                                    onClick={() => setFormValues({ step: "add_feature", modal_title: "add_feature" })}
                                ></CustomButton>
                                <CustomButton
                                    htmlType="submit"
                                    type="primary"
                                    title={t("guide.add_feature_desc")}
                                    onClick={() =>
                                        setFormValues({ step: "add_feature_desc", modal_title: "add_feature_desc" })
                                    }
                                ></CustomButton>
                            </div>
                        </Form>
                    ) : formValues.step === "add_feature" ? (
                        // <----- Thêm chức năng ------->
                        <Form form={form} className="guide-modal-form" {...layout} onFinish={handleSubmitAddFeature}>
                            <Form.Item
                                name="type"
                                label={t("guide.type")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select optionFilterProp="children" onChange={handleSelectPage}>
                                    {guideTypes?.map((opt) => {
                                        if (opt) {
                                            return (
                                                <Select.Option key={opt.id} value={opt.id}>
                                                    {t(`guide.${opt.id}`)}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="order"
                                label={t("guide.order")}
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item
                                name="roles"
                                label={t("guide.user")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    onChange={(r) => handleSelectRoles(r)}
                                    value={roles}
                                    placeholder={t("guide.select_user_role")}
                                    optionLabelProp="label"
                                >
                                    {roleOptions.map((item) => (
                                        <Select.Option key={item.value} value={item.value} label={item.label}>
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="title"
                                label={t("guide.title")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className="guide-btn-wrap">
                                <CustomButton
                                    type="grey"
                                    icon={<Icon name="icon-delete" />}
                                    title={t("action.cancel")}
                                    onClick={handleCancelForm}
                                ></CustomButton>
                                <CustomButton
                                    htmlType="submit"
                                    type="secondary"
                                    icon={<Icon name="icon-save" />}
                                    title={t("action.save")}
                                ></CustomButton>
                            </div>
                        </Form>
                    ) : (
                        //<-------- Thêm mô tả chức năng -------->
                        <Form
                            form={form}
                            className="guide-modal-form"
                            {...layout}
                            onFinish={handleSubmitAddFeatureDesc}
                        >
                            <Form.Item
                                name="type"
                                label={t("guide.type")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select optionFilterProp="children" onChange={handleSelectPageDesc}>
                                    {guideTypes?.map((opt) => {
                                        if (opt) {
                                            return (
                                                <Select.Option key={opt.id} value={opt.id}>
                                                    {t(`guide.${opt.id}`)}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="title"
                                label={t("guide.title")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select
                                    optionFilterProp="children"
                                    onChange={handleSelectFeature}

                                    // disabled={descFeatureValue.disable_feature}
                                >
                                    {formValues.feature?.map((opt) => {
                                        if (opt) {
                                            return (
                                                <Select.Option key={opt.id} value={opt.id} title={opt.title}>
                                                    {t(`${opt.title}`)}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="order"
                                label={t("guide.order")}
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item name="image" label={t("guide.image")} onChange={handleUploadImage}>
                                <Spin spinning={isLoadingImage}>
                                    {!imgData?.id ? (
                                        <Upload listType="picture-card" beforeUpload={() => false}>
                                            {uploadButton}
                                        </Upload>
                                    ) : (
                                        <Image width={100} src={imgData?.src} />
                                    )}

                                    {imgData && (
                                        <span className="img-delete-btn" onClick={handleRemoveImg}>
                                            <DeleteOutlined />
                                            <p style={{ marginBottom: 0, marginLeft: 5 }}>{t("guide.delete")}</p>
                                        </span>
                                    )}
                                </Spin>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={t("guide.description")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Editor />
                            </Form.Item>
                            <div className="guide-btn-wrap">
                                <CustomButton
                                    type="grey"
                                    icon={<Icon name="icon-delete" />}
                                    title={t("action.cancel")}
                                    onClick={handleCancelForm}
                                ></CustomButton>
                                <CustomButton
                                    htmlType="submit"
                                    type="secondary"
                                    icon={<Icon name="icon-save" />}
                                    title={t("action.save")}
                                ></CustomButton>
                            </div>
                        </Form>
                    )}
                </div>
            </Spin>
        </Modal>
    );
}

export default GuideModal;
