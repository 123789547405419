import React from "react";
import { Tooltip as AntTooltip } from "antd";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import "./Tooltip.scss";

function Tooltip({ children, className, style, type = "", placement, title, ...rest }) {
    const renderTooltipIconByType = () => {
        switch (type) {
            case "info":
                return <InfoCircleOutlined />;
            case "question":
                return <QuestionCircleOutlined />;
        }
    };

    return (
        <span className={className ? `custom-tooltip-wrapper ${className}` : "custom-tooltip-wrapper"} style={style}>
            <span className="custom-tooltip">
                <AntTooltip placement={placement} title={title} {...rest}>
                    {children ? children : renderTooltipIconByType()}
                </AntTooltip>
            </span>
        </span>
    );
}

export default Tooltip;
