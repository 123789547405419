export const pages = {
    entrance: {
        name: "entrance",
        routePath: "/entrance",
    },
    exam_checkin: {
        name: "exam_checkin",
        routePath: "/exam-checkin",
    },
    exam_checkin_system_check: {
        name: "system_check",
        routePath: "/exam-checkin",
    },
    exam_checkin_candidate_information: {
        name: "candidate_information",
        routePath: "/exam-checkin",
    },
    exam_checkin_identity_verification: {
        name: "identity_verification",
        routePath: "/exam-checkin",
    },
    exam_checkin_taking_exam: {
        name: "taking_exam",
        routePath: "/exam-checkin",
    },
    exam_asset: {
        name: "exam_asset",
        routePath: "/exam-asset",
    },
    exam_asset_reup: {
        name: "exam_asset_reup",
        routePath: "/upload",
    },
    test: {
        name: "test",
        routePath: "/test/:id",
    },
};
