import { Col, Row, Space } from "antd";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AudioIconPlay } from "src/assets/images/media/audio-speaker-high.svg";
import { ReactComponent as AudioIcon } from "src/assets/images/media/audio-speaker-medium.svg";
import "./ComputerAnswer.scss";

const ComputerAnswer = ({
    index,
    src,
    onClick,
    isPlaying,
    active,
    value,
    actor,
    onSpeakerEnd,
    speak,
    callSpeakRef,
    showGoBtn,
    handleGoContinue,
}) => {
    const audioRef = useRef(); // create a reference to the audio element

    const { t } = useTranslation();

    // if (callSpeakRef) {
    //     callSpeakRef.currenst = handleClick;
    // }

    const onEnd = () => {
        if (onSpeakerEnd instanceof Function) {
            onSpeakerEnd();
        }
    };

    useEffect(() => {
        callSpeakRef.current[index] = audioRef;
    }, []);

    useEffect(() => {
        if (!audioRef.current?.paused) {
            if (!isPlaying) {
                audioRef.current?.pause();
                audioRef.current.currentTime = 0;
            }
        }
    }, [isPlaying]);

    return (
        <div className={clsx({ "sptt-conversation_computer-answer": true, audio: true, active: active })}>
            {/* <span className="computer-answer_inner"> */}
            <Row gutter={[20, 20]}>
                <Col xs={3} sm={4} className="label-col">
                    <span className="label">{actor}:</span>
                </Col>

                <Col xs={21} sm={20}>
                    <div className="computer-answer_wrapper">
                        <div
                            data-index={index}
                            onClick={(e) => {
                                onClick(e);
                            }}
                        >
                            <audio data-index={index} ref={audioRef} src={value} onEnded={onEnd} />
                            <Space className="computer-answer_inner">
                                <span className={clsx({ speaker: true, play: isPlaying })}>
                                    <span>{isPlaying ? <AudioIconPlay /> : <AudioIcon />}</span>
                                </span>
                                {/* <p className="transcript">{sentence}</p> */}
                            </Space>
                        </div>
                        {showGoBtn && (
                            <span className="btn-continue" onClick={handleGoContinue}>
                                <span>{t("shared.go")}</span>
                            </span>
                        )}
                    </div>
                </Col>
            </Row>
            {/* </span> */}
        </div>
    );
};

export default ComputerAnswer;
