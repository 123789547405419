import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getMe } from "src/api/containers/auth";
import { checkAfterPurchasePlan } from "src/api/containers/plan";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { default as CustomButton } from "src/modules/components/Button";
import { login } from "src/reducers/auth";
import { deleteCookie } from "src/utils/helpers";

import "./PaymentResult.scss";

const PaymentResult = ({ urlParams, showPaymentInfo = true, clearURLSearchParams, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false); // false, "result".
    const [data, setData] = useState({
        paymentResult: undefined,
        paymentStatus: undefined,
    });

    const updateMyProfile = () => {
        getMe(user?.paramsForOrgOrTeam?.organization_id).then((res) => {
            if (res?.status === true && res?.data?.user) {
                dispatch(login(res.data.user));
            } else {
                localStorage.removeItem("token");
                deleteCookie("token");
            }
        });
    };

    useEffect(() => {
        if (typeof urlParams === "string" || Object.keys(urlParams).length) {
            setLoading("result");
            checkAfterPurchasePlan(urlParams).then((res) => {
                if (res.status) {
                    setData({
                        ...data,
                        paymentResult: res.data,
                        paymentStatus: "finished",
                    });
                } else {
                    setData({
                        ...data,
                        paymentResult: res.data,
                        paymentStatus: "unfinished",
                    });
                }
                setLoading(false);
                updateMyProfile();
                if (clearURLSearchParams instanceof Function) {
                    clearURLSearchParams();
                }
            });
        }
    }, [urlParams]);

    const renderSatus = () => {
        if (data.paymentStatus === "finished") {
            return (
                <div className="status-wrapper">
                    <span className="status-icon">
                        <SvgTick fill="forestgreen" />
                    </span>
                    <span className="status-message">{t("payment.sucess_make_payment_transaction")}!</span>
                </div>
            );
        } else if (data.paymentStatus === "unfinished") {
            return (
                <div className="status-wrapper">
                    <span className="status-icon">
                        <SvgCross fill="orangered" />
                    </span>
                    <span className="status-message">{t("payment.fail_make_payment_transaction")}!</span>
                </div>
            );
        }
    };

    return (
        <div className="payment-result-panel" {...rest}>
            {loading === "result" ? (
                <>
                    <div className="result-header">
                        <div className="status-wrapper skeleton"></div>
                    </div>
                    <div className="result-body">
                        <div className="body-section skeleton"></div>
                        <div className="body-section skeleton"></div>
                    </div>
                </>
            ) : (
                <>
                    <div className="result-header">{renderSatus()}</div>
                    <div className="result-body">
                        {data.paymentResult ? (
                            <>
                                <div className="body-section order-items-info">
                                    {data.paymentResult?.plan ? (
                                        <Row>
                                            <Col xs={24} sm={24} md={8}>
                                                {t("plan.plan_name")}:
                                            </Col>
                                            <Col xs={24} sm={24} md={16}>
                                                {data.paymentResult.plan.name || "_"}
                                            </Col>
                                        </Row>
                                    ) : null}
                                </div>
                                {showPaymentInfo ? (
                                    <div className="body-section payment-info">
                                        {data.paymentResult?.bill ? (
                                            <Row>
                                                <Col xs={24} sm={24} md={8}>
                                                    {t("price.amount")}:
                                                </Col>
                                                <Col xs={24} sm={24} md={16} className="price">
                                                    <span>
                                                        {(data.paymentResult.bill.amount || 0).toLocaleString() || "_"}
                                                    </span>
                                                    <span>{t("price.vnd")}</span>
                                                </Col>

                                                <Col xs={24} sm={24} md={8}>
                                                    {t("price.total")}:
                                                </Col>
                                                <Col xs={24} sm={24} md={16} className="price">
                                                    <span>
                                                        {(data.paymentResult.bill.total || 0).toLocaleString() || "_"}
                                                    </span>
                                                    <span>{t("price.vnd")}</span>
                                                </Col>

                                                <Col xs={24} sm={24} md={8}>
                                                    {t("price.paid")}:
                                                </Col>
                                                <Col xs={24} sm={24} md={16} className="price">
                                                    <span>
                                                        {(data.paymentResult.bill.amount_paid || 0).toLocaleString() ||
                                                            "_"}
                                                    </span>
                                                    <span>{t("price.vnd")}</span>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </>
            )}
            <div className="result-footer">
                <CustomButton
                    type="primary"
                    title={t("html_status_code.action_go_back_home")}
                    linkTo="/"
                    disabled={["result"].includes(loading)}
                    className="btn-gohome"
                ></CustomButton>
            </div>
        </div>
    );
};

export default PaymentResult;
