import { UserOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Spin, Upload, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as faceapi from "face-api.js";
import { t } from "i18next";
import React, { useContext, useEffect } from "react";

import { checkCandidateInformation } from "src/api/containers/examCheckin";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";

import CandidateAvatar from "../CandidateAvatar";
import Captcha from "../Captcha";
import EmailVerify from "../EmailVerify";
import UploadAvatar from "../UploadAvatar";
import "./CandidateInformation.scss";

function CandidateInformation({ value, onChange }) {
    const proctoring = useContext(ProctoringContext);
    const idAssignment = proctoring?.examCheckInInfo?.assignment?.id;
    const assignmentInfo = proctoring?.examCheckInInfo?.assignment;

    const [form] = useForm();

    const [tempUserInfo, setTempUserInfo] = useValues({
        email: "",
        username: "",
        personal_number: "",
        avatar: "",
        avatar_base64: "",
        code_verify: "",
        detectFaceSpoofing: null,
        isFaceDetected: false,
        isMultipleFacesDetected: false,
        countFacesDetected: 0,
        hasPhotoUploaded: false,
    });
    const [values, setValues] = useValues({
        loading: false,
        accessStatus: false,
    });

    const handleSubmit = () => {
        let formData = form.getFieldsValue();

        if (tempUserInfo.hasPhotoUploaded) {
            // nếu có up ảnh thì mới check

            if (
                tempUserInfo.detectFaceSpoofing?.result === false ||
                tempUserInfo.countFacesDetected === false ||
                tempUserInfo.countFacesDetected !== 1
            ) {
                notification.error({ message: t("your_photo_not_valid") });
                return;
            }

            if (!formData.name || !formData.email || !formData.human_verification || !formData.avatar) {
                return;
            }
        }

        const _formData = {
            name: formData.name,
            username: formData.email,
            personal_number: formData.personal_number,
            device_id: idAssignment,
            // case user update ảnh mới, avatar: sẽ có 2 dạng 1 là file binary nếu user up ảnh mới, còn ko thì sẽ là src string
            file0:
                typeof formData.avatar === "string" && formData.avatar?.includes("http") ? undefined : formData.avatar,
            code_verify: +tempUserInfo.code_verify,
        };

        const fmData = new FormData();
        for (const key in _formData) {
            if (_formData.hasOwnProperty(key) && _formData[key] !== undefined) {
                fmData.append(key, _formData[key]);
            }
        }

        setValues({ loading: true });
        checkCandidateInformation(fmData).then((res) => {
            if (res.status) {
                setValues({
                    loading: false,
                    accessStatus: true,
                });
                setTimeout(() => {
                    onChange?.({
                        token: res.token,
                    });
                }, 500);
            } else {
                setValues({ loading: false });
                notification.error({
                    message: res.message || t("message.sth_wrong"),
                });
            }
        });
    };

    useEffect(() => {
        if (value) {
            form.setFieldsValue({
                name: value.name,
                email: value.username,
                personal_number: value.personal_number,
            });
        } else {
            form.setFieldsValue({
                name: "",
                email: "",
                personal_number: "",
            });
        }
    }, [value]);

    async function faceDetection(img_element_id) {
        if (!img_element_id) return false;
        const imageElement = document.getElementById(img_element_id);
        if (imageElement) return false;

        const faceDescriptor = await faceapi.detectSingleFace(imageElement).withFaceLandmarks().withFaceDescriptor();
        const result = !!faceDescriptor;

        setTempUserInfo({ isFaceDetected: result });

        return result;
    }

    return (
        <div className="candidate-information">
            {!tempUserInfo.code_verify ? (
                <EmailVerify
                    setTempUserInfo={({ avatar, code_verify, email, personal_number }) =>
                        setTempUserInfo({ avatar, code_verify, email, personal_number })
                    }
                    setFormData={(data) => form.setFieldsValue(data)}
                />
            ) : (
                <>
                    <div className="candidate-info-title">
                        <div className="title-icon">
                            <UserOutlined />
                        </div>
                        <div className="title-content">
                            <div className="content-main">{t("exam_checkin.candidate_information")}</div>
                        </div>
                    </div>
                    <div className="candidate-info-form">
                        <Spin spinning={values.loading}>
                            <Form
                                form={form}
                                layout="vertical"
                                className="app-form type-primary has-rd"
                                onFinish={handleSubmit}
                            >
                                <Row gutter={[40, 8]}>
                                    <Col span={15}>
                                        <Form.Item
                                            label={`${t("email")}`}
                                            name="email"
                                            rules={[
                                                { required: true, message: t("shared.input_email") },
                                                {
                                                    type: "email",
                                                    message: t("message.invalid_email"),
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="email"
                                                className="app-input has-rd"
                                                placeholder={t("email")}
                                                maxLength={100}
                                                readOnly={values.accessStatus || values.loading}
                                                disabled
                                                value={tempUserInfo.email}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={`${t("shared.fullname")}`}
                                            name="name"
                                            rules={[{ required: true, message: t("shared.input_fullname") }]}
                                        >
                                            <Input
                                                className="app-input has-rd"
                                                placeholder={t("shared.fullname")}
                                                maxLength={100}
                                                readOnly={values.accessStatus || values.loading}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={`${t("exam_checkin.candidate_number")}`}
                                            name="personal_number"
                                            // rules={[{ required: true, message: t("exam_checkin.input_candidate_number") }]}
                                        >
                                            <Input
                                                className="app-input has-rd"
                                                placeholder={t("exam_checkin.candidate_number")}
                                                maxLength={100}
                                                readOnly={values.accessStatus || values.loading}
                                                disabled={assignmentInfo?.is_public === false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item
                                            label={`${t("exam_checkin.avatar_upload")}`}
                                            name="avatar"
                                            rules={[{ required: true, message: t("message.required") }]}
                                            // className="form-item-avatar"
                                        >
                                            {/* <UploadAvatar
                                                getAvatarBase64={(url) => setTempUserInfo({ avatar_base64: url })}
                                                faceDetection={faceDetection}
                                                getDetectFaceSpoofingData={(data) =>
                                                    setTempUserInfo({ detectFaceSpoofing: data })
                                                }
                                                getCheckFacesResult={(data) => setTempUserInfo({ ...data })}
                                                initAvtURL={
                                                    tempUserInfo.avatar && tempUserInfo.avatar.includes("http")
                                                        ? tempUserInfo.avatar
                                                        : ""
                                                }
                                                setHasPhotoUploaded={(state) =>
                                                    setTempUserInfo({ hasPhotoUploaded: state })
                                                }
                                            /> */}
                                            <CandidateAvatar
                                                getAvatarBase64={(url) => setTempUserInfo({ avatar_base64: url })}
                                                faceDetection={faceDetection}
                                                getDetectFaceSpoofingData={(data) =>
                                                    setTempUserInfo({ detectFaceSpoofing: data })
                                                }
                                                getCheckFacesResult={(data) => setTempUserInfo({ ...data })}
                                                avtUrl={
                                                    tempUserInfo.avatar_base64 ||
                                                    (tempUserInfo?.avatar?.includes("http") ? tempUserInfo.avatar : "")
                                                }
                                                setHasPhotoUploaded={(state) =>
                                                    setTempUserInfo({ hasPhotoUploaded: state })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col className="human-verification" span={24}>
                                        <Form.Item
                                            // label={`${t("exam_checkin.human_verification")}`}
                                            name="human_verification"
                                            rules={[
                                                { required: true, message: t("exam_checkin.input_human_verification") },
                                            ]}
                                        >
                                            <Captcha visible={true} readOnly={values.accessStatus || values.loading} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div className="form-actions">
                                    <CustomButton
                                        htmlType="submit"
                                        type="primary"
                                        icon={<SvgTick />}
                                        title={
                                            values.accessStatus ? `${t("shared.redirecting")}...` : t("shared.confirm")
                                        }
                                        isLoading={values.accessStatus || values.loading}
                                    ></CustomButton>
                                </div>
                            </Form>
                        </Spin>
                    </div>
                </>
            )}
        </div>
    );
}

export default CandidateInformation;
