import React, { useLayoutEffect, useRef } from "react";
import { insertExprStringIntoHtmlElement, insertHtmlStringIntoHtmlElement } from "src/utils/helpers/webAPIs";

function HTMLDisplayer({ htmlString, exprString, className, rootType = "block", ...rest }) {
    const RootTagName = rootType === "block" ? "div" : "span";
    const refDisplayer = useRef(null);

    useLayoutEffect(() => {
        if (htmlString) {
            insertHtmlStringIntoHtmlElement(refDisplayer.current, htmlString);
        } else if (exprString) {
            insertExprStringIntoHtmlElement(refDisplayer.current, exprString);
        }
    }, [htmlString, exprString]);

    return (
        <RootTagName ref={refDisplayer} className={`displayer html-displayer${className ? " " + className : ""}`}>
            {/* Do nothing here! */}
        </RootTagName>
    );
}

export default HTMLDisplayer;
