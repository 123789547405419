import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { notification } from "antd";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { countUserViolations } from "src/api/containers/record";
import configs from "src/configs";
import { useValues } from "src/hooks";
import { useAuth } from "src/modules/auth";
import DevtoolCheck from "src/modules/components/DevtoolCheck";
import { pages } from "src/routes/pages";
import { useRouting } from "src/utils/router";

import "./ExamProctoring.scss";
import MicroConnCheck from "./components/MicroConnCheck";
import MultipleDisplaysCheck from "./components/MultipleDisplaysCheck";
import ScreenCamMicRecorder from "./components/ScreenCamMicRecorder";
import ScreenSizeCheck from "./components/ScreenSizeCheck";
import WebcamConnCheck from "./components/WebcamConnCheck";
import { checkProctoringReportForCurrentPage } from "./helpers";

const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;

export const ProctoringContext = createContext();

const ExamProctoring = (props) => {
    const { children } = props;

    const navigate = useNavigate();
    const { generate } = useRouting();

    const auth = useAuth();

    const currentPage = useSelector((state) => state.general.page);

    const [values, setValues] = useValues({
        isFreezed: false,
        isPassedScreenSizeCheck: undefined, // Values: undefined, true, false.
        isPassedDisplaysCheck: undefined, // Values: undefined, true, false.
        isPassedMicroResetCheck: undefined, // Values: undefined, true, false.
        isPassedCameraResetCheck: undefined, // Values: undefined, true, false.
    });
    const [displaysCheck, setDisplaysCheck] = useValues({
        screenDetails: null,
        screenCount: 1,
    });
    const [examAccessCode, setExamAccessCode] = useState("");
    const [examCheckInInfo, setExamCheckInInfo] = useState({
        assignment: {},
    });
    const [examRecordData, setExamRecordData] = useState({
        loading: false,
        status: false,
        data: {},
    });

    const isStudent = auth.user?.role === "student";
    const isGuestAsStudent = auth.role_picked_by_guest === "student";

    const isWithMicroConnCheck = ![
        pages.entrance.name,
        pages.exam_checkin.name,
        pages.exam_checkin_system_check.name,
    ].includes(currentPage);
    const isWithWebcamConnCheck = ![
        pages.entrance.name,
        pages.exam_checkin.name,
        pages.exam_checkin_system_check.name,
    ].includes(currentPage);
    const isWithMultipleDisplaysCheck =
        examCheckInInfo.assignment?.options?.extend_monitor &&
        ![pages.entrance.name, pages.exam_checkin.name, pages.exam_checkin_system_check.name].includes(currentPage);

    const handleToggleFrozen = (boolean) => {
        setValues({ isFreezed: boolean });
    };

    const handleReportCheating = (key) => {
        const _pageInfo = checkProctoringReportForCurrentPage();
        if (_pageInfo.page === pages.test.name && _pageInfo.id && examRecordData.data.record_id) {
            countUserViolations(examRecordData.data.record_id, key).then(({ status, warning, is_load }) => {
                if (is_load) {
                    navigate(generate(pages.exam_asset.name));
                }
                if (warning) {
                    notification.warning({ message: warning });
                }
            });
        }
    };
    const handleReportCheatingRef = useRef(null);
    handleReportCheatingRef.current = handleReportCheating;

    const handleStopRecording = () => {
        handleReportCheatingRef.current(ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE);
    };

    handleStopRecording.current = () => {
        handleReportCheating(ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE);
    };

    const handleToggleScreenSizeCheck = (boolean, why) => {
        setValues({ isPassedScreenSizeCheck: !boolean });
    };

    const handleToggleMicroResetCheck = (boolean, why) => {
        setValues({ isPassedMicroResetCheck: !boolean });
        switch (why) {
            case "NotAllowedError: Permission dismissed":
            case "NotAllowedError: Permission denied": {
                handleReportCheating(ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_MICROPHONE);
                break;
            }
            default:
                break;
        }
    };

    const handleToggleCameraResetCheck = (boolean, why) => {
        setValues({ isPassedCameraResetCheck: !boolean });
        switch (why) {
            case "NotAllowedError: Permission dismissed":
            case "NotAllowedError: Permission denied": {
                handleReportCheating(ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_CAMERA);
                break;
            }
            default:
                break;
        }
    };

    const handleToggleDisplaysCheck = (boolean, why) => {
        setValues({ isPassedDisplaysCheck: !boolean });
        switch (why) {
            case "invalid_screen_count": {
                handleReportCheating(ANTI_CHEATING_KEYS.EXTEND_MONITOR);
                break;
            }
            case "NotAllowedError: Permission dismissed.":
            case "NotAllowedError: Permission denied.": {
                handleReportCheating(ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_EXTEND_MONITOR);
                break;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        // We have to use "=== false", not use "!".
        if (
            values.isPassedScreenSizeCheck === false ||
            values.isPassedDisplaysCheck === false ||
            values.isPassedMicroResetCheck === false ||
            values.isPassedCameraResetCheck === false
        ) {
            handleToggleFrozen(true);
        } else {
            handleToggleFrozen(false);
        }
    }, [
        values.isPassedDisplaysCheck,
        values.isPassedScreenSizeCheck,
        values.isPassedMicroResetCheck,
        values.isPassedCameraResetCheck,
    ]);

    if (isStudent || isGuestAsStudent) {
        return (
            <ProctoringContext.Provider
                value={{
                    // Proctoring:
                    isFreezed: values.isFreezed,
                    displaysCheck,
                    setDisplaysCheck,
                    // Exam data:
                    examAccessCode,
                    examCheckInInfo,
                    examRecordData,
                    setExamAccessCode,
                    setExamCheckInInfo,
                    setExamRecordData,
                }}
            >
                {/* Proctoring - Recording: */}
                <ScreenCamMicRecorder
                    mediaNamePrefix="exam-proctoring-video"
                    withCamera={examCheckInInfo.assignment?.options?.record_camera || false}
                    withMicrophone={examCheckInInfo.assignment?.options?.record_microphone || false}
                    withSystemAudio={examCheckInInfo.assignment?.options?.record_system_audio || false}
                    onStop={handleStopRecording}
                >
                    <div className="exam-proctoring">
                        <div className={`page-content${values.isFreezed ? " freezed" : ""}`}>{children}</div>

                        {/* Proctoring - Screen size check (runs everywhere): */}
                        <ScreenSizeCheck onVisibleChange={handleToggleScreenSizeCheck} />

                        {/* Proctoring - Microphone permission check (runs after pages.exam_checkin_system_check): */}
                        {isWithMicroConnCheck ? <MicroConnCheck onVisibleChange={handleToggleMicroResetCheck} /> : null}

                        {/* Proctoring - Webcam permission check (runs after pages.exam_checkin_system_check): */}
                        {isWithWebcamConnCheck ? (
                            <WebcamConnCheck onVisibleChange={handleToggleCameraResetCheck} />
                        ) : null}

                        {/* Proctoring - Screen size check (runs after pages.exam_checkin_system_check): */}
                        {isWithMultipleDisplaysCheck ? (
                            <MultipleDisplaysCheck onVisibleChange={handleToggleDisplaysCheck} />
                        ) : null}

                        {/* 3. Devtool check */}

                        <DevtoolCheck
                            isTestLayout={currentPage === pages.test.name}
                            recordId={examRecordData.record_id}
                        />
                    </div>
                </ScreenCamMicRecorder>
            </ProctoringContext.Provider>
        );
    }

    return children;
};

export default ExamProctoring;
