import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth } from "src/modules/auth";
import { setCurrentPage } from "src/reducers/general";
import { pages } from "src/routes/pages";

import "./Entrance.scss";
import RolePicker from "./components/RolePicker";

function Entrance() {
    const auth = useAuth();

    const lang = useSelector((state) => state.general.lang);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleChangeRole = (role) => {
        auth.pickRoleByGuest(role);

        switch (role) {
            case "student": {
                navigate(`/${lang}/exam-checkin`);
                break;
            }
            case "teacher": {
                navigate(`/${lang}/login`);
                break;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        auth.pickRoleByGuest("");
        dispatch(setCurrentPage(pages.entrance.name));
    }, []);

    return (
        <div className="entrance">
            <RolePicker onChange={handleChangeRole} />
        </div>
    );
}

export default Entrance;
