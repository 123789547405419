import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CardTableContext } from "../CardTable";
import { cardFieldSettings } from "../settings";
import "./CardSubscriptionPlan.scss";

const CardTitle = ({ className = "", ...rest }) => {
    const { t } = useTranslation();

    const table = useContext(CardTableContext);

    // Generate ClassNames:
    const currClassNames = ["subscription-plan-card", "as-title"];
    if (className) {
        currClassNames.push(className);
    }

    const renderBodyContent = () => {
        return (
            <ul id="plan-list" className="content-list">
                {Object.keys(cardFieldSettings).map((k, i) => {
                    const height = table?.cardInfo?.title?.[k]?.height;
                    const style = {};
                    height && (style.height = height);
                    return (
                        <li key={`content-${i}`} id={`plan-listfield-${k}`} style={style}>
                            <span>{t(cardFieldSettings[k].name)}</span>
                        </li>
                    );
                })}
            </ul>
        );
    };

    useEffect(() => {
        const fieldsInfo = {};
        Object.keys(cardFieldSettings).forEach((k, i) => {
            fieldsInfo[k] = {};
            // Actual height:
            const elemListItem = document.getElementById(`plan-listfield-${k}`);
            fieldsInfo[k].actualHeight = elemListItem.offsetHeight;
        });

        if (table?.setCardInfo instanceof Function) {
            table?.setCardInfo("title", fieldsInfo);
        }
    }, []);

    return (
        <div className={currClassNames.join(" ")} {...rest}>
            <div className={`card-subsc-plan`}>
                <div className="card-wrapper">
                    <div className="card-content">
                        <div className="card-header">
                            <div className="header-title">{t("payment.card_field.price_table")}</div>
                            <div className="header-title-desc">{t("payment.card_field.price_table_desc")}</div>
                        </div>
                        <div className="card-body">{renderBodyContent()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardTitle;
