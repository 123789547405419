import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";

import StaticMath from "src/modules/components/StaticMath";

import { Buttons } from "../Editor/editor/plugins/math/buttons";
import "./MathAnswer.scss";

const DEFAULT_KEY_BOARD = {
    visible: false,
    position: null,
};

const MathAnswer = (props) => {
    const { template_latex: templateStr = "", value, onChange, isReadonly } = props;
    const staticRef = useRef(null);
    const StaticMathRef = useRef(null);
    const currentField = useRef(null);
    const keyboardRef = useRef(null);
    const evt = useRef(null);
    const [keyboard, setKeyboard] = useState(DEFAULT_KEY_BOARD);

    const templateAnswer = useMemo(() => {
        const template = templateStr.replaceAll("\\embed{response}", "\\MathQuillMathField{}");

        return template;
    }, [templateStr]);

    const MQ = useMemo(() => {
        if (window.MathQuill) {
            return window.MathQuill.getInterface(2);
        }
    }, []);

    const getIndex = (el) => parseInt(el.id.replace("inner-", ""), 10);

    const handleClickOutside = useCallback((e) => {
        if (e.target.nodeName === "IMG" || e.target.nodeName === "BUTTON") {
            return;
        }

        if (StaticMathRef.current && !keyboardRef.current.contains(e.target)) {
            currentField.current = null;
            setKeyboard(DEFAULT_KEY_BOARD);
            document.removeEventListener("click", handleClickOutside, false);
        }
    }, []);

    const handleFocusInner = (innerField, e) => {
        innerField.el().querySelector("textarea").focus();
        staticRef.current?.__controller?.cursor?.hide();
        setKeyboard({
            visible: true,
            position: {
                clientX: e.clientX,
                clientY: e.clientY,
            },
        });
        evt.current = {
            clientX: e.clientX,
            clientY: e.clientY,
            target: e.target,
        };

        document.removeEventListener("click", handleClickOutside);
        const index = getIndex(innerField.el());
        currentField.current = index;
        setTimeout(() => {
            document.addEventListener("click", handleClickOutside);
        });
    };

    const goTo = (innerFieldIdx) => {
        if (!staticRef.current) {
            return;
        }
        const { innerFields = [] } = staticRef.current;
        const nextField = innerFields[innerFieldIdx];
        if (nextField) {
            nextField.focus().el().click();
        }
    };

    const handleBlur = useCallback(() => {
        staticRef.current?.__controller?.cursor?.hide();
    }, []);

    const handleClickMath = (opt, keystrokes) => {
        if (staticRef.current) {
            const { innerFields = [] } = staticRef.current;

            if (currentField.current !== null) {
                const index = currentField.current;
                const innerField = innerFields[index];
                innerField.write(opt);

                innerField?.keystroke(keystrokes?.join(" ") || "");

                if (innerField.__controller.cursor[1]) {
                    innerField.clickAt(evt.current.clientX, evt.current.clientY, evt.current.target);
                } else {
                    const textarea = innerField.el().querySelector(".mq-textarea textarea");
                    textarea.focus();
                }
            }
        }

        if (onChange && onChange instanceof Function) {
            const result = [];

            staticRef.current.innerFields.forEach((field, index) => {
                result.push(field.latex());
            });

            onChange(result);
        }
    };

    useEffect(() => {
        if (staticRef.current) {
            staticRef.current.latex(templateAnswer);
        }
    }, [templateAnswer]);

    useEffect(() => {
        if (staticRef.current) {
            staticRef.current.innerFields.forEach((field, index) => {
                const isCanWrite = value?.[index] && !field.latex();

                if (isCanWrite) {
                    field.latex(value?.[index] || "");
                }
            });
        }
    }, [value]);

    useEffect(() => {
        const amountInput = templateAnswer.match(/\MathQuillMathField{}/g)?.length || 0;
        const result = [];

        staticRef.current.innerFields.forEach((field, index) => {
            if (index <= amountInput) {
                result.push(value?.[index] || "");
            }

            field.el().id = `inner-${index}`;

            field.el().addEventListener("click", (e) => {
                handleBlur();
                handleFocusInner(field, e);
            });

            field.config({
                handlers: {
                    upOutOf(pInnerField) {
                        goTo(getIndex(pInnerField.el()) - 1);
                    },
                    downOutOf(pInnerField) {
                        goTo(getIndex(pInnerField.el()) + 1);
                    },
                    moveOutOf: (dir, pInnerField) => {
                        if (dir === MQ?.L) {
                            goTo(getIndex(pInnerField.el()) - 1);
                        } else if (dir === MQ?.R) {
                            goTo(getIndex(pInnerField.el()) + 1);
                        }
                    },
                },
            });

            field.latex("");
            const textarea = field.el().querySelector(".mq-textarea textarea");
            textarea.addEventListener("blur", handleBlur, false);
        });
        if (onChange && onChange instanceof Function) {
            value && onChange(result);
        }
    }, [templateAnswer]);

    useEffect(() => {
        staticRef.current.el().addEventListener("keydown", function () {
            setTimeout(() => {
                var currentValue = staticRef.current.innerFields;
                const result = [];
                currentValue.forEach((field) => {
                    result.push(field.latex());
                });

                if (onChange && onChange instanceof Function) {
                    onChange(result);
                }
            });
        });
    }, [staticRef]);

    useEffect(() => {
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <>
            <StaticMath ref={StaticMathRef} isReadonly={isReadonly} staticMathRef={staticRef} />

            <Draggable defaultPosition={{ x: 0, y: 0 }}>
                <div ref={keyboardRef} className={clsx("math-box", keyboard.visible && "math-box--visible")}>
                    <Buttons onClickOption={handleClickMath} />
                </div>
            </Draggable>
        </>
    );
};

export default React.memo(MathAnswer);
