import { ReactComponent as SvgTick } from "src/assets/images/payment/tick.svg";
import { getStorageValueWithUnitFromMB } from "src/utils/helpers/unit";

export const cardFieldSettings = {
    1: {
        field: "ai_function",
        name: "payment.card_field.ai_function",
        renderField: (t, value) => {
            if (value) {
                return <SvgTick />;
            }
            return "";
        },
    },
    2: {
        field: "ai_credit",
        name: "payment.card_field.ai_credit",
        renderField: (t, value) => {
            if (value === "contact") {
                return t("payment.suffix.contact");
            }
            return value;
        },
    },
    3: {
        field: "storage_limit",
        name: "payment.card_field.storage_available",
        renderField: (t, value) => {
            if (value === "contact") {
                return t("payment.suffix.contact");
            }
            return !isNaN(value) ? getStorageValueWithUnitFromMB(value) : value;
        },
    },
    4: {
        field: "exam_limit",
        name: "payment.card_field.limitof_asgmt_created",
        renderField: (t, value) => {
            if (!isNaN(value)) {
                const suffix = t("payment.suffix.exams");
                return `${value} ${suffix.toLowerCase()}`;
            }
            if (value === "unlimited") {
                return t("payment.suffix.unlimited");
            }
            return value;
        },
    },
    5: {
        field: "question_limit",
        name: "payment.card_field.limitof_q_created",
        renderField: (t, value) => {
            if (!isNaN(value)) {
                const suffix = t("payment.suffix.questions");
                return `${value} ${suffix.toLowerCase()}`;
            }
            if (value === "unlimited") {
                return t("payment.suffix.unlimited");
            }
            return value;
        },
    },
    6: {
        field: "questions_per_exam_limit",
        name: "payment.card_field.limitof_q_in_exam",
        renderField: (t, value) => {
            if (!isNaN(value)) {
                const suffix = t("payment.suffix.questions");
                return `${value} ${suffix.toLowerCase()}`;
            }
            if (value === "unlimited") {
                return t("payment.suffix.unlimited");
            }
            return value;
        },
    },
    7: {
        field: "submissions_per_assignment_limit",
        name: "payment.card_field.limitof_candidates",
        renderField: (t, value) => {
            if (!isNaN(value)) {
                const suffix = t("payment.suffix.candidates");
                return `${value} ${suffix.toLowerCase()}`;
            }
            if (value === "unlimited") {
                return t("payment.suffix.unlimited");
            }
            return value;
        },
    },
    8: {
        field: "usage_per_exam_limit",
        name: "payment.card_field.limitof_tests_created_from_an_asgmt",
        renderField: (t, value) => {
            if (!isNaN(value)) {
                const suffix = t("payment.suffix.tests_created_from_an_asgmt");
                return `${value} ${suffix.toLowerCase()}`;
            }
            if (value === "unlimited") {
                return t("payment.suffix.unlimited");
            }
            return value;
        },
    },
    9: {
        field: "employee_account_limit",
        name: "payment.card_field.limitof_employees",
        renderField: (t, value) => {
            if (!isNaN(value)) {
                return value;
            }
            return value;
        },
    },
    10: {
        field: "freeup_storage",
        name: "payment.card_field.cleanup",
        renderField: (t, value) => {
            if (value === "user_custom") {
                return t("payment.suffix.user_custom");
            }
            if (value === "auto_30_days") {
                return t("payment.suffix.auto_30_days");
            }
            return value;
        },
    },
};

export const getCardInfoByField = (field) => {
    const fieldNumberList = Object.keys(cardFieldSettings);
    for (let i = 0; i < fieldNumberList.length; i++) {
        const fNumber = fieldNumberList[i];
        const fField = cardFieldSettings[fNumber].field;
        if (field === fField) {
            return cardFieldSettings[fNumber];
        }
    }
    return undefined;
};
