import { Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";

import "./PaymentOrderPanel.scss";

const PaymentOrderPanel = ({
    isLoading,
    orderItems,
    amountUsual,
    amountCurrent,
    showActions = true,
    disableActions = false,
    ...rest
}) => {
    const { t } = useTranslation();

    const checkIsPrice = (val) => {
        return !!val || !isNaN(val);
    };

    return (
        <div className="payment-order-panel" {...rest}>
            <div className="order-header">
                <span className="order-title">{t("payment.order")}</span>
                {showActions ? (
                    <span className="order-actions">
                        <CustomButton
                            type="simple"
                            icon={<Icon name="icon-edit" />}
                            title={t("shared.edit")}
                            onClick={() => {}}
                            disabled={isLoading === true || disableActions === true}
                        ></CustomButton>
                    </span>
                ) : null}
            </div>
            <div className="order-body">
                {isLoading ? (
                    <div className="order-msg">
                        <Spin spinning={isLoading} />
                        <span> {t("shared.loading")}...</span>
                    </div>
                ) : null}
                {orderItems?.length
                    ? orderItems.map((item, i) => {
                          return (
                              <span className="order-item" key={`order-item-${i}`}>
                                  <span className="order-item-name">{item.name}</span>
                                  <span className="order-item-price">
                                      <span className="current-price">
                                          <span>{item.priceCurrent ? item.priceCurrent.toLocaleString() : "_"}</span>
                                          <span>{t("price.vnd")}</span>
                                      </span>
                                      {checkIsPrice(item.priceUsual) ? (
                                          <span className="usual-price">
                                              <span>{item.priceUsual.toLocaleString()}</span>
                                              <span>{t("price.vnd")}</span>
                                          </span>
                                      ) : null}
                                  </span>
                              </span>
                          );
                      })
                    : null}
            </div>
            <div className="order-footer">
                <span className="order-amount-title">{t("price.total")}</span>
                <span className="order-amount-price">
                    {checkIsPrice(amountCurrent) ? (
                        <span className="current-price order-amount-value">
                            <span>{amountCurrent.toLocaleString()}</span>
                            <span>{t("price.vnd")}</span>
                        </span>
                    ) : null}
                    {checkIsPrice(amountUsual) ? (
                        <span className="usual-price">
                            <span>{amountUsual.toLocaleString()}</span>
                            <span>{t("price.vnd")}</span>
                        </span>
                    ) : null}
                </span>
            </div>
        </div>
    );
};

export default PaymentOrderPanel;
