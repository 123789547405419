import { useRef, useState } from "react";
import { t } from "i18next";
import { Input } from "antd";
// import InputEditor from "src/modules/components/InputEditor";
import QuestionContentDetail from "../components/ContentDetail";
import "./WritingShort.scss";

function WritingShort({
    isReadonly = false,
    question,
    defaultAnswer /** @param {string} defaultAnswer. Student's answer. */,
    onChange = () => {},
}) {
    const timeoutIdChange = useRef(null);

    const [inputAnswer, setInputAnswer] = useState(defaultAnswer?.[0]);

    const handleChangeAnswered = (str) => {
        setInputAnswer(str);
        // Limit onChange calls:
        clearTimeout(timeoutIdChange.current);
        timeoutIdChange.current = setTimeout(() => {
            if (onChange instanceof Function) {
                onChange({
                    answered: [str],
                });
            }
        });
    };

    return (
        <div className="q-writing">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail isReadonly={isReadonly} value={{ question }} />
            </div>

            <div className="q-content-section">
                <div className="view-writing">
                    {/* <InputEditor
                        className="app-input"
                        value={inputAnswer || ""}
                        onChange={handleChangeAnswer}
                        placeholder={t("q.write_answer_here")}
                        disabled={isReadonly}
                    /> */}
                    <Input
                        className="app-input"
                        value={inputAnswer || ""}
                        onChange={(e) => handleChangeAnswered(e.target.value)}
                        placeholder={t("q.write_answer_here")}
                        disabled={isReadonly}
                    />
                </div>
            </div>
        </div>
    );
}

export default WritingShort;
