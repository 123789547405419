import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Select, Spin, Tabs } from "antd";
import { t } from "i18next";
import { findAssignment } from "src/api/containers/assignment";
import { getStudentDataListForAssignment } from "src/api/containers/statistic";
import { fetchCourses } from "src/api/containers/course";
import { useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import Overview from "./containers/Overview";
import Question from "./containers/Question";
import Student from "./containers/Student";
import "./AssignmentReport.scss";

const AssignmentReport = () => {
    const tabs = {
        overview: "1",
        student: "2",
        question: "3",
    };

    const params = useParams();
    const navigate = useNavigate();
    const [currTab, setCurrTab] = useState({
        tabKey: "",
        tabProps: {},
    });

    const [values, setValues] = useValues({
        loading: false,
        assignmentData: {},
        optionListStudent: [],
        optionListClass: [],
        isFetchingStudents: false, // Values: true, false.
        isFetchingClasses: false, // Values: "searching", true, false.
        isLoadingMoreClasses: false, // Values: "disabled", true, false.
    });
    const [selectedCourseClassId, setSelectedCourseClassId] = useState("");
    const timeoutIdGetClasses = useRef(null);
    const fetchCoursesParams = useRef({
        page: 0,
        is_class: 1,
        slug: "",
        relations: [],
    });

    const handleGoBack = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleSwitchTab = (tabKey, tabProps) => {
        setCurrTab({
            tabKey: tabKey,
            tabProps: tabProps,
        });
    };

    const handleSearchClasses = (keyword) => {
        clearTimeout(timeoutIdGetClasses.current);
        if (typeof keyword === "string") {
            setValues({
                ...values,
                isFetchingClasses: "searching",
            });
            fetchCoursesParams.current = {
                ...fetchCoursesParams.current,
                page: 1,
                slug: keyword,
            };
            timeoutIdGetClasses.current = setTimeout(() => {
                fetchCourses(fetchCoursesParams.current).then((res) => {
                    if (res.status) {
                        setValues({
                            ...values,
                            optionListClass: res.data,
                            isFetchingClasses: false,
                            isLoadingMoreClasses: false,
                        });
                    } else {
                        setValues({
                            ...values,
                            isFetchingClasses: false,
                        });
                    }
                });
            }, 500);
        }
    };

    const handleScroll = (e) => {
        if (
            ["searching", true].includes(values.isFetchingClasses) ||
            ["disabled", true].includes(values.isLoadingMoreClasses)
        ) {
            return;
        }
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = fetchCoursesParams.current.page + 1;
            fetchCoursesParams.current = {
                ...fetchCoursesParams.current,
                page: nextPageToLoad,
            };
            setValues({ ...values, isLoadingMoreClasses: true });
            fetchCourses(fetchCoursesParams.current).then((res) => {
                if (res.status) {
                    setValues({
                        ...values,
                        isLoadingMoreClasses:
                            res.data?.length === 0 || res.pagination.lastPage === nextPageToLoad ? "disabled" : false,
                        optionListClass: [...values.optionListClass, ...res.data],
                    });
                } else {
                    setValues({
                        ...values,
                        isLoadingMoreClasses: false,
                    });
                }
            });
        }
    };

    const handleChangeClass = (classId) => {
        setSelectedCourseClassId(classId);
        setValues({
            ...values,
            isFetchingStudents: true,
        });
        const fetchParams = {
            course_id: classId,
        };
        getStudentDataListForAssignment(params.assignmentId, fetchParams).then((res) => {
            if (res.status) {
                setValues({
                    ...values,
                    isFetchingStudents: false,
                    optionListStudent: res.data,
                });
            } else {
                setValues({
                    ...values,
                    isFetchingStudents: false,
                    optionListStudent: [],
                });
            }
        });
    };

    const handleFetchClass = () => {
        if (fetchCoursesParams.current.page === 0) {
            setValues({ ...values, isFetchingClasses: true });
            fetchCoursesParams.current = {
                ...fetchCoursesParams.current,
                page: 1,
                language_id: values.assignmentData?.course?.language_id,
                level_id: values.assignmentData?.course?.level_id,
            };
            fetchCourses(fetchCoursesParams.current).then((res) => {
                if (res.status === true) {
                    setValues({
                        ...values,
                        isFetchingClasses: false,
                        optionListClass: res.data || [],
                    });
                    if (!res.data?.length) {
                        fetchCoursesParams.current.page = -1;
                    }
                } else {
                    setValues({
                        ...values,
                        isFetchingClasses: false,
                    });
                }
            });
        }
    };

    const handleKeyDownClass = (e) => {
        if (values.isFetchingClasses === true || values.isLoadingMoreClasses === true) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (params.courseId && params.assignmentId) {
            setValues({ ...values, loading: true });
            Promise.all([
                findAssignment(params.assignmentId, { relations: ["course"] }),
                getStudentDataListForAssignment(params.assignmentId),
            ]).then((res) => {
                if (res[0].status === true) {
                    setValues({
                        ...values,
                        loading: false,
                        assignmentData: res[0].data || {},
                        optionListStudent: res[1].data || [],
                    });
                } else {
                    setValues({
                        ...values,
                        loading: false,
                    });
                }
            });
        }
    }, []);

    const classFilterMenu = (
        <Select
            className="app-select class-filter-menu"
            placeholder={t("shared.search_and_select_classes")}
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={values.isFetchingStudents}
            onChange={handleChangeClass}
            onSelect={() => (fetchCoursesParams.current.slug = "")}
            onFocus={handleFetchClass}
            onInputKeyDown={handleKeyDownClass}
            onSearch={(keyword) => handleSearchClasses(keyword)}
            onPopupScroll={(e) => handleScroll(e)}
            notFoundContent={
                values.isFetchingClasses === true ? (
                    <i className="notfound-when-loading">
                        <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                    </i>
                ) : values.isFetchingClasses === "searching" ? (
                    <React.Fragment></React.Fragment>
                ) : undefined
            }
            dropdownClassName="app-select-dropdown"
            dropdownRender={(menu) => (
                <>
                    {values.isFetchingClasses === "searching" ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.searching")}...`}
                        </div>
                    ) : null}
                    {menu}
                    {values.isLoadingMoreClasses === true ? (
                        <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                            <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        </div>
                    ) : null}
                </>
            )}
        >
            {values.optionListClass.map((item, index) => (
                <Select.Option key={`class-${index}`} value={item.id}>
                    ({item.code}) {item.name}
                </Select.Option>
            ))}
        </Select>
    );

    return (
        <div className="assignment-report-wrapper">
            <div className="page-header-wrapper">
                <Breadcrumb
                    isBack
                    routes={[
                        {
                            name: values.assignmentData.name || "",
                        },
                    ]}
                />
            </div>

            <div className="report-tabs-wrapper">
                <Tabs
                    type="card"
                    defaultActiveKey="1"
                    centered
                    activeKey={currTab.tabKey ? currTab.tabKey : undefined}
                    onChange={(tabKey) => setCurrTab({ tabKey: tabKey, tabProps: {} })}
                    tabBarExtraContent={!values.loading ? classFilterMenu : null}
                >
                    <Tabs.TabPane tab={<span>{t("statistical.overview")}</span>} key={tabs.overview}>
                        <Overview
                            assignmentData={values.assignmentData}
                            selectedCourseClassId={selectedCourseClassId}
                            isFetchingStudents={values.isFetchingStudents}
                            optionListStudent={values.optionListStudent}
                            handleSwitchTab={handleSwitchTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span>{t("statistical.student")}</span>} key={tabs.student}>
                        <Student
                            assignmentData={values.assignmentData}
                            selectedCourseClassId={selectedCourseClassId}
                            isFetchingStudents={values.isFetchingStudents}
                            optionListStudent={values.optionListStudent}
                            studentId={currTab.tabKey === "2" ? currTab.tabProps?.studentId : undefined}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span>{t("statistical.question")}</span>} key={tabs.question}>
                        <Question
                            assignmentData={values.assignmentData}
                            selectedCourseClassId={selectedCourseClassId}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default AssignmentReport;
