import React, { useEffect, useRef } from "react";
import { t } from "i18next";
import { Progress, notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, ControlOutlined, ReloadOutlined } from "@ant-design/icons";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { getImageForSystemCheck } from "src/api/containers/examCheckin";
import "./InternetConnectionCheck.scss";

let imgSrc = "";
const imgSrcDownloadSize = 12854484; // Bytes.
const imgSrcDownloadTimeMax = 10; // Second.
const imgSrcDownloadSpeedMin_Bps = imgSrcDownloadSize / imgSrcDownloadTimeMax; // Bps (Bytes per second).
const imgSrcDownloadSpeedMin_Mbps = (imgSrcDownloadSpeedMin_Bps * 8) / 1024 / 1024; // Mbps (Megabits per second).

const getSpeedInfo = (speedMbps) => {
    if (isNaN(speedMbps)) {
        return {
            speedStatus: false,
            speedMessage: t("exam_checkin.internet_connection_speed.slow"),
            speedDescription: t("exam_checkin.internet_connection_speed.descr_slow"),
        };
    }

    const speedStatus = speedMbps < imgSrcDownloadSpeedMin_Mbps ? false : true;
    let speedMessage, speedDescription;
    if (speedMbps < imgSrcDownloadSpeedMin_Mbps) {
        speedMessage = t("exam_checkin.internet_connection_speed.slow");
        speedDescription = t("exam_checkin.internet_connection_speed.descr_slow");
    } else {
        speedMessage = t("exam_checkin.internet_connection_speed.stable");
    }
    return {
        speedStatus,
        speedMessage,
        speedDescription,
    };
};

const measureInternetConnectionSpeed = (onStart, onSuccess, onError) => {
    let startTime, endTime;

    const download = new Image();

    download.onload = function () {
        endTime = new Date().getTime();
        const speed = getSpeed();
        if (onSuccess instanceof Function) {
            const speedInfo = getSpeedInfo(speed.speedMbps);
            onSuccess(speed, speedInfo);
        }
    };

    download.onerror = function (err, msg) {
        if (onError instanceof Function) {
            onError(err, msg);
        }
    };

    if (onStart instanceof Function) {
        onStart();
    }
    startTime = new Date().getTime();
    const cacheBuster = "?nnn=" + startTime;
    download.src = imgSrc + cacheBuster;

    const getSpeed = () => {
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = imgSrcDownloadSize * 8;
        const speedBps = (bitsLoaded / duration).toFixed(2); // bps.
        const speedKbps = (speedBps / 1024).toFixed(2); // kbps.
        const speedMbps = (speedKbps / 1024).toFixed(2); // Mbps.
        return { speedBps, speedKbps, speedMbps };
    };
};

function InternetConnectionCheck({
    disabled = false,
    value, // Values: undefined, "pending", true, false.
    onChange,
}) {
    const checkTimeoutId = useRef(null);

    const [preparation, setPreparation] = useValues({
        loading: false,
        status: false,
    });
    const [values, setValues] = useValues({
        loading: false,
        isFirstTime: true,
        // Connection:
        speed: 0,
        speed_status: false,
        speed_message: "",
        speed_description: "",
    });

    const handleChange = (newValue) => {
        if (onChange instanceof Function) {
            onChange(newValue);
        }
    };

    const handleStartChecking = () => {
        const onStart = () => {
            setValues({
                loading: true,
                isFirstTime: false,
                speed_status: false,
                speed_message: "",
                speed_description: "",
            });
            handleChange("pending");
            checkTimeoutId.current = setTimeout(() => {
                const { speedStatus, speedMessage, speedDescription } = getSpeedInfo(-1);
                setValues({
                    speed_status: speedStatus,
                    speed_message: speedMessage,
                    speed_description: speedDescription,
                });
                handleChange(speedStatus);
                clearTimeout(checkTimeoutId.current);
            }, imgSrcDownloadTimeMax * 1000);
        };
        const onSuccess = ({ speedBps, speedKbps, speedMbps }, speedInfo) => {
            setTimeout(() => {
                setValues({
                    loading: false,
                    speed: speedMbps,
                    speed_status: speedInfo.speedStatus,
                    speed_message: speedInfo.speedMessage,
                    speed_description: speedInfo.speedDescription,
                });
            }, 500);
            handleChange(speedInfo.speedStatus);
            clearTimeout(checkTimeoutId.current);
        };
        const onError = (err, msg) => {
            setTimeout(() => {
                setValues({
                    loading: false,
                    speed: 0,
                    speed_status: false,
                    speed_message: "",
                    speed_description: "",
                });
            }, 500);
            handleChange(false);
            clearTimeout(checkTimeoutId.current);
            notification.error({
                message: msg || t("exam_checkin.internet_connection_check_failed"),
            });
        };
        measureInternetConnectionSpeed(onStart, onSuccess, onError);
    };

    const handleStartCheckingAfterPreparation = () => {
        setPreparation({ loading: true });
        getImageForSystemCheck()
            .then((res) => {
                if (res.status) {
                    setPreparation({ loading: false, status: true });
                    imgSrc = res.data?.url;

                    handleStartChecking();
                } else {
                    setPreparation({ loading: false, status: false });
                    notification.error({
                        message: res.message || t("message.sth_wrong"),
                    });
                }
            })
            .catch((err) => {
                setPreparation({ loading: false, status: false });
                notification.error({
                    message: err.toString() || t("message.sth_wrong"),
                });
            });
    };

    const handleClickCheck = () => {
        handleStartCheckingAfterPreparation();
    };

    useEffect(() => {
        if (!disabled && !values.loading && values.isFirstTime) {
            handleStartCheckingAfterPreparation();
        }
    }, [disabled, values.loading, values.isFirstTime]);

    return (
        <div className="internet-connection-check">
            <div className="result-display">
                <div className="progress-wrapper">
                    <Progress
                        type="dashboard"
                        percent={values.speed}
                        gapDegree={90}
                        format={(percent) => {
                            return (
                                <span className="progress-result">
                                    <span>{values.loading ? "_" : values.speed}</span>
                                    <span>Mbps</span>
                                </span>
                            );
                        }}
                        strokeColor={{ "0%": "#52c41a", "50%": "#1890ff", "100%": "#ff4d4f" }}
                        trailColor="rgba(0,0,0,0.1)"
                        width={160}
                        strokeWidth={8}
                        {...(values.loading
                            ? {
                                  percent: 0,
                              }
                            : {})}
                    />
                    <span className="progress-level bottom-left">0</span>
                    <span className="progress-level bottom-right">100</span>
                </div>
            </div>
            <div className="check-text">
                <div className="check-title">{t("exam_checkin.internet_connection_check")}</div>
                <div className="check-result-info">
                    {values.speed_message && (
                        <div className="check-result-message">
                            <span>{t("exam_checkin.check_result")}: </span>
                            <span className={"result-tag" + (values.speed_status ? " success" : " danger")}>
                                {values.speed_status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
                                {values.speed_message}
                            </span>
                        </div>
                    )}
                    {values.speed_description && (
                        <div className={"check-result-desctiption" + (values.speed_status ? " success" : " danger")}>
                            {values.speed_description}
                        </div>
                    )}
                </div>
            </div>
            <div className="check-actions">
                {preparation.loading ? (
                    <CustomButton type="ghost" title={`${t("shared.preparing")}...`} isLoading={true}></CustomButton>
                ) : (
                    <CustomButton
                        htmlType="submit"
                        type="ghost"
                        icon={values.isFirstTime ? <ControlOutlined /> : <ReloadOutlined />}
                        title={
                            values.isFirstTime
                                ? t("shared.check")
                                : values.loading
                                ? `${t("shared.checking")}...`
                                : t("shared.try_again")
                        }
                        isLoading={values.loading}
                        isDisabled={disabled}
                        onClick={handleClickCheck}
                    ></CustomButton>
                )}
            </div>
        </div>
    );
}

export default InternetConnectionCheck;
