import React from "react";
import { Col, Form, Radio, Row } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./SettingPublishingStatus.scss";

function SettingPublishingStatus() {
    const { t } = useTranslation();

    return (
        <Row className="question-formitems set-publishing-status">
            <Col>
                <Form.Item
                    name="is_public"
                    label={t("question.publishing_status")}
                    rules={[
                        {
                            required: true,
                            message: (
                                <>
                                    <WarningOutlined /> {t("question.required_publishing_status")}
                                </>
                            ),
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio className="app-radio" value={false}>
                            {t("shared.private")}
                        </Radio>
                        <Radio className="app-radio" value={true}>
                            {t("shared.public")}
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    );
}

export default SettingPublishingStatus;
