import api from "..";

const courseRelations = ["courseUsers", "level", "units", "image", "language"];

const courseFields = [
    "name",
    "code",
    "course_ids",
    "begin_date",
    "end_date",
    "owner",
    "user_avatars",
    "teachers",
    "total_student",
    "course_names",
    "level_id",
    "progress",
    "image_id",
    "language_id",
    "is_class",
    "is_show",
    "progress",
];

/**
 * (VERIFIED) Get course/class list.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchCourses = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.name && filters.push(`name:${fetchParams.name}`);
    fetchParams.is_class !== undefined && filters.push(`is_class:${fetchParams.is_class}`);
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`);
    fetchParams.owner_id && filters.push(`owner_id:${fetchParams.owner_id}`);
    fetchParams.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams.language_id && filters.push(`language_id:${fetchParams.language_id}`);
    ![undefined, -1, "-1"].includes(fetchParams.is_show) && filters.push(`is_show:${fetchParams.is_show}`);

    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _course_fields: `${courseFields}`,
        // Other fields:
        _relations: `${[...(fetchParams.relations?.length > 0 ? fetchParams.relations.map((item) => item) : [])]}`,
        ...(fetchParams.relations?.includes("level") > 0 ? { _level_fields: `${["name"]}` } : {}),
        ...(fetchParams.relations?.includes("language") > 0 ? { _language_fields: `${["name", "code"]}` } : {}),
        ...(fetchParams.relations?.includes("image") > 0 ? { _file_fields: `${["name", "src"]}` } : {}),
        ...(fetchParams.relations?.includes("creator") > 0 ? { _user_fields: `${["name"]}` } : {}),
        // Pagination:
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    // Fetch:
    return api
        .get("/course", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
                ...(!fetchParams.noPagination && {
                    pagination: {
                        showSizeChanger: false,
                        current: meta.pagination.page,
                        pageSize: meta.pagination.perPage,
                        total: meta.pagination.total,
                        lastPage: meta.pagination.lastPage,
                    },
                }),
            };
        })
        .catch(() => ({ status: false, data: [] }));
};

/**
 * (VERIFIED) Get course/class list for student.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchCoursesForStudent = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.name && filters.push(`name:${fetchParams.name}`);
    fetchParams.is_class !== undefined && filters.push(`is_class:${fetchParams.is_class}`);
    fetchParams.language_id && filters.push(`language_id:${fetchParams.language_id}`);
    fetchParams.is_active && filters.push(`is_active:${fetchParams.is_active}`);

    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _course_fields: `${courseFields}`,
        // Other fields:
        _relations: `${[...(fetchParams.relations?.length > 0 ? fetchParams.relations.map((item) => item) : [])]}`,
        ...(fetchParams.relations?.includes("level") > 0 ? { _level_fields: `${["name"]}` } : {}),
        ...(fetchParams.relations?.includes("language") > 0 ? { _language_fields: `${["name", "code"]}` } : {}),
        ...(fetchParams.relations?.includes("image") > 0 ? { _file_fields: `${["name", "src"]}` } : {}),
        ...(fetchParams.relations?.includes("creator") > 0 ? { _user_fields: `${["name"]}` } : {}),
        // Pagination:
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    // Fetch:
    return api
        .get("/course", params)
        .then(({ status, data, meta }) => {
            return {
                status,
                data,
                ...(!fetchParams.noPagination && {
                    pagination: {
                        showSizeChanger: false,
                        current: meta.pagination.page,
                        pageSize: meta.pagination.perPage,
                        total: meta.pagination.total,
                        lastPage: meta.pagination.lastPage,
                    },
                }),
            };
        })
        .catch(() => ({ status: false, data: [] }));
};

export const fetchCoursesFilter = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.is_class && filters.push(`is_class:${fetchParams.is_class}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _course_fields: `${["name"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/course", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findCourse = (id, fetchParams) => {
    const params = {
        _course_fields: `${courseFields}`,
        _relations: `${[...(fetchParams?.relations?.length > 0 ? fetchParams.relations.map((item) => item) : [])]}`,
        ...(fetchParams?.relations?.includes("level") > 0 ? { _level_fields: `${["name"]}` } : {}),
        ...(fetchParams?.relations?.includes("image") > 0 ? { _file_fields: `${["name", "src"]}` } : {}),
        ...(fetchParams?.relations?.includes("language") > 0 ? { _language_fields: `${["name", "code"]}` } : {}),
    };
    return api
        .get(`/course/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const fetchStudentCoursesByLevel = (levelId) => {
    const filters = [];
    filters.push(`role:student`);
    levelId && filters.push(`level_id:${levelId}`);
    const params = {
        _filter: filters.join(";") || undefined,
        _course_fields: `${["name"]}`,
        _noPagination: 1,
    };
    return api
        .get(`/course`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const updateCourse = (id, data) => {
    if (!data?.image_id) {
        data.image_id = null;
    }
    return api
        .put(`/course/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const createCourse = (data) => {
    if (!data?.image_id) {
        data.image_id = null;
    }
    return api
        .post("/course", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const deleteCourse = (id) => {
    return api
        .delete(`/course/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const fetchCoursesLevelsAssignments = (prs) => {
    const { course_id, level_id, language_id, is_class, is_unit } = prs;

    const params = {
        course_id: course_id,
        level_id: level_id,
        language_id: language_id,
        is_class: is_class,
        is_unit: is_unit,
    };
    return api
        .get(`/assignment/list_selection`, params)
        .then(({ courses, levels, assignments }) => ({ courses, levels, assignments, status: true }))
        .catch(() => ({ status: false }));
};

// // User - student:
// export const addStudent = (id, data) => {
//     return api
//         .put(`/class/${id}`, data)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// export const deleteStudent = (data) => {
//     return api
//         .post(`/class/remove_user`, data)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// (Course INVITATION) User - teacher:
export const inviteTeacherToCourse = (data) => {
    if (!data.email || !data.course_id) {
        return new Promise((resolve, reject) => {
            reject({ status: false });
        }).catch(() => ({ status: false }));
    }

    return api
        .post("/course/mail_invite_teacher", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const addTeacherToCourse = (courseId, inviter_id) => {
    return api
        .put(`/course/${courseId}/add_course_user`, { inviter_id })
        .then(({ status, messages }) => ({ status, message: messages }))
        .catch(() => ({ status: false }));
};

// (COURSE INVITATION) User - student:
export const addStudentToCourse = (courseId, inviter_id) => {
    return api
        .put(`/course/${courseId}/add_course_user_student`, { inviter_id })
        .then(({ status, messages }) => ({ status, message: messages }))
        .catch(() => ({ status: false }));
};

export const removeUserFromCourse = ({ course_id, user_id }) => {
    return api
        .post(`/course/remove_user`, { course_id, user_id })
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const addUsersToCourse = (userRole, data) => {
    if (!["student", "teacher"].includes(userRole)) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: userRole!" });
        });
    }
    // Student's data: {course_id, users: [{username, name, personal_number}]}.
    // Teacher's data: {course_id, users: [{username, name}]}.
    return api
        .post(`/course_user/add_to_class/${userRole}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const uploadFileUsers = (userRole, data) => {
    if (!["student", "teacher"].includes(userRole)) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: userRole!" });
        });
    }
    // Student's data: {course_id, files}.
    // Teacher's data: {course_id, files}.
    return api
        .post(`course_user/import/${userRole}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
