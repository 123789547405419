import { Radio, Space } from "antd";
import clsx from "clsx";
import { t } from "i18next";
import { useMemo } from "react";
import { CorrectIcon, InCorrectIcon } from "src/utils/drawer";
import QuestionContentDetail from "../ContentDetail";
import "./MultipleChoiceBoolean.scss";

function MultipleChoiceBoolean(props) {
    const {
        correct_answer /**  @param {[Boolean] | {text: String; result: Boolean}} correct_answer: correct answer */,
        answered /** @param {[Boolean]} answered?: student's answers */,
        question /** @param {String} question: Question */,
    } = props;

    const convertCorrectAnswer = useMemo(() => {
        // Convert type  {text: String; result: Boolean} to [Boolean]
        if (correct_answer) {
            if (correct_answer?.[0].text) {
                const result = [];

                correct_answer?.map((caswr) => {
                    if (caswr.result) result.push(caswr.text);
                });

                return result;
            } else {
                return correct_answer;
            }
        } else {
            return;
        }
    }, [correct_answer]);

    return (
        <div className="qc_multiple-choice-boolean">
            {question && (
                <div className="q-content-title">
                    <QuestionContentDetail
                        isReadonly={true}
                        value={{
                            question,
                        }}
                    />
                </div>
            )}
            <div className="content-multiple-choice">
                <Radio.Group value={answered?.[0]}>
                    <Space direction="vertical">
                        <div
                            className={clsx(
                                "multiple-choice-boolean__answer-result",
                                convertCorrectAnswer?.[0] === "true" && "correct"
                            )}
                        >
                            <Radio value={"true"}> {t("q_multi_boolean.true")}</Radio>
                            {answered !== undefined ? (
                                answered?.[0] === "true" ? (
                                    convertCorrectAnswer?.[0] === "true" ? (
                                        <CorrectIcon />
                                    ) : (
                                        <InCorrectIcon />
                                    )
                                ) : (
                                    convertCorrectAnswer?.[0] === "true" && <CorrectIcon />
                                )
                            ) : (
                                convertCorrectAnswer?.[0] === "true" && <CorrectIcon />
                            )}
                        </div>
                        <div
                            className={clsx(
                                "multiple-choice-boolean__answer-result",
                                convertCorrectAnswer?.[0] === "false" && "correct"
                            )}
                        >
                            <Radio value={"false"}> {t("q_multi_boolean.false")}</Radio>
                            {answered !== undefined &&
                                (answered[0] === "false" ? (
                                    convertCorrectAnswer?.[0] === "false" ? (
                                        <CorrectIcon />
                                    ) : (
                                        <InCorrectIcon />
                                    )
                                ) : (
                                    convertCorrectAnswer?.[0] === "false" && <CorrectIcon />
                                ))}
                        </div>
                    </Space>
                </Radio.Group>
            </div>
        </div>
    );
}

export default MultipleChoiceBoolean;
