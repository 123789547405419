import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchSubscriptionPlans, planTypes } from "src/api/containers/plan";
import configs from "src/configs";
import { useMediaQuery, useValues } from "src/hooks";
import ModalContactUs from "src/modules/components/Modal/ModalContactUs";
import { useRouting } from "src/utils/router";

import CardSubscriptionPlan from "../CardSubscriptionPlan";
import CardTitle from "../CardSubscriptionPlan/CardTitle";
import CardTable from "../CardTable";
import "./ModalSubscriptionPlans.scss";

const ModalSubscriptionPlans = ({ className = "", visible = false, onOk = () => {}, onCancel = () => {}, ...rest }) => {
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        planList: [],
    });
    const [values, setValues] = useValues({
        modalOpen: false,
        modalData: undefined,
    });
    const currMedia = useMediaQuery(["(min-width: 576px)"]);
    const { generate } = useRouting();
    const navigate = useNavigate();

    const handleCancel = () => {
        if (onCancel && onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleOk = () => {
        if (onOk && onOk instanceof Function) {
            onOk();
        }
    };

    const handlePreventCancel = (e) => {
        e.stopPropagation();
    };

    const getPlanInUsed = () => {
        if (user.organization?.plan_type) {
            for (let i = Object.keys(planTypes).length - 1; i >= 0; i--) {
                const key = Object.keys(planTypes)[i];
                const type = planTypes[key];
                if (user.organization.plan_type === type) {
                    return type;
                }
            }
        }
        return planTypes.free;
    };

    const handleGoToPayment = (selectedPlan, planPriceId) => {
        if (selectedPlan) {
            const data = {
                planType: selectedPlan.type,
                planId: selectedPlan.id,
                planPriceId: planPriceId || "",
            };
            sessionStorage.setItem("currentOrder", JSON.stringify(data));
            handleCancel();
            navigate(generate("payment"));
        }
    };

    const handleToggleContactUsModal = (modalData) => {
        if (values.modalOpen) {
            setValues({
                modalOpen: false,
                modalData: undefined,
            });
        } else {
            setValues({
                modalOpen: "contactus",
                modalData: modalData,
            });
        }
    };

    const getActionInfoByPlanInUsed = (planInUsed, planMentioned) => {
        // Helpers:
        const getTitleByUpgradationMethod = (planType) => {
            const upgradationMethod = configs.UPGRADE_ACCOUNT_ALLOWED_METHODS[planType];
            if (upgradationMethod?.contact) {
                return t("account.contact_support");
            } else {
                return t("account.upgrade_now");
            }
        };

        // Default settings:
        let currTitle = "";
        let currClassName = "";
        let currLinkTo = { linkToIsLocal: false, linkTo: "/", target: "_blank" };
        let currHandleClick = (planItem, planPriceId) => {
            const upgradationMethod = configs.UPGRADE_ACCOUNT_ALLOWED_METHODS[planItem?.type];
            if (upgradationMethod?.contact) {
                handleToggleContactUsModal({
                    plan_id: planItem?.id,
                    plan_price_id: planPriceId,
                });
            } else {
                handleGoToPayment(planItem, planPriceId);
            }
        };

        // Suitable settings:
        if (planInUsed === planTypes.free) {
            switch (planMentioned) {
                case planTypes.free: {
                    currTitle = t("account.current_account");
                    currClassName = "activated";
                    currLinkTo = undefined;
                    currHandleClick = undefined;
                    break;
                }
                case planTypes.plan1:
                case planTypes.plan2:
                case planTypes.plan3: {
                    currTitle = getTitleByUpgradationMethod(planMentioned);
                    currLinkTo = undefined;
                    break;
                }
                default:
                    break;
            }
        } else {
            switch (planMentioned) {
                case planTypes.free: {
                    currTitle = t("plan.disabled");
                    currClassName = "disabled";
                    currLinkTo = undefined;
                    currHandleClick = undefined;
                    break;
                }
                case planTypes.plan1:
                case planTypes.plan2:
                case planTypes.plan3: {
                    if (planInUsed === planMentioned) {
                        // There are some cases:
                        // - If the plan in used is `is_multiple_at_planlist`, it can be purchased many times.
                        // - Otherwise, it can only be purchased one time.
                        if (configs.UPGRADE_ACCOUNT_ALLOWED_METHODS[planMentioned].is_multiple_at_planlist) {
                            currTitle = getTitleByUpgradationMethod(planMentioned);
                            currLinkTo = undefined;
                        } else {
                            currTitle = t("account.current_account");
                            currClassName = "activated";
                            currLinkTo = undefined;
                            currHandleClick = undefined;
                        }
                    } else {
                        // If the plan in used exists in `upgradable_from_plans` list of the mentioned plan, it can be purchased.
                        if (
                            configs.UPGRADE_ACCOUNT_ALLOWED_METHODS[planMentioned].upgradable_from_plans.includes(
                                planInUsed
                            )
                        ) {
                            currTitle = getTitleByUpgradationMethod(planMentioned);
                            currLinkTo = undefined;
                        } else {
                            currTitle = t("plan.disabled");
                            currClassName = "disabled";
                            currLinkTo = undefined;
                            currHandleClick = undefined;
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        }

        return [currTitle, currClassName, currLinkTo, currHandleClick];
    };

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        if (visible && !data.planList.length) {
            setIsLoading(true);
            fetchSubscriptionPlans().then((res) => {
                if (res.status && res.data?.length) {
                    setData({ ...data, planList: res.data });
                }
                setIsLoading(false);
            });
        }
    }, [visible]);

    const renderPlanList = () => {
        return data.planList.map((planItem, planIndex) => {
            // Settings:
            const [currTitle, currClassName, currLinkTo, currHandleClick] = getActionInfoByPlanInUsed(
                getPlanInUsed(),
                planItem.type
            );
            return (
                <CardSubscriptionPlan
                    key={`plan-${planIndex}`}
                    className={""}
                    planData={planItem}
                    type={planItem.type}
                    title={planItem.name}
                    priceUnit={t("price.vnd")}
                    priceList={planItem.prices}
                    priceCurrent={planItem.price}
                    priceForAmountOfTime={`/${t("price.month")}`}
                    actions={[
                        {
                            type: currClassName === "activated" ? "simple" : undefined,
                            title: currTitle,
                            linkTo: currLinkTo?.linkTo,
                            linkToIsLocal: currLinkTo?.linkToIsLocal,
                            target: currLinkTo?.target,
                            onClick: currHandleClick,
                            disabled: planItem.type === planTypes.free || currClassName === "disabled",
                            className: currClassName || undefined,
                        },
                    ]}
                />
            );
        });
    };

    return (
        <div className={`subscription-plans-modal${className ? " " + className : ""}`}>
            <div className={`modal-subsc-plans${visible ? " visible" : ""}`}>
                <div className="modal-wrapper">
                    <div className="modal-overlay"></div>
                    <div className="modal-content-wrapper" onClick={handleCancel}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="header-title" onClick={handlePreventCancel}>
                                    {t("account.pick_your_suitable_subscription_plan")}
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className={`plan-list`} onClick={handlePreventCancel}>
                                    {isLoading ? (
                                        <CardTable>
                                            <CardTitle />
                                            <CardSubscriptionPlan isLoadingSkeleton={true} />
                                            <CardSubscriptionPlan isLoadingSkeleton={true} />
                                            <CardSubscriptionPlan isLoadingSkeleton={true} />
                                        </CardTable>
                                    ) : currMedia.breakpointValue === 576 ? (
                                        <>
                                            {data.planList.length ? (
                                                <CardTable>
                                                    <CardTitle />
                                                    {renderPlanList()}
                                                </CardTable>
                                            ) : (
                                                <div className="empty-wrapper">
                                                    <CardTable>
                                                        <CardTitle />
                                                        <CardSubscriptionPlan />
                                                        <CardSubscriptionPlan />
                                                        <CardSubscriptionPlan />
                                                    </CardTable>
                                                    <div className="empty-msg">{t("shared.no_data")}</div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <CardTable>
                                            <CardTitle />
                                            {renderPlanList()}
                                        </CardTable>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalContactUs
                visible={values.modalOpen === "contactus"}
                formData={{
                    plan_id: values.modalData?.plan_id,
                }}
                onCancel={handleToggleContactUsModal}
                onOk={handleToggleContactUsModal}
            />
        </div>
    );
};

export default ModalSubscriptionPlans;
