import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, message, notification } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { addUsersToCourse } from "src/api/containers/course";
import actionClose from "src/assets/images/action-close.svg";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";

import "./ModalAddUser.scss";

const ModalAddUser = ({
    userRole, // Values: "student", "teacher".
    courseId = "",
    modalClassName = "",
    onOk = () => {},
    onCancel = () => {},
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleOk = () => {
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleCreateInvitation = () => {
        const formData = form.getFieldsValue();

        if (!courseId) return;
        formData.course_id = courseId;

        addUsersToCourse(userRole, formData)
            .then(({ status, message }) => {
                if (status) {
                    notification.success({ message: message || t("message.add_success") });
                    if (onOk instanceof Function) {
                        onOk();
                    }
                } else if (message) {
                    notification.error({ message: message || t("message.add_error") });
                }
            })
            .catch(() => {});
    };

    return (
        <Modal
            centered
            footer={null}
            title={t(`course.add_${userRole}`)}
            closeIcon={<img src={actionClose}></img>}
            // onOk={handleOk}
            onCancel={handleCancel}
            className={`modal-add-course modal-add-users${modalClassName ? " " + modalClassName : ""}`}
            width={"auto"}
            style={{ maxWidth: "70%" }}
            {...rest}
        >
            <Form
                form={form}
                onFinish={handleCreateInvitation}
                className="add_users_form"
                initialValues={{ users: [{}] }}
            >
                {/* <Form.Item
                    name="teacher_email"
                    label={t("course.teacher_email")}
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                        {
                            type: "email",
                            message: t("message.invalid_email"),
                        },
                    ]}
                >
                    <Input className="app-input" placeholder={t("course.teacher_email")} maxLength={100} />
                </Form.Item> */}

                <Form.List name="users">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => {
                                return (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, "username"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("message.required"),
                                                },
                                                {
                                                    type: "email",
                                                    message: t("message.invalid_email"),
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                        <Form.Item {...restField} name={[name, "name"]}>
                                            <Input placeholder={t("profile.full_name")} />
                                        </Form.Item>
                                        {userRole === "student" && (
                                            <Form.Item {...restField} name={[name, "personal_number"]}>
                                                <Input placeholder={t("course.student_id")} />
                                            </Form.Item>
                                        )}

                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                );
                            })}

                            <Form.Item className="button_add-new">
                                <Button
                                    type="dashed"
                                    size="large"
                                    style={{ borderRadius: 10 }}
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    {t("shared.add_new")}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <div className="btn-group">
                    <CustomButton
                        type="grey"
                        icon={<Icon name="icon-cross-thick" />}
                        title={t("action.cancel")}
                        onClick={handleCancel}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<Icon name="icon-tick" />}
                        title={t("shared.submit")}
                    ></CustomButton>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalAddUser;
