import { HistoryOutlined } from "@ant-design/icons";
import { Form, Spin, notification } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { fetchLanguages } from "src/api/containers/language";
import { fetchLevels } from "src/api/containers/level";
import {
    addChildQuestionForPassageQuestion,
    cloneQuestion,
    createQuestion,
    questionTypes,
    updateChildQuestion,
    updateQuestion,
} from "src/api/containers/question";
import { deleteTag, fetchTags } from "src/api/containers/tag";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import {
    getQuestionDataPreview,
    getQuestionDataSubmit,
    getQuestionKeyByType,
    getQuestionTypeByKey,
} from "src/api/helpers/question";
import { checkIf_User_CanEditQuestion } from "src/api/utils/question";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
// import Header from "src/modules/components/Header";
import Icon from "src/modules/components/Icon";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import { pauseAudios } from "src/utils/helpers/audio";
import parser from "src/utils/parser";
import { useRouting } from "src/utils/router";

import { HistoryContainer } from "../ManageHistory/HistoryFeature/HistoryContainer";
import "./QuestionDetail.scss";
import SettingPublishingStatus from "./components/SettingPublishingStatus";
import SettingTopBar from "./components/SettingTopBar";
import DetailConversationAudio from "./containers/DetailConversationAudio";
import DetailConversationText from "./containers/DetailConversationText";
import DetailDragDrop from "./containers/DetailDragDrop";
import DetailDragDropGroup from "./containers/DetailDragDropGroup";
import DetailDragDropGroupOrderBy from "./containers/DetailDragDropGroupOrderBy";
import DetailFillInTheBlankLatex from "./containers/DetailFillInTheBlankLatex";
import DetailFillInTheBlanks from "./containers/DetailFillInTheBlanks";
import DetailFillInTheBlanksDragDrop from "./containers/DetailFillInTheBlanksDragDrop";
import DetailFillInTheBlanksInput from "./containers/DetailFillInTheBlanksInput";
import DetailGraph from "./containers/DetailGraph";
import DetailGraphNumberLine from "./containers/DetailGraphNumberLine";
import DetailHighLightImage from "./containers/DetailHighLightImage";
import DetailHighlight from "./containers/DetailHighlight";
import DetailHotspot from "./containers/DetailHotspot";
import DetailImageWithLabelsDragDrop from "./containers/DetailImageWithLabelsDragDrop";
import DetailImageWithLabelsDropdown from "./containers/DetailImageWithLabelsDropdown";
import DetailImageWithLabelsText from "./containers/DetailImageWithLabelsText";
import DetailMathMatrix from "./containers/DetailMathMatrix";
import DetailMathTextDropdown from "./containers/DetailMathTextDropdown";
import DetailMultipleChoice from "./containers/DetailMultipleChoice";
import DetailMultipleChoiceBoolean from "./containers/DetailMultipleChoiceBoolean";
import DetailMultipleChoiceSentenceQuiz from "./containers/DetailMultipleChoiceSentenceQuiz";
import DetailMultipleChoiceTable from "./containers/DetailMultipleChoiceTable";
import DetailPhoneticTable from "./containers/DetailPhoneticTable";
import DetailShading from "./containers/DetailShading";
import DetailSort from "./containers/DetailSort";
import DetailSpeechToText from "./containers/DetailSpeechToText";
import DetailWriting from "./containers/DetailWriting";
import DetailWritingShort from "./containers/DetailWritingShort";
import QuestionDetailPreview from "./containers/QuestionDetailPreview";

// import DetailPassageQuestion from "./containers/DetailPassageQuestion";

const QuestionDetail = ({
    type = "",
    onGoBack,
    // Props used inside modal:
    editingQuestion = null,
    refetch = () => {},
    closeModal = () => {},
    onChange = () => {},
    // Props used for PassageQuestionDetail:
    typeOfQuestion,
    questionId,
    childId,
    paragraphId,
    // Others:
    is_show, // Được truyền từ DetailExam.
}) => {
    const dispatch = useDispatch();

    // User:
    const { user } = useSelector((state) => state.auth);

    // Language:
    const languageRef = useRef();

    // Form:
    const _handleFormAction = useRef(null);
    const [form] = Form.useForm();
    const [formStates, setFormStates] = useState({
        formQuestionData: undefined, // Form data to be used for any child/tab.
        formQuestionDataPreview: undefined, // Form data to be used for preview tab only.
        extraQuestionData: undefined, // Temporary data from child component.
    });

    // Form data & states:
    const [values, setValues] = useValues({
        isFormFilled: false, // Values: false || "ready" || true.
        loading: false,
        optionListLevel: [],
        optionListTag: [],
        optionListLanguage: [],
        ownerID: "",
        isPublish: false,
        isEmployeeCanEdit: false,
        language: "",
        questionData: {},
    });
    const [currentTab, setCurrentTab] = useState({
        tabKey: "modify", // Values: "modify" || "preview" || "history" || "".
    });
    const [isConfirm, setIsConfirm] = useState(false); // Values: "go-back" || false.

    // Routing & navigating:
    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const location = useLocation();

    // Permission:
    const isEditable = useMemo(() => {
        return checkIf_User_CanEditQuestion(
            "type-2",
            user,
            { employee_can_edit: values.isEmployeeCanEdit },
            { id: values.ownerID, role: values.questionData.user?.role }
        );
    }, [user, values.ownerID, values.isEmployeeCanEdit]);

    // Page info:
    const [currQType, currQKey, isDetailPage, isScoring] = useMemo(() => {
        let currQType = "";
        let currQKey = "";
        let isScoring = null;
        let isDetailPage = false;

        if (typeOfQuestion) {
            currQType = typeOfQuestion;
            currQKey = getQuestionKeyByType(typeOfQuestion);
            isScoring = questionTypes[currQType]?.isScoring;
            isDetailPage = false;
        } else {
            currQType = getQuestionTypeByKey(params.questionKey);
            currQKey = params.questionKey;
            isScoring = questionTypes[currQType]?.isScoring;
            const currPath = generate("add_question", { questionKey: params.questionKey });
            isDetailPage = currQType !== false && location.pathname.includes(currPath);
        }

        return [currQType, currQKey, isDetailPage, isScoring];
    }, [typeOfQuestion, params]);

    // Basic methods:
    const handleGoBack = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (onGoBack && onGoBack instanceof Function) {
            onGoBack();
        } else {
            if (type === "exam_bank") {
                closeModal();
            } else {
                navigate(generate("question_bank"));
            }
        }
    };

    // Form submitting methods:
    // (TEST CASES: #t-qd-submit-01).
    const handleSaveChildQuestion = (formQuestionData) => {
        let formData = form.getFieldsValue();
        const { question, score } = formData;
        const { type } = formQuestionData;

        // Validate:
        if (!question || (!score && type !== "phonetic_table")) {
            notification.warning({
                message: t("message.warning_missing_fields"),
            });
            return;
        }

        // Generate values to be sent to database:
        formData = {
            question: parser.compactMathField(question),
            score: score,
            ...formQuestionData,
        };

        // Make api calls:
        setValues({ ...values, loading: true });
        if (!childId) {
            if (questionId && paragraphId) {
                // Add a child question for the question:
                addChildQuestionForPassageQuestion({
                    ...formData,
                    question_id: questionId,
                    paragraph_id: paragraphId,
                }).then((res) => {
                    setValues({ ...values, loading: false });
                    if (res.status === true) {
                        if (onChange && onChange instanceof Function) {
                            formData.paragraphId = paragraphId;
                            onChange(formData);
                        }
                        notification.success({
                            message: t("message.add_success"),
                        });
                        handleGoBack();
                    }
                });
            } else {
            }
        } else {
            // Update the child question of a question:
            updateChildQuestion(childId, formData).then((childRes) => {
                setValues({ ...values, loading: false });
                if (childRes.status === true) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    handleGoBack();
                }

                if (onChange && onChange instanceof Function) {
                    formData.childId = childId;
                    onChange(formData);
                }
            });
        }
    };

    // (TEST CASES: #t-qd-submit-01).
    const handleSaveQuestion = (isPublish = false, formQuestionData) => {
        let formData = form.getFieldsValue();

        const { is_public, level_id, language_id, tag_ids, question, score } = formData;

        // // Validate:
        // if (isPublish === true && (!level_id || !language_id || !question || !score)) {
        //     notification.warning({
        //         message: t("message.warning_missing_fields"),
        //     });
        //     return;
        // }

        // Generate values to be sent to database:
        // - Question scope:
        let isPublic = is_public;
        // - Question form data:
        formData = {
            is_publish: isPublish,
            is_public: isPublic,
            level_id: level_id,
            language_id: language_id,
            tag_ids: tag_ids,
            question: parser.compactMathField(question),
            score: score,
            ...formQuestionData,
        };

        // Make api calls:
        setValues({ ...values, loading: true });
        if ((params.id && type !== "exam_bank") || editingQuestion) {
            updateQuestion(editingQuestion?.id || params.id, formData).then((res) => {
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    dispatch(animationId({ name: "question", update: editingQuestion?.id || params.id }));
                    if (editingQuestion) {
                        closeModal();
                    } else {
                        handleGoBack();
                    }
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
                if (onChange && onChange instanceof Function) {
                    formData.id = editingQuestion?.id;
                    formData.level = values.optionListLevel.find((item) => item.id === formData.level_id);
                    formData.tags = values.optionListTag.filter((item) => formData.tag_ids.includes(item.id));
                    formData.language = values.optionListLanguage.find((item) => item.id === formData.language_id);
                    onChange(formData);
                }
                setValues({ ...values, loading: false });
            });
        } else {
            createQuestion(formData).then((res) => {
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    dispatch(animationId({ name: "question", add: res?.id }));
                    if (type === "exam_bank") {
                        closeModal();
                        refetch();
                    } else {
                        handleGoBack();
                    }
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
                setValues({ ...values, loading: false });
            });
        }
    };

    const handleCloneQuestion = (e) => {
        e.preventDefault();
        cloneQuestion(params.id).then(({ status, message, id }) => {
            if (status) {
                dispatch(animationId({ name: "question", clone: id }));
                notification.success({
                    message: t("message.clone_success"),
                });
                handleGoBack();
            } else {
                notification.error({
                    message: message || t("message.clone_error"),
                });
            }
        });
    };

    const handleClickSaveDraft = () => {
        if (_handleFormAction.current instanceof Function) {
            const fData = _handleFormAction.current("save-draft");
            if (fData) {
                const formQuestionDataNew = getQuestionDataSubmit(fData._questionData);
                const formQuestionDataPreviewNew = getQuestionDataPreview(fData._questionDataPreview);
                setFormStates({
                    ...formStates,
                    formQuestionData: formQuestionDataNew,
                    formQuestionDataPreview: formQuestionDataPreviewNew,
                });
                handleSaveQuestion(false, formQuestionDataNew);
            } else {
                handleSaveQuestion(false);
            }
        }
    };

    const handleClickSave = () => {
        if (_handleFormAction.current instanceof Function) {
            const fData = _handleFormAction.current("save");
            if (fData instanceof Promise) {
                fData.then((resData) => {
                    if (resData) {
                        const formQuestionDataNew = getQuestionDataSubmit(resData._questionData);
                        const formQuestionDataPreviewNew = getQuestionDataPreview(resData._questionDataPreview);
                        setFormStates({
                            ...formStates,
                            formQuestionData: formQuestionDataNew,
                            formQuestionDataPreview: formQuestionDataPreviewNew,
                        });
                        handleSaveQuestion(true, formQuestionDataNew);
                    }
                });
            }
        }
    };

    const handleClickSaveChild = () => {
        if (_handleFormAction.current instanceof Function) {
            const fData = _handleFormAction.current("save-child");
            if (fData instanceof Promise) {
                fData.then((resData) => {
                    if (resData) {
                        const formQuestionDataNew = getQuestionDataSubmit(resData._questionData);
                        const formQuestionDataPreviewNew = getQuestionDataPreview(resData._questionDataPreview);
                        setFormStates({
                            ...formStates,
                            formQuestionData: formQuestionDataNew,
                            formQuestionDataPreview: formQuestionDataPreviewNew,
                        });
                        handleSaveChildQuestion(formQuestionDataNew);
                    }
                });
            }
        }
    };

    // Confirmation:
    const handleGoBackNeedConfirm = () => {
        setIsConfirm("go-back");
    };

    const handleClickConfirmOk = () => {
        if (isConfirm === "go-back") {
            handleGoBack();
        }
    };

    const handleClickConfirmCancel = () => {
        setIsConfirm(false);
    };

    // Changing current tab:
    const changeTab = (tabKey) => {
        setCurrentTab({ ...currentTab, tabKey: tabKey });
    };

    const handleClickTabModify = () => {
        if (currentTab.tabKey !== "modify") {
            changeTab("modify");
        }
    };

    const handleClickTabHistory = () => {
        if (currentTab.tabKey !== "history") {
            changeTab("history");
        }
    };

    const handleClickTabPreview = () => {
        if (_handleFormAction.current instanceof Function) {
            const fData = _handleFormAction.current("go-preview");
            if (fData) {
                setFormStates({
                    ...formStates,
                    formQuestionData: getQuestionDataSubmit(fData._questionData),
                    formQuestionDataPreview: getQuestionDataPreview(fData._questionDataPreview),
                });
                changeTab("preview");
                pauseAudios();
            }
        }
    };

    // Form:
    const handleKeyDown = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault();
        }
    };

    // Others:
    const handleDeleteTag = (e, id) => {
        e.stopPropagation();
        deleteTag(id).then((res) => {
            if (res.status) {
                const newOptionListTag = values.optionListTag.filter((opt) => opt.id !== id);
                setValues({
                    optionListTag: newOptionListTag,
                });
                notification.success({ message: t("tag.remove_tag_success") });
            } else {
                notification.error({ message: t("tag.remove_tag_error") });
            }
        });
    };

    // Rendering form layout and form detail:
    const fillQuestionDataInForm = (questionData, questionFormData, questionExtraData) => {
        // 1. Get extra lavels that are not included in the level menu:
        // Do nothing because level menu already listed all items!
        // 2. Get extra tags that are not included in the tag menu:
        let listOfTags = [...values.optionListTag];
        if (questionData?.tags?.length) {
            if (user.id !== questionData?.user_id) {
                listOfTags = [...questionData?.tags];
            } else {
                const extraTags = questionData?.tags.filter((tagItem) => {
                    for (let i = 0; i < listOfTags.length; i++) {
                        if (tagItem.id === listOfTags[i].id) {
                            return false;
                        }
                    }
                    return true;
                });
                listOfTags = [...listOfTags, ...extraTags];
            }
        }
        // 3. Update form states & values:
        form.setFieldsValue(questionFormData);
        setFormStates({
            ...formStates,
            formQuestionData: questionFormData,
            extraQuestionData: questionExtraData,
        });
        setValues({
            ...values,
            isFormFilled: true,
            loading: false,
            optionListTag: listOfTags,
            ownerID: questionData?.user_id || "",
            questionData: questionData || {},
            isPublish: questionData?.is_publish || false,
            isEmployeeCanEdit: questionData?.employee_can_edit || false,
        });

        if (
            !checkIf_User_CanEditQuestion("type-2", user, questionData, {
                id: questionData?.user_id,
                role: questionData.user?.role,
            })
        ) {
            handleClickTabPreview();
        }
        // if (user.id !== questionData?.user_id) {
        //     handleClickTabPreview();
        // }
    };

    const renderQuestionDetailContent = () => {
        // Check if the question is "an independent question" or "a child of passage question":
        let qParentId = undefined;
        let qId = undefined;
        if (!questionId && !childId) {
            qId = editingQuestion?.id || params.id;
        } else {
            qParentId = questionId;
            qId = childId;
        }
        // Question detail props:
        const componentProps = {
            form: form,
            formStates: formStates,
            questionId: qId,
            questionParentId: qParentId,
            isFormFilled: values.isFormFilled,
            currQType,
            onFillFormQuestionData: fillQuestionDataInForm,
            handleFormAction: _handleFormAction,
            language: values.language,
            extraQuestionFetchParams: {
                is_show: is_show, // is_show With Teacher
            },
        };

        // Render question detail:
        switch (currQKey) {
            case questionTypes.multiple_choice?.key:
                return <DetailMultipleChoice {...componentProps} />;
            case questionTypes.multiple_choice_boolean?.key:
                return <DetailMultipleChoiceBoolean {...componentProps} />;
            case questionTypes.multiple_choice_sentence_quiz?.key:
                return <DetailMultipleChoiceSentenceQuiz {...componentProps} />;
            case questionTypes.multiple_choice_table?.key:
                return <DetailMultipleChoiceTable {...componentProps} />;
            case questionTypes.writing?.key:
                return <DetailWriting {...componentProps} />;
            case questionTypes.writing_short?.key:
                return <DetailWritingShort {...componentProps} />;
            case questionTypes.sort?.key:
                return <DetailSort {...componentProps} />;
            case questionTypes.drag_drop?.key:
                return <DetailDragDrop {...componentProps} />;
            case questionTypes.drag_drop_group?.key:
                return <DetailDragDropGroup {...componentProps} />;
            case questionTypes.drag_drop_group_order_by?.key:
                return <DetailDragDropGroupOrderBy {...componentProps} />;
            case questionTypes.fill_in_the_blank?.key:
                return <DetailFillInTheBlanks {...componentProps} />;
            case questionTypes.fill_in_the_blank_text?.key:
                return <DetailFillInTheBlanksInput {...componentProps} />;
            case questionTypes.fill_in_the_blank_drag_drop?.key:
                return <DetailFillInTheBlanksDragDrop {...componentProps} />;
            case questionTypes.highlight?.key:
                return <DetailHighlight {...componentProps} />;
            case questionTypes.fill_in_the_blank_drag_drop_image?.key:
                return <DetailImageWithLabelsDragDrop {...componentProps} />;
            case questionTypes.fill_in_the_blank_image?.key:
                return <DetailImageWithLabelsDropdown {...componentProps} />;
            case questionTypes.fill_in_the_blank_text_image?.key:
                return <DetailImageWithLabelsText {...componentProps} />;
            case questionTypes.highlight_image?.key:
                return <DetailHighLightImage {...componentProps} />;
            case questionTypes.highlight_square?.key:
                return <DetailShading {...componentProps} />;
            case questionTypes.hot_spot?.key:
                return <DetailHotspot {...componentProps} />;
            case questionTypes.convert_measurement?.key:
                return <DetailMathTextDropdown {...componentProps} />;
            case questionTypes.speech_to_text?.key:
                return <DetailSpeechToText {...componentProps} ref={languageRef} />;
            case questionTypes.phonetic_table?.key:
                return <DetailPhoneticTable {...componentProps} ref={languageRef} />;
            case questionTypes.speech_to_text_conversation_text?.key:
                return <DetailConversationText {...componentProps} ref={languageRef} />;
            case questionTypes.speech_to_text_conversation_audio?.key:
                return <DetailConversationAudio {...componentProps} ref={languageRef} />;
            case questionTypes.matrix?.key:
                return <DetailMathMatrix {...componentProps} />;
            case questionTypes.fill_in_the_blank_latex?.key:
                return <DetailFillInTheBlankLatex {...componentProps} />;
            case questionTypes.draw_graph?.key:
                return <DetailGraph {...componentProps} />;
            case questionTypes.number_line?.key:
                return <DetailGraphNumberLine {...componentProps} />;
            default:
                navigate(generate("not-found"));
                break;
        }
        return null;
    };

    // Side effects:
    useEffect(() => {
        dispatch(setCurrentPage("question_detail"));
        setValues({ ...values, loading: true });
        Promise.all([
            fetchLevels({ slug: "", noPagination: true }),
            fetchTags({ slug: "", owner_id: user.id, page: 1 }),
            fetchLanguages({ slug: "", noPagination: true }),
        ]).then((res) => {
            if (res[0].status && res[1].status) {
                // 1. Get default level list:
                const defaultLevels = res[0].data ? res[0].data : [];
                // 2. Get default tag list:
                const defaultTags = res[1].data ? res[1].data : [];
                // 3. Get default language list:
                const defaultLanguages = res[2].data ? res[2].data : [];
                // 4. Update form state:
                form.setFieldsValue({
                    is_public: false,
                });
                setValues({
                    ...values,
                    isFormFilled: "ready",
                    loading: false,
                    optionListLevel: defaultLevels,
                    optionListTag: defaultTags,
                    optionListLanguage: defaultLanguages,
                });
            }
        });
    }, []);

    // Rendering:
    const renderActionList = () => {
        if (isDetailPage) {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<SvgCross />}
                            title={params.id ? t("shared.back") : t("shared.cancel")}
                            onClick={handleGoBackNeedConfirm}
                        ></CustomButton>
                        {(!params.id || (params.id && isEditable && !values.isPublish)) && (
                            <CustomButton
                                type="grey"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save_draft")}
                                onClick={handleClickSaveDraft}
                            ></CustomButton>
                        )}
                        {!params.id || (params.id && isEditable) ? (
                            <CustomButton
                                type="primary"
                                icon={<Icon name="icon-rocket" />}
                                title={params.id && values.isPublish ? t("shared.save") : t("shared.publish")}
                                onClick={handleClickSave}
                            ></CustomButton>
                        ) : (
                            <>
                                {checkIfActionIsAllowed("question", "clone") === true ? (
                                    <CustomButton
                                        type="primary"
                                        icon={<Icon name="icon-rocket" />}
                                        title={t("shared.clone")}
                                        onClick={handleCloneQuestion}
                                    ></CustomButton>
                                ) : null}
                            </>
                        )}
                    </Form.Item>
                </div>
            );
        } else if (type === "exam_bank") {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<SvgCross />}
                            title={params.id ? t("shared.back") : t("shared.cancel")}
                            onClick={handleGoBackNeedConfirm}
                        ></CustomButton>
                        {!editingQuestion && (
                            <CustomButton
                                htmlType="submit"
                                type="grey"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save_draft")}
                                onClick={handleClickSaveDraft}
                            ></CustomButton>
                        )}
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("shared.save")}
                            onClick={handleClickSave}
                        ></CustomButton>
                    </Form.Item>
                </div>
            );
        } else {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<SvgCross />}
                            title={t("shared.cancel")}
                            onClick={handleGoBackNeedConfirm}
                        ></CustomButton>
                        {isEditable && (
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-rocket" />}
                                title={t("shared.save")}
                                onClick={handleClickSaveChild}
                            ></CustomButton>
                        )}
                    </Form.Item>
                </div>
            );
        }
    };

    return (
        <div className="question-detail-wrapper">
            <Breadcrumb
                isBack
                handleBack={handleGoBackNeedConfirm}
                routes={[
                    {
                        name: t(`q_detail.header_${currQType}`),
                    },
                ]}
                actions={[
                    {
                        title: t("nav.history"),
                        icon: <HistoryOutlined color={currentTab.tabKey === "modify" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: handleClickTabHistory,
                        className: currentTab.tabKey === "history" ? "action-button--primary" : "action-button--modify",
                        isDisabled: !params.id || isEditable || type === "exam_bank" ? false : true,
                    },
                    {
                        title: t("header.modify"),
                        icon: <Icon name="icon-modify" fill={currentTab.tabKey === "modify" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: handleClickTabModify,
                        className: currentTab.tabKey === "modify" ? "action-button--primary" : "action-button--modify",
                        isDisabled: !params.id || isEditable || type === "exam_bank" ? false : true,
                    },
                    {
                        title: t("header.preview"),
                        icon: <Icon name="icon-preview" fill={currentTab.tabKey === "preview" ? "#0077FF" : "#fff"} />,
                        link: "",
                        onClick: handleClickTabPreview,
                        className: currentTab.tabKey === "preview" ? "action-button--primary" : "action-button--modify",
                        isDisabled: values.loading,
                    },
                ]}
            />

            <Spin spinning={values.loading}>
                <Form form={form} onKeyDown={handleKeyDown}>
                    <div
                        className={`question-detail ${currQKey}`}
                        style={{
                            ...(currentTab.tabKey !== "modify" ? { display: "none" } : {}),
                        }}
                    >
                        <div className="question-detail-content">
                            {(isDetailPage || type === "exam_bank") && (
                                <SettingTopBar
                                    optionListLevel={values.optionListLevel}
                                    optionListTag={values.optionListTag}
                                    optionListLanguage={values.optionListLanguage}
                                    onChangeLevelList={(newLevelList) => {
                                        setValues({ ...values, optionListLevel: newLevelList });
                                    }}
                                    onChangeTagList={(newTagList) => {
                                        setValues({ ...values, optionListTag: newTagList });
                                    }}
                                    handleAddTagPending={() => {
                                        setValues({ ...values, loading: true });
                                    }}
                                    handleAddTagFulfilled={(newTagList) => {
                                        setValues({ ...values, loading: false, optionListTag: newTagList });
                                    }}
                                    onDeleteTag={handleDeleteTag}
                                    handleChangeLanguage={languageRef.current}
                                    onChangeLang={(language) => {
                                        setValues({ language: language });
                                    }}
                                    form={form}
                                />
                            )}

                            {renderQuestionDetailContent()}

                            {(isDetailPage || type === "exam_bank") && <SettingPublishingStatus />}
                        </div>

                        <div className="question-detail-actions">{renderActionList()}</div>
                    </div>

                    {currentTab.tabKey === "preview" && (
                        <div
                            className="question-detail-preview"
                            style={{
                                ...(currentTab.tabKey !== "preview" ? { display: "none" } : {}),
                            }}
                        >
                            <div className="question-preview-content">
                                <QuestionDetailPreview
                                    formQuestionDataPreview={formStates.formQuestionDataPreview}
                                    isScoring={isScoring}
                                />

                                {isDetailPage && <>{(!params.id || isEditable) && <SettingPublishingStatus />}</>}
                            </div>

                            <div className="question-preview-actions">{renderActionList()}</div>
                        </div>
                    )}
                    {currentTab.tabKey === "history" && (
                        <HistoryContainer modal_id={editingQuestion?.id || params.id} type="question" />
                    )}
                </Form>
            </Spin>

            <PopConfirm
                type="cancel"
                visible={isConfirm !== false}
                onOk={handleClickConfirmOk}
                onCancel={handleClickConfirmCancel}
            />
        </div>
    );
};

QuestionDetail.propTypes = {
    type: PropTypes.oneOf(["exam_bank", "question_detail"]),

    editingQuestion: PropTypes.shape({
        answer: PropTypes.array,
        id: PropTypes.string,
        question: PropTypes.string,
        type: PropTypes.oneOf(Object.keys(questionTypes)),
    }),

    onGoBack: PropTypes.func,
    refetch: PropTypes.func,
    closeModal: PropTypes.func,
    onChange: PropTypes.func,

    // Just pass these props in questionDetail of passage question
    typeOfQuestion: PropTypes.oneOf(Object.keys(questionTypes)),
    questionId: PropTypes.string,
    paragraphId: PropTypes.string,
    childId: PropTypes.string,
};

QuestionDetail.defaultProps = {
    type: "question_detail",
};

export default QuestionDetail;
