import {
    CaretUpOutlined,
    CompressOutlined,
    ExpandOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    SoundOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Popover, Slider, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import { SoundMuteIcon } from "src/utils/drawer";
import { convertMillisecondsToTime } from "src/utils/helpers/timer";

import "./VideoControls.scss";

const defaulFunction = function () {};

function VideoControls({
    isFullScreen = false,
    pip = false,
    playing = false,
    volume = 0.8,
    muted = false,
    played = 0,
    playedSeconds = 0,
    loaded = 0,
    duration = 1,
    playbackRate = 1.0,
    loop = false,
    seeking = false,
    listViolations = [],
    handleChangePlaybackRate = defaulFunction,
    onToggleFullScreen = defaulFunction,
    handleSeekMouseDown = defaulFunction,
    handleSeekMouseUp = defaulFunction,
    setSeekValue = defaulFunction,
    handlePlayPause = defaulFunction,
    handleToggleMuted = defaulFunction,
    handleVolumeChange = defaulFunction,
}) {
    const marksData = useMemo(() => {
        return listViolations.reduce((result, item) => {
            const time = parseFloat(item.time) / 1000;
            // const time = item.time;
            result[time] = {
                time: time,
                label: (
                    <Tooltip title={item.action}>
                        <CaretUpOutlined style={{ color: "red" }} />
                    </Tooltip>
                ),
            };
            return result;
        }, {});
    }, [listViolations, duration]);

    function handleSeekingChange(v) {
        if (!seeking) {
            handleSeekMouseDown();
        }
        setSeekValue(v);
    }

    return (
        <div className="video-controls_container">
            <div className="video-controls_inner">
                <div className="video-controls_left">
                    <Button
                        className="btn-play"
                        icon={playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                        shape="circle"
                        onClick={handlePlayPause}
                    />
                </div>
                <div className="video-controls_center">
                    <div className="video-progress-bar_container">
                        <Slider
                            marks={marksData}
                            defaultValue={0}
                            min={0}
                            max={duration}
                            step={0.05}
                            included
                            style={{ ["--bar_width"]: `${loaded * 100}%` }}
                            railStyle={{ backgroundColor: "#ddd" }}
                            value={playedSeconds}
                            onChange={handleSeekingChange}
                            onAfterChange={handleSeekMouseUp}
                            tipFormatter={(v) => <div>{convertMillisecondsToTime(v * 1000)}</div>}
                        />
                    </div>
                </div>
                <div className="video-controls_right">
                    <SoundBar volume={volume} onAfterChange={handleVolumeChange}>
                        <Button
                            shape="circle"
                            icon={muted ? <SoundMuteIcon /> : <SoundOutlined />}
                            onClick={handleToggleMuted}
                        />
                    </SoundBar>
                    <PlaybackRateSelector
                        playbackRate={playbackRate}
                        handleChangePlaybackRate={handleChangePlaybackRate}
                    />

                    <Button
                        shape="circle"
                        icon={isFullScreen ? <CompressOutlined /> : <ExpandOutlined />}
                        onClick={onToggleFullScreen}
                    />
                </div>
            </div>
        </div>
    );
}

function PlaybackRateSelector({ playbackRate, handleChangePlaybackRate }) {
    const menu = (
        <Menu onClick={handleChangePlaybackRate}>
            <Menu.Item key={0.5}>0.5x</Menu.Item>
            <Menu.Item key={1}>1x</Menu.Item>
            <Menu.Item key={1.5}>1.5x</Menu.Item>
            <Menu.Item key={2}>2x</Menu.Item>
            <Menu.Item key={3}>3x</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="top" arrow>
            <Button shape="circle"> {`${playbackRate}x`}</Button>
        </Dropdown>
    );
}

function SoundBar({ children, volume, onAfterChange }) {
    const [localVolumn, setLocalVolumn] = useState(volume);

    function onChangeVolumn(v) {
        setLocalVolumn(v / 100);
    }
    const bar = (
        <Slider
            defaultValue={80}
            step={1}
            vertical
            min={0}
            max={100}
            value={localVolumn * 100}
            style={{ height: 100 }}
            onChange={onChangeVolumn}
            onAfterChange={(v) => onAfterChange(v / 100)}
        ></Slider>
    );

    useEffect(() => {
        if (localVolumn !== volume) {
            setLocalVolumn(volume);
        }
    }, [volume]);

    return (
        <Popover content={bar} style={{ minHeight: 120 }}>
            {children}
        </Popover>
    );
}

export default VideoControls;
