import { t } from "i18next";
import { ZwibblerDrawer } from "src/modules/components/ZwibblerDrawer";
import QuestionContentDetail from "../ContentDetail";
import "./HighlightImage.scss";

const HighlightImage = (props) => {
    const { answered = [], question = "", width, height, src } = props;

    return (
        <div className="viewScoreStudent_highlightImage">
            <QuestionContentDetail
                isReadonly={true}
                value={{
                    question: question,
                }}
            />
            {/* <div className="viewScoreStudent_highlightImage-editor">  */}
            <ZwibblerDrawer {...{ defaultAnswer: answered, width, height, src }} isReadonly={true} />
            {/* </div> */}
        </div>
    );
};

export default HighlightImage;
