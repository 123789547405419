import { EyeFilled, EyeInvisibleFilled, LockFilled, MailFilled } from "@ant-design/icons";
import { Col, Form, Input, Radio, Row, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import { guestLoginWithFacebook, guestLoginWithGoogle, register, teacherLoginWithFacebook, teacherLoginWithGoogle } from "src/api/containers/auth";
import FacebookIcon from "src/assets/images/facebook-icon.png";
import GoogleIcon from "src/assets/images/google-icon.png";
import { ReactComponent as StudentRoleIcon } from "src/assets/images/student_role_icon.svg";
import { ReactComponent as TeacherRoleIcon } from "src/assets/images/teacher_role_icon.svg";
import { default as CustomButton } from "src/modules/components/Button";

import "./SignUpForm.scss";

const SignUpForm = (props) => {
    const { setLoading, getData } = props;

    const [form] = useForm();
    const [retypePassword, setRetypePassword] = useState("");
    const [loginWithGoogle, setLoginWithGoogle] = useState("");
    const [loginWithFacebook, setLoginWithFacebook] = useState("");
    const [role, setRole] = useState("");

    const handleSignUp = () => {
        if (form.getFieldValue("password") !== retypePassword) {
            notification.error({
                message: t("message.password_not_match"),
            });
            form.setFieldsValue({
                password: "",
                retype_password: "",
            });
            setRetypePassword("");
            return;
        }

        form.validateFields().then((formValues) => {
            formValues.role = "teacher";
            setLoading(true);
            register(formValues).then(({ status, message }) => {
                if (status) {
                    notification.success({
                        message: t("message.register_success"),
                    });
                    getData(form.getFieldValue("username"), form.getFieldValue("password"));
                    setLoading(false);
                } else {
                    notification.error({
                        message: message || t("message.register_error"),
                    });
                    setLoading(false);
                }
            });
        });
    };

    const onChangeRadioBtn = (e) => {
        if (e.target.value == 2) {
            setRole("teacher");
        } else {
            setRole("student");
        }
    };

    useEffect(() => {
        Promise.all([teacherLoginWithGoogle(), teacherLoginWithFacebook()]).then((res) => {
            setLoginWithGoogle(res[0]?.url);
            setLoginWithFacebook(res[1]?.url);
            localStorage.setItem("role", "teacher");
        });
    }, []);

    return (
        <>
            <div className="sign-in-container sign-up">
                <div className="sign-in-wrapper">
                    <h1>{t("login.sign_up_now")}</h1>
                    <h4>{t("login.please_fill_in_the_form")}</h4>

                    <Form form={form} onFinish={handleSignUp} layout="vertical" className="sign-in-form">
                        {/* <Form.Item
                            name="role"
                            rules={[
                                {
                                    required: true,
                                    message: t("message.please_check_role"),
                                },
                            ]}
                        >
                            <div className="radio-btn">
                                <h4 className="register-for">{t("login.open_account_for")}:</h4>

                                <Radio.Group name="role" onChange={onChangeRadioBtn}>
                                    <label>
                                        <span className={`radio-btn-item ${role == "student" && "checked"}`}>
                                            <StudentRoleIcon />
                                            <p>{t("login.student")}</p>
                                            <Radio value={1}></Radio>
                                        </span>
                                    </label>
                                    <label>
                                        <span className={`radio-btn-item ${role == "teacher" && "checked"}`}>
                                            <TeacherRoleIcon />
                                            <p>{t("login.teacher")}</p>
                                            <Radio value={2}></Radio>
                                        </span>
                                    </label>
                                </Radio.Group>
                            </div>
                        </Form.Item> */}

                        <Row gutter={[16, 8]}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="first_name"
                                    label={t("profile.first_name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile.field_is_require"),
                                        },
                                        {
                                            type: "string",
                                        },
                                    ]}
                                >
                                    <Input className="app-input has-rd" placeholder={t("profile.first_name")} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="last_name"
                                    label={t("profile.last_name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile.field_is_require"),
                                        },
                                        {
                                            type: "string",
                                        },
                                    ]}
                                >
                                    <Input className="app-input has-rd" placeholder={t("profile.last_name")} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name="username"
                            label={"Email"}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                                {
                                    type: "email",
                                    message: t("message.invalid_email"),
                                },
                            ]}
                        >
                            <Input
                                className="app-input has-rd"
                                placeholder={t("login.enter_your_email")}
                                prefix={<MailFilled />}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label={t("login.password")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                                {
                                    min: 4,
                                    message: t("message.password_rule"),
                                },
                            ]}
                        >
                            <Input.Password
                                className="app-input has-rd"
                                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                                prefix={<LockFilled />}
                                iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
                            />
                        </Form.Item>

                        <Form.Item
                            name="confirm_password"
                            label={t("login.retype_password")}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t("message.password_not_match")));
                                    },
                                }),
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                            dependencies={["password"]}
                        >
                            <Input.Password
                                className="app-input has-rd retype-password"
                                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                                value={retypePassword}
                                onChange={(e) => setRetypePassword(e.target.value)}
                                prefix={<LockFilled />}
                                iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <CustomButton
                                type="primary"
                                title={t("login.sign_up")}
                                className="submit-btn"
                                htmlType="submit"
                            />
                        </Form.Item>
                    </Form>

                    <p className="separator">
                        <span>{t("login.or_register_with")}</span>
                    </p>

                    <div className="sign-in-login-with">
                        <CustomButton
                            type="ghost"
                            icon={GoogleIcon}
                            title={t("login.login_with_google")}
                            hasResponsiveDesign={true}
                            onClick={() => window.open(loginWithGoogle, "_self")}
                        />
                        <CustomButton
                            type="ghost"
                            icon={FacebookIcon}
                            title={t("login.login_with_facebook")}
                            hasResponsiveDesign={true}
                            onClick={() => window.open(loginWithFacebook, "_self")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpForm;
