import { Dropdown, Space, Tag, notification } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchQuestionList, quickAddOrDeleteQuestionToPassage } from "src/api/containers/question";
import Bin from "src/assets/images/bin.svg";
import FilterIcon from "src/assets/images/filter-icon.svg";
import Pencil from "src/assets/images/pencil-white.svg";
import Rocket from "src/assets/images/rocket.svg";
import { useFetch, useValues } from "src/hooks";
import Button from "src/modules/components/Button";
import InputSearch from "src/modules/components/Input/InputSearch";
import ModalSelectQuestionType from "src/modules/components/Modal/ModalSelectQuestionType";
import Table from "src/modules/components/Table";
import FilterMenu from "src/modules/containers/QuestionBank/components/FilterMenu";
import "./QuickAddPassageQuestions.scss";
import { removeItemInArray, uniqueArray } from "src/utils/helpers";

const QuickAddPassageQuestions = (props) => {
    const user = useSelector((state) => state.auth.user);
    const {
        value = [],
        onChange = () => {},
        setIsModalAddQuestions = () => {},
        paragraphI,
        reloadPassageData = () => {},
    } = props;

    const fetchQuestionInitParams = { page: 1, slug: "", is_publish: true, owner_id: "all" };
    const [loading, dataSource, pagination, fetch, refetch] = useFetch(fetchQuestionInitParams, fetchQuestionList);
    const [newValue, setNewValue] = useState(() => value?.map((item) => item?._id));

    const [values, setValues] = useValues({
        isFilterMenuRendered: false,
    });
    const [isAddQuestionModal, setIsAddQuestionModal] = useState(false);
    const [filterSettings, setFilterSettings] = useState({
        inputSearchQuestion: "",
        isFilterMenuVisible: false,
        filterParams: [
            {
                name: "owner_id",
                value: fetchQuestionInitParams.owner_id,
                labelName: t("question.owner"),
                labelValue:
                    fetchQuestionInitParams.owner_id === "all" ? t("question.owner_all") : t(`question.owner_me`),
            },
            {
                name: "is_publish",
                value: true,
            },
        ],
    });

    const columns = [
        {
            title: t("shared.level"),
            dataIndex: "level",
            key: "level",
            width: "auto",
            render: (text, record) => record?.level?.name,
        },
        {
            title: t("question.tags"),
            dataIndex: "tags",
            key: "tags",
            width: "auto",
            render: (text, record) => {
                return (
                    <div className="tags-wrapper">
                        {record?.tags.map((tag) => (
                            <Tag className="app-tag-label" key={tag.id} color={tag.color}>
                                {tag.name.toUpperCase()}
                            </Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: t("question.owner"),
            dataIndex: "owner",
            key: "owner",
            width: "auto",
            render: (text, record) => record?.user?.name,
        },
        {
            title: t("q.question"),
            dataIndex: "question",
            key: "question",
            width: "auto",
            render: (text, record) => {
                let htmlStr = record?.question;
                htmlStr = (htmlStr || "").replace(/<img(.*?)>/g, "");
                return (
                    <div className="table-content-format">
                        <div className="text-limit-lines" dangerouslySetInnerHTML={{ __html: htmlStr }} />
                    </div>
                );
            },
        },
        {
            title: t("question.type"),
            dataIndex: "type",
            key: "type",
            width: "auto",
            render: (text, record) => record?.question_type?.name,
        },
    ];

    const handleAddQuestion = () => {
        // onChange(newValue);
        quickAddOrDeleteQuestionToPassage(paragraphI, newValue, "add").then((res) => {
            if (res.status) {
                notification.success({ message: t("message.add_success") });
                setIsModalAddQuestions(false);
                reloadPassageData();
            } else {
                notification.error({
                    message: res.message || t("message.update_error"),
                });
            }
        });
    };
    const handleSelectRow = (record, selected, selectedRows) => {
        if (selected) {
            setNewValue((oldValues) => [...oldValues, record?.id]);
        } else {
            setNewValue((oldValues) => removeItemInArray(oldValues, record?.id));
        }
    };

    const handleChangeSelectedRowKeys = (ids, selectedRows, info) => {
        // Update list of selected questions:
        // console.log({ ids, selectedRows, info });
        // setNewValue(ids);
    };

    const handleSearchQuestion = () => {
        if (filterSettings) {
            fetch({ page: 1, slug: filterSettings.inputSearchQuestion });
        }
    };

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
        // Fetch data if the filter menu has never been rendered:
        if (values.isFilterMenuRendered === false) {
            setValues({ ...values, isFilterMenuRendered: true });
            const newData = {};
            for (let i = 0; i < newFilterParams.length; i++) {
                newData[newFilterParams[i].name] = newFilterParams[i].value;
            }
            const fetchParams = {
                owner_id: newData.owner_id,
                level_id: newData.level_id,
                tag_name: newData.tag_name,
                type: newData.type,
                is_public: newData.is_public,
            };
            fetch({ page: 1, ...fetchParams });
        }
    };

    useEffect(() => {
        fetch({}, true);
    }, []);

    return (
        <div className="question-bank add-exam-bank">
            <div className="filter-toolbar-wrapper">
                <div className="filter-toolbar">
                    <div className="filter-toolbar-item filterinput-wrapper">
                        <InputSearch
                            displayType="style-dream"
                            placeholder={t("question.find_question")}
                            onChange={(e) =>
                                setFilterSettings({ ...filterSettings, inputSearchQuestion: e.target.value })
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearchQuestion();
                                }
                            }}
                            onClickIconSearch={handleSearchQuestion}
                        />
                    </div>
                    <div className="filter-toolbar-item filtermenu-wrapper">
                        <div className="filter-keys-bar-wrapper">
                            <Space className="filter-keys-bar" align="center" wrap size={4}>
                                {filterSettings.filterParams?.length > 0
                                    ? filterSettings.filterParams.map((fKey, i) => {
                                          if (!fKey.labelName && !fKey.labelValue) {
                                              return null;
                                          }
                                          return (
                                              <Tag
                                                  className="app-tag"
                                                  key={`filter-key${i}`}
                                                  closable
                                                  onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                              >
                                                  {/* {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`} */}
                                                  {`${fKey.labelValue || ""}`}
                                              </Tag>
                                          );
                                      })
                                    : // <>{t("question.option_filter")}</>
                                      null}
                            </Space>
                        </div>
                        <Dropdown
                            overlayStyle={{ zIndex: 1010 }}
                            visible={filterSettings.isFilterMenuVisible}
                            overlay={
                                <FilterMenu
                                    filterParams={filterSettings.filterParams}
                                    handleFetchQuestionList={fetch}
                                    handleCloseFilterMenu={(newFilterParams) =>
                                        setFilterSettings({
                                            ...filterSettings,
                                            isFilterMenuVisible: false,
                                            ...(newFilterParams && { filterParams: newFilterParams }),
                                        })
                                    }
                                    willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    excludeFields={["is_publish"]}
                                />
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                            onVisibleChange={(val) =>
                                setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                            }
                        >
                            <div className="filter-button">
                                <Button type="primary" icon={FilterIcon} title={t("question.option_filter")}></Button>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div className="filter-toolbar-actions">
                    <Button
                        type="primary"
                        icon={Pencil}
                        title={t("exam_bank.create_question")}
                        onClick={() => setIsAddQuestionModal(true)}
                    ></Button>
                </div>
            </div>

            <div className="table add-exam-table">
                <Table
                    rowSelection={{
                        selectedRowKeys: newValue,
                        // onChange: handleChangeSelectedRowKeys,
                        onSelect: handleSelectRow,
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        ...pagination,
                        position: ["bottomCenter"],
                        onChange: (page) => {
                            fetch({ page: page });
                        },
                    }}
                    scroll={{ x: "auto" }}
                    // onRow={(record) => ({ onClick: () => handleSelectRow(record) })}
                    rowKey="id"
                />
            </div>

            <div className="btn-group">
                <Button
                    type="grey"
                    icon={Bin}
                    title={t("action.cancel")}
                    onClick={() => setIsModalAddQuestions(false)}
                ></Button>
                <Button type="primary" icon={Rocket} title={t("action.confirm")} onClick={handleAddQuestion}></Button>
            </div>

            <ModalSelectQuestionType
                visible={isAddQuestionModal}
                onCancel={() => setIsAddQuestionModal(false)}
                type="exam_bank"
                refetch={refetch}
            ></ModalSelectQuestionType>
        </div>
    );
};

export default QuickAddPassageQuestions;
