import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import "./Stopwatch.scss";

function Stopwatch() {
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    let timeInterval = useRef(null);

    const handleStart = () => {
        if (isRunning) return;
        setIsRunning(true);
        timeInterval.current = setInterval(() => {
            setTimer((prev) => prev + 1);
        }, 1000);
    };

    const handlePause = () => {
        if (!isRunning) return;
        setIsRunning(false);
        clearInterval(timeInterval.current);
    };

    const handleReset = () => {
        setIsRunning(false);
        clearInterval(timeInterval.current);
        setTimer(0);
    };

    const formatTime = (timer) => {
        const duration = moment.duration(timer);
        // const hours = duration.hours();
        // const minutes = duration.minutes();
        // const seconds = duration.seconds();

        const hours = Math.floor(timer / 3600)
            .toString()
            .padStart(2, "0");
        const minutes = Math.floor(timer / 60)
            .toString()
            .padStart(2, "0");
        const seconds = (timer % 60).toString().padStart(2, "0");

        return { minutes, seconds, hours };
    };

    const { hours, minutes, seconds } = formatTime(timer);

    useEffect(() => {
        handleStart();

        return () => {
            handlePause();
        };
    }, []);

    return (
        <div className="stop-watch">
            {/* <h1>Reactjs Stopwatch</h1> */}
            <div className="timer-container flex flex-center">
                <div className="timer-box">
                    <span>{hours}</span>
                </div>
                <span className="colon">:</span>
                <div className="timer-box">
                    <span>{minutes}</span>
                </div>
                <span className="colon">:</span>
                <div className="timer-box">
                    <span>{seconds}</span>
                </div>
            </div>
            {/* <div className="button-container">
                <button onClick={handleStart}>Start</button>
                <button onClick={handlePause}>Pause</button>
                <button onClick={handleReset}>Reset</button>
            </div> */}
        </div>
    );
}

export default Stopwatch;
