import { Tabs } from "antd";
import React, { useState } from "react";
const { TabPane } = Tabs;
import "./ActivityTabs.scss";
import { Table } from "antd";
import ActivityTable from "../ActivityTable";
import { t } from "i18next";
import ListItemMobile from "../ListItemMobile";

const ActivityTabs = ({ setFiter, assignmentFetch, assignments, filterParams, mediaWidth }) => {
    const [tab, setTab] = useState("1");

    const onTabChange = (key) => {
        switch (key) {
            case "1":
                setFiter({ status: "donot", page: 1 });
                setTab(key);
                break;
            case "2":
                setFiter({ status: "doing", page: 1 });
                setTab(key);
                break;
            case "3":
                setFiter({ status: "submited", page: 1 });
                setTab(key);
                break;
            default:
                setFiter({ status: "marked", page: 1 });
                setTab("4");
                break;
        }
    };

    return (
        <Tabs onChange={onTabChange} type="card" defaultActiveKey={tab} className="activity-tabs" centered>
            <TabPane tab={t("gradebook.donot")} key="1">
                {/* <div className="table-top">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    bordered={false}
                    title={() => <Header />}
                />
            </div> */}

                <div className="table-bottom">
                    <ActivityTable
                        setFiter={setFiter}
                        assignmentFetch={assignmentFetch}
                        assignments={tab == "1" ? assignments : null}
                        filterParams={filterParams}
                        mediaWidth={mediaWidth}
                    />
                </div>
            </TabPane>
            <TabPane tab={t("gradebook.doing")} key="2">
                <div className="table-bottom">
                    <ActivityTable
                        setFiter={setFiter}
                        assignmentFetch={assignmentFetch}
                        assignments={tab == "2" ? assignments : null}
                        filterParams={filterParams}
                        mediaWidth={mediaWidth}
                    />
                </div>
            </TabPane>
            <TabPane tab={t("gradebook.submited")} key="3">
                <div className="table-bottom">
                    <ActivityTable
                        setFiter={setFiter}
                        assignmentFetch={assignmentFetch}
                        assignments={tab == "3" ? assignments : null}
                        filterParams={filterParams}
                        mediaWidth={mediaWidth}
                    />
                </div>
            </TabPane>
            <TabPane tab={t("gradebook.marked")} key="4">
                <div className="table-bottom">
                    <ActivityTable
                        setFiter={setFiter}
                        assignmentFetch={assignmentFetch}
                        assignments={tab == "4" ? assignments : null}
                        filterParams={filterParams}
                        mediaWidth={mediaWidth}
                    />
                </div>
            </TabPane>
        </Tabs>
    );
};

const columns = [
    {
        title: "#Activity Name",
        dataIndex: "activity",
        align: "left",
    },
    {
        title: "Time",
        className: "time",
        dataIndex: "time",
        align: "left",
    },
    {
        title: "Score",
        dataIndex: "score",
        align: "left",
        className: "score",
    },
];
const data = [
    {
        key: "1",
        activity: "1 Unit 1: Small Actions, Big Results - Part 1: Tips Saving For Water - Vocabulary Pratice 1",
        time: "5 mins ago",
        score: "1.0/10.0",
    },
    {
        key: "2",
        activity: "1 Unit 1: Small Actions, Big Results - Part 1: Tips Saving For Water - Vocabulary Pratice 1",
        time: "5 mins ago",
        score: "1.0/10.0",
    },
    {
        key: "3",
        activity: "1 Unit 1: Small Actions, Big Results - Part 1: Tips Saving For Water - Vocabulary Pratice 1",
        time: "5 mins ago",
        score: "1.0/10.0",
    },
];

const Header = () => {
    return (
        <div>
            <h4>Your recent Submissions</h4>
            <div>
                Following are recently submitted (last 30 minutes) gradebook activities, show here for quick access.
                These records are also available in your gradebook table below. Select the score link to view details.
            </div>
        </div>
    );
};

export default ActivityTabs;
