import { Divider, Dropdown, Space, Spin, Tag } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button, { default as CustomButton } from "src/modules/components/Button";
import { useValues } from "src/hooks";
import FilterMenu from "./FilterMenu";
import { fetchHistories } from "src/api/containers/history";
import Empty from "src/modules/components/Empty";
import moment from "moment";

import InfiniteScroll from "react-infinite-scroll-component";
import { deepEqualObj } from "src/utils/helpers";
import { HistoryTimeLine } from "../../components/HistoryTimeline";
import clsx from "clsx";
import "./HistoryContainer.scss";

const Limit_History_Items = 100;

/**
 * @type {string}: all_history, question_history, assignemt_history, exam_question_history, course_user_history
 */
const HistoryContainer = ({ modal_id, type = "" }) => {
    const { t } = useTranslation();

    const [values, setValues] = useValues({
        isFilterMenuVisible: false,
        loadingTimeline: false,
        histories: [],
        page: 0,
        totalPages: undefined,
        oldFetchData: {},

        filterParams: [
            {
                name: "begin_date",
                value: moment().format("YYYY-MM-DD"),
                labelName: t("report.dateFrom"),
                labelValue: moment().format("YYYY-MM-DD"),
            },
            {
                name: "end_date",
                value: moment().format("YYYY-MM-DD"),
                labelName: t("report.dateFrom"),
                labelValue: moment().format("YYYY-MM-DD"),
            },
        ],
    });

    const filterParamsToFormData = (filterParams) => {
        const newFormData = {};
        for (let i = 0; i < filterParams.length; i++) {
            newFormData[filterParams[i].name] = filterParams[i].value;
        }
        return newFormData;
    };

    const loadMoreData = (filterParams = {}) => {
        if (values.loadingTimeline) {
            return;
        }

        if (filterParams.page >= values.totalPages) {
            return;
        }

        setValues({ loadingTimeline: true });
        fetchHistories({
            ...filterParams,
            page: filterParams.page || filterParams.page === 0 ? filterParams.page + 1 : values.page + 1,
            perPage: 25,
            relations: type === "question" ? ["user", "question"] : undefined,
            courseUser_course_id: type === "course_user_history" ? modal_id : undefined,
        })
            .then((resData) => {
                let nexHistories = resData.data?.length ? resData.data : [];
                nexHistories = nexHistories?.filter((item) => {
                    // loại bỏ 1 số field thừa
                    const question = item?.question;
                    if (question?.type === "highlight" && ["answer", "passage_student"].includes(item?.field_name)) {
                        return false;
                    }
                    return true;
                });
                setValues({
                    page: resData.pagination?.current,
                    listCountItems: resData.pagination?.total
                        ? resData.pagination?.total <= Limit_History_Items
                            ? resData.pagination?.total
                            : Limit_History_Items
                        : 0,
                    histories:
                        resData.pagination?.current === 1 ? nexHistories : [...values.histories, ...nexHistories],
                    loadingTimeline: false,
                    totalPages: resData.pagination?.lastPage,
                });
            })
            .catch(() => {
                setValues({ loadingTimeline: false });
            });
    };

    const handleScrollToTop = () => {
        const ele = document.getElementById("scrollableDiv");
        if (ele) {
            ele.scrollTo({
                top: 0,
                behavior: "smooth",
            }); // Reset scroll to top
        }
    };

    const getFetchParams = () => {
        const formData = filterParamsToFormData(values.filterParams);

        const fetchData = {
            page: values.page,
            model_id: modal_id,
            type: formData?.type,
            begin_date: formData?.begin_date ? moment(formData?.begin_date).format("YYYY-MM-DD") : undefined,
            end_date: formData?.end_date ? moment(formData?.end_date).format("YYYY-MM-DD") : undefined,
        };

        return fetchData;
    };

    useEffect(() => {
        if (modal_id) {
            const fetchData = getFetchParams();

            if (!deepEqualObj(fetchData, values.oldFetchData)) {
                fetchData.page = 0;
                handleScrollToTop();
            }
            loadMoreData(fetchData);
            setValues({ oldFetchData: fetchData });
        }
    }, [modal_id, values.filterParams]);

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = values.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setValues({ filterParams: newFilterParams });
    };

    return (
        <Spin spinning={values.loadingTimeline}>
            <div className="timeline_container">
                <div className="timeline_actions">
                    <div className="timeline_filter">
                        <div className="filter-toolbar-wrapper">
                            <div className="filter-toolbar">
                                <div className="filter-toolbar-item filterinput-wrapper" style={{ width: 0 }}></div>
                                <div className="filter-toolbar-item filtermenu-wrapper" style={{ flexBasis: 100 }}>
                                    <div className="filter-keys-bar-wrapper">
                                        <Space className="filter-keys-bar" align="center" wrap size={4}>
                                            {values.filterParams?.length > 0
                                                ? values.filterParams.map((fKey, i) => {
                                                      if (!fKey.labelName && !fKey.labelValue) {
                                                          return null;
                                                      }
                                                      return (
                                                          <Tag
                                                              className="app-tag"
                                                              key={`filter-key${i}`}
                                                              closable
                                                              onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                                          >
                                                              {`${fKey.labelValue || ""}`}
                                                          </Tag>
                                                      );
                                                  })
                                                : // <>{t("question.option_filter")}</>
                                                  null}
                                        </Space>
                                    </div>
                                    <Dropdown
                                        overlayStyle={{ zIndex: 1010 }}
                                        visible={values.isFilterMenuVisible}
                                        overlay={
                                            <FilterMenu
                                                filterParams={values.filterParams}
                                                handleFetchQuestionList={fetch}
                                                handleCloseFilterMenu={(newFilterParams) => {
                                                    setValues({
                                                        isFilterMenuVisible: false,
                                                        ...(newFilterParams && { filterParams: newFilterParams }),
                                                    });
                                                }}
                                                willFormBeCleared={!values.isFilterMenuVisible}
                                                excludeFields={["is_publish"]}
                                            />
                                        }
                                        trigger={["click"]}
                                        placement="bottomRight"
                                        onVisibleChange={(val) => setValues({ isFilterMenuVisible: val })}
                                    >
                                        <div className="filter-button">
                                            <Button
                                                type="primary"
                                                // icon={FilterIcon}
                                                title={t("question.option_filter")}
                                            ></Button>
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div
                    className={clsx({ timeline_body: true, "exam-question": type === "exam_question_history" })}
                    id="scrollableDiv"
                >
                    <InfiniteScroll
                        scrollableTarget={"scrollableDiv"}
                        dataLength={values.histories.length}
                        next={() => {
                            loadMoreData(getFetchParams());
                        }}
                        hasMore={true}
                        loader={
                            values.loadingTimeline ? (
                                // <Skeleton
                                //     paragraph={{
                                //         rows: 5,
                                //     }}
                                //     active={values.loadingTimeline}
                                // />
                                <Spin spinning={values.loadingTimeline} />
                            ) : null
                        }
                        endMessage={
                            values.histories?.length > 0 && (
                                <Divider className="list-end-message" plain>
                                    {t("shared.list_end")}
                                </Divider>
                            )
                        }
                    >
                        <HistoryTimeLine histories={values.histories} type={type} />
                    </InfiniteScroll>
                    {values.histories?.length === 0 && <Empty />}
                </div>
            </div>
        </Spin>
    );
};

export default HistoryContainer;
