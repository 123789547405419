import clsx from "clsx";
import { useEffect, useState } from "react";

import EditableMathField from "src/modules/components/MathQuill/EditableMathField";

import "./InputLatex.scss";
import LatexOptionsDropdown from "./components/LatexOptionsDropdown";

const InputLatex = ({
    className,
    disabled,
    placeholder,
    value,
    onChange,
    // Latex dropdown:
    dropdownProps,
    // Latex options:
    filterOptions,
    filterOptionSelected,
}) => {
    const [latexValue, setLatexValue] = useState("");

    const change = (_value) => {
        if (onChange instanceof Function) {
            onChange(_value);
        }
    };

    const handleChange = (_latexValue) => {
        setLatexValue(_latexValue);
        change(_latexValue);
    };

    const handleSelectOption = (_opt) => {
        if (_opt) {
            setLatexValue(latexValue + _opt);
        }
    };

    useEffect(() => {
        if (value !== latexValue) {
            setLatexValue(value || "");
        }
    }, [value]);

    return (
        <LatexOptionsDropdown
            filterOptions={filterOptions}
            filterOptionSelected={filterOptionSelected}
            onSelectOption={handleSelectOption}
            {...dropdownProps}
        >
            <div className={clsx("app-input-latex", className)}>
                <div className="input-latex">
                    <EditableMathField
                        latex={latexValue}
                        onChange={handleChange}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </div>
            </div>
        </LatexOptionsDropdown>
    );
};

export default InputLatex;
