import { HistoryOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Spin, notification } from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { fetchLanguages } from "src/api/containers/language";
import { fetchLevels } from "src/api/containers/level";
import {
    cloneQuestion,
    deleteChildPassage,
    deleteChildQuestion,
    findPassageQuestion,
    questionTypes,
    quickAddOrDeleteQuestionToPassage,
    updateQuestion,
} from "src/api/containers/question";
import { deleteTag, fetchTags } from "src/api/containers/tag";
import { checkIfActionIsAllowed } from "src/api/helpers/actions";
import { getQuestionDataPreview } from "src/api/helpers/question";
import { checkIf_User_CanEditQuestion } from "src/api/utils/question";
import { useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
import Header from "src/modules/components/Header";
import Icon from "src/modules/components/Icon";
import ModalSelectQuestionType from "src/modules/components/Modal/ModalSelectQuestionType";
import QuestionContent from "src/modules/components/QuestionContent";
import QuestionPanel from "src/modules/components/QuestionPanel";
import DragDrop from "src/modules/components/QuestionPanel/DragDrop";
import DragDropGroup from "src/modules/components/QuestionPanel/DragDropGroup";
import FillBlanks from "src/modules/components/QuestionPanel/FillBlanks";
import FillBlanksDragDrop from "src/modules/components/QuestionPanel/FillBlanksDragDrop";
import FillBlanksInput from "src/modules/components/QuestionPanel/FillBlanksInput";
import Graph from "src/modules/components/QuestionPanel/Graph";
import GraphNumberLine from "src/modules/components/QuestionPanel/GraphNumberLine";
import HighlightImage from "src/modules/components/QuestionPanel/HighlightImage";
import Hotspot from "src/modules/components/QuestionPanel/Hotspot";
import ImageWithLabels from "src/modules/components/QuestionPanel/ImageWithLabels";
import MathTextDropdown from "src/modules/components/QuestionPanel/MathTextDropdown";
import Matrix from "src/modules/components/QuestionPanel/Matrix";
import MultipleChoice from "src/modules/components/QuestionPanel/MultipleChoice";
import MultipleChoiceBoolean from "src/modules/components/QuestionPanel/MultipleChoiceBoolean";
import MultipleChoiceSentenceQuiz from "src/modules/components/QuestionPanel/MultipleChoiceSentenceQuiz";
import MultipleChoiceTable from "src/modules/components/QuestionPanel/MultipleChoiceTable";
import PhoneticTable from "src/modules/components/QuestionPanel/PhoneticTable";
import Shading from "src/modules/components/QuestionPanel/Shading";
import Sort from "src/modules/components/QuestionPanel/Sort";
import SpeechToText from "src/modules/components/QuestionPanel/SpeechToText";
import SpeechToTextConversationAudio from "src/modules/components/QuestionPanel/SpeechToTextConversationAudio";
import SpeechToTextConversationText from "src/modules/components/QuestionPanel/SpeechToTextConversationText";
import Writing from "src/modules/components/QuestionPanel/Writing";
import WritingShort from "src/modules/components/QuestionPanel/WritingShort";
import { HistoryContainer } from "src/modules/containers/ManageHistory/HistoryFeature/HistoryContainer";
import { animationId } from "src/reducers/animation";
import { setCurrentPage as setCurrentPage1 } from "src/reducers/general";
import { useRouting } from "src/utils/router";

import QuestionDetail from "../..";
import SettingPublishingStatus from "../../components/SettingPublishingStatus";
import SettingTopBar from "../../components/SettingTopBar";
import DetailPassage from "./DetailPassage";
import "./DetailPassageQuestion.scss";
import QuickAddPassageQuestions from "./QuickAddPassageQuestions";

function DetailPassageQuestion(props) {
    const {
        type = "",
        closeModal = () => {},
        refetch = () => {},
        editingQuestion = null,
        language,
        onChange = () => {},
    } = props;

    // User:
    const user = useSelector((state) => state.auth.user);

    // Form:
    const [form] = Form.useForm();
    const [formStates, setFormStates] = useState({
        // formQuestionData: undefined, // Form data to be used for any child/tab.
        // formQuestionDataPreview: undefined, // Form data to be used for preview tab only.
        questionParagraphsPreview: undefined, // Question paragraphs data to be used for preview tab only.
    });

    const params = useParams();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();
    const [values, setValues] = useValues({
        loading: false,
        optionListLevel: [],
        optionListTag: [],
        optionListLanguage: [],
        ownerID: "",
        isPublish: false,
        isEmployeeCanEdit: false,
        paragraphs: [],
        language,
        currentParagraphQuestions: [],
        questionOwnerRole: undefined,
    });

    const [currentTab, setCurrentTab] = useState({
        tabKey: "modify", // Values: "modify" || "preview" ||history|| "".
        data: null,
    });
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
    const [showQuickAddQuestionModal, setShowQuickAddQuestionModal] = useState(false);
    const [selectedParagraphId, setSelectedParagraphId] = useState("");
    const [isShowAnswer, setIsShowAnswer] = useState(false);

    const [currentPage, setCurrentPage] = useState({
        page: "",
        params: {},
    });
    const [currQuestionId, setCurrQuestionId] = useState(
        type === "exam_bank" && editingQuestion ? editingQuestion?.id : type === "exam_bank" ? "" : params.id
    );
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();

    // Permission:
    const isEditable = useMemo(() => {
        return checkIf_User_CanEditQuestion(
            "type-2",
            user,
            { employee_can_edit: values.isEmployeeCanEdit },
            { id: values.ownerID, role: values.questionOwnerRole }
        );
    }, [user, values.ownerID, values.isEmployeeCanEdit, values.questionOwnerRole]);

    const saveData = (isPublish = false) => {
        let formData = { ...(currentTab.data || {}), ...form.getFieldsValue() };

        if (isPublish === true && (!formData.level_id || !formData.question)) {
            notification.warning({
                message: t("message.warning_missing_fields"),
            });
            return;
        }
        if (values.paragraphs.length === 0) {
            notification.error({
                message: t("question_passage.require_question_data"),
            });
            return;
        }

        // Generate values to be sent to database:
        const { is_public, ...rest } = formData;
        // - Question scope:
        let isPublic = is_public;
        // - Question form data:
        formData = {
            ...rest,
            type: "passage",
            is_publish: isPublish,
            is_public: isPublic || false,
        };

        // Make api calls:
        setValues({ ...values, loading: true });
        if (currQuestionId) {
            updateQuestion(currQuestionId, formData).then((res) => {
                if (res.status) {
                    dispatch(animationId({ name: "question", update: currQuestionId }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    if (type === "exam_bank") {
                        closeModal();
                        if (refetch) refetch();
                    } else {
                        navigate(generate("question_bank"));
                    }
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
                if (onChange && onChange instanceof Function) {
                    formData.id = editingQuestion?.id;
                    formData.level = values.optionListLevel.find((item) => item.id === formData.level_id);
                    formData.tags = values.optionListTag.filter((item) => formData.tag_ids.includes(item.id));
                    onChange(formData);
                }
                setValues({ ...values, loading: false });
            });
        } else {
            setValues({ ...values, loading: false });
            closeModal();
        }
    };

    const handleSaveAsDraft = () => {
        saveData(false);
    };

    const handleSubmit = () => {
        saveData(true);
    };

    const handleChangeChildQuestion = (value) => {
        const newScore = values.paragraphs.reduce((total, cur) => {
            const scoreOfParagraph = cur.question_details.reduce((total, q) => {
                // Trường hợp cộng điểm cho lúc cập nhật câu hỏi con
                if (q._id === value.childId) {
                    return (total += value.score);
                }

                return (total += q.score);
            }, 0);

            return (total += scoreOfParagraph);
        }, 0);

        // Case update childId
        if (value.childId) {
            onChange({
                id: currQuestionId,
                score: newScore,
            });
        } // Case create new question
        else if (value.paragraphId) {
            onChange({
                id: currQuestionId,
                score: newScore + value.score,
            });
        } else {
        }
    };

    const handleCloneQuestion = (e) => {
        e.preventDefault();
        cloneQuestion(currQuestionId).then(({ status, message, id }) => {
            if (status) {
                dispatch(animationId({ name: "question", clone: id }));
                notification.success({
                    message: t("message.clone_success"),
                });
                navigate(generate("question_bank"));
            } else {
                notification.error({
                    message: message || t("message.clone_error"),
                });
            }
        });
    };

    const handleGoBack = (e) => {
        e.preventDefault();
        if (type === "exam_bank") {
            closeModal();
        } else {
            navigate(generate("question_bank"));
        }
    };

    const handleClickAddChildPassage = (questionId) => {
        if (questionId) {
            setCurrentPage({
                page: "child-passage",
                params: {
                    questionId: questionId,
                },
            });
        } else {
            setCurrentPage({
                page: "child-passage",
                params: {},
            });
        }
    };

    const handleDeleteChildPassage = (childId) => {
        if (childId) {
            setValues({ ...values, loading: true });
            deleteChildPassage(childId).then((res) => {
                if (res.status) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload passage detail:
                    findPassageQuestion(currQuestionId).then((res) => {
                        if (res.status) {
                            const childParagraphs = res?.data?.paragraphs || [];
                            let finalScore = 0;
                            childParagraphs.forEach((paragraph) => {
                                let total_score = 0;
                                paragraph?.question_details?.forEach((question) => {
                                    total_score += question.score;
                                });
                                paragraph.total_score = total_score;
                                finalScore += total_score;
                            });

                            form.setFieldsValue({
                                score: finalScore,
                            });

                            onChange({
                                id: currQuestionId,
                                score: finalScore,
                            });

                            setValues({
                                ...values,
                                loading: false,
                                paragraphs: res.data?.paragraphs || [],
                            });
                        }
                    });
                }
            });
        }
    };

    const handleDeleteChildQuestion = (childId) => {
        if (childId) {
            setValues({ ...values, loading: true });
            deleteChildQuestion(childId).then((res) => {
                if (res.status === true) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload passage detail:
                    findPassageQuestion(currQuestionId).then((res) => {
                        if (res.status) {
                            const childParagraphs = res?.data?.paragraphs || [];
                            let finalScore = 0;
                            childParagraphs.forEach((paragraph) => {
                                let total_score = 0;
                                paragraph?.question_details?.forEach((question) => {
                                    total_score += question.score;
                                });
                                paragraph.total_score = total_score;
                                finalScore += total_score;
                            });

                            form.setFieldsValue({
                                score: finalScore,
                            });

                            onChange({
                                id: currQuestionId,
                                score: finalScore,
                            });

                            setValues({
                                ...values,
                                loading: false,
                                paragraphs: res.data?.paragraphs || [],
                            });
                        }
                    });
                }
            });
        }
    };

    const reloadPassageData = () => {
        setValues({ loading: true });
        // Reload passage detail:
        findPassageQuestion(currQuestionId).then((res) => {
            if (res.status) {
                const childParagraphs = res?.data?.paragraphs || [];
                let finalScore = 0;
                childParagraphs.forEach((paragraph) => {
                    let total_score = 0;
                    paragraph?.question_details?.forEach((question) => {
                        total_score += question.score;
                    });
                    paragraph.total_score = total_score;
                    finalScore += total_score;
                });

                form.setFieldsValue({
                    score: finalScore,
                });

                onChange({
                    id: currQuestionId,
                    score: finalScore,
                });

                setValues({
                    loading: false,
                    paragraphs: res.data?.paragraphs || [],
                });
            }
        });
    };

    const handleDeleteChildQuestion2 = (paragraphId, childId) => {
        quickAddOrDeleteQuestionToPassage(paragraphId, [childId], "delete").then((res) => {
            if (res.status) {
                notification.success({ message: t("message.delete_success") });
                reloadPassageData();
            } else {
                notification.error({ message: res?.message || t("message.delete_error") });
            }
        });
    };

    const handleShowModalAddChildQuestion = (paragraphId) => {
        if (paragraphId) {
            setSelectedParagraphId(paragraphId);
            setShowAddQuestionModal(true);
        }
    };

    const handleShowModalQuickAddQuestion = (paragraphId, paragraphIndex) => {
        if (paragraphId) {
            setSelectedParagraphId(paragraphId);
            setShowQuickAddQuestionModal(true);
            setValues({
                currentParagraphQuestions: values.paragraphs?.[paragraphIndex]?.question_details,
            });
        }
    };

    const handleDeleteTag = (e, id) => {
        e.stopPropagation();

        deleteTag(id).then((res) => {
            if (res.status) {
                const newOptionListTag = values.optionListTag.filter((opt) => opt.id !== id);

                setValues({
                    optionListTag: newOptionListTag,
                });

                notification.success({ message: t("tag.remove_tag_success") });
            } else {
                notification.error({ message: t("tag.remove_tag_error") });
            }
        });
    };

    const redirectToChildQuestionDetail = (type, questionId, childId) => {
        // QUESTION DETAIL V2:
        setCurrentPage({
            page: `child-question-${type}`,
            params: {
                questionId: questionId,
                childId: childId,
            },
        });
    };

    useEffect(() => {
        dispatch(setCurrentPage1("passage_question"));
        if (currentPage.page) {
            return;
        }
        if (currQuestionId) {
            setValues({ ...values, loading: true });
            Promise.all([
                findPassageQuestion(currQuestionId),
                fetchLevels({ slug: "", noPagination: true }),
                fetchTags({ slug: "", owner_id: user.id, page: 1 }),
                fetchLanguages({ slug: "", noPagination: true }),
            ]).then((res) => {
                if (res[0].status && res[1].status && res[2].status) {
                    // 1. Get default level list:
                    const defaultLevels = res[1].data ? [...res[1].data] : [];
                    // 2. Get default tag list:
                    let defaultTags = res[2].data ? [...res[2].data] : [];
                    if (res[0].data?.tags?.length) {
                        if (user.id !== res[0].data?.user_id) {
                            defaultTags = [...res[0].data?.tags];
                        } else {
                            const extraTags = res[0].data?.tags.filter((tagItem) => {
                                for (let i = 0; i < defaultTags.length; i++) {
                                    if (tagItem.id === defaultTags[i].id) {
                                        return false;
                                    }
                                }
                                return true;
                            });
                            defaultTags = [...defaultTags, ...extraTags];
                        }
                    }
                    // 3. Get default language list:
                    const defaultLanguages = res[3].data ? res[3].data : [];

                    const childParagraphs = res[0].data?.paragraphs || [];
                    let finalScore = 0;
                    childParagraphs.forEach((paragraph) => {
                        let total_score = 0;
                        paragraph?.question_details?.forEach((question) => {
                            total_score += question.score;
                        });
                        paragraph.total_score = total_score;
                        finalScore += total_score;
                    });

                    const questionFormData = {
                        is_public:
                            form.getFieldValue("is_public") !== undefined
                                ? form.getFieldValue("is_public")
                                : res[0].data?.is_public || false,
                        is_publish: res[0].data?.is_publish || false,
                        level_id: form.getFieldValue("level_id") || res[0].data?.level?.id || undefined,
                        tag_ids:
                            form.getFieldValue("tag_ids") ||
                            (res[0].data?.tags?.length ? res[0].data?.tags?.map((tag) => tag.id) : []),
                        language_id: form.getFieldValue("language_id") || res[0].data?.language?.id || undefined,
                        question: form.getFieldValue("question") || res[0].data?.question || "",
                        score: finalScore,
                    };

                    const langCode = res[0].data?.language?.code;
                    setUrlSearchParams({ lang: langCode, language_id: res[0].data?.language?.id });

                    // Update form state:
                    form.setFieldsValue(questionFormData);
                    setValues({
                        ...values,
                        loading: false,
                        optionListLevel: defaultLevels,
                        optionListTag: defaultTags,
                        optionListLanguage: defaultLanguages,
                        ownerID: res[0].data?.user_id || "",
                        isPublish: res[0].data?.is_publish || false,
                        isEmployeeCanEdit: res[0].data?.employee_can_edit || false,
                        paragraphs: res[0].data?.paragraphs || [],
                        finalScore: finalScore,
                        language: res[0].data?.language,
                        questionOwnerRole: res[0].data?.user?.role,
                    });

                    if (
                        !checkIf_User_CanEditQuestion("type-2", user, res[0].data, {
                            id: res[0].data?.user_id,
                            role: res[0].data?.user?.role,
                        })
                    ) {
                        setCurrentTab({ ...currentTab, tabKey: "preview" });
                    }
                }
            });
        } else {
            setValues({ ...values, loading: true });
            Promise.all([
                fetchLevels({ slug: "", noPagination: true }),
                fetchTags({ slug: "", owner_id: user.id, page: 1 }),
                fetchLanguages({ slug: "", noPagination: true }),
            ]).then((res) => {
                if (res[0].status && res[1].status) {
                    // 1. Get default level list:
                    const defaultLevels = res[0].data ? [...res[0].data] : [];
                    // 2. Get default tag list:
                    const defaultTags = res[1].data ? [...res[1].data] : [];
                    // 3. Get default language list:
                    const defaultLanguages = res[2].data ? res[2].data : [];
                    // 4. Update form state:
                    form.setFieldsValue({
                        is_public: false,
                        score: 0,
                    });
                    setValues({
                        ...values,
                        loading: false,
                        optionListLevel: defaultLevels,
                        optionListTag: defaultTags,
                        optionListLanguage: defaultLanguages,
                    });
                }
            });
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentTab.tabKey === "preview" && !formStates.questionParagraphsPreview?.length) {
            const questionParagraphsPreviewNew = values.paragraphs?.map((pItem) => {
                return {
                    ...pItem,
                    question_details: pItem.question_details?.map((qItem) => {
                        return getQuestionDataPreview(qItem);
                    }),
                };
            });
            setFormStates({
                ...formStates,
                questionParagraphsPreview: questionParagraphsPreviewNew,
            });
        }
    }, [currentTab.tabKey]);

    const renderActionList = () => {
        if (type !== "exam_bank") {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={currQuestionId ? t("shared.back") : t("shared.cancel")}
                            onClick={(e) => handleGoBack(e)}
                        ></CustomButton>

                        {(!currQuestionId || (currQuestionId && isEditable && currQuestionId && !values.isPublish)) && (
                            <CustomButton
                                htmlType="submit"
                                type="grey"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save_draft")}
                                onClick={handleSaveAsDraft}
                            ></CustomButton>
                        )}

                        {!currQuestionId || (currQuestionId && isEditable) ? (
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-rocket" />}
                                title={currQuestionId && values.isPublish ? t("shared.save") : t("shared.publish")}
                                onClick={handleSubmit}
                            ></CustomButton>
                        ) : (
                            <>
                                {checkIfActionIsAllowed("question", "clone") === true ? (
                                    <CustomButton
                                        htmlType="submit"
                                        type="primary"
                                        icon={<Icon name="icon-rocket" />}
                                        title={t("shared.clone")}
                                        onClick={handleCloneQuestion}
                                    ></CustomButton>
                                ) : null}
                            </>
                        )}
                    </Form.Item>
                </div>
            );
        } else {
            return (
                <div className="btn-group">
                    <Form.Item>
                        <CustomButton
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={currQuestionId ? t("shared.back") : t("shared.cancel")}
                            onClick={(e) => handleGoBack(e)}
                        ></CustomButton>
                        {!editingQuestion && (
                            <CustomButton
                                htmlType="submit"
                                type="grey"
                                icon={<Icon name="icon-save" />}
                                title={t("shared.save_draft")}
                                onClick={handleSaveAsDraft}
                            ></CustomButton>
                        )}
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("shared.save")}
                            onClick={handleSubmit}
                        ></CustomButton>
                    </Form.Item>
                </div>
            );
        }
    };

    const renderChildPage = () => {
        if (currentPage.page === "child-passage") {
            return (
                <DetailPassage
                    questionId={currentPage.params.questionId || ""}
                    childId={currentPage.params.childId || ""}
                    onGoBack={(idNewPassageQuestion) => {
                        setCurrentPage({ page: "", params: {} });
                        if (idNewPassageQuestion && typeof idNewPassageQuestion === "string") {
                            setCurrQuestionId(idNewPassageQuestion);
                        }
                        if (idNewPassageQuestion && type !== "exam_bank") {
                            navigate(generate("update_passage", { id: idNewPassageQuestion }));
                        }
                    }}
                    type={type}
                    speaker={getLangSelect()}
                ></DetailPassage>
            );
        } else {
            return (
                <QuestionDetail
                    typeOfQuestion={currentPage.page.replace("child-question-", "")}
                    questionId={currentPage.params.questionId || ""}
                    paragraphId={currentPage.params.paragraphId || ""}
                    childId={currentPage.params.childId || ""}
                    onGoBack={() => setCurrentPage({ page: "", params: {} })}
                    onChange={handleChangeChildQuestion}
                ></QuestionDetail>
            );
        }
    };

    const handleChangePassage = (newValue) => {
        setCurrentTab({
            ...currentTab,
            data: {
                ...currentTab.data,
                answered: newValue.answered,
            },
        });
    };

    const getLangSelect = () => {
        const lang = urlSearchParams.get("lang");
        return lang;
    };

    // // dòng này note lại: đưa paragraph-title lên trên paragraph-heading, sau đó đưa renderChildPage ra ngoài

    // if (currentPage.page) {
    //     return renderChildPage();
    // }

    return (
        <>
            {!currentPage.page ? (
                <div className="question-detail-wrapper">
                    <Breadcrumb
                        isBack
                        handleBack={(e) => handleGoBack(e)}
                        routes={[
                            {
                                name: t("q_detail.header_passage"),
                            },
                        ]}
                        actions={[
                            {
                                title: t("nav.history"),
                                icon: <HistoryOutlined color={currentTab.tabKey === "modify" ? "#0077FF" : "#fff"} />,
                                link: "",
                                onClick: () => setCurrentTab({ ...currentTab, tabKey: "history", data: null }),
                                className:
                                    currentTab.tabKey === "history"
                                        ? "action-button--primary"
                                        : "action-button--modify",
                                isDisabled: !params.id || isEditable || type === "exam_bank" ? false : true,
                            },
                            {
                                title: t("header.modify"),
                                icon: (
                                    <Icon
                                        name="icon-modify"
                                        fill={currentTab.tabKey === "modify" ? "#0077FF" : "#fff"}
                                    />
                                ),
                                link: "",
                                onClick: () => {
                                    setCurrentTab({ ...currentTab, tabKey: "modify", data: null });
                                },
                                className:
                                    currentTab.tabKey === "modify" ? "action-button--primary" : "action-button--modify",
                                isDisabled: !params.id || isEditable || type === "exam_bank" ? false : true,
                            },
                            {
                                title: t("header.preview"),
                                icon: (
                                    <Icon
                                        name="icon-preview"
                                        fill={currentTab.tabKey === "preview" ? "#0077FF" : "#fff"}
                                    />
                                ),
                                link: "",
                                onClick: () => {
                                    if (currentTab.tabKey !== "preview") {
                                        setIsShowAnswer(false);
                                        setCurrentTab({
                                            ...currentTab,
                                            tabKey: "preview",
                                            data: {
                                                ...form.getFieldsValue(),
                                                // answered,
                                            },
                                        });
                                        // const questionParagraphsPreviewNew = values.paragraphs.map((pItem) => {
                                        //     return {
                                        //         ...pItem,
                                        //         question_details: pItem.question_details?.map((qItem) => {
                                        //             return getQuestionDataPreview(qItem);
                                        //         }),
                                        //     };
                                        // });
                                        // setFormStates({
                                        //     ...formStates,
                                        //     questionParagraphsPreview: questionParagraphsPreviewNew,
                                        // });
                                    }
                                },
                                className:
                                    currentTab.tabKey === "preview"
                                        ? "action-button--primary"
                                        : "action-button--modify",
                                isDisabled: values.loading,
                            },
                        ]}
                    />
                    <Spin spinning={values.loading}>
                        <Form form={form}>
                            {currentTab.tabKey === "modify" && (
                                <div className="question-detail passage">
                                    <div className="question-detail-content">
                                        <SettingTopBar
                                            optionListLevel={values.optionListLevel}
                                            optionListTag={values.optionListTag}
                                            optionListLanguage={values.optionListLanguage}
                                            onChangeLevelList={(newLevelList) => {
                                                setValues({ ...values, optionListLevel: newLevelList });
                                            }}
                                            onChangeTagList={(newTagList) => {
                                                setValues({ ...values, optionListTag: newTagList });
                                            }}
                                            handleAddTagPending={() => {
                                                setValues({ ...values, loading: true });
                                            }}
                                            handleAddTagFulfilled={(newTagList) => {
                                                setValues({ ...values, loading: false, optionListTag: newTagList });
                                            }}
                                            onDeleteTag={handleDeleteTag}
                                            onChangeLang={(language) => {
                                                setValues({ language: language });
                                            }}
                                            form={form}
                                        />

                                        <div className="detail-passage-question">
                                            <Form.Item
                                                name="question"
                                                label={t("question_passage.title")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("message.required"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="app-input"
                                                    placeholder={t("question_passage.title")}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="question-setup-wrapper">
                                            <CustomButton
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                title={t("question_passage.add_paragraph")}
                                                onClick={() => {
                                                    handleClickAddChildPassage(currQuestionId);
                                                }}
                                            ></CustomButton>
                                        </div>

                                        <div className="question-passage-wrapper">
                                            {!values.loading &&
                                                values.paragraphs?.map((p, pIndex) => {
                                                    return (
                                                        <Row
                                                            className="question-passage"
                                                            key={`child-passage-${pIndex}`}
                                                            gutter={[32, 32]}
                                                        >
                                                            <Col xs={24} sm={24} md={24} lg={12}>
                                                                <div className="paragraph-wrapper">
                                                                    <div className="paragraph-title">
                                                                        {p.title || ""}
                                                                    </div>
                                                                    <div className="paragraph-heading">
                                                                        {p.heading || ""}
                                                                    </div>
                                                                    <div
                                                                        className="paragraph-content"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: p.content || "",
                                                                        }}
                                                                    ></div>
                                                                    <span className="action-list">
                                                                        <div>
                                                                            {t("shared.total_score")}:{" "}
                                                                            {p?.total_score || 0}
                                                                        </div>
                                                                        <div>
                                                                            <Popconfirm
                                                                                title={`${t(
                                                                                    "shared.msg_confirm_delete"
                                                                                )}?`}
                                                                                onConfirm={() => {
                                                                                    handleDeleteChildPassage(p._id);
                                                                                }}
                                                                                okText={t("shared.yes")}
                                                                                cancelText={t("shared.no")}
                                                                            >
                                                                                <span className="action-button">
                                                                                    <Icon name="icon-delete" />
                                                                                </span>
                                                                            </Popconfirm>
                                                                            <span
                                                                                className="action-button"
                                                                                onClick={() => {
                                                                                    setCurrentPage({
                                                                                        page: "child-passage",
                                                                                        params: {
                                                                                            questionId: currQuestionId,
                                                                                            childId: p._id,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <Icon name="icon-edit" />
                                                                            </span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={24} lg={12}>
                                                                <div className="question-list-wrapper">
                                                                    <div className="list-header">
                                                                        <CustomButton
                                                                            type="primary"
                                                                            icon={<PlusOutlined />}
                                                                            title={t("question_passage.add_question")}
                                                                            onClick={() => {
                                                                                handleShowModalAddChildQuestion(p._id);
                                                                            }}
                                                                        ></CustomButton>
                                                                        <CustomButton
                                                                            type="primary"
                                                                            icon={<PlusOutlined />}
                                                                            title={t(
                                                                                "question_passage.quick_add_question"
                                                                            )}
                                                                            onClick={() => {
                                                                                handleShowModalQuickAddQuestion(
                                                                                    p._id,
                                                                                    pIndex
                                                                                );
                                                                            }}
                                                                        ></CustomButton>
                                                                    </div>
                                                                    <div className="list-body">
                                                                        {p.question_details?.map((q, qIndex) => {
                                                                            const qPreview = getQuestionDataPreview(q);
                                                                            const defaultProps = {
                                                                                question: qPreview.question,
                                                                                answer: qPreview.answer,
                                                                            };
                                                                            return (
                                                                                <div
                                                                                    className="question-wrapper"
                                                                                    key={`child-question-${qIndex}`}
                                                                                >
                                                                                    {q.type === "writing" && (
                                                                                        <Writing
                                                                                            wordLimit={q.word_limit}
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "writing_short" && (
                                                                                        <WritingShort
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "fill_in_the_blank" && (
                                                                                        <FillBlanks {...defaultProps} />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "fill_in_the_blank_drag_drop" && (
                                                                                        <FillBlanksDragDrop
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "fill_in_the_blank_text" && (
                                                                                        <FillBlanksInput
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "fill_in_the_blank_image" && (
                                                                                        <ImageWithLabels
                                                                                            coordinates={q.coordinates}
                                                                                            src={q.file?.src}
                                                                                            width={q.width}
                                                                                            height={q.height}
                                                                                            labelType="dropdown"
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "fill_in_the_blank_drag_drop_image" && (
                                                                                        <ImageWithLabels
                                                                                            coordinates={q.coordinates}
                                                                                            src={q.file?.src}
                                                                                            width={q.width}
                                                                                            height={q.height}
                                                                                            labelType="drag&drop"
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "fill_in_the_blank_text_image" && (
                                                                                        <ImageWithLabels
                                                                                            coordinates={q.coordinates}
                                                                                            src={q.file?.src}
                                                                                            width={q.width}
                                                                                            height={q.height}
                                                                                            labelType="text"
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}

                                                                                    {q.type === "multiple_choice" && (
                                                                                        <MultipleChoice
                                                                                            {...defaultProps}
                                                                                            answer={q.answer?.map(
                                                                                                (aswr) => aswr.text
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "multiple_choice_sentence_quiz" && (
                                                                                        <MultipleChoiceSentenceQuiz
                                                                                            {...defaultProps}
                                                                                            answer={q.answer?.map(
                                                                                                (aswr) => aswr.text
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "multiple_choice_boolean" && (
                                                                                        <MultipleChoiceBoolean
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "multiple_choice_table" && (
                                                                                        <MultipleChoiceTable
                                                                                            {...defaultProps}
                                                                                            answer1={q.answer1}
                                                                                            answer2={q.answer2}
                                                                                            match={q.match}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "drag_drop" && (
                                                                                        <DragDrop
                                                                                            {...defaultProps}
                                                                                            answer={{
                                                                                                answer1: q.answer1,
                                                                                                answer2: q.answer2,
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "drag_drop_group" && (
                                                                                        <DragDropGroup
                                                                                            {...defaultProps}
                                                                                            answers={q.answer1}
                                                                                            groups={q.answer2}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "drag_drop_group_order_by" && (
                                                                                        <DragDropGroup
                                                                                            {...defaultProps}
                                                                                            answers={q.answer1}
                                                                                            groups={q.answer2}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "sort" && (
                                                                                        <Sort {...defaultProps} />
                                                                                    )}
                                                                                    {q.type === "hot_spot" && (
                                                                                        <Hotspot
                                                                                            {...defaultProps}
                                                                                            qestionData={{
                                                                                                src: q.file?.src,
                                                                                                width: q.width,
                                                                                                height: q.height,
                                                                                                paths: q.path,
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "highlight_image" && (
                                                                                        <HighlightImage
                                                                                            {...defaultProps}
                                                                                            isReadonly={true}
                                                                                            answer={[
                                                                                                {
                                                                                                    src: q.file?.src,
                                                                                                    width: q.width,
                                                                                                    height: q.height,
                                                                                                },
                                                                                            ]}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "highlight_square" && (
                                                                                        <Shading
                                                                                            {...defaultProps}
                                                                                            // isReadonly={true}
                                                                                            qInfo={q}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "convert_measurement" && (
                                                                                        <MathTextDropdown
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "speech_to_text" && (
                                                                                        <SpeechToText
                                                                                            key={`k${pIndex}`}
                                                                                            {...defaultProps}
                                                                                            speaker={q.speaker}
                                                                                            is_sentence={q.is_sentence}
                                                                                            lang={q?.language?.code}
                                                                                            q={{ q, p }}
                                                                                            hideMicro={true}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "speech_to_text_conversation_text" && (
                                                                                        <SpeechToTextConversationText
                                                                                            {...defaultProps}
                                                                                            speaker={q.speaker}
                                                                                            lang={q?.language?.code}
                                                                                            voice={q?.voice}
                                                                                            disable={true}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "phonetic_table" && (
                                                                                        <PhoneticTable
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "speech_to_text_conversation_audio" && (
                                                                                        <SpeechToTextConversationAudio
                                                                                            {...defaultProps}
                                                                                            speaker={q.speaker}
                                                                                            lang={q?.language?.code}
                                                                                            disable={true}
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "matrix" && (
                                                                                        <Matrix
                                                                                            {...defaultProps}
                                                                                            template_latex={
                                                                                                q.template_latex
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    {q.type ===
                                                                                        "fill_in_the_blank_latex" && (
                                                                                        <Matrix
                                                                                            {...defaultProps}
                                                                                            template_latex={
                                                                                                q.template_latex
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    {q.type === "draw_graph" && (
                                                                                        <Graph {...defaultProps} />
                                                                                    )}
                                                                                    {q.type === "number_line" && (
                                                                                        <GraphNumberLine
                                                                                            max_value={q.max_value}
                                                                                            min_value={q.min_value}
                                                                                            jump_value={q.jump_value}
                                                                                            {...defaultProps}
                                                                                        />
                                                                                    )}
                                                                                    <span className="action-list">
                                                                                        <div>
                                                                                            {t("shared.score")}:{" "}
                                                                                            {q?.score || 0}
                                                                                        </div>
                                                                                        <div>
                                                                                            <Popconfirm
                                                                                                title={`${t(
                                                                                                    "shared.msg_confirm_delete"
                                                                                                )}?`}
                                                                                                onConfirm={() => {
                                                                                                    handleDeleteChildQuestion2(
                                                                                                        p._id,
                                                                                                        q._id
                                                                                                    );
                                                                                                }}
                                                                                                okText={t("shared.yes")}
                                                                                                cancelText={t(
                                                                                                    "shared.no"
                                                                                                )}
                                                                                            >
                                                                                                <span className="action-button">
                                                                                                    <Icon name="icon-delete" />
                                                                                                </span>
                                                                                            </Popconfirm>
                                                                                            <span
                                                                                                className="action-button"
                                                                                                onClick={() => {
                                                                                                    redirectToChildQuestionDetail(
                                                                                                        q.type,
                                                                                                        currQuestionId,
                                                                                                        q._id
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <Icon name="icon-edit" />
                                                                                            </span>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                        </div>

                                        <div className="detail-passage-total-score">
                                            <Form.Item name="score" label={t("shared.total_score")}>
                                                <InputNumber readOnly className="app-input" />
                                            </Form.Item>
                                        </div>

                                        <SettingPublishingStatus />
                                    </div>

                                    <div className="question-detail-actions">{renderActionList()}</div>
                                </div>
                            )}
                            {currentTab.tabKey === "preview" && (
                                <div className="question-detail-preview">
                                    <div className="question-preview-content">
                                        <button
                                            onClick={() => setIsShowAnswer(!isShowAnswer)}
                                            className="question-show-answer"
                                        >
                                            {isShowAnswer ? t("q_detail.hide_answer") : t("q_detail.show_answer")}
                                        </button>
                                        {!isShowAnswer && (
                                            <Form.Item>
                                                <QuestionPanel
                                                    showOrdinalNumber={false}
                                                    showQuestionNumber={false}
                                                    showBottomNav={false}
                                                    onChange={handleChangePassage}
                                                    questionInfo={{
                                                        type: "passage",
                                                        paragraphs: formStates.questionParagraphsPreview,
                                                        answered: currentTab.data?.answered,
                                                        language: language || values.language,
                                                    }}
                                                ></QuestionPanel>
                                            </Form.Item>
                                        )}
                                        {isShowAnswer && (
                                            <QuestionContent
                                                type="review"
                                                questionInfo={{
                                                    type: "passage",
                                                    paragraphs: values.paragraphs,
                                                    answered: currentTab?.data?.answered,
                                                }}
                                                showResults={true}
                                                isShowScore={false}
                                                // Question display type:
                                                displayType="question-panel"
                                            />
                                        )}

                                        {(!currQuestionId || isEditable || type === "exam_bank") && (
                                            <SettingPublishingStatus />
                                        )}
                                    </div>

                                    <div className="question-preview-actions">{renderActionList()}</div>
                                </div>
                            )}
                            {currentTab.tabKey === "history" && (
                                <HistoryContainer modal_id={editingQuestion?.id || params.id} type="question" />
                            )}
                        </Form>
                    </Spin>

                    {/* -------- Modals: ---------- */}
                    <ModalSelectQuestionType
                        visible={showAddQuestionModal}
                        onOk={() => {}}
                        onCancel={() => setShowAddQuestionModal(false)}
                        type="passage"
                        onClickItem={
                            currQuestionId
                                ? (item) => {
                                      if (
                                          item === "speech_to_text" &&
                                          (!getLangSelect() || getLangSelect() === "undefined")
                                      ) {
                                          notification.warning({
                                              message: t("question_passage.select_language_first"),
                                          });
                                          return;
                                      }
                                      setCurrentPage({
                                          page: `child-question-${item}`,
                                          params: {
                                              questionId: currQuestionId,
                                              paragraphId: selectedParagraphId,
                                          },
                                      });
                                      setShowAddQuestionModal(false);
                                  }
                                : (item) => {
                                      setCurrentPage({
                                          page: `child-question-${item}`,
                                          params: {},
                                      });
                                  }
                        }
                    />
                    {/* <div className="button-list-wrapper">
                            <div className="button-list">
                                {Object.keys(questionTypes).map((item, itemIndex) => {
                                    if (item === "highlight") return; // Câu highlight k có trong passage
                                    return (
                                        <Button key={`nav-to-q${itemIndex}`} onClick={} style={{ flex: "initial" }}>
                                            {t(`q.${item}`)}
                                        </Button>
                                    );
                                })}
                            </div>
                        </div> */}
                    {/* </ModalSelectQuestionType> */}

                    <Modal
                        className="add-exam-question-modal scroll_primary"
                        centered
                        title={t("question_passage.quick_add_question")}
                        footer={null}
                        visible={showQuickAddQuestionModal}
                        onCancel={() => setShowQuickAddQuestionModal(false)}
                        destroyOnClose
                        width="calc(100vw - 40px)"
                        height="calc(100vh - 40px)"
                        style={{
                            maxWidth: "1400px",
                            maxHeight: "1400px",
                            margin: "20px 0",
                        }}
                        bodyStyle={{
                            overflowY: "auto",
                            height: "calc(100vh - 100px)",
                            backgroundColor: "#F0F6FB",
                        }}
                        closeIcon={<Icon name="icon-cross-thin" />}
                    >
                        <QuickAddPassageQuestions
                            setIsModalAddQuestions={setShowQuickAddQuestionModal}
                            value={values.currentParagraphQuestions}
                            paragraphI={selectedParagraphId}
                            reloadPassageData={reloadPassageData}
                            onChange={(value) => {
                                // Handle add question to exam
                                // if (values.question_details.length === 0) {
                                //     setCurrentQuestionOrder(0);
                                // }
                                // setValues({
                                //     question_details: value,
                                // });
                            }}
                        />
                    </Modal>

                    {/* ----------- END Modals------------ */}
                </div>
            ) : (
                <>{renderChildPage()}</>
            )}
        </>
    );
}

export default DetailPassageQuestion;
