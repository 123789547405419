import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { setCurrentPage } from "src/reducers/general";
import Breadcrumb from "src/modules/components/Breadcrumb";
import DiskUsageStat from "./components/DiskUsageStat";
import MyFileManager from "./components/MyFileManager";
import { getStorageSpaceUsed } from "src/api/containers/storage";
import "./MyStorage.scss";

const { TabPane } = Tabs;

const DiskUsageStatTabs = React.memo(({ chartData = [], spaceUsageChart = {} }) => {
    const { t } = useTranslation();

    return (
        <Tabs defaultActiveKey="usage-tab-personal" type="card" centered tabPosition="bottom">
            {chartData.map((dataItem, i) => (
                <TabPane tab={<span>{t(`organization.${dataItem.type}`)}</span>} key={`usage-tab-${dataItem.type}`}>
                    <DiskUsageStat
                        data={dataItem.data}
                        total={dataItem.total || 0}
                        dataColors={spaceUsageChart.dataColors}
                        dataLabels={spaceUsageChart.dataLabels}
                        plotContainer={spaceUsageChart.plotContainer}
                        statisticConfig={{ contentType: "left-per-total" }}
                    />
                </TabPane>
            ))}
        </Tabs>
    );
});

function MyStorage(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [spaceUsageChart, setSpaceUsageChart] = useState({
        data: {},
        dataColors: {
            space_used: "#1a73e8",
            space_left: "rgba(0, 0, 0, 0.2)",
        },
        dataLabels: {
            space_used: t("my_storage.space_used"),
            space_left: t("my_storage.space_left"),
        },
        plotContainer: {
            appendPadding: [15, 15, 30, 15],
        },
    });

    const [chartPanelVisibleType, chartData] = useMemo(() => {
        const generateChartSettings = (chartData = {}) => {
            const r = [];
            if (Object.keys(chartData).length > 0) {
                Object.keys(chartData).map((item) => {
                    switch (item) {
                        case "used": {
                            r.push({
                                name: "space_used",
                                value: chartData.used,
                            });
                            break;
                        }
                        case "left": {
                            r.push({
                                name: "space_left",
                                value: chartData.left,
                            });
                            break;
                        }
                    }
                });
            }
            return r;
        };
        if (Array.isArray(spaceUsageChart.data) === true) {
            const visibleType = "tabs";
            const chartData = spaceUsageChart.data.map((item) => {
                return { type: item.type || "", total: item.total || 0, data: generateChartSettings(item) };
            });
            return [visibleType, chartData];
        } else {
            const visibleType = "default";
            const chartData = {
                total: spaceUsageChart.data?.total || 0,
                data: generateChartSettings(spaceUsageChart.data),
            };
            return [visibleType, chartData];
        }
    }, [spaceUsageChart]);

    const handleGetUsageInfo = () => {
        getStorageSpaceUsed().then((res) => {
            if (res.status === true) {
                setSpaceUsageChart({
                    ...spaceUsageChart,
                    data: res.data || {},
                });
            }
        });
    };

    useEffect(() => {
        dispatch(setCurrentPage("my_storage"));

        getStorageSpaceUsed().then((res) => {
            if (res.status === true) {
                setSpaceUsageChart({
                    ...spaceUsageChart,
                    data: res.data || {},
                });
            }
        });
    }, []);

    return (
        <div className="my-storage-wrapper">
            <Breadcrumb
                routes={[
                    {
                        name: t("my_storage.my_storage"),
                    },
                ]}
            />

            <div className="my-storage-panel">
                <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <div className="space-usage-wrapper">
                            <div className="space-usage-header">
                                <div className="header-title">{t("my_storage.space_usage")}</div>
                            </div>
                            <div className="space-usage-chart-wrapper">
                                {chartPanelVisibleType === "default" ? (
                                    <DiskUsageStat
                                        data={chartData.data}
                                        total={chartData.total || 0}
                                        dataColors={spaceUsageChart.dataColors}
                                        dataLabels={spaceUsageChart.dataLabels}
                                        plotContainer={spaceUsageChart.plotContainer}
                                    />
                                ) : null}
                                {chartPanelVisibleType === "tabs" ? (
                                    <DiskUsageStatTabs chartData={chartData} spaceUsageChart={spaceUsageChart} />
                                ) : null}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18}>
                        <MyFileManager
                            onAddFiles={(res) => {
                                if (res.status) {
                                    handleGetUsageInfo();
                                }
                            }}
                            onRemoveFiles={(res) => {
                                if (res.status) {
                                    handleGetUsageInfo();
                                }
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default MyStorage;
