/**
 * Get filterValues by URLSearchParams.
 * @param {Object} urlSearchParams An object of all queries in url query string.
 * @param {Object} filterValuesConfig Filter values config object.
 * @returns Object of filterValues.
 */
export const getFilterValuesByURLSearchParams = (urlSearchParams = {}, filterValuesConfig = {}) => {
    const filterValues = {};
    for (const field in filterValuesConfig) {
        const fieldQueryKey = filterValuesConfig[field].queryKey;
        const fieldValuesInfo = filterValuesConfig[field].valuesInfo || {};
        // Get field's url-query-value:
        const queryKeyValue = urlSearchParams[fieldQueryKey];
        // Get field's value:
        // - Initial value:
        filterValues[field] = queryKeyValue;
        // - Configured value.
        Object.entries(fieldValuesInfo).map(([_value, _valueInfo]) => {
            if (_valueInfo.queryValue === queryKeyValue) {
                filterValues[field] = _value;
            }
        });
    }
    return filterValues;
};

/**
 * Get URLSearchParams by filterValues.
 * @param {Object} filterValues Filter values object.
 * @param {Object} filterValuesConfig Filter values config object.
 * @returns Object of urlSearchParams.
 */
export const getURLSearchParamsByFilterValues = (filterValues = {}, filterValuesConfig = {}) => {
    const urlSearchParams = {};
    for (const field in filterValuesConfig) {
        const fieldQueryKey = filterValuesConfig[field].queryKey;
        const fieldQueryKeyValue = filterValuesConfig[field].valuesInfo?.[filterValues[field]]?.queryValue;
        // Get field's search value:
        // - Initial value:
        if (filterValues[field] !== undefined) {
            urlSearchParams[fieldQueryKey] = filterValues[field];
        }
        // - Configured value.
        if (fieldQueryKeyValue !== undefined) {
            urlSearchParams[fieldQueryKey] = fieldQueryKeyValue;
        }
    }
    return urlSearchParams;
};

const objectToSearchParamsString = (params = {}) => {
    let str = "?";
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            str += `${key}=${params[key]}` + "&";
        }
    }
    if (str?.length) {
        str = str?.slice(0, -1);
    }
    return str;
};

const getAllSearchParams = (searchParams) => {
    const params = {};
    if (searchParams?.entries()) {
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
    }

    return params;
};

export { objectToSearchParamsString, getAllSearchParams };
