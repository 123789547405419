import { t } from "i18next";
import React, { useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import JSXGraphBoard from "src/modules/components/JSXGraphBoard";
import { compareObjectsDeeply } from "src/utils/helpers/comparison";

import QuestionContentDetail from "../ContentDetail";
import "./Graph.scss";

function Graph({
    // Question & answers:
    question = "" /** @param {string} question */,
    correct_answer /** @param {string[]} correct_answer */,
    answered /** @param {string[]} answered */,
}) {
    const refGraph1Id = useRef(`qcontent1-jsxgraph-container-${uuidv4()}`);
    const refGraph2Id = useRef(`qcontent2-jsxgraph-container-${uuidv4()}`);

    const [answerResult, answerExpected, answeredCounter] = useMemo(() => {
        let answerResult = [];
        let answerExpected = [];
        let answeredCounter = 0;
        // Helpers:
        const setUpAnswerDataItem = (answerDataItem, isCorrect) => {
            // Specify attrs:
            let pointFillColor = isCorrect ? "#52c41a" : "#ff4d4f",
                geomFillColor = isCorrect ? "#52c41a" : "#ff4d4f",
                geomStrokeColor = isCorrect ? "#52c41a" : "#ff4d4f";
            switch (answerDataItem?.type) {
                case "polygon": {
                    geomFillColor = isCorrect ? "#52c41a80" : "#ff4d4f80";
                    break;
                }
                case "circle":
                case "ellipse": {
                    geomFillColor = isCorrect ? "#52c41a33" : "#ff4d4f33";
                    break;
                }
                default:
                    break;
            }
            // Apply attrs:
            if (answerDataItem.type === "point") {
                !answerDataItem.attrs && (answerDataItem.attrs = {});
                answerDataItem.attrs.fixed = true;
                answerDataItem.attrs.fillColor = pointFillColor;
                answerDataItem.attrs.strokeColor = pointFillColor;
            } else {
                !answerDataItem.attrs && (answerDataItem.attrs = {});
                answerDataItem.attrs.fixed = true;
                answerDataItem.attrs.fillColor = geomFillColor;
                answerDataItem.attrs.strokeColor = geomStrokeColor;

                if (answerDataItem.values instanceof Array) {
                    answerDataItem.values.forEach((valItem) => {
                        !valItem.attrs && (valItem.attrs = {});
                        valItem.attrs.fixed = true;
                        valItem.attrs.fillColor = pointFillColor;
                        valItem.attrs.strokeColor = pointFillColor;
                    });
                }
            }
        };
        // Converting data:
        (answered || []).forEach((item, itemIndex) => {
            const answerDataItem = {
                type: item.type,
                name: item.name,
                values: structuredClone(item.values),
            };
            // Check if the answer is correct:
            let isCorrect = false;
            if (answerDataItem.type === "point") {
                const ans = item.values;
                const ansCorrect = correct_answer[itemIndex]?.values;
                isCorrect = compareObjectsDeeply(ans, ansCorrect);
                if (ans?.length) {
                    answeredCounter++;
                }
            } else {
                if (answerDataItem.values instanceof Array) {
                    const ans = answerDataItem.values.map((valItem) => {
                        if (valItem?.values?.length) {
                            answeredCounter++;
                        }
                        return valItem?.values;
                    });
                    const ansCorrect = correct_answer[itemIndex]?.values.map((valItem) => valItem.values);
                    isCorrect = compareObjectsDeeply(ans, ansCorrect);
                }
            }
            // Update answerDataItem's values and display:
            setUpAnswerDataItem(answerDataItem, isCorrect);
            // Update the list:
            answerResult.push(answerDataItem);
        });
        (correct_answer || []).forEach((item, itemIndex) => {
            const answerDataItem = {
                type: item.type,
                name: item.name,
                values: structuredClone(item.values),
            };
            // Update answerDataItem's values and display:
            setUpAnswerDataItem(answerDataItem, true);
            // Update the list:
            answerExpected.push(answerDataItem);
        });
        // Data:
        return [answerResult, answerExpected, answeredCounter];
    }, [correct_answer, answered]);

    return (
        <div className="q-result q-graph">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            <div className="q-content-section">
                <div className="view-graph">
                    <JSXGraphBoard
                        graphMode="solving"
                        graphType="default"
                        id={refGraph1Id.current}
                        width="100%"
                        height="500px"
                        value={answerResult}
                        isReadOnly={true}
                    />
                    {answeredCounter === 0 && (
                        <div className="graph-msg">
                            <div className="msg-wrapper">
                                <span className="msg">{t("message.answer_empty")}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="view-graph">
                    <JSXGraphBoard
                        graphMode="solving"
                        graphType="default"
                        id={refGraph2Id.current}
                        width="100%"
                        height="500px"
                        value={answerExpected}
                        isReadOnly={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default Graph;
