import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, notification, Space } from "antd";
import QuestionContentDetail from "../components/ContentDetail";
import { useValues } from "src/hooks";
import UserAnswer from "./components/UserAnswer";
import ComputerAnswer from "./components/ComputerAnswer";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Button from "src/modules/components/Button";
import { distance } from "fastest-levenshtein";
import { useSpeechSynthesis } from "src/hooks/useSpeechSynthesis";
import "./SpeechToTextConversation.scss";
import useSound from "use-sound";
import { AudioOutlined } from "@ant-design/icons";
import { caclulateAccuracy } from "src/utils/helpers/text";

const { TextArea } = Input;

function SpeechToTextConversation(props) {
    const {
        isReadonly = false,
        question,
        onChange = () => {},
        answer = [], // đáp án
        speaker = "en-US",
        isRenderInPassage,
        voice,
        lang,
        defaultAnswer,
        disable = false,
        isShowPinyin,
    } = props;

    const { t } = useTranslation();

    const [values, setValues] = useValues({
        currentStranscript: "",
        index: -1,
        answers: [],
        isStart: false,
        canReset: false,
        voice: voice,
        isSupportVoice: true,
        speaking_idx: -1,
        speaking_idx_click: -1,
        newCorrAnswer: [],
        isFirstLoad: true,
    });

    const {
        transcript, //Transcription of all speech that has been spoken into the microphone
        interimTranscript, // Transcription of speech that the Web Speech API is still processing
        finalTranscript, // Transcription of speech that the Web Speech API has finished processing.
        resetTranscript,
        listening, // is listening to speech from the microphone.
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition({ transcribing: true, clearTranscriptOnListen: true });

    const speakingIndexRef = useRef(-1);

    const [playActive] = useSound("https://www.joshwcomeau.com/sounds/pop.mp3", { volume: 0.55 });
    const [playOff] = useSound("https://www.joshwcomeau.com/sounds/bite.mp3", { volume: 0.55 });

    const onEnd = () => {
        if (speakingIndexRef.current === values.index) {
            if (values.index + 1 <= answer?.length) {
                setValues({ index: values.index + 1, speaking_idx: -1 });
            } else {
                // reset
                setValues({ index: -1, speaking_idx: -1, canReset: true, isStart: false });
            }
            speakingIndexRef.current = -1;
        } else {
            setValues({ speaking_idx: -1 });
            speakingIndexRef.current = -1;
        }
    };
    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
        onEnd,
    });

    const getVoiceSelected = (voiceName, speaker, lang) => {
        if (Array.isArray(voices)) {
            let isHaveLang = -1;
            let v = null;
            let v_rollback1 = null; // get item is same speaker
            let v_rollback2 = null; // get item is same lang
            // const v = voices.find((item) => item?.voiceURI === voiceName);
            for (let item of voices) {
                if (voiceName == item?.voiceURI) {
                    v = item;
                    break;
                } else if (item?.lang === speaker) {
                    v_rollback1 = item;
                } else if (item?.lang?.slice(0, 2) == lang?.toLowerCase()) {
                    v_rollback2 = item;
                }
            }

            const reslt = v || v_rollback1 || v_rollback2;
            if (reslt) {
                setValues({
                    voice: reslt,
                    isSupportVoice: true,
                });
            } else {
                setValues({
                    voice: null,
                    isSupportVoice: false,
                });
            }
        } else {
            setValues({
                isSupportVoice: false,
            });
        }

        return;
    };

    const handleClickRecord = () => {
        if (values.isStart) {
            if (!listening) {
                playActive();
                resetTranscript();
                SpeechRecognition.startListening({
                    continuous: true,
                    // language: speaker,
                    language: Array.isArray(speaker) && speaker.length > 0 ? speaker?.[0] : speaker,
                });

                setValues({ isFirstLoad: false });
            } else {
                playOff();
                // resetTranscript();
                SpeechRecognition.stopListening(); // it had another method abortListening()
                setTimeout(() => {
                    const accr = caclulateAccuracy(values?.answers?.[values.index]?.value, transcript);
                    let times = 0;

                    const newData = values.answers.map((item, idx) => {
                        if (idx === values?.index) {
                            times = item?.times + 1;

                            const item1 = {
                                ...item,
                                isCorrectAnsw: accr == 100,
                                times: item?.times + 1,
                                hadSpeech: true,
                                userAnswer: transcript,
                            };

                            return item1;
                        } else {
                            return item;
                        }
                    });

                    //TH Nói lần thứ 3, hoặc nói đúng => next câu khác

                    setValues({
                        index: times > 2 || accr === 100 ? values.index + 1 : values.index,
                        answers: newData,
                    });

                    if (onChange instanceof Function) {
                        const item = values?.answers?.[values.index];
                        if (item?.is_user) {
                            const idx = values.newCorrAnswer?.findIndex((item) => {
                                return item?.order === values.index;
                            });
                            const newAnsw = defaultAnswer ? [...defaultAnswer] : [];

                            newAnsw[idx] = transcript;

                            onChange({
                                answered: newAnsw,
                            });
                        }
                    }
                }, 200);
            }
        }
    };

    const handleStart = () => {
        if (!values.isStart) {
            setValues({ isStart: true, index: 0 });
        }
    };

    const handleRedo = () => {
        if ((!values.isStart && values.canReset) || values.isFirstLoad) {
            setValues({
                index: 0,
                answers: values?.answers?.map((item) => ({
                    ...item,
                    isCorrectAnsw: false,
                    times: 0,
                    hadSpeech: false,
                    userAnswer: "",
                })),
                isStart: true,
                canReset: false,
                // speaking_idx: -1,
                speaking_idx_click: -1,
            });
            if (onChange instanceof Function) {
                onChange([]);
            }
        }
    };

    const handleSpeak = (text, idx) => {
        if (values.isStart && values.isSupportVoice) {
            setValues({
                speaking_idx: idx,
            });
            speakingIndexRef.current = idx;

            speak({ text: text, voice: values.voice });
        }
    };

    useEffect(() => {
        if (!isMicrophoneAvailable) {
            notification.warning({ message: t("speech_to_text.please_allow_microphone") });
        }
    }, []);

    useEffect(() => {
        if (values.isStart) return;
        let fData = [];
        const newArr = answer?.filter((item) => item?.is_user);

        if (!defaultAnswer || defaultAnswer?.length === 0) {
            fData = answer.map((item) => {
                return {
                    ...item,
                    isCorrectAnsw: "",
                    times: 0,
                    hadSpeech: false,
                };
            });
            // setValues({ answers: fData });
        } else {
            //load data

            fData = answer.map((item, index) => {
                let isCorr = "";
                // if (item?.is_user) {
                const idx = newArr?.findIndex((a) => {
                    return a?.order === item?.order;
                });

                isCorr = caclulateAccuracy(item?.value, defaultAnswer?.[idx]) === 100;
                // }

                return {
                    ...item,
                    isCorrectAnsw: isCorr,
                    times: 3,
                    hadSpeech: true,
                    userAnswer: defaultAnswer?.[idx],
                };
            });
        }
        // setValues({ answers: fData });

        setValues({
            newCorrAnswer: newArr,
            answers: fData,
        });

        return () => SpeechRecognition.stopListening();
    }, [defaultAnswer]);

    useEffect(() => {
        if (values.index >= 0) {
            if (values?.answers?.[values.index]?.is_user) {
                //active micro
                handleClickRecord();
            } else {
                handleSpeak(values?.answers?.[values.index]?.value, values.index);
            }
        }
    }, [values.index]);

    useEffect(() => {
        //speker nayf ko phaI LA speaker cua speech to text.
        getVoiceSelected(voice?.voiceURI, voice?.lang, lang);
    }, [voices]);

    useEffect(() => {
        if (listening && transcript) {
            const accr = caclulateAccuracy(values?.answers?.[values.index]?.value, transcript);
            if (accr === 100) {
                handleClickRecord();
            }
        }
    }, [transcript]);

    return (
        <div className="qp_writing qp-speech-to-text-conversation">
            <div className="question_title">{t("q.question")}</div>
            {question && (
                <>
                    <div className="content-title">
                        <QuestionContentDetail isReadonly={isReadonly} value={{ question }} />
                    </div>
                </>
            )}
            {/* "index:::"{values.index} */}
            <div className="note">
                <strong>{`${t("shared.note2")}: `}</strong>
                {t("speech_to_text_conversation.note_when_doing_homework1")}
                <span className="record-icon">
                    <AudioOutlined />
                </span>
                {t("speech_to_text_conversation.note_when_doing_homework2")}
                <span className="record-icon">
                    <AudioOutlined />
                </span>
                {t("speech_to_text_conversation.note_when_doing_homework3")}
                <span className="record-icon">
                    <AudioOutlined />
                </span>
                {t("speech_to_text_conversation.note_when_doing_homework4")}
            </div>
            <br />
            <div className="speech-to-text-conversation__container">
                <Space direction="vertical" size={18} style={{ minWidth: "38%", display: "flex" }}>
                    {values.answers.map((item, idx) => {
                        if (item?.is_user) {
                            return (
                                <UserAnswer
                                    key={idx}
                                    listening={idx == values.index && listening}
                                    actor={item?.actor}
                                    sentence={item.value}
                                    handleClickRecord={idx == values.index ? handleClickRecord : () => {}}
                                    userAnswer={item?.userAnswer} // final transcript
                                    currUserAnswer={idx == values.index && listening ? transcript : ""} // transcript when listening,
                                    isCorrect={item?.isCorrectAnsw}
                                    hadSpeech={item?.hadSpeech}
                                    isActive={values.index === idx}
                                    isShowPinyin={isShowPinyin}
                                    lang={lang}
                                />
                            );
                        } else {
                            return (
                                <ComputerAnswer
                                    onClick={() => {
                                        handleSpeak(item?.value, idx);
                                    }}
                                    key={idx}
                                    actor={item?.actor}
                                    sentence={item?.value}
                                    active={idx === values.index}
                                    isPlaying={idx === speakingIndexRef.current}
                                    isShowPinyin={isShowPinyin}
                                />
                            );
                        }
                    })}
                </Space>
            </div>
            <br />
            {!disable &&
                (supported && values.isSupportVoice ? (
                    <>
                        <div style={{ textAlign: "center" }}>
                            {!values.isStart && !values.canReset && (!defaultAnswer || defaultAnswer?.length <= 0) && (
                                // (defaultAnswer?.length > 0 && values.isFirstLoad)) && (
                                <Button
                                    title={t("speech_to_text_conversation.start")}
                                    type="primary"
                                    onClick={handleStart}
                                />
                            )}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {((!values.isStart && values.canReset && !values.isFirstLoad) ||
                                (values.isFirstLoad && defaultAnswer?.length > 0)) && (
                                <Button title={t("gradebook.redo")} type="primary" onClick={handleRedo} />
                            )}
                        </div>
                    </>
                ) : (
                    <div>{t("speech_to_text.no_browser_support")}</div>
                ))}
        </div>
    );
}

export default SpeechToTextConversation;
