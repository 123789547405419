import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import "./SelectFile.scss";

function SelectFile({
    accept = undefined, // File types. Values: "*/*", "image/*", "image/png, image/jpeg".
    fileList = undefined,
    onChange = () => {},
    className,
    wrapperClassName,
    listMultiple = true,
    selectMultiple = true,
    listType = "text",
    itemRender = undefined,
    maxCount = undefined,
}) {
    const [currentFileList, setCurrentFileList] = useState([]);

    const handleRemove = (file) => {
        file.isDelete = true;
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        onChange(newFileList);
    };

    useEffect(() => {
        if (listMultiple) {
            //
        } else {
            if (currentFileList.length > 1) {
                setCurrentFileList([currentFileList[currentFileList.length - 1]]);
            } else {
                onChange(currentFileList);
            }
        }
    }, [listMultiple, currentFileList]);

    return (
        <div className={`select-file${wrapperClassName ? ` ${wrapperClassName}` : ""}`}>
            <Upload
                fileList={fileList}
                onRemove={handleRemove}
                beforeUpload={(file) => {
                    // Validate file:
                    if (accept) {
                        const _accepts = accept.split(/,\s?/g);

                        const typeWithSubtype = file.type;
                        const typeWithAllSubtype = `${file.type.split("/")[0]}/*`;

                        let success = false;
                        for (let i = 0; i < _accepts.length; i++) {
                            const mimeType = _accepts[i];
                            if (mimeType === typeWithSubtype || mimeType === typeWithAllSubtype) {
                                success = true;
                                break;
                            }
                        }
                        if (!success) {
                            message.error(`${file.name} is not valid`);
                            return Upload.LIST_IGNORE;
                        }
                    }

                    // When the file is valid, add it to list without uploading automatically:
                    setCurrentFileList((prevState) => {
                        return [...prevState, file];
                    });
                    return false;
                }}
                className={`act-n-files${className ? " " + className : ""}`}
                multiple={selectMultiple}
                listType={listType}
                accept={accept}
                itemRender={itemRender}
                maxCount={maxCount}
            >
                <CustomButton type="ghost" icon={<UploadOutlined />} title={t("shared.select_files")}></CustomButton>
            </Upload>
        </div>
    );
}

export default SelectFile;
