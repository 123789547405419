import React, { useContext, useEffect, useRef } from "react";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";
import { t } from "i18next";
import { useValues } from "src/hooks";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ControlOutlined,
    DesktopOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import DisplaysCheckTrigger from "./DisplaysCheckTrigger";
import "./MultipleDisplaysCheck.scss";

function MultipleDisplaysCheck({
    disabled = false,
    value, // Values: undefined, "pending", true, false.
    onChange,
}) {
    const proctoring = useContext(ProctoringContext);

    const screenDetailsRef = useRef(null);
    const screenDetailsScreenChangeRef = useRef(() => {});
    const screenCount = useRef(null);

    const [chkTrggr, setChkTrggr] = useValues({
        visible: false,
    });
    const [values, setValues] = useValues({
        loading: false,
        isFirstTime: true,
        // Connection:
        conn_status: false,
        conn_message: "",
        conn_description: "",
    });

    const displaysStatus = {
        fail: t("exam_checkin.displays_info.fail"),
        success: t("exam_checkin.displays_info.success"),
    };

    const displaysDescription = {
        connecting: t("exam_checkin.displays_info.descr_connecting"),
        fail: t("exam_checkin.displays_info.descr_fail"),
        invalid_screen_count: t("exam_checkin.displays_info.descr_invalid_screen_count"),
        "NotAllowedError: Permission dismissed.": t("exam_checkin.displays_info.descr_permission_dismissed"),
        "NotAllowedError: Permission denied.": t("exam_checkin.displays_info.descr_permission_denied"),
    };

    // Permission checking with 'change' event:
    const permissionStatusRef = useRef(null);
    const checkingChangeRef = useRef(() => {});

    // Refs to be used inside event handlers (to deal with closure):
    const handleStartCheckingRef = useRef(() => {});

    const handleChange = (newValue) => {
        if (onChange instanceof Function) {
            onChange(newValue);
        }
    };

    const handleCleanUp = () => {
        if (screenDetailsRef.current) {
            screenDetailsRef.current.removeEventListener("screenschange", screenDetailsScreenChangeRef.current);
            screenDetailsRef.current = null;
            screenDetailsScreenChangeRef.current = () => {};
        }
    };

    const handleStartChecking = async () => {
        if ("getScreenDetails" in window) {
            // The Window Management API is supported.

            setValues({
                loading: true,
                isFirstTime: false,
                conn_status: false,
                conn_message: "",
                conn_description: displaysDescription.connecting,
            });
            handleChange("pending");
            handleCleanUp();
            handleStopCheckingChange();

            try {
                screenDetailsRef.current = await window.getScreenDetails();
                const screenDetails = screenDetailsRef.current;

                // Handle screens change.
                screenDetailsScreenChangeRef.current = (event) => {
                    if (screenDetails.screens.length !== screenCount.current) {
                        // Store data:
                        screenCount.current = screenDetails.screens.length;
                        // Recall checker:
                        handleStartCheckingRef.current();
                    }
                };
                screenDetails.addEventListener("screenschange", screenDetailsScreenChangeRef.current);

                handleStartCheckingChange();

                // Store data:
                screenCount.current = screenDetails.screens.length;

                // Update states:
                if (proctoring?.setDisplaysCheck instanceof Function) {
                    proctoring.setDisplaysCheck({
                        screenDetails: screenDetailsRef.current,
                        screenCount: screenCount.current,
                    });
                }
                if (screenCount.current !== 1) {
                    setValues({
                        loading: false,
                        conn_status: false,
                        conn_message: displaysStatus.fail,
                        conn_description: displaysDescription.invalid_screen_count,
                    });
                    handleChange(false);
                } else {
                    setValues({
                        loading: false,
                        conn_status: true,
                        conn_message: displaysStatus.success,
                        conn_description: "",
                    });
                    handleChange(true);
                }
            } catch (err) {
                // Update states:
                const errMsg = err.toString();
                setValues({
                    loading: false,
                    conn_status: false,
                    conn_message: displaysStatus.fail,
                    conn_description: displaysDescription[errMsg] || errMsg,
                });
                handleChange(false);
            }
        } else {
            // setValues({
            //     conn_status: false,
            //     conn_message: displaysStatus.fail,
            //     conn_description: displaysDescription.fail,
            // });
            // handleChange(false);
            setValues({
                loading: false,
                conn_status: true,
                conn_message: displaysStatus.success,
                conn_description: "",
            });
            handleChange(true);
            handleCleanUp();
            handleStopCheckingChange();
        }
    };
    handleStartCheckingRef.current = handleStartChecking;

    const handleStartCheckingChange = () => {
        if ("permissions" in navigator) {
            navigator.permissions
                .query({ name: "window-placement" })
                .then((permissionStatus) => {
                    permissionStatusRef.current = permissionStatus;
                    checkingChangeRef.current = () => {
                        switch (permissionStatus.state) {
                            case "granted": {
                                break;
                            }
                            case "denied": {
                                setValues({
                                    loading: false,
                                    conn_status: false,
                                    conn_message: displaysStatus.fail,
                                    conn_description: displaysDescription["NotAllowedError: Permission denied."],
                                });
                                handleChange(false);
                                break;
                            }
                            case "prompt": {
                                setValues({
                                    loading: false,
                                    conn_status: false,
                                    conn_message: displaysStatus.fail,
                                    conn_description: displaysDescription["NotAllowedError: Permission dismissed."],
                                });
                                handleChange(false);
                                break;
                            }
                            default:
                                break;
                        }
                    };

                    permissionStatus.addEventListener("change", checkingChangeRef.current);
                })
                .catch((err) => {
                    notification.error({
                        message: err.toString(),
                    });
                });
        }
    };

    const handleStopCheckingChange = () => {
        if (permissionStatusRef.current) {
            permissionStatusRef.current.removeEventListener("change", checkingChangeRef.current);
        }
    };

    const handleClickCheck = () => {
        handleStartChecking();
    };

    const handleToggleDisplaysCheckTrigger = (value) => {
        const newVisible = value !== undefined ? value?.visible : !chkTrggr.visible;
        setChkTrggr({
            visible: newVisible,
        });
    };

    const handleOkDisplaysCheckTrigger = () => {
        handleToggleDisplaysCheckTrigger({ visible: false });
        handleStartChecking();
    };

    useEffect(() => {
        return () => {
            handleCleanUp();
            handleStopCheckingChange();
        };
    }, []);

    useEffect(() => {
        if (!disabled && !values.loading && values.isFirstTime) {
            handleToggleDisplaysCheckTrigger({ visible: true });
        }
    }, [disabled, values.loading, values.isFirstTime]);

    return (
        <div className="multipledisplays-check">
            <div className="result-display">
                <div className="displays-icon">
                    <DesktopOutlined />
                </div>
            </div>
            <div className="check-text">
                <div className="check-title">{t("exam_checkin.displays_check")}</div>
                <div className="check-result-info">
                    {values.conn_message && (
                        <div className="check-result-message">
                            <span>{t("exam_checkin.check_result")}: </span>
                            <span className={"result-tag" + (values.conn_status ? " success" : " danger")}>
                                {values.conn_status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
                                {values.conn_message}
                            </span>
                        </div>
                    )}
                    {values.conn_description && (
                        <div className={"check-result-desctiption" + (values.conn_status ? " success" : " danger")}>
                            {values.conn_description}
                        </div>
                    )}
                </div>
            </div>
            <div className="check-actions">
                <CustomButton
                    htmlType="submit"
                    type="ghost"
                    icon={values.isFirstTime ? <ControlOutlined /> : <ReloadOutlined />}
                    title={
                        values.isFirstTime
                            ? t("shared.check")
                            : values.loading
                            ? `${t("shared.checking")}...`
                            : t("shared.try_again")
                    }
                    isLoading={values.loading}
                    isDisabled={disabled}
                    onClick={handleClickCheck}
                ></CustomButton>
            </div>

            <DisplaysCheckTrigger visible={chkTrggr.visible} onOk={handleOkDisplaysCheckTrigger} />
        </div>
    );
}

export default MultipleDisplaysCheck;
