import { EllipsisOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Popconfirm, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { findCourse } from "src/api/containers/course";
import { fetchUnitList } from "src/api/containers/unit";
import { useFetch, useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
import CourseTopic from "src/modules/components/CoursePanels/CourseTopic";
import Empty from "src/modules/components/Empty";
import Icon from "src/modules/components/Icon";
import { useRouting } from "src/utils/router";
import { OutlineButton } from "../TeacherCourses/components/Button";
import ModalDetailUnit from "../TeacherCourses/components/ModalDetailUnit";
import "./TeacherUnits.scss";

const TeacherUnits = ({ unitColumns = [], ...rest }) => {
    const [showModal, setShowModal] = useState({
        isShown: "",
        params: null,
    });
    const [values, setValues] = useValues({
        listPages: 0,
        listOfUnits: [],
    });

    const [couseName, setCourseName] = useState("");

    const user = useSelector((state) => state.auth.user);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const params = useParams();

    const [unitLoading, unitDataSource, unitPagination, unitFetch, unitRefetch] = useFetch(
        { page: 1, filters: `course_id:${params.courseId}` },
        fetchUnitList
    );

    const goToUnitDetail = (unitId) => {
        if (!params.courseId || !unitId) {
            alert("Invalid unit!");
        }
        navigate(
            generate("course_detail_unit", {
                courseId: params.courseId,
                unitId: unitId,
            })
        );
    };

    const handleOk = () => {
        unitRefetch();
        setShowModal({ isShown: "", params: null });
    };

    const handleCancel = () => {
        setShowModal({ isShown: "", params: null });
    };

    const handleDeleteUnit = (unitId) => {};

    useEffect(() => {
        unitFetch({}, true);
        findCourse(params.courseId).then((result) => {
            if (result?.data?.name) {
                setCourseName(result.data.name);
            }
        });
    }, []);

    useEffect(() => {
        if (unitDataSource.length) {
            if (unitPagination.current === 1) {
                setValues({
                    ...values,
                    listPages: unitPagination.current,
                    listOfUnits: unitDataSource,
                });
            } else if (unitPagination.current > values.listPages) {
                setValues({
                    ...values,
                    listPages: unitPagination.current,
                    listOfUnits: [...values.listOfUnits, ...unitDataSource],
                });
            }
        } else {
            setValues({
                ...values,
                listPages: 0,
                listOfUnits: [],
            });
        }
    }, [unitDataSource, unitPagination]);

    const renderMenu = (unitId) => {
        return (
            <Menu
                className="unit-list-item-dropdown"
                items={[
                    {
                        key: "unit-mn1",
                        label: (
                            <Space className="item-btn">
                                <Icon name="icon-modify" />
                                {t("action.update")}
                            </Space>
                        ),
                        disabled: showModal.isShown,
                        onClick: () => {
                            setShowModal({ isShown: "add-unit", params: { unitId: unitId } });
                        },
                    },
                    {
                        key: "unit-mn2",
                        label: (
                            <Popconfirm
                                className="item-btn"
                                title={`${t("action.msg_confirm_delete")}?`}
                                onConfirm={() => {
                                    handleDeleteUnit(unitId);
                                }}
                                okText={t("action.yes")}
                                cancelText={t("action.no")}
                            >
                                <Space>
                                    <Icon name="icon-delete" />
                                    {t("action.delete")}
                                </Space>
                            </Popconfirm>
                        ),
                        disabled: showModal.isShown,
                    },
                ]}
            />
        );
    };

    return (
        <>
            <Breadcrumb
                className="mobile"
                routes={[
                    { path: "home" },
                    {
                        name: couseName,
                    },
                ]}
            />
            <div className="unit-list-table">
                <div className="teacher-unit-wrapper">
                    {(user.role === "employee" || user.role === "admin") && (
                        <div className="unit-actions teacher-unit-actions">
                            <div className="">
                                <span>{t("teacher_courses.list_lesson")}</span>
                            </div>
                            <OutlineButton
                                title={t("unit.add_unit")}
                                icon={<PlusCircleOutlined />}
                                onClick={() => setShowModal({ isShown: "add-unit", params: null })}
                            />
                        </div>
                    )}
                    <Spin spinning={unitLoading}>
                        <div className="unit-list">
                            {Array.isArray(values?.listOfUnits) && values.listOfUnits.length > 0 ? (
                                values.listOfUnits.map((unitItem, unitIndex) => {
                                    return (
                                        <div className="unit-list-item" key={`unit${unitIndex}`}>
                                            <CourseTopic
                                                topicInfo={unitItem}
                                                isActive={false}
                                                onClick={() => {
                                                    goToUnitDetail(unitItem.id);
                                                }}
                                                actions={
                                                    user?.role === "employee" || user.role === "admin" ? (
                                                        <Dropdown
                                                            overlay={renderMenu(unitItem.id)}
                                                            trigger={["click"]}
                                                            placement="bottomRight"
                                                        >
                                                            <Button className="dropdown-btn">
                                                                <EllipsisOutlined />
                                                            </Button>
                                                        </Dropdown>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <Empty />
                            )}
                            {unitPagination.current < unitPagination.lastPage ? (
                                <div className="unit-list-item loadmore-btn">
                                    <CustomButton
                                        type="grey"
                                        title={`${t("unit.load_more_units")}...`}
                                        icon={null}
                                        onClick={() => unitFetch({ page: unitPagination.current + 1 })}
                                    />
                                </div>
                            ) : null}

                            <ModalDetailUnit
                                courseId={params?.courseId}
                                unitId={showModal.params?.unitId || ""}
                                visible={showModal.isShown === "add-unit"}
                                onOk={handleOk}
                                onCancel={handleCancel}
                            />
                        </div>
                    </Spin>
                </div>
            </div>
        </>
    );
};

export default TeacherUnits;
