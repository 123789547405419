import React, { useEffect, useRef, useState } from "react";

import { cardFieldSettings } from "../settings";

export const CardTableContext = React.createContext();

const CardTable = ({ children }) => {
    const [cardSettings, setCardSettings] = useState({});
    const [cardSettingsModified, setCardSettingsModified] = useState({});

    const handleSetCardInfo = (type, settings) => {
        if (type) {
            setCardSettings((prevState) => {
                return {
                    ...prevState,
                    [type]: settings,
                };
            });
        }
    };

    useEffect(() => {
        const cardTypes = Object.keys(cardSettings);
        if (cardTypes.length) {
            // Clone:
            const newCardSettingsModified = structuredClone(cardSettings);
            // Modify:
            Object.keys(cardFieldSettings).forEach((cardField) => {
                // 1. Height:
                // 1.1. Get the actual height of this field (row) on each card:
                const actualHeights = [];
                cardTypes.forEach((cardType) => {
                    actualHeights.push(newCardSettingsModified[cardType][cardField].actualHeight);
                });
                // 1.2. Specify the max height, then set this height value to all cards of this field:
                const maxActualHeight = Math.max(...actualHeights);
                cardTypes.forEach((cardType) => {
                    newCardSettingsModified[cardType][cardField].height = maxActualHeight;
                });
            });
            setCardSettingsModified(newCardSettingsModified);
        }
    }, [cardSettings]);

    return (
        <CardTableContext.Provider
            value={{
                cardInfo: cardSettingsModified,
                setCardInfo: handleSetCardInfo,
            }}
        >
            {children}
        </CardTableContext.Provider>
    );
};

export default CardTable;
