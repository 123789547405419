import React from "react";

function IconNavNext(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="20" height="33" viewBox="0 0 20 33" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.66468 32.2857L0.571411 29.1741L13.242 16.4286L0.571411 3.68299L3.66468 0.571423L19.4286 16.4286L3.66468 32.2857Z"
            />
        </svg>
    );
}

export default IconNavNext;
