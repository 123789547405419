import { CaretRightOutlined, PoweroffOutlined, SaveFilled } from "@ant-design/icons";
import { Tooltip, notification } from "antd";
import { t } from "i18next";
import React, { createContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import fixWebmDuration from "webm-duration-fix";

import { useValues } from "src/hooks";
import Stopwatch from "src/modules/components/Stopwatch/Stopwatch";
import { setRecordingTimeStart } from "src/reducers/test";

import "./ScreenCamMicRecorder.scss";

export const ScreenCamMicRecorderContext = createContext();

const mimeType = "video/webm";

const ScreenCamMicRecorder = ({
    children,
    mediaNamePrefix = "",
    withSystemAudio = true,
    withMicrophone = true,
    withCamera = false,
    onStop,
}) => {
    const displayMediaStreamOptions = {
        video: {
            displaySurface: "monitor",
        },
        audio: withSystemAudio,
    };

    const audioMediaStreamConstraints = {
        video: false,
        audio: withMicrophone,
    };

    const mediaStream = useRef(null);
    const audioMediaStream = useRef(null);
    const mixedMediaStream = useRef(null);
    const mediaRecorder = useRef(null);
    const chunks = useRef([]);

    const webcamMediaStream = useRef(null);
    const htmlElementVideoRef = useRef(null);

    const dispatch = useDispatch();

    const recordingDescription = {
        started: t("exam_proctoring.recording_info.started"),
        stopped: t("exam_proctoring.recording_info.stopped"),
        displaySurface_must_be_monitor: t("exam_proctoring.recording_info.displaySurface_must_be_monitor"),
        systemAudio_mustbe_turnedon: t("exam_proctoring.recording_info.systemAudio_mustbe_turnedon"),
        "NotAllowedError: Permission dismissed": t("exam_proctoring.recording_info.descr_permission_dismissed"),
        "NotAllowedError: Permission denied": t("exam_proctoring.recording_info.descr_permission_denied"),
    };

    const [values, setValues] = useValues({
        status: "stopped", // Values: "stopped", "granting-permissions", "waiting-for-recording", "recording".
        blob: {},
        mediaURL: "",
        mediaName: "",
    });

    const handleAfterStopSharingScreenRef = useRef(null);
    const onEndedMediaStreamRef = useRef(null);

    function cleanup() {
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach((track) => {
                track.removeEventListener("ended", onEndedMediaStreamRef.current);
                track.stop();
                onEndedMediaStreamRef.current = null;
            });
        }
        if (audioMediaStream.current) {
            audioMediaStream.current.getTracks().forEach((track) => track.stop());
        }
        if (mixedMediaStream.current) {
            mixedMediaStream.current.getTracks().forEach((track) => track.stop());
        }
        if (webcamMediaStream.current) {
            webcamMediaStream.current.getTracks().forEach((track) => track.stop());
        }
        if (htmlElementVideoRef.current) {
            htmlElementVideoRef.current.srcObject.getVideoTracks().forEach((track) => {
                track.stop();
                htmlElementVideoRef.current.srcObject.removeTrack(track);
            });
        }

        mediaStream.current = null;
        audioMediaStream.current = null;
        mixedMediaStream.current = null;
        mediaRecorder.current = null;
        chunks.current = [];

        webcamMediaStream.current = null;
        htmlElementVideoRef.current = null;
    }

    function handleMediaRecorderDataAvailable(e) {
        chunks.current.push(e.data);
    }

    async function handleMediaRecorderStop(e) {
        // const blob = new Blob(chunks.current, {
        //     type: "video/webm",
        // });

        const blob = await fixWebmDuration(new Blob([...chunks.current], { type: mimeType }));

        // Info:
        const mediaURL = URL.createObjectURL(blob);
        const mediaNameSuffix = new Date().toLocaleString().replace(/,/g, "");
        const mediaName = `${mediaNamePrefix}-${mediaNameSuffix}.webm`;

        // Save data:
        setValues({
            status: "stopped",
            blob: blob,
            mediaURL: mediaURL,
            mediaName: mediaName,
        });

        // Clean up and reset:
        cleanup();

        notification.success({
            message: recordingDescription.stopped,
        });

        // Trigger change:
        if (onStop instanceof Function) {
            onStop();
        }
    }

    function handleAfterStopSharingScreen() {
        if (values.status === "waiting-for-recording") {
            if (mediaStream.current) {
                mediaStream.current.getTracks().forEach((track) => {
                    track.removeEventListener("ended", onEndedMediaStreamRef.current);
                    track.stop();
                    onEndedMediaStreamRef.current = null;
                });
            }
            if (audioMediaStream.current) {
                audioMediaStream.current.getTracks().forEach((track) => track.stop());
            }

            mediaStream.current = null;
            audioMediaStream.current = null;

            setValues({
                status: "stopped",
                blob: {},
                mediaURL: "",
                mediaName: "",
            });
        } else {
            if (mediaStream.current) {
                mediaStream.current.getTracks().forEach((track) => {
                    track.removeEventListener("ended", onEndedMediaStreamRef.current);
                    track.stop();
                    onEndedMediaStreamRef.current = null;
                });
            }
            if (audioMediaStream.current) {
                audioMediaStream.current.getTracks().forEach((track) => track.stop());
            }
            if (mixedMediaStream.current) {
                mixedMediaStream.current.getTracks().forEach((track) => track.stop());
            }
            if (webcamMediaStream.current) {
                webcamMediaStream.current.getTracks().forEach((track) => track.stop());
            }
            if (htmlElementVideoRef.current) {
                htmlElementVideoRef.current.srcObject.getVideoTracks().forEach((track) => {
                    track.stop();
                    htmlElementVideoRef.current.srcObject.removeTrack(track);
                });
            }

            if (mediaRecorder.current) {
                mediaRecorder.current.stop();
            }
        }
    }
    handleAfterStopSharingScreenRef.current = handleAfterStopSharingScreen;

    function getMediaStreamForRecording(displayMediaStream, microMediaStream) {
        const hasNoAudio = !withSystemAudio && !withMicrophone && displayMediaStream instanceof MediaStream;
        const hasSystemAudioAndMicro =
            withSystemAudio &&
            withMicrophone &&
            displayMediaStream instanceof MediaStream &&
            microMediaStream instanceof MediaStream;
        const hasOnlySystemAudio = withSystemAudio && !withMicrophone && displayMediaStream instanceof MediaStream;
        const hasOnlyMicro =
            !withSystemAudio &&
            withMicrophone &&
            displayMediaStream instanceof MediaStream &&
            microMediaStream instanceof MediaStream;

        // 1. Combine audio from Microphone and System audio:
        if (hasSystemAudioAndMicro) {
            const combinedMediaStream = new MediaStream(); // Used to combine Screen recording video and audio (Microphone and System audio) together.
            const audioContext = new AudioContext();
            const audioDestinationNode = audioContext.createMediaStreamDestination();

            // - Screen recording video from displayMediaStream:
            displayMediaStream.getVideoTracks().forEach((videoTrack) => {
                combinedMediaStream.addTrack(videoTrack);
            });

            // - System audio from displayMediaStream:
            if (displayMediaStream.getAudioTracks().length > 0) {
                const systemAudioSource = audioContext.createMediaStreamSource(displayMediaStream);
                // Set up its volume:
                const systemAudioGain = audioContext.createGain();
                systemAudioGain.gain.value = 1.0;
                // Add it to the destination:
                systemAudioSource.connect(systemAudioGain).connect(audioDestinationNode);
            }

            // - Microphone from microMediaStream:
            if (microMediaStream.getAudioTracks().length > 0) {
                const micSource = audioContext.createMediaStreamSource(microMediaStream);
                // Set up its volume:
                const micGain = audioContext.createGain();
                micGain.gain.value = 1.0;
                // Add it to the destination:
                micSource.connect(micGain).connect(audioDestinationNode);
            }

            // - Add the combine of Microphone and System audio:
            audioDestinationNode.stream.getAudioTracks().forEach((audioTrack) => {
                combinedMediaStream.addTrack(audioTrack);
            });

            return combinedMediaStream;
        }

        // 2.
        if (hasNoAudio || hasOnlySystemAudio) {
            return displayMediaStream;
        }

        // 3.
        if (hasOnlyMicro) {
            return new MediaStream([...displayMediaStream.getTracks(), ...microMediaStream.getTracks()]);
        }

        return null;
    }

    function startWaitingForRecording() {
        setValues({
            status: "waiting-for-recording",
        });
    }

    function startRecordingAfterAsking() {
        if (!(mediaStream.current instanceof MediaStream)) {
            return;
        }

        // 1. Recording:
        mixedMediaStream.current = getMediaStreamForRecording(mediaStream.current, audioMediaStream.current);
        if (mixedMediaStream.current instanceof MediaStream) {
            mediaRecorder.current = new MediaRecorder(mixedMediaStream.current);
            mediaRecorder.current.ondataavailable = handleMediaRecorderDataAvailable;
            mediaRecorder.current.onstop = handleMediaRecorderStop;
            mediaRecorder.current.start();

            dispatch(setRecordingTimeStart(new Date()));
        } else {
            handleInvalidCheck(t("message.sth_wrong"));
        }

        // 2. Update states:
        setValues({
            status: "recording",
            mediaURL: "",
            mediaName: "",
        });

        notification.success({
            message: recordingDescription.started,
        });
    }

    async function startCapture(startRecordingRightAfterAsking = true) {
        cleanup();
        handleStopRecording();

        setValues({
            status: "granting-permissions",
        });

        try {
            mediaStream.current = await navigator.mediaDevices.getDisplayMedia(displayMediaStreamOptions);
            audioMediaStream.current = withMicrophone
                ? await navigator.mediaDevices.getUserMedia(audioMediaStreamConstraints)
                : null;

            if (mediaStream.current instanceof MediaStream) {
                const handleInvalidCheck = (errMsg) => {
                    cleanup();
                    setValues({
                        status: "stopped",
                    });
                    notification.error({
                        message: errMsg,
                    });
                };

                // Check if the selected displaySurface is 'monitor' or not:
                const screenVideoTrack = mediaStream.current.getVideoTracks()[0];
                const isPassedDisplaySurface = screenVideoTrack.getSettings().displaySurface === "monitor";
                if (!isPassedDisplaySurface) {
                    handleInvalidCheck(recordingDescription.displaySurface_must_be_monitor);
                    return;
                }

                // Check if the system audio is turned on:
                if (withSystemAudio) {
                    const systemAudioTrack = mediaStream.current.getAudioTracks()[0];
                    const isPassedAudioOn = systemAudioTrack;
                    if (!isPassedAudioOn) {
                        handleInvalidCheck(recordingDescription.systemAudio_mustbe_turnedon);
                        return;
                    }
                }

                // If "Stop sharing" is clicked, stop and reset all related items (system audio, microphone, recorder).
                onEndedMediaStreamRef.current = () => {
                    handleAfterStopSharingScreenRef.current();
                };
                mediaStream.current.getTracks().forEach((track) => {
                    track.addEventListener("ended", onEndedMediaStreamRef.current);
                });

                // Two cases:
                // - To start recording right after asking to grant permission, run startRecordingAfterAsking() immediately.
                // - To wait until startRecordingAfterAsking() is called.
                if (startRecordingRightAfterAsking) {
                    startRecordingAfterAsking();
                } else {
                    startWaitingForRecording();
                }
            } else {
                cleanup();
                setValues({
                    status: "stopped",
                });
                notification.error({
                    message: t("message.sth_wrong"),
                });
            }
        } catch (err) {
            cleanup();
            setValues({
                status: "stopped",
            });
            const errMsg = err.toString();
            notification.error({
                message: recordingDescription[errMsg] || errMsg,
            });
        }
    }

    async function startCameraViewer(htmlSelector) {
        if (!withCamera) {
            return;
        }

        if (webcamMediaStream.current) {
            webcamMediaStream.current.getTracks().forEach((track) => track.stop());
        }
        webcamMediaStream.current = null;

        try {
            webcamMediaStream.current = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: true,
            });

            htmlElementVideoRef.current = document.querySelector(htmlSelector);

            if (htmlElementVideoRef.current) {
                htmlElementVideoRef.current.srcObject = webcamMediaStream.current;
                htmlElementVideoRef.current.muted = true;
                htmlElementVideoRef.current.style.pointerEvents = "none";
            }
        } catch (err) {
            const errMsg = err.toString();
            notification.error({
                message: errMsg,
            });
        }
    }

    function handleStartRecording(step) {
        if (!isNaN(step)) {
            switch (step) {
                // Ask to grant permission:
                case 1: {
                    startCapture(false);
                }
                // Start recording:
                case 2: {
                    startRecordingAfterAsking();
                }
                default:
                    break;
            }
        } else {
            startCapture(true);
        }
    }

    function handleStopRecording() {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
        }
    }

    function handlestartCameraViewer(htmlSelector) {
        startCameraViewer(htmlSelector);
    }

    useEffect(() => {
        return () => {
            handleStopRecording();
            cleanup();
        };
    }, []);

    const shared = {
        recorderStatus: values.status,
        blob: values.blob,
        mediaName: values.mediaName,
        mediaURL: values.mediaURL,
        startRecording: handleStartRecording,
        stopRecording: handleStopRecording,
        startCameraViewer: handlestartCameraViewer,
    };

    return (
        <ScreenCamMicRecorderContext.Provider value={shared}>
            <div className="screencammic-recorder">
                {children}
                <div className="recording-status">
                    <div className="recording-actions">
                        {/* {values.status === "stopped" && (
                            <Tooltip title={t("exam_proctoring.start_recording")} placement="left">
                                <button className="action-button start" onClick={shared.startRecording}>
                                    <CaretRightOutlined />
                                </button>
                            </Tooltip>
                        )} */}
                        {/* {values.status === "recording" && (
                            <Tooltip title={t("exam_proctoring.stop_recording")} placement="left">
                                <button className="action-button stop" onClick={shared.stopRecording}>
                                    <PoweroffOutlined />
                                </button>
                            </Tooltip>
                        )} */}
                    </div>
                    {/* {values.mediaURL && (
                        <Tooltip title={t("shared.save")} placement="left">
                            <a
                                className="save-button"
                                href={values.mediaURL}
                                target="_blank"
                                download={values.mediaName}
                            >
                                <SaveFilled />
                            </a>
                        </Tooltip>
                    )} */}
                    {values.status === "recording" && (
                        <div className="satus-on">
                            <span className="icon">&#9679;</span>
                            <span className="abbr">rec</span>
                            <span>
                                <Stopwatch />
                            </span>
                        </div>
                    )}
                </div>

                {/* Test: */}
                {/* <video autoPlay id="camera-viewer-draggable"></video>
                <button
                    onClick={() => {
                        if (shared?.startCameraViewer instanceof Function) {
                            shared.startCameraViewer("#camera-viewer-draggable");
                        }
                    }}
                >
                    Open camera viewer
                </button>
                <button className="action-button start" onClick={() => shared.startRecording(1)}>
                    Ask for permissions
                </button>
                <button className="action-button start" onClick={() => shared.startRecording(2)}>
                    Record
                </button> */}
            </div>
        </ScreenCamMicRecorderContext.Provider>
    );
};

export default ScreenCamMicRecorder;
