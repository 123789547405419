import { QuestionCircleOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip, notification } from "antd";
import { useMemo } from "react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { accessRoles, getMe, logOut } from "src/api/containers/auth";
import { ReactComponent as SvgCaretDown } from "src/assets/images/app-icons/app-caret-down.svg";
import { ReactComponent as SvgHamburger } from "src/assets/images/app-icons/app-hamburger-btn.svg";
import { ReactComponent as SvgMessage } from "src/assets/images/app-icons/app-message.svg";
import { ReactComponent as SvgPower } from "src/assets/images/app-icons/app-power.svg";
import LogoHorizontal from "src/assets/images/app-logo/Logo-horizontal.png";
import UserAvatarDefault from "src/assets/images/profile/user-avatar-default.png";
import configs from "src/configs";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import HelpModal from "src/modules/components/Help/Screens/HelpModal";
import ButtonSubscription from "src/modules/components/Payment/ButtonSubscription";
import ModalSubscriptionPlans from "src/modules/components/Payment/ModalSubscriptionPlans";
import { login, logout } from "src/reducers/auth";
import { setCollapsed } from "src/reducers/general";
import { useRouting } from "src/utils/router";

import LanguageMenu from "../LanguageMenu";
import NavSidebar from "../NavSidebar";
import "./Header.scss";

const Header = ({ className = "", showNavMenu = true }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const { lang, collapsed } = useSelector((state) => state.general);
    const currentPage = useSelector((state) => state.general.page);

    const isUpgradable = configs.ALLOW_UPGRADE_ACCOUNT && ["teacher"].includes(user.role);

    const sidebarRef = useRef();
    const userinfowrapRef = useRef();
    const userinfoRef = useRef();
    const [values, setValues] = useValues({
        userinfoElemClassname: "",
    });
    const [modalValues, setModalValues] = useValues({
        showHelpModal: false,
        showPlansModal: false,
    });

    const linkToHome = useMemo(() => {
        const newRole = user?.role === "student" ? "student" : "teacher";

        return `/${lang}${accessRoles?.[newRole]?.homeRoute}`;
    }, [user, lang]);

    const handleLogOut = () => {
        logOut().then((res) => {
            if (res?.status) {
                notification.success({
                    message: t("message.logout_success"),
                });
                dispatch(logout());
            }
        });
    };

    function handleHelpClick() {
        setModalValues({
            showHelpModal: !modalValues.showHelpModal,
        });
    }

    function handleChangeVisible(val) {
        setModalValues({
            showHelpModal: val,
        });
    }

    const handleToggleNavSidebar = (e) => {
        const { target } = e;
        if (!collapsed && !sidebarRef.current.contains(target)) {
            dispatch(setCollapsed(true));
        } else {
            dispatch(setCollapsed(false));
        }
    };

    const handleTogglePlansModal = (isVisible) => {
        if (isVisible !== undefined) {
            setModalValues({
                showPlansModal: isVisible,
            });
        } else {
            setModalValues({
                showPlansModal: !modalValues.showPlansModal,
            });
        }
    };

    useEffect(() => {
        if (user?.is_new_user) {
            setModalValues({
                showHelpModal: true,
            });
        }
    }, []);

    useEffect(() => {
        const _userinfoElemWrapperWidth = userinfowrapRef.current?.getBoundingClientRect().width;
        const _userinfoElemWidth = userinfoRef.current?.getBoundingClientRect().width;
        if (_userinfoElemWidth > _userinfoElemWrapperWidth) {
            setValues({
                userinfoElemClassname: "sliding",
            });
        } else {
            setValues({
                userinfoElemClassname: "limited",
            });
        }
    }, [user]);

    return (
        <div className={`app-header-wrapper${className ? " " + className : ""}`}>
            <div className="app-header">
                <div className="header-left-section">
                    <Link className="header-logo-wrapper with-title" to={linkToHome}>
                        <img src={LogoHorizontal} alt="e-learning-logo" />
                        {/* <span className="header-logo-title">{"PHUONG NAM EDUCATION"}</span> */}
                    </Link>
                </div>
                <div className="header-right-section">
                    <div className="act-menu">
                        <span className="act-menu-item user-quick-info">
                            <Link to={generate("profile")} className="clickable-item">
                                <span className="user-avatar-wrapper">
                                    <img src={user.avatar || UserAvatarDefault} alt="55x55" draggable={false}></img>
                                </span>
                            </Link>
                            <span className="user-label-wrapper">
                                <Link to={generate("profile")} className="clickable-item">
                                    <span
                                        ref={userinfowrapRef}
                                        className={`user-info-wrapper ${values.userinfoElemClassname}`}
                                    >
                                        <span ref={userinfoRef} className="user-info">
                                            <span>{t("header.say_hi")}, </span>
                                            <span>{user.name || "Unknown"}</span>
                                        </span>
                                    </span>
                                </Link>
                                {isUpgradable && (
                                    // <CustomButton
                                    //     className="upgrade-btn"
                                    //     type="primary"
                                    //     title={t("account.upgrade")}
                                    //     onClick={handleTogglePlansModal}
                                    // ></CustomButton>
                                    <ButtonSubscription className="upgrade-btn" onClick={handleTogglePlansModal} />
                                )}
                            </span>
                        </span>
                        <span className="act-menu-item language-setting">
                            <span>{t("language.language")}:</span>
                            <Dropdown trigger={["click"]} placement="bottomRight" overlay={<LanguageMenu />}>
                                <span>
                                    {t(`language.${lang}`)} <SvgCaretDown />
                                </span>
                            </Dropdown>
                        </span>
                        <span className="act-menu-item act-list">
                            <span className="act-button-wrapper">
                                <Tooltip title={t("header.help")} placement="bottomRight">
                                    <span className="act-button question" onClick={handleHelpClick}>
                                        <QuestionCircleOutlined />
                                    </span>
                                </Tooltip>
                                <HelpModal
                                    visible={modalValues.showHelpModal}
                                    page={currentPage}
                                    onChangeVisible={handleChangeVisible}
                                />
                            </span>
                            {/* <span className="act-button-wrapper not-hide">
                                <span className="act-button">
                                    <span className="act-icon">
                                        <SvgMessage />
                                    </span>
                                </span>
                            </span> */}
                            {showNavMenu === true ? (
                                <>
                                    {/* <Dropdown trigger={["click"]} placement="bottomRight" overlay={<NavMenu />}>
                                        <span className="act-button-wrapper not-hide">
                                            <span className="act-button">
                                                <span className="act-icon">
                                                    <SvgHamburger />
                                                </span>
                                            </span>
                                        </span>
                                    </Dropdown> */}
                                    <span className="act-button-wrapper not-hide" onClick={handleToggleNavSidebar}>
                                        <span className="act-button">
                                            <span className="act-icon">
                                                <SvgHamburger />
                                            </span>
                                        </span>
                                    </span>
                                </>
                            ) : null}
                            <Tooltip title={t("nav.logout")} placement="bottomRight">
                                <span className="act-button-wrapper" onClick={handleLogOut}>
                                    <span className="act-button">
                                        <span className="act-icon">
                                            <SvgPower />
                                        </span>
                                    </span>
                                </span>
                            </Tooltip>
                        </span>
                    </div>
                </div>
            </div>

            {showNavMenu === true ? (
                <div className="app-header-navsidebar">
                    <NavSidebar sidebarRef={sidebarRef} />
                </div>
            ) : null}

            <ModalSubscriptionPlans
                visible={modalValues.showPlansModal}
                onOk={handleTogglePlansModal}
                onCancel={handleTogglePlansModal}
            />
        </div>
    );
};

export default Header;
