/**
 * Round a given number to the nearest multiple of a specific step or increment.
 * + If roundedStep = 0.1, value will be transformed like below:
 *   + 0.12121212 becomes 0.1
 *   + 0.19191919 becomes 0.2
 * + If roundedStep = 0.5, value will be transformed like below:
 *   + 0.12121212 becomes 0
 *   + 0.49494949 becomes 0.5
 *   + 0.72121212 becomes 0.5
 *   + 0.92929292 becomes 1
 * + If roundedStep = 1, value will be transformed like below:
 *   + 0.12121212 becomes 0
 *   + 0.49494949 becomes 0
 *   + 0.72121212 becomes 1
 *   + 0.92929292 becomes 1
 * @param {Number} value A number to be rounded.
 * @param {Number} roundedStep A specific step, such as 0.1, 0.5, 1,... .
 * @returns Rounded number.
 */
export const roundToStep = (value, roundedStep = 0.1) => {
    if (isNaN(value)) {
        return value;
    }
    if (isNaN(roundedStep) || roundedStep <= 0) {
        return value;
    }

    // Round the value to the nearest multiple of `roundedStep`:
    const roundedValue = Math.round(value / roundedStep) * roundedStep;
    return Number(roundedValue.toFixed(2));
};

/**
 * Round a given number to the nearest multiple of a specific step or increment.
 * @param {Number[]} valueArr A list of numbers to be rounded.
 * @param {Number|Number[]} roundedStep A specific step, such as 0.1, 0.5, 1,... .
 * @returns Rounded number list.
 */
export const roundToStepForArray = (valueArr, roundedStep = 0.1) => {
    if (!(valueArr instanceof Array)) {
        return valueArr;
    }

    if (typeof roundedStep === "number") {
        const valueArrClone = [...valueArr];
        for (let i = 0; i < valueArrClone.length; i++) {
            // Round the value to the nearest multiple of `roundedStep`:
            valueArrClone[i] = roundToStep(valueArrClone[i], roundedStep);
        }
        return valueArrClone;
    }

    if (roundedStep instanceof Array) {
        const valueArrClone = [...valueArr];
        for (let i = 0; i < valueArrClone.length; i++) {
            // Round the value to the nearest multiple of `roundedStep`:
            valueArrClone[i] = roundedStep[i]
                ? roundToStep(valueArrClone[i], roundedStep[i])
                : Number(valueArrClone[i].toFixed(2));
        }
        return valueArrClone;
    }

    return valueArr;
};
