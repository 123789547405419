import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Progress, Checkbox, Select, Spin, Form } from "antd";
import { useTranslation } from "react-i18next";
import ExamChartController from "../../components/ExamChartController";
import StudentCard from "./components/StudentCard";
import { getAnswerDataListForAssignment, getAssignmentOverview } from "src/api/containers/statistic";
import "./Overview.scss";
import { compareObjectsDeeply } from "src/utils/helpers/comparison";

const Overview = ({
    assignmentData = {},
    selectedCourseClassId = "",
    isFetchingStudents = false,
    optionListStudent = [],
    handleSwitchTab = () => {},
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const params = useParams();

    const [isCheckAll, setIsCheckAll] = useState(true);
    const [selectedStudentRecordIds, setSelectedStudentRecordIds] = useState([]);
    const [selectedFilterByStatus, setSelectedFilterByStatus] = useState("all");
    const [overviewRp, setOverviewRp] = useState({
        loading: false,
        data: {},
    });
    const [answerDataRp, setAnswerDataRp] = useState({
        loading: false,
        data: [],
    });

    const [answerStats, timeStats, timeMaxValue] = useMemo(() => {
        const generateChartAnswerStatistics = () => {
            const answerStats = [];
            const timeStats = [];
            let timeMaxValue = 0;
            answerDataRp.data.forEach((d) => {
                const numString = (d.order || 0).toString();
                answerStats.push(
                    { xLabel: numString, itemType: "correct", yColValue: d.correct },
                    { xLabel: numString, itemType: "correct_nearly", yColValue: d.part_correct },
                    { xLabel: numString, itemType: "incorrect", yColValue: d.wrong },
                    { xLabel: numString, itemType: "mark_by_hand", yColValue: d.hand_dots },
                    { xLabel: numString, itemType: "skip", yColValue: d.skip }
                );
                timeStats.push({ xLabel: numString, yLineValue: Math.round(d.avg_time || 0) });
                if (timeMaxValue < (d.avg_time || 0)) {
                    timeMaxValue = d.avg_time || 0;
                }
            });
            timeMaxValue = Math.round(timeMaxValue * 2);
            return [answerStats, timeStats, timeMaxValue];
        };
        return generateChartAnswerStatistics();
    }, [answerDataRp.data]);

    const [filteredList] = useMemo(() => {
        const filteredList = optionListStudent.filter((dataItem, dIndex) => {
            if (selectedFilterByStatus === "not_submitted") {
                if (
                    !(
                        dataItem.user?.user_records.length === 0 ||
                        dataItem.user?.user_records[0]?.is_student_submit === false
                    )
                ) {
                    return false;
                }
            } else if (selectedFilterByStatus === "ongoing") {
                if (
                    !(
                        dataItem.user?.user_records.length > 0 &&
                        dataItem.user?.user_records[0]?.is_student_submit === false
                    )
                ) {
                    return false;
                }
            } else if (selectedFilterByStatus === "submitted") {
                if (!(dataItem.user?.user_records[0]?.is_student_submit === true)) {
                    return false;
                }
            } else if (selectedFilterByStatus === "graded") {
                if (!(dataItem.user?.user_records[0]?.is_teacher_submit === true)) {
                    return false;
                }
            }
            return true;
        });
        // Update selected students to refresh chart:
        let currSelectedRecordIds = filteredList?.map((d) => d.user?.user_records[0]?._id);
        currSelectedRecordIds = currSelectedRecordIds.filter((strId) => !!strId);
        setSelectedStudentRecordIds(currSelectedRecordIds);
        // This new list of students will be displayed:
        return [filteredList];
    }, [selectedFilterByStatus]);

    const handleSelectAllRecord = (status) => {
        setIsCheckAll(status);
        if (status === true) {
            let currSelectedRecordIds = optionListStudent?.map((d) => d.user?.user_records[0]?._id);
            currSelectedRecordIds = currSelectedRecordIds.filter((strId) => !!strId);
            setSelectedStudentRecordIds(currSelectedRecordIds);
        } else {
            setSelectedStudentRecordIds([]);
        }
    };

    const handleSelectRecord = (status, recordId) => {
        if (recordId) {
            if (status === true) {
                setSelectedStudentRecordIds([...selectedStudentRecordIds, recordId]);
            } else {
                setIsCheckAll(false);
                setSelectedStudentRecordIds([...selectedStudentRecordIds.filter((val) => !(val === recordId))]);
            }
        }
    };

    const handleSelectFilterStudentsByStatus = (statusValue) => {
        setSelectedFilterByStatus(statusValue);
    };

    useEffect(() => {
        if (Array.isArray(optionListStudent)) {
            let currSelectedRecordIds = optionListStudent.map((d) => d.user?.user_records[0]?._id);
            currSelectedRecordIds = currSelectedRecordIds.filter((strId) => !!strId);
            if (compareObjectsDeeply(currSelectedRecordIds, selectedStudentRecordIds) === false) {
                setSelectedStudentRecordIds(currSelectedRecordIds);
            }
        }
    }, [optionListStudent]);

    useEffect(() => {
        if (params.assignmentId) {
            // Preparation:
            const fetchParams = { course_id: selectedCourseClassId || undefined };
            // Fetch overview data:
            setOverviewRp({ ...overviewRp, loading: true });
            getAssignmentOverview(params.assignmentId, fetchParams).then((res) => {
                if (res.status === true) {
                    setOverviewRp({
                        ...overviewRp,
                        loading: false,
                        data: res,
                    });
                } else {
                    setOverviewRp({
                        ...overviewRp,
                        loading: false,
                        data: {},
                    });
                }
            });
        }
    }, [selectedCourseClassId]);

    useEffect(() => {
        if (params.assignmentId && selectedStudentRecordIds.length && !isFetchingStudents) {
            // Preparation:
            const fetchParams = { recordIds: selectedStudentRecordIds, course_id: selectedCourseClassId || undefined };
            // Fetch answer data list by selected records:
            setAnswerDataRp({ ...answerDataRp, loading: true });
            getAnswerDataListForAssignment(params.assignmentId, fetchParams).then((res) => {
                if (res.status === true) {
                    setAnswerDataRp({
                        ...answerDataRp,
                        loading: false,
                        data: res.data,
                    });
                } else {
                    setAnswerDataRp({
                        ...answerDataRp,
                        loading: false,
                        data: [],
                    });
                }
            });
        }
    }, [selectedCourseClassId, selectedStudentRecordIds]);

    return (
        <div className="overview">
            <Form
                form={form}
                onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                    }
                }}
            >
                <Row gutter={[20, 20]}>
                    <Col xl={6} xs={24}>
                        <div className="overview-statistical">
                            <Spin spinning={overviewRp.loading}>
                                <div className="statistics-data">
                                    <span className="course-name">{assignmentData.name || "_"}</span>
                                    <Progress
                                        type="circle"
                                        percent={overviewRp.data.percent_score || 0}
                                        strokeColor={{
                                            "0%": "#0077FF80",
                                            "100%": "#0077FF",
                                        }}
                                        strokeWidth={8}
                                        format={(percent) => (
                                            <div className="progress-bar">
                                                <p>{t("question.total_score")}</p>
                                                <h1>{percent}%</h1>
                                                <p>MEDIAN {percent}%</p>
                                            </div>
                                        )}
                                        width={195}
                                    />
                                    <span className="estimate">
                                        {overviewRp.data.student_submitted || "_ /_"}{" "}
                                        {t("report.student_submitted").toLowerCase()}
                                    </span>
                                </div>
                            </Spin>
                        </div>
                    </Col>
                    <Col xl={18} xs={24}>
                        <div className="overview-chart">
                            <Spin spinning={isFetchingStudents || overviewRp.loading || answerDataRp.loading}>
                                {!isFetchingStudents &&
                                !overviewRp.loading &&
                                !answerDataRp.loading &&
                                selectedStudentRecordIds.length > 0 ? (
                                    <ExamChartController
                                        lineColor="#0077FF"
                                        propsColor={{
                                            correct: "#75B136",
                                            correct_nearly: "#0077ff",
                                            incorrect: "#E56965",
                                            mark_by_hand: "#F8CC5A",
                                            skip: "#DDDDDD",
                                        }}
                                        propsLabel={{
                                            correct: t("assignment.correct"),
                                            correct_nearly: t("assignment.correct_nearly"),
                                            incorrect: t("assignment.incorrect"),
                                            mark_by_hand: t("assignment.mark_by_hand"),
                                            skip: t("assignment.skip"),
                                        }}
                                        yLineTitle={t("assignment.time_spent")}
                                        examData={answerStats}
                                        timeData={timeStats}
                                        yLineMaxValue={timeMaxValue}
                                        limitYColEachPage={10}
                                        yLabel={{
                                            visible: true,
                                            labelLeft: t("report.number_of_students"),
                                            labelRight: t("report.average_time_spent_in_seconds"),
                                        }}
                                    />
                                ) : null}
                            </Spin>
                            {!isFetchingStudents &&
                            !overviewRp.loading &&
                            !answerDataRp.loading &&
                            selectedStudentRecordIds.length === 0 ? (
                                <span className="chart-null">{t("report.please_select_student")}</span>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <div className="overview-student-info-list">
                    <div className="option-menu-wrapper">
                        <div className="option-choose-all">
                            <Checkbox
                                className="app-checkbox"
                                onChange={() => handleSelectAllRecord(!isCheckAll)}
                                checked={isCheckAll}
                            />
                            <span onClick={() => handleSelectAllRecord(!isCheckAll)}>
                                {t("statistical.choose_all")}
                            </span>
                        </div>
                        <div className="option-filter">
                            <span>{t("statistical.filter_by_status")}</span>
                            <Select
                                className="app-select"
                                showArrow
                                defaultValue={"all"}
                                values={selectedFilterByStatus}
                                onChange={(val) => {
                                    handleSelectFilterStudentsByStatus(val);
                                }}
                            >
                                <Select.Option value="all">{t("statistical.assigned_student")}</Select.Option>
                                <Select.Option value="not_submitted">{t("statistical.not_submit")}</Select.Option>
                                <Select.Option value="ongoing">{t("statistical.doing")}</Select.Option>
                                <Select.Option value="submitted">{t("statistical.submitted")}</Select.Option>
                                <Select.Option value="graded">{t("statistical.graded")}</Select.Option>
                            </Select>
                        </div>
                    </div>

                    <div className="student-info-list">
                        <Spin spinning={isFetchingStudents}>
                            <Row gutter={[30, 30]}>
                                {optionListStudent.map((dataItem, dIndex) => {
                                    if (selectedFilterByStatus === "not_submitted") {
                                        if (
                                            !(
                                                dataItem.user?.user_records.length === 0 ||
                                                dataItem.user?.user_records[0]?.is_student_submit === false
                                            )
                                        ) {
                                            return null;
                                        }
                                    } else if (selectedFilterByStatus === "ongoing") {
                                        if (
                                            !(
                                                dataItem.user?.user_records.length > 0 &&
                                                dataItem.user?.user_records[0]?.is_student_submit === false
                                            )
                                        ) {
                                            return null;
                                        }
                                    } else if (selectedFilterByStatus === "submitted") {
                                        if (!(dataItem.user?.user_records[0]?.is_student_submit === true)) {
                                            return null;
                                        }
                                    } else if (selectedFilterByStatus === "graded") {
                                        if (!(dataItem.user?.user_records[0]?.is_teacher_submit === true)) {
                                            return null;
                                        }
                                    }
                                    return (
                                        <Col lg={8} sm={12} xs={24} key={dIndex}>
                                            <StudentCard
                                                checked={selectedStudentRecordIds.includes(
                                                    dataItem.user?.user_records[0]?._id
                                                )}
                                                assignmentStatus={{
                                                    isStudentSubmitted:
                                                        dataItem.user?.user_records[0]?.is_student_submit || false,
                                                    isTeacherSubmitted:
                                                        dataItem.user?.user_records[0]?.is_teacher_submit || false,
                                                }}
                                                userAvatar={dataItem.user?.avatar || ""}
                                                userName={dataItem.user?.name || ""}
                                                actualScore={dataItem.user?.user_records[0]?.score || 0}
                                                maxScore={assignmentData.exam?.max_score}
                                                progress={
                                                    ((dataItem.user?.user_records[0]?.score || 0) /
                                                        (assignmentData.exam?.max_score || 0)) *
                                                    100
                                                }
                                                answerInfoList={
                                                    dataItem.user?.user_records[0]?.reports_not_type_passage || []
                                                }
                                                handleAddToCheckList={(val) => {
                                                    handleSelectRecord(val, dataItem.user?.user_records[0]?._id);
                                                }}
                                                handleClickToViewDetail={() =>
                                                    handleSwitchTab("2", { studentId: dataItem.user?._id || "" })
                                                }
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Spin>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Overview;
