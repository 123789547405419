import { Tooltip, notification } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { questionTypes } from "src/api/containers/question";
import { ReactComponent as LightBulb } from "src/assets/images/app-icons/light-bulb.svg";
import iconChevronLeft from "src/assets/images/icon-question-chevron-left.svg";
import iconChevronRight from "src/assets/images/icon-question-chevron-right.svg";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { addAnswer, addAnswerPreview } from "src/reducers/answer";

import DragDrop from "./DragDrop";
import DragDropGroup from "./DragDropGroup";
import FillBlanks from "./FillBlanks";
import FillBlanksDragDrop from "./FillBlanksDragDrop";
import FillBlanksInput from "./FillBlanksInput";
import Graph from "./Graph";
import GraphNumberLine from "./GraphNumberLine";
import Highlight from "./Highlight";
import HighlightImage from "./HighlightImage";
import Hotspot from "./Hotspot";
import ImageWithLabels from "./ImageWithLabels";
import MathTextDropdown from "./MathTextDropdown";
import Matrix from "./Matrix";
import MultipleChoice from "./MultipleChoice";
import MultipleChoiceBoolean from "./MultipleChoiceBoolean";
import MultipleChoiceSentenceQuiz from "./MultipleChoiceSentenceQuiz";
import MultipleChoiceTable from "./MultipleChoiceTable";
import Passage from "./Passage";
import PhoneticTable from "./PhoneticTable";
import "./QuestionPanel.scss";
import Shading from "./Shading";
import Sort from "./Sort";
import SpeechToText from "./SpeechToText";
import SpeechToTextConversationAudio from "./SpeechToTextConversationAudio";
import SpeechToTextConversationText from "./SpeechToTextConversationText";
import Writing from "./Writing";
import WritingShort from "./WritingShort";

const ListQuestionPinyin = [
    "multiple_choice",
    "multiple_choice_sentence_quiz",
    "sort",
    "drag_drop",
    "drag_drop_group",
    "drag_drop_group_order_by",
    "fill_in_the_blank",
    // "highlight",
    "fill_in_the_blank_image",
    "fill_in_the_blank_drag_drop_image",
    "passage",
    "speech_to_text",
    "speech_to_text_conversation_text",
    "speech_to_text_conversation_audio",
];

function QuestionPanel(props) {
    const {
        questionInfo,

        mode,
        recordId,
        questionOrder = 0,
        showQuestionNumber = true,
        showBottomNav = true,
        questionAmount,
        onChange,
        isDisableCopyPaste,
        goPrevQuestion = () => {},
        goNextQuestion = () => {},
        ...rest
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listAnswers } = useSelector((state) => state.answer);

    const [values, setValues] = useValues({
        isShowPinyin: false,
    });

    const isChineseLang = useMemo(() => {
        if (questionInfo?.language?.code === "CN" && ListQuestionPinyin.includes(questionInfo?.type)) {
            return true;
        }
        return false;
    }, []);

    const handleChange = (value) => {
        if (onChange && onChange instanceof Function) {
            onChange(value);
        }

        const result = {
            answered: value.answered,
            ...(value.type && { type: value.type }),

            // Tạm thời để đây vì câu highlight cần submit thêm cái passage sau sẽ phải đổi
            ...(value.passage_highlighted && { passage_highlighted: value.passage_highlighted }),
        };

        if (mode === "preview") {
            // this mode name is wrong! should be "review"
            dispatch(
                addAnswerPreview({
                    value: { [questionInfo.id]: result },
                })
            );
        }

        if (mode === "test") {
            dispatch(
                addAnswer({
                    value: { [questionInfo.id]: result },
                    record_id: recordId,
                })
            );
        }
    };

    const renderQuestion = () => {
        const isReadonly = mode === "readonly";
        let answered = questionInfo?.answered;
        // Field này cho câu passage. Tạm thời câu này sẽ phải đẩy lên 2 Field (Sẽ cập nhật sau)
        let passage_highlighted = undefined;

        let newQuestionInfo = structuredClone(questionInfo);
        // Xử lý trường hợp api khi tạo đề sẽ trả về trường detail.
        // if (questionInfo?.detail) {
        //     delete newQuestionInfo.detail;

        //     newQuestionInfo = {
        //         ...newQuestionInfo,
        //         ...questionInfo.detail,
        //     };
        // }

        if (mode === "preview") {
            try {
                answered = listAnswers["preview"][newQuestionInfo.id].answered;

                // Highlight question
                if (listAnswers?.["preview"]?.[newQuestionInfo.id]?.passage_highlighted) {
                    passage_highlighted = listAnswers["preview"][newQuestionInfo.id].passage_highlighted;
                }
            } catch (err) {
                answered = null;
            }
        }
        if (mode === "test" || mode === "readonly") {
            try {
                answered = listAnswers[recordId][newQuestionInfo.id].answered;

                // Highlight question
                if (listAnswers?.[recordId]?.[newQuestionInfo.id]?.passage_highlighted) {
                    passage_highlighted = listAnswers[recordId][newQuestionInfo.id].passage_highlighted;
                }
            } catch (err) {
                answered = null;
            }
        }

        // Luu ý khi truyền answer và defaultAnswer luôn luôn phải cùng định dạng
        const defaultProps = {
            isReadonly,
            onChange: handleChange,
            question: newQuestionInfo.question,
            answer: newQuestionInfo.answer,
            correctAnswer: newQuestionInfo.correct_answer,
            defaultAnswer: answered,
            id: newQuestionInfo.id,
            type: newQuestionInfo?.question_type?.type || newQuestionInfo?.type,
            isShowPinyin: isChineseLang && values.isShowPinyin, //only use in chinese lang
        };
        switch (defaultProps?.type) {
            case "writing":
                return <Writing {...defaultProps} wordLimit={newQuestionInfo.word_limit} />;
            case "writing_short":
                return <WritingShort {...defaultProps} />;
            case "fill_in_the_blank": {
                return <FillBlanks {...defaultProps} />;
            }
            case "fill_in_the_blank_drag_drop": {
                return <FillBlanksDragDrop {...defaultProps} />;
            }
            case "fill_in_the_blank_text": {
                return <FillBlanksInput {...defaultProps} />;
            }
            case "fill_in_the_blank_image": {
                const { coordinates, answer, file, src, width, height } = questionInfo;
                defaultProps.coordinates = coordinates;
                defaultProps.answer = answer;
                defaultProps.src = file?.src || src;
                defaultProps.width = width;
                defaultProps.height = height;
                return <ImageWithLabels labelType="dropdown" {...defaultProps} />;
            }
            case "fill_in_the_blank_drag_drop_image": {
                const { coordinates, answer, file, src, width, height } = questionInfo;
                defaultProps.coordinates = coordinates;
                defaultProps.answer = answer;
                defaultProps.src = file?.src || src;
                defaultProps.width = width;
                defaultProps.height = height;
                return <ImageWithLabels labelType="drag&drop" {...defaultProps} />;
            }
            case "fill_in_the_blank_text_image": {
                const { coordinates, answer, file, src, width, height } = questionInfo;
                defaultProps.coordinates = coordinates;
                defaultProps.answer = answer;
                defaultProps.src = file?.src || src;
                defaultProps.width = width;
                defaultProps.height = height;
                return <ImageWithLabels labelType="text" {...defaultProps} />;
            }

            case "passage":
                return (
                    <Passage
                        {...defaultProps}
                        recordId={props.recordId}
                        paragraphs={newQuestionInfo.paragraphs}
                        showOrdinalNumber={props.showOrdinalNumber}
                    />
                );
            case "multiple_choice":
                return <MultipleChoice {...defaultProps} />;
            case "multiple_choice_sentence_quiz":
                return <MultipleChoiceSentenceQuiz {...defaultProps} />;
            case "multiple_choice_boolean":
                return <MultipleChoiceBoolean {...defaultProps} />;
            case "multiple_choice_table":
                return (
                    <MultipleChoiceTable
                        {...defaultProps}
                        answer1={newQuestionInfo.answer1}
                        answer2={newQuestionInfo.answer2}
                    />
                );
            case "drag_drop": {
                if (newQuestionInfo?.answer1 && newQuestionInfo?.answer2) {
                    defaultProps.answer = {
                        answer1: newQuestionInfo?.answer1,
                        answer2: newQuestionInfo?.answer2,
                    };
                }
                return <DragDrop {...defaultProps} />;
            }
            case "drag_drop_group_order_by":
            case "drag_drop_group":
                defaultProps.answers = newQuestionInfo.answer1;
                defaultProps.groups = newQuestionInfo.answer2;
                return <DragDropGroup {...defaultProps} />;
            case "highlight":
                defaultProps.passage_student = newQuestionInfo.passage_student;
                defaultProps.passage = newQuestionInfo.passage;
                defaultProps.highlight_submit = passage_highlighted;
                return <Highlight {...defaultProps} />;
            case "sort": {
                return <Sort {...defaultProps} />;
            }
            case "highlight_image":
                if (!defaultProps?.answer?.[0])
                    defaultProps.answer = [
                        {
                            width: newQuestionInfo.width,
                            height: newQuestionInfo.height,
                            src: newQuestionInfo.file?.src,
                        },
                    ];
                return <HighlightImage {...defaultProps} />;
            case "highlight_square":
                defaultProps.qInfo = questionInfo;
                return <Shading {...defaultProps} />;
            case "hot_spot":
                if (!defaultProps?.qestionData)
                    defaultProps.qestionData = {
                        width: newQuestionInfo.width,
                        height: newQuestionInfo.height,
                        src: newQuestionInfo.file?.src,
                        paths: newQuestionInfo.paths || newQuestionInfo.path,
                        answer: newQuestionInfo.answer,
                        mode: newQuestionInfo.mode,
                    };
                return <Hotspot {...defaultProps} />;
            case "speech_to_text":
                defaultProps.is_sentence = newQuestionInfo.is_sentence;
                defaultProps.speaker = newQuestionInfo?.language?.speaker || newQuestionInfo.speaker;
                defaultProps.lang = newQuestionInfo?.language?.code;
                defaultProps.spelling = newQuestionInfo?.spelling;
                defaultProps.isPreview = newQuestionInfo?.isPreview;
                defaultProps.mode = mode;
                return <SpeechToText key={newQuestionInfo?.id} {...defaultProps} />;
            case "speech_to_text_conversation_text":
                defaultProps.speaker = questionInfo.speaker;
                defaultProps.lang = questionInfo?.language?.code;
                defaultProps.voice = questionInfo?.voice;
                return <SpeechToTextConversationText {...defaultProps} />;
            case "phonetic_table":
                return <PhoneticTable {...defaultProps} />;
            case "speech_to_text_conversation_audio":
                defaultProps.speaker = questionInfo.speaker;
                defaultProps.lang = questionInfo?.language?.code;
                defaultProps.voice = questionInfo?.voice;
                return <SpeechToTextConversationAudio {...defaultProps} />;
            case "convert_measurement": {
                return <MathTextDropdown {...defaultProps} />;
            }
            case "fill_in_the_blank_latex":
            case "matrix":
                return <Matrix {...defaultProps} template_latex={newQuestionInfo.template_latex} />;
            case "draw_graph": {
                return <Graph {...defaultProps} />;
            }
            case "number_line": {
                const { max_value, min_value, jump_value } = questionInfo;
                defaultProps.max_value = max_value;
                defaultProps.min_value = min_value;
                defaultProps.jump_value = jump_value;
                return <GraphNumberLine {...defaultProps} />;
            }
            default:
                // console.log("Question type not found in question panel", newQuestionInfo?.type);
                return <>Not found</>;
        }
    };

    useEffect(() => {
        if (isDisableCopyPaste) {
            function preventPaste(e) {
                e?.stopImmediatePropagation?.();
                e?.stopPropagation?.();
                e?.preventDefault?.();
                notification.warning({ message: t("anti_cheating.copy_paste_not_allowed") });
                return;
            }

            let eles = document.querySelectorAll('[name="editor-input"]');

            const isValidElements = eles.length;
            if (eles) {
                if (isValidElements) {
                    eles?.forEach((item) => {
                        if (item) {
                            item.addEventListener("paste", preventPaste);
                        }
                    });
                }
            }
            return () => {
                if (isValidElements) {
                    eles?.forEach((item) => {
                        if (item) {
                            item.removeEventListener("paste", preventPaste);
                        }
                    });
                }
            };
        }
    }, [questionInfo.id, isDisableCopyPaste]);

    return (
        <div className="question-panel">
            {showQuestionNumber && (
                <div className="question-label-wrapper">
                    <div className="question-label">
                        <span className="label-wrapper">
                            <span className="label-question">{t("question_panel.question")}</span>
                            <span className="label-number">
                                {String(questionOrder).split(".")[0]
                                    ? `${questionOrder < 10 ? "0" : ""}${String(questionOrder).split(".")[0]}`
                                    : "00"}
                            </span>
                        </span>
                    </div>
                </div>
            )}

            <div className={clsx({ "question-content": true, un_selectable: isDisableCopyPaste })}>
                <div className="content-actions">
                    {isChineseLang && (
                        <Tooltip
                            title={!values.isShowPinyin ? t("question.show_pinyin") : t("question.hide_pinyin")}
                            placement="bottomRight"
                        >
                            <CustomButton
                                type="simple"
                                icon={<LightBulb />}
                                title={undefined}
                                onClick={() => {
                                    setValues({
                                        isShowPinyin: !values.isShowPinyin,
                                    });
                                }}
                                className={`btn-show-pinyin${values.isShowPinyin ? " is-on" : " is-off"}`}
                            ></CustomButton>
                        </Tooltip>
                    )}
                </div>

                <React.Fragment key={questionInfo?.id}>{renderQuestion()}</React.Fragment>
            </div>

            {showBottomNav && (
                <div className="question-nav">
                    <button
                        className={clsx("nav-btn-wrapper", questionOrder === 1 && "disabled")}
                        disabled={questionOrder === 1}
                        onClick={goPrevQuestion}
                    >
                        <span className="nav-btn prev">
                            <img src={iconChevronLeft} alt=""></img>
                        </span>
                    </button>
                    <button
                        className={clsx("nav-btn-wrapper", questionOrder === questionAmount && "disabled")}
                        disabled={questionOrder === questionAmount}
                        onClick={goNextQuestion}
                    >
                        <span className="nav-btn next">
                            <img src={iconChevronRight} alt=""></img>
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
}

QuestionPanel.propTypes = {
    // Question detail:
    questionInfo: PropTypes.shape({
        // Question (HTML string):
        question: PropTypes.string,
        // List of provided answers (possible responses):
        answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        // List of expected answers (expected responses):
        correct_answer: PropTypes.array,
        // Other props:
        _id: PropTypes.string,
        type: PropTypes.oneOf(Object.keys(questionTypes)),
    }),

    // Question panel's mode:
    mode: PropTypes.oneOf([undefined, "preview", "test", "readonly"]),

    // Others:
    recordId: PropTypes.string,
    questionOrder: PropTypes.number,
    showQuestionNumber: PropTypes.bool,
    showBottomNav: PropTypes.bool,
    questionAmount: PropTypes.number,
    onChange: PropTypes.func,
    goPrevQuestion: PropTypes.func,
    goNextQuestion: PropTypes.func,
};

QuestionPanel.defaultProps = {
    questionOrder: 0,
    showQuestionNumber: true,
    showBottomNav: true,
};

export default QuestionPanel;
