import { Button, Modal, Spin, Upload, message, notification } from "antd";
import * as faceApi from "face-api.js";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { detectFaceSpoofing } from "src/api/containers/examCheckin";
import DefaultAvatar from "src/assets/images/no-avatar.png";
import configs from "src/configs";
import { useValues } from "src/hooks";
import ImageWidthSkeleton from "src/modules/components/ImageWithSkeleton";
import { UploadImageBtn } from "src/modules/components/UploadImageBtn";
import WebcamCapture from "src/modules/components/WebcamCapture";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";
import { alertOnTop } from "src/reducers/general";
import { checkIsModalsFaceApiLoaded, checkMultipleFaces, dataURIToBlob } from "src/utils/helpers";
import { getFaceDetectorOptions } from "src/utils/helpers/faceapi";

import "./CandidateAvatar.scss";

const { MIN_CONFIDENCE } = configs.EXAM_PROCTORING.WEBCAM_CAPTURE;

const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};

function dataURIToFile(dataURI, fileName) {
    const blob = dataURIToBlob(dataURI);
    const formData = new FormData();
    formData.append(fileName, blob, "img.jpeg");

    return formData;
}

function CandidateAvatar({
    onChange,
    getAvatarBase64,
    getDetectFaceSpoofingData,
    avtUrl,
    faceDetection,
    getCheckFacesResult,
    setHasPhotoUploaded,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const uploadRef = useRef();
    const lang = useSelector((state) => state.general?.lang);

    const examProctoringData = useContext(ProctoringContext);
    const examRules = examProctoringData?.examCheckInInfo?.assignment?.options;

    const [modelsLoaded, setModelsLoaded] = useState(false);

    const [values, setValues] = useValues({
        isModalWebcam: false,
        detectFaceSpoofingData: null,
        countFacesDetected: null,
        isProcessingImage: false,
    });

    function handleCaptureWebcamImage(urlBase64) {
        if (!urlBase64) {
            notification.error({ message: t("an_error_occur") });
            return false;
        }
        setValues({ isModalWebcam: false });

        setTimeout(() => {
            if (getAvatarBase64 instanceof Function) {
                getAvatarBase64(urlBase64);
                setHasPhotoUploaded(true);
            }
            async function check() {
                await checkAvatar(urlBase64);
            }
            check();
        }, 100);
    }

    const beforeUpload = (file, lang = "vi") => {
        const isValidType = file.type.startsWith("image/");
        const MAX_SIZE = 4;

        if (!isValidType) {
            message.warning(t("exam_checkin.only_upload_img"));
            return isValidType;
        }

        const isValidSize = file.size / (1024 * 1024) < MAX_SIZE;

        if (!isValidSize) {
            if (lang == "vi") {
                message.warning(`Chỉ được phép tải lên hình ảnh dưới ${MAX_SIZE}MB!`);
            } else {
                message.warning(`Only allowed to upload images under ${MAX_SIZE}MB!`);
            }
        }

        return isValidSize;
    };

    const onUploadSuccess = () => {
        // setValues({ loading: false });
        if (setHasPhotoUploaded instanceof Function) {
            setHasPhotoUploaded(true);
        }
    };

    async function checkAvatarBase64(base64, fileImage) {
        let checkingResult = true;

        if (!base64) {
            setValues({ loading: false });
            checkingResult = false;
            return;
        }

        const imgEle = createImgElement({ src: base64 });

        if (imgEle) {
            let result = await checkMultipleFaces(imgEle, faceApi);

            if (result === false) {
                notification.error({ message: t("an_error_occurred") });
                checkingResult = false;
                setValues({ loading: false, countFacesDetected: false });
                return;
            } else if (result > 1) {
                notification.error({
                    message: `${t("many_faces_detected")}. ${t("please_use_another_photo")}`,
                });
                setValues({ loading: false, countFacesDetected: result });
                checkingResult = false;
                return;
            } else {
                const faceDescriptor = await faceApi
                    .detectSingleFace(imgEle, getFaceDetectorOptions({ minConfidence: MIN_CONFIDENCE }))
                    .withFaceLandmarks()
                    .withFaceDescriptor();

                result = faceDescriptor ? 1 : 0;

                setValues({ loading: false, countFacesDetected: result });
            }
            getCheckFacesResult({ countFacesDetected: result });
        } else {
            notification.error({ message: t("an_error_occurred") });
            setValues({ loading: false });
            checkingResult = false;
            return;
        }

        const new_url = base64?.split?.(",")?.[1];
        if (!new_url) {
            setValues({ loading: false });
            checkingResult = false;
            return;
        }

        const { status, message, data } = await detectFaceSpoofing(new_url);
        if (status) {
            getDetectFaceSpoofingData?.(data);
            setValues({ detectFaceSpoofingData: data, isProcessingImage: false });
        } else {
            notification.error({ message: message });
            setValues({ isProcessingImage: false });
            checkingResult = false;
        }

        if (checkingResult) {
            onChange?.(fileImage);
        }
    }

    async function checkAvatar(dataImage) {
        if (!dataImage || !modelsLoaded) return { status: false };
        let checkingResult = true;
        try {
            setValues({ isProcessingImage: true });
            if (typeof dataImage === "string" && dataImage?.startsWith("data:image")) {
                const file = dataURIToFile(dataImage, "file0");
                await checkAvatarBase64(dataImage, file?.get("file0"));
                setValues({ isProcessingImage: false });
            } else {
                // case upload ảnh
                getBase64(dataImage, async (url) => {
                    if (dataImage) {
                        getAvatarBase64(url);
                        setHasPhotoUploaded(true);
                        await checkAvatarBase64(url, dataImage);
                        setValues({ isProcessingImage: false });
                    }
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            setValues({ isProcessingImage: false });
        }

        return { status: checkingResult };
    }

    function renderNoticeMessage() {
        if (values.isFaceDetected) {
            return t("faces_cannot_detected");
        }

        if (values.countFacesDetected > 1) {
            return `${t("many_faces_detected")}. ${t("please_use_another_photo")}`;
        }
        if (values.countFacesDetected === 0) {
            return `${t("no_face_detected")}. ${t("please_use_another_photo")}`;
        }

        if (values.detectFaceSpoofingData?.result === false) {
            return t("your_photo_not_valid");
        }

        return "";
    }

    useEffect(() => {
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + "/models";

            Promise.all([
                faceApi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
                faceApi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceApi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceApi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            ])
                .then(() => {
                    setModelsLoaded(true);
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    console.error(err);
                    notification.error({
                        message: t("error_occurred_please_reload"),
                    });
                    dispatch(
                        alertOnTop({
                            message: t("error_occurred_please_reload"),
                            type: "error",
                            action: "reload_page",
                        })
                    );
                });
        };
        if (!checkIsModalsFaceApiLoaded(faceApi)) {
            loadModels();
        } else {
            setModelsLoaded(true);
        }
    }, []);

    return (
        <>
            <div className="candidate-info-avatar">
                <div className="avatar-wrapper">
                    <Spin spinning={values.isProcessingImage} tip={t("checking_image")}>
                        <div className="avatar-wrapper">
                            <div className="avatar-placholer">
                                <ImageWidthSkeleton
                                    src={avtUrl || DefaultAvatar}
                                    crossOrigin="anonymous"
                                    loading="lazy"
                                    className={"image-item"}
                                    alt="candidate image"
                                />
                            </div>
                            <div className="avatar-overlay">
                                <div className="avatar-overlay-inner">
                                    <div className="actions">
                                        {examRules?.portrait_image == "webcam_picture" ? (
                                            <Button
                                                style={{ width: 90 }}
                                                shape="round"
                                                onClick={() => setValues({ isModalWebcam: true })}
                                            >
                                                {t("take_photo")}
                                            </Button>
                                        ) : (
                                            <Button
                                                style={{ width: 90 }}
                                                shape="round"
                                                onClick={() => {
                                                    if (uploadRef.current) {
                                                        uploadRef.current?.click?.();
                                                    }
                                                }}
                                            >
                                                {t("shared.upload")}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>

                <div style={{ textAlign: "center", color: "red", maxWidth: 200 }}>{renderNoticeMessage()}</div>

                <Modal
                    className="app-modal type-basic"
                    centered
                    visible={values.isModalWebcam}
                    title={"Chụp ảnh từ webcam"}
                    style={{ maxWidth: 600, maxHeight: 780 }}
                    bodyStyle={{
                        height: "calc(100% - 70px)",
                    }}
                    onCancel={() => {
                        setValues({ isModalWebcam: false });
                    }}
                    onOk={() => {
                        setValues({ isModalWebcam: false });
                    }}
                    footer={null}
                >
                    <WebcamCapture handleCapture={handleCaptureWebcamImage} />
                </Modal>
            </div>

            <div style={{ display: "none" }}>
                <UploadImageBtn
                    onUploadSuccess={onUploadSuccess}
                    uploadApi={async (data, file) => await checkAvatar(file)}
                    onUploading={() => {
                        setValues({ isProcessingImage: true, countFacesDetected: -1 });
                    }}
                    onUploadError={(err) => {
                        setValues({ isProcessingImage: false });
                    }}
                    beforeUpload={(file) => {
                        return beforeUpload(file, lang);
                    }}
                    disabled={values.isProcessingImage}
                >
                    <Button shape="round" ref={uploadRef}>
                        {t("upload")}
                    </Button>
                </UploadImageBtn>
            </div>
        </>
    );
}

function createImgElement({ width, src }) {
    var virtualImg = document.createElement("img");

    if (!src) return false;

    virtualImg.src = src;
    virtualImg.alt = "Virtual Image";
    virtualImg.width = width || 300;

    return virtualImg;
}

export default CandidateAvatar;
