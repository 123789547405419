import configs from "src/configs";

/**
 * Check if an action is allowed on a target.
 * @param {string} targetName Page/Section/... .
 * @param {string} actionName Actions: create, read, update, delete, clone,... .
 * @returns Boolean.
 */
export function checkIfActionIsAllowed(targetName, actionName) {
    const allowedActionList = configs.ALLOW_ACTIONS;
    return allowedActionList?.[targetName]?.[actionName] || false;
}
