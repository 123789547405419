import { forwardRef, useEffect, useImperativeHandle, useRef, useState, React } from "react";
import ZwibblerToolbars from "./Toolbars";
import "./ZwibblerDrawer.scss";

//lazy load
import loadable from "@loadable/component";
const ZwibblerModule = loadable.lib(() => import("src/assets/script/zwibbler/index.js"));

function safeZwibblerLoad(zwibbler, data) {
    // hàm load json data của zwibbler
    try {
        zwibbler.load(data);
    } catch (e) {
        try {
            // eslint-disable-next-line no-control-regex
            zwibbler.load(data.replace(/[^\x00-\x80]/g, ""));
        } catch (e2) {
            /* eslint-disable */
            console.log({ e2 });
        }
    }
}

export const ZwibblerDrawer = (props) => {
    const { width, height, src, defaultAnswer, onChange, isReadonly = false } = props;
    const [zwibbler, setZwibbler] = useState();
    const zwibblerEl = useRef(null);

    const zwibblerConfig = {
        // Needed to indicate to Zwibbler that this is the main element.
        zwibbler: "",
        "z-controller": "mycontroller",
        background: "transparent",
        // confine: "view",
    };

    const saveData = (newZwibbler) => {
        const saved = newZwibbler.save("zwibbler3");

        if (onChange instanceof Function) {
            onChange({
                answered: [saved],
            });
        }
    };

    useEffect(() => {
        function initZwibbler() {
            if (window.Zwibbler && zwibblerEl.current && !zwibbler) {
                // initialize Zwibbler
                const newZwibbler = window.Zwibbler.create(zwibblerEl.current, {
                    // defaultZoom: "page",
                    // pageView: "true",
                    // confine: "'view",
                    autoPickTool: false,
                    showToolbar: false,
                    showColourPanel: false,
                    showHints: false,
                    scrollbars: false,
                    readOnly: isReadonly,
                    setFocus: false, // Zwibbler will be unable to intercept any keyboard commands
                    allowZoom: false,
                    allowTextInShape: false, // Zwibbler to write text inside a closed shapes
                    // leaveTextToolOnBlur: true, // Zwibbler text tool data will be saved if user moves out of scratchpad
                });

                newZwibbler.on("document-changed", () => {
                    if (newZwibbler.dirty()) {
                        saveData(newZwibbler);
                    }
                });

                newZwibbler.on("paste", () => {
                    // preventing pasting of images currently
                    return false;
                });

                //setting
                newZwibbler.setConfig("confine", "view");
                setZwibbler(newZwibbler);
            }
        }

        //chạy interval để check zwibbler đã load xong chưa => mếu xong thì chạy hàm khởi tạo....
        let interval = setInterval(() => {
            if ("Zwibbler" in window) {
                initZwibbler();
                clearInterval(interval);
                // console.count("interval");
            }
        }, 100);
        return () => {
            setZwibbler(null);
        };
    }, []);

    useEffect(() => {
        if (zwibbler && defaultAnswer?.[0]) {
            safeZwibblerLoad(zwibbler, defaultAnswer[0]);
            // console.count("load data");
        }
    }, [zwibbler]);

    return (
        <div className={`zwibbler-draw-container`}>
            {!isReadonly && (
                <div className="toolbar-wrapper">
                    <ZwibblerToolbars ctx={zwibbler} />
                </div>
            )}
            <div
                className="zwibbler-container"
                id="scrollable"
                // style={{ width: width || 400, height: height || 400, maxHeight: "60vh" }}
                style={{ width: width || 400, height: height || 400 }}
            >
                <div className="custom-zwibbler" style={{ height: height || 400 }}>
                    <div className="zwibbler-image-background">{src && <img src={src} />}</div>

                    <div ref={zwibblerEl} {...zwibblerConfig} id="zwibbler-canvas" className="zwibbler">
                        <div z-canvas="" className="stretch" confine="view" />
                    </div>
                </div>
            </div>
            {/* //layzyload module */}
            <ZwibblerModule></ZwibblerModule>
        </div>
    );
};
