import { WarningOutlined } from "@ant-design/icons";
import { Modal, Typography, notification } from "antd";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { countUserViolations } from "src/api/containers/record";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import configs from "src/configs";
import { useIsDevToolsOpen, useValues } from "src/hooks";
import { pages } from "src/routes/pages";
import { closeFullscreen } from "src/utils/AntiCheating";
import { useRouting } from "src/utils/router";

import "./DevtoolCheck.scss";

const DEVTOOL_CONFIG = configs.EXAM_PROCTORING.DEV_TOOL;
const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;

/**
 *
 * @param {string} type: warning || warning_reload
 * @returns
 */
const DevtoolCheck = ({ className, onVisibleChange, type = "warning", isTestLayout = false, recordId, ...rest }) => {
    const isDevtoolsOpen = useIsDevToolsOpen(undefined, DEVTOOL_CONFIG.IS_BLOCKED);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { generate } = useRouting();

    const [values, setValues] = useValues({
        isVisible: false,
        message: t("shared.warning"),
        description: "",
    });

    const handleVisibleChange = (visible) => {
        if (onVisibleChange instanceof Function) {
            onVisibleChange(visible);
        }
    };

    const handleOk = () => {
        setValues({ isVisible: false });
        handleVisibleChange(false);
    };

    useEffect(() => {
        if (isDevtoolsOpen) {
            setValues({ isVisible: true });

            if (isTestLayout && recordId && ANTI_CHEATING_KEYS.OPEN_DEVTOOL) {
                setTimeout(() => {
                    countUserViolations(recordId, ANTI_CHEATING_KEYS.OPEN_DEVTOOL).then(
                        ({ status, warning, is_load }) => {
                            if (is_load) {
                                setValues({ isVisible: false });
                                navigate(generate(pages.exam_asset.name));
                                notification.warning({ message: t("anti_cheating.violated_exam_contact_teacher") });
                                closeFullscreen();
                            }
                            // if (warning) {
                            //     notification.warning({ message: warning });
                            // }
                        }
                    );
                }, 2000);
            } else if (!(isTestLayout && ANTI_CHEATING_KEYS.OPEN_DEVTOOL)) {
                // window.location.reload();
                // navigate(generate(pages.exam_asset.name));
            }
        } else if (!isDevtoolsOpen && values.isVisible) {
            // notification.warning({ message: "close devtool" });
            setValues({ isVisible: false });
        }
    }, [isDevtoolsOpen, recordId]);

    useEffect(() => {
        if (isTestLayout) {
            setValues({ description: `${t("anti_cheating.violated_exam_by_opening_devtool")}` });
        } else {
            setValues({ description: `${t("opening_devtool_not_allowed")}. ${t("this_behavior_considered_fraud")}` });
        }
    }, [isTestLayout]);

    useEffect(() => {
        function preventF12(e) {
            // "cmd+Option" or "ctrl + shift"
            const hotkeys = (e.ctrlKey && e.shiftKey) || (e.metaKey && e.altKey);

            if (
                e.key === "F12" ||
                e.keyCode === 123 ||
                (hotkeys && e.keyCode === 73) || //Control+Shift+I || Command+Option+I
                (hotkeys && e.keyCode === 74) || //Control+Shift+J || Command+Option+J
                (hotkeys && e.keyCode === 67) //Control+Shift+J || Command+Option+C
            ) {
                e.preventDefault(); // Prevent the default behavior of the F12 key
                notification.warning({
                    message: `${t("opening_devtool_not_allowed")}. ${t("this_behavior_considered_fraud")}`,
                });
            }
        }
        if (window && DEVTOOL_CONFIG.IS_BLOCKED) {
            document.addEventListener("keydown", preventF12);
        }

        return () => document.removeEventListener("keydown", preventF12);
    }, []);

    return (
        <Modal
            centered
            destroyOnClose
            visible={values.isVisible}
            title={
                <div className="check-info-title">
                    <div className="title-icon">
                        <WarningOutlined />
                    </div>
                    <div className="title-content">
                        <div className="content-main">{values.message}</div>
                    </div>
                </div>
            }
            closeIcon={<SvgClose />}
            closable={false}
            footer={null}
            className={
                "app-modal flexible-height type-primary maxw-520 modal-screensizechk" +
                (className ? ` ${className}` : "")
            }
            wrapClassName="modal-screensizechk-wrap"
            onOk={handleOk}
            // onCancel={handleOk}
            {...rest}
        >
            <div className="modal-wraper">
                <Typography.Title level={4} className="text-center">
                    {values.description}
                    {/* <CountDown number={5} /> */}
                </Typography.Title>
            </div>
        </Modal>
    );
};

function CountDown({ number }) {
    const [count, setCount] = useState(number);
    const intervalId = useRef();

    useEffect(() => {
        intervalId.current = setInterval(() => {
            if (count > 0) {
                setCount((pre) => pre - 1);
            } else {
                clearInterval(intervalId.current);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, []);

    useEffect(() => {
        if (count <= 0) {
            clearInterval(intervalId.current);
            window.location.reload();
        }
    }, [count]);

    return (
        <div>
            <h1>Countdown: {count}</h1>
        </div>
    );
}
export default DevtoolCheck;
