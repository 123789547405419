import React from "react";
import { Link } from "react-router-dom";
import "./CourseAssignment.scss";

function CourseAssignment({ assignmentInfo = {}, actions = null, linkTo = "", onClick = () => {}, ...rest }) {
    return (
        <div className="course-assignment-info-panel" {...rest} onClick={onClick}>
            <div className="course-assignment-info">
                <div className="course-assignment-label-range">
                    <div>
                        {assignmentInfo?.order
                            ? assignmentInfo?.order < 10
                                ? `0${assignmentInfo.order}`
                                : assignmentInfo.order
                            : "-"}
                    </div>
                </div>
                <Link to={linkTo}>
                    <div className="assignment-quick-info">
                        {assignmentInfo.icon ? (
                            <span className="assignment-icon">
                                <span className="icon-wrapper">{assignmentInfo.icon || "_"}</span>
                            </span>
                        ) : null}
                        <span className="assignment-label app-txt-h2">
                            <span className="label-content">{assignmentInfo.name || "_"}</span>
                        </span>
                        <span className="assignment-title app-txt-h3">
                            <span className="title-content">{assignmentInfo.description || "_"}</span>
                        </span>
                    </div>
                </Link>
                <div className="assignment-actions">{actions}</div>
            </div>
        </div>
    );
}

export default CourseAssignment;
