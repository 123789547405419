import moment from "moment";

function formatTimeDuration(duration) {
    // const startDate = moment(time1);
    // const endDate = moment(time2);

    // Calculate the difference
    const _duration = moment.duration(duration);

    // Access individual components of the duration
    const hours = _duration.hours();
    const minutes = _duration.minutes();
    const seconds = _duration.seconds();

    return { hours, minutes, seconds };
}

function convertMillisecondsToTime(milliseconds) {
    const duration = moment.duration(milliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours) {
        return moment().hours(hours).minutes(minutes).seconds(seconds).format("H:mm:ss");
    }

    return moment().minutes(minutes).seconds(seconds).format("mm:ss");
}

export { formatTimeDuration, convertMillisecondsToTime };
