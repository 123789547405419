import moment from "moment";
import { t } from "i18next";

const caculateTime = (start, end) => {
    let a = new Date(start);
    let b = new Date(end);

    let difftime = (b - a) / 1000;

    let hour = Math.floor(difftime / 60 / 60);
    if (hour < 10) {
        hour = "0" + hour;
    }

    let minute = Math.floor((difftime / 60) % 60);
    if (minute < 10) {
        minute = "0" + minute;
    }

    let second = Math.floor(difftime % 60);
    if (second < 10) {
        second = "0" + second;
    }

    difftime = `${hour}:${minute}:${second}`;

    return difftime;
};

export const AssignmentsGradebookData = (apiData) => {
    // moment.locale("vi");
    const format = "DD/MM/YYYY HH:mm:ss";

    if (apiData?.length > 0) {
        let result = apiData.map((item) => {
            const { id, unit, record, level, course, order, count_record, exam, begin_date, end_date } = item;
            let difftime;
            if (record.start_time && record.end_time) {
                difftime = caculateTime(record.start_time, record.end_time);
            }
            let nameActivity = item?.name;
            if (item?.name && unit.name) {
                if (unit.title) {
                    nameActivity = `${unit.name}: ${unit.title} - ${item.name}`;
                } else {
                    nameActivity = `${unit.name}: ${t("gradebook.unknown")} - ${item.name}`;
                }
            }
            return {
                id: id,
                order: order,
                name: nameActivity || t("shared.unknown"),
                level: level?.name || t("shared.unknown"),
                level_id: level?.id || t("shared.unknown"),
                course: course?.name || t("shared.unknown"),
                course_id: course?.id || t("shared.unknown"),
                score:
                    (record?.score && record?.score_submited && record?.score_submited + " / " + record?.score) ||
                    t("shared.unknown"),
                score_submited: record?.score_submited || t("shared.unknown"),
                percent: record?.score_percent ? record?.score_percent + "%" : t("shared.unknown"),
                last_taken: record?.end_time ? moment(item.record.end_time).format(format) : t("shared.unknown"),
                attempts: record?.count_submit_exercise || t("shared.unknown"),
                time_spend: difftime || t("shared.unknown"),
                record_id: record?.id || t("shared.unknown"),
                status: record?.status || t("shared.unknown"),
                count_submit: count_record?.count_submit || t("shared.unknown"),
                count_marked: count_record?.count_marked || t("shared.unknown"),
                max_score: exam?.max_score || t("shared.unknown"),
                begin_date: begin_date ? moment(begin_date).format(format) : t("shared.unknown"),
                end_date: end_date ? moment(end_date).format(format) : t("shared.unknown"),
            };
        });
        return result;
    } else {
        return [];
    }
};
