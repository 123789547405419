import { notification, Radio } from "antd";
import InputEditor from "src/modules/components/InputEditor";
import { BinIcon } from "src/utils/drawer";
import { t } from "i18next";
import "./MultipleChoiceTable.scss";

const MultipleChoiceTable = (props) => {
    const { correctAnswer1, correctAnswer2, onChangeAnswer1, onChangeAnswer2, value: correctMatch, onChange } = props;
    const handleChangeAnswer1 = (i, data) => {
        const newCorrectAnswer1 = [...correctAnswer1];
        newCorrectAnswer1[i] = data;
        onChangeAnswer1(newCorrectAnswer1);
    };

    const handleChangeAnswer2 = (i, data) => {
        const newCorrectAnswer2 = [...correctAnswer2];
        newCorrectAnswer2[i] = data;
        onChangeAnswer2(newCorrectAnswer2);
    };

    const handleAddAnswer1 = () => {
        const newCorrectAnswer1 = [...correctAnswer1];

        newCorrectAnswer1.push("");

        onChangeAnswer1(newCorrectAnswer1);
    };

    const handleAddAnswer2 = () => {
        const newCorrectAnswer2 = [...correctAnswer2];

        newCorrectAnswer2.push("");

        onChangeAnswer2(newCorrectAnswer2);
    };

    const handleChangeMatch = (e, iRow, iCol) => {
        const isChecked = e.target.checked;

        const newCorrectMatch = [...correctMatch];

        if (newCorrectMatch[iRow]) newCorrectMatch[iRow] = "";

        if (isChecked) {
            newCorrectMatch[iRow] = iCol;

            if (onChange && onChange instanceof Function) onChange(newCorrectMatch);
        }
    };

    const handleDeleteCorrectAnswer1 = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        // Validate correctAnswer can't be less than 2
        if (correctAnswer1.length <= 2) {
            notification.error({
                message: t("q.q_multi_table.require_questions"),
            });
            return;
        }

        const newCorrectAnswer1 = [...correctAnswer1];
        const newCorrectMatch = [...correctMatch];

        newCorrectMatch.splice(index, 1);
        newCorrectAnswer1.splice(index, 1);

        onChange(newCorrectMatch);
        onChangeAnswer1(newCorrectAnswer1);
    };

    const handleDeleteCorrectAnswer2 = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        // Validate correctAnswer2 can't be less than 2
        if (correctAnswer1.length <= 2) {
            notification.error({
                message: t("q.q_multi_table.require_answers"),
            });
            return;
        }

        const newCorrectAnswer2 = [...correctAnswer2];
        const newCorrectMatch = [...correctMatch];

        newCorrectAnswer2.splice(index, 1);

        onChange(newCorrectMatch);
        onChangeAnswer2(newCorrectAnswer2);
    };

    return (
        <div className="detail_multiplechoicetable__table">
            <div className="multiplechoicetable__table-skin">
                <table className="multiplechoicetable__table__skeleton">
                    <tbody>
                        <tr className="sticky-row">
                            <td className="sticky-col first-col">
                                <div></div>
                            </td>
                            {correctAnswer2.map((casr2, i) => (
                                <td key={i}>
                                    <InputEditor
                                        className="multiplechoicetable__correct_answer__input"
                                        value={casr2}
                                        onChange={(e) => handleChangeAnswer2(i, e)}
                                        isSuffix
                                        suffix={
                                            <BinIcon
                                                className="multiplechoicetable__correct_answer__delete"
                                                onClick={(e) => handleDeleteCorrectAnswer2(e, i)}
                                            />
                                        }
                                    />
                                </td>
                            ))}
                            <td>
                                <button className="add_button" type="button" onClick={handleAddAnswer2}>
                                    +
                                </button>
                            </td>
                        </tr>
                        {correctAnswer1.map((casr1, i) => (
                            <tr key={i}>
                                <td className="sticky-col first-col">
                                    <div>
                                        <InputEditor
                                            className="multiplechoicetable__correct_answer__input"
                                            value={casr1}
                                            onChange={(data) => handleChangeAnswer1(i, data)}
                                            isSuffix
                                            suffix={
                                                <BinIcon
                                                    className="multiplechoicetable__correct_answer__delete"
                                                    onClick={(e) => handleDeleteCorrectAnswer1(e, i)}
                                                />
                                            }
                                        />
                                    </div>
                                </td>

                                {/* Td value */}
                                {correctAnswer2.map((casr2, iCol) => {
                                    return (
                                        <td key={iCol} className="text-center">
                                            <Radio
                                                onChange={(e) => handleChangeMatch(e, i, iCol)}
                                                checked={correctMatch?.[i] === iCol}
                                            ></Radio>
                                        </td>
                                    );
                                })}

                                {/* Td của column button */}
                                <td></td>
                            </tr>
                        ))}

                        <tr>
                            <td className="sticky-col first-col">
                                <div className="text-center">
                                    <button className="add_button" type="button" onClick={handleAddAnswer1}>
                                        +
                                    </button>
                                </div>
                            </td>
                            <td colSpan="100%"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MultipleChoiceTable;
