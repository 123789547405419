import { CaretUpOutlined, FileImageOutlined } from "@ant-design/icons";
import { Button, Collapse, Image, List, Modal, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useValues } from "src/hooks";
import { convertMillisecondsToTime } from "src/utils/helpers/timer";

import Empty from "../Empty";
import "./ListViolation.scss";

function secondsToMinutes(seconds) {
    var minutes = Math.floor(seconds / 60); // Get the whole number of minutes
    var remainingSeconds = seconds % 60; // Get the remaining seconds

    // Formatting for display, adding leading zeros if necessary
    var formattedMinutes = String(minutes).padStart(2, "0");
    var formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return formattedMinutes + ":" + formattedSeconds;
}

function ListViolation({ violationData = [], seekTo, onSelectItem, active_key, hasVideo }) {
    const { t } = useTranslation();

    const [values, setValues] = useValues({
        visible: false,
        src: "",
    });

    console.log({ violationData });

    const _violationData = useMemo(() => {
        return violationData.map((item, idx) => {
            const newItem = { ...item };

            newItem.key = idx;

            newItem.errors = item.errors?.map((err) => {
                let timeInMinutes = err.time;
                if (hasVideo) {
                    timeInMinutes = convertMillisecondsToTime(err.time);
                } else {
                    timeInMinutes = moment(timeInMinutes).format("HH:mm:ss DD/MM/YYYY");
                }
                return {
                    time_minute: timeInMinutes,
                    ...err,
                };
            });

            return newItem;
        }, {});
    }, [violationData]);

    const renderListViolation = (violations) => {
        return (
            <div>
                {violations.map((item) => {
                    return (
                        <div key={item.time} style={{ color: active_key == item.time ? "#07f" : "inherit" }}>
                            <div className="violation-item">
                                <div
                                    onClick={() => {
                                        seekTo.current?.(item.time / 1000);
                                        onSelectItem(item.time);
                                    }}
                                >
                                    <CaretUpOutlined style={{ color: "red" }} />{" "}
                                    <strong className="time">[{item.time_minute}]</strong> : <span>{item.action}</span>
                                </div>
                                {item.src && (
                                    <div>
                                        <Tooltip title={t("anti_cheating.violating_images")}>
                                            <Button
                                                shape="circle"
                                                icon={<FileImageOutlined />}
                                                onClick={() => setValues({ visible: true, src: item.src })}
                                            ></Button>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="list-violation">
            <Typography.Title level={4}>{t("anti_cheating.list_of_violations")}</Typography.Title>

            <Collapse
                bordered
                defaultActiveKey={[0]}
                expandIconPosition="right"
                style={{ height: "calc(100% - 38px)", overflow: "auto" }}
            >
                {_violationData.map((item) => {
                    if (!item.errors.length) {
                        return <Empty key={item.key} description={t("anti_cheating.no_student_violation_data")} />;
                    }
                    return (
                        <Collapse.Panel
                            header={
                                <div style={{ fontSize: 15 }}>
                                    {item.name}{" "}
                                    <strong>
                                        ({item.count} {t("times").toLowerCase()})
                                    </strong>
                                </div>
                            }
                            key={item.key}
                        >
                            {renderListViolation(item.errors)}
                        </Collapse.Panel>
                    );
                })}
            </Collapse>

            <Modal
                visible={values.visible}
                onCancel={() => setValues({ visible: false, src: "" })}
                footer={null}
                className="add-exam-question-modal modal-mark-assignment"
                width={"60%"}
                destroyOnClose
                closable
                centered
                title={t("anti_cheating.violating_images")}
            >
                <div className="flex flex-center" style={{ height: "100%" }}>
                    <Image width={"70%"} src={values.src} style={{ borderRadius: 4 }} />
                </div>
            </Modal>
        </div>
    );
}

export default ListViolation;
