import React from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import "./Button.scss";

const Button = ({
    className = "",
    type = "",
    htmlType = "button",
    isDisabled = false,
    isLoading = false,
    icon = null,
    title = "",
    linkTo = "",
    linkToIsLocal = true,
    hasResponsiveDesign = true,
    onClick = () => {},
    ...rest
}) => {
    const generateClassName = () => {
        let _classNames = "";
        switch (type) {
            case "simple":
                _classNames += "btn btn-simple";
                break;
            case "primary":
                _classNames += "btn btn-primary";
                break;
            case "second":
                _classNames += "btn btn-second";
                break;
            case "ghost":
                _classNames += "btn btn-ghost";
                break;
            case "grey":
                _classNames += "btn btn-grey";
                break;
            case "normal":
            default:
                _classNames += "btn btn-normal";
                break;
        }
        if (icon && !title) {
            _classNames += " btn-icon-only";
        }
        if (hasResponsiveDesign === true) {
            _classNames += " has-rd";
        }
        return _classNames;
    };

    const renderButtonContent = () => {
        return (
            <div className="btn__content">
                {icon && (
                    <span className="btn__icon-wrapper">
                        {isLoading ? (
                            <LoadingOutlined style={{ fontSize: 18 }} spin />
                        ) : (
                            <>
                                {icon && typeof icon === "string" && <img src={icon} className="btn__content-image" />}
                                {icon && React.isValidElement(icon) && <>{icon}</>}
                            </>
                        )}
                    </span>
                )}
                <div>{title}</div>
            </div>
        );
    };

    if (linkTo) {
        if (linkToIsLocal) {
            return (
                <Link
                    to={linkTo}
                    className={[generateClassName(), className].join(" ")}
                    onClick={onClick}
                    type={htmlType || "button"}
                    {...rest}
                >
                    {renderButtonContent()}
                </Link>
            );
        } else {
            return (
                <a
                    href={linkTo}
                    className={[generateClassName(), className].join(" ")}
                    onClick={onClick}
                    type={htmlType || "button"}
                    {...rest}
                >
                    {renderButtonContent()}
                </a>
            );
        }
    }

    return (
        <button
            className={[generateClassName(), className].join(" ")}
            onClick={onClick}
            type={htmlType || "button"}
            disabled={isDisabled || isLoading}
            {...rest}
        >
            {renderButtonContent()}
        </button>
    );
};

export default Button;
