import React, { useMemo, useState } from "react";
import "./BreadcumbStyles.scss";
import { ReactComponent as HomeIcon } from "src/assets/images/home-icon.svg";
import { ReactComponent as BackIcon } from "src/assets/images/icon-chevron-left.svg";
import ActionButton from "./components/ActionButton";
import { t } from "i18next";
import { useRouting } from "src/utils/router";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BackIconMobile } from "src/assets/images/ArrowCircleLeft.svg";

/* Route interface */

/** 
    @param {string} path | default: current page | description: path to be redirected
    @param {string} name | default: undefined    | description: title of once item in breadcumb
*/

const Breadcrumb = (props) => {
    const { isBack, routes = [], actions = [], handleBack = undefined, isUnlinkLastItem = true, className } = props;
    const { generate } = useRouting();
    const navigate = useNavigate();

    // If have path is "home" will render home icon in first breadcumb
    const isHaveHome = routes[0]?.path === "home";

    const newRoutes = useMemo(() => {
        if (isHaveHome) {
            const cloneRoutes = [...routes];
            cloneRoutes.splice(0, 1);
            return cloneRoutes;
        }
        return routes;
    }, [routes]);

    const handleClickBack = (e) => {
        if (handleBack) {
            handleBack(e);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className={`breadcumb app-txt-h3 ${className}`}>
            <div className="breadcumb__container">
                <div className="breadcumb__content">
                    {isBack && (
                        <div className="breadcumb__back">
                            <BackIcon onClick={handleClickBack} />
                        </div>
                    )}
                    <div className="breadcumb-mobile__back" onClick={handleClickBack}>
                        <BackIconMobile />
                        <span>{t("breadcumb.goback")}</span>
                    </div>

                    {isHaveHome && (
                        <Link to={generate("courses")} className="breadcumb__home-icon">
                            <HomeIcon />
                        </Link>
                    )}

                    <div className="breadcumb__title">
                        {newRoutes.map((route, index) => (
                            <span key={index}>
                                {isUnlinkLastItem && index === newRoutes.length - 1 ? (
                                    <span className="breadcrumb-item unlink">{route.name}</span>
                                ) : (
                                    <Link to={route?.path || "#"} className="breadcrumb-item">
                                        {route.name}
                                    </Link>
                                )}
                                {/* Handle render "/" beetween 2 Link */}
                                <span className="breadcumb__title-slash">
                                    {newRoutes.length !== index + 1 ? " / " : ""}
                                </span>
                            </span>
                        ))}
                    </div>
                </div>
                {Boolean(actions.length) && (
                    <div className="breadcumb__control">
                        {actions.map((action, index) => (
                            <ActionButton key={index} {...action}>
                                {action.icon}
                                <div>{action.title}</div>
                            </ActionButton>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(Breadcrumb);
