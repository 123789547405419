import { Col, Row, notification } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateRole } from "src/api/containers/auth";
import { default as CustomButton } from "src/modules/components/Button";
import { login } from "src/reducers/auth";

import "./AuthedRolePicker.scss";

const AuthedRolePicker = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();
    const handleChooseRole = (isTeacher = false) => {
        const formData = {};
        if (isTeacher) {
            formData.role = "teacher";
        } else {
            formData.role = "student";
        }
        updateRole(user?.id, formData).then((res) => {
            if (res?.status) {
                notification.success({
                    message: t("message.login_success"),
                });
                dispatch(login(res.data));
            } else {
                notification.error({
                    message: res?.message || t("message.third_party_login_error"),
                });
            }
        });
    };

    useEffect(() => {
        if (user?.role !== "guest") {
            navigate("/");
        }
    }, [user]);

    return (
        <div className="authed-role-picker">
            <div className="role-wrapper">
                <h1>{t("login.who_are_you")}</h1>
                <p>{t("login.intro")}</p>
                <Row gutter={[16, 16]} className="role-items">
                    <Col>
                        <CustomButton
                            type="ghost"
                            title={t("login.teacher")}
                            onClick={() => handleChooseRole(true)}
                        ></CustomButton>
                    </Col>
                    <Col>
                        <CustomButton
                            type="ghost"
                            title={t("login.student")}
                            onClick={() => handleChooseRole()}
                        ></CustomButton>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AuthedRolePicker;
