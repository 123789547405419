import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import QuestionContentDetail from "../ContentDetail";
import Answer from "./components/Answer";
import "./Sort.scss";

function Sort(props) {
    const {
        question /** @param {String} question?: question of the sentence */,
        correct_answer /** @param {String[]} correct_answer: Correct answer of the sentence */,
        answered /** @param {String[]} answered?: User's answer */,
        is_history,
    } = props;

    const inputAnswers = useMemo(() => {
        return correct_answer?.map((answer, index) => {
            return {
                text: answer,
                index,
                isCorrect: true,
            };
        });
    }, [correct_answer]);

    const inputAnswered = useMemo(
        () =>
            answered?.map((answer, index) => {
                const indexInAnswer = correct_answer?.findIndex((item) => item === answer);
                return {
                    text: answer,
                    index: indexInAnswer,
                    isCorrect: correct_answer?.[index] === answer,
                };
            }),
        [correct_answer]
    );

    return !is_history ? (
        <div className="q-result q-sort">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                    }}
                />
            </div>

            <div className="q-content-section">
                <h3>{t("score.your_answers")}</h3>
                <div className="qc_selected-answers">
                    {inputAnswered?.map((item, index) => (
                        <Answer key={index} answer={item} isShowIcon />
                    ))}
                </div>
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="qc_correct-answers">
                    {inputAnswers?.map((item, index) => (
                        <Answer key={index} answer={item} />
                    ))}
                </div>
            </div>
        </div>
    ) : (
        <div className="q-result q-sort">
            <div className="q-content-section">
                <div className="qc_correct-answers">
                    {inputAnswers?.map((item, index) => (
                        <Answer key={index} answer={item} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Sort;
