import React from "react";

function IconRocket(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M8.01107 11.7289C8.19225 14.8669 9.56195 18.2658 9.6924 18.5846L5.94564 20.0848L5.94564 14.2146C5.94564 13.5334 6.28626 12.8884 6.85878 12.5043L8.01107 11.7289ZM17.1642 18.0121C17.1642 18.0121 18.7513 14.1784 18.7513 11.0621C18.7513 3.23525 15.0408 0.829204 14.1421 0.350894C13.2362 0.821957 9.53297 3.23525 9.53297 11.0621C9.53297 14.1784 11.1201 18.0121 11.1201 18.0121H17.1642ZM20.2732 11.7289C20.092 14.8669 18.7223 18.2658 18.5919 18.5846L22.3386 20.0848L22.3386 14.2146C22.3386 13.5334 21.998 12.8884 21.4255 12.5043L20.2732 11.7289ZM16.3163 20.3095C16.9178 20.911 17.2149 21.7009 17.2149 22.4836C17.2149 24.1939 14.1421 28.2813 14.1421 28.2813C14.1421 28.2813 11.0694 24.1939 11.0694 22.4836C11.0694 21.7009 11.3665 20.911 11.968 20.3095C13.171 19.1064 15.1132 19.1064 16.3163 20.3095ZM12.6927 10.8882C11.8955 10.091 11.8955 8.78654 12.6927 7.98936C13.4899 7.19218 14.7944 7.19218 15.5916 7.98936C16.3887 8.78654 16.3887 10.091 15.5916 10.8882C14.7944 11.6854 13.4899 11.6854 12.6927 10.8882Z" />
        </svg>
    );
}

export default IconRocket;
