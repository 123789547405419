import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Spin, Tooltip, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { useValues } from "src/hooks";
import QrCode from "src/modules/components/QrCode";

import "./ModalInviteLink.scss";

function ModalInviteLink(props) {
    const {
        code,
        inviteLink = "",
        loading = false,
        visible = false,
        onOk = () => {},
        onCancel = () => {},
        ...rest
    } = props;

    const { t } = useTranslation();

    const [form] = useForm();
    const [values, setValues] = useValues({
        isLoading: false,
        isVisible: false,
    });

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            setValues({
                ...values,
                isVisible: false,
            });
        }
    };

    const handleOk = () => {
        if (onOk) {
            // const currLink = form.getFieldsValue().invite_link;
            if (inviteLink) {
                navigator.clipboard.writeText(inviteLink);
                notification.success({
                    message: t("shared.copy_invite_link_success"),
                });
            }
            onOk();
        } else {
            setValues({
                ...values,
                isVisible: false,
            });
        }
    };

    useEffect(() => {
        setValues({
            ...values,
            isLoading: loading,
            isVisible: visible,
        });
        if (visible === true) {
            // form.setFieldsValue({
            //     invite_link: inviteLink,
            // });
        }
    }, [props]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(inviteLink);
        notification.success({ message: t("shared.copy_invite_link_success") });
    };
    const handleCopyCode = () => {
        navigator.clipboard.writeText(code);
        notification.success({ message: t("assignment.copied_code") });
    };

    return (
        <Modal
            visible={values.isVisible}
            centered
            footer={null}
            title={t("assignment.invite_link")}
            closeIcon={<SvgCross />}
            onOk={handleOk}
            onCancel={handleCancel}
            {...rest}
            className={`app-modal type-basic flexible-height maxw-360 modal-invlink-asgmt${
                props.className ? " " + props.className : ""
            }`}
        >
            <Spin spinning={values.isLoading}>
                {props.children ? (
                    props.children
                ) : (
                    <Form form={form}>
                        <QrCode value={inviteLink} />

                        <div className="invlink-title-wrapper">
                            <span>{t("shared.or")}</span>
                            <span>{t("shared.copy_the_link_below")}</span>
                        </div>
                        {/* 
                        <Form.Item name="invite_link">
                            <Input defaultValue="Copy Link mời" suffix={<Button icon={<CopyOutlined />} />} />
                            <Space direction="horizontal" style={{ width: "100%" }}>
                                <Input
                                    readOnly
                                    className="app-input has-rd"
                                    placeholder={t("assignment.invite_link")}
                                    value={inviteLink}
                                ></Input>
                                <Tooltip title={t("shared.copy_invite_link")}>
                                    <CustomButton
                                        htmlType="submit"
                                        type="primary"
                                        icon={<CopyOutlined />}
                                        title={t("shared.copy")}
                                        className="invlink-btn"
                                        onClick={handleOk}
                                    ></CustomButton>
                                </Tooltip>
                            </Space>
                        </Form.Item> */}

                        <Space>
                            <Button onClick={handleCopyLink} icon={<LinkOutlined />} style={{ borderRadius: 5 }}>
                                {t("shared.copy_invite_link")}
                            </Button>
                            <Button onClick={handleCopyCode} icon={<CopyOutlined />} style={{ borderRadius: 5 }}>
                                {code}
                            </Button>
                        </Space>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
}

export default ModalInviteLink;
