import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Layout } from "antd";
import BasicHeader from "../components/BasicHeader";
import "./BasicLayout.scss";
import AlertOnTop from "../components/AlertOnTop";

const BasicLayout = (props) => {
    const { children } = props;

    return (
        <div className="app-basic-layout">
            <AlertOnTop />
            <div className="layout-header">
                <BasicHeader />
            </div>
            <Layout className="layout-content">
                <Layout.Content>{children}</Layout.Content>
            </Layout>
        </div>
    );
};

BasicLayout.layout = "BasicLayout";

export default BasicLayout;
