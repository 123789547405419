import api from "..";

export const assignmentTypes = [
    { name: "listening", value: "listening" },
    { name: "speaking", value: "speaking" },
    { name: "reading", value: "reading" },
    { name: "writing", value: "writing" },
    { name: "vocabulary", value: "vocabulary" },
    { name: "grammar", value: "grammar" },
    { name: "review", value: "review" }, // Revise (Ôn tập).
];
export const assignmentGradebookTypes = [
    { name: "middle", value: "middle" },
    { name: "final", value: "final" },
];
const assignment_fields_full = [
    "name",
    "level_id",
    "unit_id",
    "course_id",
    "exam_question_id",
    "level",
    "unit",
    "course",
    "exam",
    "begin_date",
    "end_date",
    "record",
    "count_record",
    "homework_time_minute",
    "icon",
    "title",
    "description",
    "type",
    "order",
    "gradebook",
    "code",
    "limit_submission",
    "is_public",
    "options",
    "is_has_student",
    "late_start_minutes",
    "user_id",
];

const assignment_fields_reduced = [
    "name",
    "unit_id",
    "course",
    "exam",
    "begin_date",
    "end_date",
    "record",
    "count_record",
    "description",
    "type",
    "order",
    "gradebook",
];

const assignment_relations = ["course", "level", "records", "unit", "histories", "teachers"];

export const fetchAssignmentsGradebook = (fetchParams) => {
    const filters = [];
    fetchParams.language && filters.push(`language_id:${fetchParams.language}`);
    fetchParams.level && filters.push(`level_id:${fetchParams.level}`);
    fetchParams.course && filters.push(`course_id:${fetchParams.course}`);
    fetchParams.unit && filters.push(`unit_id:${fetchParams.unit}`);
    fetchParams.status && filters.push(`status:${fetchParams.status}`);
    filters.push("is_unit:true");
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _assignment_fields: `${[
            "name",
            "begin_date",
            "end_date",
            "exam",
            "level",
            "course",
            "unit",
            "record",
            "order",
            "count_record",
        ]}`,
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
        _orderBy: fetchParams.order ? `order:${fetchParams.order}` : "order:asc",
    };
    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchAssignments = (fetchParams, fields, isRelationShip = true) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams.unit_id && filters.push(`unit_id:${fetchParams.unit_id}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    fetchParams.is_unit !== undefined && filters.push(`is_unit:${fetchParams.is_unit}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _assignment_fields: fields || `${assignment_fields_reduced}`,
        ...(isRelationShip && { _relations: `${["level"]}` }),
        ...(isRelationShip && { _level_fields: `${["name"]}` }),
        _orderBy: fetchParams._orderBy || "created_at:asc",
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchAssignmentsBank = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.is_unit !== undefined && filters.push(`is_unit:${fetchParams.is_unit}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _assignment_fields: `${assignment_fields_full}`,
        _relations: `${["teachers"]}`,
        _user_fields: `${["name", "phone", "username", "gender", "active", "role", "personal_number"]}`,
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchStudentAssignments = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    fetchParams.status && filters.push(`status:${fetchParams.status}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _assignment_fields: `${["name", "begin_date", "end_date", "exam", "course", "record"]}`,
        _relations: `${["level", "course", "unit"]}`,
        _level_fields: `${["name"]}`,
        _course_fields: `${["name", "is_class"]}`,
        _unit_fields: `${["name"]}`,
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    return api
        .get("/assignment", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findAssignmentIsUnit = (fetchParams = {}) => {
    const filters = ["is_unit:true"];
    fetchParams.unit_id && filters.push(`unit_id:${fetchParams.unit_id}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _assignment_fields: `${["title", "name", "course", "icon", "unit", "description", "order"]}`,
        _orderBy: "order:asc",
        _relations: "records",
        _record_fields: `${["is_student_submit", "is_teacher_submit"]}`,
    };

    return api
        .get(`/assignment`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const findAssignment = (id, fetchParams = {}) => {
    const _assignment_fields = [...assignment_fields_full];
    const hasLevelRelation = fetchParams?.relations?.includes("level") > 0;
    const hasCourseRelation = fetchParams?.relations?.includes("course") > 0;
    if (hasLevelRelation) {
        const i = _assignment_fields.indexOf("level");
        _assignment_fields.splice(i, 1);
    }
    if (hasCourseRelation) {
        const i = _assignment_fields.indexOf("course");
        _assignment_fields.splice(i, 1);
    }
    // Fetch params:
    const params = {
        _assignment_fields: `${_assignment_fields}`,
        _relations: `${[fetchParams?.relations?.length > 0 ? fetchParams.relations.map((item) => item) : undefined]}`,
        ...(hasLevelRelation ? { _level_fields: `${["name"]}` } : {}),
        ...(hasCourseRelation ? { _course_fields: `${["level_id", "language_id", "name"]}` } : {}),
    };
    // Fetch:
    return api
        .get(`/assignment/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const createAssignment = (data) => {
    return api
        .post("/assignment", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const updateAssignment = (id, data) => {
    return api
        .put(`/assignment/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const deleteAssignment = (id) => {
    return api
        .delete(`/assignment/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const addParticipantToAssignment = (assignmentId) => {
    return api
        .put(`/assignment/${assignmentId}/add_course_user`)
        .then(({ status, success }) => ({ status, message: success }))
        .catch(() => ({ status: false }));
};

export const getRecommendedUnitAssignmentOrder = (unitId) => {
    const params = {
        unit_id: unitId,
    };
    return api
        .get(`/assignment/recommend`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

/**
 * Add users to a public assignment.
 *
 * Thêm người dùng vào bài kiểm tra công khai.
 * - Mời giáo viên chấm chung.
 * @param {string} userRole Role.
 * @param {string} assignmentId Id.
 * @param {object} data Data.
 * @returns Object.
 */
export const addUsersToAssignment = (userRole, assignmentId, data) => {
    const _route = {
        teacher: "add_teachers",
    };
    if (!["teacher"].includes(userRole)) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: userRole!" });
        });
    }
    if (!assignmentId) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: assignmentId!" });
        });
    }
    // Teacher's data: {users: [{username, name}]}.
    return api
        .post(`/assignment/${assignmentId}/${_route[userRole]}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

/**
 * Add users to a public assignment using external Excel file.
 *
 * Thêm người dùng vào bài kiểm tra công khai từ file Excel.
 * - Mời giáo viên chấm chung.
 * @param {string} userRole Role.
 * @param {string} assignmentId Id.
 * @param {object} data Data.
 * @returns Object.
 */
export const addUsersToAssignmentFromFile = (userRole, assignmentId, data) => {
    const _route = {
        teacher: "import_teachers",
    };
    if (!["teacher"].includes(userRole)) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: userRole!" });
        });
    }
    if (!assignmentId) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: assignmentId!" });
        });
    }
    // Teacher's data: {files}.
    return api
        .post(`/assignment/${assignmentId}/${_route[userRole]}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

/**
 * Remove users from assignment.
 * @param {string} userRole Role.
 * @param {string} assignmentId Id.
 * @param {Array} userIds Id list.
 * @returns Object.
 */
export const removeUsersFromAssignment = (userRole, assignmentId, userIds) => {
    const _data = {
        teacher: "teacher_ids",
    };
    const _route = {
        teacher: "remove_teachers",
    };
    if (!["teacher"].includes(userRole)) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: userRole!" });
        });
    }
    if (!assignmentId) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: assignmentId!" });
        });
    }
    if (!userIds?.length) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing param: userIds!" });
        });
    }
    const data = {
        [_data[userRole]]: userIds,
    };
    return api
        .deleteWithConditions(`/assignment/${assignmentId}/${_route[userRole]}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
