import LatexDangerously from "src/modules/components/LatexDangerously";
import Answer from "../Answer";
import "./Group.scss";

const Group = (props) => {
    const { answers = [], title = "", isShowIcon } = props;

    return (
        <div className="group_of_drag_drop">
            <LatexDangerously className="group" innerHTML={title} />

            {answers.map((answer, index) => {
                return <Answer key={index} answer={answer} isShowIcon={isShowIcon} />;
            })}
        </div>
    );
};

export default Group;
