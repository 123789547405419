import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Modal, Spin, Table, Tooltip, notification } from "antd";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { removeUsersFromAssignment } from "src/api/containers/assignment";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import ROLES from "src/utils/roles";

import AssignmentUsers from "./AssignmentUsers";
import AssignmentUsersUpload from "./AssignmentUsersUpload";
import "./PublicAssignmentUserModal.scss";

const PublicAssignmentUserModal = ({
    isLoading,
    assignmentInfo,
    visible,
    className,
    onAddUsersManuallySuccess,
    onDeleteUsersManuallySuccess,
    onOk,
    onCancel,
    ...rest
}) => {
    const [values, setValues] = useValues({
        loading: false,

        isConfirmModalVisible: false,
        selectedUser: {},
    });

    const handleShowConfirmModal = (item) => {
        setValues({ isConfirmModalVisible: true, selectedUser: item });
    };

    const user = useSelector((state) => state.auth.user);

    const [isAddUserModal, setIsAddUserModal] = useState(false); // Values: "teacher", false.

    const userColumns = [
        {
            title: t("profile.full_name"),
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: t("role"),
            dataIndex: "role",
            key: "role",
            align: "center",
            render: (text, record) => {
                return t(`role_option.${text}`);
            },
        },
        {
            title: t("profile.email"),
            dataIndex: "username",
            key: "username",
            align: "center",
        },
        {
            title: t("personal_number"),
            dataIndex: "personal_number",
            key: "personal_number",
            align: "center",
            render: (text, record) => {
                if (record?.role === "student") {
                    return record?.personal_number || t("shared.unknown");
                }
                return "";
            },
        },
        {
            title: t("profile.gender"),
            dataIndex: "gender",
            key: "gender",
            align: "center",
            render: (text, record) =>
                record.gender === 0
                    ? t("shared.female")
                    : record.gender === 1
                    ? t("shared.male")
                    : record.gender === null
                    ? t("shared.unknown")
                    : "",
        },
        {
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => {
                if (user.id === assignmentInfo?.user_id) {
                    return (
                        <div className="list-actions">
                            <Tooltip placement="top" title={t("shared.delete")}>
                                <CustomButton
                                    className="list-btn btn-danger"
                                    type="simple"
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleShowConfirmModal(record)}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
        },
    ];

    const userDataSource = useMemo(() => {
        const _teachers = Array.isArray(assignmentInfo?.teachers) ? assignmentInfo.teachers : [];
        return _teachers;
    }, [assignmentInfo]);

    const handleDeleteUser = () => {
        const item = values.selectedUser;
        removeUsersFromAssignment("teacher", assignmentInfo?.id, [item.id]).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ selectedUser: null, isConfirmModalVisible: false });
                onDeleteUsersManuallySuccess();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleOk = () => {
        if (onOk instanceof Function) {
            onOk();
        }
    };

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    return (
        <Modal
            centered
            visible={visible}
            title={assignmentInfo?.name || "_"}
            closeIcon={<SvgClose />}
            footer={null}
            className={"app-modal type-primary modal-publicasgmtusers" + (className ? ` ${className}` : "")}
            wrapClassName="modal-publicasgmtusers-wrap"
            // maskStyle={{ zIndex: "1010" }}
            onOk={handleOk}
            onCancel={handleCancel}
            {...rest}
        >
            <div className="asgmt-user-list">
                <Spin spinning={isLoading || values.loading}>
                    <div className="list-top">
                        <div className="list-title">{t("assignment.teachers")}</div>
                        <div className="list-actions">
                            {ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) ? (
                                <>
                                    <span className="adduser-manual-sec">
                                        <span>
                                            <CustomButton
                                                type="primary"
                                                title={t("assignment.add_teachers")}
                                                icon={<PlusCircleOutlined />}
                                                onClick={() => setIsAddUserModal("teacher")}
                                            />
                                        </span>
                                    </span>
                                    <span className="adduser-auto-sec">
                                        <AssignmentUsersUpload userRole="teacher" assignmentId={assignmentInfo?.id} />
                                    </span>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="list-management">
                        <div className="user-table">
                            <Table
                                className="app-table"
                                columns={userColumns}
                                dataSource={userDataSource}
                                nodata_title={t("shared.no_data")}
                                // loading={userLoading}
                                // pagination={{
                                //     showSizeChanger: false,
                                //     ...userPagination,
                                //     position: ["bottomCenter"],
                                //     onChange: handlePagination,
                                // }}
                                pagination={false}
                                scroll={{ x: "auto" }}
                                rowKey="id"
                            />
                        </div>
                    </div>
                </Spin>

                <AssignmentUsers
                    userRole={isAddUserModal}
                    assignmentId={assignmentInfo?.id}
                    visible={!!isAddUserModal}
                    onOk={() => {
                        setIsAddUserModal(false);
                        onAddUsersManuallySuccess();
                    }}
                    onCancel={() => setIsAddUserModal(false)}
                />

                <PopConfirm
                    type="delete"
                    visible={values.isConfirmModalVisible}
                    onOk={handleDeleteUser}
                    onCancel={() => setValues({ isConfirmModalVisible: false })}
                />
            </div>
        </Modal>
    );
};

export default PublicAssignmentUserModal;
