import { notification } from "antd";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import api from "src/api";
import { accessRoles, loginWithFacebook, loginWithGoogle } from "src/api/containers/auth";
import { login, logout, pickRoleByGuest } from "src/reducers/auth";
import { deleteCookie, getAllSearchParams, getCookie, parse_jwt, setCookie } from "src/utils/helpers";

import LoadingScreen from "../components/LoadingScreen";
import AuthContext from "../contexts/AuthContext";
import { checkIfCurrentTargetIsValidFor, checkTargetByURLPathname, getTargetURLQueryString } from "./helpers";

export const AuthProvider = ({ children }) => {
    const { role_picked_by_guest, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.general.lang);
    const location = window.location;

    const roleCheck = localStorage.getItem("role");
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes("/callback/google")) {
            loginWithGoogle(roleCheck, location.search).then((res) => {
                if (res) {
                    const { token, role } = res;
                    if (token) {
                        const user = parse_jwt(`${token}`);

                        dispatch(login({ ...user, ...res }));
                        setCookie("token", token);
                        if (role === "guest") {
                            navigate(`/${lang}/choose-role`);
                        } else {
                            notification.success({
                                message: t("message.login_success"),
                            });
                            navigate("/");
                        }
                    } else {
                        notification.error({
                            message: res.message || t("message.third_party_login_error"),
                        });
                        navigate("/");
                    }
                    localStorage.removeItem("role");
                }
            });
        } else if (location.pathname.includes("/callback/facebook")) {
            loginWithFacebook(roleCheck, location.search).then((res) => {
                if (res) {
                    const { token, role, avatar, id, name, user_name, login_with_social, is_new_user } = res;
                    if (token) {
                        // const user = parse_jwt(`${token}`);
                        // user.role = role;
                        const user = { role, avatar, id, name, user_name, login_with_social, is_new_user };
                        dispatch(login(user));
                        setCookie("token", token);
                        // document.cookie = `token=${token}; path=/`;
                        if (role === "guest") {
                            navigate(`/${lang}/choose-role`);
                        } else {
                            notification.success({
                                message: t("message.login_success"),
                            });
                            navigate("/");
                        }
                    } else {
                        notification.error({
                            message: res.message || t("message.third_party_login_error"),
                        });
                        navigate("/");
                    }
                    localStorage.removeItem("role");
                }
            });
        }
    }, [location.pathname]);

    const _pickRoleByGuest = (role_picked_by_guest) => {
        dispatch(pickRoleByGuest(role_picked_by_guest));
    };

    const _login = (user, callback = () => {}) => {
        dispatch(login(user));
        callback();
    };

    const _logout = (callback = () => {}) => {
        dispatch(logout());
        callback();
    };

    const value = {
        role_picked_by_guest,
        user,
        login: _login,
        logout: _logout,
        pickRoleByGuest: _pickRoleByGuest,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

/**
 * PREPARATION:
 * - Prepare data before accessing the target URL.
 * - Case: The user has not login yet.
 * (A target is an URL the user want to access)
 */
export const RequireGuestTarget = ({ children }) => {
    const location = useLocation();
    const lang = useSelector((state) => state.general.lang);

    // NOTE: These cases below are not related to each other!

    // CASE 1: Set up target by url:
    if (location.search) {
        let urlParamTargetId = null;

        // 1. Assignment access through link `assignment/${id}?username=${email}`:
        urlParamTargetId = new URLSearchParams(location.search).get("assignment_id");
        let username = new URLSearchParams(location.search).get("username");
        if (urlParamTargetId) {
            // localStorage.setItem("currentTargetPathname", `/${lang}/assignment/${urlParamTargetId}`);
            localStorage.setItem("currentTargetPathname", `/${lang}/assignment`);
            localStorage.setItem("currentTargetPathnameStatus", "pending");
            localStorage.setItem("currentTargetRequireUsername", username);
        } else {
            urlParamTargetId = null;
        }
    }

    return children;
};

/**
 * HANDLE CATCHING TARGET:
 * @param {any} auth User authentication object.
 * @param {any} location Location object.
 * @param {string} lang Language key.
 * @returns null if there is no target.
 */
const handleCatchTargetIfPossible = (auth = null, location = null, lang = "") => {
    if (!auth || !location) {
        return null;
    }

    // 1. Prevent navigating if user does not meet some requirements:
    // ...

    // 2. Reach target if possible:
    const currTarget = localStorage.getItem("currentTargetPathname");
    const currTargetStatus = localStorage.getItem("currentTargetPathnameStatus");
    const currTargetRequireUsername = localStorage.getItem("currentTargetRequireUsername");

    const searchParams = getAllSearchParams(new URLSearchParams(window.location.search));
    const invite_username = searchParams?.username;

    // 2.1. Conditions to reach target:
    let isAllowedToTarget = true;
    if (auth.user && currTargetRequireUsername !== null) {
        isAllowedToTarget = auth.user.username === currTargetRequireUsername;
    }

    // 2.2. Reach target:
    if (!auth.user) {
        if (checkTargetByURLPathname("invAssignment", location.pathname)) {
            // Invite teacher to an assignment with `username`:
            if (invite_username) {
                // Store the target:
                localStorage.setItem("currentTargetPathname", location.pathname + `&username=${invite_username}`);
                localStorage.setItem("currentTargetPathnameStatus", "pending");
                const assignmentId = location.pathname.substring(location.pathname.indexOf("/assignment/") + 12);
                return (
                    <Navigate
                        to={`/${lang}/active-user?assignment_id=${assignmentId}&username=${invite_username}`}
                        state={{ from: location }}
                        replace
                    />
                );
            }
        }
    } else {
        if (currTarget) {
            if (isAllowedToTarget) {
                if (currTargetStatus === "pending") {
                    localStorage.removeItem("currentTargetPathname");
                    localStorage.removeItem("currentTargetPathnameStatus");
                    localStorage.removeItem("currentTargetRequireUsername");
                    return <Navigate to={`${currTarget}`} state={{ from: location }} replace />;
                }
            } else {
                localStorage.removeItem("currentTargetPathname");
                localStorage.removeItem("currentTargetPathnameStatus");
                localStorage.removeItem("currentTargetRequireUsername");
                return null;
            }
        }
    }

    return null;
};

export const RequireAuth = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();
    const [check, setCheck] = useState(false);
    const lang = useSelector((state) => state.general.lang);

    useEffect(() => {
        const token = localStorage.getItem("token") || getCookie("token");
        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (!auth.user && !check) {
            if (token) {
                let url = "/me";
                api.get(url).then((res) => {
                    if (res?.status && res?.data?.user) {
                        auth.login(res.data.user, () => {
                            setCheck(true);
                        });
                    } else {
                        localStorage.removeItem("token");
                        deleteCookie("token");
                        setCheck(true);
                    }
                });
            } else {
                setCheck(true);
            }
        }
    }, [check, auth.user]);

    useEffect(() => {
        if (!auth.user) {
            if (checkIfCurrentTargetIsValidFor("teacher")) {
                auth.pickRoleByGuest("teacher");
            }
        }
    }, [auth.user]);

    if (!auth.user && !check) {
        return <LoadingScreen />;
    }

    const targetToBeReached = handleCatchTargetIfPossible(auth, location, lang);
    if (targetToBeReached !== null) {
        return targetToBeReached;
    }

    let path = location.pathname.replace(`/${lang}`, "");

    if (auth?.user?.role) {
        if (!path || path == "/") {
            switch (auth?.user?.role) {
                // case "teacher": {
                //     return <Navigate to={`/${lang}${accessRoles.teacher.homeRoute || ""}`} replace />;
                // }
                case "student": {
                    return <Navigate to={`/${lang}${accessRoles.student.homeRoute || ""}`} replace />;
                }
                default:
                    return <Navigate to={`/${lang}${accessRoles.teacher.homeRoute || ""}`} replace />;
            }
        }
    }

    if (!auth.user) {
        if (!auth.role_picked_by_guest) {
            return <Navigate to={`/${lang}${accessRoles.guest.homeRoute}`} state={{ from: location }} replace />;
        }

        if (path === "/register") {
            return <Navigate to={`/${lang}/register${getTargetURLQueryString()}`} state={{ from: location }} replace />;
        } else if (path === "/forget-password") {
            return (
                <Navigate
                    to={`/${lang}/forget-password${getTargetURLQueryString()}`}
                    state={{ from: location }}
                    replace
                />
            );
        } else if (path === "/login") {
            return <Navigate to={`/${lang}/login${getTargetURLQueryString()}`} state={{ from: location }} replace />;
        } else {
            return <Navigate to={`/${lang}${accessRoles.guest.homeRoute}`} state={{ from: location }} replace />;
        }
    }

    return children;
};

export const RequireGuest = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();
    const [check, setCheck] = useState(false);
    const lang = useSelector((state) => state.general.lang);

    useEffect(() => {
        const token = localStorage.getItem("token") || getCookie("token");
        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (!auth.user && !check) {
            if (token) {
                let url = "/me";
                api.get(url).then((res) => {
                    if (res?.status && res?.data?.user) {
                        auth.login(res.data.user, () => {
                            setCheck(true);
                        });
                    } else {
                        localStorage.removeItem("token");
                        deleteCookie("token");
                        setCheck(true);
                    }
                });
            } else {
                setCheck(true);
            }
        }
    }, [check, auth.user]);

    if (!auth.user && !check) {
        return <LoadingScreen />;
    }

    if (auth.user) {
        return <Navigate to={`/${lang}`} state={{ from: location }} replace />;
    }

    if (!auth.role_picked_by_guest) {
        return <Navigate to={`/${lang}${accessRoles.guest.homeRoute}`} state={{ from: location }} replace />;
    }

    return children;
};
