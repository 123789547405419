import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchAssignmentsGradebook } from "src/api/containers/assignment";
import { findCourse } from "src/api/containers/course";
import { useFetch, useMediaQuery, useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { setCurrentPage } from "src/reducers/general";
import { useRouting } from "src/utils/router";
import ActivityTable from "./components/ActivityTable";
import ActivityTabs from "./components/ActivityTabs";
import FilterMenu from "./components/FilterMenu";
import "./GradeBook.scss";

const GradeBook = () => {
    const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
    const [isModalFilter, setIsMoadlFilter] = useState(false);
    const [breadcumRoutes, setBreadcumRoutes] = useState([{ path: "home" }, { name: "Gradebook" }]);
    const { role } = useSelector((state) => state.auth.user);

    const { generate } = useRouting();

    const paramsFilter = { order: "asc", is_unit: 1, page: 1 };
    if (role === "student") {
        paramsFilter.status = "donot";
    }

    const [filterParams, setFilterParams] = useValues(paramsFilter);
    const assignmentFetch = useFetch(filterParams, fetchAssignmentsGradebook);
    const [loadingAssignment, assignments, pagination, fetchAsignmentsData, reFetchAsignmentsData] = assignmentFetch;
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let id = searchParams.get("course");
        if (id) {
            findCourse(id, { relations: ["level"] }).then(({ status, data }) => {
                if (status) {
                    setFilterParams({ course: id, level: data?.level_id, language: data?.language_id });
                    let path = generate("units", { idCourse: id });
                    if (role !== "student") {
                        path = generate("course_unit", { courseId: id });
                    }
                    data.name &&
                        setBreadcumRoutes([{ path: "home" }, { name: data.name, path: path }, { name: "Gradebook" }]);
                }
            });
        }

        dispatch(setCurrentPage("gradebook"));
    }, [searchParams]);

    useEffect(() => {
        if (filterParams.unit || filterParams.level || filterParams.course || filterParams.language) {
            setIsMoadlFilter(true);
        } else {
            setIsMoadlFilter(false);
        }
        let id = searchParams.get("course");
        if (id && id != filterParams.course) {
            return;
        }
        fetchAsignmentsData({ ...filterParams, page: 1 }, true);
    }, [filterParams]);

    const currMedia = useMediaQuery(["(max-width: 991px)"]);

    return (
        <>
            <Breadcrumb routes={breadcumRoutes} className={"mobile"} />
            <div className="grade-book">
                <div className="grade-book__container">
                    <div className={`grade-book__tabs ${role == "student" ? "student" : "teacher"}`}>
                        {role == "student" ? (
                            <ActivityTabs
                                assignments={assignments}
                                assignmentFetch={assignmentFetch}
                                setFiter={setFilterParams}
                                filterParams={filterParams}
                                mediaWidth={currMedia.breakpointValue}
                            />
                        ) : (
                            <ActivityTable
                                assignments={assignments}
                                setFiter={setFilterParams}
                                assignmentFetch={assignmentFetch}
                                filterParams={filterParams}
                                mediaWidth={currMedia.breakpointValue}
                            />
                        )}

                        <div className={`filterButton ${role == "student" ? "student" : "teacher"}`}>
                            <div style={{ position: "relative" }}>
                                <Dropdown
                                    visible={isFilterMenuVisible}
                                    overlay={
                                        <FilterMenu
                                            setFilterParams={setFilterParams}
                                            handleCloseFilterMenu={() => setIsFilterMenuVisible(false)}
                                            // setIsMoadlFilter={setIsMoadlFilter}
                                            filterParams={filterParams}
                                            course={searchParams.get("course")}
                                        />
                                    }
                                    overlayClassName="progress-filter"
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    onVisibleChange={(val) => setIsFilterMenuVisible(val)}
                                >
                                    <button className="filter-btn">
                                        {isModalFilter ? <FilterFilled /> : <FilterOutlined />}
                                        {t("gradebook.filter")}
                                    </button>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GradeBook;
