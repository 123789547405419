import { Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileManager from ".";
import useController from "./useController";
// import axios from "axios";
import {
    addFiles,
    createFolder,
    deleteFiles,
    listFoldersAndFiles,
    moveFilesToFolder,
    renameFile,
} from "src/api/containers/storage";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import "./popup.scss";

if (!document.getElementById("file-manager0")) {
    let el = document.createElement("div");
    el.setAttribute("id", "file-manager0");
    document.body.append(el);
}

const FileManagerModal = ({ controller, ...rest }) => {
    const { t } = useTranslation();
    const fmController = FileManager.useController();

    const [visible, setVisible] = useState(false);
    const [fmSettings, setFmSettings] = useState({
        isLoading: "none",
    });
    const [modalSettings, setModalSettings] = useState({
        visible: false,
        data: null,
    });

    const handleAddFolder = (folderName, parentInfo) => {
        if (!folderName) {
            folderName = t("my_storage.new_folder");
        }
        const folderData = {
            name: folderName,
            parent_id: parentInfo.id,
        };
        return new Promise((resolve, reject) => {
            createFolder(folderData).then((res) => {
                if (res.status) {
                    resolve(res.data);
                } else {
                    reject(res);
                }
            });
        });
    };

    const handleGetListOfFolders = (parentFolderId) => {
        return new Promise((resolve, reject) => {
            listFoldersAndFiles(parentFolderId, {
                noPagination: true,
                type: "folder",
            }).then((res) => {
                if (res.status && res.data instanceof Array) {
                    // Check if there is any folder inside the selected folder:
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].childs?.length === 0) {
                            res.data[i].state = "empty";
                        } else {
                            let countFolder = 0;
                            for (let j = 0; j < res.data[i].childs.length; j++) {
                                if (res.data[i].childs[j].type === "folder") {
                                    res.data[i].state = "not-empty";
                                    countFolder++;
                                    break;
                                }
                            }
                            if (countFolder === 0) {
                                res.data[i].state = "empty";
                            }
                        }
                    }
                    resolve(res.data);
                }
            });
        });
    };

    const handleAddFiles = (fileList, parentInfo) => {
        /**
         * Create FormData instance, and set the Content-Type header to multipart/form-data.
         * To get the formData values, use:
         * console.log(formData.getAll("files"));
         */
        const formData = new FormData();
        if (fileList.length > 0) {
            [...fileList].forEach((fileItem, fileIndex) => {
                formData.append(`file${fileIndex}`, fileItem, fileItem.name);
            });
        }
        if (parentInfo.id) {
            formData.append("parent_id", parentInfo.id);
        }
        return new Promise((resolve, reject) => {
            setFmSettings({ ...fmSettings, isLoading: "uploading" });
            addFiles(formData).then((res) => {
                if (res.status) {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    resolve(res.data);
                } else {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    reject(res);
                }
            });
        });
    };

    const handleRemoveFiles = (fileList) => {
        return new Promise((resolve, reject) => {
            setFmSettings({ ...fmSettings, isLoading: "removing" });
            deleteFiles(fileList.map((fileItem) => fileItem.id)).then((res) => {
                if (res.status) {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    resolve(res.data);
                } else {
                    setFmSettings({ ...fmSettings, isLoading: "none" });
                    reject(res);
                }
            });
        });
    };

    const handleDoubleClickFile = (selectedFile) => {
        return selectedFile;
    };

    const handleGetChildren = (parentFolderId) => {
        return new Promise((resolve, reject) => {
            listFoldersAndFiles(parentFolderId, {
                noPagination: true,
            }).then((res) => {
                if (res.status && res.data instanceof Array) {
                    // Check if the folder contains anything:
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].type === "folder") {
                            if (res.data[i].childs?.length === 0) {
                                res.data[i].state = "empty";
                            } else {
                                res.data[i].state = "not-empty";
                            }
                        }
                    }
                    resolve(res.data);
                }
            });
        });
    };

    const handleRenameFile = (fileId, fileData) => {
        return new Promise((resolve, reject) => {
            renameFile(fileId, fileData).then((res) => {
                if (res.status) {
                    resolve(res.data);
                } else {
                    reject(res);
                }
            });
        });
    };

    const handleMoveFilesToFolder = (selectedFiles, selectedFolder) => {
        return new Promise((resolve, reject) => {
            const dataToBeSubmitted = {
                child_ids: (selectedFiles || []).map((item) => item.id || item._id),
                parent_id: selectedFolder.id || selectedFolder._id,
            };
            moveFilesToFolder(dataToBeSubmitted).then((res) => {
                if (res.status) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    };

    useEffect(() => {
        if (controller) {
            controller.set("open", () => {
                setVisible(true);
                setTimeout(() => {
                    fmController.call("open");
                }, 100);
            });
            controller.set("close", () => {
                setVisible(false);
                setTimeout(() => {
                    fmController.call("close");
                }, 100);
            });
        }
        fmController.set("after_close", () => {
            setVisible(false);
        });

        return () => {
            fmController.remove("after_close");
        };
    }, []);

    return (
        <Modal
            wrapClassName="file-manager-container-modal"
            visible={visible}
            onCancel={() => {
                setVisible(false);
            }}
            title={t("my_storage.my_storage")}
            closeIcon={<IconClose />}
            footer={null}
            centered
            className="app-modal type-basic"
        >
            <Spin
                spinning={fmSettings.isLoading !== "none"}
                tip={<>{t(`my_storage.filemanager_${fmSettings.isLoading}`)}</>}
                wrapperClassName="app-spin-nested-loading layout-full"
            >
                <FileManager
                    title={t("my_storage.my_storage")}
                    controller={fmController}
                    containerStyle={{ height: "100%" }}
                    isMultiple={true}
                    isHeaderVisible={false}
                    // Interacting with folders:
                    onMakeFolder={(folderName, parentInfo) => {
                        return handleAddFolder(folderName, parentInfo);
                    }}
                    onListFolders={(parentFolderId) => {
                        return handleGetListOfFolders(parentFolderId);
                    }}
                    // Interacting with files:
                    onUploadFiles={(fileList, parentInfo) => {
                        return handleAddFiles(fileList, parentInfo);
                    }}
                    onRemoveFiles={(fileList) => {
                        return handleRemoveFiles(fileList);
                    }}
                    onSelectFile={(selectedFile) => {
                        return handleDoubleClickFile(selectedFile);
                    }}
                    onListFiles={(parentFolderId) => {
                        return handleGetChildren(parentFolderId);
                    }}
                    onRenameFile={(fileId, fileData) => {
                        return handleRenameFile(fileId, fileData);
                    }}
                    onMoveFilesToFolder={handleMoveFilesToFolder}
                    {...rest}
                />
            </Spin>
        </Modal>
    );
};

const PopupFileManager = ({ controller, ...rest }) => {
    return <FileManagerModal controller={controller} {...rest} />;
};

PopupFileManager.useController = useController;

export default PopupFileManager;
