import { LoadingOutlined } from "@ant-design/icons";
import { Form, notification } from "antd";
import { t } from "i18next";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { countUserViolations, fetchRecordByAssignmentId } from "src/api/containers/record";
import BookIcon from "src/assets/images/book.svg";
import ExitIcon from "src/assets/images/exit.svg";
import PencilIcon from "src/assets/images/pencil.svg";
import configs from "src/configs";
import { useRestrictCopyPaste, useValues } from "src/hooks";
import usePageVisibility from "src/hooks/AntiCheating/useVisiblity";
import Button from "src/modules/components/Button";
import LoadingScreen from "src/modules/components/LoadingScreen";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import QuestionPanel from "src/modules/components/QuestionPanel";
import FaceDetection from "src/modules/components/WebcamFaceDetect";
import { setIsReadOnly } from "src/reducers/answer";
import { initialClock, setIsLoadingClock } from "src/reducers/clock";
import { setCurrentPage } from "src/reducers/general";
import { addRecord, gotoQuestionOrderTempTime, setIsShowConfirmModal } from "src/reducers/test";
import { pages } from "src/routes/pages";
import { closeFullscreen } from "src/utils/AntiCheating";
import { convertQuestionState } from "src/utils/helpers";
import { useRouting } from "src/utils/router";

import { ProctoringContext } from "../ExamProctoring";
import { ScreenCamMicRecorderContext } from "../ExamProctoring/components/ScreenCamMicRecorder";
import TestLayout from "../Layout/TestLayout";
import NumberQuestion from "../Layout/TestLayout/Sidebar/NumberQuestion";
import Header from "./Header";
import "./TestLayout.scss";

const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;
const copypaste = ["copy", "paste", "cut"];

const Test = (props) => {
    const proctoring = useContext(ProctoringContext);
    const recording = useContext(ScreenCamMicRecorderContext);
    const examCheckInInfo = proctoring?.examCheckInInfo;
    const examRecordData = proctoring?.examRecordData;
    const recording_withCamera = examCheckInInfo?.assignment?.options?.record_camera || false;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { generate } = useRouting();
    const { id: idAssignment } = useParams();

    const intervalId = useRef(null);
    const countVisible = useRef(0);

    const lang = useSelector((state) => state.general.lang);
    const { listAnswers, isReadonly } = useSelector((state) => state.answer);
    const { currentQuestionOrder } = useSelector((state) => state.test);

    const [loadingRecord, setLoadingRecord] = useState(true);
    const [dataRecord, setDataRecord] = useState([]);
    const [isShowExitModal, setIsShowExitModal] = useState(false);

    const { questions, record_id, homework_time_minute, start_time, now, options: testOptions } = dataRecord;

    const [values, setValues] = useValues({
        isShowModalWarning: false,
        modalMessage: "",
        okText: "",
        onOkAction: () => {},
    });

    const isVisible = usePageVisibility();

    const currentQuestionInfo = useMemo(() => {
        if (questions?.length > 0) {
            return questions[+String(currentQuestionOrder).split(".")[0] - 1] || {};
        }
    }, [currentQuestionOrder, dataRecord]);

    const handleCopyPasteEvent = () => {
        notification.warning({ message: t("anti_cheating.copy_paste_not_allowed") });
    };

    useRestrictCopyPaste({
        actions: copypaste,
        condition: testOptions?.[ANTI_CHEATING_KEYS.DISABLE_COPY_PASTE],
        eventhandler: handleCopyPasteEvent,
        window,
    });

    const handleExit = () => {
        navigate(generate("exam_asset"));
        closeFullscreen();
    };

    useEffect(() => {
        if (!isVisible && !values.isShowModalWarning && testOptions?.[ANTI_CHEATING_KEYS.ALERT_USER_LEAVE_PAGE]) {
            countVisible.current += 1;
            let message = `Cảnh báo, bạn đã thoát khỏi trang làm bài ${countVisible.current} lần! Hành vi này có thể bị coi là gian lận.`;

            if (lang === "en") {
                message = `Warning, you have exited the assignment page ${countVisible.current} times! This behavior may be considered cheating.`;
            }
            // setValues({
            //     isShowModalWarning: true,
            //     modalMessage: message,
            //     okText: t("shared.ok"),
            //     onOkAction: () =>
            //         setValues({ isShowModalWarning: false, modalMessage: "", onOkAction: () => {}, okText: "" }),
            // });
            countUserViolations(record_id, ANTI_CHEATING_KEYS.OPEN_TAB).then(({ status, warning, is_load }) => {
                if (is_load) {
                    navigate(generate(pages.exam_asset.name));
                    notification.warning({ message: t("anti_cheating.violated_exam_contact_teacher") });
                    closeFullscreen();
                }

                if (warning) {
                    notification.warning({ message: warning });
                }
            });
        }
    }, [isVisible]);

    useEffect(() => {
        if (record_id) {
            const currentQuestion = dataRecord.questions[0];
            // Nếu là câu passage thì order mặc định sẽ là 1.1
            const order = currentQuestion.type === "passage" ? 1.1 : 1;

            // Nếu là câu passage thì questionId mặc định sẽ là id của câu đầu tiền trong paragraph đầu tiên
            const questionId =
                currentQuestion.type === "passage"
                    ? currentQuestion?.paragraphs?.[0]?.question_details?.[0]?.id
                    : currentQuestion?.id;

            dispatch(
                gotoQuestionOrderTempTime({
                    order,
                    recordId: record_id,
                    questionId,

                    // Câu passage sẽ phải thêm field
                    ...(currentQuestion.type === "passage" && { passageId: currentQuestion.id }),
                })
            );
        }
    }, [record_id]);

    useEffect(() => {
        const isExamCheckin = examCheckInInfo?.assignment?.id && examRecordData;

        if (isExamCheckin) {
            setDataRecord(examRecordData.data);
            setLoadingRecord(false);
        } else {
            setLoadingRecord(true);

            fetchRecordByAssignmentId({ assignment_id: idAssignment }).then((res) => {
                if (res.status) {
                    setDataRecord(res.data);
                    setLoadingRecord(false);
                } else {
                    localStorage.removeItem("listAnswers");

                    notification.error({ message: res.message });

                    navigate(-1);
                    closeFullscreen();
                }
            });
        }
    }, [idAssignment, examCheckInInfo, examRecordData]);

    useEffect(() => {
        dispatch(addRecord({ dataRecord, idAssignment }));
    }, [dataRecord]);

    useEffect(() => {
        const isExamCheckin = examCheckInInfo?.assignment?.id && examRecordData;

        if (isExamCheckin) {
            // Do nothing, because dataRecord comes from ProctoringContext.
        } else {
            if (proctoring?.setExamRecordData instanceof Function) {
                proctoring?.setExamRecordData(dataRecord);
            }
        }
    }, [dataRecord, examCheckInInfo, examRecordData]);

    const handleChangeQuestionOrder = (order) => {
        let newOrder = order;
        let newQuestionId = dataRecord.questions[order - 1].id;
        let passageId = null;

        if (dataRecord.questions[order - 1].type === "passage") {
            newOrder = order + ".1";

            // mặc định khi nhảy vào câu passage thì sẽ tính tempTime từ câu *.1
            newQuestionId = dataRecord.questions[order - 1]?.paragraphs?.[0]?.question_details?.[0]?.id;
            passageId = dataRecord.questions[order - 1].id;
        }

        dispatch(
            gotoQuestionOrderTempTime({
                order: newOrder,
                recordId: record_id,
                questionId: newQuestionId,
                ...(passageId && { passageId }),
            })
        );
    };

    const handleNextQuestionOrder = () => {
        const continueQuestionOrder = currentQuestionOrder + 1;
        const continueQuestionInfo = dataRecord.questions[continueQuestionOrder - 1];
        let questionId = continueQuestionInfo.id;
        let passageId = null;
        if (continueQuestionOrder <= questions.length) {
            let newOrder = continueQuestionOrder;

            if (continueQuestionInfo.type === "passage") {
                newOrder = continueQuestionOrder + ".1";
                questionId = continueQuestionInfo?.paragraphs?.[0]?.question_details?.[0]?.id;
                passageId = continueQuestionInfo.id;
            }

            dispatch(
                gotoQuestionOrderTempTime({
                    order: newOrder,
                    recordId: record_id,
                    questionId,
                    ...(passageId && { passageId }),
                })
            );
        }
    };

    const handlePrevQuestionOrder = () => {
        const continueQuestionOrder = currentQuestionOrder - 1;
        const continueQuestionInfo = dataRecord.questions[continueQuestionOrder - 1];
        let questionId = continueQuestionInfo.id;
        let passageId = null;

        if (continueQuestionOrder > 0) {
            let newOrder = continueQuestionOrder;

            if (continueQuestionInfo.type === "passage") {
                newOrder = continueQuestionOrder + ".1";
                passageId = dataRecord.questions[continueQuestionOrder - 1].id;
                questionId = continueQuestionInfo?.paragraphs?.[0]?.question_details?.[0]?.id;
            }

            dispatch(
                gotoQuestionOrderTempTime({
                    order: newOrder,
                    recordId: record_id,
                    questionId,
                    ...(passageId && { passageId }),
                })
            );
        }
    };

    const initialDecreaseClock = () => {
        const dateNowSecond = Math.round(new Date(now) / 1000);
        const startTimeSecond = new Date(start_time).getTime() / 1000;

        const homeworkTimeSecond = homework_time_minute * 60;
        const endTimeSecond = startTimeSecond + homeworkTimeSecond;
        const leftTimeSecond = Math.round(endTimeSecond - dateNowSecond);

        if (dateNowSecond > endTimeSecond) {
            dispatch(
                initialClock({
                    secondTotal: homeworkTimeSecond,
                    secondLeft: 0,
                    type: "decrease",
                })
            );

            dispatch(setIsReadOnly(true));
        } else {
            if (leftTimeSecond > 0) {
                dispatch(
                    initialClock({
                        secondTotal: homeworkTimeSecond,
                        secondLeft: leftTimeSecond,
                        type: "decrease",
                    })
                );
            } else {
                alert("Lỗi thời gian bắt đầu làm bài không hợp lệ");
            }
        }
    };

    const initialIncreaseClock = () => {
        // Handle get start time
        const dateNowSecond = Math.round(Date.now() / 1000);
        const startTimeSecond = new Date(start_time).getTime() / 1000;

        dispatch(
            initialClock({
                secondTotal: 0,
                secondLeft: dateNowSecond - startTimeSecond || 0,
                type: "increase",
            })
        );
    };

    useEffect(() => {
        dispatch(setCurrentPage("test"));
    }, []);

    // Handle initial clock
    useEffect(() => {
        if (!loadingRecord) {
            if (homework_time_minute) {
                initialDecreaseClock();
            } else {
                initialIncreaseClock();
            }
        }
    }, [homework_time_minute, loadingRecord]);

    useEffect(() => {
        dispatch(setIsLoadingClock(loadingRecord));
    }, [loadingRecord]);

    useEffect(() => {
        // Remove dispatch ticktacDecrease when secondLeft is zero
        if (isReadonly) {
            clearInterval(intervalId.current);
        }
    }, [isReadonly]);

    useEffect(() => {
        return () => {
            dispatch(setIsReadOnly(false));
        };
    }, []);

    useEffect(() => {
        if (loadingRecord && recording_withCamera) {
            if (recording?.startCameraViewer instanceof Function) {
                recording.startCameraViewer("#webcam-displayer-for-proctoring");
            }
        }
    }, [loadingRecord]);

    if (loadingRecord) {
        return <LoadingScreen />;
    }

    return (
        <TestLayout record_id={record_id} testOptions={testOptions}>
            <>
                {!loadingRecord && (
                    <div className="test_content">
                        <Header title={dataRecord?.name || ""} icon={BookIcon} />

                        <div className="test_question">
                            <Form>
                                <Form.Item name={currentQuestionOrder}>
                                    <QuestionPanel
                                        isDisableCopyPaste={testOptions?.[ANTI_CHEATING_KEYS.DISABLE_COPY_PASTE]}
                                        goNextQuestion={handleNextQuestionOrder}
                                        goPrevQuestion={handlePrevQuestionOrder}
                                        questionInfo={currentQuestionInfo}
                                        recordId={record_id}
                                        questionAmount={questions?.length}
                                        questionOrder={currentQuestionOrder}
                                        mode={isReadonly ? "readonly" : "test"}
                                    />
                                </Form.Item>
                            </Form>
                        </div>

                        {/* <video autoPlay id="webcam-displayer-for-proctoring"></video> */}
                        {recording_withCamera && (
                            <FaceDetection
                                id="webcam-displayer-for-proctoring1"
                                className="webcam-displayer-for-proctoring1"
                            />
                        )}
                    </div>
                )}
                {loadingRecord && <LoadingOutlined />}
                {!loadingRecord && (
                    <div className="NumberQuestion_Responsive">
                        <NumberQuestion
                            amount={questions?.length}
                            listOrderAnswered={convertQuestionState(questions, listAnswers[dataRecord.record_id])}
                            onChangeQuestionOrder={handleChangeQuestionOrder}
                            isSlider={true}
                        />
                        {isReadonly && <div className="test_timeoutTest">{t("test.timeout_test")}</div>}
                        <div className="Submit_Test">
                            <Button
                                type="grey"
                                icon={ExitIcon}
                                onClick={() => {
                                    setIsShowExitModal(true);
                                    setValues({
                                        isShowModalWarning: true,
                                        modalMessage: t("test.confirm_exit"),
                                        onOkAction: handleExit,
                                        okText: t("test.exit"),
                                    });
                                }}
                                title={t("test.exit")}
                            ></Button>
                            <Button
                                type="pink"
                                icon={PencilIcon}
                                onClick={() => {
                                    dispatch(setIsShowConfirmModal(true));
                                }}
                                title={t("test.submit")}
                            ></Button>
                        </div>
                    </div>
                )}
            </>
            <PopConfirm
                type="delete"
                visible={values.isShowModalWarning}
                onOk={values.onOkAction}
                onCancel={() => {
                    setValues({ isShowModalWarning: false, modalMessage: "", onOkAction: () => {}, okText: "" });
                }}
                okText={values.okText}
                cancelText={t("test.cancel")}
            >
                <p className="modal-title" style={{ margin: 0 }}>
                    {values.modalMessage}
                </p>
            </PopConfirm>
        </TestLayout>
    );
};

export default Test;
