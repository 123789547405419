import Button from "../Button";
import { _2735, _dot, _2734, _2737, _2788, _3341, _2475, _equal } from "../../icons/tab4";
import { _3527, _3167, _2751, _2745, _2752, _3168, _gt, _lt } from "../../icons/tab4";
import { _6199, _4065, _8360, _8380, _Path3367, _Path2256, _Path2253, _Path2263 } from "../../icons/tab4";
import { _Path2261, _2267, _Path2266, _Path2252, _Path2254, _3180, _3169, _3172 } from "../../icons/tab4";
import { _3171, _3163, _Path3357, _Path3360, _Path3359, _Path3362, _Path3358, _Path3436 } from "../../icons/tab4";
import { _Path2744, _6101, _3170, _3181, _Path2262, _Path2743, _Path2792, _Path3526 } from "../../icons/tab4";
import {
    _Path3529,
    _Path3522,
    _Path3523,
    _Path3524,
    _Path3525,
    _Path3501,
    _Path3502,
    _Path3503,
} from "../../icons/tab4";

const Tab4 = ({ onClick }) => {
    const datas = [
        { icon: _2735, text: "+" },
        { icon: _dot, text: "\\cdot" },
        { icon: _2734, text: "*" },
        { icon: _2737, text: "-" },
        { icon: _2788, text: "\\div" },
        { icon: _3341, text: "/" },
        { icon: _2475, text: "\\pm" },
        { icon: _equal, text: "=" },

        { icon: _3527, text: "\\ne" },
        { icon: _3167, text: "\\sim" },
        { icon: _2751, text: "\\equiv" },
        { icon: _2745, text: "\\approx" },
        { icon: _2752, text: "\\simeq" },
        { icon: _3168, text: "\\cong" },
        { icon: _gt, text: ">" },
        { icon: _lt, text: "<" },

        { icon: _6199, text: "\\ge" },
        { icon: _4065, text: "\\le" },
        { icon: _8360, text: "≮" },
        { icon: _8380, text: "≯" },
        { icon: _Path3367, text: "\\infty" },
        { icon: _Path2256, text: "\\subset" },
        { icon: _Path2253, text: "\\supset" },
        { icon: _Path2263, text: "\\subseteq" },

        { icon: _Path2261, text: "\\supseteq" },
        { icon: _2267, text: "\\in" },
        { icon: _Path2266, text: "\\notin" },
        { icon: _Path2252, text: "\\cup" },
        { icon: _Path2254, text: "\\cap" },
        { icon: _3180, text: "\\square" },
        { icon: _3169, text: "\\triangle" },
        { icon: _3172, text: "\\uxparallelogram" },

        { icon: _3171, text: "\\perp" },
        { icon: _3163, text: "\\parallel" },
        { icon: _Path3357, text: "\\Q" },
        { icon: _Path3360, text: "\\W" },
        { icon: _Path3359, text: "\\Z" },
        { icon: _Path3362, text: "\\C" },
        { icon: _Path3358, text: "\\R" },
        { icon: _Path3436, text: "\\theta" },

        { icon: _Path2744, text: "\\angle" },
        { icon: _6101, text: "\\text{m}\\angle" },
        { icon: _3170, text: "\\odot" },
        { icon: _3181, text: "\\nparallel" },
        { icon: _Path2262, text: "\\ni" },
        { icon: _Path2743, text: "\\notni" },
        { icon: _Path2792, text: "\\forall" },
        { icon: _Path3526, text: "\\diamond" },

        { icon: _Path3529, text: "\\bigstar" },
        { icon: _Path3522, text: "\\clubsuit" },
        { icon: _Path3523, text: "\\diamondsuit" },
        { icon: _Path3524, text: "\\spadesuit" },
        { icon: _Path3525, text: "\\heartsuit" },
        { icon: _Path3501, text: "\\flat" },
        { icon: _Path3502, text: "\\natural" },
        { icon: _Path3503, text: "\\sharp" },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab4;
