import Button from "../Button";
import {
    _Path3391,
    _Path3399,
    _Path3405,
    _Path3411,
    _Path3417,
    _Path3423,
    _Path3429,
    _Path3436,
} from "../../icons/tab6";

import {
    _Path3444,
    _Path3392,
    _Path3400,
    _Path3406,
    _Path3412,
    _Path3418,
    _Path3424,
    _Path3430,
} from "../../icons/tab6";

import {
    _Path3437,
    _Path3395,
    _Path3394,
    _Path3401,
    _Path3407,
    _Path3413,
    _Path3419,
    _Path3425,
} from "../../icons/tab6";

import {
    _Path3432,
    _Path3431,
    _Path3438,
    _Path3396,
    _Path3402,
    _Path3408,
    _Path3414,
    _Path3420,
} from "../../icons/tab6";

import {
    _Path3426,
    _Path3433,
    _Path3441,
    _Path3439,
    _cyrillic_letter_i,
    _Path3403,
    _Path3409,
    _Path3415,
} from "../../icons/tab6";

import {
    _Path3421,
    _Path3427,
    _Path3434,
    _Path3442,
    _Path3454,
    _Path3455,
    _Path3456,
    _Path3457,
} from "../../icons/tab6";

import { _Path3458, _Path3459, _Path3460, _Path3461 } from "../../icons/tab6";

const Tab6 = ({ onClick }) => {
    const datas = [
        { icon: _Path3391, text: "\\alpha" },
        { icon: _Path3399, text: "\\beta" },
        { icon: _Path3405, text: "\\gamma" },
        { icon: _Path3411, text: "\\delta" },
        { icon: _Path3417, text: "\\varepsilon" },
        { icon: _Path3423, text: "\\zeta" },
        { icon: _Path3429, text: "\\eta" },
        { icon: _Path3436, text: "\\theta" },

        { icon: _Path3444, text: "\\vartheta" },
        { icon: _Path3392, text: "\\iota" },
        { icon: _Path3400, text: "\\kappa" },
        { icon: _Path3406, text: "\\lambda" },
        { icon: _Path3412, text: "\\mu" },
        { icon: _Path3418, text: "\\nu" },
        { icon: _Path3424, text: "\\xi" },
        { icon: _Path3430, text: "o" },

        { icon: _Path3437, text: "\\pi" },
        { icon: _Path3395, text: "\\varpi" },
        { icon: _Path3394, text: "\\rho" },
        { icon: _Path3401, text: "\\varsigma" },
        { icon: _Path3407, text: "\\sigma" },
        { icon: _Path3413, text: "\\tau" },
        { icon: _Path3419, text: "\\upsilon" },
        { icon: _Path3425, text: "\\varphi" },

        { icon: _Path3432, text: "\\phi" },
        { icon: _Path3431, text: "\\chi" },
        { icon: _Path3438, text: "\\psi" },
        { icon: _Path3396, text: "\\omega" },
        { icon: _Path3402, text: "\\text{A}" },
        { icon: _Path3408, text: "\\text{B}" },
        { icon: _Path3414, text: "\\Gamma" },
        { icon: _Path3420, text: "\\Delta" },

        { icon: _Path3426, text: "\\text{E}" },
        { icon: _Path3433, text: "\\text{Z}" },
        { icon: _Path3441, text: "\\text{H}" },
        { icon: _Path3439, text: "\\Theta" },
        { icon: _cyrillic_letter_i, text: "\\text{I}" },
        { icon: _Path3403, text: "\\text{K}" },
        { icon: _Path3409, text: "\\Lambda" },
        { icon: _Path3415, text: "\\text{M}" },

        { icon: _Path3421, text: "\\text{N}" },
        { icon: _Path3427, text: "\\Xi" },
        { icon: _Path3434, text: "\\text{Ο}" },
        { icon: _Path3442, text: "\\Pi" },
        { icon: _Path3454, text: "\\text{P}" },
        { icon: _Path3455, text: "\\Sigma" },
        { icon: _Path3456, text: "\\text{T}" },
        { icon: _Path3457, text: "\\text{Y}" },

        { icon: _Path3458, text: "\\Phi" },
        { icon: _Path3459, text: "\\text{X}" },
        { icon: _Path3460, text: "\\Psi" },
        { icon: _Path3461, text: "\\Omega" },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab6;
