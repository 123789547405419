import clsx from "clsx";
import { useEffect, useState } from "react";

import LoginImg from "src/assets/images/login/login.png";

import "./Login.scss";
import AuthedRolePicker from "./components/AuthedRolePicker";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ForgetPasswordFirstTime";
import LoginTabs from "./components/LoginTabs";
import Verify from "./components/Verify";

const LoginV2 = (props) => {
    const { register = false, forget = false, role = false, firstResetPW = false, username } = props;
    const [userData, setUserData] = useState({});
    const [hideHeader, setHideHeader] = useState("");

    const handleDataLogin = (username, password) => {
        setUserData({ username, password });
        setHideHeader(true);
    };

    useEffect(() => {
        setHideHeader(forget || role || firstResetPW);
    }, [window.location.pathname]);

    if (role) {
        return (
            <div className="login">
                <AuthedRolePicker />
            </div>
        );
    }

    return (
        <div className="login">
            <div className="login-layout-wrapper">
                {forget ? (
                    <div className="login-middle">
                        <ForgetPassword />
                    </div>
                ) : userData?.username && userData?.password ? (
                    <div className="login-middle">
                        <Verify userData={userData} />
                    </div>
                ) : firstResetPW ? (
                    <div className="login-middle">
                        <ResetPassword username={username} />
                    </div>
                ) : (
                    <>
                        <div className={clsx("login-left", hideHeader && "mt-0")}>
                            <div className="body-wrapper">
                                <LoginTabs tab={register ? "1" : "2"} getData={handleDataLogin} />
                            </div>
                        </div>
                        <div className="login-right">
                            <div className="decoration-wrapper">
                                <div className="decoration-middle">
                                    <img src={LoginImg} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginV2;
