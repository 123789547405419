import * as faceapi from "face-api.js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { alertOnTop } from "src/reducers/general";
import { checkIsModalsFaceApiLoaded } from "src/utils/helpers";

/**
 *
 * @returns {{modelsLoaded:boolean,reLoadModels: function}}
 */
function useLoadFaceapiModels() {
    const dispatch = useDispatch();
    const [modelsLoaded, setModelsLoaded] = useState(false);

    const loadModels = async () => {
        const MODEL_URL = process.env.PUBLIC_URL + "/models";

        Promise.all([
            faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        ])
            .then(() => {
                setModelsLoaded(true);
            })
            .catch(() => {
                dispatch(
                    alertOnTop({
                        message: t("error_occurred_please_reload"),
                        type: "error",
                        action: "reload_page",
                    })
                );
            });
    };

    useEffect(() => {
        if (!checkIsModalsFaceApiLoaded(faceapi)) {
            loadModels();
        } else {
            setModelsLoaded(true);
        }
    }, []);

    return { modelsLoaded, reLoadModels: loadModels };
}

export default useLoadFaceapiModels;
