import MultipleChoice from "src/modules/components/QuestionContent/components/MultipleChoice";
import "./QuestionHistory.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import MultipleChoiceBoolean from "src/modules/components/QuestionContent/components/MultipleChoiceBoolean";
import { useTranslation } from "react-i18next";
import MultipleChoiceTable from "src/modules/components/QuestionContent/components/MultipleChoiceTable";
import Highlight from "src/modules/components/QuestionContent/components/Highlight";
import Sort from "src/modules/components/QuestionContent/components/Sort";
import { Badge, List } from "antd";
import { useMemo } from "react";
import DragDropGroup from "src/modules/components/QuestionContent/components/DragDropGroup";
import { checkIsObject } from "src/utils/helpers";
import PhoneticTable from "src/modules/components/QuestionContent/components/PhoneticTable";
import ImageWithLabels from "src/modules/components/QuestionContent/components/ImageWithLabels";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import ResultAnswer from "src/modules/components/QuestionContent/components/components/ResultAnswer";
import Hotspot from "src/modules/components/QuestionContent/components/Hotspot";
import Audio from "src/modules/components/Audio";
import { renderFieldName } from "../../HistoryHelpers";
import { Link } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { getQuestionKeyByType } from "src/api/helpers/question";
import { RenderTableHisstories } from "../../components/RenderTableHistories";

function QuestionHistory({ historyData, renderValue }) {
    const { question, old_value, new_value, field_name } = historyData;

    const { t } = useTranslation();

    const defaultReturn = () => {
        return (
            <RenderTableHisstories
                component1={
                    <span className="old_value_item">
                        {renderValue(historyData?.old_value, historyData?.field_name)}
                    </span>
                }
                component2={
                    <span className="new_value_item">
                        {renderValue(historyData?.new_value, historyData?.field_name)}
                    </span>
                }
            />
        );
    };

    function renderQuestionAnswerData(type) {
        switch (type) {
            case "multiple_choice":
            case "multiple_choice_sentence_quiz":
                if (["answer"].includes(field_name)) {
                    return (
                        <div className="multiplechoice_answer_history">
                            <table>
                                <tr>
                                    <th>{t("history.old_answer")}</th>
                                    <th>{t("history.new_answer")}</th>
                                </tr>
                                <tr>
                                    <td>
                                        <MultipleChoice
                                            correct_answer={old_value}
                                            showResults={true}
                                            hideWarning={true}
                                            type={type}
                                            is_multiple={type === "multiple_choice_sentence_quiz"}
                                        />
                                    </td>
                                    <td>
                                        <MultipleChoice
                                            correct_answer={new_value}
                                            showResults={true}
                                            hideWarning={true}
                                            type={type}
                                            is_multiple={type === "multiple_choice_sentence_quiz"}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                } else {
                    return defaultReturn();
                }
            case "multiple_choice_boolean":
                if (["answer"].includes(field_name)) {
                    return (
                        <div className="multiplechoice_answer_history">
                            <table>
                                <tr>
                                    <th>{t("history.old_answer")}</th>
                                    <th>{t("history.new_answer")}</th>
                                </tr>
                                <tr>
                                    <td>
                                        <MultipleChoiceBoolean
                                            correct_answer={old_value}
                                            showResults={true}
                                            hideWarning={true}
                                            type={type}
                                            is_multiple={type === "multiple_choice_sentence_quiz"}
                                        />
                                    </td>
                                    <td>
                                        <MultipleChoiceBoolean
                                            correct_answer={new_value}
                                            showResults={true}
                                            hideWarning={true}
                                            type={type}
                                            is_multiple={type === "multiple_choice_sentence_quiz"}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                } else {
                    return defaultReturn();
                }
            case "multiple_choice_table":
                const old_data = {
                    match: question?.match,
                    answer1: question?.answer1,
                    answer2: question?.answer2,
                };
                const new_data = {
                    match: question?.match,
                    answer1: question?.answer1,
                    answer2: question?.answer2,
                };
                if (old_data.hasOwnProperty(field_name)) {
                    old_data[field_name] = old_value;
                    new_data[field_name] = new_value;
                }
                if (["match", "answer1", "answer2"].includes(field_name)) {
                    return (
                        <div className="multiplechoice_answer_history">
                            <table>
                                {/* <tr>
                                    <th>{t("history.old_answer")}</th>
                                    <th>{t("history.new_answer")}</th>
                                </tr> */}
                                <tr>
                                    <td>{t("history.old_answer")}</td>
                                    <td>
                                        <MultipleChoiceTable
                                            correct_answer={old_data.match}
                                            answer1={old_data.answer1}
                                            answer2={old_data.answer2}
                                            showResults={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t("history.new_answer")}</td>
                                    <td>
                                        <MultipleChoiceTable
                                            correct_answer={new_data.match}
                                            answer1={new_data.answer1}
                                            answer2={new_data.answer2}
                                            showResults={true}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    );
                } else {
                    return defaultReturn();
                }
            case "speech_to_text":
                if (["answer"].includes(field_name)) {
                    return (
                        <>
                            <div>{old_value?.[0]}</div>
                            <ArrowRightOutlined />
                            <div>{new_value?.[0]}</div>
                        </>
                    );
                } else {
                    return defaultReturn();
                }
            case "highlight":
                if (["passage"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<Highlight passage={old_value} is_history={true} />}
                            component2={<Highlight passage={new_value} is_history={true} />}
                        />
                    );
                } else {
                    return defaultReturn();
                }
            case "sort":
                if (["answer"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<Sort correct_answer={old_value} is_history={true} />}
                            component2={<Sort correct_answer={new_value} is_history={true} />}
                        />
                    );
                } else {
                    return defaultReturn();
                }

            case "drag_drop_group":
                if (field_name === "answer1") {
                    return (
                        <RenderTableHisstories
                            component1={<RenderList data={old_value} />}
                            component2={<RenderList data={new_value} />}
                        />
                    );
                } else if (field_name === "answer2") {
                    return (
                        <RenderTableHisstories
                            component1={<RenderList data={old_value} field_name={"name"} />}
                            component2={<RenderList data={new_value} field_name={"name"} />}
                        />
                    );
                } else if (field_name === "match") {
                    return (
                        <RenderTableHisstories
                            component1={
                                <DragDropGroup
                                    correct_answer={old_value?.match}
                                    answer2={old_value?.answer2}
                                    is_history={true}
                                />
                            }
                            component2={
                                <DragDropGroup
                                    correct_answer={new_value?.match}
                                    answer2={new_value?.answer2}
                                    is_history={true}
                                />
                            }
                        />
                    );
                } else {
                    return defaultReturn();
                }
            case "drag_drop_group_order_by":
                if (field_name === "answer1") {
                    return (
                        <RenderTableHisstories
                            component1={<RenderList data={old_value} />}
                            component2={<RenderList data={new_value} />}
                        />
                    );
                } else if (field_name === "answer2") {
                    return (
                        <RenderTableHisstories
                            component1={<RenderList data={old_value} field_name={"name"} />}
                            component2={<RenderList data={new_value} field_name={"name"} />}
                        />
                    );
                } else if (field_name === "match") {
                    return (
                        <RenderTableHisstories
                            component1={
                                <DragDropGroup
                                    correct_answer={old_value?.match}
                                    answer2={old_value?.answer2}
                                    is_history={true}
                                />
                            }
                            component2={
                                <DragDropGroup
                                    correct_answer={new_value?.match}
                                    answer2={new_value?.answer2}
                                    is_history={true}
                                />
                            }
                        />
                    );
                } else {
                    return defaultReturn();
                }
            case "drag_drop":
                if (field_name === "answer1") {
                    return (
                        <RenderTableHisstories
                            component1={<RenderList data={old_value} />}
                            component2={<RenderList data={new_value} />}
                        />
                    );
                } else if (field_name === "answer2") {
                    return (
                        <RenderTableHisstories
                            component1={<RenderList data={old_value} field_name={"name"} />}
                            component2={<RenderList data={new_value} field_name={"name"} />}
                        />
                    );
                } else if (field_name === "match") {
                    return (
                        <RenderTableHisstories
                            component1={
                                <DragDropGroup
                                    correct_answer={old_value}
                                    answer2={question?.answer2}
                                    is_history={true}
                                />
                            }
                            component2={
                                <DragDropGroup
                                    correct_answer={new_value}
                                    answer2={question?.answer2}
                                    is_history={true}
                                />
                            }
                        />
                    );
                } else {
                    return defaultReturn();
                }
            case "fill_in_the_blank_text":
            case "fill_in_the_blank":
            case "fill_in_the_blank_drag_drop":
                if (["answer", "answer1"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<RenderFillBlankAnswer correct_answer={old_value} />}
                            component2={<RenderFillBlankAnswer correct_answer={new_value} />}
                        />
                    );
                } else {
                    return defaultReturn();
                }
            case "fill_in_the_blank_image":
            case "fill_in_the_blank_text_image":
            case "fill_in_the_blank_drag_drop_image":
                if (["coordinates"].includes(field_name)) {
                    let labelType = "text";
                    switch (type) {
                        case "fill_in_the_blank_image":
                            labelType = "dropdown";
                            break;
                        case "fill_in_the_blank_text_image":
                            labelType = "text";
                            break;
                        case "fill_in_the_blank_drag_drop_image":
                            labelType = "drag&drop";
                            break;
                    }
                    return (
                        <RenderTableHisstories
                            component1={
                                <ImageWithLabels
                                    labelType={labelType}
                                    coordinates={old_value?.coordinates}
                                    correct_answer={old_value?.answer}
                                    answered={old_value?.answer}
                                    // answer={old_value?.answer}
                                    src={old_value?.file}
                                    width={old_value?.width}
                                    height={old_value?.height}
                                    is_history={true}
                                />
                            }
                            component2={
                                <ImageWithLabels
                                    labelType={labelType}
                                    coordinates={new_value?.coordinates}
                                    answered={new_value?.answer}
                                    correct_answer={new_value?.answer}
                                    src={new_value?.file}
                                    width={new_value?.width}
                                    height={new_value?.height}
                                    is_history={true}
                                />
                            }
                            type={2}
                        />
                    );
                } else if (["answer", "answer1"].includes(field_name)) {
                    const correct_answer1 = field_name === "answer" ? old_value?.answer : old_value;
                    const correct_answer2 = field_name === "answer" ? new_value?.answer : new_value;
                    return (
                        <RenderTableHisstories
                            component1={<RenderFillBlankAnswer correct_answer={correct_answer1} />}
                            component2={<RenderFillBlankAnswer correct_answer={correct_answer2} />}
                        />
                    );
                } else {
                    return defaultReturn();
                }
            case "phonetic_table":
                if (["answer"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<PhoneticTable correct_answer={old_value} />}
                            component2={<PhoneticTable correct_answer={new_value} />}
                        />
                    );
                }
            case "hot_spot":
                if (["answer", "path"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={
                                <Hotspot
                                    width={old_value.width}
                                    height={old_value.height}
                                    src={old_value.file}
                                    paths={old_value.path}
                                    correct_answer={old_value.answer}
                                    isShowCheckAnswers={false}
                                    is_history={true}
                                />
                            }
                            component2={
                                <Hotspot
                                    width={new_value.width}
                                    height={new_value.height}
                                    src={new_value.file}
                                    paths={new_value.path}
                                    correct_answer={new_value.answer}
                                    isShowCheckAnswers={false}
                                    is_history={true}
                                />
                            }
                            type={2}
                        />
                    );
                } else return defaultReturn();
            case "speech_to_text_conversation_text":
                if (["answer"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<RenderSpeechToTextConversation list={old_value} />}
                            component2={<RenderSpeechToTextConversation list={new_value} />}
                        />
                    );
                } else return defaultReturn();
            case "speech_to_text_conversation_audio":
                if (["answer"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<RenderSpeechToTextConversation list={old_value} type="audio" />}
                            component2={<RenderSpeechToTextConversation list={new_value} type="audio" />}
                        />
                    );
                } else return defaultReturn();
            case "passage":
                if (["questions"].includes(field_name)) {
                    return (
                        <RenderTableHisstories
                            component1={<RenderListQuestion data={old_value} field_name={"questions"} />}
                            component2={<RenderListQuestion data={new_value} field_name={"questions"} />}
                        />
                    );
                } else if ("content" === field_name) {
                    return (
                        <RenderTableHisstories
                            component1={<HTMLDisplayer htmlString={old_value} />}
                            component2={<HTMLDisplayer htmlString={new_value} />}
                        />
                    );
                } else return defaultReturn();
            default:
                return defaultReturn();
        }
    }
    return (
        <div className="question-history">{renderQuestionAnswerData(question?.type || question?.question?.type)}</div> //question?.question?.type: câu paragraph trong passage
    );
}

const RenderList = ({ data, field_name }) => {
    const newData = useMemo(() => {
        if (data?.[0] && typeof data?.[0] === "object" && field_name) {
            return data?.map((item) => item?.[field_name]);
        } else if (data?.[0] && typeof data?.[0] === "string") {
            return data;
        } else {
            return [];
        }
    }, [data]);
    return (
        <List
            header={null}
            footer={null}
            bordered
            dataSource={newData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
        />
    );
};

const RenderListQuestion = ({ data }) => {
    const { t } = useTranslation();
    const { generate } = useRouting();

    const newData = useMemo(() => {
        if (data?.[0] && typeof data?.[0] === "object") {
            return data?.map((item, order) => ({ ...item, key: data?._id, order: order + 1 }));
        } else {
            return [];
        }
    }, [data]);

    return (
        <List
            header={null}
            footer={null}
            bordered
            dataSource={newData}
            className="history-list-passage-question"
            renderItem={(item) => (
                <List.Item>
                    <div className="passage-question-item">
                        <div>
                            <span>
                                <Badge count={item?.order}></Badge>
                            </span>
                            <span className="question-title">
                                {t("history.question")} {`${renderFieldName(item?.type, t)}`}
                            </span>
                        </div>
                        <div className="question">
                            <Link
                                style={{ color: "unset" }}
                                target="_blank"
                                to={generate("update_question", {
                                    id: item?._id,
                                    questionKey: getQuestionKeyByType(item?.type),
                                })}
                            >
                                {<HTMLDisplayer key={item?._id} htmlString={item?.question} />}
                            </Link>
                        </div>
                    </div>
                </List.Item>
            )}
        />
    );
};

const RenderFillBlankAnswer = ({ correct_answer }) => {
    const answerConverted = useMemo(() => {
        if (checkIsObject(correct_answer?.[0])) {
            return correct_answer.map((craswr) => craswr.text);
        } else {
            return correct_answer;
        }
    }, [correct_answer]);

    return (
        <div className="q-result q-fill-blanks-input">
            <div className="q-content-section">
                <div className="qc_correct-answers">
                    {(answerConverted || []).map((item, itemIndex) => {
                        return (
                            <div key={`ans-${itemIndex}`} className="correct-answer-item">
                                <ResultAnswer letter={itemIndex + 1}>
                                    <HTMLDisplayer htmlString={item} />
                                </ResultAnswer>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const RenderSpeechToTextConversation = ({ list = [], type = "text" }) => {
    //type: text or audio

    function isMp3Link(url) {
        // Extract the file extension from the URL
        if (!url || typeof url !== "string") return false;
        const extension = url.split(".").pop().toLowerCase();

        return extension === "mp3";
    }

    return (
        <table className="table_conversation">
            {list?.map((item) => {
                return (
                    <tr key={item?.order}>
                        <td>{item?.actor}</td>
                        <td>
                            {type === "audio" && isMp3Link(item?.value) ? <Audio src={item?.value} /> : item?.value}
                        </td>
                    </tr>
                );
            })}
        </table>
    );
};

export default QuestionHistory;
