import { useState, useEffect } from "react";

function fullScreenElement(event) {
    // document.fullscreenElement will point to the element that
    // is in fullscreen mode if there is one. If not, the value
    // of the property is null.
    useEffect(() => {
        // Check if the browser supports the Fullscreen API
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            // Firefox
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            // Chrome, Safari, Opera
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
            // IE/Edge
            document.documentElement.msRequestFullscreen();
        }

        // Return a cleanup function to exit fullscreen when the component unmounts
        return () => {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        };
    }, []);
}

export function getBrowserVisibilityProp() {
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        return "visibilitychange";
    } else if (typeof document["msHidden"] !== "undefined") {
        return "msvisibilitychange";
    } else if (typeof document["webkitHidden"] !== "undefined") {
        return "webkitvisibilitychange";
    }
}

export const getVisibilityEventNames = () => {
    let hidden = null;
    let visibilityChange = null;

    if (document.hidden) {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (document["msHidden"]) {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (document["webkitHidden"]) {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    return [hidden, visibilityChange];
};

function openFullscreen() {
    let elem = document.documentElement;
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
    }
}

function closeFullscreen() {
    if (!document) return;

    if (document.fullscreenElement) {
        if (document?.exitFullscreen) {
            document.exitFullscreen();
        } else if (document?.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
        } else if (document?.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
        }
    }
}

export { fullScreenElement, openFullscreen, closeFullscreen };
