import React from "react";
import { Pie } from "@ant-design/plots";
import { useTranslation } from "react-i18next";
import "./ClassificationChart.scss";

function ClassificationChart(props) {
    const { data = {}, statisticContent = "", isMobile = false, deviceResponsive } = props;

    const propsData = Object.keys(data)?.map((key) => {
        return {
            name: key.split("percent_assignment_")[1],
            value: data[key],
        };
    });

    const { t } = useTranslation();

    // Sample data:
    const propsColor = {
        very_good: "#2DAC81",
        good: "#2FB8E1",
        medium: "#FBD729",
        failed: "#EF8628",
    };

    const propsLabel = {
        very_good: t("report.very_good"),
        good: t("report.good"),
        medium: t("report.ordinary"),
        failed: t("report.failed"),
    };

    // Chart config:
    const config = {
        // Plot Container:
        // appendPadding: isMobile ? 120 : 0,
        padding: "auto",
        // Data Mapping:
        data: propsData,
        angleField: "value",
        colorField: "name",
        height: 750,

        // Geometry Style:
        radius: 1,
        innerRadius: 0.4,
        color: (params) => {
            if (params.name) {
                return propsColor[params.name] || "";
            }
        },
        pieStyle: {
            stroke: "#000",
            strokeOpacity: 0,
            cursor: "pointer",
        },

        tooltip: {
            formatter: ({ name, value }) => ({
                name: propsLabel[name] || name,
                value,
            }),
        },

        // Plot Components:
        label: {
            type: "inner",
            offset: "-50%",
            content: ({ value }) => {
                if (value > 0) {
                    return value;
                }
                return "";
            },
            style: {
                textAlign: "center",
                fontSize: 14,
            },
        },
        legend: {
            layout: "horizontal",
            position: "bottom",
            offsetY: isMobile ? -50 : 0,
            marker: (params) => {
                return {
                    symbol: "square",
                    style: {
                        lineWidth: 1,
                        r: 20,
                    },
                    spacing: 15,
                };
            },
            itemName: {
                formatter: (text, item, index) => {
                    return propsLabel[text] || text;
                },
                style: {
                    fill: "#000",
                    fontSize: 16,
                    fontWeight: "400",
                    lineHeight: 25,
                },
            },
            itemValue: {
                alignRight: true,
                formatter: (text, item, index) => {
                    return propsData[index].value || "0";
                },
                style: {
                    fill: "#000",
                    fontSize: 16,
                    fontWeight: "700",
                    lineHeight: 25,
                },
            },
            flipPage: false,
            itemWidth: 250,
            maxItemWidth: 250,
            maxRow: 4,
            itemHeight: 40,
        },
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                content: statisticContent || "",
            },
        },

        // Plot Interactions:
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
    };

    return (
        <div className={"chart-wrapper donut-plot-with-statistics"}>
            <Pie {...config} />
        </div>
    );
}

export default React.memo(ClassificationChart);
