import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { Checkbox } from "antd";
import "./StudentCard.scss";
import DefaultAvatar from "src/assets/images/no-avatar.png";

const StudentCard = (props) => {
    const {
        checked = false,
        assignmentStatus = {
            isStudentSubmitted: false,
            isTeacherSubmitted: false,
        },
        userAvatar = "",
        userName = "",
        actualScore = 0,
        maxScore = 0,
        progress = "",
        answerInfoList = [],
        handleAddToCheckList = () => {},
        handleClickCard = () => {},
        handleClickToViewDetail = () => {},
    } = props;

    const sampleAnswerInfoList = [
        {},
        { status: "correct" },
        { status: "part_correct" },
        { status: "wrong" },
        { status: "hand_dots" },
    ];

    const [isChecked, setIsChecked] = useState(false);

    const getStatusContent = () => {
        if (assignmentStatus.isTeacherSubmitted === true) {
            return t("report.is_teacher_submitted");
        } else if (assignmentStatus.isStudentSubmitted === true) {
            return t("report.is_student_submitted");
        } else {
            return t("report.is_student_not_started");
        }
    };

    const handleCheck = (e) => {
        setIsChecked(!isChecked);
        handleAddToCheckList(!isChecked);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        handleClickCard();
    };

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    return (
        <div className="student-card">
            <div className="student-card-header">
                <div className="info">
                    <div className="info-target">
                        <span className="avatar-img">
                            <img src={userAvatar || DefaultAvatar} alt="" />
                        </span>
                        <p>
                            <span>{userName || "Unknown"}</span>
                            <span className={assignmentStatus.isStudentSubmitted === true ? "graded" : ""}>
                                {getStatusContent()}
                            </span>
                        </p>
                    </div>
                    <span className="action-list-wrapper">
                        <span className="view-detail-btn" onClick={handleClickToViewDetail}>
                            {t("report.view_detail")}
                        </span>
                        <Checkbox className="app-checkbox small-checkbox" onChange={handleCheck} checked={isChecked} />
                    </span>
                </div>
            </div>

            <div className="student-card-body">
                <div className="progress">
                    <span>{t("statistical.progress")}</span>
                    <span>{Math.round(progress) || 0}%</span>
                </div>

                <p className="score">
                    {actualScore !== undefined ? actualScore : "_ "}/{maxScore !== undefined ? maxScore : "_"}
                </p>

                <div className="statistical-status-bar fixed-row-of-10-blocks">
                    {answerInfoList.map((item, index) => {
                        switch (item?.status) {
                            case "correct":
                                return <span className="block correct" key={index}></span>;
                            case "wrong":
                                return <span className="block incorrect" key={index}></span>;
                            case "part_correct":
                                return <span className="block part" key={index}></span>;
                            case "hand_dots":
                                return <span className="block hand" key={index}></span>;
                            default:
                                return <span className="block no-record" key={index}></span>;
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default StudentCard;
