import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionContentDetail from "../components/ContentDetail";
import "./FillBlanksInput.scss";

function FillBlanksInput(props) {
    const { isReadonly = false, question, defaultAnswer, onChange = () => {} } = props;

    const { t } = useTranslation();
    const [selectedAnswer, setSelectedAnswer] = useState(defaultAnswer || []);

    const handleChangeAnswer = (selectorIndex, inputValue) => {
        const newSelectedAnswer = [...selectedAnswer];
        newSelectedAnswer[selectorIndex] = inputValue;
        setSelectedAnswer(newSelectedAnswer);
        onChange({ answered: newSelectedAnswer });
    };

    return (
        <div className="q-fill-blanks-input">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    blankVisibleType="input"
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                    selectedAnswer={selectedAnswer}
                    handleChangeAnswer={handleChangeAnswer}
                />
            </div>
        </div>
    );
}

export default FillBlanksInput;
