import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import MathAnswer from "src/modules/components/MathAnswer";

import QuestionContentDetail from "../components/ContentDetail";
import "./Matrix.scss";

function Matrix(props) {
    const {
        isReadonly = false,
        id,
        question,
        /**  Type of defaultAnswer
         *   @param {String} defaultAnswer: student's answer
         */
        defaultAnswer,
        template_latex,
        type,
        onChange,
    } = props;

    const { t } = useTranslation();

    const handleChangeAnswer = (value) => {
        if (onChange && onChange instanceof Function) {
            onChange({
                answered: value,
            });
        }
    };

    return (
        <div className="q-matrix">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question || "",
                    }}
                />
            </div>

            <div className="content-matrix">
                <MathAnswer
                    isReadonly={isReadonly}
                    value={defaultAnswer}
                    template_latex={template_latex}
                    onChange={handleChangeAnswer}
                />
            </div>
        </div>
    );
}

export default Matrix;
