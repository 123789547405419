import MathQuill from "mathquill-node";
import React, { useEffect, useRef } from "react";
import "./StaticMath.scss";

const StaticMath = (props, ref) => {
    const { staticMathRef, isReadonly } = props;
    const mqRef = useRef(null);

    useEffect(() => {
        const MQ = MathQuill.getInterface(2);
        staticMathRef.current = MQ.StaticMath(mqRef.current, {
            substituteTextarea: function () {
                return document.createElement("textarea");
            },
        });
    }, []);

    useEffect(() => {
        if (isReadonly) {
            mqRef.current?.classList.add("disabled");
        } else {
            mqRef.current?.classList.remove("disabled");
        }
    }, [isReadonly]);

    return (
        <div className="StaticMath" ref={ref}>
            <div ref={mqRef}></div>
        </div>
    );
};

export default React.memo(React.forwardRef(StaticMath));
