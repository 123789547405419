import moment from "moment";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import useValues from "src/hooks/useValues";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import CalanderIcon from "src/assets/images/calander-icon.svg";
import "./FilterMenu.scss";
import { getActionType } from "../../../HistoryHelpers";

const DATE_FORMAT = "DD/MM/YYYY";

const filterMenu = (props) => {
    const {
        filterParams = [],
        willFormBeCleared = false,
        handleFetchFeedbacks = () => {},
        handleCloseFilterMenu = (newFilterParams) => {},
    } = props;

    const [form] = useForm();
    const [currFilterParams, setCurrFilterParams] = useState([]); // Current applied filter values.
    const [values, setValues] = useValues({
        listUsers: [],
    });

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            type: formData.type,
            begin_date: formData.begin_date,
            end_date: formData.end_date,
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.begin_date &&
            newFilterParams.push({
                name: "begin_date",
                value: fetchParams.begin_date,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.begin_date).format(DATE_FORMAT),
            });
        fetchParams.end_date &&
            newFilterParams.push({
                name: "end_date",
                value: fetchParams.end_date,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.end_date).format(DATE_FORMAT),
            });
        fetchParams.type &&
            newFilterParams.push({
                name: "type",
                value: fetchParams.type,
                labelName: t("shared.type"),
                labelValue: getActionType(fetchParams.type),
            });

        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleCloseFilterMenu(newFilterParams);
    };

    // useEffect(() => {
    //     if (willFormBeCleared) {
    //         form.resetFields();
    //         let newFormData = {};
    //         if (currFilterParams.length) {
    //             newFormData = filterParamsToFormData(currFilterParams);
    //         }
    //         form.setFieldsValue(newFormData);
    //     }
    // }, [willFormBeCleared]);

    const filterParamsToFormData = (filterParams) => {
        const newFormData = {};
        for (let i = 0; i < filterParams.length; i++) {
            if (["begin_date", "end_date"].includes(filterParams[i].name)) {
                newFormData[filterParams[i].name] = moment(filterParams[i].value);
            } else {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
        }
        return newFormData;
    };

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = filterParamsToFormData(filterParams);

            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                begin_date: newFormData.begin_date,
                end_date: newFormData.end_date,
            };
            setCurrFilterParams(filterParams);
            handleFetchFeedbacks({ page: 1, ...fetchParams }, true);
        }
    }, [filterParams]);

    return (
        <Form
            form={form}
            className="form form-full-label filter-menu layout-grid manage-history_filter-menu"
            layout="vertical"
        >
            <Row gutter={[24, 12]}>
                <Col sm={12} md={12} xs={24}>
                    <Form.Item name="begin_date" label={<span>{t("report.dateFrom")}</span>}>
                        <DatePicker
                            placeholder={t("report.please_select_date")}
                            className="filter-person_input"
                            suffixIcon={<img src={CalanderIcon} />}
                        />
                    </Form.Item>
                </Col>
                <Col sm={12} md={12} xs={24}>
                    <Form.Item name="end_date" label={<span>{t("report.dateTo")}</span>}>
                        <DatePicker
                            placeholder={t("report.please_select_date")}
                            className="app-input filter-person_input"
                            suffixIcon={<img src={CalanderIcon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="type" label={t("shared.type")}>
                        <Select className="app-select" placeholder={t("history.choose_action_type")}>
                            <Select.Option key="create" value="create">
                                {getActionType("create")}
                            </Select.Option>
                            <Select.Option key="update" value="update">
                                {getActionType("update")}
                            </Select.Option>
                            <Select.Option key="delete" value="delete">
                                {getActionType("delete")}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("action.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("action.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

export default filterMenu;
