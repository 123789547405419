/**
 * Check if a plan is already purchased and is in used.
 * @param {Object} meDetail Data from /me api call.
 * @param {Object} planDetail Plan info.
 */
export const checkIfPlanIsInUsed = (meDetail, planDetail) => {
    const planInUsed = meDetail?.organization?.plan_id;
    const planMentioned = planDetail?.id;
    return planInUsed === planMentioned;
};
