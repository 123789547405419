import { Menu, notification } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { logOut } from "src/api/containers/auth";
import { ReactComponent as SvgFeedback } from "src/assets/images/nav/feedback.svg";
import configs from "src/configs";
import { useValues } from "src/hooks";
import FeedbackDrawer from "src/modules/components/Feedback/FeedbackDrawer";
import FeedbackModal from "src/modules/components/Feedback/FeedbackModal";
import { logout } from "src/reducers/auth";
import { toggleFeedbackModalVisible } from "src/reducers/general";
import { adminNav, employeeNav, generalNav, studentNav, teacherNav } from "src/routes/navs";
import { useRouting } from "src/utils/router";

import "./NavMenu.scss";
import GenerateSubNav from "./components/GenerateSubNav";
import MenuCustomHorizontal from "./components/MenuCustomHorizontal";

const FEEDBACK_CONFIGS = configs.FEEDBACK;

function NavMenu(props) {
    const { className = "", mode = "vertical", ...rest } = props;

    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { generate } = useRouting();
    const user = useSelector((state) => state.auth.user);
    const lang = useSelector((state) => state.general.lang);
    const [values, setValues] = useValues({ selectedKeys: [], modal: "", isVisible: false });
    const [subnavName, setSubnavName] = useState("");
    const navigate = useNavigate();
    const isFeedbackShown = useRef(false);

    const suitableNav = useMemo(() => {
        if (user.role === "student") {
            return studentNav;
        }

        if (["teacher"].includes(user.role)) {
            return teacherNav;
        }

        return [];
    }, [user]);

    // Modal:
    const handleShowModal = (modalName, modalVisible) => {
        setValues({
            modal: modalName,
            isVisible: modalVisible,
        });
    };
    const handleCancelModal = () => {
        setValues({
            modal: "",
            isVisible: false,
        });
    };

    const handleLogOut = () => {
        logOut().then((res) => {
            if (res?.status) {
                notification.success({
                    message: t("message.logout_success"),
                });
                dispatch(logout());
            }
        });
    };

    useEffect(() => {
        const path = location.pathname.replace(`/${lang}`, "");
        const nav = [...suitableNav, ...generalNav].find((n) => {
            if (n.active instanceof Array) {
                for (let i = 0; i < n.active.length; i++) {
                    const m = n.active[i];
                    if (typeof m.test === "function" && m.test(path)) {
                        return true;
                    } else if (m === path) {
                        return true;
                    }
                }
            }
            return false;
        });
        if (nav) {
            setValues({ selectedKeys: [nav.name] });
            if (nav?.subnav?.length > 0) {
                for (let v of nav.subnav) {
                    if (v?.active?.includes(path)) {
                        setSubnavName(v.name);
                    }
                }
            } else {
                setSubnavName("");
            }
        }

        // random to show feedback modal
        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        let random = getRndInteger(0, FEEDBACK_CONFIGS?.SPACE || 10);

        const flag = Math.round((FEEDBACK_CONFIGS?.SPACE || 10) / 2);

        let condition = random === flag;

        if (!FEEDBACK_CONFIGS?.CAN_SHOW_AFTER_CLOSE) {
            condition = condition && isFeedbackShown.current === false;
        }

        if (condition) {
            dispatch(toggleFeedbackModalVisible(true));
            isFeedbackShown.current = true;
        }
    }, [location.pathname]);

    // useEffect(() => {
    //     if (user?.show_feedback_modal) {
    //         handleShowModal("feedback", true);
    //     }
    // }, [user]);

    const renderNavItemsBy = (navItemList = []) => {
        return navItemList.map((navItem) => {
            const NavIcon = navItem.icon;

            return {
                key: `${navItem.name}`,
                title: t(`nav.${navItem.name}`),
                label:
                    navItem?.subnav?.length > 0 ? (
                        <GenerateSubNav navItem={navItem} subnavName={subnavName} mode={mode} />
                    ) : (
                        <Link to={generate(navItem.routeName, navItem.routeParams || {})}>
                            <span className="nav-menu-item">
                                <span className="nav-icon">
                                    <NavIcon />
                                </span>
                                <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                            </span>
                        </Link>
                    ),
                // disabled: values.selectedKeys.includes(navItem.name) ? true : false,
                // icon: <NavIcon />,
            };
        });
    };

    const renderMainNavItems = () => {
        return renderNavItemsBy(suitableNav);
    };

    const renderGeneralNavItems = () => {
        return renderNavItemsBy(generalNav);
    };

    const renderMenuItems = () => {
        return [
            ...renderMainNavItems(),
            {
                type: "divider",
            },
            ...renderGeneralNavItems(),
            // {
            //     key: `feedback`,
            //     title: t(`nav.feedback`),
            //     label: (
            //         <Link
            //             to={
            //                 ["admin", "employee", "employee_crm", "teacher"].includes(user?.role)
            //                     ? generate("feedback")
            //                     : {}
            //             }
            //             onClick={() => {
            //                 if (["admin", "employee", "employee_crm", "teacher"].includes(user?.role)) {
            //                     // navigate(generate("feedback"));
            //                 } else {
            //                     // handleShowModal("feedback", !values.isVisible);
            //                     dispatch(toggleFeedbackModalVisible(true));
            //                 }
            //             }}
            //         >
            //             <span className="nav-menu-item">
            //                 <span className="nav-icon">
            //                     <SvgFeedback />
            //                 </span>
            //                 <span className="nav-name">{t(`nav.feedback`)}</span>
            //             </span>
            //         </Link>
            //     ),
            // },
        ];
    };

    if (mode === "horizontal") {
        /**
         * LƯU Ý:
         * (NOTE:)
         * Trong trường hợp này, ta không sử dụng prop mode của Menu, vì sẽ xuất hiện lỗi khi nhấn chuột phải liên tiếp trên 2 menu item.
         * (In this case, we don't use the Menu's "mode" prop, because it will cause an error when right-clicking on two menu items consecutively.)
         */
        return (
            // <Menu
            //     // mode={mode}
            //     className={`app-nav-menu${className ? " " + className : ""}`}
            //     selectedKeys={values.selectedKeys}
            //     items={renderMenuItems()}
            //     {...rest}
            // />
            <>
                <MenuCustomHorizontal items={renderMenuItems()} selectedKeys={values.selectedKeys} {...rest} />
            </>
        );
    } else {
        return (
            <>
                <Menu
                    className={`app-nav-menu${className ? " " + className : ""}`}
                    items={renderMenuItems()}
                    selectedKeys={values.selectedKeys}
                    {...rest}
                />
            </>
        );
    }
}

export default NavMenu;
