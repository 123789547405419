import "./HomeStudent.scss";
import StudentFrame from "src/modules/components/StudentFrame";
import ClassImg from "src/assets/images/Rectangle5289.png";
import Button from "src/modules/components/Button";
import Pencil from "src/assets/images/pencil-white.svg";
import ReviewIcon from "src/assets/images/review-icon.svg";
import StudentHeader from "src/modules/components/StudentHeader";
import { Dropdown, Empty, Spin, Tag } from "antd";
import FilterMenu from "./components/FilterMenu";
import InputSearch from "src/modules/components/Input/InputSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssignments, fetchStudentAssignments } from "src/api/containers/assignment";
import { useFetch, useValues } from "src/hooks";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRouting } from "src/utils/router";
import { setCurrentPage } from "src/reducers/general";
import { openFullscreen } from "src/utils/AntiCheating";

const AssignmentStudent = () => {
    const navigate = useNavigate();
    const { generate } = useRouting();
    const dispatch = useDispatch();

    const { courseId } = useParams();
    const initParams = { page: 1, slug: "", perPage: 11, filter: "is_assignment_not_employ: 1" };
    const [loadingAssignment, dataAssignment, paginationAssignment, fetchAssignment, refetchAssignment] = useFetch(
        initParams,
        fetchStudentAssignments
    );

    const isLastPage = useMemo(() => {
        const { current, pageSize, total } = paginationAssignment;
        return current * pageSize >= total;
    }, [paginationAssignment]);

    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        inputSearchAssignment: "",
        filterParams: [],
    });

    const [values, setValues] = useValues({
        listPages: 0,
        listOfAssignments: [],
    });

    const handleRemoveFilterFaram = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleSearch = () => {
        if (filterSettings) {
            fetchAssignment({ page: 1, slug: filterSettings.inputSearchAssignment });
        }
    };

    useEffect(() => {
        if (courseId) {
            fetchAssignment({ course_id: courseId }, true);
        } else fetchAssignment({ course_id: "" }, true);
    }, [courseId]);

    const renderRecordStatus = ({ status }) => {
        switch (status) {
            case "donot":
                return <div className="cardItem_status cardItem_status-danger">{t("assignment.donot")}</div>;
            case "submited":
                return <div className="cardItem_status cardItem_status-success">{t("assignment.submited")}</div>;
            case "marked":
                return <div className="cardItem_status cardItem_status-info">{t("assignment.marked")}</div>;
            case "doing":
                return <div className="cardItem_status cardItem_status-warn">{t("assignment.doing")}</div>;
            case "expired":
                return <div className="cardItem_status cardItem_status-danger">{t("assignment.expired")}</div>;
            case "not_start":
                return <div className="cardItem_status cardItem_status-disable">{t("assignment.not_start")}</div>;
        }
    };

    const handleDoHomeWork = (assignmentId) => {
        navigate(generate("test", { id: assignmentId }));
        openFullscreen();
    };

    const handleClickReview = (recordId) => {
        navigate(generate("score", { id: recordId }));
    };

    const handleLoadMore = () => {
        fetchAssignment(
            {
                page: paginationAssignment.current + 1,
            },
            true
        );
    };

    const renderButtonDoHomeWork = ({ status, id: recordId }, assignmentId) => {
        switch (status) {
            case "doing":
            case "donot":
                return (
                    <Button
                        onClick={() => handleDoHomeWork(assignmentId)}
                        title={t("assignment.do_homework")}
                        icon={Pencil}
                        type="primary"
                        className="responsive"
                        style={{ minWidth: "auto" }}
                    />
                );
            case "submited":
            case "marked":
                return (
                    <Button
                        onClick={() => handleClickReview(recordId)}
                        title={t("assignment.review")}
                        icon={ReviewIcon}
                        type="primary"
                        className="responsive"
                    />
                );
        }
    };

    useEffect(() => {
        dispatch(setCurrentPage("assignment"));
    }, []);

    useEffect(() => {
        if (paginationAssignment.current === 1) {
            setValues({
                ...values,
                listPages: paginationAssignment.current,
                listOfAssignments: dataAssignment,
            });
        } else if (paginationAssignment.current > values.listPages) {
            setValues({
                ...values,
                listPages: paginationAssignment.current,
                listOfAssignments: [...values.listOfAssignments, ...dataAssignment],
            });
        }
    }, [dataAssignment, paginationAssignment]);

    return (
        <div className="homeStudent_wrapper">
            <div className="homeStudent_header">
                <StudentHeader title={t("assignment.listAssignment")}>
                    <div className="homeStudent_controlPanel">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("exam.find_assignment")}
                                onChange={(e) =>
                                    setFilterSettings({
                                        ...filterSettings,
                                        inputSearchAssignment: e.target.value,
                                    })
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="homeStudent_controlPanel-search">
                            <div className="homeStudent_controlPanel_title">{t("assignment.filter")}</div>
                            <Dropdown
                                visible={filterSettings.isFilterMenuVisible}
                                overlay={
                                    <FilterMenu
                                        filterParams={filterSettings.filterParams}
                                        handleFetch={fetchAssignment}
                                        handleCloseFilterMenu={(newFilterParams) =>
                                            setFilterSettings({
                                                ...filterSettings,
                                                isFilterMenuVisible: false,
                                                ...(newFilterParams && { filterParams: newFilterParams }),
                                            })
                                        }
                                        willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottom"
                                onVisibleChange={(val) =>
                                    setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                }
                            >
                                <div className="homeStudent_filter">
                                    {filterSettings.filterParams?.length > 0 ? (
                                        filterSettings.filterParams.map((fKey, i) => {
                                            return (
                                                <Tag
                                                    key={`filter-key${i}`}
                                                    closable
                                                    onClose={(e) => handleRemoveFilterFaram(e, fKey.name)}
                                                >
                                                    {`${fKey.labelName ? fKey.labelName + ": " : ""}${
                                                        fKey.labelValue || ""
                                                    }`}
                                                </Tag>
                                            );
                                        })
                                    ) : (
                                        <div>{t("assignment.filter_option")}</div>
                                    )}
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </StudentHeader>
            </div>
            <StudentFrame title={t("assignment.assignments")}>
                <Spin tips="loading" spinning={loadingAssignment}>
                    <div className="cardList">
                        {values?.listOfAssignments.map((assignment) => {
                            return (
                                <div key={assignment.id} className="cardItem">
                                    <div className="cardItem_inner">
                                        <div className="cardItem_thumbNail">
                                            <img src={ClassImg} />
                                        </div>
                                        <div className="cardItem_info">
                                            <h3>{assignment?.name || t("assignment.unknown")}</h3>
                                            {
                                                <p>
                                                    {assignment?.course?.is_class
                                                        ? t("assignment.class")
                                                        : t("assignment.course")}
                                                    : {assignment?.course?.name || t("assignment.unknown")}
                                                </p>
                                            }

                                            {/* <p>
                                                {t("assignment.class")}:{" "}
                                                {assignment?.class?.name || t("assignment.unknown")}
                                            </p> */}
                                            {assignment?.begin_date && (
                                                <p>
                                                    {t("assignment.start_from")}:{" "}
                                                    {moment(assignment?.begin_date).format("DD/MM/YYYY - H:mm")}{" "}
                                                </p>
                                            )}
                                            <p>
                                                {t("assignment.deadline")}:{" "}
                                                {assignment?.end_date
                                                    ? moment(assignment?.end_date).format("DD/MM/YYYY - H:mm")
                                                    : t("assignment.time_not_limited")}
                                            </p>
                                            <div>
                                                {t("assignment.status")}
                                                {renderRecordStatus(assignment?.record)}
                                            </div>
                                            <div style={{ margin: "15px 10px 0 10px" }}>
                                                {renderButtonDoHomeWork(assignment?.record, assignment?.id)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {!values.listOfAssignments.length && (
                            <div style={{ width: "100%" }}>
                                <Empty description={t("message.empty_data")} />
                            </div>
                        )}
                        {Boolean(values?.listOfAssignments.length && !isLastPage && !loadingAssignment) && (
                            <div className="cardItem loadmore_btn" onClick={handleLoadMore}>
                                <div className="cardItem_inner">{t("assignment.load_more")}</div>
                            </div>
                        )}
                    </div>
                </Spin>
            </StudentFrame>
        </div>
    );
};

export default AssignmentStudent;
