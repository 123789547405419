import Button from "../Button";
import {
    Group8879,
    Group8883,
    Group8880,
    Group8881,
    Group8882,
    Group8894,
    Group8885,
    Group8886,
} from "../../icons/tab5";
import { Group8854, Group8887, _3171, _2751, Group8895, Group8896, _9296, Path3477 } from "../../icons/tab5";
import { Group8888, Group8889, Group8890, Group8891, Group8892, Group8893, gt } from "../../icons/tab5";
import { _9297, _9298 } from "../../icons/tab5";

const Tab5 = ({ onClick }) => {
    let datas = [
        { icon: Group8879, text: "\\leftarrow" },
        { icon: Group8883, text: "\\longleftrightarrow" },
        { icon: Group8880, text: "\\rightarrow" },
        { icon: Group8881, text: "\\uparrow" },
        { icon: Group8882, text: "\\downarrow" },
        { icon: Group8894, text: "\\neg", className: "rotate90" },
        { icon: Group8885, text: "\\lceil" },
        { icon: Group8886, text: "\\rceil" },

        { icon: Group8854, text: "\\rightleftharpoons" },
        { icon: Group8887, text: "\\Rightarrow" },
        { icon: _3171, text: "\\vdash", className: "rotate90" },
        { icon: _2751, text: "\\equiv" },
        { icon: Group8895, text: "\\nleftrightarrow" },
        { icon: Group8896, text: "\\nrightarrow" },
        { icon: _9296, text: "^{\\circ}" },
        { icon: Path3477, text: "\\oplus" },

        { icon: Group8888, text: "\\vdots" },
        { icon: Group8889, text: "\\ldots" },
        { icon: Group8890, text: "\\ddots" },
        { icon: Group8891, text: "\\therefore" },
        { icon: Group8892, text: "\\because" },
        { icon: Group8893, text: ":" },
        { icon: gt, text: "\\vee", className: "rotate90" },
        { icon: gt, text: "\\wedge", className: "rotate270" },

        { icon: _9297, text: "\\barwedge" },
        { icon: _9298, text: "\\veebar" },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab5;
