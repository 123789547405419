import moment from "moment";

import api from "..";

/**
 *
 * @param {string} assignmentId Assignment id.
 * @param {Object} fetchParams Fetch params.
 * @returns The list of "students who attended the assignment" & "answer infos" for each of them.
 */
export const getStudentDataListForAssignment = (assignmentId, fetchParams = {}) => {
    // Fetch params:
    const params = {
        course_id: fetchParams?.course_id,
    };

    // Fetch:
    return api
        .get(`/statistic/list_student_for_assignment/${assignmentId}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @param {object} fetchParams { recordIds: array of strings }
 * @returns The list of answers of assignment & all student's answers.
 */
export const getAnswerDataListForAssignment = (assignmentId, fetchParams = {}) => {
    const record_ids = fetchParams?.recordIds || [];

    // Fetch params:
    const params = {
        ...(record_ids.length > 0 ? { record_ids: record_ids.join(",") } : {}),
        course_id: fetchParams?.course_id,
    };

    // Fetch:
    return api
        .get(`/statistic/list_report_record/${assignmentId}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The statistic overview of all students of the course who attended the assignment.
 */
export const getAssignmentOverview = (assignmentId, fetchParams = {}) => {
    // Fetch params:
    const params = {
        course_id: fetchParams?.course_id,
    };

    // Fetch:
    return api
        .get(`/statistic/student_submit/${assignmentId}`, params)
        .then(({ status, ...rest }) => ({ status, ...rest }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The list of assignment's question records: record ids & question numbers.
 */
export const getAssignmentQuestionRecordList = (assignmentId) => {
    const filters = [];
    const params = {
        _filter: filters.join(";") || undefined,
    };

    return api
        .get(`/statistic/list_question_assignment/${assignmentId}`, params)
        .then(({ status, ...rest }) => ({ status, ...rest }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @param {string} assignmentId Assignment id.
 * @returns The list of assignment's question records for each question.
 */
export const getAssignmentQuestionRecordDetailList = (assignmentId, fetchParams = {}) => {
    const question_ids = fetchParams?.recordIds || [];

    // Fetch params:
    const params = {
        ...(question_ids.length > 0 ? { question_id: question_ids.join(",") } : {}),
        course_id: fetchParams?.course_id,
    };

    // Fetch:
    return api
        .get(`/statistic/list_question_record/${assignmentId}`, params)
        .then(({ status, ...rest }) => ({ status, ...rest }))
        .catch(({ message }) => ({ status: false, message }));
};

/**
 *
 * @returns The list record of student
 */
export const getPersonalProgress = (fetchParams = {}) => {
    if (fetchParams.student_id) {
        const params = {
            ...(fetchParams.course_id && { course_id: fetchParams.course_id }),
            ...(fetchParams.level_id && { level_id: fetchParams.level_id }),
            ...(fetchParams.dateTo && { begin_date: moment(fetchParams.dateFrom).format("YYYY-MM-DD") }),
            ...(fetchParams.dateFrom && { end_date: moment(fetchParams.dateTo).format("YYYY-MM-DD") }),
            is_unit: fetchParams.is_unit || false,
        };

        return api
            .get(`/statistic/personal_progress/${fetchParams.student_id}`, params)
            .then(({ status, data, message }) => ({ status, data, message }))
            .catch((message) => ({ status: false, message }));
    } else {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Student id not found", data: [] });
        });
    }
};

/**
 *
 * @returns The list statistic record of student
 */
export const getGeneralProgress = (fetchParams = {}) => {
    const params = {
        assignment_id: fetchParams.assignment_id,
        ...(fetchParams.course_id !== "all" && { course_id: fetchParams.course_id }),
        ...(fetchParams.level_id && { level_id: fetchParams.level_id }),
        ...(fetchParams.dateFrom && { begin_date: moment(fetchParams.dateFrom).format("YYYY-MM-DD") }),
        ...(fetchParams.dateTo && { end_date: moment(fetchParams.dateTo).format("YYYY-MM-DD") }),
        is_unit: fetchParams.is_unit || false,
    };

    return api
        .get(`/statistic/general_progress`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false, message }));
};

export const getDashboadData = () => {
    return api
        .get(`/statistic/dashboard`)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false, message }));
};
