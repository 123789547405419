import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, InputNumber, notification, Row, Spin, Typography } from "antd";
import Editor from "src/modules/components/Editor";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import { useValues } from "src/hooks";
import ZwibblerHotspotEditor from "src/modules/components/ZwibblerHotspotEditor";
import loadable from "@loadable/component";
import HotspotAnswer from "./components/HotspotAnswer";
import { FileImageOutlined, UndoOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import configEditor from "src/utils/configEditor";
import "./DetailHotspot.scss";

const editorConfigQuestion = configEditor.question();

const ZwibblerModule = loadable.lib(() => import("src/assets/script/zwibbler"));

const DetailHotspot = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;
    const { t } = useTranslation();

    const [values, setValues] = useValues({
        loading: false,
        optionListLevel: [],
        optionListTag: [],
        ownerID: "",
        isPublish: false,
        paths: "",
        answer: [],
        defaultCorrectAnswer: [],
        isEditing: questionId ? true : false,
        isFinishCallApiEdit: false,
    });

    const [correctAnswer, setCorrectAnswer] = useState([]);

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });
    const [dataImage, setDataImage] = useValues(undefined); // Values: { id, src, currentWidth, currentHeight }.

    const handleChangePaths = (paths, answer) => {
        setValues({
            paths: paths,
            answer: answer || [],
        });
    };

    const buttonRefs = useRef();

    const handleChangeImage = (_image) => {
        setDataImage(_image);
    };

    const generateAnswerForBackend = (answer) => {
        // const answer = newAnsw
        const result = values.answer.map((item) => {
            return {
                text: `key-${item}`,
                result: answer?.includes(item),
            };
        });
        return result;
    };

    const handleUploadImage = (callback) => {
        callback();
    };
    const handleResetImage = (callback) => {
        callback();
    };
    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            const _questionData = {
                type: "hot_spot",
                is_multiple: _formData.is_multiple,
                path: values.paths,
                answer: generateAnswerForBackend(correctAnswer),
                // answer1: [...lblAnswersCorrect, ...lblAnswersIncorrect],
            };

            if (dataImage) {
                _questionData.file_id = dataImage.id;
                _questionData.width = dataImage.currentWidth;
                _questionData.height = dataImage.currentHeight;
                _questionData.src = dataImage.src;
            }

            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: "",
                // Special fields:
                type: _questionData.type,
                file: { src: dataImage?.src },
                width: _questionData.width,
                height: _questionData.height,
                paths: values.paths,
                is_multiple: _formData.is_multiple,
                mode: "preview",
                // answerd: correctAnswer, // answer của HS
                correct_answer: correctAnswer, // đáp án
            };
            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            if (correctAnswer?.length <= 0) {
                                notification.warning({ message: t("hotspot.missing_correct_answer") });
                                reject();
                                return;
                            }
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                            return;
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData?.width) {
            setDataImage({
                currentWidth: formStates.formQuestionData?.width,
                currentHeight: formStates.formQuestionData?.height,
                src: formStates.formQuestionData?.src,
            });
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            // Set form data:
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // is_multiple: qData?.is_multiple,
                                correct_answer: qData?.answer,
                                file_id: qData?.file_id,
                                // Special fields: "file_id", "src", "width", "height", "path".
                            };
                            setDataImage({
                                id: qData?.file_id,
                                // src: qData?.src, // Will be deleted later!
                                src: qData?.file?.src,
                                currentWidth: qData?.width,
                                currentHeight: qData?.height,
                            });
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                            setValues({
                                paths: qData?.path,
                                isFinishCallApiEdit: true,
                            });

                            const corrAnswer = qData?.answer;
                            if (corrAnswer) {
                                //delete 'key-' of 'key-abcdxy' => 'abcdxy
                                const result = corrAnswer
                                    .map((item) => item.result && item.text.substring(4))
                                    .filter((item) => item);
                                setValues({
                                    defaultCorrectAnswer: result,
                                });
                            }
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res?.[1]?.data;
                            const questionFormData = {
                                question: qData.question || "",
                                score: qData.score || undefined,
                                correct_answer: qData.answer,
                                file_id: qData?.file_id,
                                // Special fields: "file_id", "src", "width", "height", "path".
                            };
                            setDataImage({
                                id: qData?.file_id,
                                // src: qData?.src, // Will be deleted later!
                                src: qData?.file?.src,
                                currentWidth: qData?.width,
                                currentHeight: qData?.height,
                            });
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });

                            setValues({
                                paths: qData?.path,
                                isFinishCallApiEdit: true,
                            });

                            const corrAnswer = qData.answer;
                            if (corrAnswer) {
                                //delete 'key-' of 'key-abcdxy' => 'abcdxy
                                const result = corrAnswer
                                    .map((item) => item.result && item.text.substring(4))
                                    .filter((item) => item);
                                setValues({
                                    defaultCorrectAnswer: result,
                                });
                            }
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData, { dataImage });
        }
    }, [isFormFilled, detailData]);

    return (
        <>
            {/* lazzy load module */}
            <ZwibblerModule />
            <div className="question-detail-hotspot">
                <Spin spinning={values.loading}>
                    <div className="question-detail writing">
                        <div className="question-detail-content">
                            <Row className="question-editor">
                                <Typography className="question-editor-title">{t("question.question")}</Typography>
                                <Form.Item
                                    name="question"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                >
                                    <Editor config={editorConfigQuestion}></Editor>
                                </Form.Item>
                            </Row>
                            {/* <Row className="question-image-drag-drop">
                                <Col span={24}>
                                    <ImageWithLabels imageData={dataImage} onChangeImage={handleChangeImage} />
                                    <br />
                                </Col>
                            </Row> */}

                            {/* -------Drawing Areas------ */}
                            <div className="hotspot-areas image-with-labels-wrapper">
                                <div className="hotspot-areas-header">
                                    <h3>{t("hotspot.drawing_areas")}</h3>

                                    <div className="hotspot-image-botton">
                                        {/* <button
                                            className="btn btn-ghost"
                                            onClick={() => buttonRefs.current.handleUpload()}
                                        >
                                            <FileImageOutlined />
                                            {t("shared.select_image")}
                                        </button>
                                        <button
                                            className="btn btn-ghost"
                                            onClick={() => buttonRefs.current.handleReset()}
                                        >
                                            <UndoOutlined />
                                            {t("shared.reset")}
                                        </button> */}
                                        <CustomButton
                                            type="primary"
                                            title={t("shared.upate_image")}
                                            icon={<FileImageOutlined />}
                                            onClick={() => buttonRefs.current.handleUpload()}
                                        ></CustomButton>
                                        <CustomButton
                                            type="ghost"
                                            title={t("shared.reset")}
                                            icon={<UndoOutlined />}
                                            onClick={() => buttonRefs.current.handleReset()}
                                        ></CustomButton>
                                    </div>
                                </div>
                                <div className="image-with-labels hotspot-areas_editor">
                                    <ZwibblerHotspotEditor
                                        // width={dataImage?.currentWidth}
                                        // height={dataImage?.currentHeight}
                                        // src={dataImage?.src}
                                        path={values.paths}
                                        handleChangePaths={handleChangePaths}
                                        isEditing={values.isEditing}
                                        isFinishCallApiEdit={values.isFinishCallApiEdit}
                                        imageData={dataImage}
                                        onChangeImage={handleChangeImage}
                                        ref={buttonRefs}
                                    />
                                </div>
                            </div>
                            {/* Set correct answer */}
                            <div className="hotspot-correct-answer image-with-labels-wrapper">
                                <h3>{t("hotspot.set_correct_answer")}</h3>

                                <div className="hotspot-correct-answer_editor image-with-labels">
                                    <HotspotAnswer
                                        paths={values.paths}
                                        width={dataImage?.currentWidth}
                                        height={dataImage?.currentHeight}
                                        src={dataImage?.src}
                                        correctAnswer={correctAnswer}
                                        defaultCorrectAnswer={values.defaultCorrectAnswer}
                                        setCorrectAnswer={setCorrectAnswer}
                                        mode={"editor"}
                                    />
                                </div>
                            </div>

                            {/* ---------Set score-------- */}
                            <div className="hotspot-correct-answer_widget">
                                <br />
                                <Row
                                    className="question-setting set-input-numbers"
                                    style={{ justifyContent: "space-between" }}
                                >
                                    <Col>
                                        <Form.Item
                                            name="score"
                                            label={t("question.score")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("message.required"),
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                min={0}
                                                onKeyDown={(e) => {
                                                    if (e.code === "Enter") {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className="app-input"
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col>
                                                <Form.Item name="is_multiple">
                                                    <Checkbox
                                                        className="app-checkbox"
                                                        onChange={handleAllowMultipleAnswers}
                                                        defaultChecked={isMultipleAnswer}
                                                    >
                                                        {t("create_multiple_choice.allow_multi_answers")}
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col> */}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default DetailHotspot;
