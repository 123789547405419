import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { fetchLevels } from "src/api/containers/level";
import { fetchTagsWithFiltering } from "src/api/containers/tag";
import useValues from "src/hooks/useValues";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./FilterMenu.scss";

const filterMenu = (props) => {
    const {
        filterParams = [],
        willFormBeCleared = false,
        handleFetchGuideList = () => {},
        handleCloseFilterMenu = () => {},
    } = props;

    const [form] = useForm();

    const [currFilterParams, setCurrFilterParams] = useState([]); // Current applied filter values.
    const [selectedType, setSelectedType] = useState();
    const [roles, setRoles] = useState([]);

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            type: formData.type,
            sort: formData.sort,
        };
        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.type &&
            newFilterParams.push({
                name: "type",
                value: fetchParams.type,
                labelName: t("guide.type"),
                labelValue: fetchParams.type,
            });
        fetchParams.sort &&
            newFilterParams.push({
                name: "sort",
                value: fetchParams.sort,
                labelName: t("guide.sort"),
                labelValue: t(`guide.${fetchParams.sort}`),
            });

        setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleFetchGuideList({ page: 1, ...fetchParams });
        handleCloseFilterMenu(newFilterParams);
    };

    const handleChangeSelectedType = (val) => {
        setSelectedType(val);
    };

    const handleSelectRoles = (role) => {
        let newR = role.map((r) => ({ label: t(`guide.${r}`), value: r }));
        setRoles(newR);
    };

    useEffect(() => {
        if (willFormBeCleared) {
            form.resetFields();
            const newFormData = {};
            if (currFilterParams.length) {
                for (let i = 0; i < currFilterParams.length; i++) {
                    newFormData[currFilterParams[i].name] = currFilterParams[i].value;
                }
            }
            form.setFieldsValue(newFormData);
        }
    }, [willFormBeCleared]);

    useEffect(() => {
        if (filterParams.length !== currFilterParams.length) {
            const newFormData = {};
            for (let i = 0; i < filterParams.length; i++) {
                newFormData[filterParams[i].name] = filterParams[i].value;
            }
            // Update form:
            form.resetFields();
            form.setFieldsValue(newFormData);
            // Refetch data:
            const fetchParams = {
                type: newFormData.type,
                order: newFormData.order,
            };
            setCurrFilterParams(filterParams);
            handleFetchGuideList({ page: 1, ...fetchParams });
        }
    }, [filterParams]);

    const roleOptions = [
        { label: t("guide.student"), value: "student" },
        { label: t("guide.teacher"), value: "teacher" },
        { label: t("guide.employee"), value: "employee" },
    ];

    return (
        <Form form={form} className="form form-full-label filter-menu layout-grid" layout="vertical">
            <Row gutter={[24, 12]}>
                <Col xs={24} sm={12}>
                    <Form.Item name="type" label={t("guide.type")}>
                        <Select
                            className="app-select"
                            placeholder={t("guide.select_type")}
                            allowClear
                            onChange={handleChangeSelectedType}
                        >
                            {guideTypes?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {t(`guide.${item.id}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item name="sort" label={t("guide.sort")}>
                        <Select className="app-select" placeholder={t("guide.sort")} allowClear>
                            <Select.Option value="asc">{t("guide.asc")}</Select.Option>
                            <Select.Option value="desc">{t("guide.desc")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="roles" label={t("guide.user")}>
                        <Select
                            mode="multiple"
                            onChange={(r) => handleSelectRoles(r)}
                            value={roles}
                            placeholder={t("guide.select_user_role")}
                        >
                            {roleOptions.map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("action.cancel")}
                    icon={<Icon name="icon-cross-thick" />}
                    onClick={() => handleCloseFilterMenu(false)}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("action.apply")}
                    icon={<Icon name="icon-tick" />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

const guideTypes = [
    { id: "course" },
    { id: "question_bank" },
    { id: "my_question_resource" },
    { id: "my_exam_resource" },
    { id: "multiplechoice_question" },
    { id: "fill_blank_question" },
    { id: "writing_question" },
    { id: "passage_question" },
    { id: "sort_question" },
    { id: "drag_drop_question" },
    { id: "learning_progress" },
    { id: "my_storage" },
    { id: "guide" },
    { id: "profile" },
    { id: "exam_bank" },
    { id: "assignment" },
    { id: "score" },
    { id: "class" },
    { id: "gradebook" },
];

export default filterMenu;
