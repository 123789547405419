import React, { useEffect, useState, forwardRef } from "react";
import { Col, Form, Input, InputNumber, notification, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Editor from "src/modules/components/Editor";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import "./SpeechToText.scss";
import { fetchSpeaker } from "src/api/containers/language";
import { useValues } from "src/hooks";
import { useSearchParams } from "react-router-dom";
import configEditor from "src/utils/configEditor";
const { TextArea } = Input;
import chineseToPinyin from "chinese-to-pinyin";
import { toRomaji } from "wanakana";
import { convert as hangulRomanization } from "hangul-romanization";

const editorConfigQuestion = configEditor.question();

const listSpecialLangs = ["CN", "TH", "KR", "JP"];

const DetailSpeechToText = (props, languageRef) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const { t } = useTranslation();
    const [detailData, setDetailData] = useValues({
        questionData: undefined,
        questionFormData: undefined,
        listSpeaker: [],
        lang: "",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    let langKey = searchParams.get("lang"); // câu passage có speech to text sẽ cần có params này
    let langId = searchParams.get("language_id"); // câu passage có speech to text sẽ cần có params này

    const converToLatin = (text, lang) => {
        switch (lang) {
            case "CN":
                return chineseToPinyin(text);
            case "JP":
                return toRomaji(text);
            case "KR":
                return hangulRomanization(text);
        }
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            const answData = Array.isArray(_formData.answer) ? _formData.answer : [_formData.answer];
            const _questionData = {
                type: "speech_to_text",
                speaker: detailData.listSpeaker?.length > 1 ? _formData.speaker : detailData.listSpeaker?.[0],
                is_sentence: _formData.is_sentence,
                answer: answData,
                spelling: listSpecialLangs.includes(detailData.lang)
                    ? converToLatin(answData?.[0], detailData.lang)
                    : "",
                language_id: langId,
            };
            const _questionDataPreview = {
                // Basic fields:
                is_sentence: _formData.is_sentence,
                question: _formData.question,
                answer: answData,
                // speaker: [_formData.speaker],
                speaker: detailData.listSpeaker?.length > 1 ? [_formData.speaker] : detailData.listSpeaker,
                language: detailData.questionData?.language || { code: detailData.lang },
                type: _questionData.type,
                spelling: _questionData.spelling,
                isPreview: true,
            };

            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const handleChangeAnswer = (value) => {
        const _formData = form.getFieldsValue();
        if (_formData.is_sentence === false) {
            const ans = _formData.answer;
            _formData.answer = [ans?.split(" ")?.[0]];
            form.setFieldsValue(_formData);
        }
    };

    const getSpeakerOptions = (speaker) => {
        if (Array.isArray(speaker) && speaker.length > 1) {
            return speaker.map((item) => ({ label: item?.[1], value: item?.[0] }));
        }
        return speaker;
    };

    languageRef.current = (lang) => {
        form.setFieldsValue({
            speaker: "",
        });
        fetchSpeaker({ code: lang }).then(({ status, data }) => {
            const speakers = getSpeakerOptions(data);
            if (status) {
                setDetailData({
                    listSpeaker: speakers,
                    lang: lang,
                });
            }
        });
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "speaker","is_sentence".
                                speaker: qData?.speaker,
                                is_sentence: qData?.is_sentence,
                                answer: qData?.answer?.[0] || "",
                            };

                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                                lang: qData?.language?.code,
                            });

                            fetchSpeaker({ code: qData?.language?.code }).then(({ status, data }) => {
                                if (status) {
                                    setDetailData({
                                        listSpeaker: getSpeakerOptions(data),
                                    });
                                }
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([
                        findPassageQuestion(questionParentId),
                        findChildQuestion(questionId, ["language"]),
                    ]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const questionFormData = {
                                question: res[1].data?.question || "",
                                score: res[1].data?.score || 0,
                                // Special fields: "speaker".
                                speaker: res[1].data?.speaker || "",
                                is_sentence: res[1].data?.is_sentence,
                                answer: res[1]?.data?.answer?.[0] || "",
                            };

                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                                code: res[1]?.data?.language?.code,
                            });

                            form.setFieldsValue({
                                speaker: questionFormData.speaker,
                            });

                            fetchSpeaker({ code: res[1]?.data?.language?.code }).then(({ status, data }) => {
                                if (status) {
                                    setDetailData({
                                        listSpeaker: getSpeakerOptions(data),
                                    });
                                }
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    useEffect(() => {
        // console.log({ lang: detailData.lang });
        // if (langKey) {
        //     fetchSpeaker({ code: langKey }).then(({ status, data }) => {
        //         if (status) {
        //             setDetailData({
        //                 listSpeaker: getSpeakerOptions(data),
        //                 lang: langKey,
        //             });
        //         }
        //         console.count("fetchSpeaker2");
        //     });
        // }
    }, [searchParams]);

    return (
        <div className="detail_speech_to_text">
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion}></Editor>
                </Form.Item>
            </Row>

            <Row gutter={[16, 16]} className="question-formitems top-bar-setting">
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}

                        // labelCol={{ xs: 3, sm: 4, lg: 4 }}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    {detailData.listSpeaker && detailData.listSpeaker?.length > 1 && (
                        <Form.Item
                            name="speaker"
                            label={t("speech_to_text.speaker")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                            className="speech_to_text_select"

                            // labelCol={{ xs: 3, sm: 4, lg: 7, xl: 7, xxl: 4 }}
                        >
                            <Select
                                placeholder={t("speech_to_text.select_speaker")}
                                options={detailData.listSpeaker}
                                className="app-select"
                                showSearch
                                allowClear
                            ></Select>
                        </Form.Item>
                    )}
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                        name="is_sentence"
                        label={t("shared.type")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                        initialValue={true}
                        className="speech_to_text_select"
                        // labelCol={{ xs: 3, sm: 4, lg: 4 }}
                    >
                        <Select
                            options={[
                                { label: t("speech_to_text.sentence"), value: true },
                                { label: t("speech_to_text.word"), value: false },
                            ]}
                            // style={{ minWidth: 200 }}
                            className="app-select"
                            onChange={() => form.setFieldsValue({ answer: "" })}
                        ></Select>
                    </Form.Item>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Typography.Title level={5}>{t("question.answer")}</Typography.Title>
                    <Form.Item name={"answer"}>
                        <TextArea
                            className="app-input"
                            onChange={(e) => handleChangeAnswer(e.target.value)}
                            placeholder={t("question_panel.write_here")}
                            autoSize={{ minRows: 4, maxRows: 10 }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default forwardRef(DetailSpeechToText);
