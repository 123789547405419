import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Space, Row, Col, Tooltip } from "antd";
import chineseToPinyin from "chinese-to-pinyin";
import clsx from "clsx";
import { diffChars, diffWords } from "diff";
import { useTranslation } from "react-i18next";
import RecordButton from "src/modules/components/RecordButton";
import "./UserAnswer.scss";
import useTextCompare from "../../../../../../hooks/useTextCompare/useTextCompare";
import React, { useMemo } from "react";

const listSpecialLangs = ["CN", "TH", "KR", "JP"];

const listSpecialCharacters = [
    "~",
    "!",
    "&",
    "(",
    ")",
    ":",
    "[",
    "]",
    "{",
    "}",
    "|",
    ";",
    ",",
    ".",
    "?",
    "¿",
    "¡",
    "'",
    "`",
    "’",
    "。",
    "，",
    "、",
    "「",
    "『",
    "』",
    "」",
    "？",
    "》",
    "《",
    "……",
    "·",
    "：",
    "”",
    "“",
];

const UserAnswer = (props) => {
    const {
        actor,
        sentence,
        handleClickRecord,
        listening,
        isCorrect,
        hadSpeech,
        isShowPinyin,
        isActive,
        userAnswer,
        currUserAnswer = "",
        lang,
    } = props;

    const { t } = useTranslation();

    const { compareText } = useTextCompare({ lang: lang });

    const { sentence1, sentence2 } = useMemo(() => {
        let sentence1 = "";
        let sentence2 = "";
        if (isActive) {
            // sentence1 = compareText(sentence, currUserAnswer, "doing");
            if (listening) {
                sentence2 = compareText(currUserAnswer, sentence, "doing", "speech");
            } else {
                sentence2 = compareText(userAnswer, sentence, "preview");
            }
        } else {
            // sentence1 = compareText(sentence, userAnswer, "preview");
            sentence2 = hadSpeech ? compareText(userAnswer, sentence, "preview") : "";
        }

        return { sentence1, sentence2 };
    }, [listening, currUserAnswer, userAnswer]);

    return (
        <div className={clsx({ "sptt-conversation_user-answer": true, active: isActive })}>
            {/* <Space className="user-answer_inner"> */}
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={4} className="label-col">
                    <span className="label">{actor}:</span>
                </Col>
                <Col xs={24} sm={10} style={{ padding: "0 12px" }}>
                    <div className="user-answer_wrapper">
                        {/* <Space> */}
                        <div className="user-answer_inner">
                            <span className="transcript">
                                {isShowPinyin && (
                                    <p className="pinyin-text">{chineseToPinyin(sentence, { keepRest: true })}</p>
                                )}

                                <p>{sentence}</p>
                                {/* {sentence1} */}
                                {(currUserAnswer || userAnswer) && (
                                    <span className="user-answer-mobile">
                                        {sentence2}
                                        <Tooltip title={t("question.your_answer")}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </span>
                                )}

                                {/* {compareText(sentence, userAnswer, "doing")} */}
                                {/* {!readOnly && !hideMicro && <RecordButton onClick={handleClickRecord} isTalking={listening} />} */}
                                {/* {hadSpeech && !isCorrect && !isActive && (
                                    <span className="correct_sentence">
                                        <Tooltip title={`${t("question.answer")}: ${sentence}`}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </span>
                                )} */}
                            </span>
                        </div>
                        <Space style={{ justifyContent: "flex-end" }}>
                            {isActive && (
                                <RecordButton
                                    tooltip={t(
                                        clsx({
                                            ["speech_to_text_conversation.click_to_speech"]: !hadSpeech && !listening,
                                            ["speech_to_text_conversation.click_to_stop_speech"]: listening,
                                            ["speech_to_text_conversation.click_to_speech_again"]:
                                                hadSpeech && !isCorrect && !listening,
                                        })
                                    )}
                                    onClick={handleClickRecord}
                                    isTalking={listening}
                                    isSmallSize={true}
                                />
                            )}
                            {hadSpeech &&
                                !listening &&
                                (isCorrect ? (
                                    <Tooltip title={t("assignment.correct")}>
                                        <CheckCircleOutlined style={{ color: "#445cfe", fontSize: 20, height: 20 }} />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t("assignment.incorrect")}>
                                        <CloseCircleOutlined style={{ color: "red", fontSize: 20, height: 20 }} />
                                    </Tooltip>
                                ))}
                        </Space>
                        {/* </Space> */}
                    </div>
                </Col>
                <Col xs={24} sm={10}>
                    {(currUserAnswer || userAnswer) && (
                        <div className="user-answer-mobile-up">
                            {/* {generateStudentAnswer("userAnswer", sentence, listening ? currUserAnswer : userAnswer)} */}
                            {/* {compareText(listening ? currUserAnswer : userAnswer, sentence)} */}
                            {sentence2}

                            <Tooltip title={t("question.your_answer")}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    )}
                </Col>
            </Row>
            {/* </Space> */}
        </div>
    );
};

export default UserAnswer;
