import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRouting } from "src/utils/router";
import { Button, Checkbox, Col, Dropdown, Menu, Modal, notification, Row, Select, Space, Spin } from "antd";
import { PlusCircleOutlined, EllipsisOutlined, HistoryOutlined, SearchOutlined } from "@ant-design/icons";
import { t } from "i18next";
import CoursePanel from "src/modules/components/CoursePanels/Course";
import ModalDetailCourse from "../ModalDetailCourse";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { default as CustomButton } from "../Button";
import Icon from "src/modules/components/Icon";
import { deleteCourse } from "src/api/containers/course";
import { useNavigate } from "react-router-dom";
import InputSearch from "src/modules/components/Input/InputSearch";
import { useValues } from "src/hooks";
import { CloseIconSVG } from "src/utils/drawer";
import { useFetch } from "src/hooks";
import { fetchUserList } from "src/api/containers/user";
import ROLES from "src/utils/roles";
import { HistoryContainer } from "src/modules/containers/ManageHistory/HistoryFeature/HistoryContainer";
import "./CourseList.scss";

const CourseList = (props) => {
    const {
        isLoading = false,
        courseList = [],
        languages = [],
        pagination = {},
        handleFilterLanguages = () => {},
        handleFilter = () => {},
        handleFilterCourses = () => {},
        filterValues = {},
        handleFetchCourseList = () => {},
        handleRefetchCourseList = () => {},
        handleSearch = () => {},
        mode = "courses", // "courses" | "classes",
        is_myClass,
    } = props;

    const user = useSelector((state) => state.auth.user);
    const { generate } = useRouting();
    const navigate = useNavigate();

    const userTimeoutId = useRef();

    const [loadingUser, users, paginateUser, fetchUser] = useFetch({}, fetchUserList);

    const [modalConfirm, setModalConfirm] = useState({
        isConfirmModalVisible: false,
        params: null,
    });
    const [showModalDetailCourse, setShowModalDetailCourse] = useState({
        isShown: false,
        courseId: "",
    });
    const [showMenuBtnLoading, setShowMenuBtnLoading] = useState({
        isLoading: false,
        courseId: "",
    });

    const [values, setValues] = useValues({
        isModalHistory: false,
        courseId: "",
    });

    const handleDeleteCourse = (courseId) => {
        if (courseId) {
            setShowMenuBtnLoading({ isLoading: true, courseId: courseId });
            deleteCourse(courseId).then((res) => {
                if (res.status === true) {
                    notification.success({
                        message: t("message.delete_success"),
                    });
                    // Reload course list:
                    handleRefetchCourseList();
                } else {
                    notification.error({
                        message: res.message || "",
                    });
                }
                setShowMenuBtnLoading({ isLoading: false, courseId: "" });
                setModalConfirm({ ...modalConfirm, isConfirmModalVisible: false, params: null });
            });
        }
    };

    const onChangeCheckbox = (e) => {
        handleFilter({
            is_myClass: e.target.checked,
        });
    };

    const debounceFetchUser = (slug) => {
        userTimeoutId.current && clearTimeout(userTimeoutId.current);

        userTimeoutId.current = setTimeout(() => {
            fetchUser({ slug }, true);
        }, [500]);
    };

    const renderMenu = (courseId) => {
        return (
            <Menu
                className="course-list-item-menu"
                items={[
                    {
                        key: "course-mn1",
                        label: (
                            <Space className="item-btn">
                                <Icon name="icon-modify" />
                                {t("action.update")}
                            </Space>
                        ),
                        disabled: showModalDetailCourse.isShown,
                        onClick: () => {
                            setShowModalDetailCourse({ isShown: true, courseId: courseId });
                        },
                    },
                    {
                        key: "course-mn2",
                        label: (
                            <Space className="item-btn">
                                <Icon name="icon-delete" />
                                {t("action.delete")}
                            </Space>
                        ),
                        disabled: showMenuBtnLoading.isLoading,
                        onClick: () => {
                            setModalConfirm({ isConfirmModalVisible: true, params: { courseId: courseId } });
                        },
                    },
                    {
                        key: "course-mn3",
                        label: (
                            <Space className="item-btn">
                                <HistoryOutlined />
                                {t("nav.history")}
                            </Space>
                        ),
                        disabled: showMenuBtnLoading.isLoading,
                        onClick: () => {
                            setValues({ isModalHistory: true, courseId: courseId });
                        },
                    },
                ]}
            />
        );
    };

    const teacherActions = (item) => {
        const result = [
            {
                key: "a",
                title: t("teacher_courses.progress"),
                onClick: () => {
                    let path = "";
                    if (mode === "classes") {
                        generate("general-report") +
                            `?class=${item.id}&lang=${item?.language_id}&level=${item?.level_id}`;
                    } else {
                        path = generate("gradebook") + `?course=${item.id}`;
                    }
                    navigate(path);
                },
            },
            {
                key: "c",
                title: t("teacher_courses.assignments"),
                onClick: () => {
                    navigate(generate("course_detail", { id: item.id }) + "?tab=exam");
                },
            },
        ];

        if (mode === "courses") {
            result.splice(1, 0, {
                key: "b",
                title: t("teacher_courses.exercise"),
                onClick: () => {
                    navigate(generate("course_unit", { courseId: item.id }));
                },
            });
        }

        return result;
    };
    const employeeActions = (item) => {
        return [
            {
                key: "a",
                title: t("teacher_courses.progress"),
                onClick: () => {
                    let path = "";
                    if (mode === "classes") {
                        path =
                            generate("general-report") +
                            `?class=${item.id}&lang=${item?.language_id}&level=${item?.level_id}`;
                    } else {
                        path = generate("gradebook") + `?course=${item.id}`;
                    }
                    navigate(path);
                },
            },
            {
                key: "b",
                title: mode === "courses" ? t("teacher_courses.exercise") : t("teacher_courses.assignments"),
                onClick: () => {
                    mode === "courses"
                        ? navigate(generate("course_unit", { courseId: item.id }))
                        : navigate(generate("course_detail", { id: item.id }) + "?tab=exam");
                },
            },
        ];
    };

    useEffect(() => {
        fetchUser({}, true);
    }, []);

    return (
        <div className="teacher-courses-list">
            <div className="list-header">
                <h1 className="app-txt-h2 header-title">{t(`course.your_${mode}`)}</h1>
                <div className="course-list-actions">
                    {mode !== "courses" && user.role !== "teacher" && (
                        <div className="course-checkbox-filter">
                            <Checkbox checked={is_myClass} onChange={onChangeCheckbox} className="course-checkbox">
                                {t("course.my_class")}
                            </Checkbox>
                        </div>
                    )}

                    {ROLES.ADMIN_EMPLOYEE.includes(user?.role) && (
                        <div className="course-list-filter">
                            <span></span>
                            <span>
                                <Select
                                    className="app-select has-rd"
                                    // defaultValue={"1"}
                                    style={{ minWidth: 150 }}
                                    placeholder={
                                        mode === "courses"
                                            ? t("teacher_courses.find_courses_by_user")
                                            : t("teacher_courses.find_class_by_user")
                                    }
                                    showSearch
                                    suffixIcon={<SearchOutlined />}
                                    loading={loadingUser}
                                    notFoundContent={loadingUser ? <Spin spinning={true}></Spin> : null}
                                    onSearch={debounceFetchUser}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                    allowClear
                                    onClear={() => {
                                        handleFilterCourses({ ...filterValues, user_id: undefined });
                                    }}
                                    onChange={(value) => {
                                        handleFilterCourses({ ...filterValues, user_id: value ?? undefined });
                                    }}
                                >
                                    <Select.Option value="1">{t("course.all")}</Select.Option>
                                    {users?.map((item, i) => {
                                        return (
                                            <Select.Option key={item.id} value={item.id} label={item.name}>
                                                {item.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </span>
                        </div>
                    )}
                    <div className="course-list-filter">
                        <span>{t("language.language")}</span>
                        <span>
                            <Select
                                className="app-select has-rd"
                                defaultValue={"-1"}
                                onChange={(value) => handleFilterLanguages(value)}
                                style={{ minWidth: 150 }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                                // value={filterValues?.language_id || "-1"}
                            >
                                <Select.Option value="-1">{t("course.all")}</Select.Option>
                                {languages?.map((item, i) => {
                                    return (
                                        <Select.Option key={i} value={item.id} label={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>

                    {ROLES.ADMIN_EMPLOYEE.includes(user?.role) && (
                        <div className="course-list-filter">
                            <span></span>
                            <span>
                                <Select
                                    className="app-select has-rd"
                                    defaultValue={"-1"}
                                    onChange={(value) => handleFilterCourses({ is_show: value })}
                                    style={{ minWidth: 150 }}
                                >
                                    <Select.Option value="-1">{t("course.all")}</Select.Option>
                                    <Select.Option value={1}>{t("shared.showing")}</Select.Option>
                                    <Select.Option value={0}>{t("shared.is_hiding")}</Select.Option>
                                </Select>
                            </span>
                        </div>
                    )}

                    {/* code cho lớp học */}
                    {mode !== "courses" && (
                        <>
                            <div className="course-list-filter">
                                <span>{t(`course.${mode}`)}</span>
                                <span>
                                    <Select
                                        className="app-select has-rd"
                                        defaultValue={"1"}
                                        onChange={(value) => handleFilterCourses({ ...filterValues, is_active: value })}
                                    >
                                        <Select.Option value="-1">{t("course.all")}</Select.Option>
                                        <Select.Option value="2">{t("course.not_started_yet")}</Select.Option>
                                        <Select.Option value="1">{t("course.active")}</Select.Option>
                                        <Select.Option value="0">{t("course.finished")}</Select.Option>
                                    </Select>
                                </span>
                            </div>
                            <InputSearch
                                onClickIconSearch={handleSearch}
                                onChange={(e) =>
                                    handleFilter({
                                        slug: e?.target?.value,
                                    })
                                }
                                placeholder={t("shared.search")}
                            />
                        </>
                    )}

                    {ROLES.ADMIN_EMPLOYEE.includes(user?.role) && mode !== "classes" && (
                        <CustomButton
                            title={t("course.add_course")}
                            icon={<PlusCircleOutlined />}
                            onClick={() => setShowModalDetailCourse({ isShown: true, courseId: "" })}
                        />
                    )}
                </div>
            </div>{" "}
            {courseList.length == 0 && isLoading === false ? (
                <div className="list-msg">{t(`course.${mode}_empty`)}!</div>
            ) : (
                <></>
            )}
            <div className="list-content">
                <div className="course-list-wrapper">
                    <Row
                        gutter={[
                            { xs: 20, sm: 20, lg: 30 },
                            { xs: 20, sm: 20, lg: 30 },
                        ]}
                    >
                        {/* Skeleton: */}
                        {isLoading === true
                            ? Array(4)
                                  .fill(0)
                                  .map((_, i) => (
                                      <Col key={`course${i}`} xs={24} sm={24} md={12} xl={8}>
                                          <CoursePanel isLoading={isLoading} role={user.role} />
                                      </Col>
                                  ))
                            : null}
                        {/* Data list: */}
                        {isLoading === false
                            ? courseList.map((item, index) => (
                                  <Col key={`course${index}`} xs={24} sm={24} md={12} xl={8}>
                                      <div className="course-item-wrapper">
                                          <Spin
                                              spinning={
                                                  showMenuBtnLoading.courseId === item.id
                                                      ? showMenuBtnLoading.isLoading || false
                                                      : false
                                              }
                                          >
                                              <CoursePanel
                                                  role={user.role}
                                                  courseInfo={item}
                                                  href={
                                                      generate("course_detail", { id: item.id }) +
                                                      (item.is_class ? "?tab=student" : "?tab=unit")
                                                  }
                                                  actions={
                                                      user?.role === "teacher"
                                                          ? teacherActions(item)
                                                          : employeeActions(item)
                                                  }
                                              />
                                          </Spin>
                                          {ROLES.ADMIN_EMPLOYEE.includes(user?.role) && (
                                              <Dropdown
                                                  overlay={renderMenu(item.id)}
                                                  trigger={["click"]}
                                                  placement="bottomRight"
                                              >
                                                  <Button className="course-item-dropdown-btn">
                                                      <EllipsisOutlined />
                                                  </Button>
                                              </Dropdown>
                                          )}
                                      </div>
                                  </Col>
                              ))
                            : null}
                        {/* Pagination bar: */}
                        {isLoading === false && pagination.current < pagination.lastPage ? (
                            <Col xs={24} sm={24} md={12} xl={8}>
                                <div className="course-item-loadmore">
                                    <CustomButton
                                        title={`${t("course.load_more_courses")}...`}
                                        icon={null}
                                        onClick={() => handleFetchCourseList({ page: pagination.current + 1 })}
                                    />
                                </div>
                            </Col>
                        ) : null}
                    </Row>

                    <ModalDetailCourse
                        isClass={mode === "classes"}
                        languages={languages}
                        courseId={showModalDetailCourse.courseId}
                        visible={showModalDetailCourse.isShown}
                        onOk={() => {
                            handleRefetchCourseList();
                        }}
                        onCancel={() => {
                            setShowModalDetailCourse({ ...showModalDetailCourse, isShown: false });
                        }}
                    ></ModalDetailCourse>

                    <Modal
                        title={t("history.editing_history")}
                        visible={values.isModalHistory}
                        onOk={() => {
                            setValues({ isModalHistory: false });
                        }}
                        onCancel={() => {
                            setValues({ isModalHistory: false });
                        }}
                        closeIcon={<CloseIconSVG />}
                        footer={null}
                        className={"modal-add-course modal_histories"}
                        width={"auto"}
                        destroyOnClose={true}
                        centered={true}
                    >
                        <HistoryContainer modal_id={values.courseId} type="course_history" />
                    </Modal>

                    <PopConfirm
                        type="delete"
                        visible={modalConfirm.isConfirmModalVisible}
                        onOk={() => {
                            handleDeleteCourse(modalConfirm.params.courseId);
                        }}
                        onCancel={() =>
                            setModalConfirm({ ...modalConfirm, isConfirmModalVisible: false, params: null })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CourseList;
