import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";

export function PlayAudioBtn({ src, children }) {
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = React.createRef(); // create a reference to the audio element

    const handleClick = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const onEnded = () => {
        setIsPlaying(false);
    };

    return (
        <div onClick={handleClick}>
            <audio ref={audioRef} src={src} onEnded={onEnded} />
            {isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
            {children}
        </div>
    );
}
