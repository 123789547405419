import { Col, Row, Space, Tooltip, Typography } from "antd";
import { t } from "i18next";
import QuestionContentDetail from "../ContentDetail";
import "./SpeechToTextConversationAudio.scss";
import { useValues } from "src/hooks";
import { useEffect } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Speaker } from "src/modules/components/QuestionPanel/SpeechToTextConversationAudio/components/Speaker";

const SpeechToTextConversationAudio = (props) => {
    const { question, answered, answer_submit, percent_correct, correct_answer } = props;

    const [values, setValues] = useValues({
        answers: [],
    });

    useEffect(() => {
        const newAns = [...correct_answer];
        const userAnswer = correct_answer?.filter((item) => item?.is_user);

        for (let i = 0; i < userAnswer?.length; i++) {
            const is_correct = percent_correct?.[i] == 100;
            newAns[userAnswer[i]?.order].is_correct = is_correct;
            newAns[userAnswer[i]?.order].userAnswer = answer_submit?.[i];
        }

        setValues({
            answers: newAns,
        });
    }, [answered, percent_correct, correct_answer]);

    return (
        <div className="qc_writing qc_speech-to-text_conversation">
            <div className="viewScoreStudent_writingTitle">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question: question,
                    }}
                />
            </div>
            <div className="speech-to-text-frame">
                <br />

                <Space direction="vertical" size={18} style={{ minWidth: "38%" }}>
                    {values.answers.map((item, idx) => {
                        if (item?.is_user) {
                            return (
                                <Row gutter={[20, 20]} key={idx}>
                                    <Col xs={24} sm={4}>
                                        <span className="sentence-label">{item?.actor}:</span>
                                    </Col>
                                    <Col xs={24} sm={20}>
                                        <div className="sentence">
                                            <Space size="middle">
                                                {/* <span className="user_answer_sentence">{item?.userAnswer}</span> */}
                                                <span className="user_answer_sentence">{item?.value}</span>
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        } else {
                            return (
                                <Row gutter={[20, 20]} key={idx}>
                                    <Col xs={24} sm={4}>
                                        <span className="sentence-label">{item?.actor}:</span>
                                    </Col>
                                    <Col xs={24} sm={20}>
                                        <div className="user_answer_sentence">
                                            {/* <p>{item?.value}</p> */}
                                            <Speaker />
                                        </div>
                                    </Col>
                                </Row>
                            );
                        }
                    })}
                </Space>
            </div>
        </div>
    );
};

export default SpeechToTextConversationAudio;
