import { Layout, Modal, notification } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { fetchStudentAssignments } from "src/api/containers/assignment";
import { countUserViolations } from "src/api/containers/record";
import configs from "src/configs";
import { useEventListener, useFetch, useValues } from "src/hooks";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { pages } from "src/routes/pages";
import { closeFullscreen } from "src/utils/AntiCheating";
import { useRouting } from "src/utils/router";

import Sidebar from "./Sidebar";
import "./TestLayout.scss";

const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;

const TestLayout = (props) => {
    const { children, record_id } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const recordId = useRef(record_id);

    const countFullscreenChange = useRef(0);

    const [
        loadingListAssignmentOfStudent,
        ListAssignmentOfStudent,
        paginationListAssignmentOfStudent,
        fetchListAssignmentOfStudent,
        refetchListAssignmentOfStudent,
    ] = useFetch({}, fetchStudentAssignments);

    const [values, setValues] = useValues({
        isShowModalWarning: false,
        modalMessage: "",
    });

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            countFullscreenChange.current += 1;
            setValues({
                isShowModalWarning: true,
                modalMessage: t("anti_cheating.exit_fullscreen_warning"),
            });

            countUserViolations(recordId.current, ANTI_CHEATING_KEYS.EXIT_FULL_SCREEN).then(
                ({ status, warning, is_load }) => {
                    if (warning) {
                        notification.warning({ message: warning });
                    }
                    if (is_load) {
                        navigate(generate(pages.exam_asset.name));
                        notification.warning({ message: t("anti_cheating.violated_exam_contact_teacher") });
                        closeFullscreen();
                    }
                }
            );
        }
    };

    const [cleanUpFullscreenchange] = useEventListener("fullscreenchange", handleFullscreen);

    useEffect(() => {
        if (countFullscreenChange.current > 5) {
            cleanUpFullscreenchange?.();
        }
    }, [countFullscreenChange.current]);

    useEffect(() => {
        fetchListAssignmentOfStudent({}, true);
        const handleBeforeUnload = (event) => {
            event.returnValue = "Cảnh báo những thay đổi của bạn sẽ không được lưu!"; // this text not show in UI, because chrome dissable
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        recordId.current = record_id;
    }, [record_id]);

    return (
        <div className="testLayout_wrapper">
            <Layout hasSider style={{ background: "none" }}>
                <Layout.Content className="container">{children}</Layout.Content>
                <Sidebar listTest={ListAssignmentOfStudent} />
            </Layout>
            <PopConfirm
                type="delete"
                visible={values.isShowModalWarning}
                title={values.modalMessage}
                onOk={() => {
                    setValues({ isShowModalWarning: false });
                    if (!document.fullscreenElement) {
                        document.documentElement.requestFullscreen();
                    }
                }}
                onCancel={() => {}}
                cancelText={null}
                okText={t("ok")}
            />
        </div>
    );
};

TestLayout.layout = "TestLayout";

export default TestLayout;
