import React from "react";

function IconTick(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="21" height="15" viewBox="0 0 21 15" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0105 3.52177L9.13559 14.3959C8.33029 15.2014 7.02396 15.2014 6.2179 14.3959L0.604203 8.7818C-0.201401 7.97635 -0.201401 6.66988 0.604203 5.86427C1.40996 5.05852 2.71618 5.05852 3.52158 5.86397L7.67718 10.0196L17.0926 0.604089C17.8984 -0.201668 19.2047 -0.201058 20.0102 0.604089C20.8156 1.40969 20.8156 2.71571 20.0105 3.52177Z" />
        </svg>
    );
}

export default IconTick;
