import { useDispatch } from "react-redux";
import Breadcrumb from "../Breadcrumb";
import "./Header.scss";

const Header = (props) => {
    const { title = "", children, isShowBreadCumb = true } = props;
    const dispatch = useDispatch();

    return (
        <>
            <div className="student-header">
                {isShowBreadCumb && (
                    <Breadcrumb
                        routes={[
                            {
                                name: title,
                            },
                        ]}
                    />
                )}

                <div className="student-filter">{children}</div>
            </div>
        </>
    );
};

export default Header;
