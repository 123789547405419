import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notification, Select, Spin } from "antd";
import ExamChartController from "../../components/ExamChartController";
import QuestionNumberList from "src/modules/components/NumberList/QuestionNumberList";
import AnswerDetail from "../../components/AnswerDetail";
import { getAssignmentQuestionRecordDetailList, getAssignmentQuestionRecordList } from "src/api/containers/statistic";
import { useValues } from "src/hooks";
import "./Question.scss";

const Question = ({ assignmentData = {}, selectedCourseClassId = "" }) => {
    const params = useParams();
    const { t } = useTranslation();
    const [selectedRecordId, setSelectedRecordId] = useState("");
    const [selectedFilterByStatus, setSelectedFilterByStatus] = useState("all");
    const [values, setValues] = useValues({
        loading: false,
        questionRecords: [],
        questionRecordsDetail: [],
    });

    const [answerStats, timeStats, timeMaxValue] = useMemo(() => {
        const generateChartAnswerStatistics = () => {
            const answerStats = [];
            const timeStats = [];
            let timeMaxValue = 0;
            const types = {
                correct: "correct",
                part_correct: "correct_nearly",
                wrong: "incorrect",
                hand_dots: "mark_by_hand",
                skip: "skip",
                unknown: "",
            };
            values.questionRecordsDetail.forEach((d) => {
                const label = d.user?.name || "Unknown";
                if (d.record_question?.type === "passage") {
                    let time = 0;
                    d.chart?.forEach((dChart) => {
                        const type = types[dChart.status || "unknown"];
                        answerStats.push({ xLabel: label, itemType: type, yColValue: dChart.score || 0 });
                        time += dChart.time || 0;
                    });
                    timeStats.push({ xLabel: label, yLineValue: time });
                    if (timeMaxValue < time) {
                        timeMaxValue = time;
                    }
                } else {
                    const type = types[d.chart?.status || "unknown"];
                    answerStats.push({ xLabel: label, itemType: type, yColValue: d.chart?.score || 0 });
                    timeStats.push({ xLabel: label, yLineValue: Math.round(d.chart?.time || 0) });
                    if (timeMaxValue < d.chart?.time) {
                        timeMaxValue = d.chart?.time;
                    }
                }
            });
            timeMaxValue = Math.round(timeMaxValue * 2);
            return [answerStats, timeStats, timeMaxValue];
        };
        return generateChartAnswerStatistics();
    }, [values.questionRecordsDetail]);

    const [filteredRecordList] = useMemo(() => {
        let filteredRecordList = [];
        if (selectedFilterByStatus === "all") {
            // filteredRecordList = values.questionRecordsDetail || [];
        } else {
            const qTypes = {
                correct: "correct",
                correct_nearly: "part_correct",
                incorrect: "wrong",
                mark_by_hand: "hand_dots",
                skip: "skip",
            };
            for (let i = 0; i < values.questionRecordsDetail.length; i++) {
                if (values.questionRecordsDetail[i].record_question?.type === "passage") {
                    const chartDataList = values.questionRecordsDetail[i].chart || [];
                    if (
                        selectedFilterByStatus === "correct" ||
                        selectedFilterByStatus === "correct_nearly" ||
                        selectedFilterByStatus === "incorrect"
                    ) {
                        /**
                         * Check the current passage question:
                         * - If its grade = 0, it is incorrect.
                         * - If its grade > 0 and grade < totalScore, it is correct_nearly.
                         * - If its grade = totalScore, it is correct.
                         */
                        let countScore = 0;
                        let totalScore = values.questionRecordsDetail[i].record_question?.score || 0;
                        chartDataList.forEach((cItem, cIndex) => {
                            countScore += cItem.score_submit || cItem.score_teacher_submit || 0;
                        });
                        if (selectedFilterByStatus === "correct" && countScore === totalScore) {
                            filteredRecordList.push(values.questionRecordsDetail[i]);
                        } else if (
                            selectedFilterByStatus === "correct_nearly" &&
                            countScore > 0 &&
                            countScore < totalScore
                        ) {
                            filteredRecordList.push(values.questionRecordsDetail[i]);
                        } else if (selectedFilterByStatus === "incorrect" && countScore === 0) {
                            filteredRecordList.push(values.questionRecordsDetail[i]);
                        }
                    } else {
                        for (let cIndex = 0; cIndex < chartDataList.length; cIndex++) {
                            if (chartDataList[cIndex].status === qTypes[selectedFilterByStatus]) {
                                filteredRecordList.push(values.questionRecordsDetail[i]);
                                break;
                            }
                        }
                    }
                } else {
                    if (values.questionRecordsDetail[i].chart.status === qTypes[selectedFilterByStatus]) {
                        filteredRecordList.push(values.questionRecordsDetail[i]);
                        break;
                    }
                }
            }
        }
        return [filteredRecordList];
    }, [selectedFilterByStatus, values.questionRecordsDetail]);

    const handleSelectQuestionRecord = (recordId) => {
        if (recordId) {
            setSelectedRecordId(recordId);
            setValues({ ...values, loading: true });
            const fetchParams = {
                recordIds: [recordId],
                course_id: selectedCourseClassId || undefined,
            };
            getAssignmentQuestionRecordDetailList(params.assignmentId, fetchParams).then((res) => {
                if (res.status === true) {
                    setValues({
                        ...values,
                        loading: false,
                        questionRecordsDetail: res.data,
                    });
                } else {
                    setValues({
                        ...values,
                        loading: false,
                        questionRecordsDetail: [],
                    });
                    notification.error({
                        message: res.message || "",
                    });
                }
            });
        } else {
            setSelectedRecordId("");
            setValues({
                ...values,
                questionRecordsDetail: [],
            });
        }
    };

    const handleSelectFilterStudentsByStatus = (statusValue) => {
        setSelectedFilterByStatus(statusValue);
    };

    useEffect(() => {
        if (params.assignmentId) {
            setValues({ ...values, loading: true });
            getAssignmentQuestionRecordList(params.assignmentId).then((res) => {
                if (res.status === true) {
                    setValues({
                        ...values,
                        loading: false,
                        questionRecords: res.data,
                    });
                } else {
                    setValues({
                        ...values,
                        loading: false,
                    });
                }
            });
        }
    }, []);

    useEffect(() => {
        setSelectedRecordId("");
        setValues({
            ...values,
            questionRecordsDetail: [],
        });
    }, [selectedCourseClassId]);

    return (
        <div className="report-question">
            <div className="report-question-chart">
                <div className="option-menu-wrapper">
                    <Select
                        className="app-select show-arrow"
                        placeholder={t("report.select_question")}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        value={selectedRecordId || undefined}
                        onChange={(val) => handleSelectQuestionRecord(val)}
                    >
                        {values.questionRecords.map((opt, i) => {
                            return (
                                <Select.Option key={`question${i}`} value={opt.detail_id || ""}>
                                    {`${t("report.question_number")} ${opt.order || ""}`}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <Spin spinning={values.loading}>
                    {values.loading === false && selectedRecordId && values.questionRecordsDetail.length ? (
                        <ExamChartController
                            lineColor="#0077FF"
                            propsColor={{
                                correct: "#75B136",
                                correct_nearly: "#0077ff",
                                incorrect: "#E56965",
                                mark_by_hand: "#F8CC5A",
                                skip: "#DDDDDD",
                            }}
                            propsLabel={{
                                correct: t("assignment.correct"),
                                correct_nearly: t("assignment.correct_nearly"),
                                incorrect: t("assignment.incorrect"),
                                mark_by_hand: t("assignment.mark_by_hand"),
                                skip: t("assignment.skip"),
                            }}
                            yLineTitle={t("assignment.time_spent")}
                            examData={answerStats}
                            timeData={timeStats}
                            yLineMaxValue={timeMaxValue}
                            limitYColEachPage={10}
                            yLabel={{
                                visible: true,
                                labelLeft: t("report.grade_of_question"),
                                labelRight: t("report.total_time_spent_in_seconds"),
                            }}
                        />
                    ) : null}
                </Spin>
                {values.loading === false && !selectedRecordId ? (
                    <span className="chart-null">{t("report.please_select_question")}</span>
                ) : null}
                {values.loading === false && selectedRecordId && values.questionRecordsDetail.length === 0 ? (
                    <span className="chart-null">{t("report.empty_data")}</span>
                ) : null}
            </div>

            <div className="detail-student-answer">
                <div className="filter-menu-wrapper">
                    {values.questionRecordsDetail.length ? (
                        <div className="filter">
                            <span>{t("statistical.filter_by_status")}</span>
                            <Select
                                className="app-select"
                                showArrow
                                defaultValue={"all"}
                                values={selectedFilterByStatus}
                                onChange={(val) => {
                                    handleSelectFilterStudentsByStatus(val);
                                }}
                            >
                                <Select.Option value="all">{t("report.all")}</Select.Option>
                                <Select.Option value="correct">{t("assignment.correct")}</Select.Option>
                                <Select.Option value="correct_nearly">{t("assignment.correct_nearly")}</Select.Option>
                                <Select.Option value="incorrect">{t("assignment.incorrect")}</Select.Option>
                                <Select.Option value="mark_by_hand">{t("assignment.mark_by_hand")}</Select.Option>
                                <Select.Option value="skip">{t("assignment.skip")}</Select.Option>
                            </Select>
                        </div>
                    ) : null}

                    {(selectedFilterByStatus === "all" ? values.questionRecordsDetail : filteredRecordList).length >
                        0 && (
                        <QuestionNumberList
                            type="horizontal"
                            itemType="link"
                            numberOfQuestions={
                                (selectedFilterByStatus === "all" ? values.questionRecordsDetail : filteredRecordList)
                                    .length || 0
                            }
                            itemConfig={{ itemHtmlElementId: "question-record-" }}
                        ></QuestionNumberList>
                    )}
                </div>

                <div className="answer-record-list-wrapper">
                    {(selectedFilterByStatus === "all" ? values.questionRecordsDetail : filteredRecordList).map(
                        (recordItem, i) => {
                            return (
                                <div className="answer-record-wrapper" key={`record-${i}`}>
                                    <AnswerDetail
                                        htmlId={`question-record-${i + 1}`}
                                        className="answer-record"
                                        tab="question"
                                        infoData={{ user: recordItem.user || {} }}
                                        recordData={recordItem.record_question || {}}
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default Question;
