import {
    CaretDownOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    FileTextOutlined,
    HistoryOutlined,
    PlusCircleOutlined,
    ShareAltOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Modal, Spin, Tabs, Tooltip, notification } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { fetchAssignments } from "src/api/containers/assignment";
import { findCourse, removeUserFromCourse } from "src/api/containers/course";
import { fetchUnitList } from "src/api/containers/unit";
import { fetchStudents } from "src/api/containers/user";
// Others:
import { ReactComponent as Bin } from "src/assets/images/bin.svg";
import Contact from "src/assets/images/contact.svg";
import { ReactComponent as DeleteBin } from "src/assets/images/delete-bin.svg";
import Monitor from "src/assets/images/monitor.svg";
import { useFetch, useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import Icon from "src/modules/components/Icon";
import InputSearch from "src/modules/components/Input/InputSearch";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import ModalDetailExam from "src/modules/components/ModalDetailExam";
// import ModalInviteLink from "../../components/ModalInviteLink";
import ModalInviteLink from "src/modules/components/ModalInviteLink";
import Table from "src/modules/components/Table";
import { HistoryContainer } from "src/modules/containers/ManageHistory/HistoryFeature/HistoryContainer";
import { setCurrentPage } from "src/reducers/general";
import { CloseIconSVG } from "src/utils/drawer";
import ROLES from "src/utils/roles";

import { OutlineButton } from "../../components/Button";
import Header from "../../components/Header";
// Course management tab:
import ModalAddTeacher from "../../components/ModalAddUser";
// Exam management tab:
import ModalGeneralLearningProgress from "../../components/ModalGeneralLearningProgress";
import ModalMarkAssignment from "../../components/ModalMarkAssignment";
import ModalPersonalLearningProgress from "../../components/ModalPersonalLearningProgress";
import "./CourseDetail.scss";
import "./TableExamList/TableExamList.scss";
import "./TableStudentList/TableStudentList.scss";
// Topic tab:
import TableUnitList from "./TableUnitList";

const CourseDetail = ({ courseId }) => {
    const tabs = {
        unit: "unit",
        student: "student",
        exam: "exam",
    };

    const params = useParams();
    const navigate = useNavigate();
    const course_id = useMemo(() => {
        return courseId || params.id;
    }, [params.id, courseId]);

    const [values, setValues] = useValues({
        courseData: {},
        isEdited: false,
        isConfirmModal: false,
        pendingDeleteItem: null,
        loading: true,
        filterStudentTable: {},
        filterExamTable: {},
        markingAssignmentId: "",
        studentNumber: undefined,
        viewProgressAssignment: {},
        viewProgressCourse: {},
        viewProgressLevel: {},
        viewProgressIsUnit: undefined,
        isOnMobile: false,
        isShowForm: false,
        examType: "exam",
        searchUserText: "",
        isModalHistory: false,
        isConfirmModalVisible: false,
        selectedStudent: {},
    });
    const [showModal, setShowModal] = useState({
        isShown: "",
        params: null,
    });

    let [searchParams, setSearchParams] = useSearchParams();

    const user = useSelector((state) => state.auth.user);

    const [isAssignExamModal, setIsAssignExamModal] = useState(false);
    const [isMarkingModal, setIsMarkingModal] = useState(false);
    const [isAddTeacherModal, setIsAddTeacherModal] = useState(false);
    const [isPersonalLearningProgress, setIsPersonalLearningProgress] = useState(false);
    const [isGeneralLearningProgress, setIsGeneralLearningProgress] = useState(false);
    const [showModalInviteLink, setShowModalInviteLink] = useState({ isShown: false, assignmentId: "" });
    const [studentInfo, setStudentInfo] = useState({});
    const dispatch = useDispatch();

    const [unitLoading, unitDataSource, unitPagination, unitFetch, unitRefetch] = useFetch(
        { page: 1, filters: `course_id:${course_id}`, _orderBy: "order:asc" },
        fetchUnitList
    );
    const [studentLoading, studentDataSource, studentPagination, studentFetch, studentRefetch] = useFetch(
        { page: 1, filters: `course_id:${course_id}` },
        fetchStudents
    );
    const [examLoading, examDataSource, examPagination, examFetch, examRefetch] = useFetch(
        { page: 1, filters: `course_id:${course_id};teacher_id:${user?.id};` },
        fetchAssignments
    );

    const format = "DD/MM/YYYY HH:mm:ss";

    const handleShowModal = (id, name) => {
        setValues({
            isConfirmModal: true,
            pendingDeleteItem: { id, name },
        });
    };

    const studentColumns = [
        {
            title: t("profile.full_name"),
            dataIndex: "name",
            key: "name",
            render: (text, record) =>
                values.isEdited ? (
                    <span>
                        <span className="icon" onClick={() => handleShowModal(record.id, record.name)}>
                            <Bin />
                        </span>
                        <span>{record.name}</span>
                    </span>
                ) : (
                    <span>{record.name}</span>
                ),
        },
        {
            title: t("course.phone_number"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("course.email"),
            dataIndex: "username",
            key: "username",
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="gender"
                            cateName="course"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "female", value: 0 },
                                { name: "male", value: 1 },
                                { name: "unknown", value: null },
                            ]}
                            selectedFilterKey={values.filterStudentTable.gender}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterStudentTable.gender) {
                                    setValues({
                                        filterStudentTable: { ...values.filterStudentTable, gender: undefined },
                                    });
                                } else {
                                    setValues({ filterStudentTable: { ...values.filterStudentTable, gender: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="gender-wrapper">
                        <span>{t("course.gender")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "gender",
            key: "gender",
            render: (text, record) =>
                record.gender === 0
                    ? t("course.female")
                    : record.gender === 1
                    ? t("course.male")
                    : record.gender === null
                    ? t("course.unknown")
                    : "",
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="role"
                            cateName="course"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "teacher", value: "teacher" },
                                { name: "student", value: "student" },
                                { name: "employee", value: "employee" },
                            ]}
                            selectedFilterKey={values.filterStudentTable.role}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterStudentTable.role) {
                                    setValues({
                                        filterStudentTable: { ...values.filterStudentTable, role: undefined },
                                    });
                                } else {
                                    setValues({ filterStudentTable: { ...values.filterStudentTable, role: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="role-wrapper">
                        <span>{t("course.role")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "role",
            key: "role",
            render: (text, record) => {
                return t(record.role);
            },
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="status"
                            cateName="shared"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "active", value: true },
                                { name: "inactive", value: false },
                            ]}
                            selectedFilterKey={values.filterStudentTable.active}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterStudentTable.active) {
                                    setValues({
                                        filterStudentTable: { ...values.filterStudentTable, active: undefined },
                                    });
                                } else {
                                    setValues({ filterStudentTable: { ...values.filterStudentTable, active: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="status-wrapper">
                        <span>{t("course.status")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "course_user_status",
            key: "course_user_status",
            render: (text, record) => (record.active === true ? t("shared.active") : t("shared.inactive")),
        },
    ];

    if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role)) {
        studentColumns.push({
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => {
                return (
                    <div className="list-actions">
                        <Tooltip placement="top" title={t("shared.delete")}>
                            <CustomButton
                                className="btn list-btn btn-danger btn-icon-only"
                                type="simple"
                                icon={<DeleteOutlined />}
                                onClick={() => setValues({ isConfirmModalVisible: true, selectedStudent: record })}
                            />
                        </Tooltip>
                    </div>
                );
            },
        });
    }

    const examColumns = [
        {
            title: t("course.exam_name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("course.level"),
            dataIndex: "level",
            key: "level",
            render: (text, record) => (record.level?.name ? record.level.name : "_"),
        },
        {
            title: t("course.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            render: (text, record) => moment(record.begin_date).format(format),
        },
        {
            title: t("course.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            render: (text, record) => moment(record.end_date).format(format),
        },
        {
            title: t("course.max_score"),
            dataIndex: "max_score",
            key: "max_score",
            render: (text, record) => record?.exam?.max_score || 0,
        },
        {
            title: t("course.submission"),
            dataIndex: "count_submission",
            key: "count_submission",
            render: (text, record) => record?.count_record?.count_submit || 0,
        },
        {
            title: t("course.graded"),
            dataIndex: "count_marked",
            key: "count_marked",
            render: (text, record) => record?.count_record?.count_marked || 0,
        },
        {
            title: (filters, sortOrder) => (
                <Dropdown
                    overlay={
                        <TableColumnFilterMenu
                            columnName="ownership"
                            cateName="course"
                            filterKeys={[
                                { name: "all", value: undefined },
                                { name: "me", value: user.id },
                            ]}
                            selectedFilterKey={values.filterExamTable.teacher_id}
                            onChangeFilterKey={(val) => {
                                if (val === values.filterExamTable.teacher_id) {
                                    setValues({
                                        filterExamTable: { ...values.filterExamTable, teacher_id: undefined },
                                    });
                                } else {
                                    setValues({ filterExamTable: { ...values.filterExamTable, teacher_id: val } });
                                }
                            }}
                        />
                    }
                    placement="bottom"
                >
                    <div className="owner-ship-wrapper">
                        <span>{t("course.ownership")}</span> <CaretDownOutlined />
                    </div>
                </Dropdown>
            ),
            dataIndex: "ownership",
            key: "ownership",
            render: (text, record) => record?.exam?.user_name || t("shared.unknown"),
        },
    ];

    const examActions = [
        {
            title: t("course.mark_assignment"),
            onClick: (record) => {
                setValues({
                    markingAssignmentId: record.id,
                    viewProgressCourse: {
                        id: record.course.id,
                        name: record.course.name,
                    },
                });
                setIsMarkingModal(true);
                setValues({
                    viewProgressAssignment: {
                        id: record.id,
                        name: record.name,
                    },
                    viewProgressCourse: {
                        id: record.course?.id,
                        name: record.course?.name,
                    },
                    ...(record?.level
                        ? {
                              viewProgressLevel: {
                                  id: record?.level?.id,
                                  name: record?.level?.name,
                              },
                          }
                        : {}),
                    viewProgressIsUnit: false, // Current tab is assignments manager
                });
            },
        },
        {
            tooltip: t("course.learning_progress"),
            icon: Contact,
            onClick: (record) => {
                setValues({
                    viewProgressAssignment: {
                        id: record?.id,
                        name: record?.name,
                    },
                    viewProgressCourse: {
                        id: record.course?.id,
                        name: record.course?.name,
                    },
                    ...(record?.level
                        ? {
                              viewProgressLevel: {
                                  id: record?.level?.id,
                                  name: record?.level?.name,
                              },
                          }
                        : {}),
                    viewProgressIsUnit: false,
                });
                setIsGeneralLearningProgress(true);
            },
        },
        {
            tooltip: t("course.report"),
            icon: Monitor,
            onClick: (record) => navigate(`assignment/${record?.id}`),
        },
        {
            tooltip: t("assignment.invite_via_link"),
            icon: <ShareAltOutlined className="action-icon" />,
            onClick: (record) => {
                setShowModalInviteLink({
                    isShown: true,
                    assignmentId: `${location.origin}/assignment/` + record.id,
                });
            },
        },
    ];

    const handelChangeTab = (key) => {
        setSearchParams({
            tab: key,
        });
    };

    const handleDeleteStudent = () => {
        const data = {
            course_id: values.courseData?.id,
            user_id: values.pendingDeleteItem.id,
        };
        setValues({ loading: true, isConfirmModal: false });
        deleteStudent(data).then(({ status, message }) => {
            if (status) {
                setValues({
                    loading: false,
                    pendingDeleteItem: null,
                });
                studentRefetch();
                notification.success({
                    message: message || t("message.delete_success"),
                });
            } else {
                notification.error({
                    message: message || t("message.delete_error"),
                });
            }
        });
    };

    const handleCloseModalGeneralLearningProgress = useCallback(() => {
        setIsGeneralLearningProgress(false);
    }, []);

    const handleCloseModalPersonalLearningProgress = useCallback(() => {
        setIsPersonalLearningProgress(false);
    }, []);

    const handleSearch = (slug) => {
        setValues({
            filterStudentTable: { ...values.filterStudentTable, slug: values.searchUserText },
        });
    };

    const handleDeleteStudentFromCourse = () => {
        const data = {
            course_id: course_id,
            user_id: values.selectedStudent.id,
        };
        removeUserFromCourse(data).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ selectedStudent: null, isConfirmModalVisible: false });
                studentRefetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    useEffect(() => {
        dispatch(setCurrentPage("course"));
        setValues({ loading: true });
        // Fetch data for course info:
        findCourse(course_id, { relations: ["level"] }).then((res) => {
            setValues({
                courseData: res.data,
            });
            setValues({ loading: false });
        });
    }, []);

    useEffect(() => {
        // Fetch data for default visible tab:
        switch (searchParams.get("tab")) {
            case tabs.exam:
                examFetch({}, true);
                break;
            // case tabs.unit:
            //     unitFetch({}, true);
            //     break;
            case tabs.student:
                studentFetch({}, true);
                break;
            default:
                setSearchParams({
                    tab: values.courseData?.is_class ? tabs.student : tabs.unit,
                });
        }
    }, [searchParams.get("tab")]);

    useEffect(() => {
        if (Object.keys(values.filterStudentTable).length) {
            const fetchParams = [`course_id:${course_id}`];

            // Extra params:
            if (values.filterStudentTable.gender !== undefined) {
                fetchParams.push(`gender:${values.filterStudentTable.gender}`);
            }
            if (values.filterStudentTable.role !== undefined) {
                fetchParams.push(`role:${values.filterStudentTable.role}`);
            }
            if (values.filterStudentTable.active !== undefined) {
                fetchParams.push(`active:${values.filterStudentTable.active}`);
            }
            if (values.filterStudentTable.slug !== undefined) {
                fetchParams.push(`slug:${values.filterStudentTable.slug}`);
            }

            studentFetch({ filters: fetchParams.join(";") }, true);
        }
    }, [values.filterStudentTable]);

    useEffect(() => {
        if (Object.keys(values.filterExamTable).length) {
            const fetchParams = [`course_id:${course_id};is_unit:true`];

            // Extra params:
            if (values.filterExamTable.teacher_id !== undefined) {
                fetchParams.push(`teacher_id:${values.filterExamTable.teacher_id}`);
            }
            if (values.filterExamTable.level_id !== undefined) {
                fetchParams.push(`level_id:${values.filterExamTable.level_id}`);
            }

            examFetch({ filters: fetchParams.join(";") }, true);
        }
    }, [values.filterExamTable]);

    useEffect(() => {
        setValues({ studentNumber: studentDataSource.length });
    }, [studentDataSource]);

    return (
        <div className="teacher-course-detail">
            <Spin spinning={values.loading}>
                <Header courseData={values.courseData || {}} studentNumber={values.studentNumber} />

                <div className="course-detail-top">
                    <p className="course-detail-title">{values.courseData?.name || "_"}</p>
                    <p>
                        {searchParams.get("tab") === tabs.unit && ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) ? (
                            <>
                                <span>
                                    <OutlineButton
                                        title={t("unit.add_unit")}
                                        icon={<PlusCircleOutlined />}
                                        onClick={() => setShowModal({ isShown: "add-unit", params: null })}
                                    />
                                </span>
                            </>
                        ) : null}

                        {searchParams.get("tab") === tabs.student &&
                        ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) ? (
                            <>
                                <span>
                                    <CustomButton
                                        title={t("nav.history")}
                                        icon={<HistoryOutlined />}
                                        onClick={() => {
                                            setValues({
                                                isModalHistory: true,
                                            });
                                        }}
                                    />
                                </span>
                                <span>
                                    <CustomButton
                                        title={t("course.invite_student")}
                                        icon={<ShareAltOutlined />}
                                        onClick={() => {
                                            setShowModalInviteLink({
                                                isShown: true,
                                                assignmentId: `${location.origin}/course-invitation-student/${course_id}?inviter_id=${user?.id}`,
                                            });
                                        }}
                                    />
                                </span>
                                <span>
                                    <CustomButton
                                        title={t("course.invite_teacher")}
                                        icon={<PlusCircleOutlined />}
                                        onClick={() => setIsAddTeacherModal(true)}
                                    />
                                </span>
                            </>
                        ) : null}

                        {searchParams.get("tab") === tabs.exam ? (
                            <>
                                <span>
                                    <OutlineButton
                                        title={
                                            searchParams.get("tab") === tabs.student
                                                ? t("course.upload_student_list")
                                                : t("course.assign_exam")
                                        }
                                        icon={<CloudUploadOutlined />}
                                        onClick={() =>
                                            searchParams.get("tab") === tabs.student ? null : setIsAssignExamModal(true)
                                        }
                                    />
                                </span>
                            </>
                        ) : null}

                        {/* {values.currentTab === tabs.student && studentDataSource.length > 0 && !values.isEdited && (
                            <span>
                                <OutlineButton
                                    title={t("course.edit_list")}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        setValues({ ...values, isEdited: true });
                                    }}
                                />
                            </span>
                        )}
                        {values.currentTab === tabs.student && studentDataSource.length > 0 && values.isEdited && (
                            <span>
                                <OutlineButton
                                    title={t("course.done")}
                                    icon={<FileDoneOutlined />}
                                    onClick={() => {
                                        setValues({ ...values, isEdited: false });
                                    }}
                                />
                            </span>
                        )} */}
                    </p>
                </div>
                <div className="course-detail-management">
                    {!values.loading && (
                        <Tabs activeKey={searchParams.get("tab")} type="card" centered onChange={handelChangeTab}>
                            {/* {!values.courseData?.is_class && (
                                <Tabs.TabPane
                                    tab={
                                        <span>
                                            <UserOutlined />
                                            {t("unit.manage_unit")}
                                        </span>
                                    }
                                    key={tabs.unit}
                                >
                                    <TableUnitList
                                        courseId={values.courseData?.id}
                                        modalShow={showModal}
                                        onChangeModalShow={(val) => setShowModal(val)}
                                        unitLoading={unitLoading}
                                        unitDataSource={unitDataSource}
                                        unitPagination={unitPagination}
                                        // unitFetch={unitFetch}
                                        unitRefetch={unitRefetch}
                                    />
                                </Tabs.TabPane>
                            )} */}

                            <Tabs.TabPane
                                tab={
                                    <span>
                                        <UserOutlined />
                                        {t("course.manage_user")}
                                    </span>
                                }
                                key={tabs?.student}
                            >
                                <div className="table-student-list">
                                    <div className="filter-toolbar-wrapper">
                                        <div className="filter-toolbar">
                                            <div className="filter-toolbar-item filterinput-wrapper w-50">
                                                <InputSearch
                                                    displayType="style-dream"
                                                    placeholder={t("shared.search")}
                                                    value={values.searchUserText}
                                                    onChange={(e) => setValues({ searchUserText: e.target.value })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleSearch(e.target.value);
                                                        }
                                                    }}
                                                    onClickIconSearch={handleSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Table
                                        className="app-table"
                                        columns={studentColumns}
                                        dataSource={studentDataSource}
                                        scroll={{ x: "auto" }}
                                        bodyStyle={{ padding: 0 }}
                                        pagination={{
                                            showSizeChanger: false,
                                            ...studentPagination,
                                            onChange: (page) => {
                                                studentFetch({ page: page });
                                            },
                                        }}
                                        loading={studentLoading}
                                        responsiveOnMobile={true}
                                        mobileHeaderColumns={["name", "person_number", "email"]}
                                    />
                                </div>
                            </Tabs.TabPane>
                            {(values.courseData?.is_class || user?.role !== "employee") && (
                                <Tabs.TabPane
                                    tab={
                                        <span>
                                            <FileTextOutlined />
                                            {t("course.manage_exam")}
                                        </span>
                                    }
                                    key={tabs.exam}
                                >
                                    <div className="table-exam-list">
                                        <Table
                                            className="app-table"
                                            columns={examColumns}
                                            actions={examActions}
                                            dataSource={examDataSource}
                                            scroll={{ x: "auto" }}
                                            pagination={{
                                                showSizeChanger: false,
                                                ...examPagination,
                                                onChange: (page) => {
                                                    examFetch({ page: page });
                                                },
                                            }}
                                            loading={examLoading}
                                        />
                                    </div>
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    )}
                </div>
            </Spin>

            {/* Course detail modal: */}
            <Modal
                className="course-detail-modal"
                visible={values.isConfirmModal}
                onOk={handleDeleteStudent}
                onCancel={() => setValues({ ...values, isConfirmModal: false })}
                okText={t("action.delete")}
                cancelText={t("action.cancel")}
            >
                <span className="modal-icon">
                    <DeleteBin />
                </span>
                <p className="modal-title">{t("course.confirm_delete")}</p>
                <p>
                    {values.pendingDeleteItem?.name} {t("course.will_delete")}
                </p>
            </Modal>

            {/* Course management tab's modal: */}
            {/* <ModalAddStudent
                courseId={params?.id}
                isLoading={studentLoading}
                visible={isAddStudentModal}
                onOk={() => {
                    studentRefetch();
                    setIsAddStudentModal(false);
                }}
                onCancel={() => setIsAddStudentModal(false)}
            /> */}
            <ModalAddTeacher
                courseId={params?.id}
                visible={isAddTeacherModal}
                onOk={() => {
                    studentRefetch();
                    setIsAddTeacherModal(false);
                }}
                onCancel={() => setIsAddTeacherModal(false)}
            />
            <ModalInviteLink
                targetId={showModalInviteLink.assignmentId}
                visible={showModalInviteLink.isShown}
                onOk={() => {
                    setShowModalInviteLink({ ...showModalInviteLink, isShown: false });
                }}
                onCancel={() => {
                    setShowModalInviteLink({ ...showModalInviteLink, isShown: false });
                }}
            />

            <ModalDetailExam
                onlyAssignment={true}
                courseId={course_id}
                isLoading={examLoading}
                visible={isAssignExamModal}
                isOnMobile={values.isOnMobile}
                isShowFormSelect={values.isOnMobile}
                editingAssignment={false}
                examType={"exam"}
                setValuesExam={setValues}
                // categoryList={values.courseData?.level ? [values.courseData?.level] : []}
                categoryList={values.courseData?.level ? [values.courseData?.level] : []}
                language_id={values.courseData?.language_id}
                isHaveLanguage={true}
                onOk={() => {
                    examRefetch();
                    setIsAssignExamModal(false);
                    setValues({
                        isShowForm: false,
                    });
                }}
                onCancel={() => {
                    setIsAssignExamModal(false);
                    setValues({
                        isShowForm: false,
                        examType: "",
                    });
                }}
                destroyOnClose
            ></ModalDetailExam>

            {isMarkingModal && (
                <ModalMarkAssignment
                    className="course-detail-modal detail-marking"
                    centered
                    title={t("course.mark_assignment")}
                    footer={null}
                    visible={isMarkingModal}
                    onCancel={() => setIsMarkingModal(false)}
                    destroyOnClose
                    width="calc(100vw - 40px)"
                    height="calc(100vh - 40px)"
                    style={{
                        maxWidth: "1400px",
                        maxHeight: "1400px",
                        margin: "20px 0",
                    }}
                    closeIcon={<Icon name="icon-cross-thin" />}
                    markingAssignmentId={values.markingAssignmentId}
                    setIsPersonalLearningProgress={setIsPersonalLearningProgress}
                    setStudentInfo={setStudentInfo}
                />
            )}

            {isGeneralLearningProgress && (
                <ModalGeneralLearningProgress
                    className="course-detail-modal detail-learning-progress"
                    centered
                    title={t("report.general_learning_progress")}
                    footer={null}
                    visible={isGeneralLearningProgress}
                    onCancel={handleCloseModalGeneralLearningProgress}
                    destroyOnClose
                    width="calc(100vw - 40px)"
                    height="calc(100vh - 40px)"
                    style={{
                        maxWidth: "1400px",
                        maxHeight: "1400px",
                        margin: "20px 0",
                    }}
                    closeIcon={<Icon name="icon-cross-thin" />}
                    viewProgressAssignment={values.viewProgressAssignment}
                    viewProgressLevel={values.viewProgressLevel}
                    viewProgressCourse={values.viewProgressCourse}
                    viewProgressIsUnit={values.viewProgressIsUnit}
                />
            )}

            {isPersonalLearningProgress && (
                <ModalPersonalLearningProgress
                    title={t("report.personal_learning_progress")}
                    visible={isPersonalLearningProgress}
                    viewProgressCourse={values.viewProgressCourse}
                    viewProgressLevel={values.viewProgressLevel}
                    viewProgressIsUnit={values.viewProgressIsUnit}
                    onCancel={handleCloseModalPersonalLearningProgress}
                    viewProgressStudent={studentInfo}
                />
            )}

            <Modal
                title={t("history.editing_history")}
                visible={values.isModalHistory}
                onOk={() => {
                    setValues({ isModalHistory: false });
                }}
                onCancel={() => {
                    setValues({ isModalHistory: false });
                }}
                closeIcon={<CloseIconSVG />}
                footer={null}
                className={"modal-add-course modal_histories"}
                width={"auto"}
                destroyOnClose={true}
                centered={true}
            >
                <HistoryContainer modal_id={course_id} type="course_user_history" />
            </Modal>

            <PopConfirm
                type="delete"
                visible={values.isConfirmModalVisible}
                onOk={handleDeleteStudentFromCourse}
                onCancel={() => setValues({ isConfirmModalVisible: false })}
            />
        </div>
    );
};

const TableColumnFilterMenu = (props) => {
    const {
        cateName = "",
        cateNameNotFrom = -1,
        columnName = "",
        filterKeys = [],
        selectedFilterKey = -1,
        onChangeFilterKey = () => {},
    } = props;

    return (
        <ul className="table-column-filter-menu">
            {filterKeys.map((keyItem, keyIndex) => {
                return (
                    <li
                        key={`${columnName}-${keyIndex}`}
                        className={selectedFilterKey === keyItem.value ? "active" : ""}
                        onClick={() => onChangeFilterKey(keyItem.value)}
                    >
                        {cateNameNotFrom === -1 || keyIndex < cateNameNotFrom ? (
                            <>{t(`${cateName}.${keyItem.name}`)}</>
                        ) : (
                            <>{t(`${keyItem.name}`)}</>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default CourseDetail;
