import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Form, Input, Modal, notification, Select, Spin, Switch } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useValues } from "src/hooks";
import { fetchLevels } from "src/api/containers/level";
import { fetchTeachers } from "src/api/containers/user";
import { createCourse, findCourse, updateCourse } from "src/api/containers/course";
import PopupFileManager from "src/modules/components/FileManager/popup";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import Icon from "src/modules/components/Icon";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";
import "./ModalDetailCourse.scss";

const ModalDetailCourse = (props) => {
    const {
        courseId = "",
        languages = [],
        visible = true,
        onOk = () => {},
        onCancel = () => {},
        isClass,
        ...rest
    } = props;

    const user = useSelector((state) => state.auth.user);
    const isAllowFetchTeacherList = user.organization?.is_organization_owner || false;
    const [values, setValues] = useValues({
        loading: false,
        loadingTeachers: false,
        optionListLevel: [],
        optionListTeacher: [],
        currCourse: {},
    });
    const { t } = useTranslation();
    const [form] = useForm();
    const timeoutIdGetTeachers = useRef(null);
    const fmController = PopupFileManager.useController();
    // const dateFormat = "DD-MM-YYYY";
    const dateFormat = "YYYY-MM-DD";

    const handleCancelForm = (willFormBeCleared = false) => {
        if (!courseId && willFormBeCleared === true) {
            form.resetFields();
        }
        onCancel();
    };

    const handleAddCourse = (newCourseData) => {
        if (newCourseData) {
            newCourseData.is_class = isClass;
            setValues({ ...values, loading: true });
            createCourse(newCourseData).then((res) => {
                setValues({ ...values, loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.add_success"),
                    });
                    onOk();
                    handleCancelForm(true);
                } else {
                    notification.error({
                        message: res.message || t("message.add_error"),
                    });
                }
            });
        }
    };

    const handleUpdateCourse = (id, data) => {
        if (id && data) {
            data.is_class = values.currCourse.is_class;
            setValues({ ...values, loading: true });
            updateCourse(id, data).then((res) => {
                setValues({ ...values, loading: false });
                if (res.status) {
                    notification.success({
                        message: t("message.update_success"),
                    });
                    onOk();
                    handleCancelForm(true);
                } else {
                    notification.error({
                        message: res.message || t("message.update_error"),
                    });
                }
            });
        }
    };

    const handleSubmitForm = () => {
        let formData = form.getFieldsValue();
        if (!formData.name || !formData.code || !formData.language_id) {
            notification.warning({
                message: t("message.you_have_not_filled_enough_info"),
            });
            return;
        }
        if (formData.begin_date && formData.end_date && formData.begin_date > formData.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }
        formData = {
            ...formData,
            ...(formData.begin_date ? { begin_date: formData.begin_date.format("Y-M-D H:m:s") } : {}),
            ...(formData.end_date ? { end_date: formData.end_date.format("Y-M-D H:m:s") } : {}),
        };
        if (courseId) {
            handleUpdateCourse(courseId, formData);
        } else {
            handleAddCourse(formData);
        }
    };

    const handleSearchTeachers = (keyword) => {
        clearTimeout(timeoutIdGetTeachers.current);
        if (typeof keyword === "string") {
            setValues({ ...values, loadingTeachers: true, optionListTeacher: [] });
            timeoutIdGetTeachers.current = setTimeout(() => {
                fetchTeachers({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, loadingTeachers: false, optionListTeacher: res.data });
                    }
                });
            }, 500);
        }
    };

    const handleClearImage = (e) => {
        const value = e.target.value;
        if (typeof value === "string" && value.trim() !== "") {
            return e.target.value ? true : false;
        } else {
            form.setFieldsValue({
                image_id: null,
            });
        }
    };

    useEffect(() => {
        setValues({ ...values, loading: true });
        Promise.all([
            fetchLevels({ noPagination: true }),
            ...(isAllowFetchTeacherList
                ? [fetchTeachers({ noPagination: true, user_organization: isAllowFetchTeacherList })]
                : []),
        ]).then((res) => {
            if (res[0].status && !res[1]) {
                setValues({
                    ...values,
                    loading: false,
                    optionListLevel: res[0].data ? res[0].data : [],
                });
            } else if (res[0].status && res[1].status) {
                setValues({
                    ...values,
                    loading: false,
                    optionListLevel: res[0].data ? res[0].data : [],
                    optionListTeacher: res[1].data ? [user, ...res[1].data] : [user],
                });
            } else {
                setValues({ ...values, loading: false });
            }
        });
    }, []);

    useEffect(() => {
        if (visible) {
            if (courseId) {
                setValues({ ...values, loading: true });
                findCourse(courseId, { relations: ["image"] }).then((res) => {
                    if (res.status) {
                        form.setFieldsValue({
                            name: res.data.name || "",
                            code: res.data.code || "",
                            image_id: res.data.image_id || undefined,
                            image_name: res.data.image?.name || undefined,
                            begin_date: res.data.begin_date ? moment(new Date(res.data.begin_date), dateFormat) : "",
                            end_date: res.data.end_date ? moment(new Date(res.data.end_date), dateFormat) : "",
                            level_id: res.data.level_id || undefined,
                            language_id: res.data.language_id || undefined,
                            teacher_ids: res.data.teachers?.map((t) => t._id) || [],
                            is_show: res.data?.is_show,
                            is_class: res.data?.is_class,
                        });
                        setValues({ ...values, loading: false, currCourse: res.data });
                    } else {
                        setValues({ ...values, loading: false });
                    }
                });
            } else {
                form.resetFields();
            }
        }
    }, [courseId]);

    const renderModalTitle = () => {
        if (isClass) {
            return courseId ? t("course.update_class") : t("course.add_class");
        } else {
            return courseId ? t("course.update_course") : t("course.add_course");
        }
    };

    return (
        <Modal
            visible={visible}
            onOk={handleSubmitForm}
            // onOk={form.submit}
            onCancel={handleCancelForm}
            centered
            title={renderModalTitle()}
            closeIcon={<IconClose />}
            footer={null}
            width="auto"
            {...rest}
            className={`modal-add-course${props.className ? " " + props.className : ""}`}
        >
            <Spin spinning={values.loading}>
                {props.children ? (
                    props.children
                ) : (
                    <Form form={form}>
                        <Form.Item
                            label={t("course.name")}
                            name="name"
                            rules={[{ required: true, message: t("course.input_course_name") }]}
                        >
                            <Input placeholder={t("course.input_course_name")} maxLength={100} />
                        </Form.Item>

                        <Form.Item
                            label={isClass ? t("course.class_code") : t("course.code")}
                            name="code"
                            rules={[{ required: true, message: t("course.input_course_code") }]}
                        >
                            <Input placeholder={t("course.input_course_code")} maxLength={100} />
                        </Form.Item>
                        <Form.Item
                            label={t("language.language")}
                            name="language_id"
                            rules={[{ required: true, message: t("language.select_language") }]}
                        >
                            <Select
                                className="app-select"
                                placeholder={t("language.select_language")}
                                optionFilterProp="children"
                                showArrow
                                showSearch
                                allowClear
                            >
                                {languages?.map((opt, i) => {
                                    return (
                                        <Select.Option key={`language${i}`} value={opt.id}>
                                            {opt.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("course.level")}
                            name="level_id"
                            // rules={[{ required: true, message: t("course.select_level") }]}
                        >
                            <Select
                                className="app-select"
                                placeholder={t("course.select_level")}
                                optionFilterProp="children"
                                showArrow
                                showSearch
                                allowClear
                            >
                                {values.optionListLevel.map((opt, i) => {
                                    return (
                                        <Select.Option key={`level${i}`} value={opt.id}>
                                            {opt.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("course.begin_date")}
                            name="begin_date"
                            // rules={[{ required: true, message: t("course.input_begin_date") }]}
                        >
                            <DatePicker placeholder={t("course.input_begin_date")} format={dateFormat} />
                        </Form.Item>

                        <Form.Item
                            label={t("course.end_date")}
                            name="end_date"
                            // rules={[{ required: true, message: t("course.input_end_date") }]}
                        >
                            <DatePicker placeholder={t("course.input_end_date")} format={dateFormat} />
                        </Form.Item>

                        <div className="course-bgimage-field">
                            <Form.Item
                                label={t("course.bgImage")}
                                name="image_name"

                                // rules={[{ required: true, message: t("course.input_course_bgImage") }]}
                            >
                                <Input
                                    placeholder={t("course.input_course_bgImage")}
                                    allowClear
                                    onChange={handleClearImage}
                                />
                            </Form.Item>
                            <Form.Item
                                hidden
                                label={t("course.bgImage")}
                                name="image_id"
                                // rules={[{ required: true, message: t("course.input_course_bgImage") }]}
                            >
                                <Input placeholder={t("course.input_course_bgImage")} />
                            </Form.Item>
                            <CustomButton
                                type="grey"
                                icon={<FileImageOutlined />}
                                title={undefined}
                                onClick={() => {
                                    fmController.call("open");
                                }}
                            ></CustomButton>
                        </div>

                        {user && user.organization?.is_organization_owner === true ? (
                            <Form.Item
                                label={t("course.teacher")}
                                name="teacher_ids"
                                // rules={[{ required: true, message: t("course.input_teacher_name") }]}
                            >
                                <Select
                                    className="app-select"
                                    placeholder={t("course.input_teacher_name")}
                                    optionFilterProp="children"
                                    showArrow
                                    showSearch
                                    allowClear
                                    // onSearch={(keyword) => {
                                    //     handleSearchTeachers(keyword);
                                    // }}
                                    // notFoundContent={
                                    //     values.loadingTeachers === true ? (
                                    //         <i>
                                    //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                    //         </i>
                                    //     ) : undefined
                                    // }
                                >
                                    {values.optionListTeacher.map((opt, i) => {
                                        return (
                                            <Select.Option key={`teacher${i}`} value={opt.id}>
                                                {opt.name || "(Unknown)"}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        ) : null}

                        <Form.Item
                            label={t("shared.status")}
                            name="is_show"
                            valuePropName="checked"
                            hidden={user?.role !== "admin"}
                        >
                            <Switch
                                defaultChecked
                                checkedChildren={t("shared.show")}
                                unCheckedChildren={t("shared.hide")}
                            ></Switch>
                        </Form.Item>

                        <div className="btn-group">
                            <CustomButton
                                type="grey"
                                icon={<Icon name="icon-delete" />}
                                title={t("action.cancel")}
                                onClick={handleCancelForm}
                            ></CustomButton>
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<Icon name="icon-save" />}
                                title={t("action.save")}
                                onClick={handleSubmitForm}
                            ></CustomButton>
                        </div>
                    </Form>
                )}
            </Spin>

            <PopupFileManager
                controller={fmController}
                isMultiple={false}
                onSelectFile={(selectedFile) => {
                    if (selectedFile?.id && selectedFile?.src) {
                        if (validateFileBeforeUpload(selectedFile.src).type === "image") {
                            form.setFieldsValue({
                                ...form.getFieldsValue(),
                                image_id: selectedFile.id,
                                image_name: selectedFile.name,
                            });
                            fmController.call("close");
                        } else {
                            const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                            notification.error({
                                message: (
                                    <>
                                        <div>{`
                                            ${t("message.err_file_extension_not_valid")}.
                                            ${t("message.please_try_another_file_extension")}!
                                        `}</div>
                                        <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                    </>
                                ),
                            });
                        }
                    }
                }}
            />
        </Modal>
    );
};

export default ModalDetailCourse;
