/**
 * Check iff number is phone number.
 * @param {Number} value Number.
 * @returns Message error or nothing.
 */
export const validatePhoneNumber = (value) => {
    const phoneNumberPattern = /^\d{10}$/;
    if (!phoneNumberPattern.test(value)) {
        return "Please enter a valid 10-digit phone number!";
    }
    return;
};
