import { Input, InputNumber } from "antd";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getQuestionTypesExclude } from "src/api/helpers/question";
import Icon from "src/modules/components/Icon";
import QuestionContent from "../QuestionContent";
import "./QuestionResultPanel.scss";

function QuestionResultPanel(props) {
    const {
        mode = "",
        type = "",
        isMarking,
        markedQuestions,
        markingQuestion,
        record = null,
        order,
        typeViewScore, // "preview" or "review"
        setRecord = () => {},
    } = props;

    const { t } = useTranslation();
    const [note, setNote] = useState("");

    const questionInfo = useMemo(() => {
        const currentQuestion = record.history[order - 1];

        const generalProps = {
            ...currentQuestion,
        };

        // Tính tổng điểm của câu passage hiện tại backend chưa trả về.
        if (currentQuestion?.type === "passage") {
            generalProps.score_submit = currentQuestion.paragraphs.reduce((prevValue, currentValue) => {
                const result =
                    prevValue +
                    currentValue.question_details?.reduce((prevChildQuestionValue, currentChildQuestionValue) => {
                        return prevChildQuestionValue + currentChildQuestionValue?.score_submit || 0;
                    }, 0);
                return result;
            }, 0);
        }

        if (isMarking) {
            generalProps.score_teacher_submit = currentQuestion?.score_teacher_submit;
            generalProps.note = currentQuestion?.note;
            generalProps.score_teacher_marking =
                markedQuestions[currentQuestion.id]?.score ?? currentQuestion?.score_teacher_submit;

            switch (currentQuestion.type) {
                case "passage":
                    generalProps.paragraphs = currentQuestion?.paragraphs?.map((p) => {
                        const questionDetails = p?.question_details?.map((q) => {
                            const generalChildProps = {
                                ...q,
                            };

                            if (isMarking) {
                                generalChildProps.score_teacher_submit = q?.score_teacher_submit || 0;

                                generalChildProps.score_teacher_marking =
                                    markedQuestions[currentQuestion.id]?.[q.id]?.score ?? q?.score_teacher_submit ?? 0;
                            }

                            return generalChildProps;
                        });

                        return {
                            ...p,
                            question_details: questionDetails,
                        };
                    });
                    return generalProps;
                default:
                    return generalProps;
            }
        }

        return generalProps;
    }, [order, markedQuestions]);

    useEffect(() => {
        if (isMarking) {
            setNote(markedQuestions[questionInfo.id]?.note || questionInfo.note);
        } else {
            setNote(questionInfo.note);
        }
    }, [order]);

    const handleNextQuestion = () => {
        if (order >= record?.history?.length) {
            return;
        } else {
            setRecord({
                data: record,
                order: order + 1,
            });
        }
    };

    const handlePreviousQuestion = () => {
        if (questionInfo?.order <= 1) {
            return;
        } else {
            setRecord({
                data: record,
                order: order - 1,
            });
        }
    };

    const handleWriteComment = (e) => {
        // const idQuestion = questionInfo?.history?.[questionInfo?.order]?.id;
        const note = e.target.value;

        if (isMarking) {
            markingQuestion(questionInfo.id, {
                note,
                oldScore: questionInfo?.score_teacher_submit ?? questionInfo?.score_submit,
            });
            setNote(note);
        }
    };

    const handleMarkWriting = (score) => {
        if (!score) score = 0;

        if (isMarking) {
            markingQuestion(questionInfo.id, {
                score,
                oldScore: questionInfo?.score_teacher_submit ?? questionInfo?.score_submit,
            });
        }
    };

    const handleMarkWritingInPassage = (score, oldScore, questionId, childId) => {
        markingQuestion(questionId, {
            score,
            oldScore,
            childId,
        });
    };

    return (
        <div className="question-result-panel">
            <div className="question-number-wrapper">
                <div className="question-number">
                    {questionInfo?.order ? `${questionInfo?.order < 10 ? "0" : ""}${questionInfo?.order}` : "00"}
                </div>
            </div>

            <QuestionContent
                questionInfo={questionInfo}
                handleMarkWritingInPassage={handleMarkWritingInPassage}
                showQuestionNumber
                questionNumber={questionInfo?.order}
                styleShowQuestion="circle"
                isMarking={isMarking}
                type={typeViewScore}
            />

            {mode !== "statistical" && (
                <>
                    {isMarking && getQuestionTypesExclude(["passage"]).includes(questionInfo.type) ? (
                        <div className="teacher-mark">
                            <strong>{t("shared.score")}:</strong>
                            <InputNumber
                                className="app-input"
                                value={
                                    questionInfo.score_teacher_marking ??
                                    questionInfo.score_teacher_submit ??
                                    questionInfo.score_submit
                                }
                                onChange={handleMarkWriting}
                                min={0}
                                max={questionInfo.score}
                            />
                            <span>
                                /{questionInfo?.score}
                                {questionInfo?.score_teacher_submit !== questionInfo?.score_submit &&
                                    ` - ${t("scored_manually")}`}
                            </span>
                        </div>
                    ) : (
                        <>
                            {!isMarking && (
                                <div
                                    className={clsx(
                                        "student-view-mark",
                                        (questionInfo.score_teacher_submit ?? questionInfo.score_submit) && "correct"
                                    )}
                                >
                                    <strong>{t("shared.score")}:</strong>
                                    <span>
                                        {questionInfo.score_teacher_submit ?? questionInfo?.score_submit ?? 0}/
                                        {questionInfo?.score || 0}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    <div className="question-comment">
                        <div className="question-comment-title">{t("exam_result.teacher_comment")}</div>
                        <div className={clsx("question-comment-content", isMarking && "role-teacher")}>
                            <Input.TextArea
                                value={note}
                                onChange={(e) => handleWriteComment(e)}
                                rows={4}
                                placeholder={
                                    !isMarking ? t("exam_result.no_comment") : `${t("exam_result.teacher_comment")}...`
                                }
                                maxLength={500}
                                style={{ resize: "none" }}
                                readOnly={!isMarking}
                            />
                        </div>
                    </div>
                </>
            )}

            <div className="question-nav">
                <span className="nav-btn-wrapper" onClick={handlePreviousQuestion}>
                    <span className="nav-btn prev">
                        <Icon name="icon-nav-prev" fill="#fff"></Icon>
                    </span>
                </span>
                <span className="nav-btn-wrapper" onClick={handleNextQuestion}>
                    <span className="nav-btn next">
                        <Icon name="icon-nav-next" fill="#fff"></Icon>
                    </span>
                </span>
            </div>
        </div>
    );
}

export default QuestionResultPanel;
