import { ReactComponent as FlagVi } from "src/assets/images/language/flag_vi.svg";
import { ReactComponent as FlagEn } from "src/assets/images/language/flag_en.svg";

export const languageList = [
    {
        key: "vi",
    },
    {
        key: "en",
    },
];

const IconLanguageFlag = (props) => {
    const { name = "vi", ...rest } = props;

    switch (name) {
        case "vi":
            return <FlagVi {...rest} />;
        case "en":
            return <FlagEn {...rest} />;
        default:
            return null;
    }
};

export default IconLanguageFlag;
