import { Input } from "antd";
import clsx from "clsx";
import "./InputSuffix.scss";

const InputSuffix = (props) => {
    const { className = "", suffix, ...rest } = props;
    return (
        <Input
            {...rest}
            className={clsx("input-suffix", className)}
            {...(suffix ? { suffix: <div className="delete-button">{suffix}</div> } : {})}
        />
    );
};

export default InputSuffix;
