import React, { useEffect, useRef, useState } from "react";
// import ReactPlayer from "react-player";
import ReactPlayerLayzyLoad from "react-player/lazy";

import { useValues } from "src/hooks";
import fullscreen_element from "src/utils/helpers/webAPIs/fullscreen_element";

import VideoControls from "./VideoControls";
import "./VideoPlayer.scss";

const demo_url =
    "https://toeduvn-dev.sgp1.digitaloceanspaces.com/dev/6308d5aad98258390b0c98b6/bd8d443ec66fe818ef80f51bf4afdc6c.webm";

function VideoPlayer({ videoData, listViolations = [], seekTo }) {
    const playerRef = useRef(null);
    const videoContainerRef = useRef(null);

    const [values, setValues] = useValues({
        pip: false,
        playing: true,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        playedSeconds: 0,
        loaded: 0,
        duration: 1,
        playbackRate: 1.0,
        loop: false,
        seeking: false,
        isFullScreen: false,
    });

    const handlePlayPause = () => {
        setValues({ playing: !values.playing });
    };
    const handlePlay = () => {
        setValues({ playing: true });
    };
    const handlePause = () => {
        setValues({ playing: false });
    };
    function handleSeekMouseDown() {
        setValues({ seeking: true });
    }

    function setSeekValue(v) {
        setValues({ played: parseFloat(v) });
    }

    function handleSeekMouseUp(v) {
        setValues({ seeking: false });
        playerRef.current.seekTo(parseFloat(v));
    }

    function handleChangePlaybackRate(item) {
        setValues({ playbackRate: item.key });
    }

    const handleVolumeChange = (v) => {
        setValues({ volume: parseFloat(v) });
    };

    const handleToggleMuted = () => {
        setValues({ muted: !values.muted });
    };

    const handleDuration = (duration) => {
        setValues({ duration });
    };

    const handleClickFullscreen = () => {
        const ele = document.querySelector(".video-player_inner");
        if (ele && fullscreen_element.isEnabled) {
            fullscreen_element.toggle(ele, null, setValues({ isFullScreen: !values.isFullScreen }));
        }
    };
    const handleProgress = (state) => {
        // set played, loaded,....

        // We only want to update time slider if we are not currently seeking
        if (!values.seeking) {
            setValues(state);
        }
    };

    const handleOnPlaybackRateChange = (speed) => {
        setValues({ playbackRate: parseFloat(speed) });
    };

    const handleEnded = () => {
        setValues({ playing: values.loop });
    };

    useEffect(() => {
        if (seekTo) {
            seekTo.current = (time) => {
                playerRef.current.seekTo(time);
            };
        }
    }, [seekTo]);

    return (
        <div className="video-player">
            <div className="video-player_container">
                <div className="video-player_inner" ref={videoContainerRef}>
                    <div className="video-player_area flex flex-center">
                        {/* <div className="video-wrapper">
                            <div className="video-content"> */}
                        <ReactPlayerLayzyLoad
                            url={videoData?.src}
                            ref={playerRef}
                            className="react-player"
                            width="100%"
                            height="78%"
                            pip={values.pip}
                            playing={values.playing}
                            playbackRate={values.playbackRate}
                            volume={values.volume}
                            muted={values.muted}
                            onReady={() => console.log("onReady")}
                            onStart={() => console.log("onStart")}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            //  onEnablePIP={this.handleEnablePIP}
                            //  onDisablePIP={this.handleDisablePIP}
                            onBuffer={() => console.log("onBuffer")}
                            onPlaybackRateChange={handleOnPlaybackRateChange}
                            onSeek={(e) => console.log("onSeek", e)}
                            onEnded={handleEnded}
                            onError={(e) => console.log("onError", e)}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            onPlaybackQualityChange={(e) => {}}
                        />
                        {/* </div>
                        </div> */}
                    </div>
                    <VideoControls
                        isFullScreen={values.isFullScreen}
                        {...values}
                        handleChangePlaybackRate={handleChangePlaybackRate}
                        onToggleFullScreen={handleClickFullscreen}
                        handleSeekMouseDown={handleSeekMouseDown}
                        handleSeekMouseUp={handleSeekMouseUp}
                        setSeekValue={setSeekValue}
                        handlePlayPause={handlePlayPause}
                        listViolations={listViolations}
                        handleToggleMuted={handleToggleMuted}
                        handleVolumeChange={handleVolumeChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default VideoPlayer;
