import { useEffect, useMemo, useState } from "react";
import CRUDFolder from "./CRUDFolder";
import Confirm from "./Confirm";
import Content from "./Content";
import NewFolderModal from "./NewFolderModal";
import MoveToModal from "./MoveToModal";
import Notification from "./Notification";
import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";
import useController from "./useController";
import Header from "./Header";
import Shared from "./Shared";
import "./index.scss";

const FileManager = ({
    controller,
    title = "File Manager",
    containerStyle,
    isMultiple = false,
    isHeaderVisible = true,
    onMakeFolder = () => {},
    onUploadFiles = () => {},
    onListFolders = () => {},
    onListFiles = () => {},
    onSelectFile = () => {},
    onRemoveFiles = () => {},
    onRenameFile = () => {},
    onMoveFilesToFolder = () => {}, // Change directory for files and folder.
}) => {
    const [active, setActive] = useState(false);
    const internal = useController();

    const extraClassnames = useMemo(() => {
        let r = "";
        if (isHeaderVisible === false) {
            r += " disable-header";
        }
        return r;
    }, [isHeaderVisible]);

    useEffect(() => {
        controller.set("open", () => {
            setActive(true);
        });
        controller.set("close", () => {
            setActive(false);
            controller.call("after_close");
        });
        internal.set("close", () => controller.call("close"));
        return () => {
            internal.remove("close");
        };
    }, []);

    if (!active) {
        return <></>;
    }

    return (
        <div className={`file-manager-container${extraClassnames}`} style={containerStyle}>
            <CRUDFolder controller={internal} makeFolder={onMakeFolder} />

            {isHeaderVisible ? <Header controller={internal} title={title} /> : null}
            <Toolbar controller={internal} uploadFiles={onUploadFiles} />
            <Shared controller={internal} />
            <div className="file-manager-body">
                <Sidebar controller={internal} listFolders={onListFolders} removeFiles={onRemoveFiles} />
                <Content
                    controller={internal}
                    listFiles={onListFiles}
                    selectFile={onSelectFile}
                    uploadFiles={onUploadFiles}
                    removeFiles={onRemoveFiles}
                    renameFile={onRenameFile}
                    isMultiple={isMultiple}
                />
            </div>
            <Confirm controller={internal} />
            <NewFolderModal controller={internal} makeFolder={onMakeFolder} />
            <MoveToModal controller={internal} listFolders={onListFolders} moveFilesToFolder={onMoveFilesToFolder} />
            <Notification controller={internal} />
        </div>
    );
};

FileManager.useController = () => {
    return useController();
};

export default FileManager;
