import clsx from "clsx";
import { t } from "i18next";
import Audio from "src/modules/components/Audio";

const MultipleChoiceTable = (props) => {
    const { value } = props;

    return (
        <>
            <div className="detail_phonetic_table">
                <div className="phonetictable__table-skin">
                    <table className="phonetictable__table__skeleton">
                        <tbody>
                            {value?.map((vRow, iRow) => {
                                return (
                                    <tr className={clsx(iRow === 0 && "sticky-row")} key={iRow}>
                                        {vRow.map((vCol, iCol) => {
                                            if (iCol === 0 && iRow === 0) {
                                                return (
                                                    <td key={iCol} className="sticky-col first-col">
                                                        <div></div>
                                                    </td>
                                                );
                                            }

                                            if (iCol === 1) {
                                                if (iRow === 0)
                                                    return (
                                                        <td key={iCol}>
                                                            <div>{t("audio")}</div>
                                                        </td>
                                                    );
                                                return (
                                                    <td key={iCol}>
                                                        <div className="audio-item">
                                                            <span>{vCol && <Audio src={vCol} />}</span>
                                                        </div>
                                                    </td>
                                                );
                                            }

                                            return (
                                                <td className={clsx(iCol === 0 && "sticky-col first-col")} key={iCol}>
                                                    <div>{vCol}</div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default MultipleChoiceTable;
