import { Dropdown, Layout, notification } from "antd";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ExitIcon from "src/assets/images/exit.svg";
import HaveNotificationIcon from "src/assets/images/HaveNotification.svg";
import DropdownIcon from "src/assets/images/icon-dropdown.svg";
import NoAvatar from "src/assets/images/no-avatar.png";
import NotificationIcon from "src/assets/images/Notification.svg";
import PencilIcon from "src/assets/images/pencil.svg";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useAuth } from "src/modules/auth";
import Button from "src/modules/components/Button";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { removeRecord } from "src/reducers/answer";
import { gotoQuestionOrderTempTime, handleSubmitRecord, removeTest, setIsShowConfirmModal } from "src/reducers/test";
import { convertQuestionState, getAllSearchParams, objectToSearchParamsString } from "src/utils/helpers";
import { useRouting } from "src/utils/router";
import { logout } from "src/reducers/auth";
import Clock from "../../../../components/Clock";
import Menu from "./Menu";
import NumberQuestion from "./NumberQuestion";
import StudentFeatureDropDown from "./StudentFeatureDropDown";
import { ScreenCamMicRecorderContext } from "src/modules/containers/ExamProctoring/components/ScreenCamMicRecorder";
import "./TestLayout_Sidebar.scss";
import { closeFullscreen } from "src/utils/AntiCheating";

const Sidebar = (props) => {
    const recording = useContext(ScreenCamMicRecorderContext);

    const dispatch = useDispatch();
    const { listTest = [], listOrderAnswered = [] } = props;
    const { listAnswers } = useSelector((state) => state.answer);
    const { isReadonly } = useSelector((state) => state.clock);
    const { isShowConfirmModal, listRecord } = useSelector((state) => state.test);
    const currMedia = useMediaQuery(["(min-width: 1023px)"]);
    const [recordInfo, setRecordInfo] = useState({});
    const { generate } = useRouting();
    const navigate = useNavigate();
    const params = useParams();
    const { user } = useAuth();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (params.id) {
            setRecordInfo(listRecord[params.id]);
        }
    }, [listRecord, params]);

    const handleExit = () => {
        navigate(generate("exam_asset"));

        setTimeout(() => {
            closeFullscreen();

            if (recording?.stopRecording instanceof Function) {
                recording?.stopRecording();
            }
        }, 1000);
    };

    const onChangeQuestionOrder = (order) => {
        dispatch(
            gotoQuestionOrderTempTime({
                order,
                recordId: recordInfo?.record_id,
                questionId: recordInfo.questions[order - 1].id,
            })
        );
    };

    const handleSubmit = async () => {
        // if (
        //     !localStorage.getItem("listTimePerQuestion") ||
        //     Object.values(localStorage.getItem("listTimePerQuestion"))[0]
        // ) {
        //     onChangeQuestionOrder(2);
        // }
        const res = await dispatch(
            handleSubmitRecord({
                recordId: recordInfo.record_id,
            })
        );

        const { status, message } = res.payload;

        if (status) {
            notification.success({
                message: t("test.submit_record_success"),
            });

            dispatch(
                removeRecord({
                    recordId: recordInfo?.record_id,
                })
            );

            dispatch(removeTest());

            handleExit();
        } else {
            notification.error({
                message,
            });
        }

        dispatch(setIsShowConfirmModal(false));

        setTimeout(() => {
            if (recordInfo?.record_id) {
                // const params = getAllSearchParams(searchParams);
                // if (params.prev === "gradebook") {
                //     navigate(generate("score", { id: recordInfo.record_id }) + objectToSearchParamsString(params));
                // } else {
                //     navigate(generate("score", { id: recordInfo.record_id }) + "?prev=test")
                // };
            } else {
                // navigate(generate("courses"));
            }
        }, 200);

        closeFullscreen();
    };

    return (
        <>
            <Layout.Sider
                className="test_sidebar scroll_primary"
                theme="light"
                width={352}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <div className="SiderHeader">
                    <div className="SiderHeader_notification">
                        <Dropdown placement="bottomRight" overlay={<Menu listTest={listTest} />} trigger={["click"]}>
                            <img
                                src={listTest?.length ? HaveNotificationIcon : NotificationIcon}
                                width="24px"
                                height="30px"
                            />
                        </Dropdown>
                    </div>
                    <Dropdown overlay={<StudentFeatureDropDown />} trigger={["click"]}>
                        <div className="SiderHeader_Account">
                            <div className="SiderHeader_Account_avatarWrapper">
                                <img className="SiderHeader_Account_avatar" src={user.avatar || NoAvatar} />
                            </div>
                            <div className="SiderHeader_Account_info">
                                <div className="SiderHeader_Account_name">{user.name}</div>
                                <div className="SiderHeader_Account_email">{user.username}</div>
                            </div>
                            <div className="SiderHeader_Account_arrowWrapper">
                                <img src={DropdownIcon} />
                            </div>
                        </div>
                    </Dropdown>
                </div>

                {params.id && (
                    <>
                        {currMedia.breakpointValue === 1023 && <Clock isTitle={true} isLoading={false} />}
                        {isReadonly && <div className="test_timeoutTest">{t("test.timeout_test")}</div>}
                        <NumberQuestion
                            amount={recordInfo?.questions?.length}
                            listOrderAnswered={convertQuestionState(
                                recordInfo?.questions,
                                listAnswers[recordInfo?.record_id]
                            )}
                            onChangeQuestionOrder={onChangeQuestionOrder}
                        />
                        <div className="Submit_Test">
                            <Button type="grey" icon={ExitIcon} onClick={handleExit} title={t("test.exit")}></Button>
                            <Button
                                type="pink"
                                onClick={() => {
                                    dispatch(setIsShowConfirmModal(true));
                                }}
                                icon={PencilIcon}
                                title={t("test.submit")}
                            ></Button>
                        </div>
                    </>
                )}
            </Layout.Sider>

            <PopConfirm
                type="delete"
                visible={isShowConfirmModal}
                onOk={handleSubmit}
                onCancel={() => dispatch(setIsShowConfirmModal(false))}
                okText={t("test.submit")}
                cancelText={t("test.cancel")}
            >
                <p className="modal-title" style={{ margin: 0 }}>
                    {t("test.confirm_submit")}
                </p>
            </PopConfirm>
        </>
    );
};

export default Sidebar;
