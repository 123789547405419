import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { fetchRecordByUserId } from "src/api/containers/record";
import { useFetch } from "src/hooks";
import { useRouting } from "src/utils/router";
import "./TestLayout_Sidebar_menu.scss";

const Menu = (props) => {
    const { listTest } = props;
    let navigate = useNavigate();
    const { generate } = useRouting();

    const openNotificationWithIcon = (messageError) => {
        notification.error({
            message: messageError,
        });
    };

    const handleStartTest = (testItem) => {
        const endDate = new Date(testItem.end_date);
        const beginDate = new Date(testItem.begin_date);

        const totalTimeSecond = endDate.getTime() / 1000 - beginDate.getTime() / 1000;

        if (Date.now() < beginDate.getTime()) {
            openNotificationWithIcon("Chưa đến giờ làm bài");
            return;
        }

        if (Date.now() > endDate.getTime()) {
            openNotificationWithIcon("Hết hạn làm bài");
            return;
        }

        navigate(generate(`test`, { id: testItem.id }));
    };

    return (
        <div className="notification_menu">
            <div className="notification_title">Danh sách bài tập của bạn</div>
            <div className="notification_testList">
                {listTest.map((testItem) => (
                    <div key={testItem.id} className="notification_testItem">
                        <div className="testItem_name">{testItem.name}</div>
                        <Button
                            type="primary"
                            className="testItem_button"
                            loading={false}
                            onClick={() => handleStartTest(testItem)}
                        >
                            Làm bài
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Menu;
