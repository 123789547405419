import React from "react";

function IconDelete(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6354 10.9092H12.7263H11.5142V18.1819V19.091V20.0001H13.6354C14.1374 20.0001 14.5445 19.593 14.5445 19.091V10.9092H13.6354Z" />
            <path d="M6.66602 10.9092V18.1819V19.091V20.0001H9.69632V19.091V18.1819V10.9092H6.66602Z" />
            <path d="M3.63603 10.9092H2.72693H1.81787V19.091C1.81787 19.593 2.2249 20.0001 2.72697 20.0001H4.84818V19.091V18.1819V10.9092H3.63603Z" />
            <path d="M15.4545 7.27272H14.5454V4.54546C14.5454 4.04339 14.1384 3.63636 13.6363 3.63636H12.1212V0.9091C12.1212 0.407031 11.7142 0 11.2121 0H5.15151C4.64944 0 4.24241 0.407031 4.24241 0.9091V3.63636H2.72726C2.22519 3.63636 1.81816 4.04339 1.81816 4.54546V7.27272H0.9091C0.407031 7.27272 0 7.67967 0 8.18182C0 8.68381 0.407031 9.09092 0.9091 9.09092H1.8182H2.7273H3.6364H4.84851H6.66667H9.69697H11.5151H12.7272H13.6363H14.5454H15.4545C15.9566 9.09092 16.3636 8.68381 16.3636 8.18182C16.3636 7.67967 15.9566 7.27272 15.4545 7.27272ZM10.303 3.63636H6.06061V1.81816H10.303V3.63636Z" />
        </svg>
    );
}

export default IconDelete;
