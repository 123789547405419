import React, { useEffect, useState, forwardRef } from "react";
import {
    Col,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Space,
    Tooltip,
    Typography,
    Button as AntdButton,
} from "antd";
import { useTranslation } from "react-i18next";
import Editor from "src/modules/components/Editor";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import { fetchSpeaker } from "src/api/containers/language";
import { useValues } from "src/hooks";
import { useSearchParams } from "react-router-dom";
import configEditor from "src/utils/configEditor";
const { TextArea } = Input;
import { CheckCircleOutlined, CloudUploadOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Button from "src/modules/components/Button";
import clsx from "clsx";
import "./DetailConversationAudio.scss";
import objectHelper from "src/utils/helpers/object";
import ListAnswer from "./components/ListAnswer";

const editorConfigQuestion = configEditor.question();

const conversationItemData = {
    order: 0,
    actor: "",
    value: "",
    // src: "",
    is_user: false,
};

const defaultListActor = [
    { actor: "A", order: 0 },
    { actor: "B", order: 1 },
];

const DetailConversationAudio = (props, languageRef) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
        language,
    } = props;

    const { t } = useTranslation();
    const [detailData, setDetailData] = useValues({
        questionData: undefined,
        questionFormData: undefined,
        listSpeaker: [],
        lang: "",
        listActor: [],
        answer: [{ ...conversationItemData }],
        actorSelected: {},
        language,
    });

    const lstActor = Form.useWatch("listActors", form);

    const [searchParams, setSearchParams] = useSearchParams();
    let langKey = searchParams.get("lang"); // câu passage có speech to text sẽ cần có params này
    let langId = searchParams.get("language_id"); // câu passage có speech to text sẽ cần có params này

    const prepareFormDataForAction = (isAllFieldsValid) => {
        // return;
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();
            // Data:
            let answData = _formData?.answer;
            answData = fortmatAnswer(detailData?.actorSelected?.actor, answData);

            const _questionData = {
                type: "speech_to_text_conversation_audio",
                speaker: detailData.listSpeaker?.length > 1 ? _formData.speaker : detailData.listSpeaker?.[0],
                answer: answData,
                language_id: langId || _formData?.language_id,
                voice: detailData.voice,
            };
            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: answData,
                // speaker: [_formData.speaker],
                speaker: detailData.listSpeaker?.length > 1 ? [_formData.speaker] : detailData.listSpeaker,
                language: detailData.language || detailData.questionData?.language,
                type: _questionData.type,
                isPreview: true,
                voice: detailData.voice,
            };

            // Return form data:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };
    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                if (!detailData.actorSelected?.actor) {
                    notification.warning({
                        message: t("speech_to_text_conversation.havent_choose_actor"),
                    });
                    return;
                }

                if (form.getFieldValue("answer")?.length < 1) {
                    notification.warning({
                        message: t("message.warning_missing_fields"),
                    });
                    return;
                }
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const fortmatAnswer = (selectedActor, answer) => {
        return answer?.map((item, idx) => ({
            ...item,
            is_user: selectedActor === item?.actor,
            order: idx,
        }));
    };

    const handleSetActor = (index) => {
        const ans = form?.getFieldValue("answer");
        if (ans?.length > 1) {
            notification.warn({ message: t("speech_to_text_conversation.notAllow_change_actor") });
            return;
        }
        const actors = form?.getFieldValue("listActors");
        let item = actors?.[index];
        setDetailData({
            actorSelected: item,
        });
        // console.log(newAns);
    };

    const getSpeakerOptions = (speaker) => {
        if (Array.isArray(speaker) && speaker.length > 1) {
            return speaker.map((item) => ({ label: item?.[1], value: item?.[0] }));
        }
        return speaker;
    };

    languageRef.current = (lang) => {
        form.setFieldsValue({
            speaker: null,
            voice: null,
        });

        fetchSpeaker({ code: lang }).then(({ status, data }) => {
            const speakers = getSpeakerOptions(data);
            if (status) {
                setDetailData({
                    listSpeaker: speakers,
                    lang: lang,
                });
            }
        });
    };

    const getActiveActor = (answer, listActor) => {
        for (let i = 0; i < answer?.length; i++) {
            if (answer?.[i]?.is_user && Array.isArray(listActor)) {
                const idx = listActor?.findIndex((item) => item?.actor == answer?.[i]?.actor);
                setDetailData({
                    actorSelected: {
                        actor: answer?.[i]?.actor,
                        order: idx,
                    },
                });

                break; // break out of the loop when i is equal to 5
            }
        }
    };

    const getlistActor = (answer) => {
        if (Array.isArray(answer)) {
            const arr = answer.map((item) => item?.actor);
            const uniq = [...new Set(arr)];
            const actors = uniq.map((item, idx) => ({ actor: item, order: idx }));
            return actors;
        }
        return [];
    };

    const handleChangeListActors = (lstActor) => {
        setDetailData({
            listActor: lstActor,
        });
    };

    const onChangeNameActorSelected = (value, idx) => {
        if (detailData.actorSelected?.order === idx) {
            setDetailData({
                actorSelected: {
                    ...detailData.actorSelected,
                    actor: value,
                },
            });
        }
    };

    useEffect(() => {
        if (langKey && langId && questionParentId && !questionId) {
            fetchSpeaker({ code: langKey }).then(({ status, data }) => {
                if (status) {
                    setDetailData({
                        listSpeaker: getSpeakerOptions(data),
                    });
                }
            });
        }
        if (language) {
            setDetailData({ language: language });
        }
    }, [langKey]);

    useEffect(() => {
        handleChangeListActors(lstActor);
    }, [lstActor]);

    useEffect(() => {
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                speaker: qData?.speaker,
                            };

                            const listActor = getlistActor(qData?.answer);
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                                lang: qData?.language?.code,
                                voice: qData?.voice,
                                answer: qData?.answer,
                                listActor: listActor,
                            });

                            fetchSpeaker({ code: qData?.language?.code }).then(({ status, data }) => {
                                if (status) {
                                    setDetailData({
                                        listSpeaker: getSpeakerOptions(data),
                                    });
                                }
                            });

                            getActiveActor(qData?.answer, listActor);
                            form.setFieldsValue({
                                answer: qData?.answer,
                                voice: qData?.voice?.voiceURI,
                                listActors: listActor,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([
                        findPassageQuestion(questionParentId),
                        findChildQuestion(questionId, ["language"]),
                    ]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const questionFormData = {
                                question: res[1].data?.question || "",
                                score: res[1].data?.score || 0,
                                // Special fields: "speaker".
                                speaker: res[1].data?.speaker || "",
                                answer: res[1]?.data?.answer,
                            };
                            const listActor = getlistActor(questionFormData.answer);
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                                lang: res[1]?.data?.language?.code,

                                voice: res[1]?.data?.voice?.voiceURI,
                                answer: questionFormData.answer,
                                listActor: listActor,
                            });

                            form.setFieldsValue({
                                voice: res[1]?.data?.voice?.voiceURI,
                                answer: res[1]?.data?.answer,
                                listActors: listActor,
                            });

                            getActiveActor(questionFormData.answer, listActor);

                            fetchSpeaker({ code: res[1]?.data?.language?.code }).then(({ status, data }) => {
                                if (status) {
                                    setDetailData({
                                        listSpeaker: getSpeakerOptions(data),
                                    });
                                }
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <div className="detail_conversation">
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("q.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion}></Editor>
                </Form.Item>
            </Row>
            <Row gutter={[16, 16]} className="question-formitems top-bar-setting">
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}

                        // labelCol={{ xs: 3, sm: 4, lg: 4 }}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    {detailData.listSpeaker && detailData.listSpeaker?.length > 1 && (
                        <Form.Item
                            name="speaker"
                            label={
                                <div className="form_item_label">
                                    <div className="form_item_label-parent">
                                        {t("speech_to_text_conversation.speaker")}
                                        <span className="form_item_label-child">(Speech to text)</span>
                                    </div>
                                </div>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                            className="speech_to_text_select"

                            // labelCol={{ xs: 3, sm: 4, lg: 7, xl: 7, xxl: 4 }}
                        >
                            <Select
                                placeholder={t("speech_to_text.select_speaker")}
                                options={detailData.listSpeaker}
                                className="app-select how-arrow has-rd"
                                showSearch
                                allowClear
                            ></Select>
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {/* <Typography.Title level={5}>{t("speech_to_text_conversation.actor")}</Typography.Title> */}
                    <div style={{ marginBottom: 10 }}>
                        <Space>
                            <Typography.Title level={5} style={{ marginBottom: 0 }}>
                                {t("speech_to_text_conversation.actor")}
                            </Typography.Title>
                            <span>
                                ({t("speech_to_text_conversation.choose_actor1")} <CheckCircleOutlined />{" "}
                                {t("speech_to_text_conversation.choose_actor2")})
                            </span>
                        </Space>
                    </div>
                    <Form.List name="listActors" className="list-actors" initialValue={defaultListActor}>
                        {(fields, { add, remove, move }) => {
                            return (
                                <Space>
                                    {fields.map((field, idx) => {
                                        return (
                                            <React.Fragment key={field.key}>
                                                <Form.Item
                                                    // {...field}
                                                    // key={field.key}
                                                    name={[field.name, "actor"]}
                                                    rules={[{ required: true, message: t("message.required") }]}
                                                >
                                                    <Input
                                                        className="app-input actor-input"
                                                        prefix={
                                                            <Tooltip
                                                                title={t("speech_to_text_conversation.choose_actor")}
                                                            >
                                                                <CheckCircleOutlined
                                                                    onClick={() => handleSetActor(idx)}
                                                                    className={clsx({
                                                                        "site-form-item-icon": true,
                                                                        active:
                                                                            field.name ===
                                                                            detailData?.actorSelected?.order,
                                                                    })}
                                                                />
                                                            </Tooltip>
                                                        }
                                                        suffix={
                                                            idx >= 2 ? (
                                                                <Tooltip title={t("shared.delete")}>
                                                                    <DeleteOutlined
                                                                        onClick={() => {
                                                                            remove(idx);
                                                                            // handleRemoveActor(field?.name);
                                                                        }}
                                                                        style={{ color: "rgba(0,0,0,.45)" }}
                                                                    />
                                                                </Tooltip>
                                                            ) : null
                                                        }
                                                        onChange={(e) => {
                                                            onChangeNameActorSelected(e.target.value, idx);
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    // {...field}
                                                    name={[field.name, "order"]}
                                                    initialValue={idx}
                                                    rules={[{ required: true, message: t("message.required") }]}
                                                    hidden={true}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </React.Fragment>
                                        );
                                    })}
                                    {fields?.length < 3 && (
                                        <Form.Item>
                                            <Button
                                                icon={<PlusCircleOutlined />}
                                                title={t("shared.add_new")}
                                                type={"ghost"}
                                                onClick={() => add()}
                                            />
                                        </Form.Item>
                                    )}
                                </Space>
                            );
                        }}
                    </Form.List>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Typography.Title level={5}>{t("question.answer")}</Typography.Title>
                    <div className="conversation-list">
                        <Row style={{ width: "100%" }}>
                            <Form.Item name={"answer"}>
                                <ListAnswer
                                    listActor={detailData.listActor}
                                    // answer={detailData.answer}
                                    answer={form.getFieldValue("answer")}
                                    form={form}
                                    actorSelected={detailData.actorSelected}
                                    setData={setDetailData}
                                />
                            </Form.Item>

                            {/* </Form.List> */}
                        </Row>
                    </div>
                </Col>
                {/* <Button onClick={() => console.log(form.getFieldsValue())}>jfjvwejfvnwjen</Button> */}
            </Row>
        </div>
    );
};

export default forwardRef(DetailConversationAudio);
