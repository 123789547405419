import Button from "../Button";
import { _6105, _6106, _1029, _3521, _1058, _1031, _1030, _1032 } from "../../icons/tab10";
import { _1033, _8819, _8821, _8820, _8822, _8824, _8825, _8826 } from "../../icons/tab10";
import { _8827, _8828, _8829, _9295 } from "../../icons/tab10";

const Tab8 = ({ onClick }) => {
    const datas = [
        { icon: _6105, text: "\\frac{d}{d}" },
        { icon: _6106, text: "\\frac{\\partial}{\\partial}" },
        { icon: _1029, text: "\\int{}^{}", keys: ["Left", "Left", "Left"] },
        { icon: _3521, cmd: "int" },
        { icon: _1058, text: "\\lim_{x\\to}" },
        { icon: _1031, text: "\\sum" },
        { icon: _1030, text: "\\sum", keys: ["Left", "Down"] },
        { icon: _1032, text: "\\sum", keys: ["Left", "Down"] },

        { icon: _1033, text: "\\sum", keys: ["Left"] },
        { icon: _8819, text: "\\nabla" },
        { icon: _8821, text: "\\triangle" },
        { icon: _8820, text: "\\nabla\\times" },
        { icon: _8822, text: "\\triangle\\cdot" },
        { icon: _8824, text: "\\oint" },
        { icon: _9295, text: "\\left(f\\circ g\\right)\\left(x\\right)" },
        { icon: _8826, text: "\\prod" },

        { icon: _8827, text: "\\prod", keys: ["Left", "Down"] },
        { icon: _8828, text: "\\prod", keys: ["Left", "Down"] },
        { icon: _8829, text: "\\prod", keys: ["Left", "Up"] },
    ];

    return (
        <div className="button-tab">
            {datas.map((btn, index) => {
                return (
                    <div key={index} className="button-skeleton">
                        <Button icon={btn.icon} write={btn.text} keystrokes={btn.keys} onClick={onClick} />
                    </div>
                );
            })}
        </div>
    );
};

export default Tab8;
