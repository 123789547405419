import {
    CloudUploadOutlined,
    DeleteOutlined,
    EditOutlined,
    IdcardOutlined,
    InfoCircleOutlined,
    ScanOutlined,
    UploadOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import { Modal, Space, Tooltip, message, notification } from "antd";
import clsx from "clsx";
import * as faceapi from "face-api.js";
import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { checkPersonNumber, detectPersonNumber } from "src/api/containers/examCheckin";
import { ReactComponent as IconClose } from "src/assets/images/action-close.svg";
import configs from "src/configs";
import { useValues } from "src/hooks";
import { useAuth } from "src/modules/auth";
import Button from "src/modules/components/Button";
import Checkmark from "src/modules/components/CheckMark";
import ScanningEffect from "src/modules/components/ScanningEffect";
import { UploadImageBtn } from "src/modules/components/UploadImageBtn";
import { logout } from "src/reducers/auth";
import { createImgElement, getCookie, parse_jwt } from "src/utils/helpers";
import { getFaceDetectorOptions } from "src/utils/helpers/faceapi";

import IdCardPolicy from "./IdCardPolicy";
import "./UploadIdCard.scss";

const { DISTANCE_ALLOW, MAX_FILE_SIZE } = configs.EXAM_PROCTORING.FACE_IN_ID_CARD;
const { INPUT_SIZE } = configs.EXAM_PROCTORING.WEBCAM_CAPTURE;

const VERIFY_STATUS = {
    NOT_VERIFIED: "NOT_VERIFIED",
    VERIFIED_SUCCESSFULLY: "VERIFIED_SUCCESSFULLY",
    VERIFICATION_FAILED: "VERIFICATION_FAILED",
    FACE_NOT_DETECTED: "FACE_NOT_DETECTED",
    FACE_NOT_MATCH: "FACE_NOT_MATCH",
    ERROR: "ERROR",
};

const getCheckMaskStatus = (status) => {
    switch (status) {
        case VERIFY_STATUS.VERIFIED_SUCCESSFULLY:
            return "SUCCESS";
        case (VERIFY_STATUS.VERIFICATION_FAILED, VERIFY_STATUS.FACE_NOT_DETECTED):
            return "FAILED";
        default:
            return "HIDE";
    }
};

const getCheckResult = (status) => {
    switch (status) {
        case VERIFY_STATUS.VERIFIED_SUCCESSFULLY:
            return "verified_successfully";
        case VERIFY_STATUS.VERIFICATION_FAILED:
            return "verification_failed";
        case VERIFY_STATUS.ERROR:
            return "message.system_error";
        case VERIFY_STATUS.FACE_NOT_DETECTED:
            return "no_face_detected";
        case VERIFY_STATUS.FACE_NOT_MATCH:
            return "faces_not_match";
        default:
            return "";
    }
};

function UploadIdCard({ currentStep, onDone, setStep, imgWebcamRef }) {
    const { t } = useTranslation();
    const { user } = useAuth();
    const lang = useSelector((state) => state?.general?.lang);
    const uploadRef = useRef(null);
    const img1Ref = useRef(null);
    const img2Ref = useRef(null);

    const [values, setValues] = useValues({
        urlImagePreview: "",
        isImgProcessing: false,
        verification_status: VERIFY_STATUS.NOT_VERIFIED,
        isRemoveToken: false,
        isModalConfirm: false,
        personal_number_detected: "",
        isModalReUpload: false,
        isModalIdCardPolicy: false,
    });

    const { personal_number, is_public_assignment } = useMemo(() => {
        const token = localStorage.getItem("token") || getCookie("token");
        const tokenObj = parse_jwt(token);
        return { is_public_assignment: tokenObj?.is_public_assignment, personal_number: tokenObj?.personal_number };
    }, [user]);

    const renderImageAlertText = (status, type) => {
        switch (status) {
            case VERIFY_STATUS.FACE_NOT_MATCH:
                return (
                    <span>
                        Khuôn mặt của bạn không khớp. Vui lòng đảm bảo rằng ảnh ID của bạn đúng{" "}
                        <u
                            style={{ color: "#52c41a", fontWeight: 700, cursor: "pointer", wordBreak: "keep-all" }}
                            onClick={() => {
                                setValues({ isModalIdCardPolicy: true });
                            }}
                        >
                            quy định
                        </u>
                    </span>
                );
            case VERIFY_STATUS.NOT_VERIFIED:
                return (
                    <span style={{ color: "#555" }}>
                        <strong>Lưu ý:</strong>{" "}
                        <span>
                            Bạn vui lòng đọc kỹ{" "}
                            <u
                                style={{ color: "#52c41a", fontWeight: 700, cursor: "pointer" }}
                                onClick={() => {
                                    setValues({ isModalIdCardPolicy: true });
                                }}
                            >
                                quy định
                            </u>{" "}
                            trước khi tải ảnh lên
                        </span>
                    </span>
                );
            case VERIFY_STATUS.FACE_NOT_DETECTED:
                return (
                    <span>
                        Không phát hiện khuôn mặt trong ảnh ID, vui lòng sử dụng ảnh đúng{" "}
                        <u
                            style={{ color: "#52c41a", fontWeight: 700, cursor: "pointer", wordBreak: "keep-all" }}
                            onClick={() => {
                                setValues({ isModalIdCardPolicy: true });
                            }}
                        >
                            quy định
                        </u>
                    </span>
                );
            default:
                return "";
        }
    };

    const renderUploadBtn = () => {
        return (
            <div className={clsx({ "upload-btn": true })} ref={uploadRef}>
                {values.urlImagePreview ? (
                    <>
                        {values.isImgProcessing ? (
                            <ScanningEffect isDisableClick={true}>
                                <img
                                    src={values.urlImagePreview}
                                    alt="ID Card"
                                    style={{
                                        width: "100%",
                                    }}
                                    id="id-card-img"
                                    crossOrigin="anonymous"
                                />
                            </ScanningEffect>
                        ) : (
                            <Checkmark
                                type={getCheckMaskStatus(values.verification_status)}
                                duration={2}
                                text={t(getCheckResult(values.verification_status))}
                                isDisableClick={true}
                            >
                                <img
                                    src={values.urlImagePreview}
                                    alt="ID Card"
                                    style={{
                                        width: "100%",
                                    }}
                                    id="id-card-img"
                                    crossOrigin="anonymous"
                                />
                            </Checkmark>
                        )}
                        {values.urlImagePreview && !values.isImgProcessing && (
                            <Tooltip title="xóa ảnh">
                                <Button
                                    icon={<DeleteOutlined style={{ color: "red" }} />}
                                    className="delete-img"
                                    title={null}
                                    type="simple"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveImage();
                                    }}
                                    isDisabled={values.isImgProcessing}
                                />
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <div className="upload-btn_init">
                        <span className="upload-btn_init-icon">
                            <IdcardOutlined />
                        </span>
                        <div className="upload-btn_init-desc">{t("upload_or_drag")}</div>
                    </div>
                )}
            </div>
        );
    };
    const onStudentUploadAvtSuccess = () => {
        updateMyProfile();
        profileFetch({ id: auth.user?.id }, true);
        notification.success({
            message: t("profile.update_success"),
        });
    };

    const handleRemoveImage = () => {
        setValues({
            urlImagePreview: "",
            isImgProcessing: false,
            verification_status: VERIFY_STATUS.NOT_VERIFIED,
            personal_number_detected: "",
        });
    };

    const handleUploadImg = (data) => {
        return new Promise(() => true);
    };

    const handleImageBeforeUpload = (file) => {
        // Create a FileReader to read the uploaded file

        const isImg = file?.type?.startsWith?.("image/");

        if (!isImg) {
            message.warning(t("upload.only_image"));
            return false;
        }

        if (file?.size > MAX_FILE_SIZE) {
            message.warning(`${t("upload.file_size_limit")} 4MB`);
            return false;
        }
        const reader = new FileReader();

        reader.onload = (e) => {
            // When the reader is done, you can get the base64 data URL
            setValues({ urlImagePreview: e.target.result });
        };

        // Read the file as data URL
        reader.readAsDataURL(file);

        // Return false to prevent Ant Design from uploading the file
        return false;
    };

    function comparePersonalNumber(personal_number, personal_number_detected) {
        try {
            if (personal_number == personal_number_detected) {
                setValues({ isImgProcessing: false, verification_status: VERIFY_STATUS.VERIFIED_SUCCESSFULLY });
                setTimeout(() => {
                    if (setStep instanceof Function) {
                        setStep(2);
                    }
                    setTimeout(() => {
                        if (onDone instanceof Function) {
                            onDone();
                            notification.success({ message: t("verified_successfully") });
                        }
                    }, 2000);
                }, 2000);
            } else {
                setValues({
                    isImgProcessing: false,
                    verification_status: VERIFY_STATUS.VERIFICATION_FAILED,
                    personal_number_detected: personal_number_detected,
                });
                notification.error({ message: t("verification_failed") });
            }
        } catch (error) {
            //eslint-disable-next-line
            console.error(error);
        }
    }

    const handleScanningImage = async () => {
        if (values.verification_status == VERIFY_STATUS.VERIFICATION_FAILED && values.urlImagePreview) {
            notification.warn({ message: "Vui lòng sử dụng ảnh khác" });
            return;
        }
        if (values.isImgProcessing || !values.urlImagePreview) {
            return;
        }

        const base64Data = values.urlImagePreview.split(",")?.[1];
        if (!base64Data) return;

        setValues({ isImgProcessing: true });

        try {
            const faceDetect = await detectFaceInIdCard();
            // console.log({ faceDetect });
            if (!faceDetect) {
                setValues({ isImgProcessing: false });
                return;
            }
            // return;
            /* -----END detect Face in ID Card ----- */

            /* -----BEGIN detect ID Number ----- */
            detectPersonNumber(base64Data)
                .then((res) => {
                    if (res.status) {
                        comparePersonalNumber(personal_number, res.data?.personal_number);
                        return;
                    } else {
                        setValues({ isImgProcessing: false, verification_status: VERIFY_STATUS.ERROR });
                        notification.error({ message: res.message || t("an_error_occurred") });
                    }
                })
                .catch((err) => {
                    setValues({ isImgProcessing: false, verification_status: VERIFY_STATUS.ERROR });
                    notification.error({ message: t("an_error_occurred") });
                });
            /* -----END detect ID number ----- */
        } catch (error) {
            //eslint-disable-next-line
            console.error(error);
            setValues({ isImgProcessing: false });
        }
    };

    function handleVerifyAgain() {
        if (values.personal_number_detected) {
            //case user ko xóa ảnh cũ và nhấn verify lại
            setValues({ isImgProcessing: true });
            checkPersonNumber(values.personal_number_detected).then((res) => {
                setValues({ isImgProcessing: false });
            });
        } else {
            // case user upload ảnh khác và nhấn verify lại

            if (values.isImgProcessing || !values.urlImagePreview) {
                return;
            }

            const base64Data = values.urlImagePreview.split(",")?.[1];
            if (!base64Data) return;

            setValues({ isImgProcessing: true });
            detectPersonNumber(base64Data)
                .then((res) => {
                    if (res.status) {
                        checkPersonNumber(values.personal_number_detected).then((res) => {
                            setValues({ isImgProcessing: false });
                            comparePersonalNumber(personal_number, personal_number);
                        });
                    } else {
                        setValues({ isImgProcessing: false, verification_status: VERIFY_STATUS.ERROR });
                        notification.error({ message: res.message || t("an_error_occurred") });
                    }
                })
                .catch((err) => {
                    setValues({ isImgProcessing: false, verification_status: VERIFY_STATUS.ERROR });
                    notification.error({ message: t("an_error_occurred") });
                });
        }
    }

    async function detectFaces(imgItem, inputSize) {
        if (imgItem) {
            const result = await faceapi
                .detectSingleFace(imgItem, getFaceDetectorOptions({})) //chỉnh thông số trong trong file config
                .withFaceLandmarks()
                .withFaceDescriptor();
            return result;
        }
        return null;
    }

    const detectFaceInIdCard = async () => {
        const imgUpload = document.getElementById("image2");
        const imgWebcamElement = document.getElementById("image1");

        if (!imgWebcamElement || !imgUpload) {
            notification.error({ message: t("an_error_occurred") });
            return false;
        }
        // const imgWebcamElement = createImgElement({ width: 400, height: 300, src: imgWebcamRef.current });

        const webcamFacedetection = await detectFaces(imgWebcamElement, INPUT_SIZE || 320);
        const idCardFacedetection = await detectFaces(imgUpload);

        if (!idCardFacedetection) {
            message.error(t("no_face_detected"));
            setValues({
                isImgProcessing: false,
                verification_status: VERIFY_STATUS.FACE_NOT_DETECTED,
            });
            return false;
        }

        if (idCardFacedetection && webcamFacedetection) {
            // Using Euclidean distance to comapare face descriptions
            const distance = faceapi.euclideanDistance(idCardFacedetection.descriptor, webcamFacedetection.descriptor);

            if (webcamFacedetection?.detection) {
                const canvas2 = document.getElementById("overlay1");
                drawFaceRecognitionResults(webcamFacedetection, canvas2, imgWebcamElement);
            }

            if (idCardFacedetection?.detection) {
                const canvas1 = document.getElementById("overlay2");
                drawFaceRecognitionResults(idCardFacedetection, canvas1, imgUpload);
            }

            if (distance > DISTANCE_ALLOW) {
                setValues({
                    isImgProcessing: false,
                    verification_status: VERIFY_STATUS.FACE_NOT_MATCH,
                });
                return false;
            }
        } else {
            notification.error({ message: t("an_error_occurred") });
            return false;
        }

        return true;
    };

    function drawFaceRecognitionResults(results, canvas, inputImgEl) {
        faceapi.matchDimensions(canvas, inputImgEl);
        // resize detection and landmarks in case displayed image is smaller than
        // original size
        const resizedResults = faceapi.resizeResults(results, inputImgEl);

        const { detection } = resizedResults;

        const drawBox = new faceapi.draw.DrawBox(detection.box);
        drawBox.draw(canvas);
    }

    return (
        <div className={clsx({ "upload-id-card": true, disable: currentStep != 1 })}>
            {currentStep === 0 ? null : (
                <>
                    <UploadImageBtn
                        onUploadSuccess={onStudentUploadAvtSuccess}
                        uploadApi={(data) => handleUploadImg(data)}
                        onUploading={() => {
                            // setValues({ isLoadingAvatar: true });
                        }}
                        onUploadError={() => {
                            // setValues({ isLoadingAvatar: false });
                        }}
                        beforeUpload={handleImageBeforeUpload}
                    >
                        <div
                            className={clsx({ "upload-id-card_content": true, "has-image": !!values.urlImagePreview })}
                        >
                            {renderUploadBtn()}
                        </div>
                    </UploadImageBtn>
                    <span style={{ color: "red", width: 560, textAlign: "center", marginBottom: 10 }}>
                        {renderImageAlertText(values.verification_status)}
                    </span>
                    <div className="upload-id-card_actions">
                        {values.verification_status === VERIFY_STATUS.NOT_VERIFIED ||
                        values.verification_status === VERIFY_STATUS.ERROR ? (
                            <Space>
                                <Button
                                    title={t("shared.check")}
                                    icon={<ScanOutlined />}
                                    onClick={handleScanningImage}
                                    isDisabled={!values.urlImagePreview}
                                    isLoading={values.isImgProcessing}
                                />
                            </Space>
                        ) : (
                            <Space>
                                {values.verification_status === VERIFY_STATUS.VERIFICATION_FAILED && (
                                    <>
                                        {is_public_assignment ? (
                                            <div className="flex-center" style={{ flexDirection: "column" }}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        textAlign: "center",
                                                        maxWidth: "60%",
                                                        marginBottom: 20,
                                                        color: "red",
                                                    }}
                                                >
                                                    {lang == "vi" ? (
                                                        <>
                                                            ID của Bạn không chính xác, vui lòng kiểm tra lại hình chụp{" "}
                                                            <Tooltip
                                                                title="Vui lòng chụp lại hình khác nếu ảnh chụp của bạn đang không rõ"
                                                                color="green"
                                                            >
                                                                <InfoCircleOutlined
                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                />
                                                            </Tooltip>
                                                            {", "}
                                                            Hoặc Bạn vui lòng kiểm tra lại ID ở bước thông tin thí sinh{" "}
                                                            <Tooltip
                                                                title="Nhấn cập nhật thông tin để quay lại bước thông tin thí sinh"
                                                                color="green"
                                                            >
                                                                <InfoCircleOutlined
                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                />
                                                            </Tooltip>
                                                            .
                                                        </>
                                                    ) : (
                                                        <>
                                                            Your ID is incorrect, please check the photo again{" "}
                                                            <Tooltip
                                                                title="Please take another photo if your photo is not clear"
                                                                color="green"
                                                            >
                                                                <InfoCircleOutlined
                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                />
                                                            </Tooltip>
                                                            {", "}
                                                            Or please check your ID again in the candidate information
                                                            step{" "}
                                                            <Tooltip
                                                                title="Click update information to return to the candidate information step"
                                                                color="green"
                                                            >
                                                                <InfoCircleOutlined
                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                />
                                                            </Tooltip>
                                                            .
                                                        </>
                                                    )}
                                                </div>
                                                <Space style={{ justifyContent: "center" }}>
                                                    <Button
                                                        title={t("action.update_info_again")}
                                                        icon={<EditOutlined />}
                                                        onClick={() => setValues({ isModalConfirm: true })}
                                                        isDisabled={values.isImgProcessing}
                                                    />
                                                    <Button
                                                        title={t("action.verify_again")}
                                                        icon={<ScanOutlined />}
                                                        onClick={() => setValues({ isModalReUpload: true })}
                                                        isDisabled={!values.urlImagePreview}
                                                        isLoading={values.isImgProcessing}
                                                    />
                                                </Space>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    textAlign: "center",
                                                    marginBottom: 20,
                                                    color: "red",
                                                    flexDirection: "column",
                                                    width: 560,
                                                }}
                                                className="flex-center"
                                            >
                                                {lang == "en" ? (
                                                    <div>
                                                        Your information does not match. Please contact the exam
                                                        organization to update your ID(if it wrong) and verify again.
                                                    </div>
                                                ) : (
                                                    <div>
                                                        Thông tin của bạn không khớp. Vui lòng liên hệ tổ chức thi để
                                                        cập nhật lại ID(nếu bị sai) và xác minh lại.
                                                    </div>
                                                )}
                                                <Button
                                                    title={t("action.verify_again")}
                                                    icon={<ScanOutlined />}
                                                    onClick={handleVerifyAgain}
                                                    isDisabled={!values.urlImagePreview}
                                                    isLoading={values.isImgProcessing}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Space>
                        )}
                    </div>
                    <Space>
                        <div style={{ position: "relative" }}>
                            <img
                                ref={img1Ref}
                                id="image1"
                                src={imgWebcamRef.current}
                                style={{ display: "none", width: 700 }}
                                crossOrigin="true"
                                height="auto"
                            />
                            <canvas
                                id="overlay1"
                                style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}
                            />
                        </div>
                        <div style={{ position: "relative" }}>
                            <img
                                ref={img2Ref}
                                id="image2"
                                src={values.urlImagePreview}
                                style={{ display: "none", width: 700 }}
                                crossOrigin="true"
                                height="auto"
                            />
                            <canvas
                                id="overlay2"
                                style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}
                            />
                        </div>
                    </Space>
                </>
            )}

            <Modal
                centered
                visible={values.isModalConfirm}
                title={
                    values.isRemoveToken ? (
                        <div className="check-info-title">
                            <div className="title-icon">
                                <WarningOutlined />
                            </div>
                            <div className="title-content">
                                <div className="content-main">
                                    {`${t("action.change_pages_after")} `} <CountDown number={5} />
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                closeIcon={<IconClose />}
                closable={!values.isRemoveToken}
                footer={null}
                className={"app-modal flexible-height type-primary maxw-520 modal-screensizechk"}
                wrapClassName="modal-screensizechk-wrap"
                onCancel={() => {
                    if (!values.isRemoveToken) {
                        setValues({ isModalConfirm: false, isRemoveToken: false });
                    }
                }}
            >
                {values.isRemoveToken ? (
                    <div className="modal-wraper text-center" style={{ fontSize: 16 }}>
                        {lang == "en" ? (
                            <>
                                You will be returned to the <strong>system check</strong> page.
                            </>
                        ) : (
                            <>
                                Bạn sẽ được quay lại trang <strong>kiểm tra hệ thống</strong>.
                            </>
                        )}
                    </div>
                ) : (
                    <Space direction="vertical" align="center">
                        <div
                            className="modal-wraper text-center flex-center"
                            style={{ fontSize: 18, height: 100, marginTop: 20 }}
                        >
                            {lang == "en" ? (
                                <span>
                                    You will be returned to the <strong>system check</strong> page to update the
                                    information
                                </span>
                            ) : (
                                <span>
                                    Bạn sẽ được quay lại trang <strong>kiểm tra hệ thống</strong> để cập nhật lại thông
                                    tin
                                </span>
                            )}
                        </div>
                        <Button
                            title="OK"
                            onClick={() => {
                                setValues({ isRemoveToken: true });
                            }}
                        />
                    </Space>
                )}
            </Modal>

            <Modal
                centered
                visible={values.isModalReUpload}
                destroyOnClose
                title={
                    <div className="check-info-title">
                        <div className="title-icon">
                            <CloudUploadOutlined />
                        </div>
                        <div className="title-content">
                            <div className="content-main">
                                <span>{t("anti_cheating.upload_another_ID")}</span>
                            </div>
                        </div>
                    </div>
                }
                closeIcon={<IconClose />}
                closable={true}
                footer={null}
                className={"app-modal flexible-height type-primary maxw-520 modal-screensizechk"}
                wrapClassName="modal-screensizechk-wrap"
                onCancel={() => {
                    setValues({ isModalReUpload: false });
                }}
            >
                <div className="flex-center">
                    <Button
                        title={t("shared.upload")}
                        onClick={() => {
                            uploadRef.current?.click?.();
                            setValues({ isModalReUpload: false });
                            handleRemoveImage();
                        }}
                    />
                </div>
            </Modal>

            <Modal
                centered
                visible={values.isModalIdCardPolicy}
                destroyOnClose
                title={<div>{t("anti_cheating.id_photo_regulations")}</div>}
                closeIcon={<IconClose />}
                closable={true}
                footer={null}
                className={"app-modal flexible-height type-primary "}
                bodyStyle={{ overflowY: "auto", maxHeight: 680 }}
                onCancel={() => {
                    setValues({ isModalIdCardPolicy: false });
                }}
            >
                <IdCardPolicy />
            </Modal>
        </div>
    );
}

function CountDown({ number }) {
    const [count, setCount] = useState(number);
    const intervalId = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        intervalId.current = setInterval(() => {
            if (count > 0) {
                setCount((pre) => pre - 1);
            } else {
                clearInterval(intervalId.current);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, []);

    useEffect(() => {
        if (count <= 0) {
            dispatch(logout());
            clearInterval(intervalId.current);
            window.location.reload();
        }
    }, [count]);

    return <span>{count}s</span>;
}

export default UploadIdCard;
