import { store } from "src/store";

import api from "..";

export const fetchRecordByAssignmentId = (fetchData) => {
    const params = {
        _record_fields: `${[""]}`,
    };
    return api
        .post(`/record/start_time`, fetchData, params)
        .then(({ status, data, message, warning }) => ({ status, data, message: message || warning }))
        .catch(() => ({ status: false }));
};

export const findRecord = (id) => {
    const params = {
        _record_fields: `${[
            "user_id",
            "assignment_id",
            "score",
            "history",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "course",
            "content_violation_error",
        ]}`,
        _relations: `${["assignment"]}`,
        _assignment_fields: `${["exam", "name", "unit_id"]}`,
    };

    return api
        .get(`/record/${id}`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};

/**
 * Get test records.
 * @param {string} assignmentId Assignment
 * @param {string} userId The student who take the assignment.
 * @returns Record data
 */
export const findRecordBy = (assignmentId, userId, fetchParams) => {
    const filters = [];
    assignmentId && filters.push(`assignment_id:${assignmentId}`);
    userId && filters.push(`user_id:${userId}`);
    [0, 1].includes(fetchParams?.is_show_user_info) &&
        filters.push(`is_show_user_info:${fetchParams.is_show_user_info}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _record_fields: `${[
            "user_id",
            "assignment_id",
            "score",
            "max_score",
            "history",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "is_student_submit",
            "is_teacher_submit",
        ]}`,
        _relations: `${["assignment", "user", "reports"]}`,
        _assignment_fields: `${["exam"]}`,
    };

    return api
        .get(`/record`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};

/**
 * Get test records.
 * @param {object} fetchParams Fetch params.
 * @returns Array.
 */
export const fetchRecordsOfStudent = (fetchParams) => {
    const filters = [];
    fetchParams?.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams?.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams?.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    (fetchParams?.is_unit || fetchParams?.is_unit == 0) && filters.push(`is_unit:${fetchParams.is_unit}`);
    fetchParams.code_assignment && filters.push(`code_assignment:${fetchParams.code_assignment}`);
    [0, 1].includes(fetchParams?.is_show_user_info) &&
        filters.push(`is_show_user_info:${fetchParams.is_show_user_info}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _record_fields: `${[
            "user_id",
            "assignment_id",
            "score",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "level",
            "course",
            "history",
            "content_violation_error",
        ]}`,
        _relations: `${["assignment", "files"]}`,
        _assignment_fields: `${["name", "class", "category"]}`,
        _file_fields: `${["src", "name"]}`,
        ...(fetchParams?.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };

    return api
        .get(`/record`, params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(() => ({ status: false }));
};

export const submitRecord = (fetchData) => {
    const { id, data } = fetchData;
    return api
        .put(`/record/${id}/submit`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchStudentListByAssignmentID = (fetchParams) => {
    const filters = [];
    fetchParams?.filter && filters.push(`${fetchParams.filter}`);
    [0, 1].includes(fetchParams?.is_show_user_info) &&
        filters.push(`is_show_user_info:${fetchParams.is_show_user_info}`);

    const params = {
        _record_fields: `${[
            "score",
            "history",
            "start_time",
            "end_time",
            "count_correct_sentences",
            "count_sentences",
            "content_violation_error",
        ]}`,
        _filter: filters.join(";") || undefined,
        _relations: `${["user", "assignment", "courseUser", "files"]}`,
        _user_fields: `${["name", "personal_number", "username"]}`,
        _assignment_fields: `${["exam"]}`,
        _course_user_fields: `${["name", "personal_number"]}`,
        _file_fields: `${["src", "name"]}`,
    };

    return api
        .get(`/record`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};

export const markStudentAssignment = (id, formData) => {
    return api
        .put(`/record/${id}/submit`, formData)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const countUserViolations = (id, keyName, violation_time) => {
    const time_start = store.getState().test.recording_time_start;
    let _violation_time = violation_time;

    if (!violation_time) {
        const _now = new Date();

        if (time_start) {
            _violation_time = _now - time_start;
        } else {
            _violation_time = _now;
        }
    }

    return api
        .put(`/record/${id}`, { options: [{ time: _violation_time, action: keyName }] })
        .then(({ status, warning, is_load }) => ({ status, warning, is_load }))
        .catch(() => ({ status: false, data: [] }));
};

export const uploadExamProctoringAssets = (id, data, config) => {
    // const _data = {
    //     file0: {},
    //     file1: {},
    //     file2: {},
    // };

    return api
        .post(
            `/record/${id}/upload_recording`,
            data,
            {},
            {
                "Content-Type": "multipart/form-data",
            },
            {
                onUploadProgress: config?.onUploadProgress,
            }
        )
        .then(({ status, data, success }) => ({ status, data, message: success }))
        .catch(() => ({ status: false }));
};

export const reUploadExamProctoringAssets = (data, config) => {
    // const _data = {
    //     file0: {},
    //     file1: {},
    //     file2: {},
    //     token: "",
    // };

    return api
        .post(
            `record/upload_recording`,
            data,
            {},
            {
                "Content-Type": "multipart/form-data",
            },
            {
                onUploadProgress: config?.onUploadProgress,
            }
        )
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};

export const checkReUploadStatus = (tokenFromUrl) => {
    const _data = {
        token: tokenFromUrl,
    };

    return api
        .get(`/record/check_upload_recording`, _data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};

export const uploadViolationImage = (recordId, data) => {
    return api
        .post(`/record/${recordId}/upload_recording`, data)
        .then(({ status, message, data, success, is_no_face_on_screen, are_many_faces_on_screen }) => ({
            status,
            message,
            data,
            success,
            is_no_face_on_screen,
            are_many_faces_on_screen,
        }))
        .catch(() => ({ status: false }));
};

// export const findCategory = (id) => {
//     const params = {
//         _category_fields: `${["name"]}`,
//     };
//     return api
//         .get(`/category/${id}`, params)
//         .then(({ status, data }) => ({ status, data }))
//         .catch(() => ({ status: false }));
// };

// export const updateCategory = (id, data) => {
//     return api
//         .put(`/category/${id}`, data)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// export const createCategory = (data) => {
//     return api
//         .post("/category", data)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };

// export const deleteCategory = (id) => {
//     return api
//         .delete(`/category/${id}`)
//         .then(({ status, message }) => ({ status, message }))
//         .catch(() => ({ status: false }));
// };
