// File is verified!
import api from "..";

const planFields = {
    planInfo: [
        "type",
        "name",
        "description",
        "price",
        "ai_function",
        "ai_credit",
        "storage_limit",
        "exam_limit",
        "question_limit",
        "questions_per_exam_limit",
        "submissions_per_assignment_limit",
        "usage_per_exam_limit",
        "employee_account_limit",
        "freeup_storage",
    ],
};
const planPriceFields = ["months", "original_price", "current_price", "discount"];
const optionFields = ["key", "value", "original_price", "current_price"];
const planRelations = ["prices", "orders", "transactions"];

// Plan types, mut be arranged in ascending order:
export const planTypes = {
    free: "free",
    plan1: "plus",
    plan2: "premium",
    plan3: "enterprise",
};

// Get subscription plan list:
export const fetchSubscriptionPlans = (fetchParams = {}) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.planType && filters.push(`type:${fetchParams.planType}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _plan_fields: `${planFields.planInfo}`,
        _relations: `${planRelations}`,
        _plan_price_fields: `${planPriceFields}`,
    };
    return api
        .get("/plan", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};

// Find plan by id:
export const findSubscriptionPlan = (id) => {
    const params = {
        _plan_fields: `${planFields.planInfo}`,
        _relations: `${planRelations}`,
        _plan_price_fields: `${planPriceFields}`,
    };
    return api
        .get(`/plan/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(({ message }) => ({ status: false, message }));
};

// Promotional codes:
export const applyPromoCode = (data) => {
    const { plan_price_id, promotion_codes } = data;

    if (!plan_price_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Please select a plan!" });
        });
    }
    if (!promotion_codes) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Please enter your promotion code!" });
        });
    }

    return api
        .post("/plan/apply_promotion", data)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// Purchase plan:
export const purchasePlan = (data) => {
    const { plan_price_id, option_ids, payment_method, promotion_codes, organization_id } = data;

    if (!plan_price_id && !organization_id) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Please select a plan!" });
        });
    }
    if (!payment_method) {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Please select a payment method!" });
        });
    }

    return api
        .post("/plan/purchase", data)
        .then(({ status, data, message, success }) => ({ status, data, message, success }))
        .catch(({ message }) => ({ status: false, message }));
};

// Check after purchasing plan:
export const checkAfterPurchasePlan = (data) => {
    let urlParams;

    if (typeof data === "string") {
        urlParams = data;
    } else if (Object.keys(data).length) {
        urlParams = [];
        for (const key of Object.keys(data)) {
            urlParams.push(`${key}=${data[key]}`);
        }
        urlParams = urlParams.join("&");
    } else {
        return new Promise((resolve, reject) => {
            resolve({ status: false, message: "Missing information!" });
        });
    }

    return api
        .get(`/plan/after_purchase?${urlParams}`)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(({ message }) => ({ status: false, message }));
};

// Get subscription plan option list:
export const fetchSubscriptionOptions = () => {
    const params = {
        _option_fields: `${optionFields}`,
    };
    return api
        .get("/option", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(({ message }) => ({ status: false, message, data: [] }));
};
