import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import Item from "./Item";
import { default as CustomButton } from "src/modules/components/Button";
import "./MoveToModal.scss";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";

const MoveToModal = ({
    controller,
    // Methods:
    listFolders,
    moveFilesToFolder,
    removeFiles,
    // Content:
    children,
    // Modal:
    className,
    onOk,
    onCancel,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const [isVisible, setIsVisible] = useState(false);
    const [activeFolder, setActiveFolder] = useState(null);
    const [targetedFolder, setTargetedFolder] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Values: "saving" || false.
    const excludedTarget = useRef([]);

    const handleSave = (doAfterSave) => {
        const res = moveFilesToFolder(selectedFiles, targetedFolder);
        if (res instanceof Promise) {
            setIsLoading("saving");
            res.then(({ message }) => {
                setIsLoading(false);
                message && controller.call("notification.show", message, 5000, "success");
                if (doAfterSave instanceof Function) {
                    doAfterSave();
                }
                // Preparation:
                const selectedFolders = (selectedFiles || []).filter((item) => item.type === "folder");
                // 1. Update current folder content:
                controller.call("content.open_folder", activeFolder);
                // 2. Update current folder sidebar:
                // - Solution 1:
                // controller.call(`sidebar_item.${activeFolder.id}.load`);
                // - Solution 2:
                controller.call(`sidebar_item.${activeFolder.id}.remove_child_list`, selectedFolders);
                // 3. Update targeted folder sidebar:
                // - Solution 1:
                // controller.call(`sidebar_item.${targetedFolder.id}.load`);
                // controller.call(`sidebar_item.${targetedFolder.id}.set_is_empty`, false);
                // - Solution 2:
                controller.call(`sidebar_item.${targetedFolder.id}.add_child_list`, selectedFolders);
            }).catch(({ message }) => {
                setIsLoading(false);
                message && controller.call("notification.show", message, 5000, "error");
                if (doAfterSave instanceof Function) {
                    doAfterSave();
                }
            });
        }
    };

    const handleSaveWithValidation = (doAfterSave) => {
        const res = listFolders(targetedFolder?.id);
        if (res instanceof Promise) {
            setIsLoading("validating");
            res.then((folders) => {
                let countIssuses = 0;
                // Validate:
                const targetFolItems = folders || [];
                const duplicatedItems = [];
                for (let i = 0; i < selectedFiles?.length; i++) {
                    for (let j = 0; j < targetFolItems?.length; j++) {
                        if (
                            selectedFiles[i].type === targetFolItems[j].type &&
                            selectedFiles[i].name === targetFolItems[j].name
                        ) {
                            countIssuses++;
                            alert("Can't move items due to duplicated files. Please check again!");
                            break;
                        }
                    }
                    if (countIssuses) {
                        break;
                    }
                }
                // After validation:
                if (countIssuses === 0) {
                    handleSave(doAfterSave);
                } else {
                    setIsLoading(false);
                }
            }).catch(({ message }) => {
                setIsLoading(false);
                message && controller.call("notification.show", message, 5000, "error");
            });
        }
    };

    const handleSubmitForm = () => {
        const doAfterSave = () => {
            controller.call("movetomodal.hide");
            if (onOk instanceof Function) {
                onOk();
            }
        };
        handleSaveWithValidation(doAfterSave);
    };

    const handleCancelForm = () => {
        controller.call("movetomodal.hide");
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleClickNewFolder = () => {
        if (targetedFolder) {
            controller.call(`movetomodal_item.${targetedFolder.id}.input_create_folder`);
        } else {
            alert("You have to select a folder!");
        }
    };

    useEffect(() => {
        controller.set("movetomodal.show", () => {
            // Modal:
            setIsVisible(true);
            controller.call(`movetomodal_item.null.open`);
            // Folder & form fields:
            // (By default, sidebar's activeFolder is targeted folder).
            const _activeFolder = controller.call("sidebar.get_active_folder");
            const _currentSelectedFiles = controller.call("content.get_current_selected_files");
            setActiveFolder(_activeFolder);
            setTargetedFolder(_activeFolder);
            setSelectedFiles(_currentSelectedFiles);
            excludedTarget.current = _currentSelectedFiles
                .map((item) => {
                    if (item?.type === "folder") {
                        return item?.id;
                    }
                })
                .filter((item) => !!item);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                folder_id: _activeFolder.id,
                folder_name: _activeFolder.name,
            });
        });
        controller.set("movetomodal.hide", () => {
            // Modal:
            setIsVisible(false);
            controller.call(`movetomodal_item.null.close`);
            // Folder & form fields:
            setActiveFolder(null);
            setTargetedFolder(null);
            setSelectedFiles(null);
            excludedTarget.current = [];
            form.resetFields();
        });
        controller.set("movetomodal.set_targeted_folder", (_folder) => {
            // Folder & form fields:
            setTargetedFolder(_folder);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                folder_id: _folder.id,
                folder_name: _folder.name,
            });
        });

        return () => {
            controller.remove("movetomodal.show");
            controller.remove("movetomodal.hide");
            controller.remove("movetomodal.set_targeted_folder");
        };
    }, []);

    return (
        <Modal
            visible={isVisible}
            onOk={handleSubmitForm}
            onCancel={handleCancelForm}
            centered
            title={t("file_manager.move_to")}
            closeIcon={<SvgClose />}
            footer={null}
            width="auto"
            destroyOnClose // Important!
            {...rest}
            className={`app-modal type-basic flexible-height maxw-520${className ? " " + className : ""}`}
        >
            <Spin spinning={isLoading !== false}>
                {children ? (
                    children
                ) : (
                    <Form form={form} className="fm-form-moveto">
                        <div className="fm-moveto-wrapper">
                            <div className="fm-moveto-actions">
                                <CustomButton
                                    type="grey"
                                    title={t("file_manager.add_folder")}
                                    onClick={handleClickNewFolder}
                                ></CustomButton>
                            </div>
                            <div className="fm-moveto">
                                <div className="fm-moveto-container">
                                    <ul>
                                        <Item
                                            controller={controller}
                                            folder={{ id: null, name: "Root" }}
                                            folderExcluded={excludedTarget.current}
                                            flow={[{ id: null, name: "Root" }]}
                                            path="/"
                                            targetedFolder={targetedFolder}
                                            listFolders={listFolders}
                                            // removeFiles={removeFiles}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <Form.Item
                            hidden
                            label={t("file_manager.folder")}
                            name="folder_id"
                            rules={[{ required: true, message: t("file_manager.require_folder") }]}
                        >
                            <Input readOnly placeholder={t("file_manager.folder")} />
                        </Form.Item>

                        <Form.Item
                            label={t("file_manager.folder")}
                            name="folder_name"
                            rules={[{ required: true, message: t("file_manager.require_folder") }]}
                        >
                            <Input readOnly placeholder={t("file_manager.folder")} />
                        </Form.Item>

                        <div className="btn-group">
                            <CustomButton
                                type="grey"
                                icon={<SvgCross />}
                                title={t("shared.cancel")}
                                onClick={handleCancelForm}
                            ></CustomButton>
                            <CustomButton
                                htmlType="submit"
                                type="primary"
                                icon={<SvgTick />}
                                title={t("shared.ok")}
                                onClick={handleSubmitForm}
                                isDisabled={activeFolder?.id === targetedFolder?.id}
                            ></CustomButton>
                        </div>
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default MoveToModal;
