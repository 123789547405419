import { forwardRef, useEffect, useRef, useState, React } from "react";
import ZwibblerToolbars from "./Toolbars";
import "./ZwibblerHotSpotEditor.scss";

//lazy load
import loadable from "@loadable/component";
import { notification } from "antd";
import HotspotImage from "./HotspotImage";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
const ZwibblerModule = loadable.lib(() => import("src/assets/script/zwibbler"));

function safeZwibblerLoad(zwibbler, data) {
    // hàm load json data của zwibbler
    try {
        zwibbler.load(data);
    } catch (e) {
        try {
            // eslint-disable-next-line no-control-regex
            zwibbler.load(data.replace(/[^\x00-\x80]/g, "")); //Allow only ASCII characters
        } catch (e2) {
            /* eslint-disable */
            console.log({ e2 });
        }
    }
}

const ZwibblerHotspotEditor = forwardRef((props, ref) => {
    const {
        isReadonly = false,
        handleChangePaths,
        isLoadZwibbler = false,
        path,
        isEditing,
        isFinishCallApiEdit,
        onChangeImage,
        imageData,
    } = props;

    const [zwibbler, setZwibbler] = useState();
    const zwibblerEl = useRef(null);
    const nodeFlag = useRef(null); // biến flag để check node cuối cùng được add vào
    const commonRef = useRef({
        firstLoadPath: true,
        saveData: () => {},
    }); //{saveData,firstLoadPath}

    const { t } = useTranslation();

    const zwibblerConfig = {
        // Needed to indicate to Zwibbler that this is the main element.
        zwibbler: "",
        "z-controller": "mycontroller",
        background: "transparent",
        // confine: "view",
    };

    const saveData = (newZwibbler) => {
        try {
            const saved = newZwibbler.save("zwibbler3");

            // ----Xử lý T/H edit câu hỏi-----
            //Lần đầu coponent được mounted thì check xem đã call api xong chưa.
            //Nếu chưa thì check xem api chưa trả về thì sẽ ko gọi hàm handleChangePaths

            if (isEditing && !isFinishCallApiEdit) {
                return;
            }

            if (handleChangePaths instanceof Function) {
                handleChangePaths(saved, newZwibbler.getAllNodes());
            }

            // if (onChange instanceof Function) {
            //     onChange({
            //         answered: [saved],
            //     });
            // }
        } catch (error) {
            console.log({ error });
        }
    };

    commonRef.current.saveData = saveData;

    useEffect(() => {
        function initZwibbler() {
            if (window.Zwibbler && zwibblerEl.current && !zwibbler) {
                // initialize Zwibbler
                const newZwibbler = window.Zwibbler.create(zwibblerEl.current, {
                    // defaultZoom: "page",
                    // pageView: "true",
                    // confine: "'view",
                    defaultLineWidth: 3,
                    autoPickTool: false,
                    showToolbar: false,
                    showColourPanel: false,
                    showHints: false,
                    scrollbars: false,
                    readOnly: isReadonly,
                    setFocus: false, // Zwibbler will be unable to intercept any keyboard commands
                    allowZoom: false,
                    allowTextInShape: true, // Zwibbler to write text inside a closed shapes
                    // leaveTextToolOnBlur: true, // Zwibbler text tool data will be saved if user moves out of scratchpad
                });

                newZwibbler.on("document-changed", () => {
                    commonRef.current.saveData(newZwibbler);
                });

                newZwibbler.on("paste", () => {
                    // preventing pasting of images currently
                    return false;
                });

                function isClosedNode(nodes, ctx) {
                    if (nodes?.[0]) {
                        return ctx.getNodeProperty(nodes[0], "closed"); // true if node closed
                    }
                    return false;
                }

                function handleAddNodes(nodes, ctx) {
                    //khi click vẽ thì zb sẽ tạo ra 1 node tạm, khi vẽ xong thì sẽ xóa node tạm đi và thay bằng node đã vẽ
                    try {
                        if (nodes && ctx) {
                            if (nodeFlag.current === 1) {
                                //hàm check is node closed, phải là 1 node khép kín mới được
                                if (isClosedNode(nodes, ctx)) {
                                } else {
                                    notification.error({ message: t("hotspot.node_must_be_closed") });
                                    if (nodes?.[0]) {
                                        ctx.deleteNode(nodes[0]); // còn bug khi click liên tục
                                        // ctx.deleteNode(nodes[0]);
                                    }
                                }
                                nodeFlag.current = null;
                            } else if (nodeFlag.current === null) {
                                nodeFlag.current = 1;
                            }
                        }
                    } catch (error) {
                        notification.error({ message: error.message });
                    }
                }

                newZwibbler.on("nodes-added", function (nodes, _unused, remote) {
                    // console.log("Added %s nodes", nodes.length);
                    // showNodes(nodes);

                    handleAddNodes(nodes, newZwibbler);
                });

                // Delete mode: xóa node khi click vào
                newZwibbler.on("node-clicked", (node) => {
                    if (isDeleteMode.current) {
                        newZwibbler.deleteNode(node);
                    }
                });

                //setting
                newZwibbler.setConfig("confine", "view");
                setZwibbler(newZwibbler);
            }
        }

        //chạy interval để check zwibbler đã load xong chưa => mếu xong thì chạy hàm khởi tạo....
        let interval = setInterval(() => {
            if ("Zwibbler" in window) {
                initZwibbler();
                clearInterval(interval);
                // console.count("interval");
            }
        }, 100);

        if (zwibbler)
            return () => {
                setZwibbler(null);
            };
    }, []);

    useEffect(() => {
        //load path 1 lần đầu tiên
        if (zwibbler && path && isFinishCallApiEdit && commonRef.current.firstLoadPath) {
            safeZwibblerLoad(zwibbler, path);
            commonRef.current.firstLoadPath = false;
        }
    }, [zwibbler, path, isFinishCallApiEdit]);

    return (
        <div className="zwibbler-draw-container ">
            {!isReadonly && (
                <div className="toolbar-wrapper">
                    <ZwibblerToolbars ctx={zwibbler} handleClearPaths={handleChangePaths} />
                </div>
            )}
            <div
                className="zwibbler-container"
                id="scrollable"
                style={{ width: imageData?.currentWidth || 500, height: imageData?.currentHeight || 500 }}
            >
                <HotspotImage ref={ref} onChangeImage={onChangeImage} imageData={imageData}>
                    {/* <div className="custom-zwibbler" style={{ height: height || 400 }}> */}
                    {/* <div className="zwibbler-image-background">{src && <img src={src} />}</div> */}

                    {/* </div> */}
                    <div ref={zwibblerEl} {...zwibblerConfig} id="zwibbler-canvas" className="zwibbler">
                        <div z-canvas="" className="stretch" confine="view" />
                    </div>
                </HotspotImage>
            </div>
            {/* //layzyload module */}
            {isLoadZwibbler && <ZwibblerModule></ZwibblerModule>}
        </div>
    );
});

export default ZwibblerHotspotEditor;
