import { en_questions } from "./questions/en";

export default {
    ...en_questions,

    role_option: {
        student: "Student",
        teacher: "Teacher",
        pick_your_role: "Who are you?",
    },

    account: {
        current_account: "Current account",
        // Actions:
        upgrade: "Upgrade",
        upgrade_now: "Upgrade now",
        contact_support: "Contact support",
        pick_your_suitable_subscription_plan: "Subscription plans",
    },

    plan: {
        plan_name: "Plan name",
        option: "Option",
        buy_more_options: "Buy more options",
        select_options: "Select options",
        disabled: "Disabled",
    },

    price: {
        amount: "Amount",
        total: "Total",
        paid: "Paid",
        vnd: "VND",
        vnd_per_month: "VND/month",
        free: "Free",
        month: "month",
    },

    payment: {
        payment: "Payment",
        extend_subscription: "Extend subscription",
        buy_options: "Buy options",
        order: "Order",
        promotion: "Promotion",
        payment_result: "Payment result",
        method_CASH: "Pay in cash",
        method_TRANSFER: "Bank transfer",
        method_VISA: "Visa payment",
        method_ATM_VNPAY: "ATM card and bank account",
        method_VNMART: "Pay with VNPAY",
        method_VNPAYQR: "Pay with VNPAY QR",
        method_PORTONE: "Online Payment",
        method_ONEPAY_QR: "Pay with banking app and e-wallet",
        method_ONEPAY_DOMESTIC: "Domestic payment card and bank account",
        method_ONEPAY_INTERNATIONAL: "International payment card",
        bank: "Bank",
        account_name: "Account name",
        account_number: "Account number",
        payment_content: "Payment content",
        price: "Price",
        paid: "Paid",
        not_paid: "Not paid",
        pay_error: "Payment error",
        card_field: {
            price_table: "Price table",
            price_table_desc: "Use Stripe for online payment: card, Paypal",
            ai_function: "A.I. features",
            ai_credit: "A.I. credit",
            storage_available: "Storage available",
            limitof_asgmt_created: "Limit of assignments created",
            limitof_q_created: "Limit of questions created",
            limitof_q_in_exam: "Limit of questions in exam",
            limitof_candidates: "Limit of candidates",
            limitof_tests_created_from_an_asgmt: "Limit of tests created from an assignment",
            limitof_employees: "Limit of employees",
            cleanup: "Free up storage space",
        },
        suffix: {
            questions: "Questions",
            exams: "Exams",
            candidates: "Candidates",
            tests_created_from_an_asgmt: "Tests created/template",
            contact: "Contact",
            unlimited: "Unlimited",
            user_custom: "Free up by user",
            auto_30_days: "Free up automatically",
        },
        // Description:
        method_CASH_please_make_payment_at: "Please make payment at Phuong Nam Education.",
        method_CASH_payment_address: "Address: 357 Le Hong Phong street, ward 2, district 10, Ho Chi Minh City.",
        method_TRANSFER_description: "As soon as we receive your payment, your plan will be activated.",
        method_PORTONE_description: "Support payment via ATM, VISA, MASTERCARD, e-wallet.",
        viewtype_extend_desc: "You are extending subscription for",
        viewtype_opts_desc: "You are buying more options for",
        // Actions:
        select_to_buy: "Select to buy",
        unselect_to_buy: "Selected",
        purchase: "Purchase",
        input_promotion_code_or_not: "Enter your promo code",
        select_plan_price: "Select a plan",
        // Messages:
        err_can_not_purchase: "Payment error",
        err_can_not_apply_promocode: "Can not apply the promotional code",
        please_input_promocode: "Please enter a promo code",
        please_select_a_plan: "Please select a plan",
        please_select_a_payment_method: "Please select a payment method",
        cannot_apply_more_promocodes: "You can not apply more promotional codes",
        sucess_make_payment_transaction: "You have done a payment transaction",
        fail_make_payment_transaction: "Your payment transaction cannot be completed",
        this_code_is_already_used: "This code is already used",
    },

    audio: "Audio",
    phonetic: "Phonetic",
    username: "Username",
    password: "Password",
    first_name: "First name",
    last_name: "Last name",
    full_name: "Full name",
    email: "Email",
    active: "Active",
    inactive: "Inactive",
    role: "Role",
    student: "Student",
    teacher: "Teacher",
    employee: "Employee",
    employee_crm: "Employee CRM",
    unknown: "Unkown",
    admin: "Admin",
    status: "Status",
    no_name_yet: "No name yet",
    // Action:
    select_role: "Select role",
    add_user: "Add user",
    update_user: "Update user",
    update_success: "Update success",
    find_user: "Find user",

    // Message:
    add_new_success: "Add new success",
    require_role: "Require select role",
    require_username: "Require username",
    require_email: "Require valid email",
    delete_error: "Delete error",
    delete_success: "Delete success",
    choose_role: "Choose role",
    choose_status: "Choose status",
    scored_manually: "Scored manually",
    an_error_occurred: "An error occurred",

    tooltip_update_password_user: "Leaving this field blank will retain the old password",

    export: "Export file",
    import_user_success: "The file upload is successful, the data import process may take a few minutes or longer",
    add_user_fail: "Add user failed",
    download_sample_file: "Download sample files",
    batch_import: "Batch import",
    batch_import_teacher: "Batch import (teachers)",
    batch_import_student: "Batch import (students)",

    reset_success: "Reset success",

    code_sent_success: "Code sent successfully. Please check your email",

    code_sent: "Code sent",
    resent_code: "Resend code",
    email_verification: "Email Verification",
    email_erification_code: "Email Verification Code",
    your_photo_not_valid: "Your photo is not valid. Please try another photo",
    valid_photo: "Valid photo",
    checking_image: "Checking image",
    your_image: "Your picture",
    image_form_webcam: "Photo taken from webcam",
    verified_successfully: "Verified successfully",
    verification_failed: "Verification failed",
    please_choose: "Please choose",
    faces_not_match: "Faces don't match",
    loading_resources: "Loading resources",
    image_processing_please_wait: "Image processing, please wait a moment",
    take_photo: "Take a photo",
    face_verification: "Face verification",
    faces_cannot_detected: "Faces cannot be detected in the image",
    please_take_another_photo: "Please take another photo",
    next: "Next",
    error_occurred_please_reload: "An error occurred while loading the resource, please reload the page!",
    this_behavior_considered_fraud: "This behavior may be considered fraud",
    opening_devtool_not_allowed: "Opening devtool is not allowed",
    please_use_another_photo: "Please use another photo",
    many_faces_detected: "Detected that there were many faces in the image",
    no_face_detected: "No face detected",
    no_taken_photo_from_webcam: "You have not taken a photo from the webcam",
    please_wait_a_moment: "Please wait a moment",
    personal_number: "Personal number",
    upload_or_drag: "Click to upload photo or drag and drop here",
    id_photo_verification: "ID photo verification",
    warning: "Warning",
    violation_warning: "Violation warning",
    times: "Times",

    breadcumb: {
        goback: "Go back",
    },

    help: {
        help: "Help",
        next: "Next",
        pre: "Previous",
        nodata: "No data",
    },

    student_courses: {
        //Course Card Item
        study: "Study",
        score: "Score",
        assignments: "Assignments",
        exercises: "Exercises",
        list_lesson: "Lesson list",
        empty_courses: "Empty courses",
    },

    teacher_courses: {
        //Course Card Item
        progress: "Progress",
        assignments: "Assignments",
        exercise: "Exercise",
        list_lesson: "Lesson list",
        empty_courses: "Empty courses",
        find_courses_by_user: "Find courses by user",
        find_class_by_user: "Find classes by user",
    },

    shared: {
        from: "From",
        to: "To",
        info: "Info",
        // Title:
        warning: "Warning",
        // Info:
        fullname: "Full name",
        phonenumber: "Phone number",
        message: "Message",
        // Scope:
        scope: "Scope",
        public: "Public",
        private: "Private",
        only_me: "Only me",
        // Others:
        quantity: "Quantity",
        quantity_abbr: "Quantity",
        no_data: "No data",
        exit: "Exit",
        loading: "Loading",
        redirecting: "Redirecting",
        searching: "Searching",
        search: "Search...",
        check: "Check",
        checking: "Checking",
        preparing: "Preparing",
        try_again: "Try again",
        setting: "Setting",
        signout: "Sign out",
        save: "Save",
        back: "Back",
        confirm: "Confirm",
        clone: "Clone",
        apply: "Apply",

        category: "Category",
        tags: "Tags",
        level: "Level",
        owner: "Owner",
        question: "Question",
        type: "Type",
        status: "Status",
        created_at: "Date created",
        updated_at: "Date modified",
        choose_category: "Choose category",
        choose_tags: "Choose tags",
        choose_type: "Choose type",
        choose_status: "Choose status",
        choose_public: "Choose public",
        score: "Score",
        total_score: "Total score",
        answer: "Answer",

        save_draft: "Save as draft",
        publish: "Publish",
        add_new_question: "Add new question",
        add_new_exam: "Add new exam",
        find_question: "Find question",
        find_exam: "Find exam",
        find_assignment: "Find assignment",
        add_assignment: "Add assignment",
        option_filter: "Filter",
        unknown: "Unknown",
        search_and_select_category: "Search and select a category...",
        search_and_select_tags: "Search and select a tag...",
        search_and_select_classes: "Search and select a class...",
        mark: "Mark",
        copy_invite_link: "Copy invite link",
        copy_invite_link_success: "Link has been copied to clipboard!",
        or: "or",
        copy_the_link_below: "Copy the link below",
        active: "Active",
        inactive: "Inactive",
        all: "All",
        is_removed: "Removed",
        // Fields:
        note: "Note",
        note2: "Note",
        // Actions:
        ok: "Ok",
        cancel: "Cancel",
        yes: "Yes",
        no: "No",
        reset: "Reset",
        add: "Add",
        add_new: "Add New",
        update: "Update",
        edit: "Edit",
        delete: "Delete",
        copy: "Copy",
        submit: "Submit",
        view_detail: "View detail",
        upate_image: "Update image",
        select_scope: "Select a scope",
        contact_us: "Contact Us",
        select_files: "Select files",
        download: "Download",
        upload: "Upload",
        uploading: "Uploading",
        uploaded: "Uploaded",
        // Confirm:
        confirm_cancel: "Cancel",
        confirm_reset: "Reset",
        confirm_update: "Update",
        confirm_delete: "Delete",
        confirm_leave: "Leave",
        confirm_action: "Confirm",
        msg_confirm_cancel: "Are you sure to cancel",
        msg_confirm_reset: "Are you sure to reset?",
        msg_confirm_delete: "Are you sure to delete",
        msg_confirm_leave: "Are you sure to leave?",
        msg_confirm_action: "Are you sure to do this action",
        msg_confirm_action_of: "Are you sure to",
        msg_confirm_action_of_demo_account: "Are you sure to login with demo account",
        msg_confirm_action_of_main_account: "Are you sure to get back to your main account",
        msg_confirm_redo: "Do you want to do this exercise again?",
        // Message:
        warning_demo_account: "You are logged in with a demo account!",
        you_dont_have_permission: "You don't have permission to do this!",
        input_fullname: "Please enter your full name",
        input_phonenumber: "Please enter your phone number",
        input_message: "Please enter a message",
        input_email: "Please enter your email address",
        //user
        gender: "Gender",
        male: "Male",
        female: "Female",
        find: "Search...",
        select_image: "Select image",

        //qrcode
        download_qr: "Download QR code",
        set_correct_answer: "Set correct answer",
        close_modal: "Close",
        play: "Play",
        go: "GO",
        list_end: "End",
        width: "Width",
        height: "Height",
        show: "Show",
        hide: "Hide",
        start_doing: "Start doing",
        course_or_class: "Course/Class",

        something_went_wrong: "Oops! Something went wrong.",
    },

    html_status_code: {
        page_404_title: "Page not found!",
        page_404_msg: "Sorry, the page doesn't exist!",
        action_go_back_home: "Go back to Homepage",
    },

    action: {
        add_new: "Add New",
        update: "Update",
        delete: "Delete",
        save: "Save",
        back: "Back",
        confirm: "Confirm",
        apply: "Apply",
        yes: "Yes",
        no: "No",
        ok: "Ok",
        cancel: "Cancel",
        signout: "Sign out",
        clone: "Clone",
        copy_invite_link: "Copy invite link",
        copy_invite_link_success: "Link has been copied to clipboard!",
        // Confirm:
        confirm_delete: "Delete",
        confirm_action: "Confirm",
        msg_confirm_cancel: "Are you sure to cancel",
        msg_confirm_delete: "Are you sure to delete",
        msg_confirm_action: "Are you sure to do this action",
        msg_confirm_action_of: "Are you sure to",
        verify_again: "Verify again",
        update_info_again: "Update information again",
        change_pages_after: "Change pages after",
        view: "View",
    },

    language: {
        language: "Language",
        vi: "Vietnamese",
        en: "English",
        select_language: "Select language",
    },

    header: {
        // Layout header:
        say_hi: "Hello",
        // Page header:
        go_back: "Go back",
        modify: "Modify",
        preview: "Preview",
        question_bank: "Question bank",
        exam_bank: "Exam bank",
        say_hello: "Hello",
        assignment: "Assignment",
        exam: "Exam",
        organization: "Organization",
        my_exam: "My Exam",
        my_questions: "My Question",
        help: "Help",
        guide: "Help",
        manage_user: "Manage user",
    },

    nav: {
        notification: "Notification",
        account: "Account",
        profile: "Profile",
        account_info: "Account info",
        logout: "Logout",

        // Role - teacher:
        courses: "Courses",
        class: "Manage classes",
        question_bank: "Question bank",
        exam_bank: "Exam bank",
        assignment: "Assign exam",
        learning_progress: "Learning progress",
        personal_leaning_progress: "Personal learning progress",
        my_storage: "My storage",
        organization: "Organization",
        my_resources: "My Resources",
        my_resources_question: "Questions",
        my_resources_exam: "Exam",

        // Role - student:
        exam_asset: "Exam assets",
        classes: "Classes",
        assignments: "Assignments",
        scores: "Scores",
        gradebook: "Gradebook",
        guide: "Help",
        manage_user: "Manage user",
        score_gradebook: "Scores - gradebook",
        score_assignment: "Scores - exam",
        feedback: "Feedback",
        history: "History",
        action_statistics: "Action Statistics",
        dashboard: "Dashboard",
    },

    select_question: {
        // Messages:
        null: "Have not selected the type",
        tutorial_tooltip: "Click me to display instruction for question types",
        scoring_manual: "Manual scoring",
        scoring_auto: "Automatic scoring",
    },

    question: {
        no_question: "No questions",
        question: "Question",
        question_number_list: "List of questions",
        show_answer: "Show answer",
        empty_answer: "The answer has been left blank",
        // Type:
        header_highlight: "Highlight",
        multiple_choice: "Multiple choice",
        multiple_choice_boolean: "Multiple choice (boolean)",
        multiple_choice_table: "Multiple choice (table)",
        writing: "Writing",
        fill_in_the_blank: "Fill in the blanks (select)",
        fill_in_the_blank_text: "Fill in the blanks (write)",
        fill_in_the_blank_drag_drop: "Fill in the blanks (drag drop)",
        passage: "Passage",
        sort: "Sort",
        drag_drop: "Drag drop",
        drag_drop_group: "Drag drop group",
        highlight: "Highlight",
        hotspot: "Hotspot",
        fill_in_the_blank_image: "Image lable and dropdown",
        fill_in_the_blank_text_image: "Image lable and text",
        fill_in_the_blank_drag_drop_image: "Image lable and drag drop",
        speech_to_text: "Speech to text",
        speech_to_text_conversation_audio: "Speech to text conversation audio",
        speech_to_text_conversation_text: "Speech to text conversation text",
        phonetic_table: "Phonetic table",

        // Fields:
        owner: "Owner",
        updater: "Updater",
        owner_me: "Me",
        owner_all: "All",
        done: "Done",
        draft: "Draft",
        private: "Private",
        public: "Public",
        program: "Program",
        level: "Level",
        tags: "Tags",
        answer: "Answer",
        group: "Group",
        score: "Score",
        publishing_status: "Publishing status",
        only_me: "Only me",
        type: "Type",
        status: "Status",
        total_score: "Total score",
        created_at: "Created date",
        updated_at: "Updated date",
        // Actions:
        view: "View",
        copy: "Make a copy",
        save: "Save",
        cancel: "Cancel",
        input_answer: "Input answer",
        choose_level: "Choose level",
        choose_tags: "Choose tags",
        choose_type: "Choose type",
        choose_status: "Choose status",
        choose_public: "Choose public",
        save_draft: "Save as draft",
        publish: "Publish",
        choose_option: "Choose an option",
        select_answer: "Select an answer",
        select_question_type: "Select a question type",
        select_level: "Select level",
        select_tags: "Select tags",
        select_owner: "Select the owner",
        message_help_group: "This is the column used to group answers",
        message_choose_the_correct_answer: "Choose correct answer of group",
        input_new_tag: "New tag name...",
        input_new_tag_color: "Tag color (hex)",
        required_publishing_status: "You haven't selected publishing status!",
        please_input_groups: "Please input name of group",
        correct_answer: "Correct answer",
        your_answer: "Your answer",
        add_new_question: "Add new question",
        find_question: "Find question",
        option_filter: "Filter",
        search_and_select_level: "Search and select a level...",
        search_and_select_tags: "Search and select a tag...",
        // Sections:
        header_multiple_choice: "Multiple choice question",
        header_multiple_choice_table: "Multiple choice (table)",
        header_multiple_choice_boolean: "Multiple choice (boolean)",
        header_fill_in_the_blanks: "Fill in the blanks (select)",
        header_fill_in_the_blanks_text: "Fill in the blanks (write)",
        header_fill_in_the_blanks_drag_drop: "Fill in the blanks (drag drop)",
        header_writing: "Writing",
        header_passage: "Passage",
        header_sort: "Sort",
        header_drag_drop: "Drag drop",
        header_drag_drop_group: "Drag drop group",
        header_passage_add_child: "Add passage",
        hint_create_blanks_1: "Press",
        hint_create_blanks_2: "button to add a blank",
        hint_highlight_1: "Highlight (word, sentence, paragraph) then click the",
        hint_highlight_2: "button to choose the correct answer",
        // Others:
        personal_learning_progress: "Personal learning progress",
        message_shuffle: "Answers will be shuffle when students do the test",
        help_highlight:
            "Highlight (word, sentence, paragraph) then click the Highlight button to choose the correct answer",
        question_skipped: "This question has been skipped",

        method: "Method",
        correct: "Correct",
        incorrect: "Incorrect",
        options: "Options",
        show_pinyin: "Show Pinyin",
        hide_pinyin: "Hide Pinyin",
    },

    question_panel: {
        question: "Question",
        write_here: "Write here",
    },

    question_writing: {
        word_limit: "Word limit",
    },

    question_fillblanks: {
        add_answer: "Add an answer",
        require_answers: "Answer fields are required",
        is_require_uppercase: "Uppercase and lowercase validation",
    },

    question_multiple_choice: {
        index: "No.",
        answer: "Answer",
        blank_answer: "Please enter all the answers",
        blank_result: "Please enter choose at least one result",
        add_success: "New question has been added",
        add_error: "Fail to add new question",
        save_as_draft_success: "Save successfully",
        save_as_draft_error: "Fail to save as draft",
        must_choose_two_or_more_answers: "Must choose two or more answers",
    },

    question_passage: {
        add_paragraph: "Add a paragraph",
        add_question: "Add a question",
        quick_add_question: "Quick add question",
        heading: "Heading",
        title: "Title",
        placeholder_heading: "Passage's heading",
        placeholder_title: "Passage's title",
        require_question_data: "Question data should not be empty!",
        select_language_first: "You need to select the language first!",
        btn_toggle_note_extend: "Click on the arrow to expand the worksheet",
        btn_toggle_note_down: "Click on the arrow to narrow the worksheet",
    },

    question_dragdrop: {
        add_answer: "Add an answer",
        require_answers: "Answer fields are required",
    },

    question_dragdrop_group: {
        add_answer: "Add an answer",
        require_corresponding_answers: "Please fill in the corresponding answer",
        require_answers: "Please fill in answer",
    },

    question_sort: {
        add_answer: "Add an answer",
        require_answers: "Answer fields are required",
    },

    create_multiple_choice: {
        answer: "Answer",
        blank_answer: "Please enter all the answers",
        blank_result: "Please enter choose at least one result",
        add_success: "New question has been added",
        add_error: "Fail to add new question",
        save_as_draft_success: "Save successfully",
        save_as_draft_error: "Fail to save as draft",
    },

    gradebook: {
        unknown: "Unknown",
        my_learning_path: "My Learning Path",
        //table header
        activity: "Activity",
        level: "Level",
        score: "Score",
        last_taken: "Last Taken",
        attempts: "Attempts",
        time_spend: "Total Time Spend",
        begin_date: "Begin date",
        end_date: "End date",
        max_score: "Max score",
        count_submit: "Submited",
        count_marked: "Marked",
        percent: "Percent",
        loadmore: "Load more...",
        empty_exam: "Empty exam",

        //filter
        unit: "Unit",
        course: "Course",
        filter: "Filter",
        expired: "Expired",
        assigned: "Assigned",
        all: "All",
        clear: "Clear",
        apply: "Apply",
        sort: "Sort",
        asc: "Ascending",
        desc: "Descending",
        please_select_sort_type: "Please select sort type",
        please_select_level: "Please select level",
        please_select_unit: "Please select unit",
        please_select_course: "Please select course",

        doing: "Doing",
        donot: "Do not",
        marked: "Marked",
        submited: "Submited",
        redo: "Re-do",
        review: "Review",
    },

    course: {
        name: "Name",
        course_name: "Course_name",
        type: "Type",
        level: "Level",
        start_date: "Start date",
        end_date: "End date",
        complete: "Complete",
        teacher: "Teacher",
        employee: "Employee",
        responsible: "Responsible",
        student: "Student",
        bgImage: "Background image",
        // Messages:
        student_no_courses: "You have not joined any courses yet",
        teacher_no_courses: "You have no courses",
        courses_empty: "There are no courses",
        classes_empty: "There are no classes",
        // Titles:
        course_list: "Course list",
        your_courses: "Your courses",
        your_classes: "Your classes",
        // Status:
        all: "All",
        not_started_yet: "Not started yet",
        active: "Active",
        finished: "Finished",
        // Actions:
        learn: "Learn",
        score: "Score",
        add_course: "Add course",
        load_more_courses: "Load more courses",
        select_level: "Select level",
        input_course_bgImage: "Choose background image",
        // Others:
        course: "Course",
        assignment: "Assignment",
        list_course: "Course list",
        active_course: "Active courses",
        finished_course: "Finished courses",
        code: "Course code",
        begin_date: "Begin date",
        no_teacher: "No teacher",
        load_more_course: "Load more",
        update_course: "Update course",
        add_student: "Add student",
        add_teacher: "Add teacher",
        invite_student: "Invite student",
        invite_teacher: "Invite teacher",
        invite_teacher_marking: "Invite teacher",
        select_student: "Select student",
        select_teacher: "Select teacher",
        upload_student_list: "Upload a list of student",
        manage_course: "Manage courses",
        manage_user: "Manage users",
        manage_student: "Manage students",
        manage_exam: "Manage assignments",
        student_name: "Student's name",
        phone_number: "Phone number",
        email: "Email",
        gender: "Gender",
        role: "Role",
        male: "Male",
        female: "Female",
        unknown: "Unknown",
        status: "Status",
        password: "Password",
        add_exam: "Add new exam",
        assign_exam: "Assign exam",
        exam_name: "Name",
        max_score: "Max score",
        submission: "Number of submissions",
        graded: "Number of graded submissions",
        edit_list: "Edit list",
        done: "Done",
        student_amount: "Number of students",
        confirm_delete: "Are you sure to delete?",
        will_delete: "will be deleted from the course. Are you sure?",
        input_course_code: "Input course code",
        input_course_name: "Input course name",
        input_begin_date: "Input start date",
        input_end_date: "Input end date",
        input_teacher_name: "Input teacher name",
        input_exam_name: "Input exam name",
        select_exam: "Select exam",
        select_assignment: "Select assignment",
        input_assignment_name: "Input a new name for the assignment",
        input_assignment_description: "Input assignment description",
        select_course: "Select course",
        duration: "Duration",
        input_time: "Minutes",
        exam: "Exam",
        assignment_name: "Assignment name",
        assignment_description: "Description",
        ownership: "Ownership",
        me: "Mine",
        mark_assignment: "Mark assignment",
        assignment_result: "Assignment result",
        learning_progress: "Learning progress",
        report: "Report",
        start_time: "Start time",
        end_time: "End time",
        count_correct_sentences: "Count correct sentences",
        // Invitation:
        send_invitation: "Invite",
        send_invitation_success: "Invitation sent successfully",
        send_invitation_error: "Failed to send invitation",
        you_are_not_teacher: "You are not teacher",
        you_are_not_student: "You are not student",
        // Invite teacher:
        teacher_email: "Teacher's email",
        add_teacher_not_others: "Only teacher can join the course",
        add_teacher_success: "Add teacher successfully",
        add_teacher_error: "Failed to add teacher",
        // Invite student:
        add_student_not_others: "Only student can join the course",
        add_student_success: "Add student successfully",
        add_student_error: "Failed to add student",
        // Assignment type:
        listening: "listening",
        speaking: "speaking",
        reading: "reading",
        writing: "writing",
        vocabulary: "vocabulary",
        grammar: "grammar",
        review: "review",
        load_more: "Load more",
        my_class: "My class",
        class_code: "Class code",
        update_class: "Update class",
        select_course_or_class: "Select course or class",
        student_id: "Student code",
    },

    unit: {
        // Fields:
        name: "Name",
        title: "Title",
        order: "Ordinal number",
        // Actions:
        manage_unit: "Manage topics",
        add_unit: "Add topic",
        update_unit: "Update topic",
        input_unit_name: "Input topic name",
        input_unit_title: "Input topic title",
        input_unit_order: "Input ordinal number",
        input_course_order: "Input ordinal number",
        add_error_missing_course_id: "Unknown course",
        add_error_missing_fields: "You must fill out the form",
        load_more_units: "Load more topics",
        // Messages:
        unit_list_empty: "There are no topics",
        do_homework: "Do Homework",
        empty_unit_assignment: "Empty assignemnts",
        redo: "Redo",
    },

    login: {
        welcome_back: "Welcome back",
        login_to_experience: "Login to experience",
        enter_your_email: "Enter your email",
        password: "Password",
        retype_password: "Retype password",
        forget_password: "Forget password",
        remember: "Remember me",
        or_login_with: "Or login with",
        or_register_with: "Or register with",
        login_with_facebook: "Facebook",
        login_with_google: "Google",
        sign_in: "Sign in",
        sign_up: "Sign up",
        forgot_password: "Forgot password?",
        dont_have_account: "Don't have an account?",
        free_register: "Free register",
        start_signup_free: "Start creating a free account!",
        intro: "Join the growing teacher community using Test Online methods to promote learning",
        who_are_you: "Who are you?",
        teacher: "Teacher",
        student: "Student",
        sign_up_now: "Sign up now",
        please_fill_in_the_form: "Please fill in the form",
        still_remember: "Still remember password?",
        send: "Send",
        enter_mail_to_reset_password: "Enter your email to reset password",
        email_sent: "Email sent!",
        send_error: "Fail to send mail!",
        check_email: "Please check your email and click the link to reset password",
        havent_received_link: "Haven't received the link?",
        resend_link: "Resend link",
        reset_password: "Create new password",
        rule_new_password: "New password must be different from the old password",
        receive_code: "Receive verify code in your email",
        new_password: "New password",
        retype_new_password: "Retype new password",
        save_password: "Save password",
        verify_email: "Verify email",
        please_verify: "Please input the code sent to your email",
        enter_code: "Enter code",
        verify: "Verify",
        verify_code: "Verify code",
        open_account_for: "Register as",
        account_activated: "This account is already activated",
        please_login_to_mark: "Please login to mark students'assignments",
        invalid_path: "Invalid path",
        update_your_password: "Update your new password",
        update_password: "Update password",
        username: "Username",
        guide: "Guide",
        introducing_ELearning: "Introducing E-Learning",
        create_student_account: "Create a student account",
        documentation_guidelines: "Documentation Guidelines",
        create_teacher_account: "Create a teacher account",
        forgot_username: "Forgot username?",
        required: "Please fill out this field!",
        password_rule: "Password must be at least 4 characters long",
    },

    assigmment_topic: {
        view_detail: "Detail",
    },

    // login: {
    //     welcome_back: "Welcome back",
    //     login_to_experience: "Login to experience",
    //     enter_your_email: "Enter your email",
    //     password: "Password",
    //     retype_password: "Retype password",
    //     forget_password: "Forget password",
    //     remember: "Remember me",
    //     or_login_with: "Or login with",
    //     or_register_with: "Or register with",
    //     login_with_facebook: "Facebook",
    //     login_with_google: "Google",
    //     sign_in: "Sign in",
    //     sign_up: "Sign up",
    //     forgot_password: "Forgot password",
    //     dont_have_account: "Don't have an account?",
    //     free_register: "Free register",
    //     start_signup_free: "Start creating a free account!",
    //     intro: "Join the growing teacher community using Test Online methods to promote learning",
    //     who_are_you: "Who are you?",
    //     teacher: "Teacher",
    //     student: "Student",
    //     sign_up_now: "Sign up now!",
    //     please_fill_in_the_form: "Please fill in the form",
    //     still_remember: "Still remember password?",
    //     send: "Send",
    //     enter_mail_to_reset_password: "Enter your email to reset password",
    //     email_sent: "Email sent!",
    //     send_error: "Fail to send mail!",
    //     check_email: "Please check your email and click the link to reset password",
    //     havent_received_link: "Haven't received the link?",
    //     resend_link: "Resend link",
    //     reset_password: "Create new password",
    //     rule_new_password: "New password must be different from the old password",
    //     receive_code: "Receive verify code in your email",
    //     new_password: "New password",
    //     retype_new_password: "Retype new password",
    //     save_password: "Save password",
    //     verify_email: "Verify email",
    //     please_verify: "Please input the code sent to your email",
    //     enter_code: "Enter code",
    //     verify: "Verify",
    //     verify_code: "Verify code",
    //     open_account_for: "Register as",
    // },

    statistical: {
        overview: "Overview",
        student: "Student",
        question: "Question",
        choose_all: "CHOOSE ALL",
        filter_by_status: "FILTER BY STATUS",
        assigned_student: "Assigned students",
        not_submit: "Have not submitted",
        doing: "Doing",
        submitted: "Submitted",
        graded: "Graded",
        progress: "Progress",
        submit_at: "Submit at",
        question_grade: "Question grade",
    },

    exam: {
        name: "Name",
        // Actions:
        add_new_exam: "Add new exam",
        add_assignment: "Add exam",
        find_assignment: "Find exam",
        find_exam: "Find exam",
        option_filter: "Filter",
        reset: "Reset the test",
    },

    question_bank: {
        done: "Done",
        draft: "Draft",
        view: "View",
        copy: "Make a copy",
        choose_option: "Choose an option:",
        personal_learning_progress: "Personal learning progress",
    },

    exam_bank: {
        name: "Name",
        owner: "Owner",
        list_question: "List question",
        add_question: "Add question",
        total_score: "Total score",
        add_question_to_exam: "Add questions to exam",
        question_list_is_empty: "Question list is empty",
        create_exam_success: "New exam has been created",
        create_exam_error: "Fail to create new exam",
        score_is_empty: "Score is empty",
        create_question: "Create question",
        up: "Up",
        down: "Down",
    },

    exam_result: {
        count_correct_answers: "Number of correct answers",
        your_assignment: "Your assignment",
        comment: "Comment",
        teacher_comment: "Teacher's comment",
        no_comment: "No comment",
        your_assignment_not_found: "Your assignment was not found",
    },

    message: {
        content_highlight_not_valid: "Content highlight is not valid",
        you_have_chosen_this_correct_answer: "You have chosen this correct answer",
        empty_data: "Empty Data",
        warning_missing_fields: "You must fill out the form",
        warning_duplicate_answer: "You are using two identical answers",
        warning_duplicate_group: "You are using two identical groups",
        you_have_not_filled_enough_info: "You have not filled enough info to do this action",
        required: "Please fill out this field!",
        free_text: "Please input or delete this field!",
        min0: "Input value must be greater than 0!",
        add_success: "New added success!",
        add_error: "New add failed!",
        update_success: "Update successful!",
        upload_success: "Uploaded successfully!",
        update_error: "Update failed!",
        delete_success: "Delete successful!",
        delete_error: "Delete failed!",
        submit_success: "Submit successful!",
        submit_error: "Submit failed!",
        system_error: "Sytem error!",
        sth_wrong: "Oh, something went wrong!",
        clone_success: "Copy successful!",
        clone_error: "Copy failed!",
        password_rule: "Password must be at least 4 characters long",
        password_not_match: "Password does not match",
        invalid_email: "Invalid email address",
        register_success: "Sign up successful!",
        register_error: "Sign up failed!",
        login_error: "Login error!",
        login_fail: "Wrong email or password!",
        third_party_login_error: "Login failed!",
        err_begin_date_is_greater: "End date must be greater than begin date!",
        email_sent: "Email sent!",
        reset_success: "Reset password successful!",
        reset_error: "Reset password failed!",
        mark_success: "Grade successful!",
        mark_error: "Grade failed!",
        login_success: "Login successful!",
        logout_success: "Logout successful!",
        logout_success_passive: "You has been logged out!",
        require_less_than_anwers: "Answers must be have less than 2",
        require_less_than_groups: "Groups must be have less than 2",
        // Question:
        answer_empty: "The answer has been left empty",
        // File manager:
        file_extension_supported: "Supported file extension",
        please_try_again_later: "Please try again later",
        please_try_another_file_extension: "Please try another file extension",
        err_can_not_upload_files: "Error! Can not upload files",
        err_file_extension_not_valid: "Error! File extension is not valid",
    },

    table: {
        actions: "Actions",
    },

    profile: {
        profile: "Profile",
        full_name: "Full name",
        gender: "Gender",
        gender_male: "Male",
        gender_female: "Female",
        role: "Role",
        role_student: "Student",
        role_teacher: "Teacher",
        phone: "Phone",
        active: "Active status",
        active_false: "Inactive",
        active_true: "Active",
        update_profile: "Update profile",
        first_name: "First name",
        last_name: "Last name",
        phone_number: "Phone number",
        email: "Email",
        address: "Address",
        password: "Password",
        new_password: "New password",
        confirm_newPassword: "Confirm new password",
        cancel: "Cancel",
        save_profile: "Save profile",
        update_success: "Update profile success",
        update_error: "Update profile error",
        please_input_password: "Please input password field",
        please_input_phone: "Please input phone number field",
        wrong_password: "Wrong password",
        select_gender: "Select gender",
        select_active: "Select active status",
        please_enter_minimum_character: "Please enter at least 4 characters",
        field_is_require: "This field is require",
        change_password: "Change password",
    },

    userBoard: {
        full_name: "Full name",
        class: "Class",
        subject: "Subject",
        course: "Course",
    },

    report: {
        question_number: "Question",
        select_question: "Select a question",
        very_good: "Very good",
        good: "Good",
        ordinary: "Ordinary",
        failed: "Failed",
        level_completion_score: "Level completion score",
        complete_progress: "Complete progress",
        ratio: "Ratio",
        dateFrom: "Date from",
        dateTo: "Date to",
        subject: "Subject",
        class: "Class",
        test_name: "Test name",
        name_of_course: "Name of course",
        student_list: "Student list",
        please_select_question: "Please select question",
        please_select_date: "Please select date",
        please_select_level: "Please select level",
        please_select_class: "Please select class",
        please_select_student: "Please select student",
        please_select_test_name: "Please select test name",
        apply: "Apply",
        cancel: "Cancel",
        filter: "Filter",
        test_classification: "Test Classification",
        rate_tests_taken: "Rate test taken",
        name_of_class: "Name of class",
        subject2: "Subject",
        test: "Test",
        exercise: "Exercise",
        average_score: "Average score",
        grasp: "Grasp",
        all_classes: "All classes",
        all_subject: "All subject",
        all: "All",
        progress: "Progress",
        personal_learning: "Personal learning",
        general_learning: "General learning",
        student_name: "Student name",
        score: "Score",
        select_student: "Select student",
        is_student_submitted: "Submitted",
        is_student_not_submitted: "Not submitted yet",
        is_student_not_started: "Not started yet",
        is_teacher_submitted: "Scored",
        is_teacher_not_submitted: "Not scored yet",
        student_submitted: "Submitted",
        student_not_submitted: "Not submitted",
        warning_select_student:
            "The student that needs statistics has not been selected or the student you selected does not have data.",
        student: "Student",
        empty_data: "Empty data",
        number_of_students: "Number of students",
        total_time_spent_in_seconds: "Time spent (in seconds)",
        average_time_spent_in_seconds: "Average time spent (in seconds)",
        grade_of_question: "Question's grade",
        assignment: "Assignment",
        exam: "Exam",
        export_pdf: "Export PDF",
        message_no_one_has_done_the_homework: "No one has done the home work, cannot be counted",
        message_student_have_not_selected:
            "Students have not been selected for statistics or students do not have data",
        message_general_data_not_found: "Data not found or you've not select a class/course yet",
        level: "Level",
        course: "Course",
        please_select_course: "Please select course",
        type_of_exercise: "Type of exercise",
        please_select_type_of_exercise: "Please select type of exercise",
        unit: "Unit",
        // Actions:
        view_detail: "View detail",
        // Fields:
        asgmt_datefrom: "Assignment start date",
        asgmt_dateto: "Assignment end date",
        // Titles:
        learning_progress_report: "Learning progress report",
        learning_progress_report_general: "General learning progress report",
        learning_progress_report_personal: "Personal learning progress report",
        personal_learning_progress: "Personal learning progress",
        general_learning_progress: "General learning progress",
        type_of_excercise: "Type",
        select_course_or_class: "Select class or course type",
        need_select_class_or_course: "You need to select a class or a course to see statistics!",
        need_select_student: "You need to select a student to view statistics!",
        need_select_type_before: "Need to select course type or class first",
    },

    assignment: {
        donot: "Do not",
        submited: "Worked",
        marked: "Marked",
        expired: "Expired",
        course: "Course",
        subject: "Subject",
        start_from: "Start from",
        deadline: "Deadline",
        status: "Status",
        teachers: "Teachers",
        listAssignment: "List Assignment",
        assignments: "Assignments",
        do_homework: "Do Homework",
        review: "Review",
        doing: "Doing",
        score: "Score",
        invite_link: "Invite link",
        invite_via_link: "Invite via link",
        add_user_successfully: "You has been added to the assignment!",
        assignment_teacher_not_allowed: "Teacher is not allowed to attend to the assignment!",
        choose_subject: "Choose subject",
        choose_course: "Choose course",
        choose_status: "Choose status",
        filter_option: "Filter option",
        filter: "Filter",
        choose_owner: "Owner",
        owner: "Choose owner",
        assignment_not_existed: "Assignment is not existed!",
        correct: "Correct",
        correct_nearly: "Partially correct",
        incorrect: "Incorrect",
        mark_by_hand: "Mark by hand",
        skip: "Skip",
        time_spent: "Time spent",
        // Fields:
        name: "Name",
        begin_date: "Begin date",
        end_date: "End date",
        max_score: "Max score",
        duration: "Duration",
        exam_name: "Exam name",
        exam_name_description: "You can choose an another name for the assignment, or it will have a default name",
        type: "Type",
        type_listening: "Listening",
        type_speaking: "Speaking",
        type_reading: "Reading",
        type_writing: "Writing",
        type_vocabulary: "Vocabulary",
        type_grammar: "Grammar",
        type_review: "Review",
        // Messages:
        time_not_limited: "Indefinitely",
        assignment_list_empty: "There are no assignments",
        // Actions:
        add_teachers: "Add teachers",
        mark: "Mark",
        add_assignment: "Add assignment",
        find_assignment: "Find assignment",
        option_filter: "Filter",
        load_more_assignments: "View more assignments",
        select_type: "Select page",
        user: "User",
        select_user_role: "Select user role",
        student: "Student",
        teacher: "Teacher",
        employee: "Employee",

        //filter
        class: "Class",
        choose_class: "Chooose class",

        your_score: "Your score",
        load_more: "Load more",

        unknown: "Unknown",

        gradebook_type_middle: "Mid-term",
        gradebook_type_final: "End of term",
        select_gradebook_type: "Choose an exam",
        gradebook_type: "Exam",

        limit_submission: "Maximum number of tests",
        count_submission: "Number of tests",

        public: "Public",
        private: "Private",
        copied_code: "Code copied",
    },

    classes: {
        your_class_list: "Your class list",
        view_detail: "View detail",
        owner: "Owner",
    },

    test: {
        submit_record_success: "Submit record success",
        submit_record_error: "Submit record error",
        submit: "Submit",
        confirm_submit: "Do you sure submit ?",
        time_remaining: "Time remaining",
        cancel: "Cancel",
        exit: "Exit",
        confirm_exit: "Do you sure exit ?",
        timeout_test: "The test time has expired, you cannot change the answer.",
    },

    tag: {
        tag: "Tag",
        add_tag: "Add tag",
        create_tag: "Create a new tag",
        add_tag_success: "Add tag successfully",
        add_tag_error: "Fail to add tag",
        remove_tag_success: "Remove tag successfully",
        remove_tag_error: "Remove tag error",
    },

    editor: {
        attach_files: "Attach files",
        create_blank: "Create a blank",
        blank: "Blank",
        highlight: "Highlight",
        select_audio_visible_type: "Select audio visible type",
        audio_type_icon: "Audio icon",
        audio_type_player: "Audio player",
        insert_or_edit_image: "Insert/edit image",
        insert_or_edit_media: "Insert/edit media",
        insert_or_edit_image_1: "Attach image",
        insert_or_edit_media_1: "Attach media",
        // Plugins - paste-options:
        paste_options: "Paste options",
        paste_option_keep_text_only: "Keep text only",
        paste_option_keep_source_formatting: "Keep source formatting",
        // Plugins - math:
        standard_keypads: "Standard keypads",
        numbers_only: "Numbers only",
        numbers_n_basic_symbols: "Numbers and basic symbols",
        basic: "Basic",
        basic_wo_numbers: "Basic without numbers",
        intermediate: "Intermediate",
        intermediate_wo_numbers: "Intermediate without numbers",
        matrices: "Matrices",
        matrices_basic: "Matrices (basic)",
        trignometry: "Trignometry",
        geometry: "Geometry",
        chemistry: "Chemistry",
        units_SI: "Units (SI)",
        units_US: "Units (US)",
        full: "Full",
        // Plugins - wordcount:
        wordcountmax_reached: "You have exceeded the maximum word limit allowed",
    },

    file_manager: {
        // Fields:
        folder: "Folder",
        // Actions:
        rename: "Rename",
        add_folder: "Add folder",
        add_files: "Add files",
        move_to: "Move to",
        delete: "Delete",
        // Messages:
        require_folder: "You have to select a folder!",
        rename_file_success: "The file has been successfully renamed",
        rename_folder_success: "The folder has been successfully renamed",
    },

    my_storage: {
        my_storage: "My storage",
        new_folder: "New folder",
        view_file: "View file",
        view_file_error: "Can not view the file!",
        space_used: "Space used",
        space_left: "Space left",
        space_usage: "Space usage",
        total: "Total",
        used: "Used",
        left: "Available",
        filemanager_loading: "Loading",
        filemanager_uploading: "Uploading",
        filemanager_removing: "Removing",
    },

    organization: {
        personal: "Personal",
        organization: "Organization",
        tablecol_user: "User",

        name: "Name",
        organization_details: "Details",
        options: "Options",
        plan_type: "Plan type",
        expiration_date: "Expiration date",
        // Actions:
        find_user: "Find user",
        add_user: "Add user",
        // Invite teacher:
        user_email: "User's email",
        send_invitation: "Send invitation",
        send_invitation_success: "Invitation sent successfully",
        send_invitation_error: "Failed to send invitation",
        add_teacher_not_others: "Only teacher can join the class",
        add_teacher_success: "Add teacher successfully",
        add_teacher_error: "Failed to add teacher",
    },
    guide: {
        help: "Help",
        title: "Title",
        choose: "Choose",
        add_feature: "Add feature",
        add_feature_desc: "Add description for feature",
        type: "Page",
        order: "Order",
        description: "Description",
        upload: "Upload",
        image: "Image",
        create_guide: "Create guide",
        delete: "Delete",
        upload_success: "Upload image success",
        update_guide: "Update guide",
        add_new: "New",
        find: "Search",
        sort: "Sort",
        asc: "Ascending",
        desc: "Descending",
        select_type: "Select page",
        user: "User",
        select_user_role: "Select user role",
        student: "Student",
        teacher: "Teacher",
        employee: "Employee",

        assignment: "Assignment",
        class: "Class",
        question_bank: "Question",
        statistic: "Statistic",
        storage: "Storage",
        account: "Account",
        exam_bank: "Exam bank",
        my_storage: "My storage",
        organization: "Organization",

        course: "Courses",
        my_question_resource: "My questions resource",
        my_exam_resource: "My exam resource",
        questions_bank: "Questions bank",
        multiplechoice_question: "Multiplechoice question",
        fill_blank_question: "Fill blank",
        writing_question: "Question writing",
        passage_question: "Question passage",
        sort_question: "Question sort",
        drag_drop_question: "Question drag drop",
        learning_progress: "Learning progress",
        guide: "Guide",
        profile: "Profile",
        score: "Scores",
        gradebook: "Gradebook",

        category: "Category",
        collapse_sidebar: "Collapse sidebar",
    },

    score: {
        unknown: "Unknown",
        correct_answers: "Correct answers",
        your_answers: "Your answers",
    },

    hotspot: {
        set_correct_answer: "Set correct answer",
        drawing_areas: "Drawing shape",
        node_must_be_closed: "Shape must be closed",
        missing_correct_answer: "Missing correct answer",
    },

    zwibbler_toolbar: {
        helper: "help",
        pick_tool_helper: "Select the relevant button to use a function.",
        brush_tool_helper: "Tap and drag to draw lines and shapes.",
        line_tool_helper: "Tap to start and add a point, double tap to end.",
        shape_tool_helper: "Tap to start and drag to draw a shape.",
    },

    shade: {
        row_count: "Row count",
        column_count: "Column count",
        cell_width: "Cell width",
        cell_height: "Cell height",
        lock_shade_cells: "Lock cells",
        by_location: "By location",
        by_count: "By count",
        unlockcell_note: "Note: You only need to select the number of squares corresponding to the answer",
    },

    speech_to_text: {
        sentence: "Sentence",
        word: "Word",
        please_allow_microphone: "Please allow access to the microphone",
        no_browser_support: "Your browser does not support this function, please use another browser (Chrome/Edge)",
        question: "Question:",
        solution: "Your solution:",
        speaker: "Voice",
        select_speaker: "Choose voice",
        tap_to_open_mic: "Tap to turn on the microphone",
        tap_to_close_mic: "Tap to turn off the microphone",
    },
    speech_to_text_conversation: {
        speaker: "Voice (human)",
        voice: "Voice (computer)",
        actor: "Actor",
        start: "Start",
        havent_choose_actor: "You haven't chosen a role yet",
        select_actor: "Select actor",
        select_voice: "Select voice",
        enter_sentence: "Enter the sentence",
        choose_actor: "Click the tick mark to select an actor",
        choose_actor1: "Click the ",
        choose_actor2: " to select an actor",
        click_micro_to_answer: "Click on the microphone to enter your answer",
        havent_entered_answer: "You haven't entered your answer yet",
        click_to_speech: "Click to speak",
        click_to_stop_speech: "Click to stop speaking",
        click_to_speech_again: "Click to speak again",
        note_when_doing_homework1: "Press the record button ",
        note_when_doing_homework2: " to speak, and to stop press ",
        note_when_doing_homework3: " , if it is wrong, press  ",
        note_when_doing_homework4: "to speak again (only for repeating up to 3 times)",
        notAllow_change_actor: "You can't change the actor once you have the answer",
        voice_not_support: "This language is not supported please choose another language",
    },
    feedback: {
        title: "Product Customer Feedback Form",
        subtitle:
            "Please enter your comments in the input below. Thank you for taking the time to feedback on our product.",
        thanks:
            "Thank you for taking time to provide feedback. We will consider this feedback to improve the product and get back to you as soon as possible.",
        send_feedback: "Send feedback",
        solved: "Solved",
        unresolved: "Unresolved",
        handler: "Handler",
        select_handler: "Choose handler",
        feedback: "Feedback",
    },

    history: {
        history: "History",
        editing_history: "Editing history",
        choose_action_type: "Choose action type",
        assignment: "Assignment",
        exam_question: "Exam question",
        question: "Question",
        course: "Course",
        model: "Page",
        choose_model: "Choose page",
        old_value: "Old value",
        new_value: "New value",
        old_question: "Old question",
        new_question: "New question",
        old_answer: "Old answer",
        new_answer: "New answer",
        coordinates: "Coordinates of the answer",
        update_questions_in_passage: "Update questions in passage",
        action_statistics: "Action Statistics",
    },

    exam_checkin: {
        access_exam: "Access your exam",
        exam_access_code: "Exam access code",
        descr_exam_access_code: "Access code issued by your instructor or proctor",
        system_check: "System check",
        descr_system_check: "Please wait while we check if your device meets our system requirements",
        internet_connection_check: "Internet connection",
        webcam_check: "Webcam",
        microphone_check: "Microphone",
        displays_check: "Displays",
        internet_connection_speed: {
            slow: "Slow",
            stable: "OK",
            descr_slow:
                "A slow internet connection will significantly impact your work. Please check your internet settings.",
        },
        webcam_info: {
            fail: "Fail",
            success: "OK",
            descr_connecting: "Connecting to webcam...",
            descr_fail: "Webcam connection failed.",
            descr_permission_dismissed:
                "Webcam connection failed due to permission dismissal. Please allow access to your camera.",
            descr_permission_denied:
                "Webcam connection failed due to permission denial. Please allow access to your camera.",
        },
        microphone_info: {
            fail: "Fail",
            success: "OK",
            descr_connecting: "Connecting to microphone...",
            descr_fail: "Microphone connection failed.",
            descr_permission_dismissed:
                "Microphone connection failed due to permission dismissal. Please allow access to your microphone.",
            descr_permission_denied:
                "Microphone connection failed due to permission denial. Please allow access to your microphone.",
            descr_say_sth: "Please say something to check microphone's volume",
        },
        displays_info: {
            what: "Displays check",
            why:
                "We are verifying if your displays are in Extended mode. So, we need to grant permission to manage windows on all your displays.",
            fail: "Fail",
            success: "OK",
            descr_connecting: "Checking displays...",
            descr_fail: "Displays checking failed.",
            descr_invalid_screen_count: "Please do not use multiple displays in Extended mode",
            descr_permission_dismissed:
                "Displays checking failed due to permission dismissal. Please allow windows management on all your displays.",
            descr_permission_denied:
                "Displays checking failed due to permission denial. Please allow windows management on all your displays.",
        },
        check_result: "Result",
        candidate_information: "Candidate information",
        identity_verification: "Identity verification",
        taking_exam: "Taking your exam",
        candidate_number: "Candidate number",
        human_verification: "I'm not a robot",
        start_taking_exam: "Start taking your exam",
        input_internet_connection_check: "Please check your internet connection",
        input_microphone_check: "Please check your microphone",
        input_webcam_check: "Please check your webcam",
        input_exam_access_code: "Please enter your exam access code",
        input_candidate_number: "Please enter your number",
        input_human_verification: "Please finish your human verification",
        internet_connection_check_failed: "Checking failed",
        avatar_upload: "Your portrait photo",
        only_upload_img: "Only image file uploads are allowed!",
    },

    exam_asset: {
        input_upload_files: "Please select files to be uploaded",
        exam_complete: "You have finished the examination.",
        descr_exam_complete:
            "Please upload the exam proctoring asset to ensure your full compliance with the examination regulations.",
        exam_asset: "Exam proctoring asset",
        descr_exam_asset:
            "You are required to upload the essential files, such as screen recordings captured during the examination.",
        exam_asset_done: "Everything is done! No further action is required",
        descr_exam_asset_maybelost: "No files found.",
    },

    exam_proctoring: {
        screen_size_check_invalid: "Invalid screen resolution",
        descr_screen_size_check_invalid:
            "The app requires a higher screen resolution to function correctly. Please adjust your screen resolution settings and try running the app in maximized or fullscreen mode.",
        displays_check_invalid: "Invalid mode of displays",
        descr_displays_check_invalid: "Please do not use multiple displays in Extended mode",
        webcam_info: {
            permission_dismissed: "Webcam connection failed",
            permission_denied: "Webcam connection failed",
            descr_permission_dismissed:
                "Webcam connection failed due to permission dismissal. Please allow access to your webcam.",
            descr_permission_denied:
                "Webcam connection failed due to permission denial. Please allow access to your webcam.",
        },
        microphone_info: {
            permission_dismissed: "Microphone connection failed",
            permission_denied: "Microphone connection failed",
            descr_permission_dismissed:
                "Microphone connection failed due to permission dismissal. Please allow access to your microphone.",
            descr_permission_denied:
                "Microphone connection failed due to permission denial. Please allow access to your microphone.",
        },
        displays_info: {
            permission_dismissed: "Failed to check displays",
            permission_denied: "Failed to check displays",
            descr_permission_dismissed:
                "Displays checking failed due to permission dismissal. Please allow windows management on all your displays.",
            descr_permission_denied:
                "Displays checking failed due to permission denial. Please allow windows management on all your displays.",
        },
        this_platform_is_only_available_on_pc: "This platform is only available on PC.",
        device_not_available: "Device not available.",
        start_recording: "Start recording",
        stop_recording: "Stop recording",
        recording_info: {
            started: "Recording has been started...",
            stopped: "Recording has been stopped!",
            displaySurface_must_be_monitor: "You have to share your entire screen!",
            systemAudio_mustbe_turnedon: "You have to share your system audio!",
            descr_permission_dismissed:
                "Recording failed due to permission dismissal. Please allow access to your microphone and screen sharing.",
            descr_permission_denied:
                "Webcam connection failed due to permission denial. Please allow access to your microphone and screen sharing.",
        },
        ask_recording: "The system needs permissions to proctor the exam with screen recording.",
        descr_ask_recording: "Please press the 'confirm' button and grant required permissions.",
        rules_reminder: "Exam rules reminder",
        descr_rules_reminder: "You have to obey all the exam rules listed below",
        agree_rules: "I agree that breaking these rules above will lead to the cancellation of my examination.",
        notes: {
            record_screen_share: "Record video",
            record_camera: "Record camera",
            record_microphone: "Record audio from the microphone",
            record_system_audio: "Record system audio",
            extend_monitor: "Do not use multiple displays in Extended mode",
            disable_coppy_paste: "Do not copy/cut and paste text",
            alert_user_leave_page: "Do not leave the page",
            screen_shrinking_disable: "Do not minimize the screen",
            open_tab: "Do not open new tabs",
            exit_full_screen: "Do not exit full-screen mode",
            suffix_open_tab: "times",
            suffix_exit_full_screen: "times",
        },
        starttime_notreached: "The exam hasn't started yet. Please wait...",
        starttime_reached: "The exam has already started.",
    },

    anti_cheating: {
        proctoring: "Giám sát bài thi",
        webcam_picture: "Take a picture with a webcam",
        upload_picture: "Upload a portrait photo",
        upload_identity_card: "Upload ID photo",
        record_screen_share: "Share screen recording",
        disable_coppy_paste: "Block copy-paste",
        alert_user_leave_page: "Alerts when students leave the tab they are doing",
        limit_exit_tab: "Number of times allowed to exit the test screen",
        screen_shrinking_disable: "Screen shrinking is not allowed",
        count_exit_full_screen: "The number of times allowed to exit full-screen mode",
        record_audio: "Record audio on student's computer",
        portrait_image: "Portrait image",
        id_verification: "ID verification",
        secured_recording: "Secured recording",
        lockdown_browser: "Lock-down browser",
        exit_fullscreen_warning: "Do not exit full screen mode while doing the test!",
        copy_paste_not_allowed: "Copy/paste is not allowed!",
        extend_monitor: "Detect multiple displays in Extended mode",
        multiple_displays_extended: "Detect multiple displays in Extended mode",

        allowed_late_start: "Late start of homework is allowed",
        late_start_minutes: "Time allowed to take the exam late (minutes)",
        violated_exam_by_opening_devtool: "You violated exam regulations by opening devtool",
        violated_exam_contact_teacher:
            "You cannot continue taking the exam because you have violated the exam regulations. Please contact your teacher for support",
        record_system_audio: "Record system audio",
        record_microphone: "Record microphone",
        record_camera: "Record camera",
        open_devtool: "Block opening Developer Tool",
        detect_many_people_in_camera: "Detect multiple faces in the camera",
        not_appear_on_camera: "Your face is not detected in the camera",
        times_allowed_many_people_in_camera: "The number of times multiple faces are allowed to appear in the camera",
        times_allowed_not_appear_on_camera:
            "The number of times you are allowed to leave your position while doing the test",
        alert_not_appear_on_camera: "Your face is not detected, please do not leave your position while doing the test",
        id_verify_success_message: "Verification completed!. You will be redirected to the assignment page.",
        upload_another_ID: "Upload another ID photo",
        id_photo_regulations: "ID photo regulations",
        video_record: "Surveillance history",
        violating_images: "Violating images",
        list_of_violations: "List of violations",
        no_student_violation_data: "There is no student violation data",
    },

    upload: {
        only_image: "Only image files are allowed to be uploaded",
        file_size_limit: "File size must not exceed ",
    },

    dashboard: {
        total_course: "Total number of courses",
        total_class: "Total number of classes",
        total_student_in_course: "Total student in courses",
        total_student_in_class: "Total number of students in classes",
        total_created_question: "Total number of questions created",
        total_created_exam: "Total number of created assignments",
        total_created_assignment: "Total assigned tasks",
        total_public_assignment: "Total number of public tests",
        total_private_assignment: "Total number of private tests",
        total_student_in_public_assignment: "Total number of candidates participating in the public test",
        total_student_in_private_assignment: "Total number of candidates participating in the private test",
    },

    setting: {
        // Tabs:
        payment_history: "Payment history",
        my_plan: "My plan",
        plan_info: "Plan info",
        // Fields:
        code: "Code",
        subscription: "Subscription plan",
        amount: "Amount",
        effective_date: "Effective date",
        expiration_date: "Expiration date",
        payment_method: "Payment",
        payment_status: "Payment status",
        plan_name: "Plan name",
        options: "Options",
        // Actions:
        select_plan: "Select a plan",
        buy_more_options: "Buy more options",
        extend_subscription: "Extend subscription",
    },
};
