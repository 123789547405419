import { CloudUploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "src/api";
import { checkReUploadStatus, reUploadExamProctoringAssets } from "src/api/containers/record";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { useValues } from "src/hooks";
import { useAuth } from "src/modules/auth";
import { default as CustomButton } from "src/modules/components/Button";
import FeedbackDrawer from "src/modules/components/Feedback/FeedbackDrawer";
import { pages } from "src/routes/pages";

import "./ExamAssetReupload.scss";
import SelectFile from "./components/SelectFile";

function ExamAssetReupload() {
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const reuploadToken = urlSearchParams.get("token");

    const navigate = useNavigate();

    const auth = useAuth();
    const lang = useSelector((state) => state.general.lang);

    const [form] = useForm();
    const form_file_list = Form.useWatch("files", form);

    const htmlElementProgressRef = useRef(null);
    const htmlElementProgressFulfilledRef = useRef(null);
    const htmlElementProgressPercentRef = useRef(null);

    const [values, setValues] = useValues({
        uploading: false, // Values: true, false.
        uploaded: false, // Values: true, false, "error".
        isModalVisible: false, // Values: "feedback", false.
        redirecting: false,
    });

    const isEnabledUpload_VOSR = reuploadToken && form_file_list?.length && values.uploaded !== true;

    const handleExit = () => {
        // auth.logout();
        navigate(`${lang}${pages.entrance.routePath}`);
    };

    const handleSubmit = () => {
        if (!isEnabledUpload_VOSR) {
            return;
        }

        /**
         * Create FormData instance, and set the Content-Type header to multipart/form-data.
         * To get the formData values, use:
         * console.log(formData.getAll("files"));
         */
        const formData = new FormData();
        for (let i = 0; i < form_file_list.length; i++) {
            const _file = form_file_list[i];
            formData.append(`file${i}`, _file, _file.name);
        }
        formData.append("token", reuploadToken);

        /**
         * Config.
         */
        if (htmlElementProgressFulfilledRef.current) {
            htmlElementProgressFulfilledRef.current.style.setProperty("--width", "0");
        }
        const config = {
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (htmlElementProgressRef.current && htmlElementProgressFulfilledRef.current) {
                    const progressWidth = htmlElementProgressRef.current.offsetWidth;
                    const progressFulfilledWidth = (progressWidth * percent) / 100;
                    htmlElementProgressFulfilledRef.current.style.setProperty("--width", `${progressFulfilledWidth}px`);
                    htmlElementProgressPercentRef.current.innerHTML = `${percent}%`;
                }
            },
        };

        setValues({ uploading: true, uploaded: false });
        reUploadExamProctoringAssets(formData, config)
            .then((res) => {
                if (res.status) {
                    setValues({ uploading: false, uploaded: true, redirecting: true });
                    notification.success({
                        message: res.message || t("message.upload_success"),
                    });

                    setTimeout(() => {
                        handleExit();
                    }, 3000);
                } else {
                    setValues({ uploading: false, uploaded: "error" });
                    notification.error({
                        message: res.message || t("message.sth_wrong"),
                    });
                }
            })
            .catch((err) => {
                setValues({ uploading: false, uploaded: "error" });
                notification.error({
                    message: err ? err.toString() : t("message.sth_wrong"),
                });
            });
    };

    const handleToggleFeedback = (value = undefined) => {
        if (value !== undefined) {
            setValues({ isModalVisible: values.isModalVisible ? false : "feedback" });
        } else {
            setValues({ isModalVisible: value });
        }
    };

    useEffect(() => {
        api.defaults.headers.common["Authorization"] = undefined;

        auth.logout();

        checkReUploadStatus(reuploadToken)
            .then((res) => {
                if (res.status) {
                } else {
                    notification.info({
                        message: res.message || t("message.sth_wrong"),
                    });

                    setValues({ redirecting: true });
                    setTimeout(() => {
                        handleExit();
                    }, 3000);
                }
            })
            .catch((err) => {
                const errMsg = err.toString();
                notification.error({
                    message: errMsg || t("message.sth_wrong"),
                });

                setValues({ redirecting: true });
                setTimeout(() => {
                    handleExit();
                }, 3000);
            });
    }, []);

    return (
        <div className="exam-asset-wrapper">
            <div className="exam-asset exam-asset-reup">
                <div className="exam-asset-title">
                    <div className="title-icon">
                        <CloudUploadOutlined />
                    </div>
                    <div className="title-content">
                        <div className="content-main">{t("exam_asset.exam_asset")}</div>
                        <div className="content-descr">{t("exam_asset.descr_exam_asset")}</div>
                    </div>
                </div>
                <div className="exam-asset-content">
                    <div className="exam-asset-form">
                        <Form
                            form={form}
                            layout="vertical"
                            className="app-form type-primary has-rd"
                            onFinish={handleSubmit}
                        >
                            <div className="exam-asset-content">
                                <Row gutter={[32, 64]}>
                                    <Col span={24}>
                                        <div className="exam-asset-upload">
                                            <Form.Item
                                                // label={`${t("exam_asset.upload_files")}`}
                                                name="files"
                                                rules={[
                                                    { required: true, message: t("exam_asset.input_upload_files") },
                                                ]}
                                            >
                                                <SelectFile
                                                    accept="video/*"
                                                    fileList={form_file_list}
                                                    className="upload-exam-assets"
                                                    listMultiple={false}
                                                    selectMultiple={false}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="exam-asset-upload-progress">
                                            <div ref={htmlElementProgressRef} className="progress-bar">
                                                <div
                                                    ref={htmlElementProgressFulfilledRef}
                                                    className={`progress-fulfilled ${
                                                        values.uploaded === false
                                                            ? "pending"
                                                            : values.uploaded === true
                                                            ? "success"
                                                            : "danger"
                                                    }`}
                                                ></div>
                                            </div>
                                            {(values.uploaded === true || values.uploading) && (
                                                <div ref={htmlElementProgressPercentRef} className="progress-percent">
                                                    0%
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="exam-asset-redirecting">
                                {values.redirecting ? (
                                    <>
                                        <Spin spinning={true} />
                                        {`${t("shared.redirecting")}...`}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="form-actions">
                                <CustomButton
                                    htmlType="submit"
                                    type="primary"
                                    icon={<SvgTick />}
                                    title={
                                        values.uploaded === true
                                            ? t("shared.uploaded")
                                            : values.uploading
                                            ? `${t("shared.uploading")}...`
                                            : t("shared.upload")
                                    }
                                    disabled={!isEnabledUpload_VOSR || values.uploading}
                                    isLoading={values.uploading}
                                    onClick={handleSubmit}
                                ></CustomButton>
                                <CustomButton
                                    type="ghost"
                                    title={t("feedback.feedback")}
                                    onClick={handleToggleFeedback}
                                ></CustomButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            <FeedbackDrawer
                visible={values.isModalVisible === "feedback"}
                onOk={handleToggleFeedback}
                onCancel={handleToggleFeedback}
            ></FeedbackDrawer>
        </div>
    );
}

export default ExamAssetReupload;
