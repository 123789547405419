import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as ViewDetail } from "src/assets/images/app-icons/app-view-detail.svg";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
import { setCurrentPage } from "src/reducers/general";
import { useRouting } from "src/utils/router";
import { ReactComponent as ChartBar } from "./assets/chart-bar.svg";
import { ReactComponent as ChartLineGraph } from "./assets/chart-line-graph.svg";
import Card from "./components/Card";
import "./LearningProgressReport.scss";

const LearningProgressReport = () => {
    const { generate } = useRouting();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("learning_progress"));
    }, []);

    return (
        <div className="learningProgressReport_wrapper">
            <div className="learningProgressReport_header">
                <Breadcrumb
                    routes={[
                        {
                            name: t("report.learning_progress_report"),
                        },
                    ]}
                />
            </div>
            <div className="learningProgressReport_container">
                <div className="card-list">
                    <Card title={t("report.learning_progress_report_general")} image={<ChartLineGraph />}>
                        <CustomButton
                            linkTo={generate("general-report")}
                            className="card-action-item"
                            title={t("report.view_detail")}
                            icon={<ViewDetail />}
                            type="primary"
                        ></CustomButton>
                    </Card>
                    <Card title={t("report.learning_progress_report_personal")} image={<ChartBar />}>
                        <CustomButton
                            linkTo={generate("personal-report")}
                            className="card-action-item"
                            title={t("report.view_detail")}
                            icon={<ViewDetail />}
                            type="primary"
                        ></CustomButton>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default LearningProgressReport;
