import { CheckCircleOutlined, SafetyOutlined, ScanOutlined, UserOutlined } from "@ant-design/icons";
import { Spin, Steps, Typography } from "antd";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useValues } from "src/hooks";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";

import FaceVerification from "../FaceVerification";
import UploadIdCard from "../UploadIdCard";
import "./IdentityVerification.scss";

const activeStyle = {
    fontWeight: 700,
    fontSize: 20,
    color: "#445cfe",
};

function IdentityVerification({ visible = true, formData, className, onOk, onCancel, ...rest }) {
    const { t } = useTranslation();
    const imgWebcamRef = useRef(null);

    const { examCheckInInfo } = useContext(ProctoringContext);
    const examRules = examCheckInInfo?.assignment?.options;

    const [values, setValues] = useValues({
        loading: false,
        step: 0,
    });

    return (
        <div className="identity-verification">
            <div className="identity-verification_content">
                {examRules?.upload_identity_card ? (
                    <Steps current={values.step} direction="vertical" style={{ maxWidth: 1000 }}>
                        <Steps.Step
                            icon={<UserOutlined />}
                            title={
                                <div style={{ fontWeight: values.step == 0 ? 700 : 500 }}>{t("face_verification")}</div>
                            }
                            description={
                                <FaceVerification
                                    currentStep={values.step}
                                    setStep={(step) => setValues({ step: step })}
                                    imgWebcamRef={imgWebcamRef}
                                />
                            }
                        />

                        <Steps.Step
                            icon={<ScanOutlined />}
                            title={
                                <div style={values.step == 1 ? activeStyle : null}>{t("id_photo_verification")}</div>
                            }
                            description={
                                <UploadIdCard
                                    currentStep={values.step}
                                    setStep={(step) => setValues({ step: step })}
                                    onDone={onOk}
                                    imgWebcamRef={imgWebcamRef}
                                />
                            }
                        />

                        <Steps.Step
                            icon={<SafetyOutlined />}
                            title={<div style={values.step == 2 ? activeStyle : null}>{t("course.complete")}</div>}
                            description={
                                values.step == 2 ? (
                                    <Typography.Text
                                        strong
                                        type="success"
                                        style={{
                                            fontSize: 20,
                                            textAlign: "center",
                                            width: "100%",
                                            display: "block",
                                            marginTop: 20,
                                        }}
                                    >
                                        <CheckCircleOutlined /> {"  "}
                                        {t("anti_cheating.id_verify_success_message")}
                                    </Typography.Text>
                                ) : null
                            }
                        />
                    </Steps>
                ) : (
                    <FaceVerification onDone={onOk} imgWebcamRef={imgWebcamRef} />
                )}
            </div>
        </div>
    );
}

export default IdentityVerification;
