import { notification, Spin } from "antd";
import { t } from "i18next";
import { isArray } from "jquery";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { findRecord, markStudentAssignment } from "src/api/containers/record";
import Button from "src/modules/components/Button";
import QuestionNumberList from "src/modules/components/NumberList/QuestionNumberList";
import ExamResult from "src/modules/components/ProgressBar/ExamResult";
import QuestionResultPanel from "src/modules/components/QuestionResultPanel";
import StudentFrame from "src/modules/components/StudentFrame";
import StudentHeader from "src/modules/components/StudentHeader";
import "./ViewScoreStudent.scss";
import { useSelector } from "react-redux";
import { useRouting } from "src/utils/router";
import { getAllSearchParams, objectToSearchParamsString } from "src/utils/helpers";
import Empty from "src/modules/components/Empty";

const ViewScoreStudent = (props) => {
    const {
        markingRecord = null,
        role = "student",
        typeViewScore, // "preview" || "review"
        isMarking = false,
        isModalBox = false,
        closeModal = () => {},
    } = props;

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [record, setRecord] = useState({
        data: null,
        order: -1,
    });
    const [markedQuestions, setMarkedQuestions] = useState({});
    const [loading, setLoading] = useState(true);

    const { user } = useSelector((state) => state.auth);

    const { generate } = useRouting();
    const navigate = useNavigate();

    const markedScore = useMemo(() => {
        const pureScore = record?.data?.score;

        const markedTotalScore = Object.entries(markedQuestions).reduce((prevValue, [_, curValue]) => {
            // Case passage
            if (curValue.type === "passage") {
                const totalScoreInPassage = Object.keys(curValue).reduce((prevChildValue, curChildKey) => {
                    const curChildValue = curValue[curChildKey];
                    if (curChildKey === "note" || curChildKey === "type") return prevChildValue;
                    return prevChildValue + curChildValue.score - curChildValue.oldScore;
                }, 0);

                return (prevValue += totalScoreInPassage);
            } else {
                if (typeof curValue.score === "number") {
                    return (prevValue += curValue.score - (curValue.oldScore || 0));
                }

                return prevValue;
            }
        }, 0);

        return pureScore + markedTotalScore;
    }, [markedQuestions, record?.data?.score]);

    const handleSaveMarkingRecord = () => {
        const payloadQuestion = {};

        record.data.history.forEach((q) => {
            if (q.type === "passage") {
                const newQuestionMarked = {};

                q.paragraphs.forEach((p) => {
                    p?.question_details?.forEach((qChild) => {
                        newQuestionMarked[qChild.id] = {
                            score: (qChild.score_teacher_submit ?? qChild.score_submit) || 0,
                        };
                    });
                });

                payloadQuestion[q.id] = newQuestionMarked;
            } else {
                payloadQuestion[q.id] = { score: q.score_teacher_submit ?? q.score_submit };
            }
        });

        Object.entries(markedQuestions).forEach(([questionId, element]) => {
            // Case passage
            if (element.type === "passage") {
                const childScoreOfQuestions = {};

                Object.entries(element).forEach(([childId, value]) => {
                    if (childId === "note") return (childScoreOfQuestions[childId] = value);

                    if (childId === "type") return;

                    childScoreOfQuestions[childId] = {
                        score: value.score,
                    };
                });

                payloadQuestion[questionId] = {
                    ...payloadQuestion[questionId],
                    ...childScoreOfQuestions,
                };
            } else {
                payloadQuestion[questionId] = {
                    ...(payloadQuestion[questionId] || {}),
                    score: element.score,
                    ...(element.note && { note: element.note }),
                };
            }
        });

        setLoading(true);
        markStudentAssignment(markingRecord?.id, { questions: payloadQuestion }).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.mark_success") });
                closeModal();
            } else {
                notification.error({ message: message || t("message.mark_error") });
            }
            setLoading(false);
        });
    };

    const markingQuestion = (id, { score, oldScore, note, childId }) => {
        const newMarkedQuestions = { ...markedQuestions };

        if (childId) {
            const payload = {
                type: "passage",
                ...(newMarkedQuestions[id] || {}),
                [childId]: {
                    oldScore: 0,
                    score: 0,
                },
            };

            payload[childId].oldScore = oldScore || 0;
            payload[childId].score = score || 0;

            newMarkedQuestions[id] = payload;
        } else {
            newMarkedQuestions[id] = {
                ...(newMarkedQuestions[id] || {}),
            };

            newMarkedQuestions[id].oldScore = oldScore || 0;
            newMarkedQuestions[id].score = score || 0;

            note !== undefined && (newMarkedQuestions[id].note = note);
        }

        setMarkedQuestions(newMarkedQuestions);
    };

    useEffect(() => {
        setLoading(true);
        if (!markingRecord) {
            findRecord(id).then((res) => {
                if (res?.status) {
                    setRecord({
                        data: res?.data,
                        order: 1,
                    });
                    setLoading(false);
                }
            });
        } else {
            setRecord({
                order: 1,
                data: markingRecord,
            });
            setLoading(false);
        }
    }, [id]);

    const handleChooseQuestion = (question) => {
        setRecord({
            ...record,
            order: question,
        });
    };

    const handleStudentExit = () => {
        const course_id = record?.data?.course?._id;
        const unit_id = record?.data?.assignment?.unit_id;

        const previousPage = searchParams.get("prev");
        const params = getAllSearchParams(searchParams);
        delete params.prev;

        if (previousPage === "gradebook") {
            if (params.course) {
                navigate(generate("gradebook") + objectToSearchParamsString(params));
            } else {
                navigate(generate("gradebook") + objectToSearchParamsString(params));
            }
        } else if (previousPage === "scores") {
            navigate(generate("scores"));
        } else {
            //previousPage === "test"
            if (course_id && unit_id) {
                navigate(generate("unit", { idUnit: unit_id, idCourse: course_id }));
            } else navigate(generate("courses"));
        }
        // } else {
        //     if (course_id && unit_id) {
        //         navigate(generate("unit", { idUnit: unit_id, idCourse: course_id }));
        //     } else navigate(generate("courses"));
        // }
    };

    return (
        <Spin spinning={loading}>
            <div className={isModalBox ? "viewScoreStudent_wrapper model" : "viewScoreStudent_wrapper"}>
                <div className="viewScoreStudent_container">
                    {!isModalBox && (
                        <div className="viewScoreStudent_header">
                            <StudentHeader title="Danh sách bài được giao" isHaveBanner={false} isShowBreadCumb={false}>
                                <div className="homeStudent_controlPanel">
                                    {/* Mobile exam result */}
                                    <ExamResult
                                        isMobile
                                        width={200}
                                        totalScore={record?.data?.assignment?.exam?.max_score}
                                        score={markedScore}
                                        countQuestions={record?.data?.count_sentences}
                                        countCorrectAnswers={record?.data?.count_correct_sentences}
                                    />
                                </div>
                            </StudentHeader>
                        </div>
                    )}

                    <StudentFrame
                        isSelectBoxMobile
                        title={t("exam_result.your_assignment")}
                        questionAmount={record?.data?.history?.length}
                        handleChooseQuestion={handleChooseQuestion}
                        order={record.order}
                        assignmentName={record?.data?.assignment?.exam?.name}
                        isShowBreadcrumb
                    >
                        {record?.data?.count_sentences ? (
                            <QuestionResultPanel
                                role={role}
                                showResults={true}
                                record={record?.data}
                                order={record?.order}
                                isMarking={isMarking}
                                markedQuestions={markedQuestions}
                                markingQuestion={markingQuestion}
                                setRecord={setRecord}
                                typeViewScore="review"
                            />
                        ) : (
                            <Empty />
                        )}
                    </StudentFrame>

                    {role === "teacher" && (
                        <div className="viewScoreStudent_container_teacherSaveMark">
                            <Button type="primary" title={t("shared.save")} onClick={handleSaveMarkingRecord} />
                        </div>
                    )}
                </div>

                <div className="viewScore_controlPanel">
                    <div className="viewScore_controlPanel_inner">
                        <div className="viewScore_examResult">
                            <ExamResult
                                width={320}
                                totalScore={record?.data?.assignment?.exam?.max_score}
                                score={markedScore}
                                countQuestions={record?.data?.count_sentences}
                                countCorrectAnswers={record?.data?.count_correct_sentences}
                            />
                        </div>
                        <div className="viewScore_questionNumberList">
                            <QuestionNumberList
                                numberOfQuestions={record?.data?.history?.length}
                                order={record.order}
                                onChangeSelectedQuestion={handleChooseQuestion}
                            />
                        </div>
                        {isMarking && record?.data?.history?.length > 0 && (
                            <div className="viewScore_teacherSaveMark">
                                <Button type="primary" title={t("shared.save")} onClick={handleSaveMarkingRecord} />
                            </div>
                        )}
                        {user?.role === "student" && (
                            <div className="viewScore_teacherSaveMark">
                                <Button type="" title={t("shared.exit")} onClick={handleStudentExit} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ViewScoreStudent;
