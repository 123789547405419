import { Col, Form, Row, Select, DatePicker, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef } from "react";
import useValues from "src/hooks/useValues";
import CalanderIcon from "src/assets/images/calander-icon.svg";
import Button from "src/modules/components/Button";
import { fetchCourses } from "src/api/containers/course";
import moment from "moment";
import Icon from "src/modules/components/Icon";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { fetchLanguages } from "src/api/containers/language";
import "./FilterMenu.scss";
import { fetchLevels } from "src/api/containers/level";
import { fetchAssignments } from "src/api/containers/assignment";

const formItemsFilterInitParams = {
    language_id: "",
    is_class: 0,
    course_id: "",
    level_id: "",
    is_unit: "",
    assignment_id: "",
};

const filterMenu = (props) => {
    const {
        isModal = false,
        filterParams = [],
        handleFetch = () => {},
        handleCloseFilterMenu = () => {},
        isTeacher = false,
        class_id,
        language_id,
        level_id,
    } = props;
    const [form] = useForm();

    const timeoutIdGetCourses = useRef();
    const timeoutIdGetAssignments = useRef();

    const [values, setValues] = useValues({
        courses: [],
        coursePagination: {},
        assignments: [],
        assignmentsPagination: {},
        levels: [],
        languages: [],
        isFetchingCourses: false,
        isScrollingCourse: false,
        isFetchingAssignments: false,
        isScrollingAssignments: false,
        isFetchingLevels: false,
        isFetchingLanguages: false,
        isFetching: false,
        hide_assignment_type: false,
        formItemsValue: formItemsFilterInitParams,
    });

    // const [currFilterParams, setCurrFilterParams] = useState([]); // Current applied filter values.

    const courseFilter = useMemo(() => {
        return filterParams.find((filterParam) => filterParam.name === "course_id");
    }, []);

    const assignmentFilter = useMemo(() => {
        return filterParams.find((filterParam) => filterParam.name === "assignment_id");
    }, []);

    const handleCloseModal = () => {
        handleCloseFilterMenu(false);
    };

    const handleFetchDataForFilter = (data = {}, callback) => {
        if (callback instanceof Function) {
            callback();
        }
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            level_id: formData.level_id,
            course_id: formData.course_id,
            assignment_id: formData.assignment_id,
            is_unit: formData.is_unit,
            is_class: formData.is_class,
            language_id: formData.language_id,
        };

        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.dateFrom &&
            newFilterParams.push({
                name: "dateFrom",
                value: fetchParams.dateFrom,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.dateFrom).format("YYYY-MM-DD"),
            });
        fetchParams.dateTo &&
            newFilterParams.push({
                name: "dateTo",
                value: fetchParams.dateTo,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.dateTo).format("YYYY-MM-DD"),
            });
        fetchParams.level_id &&
            newFilterParams.push({
                name: "level_id",
                value: fetchParams.level_id,
                labelName: t("report.level"),
                labelValue:
                    values.levels.filter((level) => {
                        return level.id === fetchParams.level_id;
                    })[0]?.name || "Tất cả",
            });
        fetchParams.course_id &&
            newFilterParams.push({
                name: "course_id",
                value: fetchParams.course_id,
                labelName:
                    fetchParams.is_class == 0 ? t("report.course") : fetchParams.is_class == 1 ? t("nav.classes") : "",
                labelValue: values.courses.filter((item) => {
                    return item.id === fetchParams.course_id;
                })[0]?.name,
            });
        fetchParams.assignment_id &&
            newFilterParams.push({
                name: "assignment_id",
                value: fetchParams.assignment_id,
                labelName: t("report.assignment"),
                labelValue: values.assignments.filter((assignment) => {
                    return assignment.id === fetchParams.assignment_id;
                })[0]?.name,
            });
        fetchParams.language_id &&
            newFilterParams.push({
                name: "language_id",
                value: fetchParams.language_id,
                labelName: t("language.language"),
                labelValue: values.languages.filter((lang) => {
                    return lang.id === fetchParams.language_id;
                })[0]?.name,
            });
        [0, 1, "0", "1"].includes(fetchParams.is_class) &&
            newFilterParams.push({
                name: "is_class",
                value: fetchParams.is_class,
                labelName: t("shared.type"),
                labelValue:
                    fetchParams.is_class == 0 ? t("report.course") : fetchParams.is_class == 1 ? t("nav.classes") : "",
            });
        [true, false].includes(fetchParams.is_unit) &&
            newFilterParams.push({
                name: "is_unit",
                value: fetchParams.is_unit,
                labelName: t("report.type_of_excercise"),
                labelValue: fetchParams.is_unit ? t("report.unit") : t("report.assignment"),
            });

        // setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleCloseFilterMenu(newFilterParams);
    };

    function handleSelectCourseType(is_class) {
        if (is_class && values.hide_assignment_type === true) {
            setValues({ hide_assignment_type: false });
            form.setFieldsValue({ is_unit: false });
        } else if (!is_class && values.hide_assignment_type !== true) {
            setValues({ hide_assignment_type: true });
            form.setFieldsValue({ is_unit: true });
        }
    }
    useEffect(() => {
        const newFormData = {};
        let haveIsClass = false;
        let haveCourse = false;
        for (let i = 0; i < filterParams.length; i++) {
            newFormData[filterParams[i]?.name] = filterParams[i]?.value;
            if (filterParams[i]?.name === "is_class") {
                haveIsClass = true;
            }
            if (filterParams[i]?.name === "course_id") {
                haveCourse = true;
            }
        }

        if (!haveIsClass && haveCourse && newFormData.course_id) {
            delete newFormData.course_id;
        }

        if (class_id) {
            newFormData.course_id = class_id;
            newFormData.is_class = 1;
            newFormData.language_id = language_id;
            // newFormData.level_id = level_id;
        }

        // Update form:
        form.resetFields();
        form.setFieldsValue(newFormData);

        // Refetch data:
        const fetchParams = {
            dateFrom: newFormData.dateFrom,
            dateTo: newFormData.dateTo,
            level_id: newFormData.level_id,
            course_id: newFormData.course_id || class_id,
            assignment_id: newFormData.assignment_id,
            is_unit: newFormData.is_unit,
            language_id: newFormData.language_id || language_id,
            is_class: newFormData.is_class,
        };
        // Catch case call 2 api when in modal box
        if (!isModal) {
            //fetch tiến độ học tập
            if (!fetchParams.course_id) {
                notification.warning({ message: t("report.need_select_class_or_course") });
            } else {
                handleFetch({ page: 1, ...fetchParams });
            }
        }
    }, [filterParams]);

    useEffect(() => {
        fetchLanguages({ noPagination: 1 }).then(({ status, data }) => {
            if (status) {
                setValues({
                    languages: data,
                    isFetchingLanguages: false,
                });
            } else {
                setValues({
                    isFetchingLanguages: false,
                });
            }
        });

        fetchLevels({ noPagination: true }).then(({ status, data }) => {
            if (status) {
                setValues({
                    levels: data,
                    isFetchingLevels: false,
                });
            } else {
                setValues({
                    isFetchingLevels: false,
                });
            }
        });

        // handleFetchDataForFilter();
    }, []);

    const handleChangeLang = (language_id) => {
        handleFetchDataForFilter({ language_id });
        setValues({
            formItemsValue: {
                ...formItemsFilterInitParams,
                language_id: language_id,
            },
        });
        form.resetFields(["level_id", "is_class", "course_id", "is_unit", "assignment_id"]);
    };

    const handleFetchCourses = (params, callback, isScrolling) => {
        setValues({ isFetchingCourses: true, isScrollingCourse: isScrolling });
        fetchCourses(params)
            .then((res) => {
                if (res.status) {
                    setValues({
                        courses: isScrolling ? [...values.courses, ...res.data] : res.data,
                        coursePagination: res.pagination,
                    });
                    if (callback instanceof Function) {
                        callback();
                    }
                }
            })
            .finally(() => {
                setValues({ isFetchingCourses: false, isScrollingCourse: false });
            });
    };

    const handleFetchAssignments = (params, callback, isScrolling) => {
        setValues({ isFetchingAssignments: true, isScrollingAssignments: isScrolling });
        params.perPage = 20;
        fetchAssignments(params)
            .then((res) => {
                if (res.status) {
                    setValues({
                        assignments: isScrolling ? [...values.assignments, ...res.data] : res.data,
                        assignmentsPagination: res.pagination,
                    });
                    if (callback instanceof Function) {
                        callback();
                    }
                }
            })
            .finally(() => {
                setValues({ isFetchingAssignments: false, isScrollingAssignments: false });
            });
    };

    const handleScrollCourse = (e) => {
        if (values.isFetchingCourses) {
            return;
        }

        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = values.coursePagination?.current + 1;

            if (nextPageToLoad > values.coursePagination.lastPage) {
                return;
            }

            const formData = form.getFieldValue();

            handleFetchCourses(
                {
                    language_id: formData.language_id,
                    is_class: formData.is_class,
                    level_id: formData.level_id,
                    page: nextPageToLoad,
                },
                () => {},
                true
            );
        }
    };

    const handleScrollAssignments = (e) => {
        if (values.isFetchingAssignments) {
            return;
        }

        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = values.assignmentsPagination?.current + 1;

            if (nextPageToLoad > values.assignmentsPagination.lastPage) {
                return;
            }

            const formData = form.getFieldValue();

            handleFetchAssignments(
                {
                    course_id: formData.course_id,
                    is_unit: [true, false].includes(formData.is_unit) ? formData.is_unit : undefined,
                    level_id: formData.level_id,
                    page: nextPageToLoad,
                },
                () => {},
                true
            );
        }
    };

    const handleSearchCourse = (keyword) => {
        const formData = form.getFieldsValue();
        clearTimeout(timeoutIdGetCourses.current);
        if (typeof keyword === "string") {
            setValues({
                isFetchingCourses: "searching",
            });

            timeoutIdGetCourses.current = setTimeout(() => {
                handleFetchCourses(
                    {
                        language_id: formData.language_id,
                        is_class: formData.is_class,
                        level_id: formData.level_id,
                        slug: keyword,
                        page: 1,
                    },
                    () => {},
                    false
                );
            }, 500);
        }
    };

    const handleSearchAssignments = (keyword) => {
        const formData = form.getFieldsValue();
        clearTimeout(timeoutIdGetAssignments.current);
        if (typeof keyword === "string") {
            setValues({
                isFetchingAssignments: "searching",
            });

            timeoutIdGetAssignments.current = setTimeout(() => {
                handleFetchAssignments(
                    {
                        course_id: formData.course_id,
                        is_unit: [true, false].includes(formData.is_unit) ? formData.is_unit : undefined,
                        level_id: formData.level_id,
                        slug: keyword,
                        page: 1,
                    },
                    () => {},
                    false
                );
            }, 500);
        }
    };

    useEffect(() => {
        const formData = form.getFieldValue();

        handleFetchCourses({
            language_id: formData.language_id,
            is_class: formData.is_class,
            level_id: formData.level_id,
        });
    }, [values.formItemsValue.is_class, values.formItemsValue.language_id, values.formItemsValue.level_id]);

    useEffect(() => {
        if (values.formItemsValue?.course_id || [true, false].includes(values.formItemsValue?.is_unit)) {
            handleFetchAssignments({
                course_id: values.formItemsValue.course_id,
                is_unit: [true, false].includes(values.formItemsValue?.is_unit)
                    ? values.formItemsValue.is_unit
                    : undefined,
                level_id: values.formItemsValue.level_id,
            });
        }
    }, [values.formItemsValue.course_id, values.formItemsValue.is_unit, values.formItemsValue.level_id]);

    return (
        <div className="filter-personal-learning-progress_wrapper">
            <Spin spinning={values.isFetching}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    className="form form-full-label filter-personal-learning-progress_inner scroll_primary"
                >
                    <Row>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="dateFrom"
                                label={
                                    <>
                                        <span>{t("report.dateFrom")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_datefrom")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="dateTo"
                                label={
                                    <>
                                        <span>{t("report.dateTo")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_dateto")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="language_id"
                                label={t("language.language")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={handleChangeLang}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    className="filter-person_selector"
                                    listHeight={150}
                                    placeholder={t("language.select_language")}
                                    allowClear
                                    loading={values.isFetchingLanguages}
                                >
                                    {values?.languages?.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={12} xs={24}>
                            <Form.Item name="level_id" label={t("report.level")}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    onChange={(level_id) => {
                                        setValues({ formItemsValue: { ...values.formItemsValue, level_id } });
                                        form.resetFields(["course_id", "assignment_id"]);
                                    }}
                                    className="filter-person_selector"
                                    placeholder={t("report.please_select_level")}
                                    allowClear
                                    disabled={!values.formItemsValue.language_id}
                                >
                                    {values?.levels?.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="is_class"
                                label={t("question.type")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                                initialValue={formItemsFilterInitParams.is_class}
                            >
                                <Select
                                    showSearch
                                    className="filter-person_selector"
                                    placeholder={t("report.select_course_or_class")}
                                    disabled={!values.formItemsValue?.language_id}
                                    onChange={(is_class) => {
                                        setValues({
                                            formItemsValue: {
                                                ...values.formItemsValue,
                                                is_class,
                                            },
                                            courses: [],
                                        });
                                        form.setFieldsValue({ course_id: null });
                                        handleSelectCourseType(is_class);
                                    }}
                                >
                                    <Select.Option value={0}>{t("course.course")}</Select.Option>
                                    <Select.Option value={1}>{t("nav.classes")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="course_id"
                                label={
                                    values.formItemsValue.is_class == 0
                                        ? t("report.course")
                                        : values.formItemsValue.is_class == 1
                                        ? t("nav.classes")
                                        : t("report.need_select_type_before")
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(course_id) =>
                                        setValues({
                                            formItemsValue: {
                                                ...values.formItemsValue,
                                                course_id: course_id,
                                            },
                                        })
                                    }
                                    onSearch={handleSearchCourse}
                                    onPopupScroll={handleScrollCourse}
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    className="filter-person_selector"
                                    listHeight={150}
                                    placeholder={
                                        form.getFieldValue("is_class") == 0
                                            ? t("gradebook.please_select_course")
                                            : form.getFieldValue("is_class") == 1
                                            ? t("report.please_select_class")
                                            : " "
                                    }
                                    allowClear
                                    disabled={!values.formItemsValue.language_id}
                                    dropdownClassName="app-select-dropdown"
                                    dropdownRender={(menu) => (
                                        <>
                                            {values.isFetchingCourses === "searching" ? (
                                                <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                    <Spin style={{ marginRight: "10px" }} />{" "}
                                                    {`${t("shared.searching")}...`}
                                                </div>
                                            ) : null}
                                            {menu}
                                            {values.isScrollingCourse === true && values.courses.length ? (
                                                <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                    <Spin style={{ marginRight: "10px" }} />{" "}
                                                    {`${t("shared.loading")}...`}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                    notFoundContent={
                                        values.isFetchingCourses === true ? (
                                            <i className="notfound-when-loading">
                                                <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                            </i>
                                        ) : values.isFetchingCourses === "searching" ? (
                                            <React.Fragment></React.Fragment>
                                        ) : undefined
                                    }
                                >
                                    {values?.courses?.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                    {/* Fix show id if api courses is pending */}
                                    {courseFilter?.value && !values.courses.length && values.isFetchingCourses && (
                                        <Select.Option value={courseFilter.value}>
                                            {courseFilter.labelValue}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>

                        {values.hide_assignment_type === false && (
                            <Col sm={12} lg={8} md={12} xs={24}>
                                <Form.Item
                                    name="is_unit"
                                    label={t("report.type_of_exercise")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                    initialValue={true}
                                >
                                    <Select
                                        onChange={(is_unit) =>
                                            setValues({
                                                formItemsValue: {
                                                    ...values.formItemsValue,
                                                    is_unit,
                                                },
                                            })
                                        }
                                        className="filter-person_selector"
                                        placeholder={t("report.please_select_type_of_exercise")}
                                        disabled={!values.formItemsValue.course_id}
                                    >
                                        <Select.Option value={false}>{t("report.assignment")}</Select.Option>
                                        <Select.Option value={true}>{t("report.unit")}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}

                        <Col lg={8} md={12} sm={12} xs={24}>
                            <Form.Item name="assignment_id" label={t("report.test_name")}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    className="filter-person_selector"
                                    placeholder={t("report.please_select_test_name")}
                                    allowClear
                                    listHeight={200}
                                    onPopupScroll={handleScrollAssignments}
                                    onChange={(assignment_id) => {
                                        setValues({ formItemsValue: { ...values.formItemsValue, assignment_id } });
                                    }}
                                    dropdownClassName="app-select-dropdown"
                                    disabled={!values.formItemsValue.course_id}
                                    onSearch={handleSearchAssignments}
                                    dropdownRender={(menu) => (
                                        <>
                                            {values.isFetchingAssignments === "searching" ? (
                                                <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                    <Spin style={{ marginRight: "10px" }} />{" "}
                                                    {`${t("shared.searching")}...`}
                                                </div>
                                            ) : null}
                                            {menu}
                                            {values.isFetchingAssignments === true && values.assignments.length ? (
                                                <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                    <Spin style={{ marginRight: "10px" }} />{" "}
                                                    {`${t("shared.loading")}...`}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                    notFoundContent={
                                        values.isFetchingAssignments === true ? (
                                            <i className="notfound-when-loading">
                                                <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                            </i>
                                        ) : values.isFetchingAssignments === "searching" ? (
                                            <React.Fragment></React.Fragment>
                                        ) : undefined
                                    }
                                >
                                    {values.assignments?.map((assignment) => (
                                        <Select.Option key={assignment.id} value={assignment.id}>
                                            {assignment.name}
                                        </Select.Option>
                                    ))}
                                    {/* Fix show id if api assignments is pending */}
                                    {assignmentFilter?.value &&
                                        !values.assignments.length &&
                                        values.isFetchingAssignments && (
                                            <Select.Option value={assignmentFilter.value}>
                                                {assignmentFilter.labelValue}
                                            </Select.Option>
                                        )}
                                </Select>
                            </Form.Item>
                        </Col>
                        {values.hide_assignment_type && (
                            <Col lg={8} md={12} sm={12} xs={24}>
                                <Form.Item
                                    name="is_unit"
                                    label={t("report.type_of_exercise")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                    hidden={values.hide_assignment_type}
                                >
                                    <Select
                                        showSearch
                                        onChange={(is_unit) =>
                                            handleFetchDataForFilter({ is_unit }, () => {
                                                form.setFieldsValue({ assignment_id: "" });
                                            })
                                        }
                                        className="filter-person_selector"
                                        placeholder={t("report.please_select_type_of_exercise")}
                                        allowClear
                                        value={true}
                                    >
                                        <Select.Option value={false}>{t("report.assignment")}</Select.Option>
                                        <Select.Option value={true}>{t("report.unit")}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row className="filter_btn">
                        <Button
                            type="grey"
                            onClick={handleCloseModal}
                            icon={<Icon name="icon-delete" />}
                            title={t("report.cancel")}
                        ></Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("report.apply")}
                        ></Button>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default filterMenu;
