import clsx from "clsx";
import MathQuill from "mathquill-node";
import "mathquill-node/lib/mathquill.css";
import { useEffect, useRef } from "react";

import "./EditableMathField.scss";

const MQ = MathQuill.getInterface(2);

const EditableMathField = ({
    disabled,
    placeholder,
    latex,
    // Event handlers:
    onChange,
}) => {
    const mathFieldRef = useRef(null);
    const mathFieldElemRef = useRef(null);
    const placeholderElemRef = useRef(null);

    const change = (_latex) => {
        if (onChange instanceof Function) {
            onChange(_latex);
        }
    };

    const displayPlaceholder = (_isDisplay) => {
        if (_isDisplay) {
            placeholderElemRef.current.style.display = "";
        } else {
            placeholderElemRef.current.style.display = "none";
        }
    };

    const handleChange = (_mathField) => {
        // Value:
        const _latex = mathFieldRef.current.latex();
        // Placeholder:
        if (_latex) {
            displayPlaceholder(false);
        } else {
            displayPlaceholder(true);
        }
        // Event handlers:
        change(_latex);
    };

    useEffect(() => {
        // Preparation:
        const mathFieldConfig = {
            handlers: {
                edit: handleChange,
            },
        };

        // Set up:
        mathFieldRef.current = MQ.MathField(mathFieldElemRef.current, mathFieldConfig);

        // Clean up:
        return () => {
            mathFieldRef.current.revert();
        };
    }, []);

    useEffect(() => {
        const _latex = mathFieldRef.current.latex();
        if (latex !== _latex) {
            // Insert value to the field:
            mathFieldRef.current.latex(latex);
            // Placeholder:
            if (latex) {
                displayPlaceholder(false);
            } else {
                displayPlaceholder(true);
            }
        }
    }, [latex]);

    return (
        <div className={clsx("app-editable-math-field has-rd", disabled && "disabled")}>
            <div ref={mathFieldElemRef} className="emf-field">
                {/* MUST BE EMPTY! */}
            </div>
            <div ref={placeholderElemRef} className="emf-placeholder">
                {placeholder || "Enter your formula here..."}
            </div>
        </div>
    );
};

export default EditableMathField;
