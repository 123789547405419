import api from "..";

const questionAllFields = [
    "user_id",
    "level_id",
    "type",
    "score",
    "is_publish",
    "is_public",
    "question",
    "answer",
    "answer1",
    "heading",
    "text",
    "word_limit",
    "is_multiple",
    "answer2",
    "title",
    "content",
    "paragraphs",
    "is_require_uppercase",
    "match",
    "passage",
    "passage_student",
    "employee_can_edit",
    "language_id",
    "file_id",
    "coordinates",
    "height",
    "width",
    "path",
    "columnCount",
    "rowCount",
    "lock_cell",
    "shaded",
    "by_location",
    "is_sentence",
    "speaker",
    "spelling",
    "voice",
    "min_value",
    "max_value",
    "jump_value",
    "template_latex",
];

// Question fields:
const questionFields = ["is_publish", "is_public", "user_id", "type", "question", "score", "employee_can_edit"];
// Question fields by types:
const qWriting = ["word_limit"];
const qWritingShort = ["word_limit"];
const qMultipleChoice = ["answer", "is_multiple"];
const qMultipleChoiceBool = ["answer"];
const qMultipleChoiceTable = ["answer1", "answer2", "match"];
const qSort = ["answer"];
const qImgLblsDragDrop = ["file_id", "src", "coordinates", "width", "height", "answer", "answer1"];
const qImgLblsDropdown = ["file_id", "src", "coordinates", "width", "height", "answer", "answer1"];
const qImgLblsText = ["file_id", "src", "coordinates", "width", "height", "answer", "is_require_uppercase"];
const qHighlight = ["passage", "passage_student", "answer"];
const qHighlightImage = ["file_id", "src", "coordinates", "width", "height", "answer"];
const qFillBlanksInput = ["answer", "is_require_uppercase"];
const qFillBlanksSelect = ["answer", "answer1"];
const qFillBlanksDragdrop = ["answer1"];
const qDragDrop = ["answer1", "answer2"];
const qDragDropGroup = ["answer1", "answer2", "match"];
const qDragDropGroupOrder = ["answer1", "answer2", "match"];
const qhotspot = ["file_id", "src", "coordinates", "width", "height", "answer", "path"];
const qshading = ["width", "height", "answer", "path", "shaded", "columnCount", "rowCount", "by_location", "lock_cell"];
const qSpeechToText = ["answer", "is_sentence", "speaker", "spelling"];
const qPhoneticTable = ["answer"];
const qSpeechToText_Text = ["answer", "speaker", "voice"];
const qGraph = ["answer"];
const qGraphNumberLine = ["answer", "min_value", "max_value", "jump_value"];
const qConvertMeasurement = ["answer"];
const qMatrix = ["template_latex", "answer"];
const qFillBlanksLatex = ["template_latex", "answer"];

/**
 * (VERIFIED) List of question types.
 * Notes:
 * - Question types and keys are used to specify a question type! But:
 *   + types are used for fetch params.
 *   + keys are used for url params.
 * - Each question has its own fetch params.
 * - Others:
 *   + isScoring (Là câu chấm tay).
 */
export const questionTypes = {
    writing: {
        key: "writing",
        fetchParams: qWriting,
        isScoring: true,
    },
    writing_short: {
        key: "writing-short",
        fetchParams: qWritingShort,
        isScoring: true,
    },

    multiple_choice: {
        key: "multiple-choice",
        fetchParams: qMultipleChoice,
    },
    multiple_choice_sentence_quiz: {
        key: "multiple-choice-sentence-quiz",
        fetchParams: qMultipleChoice,
    },
    multiple_choice_boolean: {
        key: "multiple-choice-boolean",
        fetchParams: qMultipleChoiceBool,
    },
    multiple_choice_table: {
        key: "multiple-choice-table",
        fetchParams: qMultipleChoiceTable,
    },

    sort: {
        key: "sort",
        fetchParams: qSort,
    },

    drag_drop: {
        key: "drag-drop",
        fetchParams: qDragDrop,
    },
    drag_drop_group: {
        key: "drag-drop-group",
        fetchParams: qDragDropGroup,
    },
    drag_drop_group_order_by: {
        key: "drag-drop-group-order-by",
        fetchParams: qDragDropGroupOrder,
    },

    fill_in_the_blank_text: {
        key: "fill-in-the-blank-text",
        fetchParams: qFillBlanksInput,
    },
    fill_in_the_blank: {
        key: "fill-in-the-blank",
        fetchParams: qFillBlanksSelect,
    },
    fill_in_the_blank_drag_drop: {
        key: "fill-in-the-blank-drag-drop",
        fetchParams: qFillBlanksDragdrop,
    },

    highlight: {
        key: "highlight",
        fetchParams: qHighlight,
    },

    // Điền vào chỗ trống trong ảnh:
    fill_in_the_blank_text_image: {
        key: "imgwithlabels-text",
        fetchParams: qImgLblsText,
    },
    // Dropdown list trong ảnh:
    fill_in_the_blank_image: {
        key: "imgwithlabels-dropdown",
        fetchParams: qImgLblsDropdown,
    },
    // Kéo thả vào hình ảnh:
    fill_in_the_blank_drag_drop_image: {
        key: "imgwithlabels-dragdrop",
        fetchParams: qImgLblsDragDrop,
    },

    // (Graph) Đồ thị:
    draw_graph: {
        key: "graph",
        fetchParams: qGraph,
    },
    number_line: {
        key: "number-line",
        fetchParams: qGraphNumberLine,
    },

    // Đánh dấu trong ảnh:
    highlight_image: {
        key: "highlight_image",
        fetchParams: qHighlightImage,
        isScoring: true,
    },
    // Hotspot
    hot_spot: {
        key: "hotspot",
        fetchParams: qhotspot,
        // isScoring: true,
    },

    matrix: {
        key: "math-matrix",
        fetchParams: qMatrix,
    },

    fill_in_the_blank_latex: {
        key: "fill-in-the-blank-latex",
        fetchParams: qFillBlanksLatex,
    },

    // math_fill_expression: {
    //     key: "fill-expression",
    // },

    // complete_the_equation: {
    //     key: "complete-the-equation",
    // },

    // Math - Text and dropdown:
    convert_measurement: {
        key: "math-text-dropdown",
        fetchParams: qConvertMeasurement,
    },

    // Đoạn văn:
    passage: {
        key: "passage",
        fetchParams: [],
    },

    // Tô màu ô vuông:
    highlight_square: {
        key: "highlight_square",
        fetchParams: qshading,
    },

    //speech to text:
    speech_to_text: {
        key: "speech-to-text",
        fetchParams: qSpeechToText,
        isScoring: true,
    },

    //phonetic table:
    phonetic_table: {
        key: "phonetic-table",
        fetchParams: qPhoneticTable,
        // isScoring: true,
    },
    //Đoạn hội thoại
    speech_to_text_conversation_text: {
        key: "speech-to-text-conversation-text",
        fetchParams: qSpeechToText_Text,
        isScoring: true,
    },
    speech_to_text_conversation_audio: {
        key: "speech-to-text-conversation-audio",
        fetchParams: qSpeechToText_Text,
        isScoring: true,
    },
};

/**
 * (VERIFIED) Get fields by question type.
 * @param {string} _questionType
 * @returns Array of strings.
 */
const getQuestionDetailFieldsByType = (_questionType) => {
    return questionTypes[_questionType]?.fetchParams || [];
};

/**
 * (VERIFIED) Get question detail.
 * @param {string} id Question id.
 * @param {Array} relations Question more info.
 * @returns Object.
 */
export const findQuestion = (
    id,
    qType,
    fetchParams = {},
    relations = ["level", "tags", "language", "file", "user"]
) => {
    // Fields:
    const qDetailFields = getQuestionDetailFieldsByType(qType) || [];
    // Filter:
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.is_show && filters.push(`is_show:${fetchParams.is_show}`);
    // Fetch params:
    const params = {
        _filter: filters.join(";") || undefined,
        _relations: relations.join(","),
        _question_fields: `${[...questionFields, ...qDetailFields]} `,
        _level_fields: relations.includes("level") ? `${["name"]}` : "",
        _tag_fields: relations.includes("tags") ? `${["name", "color", "owner_id"]}` : "",
        _language_fields: relations.includes("language") ? `${["name", "code"]}` : "",
        _file_fields: relations.includes("file") ? `${["src"]}` : "",
        _user_fields: relations.includes("user") ? `${["role"]}` : "",
    };
    // Fetch:
    return api
        .get(`/question/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

/**
 * (VERIFIED) Get question detail of passage question - child.
 * @param {string} id Question id.
 * @param {Array} relations Question more info.
 * @returns Object.
 */
export const findChildQuestion = (id, relations = []) => {
    const params = {
        _relations: relations.join(","),
        _question_fields: `${[
            "type",
            "question",
            "score",
            "answer",
            "answer1",
            "answer2",
            "match",
            "word_limit",
            "is_multiple",
            "is_require_uppercase",
            "file",
            "coordinates",
            "width",
            "height",
            "text",
            "passage",
            "passage_student",
            "question_parent_id",
            "file_id",
            "path",
            "is_sentence",
            "speaker",
            "spelling",
            "language_id",
            "voice",
        ]}`,
        _language_fields: relations.includes("language") ? `${["name", "code"]}` : "",
    };
    return api
        .get(`/question/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

/**
 * (VERIFIED) Get question list.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchQuestionList = (fetchParams = {}) => {
    // Filter:
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`);
    fetchParams.owner_id && fetchParams.owner_id !== "all" && filters.push(`user_id:${fetchParams.owner_id}`);
    fetchParams.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams.tag_id && filters.push(`tag_id:${fetchParams.tag_id}`);
    fetchParams.tag_name && filters.push(`tag_name:${fetchParams.tag_name}`);
    fetchParams.type && filters.push(`type:${fetchParams.type}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.language_id && filters.push(`language_id:${fetchParams.language_id}`);
    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _question_fields: `${questionAllFields}`,
        // Other fields:
        _relations: `${["level", "tags", "language", "file", "user", "updater", "question_type"]}`,
        _level_fields: `${["name"]} `,
        _tag_fields: `${["name", "color"]} `,
        _language_fields: `${["name", "code"]}`,
        _file_fields: `${["src"]}`,
        _user_fields: `${["name"]} `,
        _question_type_fields: `${["description", "name", "type", "parent_id"]}`,
        // Pagination:
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    // Fetch:
    return api
        .get("/question", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const createQuestion = (data) => {
    return api
        .post("/question", data)
        .then(({ status, id, message }) => {
            return { status, id, message };
        })
        .catch(() => ({ status: false }));
};

export const updateQuestion = (id, data) => {
    return api
        .put(`/question/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const deleteQuestion = (id) => {
    return api
        .delete(`/question/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const cloneQuestion = (id) => {
    return api
        .post(`/question/clone_question/${id}`)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

/**
 * ****************************************************************************************************
 */

/**
 * (VERIFIED) Get question detail of passage question - parent.
 * @param {string} id Question id.
 * @returns Object.
 */
export const findPassageQuestion = (id, fetchParams = {}) => {
    const filters = [];
    const params = {
        _filter: filters.join(";") || undefined,
        _relations: "level,tags,language,user",
        // _relations: "level,tags,language,file,user",
        _question_fields: `${questionAllFields} `,
        _level_fields: `${["name"]}`,
        _tag_fields: `${["name", "color", "owner_id"]}`,
        _language_fields: `${["name", "code"]}`,
        _user_fields: `${["role"]}`,
    };
    return api
        .get(`/question/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const addChildPassageForPassageQuestion = (data) => {
    return api
        .post("/question_paragraph", data)
        .then(({ status, id }) => ({ status, id }))
        .catch(() => ({ status: false }));
};

export const addChildQuestionForPassageQuestion = (data) => {
    return api
        .post("/question", data)
        .then(({ status, id }) => {
            return { status, id };
        })
        .catch(() => ({ status: false }));
};

export const findChildPassage = (id, relations = []) => {
    const params = {
        _relations: relations.join(","),
        _question_paragraph_fields: `${["heading", "title", "content"]} `,
    };
    return api
        .get(`/question_paragraph/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const updateChildPassage = (id, data) => {
    return api
        .put(`/question_paragraph/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const updateChildQuestion = (id, data) => {
    return (
        api
            // .put(`/question_detail/${id}`, data)
            .put(`/question/${id}`, data)
            .then(({ status, message }) => ({ status, message }))
            .catch(() => ({ status: false }))
    );
};

export const deleteChildPassage = (id) => {
    return api
        .delete(`/question_paragraph/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const deleteChildQuestion = (id) => {
    return (
        api
            // .delete(`/question_detail/${id}`)
            .delete(`/question/${id}`)
            .then(({ status, message }) => ({ status, message }))
            .catch(() => ({ status: false }))
    );
};

export const quickAddOrDeleteQuestionToPassage = (id, data, type) => {
    return api
        .put(`/question_paragraph/${id}/sync_questions`, { type, question_ids: data })
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
