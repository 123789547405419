import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import "./RecordButton.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

const RecordButton = ({ isTalking, tooltip, onClick = () => {}, isSmallSize = false }) => {
    const { t } = useTranslation();

    return (
        <Tooltip title={tooltip}>
            <div className={clsx({ "record-button-wrapper": true, small: isSmallSize })}>
                <button type="button" className="record-button" onClick={onClick}>
                    <div className={clsx({ "pulse-ring": true, recording: isTalking })}></div>
                    <div className="record-icon">{isTalking ? <AudioOutlined /> : <AudioMutedOutlined />}</div>
                </button>
                {isTalking ? (
                    <div className="record-note">({t("speech_to_text.tap_to_close_mic")})</div>
                ) : (
                    <div className="record-note">({t("speech_to_text.tap_to_open_mic")})</div>
                )}
            </div>
        </Tooltip>
    );
};

export default RecordButton;
