import { t } from "i18next";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragDropIcon from "src/assets/images/drag-icon.svg";
import LatexDangerously from "src/modules/components/LatexDangerously";
import QuestionContentDetail from "../components/ContentDetail";
import chineseToPinyin from "chinese-to-pinyin";
import "./dragDropGroup.scss";

const DragDropGroup = (props) => {
    const { question, groups, answers, defaultAnswer, answered, isReadonly = false, onChange, isShowPinyin } = props;

    const convertMatch = (match, answers) => {
        const newMatch = structuredClone(match);

        Object.entries(newMatch || {}).forEach(([key, aswrs]) => {
            aswrs.forEach((answer, answerIndex) => {
                if (typeof answer === "string") {
                    const indexOfAnswers = answers.findIndex((aswr) => {
                        return aswr === answer;
                    });
                    newMatch[key][answerIndex] = {
                        text: answer,
                        value: indexOfAnswers,
                    };
                }
            });
        });

        return newMatch;
    };

    const [listAnswered, setListAnswered] = useState(convertMatch(defaultAnswer?.match, answers));
    const [restAnswers, setRestAnswers] = useState(null);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const convertChangeMatch = (match) => {
        const newMatch = structuredClone(match);

        Object.entries(newMatch || {}).forEach(([key, aswrs]) => {
            aswrs.forEach((answer, answerIndex) => {
                newMatch[key][answerIndex] = answer.text;
            });
        });

        return newMatch;
    };

    const handleChange = (newAnswered) => {
        // onChange two way binding (create, update question)
        if (answered) {
            onChange({
                answered: {
                    match: newAnswered,
                },
            });
        } else {
            // onChange one way binding (test and preview exam, preview question)
            onChange({
                answered: {
                    match: convertChangeMatch(newAnswered),
                },
            });
        }
    };

    const handleDragEnd = (value) => {
        if (!value.destination) {
            return;
        }

        // Loại kéo thả (từ answer qua answered)
        const sourceType = value.source.droppableId === "listAnswer" ? "listAnswer" : "listAnswered";
        const destinationType = value.destination.droppableId === "listAnswer" ? "listAnswer" : "listAnswered";

        // Vị trí được kéo
        const sourceIndex = value.source.index;

        // Vị trí được thả
        const destinationIndex = value.destination.index;

        // Chỉ sử dụng khi kéo thả từ group này sang group khác (Từ answered sang answer sẽ không có tác dụng)
        const sourceGroup = value.source.droppableId.split("group-key-")[1];
        const destinationGroup = value.destination.droppableId.split("group-key-")[1];

        // Case list answer to list answered
        if (sourceType === "listAnswer" && destinationType === "listAnswered") {
            // Add answer to answered
            const newAnswered = structuredClone(listAnswered) || {};

            if (!newAnswered[`key-${destinationGroup}`]) newAnswered[`key-${destinationGroup}`] = [];

            newAnswered[`key-${destinationGroup}`].splice(destinationIndex, 0, restAnswers[sourceIndex]);

            setListAnswered(newAnswered);
            // Remove answer in rest answer list
            const newRestAnswers = [...restAnswers];
            newRestAnswers.splice(sourceIndex, 1);

            setRestAnswers(newRestAnswers);

            handleChange(newAnswered);
        }

        // Case list answered to list answer
        if (sourceType === "listAnswered" && destinationType === "listAnswer") {
            // Remove answer in answerd
            const newAnswered = structuredClone(listAnswered) || {};

            if (!newAnswered[`key-${sourceGroup}`]) newAnswered[`key-${sourceGroup}`] = [];
            const [answerRemoved] = newAnswered[`key-${sourceGroup}`].splice(sourceIndex, 1);

            setListAnswered(newAnswered);

            // Add answer have removed to rest answer
            const newRestAnswers = [...restAnswers];
            newRestAnswers.splice(destinationIndex, 0, answerRemoved);

            setRestAnswers(newRestAnswers);

            handleChange(newAnswered);
        }

        // Case list answer to list answer
        if (sourceType === "listAnswer" && destinationType == "listAnswer") {
            const newRestAnswer = reorder(restAnswers, sourceIndex, destinationIndex);

            setRestAnswers(newRestAnswer);
        }

        // Case list answered to list answered
        if (sourceType == "listAnswered" && destinationType === "listAnswered") {
            // Case move to other group
            const newAnswered = structuredClone(listAnswered) || {};

            // Remove answer in source group
            if (!newAnswered[`key-${sourceGroup}`]) newAnswered[`key-${sourceGroup}`] = [];
            const [answerRemoved] = newAnswered[`key-${sourceGroup}`].splice(sourceIndex, 1);

            // Add answer in destination group
            if (!newAnswered[`key-${destinationGroup}`]) newAnswered[`key-${destinationGroup}`] = [];

            newAnswered[`key-${destinationGroup}`].splice(destinationIndex, 0, answerRemoved);

            setListAnswered(newAnswered);

            handleChange(newAnswered);
        }
    };

    // Rest answers
    useEffect(() => {
        if (answers) {
            const newRestAnswers = [];

            const answeredArr = Object.values(listAnswered || {}).flat();

            answers?.forEach((answer, index) => {
                // If answer has not been selected then will be added to rest answers list
                const indexAnswerInAnswerd = answeredArr.findIndex((aswr) => aswr.value === index);

                if (indexAnswerInAnswerd === -1) {
                    newRestAnswers.push({
                        text: answer,
                        value: answers[indexAnswerInAnswerd]?.value || index,
                    });
                }
            });

            setRestAnswers(newRestAnswers);
        }
    }, [answers, listAnswered]);

    useEffect(() => {
        if (answered?.match) {
            const answeredArr = Object.values(listAnswered || {}).flat();
            const currentListAnswered = defaultAnswer?.match || answered?.match;
            // Handle change answered sync with rest answers (mutate state)
            const newListAnswered = structuredClone(convertMatch(currentListAnswered, answers));

            Object.entries(newListAnswered || {}).forEach(([key, aswrs]) => {
                aswrs.forEach((answer, i) => {
                    const answerIndex = answeredArr.findIndex((aswr) => aswr?.value === answer?.value);

                    if (answerIndex !== -1) {
                        if (answers[answer?.value]) answer.text = answers[answer?.value];
                        else delete aswrs[i];
                    }
                });
            });

            setListAnswered(newListAnswered);
        }
    }, [answered, answers]);

    return (
        <div className="q-drag-drop-group">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question,
                    }}
                />
            </div>

            <div className="dragDropQuestion_container">
                <div className="dragDropGroup_container">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {restAnswers?.length ? (
                            <Droppable className="droppable-listItem" droppableId="listAnswer" isDropDisabled={false}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="dragDropGroup_listAnswer answer"
                                    >
                                        {restAnswers?.map((item, index) => {
                                            return (
                                                <Draggable
                                                    key={`answer-${item?.value}`}
                                                    draggableId={`answer-${item?.value}`}
                                                    index={index}
                                                    isDragDisabled={isReadonly}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className={`${
                                                                snapshot?.isDragging
                                                                    ? "dragDrop_item dragging"
                                                                    : "dragDrop_item"
                                                            } ${isReadonly ? "disabled" : ""}`}
                                                        >
                                                            <img
                                                                {...provided.dragHandleProps}
                                                                src={dragDropIcon}
                                                                alt="dragDropIcon"
                                                            />
                                                            <div
                                                                className="dragDrop_item-value"
                                                                style={{ width: "100%", height: "100%" }}
                                                            >
                                                                {item?.value + 1}.{" "}
                                                                <LatexDangerously innerHTML={item.text} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                        {snapshot.isDraggingOver && (
                                            <div
                                                className="placeholder"
                                                style={{
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    borderRadius: "3px",
                                                    border: "dashed 1px #ddd",
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        ) : (
                            <Droppable droppableId="listAnswer">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            backgroundColor: snapshot.isDraggingOver ? "rgba(0, 119, 255, 0.4)" : "",
                                        }}
                                        className="dropEmptyItem answer"
                                    >
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}
                        <div className="dragDropGroup_listAnswer group">
                            {groups?.map((item) => {
                                return (
                                    <div className="dragDrop_itemWrapper" key={item.key}>
                                        <div>
                                            {isShowPinyin && (
                                                <div className="dragDrop_itemTitle_pinyin">
                                                    <LatexDangerously
                                                        innerHTML={chineseToPinyin(item.name, {
                                                            keepRest: true,
                                                        })}
                                                    />
                                                </div>
                                            )}

                                            <div className="dragDrop_itemTitle">
                                                <LatexDangerously innerHTML={item.name} />
                                            </div>
                                        </div>
                                        <Droppable key={`group-${item.key}`} droppableId={`group-${item.key}`}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor: snapshot.isDraggingOver
                                                            ? "rgba(0, 119, 255, 0.4)"
                                                            : "",
                                                    }}
                                                    className="dropEmptyItem group"
                                                >
                                                    {listAnswered?.[item.key]?.map((answer, index) => {
                                                        return (
                                                            <Draggable
                                                                draggableId={`group-${answer?.value}`}
                                                                index={index}
                                                                isDragDisabled={isReadonly}
                                                                key={answer?.value}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        className={`${
                                                                            snapshot?.isDragging
                                                                                ? "dragDrop_item dragging"
                                                                                : "dragDrop_item"
                                                                        } ${isReadonly ? "disabled" : ""}`}
                                                                    >
                                                                        <img
                                                                            {...provided.dragHandleProps}
                                                                            src={dragDropIcon}
                                                                            alt="dragDropIcon"
                                                                        />
                                                                        <div
                                                                            className="dragDrop_item-value"
                                                                            style={{ width: "100%", height: "100%" }}
                                                                        >
                                                                            {answer?.value + 1}.
                                                                            <LatexDangerously
                                                                                innerHTML={answer?.text}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}

                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                );
                            })}
                        </div>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

/******* Type Checking **********/
const IAnswers = PropTypes.arrayOf(
    PropTypes.oneOfType([
        PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        PropTypes.string,
    ])
);

const IMatch = PropTypes.shape({
    match: PropTypes.objectOf(IAnswers),
});

DragDropGroup.propTypes = {
    question: PropTypes.string,
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    answers: PropTypes.arrayOf(PropTypes.string),
    defaultAnswer: IMatch,
    answered: IMatch,
};

export default DragDropGroup;
