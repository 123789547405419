import api from "..";

const subscriptionFields = {
    subscInfo: [
        "code",
        "user_id",
        "valid_from",
        "valid_to",
        "total",
        "amount",
        "status",
        "plan_id",
        "plan_price_id",
        "ai_function",
        "ai_credit",
        "storage_limit",
        "exam_limit",
        "question_limit",
        "questions_per_exam_limit",
        "submissions_per_assignment_limit",
        "usage_per_exam_limit",
        "employee_account_limit",
        "freeup_storage",
        // "used_plan",
        // "list_plan_price",
        "payment_method",
        "plan_type",
        "options",
    ],
};
const subscriptionRelations = [
    "promotions",
    "user",
    "transaction",
    "plan",
    "organization",
    // "options" // Already used in _order_fields.
];

// Get subscription history list:
export const fetchSubscriptions = (fetchParams = {}, purchased = false) => {
    const filters = purchased ? ["purchased_plan:1"] : [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.valid_from && filters.push(`valid_from:${fetchParams.valid_from}`);
    fetchParams.valid_to && filters.push(`valid_to:${fetchParams.valid_to}`);
    fetchParams.status && filters.push(`status:${fetchParams.status}`);
    fetchParams.plan_id && filters.push(`plan_id:${fetchParams.plan_id}`);
    fetchParams.purchased_plan && filters.push("purchased_plan:1"); // If `purchased_plan` exists, the result will be the list of success orders.
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _order_fields: `${subscriptionFields.subscInfo}`,
        _relations: `${subscriptionRelations}`,
        _orderBy: fetchParams.orderBy || "created_at:desc",
        _plan_fields: `${["name"]}`,
        _plan_price_fields: `${["months"]}`,
        _transaction_fields: `${["status", "payment_method"]}`,
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/order", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                    lastPage: meta.pagination.lastPage,
                },
            }),
        }))
        .catch(({ message }) => ({ status: false, data: [], message }));
};
