import { Col, Form, InputNumber, notification, Row, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import Editor from "src/modules/components/Editor";
import configEditor from "src/utils/configEditor";
import parser from "src/utils/parser";
import MultipleChoiceAnswers from "../../components/MultipleChoiceAnswers";

const editorConfigQuestion = configEditor.question();

const DetailMultipleChoiceSentenceQuiz = (props) => {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
        // Others:
        language,
    } = props;

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    const resultCount = fValues.correct_answer.filter((aswr) => aswr.result).length;
                                    if (resultCount >= 1) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject({
                                        customError: true,
                                        message: t("create_multiple_choice.blank_result"),
                                    });
                                })
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    if (errorInfo.customError) {
                                        notification.warning({
                                            message: errorInfo.message,
                                        });

                                        return;
                                    }
                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        const _formData = form.getFieldsValue();

        if (isAllFieldsValid) {
            const corrLength = _formData.correct_answer?.filter((item) => item.result)?.length;
            if (corrLength === 0) {
                notification.error({ message: t("question.empty_answer") });
                return;
            }

            // check phải có ít nhất 2 đáp án đúng
            // if (corrLength < 2) {
            //     notification.error({ message: t("question_multiple_choice.must_choose_two_or_more_answers") });
            //     return;
            // }

            // Preparation:
            const _answerCompact = [];
            const _answer = _formData.correct_answer.map((aswr) => {
                _answerCompact.push(parser.compactMathField(aswr.text));
                return {
                    ...aswr,
                    text: parser.compactMathField(aswr?.text),
                };
            });

            // Data:
            const _questionData = {
                type: currQType,
                answer: _answer,
            };

            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: _answerCompact,
                correct_answer: _formData.correct_answer,
                // Special fields:
                type: _questionData.type,
                language: language || detailData?.questionData?.language,
            };
            // Start action:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;
                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                correct_answer: qData.answer,
                            };
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    // Case create
                    form.setFieldsValue({
                        correct_answer: [
                            { text: "", result: false },
                            { text: "", result: false },
                        ],
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res[1].data;
                            const questionFormData = {
                                question: qData.question || "",
                                score: qData.score || 0,
                                correct_answer: qData.answer,
                            };
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });

                    // Case create in passage
                    form.setFieldsValue({
                        correct_answer: [
                            { text: "", result: false },
                            { text: "", result: false },
                        ],
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <>
            <Row className="question-editor">
                <Typography className="question-editor-title">{t("question.question")}</Typography>
                <Form.Item
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Editor config={editorConfigQuestion}></Editor>
                </Form.Item>
            </Row>

            <Row className="question-formitems">
                <Col>
                    <Form.Item
                        name="score"
                        label={t("question.score")}
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <InputNumber
                            className="app-input"
                            min={0}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="question-formitems question-setanswers">
                <Typography className="question-setanswers-title">{t("question.answer")}</Typography>
                <div className="question-answer-list">
                    <Form.Item name="correct_answer">
                        <MultipleChoiceAnswers isMultiple={true} />
                    </Form.Item>
                </div>
            </Row>
        </>
    );
};

export default DetailMultipleChoiceSentenceQuiz;
