import { Col, Form, Input, Row, Select } from "antd";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { fetchLevels } from "src/api/containers/level";
import { useValues } from "src/hooks";

import "./SettingTopBar.scss";

function SettingTopBar(props) {
    const { optionListLevel = [], optionListLanguage = [], onChangeLevelList = () => {} } = props;

    const { t } = useTranslation();
    const [values, setValues] = useValues({
        isFetchingLevels: false,
    });
    const timeoutIdGetLevels = useRef(null);

    const handleSearchLevels = (keyword) => {
        clearTimeout(timeoutIdGetLevels.current);
        if (typeof keyword === "string") {
            setValues({ ...values, isFetchingLevels: true });
            onChangeLevelList([]);
            timeoutIdGetLevels.current = setTimeout(() => {
                fetchLevels({ slug: keyword, noPagination: true }).then((res) => {
                    if (res.status && res.data) {
                        setValues({ ...values, isFetchingLevels: false });
                        onChangeLevelList(res.data);
                    }
                });
            }, 500);
        }
    };

    return (
        <Row className="exam-formitems top-bar-setting">
            <Col xs={24} md={8}>
                <Form.Item
                    name="name"
                    label={t("exam_bank.name")}
                    rules={[{ required: true, message: t("message.required") }]}
                >
                    <Input className="app-input has-rd" placeholder={t("exam.name")} />
                </Form.Item>
            </Col>

            <Col xs={24} md={8}>
                <Form.Item
                    name="language_id"
                    label={t("language.language")}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: t("message.required"),
                    //     },
                    // ]}
                >
                    <Select
                        className="app-select show-arrow has-rd"
                        placeholder={t("language.select_language")}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        showArrow={false}
                    >
                        {optionListLanguage.map((opt, i) => {
                            if (opt) {
                                return (
                                    <Select.Option key={opt.id} value={opt.id}>
                                        {opt.name}
                                    </Select.Option>
                                );
                            }
                        })}
                    </Select>
                </Form.Item>
            </Col>

            <Col xs={24} md={8}>
                <Form.Item
                    name="level_id"
                    label={t("shared.level")}
                    rules={[
                        {
                            required: true,
                            message: t("message.required"),
                        },
                    ]}
                >
                    <Select
                        className="app-select show-arrow has-rd"
                        placeholder={t("question.select_level")}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        showArrow={false}
                        // onSearch={(keyword) => {
                        //     handleSearchLevels(keyword);
                        // }}
                        // notFoundContent={
                        //     values.isFetchingLevels === true ? (
                        //         <i>
                        //             <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                        //         </i>
                        //     ) : (
                        //         undefined
                        //     )
                        // }
                    >
                        {optionListLevel.map((opt, i) => {
                            if (opt) {
                                return (
                                    <Select.Option key={`cat${i}`} value={opt.id}>
                                        {opt.name}
                                    </Select.Option>
                                );
                            }
                        })}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
}

export default SettingTopBar;
