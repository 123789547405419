import { UserOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import { accessRoles } from "src/api/containers/auth";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";

import "./RolePicker.scss";

function RolePicker({ onChange }) {
    const [values, setValues] = useValues({
        loading: false,
    });

    const { t } = useTranslation();

    const handleClickRole = (role) => {
        if (onChange instanceof Function) {
            onChange(role);
        }
    };

    return (
        <div className="role-picker">
            <div className="role-info">
                <div className="info-title">
                    <div className="info-icon">{<UserOutlined />}</div>
                    <div className="info-iconlabel">{t("role_option.pick_your_role")}</div>
                </div>
            </div>
            <div className="role-selections">
                {Object.keys(accessRoles).map((item, itemIndex) => {
                    if (accessRoles[item]?.isRolePickerOption) {
                        return (
                            <CustomButton
                                key={`role-opt${itemIndex}`}
                                type="ghost"
                                title={t(`role_option.${item}`)}
                                onClick={() => handleClickRole(item)}
                                isLoading={values.loading}
                            ></CustomButton>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

export default RolePicker;
