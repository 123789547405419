import { createSlice } from "@reduxjs/toolkit";
import { decodebase64Utf8, encodebase64Utf8 } from "../utils/helpers/string";
const initialState = {};

// Catch error if JSON.parse listAnswer in localstorage invalid
try {
    initialState.listAnswers = JSON.parse(decodebase64Utf8(localStorage.getItem("listAnswers"))) || {};
} catch (err) {
    localStorage.removeItem("listAnswers");
    initialState.listAnswers = {};
}

initialState.isReadonly = false;
initialState.tempTime = {};

export const answerSlice = createSlice({
    name: "answer",
    initialState,
    reducers: {
        addAnswer: (state, action) => {
            const { record_id, value } = action.payload;

            state.listAnswers[record_id] = {
                ...state.listAnswers[record_id],
                ...value,
            };
            localStorage.setItem(
                "listAnswers",
                encodebase64Utf8(
                    JSON.stringify({
                        [record_id]: {
                            ...state.listAnswers[record_id],
                            ...value,
                        },
                    })
                )
            );
        },
        addAnswerPreview: (state, action) => {
            const { value } = action.payload;

            state.listAnswers.preview = {
                ...state.listAnswers.preview,
                ...value,
            };
        },
        removeRecord: (state, action) => {
            const { recordId } = action.payload;
            delete state.listAnswers[recordId];

            localStorage.setItem(
                "listAnswers",
                JSON.stringify({
                    ...JSON.parse(decodebase64Utf8(localStorage.getItem("listAnswers"))),
                    [recordId]: {},
                })
            );

            localStorage.setItem(
                "listTimePerQuestion",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("listTimePerQuestion")),
                    [recordId]: {},
                })
            );

            localStorage.setItem(
                "tempTime",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("tempTime")),
                    [recordId]: {},
                })
            );
        },
        clearListAnswers: (state, action) => {
            state.listAnswers = {};
        },
        setIsReadOnly: (state, action) => {
            state.isReadonly = action.payload;
        },
    },
});

export const { addAnswer, addAnswerPreview, removeRecord, clearListAnswers, setIsReadOnly } = answerSlice.actions;

export default answerSlice.reducer;
