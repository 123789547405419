export const fileManagerConfigs = {
    imageAcceptedExtensionList: [".png", ".jpg", ".jpeg", ".webp", ".bmp", ".dib", ".gif", ".ico"],
    audioAcceptedExtensionList: [".mp3", ".wav", ".wma", ".aif", ".aifc", ".mid"],
    videoAcceptedExtensionList: [".mp4", ".m4p", ".m4v", ".webm", ".avi", ".wmv", ".mov", ".flv"],
};

const getFileExtensionPath = (filePath = "") => {
    if (filePath) {
        let iExtension = -1;
        for (let i = filePath.length - 1; i >= 0; i--) {
            if (filePath[i] === ".") {
                iExtension = i;
                break;
            }
        }
        return filePath.substring(iExtension, filePath.length).toLowerCase();
    }
    return false;
};

/**
 * Check if file to be upload are valid!
 * @param {string} filePath File name and extension.
 * @returns true/false.
 */
export const validateFileBeforeUpload = (filePath = "", fileFormat = "") => {
    if (filePath) {
        let iExtension = -1;
        // Get extension path:
        for (let i = filePath.length - 1; i >= 0; i--) {
            if (filePath[i] === ".") {
                iExtension = i;
                break;
            }
        }
        // Checking:
        if (iExtension > 0) {
            const name = filePath.substring(iExtension, filePath.length).toLowerCase();
            for (let i = 0; i < fileManagerConfigs.imageAcceptedExtensionList.length; i++) {
                if (name === fileManagerConfigs.imageAcceptedExtensionList[i]) {
                    return { status: true, type: "image" };
                }
            }
            for (let i = 0; i < fileManagerConfigs.audioAcceptedExtensionList.length; i++) {
                if (name === fileManagerConfigs.audioAcceptedExtensionList[i]) {
                    return { status: true, type: "audio" };
                }
            }
            for (let i = 0; i < fileManagerConfigs.videoAcceptedExtensionList.length; i++) {
                if (name === fileManagerConfigs.videoAcceptedExtensionList[i]) {
                    return { status: true, type: "video" };
                }
            }
        }
    } else if (fileFormat) {
        for (let i = 0; i < fileManagerConfigs.imageAcceptedExtensionList.length; i++) {
            if (fileFormat.toLowerCase() === fileManagerConfigs.imageAcceptedExtensionList[i]) {
                return true;
            }
        }
        for (let i = 0; i < fileManagerConfigs.audioAcceptedExtensionList.length; i++) {
            if (fileFormat.toLowerCase() === fileManagerConfigs.audioAcceptedExtensionList[i]) {
                return true;
            }
        }
        for (let i = 0; i < fileManagerConfigs.videoAcceptedExtensionList.length; i++) {
            if (fileFormat.toLowerCase() === fileManagerConfigs.videoAcceptedExtensionList[i]) {
                return true;
            }
        }
    }
    return false;
};

/**
 * Check if files to be upload are valid!
 * @param {FileList} fileList A FileList object.
 * @returns status.
 */
export const validateFileList = (fileList) => {
    const invalidFiles = [];
    // Validate file format:
    if (fileList?.length > 0) {
        let currFormat = "";
        for (let i = 0; i < [...fileList].length; i++) {
            currFormat = getFileExtensionPath(fileList[i].name);
            if (invalidFiles.includes(currFormat)) {
                continue;
            } else if (validateFileBeforeUpload(false, currFormat) === false) {
                invalidFiles.push(` ${currFormat}`);
            }
        }
        if (invalidFiles.length) {
            return {
                status: false,
                message: `Unsupported file format: ${invalidFiles.join(", ")}`,
            };
        }
        return {
            status: true,
            message: "",
        };
    } else {
        return {
            status: false,
            message: "Unknown error!",
        };
    }
};
