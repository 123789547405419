import React from "react";
import { Pie } from "@ant-design/plots";
import { useTranslation } from "react-i18next";
import "./DiskUsageStat.scss";

function DiskUsageStat(props) {
    const {
        className = "",
        total = 0,
        data = [],
        dataColors = {},
        dataLabels = {},
        statisticConfig = {
            content: "",
            contentType: "total", // Values: "total", "left-per-total".
        },
        plotContainer = {
            appendPadding: [0, 0, 30, 0],
        },
        ...rest
    } = props;

    const { t } = useTranslation();

    // Sample data:
    // const propsColor = {
    //     space_used: "#1a73e8",
    //     space_left: "rgba(0, 0, 0, 0.2)",
    // };
    // const propsLabel = {
    //     space_used: t("my_storage.space_used"),
    //     space_left: t("my_storage.space_left"),
    // };
    // const propsData = [
    //     {
    //         name: "space_used",
    //         value: 19,
    //     },
    //     {
    //         name: "space_left",
    //         value: 36,
    //     },
    // ];

    const generateValueWithUnit = (_value, isExact = false) => {
        const value = Math.round(_value);
        if (value >= 1048576) {
            const r = value / 1024;
            return isExact === false ? `${Math.round(r)} TB` : `${r} TB`;
        } else if (value >= 1024) {
            const r = value / 1024;
            return isExact === false ? `${Math.round(r)} GB` : `${r} GB`;
        } else {
            return isExact === false ? `${Math.round(value)} MB` : `${value} MB`;
        }
    };

    const generateDefaultStatisticTitle = () => {
        if (!statisticConfig.contentType || statisticConfig.contentType === "total") {
            return t("my_storage.total");
        } else if (statisticConfig.contentType === "left-per-total") {
            return t("my_storage.left");
        } else {
            return "_";
        }
    };

    const generateDefaultStatisticValue = () => {
        if (!statisticConfig.contentType || statisticConfig.contentType === "total") {
            return total
                ? generateValueWithUnit(total, true)
                : generateValueWithUnit(
                      data.reduce((r, d) => r + d.value, 0),
                      true
                  );
        } else if (statisticConfig.contentType === "left-per-total") {
            let l = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].name === "space_left") {
                    l = Math.round(data[i].value);
                    break;
                }
            }
            const t = total
                ? generateValueWithUnit(total, true)
                : generateValueWithUnit(
                      data.reduce((r, d) => r + d.value, 0),
                      true
                  );
            return `${l} / ${t}`;
        } else {
            return "_";
        }
    };

    // Chart data:
    const propsColor = dataColors;
    const propsLabel = dataLabels;
    const propsData = data;

    // Chart config:
    const config = {
        // Plot Container:
        appendPadding: plotContainer.appendPadding || 0,
        width: 200,
        height: 350,

        // Data Mapping:
        data: propsData,
        angleField: "value",
        colorField: "name",

        // Geometry Style:
        radius: 1,
        innerRadius: 0.65,
        color: (params) => {
            if (params.name) {
                return propsColor[params.name] || "";
            }
        },
        pieStyle: {
            // stroke: "#000",
            strokeOpacity: 0,
            cursor: "pointer",
        },

        // Plot Components:
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}",
            style: {
                fill: "#000",
                textAlign: "center",
                fontSize: 15,
                fontWeight: "500",
                opacity: 0,
            },
            autoRotate: false,
        },
        legend: {
            layout: "vertical",
            position: "bottom",
            marker: (params) => {
                return {
                    symbol: "square",
                    style: {
                        lineWidth: 1,
                        r: 10,
                    },
                    spacing: 10,
                };
            },
            itemName: {
                formatter: (text, item, index) => {
                    return propsLabel[text] || text;
                },
                style: {
                    fill: "#000",
                    fontSize: 18,
                    fontWeight: "400",
                    lineHeight: 25,
                },
            },
            itemValue: {
                alignRight: true,
                formatter: (text, item, index) => {
                    return propsData[index].value !== undefined ? generateValueWithUnit(propsData[index].value) : "";
                },
                style: {
                    fill: "#000",
                    fontSize: 18,
                    fontWeight: "700",
                    lineHeight: 25,
                },
            },
            flipPage: false,
            itemWidth: 200,
            maxItemWidth: 250,
        },
        statistic: {
            title: {
                offsetY: -4,
                // content: t("my_storage.total")
                customHtml: (container, view, datum) => {
                    const text = datum ? t(`my_storage.${datum.name}`) : generateDefaultStatisticTitle();
                    return `
                        <span style="line-height: 30px;">${text}</span>
                    `;
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                // content: statisticConfig.content || "",
                customHtml: (container, view, datum, data) => {
                    const text = datum ? generateValueWithUnit(datum.value, true) : generateDefaultStatisticValue();
                    return text;
                },
            },
        },

        // Plot Interactions:
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
            {
                type: "pie-statistic-active",
            },
        ],
    };

    return (
        <div className="disk-usage-statistics">
            <div className={"disk-usage-chart-wrapper donut-plot-with-statistics"}>
                <Pie {...config} />
            </div>
        </div>
    );
}

export default React.memo(DiskUsageStat);
