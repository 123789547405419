import { Checkbox, Col, Collapse, Pagination, Row, Spin } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { cls } from "src/utils/helpers";

import CustomizeRenderEmpty from "../CustomizeRenderEmpty";
import "./MobileTable.scss";

const MobileTable = ({
    loading = false,
    dataSource = [],
    columns = [],
    headers = [],
    pagination = {
        current: 1,
        total: 0,
        pageSize: 0,
    },
    onChange = (page) => {},
    height = undefined,
    rowSelection = undefined,
    rowKey = "id",
    showTotal = false,
    className = undefined,
    mobileHeight,
}) => {
    const { t } = useTranslation();
    const headerColumns = useMemo(() => {
        const _columns = columns.filter((c) => headers.includes(c.key));
        return _columns.sort((a2, a1) => headers.indexOf(a2.key) - headers.indexOf(a1.key));
    }, [columns, headers]);

    const bodyColumns = useMemo(() => {
        return columns.filter((c) => !headers.includes(c.key));
    }, [columns, headers]);

    const ref = useRef();

    const [activeKey, setActiveKey] = useState([]);

    const renderColumnRow = (column, row, index) => {
        if (column && row) {
            let dataIndex = column.dataIndex;
            let value = row;
            if (dataIndex) {
                if (dataIndex instanceof Array) {
                    for (let key of dataIndex) {
                        value = value?.[key] || undefined;
                    }
                } else if (typeof dataIndex === "string") {
                    value = row[dataIndex];
                }
            }
            if (typeof column.render === "function") {
                value = column.render(value, row, index);
            }

            return value;
        }
    };

    const handleOnChange = (page) => {
        onChange(page);
    };

    useEffect(() => {
        setActiveKey([]);
    }, [dataSource]);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollTop = 0;
        }
    }, [pagination?.current]);

    const getRowKey = (row, index) => {
        if (typeof rowKey === "string") {
            return row?.[rowKey];
        } else if (typeof row === "function") {
            return row.call(this, row, index);
        }
        return index;
    };

    const useRowSelection = useMemo(() => {
        if (!rowSelection) {
            return false;
        }
        return true;
    }, [rowSelection]);

    const _dataSource = useMemo(() => {
        if (pagination && pagination.pageSize < dataSource.length) {
            const start = (pagination.current - 1) * pagination.pageSize;
            return [...dataSource].slice(start, start + pagination.pageSize);
        }
        return [...dataSource];
    }, [dataSource, pagination]);

    function getSelectedKeys(keys) {
        const arr = [];
        _dataSource.forEach((row, index) => {
            const id = getRowKey(row, index);
            if (keys?.includes(id)) {
                arr.push(id);
            }
        });
        return arr;
    }

    function getSelectedRows(keys) {
        const arr = [];
        _dataSource.forEach((row, index) => {
            const id = getRowKey(row, index);
            if (keys?.includes(id)) {
                arr.push(row);
            }
        });
        return arr;
    }

    const selectedRowKeys = useMemo(() => {
        if (!rowSelection) {
            return [];
        }
        const keys = Array.isArray(rowSelection.selectedRowKeys) ? rowSelection.selectedRowKeys : [];
        return getSelectedKeys(keys);
    }, [rowSelection, _dataSource]);

    const rowSelectionProps = (row) => {
        if (typeof rowSelection?.getCheckboxProps === "function") {
            return rowSelection?.getCheckboxProps(row);
        }
        return {};
    };

    const handleChangeSelectedRowKeys = (key, checked) => {
        if (rowSelection?.onChange && typeof rowSelection?.onChange === "function") {
            const keys = checked ? [...selectedRowKeys, key] : selectedRowKeys.filter((id) => id != key);
            const items = getSelectedRows(keys);
            rowSelection?.onChange(keys, items, { type: "single" });
        }
    };

    const renderTitle = (title) => {
        if (typeof title === "function") {
            return title();
        }
        return title;
    };

    return (
        <div className={cls(className, "mobile-table-list")}>
            <Spin spinning={loading}>
                <div className="mobile-table-list-items" ref={ref}>
                    <Collapse
                        expandIconPosition="end"
                        style={{
                            border: "none",
                            backgroundColor: "inherit",
                            height,
                            overflow: "unset",
                        }}
                        activeKey={activeKey}
                        onChange={(keys) => setActiveKey(keys)}
                    >
                        {_dataSource.map((row, x) => {
                            const key = getRowKey(row, x);
                            return (
                                <Collapse.Panel
                                    className="mobile-table-list-item"
                                    key={key}
                                    header={
                                        <div className="d-flex justify-space-between">
                                            <div>
                                                {headerColumns.map((column, y) => (
                                                    <div className="mobile-table-row" key={y}>
                                                        <b>{renderTitle(column.mobileTitle || column.title)}</b>
                                                        <span className="mobile-table-space">:</span>
                                                        {renderColumnRow(column, row, x)}
                                                    </div>
                                                ))}
                                            </div>
                                            {useRowSelection && (
                                                <div
                                                    className="d-flex mr-5px"
                                                    style={{ alignItems: "center" }}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <Checkbox
                                                        {...rowSelectionProps(row)}
                                                        checked={selectedRowKeys.includes(key)}
                                                        onChange={(e) =>
                                                            handleChangeSelectedRowKeys(key, e.target.checked)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    }
                                    collapsible={bodyColumns.length === 0 ? "disabled" : undefined}
                                >
                                    {bodyColumns.map((column, y) => (
                                        <div className="mobile-table-row" key={y}>
                                            <b>{renderTitle(column.mobileTitle || column.title)}</b>
                                            <span className="mobile-table-space">:</span>
                                            {renderColumnRow(column, row, x)}
                                        </div>
                                    ))}
                                </Collapse.Panel>
                            );
                        })}
                        {_dataSource.length === 0 && <CustomizeRenderEmpty />}
                    </Collapse>
                </div>

                {pagination?.total > 0 && (
                    <Row justify="space-between" className="mt-15px">
                        <Col span={24}>
                            <div className="mobile-table-pagination flex flex-center">
                                {/* <MobilePagination pagination={pagination} onChange={handleOnChange} /> */}
                                <Pagination {...pagination} responsive />
                            </div>
                        </Col>
                        {showTotal && (
                            <Col className="mt-10px line-32px">
                                <b children={`${t("total_records")}: ${pagination.total}`} />
                            </Col>
                        )}
                    </Row>
                )}
            </Spin>
        </div>
    );
};

export default MobileTable;
