import React from "react";
import "./Button.scss";

const Button = (props) => {
    const { icon = null, title = "", className = "", onClick = () => {} } = props;

    return (
        <button className={`course-btn${className ? " " + className : ""}`} onClick={onClick}>
            {icon && icon}
            <span className="button-title">{title}</span>
        </button>
    );
};

const OutlineButton = (props) => {
    const { icon = null, title = "", className = "", onClick = () => {} } = props;

    return (
        <button className={`course-btn outline-btn${className ? " " + className : ""}`} onClick={onClick}>
            {icon && icon}
            <span className="button-title">{title}</span>
        </button>
    );
};

export default Button;
export { OutlineButton };
