import { useState } from "react";
import { Vertify } from "@alex_xu/react-slider-vertify";
import { t } from "i18next";
import { Checkbox, Dropdown } from "antd";
import "./Captcha.scss";

function Captcha({ visible = true, readOnly, value, onChange, ...rest }) {
    const [isCaptchaOpen, setIsCaptchaOpen] = useState(false); // Values: true, false.

    const handleChange = (status) => {
        onChange?.(status);
    };

    const handleSuccess = () => {
        handleChange(true);
        setTimeout(() => {
            setIsCaptchaOpen(false);
        }, 200);
    };

    const handleFail = () => {
        handleChange(undefined);
    };

    const handleRefresh = () => {
        handleChange(undefined);
    };

    const handleCheck = (e) => {
        if (readOnly) {
            return;
        }
        setIsCaptchaOpen(!isCaptchaOpen);
    };

    return (
        <div className="captcha-with-vertify">
            <div className={`captcha-input${readOnly ? " readonly" : ""}`}>
                <Dropdown
                    arrow
                    visible={isCaptchaOpen}
                    overlay={
                        <div className="captcha-box captcha-box-of-dropdown">
                            <Vertify
                                width={320}
                                height={160}
                                visible={true}
                                onSuccess={handleSuccess}
                                onFail={handleFail}
                                onRefresh={handleRefresh}
                            />
                        </div>
                    }
                    overlayClassName="captcha-with-vertify-dropdown"
                >
                    <Checkbox checked={value || false} onChange={handleCheck}>
                        {t("exam_checkin.human_verification")}
                    </Checkbox>
                </Dropdown>
            </div>
        </div>
    );
}

export default Captcha;
