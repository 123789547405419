import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import { TXT_RPLC_ANS } from "src/modules/containers/QuestionDetail/containers/DetailMathTextDropdown/config";

import QuestionContentDetail from "../ContentDetail";
import ResultAnswer from "../components/ResultAnswer";
import "./MathTextDropdown.scss";

function MathTextDropdown({
    // Question & answers:
    question = "" /** @param {string} question */,
    correct_answer /** @param {string[]} correct_answer */,
    answered /** @param {string[]} answered */,
}) {
    const { t } = useTranslation();
    const [providedAnswers, setProvidedAnswers] = useState([]);

    const getAnsResultStatus = (ansCorrect, ansSelected) => {
        return (ansCorrect === ansSelected) === true ? "correct" : "incorrect";
    };

    /**
     * Side effects:
     */
    useEffect(() => {
        // Preparation:
        const _answer = correct_answer || [];
        const _answered = answered || [];
        // Data:
        const _answerList = _answer
            .map((item, itemIndex) => {
                const operandArr = item.split(" ");
                const operandArrAnswered = _answered.length > itemIndex ? _answered[itemIndex].split(" ") : null;
                if (operandArr.length === 5 && operandArr[2] === "=") {
                    return {
                        lhs_operand: {
                            value: `${operandArr[0]}`,
                            unit: `${operandArr[1]}`,
                        },
                        rhs_operand: {
                            value_correct: `${operandArr[3]}`,
                            value_answered:
                                operandArrAnswered?.length === 5 &&
                                operandArrAnswered[2] === "=" &&
                                operandArrAnswered[3] !== TXT_RPLC_ANS
                                    ? `${operandArrAnswered[3]}`
                                    : null,
                            unit: `${operandArr[4]}`,
                        },
                    };
                } else {
                    return null;
                }
            })
            .filter((item) => !!item);
        // Update values:
        setProvidedAnswers(_answerList);
    }, []);

    /**
     * Rendering:
     */
    return (
        <div className={`q-mathtextdropdown`}>
            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={true}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            <div className="q-content-section">
                <div className="view-mathtextdropdown">
                    {providedAnswers.map((item, itemIndex) => {
                        return (
                            <div key={`mathunit-${itemIndex}`} className="view-unitcv">
                                {/* Left-hand side: */}
                                <HTMLDisplayer exprString={item?.lhs_operand?.value || "_"} />
                                <HTMLDisplayer exprString={item?.lhs_operand?.unit || "_"} />

                                {/* Equal character: */}
                                <span>=</span>

                                {/* Right-hand side: */}
                                <ResultAnswer
                                    letter={itemIndex + 1}
                                    resultStatus={getAnsResultStatus(
                                        item?.rhs_operand?.value_correct,
                                        item?.rhs_operand?.value_answered
                                    )}
                                >
                                    <HTMLDisplayer exprString={item?.rhs_operand?.value_answered || "_"} />
                                </ResultAnswer>
                                <HTMLDisplayer exprString={item?.rhs_operand?.unit || "_"} />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="q-content-section">
                <h3>{t("score.correct_answers")}</h3>
                <div className="qc_correct-answers">
                    {providedAnswers.map((item, itemIndex) => {
                        return (
                            <div key={`lbl-${itemIndex}`} className="correct-answer-item">
                                <ResultAnswer letter={itemIndex + 1}>
                                    <HTMLDisplayer exprString={item?.rhs_operand?.value_correct || "_"} />
                                </ResultAnswer>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default MathTextDropdown;
