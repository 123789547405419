import { Alert, Dropdown, Spin, Table, Tag } from "antd";
import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getGeneralProgress } from "src/api/containers/statistic";
import ArrowCompareIcon from "src/assets/images/arrow-compare-icon.svg";
import { ReactComponent as FilterIcon } from "src/assets/images/filter-icon.svg";
import { useFetch } from "src/hooks";
import useMediaQuery from "src/hooks/useMediaQuery";
import Breadcrumb from "src/modules/components/Breadcrumb";
import Button from "src/modules/components/Button";
import { setCurrentPage } from "src/reducers/general";
import { getColorForPercentChart } from "src/utils/helpers";
import FilterMenu from "./components/FilterMenu";
import "./GeneralLearningProgress.scss";

const GeneralLearningProgress = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [
        loadingGeneralProgress,
        dataGeneralProgress,
        paginationGeneralProgress,
        fetchGeneralProgress,
        refetchGeneralProgress,
    ] = useFetch({ page: 1, slug: "" }, getGeneralProgress);

    const [searchParams, setSearchParams] = useSearchParams();
    const class_id = searchParams.get("class");
    const level_id = searchParams.get("level");
    const language_id = searchParams.get("lang");

    // const aaa = searchParams.getAll();
    // console.log(aaa);

    const tableRef = useRef(null);
    const currMedia = useMediaQuery(["(max-width: 576px)"]);
    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: true,
        filterParams: [],
    });

    const renderArrow = (value) => {
        switch (value) {
            case "bigger":
                return <img src={ArrowCompareIcon} className="bigger" />;
            case "smaller":
                return <img src={ArrowCompareIcon} className="smaller" />;
            case "equal":
                return <img src={ArrowCompareIcon} className="equal" />;
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            if (paramName === "is_class") {
                return !(paramName === item.name || item.name === "course_id");
            }
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const columns = useMemo(() => {
        const result = [
            {
                title: t("report.student_name"),
                dataIndex: "studentName",
                align: "center",
                width: "auto",
                fixed: currMedia.breakpointValue === 576 ? undefined : "left",
            },
            {
                title: t("report.class"),
                dataIndex: "class",
                align: "center",
                width: "auto",
                fixed: currMedia.breakpointValue === 576 ? undefined : "left",
            },
            {
                title: t("report.exam"),
                dataIndex: "tests",
                align: "center",
                width: "auto",
                children: dataGeneralProgress?.assignment_names?.map((assignment) => {
                    return {
                        title: <div className="table-txt-limited-lines lim-2-lines">{assignment?.name}</div>,
                        dataIndex: ["tests", `test${assignment?._id}`],
                        width: "auto",
                        render: (value) => {
                            const color = getColorForPercentChart(value?.score);
                            return (
                                // <Tooltip placement="top" title={value?.name}>
                                <div className="reportTable_scoreBar" style={{ backgroundColor: color }}>
                                    {value?.score && value?.score + "%"}
                                </div>
                                // </Tooltip>
                            );
                        },
                    };
                }),
            },
        ];

        // Handle hidden score column.
        if (dataGeneralProgress?.max_count_assignment >= 2) {
            result.splice(2, 0, {
                title: t("report.score"),
                dataIndex: "score",
                align: "center",
                width: "auto",
                fixed: currMedia.breakpointValue === 576 ? undefined : "left",
                render: (value) => {
                    const colorItem1 = getColorForPercentChart(value[0]);
                    const colorItem2 = getColorForPercentChart(value[1]);
                    let typeArrow = "";

                    if (value[0] < value[1]) {
                        typeArrow = "bigger";
                    } else if (value[0] > value[1]) {
                        typeArrow = "smaller";
                    } else if (value[0] === value[1]) {
                        typeArrow = "equal";
                    }

                    const heightItem1 = (value[0] / (value[0] + value[1])) * 100;
                    const heightItem2 = (value[1] / (value[0] + value[1])) * 100;

                    return (
                        <div className="reportTable_scoreCompare">
                            <div className="reportTable_scoreBox">
                                <div className="reportTable_scoreItem">
                                    <div
                                        className="reportTable_scoreItemContent"
                                        style={{
                                            backgroundColor: colorItem1,
                                            height: `${heightItem1}%`,
                                        }}
                                    ></div>
                                </div>
                                {value[1] !== null && value[1] >= 0 && (
                                    <div className="reportTable_scoreItem">
                                        <div
                                            className="reportTable_scoreItemContent"
                                            style={{
                                                backgroundColor: colorItem2,
                                                height: `${heightItem2}%`,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            </div>

                            {value[1] !== null && value[1] >= 0 && (
                                <div className="reportTable_arrow">{renderArrow(typeArrow)}</div>
                            )}
                        </div>
                    );
                },
            });
        }

        return result;
    }, [dataGeneralProgress, currMedia]);

    useEffect(() => {
        if (dataGeneralProgress?.student) {
            tableRef.current = document.querySelector(".table-general-learning-report .ant-table-content");
            const thArr = tableRef.current.querySelectorAll(".ant-table-thead tr:first-child th:nth-child(-n+3)");

            if (tableRef.current) {
                const handleResizeTable = () => {
                    let widthTaked = 0;
                    for (let i = 0; i < thArr.length; i++) {
                        widthTaked += thArr[i].offsetWidth;
                    }
                    tableRef.current.style.setProperty("--scrollbar-mrleft", `${widthTaked}px`);
                };
                const handleScrollTable = (evt) => {
                    evt.preventDefault();
                    tableRef.current.scrollLeft += evt.deltaY;
                };
                // Event handler:
                window.addEventListener("resize", handleResizeTable); // To set margin-left for table's scrollbar.
                tableRef.current.addEventListener("wheel", handleScrollTable, { passive: false }); // Horizontal scrolling.
                // Call event handler for the first time:
                handleResizeTable();
                // Cleanup:
                return () => {
                    window.removeEventListener("resize", handleResizeTable);
                    tableRef.current.removeEventListener("wheel", handleScrollTable);
                };
            }
        }
    }, [dataGeneralProgress]);

    useEffect(() => {
        if (class_id) {
        }
    }, [class_id]);

    useEffect(() => {
        dispatch(setCurrentPage("learning_progress"));
    }, []);

    const data = useMemo(() => {
        const result = dataGeneralProgress?.student?.map((student) => {
            const tests = {};
            student?.records_without_history.forEach((record, index) => {
                tests[`test${record.assignment_id}`] = {
                    score: record.score_percent,
                    name: record.assignment.name,
                };
            });

            return {
                key: student?._id,
                studentName: student?.name || student?.username,
                class: student?.class?.name,
                score: [
                    student?.evaluate_assignment?.score_percent_first,
                    student?.evaluate_assignment?.score_percent_end,
                ],
                tests,
            };
        });

        return result;
    }, [dataGeneralProgress]);

    return (
        <div className="generalLearningProgress_wrapper">
            <div className="generalLearningProgress_header">
                <Breadcrumb
                    isBack
                    routes={[
                        {
                            name: t("report.general_learning_progress"),
                        },
                    ]}
                />
            </div>
            <div className="generalLearningProgress_box">
                <div className="generalLearningProgress_filter">
                    <div className="tags">
                        {filterSettings.filterParams?.length > 0 &&
                            filterSettings.filterParams.map((fKey, i) => {
                                if (fKey.name == "is_unit") {
                                    const course = filterSettings.filterParams.find((data, i) => {
                                        return data.name == "is_class" && data.value == "0"; // là khóa học
                                    });
                                    if (course) return null;
                                }

                                return (
                                    <Tag
                                        className="tag_item_inner"
                                        key={`filter-key${i}`}
                                        closable
                                        onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                    >
                                        {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`}
                                    </Tag>
                                );
                            })}
                    </div>
                    <div className="PersonalLearningProgressFilter_wrapper">
                        <div className="PersonalLearningProgressFilter_inner">
                            <div className="filterButton">
                                <Dropdown
                                    visible={filterSettings.isFilterMenuVisible}
                                    overlay={
                                        <FilterMenu
                                            isTeacher={true}
                                            filterParams={filterSettings.filterParams}
                                            setFilterSettings={setFilterSettings}
                                            handleFetch={fetchGeneralProgress}
                                            handleCloseFilterMenu={(newFilterParams) =>
                                                setFilterSettings({
                                                    ...filterSettings,
                                                    isFilterMenuVisible: false,
                                                    ...(newFilterParams && { filterParams: newFilterParams }),
                                                })
                                            }
                                            class_id={class_id}
                                            language_id={language_id}
                                            level_id={level_id}
                                        />
                                    }
                                    overlayClassName="progress-filter"
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    onVisibleChange={(val) =>
                                        setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                    }
                                >
                                    <Button
                                        type="primary"
                                        title={t("report.filter")}
                                        icon={<FilterIcon />}
                                        className="tags_button"
                                    />
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                {loadingGeneralProgress && <Spin tip={`${t("shared.loading")}...`}></Spin>}
                {!loadingGeneralProgress && (
                    <>
                        {dataGeneralProgress?.student ? (
                            <>
                                {
                                    // count evaluate assignment
                                    dataGeneralProgress?.count_evaluate_assignment && (
                                        <>
                                            <div className="generalLearningProgress_dashboard">
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content info">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_assignment?.good}
                                                        </div>{" "}
                                                        <div className="arrowCompare">{renderArrow("bigger")}</div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content warning">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_assignment?.equal}
                                                        </div>{" "}
                                                        <div className="arrowCompare ">{renderArrow("equal")}</div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content danger">
                                                        <div>{dataGeneralProgress?.count_evaluate_assignment?.bad}</div>{" "}
                                                        <div className="arrowCompare">{renderArrow("smaller")}</div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content disable">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_assignment?.unknown}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                                {
                                    // count evaluate assignment
                                    dataGeneralProgress?.count_evaluate_one_assignment && (
                                        <>
                                            <div className="generalLearningProgress_dashboard">
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content very_good">
                                                        <div>
                                                            {
                                                                dataGeneralProgress?.count_evaluate_one_assignment
                                                                    ?.very_good
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content good">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_one_assignment?.good}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content medium">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_one_assignment?.medium}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content bad">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_one_assignment?.bad}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dashboard_item">
                                                    <div className="dashboard_content failed">
                                                        <div>
                                                            {dataGeneralProgress?.count_evaluate_one_assignment?.failed}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                                <div className="generalLearningProgress_table">
                                    <Table
                                        className="app-table table-general-learning-report"
                                        columns={columns}
                                        dataSource={data}
                                        pagination={{
                                            pageSize: 9,
                                            position: ["bottomCenter"],
                                        }}
                                        scroll={{ x: "auto" }}
                                    />
                                </div>
                            </>
                        ) : (
                            <Alert
                                description={t("report.message_general_data_not_found")}
                                type="warning"
                                style={{ marginTop: 20 }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default GeneralLearningProgress;
