import { Radio, Space } from "antd";
import { t } from "i18next";
import QuestionContentDetail from "../components/ContentDetail";
import "./MultipleChoiceBoolean.scss";

const MultipleChoiceBoolean = (props) => {
    const {
        isReadonly,
        id,
        question = "",
        defaultAnswer, // [boolean]
        onChange,
    } = props;

    const handleChooseAnswer = (e) => {
        // Only pass the necessary values
        // Notice: Don't use rest operator
        const newDataAfterChange = {
            answered: [e.target.value.toString()],
        };

        if (onChange && onChange instanceof Function) {
            onChange(newDataAfterChange);
        }
    };

    return (
        <div className="q-multiple-choice-boolean">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                    }}
                />
            </div>

            <div className="view-multiple-choice">
                <Radio.Group
                    disabled={isReadonly}
                    defaultValue={defaultAnswer?.[0] && defaultAnswer?.[0] === "true"}
                    onChange={handleChooseAnswer}
                >
                    <Space direction="vertical">
                        {/* 
                            Multiple choice boolean always have answer is 
                            true and false because I didn't use a prop answer
                         */}
                        <Radio className="app-radio" value={true}>
                            {" "}
                            {t("q_multi_boolean.true")}
                        </Radio>
                        <Radio className="app-radio" value={false}>
                            {" "}
                            {t("q_multi_boolean.false")}
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>
        </div>
    );
};

export default MultipleChoiceBoolean;
