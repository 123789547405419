import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import router, { useRouting } from "src/utils/router";
import { useMediaQuery } from "src/hooks";

const Authed = () => {
    const { user } = useSelector((state) => state.auth);
    const { generate } = useRouting();
    const currMedia = useMediaQuery(["(max-width: 991px)"]);

    if (user?.role === "guest") {
        let lang = localStorage.getItem("lang");

        if (lang && lang === "en") {
            window.location.href = "/en/choose-role";
        } else window.location.href = "/vi/choose-role";
    }

    return (
        <>
            {
                <Routes>
                    {router.collection.items.map((item, idx) => {
                        let Layout = item.layout;
                        let Element = item.element;
                        if (item.role.includes(user?.role)) {
                            const hidelayout = currMedia.breakpointValue === 991 && item.hideLayoutOnMobile;

                            return (
                                <Route
                                    key={idx}
                                    path={item.path}
                                    element={
                                        Layout && !hidelayout ? (
                                            <Layout>
                                                <Element />
                                            </Layout>
                                        ) : (
                                            <Element />
                                        )
                                    }
                                />
                            );
                        }
                    })}
                    <Route path="*" element={<Navigate to={generate("not-found")} />} />
                </Routes>
            }
        </>
    );
};

export default Authed;
