import { Checkbox, Col, Form, InputNumber, notification, Row, Typography } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { findChildQuestion, findPassageQuestion, findQuestion } from "src/api/containers/question";
import Editor from "src/modules/components/Editor";
import MyInput from "./components/MyInput";
import parser from "src/utils/parser";
import configEditor from "src/utils/configEditor";
import "./DetailFillInTheBlanksInput.scss";

const editorConfigQuestionFB = configEditor.question_fillblanks();

function DetailFillInTheBlanksInput(props) {
    const {
        form,
        formStates,
        currQType,
        // Question id:
        questionId,
        questionParentId,
        // Fetch:
        extraQuestionFetchParams,
        // Fill in the form:
        isFormFilled,
        onFillFormQuestionData,
        handleFormAction,
    } = props;

    const [detailData, setDetailData] = useState({
        questionData: undefined,
        questionFormData: undefined,
    });

    const handleInputQuestion = (value) => {
        const blankHtmlElemList = value.match(/<fillbox(.*?)<\/fillbox>/g) || [];
        const blankNumberList = blankHtmlElemList.map((str) => str.match(/(id=")(.*?)(")/)[2] || 0);
        let newListCorrectAnswer = form.getFieldValue("correct_answer") || [];
        const correctAnswers = form.getFieldValue("correct_answer") || [];

        // Xoá những ID không tồn tại trong đáp án thực
        for (let i = 0; i < correctAnswers.length; i++) {
            const isExist = blankNumberList.includes(String(correctAnswers[i]?.id));
            if (!isExist && correctAnswers[i]?.id >= 0) {
                newListCorrectAnswer = newListCorrectAnswer.filter((item) => item.id !== correctAnswers[i]?.id);
            }
        }

        // Thêm input từ blank question
        blankHtmlElemList.forEach((blkEle, index) => {
            const id = +blkEle.match(/(id=")(.*?)(")/)[2];

            const isExistId = Boolean(newListCorrectAnswer.filter((answr) => answr.id === id).length);

            if (!isExistId) {
                // Kiểm tra xem vị trí ô trống cũ có hay không
                newListCorrectAnswer.splice(index, 0, {
                    text: "",
                    id,
                });
            }
        });
        form.setFieldsValue({ correct_answer: newListCorrectAnswer });
    };

    const fillFormQuestionData = (questionData, questionFormData, questionExtraData) => {
        if (onFillFormQuestionData instanceof Function) {
            onFillFormQuestionData(questionData, questionFormData, questionExtraData);
        }
    };

    const prepareFormDataForAction = (isAllFieldsValid) => {
        if (isAllFieldsValid) {
            // Preparation:
            const _formData = form.getFieldsValue();

            const newAnswer = _formData.correct_answer?.map((aswr) => parser.compactMathField(aswr.text));

            // Data:
            const _questionData = {
                type: currQType,
                answer: newAnswer || [],
                is_require_uppercase: _formData.is_require_uppercase,
            };
            const _questionDataPreview = {
                // Basic fields:
                question: _formData.question,
                answer: newAnswer || [],
                correct_answer: newAnswer,
                is_require_uppercase: _formData.is_require_uppercase,
                // Special fields:
                type: _questionData.type,
            };
            // Start action:
            return { _questionData, _questionDataPreview };
        } else {
            return false;
        }
    };

    if (Object.keys(handleFormAction || {}).includes("current")) {
        handleFormAction.current = (formAction) => {
            if (formAction) {
                switch (formAction) {
                    case "go-preview":
                    case "save-draft": {
                        return prepareFormDataForAction(true);
                    }
                    case "save-child":
                    case "save": {
                        return new Promise((resolve, reject) => {
                            form.validateFields()
                                .then((fValues) => {
                                    resolve(prepareFormDataForAction(true));
                                })
                                .catch((errorInfo) => {
                                    const correctAnswers = form.getFieldValue("correct_answer") || [];
                                    const isHaveEmptyAnswer = correctAnswers?.some((aswr) => aswr.text === "");

                                    if (!correctAnswers?.length) {
                                        return notification.warning({
                                            message: t("message.require_fill_in_the_blank"),
                                        });
                                    }

                                    if (isHaveEmptyAnswer) {
                                        return notification.warning({
                                            message: t("message.warning_answer_empty"),
                                        });
                                    }

                                    notification.warning({
                                        message: t("message.warning_missing_fields"),
                                    });
                                    resolve(prepareFormDataForAction(false));
                                });
                        });
                    }
                    default: {
                        return prepareFormDataForAction(false);
                    }
                }
            }
        };
    }

    useEffect(() => {
        // Fetching:
        // - Case 1. Nếu đã có sẵn dữ liệu câu hỏi (biết nó được truyền vào từ component cha), thì lấy cái có sẵn.
        // - Case 2. Nếu không có sẵn dữ liệu câu hỏi:
        //   - 2.1. Nếu là câu hỏi độc lập, thì gọi API lấy thông tin.
        //   - 2.2. Nếu là câu hỏi phụ thuộc câu hỏi cha, thì gọi API lấy thông tin dựa theo id của câu hỏi cha.
        if (formStates.formQuestionData) {
            //
        } else {
            if (!questionParentId) {
                if (questionId) {
                    findQuestion(questionId, currQType, extraQuestionFetchParams).then((res) => {
                        if (res.status) {
                            const qData = res.data;

                            // Get all empty input field (blank space):
                            const blankHtmlElemList = (qData?.question || "").match(/<fillbox(.*?)<\/fillbox>/g) || [];
                            const blankNumberList = blankHtmlElemList.map((str) => str.match(/(id=")(.*?)(")/)[2] || 0);

                            const correctAnswer = qData?.answer?.map((aswr, i) => ({
                                text: aswr,
                                id: +blankNumberList[i],
                            }));

                            const questionFormData = {
                                is_publish: qData?.is_publish || false,
                                is_public: qData?.is_public || false,
                                level_id: qData?.level?.id || undefined,
                                tag_ids: qData?.tags?.length ? qData?.tags?.map((tag) => tag.id) : [],
                                language_id: qData?.language?.id || undefined,
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "answer", "is_require_uppercase".
                                correct_answer: correctAnswer || [],
                                is_require_uppercase: qData?.is_require_uppercase || false,
                            };
                            setDetailData({
                                questionData: qData,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                }
            } else {
                if (questionId) {
                    Promise.all([findPassageQuestion(questionParentId), findChildQuestion(questionId)]).then((res) => {
                        if (res[0].status && res[1].status) {
                            const qData = res[1].data;

                            // Get all empty input field (blank space):
                            const blankHtmlElemList = (qData?.question || "").match(/<fillbox(.*?)<\/fillbox>/g) || [];
                            const blankNumberList = blankHtmlElemList.map((str) => str.match(/(id=")(.*?)(")/)[2] || 0);

                            const newCorrectAnswer = qData?.answer?.map((aswr, index) => ({
                                text: aswr,
                                id: +blankNumberList[index] || -Math.random(),
                            }));

                            const questionFormData = {
                                question: qData?.question || "",
                                score: qData?.score || 0,
                                // Special fields: "answer", "is_require_uppercase".
                                correct_answer: newCorrectAnswer || [],
                                is_require_uppercase: qData?.is_require_uppercase || false,
                            };
                            setDetailData({
                                questionData: res[0].data,
                                questionFormData: questionFormData,
                            });
                        }
                    });
                } else {
                    findPassageQuestion(questionParentId).then((res) => {
                        if (res.status) {
                            setDetailData({
                                questionData: res.data,
                                questionFormData: undefined,
                            });
                        }
                    });
                }
            }
        }
    }, [formStates.formQuestionData, questionId]);

    useEffect(() => {
        // Nếu thỏa mãn 3 điều kiện sau thì đưa dữ liệu câu hỏi vào form:
        // - Là lần đầu mà component này đưa dữ liệu câu hỏi vào form của component cha.
        // - Form tại component cha đã sẵn sàng (có tag list và level list).
        // - Component này đã có dữ liệu câu hỏi.
        if (isFormFilled === "ready" && detailData.questionData?.id) {
            fillFormQuestionData(detailData.questionData, detailData.questionFormData);
        }
    }, [isFormFilled, detailData]);

    return (
        <div className="question-detail fill-blanks-input">
            <div>
                <Row className="question-editor">
                    <Typography className="question-editor-title">
                        {t("q.question")}
                        <div className="question-editor-hint-text">
                            {" ("}
                            {t("question.hint_create_blanks_1")}
                            <span className="hint-text highlighted"> {t("editor.create_blank")} </span>
                            {t("question.hint_create_blanks_2")}
                            {")"}
                        </div>
                    </Typography>
                    <Form.Item
                        name="question"
                        rules={[
                            {
                                required: true,
                                message: t("message.required"),
                            },
                        ]}
                    >
                        <Editor config={editorConfigQuestionFB} onChange={handleInputQuestion}></Editor>
                    </Form.Item>
                </Row>

                <Row className="question-formitems question-setcheck">
                    <Col>
                        <Form.Item name="is_require_uppercase" valuePropName="checked">
                            <Checkbox className="app-checkbox">
                                {t("question_fillblanks.is_require_uppercase")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="question-formitems">
                    <Col>
                        <Form.Item
                            name="score"
                            label={t("question.score")}
                            rules={[
                                {
                                    required: true,
                                    message: t("message.required"),
                                },
                            ]}
                        >
                            <InputNumber
                                className="app-input"
                                min={0}
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="question-setting set-answers">
                    <Typography className="question-answer-title">{t("question.answer")}</Typography>
                    <div className="question-answer-list">
                        <Form.List name="correct_answer">
                            {(fields, { add, remove }, { errors }) => {
                                const correctAnswers = form.getFieldsValue().correct_answer;
                                return (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...field}
                                                className="answer-input"
                                                key={correctAnswers[field.name]?.id}
                                                label={`(${index + 1})`}
                                                validateTrigger={["onChange", "onBlur"]}
                                            >
                                                <MyInput placeholder={t("question.answer")} />
                                            </Form.Item>
                                        ))}
                                    </>
                                );
                            }}
                        </Form.List>
                    </div>
                </Row>
            </div>
        </div>
    );
}

export default DetailFillInTheBlanksInput;
