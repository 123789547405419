import { notification } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { checkIfUserIsActive } from "src/api/containers/auth";
import { getTargetURLQueryString } from "src/modules/auth/helpers";
import LoadingScreen from "src/modules/components/LoadingScreen";
import LoginV2 from "src/modules/containers/LoginV2";
import { useRouting } from "src/utils/router";

const ForgetPasswordFirstTimePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { generate } = useRouting();

    const [isLoading, setIsLoading] = useState(true);
    const [username, setUsername] = useState("");

    const lang = useSelector((state) => state.general.lang);

    const checkIsUserActive = (username) => {
        checkIfUserIsActive(username).then(({ status, data, message }) => {
            if (status) {
                if (data?.is_active === true) {
                    navigate(`/${lang}/login${getTargetURLQueryString()}`);
                    notification.success({
                        message: `${t("login.account_activated")}, ${t("login.please_login_to_mark")}!`,
                    });
                } else if (data?.is_active === false) {
                    setIsLoading(false);
                }
            } else {
                navigate(`/${lang}/login`);
                notification.error({ message: message || t("login.invalid_path") });
            }
        });
        return false;
    };

    useEffect(() => {
        /**
         * Check if user account is active or not.
         */
        if (location.search) {
            let _username = new URLSearchParams(location.search).get("username");
            if (_username) {
                setUsername(_username);
                checkIsUserActive(_username);
            } else {
                navigate(`/${lang}/login`);
                notification.error({ message: t("login.invalid_path") });
            }
        } else {
            navigate(`/${lang}/login`);
            notification.error({ message: t("login.invalid_path") });
        }
    }, []);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return <LoginV2 username={username} firstResetPW={true} />;
};

export default ForgetPasswordFirstTimePage;
