import { useTranslation } from "react-i18next";
import "./RenderTableHistories.scss";

const RenderTableHistories = ({ component1, component2, title1, title2, type }) => {
    const { t } = useTranslation();

    return (
        <div className="table-histories-render">
            {type == 2 ? (
                <table className="table-answer">
                    <tr>
                        <td>{title1 || t("history.old_value")}</td>
                        <td>{component1}</td>
                    </tr>
                    <tr>
                        <td>{title2 || t("history.new_value")}</td>
                        <td>{component2}</td>
                    </tr>
                </table>
            ) : (
                <table className="table-answer">
                    <tr>
                        <th>{title1 || t("history.old_value")}</th>
                        <th>{title2 || t("history.new_value")}</th>
                    </tr>
                    <tr>
                        <td>{component1}</td>
                        <td>{component2}</td>
                    </tr>
                </table>
            )}
        </div>
    );
};

export default RenderTableHistories;
