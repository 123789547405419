const characterMap = {
    // 1. Comma:
    comma: {
        default: ",", // "U+002C".
        forms: [
            "،", // "U+060C".
            "、", // "U+3001".
            "︐", // "U+FE10".
            "︑", // "U+FE11".
            "﹐", // "U+FE50".
            "﹑", // "U+FE51".
            "，", // "U+FF0C".
            "､", // "U+FF64".
            "·", // U+00B7.
            "∘", // U+2218.
        ],
    },
    // 2. Dot:
    full_stop: {
        default: ".", // "U+002E".
        forms: [
            "。", // "U+3002".
            "။", // "U+104B".
            "།", // "U+0F0D".
            "༎", // "U+0F0E".
            "᰻", // "U+1C3B".
            "᰼", // "U+1C3C".
            "۔", // "U+06D4".
            "．", // "U+FF0E".
            "⸼", // "U+2E3C".
        ],
    },
    // 3. Quotation mark:
    single_quote: {
        default: "'", // "U+0027".
        forms: [
            "‘", // "U+2018" (default left).
            "’", // "U+2019" (default right).
            "ʼ", // "U+02BC".
            "՚", // "U+055A".
            "‚", // U+201A.
            "⸲", // "U+2E32".
            "⸴", // "U+2E34".
            "⹁", // "U+2E41".
        ],
    },
    double_quote: {
        default: '"', // "U+0022".
        forms: [
            "“", // "U+201C" (default left).
            "”", // "U+201D" (default right).
        ],
    },
};

/**
 * Convert unicode to character.
 * @param {string} unicode Unicode.
 * @returns string.
 */
export const convertUnicodeToChar = (unicode) => {
    const unicodePoint = unicode.replace(/U\+/g, "");
    const charCode = parseInt(unicodePoint, 16);
    return String.fromCodePoint(charCode);
};

/**
 * Convert unicode list to character list.
 * @param {Array} unicodeArray Unicode list.
 * @returns string list.
 */
export const convertUnicodeListToCharList = (unicodeArray = []) => {
    const charList = [];
    unicodeArray.forEach((item) => {
        charList.push(convertUnicodeToChar(item));
    });
    return charList;
};

/**
 * Replace characters with their corresponding default characters as specified in the characterMap.
 * @param {string} string A string.
 * @returns string.
 */
export const convertStringWithDefaultCharacters = (string) => {
    if (!string) {
        return string;
    }
    let result = string;
    Object.keys(characterMap).forEach((item) => {
        const { default: defaultForm, forms } = characterMap[item];
        const regexPattern = `${forms.join("|")}`;
        const regex = new RegExp(regexPattern, "g");
        result = result.replace(regex, defaultForm);
    });
    return result;
};

/**
 * ?
 * @param {*} str
 * @returns
 */
export const encodebase64Utf8 = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
};

/**
 * ?
 * @param {*} str
 * @returns
 */
export const decodebase64Utf8 = (str) => {
    if (!str) return JSON.stringify({});
    return decodeURIComponent(escape(window.atob(str)));
};
