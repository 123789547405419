import { t } from "i18next";
import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import JSXGraphBoard from "src/modules/components/JSXGraphBoard";

import QuestionContentDetail from "../components/ContentDetail";
import "./Graph.scss";

function Graph({
    isReadonly = false,
    // Question & answers:
    question = "" /** @param {string} question */,
    answer /** @param {string[]} answer - list of provided answers. */,
    defaultAnswer /** @param {string[]} defaultAnswered - list of selected answers */,
    // Event handlers:
    onChange,
}) {
    const refGraphId = useRef(`qpanel-jsxgraph-container-${uuidv4()}`);

    const handleChange = (_answersSelected) => {
        if (onChange instanceof Function) {
            onChange({ answered: _answersSelected });
        }
    };

    return (
        <div className="q-graph">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question,
                        answerList: [],
                    }}
                />
            </div>

            <div className="q-content-section">
                <div className="view-graph">
                    <JSXGraphBoard
                        graphMode="solving"
                        graphType="default"
                        id={refGraphId.current}
                        width="100%"
                        height="500px"
                        value={defaultAnswer ? structuredClone(defaultAnswer) : answer ? structuredClone(answer) : []}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default Graph;
