import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { Col, Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import "./FilterMenu.scss";

const FilterMenu = ({
    formLayout = "horizontal",
    formFields = {},
    formFieldGroups = [],
    formData = {},
    willResetForm = false,
    // Event handlers:
    onSubmit,
    onCancel,
    className,
}) => {
    const [form] = useForm();

    const handleCancel = () => {
        form.resetFields();
        form.setFieldsValue(formData);
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmit = () => {
        const newFormData = form.getFieldsValue();
        if (onSubmit instanceof Function) {
            const _result = onSubmit({ ...formData, ...newFormData });
            if (_result === false) {
                form.resetFields();
                form.setFieldsValue(formData);
            }
        }
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(formData);
    }, [formData]);

    useEffect(() => {
        if (willResetForm === true) {
            form.resetFields();
            form.setFieldsValue(formData);
        }
    }, [willResetForm]);

    // Class names:
    const classFormLayout = `form-${formLayout}`;

    return (
        <Form
            form={form}
            className={`form form-full-label filter-menu layout-grid ${classFormLayout} ${className ? className : ""}`}
            layout={formLayout}
        >
            {formFieldGroups.map((fGroup, fGroupIndex) => {
                const _fgFormFields = fGroup.formFields;
                const _fgRowProps = fGroup.rowProps || {};
                // Group info:
                if (_fgFormFields?.length) {
                    return (
                        <Row key={`fGroup${fGroupIndex}`} {..._fgRowProps}>
                            {_fgFormFields.map((fItem, fItemIndex) => {
                                const _fiData = formFields[fItem];
                                if (_fiData) {
                                    const _fiLabel = _fiData.label;
                                    const _fiElement = _fiData.element;
                                    const _fiColProps = _fiData.colProps;
                                    // Group item info:
                                    if (React.isValidElement(_fiElement)) {
                                        return (
                                            <Col key={`formItem${fItemIndex}`} {..._fiColProps}>
                                                <Form.Item name={fItem} label={_fiLabel}>
                                                    {_fiElement}
                                                </Form.Item>
                                            </Col>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Row>
                    );
                }
                return null;
            })}

            <div className="filter-menu-footer">
                <CustomButton
                    type="grey"
                    title={t("shared.cancel")}
                    icon={<SvgCross />}
                    onClick={handleCancel}
                ></CustomButton>
                <CustomButton
                    htmlType="submit"
                    type="primary"
                    title={t("shared.apply")}
                    icon={<SvgTick />}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </Form>
    );
};

const formField = PropTypes.shape({
    label: PropTypes.any.isRequired,
    element: PropTypes.any.isRequired,
    colProps: PropTypes.object, // Ant Design Col.
});

const formFieldGroup = PropTypes.shape({
    formFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowProps: PropTypes.object, // Ant Design Row.
});

FilterMenu.propTypes = {
    formLayout: PropTypes.oneOf(["horizontal", "vertical"]),
    formFields: PropTypes.objectOf(formField).isRequired,
    formFieldGroups: PropTypes.arrayOf(formFieldGroup).isRequired,
    formData: PropTypes.object,
    willResetForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

export default FilterMenu;
