import { Dropdown } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { accessRoles } from "src/api/containers/auth";
import { ReactComponent as SvgCaretDown } from "src/assets/images/app-icons/app-caret-down.svg";
import Logo from "src/assets/images/app-logo/Logo-horizontal.png";
import { useAuth } from "src/modules/auth";

import LanguageMenu from "../LanguageMenu";
import "./BasicHeader.scss";

const BasicHeader = ({ className = "" }) => {
    const { t } = useTranslation();

    const auth = useAuth();

    const { lang } = useSelector((state) => state.general);

    const navigate = useNavigate();

    const handleGoHome = () => {
        if (!auth.user) {
            navigate(`/${lang}${accessRoles.guest.homeRoute}`);
        }
    };

    return (
        <div className={`basic-header-wrapper${className ? " " + className : ""}`}>
            <div className="app-header">
                <div className="header-left-section">
                    <span className="header-logo-wrapper with-title" onClick={handleGoHome}>
                        <img src={Logo} alt="e-learning-logo" />
                    </span>
                </div>
                <div className="header-right-section">
                    <div className="act-menu">
                        <span className="act-menu-item language-setting">
                            <span>{t("language.language")}:</span>
                            <Dropdown trigger={["click"]} placement="bottomRight" overlay={<LanguageMenu />}>
                                <span>
                                    {t(`language.${lang}`)} <SvgCaretDown />
                                </span>
                            </Dropdown>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicHeader;
