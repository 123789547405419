import { Alert, Modal, Spin } from "antd";
import html2canvas from "html2canvas";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPersonalProgress } from "src/api/containers/statistic";
import { useFetch } from "src/hooks";
import ClassificationChart from "src/modules/components/Chart/ClassificationChart";
import PercentColumnPlot from "src/modules/components/PercentColumnPlot";
import UserBoard from "src/modules/components/UserBoard";
import ReportTable from "src/modules/containers/PersonalLearningProgress/components/ReportTable";
import PNDigitalBase64Img from "src/utils/base64img";
import { exportPersonalLearningToPdfText } from "src/utils/helpers";

const ModalPersonalLearningProgress = (props) => {
    const {
        title,
        visible,
        viewProgressStudent,
        viewProgressCourse,
        viewProgressLevel,
        viewProgressIsUnit,
        onCancel,
    } = props;
    const navigate = useNavigate();
    const [
        loadingPersonalProgress,
        dataPersonalProgress,
        paginationPersonalProgress,
        fetchPersonalProgress,
        refetchPersonalProgress,
    ] = useFetch({ page: 1, slug: "" }, getPersonalProgress);

    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        filterParams: (() => {
            const result = [];

            Object.keys(viewProgressCourse).length &&
                result.push({
                    name: "course_id",
                    value: viewProgressCourse.id,
                    labelName: t("report.course"),
                    labelValue: viewProgressCourse.name,
                }),
                Object.keys(viewProgressStudent).length &&
                    result.push({
                        name: "student_id",
                        value: viewProgressStudent.id,
                        labelName: t("report.student"),
                        labelValue: viewProgressStudent.name,
                    }),
                Object.keys(viewProgressLevel).length &&
                    result.push({
                        name: "level_id",
                        value: viewProgressLevel.id,
                        labelName: t("report.level"),
                        labelValue: viewProgressLevel.name,
                    });

            result.push({
                name: "is_unit",
                value: viewProgressIsUnit,
                labelName: t("report.type_of_excercise"),
                labelValue: viewProgressIsUnit ? t("report.unit") : t("report.assignment"),
            });

            return result;
        })(),
    });

    const typeOfData = useMemo(() => {
        if (dataPersonalProgress?.arr_name_course?.length) {
            return "course";
        }

        if (dataPersonalProgress?.arr_name_class?.length) {
            return "class";
        }

        return "unknown";
    }, [dataPersonalProgress?.arr_name_class, dataPersonalProgress?.arr_name_course]);

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const handleExportPersonalLearningToPdf = async () => {
        const logoOrganization = document.createElement("div");
        logoOrganization.classList.add("pdf__logo");

        // base64 img
        logoOrganization.innerHTML = `<img src="${PNDigitalBase64Img}" /> <span style="font-size: 28px; font-weight: bold">Phương Nam Digital</span>`;
        document.body.appendChild(logoOrganization);

        const canvas = await html2canvas(logoOrganization);
        const logo = canvas.toDataURL();

        exportPersonalLearningToPdfText(dataPersonalProgress, dataPersonalProgress?.name, logo, {
            name_of_course: dataPersonalProgress?.arr_name_course,
            name_of_class: dataPersonalProgress?.arr_name_class,
            type: typeOfData,
        });

        logoOrganization.remove();
    };

    const handleGoBack = (e) => {
        if (e) {
            e.preventDefault();
        }

        navigate(-1);
    };

    useEffect(() => {
        fetchPersonalProgress(
            {
                // course_id:
                //     filterSettings.filterParams.find((filterParam) => filterParam.name === "course_id")?.value || "",
                student_id:
                    filterSettings.filterParams.find((filterParam) => filterParam.name === "student_id")?.value || null,
                // level_id:
                //     filterSettings.filterParams.find((filterParam) => filterParam.name === "level_id")?.value || null,
                // is_unit:
                //     filterSettings.filterParams.find((filterParam) => filterParam.name === "is_unit")?.value || null,

                is_unit: viewProgressIsUnit,
            },
            true
        );
    }, []);

    return (
        <Modal
            className="add-exam-question-modal modal-mark-assignment-table"
            title={title}
            visible={visible}
            footer={null}
            onCancel={onCancel}
            width={"80%"}
            bodyStyle={{ padding: "0px" }}
            destroyOnClose
        >
            <div className="reportStudent_wrapper">
                <div className="reportStudent_box m-0">
                    <div className="reportStudent_filter">
                        {/* <div className="tags">
                            {filterSettings.filterParams?.length > 0 &&
                                filterSettings.filterParams.map((fKey, i) => {
                                    return (
                                        <Tag
                                            className="tag_item_inner"
                                            key={`filter-key${i}`}
                                            closable
                                            onClose={(e) => handleRemoveFilterParam(e, fKey.name)}
                                        >
                                            {`${fKey.labelName ? fKey.labelName + ": " : ""}${fKey.labelValue || ""}`}
                                        </Tag>
                                    );
                                })}
                        </div>
                        <div className="reportStudent_filter_inner">
                            <div className="filterButton">
                                <Dropdown
                                    visible={filterSettings.isFilterMenuVisible}
                                    overlay={
                                        <FilterMenu
                                            isModal
                                            isTeacher={true}
                                            filterParams={filterSettings.filterParams}
                                            handleFetch={fetchPersonalProgress}
                                            // dataSource={}
                                            handleCloseFilterMenu={(newFilterParams) =>
                                                setFilterSettings({
                                                    ...filterSettings,
                                                    isFilterMenuVisible: false,
                                                    ...(newFilterParams && { filterParams: newFilterParams }),
                                                })
                                            }
                                            willFormBeCleared={!filterSettings.isFilterMenuVisible}
                                        />
                                    }
                                    overlayClassName="progress-filter"
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    onVisibleChange={(val) =>
                                        setFilterSettings({ ...filterSettings, isFilterMenuVisible: val })
                                    }
                                >
                                    <Button
                                        type="primary"
                                        title={t("report.filter")}
                                        className="tags_button"
                                        icon={FilterIcon}
                                    />
                                </Dropdown>
                            </div>
                        </div> */}
                    </div>
                    {loadingPersonalProgress && <Spin tip={`${t("shared.loading")}...`}></Spin>}
                    {!loadingPersonalProgress && (
                        <>
                            {dataPersonalProgress?._id ? (
                                <>
                                    <div className="reportStudent_item">
                                        <UserBoard
                                            fullName={dataPersonalProgress?.name || ""}
                                            nameOfCourse={dataPersonalProgress?.arr_name_course}
                                            nameOfClass={dataPersonalProgress?.arr_name_class}
                                            avatar={dataPersonalProgress?.avatar}
                                            exportPdf={handleExportPersonalLearningToPdf}
                                        />
                                        <PercentColumnPlot
                                            label={t("report.level_completion_score")}
                                            data={dataPersonalProgress?.chart_for_assignment_level}
                                        />
                                    </div>
                                    <div className="reportStudent_item" style={{ height: "815px" }}>
                                        <div className="reportStudent_chartPie_wrapper">
                                            <div className="reportStudent_chartPie">
                                                <div className="reportStudent_title">
                                                    {t("report.rate_tests_taken")}
                                                </div>
                                                <ClassificationChart
                                                    data={dataPersonalProgress?.chart_for_assignment}
                                                ></ClassificationChart>
                                            </div>
                                        </div>
                                        <div className="reportStudent_table_wrapper">
                                            <div id="pdf__tableWithPagination" className="reportStudent_table_inner">
                                                <ReportTable
                                                    isPagination={true}
                                                    data={dataPersonalProgress?.records_without_history}
                                                    type={typeOfData}
                                                />
                                            </div>
                                            <div
                                                id="pdf__tableWithoutPagination"
                                                style={{ display: "none" }}
                                                className="reportStudent_table_inner"
                                            >
                                                <ReportTable
                                                    isPagination={false}
                                                    data={dataPersonalProgress?.records_without_history}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div style={{ padding: 20, minHeight: 200 }}>
                                    <Alert
                                        type="warning"
                                        message={t("shared.no_data")}
                                        showIcon
                                        style={{ borderRadius: 5, fontSize: 16 }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(ModalPersonalLearningProgress);
