import React, { useEffect } from "react";
import { t } from "i18next";
import { Col, Form, Row } from "antd";
import { LaptopOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useValues } from "src/hooks";
import InternetConnectionCheck from "./InternetConnectionCheck";
import WebcamCheck from "./WebcamCheck";
import MicrophoneCheck from "./MicrophoneCheck";
import MultipleDisplaysCheck from "./MultipleDisplaysCheck";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import "./SystemCheck.scss";

function SystemCheck({ onChange }) {
    const [form] = useForm();
    const form_check_internet = Form.useWatch("check_internet", form);
    const form_check_multiple_displays = Form.useWatch("check_multiple_displays", form);
    const form_check_webcam = Form.useWatch("check_webcam", form);
    const form_check_microphone = Form.useWatch("check_microphone", form);

    const [values, setValues] = useValues({
        checkingAuto: true, // Values: "check_internet", "check_multiple_displays", "check_webcam", "check_microphone", true, false.
        checked: false, // Values: true, false.
    });

    const handleSubmit = () => {
        if (!form_check_internet || !form_check_multiple_displays || !form_check_webcam || !form_check_microphone) {
            return;
        }

        if (onChange instanceof Function) {
            onChange(true);
        }
    };

    const handleStartNextAutoChecking = () => {
        const whatToCheck = ["check_internet", "check_multiple_displays", "check_webcam", "check_microphone"];
        if (values.checkingAuto) {
            if (values.checkingAuto === true) {
                setValues({ checkingAuto: whatToCheck[0] });
            } else {
                const currentWhat = values.checkingAuto;
                const currentWhatIndex = whatToCheck.indexOf(currentWhat);
                if (currentWhatIndex >= 0 && currentWhatIndex + 1 < whatToCheck.length) {
                    setValues({ checkingAuto: whatToCheck[currentWhatIndex + 1] });
                } else {
                    setValues({ checkingAuto: false });
                }
            }
        }
    };

    useEffect(() => {
        const newValues = {};

        if (values.checkingAuto) {
            const isEnabledNextAutoCheck =
                values.checkingAuto === true ||
                (values.checkingAuto === "check_internet" && [true, false].includes(form_check_internet)) ||
                (values.checkingAuto === "check_multiple_displays" &&
                    [true, false].includes(form_check_multiple_displays)) ||
                (values.checkingAuto === "check_webcam" && [true, false].includes(form_check_webcam)) ||
                (values.checkingAuto === "check_microphone" && [true, false].includes(form_check_microphone));

            if (isEnabledNextAutoCheck) {
                setTimeout(() => {
                    handleStartNextAutoChecking();
                }, 1000);
            }
        }

        if (
            form_check_internet === true &&
            form_check_multiple_displays === true &&
            form_check_webcam === true &&
            form_check_microphone === true
        ) {
            newValues.checked = true;
        } else {
            newValues.checked = false;
        }

        setValues(newValues);
    }, [form_check_internet, form_check_multiple_displays, form_check_webcam, form_check_microphone]);

    return (
        <div className="system-check">
            <div className="system-check-title">
                <div className="title-icon">
                    <LaptopOutlined />
                </div>
                <div className="title-content">
                    <div className="content-main">{t("exam_checkin.system_check")}</div>
                    <div className="content-descr">{t("exam_checkin.descr_system_check")}</div>
                </div>
            </div>
            <div className="system-check-form">
                <Form form={form} layout="vertical" className="app-form type-primary has-rd" onFinish={handleSubmit}>
                    <div className="system-check-content">
                        <Row gutter={[32, 64]}>
                            <Col xs={24} sm={24} xl={6}>
                                <div
                                    className={`system-check-item${
                                        values.checkingAuto === "check_internet" ? " focused" : ""
                                    }`}
                                >
                                    <Form.Item
                                        // label={`${t("exam_checkin.internet_connection_check")}`}
                                        name="check_internet"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("exam_checkin.input_internet_connection_check"),
                                            },
                                        ]}
                                    >
                                        <InternetConnectionCheck
                                            disabled={values.checkingAuto && values.checkingAuto !== "check_internet"}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} xl={6}>
                                <div
                                    className={`system-check-item${
                                        values.checkingAuto === "check_multiple_displays" ? " focused" : ""
                                    }`}
                                >
                                    <Form.Item
                                        // label={`${t("exam_checkin.multiple_displays_check")}`}
                                        name="check_multiple_displays"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("exam_checkin.input_multiple_displays_check"),
                                            },
                                        ]}
                                    >
                                        <MultipleDisplaysCheck
                                            disabled={
                                                values.checkingAuto && values.checkingAuto !== "check_multiple_displays"
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} xl={6}>
                                <div
                                    className={`system-check-item${
                                        values.checkingAuto === "check_webcam" ? " focused" : ""
                                    }`}
                                >
                                    <Form.Item
                                        // label={`${t("exam_checkin.webcam_check")}`}
                                        name="check_webcam"
                                        rules={[{ required: true, message: t("exam_checkin.input_webcam_check") }]}
                                    >
                                        <WebcamCheck
                                            disabled={values.checkingAuto && values.checkingAuto !== "check_webcam"}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} xl={6}>
                                <div
                                    className={`system-check-item${
                                        values.checkingAuto === "check_microphone" ? " focused" : ""
                                    }`}
                                >
                                    <Form.Item
                                        // label={`${t("exam_checkin.microphone_check")}`}
                                        name="check_microphone"
                                        rules={[{ required: true, message: t("exam_checkin.input_microphone_check") }]}
                                    >
                                        <MicrophoneCheck
                                            disabled={values.checkingAuto && values.checkingAuto !== "check_microphone"}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form-actions">
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<SvgTick />}
                            title={t("shared.confirm")}
                            isDisabled={!values.checked}
                        ></CustomButton>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default SystemCheck;
