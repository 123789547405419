import { Col, DatePicker, Form, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchCoursesForStudent, fetchStudentCoursesByLevel } from "src/api/containers/course";
import { fetchLanguages } from "src/api/containers/language";
import { fetchLevels } from "src/api/containers/levelH";
import { fetchUnitByCourse } from "src/api/containers/unit";
import TrashIcon from "src/assets/images/icon-question-delete.svg";
import RocketIcon from "src/assets/images/rocket.svg";
import useValues from "src/hooks/useValues";
import Button from "src/modules/components/Button";

import "./FilterMenu.scss";

const FilterMenu = (props) => {
    const { course, filterParams, setFilterParams = () => {}, handleCloseFilterMenu = () => {} } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const course_id = searchParams.get("course");
    const { user } = useSelector((state) => state.auth);

    const [form] = useForm();
    const [values, setValues] = useValues({
        levels: [],
        courses: [],
        units: [],
        sort: "",
        languages: [],
        isFetchingLevels: true,
        isFetchingUnits: false,
        isFetchingCourses: false,
        isFetchingLanguages: true,
    });

    const handleClearModal = () => {
        form.resetFields();
        setFilterParams(form.getFieldsValue());
    };

    // const handleChangeLevel = (levelId) => {
    //     if (levelId) {
    //         fetchUnitAndCourseByLevel(levelId);
    //     } else {
    //         setValues({
    //             courses: [],
    //             units: [],
    //         });
    //         form.resetFields();
    //         setFilterParams(form.getFieldsValue());
    //     }
    // };

    const fetchUnitAndCourseByLevel = (levelId) => {
        setValues({
            isFetchingCourses: true,
            isFetchingUnits: true,
        });
        fetchStudentCoursesByLevel(levelId).then((res) => {
            setValues({
                courses: res.data,
                isFetchingCourses: false,
            });
            // fetchUnitByCourse(res.data.id).then((res) => {
            //     setValues({
            //         units: res.data,
            //         isFetchingUnits: false,
            //     });
            // });
        });
    };

    const fetchCourses = async (language_id) => {
        try {
            setValues({ isFetchingCourses: true });

            let fetchParamsCourses = { noPagination: true, is_class: 0, language_id: language_id };
            const res = await fetchCoursesForStudent({ ...fetchParamsCourses });
            const { status = false, data = [] } = res;
            setValues({
                courses: data,
                isFetchingCourses: false,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const fetchAllLanguages = async () => {
        try {
            setValues({ isFetchingLanguages: true });
            const res = await fetchLanguages({ noPagination: true });
            const { status = false, data = [] } = res;
            setValues({
                languages: data,
                isFetchingLanguages: false,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleChangeLanguage = async (language_id) => {
        fetchCourses(language_id);
    };

    const handleFetchUnitByCourse = (course_id) => {
        setValues({
            isFetchingUnits: true,
        });

        fetchUnitByCourse(course_id).then((res) => {
            setValues({
                units: res.data,
                isFetchingUnits: false,
            });
        });
    };

    const handleChangeCourse = (courseId) => {
        if (courseId) {
            setValues({
                isFetchingUnits: true,
            });
            fetchUnitByCourse(courseId).then((res) => {
                setValues({
                    units: res.data,
                    isFetchingUnits: false,
                });
            });
        } else {
            form.setFieldsValue({ unit: null });
        }
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        setFilterParams(formData);
        if (formData.level || formData.unit || formData.courses || formData.language) {
        }
        handleCloseFilterMenu();
    };

    useEffect(() => {
        fetchAllLanguages();
    }, []);

    useEffect(() => {
        if (course_id) {
            handleFetchUnitByCourse(course_id);
        }
    }, [course_id]);

    // useEffect(() => {
    //     form.setFieldsValue({ level: filterParams.level, course: filterParams.course });
    //     if (filterParams.level && user?.role === "student") {
    //         fetchUnitAndCourseByLevel(filterParams.level);
    //     }
    // }, [filterParams?.level]);

    // useEffect(() => {
    //     fetchLevels({ noPagination: true }).then((res) => {
    //         setValues({
    //             levels: res.data,
    //             isFetchingLevels: false,
    //         });
    //     });
    // }, []);

    return (
        <div className="filter-gradebook_wrapper">
            <Form
                form={form}
                onFinish={handleSubmit}
                className="form form-full-label filter-gradebook_inner scroll_primary"
            >
                <Row>
                    {user?.role === "student" && !course && (
                        <>
                            <Col span={24}>
                                <Form.Item name="language" label={t("language.language")}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={handleChangeLanguage}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        className="filter-person_selector"
                                        placeholder={t("language.select_language")}
                                        loading={values.isFetchingLanguages}
                                        allowClear
                                    >
                                        {values?.languages?.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="course" label={t("gradebook.course")}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={handleChangeCourse}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        loading={values.isFetchingCourses}
                                        className="filter-person_selector"
                                        placeholder={t("gradebook.please_select_course")}
                                        allowClear
                                    >
                                        {values?.courses?.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item name="level" label={t("gradebook.level")}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={handleChangeLevel}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        className="filter-person_selector"
                                        placeholder={t("gradebook.please_select_level")}
                                        loading={values.isFetchingLevels}
                                        allowClear
                                    >
                                        {values?.levels?.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                        </>
                    )}

                    <Col span={24}>
                        <Form.Item name="unit" label={t("gradebook.unit")}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                loading={values.isFetchingUnits}
                                className="filter-person_selector"
                                placeholder={t("gradebook.please_select_unit")}
                                allowClear
                            >
                                {values?.units?.map((unit) => (
                                    <Select.Option key={unit.id} value={unit.id}>
                                        {unit.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="order" label={t("gradebook.sort")}>
                            <Select
                                className="filter-person_selector"
                                placeholder={t("gradebook.please_select_sort_type")}
                                allowClear
                            >
                                <Select.Option key="asc" value="asc">
                                    {t("gradebook.asc")}
                                </Select.Option>
                                <Select.Option key="desc" value="desc">
                                    {t("gradebook.desc")}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="filter_btn">
                    <Button
                        onClick={handleClearModal}
                        type="grey"
                        icon={TrashIcon}
                        title={t("gradebook.clear")}
                        className="responsive"
                    ></Button>
                    <Button
                        className="responsive"
                        htmlType="submit"
                        type="primary"
                        icon={RocketIcon}
                        title={t("gradebook.apply")}
                    ></Button>
                </Row>
            </Form>
        </div>
    );
};

export default FilterMenu;
