import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRouting } from "src/utils/router";
import CustomDropdown from "../Dropdown";
import "./GenerateSubNav.scss";

const GenerateSubnav = ({ navItem, subnavName, mode }) => {
    const { t } = useTranslation();
    const { generate } = useRouting();

    const NavIcon = navItem.icon;
    let items = [];
    const subMenu = navItem.subnav;
    items = subMenu.map((item) => {
        let a = item.name === subnavName ? "active" : "";
        const SubIcon = item.icon;
        return {
            key: `${item.name}_${a}`,
            title: t(`nav.${item.name}`),
            label: (
                <div className={`subnav-item ${a}`}>
                    <Link className={`subnav-link`} to={generate(item.routeName, item.routeParams || {})}>
                        <span>{SubIcon && <SubIcon />}</span>
                        {t(`nav.${item.name}`)}
                    </Link>
                </div>
            ),
        };
    });
    const menu = (
        <div className="subnav-dropdown-menu">
            <Menu items={items} />
        </div>
    );

    const dropdown = (
        <Dropdown overlay={menu} destroyPopupOnHide={true}>
            <a onClick={(e) => e?.stopPropagation()}>
                <Space>
                    <span className="nav-menu-item">
                        <span className="nav-icon">
                            <NavIcon />
                        </span>
                        <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                    </span>
                    <DownOutlined style={{ fontSize: "12px" }} />
                </Space>
            </a>
        </Dropdown>
    );

    const dropdown2 = (
        <Space>
            <span className="nav-menu-item">
                <span className="nav-icon">
                    <NavIcon />
                </span>
                <span className="nav-name">{t(`nav.${navItem.name}`)}</span>
                <CustomDropdown navItem={navItem} subnavName={subnavName} />
                <DownOutlined style={{ fontSize: "12px" }} />
            </span>
        </Space>
    );

    // if (mode == "vertical") {
    //     return dropdown2;
    // } else

    return dropdown;
};

export default GenerateSubnav;
