import api from "..";

// (ORGANIZATION INVITATION) User - teacher:
export const inviteUserToOrganization = (data) => {
    if (!data.email) {
        return new Promise((resolve, reject) => {
            reject({ status: false });
        }).catch(() => ({ status: false }));
    }

    return api
        .post("/organization/mail_invite_user", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const addUserToOrganization = (organizationId) => {
    return api
        .put(`/organization/${organizationId}/add_user_to_organization`)
        .then(({ status, message }) => ({ status, message: message }))
        .catch(() => ({ status: false }));
};

export const removeUserFromOrganization = (userId) => {
    return api
        .put(`/organization/${userId}/remove_user_organization`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
