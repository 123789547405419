import { Col, Empty, Row } from "antd";
import React from "react";
import GradebookItemMobile from "../GradebookItemMobile.js";
import { t } from "i18next";
import "./ListItemMobile.scss";

function ListItemMobile({ assignments = [], onLoadMore = () => {}, pagination, actions, studentActions }) {
    return (
        <div className="gradebook-list-item-mobile">
            <Row gutter={[20, 20]}>
                {assignments?.length > 0 ? (
                    <>
                        {assignments.map((item) => {
                            return (
                                <Col xs={24} sm={24} md={12} key={item.id}>
                                    <GradebookItemMobile
                                        item={item}
                                        actions={actions}
                                        studentActions={studentActions}
                                    />
                                </Col>
                            );
                        })}
                        {pagination?.current < pagination?.lastPage && (
                            <Col xs={24} sm={24} md={12}>
                                <div className="gradebook-item-mobile" onClick={onLoadMore}>
                                    <div className="gradebook-item-mobile_content">
                                        <div className="gradebook-item-loadmore">
                                            <span>{t("gradebook.loadmore")}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )}
                    </>
                ) : (
                    <Col xs={24}>
                        <Empty description={t("gradebook.empty_exam")} />
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default ListItemMobile;
