import api from "..";

export const getImageForSystemCheck = () => {
    return api
        .get("/verify/get_image")
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false }));
};

export const checkExamAccessCode = (code) => {
    const params = {
        code: code,
    };
    return api
        .get("/verify/assignment/check_validity", params)
        .then(({ status, assignment, message }) => ({ status, assignment, message }))
        .catch(() => ({ status: false }));
};

export const checkCandidateInformation = (data) => {
    // const _data = {
    //     username: data.username,
    //     name: data.name,
    //     personal_number: data.personal_number,
    //     device_id: data.device_id,
    //     code_verify: data.code_verify,
    //     files: data.files,
    // };

    return api
        .post("/verify/check_student_info", data)
        .then(({ status, token, message }) => ({ status, token, message }))
        .catch(() => ({ status: false }));
};

export const getCodeVerifyEmail = (email) => {
    const params = {
        username: email,
    };
    return api
        .post("/verify/send_code_verify", params)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const verifyCodeEmail = (email, code_verify, assignment_id) => {
    const params = {
        username: email,
        code_verify: code_verify,
        assignment_id,
    };
    return api
        .post("/verify/verify_code", params)
        .then(({ status, message, data }) => ({ status, message, data }))
        .catch(() => ({ status: false }));
};

export const checkStudentInfo = (data) => {
    return api
        .post("/verify/check_student_info", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const detectFaceSpoofing = (img) => {
    return api
        .post("/verify/detect_face_spoofing", { data: img })
        .then(({ status, message, data }) => ({ status, message, data }))
        .catch(() => ({ status: false }));
};

export const detectPersonNumber = (base64, lang = "en") => {
    return api
        .post("/verify/detect_person_number", { data_base64: base64, lang: lang })
        .then(({ status, message, data }) => ({ status, message, data }))
        .catch(() => {
            return { status: false };
        });
};

export const checkPersonNumber = (person_number) => {
    return api
        .post("/check_personal_number", { person_number })
        .then(({ status, message, data }) => ({ status, message, data }))
        .catch(() => ({ status: false }));
};
