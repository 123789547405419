import { Alert, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function AlertOnTop() {
    const alertOnTop = useSelector((state) => state.general?.alertOnTop);
    const { t } = useTranslation();

    const getAction = () => {
        switch (alertOnTop?.action) {
            case "reload_page":
                return window?.location?.reload();
            default:
                return;
        }
    };

    return alertOnTop ? (
        <Alert
            type={alertOnTop?.type || "warning"}
            message={alertOnTop?.message || t("an_error_occurred")}
            action={
                alertOnTop?.action ? (
                    <Button size="small" type="primary" shape="round" onClick={getAction}>
                        {t("Reload")}
                    </Button>
                ) : null
            }
            closable
            showIcon
        />
    ) : null;
}

export default AlertOnTop;
