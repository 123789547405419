import { useCallback, useEffect, useState } from "react";

/**
 * (Basic version)
 * Check if current viewport's width catch any one of the listed breakpoint.
 * @param {Array} mediaValues CSS breakpoints (mediafeatures).
 * @returns Object.
 */
// const useMediaQuery = (mediaValue = '(min-width: 576px)') => {
//     const [targetReached, setTargetReached] = useState('');

//     const updateTarget = useCallback((e) => {
//         if (e.matches) {
//             setTargetReached(e.media);
//         } else {
//             setTargetReached('');
//         }
//     }, []);

//     useEffect(() => {
//         if (typeof window !== 'undefined') {
//             const media = window.matchMedia(mediaValue);
//             media.addEventListener('change', updateTarget);

//             if (media.matches) {
//                 setTargetReached(true);
//             }

//             return () => {
//                 media.removeEventListener('change', updateTarget);
//             };
//         }
//     }, []);

//     return targetReached;
// }

/**
 * Check if current viewport's width catch any one of the listed breakpoint.
 * @param {Array} mediaValues CSS breakpoints (mediafeatures).
 * @returns Object.
 */
const useMediaQuery = (mediaValues = ["(min-width: 576px)"]) => {
    const [targetReached, setTargetReached] = useState({
        media: "",
        breakpointValue: -1,
    });

    const getBreakpointValue = useCallback((str = "") => {
        if (str.search(/\((.*?):\s[0-9.]+px\)/) === 0) {
            const r = str.match(/[0-9.]+/);
            return r[0] ? parseInt(r[0]) : -1;
        }
        return -1;
    }, []);

    const updateTarget = useCallback((e) => {
        if (e.matches) {
            setTargetReached({
                media: e.media || "",
                breakpointValue: getBreakpointValue(e.media || ""),
            });
        } else {
            setTargetReached({
                media: "",
                breakpointValue: -1,
            });
        }
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const arr = [];

            for (let i = 0; i < mediaValues.length; i++) {
                const media = window.matchMedia(mediaValues[i]);
                media.addEventListener("change", updateTarget);
                // Store media object to remove its event handler later.
                arr.push(media);
                // Check if the current screen size matches the breakpoint.
                if (media.matches) {
                    setTargetReached({
                        media: mediaValues[i],
                        breakpointValue: getBreakpointValue(mediaValues[i]),
                    });
                }
            }

            return () => {
                arr.forEach((media) => {
                    media.removeEventListener("change", updateTarget);
                });
            };
        }
    }, []);

    return targetReached;
};

export default useMediaQuery;
