import { generateConfigForQuestion } from "./question";

export const generateConfigForQuestionFB = () => {
    return {
        ...generateConfigForQuestion(),

        // Plugins, toolbar, menubar:
        plugins:
            "paste-options" +
            " lists advlist" +
            " image imagetools media file-manager" +
            " insertdatetime link anchor table charmap math codesample hr" +
            " searchreplace wordcount" +
            " directionality visualblocks visualchars" +
            " preview" +
            " nonbreaking pagebreak" +
            " quickbars help" +
            " input",
        toolbar:
            "undo redo" +
            " | searchreplace" +
            " | input" +
            " | bold italic underline strikethrough removeformat" +
            " | fontselect fontsizeselect formatselect lineheight" +
            " | alignleft aligncenter alignright alignjustify" +
            " | outdent indent" +
            " | bullist numlist" +
            " | subscript superscript" +
            " | forecolor backcolor" +
            " | paste-options" +
            " | image media file-manager" +
            " | link table charmap math",
    };
};
