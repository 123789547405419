module.exports = {
    // API:
    API_ENDPOINT: "https://api-beta.exam.vn/api",
    // API_ENDPOINT_TEST: "https://api-beta.exam.vn/api",

    // Authentication - upgradation:
    ALLOW_UPGRADE_ACCOUNT: true,
    UPGRADE_ACCOUNT_ALLOWED_METHODS: {
        /**
         * 1. contact:
         * If contact === true, open contact modal.
         * Otherwise, go to make-payment page.
         *
         * 2. is_multiple_at_planlist:
         * If is_multiple_at_planlist === true, the plan inside PLAN LIST (not elsewhere) can be purchased many times (in order to extend subscription).
         *
         * 3. upgradable_from_plans:
         * Is a list (an array) of plans that a user can upgrade from.
         * It means that the user can purchase the mentioned plan after they already purchased any of the plan of the `upgradable_from_plans` list.
         */
        plus: {
            contact: false,
            is_multiple_at_planlist: false,
            upgradable_from_plans: [],
        },
        premium: {
            contact: false,
            is_multiple_at_planlist: false,
            upgradable_from_plans: ["plus"],
        },
        enterprise: {
            contact: true,
            upgradable_from_plans: ["plus", "premium"],
        },
    },

    // Actions:
    ALLOW_ACTIONS: {
        question: {
            clone: false,
        },
        exam: {
            clone: false,
        },
    },

    ANTI_CHEATING_KEYS: {
        RECORD_AUDIO: "record_audio",
        RECORD_SCREEN_SHARE: "record_screen_share", // Check if screen sharing is running.
        EXTEND_MONITOR: "extend_monitor", // Check if multiple displays is running on Extended mode.
        DISABLE_COPY_PASTE: "disable_coppy_paste",
        ALERT_USER_LEAVE_PAGE: "alert_user_leave_page",
        DISABLE_SCREEN_SHRINKING: "screen_shrinking_disable",

        RESET_PERMISSION: {
            RESET_EXTEND_MONITOR: "reset_extend_monitor", // Can not grant window-managements permission.
            RESET_MICROPHONE: "reset_microphone", // Can not grant microphone permission.
            RESET_CAMERA: "reset_camera", // Can not grant webcam permission.
        },

        OPEN_TAB: "open_tab",
        EXIT_FULL_SCREEN: "exit_full_screen",
        OPEN_DEVTOOL: "open_devtool",
        // USER_LEAVE_PAGE: "user_leave_page",
        NOT_APPEAR_ON_CAMERA: "no_face_on_screen",
        MULTIPLE_PEOPLE_IN_CAMERA: "many_faces_on_screen",
    },

    EXAM_PROCTORING: {
        CHECK_FACES: {
            FACE_MATCHER_DISTANCE_THRESHOLD: 0.45,
        },
        DEV_TOOL: {
            IS_BLOCKED: true,
            BLOCK_RIGHT_CLICK: true,
            WAITING_TIME: 30, // đơn vị (giây), giá trị sẽ là số dương, hoặc null.
        },

        FACES_DETECT_REALTIME: {
            MODEL: "ssd_mobilenetv1", //  [tiny_face_detector,ssd_mobilenetv1]
            INPUT_SIZE: 320,
            SCORE_THRES_HOLD: 0.45,
        },
        WEBCAM_CAPTURE: {
            MIN_CONFIDENCE: 0.9,
            SCORE_THRES_HOLD: 0.9,
            INPUT_SIZE: 320,
            MODEL: "ssd_mobilenetv1", // [tiny_face_detector,ssd_mobilenetv1]
        },

        FACE_IN_ID_CARD: {
            MODEL: "ssd_mobilenetv1",
            MIN_CONFIDENCE: 0.42,
            INPUT_SIZE: 640,
            SCORE_THRES_HOLD: 0.42,
            DISTANCE_ALLOW: 0.42,
            MAX_FILE_SIZE: 4 * 1024 * 1024,
        },
    },

    //Feedback config
    FEEDBACK: {
        SPACE: 10,
        /**
         * false: user ko muốn feedback -> tắt modal đi thì ko show lại tại page đó nữa,
         * nếu sang trang khác hoặc reload thì vẫn có thể show
         */
        CAN_SHOW_AFTER_CLOSE: false,
    },
};
