import { removeAudioIdPlaying } from "src/reducers/general";
import { store } from "src/store";

export function pauseAudios() {
    const playingAudios = document.querySelectorAll("audio");
    for (var i = 0; i < playingAudios.length; i++) {
        if (!playingAudios[i].paused) {
            playingAudios[i].pause();
            playingAudios[i].currentTime = 0;
        }
    }
    store.dispatch(removeAudioIdPlaying());
}
