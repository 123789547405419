const methodMap = [
    [
        "requestFullscreen",
        "exitFullscreen",
        "fullscreenElement",
        "fullscreenEnabled",
        "fullscreenchange",
        "fullscreenerror",
    ],
    // New WebKit
    [
        "webkitRequestFullscreen",
        "webkitExitFullscreen",
        "webkitFullscreenElement",
        "webkitFullscreenEnabled",
        "webkitfullscreenchange",
        "webkitfullscreenerror",
    ],
    // Old WebKit
    [
        "webkitRequestFullScreen",
        "webkitCancelFullScreen",
        "webkitCurrentFullScreenElement",
        "webkitCancelFullScreen",
        "webkitfullscreenchange",
        "webkitfullscreenerror",
    ],
    [
        "mozRequestFullScreen",
        "mozCancelFullScreen",
        "mozFullScreenElement",
        "mozFullScreenEnabled",
        "mozfullscreenchange",
        "mozfullscreenerror",
    ],
    [
        "msRequestFullscreen",
        "msExitFullscreen",
        "msFullscreenElement",
        "msFullscreenEnabled",
        "MSFullscreenChange",
        "MSFullscreenError",
    ],
];

const nativeAPI = (() => {
    if (typeof document === "undefined") {
        return false;
    }

    const unprefixedMethods = methodMap[0];
    const returnValue = {};

    for (const methodList of methodMap) {
        const exitFullscreenMethod = methodList?.[1];
        if (exitFullscreenMethod in document) {
            for (const [index, method] of methodList.entries()) {
                returnValue[unprefixedMethods[index]] = method;
            }

            return returnValue;
        }
    }

    return false;
})();

const eventNameMap = {
    change: nativeAPI.fullscreenchange,
    error: nativeAPI.fullscreenerror,
};

let fullscreen_element = {
    request(element = document.documentElement, options) {
        return new Promise((resolve, reject) => {
            const onFullScreenEntered = () => {
                fullscreen_element.off("change", onFullScreenEntered);
                resolve();
            };

            fullscreen_element.on("change", onFullScreenEntered);

            const returnPromise = element[nativeAPI.requestFullscreen](options);

            if (returnPromise instanceof Promise) {
                returnPromise.then(onFullScreenEntered).catch(reject);
            }
        });
    },
    exit() {
        return new Promise((resolve, reject) => {
            if (!fullscreen_element.isFullscreen) {
                resolve();
                return;
            }

            const onFullScreenExit = () => {
                fullscreen_element.off("change", onFullScreenExit);
                resolve();
            };

            fullscreen_element.on("change", onFullScreenExit);

            const returnPromise = document[nativeAPI.exitFullscreen]();

            if (returnPromise instanceof Promise) {
                returnPromise.then(onFullScreenExit).catch(reject);
            }
        });
    },
    toggle(element, options, callback) {
        if (fullscreen_element.isFullscreen) {
            fullscreen_element.exit();
            callback?.();
        } else {
            fullscreen_element.request(element, options);
        }
        return;
    },
    onchange(callback) {
        fullscreen_element.on("change", callback);
    },
    onerror(callback) {
        fullscreen_element.on("error", callback);
    },
    on(event, callback) {
        const eventName = eventNameMap[event];
        if (eventName) {
            document.addEventListener(eventName, callback, false);
        }
    },
    off(event, callback) {
        const eventName = eventNameMap[event];
        if (eventName) {
            document.removeEventListener(eventName, callback, false);
        }
    },
    raw: nativeAPI,
};

Object.defineProperties(fullscreen_element, {
    isFullscreen: {
        get: () => Boolean(document[nativeAPI.fullscreenElement]),
    },
    element: {
        enumerable: true,
        get: () => document[nativeAPI.fullscreenElement] ?? undefined,
    },
    isEnabled: {
        enumerable: true,
        // Coerce to boolean in case of old WebKit.
        get: () => Boolean(document[nativeAPI.fullscreenEnabled]),
    },
});

if (!nativeAPI) {
    fullscreen_element = { isEnabled: false };
}

export default fullscreen_element;
