import { FileDoneOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { default as CustomButton } from "src/modules/components/Button";

const defaultFunction = () => {};

function ViewResultSelection({ onSelectViewResult = defaultFunction, onSelectViewViolation = defaultFunction }) {
    const { t } = useTranslation();
    return (
        <div className="view-result-selection">
            <div className="role-picker">
                <div className="role-info">
                    <div className="info-title">
                        <div className="info-icon">{<FileDoneOutlined />}</div>
                        <div className="info-iconlabel">{`${t("action.view")} ${t(
                            "course.assignment_result"
                        ).toLowerCase()}`}</div>
                    </div>
                </div>
                <div className="role-selections">
                    <CustomButton
                        type="ghost"
                        title={t(`${t("exam_result.your_assignment")}`)}
                        onClick={() => onSelectViewResult()}
                    ></CustomButton>
                    <CustomButton
                        type="ghost"
                        title={t("anti_cheating.video_record")}
                        onClick={() => onSelectViewViolation()}
                    ></CustomButton>
                </div>
            </div>
        </div>
    );
}

export default ViewResultSelection;
