import { ZwibblerDrawer } from "../ZwibblerDrawer";
import "./DrawingImage.scss";

const DrawingImage = ({ qestionData, isReadonly, onChange }) => {
    const qData = qestionData?.answer?.[0];

    return (
        <div className="drawing-image">
            <ZwibblerDrawer
                {...{
                    width: qData?.width,
                    height: qData?.height,
                    src: qData?.src,
                    defaultAnswer: qestionData?.defaultAnswer,
                }}
                isReadonly={isReadonly}
                onChange={onChange}
            />
        </div>
    );
};

export default DrawingImage;
