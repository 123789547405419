import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, message, notification } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";

import { addUsersToAssignment } from "src/api/containers/assignment";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";

import "./AssignmentUsers.scss";

const AssignmentUsers = ({ userRole, assignmentId, visible = true, className, onOk, onCancel, ...rest }) => {
    // Form:
    const [form] = Form.useForm();
    const [values, setValues] = useValues({
        loadingForAct: false,
    });

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmit = () => {
        let formData = form.getFieldsValue();
        if (!assignmentId) {
            return;
        }
        if (!formData.users?.length) {
            return;
        }

        setValues({ loadingForAct: "submit" });
        addUsersToAssignment("teacher", assignmentId, formData).then((res) => {
            setValues({ loadingForAct: false });
            if (res.status) {
                form.resetFields();
                if (onOk instanceof Function) {
                    onOk();
                }
                notification.success({
                    message: res.message || t("message.add_success"),
                });
            } else {
                notification.error({
                    message: res.message || t("message.add_error"),
                });
            }
        });
    };

    const classNameSuffix = {
        teacher: "teachers",
    };

    return (
        <Modal
            centered
            visible={visible}
            title={t(`assignment.add_${classNameSuffix[userRole]}`)}
            closeIcon={<SvgClose />}
            footer={null}
            className={
                "app-modal flexible-height maxw-520 type-primary modal-publicasgmtusers-detail" +
                (className ? ` ${className}` : "")
            }
            wrapClassName="modal-publicasgmtusers-detail-wrap"
            // maskStyle={{ zIndex: "1011" }}
            // onOk={handleOk}
            onCancel={handleCancel}
            {...rest}
        >
            <Form form={form} initialValues={{ users: [{}] }} onFinish={handleSubmit}>
                <Form.List name="users">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => {
                                return (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, "username"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("message.required"),
                                                },
                                                {
                                                    type: "email",
                                                    message: t("message.invalid_email"),
                                                },
                                            ]}
                                        >
                                            <Input className="app-input has-rd" placeholder="Email" />
                                        </Form.Item>
                                        <Form.Item {...restField} name={[name, "name"]}>
                                            <Input className="app-input has-rd" placeholder={t("profile.full_name")} />
                                        </Form.Item>

                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                );
                            })}

                            <Form.Item className="button_add-new">
                                <Button
                                    type="dashed"
                                    size="large"
                                    style={{ borderRadius: 10 }}
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    {t("shared.add_new")}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <div className="form-actions">
                    <CustomButton
                        type="grey"
                        icon={<SvgCross />}
                        title={t("shared.cancel")}
                        onClick={handleCancel}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<SvgTick />}
                        title={t("shared.submit")}
                        isDisabled={values.loadingForAct === "submit"}
                    ></CustomButton>
                </div>
            </Form>
        </Modal>
    );
};

export default AssignmentUsers;
