import { Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "src/hooks";
import BackTopButton from "src/modules/components/BackTop";
import FeedbackDrawer from "src/modules/components/Feedback/FeedbackDrawer";
import FeedbackButtonFloat from "src/modules/components/FeedbackButtonFloat";
import { toggleFeedbackModalVisible } from "src/reducers/general";

import Header from "../components/Header";
import NavMenu from "../components/NavMenu";
import "./MainLayout.scss";

const MainLayout = (props) => {
    const { children } = props;
    const { isFeedbackModalVisible } = useSelector((state) => state.general);
    const dispatch = useDispatch();

    const currMedia = useMediaQuery(["(min-width: 992px)"]);
    const [headerSettings, setHeaderSettings] = useState({
        className: "",
    });

    const settingRef = useRef("");
    settingRef.current = headerSettings.className;

    const handleCancelModalFeedback = () => {
        dispatch(toggleFeedbackModalVisible(false));
    };

    useEffect(() => {
        const handleScroll = () => {
            if (settingRef.current !== "fixed-on-top" && window.pageYOffset > 0) {
                setHeaderSettings({ ...headerSettings, className: "fixed-on-top" });
            } else if (settingRef.current && window.pageYOffset <= 0) {
                setHeaderSettings({ ...headerSettings, className: "" });
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="app-main-layout">
            <div className={`layout-header${headerSettings.className ? " " + headerSettings.className : ""}`}>
                <Header className="header-flexible" showNavMenu={currMedia.breakpointValue !== 992} />
            </div>
            {currMedia.breakpointValue === 992 ? (
                <div className="layout-nav-menu">
                    <NavMenu mode="horizontal" />
                </div>
            ) : null}
            <Layout className="layout-content">
                <Layout.Content>{children}</Layout.Content>
            </Layout>

            <BackTopButton
            // duration={window.innerHeight / 2}
            />

            <FeedbackButtonFloat
                tooltip={"Feedback"}
                onClick={() => {
                    dispatch(toggleFeedbackModalVisible(true));
                }}
            />
            <FeedbackDrawer
                visible={isFeedbackModalVisible}
                onOk={handleCancelModalFeedback}
                onCancel={handleCancelModalFeedback}
            ></FeedbackDrawer>
        </div>
    );
};

MainLayout.layout = "MainLayout";

export default MainLayout;
