import api from "..";

export const fetchLanguages = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _language_fields: `${["name", "code"]}`,
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/language", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchSpeaker = (fetchParams) => {
    const params = {
        code: fetchParams?.code,
    };

    return api
        .get("/language/get_speakers_speech", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: [] }));
};
