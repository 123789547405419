import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "src/utils/helpers";

const initialState = {
    role_picked_by_guest: "",
    user: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            if (action.payload) {
                state.user = action.payload;
            }
        },
        logout: (state) => {
            state.user = null;
            localStorage.removeItem("token");
            deleteCookie("token");
        },
        pickRoleByGuest: (state, action) => {
            state.role_picked_by_guest = action.payload;
        },
        setAdditionalUserInfo: (state, action) => {
            // action.payload = {uploaded_avatar,....}
            if (action.payload) {
                state.user = { ...(state.user || {}), ...(action.payload || {}) };
            }
        },
    },
});

export const { login, logout, pickRoleByGuest, setAdditionalUserInfo } = authSlice.actions;

export default authSlice.reducer;
