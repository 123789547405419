import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLang } from "src/reducers/general";
import LoadingScreen from "../components/LoadingScreen";

export const LanguageProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.general.lang);
    const slashIndex = window.location.pathname.indexOf("/", 1);
    const routeLang = window.location.pathname.substring(1, slashIndex > 0 ? slashIndex : undefined);
    const allows = Object.keys(i18n.store.data);

    useEffect(() => {
        const allows = Object.keys(i18n.store.data);
        if (!allows.includes(lang)) {
            dispatch(changeLang(allows.includes(routeLang) ? routeLang : allows[0]));
        } else {
            i18n.changeLanguage(lang);
        }
    }, [lang]);

    if (!allows.includes(routeLang) && routeLang.length > 2) {
        // location.href = `/${lang}${window.location.pathname}`;
        location.href = `/${lang}${window.location.pathname}${window.location.search}`;
        return null;
    }

    if (routeLang !== lang) {
        if (allows.includes(lang)) {
            if (window.location.pathname.indexOf(`/${routeLang}/`) === 0) {
                let newPath = window.location.pathname.replace(`/${routeLang}/`, `/${lang}/`);
                if (window.location.search) {
                    newPath = newPath += window.location.search;
                }
                location.href = newPath;
            } else {
                location.href = `/${lang}`;
            }
        }
        return <LoadingScreen />;
    }

    return children;
};
