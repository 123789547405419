// Used for generalNav:
import { AreaChartOutlined, HistoryOutlined } from "@ant-design/icons";

// Used for teacherNav:
import { ReactComponent as MyQuestions } from "src/assets/images/nav/CauHoi.svg";
import { ReactComponent as MyExam } from "src/assets/images/nav/DeThi.svg";
import { ReactComponent as MyResources } from "src/assets/images/nav/TaiNguyenCuaToi.svg";
// Used for studentNav:
import { ReactComponent as ClipboardGradient } from "src/assets/images/nav/clipboard-gradient.svg";
import { ReactComponent as Clipboard } from "src/assets/images/nav/clipboard.svg";
import { ReactComponent as FileManagerActive } from "src/assets/images/nav/file-manager-active.svg";
import { ReactComponent as FileManager } from "src/assets/images/nav/file-manager.svg";
import { ReactComponent as FolderActive } from "src/assets/images/nav/folder-active.svg";
import { ReactComponent as Folder } from "src/assets/images/nav/folder.svg";
import { ReactComponent as GroupUserActive } from "src/assets/images/nav/group-user-active.svg";
import { ReactComponent as GroupUser } from "src/assets/images/nav/group-user.svg";
import { ReactComponent as HandWritingActive } from "src/assets/images/nav/hand-writing-active.svg";
import { ReactComponent as HandWriting } from "src/assets/images/nav/hand-writing.svg";
// Used for restrictedNav:
import { ReactComponent as Organization } from "src/assets/images/nav/organization.svg";
import { ReactComponent as QuestionCloudActive } from "src/assets/images/nav/question-cloud-active.svg";
import { ReactComponent as QuestionCloud } from "src/assets/images/nav/question-cloud.svg";
import { ReactComponent as Report } from "src/assets/images/nav/report-gradient.svg";
import { ReactComponent as SettingGradient } from "src/assets/images/nav/setting-gradient.svg";
import { ReactComponent as Setting } from "src/assets/images/nav/setting.svg";
import { ReactComponent as TestGradient } from "src/assets/images/nav/test-gradient.svg";
import { ReactComponent as Test } from "src/assets/images/nav/test.svg";
import { CoursesIcon, DashboardIcon } from "src/utils/drawer";

export const generalNav = [
    // {
    //     name: "profile",
    //     routeName: "profile",
    //     icon: SettingGradient,
    //     iconResponsive: Setting,
    //     active: ["/profile"],
    // },
    {
        name: "account_info",
        routeName: "account_info",
        icon: SettingGradient,
        iconResponsive: Setting,
        active: ["/account-info"],
    },
];

export const teacherNav = [
    {
        name: "dashboard",
        routeName: "dashboard",
        icon: DashboardIcon,
        activeIcon: DashboardIcon,
        source: "general",
        active: ["/dashboard"],
    },
    // {
    //     name: "courses",
    //     routeName: "courses",
    //     icon: CoursesIcon,
    //     activeIcon: CoursesIcon,
    //     source: "general",
    //     active: ["/courses"],
    // },
    // {
    //     name: "classes",
    //     routeName: "classes",
    //     icon: GroupUser,
    //     activeIcon: GroupUserActive,
    //     source: "general",
    //     active: ["/classes"],
    // },
    {
        name: "my_resources",
        routeName: "my_resources",
        icon: MyResources,
        activeIcon: MyResources,
        source: "general",
        active: ["/my-resources/exam", "/my-resources/question"],
        subnav: [
            {
                key: 1,
                name: "my_resources_question",
                routeName: "my_resources_question",
                source: "general",
                active: ["/my-resources/question"],
                icon: MyQuestions,
            },
            {
                key: 2,
                name: "my_resources_exam",
                routeName: "my_resources_exam",
                source: "general",
                active: ["/my-resources/exam"],
                icon: MyExam,
            },
        ],
    },
    {
        name: "question_bank",
        routeName: "question_bank",
        icon: Folder,
        activeIcon: FolderActive,
        source: "general",
        active: ["/question"],
    },
    {
        name: "exam_bank",
        routeName: "exam_bank",
        icon: QuestionCloud,
        activeIcon: QuestionCloudActive,
        source: "general",
        active: ["/exam"],
    },
    {
        name: "assignment",
        routeName: "assignment",
        icon: HandWriting,
        activeIcon: HandWritingActive,
        source: "general",
        active: ["/assignment"],
    },
    {
        name: "learning_progress",
        routeName: "learning-progress-report",
        icon: Report,
        iconResponsive: Report,
        active: ["/learning-progress-report"],
    },

    {
        name: "my_storage",
        routeName: "my_storage",
        icon: FileManager,
        activeIcon: FileManagerActive,
        source: "general",
        active: ["/my-storage"],
    },
];

export const employeeNav = [
    ...teacherNav,
    {
        name: "guide",
        routeName: "guide",
        icon: QuestionCloud,
        activeIcon: QuestionCloud,
        source: "general",
        active: ["/guide"],
    },
];

export const studentNav = [
    {
        id: 1,
        name: "exam_asset",
        routeName: "exam_asset",
        icon: ClipboardGradient,
        iconResponsive: Clipboard,
        active: ["/exam-asset"],
    },
    // {
    //     id: 1,
    //     name: "courses",
    //     routeName: "courses",
    //     icon: ClipboardGradient,
    //     iconResponsive: Clipboard,
    //     active: ["/courses"],
    // },
    // {
    //     id: 2,
    //     name: "classes",
    //     routeName: "classes",
    //     icon: ClipboardGradient,
    //     iconResponsive: Clipboard,
    //     active: ["/classes"],
    // },
    // {
    //     id: 3,
    //     name: "assignments",
    //     routeName: "assignment",
    //     icon: HandWriting,
    //     activeIcon: HandWritingActive,
    //     active: ["/assignment"],
    // },
    // {
    //     id: 4,
    //     name: "score_gradebook",
    //     routeName: "gradebook",
    //     icon: TestGradient,
    //     iconResponsive: Test,
    //     active: ["/gradebook"],
    // },
    // {
    //     id: 4,
    //     name: "score_assignment",
    //     routeName: "scores",
    //     icon: TestGradient,
    //     iconResponsive: Test,
    //     active: ["/scores"],
    // },
    // {
    //     id: 5,
    //     name: "personal_leaning_progress",
    //     routeName: "personal-report",
    //     icon: Report,
    //     iconResponsive: Report,
    //     active: ["/personal-report"],
    // },
    // // {
    // //     id: 4,
    // //     name: "classes",
    // //     routeName: "classes",
    // //     icon: ClassesGradient,
    // //     iconResponsive: Classes,
    // //     active: ["/classes"],
    // // },
];

export const adminNav = [
    ...teacherNav,
    {
        name: "manage_user",
        routeName: "manage_user",
        icon: QuestionCloud,
        activeIcon: QuestionCloud,
        source: "general",
        active: ["/manage_user"],
    },
    // {
    //     name: "history",
    //     routeName: "history",
    //     icon: HistoryOutlined,
    //     activeIcon: HistoryOutlined,
    //     source: "general",
    //     active: ["/history"],
    // },
    {
        name: "history",
        routeName: "history",
        icon: HistoryOutlined,
        activeIcon: HistoryOutlined,
        source: "general",
        active: ["/history", "/history/statistic"],
        subnav: [
            {
                key: 1,
                name: "history",
                routeName: "history",
                source: "general",
                active: ["/history"],
                icon: HistoryOutlined,
            },
            {
                key: 2,
                name: "action_statistics",
                routeName: "statistic_history",
                source: "general",
                active: ["/history/statistic"],
                icon: AreaChartOutlined,
            },
        ],
    },
];
