import { t } from "i18next";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragDropIcon from "src/assets/images/drag-icon.svg";
import QuestionContentDetail from "../components/ContentDetail";
import LatexDangerously from "src/modules/components/LatexDangerously";
import chineseToPinyin from "chinese-to-pinyin";
import "./dragDrop.scss";

const DragDrop = (props) => {
    const {
        isReadonly = false,
        question,
        answer, // { answer1: string[], answer2: string[] }
        defaultAnswer, // { answer1: string[], answer2: string[] }
        onChange = () => {},
        isShowPinyin,
    } = props;

    const [listAnswer, setListAnswer] = useState(() => {
        if (defaultAnswer) {
            const listAnswer = [];

            answer.answer1.forEach((aswr, index) => {
                const indexOfAnswer = defaultAnswer.answer1.includes(aswr);
                if (!indexOfAnswer) {
                    listAnswer.push({
                        value: index + 1,
                        text: aswr,
                    });
                }
            });

            return listAnswer;
        } else {
            const listAnswer = answer.answer1?.map((answer, index) => {
                return {
                    value: index + 1,
                    text: answer,
                };
            });

            return listAnswer;
        }
    });
    const [listAnswered, setListAnswered] = useState(() => {
        if (defaultAnswer) {
            const listAnswer = defaultAnswer.answer2.map((title, index) => {
                const answered = defaultAnswer.answer1[index];

                const indexOfAnswer = answer.answer1.findIndex((aswr) => aswr === answered);

                return {
                    title: `${String.fromCharCode(65 + index)} ${title}`,
                    ...(answered
                        ? {
                              text: answered,
                              value: indexOfAnswer + 1,
                          }
                        : {}),
                };
            });

            return listAnswer;
        } else {
            const listAnswered = answer.answer2?.map((title, index) => {
                return {
                    title: `${String.fromCharCode(65 + index)} ${title}`,
                };
            });

            return listAnswered;
        }
    });

    const [placeholderProps, setPlaceholderProps] = useState({});

    const convertAnswer1 = (listAnswered) => {
        const resultChange = [];

        listAnswered.forEach((answer) => {
            resultChange.push(answer.text || null);
        });

        return resultChange;
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleDragEnd = (value) => {
        setPlaceholderProps({});

        if (!value.destination) {
            return;
        }

        const destinationNameOfList =
            value.destination.droppableId.search(/^answered/) === 0 ? "listAnswered" : "listAnswer";
        const destinationIndexOfList =
            value.destination.droppableId.search(/^answered/) === 0
                ? +value.destination.droppableId.split("-")[1]
                : +value.destination.index;

        const sourceNameOfList = value.source.droppableId.search(/^answered/) === 0 ? "listAnswered" : "listAnswer";
        const sourceIndexOfList =
            value.source.droppableId.search(/^answered/) === 0
                ? +value.source.droppableId.split("-")[1]
                : +value.source.index;

        // Case list answer to list answered
        if (destinationNameOfList === "listAnswered" && sourceNameOfList === "listAnswer") {
            let newListAnswered = [];
            let newListAnswer = [];

            // Handle add item in answered list
            if (listAnswered) {
                const result = [...listAnswered];
                result[destinationIndexOfList] = listAnswer[sourceIndexOfList];
                result[destinationIndexOfList].title = listAnswered[destinationIndexOfList].title;

                newListAnswered = result;
            }

            setListAnswered(newListAnswered);

            // Handle remove item in listAnswer list
            if (listAnswer) {
                const result = [...listAnswer];
                result.splice(value?.source?.index, 1);

                newListAnswer = result;
            }

            setListAnswer(newListAnswer);

            const resultChange = convertAnswer1(newListAnswered);

            // Phải đẩy lên cả answer 1 và answer2 để biết được trường hợp.
            onChange({
                answered: {
                    answer1: resultChange,
                    answer2: props.answer.answer2,
                },
            });
        }

        // Case list answered to list answer
        if (destinationNameOfList === "listAnswer" && sourceNameOfList === "listAnswered") {
            let newListAnswered = [];
            let newListAnswer = [];
            // Handle add item in answer list
            if (listAnswer) {
                const result = [...listAnswer];
                result.splice(destinationIndexOfList, 0, listAnswered[sourceIndexOfList]);
                newListAnswer = result;
            }

            setListAnswer(newListAnswer);
            // Handle remove item in listAnswered list
            if (listAnswered) {
                const result = [...listAnswered];
                // result.splice(sourceIndexOfList, 1);
                result[sourceIndexOfList] = { title: listAnswered[sourceIndexOfList].title };
                newListAnswered = result;
            }

            setListAnswered(newListAnswered);

            const resultChange = convertAnswer1(newListAnswered);

            onChange({
                answered: {
                    answer1: resultChange,
                    answer2: props.answer.answer2,
                },
            });
        }

        // Case list answer to list answer
        if (destinationNameOfList === "listAnswer" && sourceNameOfList == "listAnswer") {
            const result = reorder(listAnswer, sourceIndexOfList, destinationIndexOfList);

            setListAnswer(result);
        }

        // Case list answered to list answered
        if (destinationNameOfList === "listAnswered" && sourceNameOfList == "listAnswered") {
            const result = [...listAnswered];

            const sourceTitle = result[sourceIndexOfList].title;
            const destinationTitle = result[destinationIndexOfList].title;

            const temp = result[destinationIndexOfList];

            result[destinationIndexOfList] = {
                ...result[sourceIndexOfList],
                title: destinationTitle,
            };
            result[sourceIndexOfList] = {
                ...temp,
                title: sourceTitle,
            };

            setListAnswered(result);

            const resultChange = convertAnswer1(result);

            onChange({
                answered: {
                    answer1: resultChange,
                    answer2: props.answer.answer2,
                },
            });
        }
    };

    return (
        <div className="q-drag-drop">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail
                    isReadonly={isReadonly}
                    value={{
                        question: question,
                    }}
                />
            </div>

            <div className="dragDropQuestion_container">
                <div className="dragDrop_container">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {listAnswer.length ? (
                            <Droppable className="droppable-listItem" droppableId="listAnswer" isDropDisabled={false}>
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="dragDrop_list">
                                        {listAnswer.map((item, index) => {
                                            return (
                                                <Draggable
                                                    key={`answer-${item.value}`}
                                                    draggableId={`answer-${item.value}`}
                                                    index={index}
                                                    isDragDisabled={isReadonly}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className={`${
                                                                snapshot?.isDragging
                                                                    ? "dragDrop_item dragging"
                                                                    : "dragDrop_item"
                                                            } ${isReadonly ? "disabled" : ""}`}
                                                        >
                                                            <img
                                                                {...provided.dragHandleProps}
                                                                src={dragDropIcon}
                                                                alt="dragDropIcon"
                                                            />
                                                            <div
                                                                className="dragDrop_item-value"
                                                                style={{ width: "100%", height: "100%" }}
                                                            >
                                                                <span style={{ marginLeft: 5 }}>
                                                                    {isShowPinyin && (
                                                                        <div className="dragDrop_item-pinyin">
                                                                            <LatexDangerously
                                                                                innerHTML={chineseToPinyin(item.text, {
                                                                                    keepRest: true,
                                                                                })}
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    <LatexDangerously
                                                                        innerHTML={`${item.value}. ${item.text}`}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                        {snapshot.isDraggingOver && (
                                            <div
                                                className="placeholder"
                                                style={{
                                                    top: placeholderProps.clientY,
                                                    left: placeholderProps.clientX,
                                                    height: placeholderProps.clientHeight,
                                                    width: placeholderProps.clientWidth,
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    borderRadius: "3px",
                                                    border: "dashed 1px #ddd",
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        ) : (
                            <Droppable droppableId="listAnswer">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            backgroundColor: snapshot.isDraggingOver ? "rgba(0, 119, 255, 0.4)" : "",
                                        }}
                                        className="dropEmptyItem fixHeight"
                                    >
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        )}

                        <div className="dragDrop_list answered">
                            {listAnswered.map((item, index) => {
                                const isDropDisabled = Boolean(Object.keys(item).length > 1);

                                return (
                                    <div className="dragDrop_itemWrapper" key={index}>
                                        <Droppable
                                            isDropDisabled={isDropDisabled || false}
                                            droppableId={`answered-${index}`}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor: snapshot.isDraggingOver
                                                            ? "rgba(0, 119, 255, 0.4)"
                                                            : "",
                                                    }}
                                                    className="dropEmptyItem"
                                                >
                                                    {item?.value && (
                                                        <Draggable
                                                            draggableId={`answered-${item.value}`}
                                                            index={item.value}
                                                            isDragDisabled={isReadonly}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    className={`${
                                                                        snapshot?.isDragging
                                                                            ? "dragDrop_item dragging"
                                                                            : "dragDrop_item"
                                                                    } ${isReadonly ? "disabled" : ""}`}
                                                                >
                                                                    <img
                                                                        {...provided.dragHandleProps}
                                                                        src={dragDropIcon}
                                                                        alt="dragDropIcon"
                                                                    />
                                                                    <span style={{ marginLeft: 5 }}>
                                                                        {isShowPinyin && (
                                                                            <div className="dragDrop_item-pinyin">
                                                                                <LatexDangerously
                                                                                    innerHTML={chineseToPinyin(
                                                                                        item.text,
                                                                                        {
                                                                                            keepRest: true,
                                                                                        }
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <LatexDangerously
                                                                            innerHTML={`${item.value}. ${item.text}`}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                        <div>
                                            {isShowPinyin && (
                                                <div className="dragDrop_item_answerTitle dragDrop_item-pinyin">
                                                    <LatexDangerously
                                                        innerHTML={chineseToPinyin(item.title, {
                                                            keepRest: true,
                                                        })}
                                                    />
                                                </div>
                                            )}
                                            <LatexDangerously
                                                className="dragDrop_item_answerTitle"
                                                innerHTML={item.title}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

export default DragDrop;
