import { CheckCircleOutlined } from "@ant-design/icons";
import { Form, Input, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { verify } from "src/api/containers/auth";
import { login } from "src/api/containers/auth";
import ForgetPasswordPNG from "src/assets/images/ForgetPassword.png";
import { useAuth } from "src/modules/auth";
import Button from "src/modules/components/Button";
import { toggleCollapsed } from "src/reducers/general";
import { parse_jwt } from "src/utils/helpers";

import "./Verify.scss";

const Verify = ({ userData }) => {
    const { username = "", password = "" } = userData;
    const [form] = useForm();
    const auth = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleLogin = () => {
        if (!username || !password) {
            return;
        }
        const formData = {
            device_id: uuidv4(),
            username: username,
            password: password,
        };
        setIsLoading(true);
        login(formData).then((res) => {
            if (res?.status) {
                const { token } = res;
                if (token) {
                    const user = parse_jwt(`${token}`);
                    auth.login({ ...user, ...res }, () => {
                        document.cookie = `token=${token}; path=/`;
                        dispatch(toggleCollapsed(true));
                    });
                    notification.success({
                        message: t("message.login_success"),
                    });
                    setIsLoading(false);
                }
            } else {
                notification.error({
                    message: res.message || t("message.login_error"),
                    description: t("message.login_fail"),
                });
                setIsLoading(false);
                if (res.message === "The account has not been activated!") {
                    navigate("/register", { state: { verify: true, username: form.getFieldValue("username") } });
                }
            }
        });
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        formData.username = username;
        formData.code_verify = parseInt(formData.code_verify) || 0;
        setIsLoading(true);
        verify(formData).then(({ status, message }) => {
            if (status) {
                notification.success({
                    message: t("message.verify_success"),
                });
                setIsLoading(false);
                // Login after registering successfully:
                // navigate("/login");
                handleLogin();
            } else {
                form.resetFields();
                notification.error({
                    message: message || t("message.verify_error"),
                });
                setIsLoading(false);
            }
        });
    };

    return (
        <div className="sign-in verify">
            <div className="sign-in-wrapper">
                <Spin spinning={isLoading}>
                    <h1>{t("login.verify_email")}</h1>
                    <h4>
                        {t("login.please_verify")}
                        <div>{username || "Unknown email!"}</div>
                    </h4>

                    <div className="verify-wrapper">
                        <div className="verify-img">
                            <img src={ForgetPasswordPNG} alt="" />
                        </div>
                        <Form form={form} onFinish={handleSubmit}>
                            <h2>{t("login.verify_code")}</h2>
                            <Form.Item
                                name="code_verify"
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Input
                                    className="app-input"
                                    placeholder={t("login.enter_code")}
                                    prefix={<CheckCircleOutlined />}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    title={t("login.verify")}
                                    className="submit-btn"
                                    htmlType="submit"
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default Verify;
