import Sider from "antd/lib/layout/Sider";
import React, { useMemo, useRef, useState } from "react";

import { useValues } from "src/hooks";

import VideoPlayer from "../VideoPlayer";
import ListViolation from "./ListViolation";
import "./VideoWithListViolation.scss";

function isVideoUrl(url) {
    // Define regular expressions for common video file extensions, including .webm
    var videoExtensions = /\.(mp4|mov|avi|mkv|wmv|flv|webm)$/i;

    // Check if the URL ends with a video file extension
    return videoExtensions.test(url);
}

function VideoWithListViolation({ width, height, recorData }) {
    const seekTo = useRef(null);
    const [collapsed, setCollapsed] = useState(false);
    const [values, setValues] = useValues({
        active_key: "",
    });

    const { content_violation_error: violationData, files } = recorData || {};
    const videoData = files?.[0];

    const listErrors = useMemo(() => {
        return violationData.reduce((results, currentVal) => {
            return [...results, ...(currentVal.errors || [])];
        }, []);
    }, [violationData]);

    const isHasVideo = useMemo(() => {
        return videoData && isVideoUrl(videoData.src);
    }, [videoData]);

    return isHasVideo ? (
        <div className="video-with-violation" style={{ width, height }}>
            <div className="video-with-violation_left">
                <ListViolation
                    hasVideo={true}
                    seekTo={seekTo}
                    violationData={violationData}
                    onSelectItem={(k) => setValues({ active_key: k })}
                    active_key={values.active_key}
                />
            </div>
            <div className="video-with-violation_right">
                <VideoPlayer listViolations={listErrors} seekTo={seekTo} videoData={videoData} />
            </div>
        </div>
    ) : (
        <div className="video-with-violation">
            <ListViolation
                hasVideo={false}
                seekTo={seekTo}
                violationData={violationData}
                onSelectItem={(k) => setValues({ active_key: k })}
                active_key={values.active_key}
            />
        </div>
    );
}

export default VideoWithListViolation;
