import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

import Logo from "src/assets/images/app-logo/logo.png";

import "./QrCode.scss";

const downloadQR = ({ QrName }) => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = QrName ? `qrcode_${QrName}.png` : "qrcode_testonline.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

function QrCode(props) {
    const { value, QrName = "" } = props;
    const { t } = useTranslation();

    return (
        <div className="qr-code">
            <div className="qr-code-wrapper">
                <QRCode
                    id="qrcode"
                    value={value || "https://app.testonline.vn"}
                    size={250}
                    level={"L"}
                    includeMargin={true}
                    imageSettings={{ src: Logo, excavate: true }}
                    // bgColor={"#4fe3c1"}
                    fgColor={"#455a55"}
                />
                <a className="download-qr-btn" onClick={() => downloadQR({ QrName })}>
                    {t("shared.download_qr")}
                </a>
            </div>
        </div>
    );
}

export default QrCode;
