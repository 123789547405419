import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { checkExamAccessCode } from "src/api/containers/examCheckin";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { default as CustomTooltip } from "src/modules/components/Tooltip";

import "./ExamAccess.scss";

function ExamAccess({ onChange }) {
    const [form] = useForm();

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const examAccessCode = urlSearchParams.get("code");

    const [values, setValues] = useValues({
        loading: false,
        accessStatus: false,
    });

    const handleSubmit = () => {
        let formData = form.getFieldsValue();
        if (!formData.exam_access_code) {
            return;
        }

        setValues({ loading: true });
        checkExamAccessCode(formData.exam_access_code).then((res) => {
            if (res.status) {
                setValues({
                    loading: false,
                    accessStatus: res.status,
                });
                setTimeout(() => {
                    onChange?.(formData.exam_access_code, res.assignment);
                }, 500);

                setUrlSearchParams({
                    code: formData.exam_access_code,
                });
            } else {
                setValues({ loading: false });
                onChange?.("", undefined);

                setUrlSearchParams({});

                notification.error({
                    message: res.message || t("message.sth_wrong"),
                });
            }
        });
    };

    useEffect(() => {
        if (examAccessCode) {
            form.setFieldsValue({
                exam_access_code: examAccessCode,
            });
            handleSubmit();
        } else {
            form.setFieldsValue({
                exam_access_code: "",
            });
            onChange?.("", undefined);
        }
    }, [examAccessCode]);

    return (
        <div className="exam-access">
            <div className="access-content">
                <div className="access-info">
                    <div className={`info-title${values.accessStatus ? " active" : ""}`}>
                        <div className="info-icon">{values.accessStatus ? <UnlockOutlined /> : <LockOutlined />}</div>
                        <div className="info-iconlabel">{t("exam_checkin.access_exam")}</div>
                    </div>
                </div>
                <div className="access-code">
                    <>
                        <Form
                            form={form}
                            layout="vertical"
                            className="app-form type-primary has-rd"
                            onFinish={handleSubmit}
                        >
                            <Row gutter={[16, 8]}>
                                <Col className="exam-access-code" span={24}>
                                    <Form.Item
                                        label={
                                            <>
                                                <span>{`${t("exam_checkin.exam_access_code")}`}</span>
                                                <CustomTooltip
                                                    type="info"
                                                    title={t("exam_checkin.descr_exam_access_code")}
                                                    style={{ marginLeft: "4px" }}
                                                />
                                            </>
                                        }
                                        name="exam_access_code"
                                        rules={[{ required: true, message: t("exam_checkin.input_exam_access_code") }]}
                                    >
                                        <Input
                                            className="app-input has-rd"
                                            placeholder={t("exam_checkin.exam_access_code")}
                                            maxLength={100}
                                            readOnly={values.accessStatus || values.loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className="form-actions">
                                <CustomButton
                                    htmlType="submit"
                                    type="primary"
                                    icon={<SvgTick />}
                                    title={values.accessStatus ? `${t("shared.redirecting")}...` : t("shared.confirm")}
                                    isLoading={values.accessStatus || values.loading}
                                ></CustomButton>
                            </div>
                        </Form>
                    </>
                </div>
            </div>
        </div>
    );
}

export default ExamAccess;
