import React from "react";
import { t } from "i18next";
import "./GradebookItemMobile.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";

function GradebookItemMobile({ item = {}, actions = [], studentActions = () => {} }) {
    const {
        name,
        level,
        score,
        percent,
        last_taken,
        attempts,
        time_spend,
        begin_date,
        end_date,
        max_score,
        count_submit,
        count_marked,
    } = item;
    const { role } = useSelector((state) => state.auth.user);

    return (
        <div className="gradebook-item-mobile">
            {role == "student" ? (
                <div className="gradebook-item-mobile_content">
                    <h3 className="gradebook-item-mobile_title">{name}</h3>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.level")}:</span> {level || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.score")}:</span> {score || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.percent")}:</span> {percent || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.last_taken")}:</span> {last_taken || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.attempts")}:</span> {attempts || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.time_spend")}:</span>
                        {time_spend || "_"}
                    </div>
                    <div>{studentActions && studentActions(item)}</div>
                </div>
            ) : (
                <div className="gradebook-item-mobile_content">
                    <h3 className="gradebook-item-mobile_title">{name}</h3>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.level")}:</span> {level || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.begin_date")}:</span> {begin_date || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.end_date")}:</span> {end_date || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.max_score")}:</span> {max_score || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.submited")}:</span> {count_submit || "_"}
                    </div>
                    <div className="gradebook-item-mobile_field">
                        <span>{t("gradebook.marked")}:</span> {count_marked || "_"}
                    </div>
                    <span className="gradebook-item-mobile_action teacher">
                        {actions &&
                            actions.map((action, index) => {
                                const { title, icon, link, onClick, width, tooltip = "" } = action;
                                if (link) {
                                    return (
                                        <Link to={link} key={index} className="action_icon">
                                            {icon && typeof icon === "string" && (
                                                <img src={icon} width={width || "25"} className="btn__content-image" />
                                            )}
                                            {icon && React.isValidElement(icon) && <>{icon}</>}
                                        </Link>
                                    );
                                }
                                if (title) {
                                    return (
                                        <Button key={index} onClick={() => onClick(item)} className="action_btn">
                                            {title}
                                        </Button>
                                    );
                                }
                                return (
                                    <Tooltip key={index} placement="bottom" title={tooltip}>
                                        <span onClick={() => onClick(item)} className="table-action__icon">
                                            {icon && typeof icon === "string" && (
                                                <img src={icon} width={width || "25"} className="btn__content-image" />
                                            )}
                                            {icon && React.isValidElement(icon) && <>{icon}</>}
                                        </span>
                                    </Tooltip>
                                );
                            })}
                    </span>
                </div>
            )}
        </div>
    );
}

export default GradebookItemMobile;
