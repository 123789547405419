import { Alert } from "antd";
import { t } from "i18next";
import DragDropGroup from "src/modules/components/QuestionPanel/DragDropGroup";
import { checkIfDuplicateExists } from "src/utils/helpers";

const MyDragDropGroup = (props) => {
    const { value: answered, answers, groups, onChange } = props;

    const handleChangeAnswerDragDrop = (value) => {
        onChange(value.answered.match);
    };

    return (
        <div>
            {!checkIfDuplicateExists(answers || []) ? (
                <DragDropGroup
                    answers={structuredClone(answers)}
                    groups={groups}
                    answered={{
                        match: answered,
                    }}
                    isShowQuestion={false}
                    onChange={handleChangeAnswerDragDrop}
                />
            ) : (
                <Alert type="warning" message={t("message.warning_duplicate_answer")} />
            )}
        </div>
    );
};

export default MyDragDropGroup;
