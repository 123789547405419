import { useRef } from "react";
import NoImage from "src/assets/images/display/image.png";
import "./ImageFallback.scss";

const ImageFallback = (props) => {
    const { src, alt = "", ...rest } = props;
    const imgEle = useRef(null);

    const handleLoadImage = () => {
        imgEle.current.classList.remove("imgFallback--loading");
    };

    return (
        <div ref={imgEle} className="imgFallback imgFallback--loading" {...rest}>
            <img onLoad={handleLoadImage} src={src} alt={alt} />
        </div>
    );
};

export default ImageFallback;
