import { CloseOutlined, EditOutlined, LogoutOutlined, SelectOutlined, SyncOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { ReactComponent as SvgSubmitTest } from "src/assets/images/app-icons/submit.svg";
import { ReactComponent as SvgDelete } from "src/assets/images/delete-bin.svg";
import { default as CustomButton } from "src/modules/components/Button";

import "./PopConfirm.scss";

export const CONFIRM_TYPE = {
    cancel: "cancel",
    update: "update",
    delete: "delete",
    leave: "leave",
    action: "action",
    "submit-test": "submit-test",
    reset: "reset",
};
function PopConfirm({
    className,
    type, // type: "cancel", "update", "delete", "leave", "action", "submit-test".
    visible = false,
    showIcon = true,
    icon,
    title = "",
    okText = "",
    cancelText = "",
    isLoading = false,
    onOk = () => {},
    onCancel = () => {},
    children,
    ...rest
}) {
    const { t } = useTranslation();

    if (CONFIRM_TYPE.hasOwnProperty(type)) {
        let ModalIcon = React.isValidElement(icon) ? icon : null;
        let modalClassName = "";
        let modalTitle = "";
        let modalOkTxt = okText;
        let modalCancelTxt = cancelText;

        switch (type) {
            case "cancel": {
                ModalIcon = icon || CloseOutlined;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_cancel")}?`;
                modalOkTxt = modalOkTxt || t(`shared.yes`);
                modalCancelTxt = modalCancelTxt || t(`shared.no`);
                break;
            }
            case "update": {
                ModalIcon = icon || EditOutlined;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_delete")}?`;
                break;
            }
            case "delete": {
                ModalIcon = icon || SvgDelete;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_delete")}?`;
                break;
            }
            case "leave": {
                ModalIcon = icon || LogoutOutlined;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_leave")}?`;
                break;
            }
            case "action": {
                ModalIcon = icon || SelectOutlined;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_action")}?`;
                break;
            }
            case "submit-test": {
                ModalIcon = icon || SvgSubmitTest;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_action")}?`;
                break;
            }
            case "reset": {
                ModalIcon = icon || SyncOutlined;
                modalClassName = "confirm-popup-modal";
                modalTitle = title || `${t("shared.msg_confirm_action")}?`;
                modalOkTxt = modalOkTxt || t(`shared.yes`);
                break;
            }

            default:
                break;
        }

        return (
            <Modal
                className={className ? `${modalClassName} ${className}` : modalClassName}
                visible={visible}
                centered
                onOk={onOk}
                onCancel={onCancel}
                okText={modalOkTxt || t(`shared.confirm_${type}`)}
                cancelText={modalCancelTxt || t("shared.cancel")}
                footer={
                    <div className="modal-actions">
                        {modalCancelTxt !== null && (
                            <CustomButton
                                type="grey"
                                title={modalCancelTxt || t("shared.cancel")}
                                icon={<SvgCross />}
                                onClick={onCancel}
                            ></CustomButton>
                        )}
                        <CustomButton
                            type="primary"
                            title={modalOkTxt || t(`shared.confirm_${type}`)}
                            icon={<SvgTick />}
                            onClick={onOk}
                            isLoading={isLoading}
                        ></CustomButton>
                    </div>
                }
                {...rest}
            >
                <div className="modal-icon-wrapper">
                    <span className={`modal-icon ${type}`}>
                        <ModalIcon />
                    </span>
                </div>

                {children ? children : <p className="modal-title">{modalTitle}</p>}
            </Modal>
        );
    }

    return <></>;
}

export default PopConfirm;
