import React, { useEffect, useState } from "react";
import { t } from "i18next";
import QuestionContentDetail from "../ContentDetail";
import Answer from "./components/Answer";
import { Alert } from "antd";
import parser from "src/utils/parser";

function MultipleChoice(props) {
    const {
        question = "", // string
        /**
         * Description type for correct_answer
         * @param {@Interface {text: string; result: boolean}[]}
         */
        correct_answer,
        answered, // string[]
        is_multiple = false, // boolean
        showResults = false,
        hideWarning = false,
        type,
    } = props;

    return (
        <div className="qc_multiple-choice">
            {question && (
                <div className="q-content-title">
                    <QuestionContentDetail
                        isReadonly={true}
                        value={{
                            question,
                        }}
                    />
                    {is_multiple && <span>({t("q.allow_multi_answers")})</span>}
                </div>
            )}
            <div className="content-multiple-choice">
                {!answered?.length && !hideWarning && (
                    <Alert style={{ marginBottom: 15 }} type="warning" message={t("question.question_skipped")} />
                )}
                {correct_answer?.map((item, index) => (
                    <Answer
                        key={index}
                        letter={String.fromCharCode(97 + index)}
                        answer={{
                            text: item.text,
                            result: item.result,
                            chosen:
                                answered?.includes(parser.compactMathField(item.text)) ||
                                answered?.includes(item.text) ||
                                false,
                        }}
                        showResult={showResults}
                    />
                ))}
            </div>
        </div>
    );
}

export default MultipleChoice;
