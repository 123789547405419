import { t } from "i18next";
import renderFileManager from "./FileManager";

tinymce.PluginManager.add("file-manager", function (editor, url) {
    let fileManagerButton = null;

    const getFileManagerButton = () => {
        const buttons = editor.editorContainer.querySelectorAll(".tox-toolbar__group .tox-tbtn") || [];
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].getAttribute("aria-label") === t("editor.attach_files")) {
                return buttons[i];
            }
        }
        return null;
    };

    const reloadAudioBtnList = function () {
        // List of audio buttons:
        let items = editor.contentDocument.querySelectorAll(".media-audio-action");
        items.forEach(function (item, index) {
            // Make the content of an audio buton element is uneditable:
            item.setAttribute("contenteditable", false);
            // If <audio> element of an audio button is removed, it should be removed too:
            const childAudio = item.querySelector(".media-audio-element--type-icon");
            if (!childAudio) {
                item.remove();
            }
        });
    };

    editor.ui.registry.addIcon(
        "attach-media",
        `
            <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <defs><style>.cls-1,.cls-2{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:bevel;stroke-width:1.5px;}.cls-2{fill-rule:evenodd;}</style></defs>
                <g id="ic-actions-add-file"><line class="cls-1" x1="16" y1="13.13" x2="8" y2="13.13"/><line class="cls-1" x1="12" y1="17.13" x2="12" y2="9.13"/><path class="cls-2" d="M14.86,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8.92a.94.94,0,0,0-.18-.57L15.67,2.43A1,1,0,0,0,14.86,2Z"/></g>
            </svg>
        `
    );

    editor.ui.registry.addButton("file-manager", {
        text: t("editor.attach_files"),
        icon: "attach-media",
        tooltip: t("editor.attach_files"),
        onSetup: function (api) {
            fileManagerButton = getFileManagerButton();
            if (fileManagerButton) {
                fileManagerButton.id = "custom-tox-tbtn--file-manager";
            }
        },
        onAction: function () {
            // 1. Create a function to be called from FileManager Modal:
            const handleSelectFile = (fileUrl, fileVisibleType) => {
                // console.log(editor.selection.getNode())
                if (fileVisibleType === "image") {
                    editor.insertContent(`
                            &nbsp;
                            <img src="${fileUrl}" alt="" />
                            &nbsp;
                        `);
                } else if (fileVisibleType === "video") {
                    editor.insertContent(`
                            &nbsp;
                            <video class="media-video-element--type-player" controls>
                                <source src="${fileUrl}" type="video/mp4" />
                            </video>
                            &nbsp;
                        `);
                } else if (fileVisibleType === "audio-player") {
                    editor.insertContent(`
                            &nbsp;
                            <audio class="media-audio-element--type-player" controls>
                                <source src="${fileUrl}" type="audio/mpeg" />
                            </audio>
                            &nbsp;
                        `);
                } else if (fileVisibleType === "audio-icon") {
                    editor.insertContent(`
                            &nbsp;
                            <span class="media-audio-action">
                                <audio class="media-audio-element--type-icon" controls style="display: none;">
                                    <source src="${fileUrl}" type="audio/mpeg" />
                                </audio>
                                <img class="media-audio-icon" src="audio-speaker-medium.svg" alt="" />
                            </span>
                            &nbsp;
                        `);
                } else {
                    editor.insertContent(`&nbsp;<span style="color: red;">(Unknown element!)<span/>&nbsp;`);
                }
            };
            // 2. Display File manager:
            renderFileManager(editor, {
                displayType: "modal",
                selectFile: handleSelectFile,
            });
        },
    });

    editor.on("NodeChange", function () {
        setTimeout(reloadAudioBtnList);
    });

    editor.on("KeyDown", function (e) {
        e.key === "Backspace" && setTimeout(reloadAudioBtnList);
    });

    // editor.on("Cut", function () {
    //     setTimeout(reloadAudioBtnList);
    // });

    // editor.on("Paste", function () {
    //     setTimeout(reloadAudioBtnList);
    // });

    return {
        getMetadata: function () {
            return {
                name: "Upload files",
                url: "",
            };
        },
    };
});
