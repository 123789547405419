import { useEffect, useState } from "react";

function verify({ minWidth = undefined, maxWidth = undefined }) {
    const windowWidth = window.innerWidth;
    if (minWidth !== undefined && windowWidth < minWidth) {
        return false;
    }
    if (maxWidth !== undefined && windowWidth > maxWidth) {
        return false;
    }
    return true;
}

function useMedia({ minWidth = undefined, maxWidth = undefined }) {
    const [show, setShow] = useState(() => verify({ minWidth, maxWidth }));
    useEffect(() => {
        function onResize(e) {
            let _show = verify({ minWidth, maxWidth });
            if (_show != show) {
                setShow(_show);
            }
        }
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [show]);
    return show;
}

function MediaView({ minWidth = undefined, maxWidth = undefined, children = null }) {
    const show = useMedia({ minWidth, maxWidth });
    return show && children;
}

MediaView.useMedia = useMedia;

export default MediaView;
