const Item = ({
    src,
    className,
    title,
    text,
    inputId,
    inputType,
    onClick = () => {},
    onChange = () => {},
    disabled = false,
}) => {
    const renderIcon = () => {
        if (!src) {
            return null;
        } else if (typeof src === "string") {
            return <img src={src} />;
        } else {
            const Icon = src;
            return <Icon />;
        }
    };

    if (inputType) {
        return (
            <>
                <input
                    type={inputType}
                    id={inputId}
                    multiple
                    onClick={onClick}
                    onChange={onChange}
                    disabled={disabled}
                    style={{ display: "none" }}
                />
                <button
                    className={
                        className ? `file-manager-toolbar-item lbl ${className}` : "file-manager-toolbar-item item-lbl"
                    }
                    disabled={disabled}
                    title={title}
                >
                    <label htmlFor={inputId}>
                        <span className="btn-icon-wrapper">{renderIcon()}</span>
                        {text ? <span className="btn-text">{text}</span> : null}
                    </label>
                </button>
            </>
        );
    } else {
        return (
            <button
                className={className ? `file-manager-toolbar-item ${className}` : "file-manager-toolbar-item"}
                onClick={onClick}
                disabled={disabled}
                title={title}
            >
                <span className="btn-icon-wrapper">{renderIcon()}</span>
                {text ? <span className="btn-text">{text}</span> : null}
            </button>
        );
    }
};

export default Item;
