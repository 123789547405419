import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import bankLogoSacombank from "src/assets/images/payment/bank_logo_sacombank.png";

import "./BankTransferInfo.scss";

const BankTransferInfo = () => {
    const { t } = useTranslation();

    return (
        <div className="bank-transfer-info">
            <div className="info-section">
                <Row className="account-name" gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={8}>
                        {t("payment.account_name")}
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        CÔNG TY TNHH TƯ VẤN DU HỌC GIÁO DỤC PHƯƠNG NAM
                    </Col>
                </Row>
                <Row className="payment-content" gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={8}>
                        {t("payment.payment_content")}
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        ...
                    </Col>
                </Row>
            </div>
            <div className="info-section bank-info">
                <Row className="bank-info-header" gutter={[16, 8]}>
                    <Col md={12}>{t("payment.bank")}</Col>
                    <Col md={6}>{t("payment.account_name")}</Col>
                    <Col md={6}>{t("payment.account_number")}</Col>
                </Row>
                <Row className="bank-info-content" gutter={[16, 8]}>
                    <Col md={12}>
                        <span className="bank-logo-wrapper">
                            <span className="bank-logo">
                                <img src={bankLogoSacombank} alt="bank-logo"></img>
                            </span>
                        </span>
                        <span>Sacombank - Ngân hàng TMCP Sài Gòn Thương Tín - Sài Gòn</span>
                    </Col>
                    <Col md={6}>CÔNG TY TNHH TƯ VẤN DU HỌC GIÁO DỤC PHƯƠNG NAM</Col>
                    <Col md={6}>060205085575</Col>
                </Row>
            </div>
        </div>
    );
};

export default BankTransferInfo;
