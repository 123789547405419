import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input, notification } from "antd";
import { FileImageOutlined, UndoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { default as CustomButton } from "src/modules/components/Button";
import ImageLabelsContainer from "../ImageLabelsContainer";
import PopupFileManager from "src/modules/components/FileManager/popup";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";
import "./ImageWithLabels.scss";

function ImageWithLabels({
    labelType = "", // Values: "text", "dropdown", "drag&drop", undefined.
    labelListData, // We should not do anything with labelListData in this component!
    imageData,
    onChangeImage,
    onChangeLabels,
    onAddLabel,
    onRemoveLabel,
    className,
}) {
    const { t } = useTranslation();
    const fmController = PopupFileManager.useController();

    const imgRef = useRef(null);
    const resizerPos = useRef({
        rsz_clientX: 0, // Horizontal coordinate (used with resizer).
        rsz_clientY: 0, // Vertical coordinate (used with resizer).
    });
    const [resizeState, setResizeState] = useState(false); // Values: "resizing", false.
    const [resizePos, setResizePos] = useState(false); // Values: "bottom-right", false.
    const [selectedImg, setSelectedImg] = useState({
        id: undefined,
        src: undefined,
    });
    const [selectedImgConfigs, setSelectedImgConfigs] = useState({
        nWidth: undefined, // Natural width.
        nHeight: undefined, // Natural height.
        cWidth: "100%", // Current width.
        cHeight: "auto", // Current height.
        aspectRatio: undefined,
    });
    const [labelConfigs, setLabelConfigs] = useState([]);
    const [showModal, setShowModal] = useState(false); // Values: "confirm-reset", false.

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (resizeState) {
            r += ` ${resizeState}`;
        }
        return r;
    }, [className, resizeState]);

    const handleOpenFileManager = () => {
        fmController.call("open");
    };

    const changeImage = (id, currentWidth, currentHeight, src) => {
        if (onChangeImage instanceof Function) {
            onChangeImage({
                id: id,
                src: src,
                currentWidth: currentWidth,
                currentHeight: currentHeight,
            });
        }
    };

    const changeLabels = (labels) => {
        if (onChangeLabels instanceof Function) {
            onChangeLabels(labels);
        }
    };

    const doResize = (movementX = 0, movementY = 0) => {
        // - STEP 1: Get current width:
        let newWidth = imgRef.current.width;
        let newHeight = imgRef.current.height;
        // - STEP 2: Update width & height:
        if (movementX >= movementY) {
            newWidth = newWidth - movementX;
            newHeight = newWidth / selectedImgConfigs.aspectRatio;
        } else if (movementX < movementY) {
            newHeight = newHeight - movementY;
            newWidth = newHeight * selectedImgConfigs.aspectRatio;
        }
        imgRef.current.style.width = `${newWidth}px`;
        imgRef.current.style.height = `${newHeight}px`;
    };

    const handleReset = () => {
        resizerPos.current = {
            rsz_clientX: 0,
            rsz_clientY: 0,
        };
        setSelectedImg({
            id: undefined,
            src: undefined,
        });
        setSelectedImgConfigs({
            nWidth: undefined,
            nHeight: undefined,
            cWidth: "100%",
            cHeight: "auto",
            aspectRatio: undefined,
        });
        setLabelConfigs([]);
        // Trigger onChange prop:
        changeImage();
    };

    const handleUpdateImageConfigs = (naturalWidth, naturalHeight, currentWidth, currentHeight) => {
        setSelectedImgConfigs({
            nWidth: naturalWidth,
            nHeight: naturalHeight,
            cWidth: currentWidth,
            cHeight: currentHeight,
            aspectRatio: naturalWidth / naturalHeight,
        });
        // Trigger onChange prop:
        if (selectedImg?.id) {
            changeImage(selectedImg.id, currentWidth, currentHeight, selectedImg.src);
        }
    };

    const handleUpdateImage = (selectedImg) => {
        setSelectedImg({
            id: selectedImg?.id,
            src: selectedImg?.src,
        });
        setSelectedImgConfigs({
            nWidth: undefined,
            nHeight: undefined,
            cWidth: "100%",
            cHeight: "auto",
            aspectRatio: undefined,
        });
    };

    const handleChangeResizeState = (resizeState, resizePos) => {
        setResizeState(resizeState || false);
        setResizePos(resizePos || false);
    };

    const handleStartResize = (e, resizePos) => {
        switch (e?.type) {
            case "mousedown":
                break;
            case "touchstart":
                resizerPos.current.rsz_clientX = e.touches[0].clientX;
                resizerPos.current.rsz_clientY = e.touches[0].clientY;
                break;
            default:
                break;
        }
        // Trigger onChange prop:
        handleChangeResizeState("resizing", resizePos);
    };

    const handleResize = (e, resizePos) => {
        switch (resizePos) {
            case "bottom-right": {
                switch (e?.type) {
                    case "mousemove": {
                        // Calculate movementX, movementY:
                        const touchPrev = resizerPos.current;
                        const movementX = (touchPrev.rsz_clientX || e.clientX) - e.clientX;
                        const movementY = (touchPrev.rsz_clientY || e.clientY) - e.clientY;
                        // Store new coordinate:
                        resizerPos.current.rsz_clientX = e.clientX;
                        resizerPos.current.rsz_clientY = e.clientY;
                        // Change size:
                        doResize(movementX, movementY);
                        break;
                    }
                    case "touchmove":
                        // Calculate movementX, movementY:
                        const touchCurr = e.touches[0];
                        const touchPrev = resizerPos.current;
                        const movementX = touchCurr.clientX - touchPrev.rsz_clientX;
                        const movementY = touchCurr.clientY - touchPrev.rsz_clientY;
                        // Store new coordinate:
                        resizerPos.current.rsz_clientX = touchCurr.clientX;
                        resizerPos.current.rsz_clientY = touchCurr.clientY;
                        // Change size:
                        doResize(movementX, movementY);
                        break;
                    default:
                        break;
                }
                break;
            }
            default:
                break;
        }
    };

    const handleRepositionLabels = () => {
        // Current:
        const cw = selectedImgConfigs.cWidth;
        const ch = selectedImgConfigs.cHeight;
        // New:
        const nw = imgRef.current.width;
        const nh = imgRef.current.height;
        // Reposition labels:
        const newLabelConfigs = [...labelConfigs];
        for (let i = 0; i < newLabelConfigs.length; i++) {
            const newLeft = nw * (newLabelConfigs[i].left / cw);
            const newTop = nh * (newLabelConfigs[i].top / ch);
            newLabelConfigs[i].left = newLeft;
            newLabelConfigs[i].top = newTop;
        }
        changeLabels(newLabelConfigs);
    };

    useEffect(() => {
        if (resizeState === "resizing") {
            const _doResize = (_e) => {
                handleResize(_e, resizePos);
            };
            const _stopResize = () => {
                window.removeEventListener("mousemove", _doResize);
                window.removeEventListener("mouseup", _stopResize);
                window.removeEventListener("touchmove", _doResize); // Touch devices.
                window.removeEventListener("touchend", _stopResize); // Touch devices.
                // Image configs:
                resizerPos.current = {
                    rsz_clientX: 0,
                    rsz_clientY: 0,
                };
                setSelectedImgConfigs({
                    ...selectedImgConfigs,
                    cWidth: imgRef.current.width,
                    cHeight: imgRef.current.height,
                });
                // Labels configs:
                handleRepositionLabels();
                // Trigger onChange prop:
                handleChangeResizeState(false);
                changeImage(selectedImg.id, imgRef.current.width, imgRef.current.height, selectedImg.src);
            };
            window.addEventListener("mousemove", _doResize);
            window.addEventListener("mouseup", _stopResize);
            window.addEventListener("touchmove", _doResize); // Touch devices.
            window.addEventListener("touchend", _stopResize); // Touch devices.
        }
    }, [resizeState, resizePos]);

    useEffect(() => {
        changeLabels(labelConfigs);
    }, [labelConfigs]);

    useEffect(() => {
        if (imageData) {
            const { id, src, currentWidth, currentHeight } = imageData;
            if (
                id !== selectedImg.id &&
                currentWidth !== selectedImgConfigs.cWidth &&
                currentHeight !== selectedImgConfigs.cHeight
            ) {
                setSelectedImg({
                    id: id,
                    src: src,
                });
                setSelectedImgConfigs({
                    ...selectedImgConfigs,
                    cWidth: currentWidth,
                    cHeight: currentHeight,
                });
            }
        }
    }, [imageData]);

    return (
        <div className="image-with-labels-wrapper">
            <div className="image-with-labels-tools">
                <CustomButton
                    type="primary"
                    title={t("shared.upate_image")}
                    icon={<FileImageOutlined />}
                    onClick={handleOpenFileManager}
                ></CustomButton>
                <CustomButton
                    type="ghost"
                    title={t("shared.reset")}
                    icon={<UndoOutlined />}
                    onClick={() => setShowModal("confirm-reset")}
                ></CustomButton>
            </div>

            <div className="image-with-labels-configs">
                <div className="config-imgsize">
                    <Input className="app-input" value={selectedImgConfigs.cWidth} addonAfter={"px"} />
                    <span>x</span>
                    <Input className="app-input" value={selectedImgConfigs.cHeight} addonAfter={"px"} />
                </div>
            </div>

            <div
                className={`image-with-labels${extraClassnames}`}
                style={{ ...(selectedImg.src ? { height: "auto" } : {}) }}
            >
                {selectedImg.src ? (
                    <ImageLabelsContainer
                        className="image-src-wrapper"
                        labelType={labelType}
                        labelList={labelListData}
                        containerSize={{ width: selectedImgConfigs.cWidth, height: selectedImgConfigs.cHeight }}
                        onChangeLabels={(lblList) => setLabelConfigs(lblList)}
                        onAddLabel={onAddLabel}
                        onRemoveLabel={onRemoveLabel}
                    >
                        <span className="image-src">
                            <img
                                ref={imgRef}
                                src={selectedImg.src}
                                alt="image-with-labels"
                                draggable={false}
                                onLoad={(e) => {
                                    const currImg = e.target;
                                    handleUpdateImageConfigs(
                                        currImg.naturalWidth,
                                        currImg.naturalHeight,
                                        currImg.width,
                                        currImg.height
                                    );
                                }}
                                style={{
                                    ...(selectedImgConfigs.nWidth
                                        ? {
                                              maxWidth: `${selectedImgConfigs.nWidth}px`,
                                              maxHeight: `${selectedImgConfigs.nHeight}px`,
                                          }
                                        : {}),
                                    ...(selectedImgConfigs.cWidth
                                        ? {
                                              width: !isNaN(selectedImgConfigs.cWidth)
                                                  ? `${selectedImgConfigs.cWidth}px`
                                                  : selectedImgConfigs.cWidth,
                                              height: !isNaN(selectedImgConfigs.cHeight)
                                                  ? `${selectedImgConfigs.cHeight}px`
                                                  : selectedImgConfigs.cHeight,
                                          }
                                        : {}),
                                }}
                            />
                            <span
                                className="image-resizer bottom-right"
                                onMouseDown={(e) => handleStartResize(e, "bottom-right")}
                                onTouchStart={(e) => handleStartResize(e, "bottom-right")}
                            >
                                <span className="image-resizer-icon"></span>
                            </span>
                        </span>
                    </ImageLabelsContainer>
                ) : (
                    <span className="image-src-wrapper empty">
                        <span className="image-src">
                            <span className="image-title">
                                {labelType ? t("q_imglbls.image_and_content_here") : t("q_imglbls.image_here")}
                            </span>
                        </span>
                    </span>
                )}
            </div>

            <PopConfirm
                type="delete"
                visible={showModal === "confirm-reset"}
                onOk={() => {
                    handleReset();
                    setShowModal(false);
                }}
                onCancel={() => {
                    setShowModal(false);
                }}
                title={t("shared.msg_confirm_reset")}
                okText={t("shared.confirm_reset")}
            />

            <PopupFileManager
                controller={fmController}
                isMultiple={false}
                onSelectFile={(selectedFile) => {
                    if (selectedFile?.id && selectedFile?.src) {
                        if (validateFileBeforeUpload(selectedFile.src).type === "image") {
                            handleUpdateImage(selectedFile);
                            fmController.call("close");
                        } else {
                            const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                            notification.error({
                                message: (
                                    <>
                                        <div>
                                            {`
                                                ${t("message.err_file_extension_not_valid")}.
                                                ${t("message.please_try_another_file_extension")}!
                                            `}
                                        </div>
                                        <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                    </>
                                ),
                            });
                        }
                    }
                }}
            />
        </div>
    );
}

export default ImageWithLabels;
