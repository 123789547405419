import { Col, Form, Input, InputNumber, Modal, Row, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import { default as CustomButton } from "src/modules/components/Button";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";

import { updateGraphElementByData } from "../../helpers";
import "./GraphElemConfigModal.scss";

const GraphElemConfigModal = ({
    mode,
    visible = true,
    className,
    elemData,
    graphHelperRefs,
    onOk,
    onCancel,
    ...rest
}) => {
    const [form] = useForm();

    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmit = () => {
        let formData = form.getFieldsValue();
        // 1. Validate the changes:
        // 2. Submit/cancel the changes:
        // - New data:
        const elemDataNew = {
            id: elemData.id,
            type: elemData.type,
            name: formData[elemData.id]?.name || elemData.name,
            // label: undefined, // String.
            // value: undefined, // Number.
            // values: undefined, // [Number, Number].
            // attrs: undefined, // Object.
        };
        switch (elemDataNew.type) {
            case "point": {
                elemDataNew.values =
                    typeof formData[elemData.id]?.x === "number" && typeof formData[elemData.id]?.y === "number"
                        ? [formData[elemData.id]?.x, formData[elemData.id]?.y]
                        : [...elemData.values];
                break;
            }
            case "segment":
            case "line":
            case "polygon":
            case "vector":
            case "circle":
            case "ellipse": {
                elemDataNew.values = (elemData.values instanceof Array ? elemData.values : []).map((valItem) => {
                    const formValItem = formData[elemData.id]?.[valItem.id];
                    return {
                        id: valItem.id,
                        type: valItem.type,
                        name: formValItem?.name || valItem.name,
                        values:
                            typeof formValItem?.x === "number" && typeof formValItem?.y === "number"
                                ? [formValItem?.x, formValItem?.y]
                                : [...valItem.values],
                    };
                });
                break;
            }
            case "numberline_label": {
                elemDataNew.label = elemData.label;
                elemDataNew.value =
                    typeof formData[elemData.id]?.x === "number" ? formData[elemData.id]?.x : elemData.value;
                break;
            }
            default:
                break;
        }
        // - Submit data:
        const submitResult = updateGraphElementByData(elemDataNew, graphHelperRefs);
        if (submitResult.status) {
            if (onOk instanceof Function) {
                onOk(elemDataNew, submitResult.element);
                notification.success({
                    message: t(`${submitResult.message}`),
                });
            }
        } else {
            notification.error({
                message: t(`${submitResult.message}`),
            });
        }
    };

    useEffect(() => {
        if (elemData) {
            switch (elemData.type) {
                case "point": {
                    form.setFieldsValue({
                        [elemData.id]: {
                            name: elemData.name,
                            x: elemData.values[0],
                            y: elemData.values[1],
                        },
                    });
                    break;
                }
                case "segment":
                case "line":
                case "polygon":
                case "vector":
                case "circle":
                case "ellipse": {
                    if (elemData.values instanceof Array) {
                        const fieldsValue = {
                            [elemData.id]: {},
                        };
                        elemData.values.forEach((val) => {
                            fieldsValue[elemData.id][val.id] = {
                                name: val.name,
                                x: val.values[0],
                                y: val.values[1],
                            };
                        });
                        form.setFieldsValue(fieldsValue);
                    }
                    break;
                }
                case "numberline_label": {
                    form.setFieldsValue({
                        [elemData.id]: {
                            x: elemData.value,
                        },
                    });
                    break;
                }
                default:
                    break;
            }
        }
    }, [elemData]);

    const renderFormItemsForPoints = () => {
        if (elemData) {
            switch (elemData.type) {
                case "point": {
                    return (
                        <Form.Item key={`${elemData.id}`} className="formfield-point">
                            <Form.Item
                                name={[elemData.id, "name"]}
                                rules={[{ required: true, message: "Missing name!" }]}
                                className="formfield-point-name"
                            >
                                <Input className="app-input has-rd" placeholder="Name" readOnly={mode === "solving"} />
                            </Form.Item>
                            <Form.Item
                                name={[elemData.id, "x"]}
                                rules={[{ required: true, message: "Missing x!" }]}
                                className="formfield-point-coord"
                            >
                                <InputNumber className="app-input has-rd" placeholder="x" />
                            </Form.Item>
                            <Form.Item
                                name={[elemData.id, "y"]}
                                rules={[{ required: true, message: "Missing y!" }]}
                                className="formfield-point-coord"
                            >
                                <InputNumber className="app-input has-rd" placeholder="y" />
                            </Form.Item>
                        </Form.Item>
                    );
                }
                case "segment":
                case "line":
                case "polygon":
                case "vector":
                case "circle":
                case "ellipse": {
                    if (elemData.values instanceof Array) {
                        return (
                            <>
                                {elemData.values.map((val) => {
                                    return (
                                        <Form.Item key={`${elemData.id}-${val.id}`} className="formfield-point">
                                            <Form.Item
                                                name={[elemData.id, val.id, "name"]}
                                                rules={[{ required: true, message: "Missing name!" }]}
                                                className="formfield-point-name"
                                            >
                                                <Input
                                                    className="app-input has-rd"
                                                    placeholder="Name"
                                                    readOnly={mode === "solving"}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name={[elemData.id, val.id, "x"]}
                                                rules={[{ required: true, message: "Missing x!" }]}
                                                className="formfield-point-coord"
                                            >
                                                <InputNumber className="app-input has-rd" placeholder="x" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[elemData.id, val.id, "y"]}
                                                rules={[{ required: true, message: "Missing y!" }]}
                                                className="formfield-point-coord"
                                            >
                                                <InputNumber className="app-input has-rd" placeholder="y" />
                                            </Form.Item>
                                        </Form.Item>
                                    );
                                })}
                            </>
                        );
                    }
                    return null;
                }
                case "numberline_label": {
                    return (
                        <Form.Item key={`${elemData.id}`} className="formfield-nllbl">
                            <HTMLDisplayer
                                rootType="block"
                                htmlString={elemData.label}
                                className="formfield-nllbl-label"
                            />
                            <Form.Item
                                name={[elemData.id, "x"]}
                                rules={[{ required: true, message: "Missing position!" }]}
                                className="formfield-nllbl-pos"
                            >
                                <InputNumber className="app-input has-rd" placeholder="Position" />
                            </Form.Item>
                        </Form.Item>
                    );
                }
                default:
                    break;
            }
        }
        return null;
    };

    return (
        <Modal
            centered
            visible={visible}
            title={t("q_graph.update_graph_elem")}
            closeIcon={<SvgClose />}
            footer={null}
            className={
                "app-modal flexible-height type-primary maxw-520 modal-graphelemconfig" +
                (className ? ` ${className}` : "")
            }
            wrapClassName="modal-graphelemconfig-wrap"
            onOk={handleSubmit}
            onCancel={handleCancel}
            {...rest}
        >
            <Form form={form} layout="vertical" className="app-form type-primary has-rd" onFinish={handleSubmit}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>{renderFormItemsForPoints()}</Col>
                </Row>

                <div className="form-actions">
                    <CustomButton
                        type="grey"
                        icon={<SvgCross />}
                        title={t("shared.cancel")}
                        onClick={handleCancel}
                    ></CustomButton>
                    <CustomButton
                        htmlType="submit"
                        type="primary"
                        icon={<SvgTick />}
                        title={t("shared.save")}
                    ></CustomButton>
                </div>
            </Form>
        </Modal>
    );
};

GraphElemConfigModal.propTypes = {
    // JSXGraphBoard's settings:
    mode: PropTypes.oneOf(["creating", "solving"]),
    // JSXGraphBoard's graph data:
    elemData: PropTypes.object,
    // JSXGraphBoard's graph helper refs:
    graphHelperRefs: PropTypes.object,
    // Modal:
    className: PropTypes.string,
    visible: PropTypes.bool,
    // Events:
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default GraphElemConfigModal;
