import React, { useCallback, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import PropTypes from "prop-types";
import { CameraOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import "./WebcamCapture.scss";
import { FaceFrame } from "src/utils/drawer";

const WebcamCapture = ({ height, width, handleCapture, captureNewImage }) => {
    const webcamRef = useRef(null);
    const { t } = useTranslation();

    const videoConstraints = {
        width: { min: height },
        height: { min: width },
        aspectRatio: width / height,
        facingMode: "user",
    };

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (handleCapture) {
            handleCapture(imageSrc);
        }
    }, [webcamRef]);

    useEffect(() => {
        if (captureNewImage?.current) {
            captureNewImage.current = capture;
        }
    });

    return (
        <div className="webcam-capture">
            <div className="webcam-capture_container">
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    height={height}
                    width={width}
                    videoConstraints={videoConstraints}
                    screenshotQuality={1}
                    mirrored={true}
                />
                <div className="face-frame">
                    {/* <span className="face-frame-img"> */}
                    <FaceFrame className="face-frame-img" />
                    {/* </span>/ */}
                    <span className="bg1"></span>
                </div>
                {/* <img className="img-box-frame" src={boxFrameImg} /> */}
            </div>
            <Button onClick={capture} title={t("take_photo")} icon={<CameraOutlined />}></Button>
        </div>
    );
};

WebcamCapture.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    handleCapture: PropTypes.func,
};

WebcamCapture.defaultProps = {
    width: 450,
    height: 600,
};

export default WebcamCapture;
