import { Col, Form, Row, Select, DatePicker, Spin, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useValues from "src/hooks/useValues";
import "./FilterMenu.scss";
import CalanderIcon from "src/assets/images/calander-icon.svg";
import Button from "../../../../components/Button";
import { fetchStudents } from "src/api/containers/user";
import moment from "moment";
import Icon from "src/modules/components/Icon";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import { fetchLanguages } from "src/api/containers/language";
import { fetchCourses, fetchCoursesLevelsAssignments } from "src/api/containers/course";
import { fetchLevels } from "src/api/containers/level";
import Empty from "src/modules/components/Empty";

const formItemsFilterInitParams = {
    language_id: "",
    is_class: 0,
    course_id: "",
    level_id: "",
    student_id: "",
    is_unit: true,
};

const filterMenu = (props) => {
    const {
        isModal = false,
        filterParams = [],
        willFormBeCleared = false,
        handleFetch = () => {},
        handleCloseFilterMenu = () => {},
        setIsCloseModal = () => {},
        isStudent,
    } = props;

    const timeoutIdGetStudents = useRef();

    const [form] = useForm();
    const [values, setValues] = useValues({
        students: [],
        studentPagination: {},
        courses: [],
        levels: [],
        languages: [],
        isFetching: false,
        isFetchingStudents: false,
        isScrollingStudents: false,
        isFetchingCourses: false,
        isFetchingLevels: false,
        isFetchingLanguages: false,
        hide_assignment_type: false,
        formItemsValue: formItemsFilterInitParams,
    });

    const studentFilter = useMemo(() => {
        return filterParams.find((filterParam) => filterParam.name === "student_id");
    }, []);

    const courseFilter = useMemo(() => {
        return filterParams.find((filterParam) => filterParam.name === "course_id");
    }, []);

    const levelFilter = useMemo(() => {
        return filterParams.find((filterParam) => filterParam.name === "level_id");
    }, []);

    // const [currFilterParams, setCurrFilterParams] = useState([]); // Current applied filter values.

    const handleCloseModal = () => {
        handleCloseFilterMenu(false);
    };

    const handleSubmit = () => {
        const formData = form.getFieldsValue();
        const fetchParams = {
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            level_id: formData.level_id,
            course_id: formData.course_id,
            student_id: formData.student_id,
            is_unit: formData.is_unit,
            is_class: formData.is_class,
            language_id: formData.language_id,
        };

        // Save new applied filter values:
        const newFilterParams = [];
        fetchParams.dateFrom &&
            newFilterParams.push({
                name: "dateFrom",
                value: fetchParams.dateFrom,
                labelName: t("report.dateFrom"),
                labelValue: moment(fetchParams.dateFrom).format("YYYY-MM-DD"),
            });
        fetchParams.dateTo &&
            newFilterParams.push({
                name: "dateTo",
                value: fetchParams.dateTo,
                labelName: t("report.dateTo"),
                labelValue: moment(fetchParams.dateTo).format("YYYY-MM-DD"),
            });
        fetchParams.level_id &&
            newFilterParams.push({
                name: "level_id",
                value: fetchParams.level_id,
                labelName: t("report.level"),
                labelValue: values.levels.filter((level) => {
                    return level.id === fetchParams.level_id;
                })[0]?.name,
            });
        fetchParams.course_id &&
            newFilterParams.push({
                name: "course_id",
                value: fetchParams.course_id,
                labelName: t("report.class"),
                labelValue: values.courses.filter((courseItem) => {
                    return courseItem.id === fetchParams.course_id;
                })[0]?.name,
            });
        fetchParams.student_id &&
            newFilterParams.push({
                name: "student_id",
                value: fetchParams.student_id,
                labelName: t("report.student"),
                labelValue: values.students.filter((student) => {
                    return student.id === fetchParams.student_id;
                })[0]?.name,
            });

        newFilterParams.push({
            name: "is_unit",
            value: fetchParams.is_unit,
            labelName: t("report.type_of_excercise"),
            labelValue: fetchParams.is_unit ? t("report.unit") : t("report.assignment"),
        });
        fetchParams.language_id &&
            newFilterParams.push({
                name: "language_id",
                value: fetchParams.language_id,
                labelName: t("language.language"),
                labelValue: values.languages.filter((lang) => {
                    return lang.id === fetchParams.language_id;
                })[0]?.name,
            });
        [0, 1, "0", "1"].includes(fetchParams.is_class) &&
            newFilterParams.push({
                name: "is_class",
                value: fetchParams.is_class,
                labelName: t("shared.type"),
                labelValue:
                    fetchParams.is_class == 0 ? t("report.course") : fetchParams.is_class == 1 ? t("nav.classes") : "",
            });

        // setCurrFilterParams(newFilterParams);
        // Fetch exam list by the corresponding filter values:
        handleCloseFilterMenu(newFilterParams);
    };

    const handleFetchDataForFilter = (data = {}, callback) => {
        const formData = form.getFieldValue();
        // const { level_id, course_id, is_unit,language_id } = formData;

        if (callback instanceof Function) {
            callback();
        }
    };

    function handleSelectCourseType(is_class) {
        if (is_class && values.hide_assignment_type === true) {
            setValues({ hide_assignment_type: false });
            form.setFieldsValue({ is_unit: false });
        } else if (!is_class && values.hide_assignment_type !== true) {
            setValues({ hide_assignment_type: true });
            form.setFieldsValue({ is_unit: true });
        }
    }

    useEffect(() => {
        fetchLanguages({ noPagination: 1 }).then(({ status, data }) => {
            if (status) {
                setValues({
                    languages: data,
                    isFetchingLanguages: false,
                });
            } else {
                setValues({
                    isFetchingLanguages: false,
                });
            }
        });

        fetchLevels({ noPagination: true }).then(({ status, data }) => {
            if (status) {
                setValues({
                    levels: data,
                    isFetchingLevels: false,
                });
            } else {
                setValues({
                    isFetchingLevels: false,
                });
            }
        });
    }, []);

    useEffect(() => {
        const newFormData = {};
        let haveIsClass = false;
        let haveCourse = false;
        for (let i = 0; i < filterParams.length; i++) {
            newFormData[filterParams[i]?.name] = filterParams[i]?.value;
            if (filterParams[i]?.name === "is_class") {
                haveIsClass = true;
            }
            if (filterParams[i]?.name === "course_id") {
                haveCourse = true;
            }
        }

        if (!haveIsClass && haveCourse && newFormData.course_id) {
            delete newFormData.course_id;
        }

        // Update form:
        form.resetFields();
        form.setFieldsValue(newFormData);

        // Refetch data:
        const fetchParams = {
            dateFrom: newFormData.dateFrom,
            dateTo: newFormData.dateTo,
            level_id: newFormData.level_id,
            course_id: newFormData.course_id,
            student_id: newFormData.student_id,
            is_unit: newFormData.is_unit,
            language_id: newFormData.language_id,
            is_class: newFormData.is_class,
        };

        if (!isModal) {
            if (!fetchParams.student_id) {
                notification.warning({ message: t("report.need_select_student") });
            } else {
                handleFetch({ page: 1, ...fetchParams });
            }
        }
        // setCurrFilterParams(filterParams);
    }, [filterParams]);

    const handleChangeLang = (language_id) => {
        setValues({
            formItemsValue: {
                ...formItemsFilterInitParams,
                language_id: language_id,
            },
        });
        form.resetFields(["level_id", "is_class", "course_id", "student_id"]);
    };

    const handleFetchCourses = (params, callback, isScrolling) => {
        setValues({ isFetchingCourses: true, isScrollingCourse: isScrolling });
        params.noPagination = true;
        fetchCourses(params)
            .then((res) => {
                if (res.status) {
                    setValues({
                        courses: isScrolling ? [...values.courses, ...res.data] : res.data,
                        coursePagination: res.pagination,
                    });
                    if (callback instanceof Function) {
                        callback();
                    }
                }
            })
            .finally(() => {
                setValues({ isFetchingCourses: false, isScrollingCourse: false });
            });
    };

    const handleFetchStudents = (params, callback, isScrolling) => {
        setValues({ isFetchingStudents: true, isScrollingStudents: isScrolling });
        params.role = "student";
        params.perPage = 20;

        fetchStudents(params)
            .then((res) => {
                if (res.status) {
                    setValues({
                        students: isScrolling ? [...values.students, ...res.data] : res.data,
                        studentPagination: res.pagination,
                    });
                    if (callback instanceof Function) {
                        callback();
                    }
                }
            })
            .finally(() => {
                setValues({ isFetchingStudents: false, isScrollingStudents: false });
            });
    };

    const handleScrollStudents = (e) => {
        if (values.isFetchingStudents) {
            return;
        }

        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8) {
            const nextPageToLoad = values.studentPagination?.current + 1;

            if (nextPageToLoad > values.studentPagination.lastPage) {
                return;
            }

            const formData = form.getFieldValue();

            handleFetchStudents(
                {
                    course_id: formData.course_id,
                    page: nextPageToLoad,
                },
                () => {},
                true
            );
        }
    };

    useEffect(() => {
        const formData = form.getFieldValue();

        if (values.formItemsValue.language_id) {
            handleFetchCourses({
                language_id: formData.language_id,
                is_class: formData.is_class,
                level_id: formData.level_id,
            });
        }
    }, [values.formItemsValue.is_class, values.formItemsValue.language_id, values.formItemsValue.level_id]);

    useEffect(() => {
        if (values.formItemsValue?.course_id) {
            handleFetchStudents({
                course_id: values.formItemsValue.course_id,
            });
        }
    }, [values.formItemsValue.course_id]);

    const handleSearchStudents = (keyword) => {
        const formData = form.getFieldsValue();
        clearTimeout(timeoutIdGetStudents.current);
        if (typeof keyword === "string") {
            setValues({
                isFetchingStudents: "searching",
            });

            timeoutIdGetStudents.current = setTimeout(() => {
                handleFetchStudents(
                    {
                        course_id: formData.course_id,
                        slug: keyword,
                        page: 1,
                    },
                    () => {},
                    false
                );
            }, 500);
        }
    };

    return (
        <div className="filter-personal-learning-progress_wrapper">
            <Spin spinning={values.isFetching}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    className="form form-full-label filter-personal-learning-progress_inner scroll_primary"
                >
                    <Row>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="dateFrom"
                                label={
                                    <>
                                        <span>{t("report.dateFrom")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_datefrom")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="dateTo"
                                label={
                                    <>
                                        <span>{t("report.dateTo")}</span>
                                        <CustomTooltip
                                            type="question"
                                            placement="right"
                                            title={t("report.asgmt_dateto")}
                                            style={{ order: "4", marginLeft: "4px" }}
                                        />
                                    </>
                                }
                            >
                                <DatePicker
                                    placeholder={t("report.please_select_date")}
                                    className="filter-person_input"
                                    suffixIcon={<img src={CalanderIcon} />}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="language_id"
                                label={t("language.language")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    className="filter-person_selector"
                                    listHeight={150}
                                    placeholder={t("language.select_language")}
                                    allowClear
                                    loading={values.isFetchingLanguages}
                                    onChange={(language_id) => handleChangeLang({ language_id })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                >
                                    {values?.languages?.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item name="level_id" label={t("report.level")}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    onChange={(level_id) =>
                                        setValues({ formItemsValue: { ...values.formItemsValue, level_id } })
                                    }
                                    className="filter-person_selector"
                                    placeholder={t("report.please_select_level")}
                                    // dropdownClassName="filter-limit-height"
                                    allowClear
                                    dropdownMatchSelectWidth={false}
                                    disabled={!values.formItemsValue.language_id}
                                >
                                    {values.levels?.map((level) => (
                                        <Select.Option key={level.id} value={level.id}>
                                            {level.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="is_class"
                                label={t("question.type")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                                initialValue={values.formItemsValue.is_class}
                            >
                                <Select
                                    showSearch
                                    className="filter-person_selector"
                                    placeholder={t("report.select_course_or_class")}
                                    allowClear
                                    disabled={!values.formItemsValue.language_id}
                                    onChange={(is_unit) => {
                                        setValues({
                                            formItemsValue: {
                                                ...values.formItemsValue,
                                                is_unit: is_unit,
                                            },
                                        });
                                        handleSelectCourseType(is_class);
                                    }}
                                >
                                    <Select.Option value={0}>{t("course.course")}</Select.Option>
                                    <Select.Option value={1}>{t("nav.classes")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={12} xs={24}>
                            <Form.Item
                                name="course_id"
                                label={
                                    form.getFieldValue("is_class") == 0
                                        ? t("report.course")
                                        : form.getFieldValue("is_class") == 1
                                        ? t("nav.classes")
                                        : t("report.need_select_type_before")
                                }
                                required
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    listHeight={200}
                                    optionFilterProp="children"
                                    onChange={(course_id) =>
                                        setValues({ formItemsValue: { ...values.formItemsValue, course_id } })
                                    }
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    className="filter-person_selector"
                                    placeholder={
                                        form.getFieldValue("is_class") == 0
                                            ? t("gradebook.please_select_course")
                                            : form.getFieldValue("is_class") == 1
                                            ? t("report.please_select_class")
                                            : " "
                                    }
                                    disabled={!values.formItemsValue.language_id}
                                    dropdownMatchSelectWidth={false}
                                    loading={values.isFetchingCourses}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}

                                            {values.isFetchingCourses === "searching" ? (
                                                <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                    <Spin style={{ marginRight: "10px" }} />{" "}
                                                    {`${t("shared.searching")}...`}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                    notFoundContent={
                                        values.isFetchingCourses ? (
                                            <i className="notfound-when-loading">
                                                <Spin style={{ marginRight: "10px" }} /> {`${t("shared.loading")}...`}
                                            </i>
                                        ) : (
                                            <Empty />
                                        )
                                    }
                                >
                                    {values.courses?.map((courseItem) => (
                                        <Select.Option key={courseItem.id} value={courseItem.id}>
                                            {courseItem.name}
                                        </Select.Option>
                                    ))}
                                    {/* Fix show id if api classes is pending */}
                                    {courseFilter?.value && !values.courses?.length && values.isFetchingCourses && (
                                        <Select.Option value={courseFilter.value}>
                                            {courseFilter.labelValue}
                                        </Select.Option>
                                    )}
                                    loading={values.isFetchingCourses}
                                </Select>
                            </Form.Item>
                        </Col>

                        {!isStudent && (
                            <Col sm={12} lg={8} md={12} xs={24}>
                                <Form.Item
                                    name="student_id"
                                    label={t("report.student_list")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("message.required"),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        listHeight={200}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return (
                                                option.children &&
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            );
                                        }}
                                        className="filter-person_selector"
                                        placeholder={t("report.please_select_student")}
                                        allowClear
                                        dropdownMatchSelectWidth={false}
                                        disabled={!values.formItemsValue.course_id}
                                        onSearch={handleSearchStudents}
                                        onPopupScroll={handleScrollStudents}
                                        loading={values.isFetchingStudents}
                                        dropdownRender={(menu) => (
                                            <>
                                                {values.isFetchingStudents === "searching" ? (
                                                    <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                        <Spin style={{ marginRight: "10px" }} />{" "}
                                                        {`${t("shared.searching")}...`}
                                                    </div>
                                                ) : null}
                                                {menu}
                                                {values.isScrollingStudents === true && values.students.length ? (
                                                    <div style={{ padding: "5px 12px", fontStyle: "italic" }}>
                                                        <Spin style={{ marginRight: "10px" }} />{" "}
                                                        {`${t("shared.loading")}...`}
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                        notFoundContent={
                                            values.isFetchingCourses === true ? (
                                                <i className="notfound-when-loading">
                                                    <Spin style={{ marginRight: "10px" }} />{" "}
                                                    {`${t("shared.loading")}...`}
                                                </i>
                                            ) : values.isFetchingCourses === "searching" ? (
                                                <React.Fragment></React.Fragment>
                                            ) : (
                                                <Empty />
                                            )
                                        }
                                    >
                                        {values.students?.map((student) => (
                                            <Select.Option key={student.id} value={student.id}>
                                                {student.name || t("no_name_yet")}
                                            </Select.Option>
                                        ))}
                                        {/* Fix show id if api assignments is pending */}
                                        {studentFilter?.value &&
                                            !values.students?.length &&
                                            values.isFetchingStudents && (
                                                <Select.Option value={studentFilter.value}>
                                                    {studentFilter.labelValue}
                                                </Select.Option>
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col sm={12} lg={8} md={12} xs={24}>
                            <Form.Item
                                name="is_unit"
                                label={t("report.type_of_exercise")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("message.required"),
                                    },
                                ]}
                                hidden={values.hide_assignment_type}
                                initialValue={formItemsFilterInitParams.is_unit}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return (
                                            option.children &&
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    className="filter-person_selector"
                                    placeholder={t("report.please_select_type_of_exercise")}
                                    allowClear
                                    dropdownMatchSelectWidth={false}
                                    onChange={(is_unit) =>
                                        setValues({
                                            formItemsValue: {
                                                ...values.formItemsValue,
                                                is_unit: is_unit,
                                            },
                                        })
                                    }
                                >
                                    <Select.Option value={false}>{t("report.assignment")}</Select.Option>
                                    <Select.Option value={true}>{t("report.unit")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="filter_btn">
                        <Button
                            onClick={handleCloseModal}
                            type="grey"
                            icon={<Icon name="icon-delete" />}
                            title={t("report.cancel")}
                        ></Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icon name="icon-rocket" />}
                            title={t("report.apply")}
                        ></Button>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default filterMenu;
