import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";

import { fontSizeList, getFontList, lineHeightList } from "./fonts";

const editorEditingMode = {
    classic: {
        key: "editorcm",
    },
    inline: {
        key: "editorim",
    },
};

const showNotification = (editor, config = {}) => {
    const { title, message, onCancel } = config;

    const dialog = {
        title: title,
        body: {
            type: "panel",
            items: [
                {
                    type: "htmlpanel",
                    html: message,
                },
            ],
        },
        buttons: [
            {
                type: "cancel",
                text: "OK",
                primary: true,
            },
        ],
        onCancel: function (api) {
            api.close();
            if (onCancel instanceof Function) {
                onCancel();
            }
        },
    };

    editor.windowManager.open(dialog);
};

export const editorHtmlStructure = {
    root: {
        containerClassName: "editor-root",
    },
    toolbar: {
        containerClassName: "editor-toolbar-container",
    },
    content: {
        containerClassName: "editor-body-container",
    },
};

/**
 * Editor (default).
 * IMPORTANT NOTES:
 * - DON'T CHANGE ANY THING HERE! IF YOU WANT TO MODIFY SOMETHING, CREATE A NEW CONFIG FILE!
 * - There are 2 main types of editor: input, textarea.
 * @param {Object} values Custom config values.
 * @returns Editor's config.
 */
export const generateConfig = (values = {}) => {
    const {
        customId,
        customTypeName,
        // How editor is displayed:
        editingMode = "inline",
        isToolbarInsideEditorRoot = false, // Should be false, because editor may be placed inside a container that has 'overflow: hidden;'.
        // Counting & limiting words/characters:
        wordCountMax,
        onCountWord,
    } = values;

    // Language:
    const language = localStorage.getItem("lang") || "vi";
    // Editor identification:
    // - Info:
    const editorId = customId || uuidv4();
    const editorMode = editorEditingMode[editingMode].key;
    const editorName = customTypeName || "default";
    // - Element's id/class:
    const editorRootClassName = `${editorMode}-root--${editorName}`;
    const editorToolbarClassName = `${editorMode}-toolbar--${editorName}`;
    const editorContentClassName = `${editorMode}-content--${editorName}`;
    const editorRootId = `${editorRootClassName}-${editorId}`;
    const editorToolbarId = `${editorToolbarClassName}-${editorId}`;

    return {
        language,

        // Indentification:
        ...(() => {
            return {
                // TinyMCE's configs:
                fixed_toolbar_container: isToolbarInsideEditorRoot
                    ? `#${editorRootId} .${editorHtmlStructure.toolbar.containerClassName}`
                    : false,
                // Custom configs:
                rootId: editorRootId,
                rootClassName: editorRootClassName,
            };
        })(),

        // Editing mode:
        inline: editingMode === "inline" ? true : false,

        // Style:
        content_css: ["/timymce_content.css", "/content.css", "/content_elements.css"],
        // content_style: '.myclassname{ color: #fff; }',
        skin: false,

        // Plugins, toolbar, menubar:
        plugins:
            "paste-options" +
            " lists advlist" +
            " image imagetools media file-manager" +
            " insertdatetime link anchor table charmap math codesample hr" +
            " searchreplace wordcount" +
            " directionality visualblocks visualchars" +
            " preview" +
            " nonbreaking pagebreak" +
            " quickbars help",
        toolbar:
            "undo redo" +
            " | searchreplace" +
            " | bold italic underline strikethrough removeformat" +
            " | fontselect fontsizeselect formatselect lineheight" +
            " | alignleft aligncenter alignright alignjustify" +
            " | outdent indent" +
            " | bullist numlist" +
            " | subscript superscript" +
            " | forecolor backcolor" +
            " | paste-options" +
            " | image media file-manager" +
            " | link table charmap math",
        toolbar_mode: "sliding",
        menubar: "edit insert view format table tools help",
        contextmenu: "image imagetools link table",
        quickbars_selection_toolbar: "bold italic underline strikethrough | forecolor backcolor removeformat",
        quickbars_insert_toolbar: false,

        // Formatting:
        font_formats: getFontList(),
        fontsize_formats: fontSizeList,
        lineheight_formats: lineHeightList,

        // Settings:
        // relative_urls: true,
        paste_as_text: false, // IMPORTANT NOTE: This must be false!
        forced_root_block: "p", // IMPORTANT NOTE: This <p> tag is being widely used. SO, DON'T CHANGE IT!
        forced_root_block_attrs: {}, // IMPORTANT NOTE: DO NOTHING HERE!

        // Elements:
        extended_valid_elements:
            "fillbox[contenteditable|id]" + ",math-static[contenteditable|id|class]" + ",highlight[contenteditable|id]",
        custom_elements:
            "fillbox[contenteditable|id]" + ",math-static[contenteditable|id|class]" + ",highlight[contenteditable|id]",

        // Others:
        deprecation_warnings: false,

        // Custom settings:
        setup: (editor) => {
            /**
             * 1. Setup editor's identification.
             */
            editor.on("init", function () {
                /**
                 * [Editor editing mode: inline]
                 */
                switch (editingMode) {
                    case "inline": {
                        const elemEditorContent = editor.targetElm;
                        const elemEditorToolbar = editor.editorContainer;
                        // Editor's content element:
                        elemEditorContent.classList.add(editorContentClassName);
                        // Editor's toolbar element:
                        elemEditorToolbar.id = editorToolbarId;
                        elemEditorToolbar.classList.add(editorToolbarClassName);
                        elemEditorToolbar.style.setProperty(
                            "--max-width",
                            `${elemEditorContent.getBoundingClientRect().width}px`
                        );
                        break;
                    }
                    default:
                        break;
                }
            });

            /**
             * 2. Setup editor's display.
             */
            editor.on("focus", function () {
                /**
                 * [Editor editing mode: inline]
                 */
                switch (editingMode) {
                    case "inline": {
                        const elemEditorContent = editor.targetElm;
                        const elemEditorToolbar = editor.editorContainer;
                        // Editor's toolbar element:
                        elemEditorToolbar.style.setProperty(
                            "--max-width",
                            `${elemEditorContent.getBoundingClientRect().width}px`
                        );
                        break;
                    }
                    default:
                        break;
                }
            });

            /**
             * 3. Setup editor's behaviour: Counting & limiting words/characters.
             */
            if (wordCountMax) {
                let editorContentPrev = undefined;
                let isNotificationDisplayed = false;
                editor.on("keyup", function (e) {
                    // Ctrl + Key will make this event handler run twice, so we should block one here!
                    if (e.key === "Control") {
                        return;
                    }

                    // Counting & limiting number of words:
                    const wordcount = editor.plugins.wordcount;
                    if (wordcount) {
                        // Check number of words:
                        const wordCountCurr = wordcount.body.getWordCount();
                        if (wordCountCurr > wordCountMax) {
                            e.preventDefault();
                            // 1. Show notification:
                            if (!isNotificationDisplayed) {
                                isNotificationDisplayed = true;
                                showNotification(editor, {
                                    title: t("shared.warning"),
                                    message: t("editor.wordcountmax_reached"),
                                    onCancel: () => {
                                        isNotificationDisplayed = false;
                                    },
                                });
                            }
                            // 2. Change the content to the previous one:
                            editor.setContent("");
                            if (editorContentPrev) {
                                editor.insertContent(editorContentPrev);
                            }
                        }
                        editorContentPrev = editor.getContent();
                        // Other event handlers:
                        if (onCountWord instanceof Function) {
                            onCountWord(wordCountCurr);
                        }
                    }
                });
            }
        },
    };
};
