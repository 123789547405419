import { Input, notification } from "antd";
import clsx from "clsx";
import PopupFileManager from "src/modules/components/FileManager/popup";
import Audio from "src/modules/components/Audio";
import InputSuffix from "src/modules/components/InputSuffix";
import { fileManagerConfigs, validateFileBeforeUpload } from "src/modules/components/FileManager/utils/helpers";
import { t } from "i18next";
import { useRef } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { BinIcon } from "src/utils/drawer";
import "./PhoneticTable.scss";

const MultipleChoiceTable = (props) => {
    const { value, onChange } = props;
    const fmController = PopupFileManager.useController();
    const tableIndex = useRef(null);

    const handleOpenFileManager = (e) => {
        const rowIndex = e.currentTarget.dataset.irow;
        const colIndex = e.currentTarget.dataset.icol;

        tableIndex.current = {
            rowIndex,
            colIndex,
        };

        fmController.call("open");
    };

    const handleChangeSelectedFile = (selectedFile) => {
        const { rowIndex, colIndex } = tableIndex.current;
        const result = [...value];

        result[rowIndex][colIndex] = selectedFile.src;

        onChange(result);
    };

    const handleChangeValue = (e) => {
        const result = [...value];
        const newValue = e.target.value;
        const rowIndex = e.target.dataset.irow;
        const colIndex = e.target.dataset.icol;

        try {
            result[rowIndex][colIndex] = newValue;

            onChange(result);
        } catch (err) {
            throw new Error("Have errors", err);
        }
    };

    const handleAddCol = () => {
        const result = [...value];

        result.forEach((col) => {
            col.push("");
        });

        onChange(result);
    };
    const handleAddRow = () => {
        const result = [...value];

        const lengthOfARow = result[0].length;

        result.push(Array(lengthOfARow).fill(undefined));

        onChange(result);
    };

    const handleDeleteCol = (iCol) => {
        const result = [...value];
        if (iCol >= 2) {
            result.forEach((row) => {
                row.splice(iCol, 1);
            });

            onChange(result);
        } else {
            throw new Error("Coloumn is not valid");
        }
    };

    const handleDeleteRow = (iRow) => {
        const result = [...value];

        if (iRow >= 1) {
            result.splice(iRow, 1);

            onChange(result);
        } else {
            throw new Error("Row is not valid");
        }
    };

    return (
        <>
            <div className="detail_phonetic_table">
                <div className="phonetictable__table-skin">
                    <table className="phonetictable__table__skeleton">
                        <tbody>
                            {value?.map((vRow, iRow) => {
                                return (
                                    <tr className={clsx(iRow === 0 && "sticky-row")} key={iRow}>
                                        {vRow.map((vCol, iCol) => {
                                            if (iCol === 0 && iRow === 0) {
                                                return (
                                                    <td key={iCol} className="sticky-col first-col">
                                                        <div></div>
                                                    </td>
                                                );
                                            }

                                            if (iCol === 1) {
                                                if (iRow === 0)
                                                    return (
                                                        <td key={iCol}>
                                                            <div className="title-col">{t("audio")}</div>
                                                        </td>
                                                    );
                                                return (
                                                    <td key={iCol}>
                                                        <div className="audio-item">
                                                            <span>{vCol && <Audio src={vCol} />}</span>
                                                            <span
                                                                data-irow={iRow}
                                                                data-icol={iCol}
                                                                onClick={handleOpenFileManager}
                                                            >
                                                                <CloudUploadOutlined />
                                                            </span>
                                                        </div>
                                                    </td>
                                                );
                                            }

                                            return (
                                                <td className={clsx(iCol === 0 && "sticky-col first-col")} key={iCol}>
                                                    <div>
                                                        <InputSuffix
                                                            data-irow={iRow}
                                                            data-icol={iCol}
                                                            value={vCol}
                                                            onChange={handleChangeValue}
                                                            suffix={
                                                                (iRow == 0 || iCol === 0) && (
                                                                    <BinIcon
                                                                        className="multiplechoicetable__correct_answer__delete"
                                                                        onClick={() => {
                                                                            if (iCol === 0) handleDeleteRow(iRow);
                                                                            if (iRow === 0) handleDeleteCol(iCol);
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            );
                                        })}

                                        {iRow === 0 && (
                                            <td style={{ textAlign: "center" }}>
                                                <button className="add_button" type="button" onClick={handleAddCol}>
                                                    +
                                                </button>
                                            </td>
                                        )}

                                        {iRow !== 0 && <td></td>}
                                    </tr>
                                );
                            })}

                            <tr>
                                <td colSpan="100%">
                                    <div className="text-center">
                                        <button className="add_button" type="button" onClick={handleAddRow}>
                                            +
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <PopupFileManager
                controller={fmController}
                isMultiple={false}
                onSelectFile={(selectedFile, params) => {
                    if (selectedFile?.id && selectedFile?.src) {
                        if (validateFileBeforeUpload(selectedFile.src).type === "audio") {
                            handleChangeSelectedFile(selectedFile, params);
                            fmController.call("close");
                        } else {
                            const str = fileManagerConfigs.imageAcceptedExtensionList.join(", ");
                            notification.error({
                                message: (
                                    <>
                                        <div>{`
                                            ${t("message.err_file_extension_not_valid")}.
                                            ${t("message.please_try_another_file_extension")}!
                                        `}</div>
                                        <i>{`(${t("message.file_extension_supported")}: ${str})`}</i>
                                    </>
                                ),
                            });
                        }
                    }
                }}
            />
        </>
    );
};

export default MultipleChoiceTable;
