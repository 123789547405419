import JXG from "jsxgraph";

import { roundToStepForArray } from "src/utils/helpers/number";

import { graphElements } from "../configs";

/**
 * Set events to a point:
 * @param {Object} point JXG.Point.
 * @param {Object} pointOwner JXG.GeometryElement.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * > - params.config = { coordsStep }.
 * > - params.eventHandlers = { onUpdate }.
 */
export const setPointEvents = (point, pointOwner, params = {}) => {
    // Params:
    const { jxgBoard, config, eventHandlers } = params;

    // Events:
    let selectedPointId;
    let selectedPointPos;
    point.on("drag", function (event) {
        if (!selectedPointId) {
            selectedPointId = point.id;
            selectedPointPos = point.coords.usrCoords.slice(1);
        }
    });
    point.on("up", function (event) {
        if (point.id === selectedPointId) {
            const coords = jxgBoard.getUsrCoordsOfMouse(event);
            const coordsRounded = roundToStepForArray(coords, config?.coordsStep || [1, 1]);
            point.setPositionDirectly(JXG.COORDS_BY_USER, coordsRounded);
            jxgBoard.update();

            // Clean up:
            selectedPointId = undefined;
            // Trigger custom event handlers:
            eventHandlers?.onUpdate?.(pointOwner || point);
        }
    });
};

/**
 * Set events to a segment/line:
 * @param {Object} segment JXG.Line.
 * @param {Object} segmentOwner JXG.GeometryElement.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * > - params.config = { coordsStep }.
 * > - params.eventHandlers = { onUpdate }.
 */
export const setSegmentEvents = (segment, segmentOwner, params = {}) => {
    // Params:
    const { jxgBoard, config, eventHandlers } = params;

    // Events:
    let selectedSegmentId;
    let selectedSegmentPos1,
        selectedSegmentPos2 = [];
    segment.on("drag", function (event) {
        if (!selectedSegmentId) {
            selectedSegmentId = segment.id;
            selectedSegmentPos1 = [segment.point1.X(), segment.point1.Y()];
            selectedSegmentPos2 = [segment.point2.X(), segment.point2.Y()];
        }
    });
    segment.on("up", function (event) {
        if (segment.id === selectedSegmentId) {
            const coords1 = [segment.point1.X(), segment.point1.Y()];
            const coords2 = [segment.point2.X(), segment.point2.Y()];
            const coords1Rounded = roundToStepForArray(coords1, config?.coordsStep || [1, 1]);
            const coords2Rounded = roundToStepForArray(coords2, config?.coordsStep || [1, 1]);
            segment.point1.setPositionDirectly(JXG.COORDS_BY_USER, coords1Rounded);
            segment.point2.setPositionDirectly(JXG.COORDS_BY_USER, coords2Rounded);
            jxgBoard.update();

            // Clean up:
            selectedSegmentId = undefined;
            // Trigger custom event handlers:
            eventHandlers?.onUpdate?.(segmentOwner || segment);
        }
    });
};

/**
 * Set events to a circle:
 * @param {Object} circle JXG.Circle.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * > - params.config = { coordsStep }.
 * > - params.eventHandlers = { onUpdate }.
 */
export const setCircleEvents = (circle, params = {}) => {
    // Params:
    const { jxgBoard, config, eventHandlers } = params;

    // Events:
    let selectedCircleId;
    let selectedCirclePos1,
        selectedCirclePos2 = [];
    circle.on("drag", function (event) {
        if (!selectedCircleId) {
            selectedCircleId = circle.id;
            selectedCirclePos1 = [circle.center.X(), circle.center.Y()];
            selectedCirclePos2 = [circle.point2.X(), circle.point2.Y()];
        }
    });
    circle.on("up", function (event) {
        if (circle.id === selectedCircleId) {
            const coords1 = [circle.center.X(), circle.center.Y()];
            const coords2 = [circle.point2.X(), circle.point2.Y()];
            const coords1Rounded = roundToStepForArray(coords1, config?.coordsStep || [1, 1]);
            const coords2Rounded = roundToStepForArray(coords2, config?.coordsStep || [1, 1]);
            circle.center.setPositionDirectly(JXG.COORDS_BY_USER, coords1Rounded);
            circle.point2.setPositionDirectly(JXG.COORDS_BY_USER, coords2Rounded);
            jxgBoard.update();

            // Clean up:
            selectedCircleId = undefined;
            // Trigger custom event handlers:
            eventHandlers?.onUpdate?.(circle);
        }
    });
};

/**
 * Set events to a text:
 * @param {Object} text JXG.Text.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * > - params.config = { coordsStep }.
 * > - params.eventHandlers = { onUpdate }.
 * > - params.fixedX: Number.
 * > - params.fixedY: Number.
 */
export const setTextEvents = (text, params = {}) => {
    // Params:
    const { jxgBoard, config, eventHandlers, fixedX, fixedY } = params;

    // Specification:
    let qSelectorWrap;
    let qSelectorPos;
    let qSelectorRmVal;
    let setDisplayPos = () => {};
    [".numberline-label-wrapper"].forEach((item) => {
        if (text.rendNode?.querySelector(item)) {
            switch (item) {
                case ".numberline-label-wrapper": {
                    qSelectorWrap = ".numberline-label-wrapper";
                    qSelectorPos = ".numberline-label-wrapper .label-pos";
                    qSelectorRmVal = ".numberline-label-wrapper .label-btn-out";
                    setDisplayPos = (htmlElement, x, y) => {
                        htmlElement.innerHTML = x;
                    };
                    break;
                }
                default:
                    break;
            }
        }
    });

    // Events:
    let selectedPointId;
    let selectedPointPos;
    text.on("drag", function (event) {
        if (!selectedPointId) {
            selectedPointId = text.id;
            selectedPointPos = text.coords.usrCoords.slice(1);
        }
    });
    text.on("up", function (event) {
        if (text.id === selectedPointId) {
            // 1. Position info:
            const textDisplay = text.rendNode?.querySelector(qSelectorWrap);
            const labelWidth = textDisplay.offsetWidth;
            const labelHeight = textDisplay.offsetHeight;
            const labelTop = textDisplay.getBoundingClientRect().top;
            const labelLeft = textDisplay.getBoundingClientRect().left;
            const labelTopExact = labelTop + labelHeight + 8;
            const labelLeftExact = labelLeft + labelWidth / 2;
            const fakeMouseEvent = new MouseEvent("mousemove", {
                clientX: labelLeftExact,
                clientY: labelTopExact,
            });

            // 2. Update position:
            let coords = jxgBoard.getUsrCoordsOfMouse(fakeMouseEvent) || jxgBoard.getUsrCoordsOfMouse(event);
            if (typeof fixedX === "number") {
                coords[0] = fixedX;
            }
            if (typeof fixedY === "number") {
                coords[1] = fixedY;
            }
            const coordsRounded = roundToStepForArray(coords, config?.coordsStep || [1, 1]);
            text.setPositionDirectly(JXG.COORDS_BY_USER, coordsRounded);
            jxgBoard.update();

            // 3. Update point's position displayer:
            const textDisplayPos = text.rendNode?.querySelector(qSelectorPos);
            setDisplayPos(textDisplayPos, coordsRounded[0], coordsRounded[1]);

            // Clean up:
            selectedPointId = undefined;
            // Trigger custom event handlers:
            eventHandlers?.onUpdate?.(text);
        }
    });

    // Other events:
    const textDisplayRmVal = text.rendNode?.querySelector(qSelectorRmVal);
    if (textDisplayRmVal) {
        textDisplayRmVal.addEventListener("click", (e) => {
            // Trigger custom event handlers:
            eventHandlers?.onRemoveValue?.(text);
        });
    }
};

/**
 * Get graph element's data.
 * (JXG.GeometryElement => Data).
 * @param {Object} graphElement JXG.GeometryElement.
 * @param {Object} customConfig List of custom config values to used on the returned data.
 * > - customConfig.type: Type of graph element.
 * > - customConfig.label: Label of graph element (numberline_label).
 * @returns Graph element's data.
 */
export const getDataOfGraphElement = (graphElement, customConfig = {}) => {
    // Params validation:
    // - Check if the element is valid.
    // - Check if ....
    if (!(graphElement && graphElement.id && graphElement.elType)) {
        return;
    }

    // Handle creating element's data:
    const data = {
        id: graphElement.id,
        type: graphElement.elType,
        name: graphElement.name,
        // label: undefined, // String.
        // value: undefined, // Number.
        // values: undefined, // [Number, Number].
        // attrs: undefined, // Object.
    };
    switch (graphElement?.elType) {
        case "point": {
            data.values = [graphElement.X(), graphElement.Y()];
            break;
        }
        case "segment":
        case "line": {
            data.name = graphElement.point1.name + graphElement.point2.name;
            data.values = [getDataOfGraphElement(graphElement.point1), getDataOfGraphElement(graphElement.point2)];
            break;
        }
        case "polygon": {
            data.name = Object.keys(graphElement.ancestors)
                .map((itemKey) => {
                    return graphElement.ancestors[itemKey].name;
                })
                .join("");
            data.values = Object.keys(graphElement.ancestors).map((itemKey) => {
                return getDataOfGraphElement(graphElement.ancestors[itemKey]);
            });
            break;
        }
        case "circle": {
            data.name = `{${graphElement.center.name}, ${graphElement.center.name + graphElement.point2.name}}`;
            data.values = [getDataOfGraphElement(graphElement.center), getDataOfGraphElement(graphElement.point2)];
            break;
        }
        case "arrow": {
            data.type = customConfig?.type || "arrow"; // Values: "vector", "ray".
            switch (customConfig?.type) {
                case "vector": {
                    data.name = graphElement.point1.name + graphElement.point2.name;
                    data.values = [
                        getDataOfGraphElement(graphElement.point1),
                        getDataOfGraphElement(graphElement.point2),
                    ];
                    break;
                }
                // case "ray": {
                //     break;
                // }
                default:
                    break;
            }
            break;
        }
        case "curve": {
            data.type = customConfig?.type || "curve"; // Values: "ellipse", "parabola", "parabola3", "hyperbola".
            switch (customConfig?.type) {
                case "ellipse": {
                    const pointNames = [];
                    const dataValues = [];
                    Object.keys(graphElement.ancestors).forEach((itemKey, itemIndex) => {
                        const item = graphElement.ancestors[itemKey];
                        if (item?.elType === "point" && item?.name) {
                            pointNames.push(item?.name);
                            dataValues.push(getDataOfGraphElement(item));
                        }
                    });
                    data.name = pointNames?.length ? `(${pointNames.join(", ")})` : "";
                    data.values = dataValues.filter((item) => !!item);
                    break;
                }
                // case "parabola3": {
                //     Object.keys(graphElement.ancestors).forEach((itemKey) => {
                //         const item = graphElement.ancestors[itemKey];
                //         if (item?.elType === "line" && item?.name) {
                //             data.values = [getDataOfGraphElement(item.point1), getDataOfGraphElement(item.point2)];
                //         }
                //     });
                //     if (data.values?.length === 2) {
                //         Object.keys(graphElement.ancestors).forEach((itemKey) => {
                //             const item = graphElement.ancestors[itemKey];
                //             if (item?.elType === "point" && item?.name
                //                 && item?.name !== data.values[0].name && item?.name !== data.values[1].name) {
                //                 data.values.push(getDataOfGraphElement(item));
                //             }
                //         });
                //     }
                //     break;
                // }
                // case "hyperbola": {
                //     data.values = Object.keys(graphElement.ancestors).map((itemKey) => {
                //         const item = graphElement.ancestors[itemKey];
                //         if (item?.elType === "point" && item?.name) {
                //             return getDataOfGraphElement(item);
                //         }
                //         return false;
                //     }).filter((item) => !!item);
                //     break;
                // }
                default:
                    break;
            }
            break;
        }
        case "text": {
            data.type = customConfig?.type || "text"; // Values: "numberline_label".
            switch (customConfig?.type) {
                case "numberline_label": {
                    delete data.name;
                    data.label =
                        customConfig.label ||
                        graphElement.rendNode?.querySelector(".numberline-label-wrapper .label").innerHTML;
                    data.value = graphElement.X();
                    break;
                }
                default:
                    break;
            }
            break;
        }
        default:
            break;
    }
    return data;
};

/**
 * Create graph element by its data.
 * (Data => JXG.GeometryElement).
 * @param {Object} graphElementData Graph element's data.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * > - params.config = { coordsStep }.
 * > - params.eventHandlers = { onUpdate }.
 * @returns JXG.GeometryElement.
 */
export const createGraphElementByData = (graphElementData, params = {}) => {
    // Params:
    const {
        jxgBoard,
        // config,
        // eventHandlers,
    } = params;

    // Params validation:
    // - Check if the position (coords) is valid.
    // - Check if ....
    const checkElemDataValue = (elemData) => {
        if (typeof elemData.value !== "number") {
            return false;
        }
        return true;
    };
    const checkElemDataValues = (elemData) => {
        if (elemData.values?.length < 2) {
            return false;
        }
        if (typeof elemData.values[0] !== "number" || typeof elemData.values[1] !== "number") {
            return false;
        }
        return true;
    };
    switch (graphElementData?.type) {
        case "point": {
            if (!checkElemDataValues(graphElementData)) {
                return;
            }
            break;
        }
        case "segment":
        case "line":
        case "polygon":
        case "vector":
        case "circle":
        case "ellipse": {
            const elemValues = graphElementData?.values;
            if (elemValues instanceof Array) {
                for (let i = 0; i < elemValues.length; i++) {
                    if (!checkElemDataValues(elemValues[i])) {
                        return;
                    }
                }
            }
            break;
        }
        case "numberline_label": {
            if (!checkElemDataValue(graphElementData)) {
                return;
            }
            break;
        }
        default:
            break;
    }

    // Helpers:
    const getPointList = () => {
        let list = [];
        if (graphElementData?.values instanceof Array) {
            list = graphElementData.values?.map((valItem) => {
                const coords = valItem.values;
                /**
                 * Use one of these:
                 * - return coords;
                 * - return jxgBoard.create("point", coords);
                 */
                return jxgBoard.create("point", coords, {
                    name: valItem?.name,
                    ...valItem?.attrs,
                });
            });
        }
        return list;
    };

    // Handle creating element:
    let graphElement;
    switch (graphElementData?.type) {
        case "point": {
            // 1. Create point:
            graphElement = jxgBoard.create("point", graphElementData.values, {
                ...graphElementData?.attrs,
            });
            // 2. Set up point's event handlers:
            setPointEvents(graphElement, undefined, params);
            break;
        }
        case "segment": {
            const pointList = getPointList();
            if (pointList?.length === 2) {
                // 1. Create segment:
                graphElement = jxgBoard.create("segment", pointList, {
                    ...graphElementData?.attrs,
                });
                // 2. Set up point's event handlers:
                setPointEvents(graphElement.point1, graphElement, params);
                setPointEvents(graphElement.point2, graphElement, params);
                // 2. Set up line's event handlers:
                setSegmentEvents(graphElement, undefined, params);
            }
            break;
        }
        case "line": {
            const pointList = getPointList();
            if (pointList?.length === 2) {
                // 1. Create line:
                graphElement = jxgBoard.create("line", pointList, {
                    ...graphElementData?.attrs,
                });
                // 2. Set up point's event handlers:
                setPointEvents(graphElement.point1, graphElement, params);
                setPointEvents(graphElement.point2, graphElement, params);
                // 2. Set up line's event handlers:
                setSegmentEvents(graphElement, undefined, params);
            }
            break;
        }
        case "polygon": {
            const pointList = getPointList();
            if (pointList?.length > 2) {
                // 1. Create polygon:
                graphElement = jxgBoard.create("polygon", pointList, {
                    ...graphElementData?.attrs,
                });
                // 2. Set up point's event handlers:
                Object.keys(graphElement.ancestors).forEach((itemKey) => {
                    setPointEvents(graphElement.ancestors[itemKey], graphElement, params);
                });
                // 3. Set up segment's event handlers:
                graphElement.borders.forEach((item) => {
                    setSegmentEvents(item, graphElement, params);
                });
            }
            break;
        }
        case "circle": {
            const pointList = getPointList();
            if (pointList?.length === 2) {
                // 1. Create circle:
                graphElement = jxgBoard.create("circle", pointList, {
                    ...graphElementData?.attrs,
                });
                // 2. Set up point's event handlers:
                setPointEvents(graphElement.center, graphElement, params);
                setPointEvents(graphElement.point2, graphElement, params);
                // 3. Set up circle's event handlers:
                setCircleEvents(graphElement, params);
            }
            break;
        }
        // case "arrow":
        case "vector":
        case "ray": {
            const pointList = getPointList();
            if (pointList?.length === 2) {
                // 1. Create arrow:
                graphElement = jxgBoard.create("arrow", pointList, {
                    ...graphElementData?.attrs,
                });
                // 2. Set up point's event handlers:
                setPointEvents(graphElement.point1, graphElement, params);
                setPointEvents(graphElement.point2, graphElement, params);
                // 2. Set up arrow's event handlers:
                setSegmentEvents(graphElement, undefined, params);
            }
            break;
        }
        // case "curve":
        case "ellipse": {
            const pointList = getPointList();
            if (pointList?.length > 2) {
                // 1. Create ellipse:
                graphElement = jxgBoard.create("ellipse", pointList, {
                    ...graphElementData?.attrs,
                });
                // 2. Set up point's event handlers:
                Object.keys(graphElement.ancestors).forEach((itemKey) => {
                    const item = graphElement.ancestors[itemKey];
                    if (item?.elType === "point" && item?.name) {
                        setPointEvents(item, graphElement, params);
                    }
                });
            }
            break;
        }
        // case "text":
        case "numberline_label": {
            // 1. Create text:
            const label =
                graphElements[graphElementData.type].getDisplayOfText(graphElementData.label, graphElementData.value) ||
                graphElementData.label;
            graphElement = jxgBoard.create("text", [graphElementData.value, 0, label], {
                anchorX: "middle",
                anchorY: "bottom",
                display: "html",
                ...graphElementData?.attrs,
            });
            const labelNode = graphElement.rendNode?.querySelector(".numberline-label-wrapper .numberline-label");
            if (labelNode && graphElementData?.attrs?.labelClassName) {
                labelNode.classList.add(graphElementData?.attrs?.labelClassName);
            }
            // 2. Set up text's event handlers:
            setTextEvents(graphElement, params);
            break;
        }
        default:
            break;
    }
    return graphElement;
};

/**
 * Delete graph element by its data.
 * @param {Object} graphElementData Graph element's data.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 */
export const removeGraphElementByData = (graphElementData, params = {}) => {
    // Params:
    const { jxgBoard } = params;

    // Find and select element by data:
    const graphElement = jxgBoard.select(graphElementData.id, true);

    // Handle removing element:
    switch (graphElement?.elType) {
        case "point": {
            jxgBoard.removeObject(graphElement);
            break;
        }
        case "segment":
        case "line": {
            jxgBoard.removeObject(graphElement.point1);
            jxgBoard.removeObject(graphElement.point2);
            jxgBoard.removeObject(graphElement);
            break;
        }
        case "polygon": {
            Object.keys(graphElement.ancestors).forEach((itemKey) => {
                jxgBoard.removeObject(graphElement.ancestors[itemKey]);
            });
            jxgBoard.removeObject(graphElement);
            break;
        }
        case "circle": {
            jxgBoard.removeObject(graphElement.center);
            jxgBoard.removeObject(graphElement.point2);
            jxgBoard.removeObject(graphElement);
            break;
        }
        case "arrow": {
            switch (graphElementData?.type) {
                case "vector": {
                    jxgBoard.removeObject(graphElement.point1);
                    jxgBoard.removeObject(graphElement.point2);
                    jxgBoard.removeObject(graphElement);
                    break;
                }
                // case "ray": {
                //     break;
                // }
                default:
                    break;
            }
            break;
        }
        case "curve": {
            switch (graphElementData?.type) {
                case "ellipse": {
                    Object.keys(graphElement.ancestors).forEach((itemKey) => {
                        jxgBoard.removeObject(graphElement.ancestors[itemKey]);
                    });
                    jxgBoard.removeObject(graphElement);
                    break;
                }
                default:
                    break;
            }
            break;
        }
        case "text": {
            jxgBoard.removeObject(graphElement);
            break;
        }
        default:
            break;
    }
};

/**
 * Get graph element by its data.
 * @param {Object} graphElementData Graph element's data.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * @returns JXG.GeometryElement | JXG.Composition.
 */
export const getGraphElementByData = (graphElementData, params = {}) => {
    // Params:
    const { jxgBoard } = params;

    // Find and select element by data:
    const graphElement = jxgBoard.select(graphElementData.id, true);
    return graphElement;
};

/**
 * Update graph element by its data.
 * @param {Object} graphElementData Graph element's data.
 * @param {Object} params Objects of params.
 * > - params.jxgBoard: JXG.Board.
 * > - params.config = { coordsStep }.
 * > - params.eventHandlers = { onUpdate }.
 */
export const updateGraphElementByData = (graphElementData, params = {}) => {
    // Params:
    const {
        jxgBoard,
        // config,
        // eventHandlers,
    } = params;

    // Find and select element by data:
    const graphElement = jxgBoard.select(graphElementData.id, true);

    // Handle updating element:
    switch (graphElementData?.type) {
        case "point": {
            const element = jxgBoard.select(graphElementData.id);
            // 1. Update point's name:
            element.setName(graphElementData.name);
            // 2. Update the position:
            element.setPositionDirectly(JXG.COORDS_BY_USER, graphElementData.values);
            jxgBoard.update();
            break;
        }
        case "segment":
        case "line":
        case "polygon":
        case "circle":
        // case "arrow":
        case "vector":
        case "ray":
        // case "curve":
        case "ellipse": {
            if (graphElementData.values instanceof Array) {
                graphElementData.values.forEach((valItem) => {
                    const element = jxgBoard.select(valItem.id);
                    // 1. Update point's name:
                    element.setName(valItem.name);
                    // 2. Update the position:
                    element.setPositionDirectly(JXG.COORDS_BY_USER, valItem.values);
                });
                jxgBoard.update();
            }
            break;
        }
        // case "text":
        case "numberline_label": {
            const element = jxgBoard.select(graphElementData.id);
            // 1. Update point's name:
            const labelNode = graphElement.rendNode?.querySelector(".numberline-label-wrapper .label");
            const labelPosNode = graphElement.rendNode?.querySelector(".numberline-label-wrapper .label-pos");
            if (labelNode) {
                labelNode.innerHTML = graphElementData.label;
            }
            if (labelPosNode) {
                labelPosNode.innerHTML = graphElementData.value;
            }
            // 2. Update the position:
            element.setPositionDirectly(JXG.COORDS_BY_USER, [graphElementData.value, 0]);
            jxgBoard.update();
            break;
        }
        default:
            break;
    }
    return {
        status: true,
        message: "message.update_success",
        element: graphElement,
    };
};
