import { Collapse, Radio } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import paymentCash from "src/assets/images/payment/payment_cash.jpg";
import paymentTransfer from "src/assets/images/payment/payment_creditcard.png";
import paymentEwalletVnPay from "src/assets/images/payment/payment_ewallet_vnpay.png";
import paymentVisa from "src/assets/images/payment/payment_intcard.png";
import paymentAtmCard from "src/assets/images/payment/payment_localcard.png";
import paymentQR from "src/assets/images/payment/payment_qr.jpg";
import paymentQRVnPay from "src/assets/images/payment/payment_qr_vnpay.png";

import BankTransferInfo from "../BankTransferInfo";
import "./PaymentMethods.scss";

const { Panel } = Collapse;

const PaymentMethods = ({ className, onChangePaymentMethod, ...rest }) => {
    const { t } = useTranslation();
    const [activePanel, setActivePanel] = useState("");

    const handleChangePaymentMethod = (activePanel, suffix) => {
        setActivePanel(activePanel);
        if (activePanel && onChangePaymentMethod instanceof Function) {
            const methodIndex = activePanel.replace("method-", "");
            const data = {
                type: paymentMethods[methodIndex].type + (suffix || ""),
            };
            // The onChangePaymentMethod can be trigged if one of these cases happens:
            // - The selected method doesn't require suffix.
            // - The selected method requires suffix and suffix param is supplied.
            if (!paymentMethods[methodIndex].needSuffix || suffix) {
                onChangePaymentMethod(data);
            } else {
                onChangePaymentMethod(undefined);
            }
        } else {
            onChangePaymentMethod(activePanel);
        }
    };

    const paymentMethods = [
        {
            type: "CASH",
            symbol: paymentCash,
            description: ["payment.method_CASH_please_make_payment_at", "payment.method_CASH_payment_address"],
        },
        {
            type: "TRANSFER",
            symbol: paymentTransfer,
            description: ["payment.method_TRANSFER_description"],
            children: <BankTransferInfo />,
        },
        {
            type: "ONEPAY_QR",
            symbol: paymentQR,
        },
        {
            type: "ONEPAY_DOMESTIC",
            symbol: paymentAtmCard,
        },
        {
            type: "ONEPAY_INTERNATIONAL",
            symbol: paymentVisa,
        },
        // {
        //     type: "PORTONE",
        //     symbol: paymentVisa,
        //     description: ["payment.method_PORTONE_description"],
        // },
        // {
        //     type: "VISA",
        //     symbol: paymentVisa,
        // },
        // {
        //     type: "VNMART",
        //     symbol: paymentEwalletVnPay,
        // },
        // {
        //     type: "VNPAYQR",
        //     symbol: paymentQRVnPay,
        // },
    ];

    // Generate ClassNames:
    const currClassNames = ["payment-methods"];
    if (className) {
        currClassNames.push(className);
    }

    return (
        <Collapse accordion className={currClassNames.join(" ")} onChange={handleChangePaymentMethod} {...rest}>
            {paymentMethods.map((method, i) => {
                const hasChildren = method.children && React.isValidElement(method.children);
                const className = (method.className || "") + (hasChildren ? "" : " has-no-children");

                return (
                    <Panel
                        key={`method-${i}`}
                        className={className}
                        showArrow={false}
                        header={
                            <div className="menu-item">
                                <span className="menu-item-control">
                                    <Radio className="app-radio" checked={activePanel === `method-${i}`}></Radio>
                                </span>
                                <span className="menu-item-symbol">
                                    <img src={method.symbol} alt="55x55" />
                                </span>
                                <div className="menu-item-content">
                                    <div className="content-title">{t(`payment.method_${method.type}`)}</div>
                                    <div className="content-description">
                                        {method.description?.length ? (
                                            method.description.map((d, i) => {
                                                return <div key={`descr-${i}`}>{t(d)}</div>;
                                            })
                                        ) : (
                                            <div>{method.description}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                    >
                        {hasChildren ? method.children : null}
                    </Panel>
                );
            })}
        </Collapse>
    );
};

export default PaymentMethods;
