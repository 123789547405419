import { Badge, Image, Space, Table, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import {
    HISTORY_FIELDS,
    getActionType,
    getDataRelation,
    getHistoryFields,
    getModalName,
    renderFieldName,
} from "../../HistoryHelpers";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getQuestionKeyByType } from "src/api/helpers/question";
import { useRouting } from "src/utils/router";
import LatexDangerously from "src/modules/components/LatexDangerously";
import { useTranslation } from "react-i18next";
import { QuestionHistory } from "../../HistoryFeature/QuestionHistory";
import { RenderTableHisstories } from "../RenderTableHistories";
import clsx from "clsx";
const { Column, ColumnGroup } = Table;

const matchingFields = {
    Question: "question",
    Assignment: "name",
    ExamQuestion: "name",
    Course: "name",
};

const formatDataForQuestionInExam = (old_questions, new_questions) => {
    const result = [];

    if (Array.isArray(old_questions) && Array.isArray(new_questions)) {
        const length = old_questions.length > new_questions.length ? old_questions.length : new_questions.length;
        for (let i = 0; i < length; i++) {
            result.push({
                key: i + 1,
                old_questions_id: old_questions?.[i]?._id,
                old_question: old_questions?.[i]?.question,
                old_questions_type: old_questions?.[i]?.type,

                new_questions_id: new_questions?.[i]?._id,
                new_question: new_questions?.[i]?.question,
                new_questions_type: new_questions?.[i]?.type,
            });
        }
    }

    return result;
};

/**
 *
 * @item {object}
 * @modalType {string} :Question||  Assignment || ExamQuestion || Course
 * @type {string}: all_history, question_history, assignemt_history, exam_question_history, course_user_history
 * @returns string || JSX
 */

function HistoryTimeLineItem({ item, modalType, type }) {
    const { generate } = useRouting();
    const { t } = useTranslation();

    const renderTitle = (modals, item) => {
        //là name của bài đc giao,câu hỏi,...
        let linkTo = "";

        const itemChild = getDataRelation(modals, item);

        switch (modals) {
            case "Question":
                const questionKey = getQuestionKeyByType(itemChild?.type);
                linkTo = itemChild?.id
                    ? generate("update_question", { id: itemChild?.id, questionKey })
                    : generate("question_bank");
                break;
            case "QuestionParagraph":
                const questionKey2 = getQuestionKeyByType(item?.question?.question?.type);
                linkTo = item?.question?.question?._id
                    ? generate("update_question", { id: item?.question?.question?._id, questionKey: questionKey2 })
                    : generate("question_bank");
                break;
            case "Assignment":
                linkTo = generate("assignment");
                break;
            case "ExamQuestion":
                linkTo = itemChild?.id ? generate("update_exam_bank", { id: itemChild?.id }) : generate("exam_bank");
                break;
        }
        if (["Question"].includes(modals)) {
            return (
                <Link style={{ color: "unset" }} to={linkTo} target="_blank">
                    <LatexDangerously innerHTML={itemChild?.[matchingFields[modals]]} />
                </Link>
            );
        }
        if (["QuestionParagraph"].includes(modals)) {
            return (
                <Link to={linkTo} target="_blank">
                    <LatexDangerously innerHTML={item?.question?.question?.question} />
                </Link>
            );
        }

        return (
            <Link style={{ color: "#111", fontSize: 16, fontWeight: 600 }} to={linkTo}>
                {itemChild?.[matchingFields[modals]]}
            </Link>
        );
    };

    const getQuestionPath = (record) => {
        const questionKey = getQuestionKeyByType(record?.old_questions_type);
        const id = record?.old_questions_id;
        if ((questionKey, id))
            return generate("update_question", {
                questionKey: questionKey,
                id: id,
            });
        return "";
    };

    const renderExamQuestionList = (old_questions, new_questions) => {
        const tableData = formatDataForQuestionInExam(old_questions, new_questions);

        return (
            <>
                <br />
                <Table dataSource={tableData} className="app-table">
                    {/* <ColumnGroup title="old"> */}
                    <Column
                        dataIndex="key"
                        key="key"
                        width={"70px !important"}
                        render={(key) => <div style={{ minWidth: 40 }}>{key}</div>}
                    />
                    <Column
                        title={t("history.old_question")}
                        dataIndex="old_question"
                        key="old_question"
                        render={(data, record) => {
                            let htmlStr = data;
                            htmlStr = (htmlStr || "").replace(/<img(.*?)>/g, "");

                            return (
                                <Link to={getQuestionPath(record)} target="_blank" style={{ color: "unset" }}>
                                    <div className="table-content-format">
                                        <div className="text-limit-lines" style={{ minWidth: 200 }}>
                                            <LatexDangerously innerHTML={htmlStr} />
                                        </div>
                                    </div>
                                </Link>
                            );
                        }}
                    />
                    {/* </ColumnGroup> */}
                    {/* <ColumnGroup title="new"> */}
                    <Column
                        title={t("history.new_question")}
                        dataIndex="new_question"
                        key="new_question"
                        render={(data, record) => {
                            let htmlStr = data;
                            htmlStr = (htmlStr || "").replace(/<img(.*?)>/g, "");

                            return (
                                <Link to={getQuestionPath(record)} target="_blank" style={{ color: "unset" }}>
                                    <div className="table-content-format">
                                        <div className="text-limit-lines" style={{ minWidth: 200 }}>
                                            <LatexDangerously innerHTML={htmlStr} />
                                        </div>
                                    </div>
                                </Link>
                            );
                        }}
                    />
                    {/* </ColumnGroup> */}
                </Table>
            </>
        );
    };

    const renderValue = (value, fieldName, modal_type) => {
        let type1 = type || modal_type;
        if (value == null) {
            return "";
        }
        if (typeof value === "string") {
            // render 1 vài value đặc biệt, cần dịch theo ngôn ngữ để dễ hiểu
            let v1 = "";
            switch (type1) {
                case "assignment_history":
                    v1 = getHistoryFields(`assignment_fields`, t, value);
                    break;
                case "exam_question_history":
                    v1 = getHistoryFields(`exam_question_fields`, t, value);

                    break;
                case "course_history":
                    v1 = getHistoryFields(`course_fields`, t, value);

                    break;
                case "question_history":
                    v1 = getHistoryFields(`question_fields`, t, value);

                    break;
            }

            if (v1) {
                return v1;
            }

            if (["question", "content"].includes(fieldName)) {
                return <LatexDangerously innerHTML={value} />;
            }
            if (["questions"].includes(fieldName)) {
                return value?.map((item) => {
                    return <LatexDangerously key={item?._id} innerHTML={item?.question} />;
                });
            }

            if (["image", "file"].includes(fieldName)) {
                return value ? <Image src={value} style={{ maxWidth: 80, borderRadius: 4 }} /> : "null";
            }

            return value;
        } else if (["tag_ids"].includes(fieldName)) {
            return (
                <Space size={[0, "small"]} wrap>
                    {value?.map((item) => {
                        return (
                            <Tag key={item?._id} color={item?.color}>
                                {item?.name}
                            </Tag>
                        );
                    })}
                </Space>
            );
        } else if (typeof value === "object") {
            if (fieldName == "questions" && modalType == "QuestionParagraph") {
                return t("history.update_questions_in_passage");
            } else if (["questions"].includes(fieldName)) {
                return value?.map((item, idx) => {
                    return (
                        <div key={item?._id} style={{ border: "solid 1px #ccc", padding: 10 }}>
                            <div>
                                <span>
                                    <Badge count={idx + 1}></Badge>
                                </span>
                                <strong className="question-title">
                                    {t("history.question")} {`${renderFieldName(item?.type, t)}`}
                                </strong>
                            </div>
                            <br />
                            <Link
                                style={{ color: "unset" }}
                                to={generate("update_question", { id: item?._id, questionKey: item?.type })}
                            >
                                <LatexDangerously innerHTML={item?.question}></LatexDangerously>
                            </Link>
                        </div>
                    );
                });
            }
        }
        return JSON.stringify(value);
    };

    const renderValueForCourseUser = () => {
        if (item?.type === "create") {
            return (
                <div style={{ fontWeight: 600 }}>
                    <Tooltip title={item?.user?.username}>
                        {item?.user?.name}({renderFieldName(item?.user?.role, t)})
                    </Tooltip>
                    <span>
                        {` ${t("history.add_user")} ${item?.value?.user?.name} ${t("history.to_class")} ${
                            item?.value?.course?.is_class ? t("history.class1") : t("history.course1")
                        } `}
                        <Link to={generate("course_detail", { id: item?.value?.course?._id })} target="_blank">
                            {item?.value?.course?.name}
                        </Link>
                    </span>
                </div>
            );
        } else if (item?.type === "delete") {
            return (
                <div style={{ fontWeight: 600 }}>
                    <Tooltip title={item?.user?.username}>
                        {item?.user?.name}({renderFieldName(item?.user?.role, t)})
                    </Tooltip>
                    <span>
                        {` ${t("history.delete_user")} ${item?.value?.user?.name} ${t("history.out_of")} ${
                            item?.value?.course?.is_class ? t("history.class1") : t("history.course1")
                        } `}
                        <Link to={generate("course_detail", { id: item?.value?.course?._id })} target="_blank">
                            {item?.value?.course?.name}
                        </Link>
                    </span>
                </div>
            );
        }
    };

    const renderGeneralHistories = () => {
        /* render cho modal history chung bên tab history */
        return (
            <div className="history_item_timeline">
                <div className="history_item_time">
                    <Typography.Text strong style={{ color: "#389e0d" }}>
                        {moment(item?.created_at).format("HH:mm:ss DD/MM/YYYY")}
                    </Typography.Text>
                </div>

                {modalType === "CourseUser" ? (
                    renderValueForCourseUser()
                ) : (
                    <>
                        <div>
                            <strong style={{ color: "#08b651db" }}>
                                {<Tooltip title={item?.user?.username}>{item?.user?.name}</Tooltip>} -
                                {`${getActionType(item?.type)} (${getModalName(modalType)}): `}
                            </strong>
                            {/* {type !== "all_history" && <span>{renderTitle(modalType, item)}</span>} */}
                        </div>
                        <div className={clsx({ model_name: true, question: modalType == "Question" })}>
                            <span>{renderTitle(modalType, item)}</span>
                        </div>

                        <br />
                        <strong style={{ display: type === "all_history" ? "block" : "inline-block" }}>
                            {renderFieldName(item?.field_name, t) ?? "null"}{" "}
                        </strong>
                        {["Question", "QuestionParagraph"].includes(modalType) ? (
                            <QuestionHistory historyData={item} renderValue={renderValue} />
                        ) : (
                            <RenderTableHisstories
                                component1={
                                    <span className="old_value_item">
                                        {renderValue(item?.old_value, item?.field_name)}
                                    </span>
                                }
                                component2={
                                    <span className="new_value_item">
                                        {renderValue(item?.new_value, item?.field_name)}
                                    </span>
                                }
                            />
                        )}
                    </>
                )}
            </div>
        );
    };

    const renderSpecificHistories = () => {
        return (
            <div className="history_item_timeline">
                {/* render cho các modal trong các trang chi tiết như: chi tiết khóa học, giao bài,.... */}
                <div className="history_item_time">
                    <Typography.Text strong style={{ color: "#389e0d" }}>
                        {moment(item?.created_at).format("HH:mm:ss DD/MM/YYYY")}
                    </Typography.Text>
                </div>
                {type !== "course_user_history" && (
                    <div>
                        <strong style={{ color: "#08b651db" }}>
                            {
                                <Tooltip title={item?.user?.username}>
                                    {item?.user?.name}({renderFieldName(item?.user?.role, t)})
                                </Tooltip>
                            }
                            {` - ${getActionType(item?.type)}: `}
                            <strong style={{ display: type === "all_history" ? "block" : "inline-block" }}>
                                {renderFieldName(item?.field_name, t) ?? "null"}
                            </strong>
                        </strong>
                    </div>
                )}
                {(() => {
                    if (type === "question" || type === "questionParagraph") {
                        return <QuestionHistory historyData={item} renderValue={renderValue} />;
                    } else if (type === "course_user_history") {
                        return renderValueForCourseUser();
                    } else {
                        if (item?.field_name === "exam_question_list_ids") {
                            return renderExamQuestionList(item?.old_value, item?.new_value);
                        } else {
                            return (
                                <RenderTableHisstories
                                    component1={
                                        <span className="old_value_item">
                                            {renderValue(item?.old_value, item?.field_name)}
                                        </span>
                                    }
                                    component2={
                                        <span className="new_value_item">
                                            {renderValue(item?.new_value, item?.field_name)}
                                        </span>
                                    }
                                />
                            );
                        }
                    }
                })()}
                {/* {type === "question" || type === "questionParagraph" ? (
                    <QuestionHistory historyData={item} renderValue={renderValue} />
                ) : (
                    <>
                        {item?.field_name === "exam_question_list_ids" ? (
                            renderExamQuestionList(item?.old_value, item?.new_value)
                        ) : (
                            <RenderTableHisstories
                                component1={
                                    <span className="old_value_item">
                                        {renderValue(item?.old_value, item?.field_name)}
                                    </span>
                                }
                                component2={
                                    <span className="new_value_item">
                                        {renderValue(item?.new_value, item?.field_name)}
                                    </span>
                                }
                            />
                        )}
                    </>
                )} */}
            </div>
        );
    };

    return <>{type === "all_history" ? renderGeneralHistories() : renderSpecificHistories()}</>;
}

export default HistoryTimeLineItem;
