import { Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { planTypes } from "src/api/containers/plan";
import { default as CustomButton } from "src/modules/components/Button";

import { CardTableContext } from "../CardTable";
import { cardFieldSettings } from "../settings";
import "./CardSubscriptionPlan.scss";

const CardSubscriptionPlan = ({
    className = "",
    planData,
    type,
    title,
    // Price:
    priceUnit,
    // For price list:
    priceList,
    // For single price:
    priceCurrent,
    priceForAmountOfTime,
    // Actions:
    actions = [],
    isLoadingSkeleton = false,
    ...rest
}) => {
    const { t } = useTranslation();

    const table = useContext(CardTableContext);

    const [selectedPriceId, setSelectedPriceId] = useState("");

    // Generate ClassNames:
    const currClassNames = ["subscription-plan-card"];
    if (className) {
        currClassNames.push(className);
    }
    if (isLoadingSkeleton) {
        currClassNames.push("skeleton");
    }

    const handleChangePrice = (priceId) => {
        setSelectedPriceId(priceId);
    };

    useEffect(() => {
        if (Array.isArray(priceList) && priceList?.length) {
            setSelectedPriceId(priceList[0]?.id);
        }
    }, [priceList]);

    const renderPriceCurrent = () => {
        /**
         * There are two cases:
         * - Case 1: If price list doesn't exist, show its price only.
         * - Case 2: If price list exists, show the first price item of the list by default.
         */
        if (Array.isArray(priceList) && priceList?.length) {
            const _priceOptions = priceList?.map((item) => {
                let _priceCurrent = item.current_price;
                let _priceForAmountOfTime;
                let _priceCurrentStr;
                let _priceCurrentStrPrefix;
                let hasPriceCurrent = false;
                if (!isNaN(item.current_price)) {
                    if (_priceCurrent === 0) {
                        _priceCurrentStr = t("price.free");
                    } else {
                        hasPriceCurrent = true;
                        _priceCurrentStr = item.current_price.toLocaleString();
                    }
                } else if (item.current_price?.match(/from\s\d+/)) {
                    _priceCurrent = Number(item.current_price.replace(/from\s/, ""));
                    if (_priceCurrent === 0) {
                        _priceCurrentStr = t("price.free");
                    } else {
                        hasPriceCurrent = true;
                        _priceCurrentStrPrefix = t("shared.from");
                        _priceCurrentStr = _priceCurrent.toLocaleString();
                    }
                }
                if (!isNaN(item.months)) {
                    if (item.months > 1) {
                        _priceForAmountOfTime = `/${item.months} ${t("price.month")}`;
                    } else {
                        _priceForAmountOfTime = `/${t("price.month")}`;
                    }
                }

                if (_priceCurrent === 0) {
                    return {
                        value: item.id,
                        label: (
                            <div className="card-price free">
                                <div className="price-current">
                                    <span className="price-value">{t("price.free")}</span>
                                    <span className="price-unit display-as-placeholder">_</span>
                                    <span className="price-for display-as-placeholder">_</span>
                                </div>
                            </div>
                        ),
                    };
                } else {
                    const priceCurrentClassName = `price-value${!hasPriceCurrent ? " display-as-placeholder" : ""}`;
                    const priceUnitClassName = `price-unit${
                        !priceUnit || !hasPriceCurrent ? " display-as-placeholder" : ""
                    }`;
                    const priceForAmountOfTimeClassName = `price-for${
                        !_priceForAmountOfTime || !hasPriceCurrent ? " display-as-placeholder" : ""
                    }`;
                    return {
                        value: item.id,
                        label: (
                            <div className="card-price">
                                <div className="price-current">
                                    <span className={priceCurrentClassName}>
                                        {_priceCurrentStrPrefix && (
                                            <span className="price-prefix">{_priceCurrentStrPrefix} </span>
                                        )}
                                        {_priceCurrentStr || "_"}
                                    </span>
                                    <span className={priceUnitClassName}>{priceUnit || "_"}</span>
                                    <span className={priceForAmountOfTimeClassName}>
                                        {_priceForAmountOfTime || "/_"}
                                    </span>
                                </div>
                            </div>
                        ),
                    };
                }
            });

            return (
                <Select
                    bordered={false}
                    options={_priceOptions}
                    placeholder="select"
                    className="card-price-list"
                    value={selectedPriceId}
                    onChange={handleChangePrice}
                />
            );
        } else {
            let _priceCurrent = priceCurrent;
            let _priceCurrentStr;
            let _priceCurrentStrPrefix;
            let hasPriceCurrent = false;
            if (!isNaN(priceCurrent)) {
                if (_priceCurrent === 0) {
                    _priceCurrentStr = t("price.free");
                } else {
                    hasPriceCurrent = true;
                    _priceCurrentStr = priceCurrent.toLocaleString();
                }
            } else if (priceCurrent?.match(/from\s\d+/)) {
                _priceCurrent = Number(priceCurrent.replace(/from\s/, ""));
                if (_priceCurrent === 0) {
                    _priceCurrentStr = t("price.free");
                } else {
                    hasPriceCurrent = true;
                    _priceCurrentStrPrefix = t("shared.from");
                    _priceCurrentStr = _priceCurrent.toLocaleString();
                }
            }

            if (type === planTypes.free || _priceCurrent === 0) {
                return (
                    <div className="card-price free">
                        <div className="price-current">
                            <span className="price-value">{t("price.free")}</span>
                            <span className="price-unit display-as-placeholder">_</span>
                            <span className="price-for display-as-placeholder">_</span>
                        </div>
                    </div>
                );
            } else {
                const priceCurrentClassName = `price-value${!hasPriceCurrent ? " display-as-placeholder" : ""}`;
                const priceUnitClassName = `price-unit${
                    !priceUnit || !hasPriceCurrent ? " display-as-placeholder" : ""
                }`;
                const priceForAmountOfTimeClassName = `price-for${
                    !priceForAmountOfTime || !hasPriceCurrent ? " display-as-placeholder" : ""
                }`;
                return (
                    <div className="card-price">
                        <div className="price-current">
                            <span className={priceCurrentClassName}>
                                {_priceCurrentStrPrefix && (
                                    <span className="price-prefix">{_priceCurrentStrPrefix} </span>
                                )}
                                {_priceCurrentStr || "_"}
                            </span>
                            <span className={priceUnitClassName}>{priceUnit || "_"}</span>
                            <span className={priceForAmountOfTimeClassName}>{priceForAmountOfTime || "/_"}</span>
                        </div>
                    </div>
                );
            }
        }
    };

    const renderBodyContent = () => {
        return (
            <ul className="content-list">
                {Object.keys(cardFieldSettings).map((k, i) => {
                    // Field:
                    const field = cardFieldSettings[k].field;
                    const renderField = cardFieldSettings[k].renderField;
                    const content =
                        renderField instanceof Function ? renderField(t, planData?.[field]) : planData?.[field] || "";
                    // Style:
                    const height = table?.cardInfo?.title?.[k]?.height;
                    const style = {};
                    height && (style.height = height);
                    return (
                        <li
                            key={`content-${i}`}
                            id={`plan-listitem-${type}${k}`}
                            className={content ? "" : "display-as-placeholder"}
                            style={style}
                        >
                            {content}
                        </li>
                    );
                })}
            </ul>
        );
    };

    useEffect(() => {
        const fieldsInfo = {};
        Object.keys(cardFieldSettings).forEach((k, i) => {
            fieldsInfo[k] = {};
            // Actual height:
            const elemListItem = document.getElementById(`plan-listitem-${type}${k}`);
            fieldsInfo[k].actualHeight = elemListItem.offsetHeight;
        });

        if (table?.setCardInfo instanceof Function) {
            table?.setCardInfo(type, fieldsInfo);
        }
    }, []);

    return (
        <div className={currClassNames.join(" ")} {...rest}>
            <div className={`card-subsc-plan`}>
                <div className="card-wrapper">
                    <div className="card-content">
                        <div className="card-header">
                            <div className={`header-title${!title ? " display-as-placeholder" : ""}`}>
                                {title || "_"}
                            </div>
                            {renderPriceCurrent()}
                            {actions?.length ? (
                                <div className="card-actions">
                                    {actions.map((act, i) => {
                                        return (
                                            <CustomButton
                                                key={`act-${i}`}
                                                type={act.type}
                                                title={act.title}
                                                linkTo={act.linkTo}
                                                linkToIsLocal={act.linkToIsLocal}
                                                target={act.target}
                                                onClick={() => {
                                                    if (act.onClick instanceof Function) {
                                                        act.onClick(planData, selectedPriceId);
                                                    }
                                                }}
                                                disabled={act.disabled}
                                                className={act.className}
                                            ></CustomButton>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="card-actions">
                                    <CustomButton
                                        type="grey"
                                        title="_"
                                        className="display-as-placeholder"
                                    ></CustomButton>
                                </div>
                            )}
                        </div>
                        <div className="card-body">{renderBodyContent()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSubscriptionPlan;
