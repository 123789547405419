import { Avatar, Card, Progress } from "antd";
import { t } from "i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import NoAvatar from "src/assets/images/profile/user-avatar-default.png";
import { default as CustomButton } from "src/modules/components/Button";
import ImageFallback from "../../ImageFallback";
import "./CoursePanel.scss";

function CoursePanel({
    role = "student",
    href = "",
    courseInfo = {},
    formatDate = "DD/MM/YYYY",
    extraFields = [], // Example: [{fieldName: "", fieldValue: ""}],
    isLoading = false,

    /* PROP: action */
    /**
     * action is an array of objects. Each object contains these properties:
     * @param {any - unique - required} key | @description: Used for Ant Design card
     * @param {string} title  | @description: Button title | @default: ""
     * @param {void (e) => {}} onClick
     *  | @description: Event handler - function will be called on button click
     *  | @default: () => {}
     */
    actions = [],
}) {
    if (["teacher", "employee", "admin", "employee_crm"].includes(role)) {
        return (
            <div className="course-info-panel">
                {/* ClassName for 'default' state: "course-item" */}
                {/* ClassName for 'disabled' state: "course-item course-item--finish" */}
                <div className="course-item">
                    <div className="card-img-mobile">
                        <div className="card-img-mobile-wrap">
                            <ImageFallback src={courseInfo.image?.src} />
                        </div>
                    </div>
                    <Card
                        loading={isLoading}
                        cover={
                            <div>
                                <Link className="card-link" to={href}>
                                    <ImageFallback src={courseInfo.image?.src} />
                                </Link>
                            </div>
                        }
                        actions={actions.map((item) => (
                            <CustomButton
                                key={item.key}
                                type="ghost"
                                title={item.title}
                                onClick={item.onClick}
                            ></CustomButton>
                        ))}
                        bordered={false}
                    >
                        <Link className="card-link" to={href}>
                            <div className="course-body">
                                <h2 className="course-title">{courseInfo.name || "_"}</h2>
                                <div className="course-info">
                                    {courseInfo.user_avatars?.length ? (
                                        <Avatar.Group maxCount={5}>
                                            {courseInfo.user_avatars.map((classUser, index) => (
                                                <div className="course-info__item" key={`classuser${index}`}>
                                                    <Avatar src={classUser.avatar || NoAvatar} />
                                                </div>
                                            ))}
                                        </Avatar.Group>
                                    ) : null}
                                    <div className="course-info__item">
                                        <span className="text-bold">Code:</span>
                                        {courseInfo?.code || <i>{t("course.unknown")}</i>}
                                    </div>
                                    <div className="course-info__item">
                                        <span className="text-bold">{t("course.responsible")}:</span>
                                        {courseInfo?.creator?.name || <i>{t("course.unknown")}</i>}
                                    </div>
                                    <div className="course-info__item">
                                        <span className="text-bold">{t("course.start_date")}:</span>
                                        {courseInfo.begin_date ? (
                                            moment(courseInfo.begin_date).format(formatDate)
                                        ) : (
                                            <i>{t("course.unknown")}</i>
                                        )}
                                    </div>
                                    <div className="course-info__item">
                                        <span className="text-bold">{t("course.end_date")}:</span>
                                        {courseInfo.end_date ? (
                                            moment(courseInfo.end_date).format(formatDate)
                                        ) : (
                                            <i>{t("course.unknown")}</i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Card>
                </div>
            </div>
        );
    } else {
        return (
            <div className="course-info-panel">
                {/* ClassName for 'default' state: "course-item" */}
                {/* ClassName for 'disabled' state: "course-item course-item--finish" */}
                <div className="course-item">
                    <div className="card-img-mobile">
                        <div className="card-img-mobile-wrap">
                            <ImageFallback src={courseInfo.image?.src} />
                        </div>
                    </div>
                    <Card
                        loading={isLoading}
                        cover={
                            <div>
                                <Link className="card-link" to={href}>
                                    <ImageFallback src={courseInfo.image?.src} />
                                </Link>
                            </div>
                        }
                        actions={actions.map((item) => (
                            <CustomButton
                                key={item.key}
                                type="ghost"
                                title={item.title}
                                onClick={item.onClick}
                            ></CustomButton>
                        ))}
                        bordered={false}
                    >
                        <Link className="card-link" to={href}>
                            <div className="course-body">
                                <h2 className="course-title">{courseInfo?.name || <i>{t("course.unknown")}</i>}</h2>
                                <div className="course-info">
                                    <div className="course-info__item">
                                        <span className="text-bold">Code:</span>
                                        {courseInfo?.code || <i>{t("course.unknown")}</i>}
                                    </div>
                                    <div className="course-info__item">
                                        <span className="text-bold">{t("course.responsible")}:</span>
                                        {courseInfo?.creator?.name || <i>{t("course.unknown")}</i>}
                                    </div>
                                    <div className="course-info__item">
                                        <span className="text-bold">{t("course.start_date")}:</span>
                                        {courseInfo.begin_date ? (
                                            moment(courseInfo.begin_date).format(formatDate)
                                        ) : (
                                            <i>{t("course.unknown")}</i>
                                        )}
                                    </div>
                                    <div className="course-info__item">
                                        <span className="text-bold">{t("course.end_date")}:</span>
                                        {courseInfo.end_date ? (
                                            moment(courseInfo.end_date).format(formatDate)
                                        ) : (
                                            <i>{t("course.unknown")}</i>
                                        )}
                                    </div>
                                    <div className="course-progress">
                                        <div className="course-progress__info">
                                            <span className="info-status">{t("course.complete")}</span>
                                            <span className="info-progress">{`${courseInfo.progress || 0}%`}</span>
                                        </div>
                                        <Progress
                                            strokeColor={{ "0%": "#FB3B50", "100%": "#F54E62" }}
                                            percent={courseInfo?.progress || 0}
                                            status="active"
                                            showInfo={false}
                                            className={
                                                courseInfo?.progress == 0 || courseInfo?.progress == 100
                                                    ? "hide-progressbar-dot"
                                                    : ""
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Card>
                </div>
            </div>
        );
    }
}

export default CoursePanel;
