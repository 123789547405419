import api from "..";

const feedbackFields = {
    general: ["content", "handler_id", "status"],
    userFields: ["name", "username", "role"],
    handlerFields: ["name", "username", "role"],
};

export const createFeedback = (data) => {
    return api
        .post("/feedback", data)
        .then(({ status, message, id }) => ({ status, message }))
        .catch(({ message }) => ({ status: false, message: message }));
};

export const fetchFeedbacks = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.handler_id && filters.push(`handler_id:${fetchParams.handler_id}`);
    [true, false].includes(fetchParams.status) && filters.push(`status:${fetchParams.status}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _feedback_fields: `${feedbackFields.general}`,
        _user_fields: `${feedbackFields.userFields}`,
        _relations: `${["user", "handler"]}`,
        _admin_fields: `${feedbackFields.handlerFields}`,
    };
    return api
        .get("/feedback", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findFeedback = (id) => {
    const params = {
        _feedback_fields: `${feedbackFields.general}`,
    };
    return api
        .get(`/feedback/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const updateFeedback = (id, data) => {
    return api
        .put(`/feedback/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const deleteFeedback = (id) => {
    return api
        .delete(`/feedback/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
