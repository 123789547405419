import React, { useEffect, useMemo, useState } from "react";
import { uuid4 } from "@sentry/utils";
import ImageLabel from "../ImageLabel";
import "./ImageLabelsContainer.scss";

function ImageLabelsContainer({
    children,
    className,
    labelType,
    labelList,
    containerSize,
    onAddLabel,
    onChangeLabels,
    onRemoveLabel,
}) {
    const [labels, setLabels] = useState([]);
    const [labelIsFocused, setLabelIsFocused] = useState(false);
    const [containerState, setContainerState] = useState(false); // Values: "highlight-dragging", false.

    const extraClassnames = useMemo(() => {
        let r = "";
        if (className) {
            r += ` ${className}`;
        }
        if (containerState) {
            r += ` ${containerState}`;
        }
        return r;
    }, [className, containerState]);

    const preventEventBubbling = (e) => {
        e.stopPropagation();
    };

    const addLabel = (label, labelIndex) => {
        if (onAddLabel instanceof Function) {
            onAddLabel(label, labelIndex);
        }
    };

    const removeLabel = (label, labelIndex) => {
        if (onRemoveLabel instanceof Function) {
            onRemoveLabel(label, labelIndex);
        }
    };

    const changeLabels = (labels) => {
        if (onChangeLabels instanceof Function) {
            onChangeLabels(labels);
        }
    };

    const handleCreateLabel = (e) => {
        // Element that has been clicked:
        const elem = e.target;
        // Get mouse position on the element:
        const x = e.clientX - elem.getBoundingClientRect().x;
        const y = e.clientY - elem.getBoundingClientRect().y;
        // Update label list:
        const newList = [...labels];
        const newLbl = {
            _local_id: uuid4(),
            left: x,
            top: y,
            content: undefined,
        };
        newList.push(newLbl);
        setLabels(newList, newList.length - 1);
        // Trigger onChange prop:
        addLabel(newLbl, newList.length - 1);
        changeLabels(newList);
    };

    const handleRemoveLabel = (label, index) => {
        if (labels[index]._local_id === label._local_id) {
            const newLabels = [...labels];
            newLabels.splice(index, 1);
            setLabels(newLabels);
            // Trigger onChange prop:
            removeLabel(label, index);
            changeLabels(newLabels);
        }
    };

    const handleUpdateLabel = (label, index, configs) => {
        if (labels[index]._local_id === label._local_id) {
            const newLabels = [...labels];
            newLabels[index].left = configs.left;
            newLabels[index].top = configs.top;
            newLabels[index].content = configs.content;
            setLabels(newLabels);
            // Trigger onChange prop:
            changeLabels(newLabels);
        }
    };

    const handleUpdateContainerByDragState = (dragState) => {
        if (dragState === "dragging") {
            setContainerState("highlight-dragging");
        } else {
            setContainerState(false);
        }
    };

    const handleFocusLabelContent = (lblLocalId) => {
        setLabelIsFocused(lblLocalId);
    };

    const handleBlurLabelContent = () => {
        setLabelIsFocused(false);
    };

    useEffect(() => {
        if (labelList?.length >= 0) {
            setLabels(labelList);
            changeLabels(labelList);
        }
    }, [labelList]);

    if (!labelType) {
        return <div className={`image-labels-container${extraClassnames}`}>{children}</div>;
    }

    return (
        <div className={`image-labels-container${extraClassnames}`} onClick={handleCreateLabel}>
            {children}
            {labels.map((item, itemIndex) => {
                return (
                    <div key={`lbl-${itemIndex}-${item._local_id}`} onClick={preventEventBubbling}>
                        <ImageLabel
                            style={{ top: `${item.top}px`, left: `${item.left}px` }}
                            localId={item._local_id}
                            localOrdinalNumber={itemIndex + 1}
                            type={labelType}
                            content={item.content}
                            onClose={() => handleRemoveLabel(item, itemIndex)}
                            onChangeConfigs={(configs) => handleUpdateLabel(item, itemIndex, configs)}
                            onChangeDragState={(dragState) => handleUpdateContainerByDragState(dragState)}
                            rules={{
                                xMin: 0,
                                yMin: 0,
                                xMax: containerSize.width,
                                yMax: containerSize.height,
                            }}
                            // Focus and blur:
                            isFocused={labelIsFocused === item._local_id}
                            onFocusContent={handleFocusLabelContent}
                            onBlurContent={handleBlurLabelContent}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default ImageLabelsContainer;
