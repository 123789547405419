// Others:
import Guide from "src/modules/components/Help/Screens/Guide";
import Assignment from "src/modules/containers/Assignment";
// Teacher pages:
import AssignmentReport from "src/modules/containers/AssignmentReport";
// Student pages:
import AssignmentStudent from "src/modules/containers/AssignmentStudent";
import ClassesStudent from "src/modules/containers/ClassesStudent";
import ComponentLibs from "src/modules/containers/ComponentLibs";
// HTML Status Code pages:
// Others:
import Dashboard from "src/modules/containers/Dashboard";
import DetailExam from "src/modules/containers/DetailExam";
// ROLE - Student:
import ExamAsset from "src/modules/containers/ExamAsset";
// Exam:
import ExamBank from "src/modules/containers/ExamBank";
import GeneralLearningProgress from "src/modules/containers/GeneralLearningProgress";
import GradeBook from "src/modules/containers/GradeBook";
import BasicLayout from "src/modules/containers/Layout/BasicLayout";
import MainLayout from "src/modules/containers/Layout/MainLayout";
import LearningProgressReport from "src/modules/containers/LearningProgressReport";
import ListFeedback from "src/modules/containers/ListFeedback";
import { ManageHistory } from "src/modules/containers/ManageHistory";
import ManageUser from "src/modules/containers/ManageUser";
import MyExams from "src/modules/containers/MyResources/MyExams";
import MyQuestions from "src/modules/containers/MyResources/MyQuestions";
import MyStorage from "src/modules/containers/MyStorage";
import Organization from "src/modules/containers/Organization";
// HTML Status Code pages:
import Page404 from "src/modules/containers/PageForStatusCode/Page404";
import Payment from "src/modules/containers/Payment";
import PaymentResultPage from "src/modules/containers/Payment/pages/PaymentResultPage";
import PersonalLearningProgress from "src/modules/containers/PersonalLearningProgress";
import Profile from "src/modules/containers/Profile";
// Question:
import QuestionBank from "src/modules/containers/QuestionBank";
import QuestionDetail from "src/modules/containers/QuestionDetail";
import DetailPassageQuestion from "src/modules/containers/QuestionDetail/containers/DetailPassageQuestion";
import QuestionPreview from "src/modules/containers/QuestionPreview";
import ScoresStudent from "src/modules/containers/ScoresStudent";
import Setting from "src/modules/containers/Setting";
import StatisticHistory from "src/modules/containers/Statistics/History";
import StudentClasses from "src/modules/containers/StudentClasses";
import StudentCourses from "src/modules/containers/StudentCourses/containers/Courses";
import StudentUnitDetail from "src/modules/containers/StudentCourses/containers/UnitDetail";
import StudentUnits from "src/modules/containers/StudentCourses/containers/Units";
import StudentViewResult from "src/modules/containers/StudentViewResult";
import TeacherClasses from "src/modules/containers/TeacherClasses";
// ROLE - Teacher:
import TeacherCourseDetail from "src/modules/containers/TeacherCourses/containers/CourseDetail";
import TeacherCourseDetailUnit from "src/modules/containers/TeacherCourses/containers/CourseDetailUnit";
import TeacherCourses from "src/modules/containers/TeacherCourses/containers/Courses";
import TeacherUnits from "src/modules/containers/TeacherUnits";
import Test from "src/modules/containers/Test";
import ViewScoreStudent from "src/modules/containers/ViewScoreStudent";

import { pages } from "./pages";

// const StudentClasses = React.lazy(() => import("src/modules/containers/StudentClasses"));

// Upgrade & payment:
const routesSubscription = [
    {
        name: "payment",
        path: "/payment",
        element: Payment,
        role: ["teacher"],
        layout: MainLayout,
    },
    {
        name: "payment_result",
        path: "/payonline/return",
        element: PaymentResultPage,
        role: ["teacher"],
        layout: MainLayout,
    },
];

// Question details:
const questions = [
    {
        name: "add_passage",
        path: "/question/passage",
        element: DetailPassageQuestion,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "update_passage",
        path: "/question/passage/:id",
        element: DetailPassageQuestion,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "add_question",
        path: "/question/:questionKey",
        element: QuestionDetail,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "update_question",
        path: "/question/:questionKey/:id",
        element: QuestionDetail,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "preview_question",
        path: "/question/preview/:questionKey/:id",
        element: QuestionPreview,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
];

export default [
    ...routesSubscription,

    /**
     * ROLE: TEACHER & EMPLOYEE.
     */
    ...questions,

    /**
     * ROLE - TEACHER:
     */
    // 1. Storage:
    {
        name: "my_storage",
        path: "/my-storage",
        element: MyStorage,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    // 2. Course:
    {
        name: "courses",
        path: "/courses",
        element: TeacherCourses,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "course_detail",
        path: "/course/:id",
        element: TeacherCourseDetail,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "course_unit",
        path: "/course-unit/:courseId",
        element: TeacherUnits,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
        hideLayoutOnMobile: true,
    },
    {
        name: "course_detail_unit",
        path: "/course-detail/:courseId/unit/:unitId",
        element: TeacherCourseDetailUnit,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
        hideLayoutOnMobile: true,
    },
    // 3. Classes:
    {
        name: "classes",
        path: "/classes",
        element: TeacherClasses,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },

    // 4. Organization:
    {
        name: "organization",
        path: "/organization",
        element: Organization,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    // 5. Question:
    {
        name: "question_bank",
        path: "/question",
        element: QuestionBank,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    // {
    //     name: "manage_user",
    //     path: "/manage_user",
    //     element: ManageUser,
    //     role: ["teacher", "employee", "employee_crm", "admin"],
    //     layout: MainLayout,
    // },
    // 7. Question detail - preview:
    {
        name: "preview_question",
        path: "/question/preview/:type/:id",
        element: QuestionPreview,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    // 8. Assignment page:
    {
        name: "assignment",
        path: "/assignment",
        element: Assignment,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "course-assignment",
        path: "/assignment/:courseId",
        element: Assignment,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "assignment_report",
        path: "/course/:courseId/assignment/:assignmentId",
        element: AssignmentReport,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    // 9. Exam page:
    {
        name: "exam_bank",
        path: "/exam",
        element: ExamBank,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "add_exam_bank",
        path: "/exam/create",
        element: DetailExam,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "update_exam_bank",
        path: "/exam/:id",
        element: DetailExam,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "update_exam_bank_course",
        path: "/exam/:id/:assignment_id",
        element: DetailExam,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "my_resources_question",
        path: "/my-resources/question",
        element: MyQuestions,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "my_resources_exam",
        path: "/my-resources/exam",
        element: MyExams,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "guide",
        path: "/guide",
        element: Guide,
        role: ["employee", "employee_crm"],
        layout: MainLayout,
    },
    // {
    //     name: "feedback",
    //     path: "/feedback",
    //     element: ListFeedback,
    //     role: ["employee", "employee_crm", "admin", "teacher"],
    //     layout: MainLayout,
    // },
    // {
    //     name: "history",
    //     path: "/history",
    //     element: ManageHistory,
    //     role: ["admin"],
    //     layout: MainLayout,
    // },
    // {
    //     name: "statistic_history",
    //     path: "/history/statistic",
    //     element: StatisticHistory,
    //     role: ["admin"],
    //     layout: MainLayout,
    // },
    /**
     * ROLE - STUDENT:
     */
    // 1. Course:
    // {
    //     name: "courses",
    //     path: "/courses",
    //     element: StudentCourses,
    //     role: ["student"],
    //     layout: MainLayout,
    // },
    // {
    //     name: "units",
    //     path: "/units/:idCourse",
    //     element: StudentUnits,
    //     role: ["student"],
    //     layout: MainLayout,
    //     hideLayoutOnMobile: true,
    // },
    // {
    //     name: "unit",
    //     path: "/course/:idCourse/unit/:idUnit",
    //     element: StudentUnitDetail,
    //     role: ["student"],
    //     layout: MainLayout,
    //     hideLayoutOnMobile: true,
    // },

    // 2. Classes:
    // {
    //     name: "classes",
    //     path: "/classes",
    //     element: StudentClasses,
    //     role: ["student"],
    //     layout: MainLayout,
    // },

    /**
     * ********************************************************************************
     * Student route:
     */
    {
        name: pages.exam_asset.name,
        path: pages.exam_asset.routePath,
        element: ExamAsset,
        role: ["student"],
        layout: BasicLayout,
    },
    // {
    //     name: "assignment",
    //     path: "/assignment",
    //     element: AssignmentStudent,
    //     role: ["student"],
    //     layout: MainLayout,
    // },
    // {
    //     name: "assignment_course",
    //     path: "/assignment/course/:courseId",
    //     element: AssignmentStudent,
    //     role: ["student"],
    //     layout: MainLayout,
    // },
    {
        name: pages.test.name,
        path: pages.test.routePath,
        element: Test,
        role: ["student"],
    },
    {
        name: "profile",
        path: "/profile",
        element: Profile,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    {
        name: "account_info",
        path: "/account-info",
        element: Setting,
        role: ["teacher", "employee", "employee_crm", "admin"],
        layout: MainLayout,
    },
    // // {
    // //     name: "classes",
    // //     path: "/classes",
    // //     role: ["student"],
    // //     element: ClassesStudent,
    // //     layout: MainLayout,
    // // },
    // {
    //     name: "scores",
    //     path: "/scores",
    //     element: ScoresStudent,
    //     role: ["student"],
    //     layout: MainLayout,
    // },
    // {
    //     name: "score",
    //     path: "/score/:id",
    //     element: ViewScoreStudent,
    //     role: ["student"],
    //     // layout: MainLayout,
    // },
    // {
    //     name: "score",
    //     path: "/score/:id",
    //     element: ViewScoreStudent,
    //     layout: MainLayout,
    // },
    {
        name: "not-found",
        path: "/not-found",
        role: ["teacher", "student", "employee", "employee_crm", "admin"],
        element: Page404,
    },
    {
        name: "personal-report",
        path: "/personal-report",
        role: [
            // "student",
            "teacher",
            "employee",
            "employee_crm",
            "admin",
        ],
        element: PersonalLearningProgress,
        layout: MainLayout,
    },
    {
        name: "general-report",
        path: "/general-report",
        role: ["teacher", "employee", "employee_crm", "admin"],
        element: GeneralLearningProgress,
        layout: MainLayout,
    },
    {
        name: "learning-progress-report",
        path: "/learning-progress-report",
        role: ["teacher", "employee", "employee_crm", "admin"],
        element: LearningProgressReport,
        layout: MainLayout,
    },

    {
        name: "gradebook",
        path: "/gradebook",
        role: [
            // "student",
            "teacher",
            "employee",
            "employee_crm",
            "admin",
        ],
        element: GradeBook,
        layout: MainLayout,
        hideLayoutOnMobile: true,
    },
    {
        name: "dashboard",
        path: "/dashboard",
        role: ["teacher", "employee", "employee_crm", "admin"],
        element: Dashboard,
        layout: MainLayout,
    },
    {
        name: "view_result",
        path: "/view-result",
        role: ["student"],
        element: StudentViewResult,
        layout: MainLayout,
    },
];
