import {
    DeleteOutlined,
    EditOutlined,
    FileDoneOutlined,
    HistoryOutlined,
    ShareAltOutlined,
    SolutionOutlined,
    TeamOutlined,
    UserAddOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Select, Space, Spin, Tag, Tooltip, notification } from "antd";
import { t } from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { deleteAssignment, fetchAssignmentsBank } from "src/api/containers/assignment";
import { fetchCourses } from "src/api/containers/course";
import { fetchLevels } from "src/api/containers/level";
import { ReactComponent as SvgFilter } from "src/assets/images/filter-icon.svg";
import { ReactComponent as GroupUser } from "src/assets/images/nav/group-user.svg";
import { ReactComponent as SvgPencil } from "src/assets/images/pencil.svg";
import { useFetch, useMediaQuery, useValues } from "src/hooks";
import Breadcrumb from "src/modules/components/Breadcrumb";
import { default as CustomButton } from "src/modules/components/Button";
import FilterMenu from "src/modules/components/FilterMenu";
import Icon from "src/modules/components/Icon";
import InputSearch from "src/modules/components/Input/InputSearch";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import ModalDetailExam from "src/modules/components/ModalDetailExam";
import ModalInviteLink from "src/modules/components/ModalInviteLink";
import Table from "src/modules/components/Table";
import { CourseDetailModal } from "src/modules/containers/TeacherCourses/containers/CourseDetail";
import { animationId } from "src/reducers/animation";
import { setCurrentPage } from "src/reducers/general";
import { CloseIconSVG, Contact, Monitor } from "src/utils/drawer";
import { compareObjects } from "src/utils/helpers/comparison";
import { getFilterValuesByURLSearchParams, getURLSearchParamsByFilterValues } from "src/utils/helpers/urlQueryString";
import ROLES from "src/utils/roles";
import { useRouting } from "src/utils/router";

import { HistoryContainer } from "../ManageHistory/HistoryFeature/HistoryContainer";
import ModalGeneralLearningProgress from "../TeacherCourses/components/ModalGeneralLearningProgress";
import ModalMarkAssignment from "../TeacherCourses/components/ModalMarkAssignment";
import ModalPersonalLearningProgress from "../TeacherCourses/components/ModalPersonalLearningProgress";
import "./Assignment.scss";
import PublicAssignmentUserModal from "./components/PublicAssignmentUserModal";

const Assignment = ({ isWithUrlQueryString = true, extraFixedFetchParams, excludedFilterFields }) => {
    const dispatch = useDispatch();

    // User:
    const { user } = useSelector((state) => state.auth);
    // Date format:
    const formatDateTime = useSelector((state) => state.general.formatDateTime);
    // Table row animation:
    const animate = useSelector((state) => state.animate.animation);
    const idRow = animate?.clone || animate?.add || animate?.update;
    const timeoutIdCourses = useRef(null);
    const navigate = useNavigate();
    const { generate } = useRouting();

    // FILTER SETTINGS:
    const fixedFetchParams = useRef({
        teacher_id: user.id,
    });

    const [studentInfo, setStudentInfo] = useState({});
    const [filterInfo, setFilterInfo] = useValues({
        isFirstTimeFetchDone: false,
        // Search box:
        inputSearch: "",
        // Filter menu:
        isVisible: false,
        levels: [],
        listCourse: [],
        isLoadingCourses: false,
        currPageForCourses: 1,
        course_type: "", // "class", "course",""
    });

    // const [dropDownBtnVisible, setDropDownBtnVisible] = useState(false);

    const [filterValuesInitial, filterValuesConfig] = useMemo(() => {
        // Flter values and config:
        const _filterValuesInitial = {
            page: 1,
            slug: undefined,
            is_public: null,
        };
        const _filterValuesConfig = {
            page: {
                queryKey: "p",
            },
            slug: {
                queryKey: "s",
            },
            is_public: {
                queryKey: "is_public",
                label: t("status"),
                values: ["true", "false"],
                valuesInfo: {
                    true: { label: t("assignment.public"), queryValue: "1" },
                    false: { label: t("assignment.private"), queryValue: "0" },
                },
            },
        };
        // Remove excluded filter values and config:
        const filterValuesInitial = {};
        const filterValuesConfig = {};
        Object.keys(_filterValuesInitial).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesInitial[elem] = _filterValuesInitial[elem];
            }
        });
        Object.keys(_filterValuesConfig).forEach((elem) => {
            if (!(excludedFilterFields?.length && excludedFilterFields.includes(elem))) {
                filterValuesConfig[elem] = _filterValuesConfig[elem];
            }
        });
        // Result:
        return [filterValuesInitial, filterValuesConfig];
    }, [filterInfo]);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [currFilterValues, setCurrFilterValues] = useState(filterValuesInitial || {});

    // FILTER MENU:
    const formFields = useMemo(() => {
        const handleSearchCourse = (keyword) => {
            clearTimeout(timeoutIdCourses.current);
            if (typeof keyword === "string") {
                setFilterInfo({
                    isLoadingCourses: true,
                });
                const fetchParams = {
                    slug: keyword,
                    is_class:
                        filterInfo.course_type === "course"
                            ? false
                            : filterInfo.course_type === "class"
                            ? true
                            : undefined,
                    page: 1,
                };
                timeoutIdCourses.current = setTimeout(() => {
                    fetchCourses(fetchParams).then((res) => {
                        if (res.status && res.data) {
                            setFilterInfo({
                                listCourse: res.data,
                                isLoadingCourses: false,
                                currPageForCourses: 1,
                            });
                        }
                    });
                }, 500);
            }
        };

        const _formFields = {
            ...(filterValuesConfig.level_id
                ? {
                      level_id: {
                          label: filterValuesConfig.level_id.label,
                          element: (
                              <Select
                                  className="app-select"
                                  placeholder={t("question.search_and_select_level")}
                                  allowClear
                                  showSearch
                                  optionFilterProp="children"
                              >
                                  {filterInfo.levels.map((item, index) => (
                                      <Select.Option key={`lvl${index}`} value={item.id}>
                                          {item.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
            ...(filterValuesConfig.is_public
                ? {
                      is_public: {
                          label: filterValuesConfig.is_public.label,
                          element: (
                              <Select className="app-select" placeholder={t("choose_status")} allowClear>
                                  {filterValuesConfig.is_public.values.map((item, itemIndex) => {
                                      return (
                                          <Select.Option key={`scope${itemIndex}`} value={item}>
                                              {filterValuesConfig.is_public.valuesInfo[item].label}
                                          </Select.Option>
                                      );
                                  })}
                              </Select>
                          ),
                          colProps: { span: 24 },
                      },
                  }
                : {}),
        };
        return _formFields;
    }, [filterInfo]);
    const formFieldGroups = useMemo(() => {
        return [
            {
                formFields: ["is_public"],
                rowProps: {
                    gutter: [24, 12],
                },
            },
        ];
    }, []);

    // API CALL:
    const [loading, dataSource, pagination, fetch, refetch] = useFetch({ is_unit: false }, fetchAssignmentsBank);

    // PAGE DATA:
    const currMedia = useMediaQuery(["(max-width: 768px)"]);
    const [editingAssignment, setEditingAssignment] = useState({});
    const [values, setValues] = useValues({
        // Modal:
        isConfirmModalVisible: false,
        isInvModalVisible: false,
        isAddAsgmtModalVisible: false,
        // Modal data:
        selectedItem: null,
        hideCandidateInfo: false,
        // Others:
        isOnMobile: false,
        isShowForm: false,
        // examType: "exam",
        isModalHistory: false,
        isShowModal: false,
        modal_name: undefined,

        //LearningProgress modal
        isGeneralLearningProgress: false,
        viewProgressAssignment: {},
        viewProgressCourse: {},
        viewProgressSubject: {},
        currentAssignmentId: "",
        isModalRecords: false,
        isPersonalLearningProgress: false,
        viewProgressIsUnit: false,
    });

    const renderMenu = (record) => {
        const _items = [];

        if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) && user?.id === record?.user_id) {
            _items.push({
                key: "1",
                label: (
                    <Space className="item-btn">
                        <TeamOutlined />
                        {t("course.manage_user")}
                    </Space>
                ),
                // disabled: showModalDetailCourse.isShown,
                onClick: () => {
                    setValues({
                        isShowModal: true,
                        selectedItem: record,
                        modal_name: "detail_class",
                        currentAssignmentId: record?.id,
                    });
                },
            });
        }

        // if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) && record?.is_public && user?.id === record?.user_id) {
        //     _items.push({
        //         key: "2",
        //         label: (
        //             <Space className="item-btn">
        //                 <UserAddOutlined />
        //                 {t("course.invite_teacher_marking")}
        //             </Space>
        //         ),

        //         onClick: () => {
        //             setValues({
        //                 isShowModal: "invite_teacher_marking",
        //                 selectedItem: record,
        //             });
        //         },
        //     });
        // }

        if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role)) {
            _items.push({
                key: "3",
                label: (
                    <Space className="item-btn">
                        <SolutionOutlined />
                        {t("course.mark_assignment")}
                    </Space>
                ),
                onClick: () => {
                    setValues({
                        isModalRecords: true,
                        selectedItem: record,
                        hideCandidateInfo: true,
                        currentAssignmentId: record?.id,

                        viewProgressAssignment: {
                            id: record.id,
                            name: record.name,
                        },
                        viewProgressCourse: {
                            id: record.course?.id,
                            name: record.course?.name,
                        },
                        ...(record?.level
                            ? {
                                  viewProgressLevel: {
                                      id: record?.level?.id,
                                      name: record?.level?.name,
                                  },
                              }
                            : {}),
                        viewProgressIsUnit: false, // Current tab is assignments manager
                    });
                },
            });
        }

        if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role) && user?.id === record?.user_id) {
            _items.push({
                key: "4",
                label: (
                    <Space className="item-btn">
                        <FileDoneOutlined />
                        {t("course.assignment_result")}
                    </Space>
                ),
                onClick: () => {
                    setValues({
                        isModalRecords: true,
                        selectedItem: record,
                        hideCandidateInfo: false,
                        currentAssignmentId: record?.id,

                        viewProgressAssignment: {
                            id: record.id,
                            name: record.name,
                        },
                        viewProgressCourse: {
                            id: record.course?.id,
                            name: record.course?.name,
                        },
                        ...(record?.level
                            ? {
                                  viewProgressLevel: {
                                      id: record?.level?.id,
                                      name: record?.level?.name,
                                  },
                              }
                            : {}),
                        viewProgressIsUnit: false, // Current tab is assignments manager
                    });
                },
            });
        }

        if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role)) {
            _items.push({
                key: "5",
                label: (
                    <Space className="item-btn">
                        <Contact />
                        {t("report.learning_progress_report_general")}
                    </Space>
                ),
                onClick: () => {
                    setValues({
                        isGeneralLearningProgress: true,
                        viewProgressAssignment: {
                            id: record?.id,
                            name: record?.name,
                        },
                        viewProgressCourse: {
                            id: record.course?.id,
                            name: record.course?.name,
                        },
                        ...(record?.category
                            ? {
                                  viewProgressSubject: {
                                      id: record?.category?.id,
                                      name: record?.category?.name,
                                  },
                              }
                            : {}),
                    });
                },
            });
        }

        if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role)) {
            _items.push({
                key: "6",
                label: (
                    <Space className="item-btn">
                        <Monitor />
                        {t("course.report")}
                    </Space>
                ),
                onClick: () => {
                    window.open(
                        `${location.origin}${generate("assignment_report", {
                            courseId: record?.course_id,
                            assignmentId: record?.id,
                        })}`,
                        "_blank"
                    );
                },
            });
        }

        if (ROLES.ADMIN_EMPLOYEE_TEACHER.includes(user?.role)) {
            _items.push({
                key: "7",
                label: (
                    <Space className="item-btn">
                        <HistoryOutlined />
                        {t("nav.history")}
                    </Space>
                ),
                onClick: () => {
                    setValues({
                        isModalHistory: true,
                        selectedItem: record,
                        isShowModal: true,
                        modal_name: "history",
                    });
                },
            });
        }
        return <Menu className="assignment-bank action-btn-menu" items={_items} />;
    };

    const columns = [
        {
            title: t("assignment.name"),
            dataIndex: "name",
            key: "name",
            width: "auto",
        },
        {
            title: t("assignment.exam_name"),
            dataIndex: "exam_name",
            key: "exam_name",
            width: "auto",
            render: (text, record) => record?.exam?.name,
        },
        {
            title: t("status"),
            dataIndex: "is_public",
            key: "is_public",
            width: "auto",
            render: (text) => (text ? t("assignment.public") : t("assignment.private")),
        },
        {
            title: t("assignment.begin_date"),
            dataIndex: "begin_date",
            key: "begin_date",
            width: "auto",
            render: (text, record) => {
                return record.begin_date
                    ? moment(record.begin_date).format(formatDateTime)
                    : t("assignment.time_not_limited");
            },
        },
        {
            title: t("assignment.end_date"),
            dataIndex: "end_date",
            key: "end_date",
            width: "auto",
            render: (text, record) => {
                return record.end_date
                    ? moment(record.end_date).format(formatDateTime)
                    : t("assignment.time_not_limited");
            },
        },
        {
            title: t("assignment.max_score"),
            dataIndex: "max_score",
            key: "max_score",
            width: "auto",
            render: (text, record) => record?.exam?.max_score || 0,
        },
        {
            title: t("assignment.duration"),
            dataIndex: "homework_time_minute",
            key: "homework_time_minute",
            width: "auto",
            render: (text, record) => record?.homework_time_minute || t("assignment.time_not_limited"),
        },
        {
            title: t("course.submission"),
            dataIndex: "count_submission",
            key: "count_submission",
            render: (text, record) => record?.count_record?.count_submit || 0,
        },
        {
            title: t("course.graded"),
            dataIndex: "count_marked",
            key: "count_marked",
            render: (text, record) => record?.count_record?.count_submit || 0,
        },
        {
            title: t("table.actions"),
            dataIndex: "actions",
            key: "actions",
            width: "auto",
            render: (text, record) => (
                <div className="table-action question-bank-action assignment-bank-action list-actions">
                    <Dropdown
                        overlay={renderMenu(record)}
                        onClick={handleDropdownBtnClick}
                        trigger={["click", "hover"]}
                        placement="bottomRight"
                        // visible={true}
                    >
                        <Button className="btn-action-dropdown" type="ghost" shape="round" style={{ borderRadius: 10 }}>
                            {t("table.actions")}
                        </Button>
                    </Dropdown>

                    <Tooltip placement="top" title={t("assignment.invite_via_link")}>
                        <CustomButton
                            className="list-btn"
                            type="simple"
                            icon={<ShareAltOutlined />}
                            onClick={() => {
                                setValues({
                                    isInvModalVisible: true,
                                    selectedItem: record,
                                    isShowModal: true,
                                    modal_name: "invite_link",
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title={t("shared.update")}>
                        <CustomButton
                            className="list-btn"
                            type="simple"
                            icon={<EditOutlined />}
                            onClick={() => handleUpdateAssignment(record)}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title={t("shared.delete")}>
                        <CustomButton
                            className="list-btn"
                            type="simple"
                            icon={<DeleteOutlined />}
                            onClick={() => handleShowConfirmModal(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    function handleDropdownBtnClick(e) {
        // setDropDownBtnVisible((s) => !s);
    }

    const handleUpdateQueryString = (urlQueries = {}) => {
        const sParams = {};
        urlQueries?.p && (sParams.p = urlQueries.p);
        urlQueries?.s && (sParams.s = urlQueries.s);
        // urlQueries?.own && (sParams.own = urlQueries.own);
        urlQueries?.is_public && (sParams.is_public = urlQueries.is_public);
        urlQueries?.lvl && (sParams.lvl = urlQueries.lvl);
        urlQueries?.course_type && (sParams.course_type = urlQueries.course_type);
        setUrlSearchParams(sParams);
    };

    const handleFetchDataList = (fetchParams = {}, isReload = true) => {
        const fParams = {
            page: fetchParams.page,
            slug: fetchParams.slug,
            // teacher_id: fetchParams.teacher_id,
            // is_test: fetchParams.is_test,
            level_id: fetchParams.level_id,
            course_id: fetchParams.course_id,
            is_public: ["true", "false"].includes(fetchParams.is_public) ? fetchParams.is_public : undefined,
            ...(Object.keys(fixedFetchParams.current || {})?.length ? fixedFetchParams.current : {}),
            ...(Object.keys(extraFixedFetchParams || {})?.length ? extraFixedFetchParams : {}),
        };
        fetch({ ...fParams }, isReload);
        // Update search box's value:
        if (fParams.slug) {
            setFilterInfo({ inputSearch: fParams.slug });
        }
    };

    const handleSearch = () => {
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            slug: filterInfo.inputSearch,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handlePagination = (page) => {
        const newFilterValues = {
            ...currFilterValues,
            page: page,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleRemoveFilterParam = (e, fieldName) => {
        e && e.preventDefault();
        const newFilterValues = {
            ...currFilterValues,
            page: 1,
            [fieldName]: undefined,
        };
        if (isWithUrlQueryString) {
            const newUrlSearchParams = getURLSearchParamsByFilterValues(newFilterValues, filterValuesConfig);
            handleUpdateQueryString(newUrlSearchParams);
        } else {
            setCurrFilterValues(newFilterValues);
        }
    };

    const handleUpdateAssignment = (item) => {
        setEditingAssignment(item);
        setValues({
            isShowModal: true,
            modal_name: "detail_exam",
            isShowForm: true,
            // examType: item?.is_test === false ? "assignment" : "exam",
        });
    };

    const handleDeleteAssignment = () => {
        const item = values.selectedItem;
        deleteAssignment(item.id).then(({ status, message }) => {
            if (status) {
                notification.success({ message: t("message.delete_success") });
                setValues({ isShowModal: false, modal_name: undefined });
                refetch();
            } else {
                notification.error({ message: message || t("message.delete_error") });
            }
        });
    };

    const handleShowConfirmModal = (item) => {
        setValues({ isShowModal: true, modal_name: "confirm", selectedItem: item });
    };

    function handleFetchClasses({ isClass }) {
        fetchCourses({ is_class: isClass })
            .then(({ status, data }) => {
                if (status) {
                    setFilterInfo({ listCourse: data });
                }
            })
            .catch((err) => {});
    }

    useEffect(() => {
        dispatch(setCurrentPage("assignment"));

        fetchLevels({ slug: "", noPagination: true }).then((res) => {
            setFilterInfo({ levels: res.data });
        });
    }, []);

    useEffect(() => {
        const isClass =
            filterInfo.course_type === "course" ? false : filterInfo.course_type === "class" ? true : undefined;
        handleFetchClasses({ isClass });
    }, [filterInfo.course_type]);

    useEffect(() => {
        if (isWithUrlQueryString) {
            const currUrlSearchParams = Object.fromEntries(urlSearchParams.entries());
            // Deal with one of these cases:
            // 1. Handle update query string whenever:
            // - The URL query string is empty and it's the first time fetch.
            // 2. Handle fetching whenever:
            // - The URL query string is not empty.
            // - The URL query string is empty but it's not the first time fetch.
            const isUrlQSEmpty = Object.keys(currUrlSearchParams).length === 0;
            const isFirstTimeFetch = filterInfo.isFirstTimeFetchDone === false;
            if (isUrlQSEmpty && isFirstTimeFetch) {
                const newUrlSearchParams = getURLSearchParamsByFilterValues(currFilterValues, filterValuesConfig);
                handleUpdateQueryString(newUrlSearchParams);
            } else {
                if (!isUrlQSEmpty || (isUrlQSEmpty && !isFirstTimeFetch)) {
                    const newFilterValues = getFilterValuesByURLSearchParams(currUrlSearchParams, filterValuesConfig);
                    if (compareObjects(currFilterValues, newFilterValues) === false) {
                        setCurrFilterValues(newFilterValues);
                        setFilterInfo({ ...filterInfo, isFirstTimeFetchDone: true });
                        // Update data list whenever the URL query string is changed:
                        handleFetchDataList(newFilterValues, true);
                    }
                }
            }
        } else {
            // Update data list whenever currFilterValues is changed:
            handleFetchDataList(currFilterValues, true);
        }
    }, [urlSearchParams, currFilterValues]);

    useEffect(() => {
        // Animation happens whenever asgmt is added/updated/cloned:
        if (animate?.name == "assignment") {
            const rowAnimation = (id, action, timeout = 4000) => {
                setTimeout(() => {
                    const str = "[data-row-key=" + "'" + id + "']";
                    const eln = document.querySelector(str);

                    eln?.classList?.add(`row-${action}-animate`);

                    setTimeout(() => {
                        // setIdRowClone();
                        dispatch(animationId({ name: "", [action]: "" }));
                        eln?.classList?.remove(`row-${action}-animate`);
                    }, timeout);
                }, 200);
            };
            if (animate?.clone) {
                rowAnimation(idRow, "clone");
            }
            if (animate?.add) {
                rowAnimation(idRow, "add");
            }
            if (animate?.update) {
                rowAnimation(idRow, "update", 3000);
            }
        }
    }, [dataSource]);

    useEffect(() => {
        if (currMedia.breakpointValue !== -1) {
            setValues({ isOnMobile: true });
        } else if (values.isOnMobile) {
            setValues({ isOnMobile: false });
        }
    }, [currMedia.breakpointValue]);

    useEffect(() => {
        if (values.selectedItem?.id) {
            for (let i = 0; i < dataSource.length; i++) {
                if (values.selectedItem.id === dataSource[i].id) {
                    setValues({ selectedItem: dataSource[i] });
                    return;
                }
            }
        }
    }, [dataSource]);

    return (
        <>
            <div className="question-bank exam-bank assignment-bank">
                {/* <Header backEnable={false} backTitle={t("header.assignment")} actions={[]} /> */}
                <Breadcrumb
                    routes={[
                        {
                            name: t("header.exam"),
                        },
                    ]}
                />

                <div className="filter-toolbar-wrapper">
                    <div className="filter-toolbar">
                        <div className="filter-toolbar-item filterinput-wrapper">
                            <InputSearch
                                displayType="style-dream"
                                placeholder={t("shared.find_assignment")}
                                value={filterInfo.inputSearch}
                                onChange={(e) => {
                                    setFilterInfo({ ...filterInfo, inputSearch: e.target.value });
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                onClickIconSearch={handleSearch}
                            />
                        </div>
                        <div className="filter-toolbar-item filtermenu-wrapper ">
                            <div className="filter-keys-bar-wrapper">
                                <Space className="filter-keys-bar" align="center" wrap size={4}>
                                    {(!isWithUrlQueryString || filterInfo.isFirstTimeFetchDone) &&
                                        Object.keys(currFilterValues).map((fKey, i) => {
                                            const fValue = currFilterValues[fKey];
                                            const fValueLabel =
                                                filterValuesConfig[fKey]?.valuesInfo?.[fValue]?.label ||
                                                (filterValuesConfig[fKey]?.getValueLabel instanceof Function
                                                    ? filterValuesConfig[fKey].getValueLabel(fValue)
                                                    : undefined);
                                            if (fValue && fValueLabel) {
                                                return (
                                                    <Tag
                                                        className="app-tag"
                                                        key={`filter-key${i}`}
                                                        closable
                                                        onClose={(e) => handleRemoveFilterParam(e, fKey)}
                                                    >
                                                        {`${fValueLabel ? t(fValueLabel) : ""}`}
                                                    </Tag>
                                                );
                                            }
                                            return null;
                                        })}
                                </Space>
                            </div>
                            <Dropdown
                                forceRender
                                visible={filterInfo.isVisible}
                                overlay={
                                    <FilterMenu
                                        className="assignment_filter_menu"
                                        formLayout={"horizontal"}
                                        formFields={formFields}
                                        formFieldGroups={formFieldGroups}
                                        formData={currFilterValues}
                                        willResetForm={filterInfo.isVisible === false}
                                        onCancel={() => {
                                            setFilterInfo({
                                                ...filterInfo,
                                                isVisible: false,
                                            });
                                        }}
                                        onSubmit={(newFilterValues) => {
                                            newFilterValues.page = 1;
                                            if (isWithUrlQueryString) {
                                                const currUrlSearchParams = Object.fromEntries(
                                                    urlSearchParams.entries()
                                                );

                                                const newUrlSearchParams = getURLSearchParamsByFilterValues(
                                                    newFilterValues,
                                                    filterValuesConfig
                                                );

                                                if (compareObjects(currUrlSearchParams, newUrlSearchParams) === false) {
                                                    handleUpdateQueryString(newUrlSearchParams);
                                                }
                                            } else {
                                                setCurrFilterValues(newFilterValues);
                                            }
                                        }}
                                    />
                                }
                                trigger={["click"]}
                                placement="bottomRight"
                                onVisibleChange={(val) => {
                                    setFilterInfo({ ...filterInfo, isVisible: val });
                                }}
                            >
                                <div className="filter-button">
                                    <CustomButton
                                        type="primary"
                                        icon={<SvgFilter />}
                                        title={t("shared.option_filter")}
                                    ></CustomButton>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filter-toolbar-actions">
                        <CustomButton
                            type="primary"
                            icon={<SvgPencil />}
                            title={t("shared.add_assignment")}
                            onClick={() => {
                                setEditingAssignment({});
                                setValues({ isShowModal: true, modal_name: "detail_exam" });
                            }}
                        ></CustomButton>
                    </div>
                </div>

                <div className="table assignment-bank-table">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        nodata_title={t("shared.no_data")}
                        loading={loading}
                        pagination={{
                            showSizeChanger: false,
                            ...pagination,
                            position: ["bottomCenter"],
                            onChange: handlePagination,
                        }}
                        scroll={{ x: "auto" }}
                        rowKey="id"
                        responsiveOnMobile={true}
                        mobileHeaderColumns={["name", "exam_name", "is_public"]}
                    />
                </div>
            </div>

            <ModalDetailExam
                isLoading={loading}
                visible={values.isShowModal && values.modal_name === "detail_exam"}
                isOnMobile={values.isOnMobile}
                isShowFormSelect={
                    (values.isShowModal && values.modal_name === "detail_exam" && values.isShowForm) ||
                    values.isOnMobile
                }
                editingAssignment={editingAssignment}
                // examType={values.examType}
                examType="exam"
                setValuesExam={setValues}
                onOk={() => {
                    refetch();
                    setValues({ isShowModal: false, modal_name: undefined, isShowForm: false });
                    setEditingAssignment({});
                }}
                onCancel={() => {
                    setValues({ isShowModal: false, modal_name: undefined, isShowForm: false });
                    setEditingAssignment({});
                }}
                destroyOnClose
            ></ModalDetailExam>

            <PopConfirm
                type="delete"
                visible={values.isShowModal && values.modal_name === "confirm"}
                onOk={handleDeleteAssignment}
                onCancel={() => setValues({ isShowModal: false, modal_name: undefined })}
            />

            <ModalInviteLink
                inviteLink={`${location.origin}/exam-checkin?code=` + values.selectedItem?.code}
                code={values.selectedItem?.code}
                visible={values.isShowModal && values.modal_name === "invite_link"}
                onOk={() => {
                    setValues({ isShowModal: false, modal_name: undefined });
                }}
                onCancel={() => {
                    setValues({ isShowModal: false, modal_name: undefined });
                }}
            />
            <Modal
                title={t("history.editing_history")}
                visible={values.isShowModal && values.modal_name === "history"}
                onOk={() => {
                    setValues({ isShowModal: false, modal_name: undefined });
                }}
                onCancel={() => {
                    setValues({ isShowModal: false, modal_name: undefined });
                }}
                closeIcon={<CloseIconSVG />}
                footer={null}
                className={"modal-add-course modal_histories"}
                width={"auto"}
                destroyOnClose={true}
                centered={true}
            >
                <HistoryContainer modal_id={values.selectedItem?.id} type="assignment_history" />
            </Modal>

            {/* <Modal
                visible={values.isShowModal && values.modal_name === "detail_class"}
                centered
                // title={t("course.add_exam")}
                // closeIcon={<img src={iconClose}></img>}
                footer={null}
                width="auto"
                className={`app-modal type-basic flexible-height  modal-detail-class`}
                style={{ minWidth: 1200, minHeight: "70vh" }}
                onOk={() => {
                    setValues({ isShowModal: false, modal_name: undefined });
                }}
                onCancel={() => {
                    setValues({ isShowModal: false, modal_name: undefined });
                }}
            >
                <CourseDetail />
            </Modal> */}

            <PublicAssignmentUserModal
                isLoading={loading}
                assignmentInfo={values.selectedItem}
                visible={values.isShowModal === "invite_teacher_marking"}
                onOk={() => {
                    setValues({ isShowModal: false });
                }}
                onCancel={() => {
                    setValues({ isShowModal: false });
                }}
                onAddUsersManuallySuccess={() => {
                    refetch();
                }}
                onDeleteUsersManuallySuccess={() => {
                    refetch();
                }}
            />
            {values.isShowModal && values.modal_name === "detail_class" && (
                <CourseDetailModal
                    code={values.selectedItem?.code}
                    courseId={values.selectedItem?.course?.id}
                    visible={values.isShowModal && values.modal_name === "detail_class"}
                    assignmentId={values.currentAssignmentId}
                    examInfo={values.selectedItem?.exam}
                    assignmentInfo={values.selectedItem}
                    onOk={() => {
                        setValues({ isShowModal: false, modal_name: undefined });
                    }}
                    onCancel={() => {
                        setValues({ isShowModal: false, modal_name: undefined });
                    }}
                />
            )}
            {values.isGeneralLearningProgress && (
                <ModalGeneralLearningProgress
                    className="class-detail-modal detail-learning-progress"
                    centered
                    title={t("report.general_learning_progress")}
                    footer={null}
                    visible={values.isGeneralLearningProgress}
                    onCancel={() => setValues({ isGeneralLearningProgress: false })}
                    destroyOnClose
                    width="calc(100vw - 40px)"
                    height="calc(100vh - 40px)"
                    style={{
                        maxWidth: "1400px",
                        maxHeight: "1400px",
                        margin: "20px 0",
                    }}
                    closeIcon={<Icon name="icon-cross-thin" />}
                    viewProgressAssignment={values.viewProgressAssignment}
                    viewProgressSubject={values.viewProgressSubject}
                    viewProgressCourse={values.viewProgressCourse}
                />
            )}

            {values.isPersonalLearningProgress && (
                <ModalPersonalLearningProgress
                    title={t("report.personal_learning_progress")}
                    visible={values.isPersonalLearningProgress}
                    viewProgressCourse={values.viewProgressCourse}
                    viewProgressLevel={values.viewProgressLevel}
                    viewProgressIsUnit={values.viewProgressIsUnit}
                    onCancel={() => {
                        setValues({ isPersonalLearningProgress: false });
                    }}
                    viewProgressStudent={studentInfo}
                />
            )}

            {values.isModalRecords && (
                <ModalMarkAssignment
                    className="course-detail-modal detail-marking"
                    centered
                    title={t("course.mark_assignment")}
                    footer={null}
                    visible={values.isModalRecords}
                    onCancel={() => setValues({ isModalRecords: false })}
                    destroyOnClose
                    width="calc(100vw - 40px)"
                    height="calc(100vh - 40px)"
                    style={{
                        maxWidth: "1400px",
                        maxHeight: "1400px",
                        margin: "20px 0",
                    }}
                    closeIcon={<Icon name="icon-cross-thin" />}
                    markingAssignmentId={values.currentAssignmentId}
                    setIsPersonalLearningProgress={(v) => setValues({ isPersonalLearningProgress: v })}
                    setStudentInfo={setStudentInfo}
                    hideCandidateInfo={values.hideCandidateInfo}
                />
            )}
        </>
    );
};

Assignment.propTypes = {
    isWithUrlQueryString: PropTypes.bool,
    extraFixedFetchParams: PropTypes.object,
    excludedFilterFields: PropTypes.array,
};

export default Assignment;
