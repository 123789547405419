import { Timeline } from "antd";
import { HistoryTimeLineItem } from "../HistoryTimeLineItem";

function HistoryTimeLine({ histories, timelineMode = "left", type }) {
    return (
        <Timeline mode={timelineMode} style={{ paddingTop: 10 }}>
            {histories?.map((item) => {
                const modalType = item?.historiable_type?.substr(11);
                return (
                    <Timeline.Item key={item.id}>
                        <HistoryTimeLineItem item={item} modalType={modalType} type={type} />
                    </Timeline.Item>
                );
            })}
        </Timeline>
    );
}

export default HistoryTimeLine;
