import { Modal } from "antd";
import React, { useEffect } from "react";
import { t } from "i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import { ReactComponent as SvgClose } from "src/assets/images/action-close.svg";
import { ReactComponent as SvgCross } from "src/assets/images/app-icons/app-cross.svg";
import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import "./DisplaysCheckTrigger.scss";

const DisplaysCheckTrigger = ({ className, visible = false, onOk, onCancel, ...rest }) => {
    const handleCancel = () => {
        if (onCancel instanceof Function) {
            onCancel();
        }
    };

    const handleSubmit = () => {
        if (onOk instanceof Function) {
            onOk();
        }
    };

    return (
        <Modal
            centered
            visible={visible}
            title={
                <div className="check-info-title">
                    <div className="title-icon">
                        <QuestionCircleOutlined />
                    </div>
                    <div className="title-content">
                        <div className="content-main">{t("exam_checkin.displays_info.what")}</div>
                    </div>
                </div>
            }
            closeIcon={<SvgClose />}
            closable={false}
            footer={null}
            className={
                "app-modal flexible-height type-primary maxw-520 modal-displayschktrggr" +
                (className ? ` ${className}` : "")
            }
            wrapClassName="modal-displayschktrggr-wrap"
            onOk={handleSubmit}
            // onCancel={handleCancel}
            {...rest}
        >
            <div className="modal-wraper">
                <div className="modal-title">{t("exam_checkin.displays_info.why")}</div>
                <div className="modal-actions">
                    {/* <CustomButton
                        type="grey"
                        icon={<SvgCross />}
                        title={t("shared.cancel")}
                        onClick={handleCancel}
                    ></CustomButton> */}
                    <CustomButton
                        type="primary"
                        icon={<SvgTick />}
                        title={t("shared.ok")}
                        onClick={handleSubmit}
                    ></CustomButton>
                </div>
            </div>
        </Modal>
    );
};

export default DisplaysCheckTrigger;
