import api from "..";

export const fetchGuides = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.filter && filters.push(`${fetchParams.filter}`);
    fetchParams.type && filters.push(`type:${fetchParams.type}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _guide_fields: `${["type", "image", "description", "order", "roles", "childGuides", "parent_id", "title"]}`,
    };
    if (fetchParams?.sort) {
        params._orderBy = `order:${fetchParams.sort}`;
    }
    if (fetchParams.is_split) {
        params.is_split = 1;
    }
    return api
        .get("/guide", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const createGuide = (data) => {
    return api
        .post("/guide", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const updateGuide = (id, data) => {
    return api
        .put(`/guide/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const deleteGuide = (id) => {
    return api
        .delete(`/guide/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const findGuide = (id) => {
    const params = {
        _guide_fields: `${["type", "image", "description", "order", "roles", "childGuides", "parent_id", "title"]}`,
    };
    return api
        .get(`/guide/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const fetchRecommend = (type, parent_id) => {
    const params = {};
    type && Object.assign(params, { type: type });
    parent_id && Object.assign(params, { parent_id: parent_id });

    return api
        .get("/guide/recommend", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: [] }));
};
