import React from "react";
import { useSelector } from "react-redux";

import imgBad1 from "src/assets/images/id-card/bad1.jpg";
import imgBad2 from "src/assets/images/id-card/bad2.jpg";
import imgBadRotate from "src/assets/images/id-card/bad_rotate.jpg";
import imgOk from "src/assets/images/id-card/good.jpg";
import configs from "src/configs";

import "./IdCardPolicy.scss";

const { MAX_FILE_SIZE } = configs.EXAM_PROCTORING.FACE_IN_ID_CARD;
const file_size = MAX_FILE_SIZE / 1024 / 1024;

const listPolicy = {
    en: [
        {
            title: "Requirements",
            list: [
                {
                    text: "The input image must have 4 clear angles",
                },
                {
                    text: "All information fields must be visible, readable, unmodified, not blurred.",
                },
                {
                    text: `The input image size does not exceed  ${file_size} MB and the minimum resolution is approximately 640x480 to ensure the confident rate.`,
                },
                {
                    text: "The ratio of ID card area must be at least ¼ of the total image area.",
                },
                {
                    text: "Photos must be taken in the right direction, do not rotate the photo to another direction",
                },
            ],
        },
        {
            title: "Standard image",
            list: [
                {
                    text: "Photos meet standards",
                    img: [imgOk],
                },
            ],
        },
        {
            title: "Bad image",
            list: [
                {
                    text: "Image with low quality and lack of corner",
                    img: [imgBad1, imgBad2],
                },
                {
                    text: "Image with low quality and lack of information",
                    img: [imgBad1, imgBad2],
                },
                {
                    text: "The photo is rotated to the right",
                    img: [imgBadRotate],
                },
            ],
        },
    ],
    vi: [
        {
            title: "Yêu cầu",
            list: [
                {
                    text: "Ảnh đầu vào phải đủ 4 góc rõ ràng",
                },
                {
                    text:
                        "Các trường thông tin mã số ID và hình ảnh của bạn trên thẻ phải không được mờ, và không bị sửa đổi",
                },
                {
                    text: `Kích thước hình ảnh đầu vào không vượt quá ${file_size} MB và độ phân giải tối thiểu khoảng 640x480 để đảm bảo tỷ lệ đọc chính xác.`,
                },
                {
                    text: "Tỷ lệ diện tích thẻ ít nhất phải bằng ¼ tổng diện tích ảnh.",
                },
                {
                    text: "Ảnh chụp phải đúng hướng, không được quay xoay ảnh sang hướng khác",
                },
            ],
        },
        {
            title: "Hình ảnh chuẩn",
            list: [
                {
                    text: "Ảnh đủ tiêu chuẩn",
                    img: [imgOk],
                },
            ],
        },
        {
            title: "Ảnh kém chất lượng",
            list: [
                {
                    text: "Ảnh chất lượng thấp và bị mất góc",
                    img: [imgBad1],
                },
                {
                    text: "Ảnh chất lượng thấp và thiếu thông tin",
                    img: [imgBad2],
                },
                {
                    text: "Ảnh bị quay sang phải",
                    img: [imgBadRotate],
                },
            ],
        },
    ],
};

function IdCardPolicy() {
    const lang = useSelector((state) => state.general?.lang);
    const policies = listPolicy[lang === "en" ? "en" : "vi"];

    return (
        <div className="id-card-policy">
            {policies.map((item) => {
                return (
                    <>
                        <h2>{item.title}</h2>
                        <ul>
                            {item.list.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        {item?.text && <p>{item.text}</p>}
                                        {item?.img && item?.img?.map((item, idx) => <img key={idx} src={item} />)}
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                );
            })}
        </div>
    );
}

export default IdCardPolicy;
