import api from "..";

export const fetchExams = (fetchParams) => {
    const filters = [];
    fetchParams?.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.user_id && filters.push(`user_id:${fetchParams.user_id}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.level_id && filters.push(`level_id:${fetchParams.level_id}`);
    fetchParams.language_id && filters.push(`language_id:${fetchParams.language_id}`);
    fetchParams.is_publish !== undefined && filters.push(`is_publish:${fetchParams.is_publish}`);
    fetchParams.is_public !== undefined && filters.push(`is_public:${fetchParams.is_public}`);
    fetchParams.is_show && filters.push(`is_show:${fetchParams.is_show}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _examquestion_fields: `${["name", "is_publish", "is_public", "employee_can_edit", "language_id"]}`,
        _relations: `${["user", "level", "language"]}`,
        _user_fields: `${["name"]}`,
        _level_fields: `${["name"]}`,
        _language_fields: `${["name", "code"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/exam_question", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findExam = (id, fetchParams) => {
    const filters = [];
    const extraRelations = [];
    const extraFields = {};
    if (fetchParams.assignment_id) {
        filters.push(`assignment_id:${fetchParams.assignment_id}`);
        extraRelations.push("assignments");
        extraFields._assignment_fields = `${["name"]}`;
    }
    fetchParams.is_show && filters.push(`is_show:${fetchParams.is_show}`);

    const params = {
        _filter: filters.join(";") || undefined,
        _examquestion_fields: `${[
            "name",
            "is_publish",
            "is_public",
            "question_list",
            "level_id",
            "max_score",
            "employee_can_edit",
            "language_id",
        ]}`,
        _relations: `${["user", "level", "language", "question_type", ...extraRelations]}`,
        _user_fields: `${["name", "role"]}`,
        _level_fields: `${["name"]}`,
        _language_fields: `${["name", "code"]}`,
        _question_type_fields: `${["description", "name", "type", "parent_id"]}`,
        ...extraFields,
    };
    return api
        .get(`/exam_question/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const updateExam = (id, data) => {
    return api
        .put(`/exam_question/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const createExam = (data) => {
    return api
        .post("/exam_question", data)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};

export const deleteExam = (id) => {
    return api
        .delete(`/exam_question/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const cloneExam = (id) => {
    return api
        .post(`/exam_question/${id}/clone`)
        .then(({ status, message, id }) => ({ status, message, id }))
        .catch(() => ({ status: false }));
};
