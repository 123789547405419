import { Dropdown } from "antd";
import { t } from "i18next";
import moment from "moment";

import Book from "src/assets/images/book-icon.svg";
import Calender from "src/assets/images/calender.svg";
import GraduateHat from "src/assets/images/graduate-hat.svg";
import DetailClassHeader from "src/assets/images/header_class.png";
import QRCode from "src/assets/images/qr_code.svg";
import User from "src/assets/images/user.svg";
import Breadcrumb from "src/modules/components/Breadcrumb";

import "./Header.scss";

const Header = (props) => {
    const { courseData = {}, studentNumber = undefined, ...rest } = props;
    const formatDate = "DD/MM/YYYY";
    // const isDetailCourse = Object.keys(courseData).length !== 0;
    const isDetailCourse = !!courseData;

    const CourseMenu = () => (
        <div className="course-header-dropdown-menu">
            {courseData.course_names &&
                courseData.course_names.map((course, index) => {
                    return <strong key={`course-${index}`}>{course}</strong>;
                })}
        </div>
    );

    const TeacherMenu = () => (
        <div className="course-header-dropdown-menu">
            {courseData.teachers &&
                courseData.teachers.map((teacher, index) => {
                    return <strong key={`teacher-${index}`}>{teacher.name}</strong>;
                })}
        </div>
    );

    return (
        <>
            {!isDetailCourse ? (
                <>
                    <Breadcrumb
                        routes={[
                            {
                                name: t("course.course_list"),
                            },
                        ]}
                    />
                    {/* <div className="course-header">
                        <div className="course-header-wrapper">
                            <TVStatistic />
                            <p className="course-header-title">{t("course.list_course")}</p>
                            <div className="course-header-img">
                                <img src={GirlAndBook} alt="" />
                            </div>
                        </div>
                    </div> */}
                </>
            ) : (
                <div className="detail-course-header" {...rest}>
                    <div className="detail-course-header-wrapper">
                        <div className="course-img-wrapper">
                            <img src={DetailClassHeader} alt="220x135" />
                        </div>

                        <ul>
                            <li>
                                <p>
                                    <span className="detail-course-icon">
                                        <img src={QRCode} alt="icon" />
                                    </span>
                                    <span>{t("Code")}</span>
                                    <strong>{courseData.code ? courseData.code : ""}</strong>
                                </p>
                            </li>

                            {/* <li>
                                <p>
                                    <span className="detail-course-icon">
                                        <img src={QRCode} alt="icon" />
                                    </span>
                                    <span>{t("course.level")}</span>
                                    <strong>{courseData.level?.name ? courseData.level.name : "_"}</strong>
                                </p>
                            </li> */}

                            {courseData?.course_names ? (
                                <li>
                                    <p>
                                        <span className="detail-course-icon">
                                            <img src={Book} alt="icon" />
                                        </span>
                                        <span>{t("course.xxx")}</span>
                                        {courseData?.course_names.length !== 0 ? (
                                            courseData?.course_names.length <= 2 ? (
                                                courseData.course_names.map((d, i) => {
                                                    return <strong key={`course-${i}`}>{d || ""}</strong>;
                                                })
                                            ) : (
                                                <Dropdown overlay={<CourseMenu />} placement="bottom">
                                                    <strong className="show-more">...</strong>
                                                </Dropdown>
                                            )
                                        ) : (
                                            <strong>{courseData?.name || "_"}</strong>
                                        )}
                                    </p>
                                </li>
                            ) : null}

                            <li>
                                <p>
                                    <span className="detail-course-icon">
                                        <img src={Calender} alt="icon" />
                                    </span>
                                    <span>{t("course.begin_date")}</span>
                                    <strong>
                                        {courseData.begin_date ? moment(courseData.begin_date).format(formatDate) : ""}
                                    </strong>
                                </p>
                            </li>

                            <li>
                                <p>
                                    <span className="detail-course-icon">
                                        <img src={Calender} alt="icon" />
                                    </span>
                                    <span>{t("course.end_date")}</span>
                                    <strong>
                                        {courseData.end_date ? moment(courseData.end_date).format(formatDate) : ""}
                                    </strong>
                                </p>
                            </li>

                            {courseData?.teachers?.length ? (
                                <li>
                                    <p>
                                        <span className="detail-course-icon">
                                            <img src={User} alt="icon" />
                                        </span>
                                        <span>{t("course.teacher")}</span>
                                        {courseData?.teachers.length !== 0 ? (
                                            courseData?.teachers.length <= 2 ? (
                                                courseData.teachers.map((t, i) => {
                                                    return <strong key={`teacher-${i}`}>{t.name || ""}</strong>;
                                                })
                                            ) : (
                                                <Dropdown overlay={<TeacherMenu />} placement="bottom">
                                                    <strong className="show-more">...</strong>
                                                </Dropdown>
                                            )
                                        ) : (
                                            <strong>{t("course.no_teacher")}</strong>
                                        )}
                                    </p>
                                </li>
                            ) : null}

                            <li>
                                <p>
                                    <span className="detail-course-icon">
                                        <img src={GraduateHat} alt="icon" />
                                    </span>
                                    <span>{t("course.student_amount")}</span>
                                    <strong>{courseData.total_student || studentNumber || "_"}</strong>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
