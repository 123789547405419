import { DoubleRightOutlined } from "@ant-design/icons";
import { Buffer } from "buffer";
import { t } from "i18next";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { getFaceDetectorOptions } from "./helpers/faceapi";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const base64_encode = (data) => {
    return Buffer.from(data).toString("base64");
};

export const base64_decode = (data) => {
    return Buffer.from(data, "base64").toString();
};

export function parse_jwt(token) {
    try {
        return JSON.parse(base64_decode(token.split(".")[1]));
    } catch (e) {
        return null;
    }
}
export function formatNumber(number) {
    if (typeof number === "undefined") {
        number = 0;
    }
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
}

export function setCookie(key, value, days = 1) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = key + "=" + (value || "") + expires + "; path=/";
}

/**
 * Convert color:
 * @param {*} r red
 * @param {*} g green
 * @param {*} b blue
 * @param {*} a alpha
 * @returns (string) A hexadecimal color.
 */
export function convertRGBAToHexA(r, g, b, a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
}

/**
 *
 * @param {string} h hex
 * @returns (object) A rgba color in detail.
 */
export function convertHexAToRGBA(h) {
    let r = 0,
        g = 0,
        b = 0,
        a = 1;

    if (h.length == 5) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];
        a = "0x" + h[4] + h[4];
    } else if (h.length == 9) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
        a = "0x" + h[7] + h[8];
    }
    a = +(a / 255).toFixed(3);

    return {
        str: "rgba(" + +r + "," + +g + "," + +b + "," + a + ")",
        rgb: {
            r: +r,
            g: +g,
            b: +b,
            a: +a,
        },
    };
}

/**
 *
 * @param {number} value A number
 * @returns (string) A color.
 */
export function getColorForPercentChart(value) {
    if (value <= 100 && value >= 80) {
        return "#7FB6A1";
    } else if (value < 80 && value >= 60) {
        return "#7ACBBE";
    } else if (value < 60 && value >= 40) {
        return "#F9D26D";
    } else if (value < 40 && value >= 20) {
        return "#FBB36B";
    } else if (value < 20 && value >= 0) {
        return "#FB836B";
    } else {
        return "#757575";
    }
}

/**
 * @param {string[]} arr: list need check duplicate
 * @return {boolean}: is duplicate
 */
export const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length;
};

export const convertQuestionState = (questions, listAnswersInRecord) => {
    const result = [];

    questions?.forEach((question) => {
        if (question?.id && listAnswersInRecord) {
            if (listAnswersInRecord[question.id]) {
                // Rest question types
                if (Array.isArray(listAnswersInRecord[question.id].answered)) {
                    listAnswersInRecord[question.id].answered.length > 0 && result.push(question.order);

                    return;
                }

                // Drag drop group
                if (listAnswersInRecord[question.id]?.answered?.match) {
                    Object.values(listAnswersInRecord[question.id]?.answered?.match || {}).forEach((value) => {
                        if (value?.length > 0) {
                            result.push(question.order);
                            return;
                        }
                    });
                }

                // Passage
                if (typeof listAnswersInRecord[question.id].answered === "object") {
                    Object.keys(listAnswersInRecord[question.id]?.answered || {}).forEach((childId) => {
                        if (listAnswersInRecord[question.id]?.answered?.[childId]?.length) {
                            result.push(question.order);
                        }
                    });
                }
            }
        }
    });

    return result;
};

export const checkIsObject = (object) => {
    if (typeof object === "object" && !Array.isArray(object) && object !== null) return true;
    return false;
};

/**
 *
 * @param {response of getPersonalProgress api} data Element for snapshot and printf
 * @param {string} fileName name's file will save when export pdf
 * @returns (void)
 *
 *  */
export const exportPersonalLearningToPdfText = async (data, fileName, logo, organization) => {
    const renderUserBoard = (username) => {
        return [
            {
                text: [
                    {
                        text: `${t("userBoard.full_name")}: `,
                        bold: true,
                    },
                    username,
                ],
            },
            {
                text: [
                    {
                        text: `${t("userBoard." + organization.type)}` + ": ",
                        bold: true,
                    },
                    organization[`name_of_${organization.type}`].join(", "),
                ],
            },
        ];
    };

    const renderBodyChartAssignmentLevel = (data = []) => {
        const columns = data?.map((col) => [
            col.name,
            { text: col.percent, color: getColorForPercentChart(col.percent) },
        ]);

        return columns;
    };

    const renderBodyChartAllAssignmentDone = (data = {}) => {
        const propsLabel = {
            very_good: t("report.very_good"),
            good: t("report.good"),
            medium: t("report.ordinary"),
            failed: t("report.failed"),
        };

        const propsColor = {
            very_good: "#2DAC81",
            good: "#2FB8E1",
            medium: "#FBD729",
            failed: "#EF8628",
        };

        const columns = Object.entries(data || {})?.map(([classification, value]) => {
            classification = classification.split("percent_assignment_")[1];
            return [
                {
                    text: propsLabel[classification],
                    color: propsColor[classification],
                },
                value + "%",
            ];
        });

        return columns;
    };

    const renderBodyReportTable = (data) => {
        const result = data?.map((record) => {
            const kq = [
                record.assignment?.course?.name,
                record.assignment?.level?.name,
                record.assignment?.name,
                {
                    text: record.score_percent,
                    alignment: "center",
                    fillColor: getColorForPercentChart(record.score_percent),
                    color: "#fff",
                },
            ];

            return kq;
        });

        return result;
    };

    const myCustomLayout = {
        fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? "#f8f8f8" : null;
        },
        hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
        },
        vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
        },
        hLineColor: function (i, node) {
            return "red";
        },
        vLineColor: function (i, node) {
            return "red";
        },
        hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
                return null;
            }
            return 0;
        },
        vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
                return null;
            }
            return 0;
        },
        paddingTop: () => {
            return 14;
        },
        paddingBottom: () => {
            return 14;
        },
        // paddingLeft: function (i, node) {
        //     return 8;
        // },
        // paddingRight: function (i, node) {
        //     return 8;
        // },
    };

    const docDefinition = {
        content: [
            {
                image: logo,
                fit: [200, 60],
                alignment: "right",
            },
            renderUserBoard(data.name),
            {
                text: t("report.level_completion_score"),
                bold: true,
                margin: [0, 40, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["25%", "25%"],
                    body: [
                        [t("report.level"), t("report.complete_progress")],
                        ...renderBodyChartAssignmentLevel(data?.chart_for_assignment_level),
                    ],
                },
                layout: myCustomLayout,
            },
            {
                text: t("report.rate_tests_taken"),
                bold: true,
                margin: [0, 40, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["25%", "25%"],
                    body: [
                        [t("report.level"), t("report.ratio")],
                        ...renderBodyChartAllAssignmentDone(data?.chart_for_assignment),
                    ],
                },
                layout: myCustomLayout,
            },
            {
                text: t("report.test_classification"),
                bold: true,
                margin: [0, 40, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*", "25%", "*", "20%"],

                    body: [
                        [
                            t(`report.name_of_${organization.type}`),
                            t("report.level"),
                            organization.type === "class" ? t("report.test") : t("report.exercise"),
                            t("report.average_score"),
                        ],
                        ...renderBodyReportTable(data?.records_without_history),
                    ],
                },
                layout: myCustomLayout,
            },
        ],
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // for product
    pdfDocGenerator.download(fileName);

    // for debug
    // pdfDocGenerator.getBlob((blob) => {
    //     const url = URL.createObjectURL(blob);
    //     window.open(url);
    // });
};

const objectToSearchParamsString = (params = {}) => {
    let str = "?";
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            str += `${key}=${params[key]}` + "&";
        }
    }
    if (str?.length) {
        str = str?.slice(0, -1);
    }
    return str;
};

const getAllSearchParams = (searchParams) => {
    const params = {};
    if (searchParams?.entries()) {
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
    }

    return params;
};

const uniqueArray = (array) => {
    if (Array.isArray(array)) {
        return [...new Set(array)];
    }
    return [];
};

const removeItemInArray = (arr, itemToRemove) => {
    if (!Array.isArray(arr)) return [];
    return arr.filter((item) => item !== itemToRemove);
};

function renderLongTextInTableCell(text, action = () => {}) {
    if (typeof text === "string") {
        if (text.length > 100) {
            return (
                <span>
                    {text.substring(0, 300)}...{" "}
                    <span
                        onClick={action}
                        style={{
                            color: "#888",
                            cursor: "pointer",
                        }}
                    >
                        {t("classes.view_detail")} <DoubleRightOutlined />
                    </span>
                </span>
            );
        }
        return text;
    } else {
        return text;
    }
}

function deepEqualObj(obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqualObj(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}
const validateEmail = (_, value) => {
    // Email validation regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (value && !emailRegex.test(value)) {
        return Promise.reject(t("message.invalid_email"));
    }

    return Promise.resolve();
};

/**
 *
 * @param {blob} blob :  Object Blob
 * @returns
 */
const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

function isPromise(p) {
    if (Promise && Promise.resolve) {
        return Promise.resolve(p) == p;
    }
}

async function checkMultipleFaces(imageElement, faceApi) {
    try {
        const detections = await faceApi
            .detectAllFaces(imageElement, new faceApi.SsdMobilenetv1Options({ minConfidence: 0.4 }))
            .withFaceLandmarks()
            .withFaceDescriptors();

        return detections.length;
    } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        return false;
    }
}

async function checkSingleFaceIsValid(imageElement, faceApi) {
    let faceDescriptor;
    try {
        faceDescriptor = await faceApi
            .detectSingleFace(imageElement, getFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptor();
    } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        return { status: false, faceDescriptor: null };
    }

    return { status: true, faceDescriptor };
}

function checkIsModalsFaceApiLoaded(faceApi) {
    return (
        faceApi.nets.ssdMobilenetv1.isLoaded &&
        faceApi.nets.tinyFaceDetector.isLoaded &&
        faceApi.nets.faceRecognitionNet.isLoaded &&
        faceApi.nets.faceLandmark68Net.isLoaded
    );
}

function dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
}

function createImgElement({ width, height, src }) {
    var virtualImg = document.createElement("img");

    if (!src) return false;

    virtualImg.src = src;
    virtualImg.alt = "Virtual Image";
    // virtualImg.width = width || 300;
    // virtualImg.height = height || 400;
    virtualImg.crossorigin = true;
    virtualImg.display = "block";

    return virtualImg;
}

function cls(...classNames) {
    let str = "";
    classNames.forEach((className) => {
        if (typeof className === "string" || typeof className === "number") {
            str += " " + className;
        }
        if (Array.isArray(className)) {
            str += " " + cls(...className);
        }
        if (typeof className === "object") {
            for (let key in className) {
                className[key] && (str += " " + key);
            }
        }
    });
    return str.trim();
}

function blobToFile(blob, fileName, mimeType) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const arrayBuffer = atob(event.target.result.split(",")[1]);
            const byteArray = new Uint8Array(arrayBuffer.length);

            for (let i = 0; i < arrayBuffer.length; i++) {
                byteArray[i] = arrayBuffer.charCodeAt(i);
            }

            const fileBlob = new Blob([byteArray], { type: mimeType });
            const file = new File([fileBlob], fileName, { type: mimeType });

            resolve(file);
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsDataURL(blob);
    });
}

export {
    objectToSearchParamsString,
    getAllSearchParams,
    renderLongTextInTableCell,
    uniqueArray,
    removeItemInArray,
    deepEqualObj,
    validateEmail,
    convertBlobToBase64,
    isPromise,
    checkMultipleFaces,
    checkIsModalsFaceApiLoaded,
    dataURIToBlob,
    createImgElement,
    checkSingleFaceIsValid,
    cls,
    blobToFile,
};
