import React from "react";

function IconEdit(props) {
    const { fill = "#000" } = props;

    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6212 1.9827C21.2936 1.21833 20.6384 0.563152 19.874 0.235565C18.2361 -0.41961 16.3798 0.34476 15.6154 1.8735L10.5924 13.1207C10.1556 14.1034 10.0464 15.3046 10.1556 16.2873L10.5924 18.6896C10.7016 19.0172 10.92 19.3448 11.2476 19.454C11.3567 19.5632 11.5751 19.5632 11.6843 19.5632C11.9027 19.5632 12.1211 19.454 12.2303 19.3448L14.305 18.0345C15.2878 17.3793 15.943 16.6149 16.3798 15.523L21.4028 4.27581C21.9487 3.62063 21.9487 2.74707 21.6212 1.9827ZM14.6326 14.8678C14.305 15.523 13.8683 15.9598 13.3223 16.3965L12.5579 16.8333L12.4487 16.069C12.3395 15.4138 12.4487 14.7586 12.6671 14.1034L16.489 5.36777L18.4545 6.13214L14.6326 14.8678ZM19.6556 3.51144L19.4372 4.05742L17.4717 3.29305L17.6901 2.74707C17.9085 2.31028 18.2361 2.09189 18.6729 2.09189C18.7821 2.09189 19.0005 2.09189 19.1096 2.20109C19.328 2.31028 19.5464 2.52868 19.6556 2.74707C19.7648 3.07465 19.7648 3.29305 19.6556 3.51144Z" />
            <path d="M1.09196 15.6322H5.45979C6.11496 15.6322 6.55175 15.1954 6.55175 14.5402C6.55175 13.885 6.11496 13.4482 5.45979 13.4482H1.09196C0.436783 13.4482 0 13.885 0 14.5402C0 15.1954 0.436783 15.6322 1.09196 15.6322Z" />
            <path d="M7.6437 17.8164H1.09196C0.436783 17.8164 0 18.2532 0 18.9084C0 19.5635 0.436783 20.0003 1.09196 20.0003H7.6437C8.29888 20.0003 8.73566 19.5635 8.73566 18.9084C8.73566 18.2532 8.29888 17.8164 7.6437 17.8164Z" />
        </svg>
    );
}

export default IconEdit;
