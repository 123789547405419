import { useEffect, useRef, useState, React } from "react";
// import "./HoEditor.scss";

//lazy load
import loadable from "@loadable/component";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
const ZwibblerModule = loadable.lib(() => import("src/assets/script/zwibbler"));

function safeZwibblerLoad(zwibbler, data) {
    // hàm load json data của zwibbler
    try {
        zwibbler.load(data);
    } catch (e) {
        try {
            // eslint-disable-next-line no-control-regex
            zwibbler.load(data.replace(/[^\x00-\x80]/g, ""));
        } catch (e2) {
            /* eslint-disable */
            console.log({ e2 });
        }
    }
}

// nhận vào paths=> show. cho select shape => lấy ra shape đã select// view shape đã click
// dissable editor
const HotspotAnswer = (props) => {
    const {
        width,
        height,
        src,
        paths,
        onChange,
        isLoadZwibbler = false,
        setCorrectAnswer,
        defaultCorrectAnswer,
        mode,
        is_multiple = true,
        defaultAnswer, //answered
    } = props;

    const { t } = useTranslation();
    const [zwibbler, setZwibbler] = useState();
    const zwibblerEl = useRef(null);
    const nodeFlag = useRef(null); // biến flag để check node cuối cùng được add vào
    const isMultipleAnswer = useRef(is_multiple);
    const generateAnswerForBackendRef = useRef(null);
    const isFirstLoad = useRef(true);

    const [localCorrectAnswer, setLocalCorrectAnswer] = useState(defaultCorrectAnswer || defaultAnswer);

    const zwibblerConfig = {
        // Needed to indicate to Zwibbler that this is the main element.
        zwibbler: "",
        "z-controller": "mycontroller",
        background: "transparent",
        // confine: "view",
    };

    const generateAnswerForBackend = () => {
        let result = [];
        if (isFirstLoad.current) {
            return defaultAnswer;
        } else {
            if (localCorrectAnswer && localCorrectAnswer?.length > 0) {
                result = localCorrectAnswer.map((item) => `key-${item}`);
            }
        }

        return result;
    };

    const formatAnswer = (answer) => {
        if (Array.isArray(answer)) {
            const result = answer.map((item) => item && item.substring(4)).filter((item) => item);
            return result;
        }
        return [];
    };

    const saveData = () => {
        if (onChange instanceof Function) {
            onChange({
                answered: generateAnswerForBackendRef.current(),
            });
        }
    };

    const handleSelectAnswer = (node) => {
        //hàm này gọi trong addEventlistener nên bị closure
        // dùng callback trong setSate => xử lý closure của correctAnswer
        setLocalCorrectAnswer((items) => {
            isFirstLoad.current = false;
            if (items && items.includes(node)) {
                // console.log("delete", node, correctAnswer);
                //xóa node
                if (zwibbler) {
                    // zwibbler.setConfig("readOnly", false);
                    const res = items.filter((item) => item !== node);
                    return res;
                }
            } else {
                if (zwibbler) {
                    if (isMultipleAnswer.current && items) {
                        return [...items, node];
                    }

                    return [node];
                }
            }
        });
    };

    const handleAllowMultipleAnswers = (value) => {
        isMultipleAnswer.current = value.target.checked;
        if (!isMultipleAnswer.current && localCorrectAnswer.length > 1) {
            setLocalCorrectAnswer(localCorrectAnswer[0]);
            if (setCorrectAnswer instanceof Function) {
                setCorrectAnswer([localCorrectAnswer[0]]);
            }
        }
    };

    const modifyNodes = (correctAnswer = []) => {
        if (zwibbler) {
            if (mode === "result") {
                zwibbler.setConfig("readOnly", false);
            }
            const allNodes = zwibbler.getAllNodes();
            //unlock để set lineWidth
            allNodes.map((item) => {
                if (item) {
                    zwibbler.setNodeProperties(item, {
                        lockPosition: false,
                        lockSize: false,
                        lockAspectRatio: false,
                        lockEditMode: false,
                        lockRotation: true,
                    });

                    if (correctAnswer && correctAnswer.includes(item)) {
                        zwibbler.setNodeProperties(item, {
                            lineWidth: 5,
                            dashes: "unset",
                        });
                    } else {
                        zwibbler.setNodeProperties(item, {
                            dashes: "5,2",
                            lineWidth: 3,
                            doubleArrow: true,
                        });
                    }

                    zwibbler.setNodeProperties(item, {
                        lockPosition: true,
                        lockSize: true,
                        lockAspectRatio: true,
                        lockEditMode: true,
                    });
                }
            });
            zwibbler.setConfig("allowSelectBox", false);
            //show kq
            if (mode === "result") {
                zwibbler.setConfig("readOnly", true);
            }
        }
    };

    useEffect(() => {
        function initZwibbler() {
            //chỉ khởi tạo 1 lần duy nhất
            if (window.Zwibbler && zwibblerEl.current && !zwibbler) {
                // initialize Zwibbler
                const newZwibbler = window.Zwibbler.create(zwibblerEl.current, {
                    // defaultZoom: "page",
                    // pageView: "true",
                    // confine: "view",
                    autoPickTool: false,
                    showToolbar: false,
                    showColourPanel: false,
                    showHints: false,
                    scrollbars: false,
                    readOnly: false,
                    setFocus: false, // Zwibbler will be unable to intercept any keyboard commands
                    allowZoom: false,
                    allowTextInShape: false, // Zwibbler to write text inside a closed shapes
                    // leaveTextToolOnBlur: true, // Zwibbler text tool data will be saved if user moves out of scratchpad
                    allowSelectBox: "pan",

                    //color
                    defaultFillStyle: "rgba(241, 241, 21, 0.3)",
                    defaultStrokeStyle: "#EC9B3B",
                });

                newZwibbler.on("document-changed", () => {
                    // if (newZwibbler.dirty()) {

                    saveData(newZwibbler);

                    // }
                });

                newZwibbler.on("paste", () => {
                    // preventing pasting of images currently
                    return false;
                });

                newZwibbler.usePickTool();

                function isClosedNode(nodes, ctx) {
                    if (nodes?.[0]) {
                        return ctx.getNodeProperty(nodes[0], "closed"); // true if node closed
                    }
                    return false;
                }

                function handleAddNodes(nodes, ctx) {
                    //khi click vẽ thì zb sẽ tạo ra 1 node tạm, khi vẽ xong thì sẽ xóa node tạm đi và thay bằng node đã vẽ
                    try {
                        if (nodes && ctx) {
                            if (nodeFlag.current === 1) {
                                //hàm check is node closed, phải là 1 node khép kín mới được
                                if (isClosedNode(nodes, ctx)) {
                                } else {
                                    notification.error({ message: t("hotspot.node_must_be_closed") });
                                    if (nodes?.[0]) {
                                        ctx.deleteNode(nodes[0]); // còn bug khi click liên tục
                                        // ctx.deleteNode(nodes[0]);
                                    }
                                }
                                nodeFlag.current = null;
                            } else if (nodeFlag.current === null) {
                                nodeFlag.current = 1;
                            }
                        }
                    } catch (error) {
                        notification.error({ message: error.message });
                    }
                }

                newZwibbler.on("nodes-added", function (nodes, _unused, remote) {
                    handleAddNodes(nodes, newZwibbler);
                });

                //setting
                newZwibbler.setConfig("confine", "view");
                // newZwibbler.setConfig("allowSelectBox", "pan");

                setZwibbler(newZwibbler);
            }
        }

        //chạy interval để check zwibbler đã load xong chưa => mếu xong thì chạy hàm khởi tạo....
        let interval = setInterval(() => {
            if ("Zwibbler" in window) {
                initZwibbler();
                clearInterval(interval);
                // console.count("interval");
            }
        }, 100);

        // isFirstLoad.current = false;

        if (zwibbler)
            return () => {
                setZwibbler(null);
            };
    }, []);

    useEffect(() => {
        if (zwibbler) {
            //chỗ này chỉ chạy 1 lần duy nhất từ khi component mount.
            zwibbler.on("node-clicked", function (node, x, y) {
                if (mode !== "result") handleSelectAnswer(node);
            });
        }
    }, [zwibbler]);

    const removeNodesDeleted = (newNodes) => {
        setLocalCorrectAnswer((oldNodes) => {
            if (newNodes && oldNodes?.length > 0) {
                let newSelectedNodes = oldNodes.filter((node) => newNodes.includes(node));
                return newSelectedNodes;
            }
            return oldNodes;
        });
    };

    useEffect(() => {
        if (defaultCorrectAnswer?.length > 0 || defaultAnswer?.length > 0) {
            const newDfAns = formatAnswer(defaultAnswer);
            setLocalCorrectAnswer(() => {
                return defaultCorrectAnswer || newDfAns;
            });
        }
    }, [defaultCorrectAnswer]);

    useEffect(() => {
        if (zwibbler && paths) {
            safeZwibblerLoad(zwibbler, paths);
            //nếu nodes đã bị remove thì xóa node đó trong selectedNode
            const newNodes = zwibbler.getAllNodes();
            if (newNodes && !isFirstLoad) {
                removeNodesDeleted(newNodes);
            }
        } else if (zwibbler && !paths) {
            // nếu ko có path=> do clear
            zwibbler.newDocument();
            if (localCorrectAnswer?.length > 0) {
                setLocalCorrectAnswer([]);
            }
        }
    }, [zwibbler, paths]);

    useEffect(() => {
        modifyNodes(localCorrectAnswer);
        if (setCorrectAnswer instanceof Function) {
            setCorrectAnswer(localCorrectAnswer);
        }

        //xử lý closure
        generateAnswerForBackendRef.current = generateAnswerForBackend;
    }, [localCorrectAnswer, zwibbler]);

    return (
        <>
            <div className="zwibbler-draw-container ">
                <div
                    className="zwibbler-container"
                    id="scrollable"
                    style={{ width: width || 500, height: height || 500 }}
                >
                    <div className="custom-zwibbler" style={{ height: height || 500 }}>
                        <div className="zwibbler-image-background">{src && <img src={src} />}</div>

                        <div ref={zwibblerEl} {...zwibblerConfig} id="zwibbler-canvas" className="zwibbler">
                            <div z-canvas="" className="stretch" confine="view" />
                        </div>
                    </div>
                </div>
                {/* //layzyload module */}
                {isLoadZwibbler && <ZwibblerModule></ZwibblerModule>}
            </div>
        </>
    );
};

export default HotspotAnswer;
