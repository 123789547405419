import { diffChars, diffWords } from "diff";

const listSpecialLangs = ["CN", "TH", "KR", "JP"];

const listSpecialCharacters = [
    "~",
    "!",
    "&",
    "(",
    ")",
    ":",
    "[",
    "]",
    "{",
    "}",
    "|",
    ";",
    ",",
    ".",
    "?",
    "¿",
    "¡",
    "'",
    "`",
    "’",
    "。",
    "，",
    "、",
    "「",
    "『",
    "』",
    "」",
    "？",
    "》",
    "《",
    "……",
    "·",
    "：",
    "”",
    "“",
];

function useTextCompare({ lang }) {
    const formatAnswer = (answer = "", newAnswer = []) => {
        //chuyển từ chữ thường sang chữ hoa như định dang của câu hỏi lúc gv ra đề.
        /* --định dang của arr mà diff trả về:
            + removed: true là các từ ko nằm trong string answer
            + added: true là các từ nằm trong nhưng mà sai
            + !removed && !added là từ đúng.
        */

        let flag = 0;
        if (Array.isArray(newAnswer) && typeof answer === "string") {
            let result = newAnswer.map((item) => {
                if (!item?.added || !(item?.added || item?.removed)) {
                    const newItem = structuredClone(item);
                    const Itemlength = item?.value?.length;
                    newItem.value = answer.slice(flag, flag + Itemlength);
                    flag += item.value?.length;
                    return newItem;
                }
                return item;
            });
            return result;
        }

        return newAnswer;
    };

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return string;
    }

    const ignorePunctuation = (str, key) => {
        let fragment = [];
        let tempArr = [];
        for (let i in str) {
            // console.log("aaa:::", str[i], listSpecialCharacters.includes());

            if (!listSpecialCharacters.includes(str[i])) {
                tempArr.push(str[i]);
            } else {
                const node = <span key={`${i}_${key}`}>{tempArr.join("")}</span>;
                fragment.push(node);
                tempArr = [];
                const node2 = (
                    <span style={{ color: "#222222b5" }} key={`${i}__${key}`}>
                        {str[i]}
                    </span>
                );
                fragment.push(node2);
            }
        }
        if (tempArr.length > 0) {
            const node = <span key={`${str}_${key}`}>{tempArr.join("")}</span>;
            fragment.push(node);
            tempArr = [];
        }

        return <>{fragment.map((item) => item)}</>;
    };

    function changed(a, b, answer, type) {
        // let diff = is_sentence ? diffWords(a, b) : diffChars(a, b);
        let diff = listSpecialLangs.includes(lang) ? diffChars(a, b) : diffWords(a, b);
        // if (type !== "userAnswer") {
        //     diff = formatAnswer(answer, diff);
        // }
        let fragment = [];
        for (let i = 0; i < diff.length; i++) {
            if (diff[i].added && diff[i + 1] && diff[i + 1].removed) {
                let swap = diff[i];
                diff[i] = diff[i + 1];
                diff[i + 1] = swap;
            }

            let node;
            if (diff[i].removed && !listSpecialCharacters.includes(diff[i].value)) {
                node = (
                    <span key={i} style={{ color: "red" }}>
                        {/* {diff[i].value} */}
                        {ignorePunctuation(diff[i].value, i)}
                    </span>
                );
            } else if (diff[i].added) {
                // node = (
                //     <span key={i} style={{ color: "#406619", background: "#eaf2c2" }}>
                //         {diff[i].value}
                //     </span>
                // );
            } else {
                node = (
                    <span key={i} style={{ color: "#52c41a" }}>
                        {/* {diff[i].value} */}
                        {ignorePunctuation(diff[i].value, i)}
                    </span>
                );
            }
            fragment.push(node);
        }

        return (
            <span className="diff-text" style={{ fontWeight: 500, fontSize: 18, textAlign: "left", display: "block" }}>
                <span style={{ fontWeight: 500 }}>{fragment.map((item) => item)}</span>
            </span>
        );
    }

    //mode: doing, preview
    const compareText = (sentence1, sentence2, mode = "doing", type) => {
        if (!sentence1 || !sentence2) {
            if (type === "speech") {
                return <p>{sentence1}</p>;
            }
            return <p>{sentence1 || ""}</p>;
        }

        if (mode === "doing") {
            return changed(
                capitalizeFirstLetter(sentence1?.toLowerCase()),
                capitalizeFirstLetter(sentence2?.toLowerCase())
            );
        } else {
            return changed(
                capitalizeFirstLetter(sentence1?.toLowerCase()),
                capitalizeFirstLetter(sentence2?.toLowerCase()),
                sentence1
            );
        }
    };

    return { compareText };
}

export default useTextCompare;
