import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Folder } from "src/assets/images/nav/folder.svg";
import { ReactComponent as QuestionCloud } from "src/assets/images/nav/question-cloud.svg";
import { useRouting } from "src/utils/router";
import "./Dropdown.scss";

function Dropdown({ navItem, subnavName }) {
    const { t } = useTranslation();
    const { generate } = useRouting();

    const NavIcon = navItem.icon;
    let items = [];
    const subMenu = navItem.subnav;
    items = subMenu.map((item) => {
        let a = item.name === subnavName ? "active" : "";
        const SubIcon = item.icon;
        return {
            key: `${item.name}`,
            title: t(`nav.${item.name}`),
            label: (
                <Link className={`subnav-link ${a}`} to={generate(item.routeName, item.routeParams || {})}>
                    <span>{SubIcon && <SubIcon />}</span>
                    {t(`nav.${item.name}`)}
                </Link>
            ),
        };
    });
    return (
        <div className="custom-nav-dropdown">
            <div className="dropdown-content">
                {subMenu.map((item) => {
                    let a = item.name === subnavName ? "active" : "";
                    const SubIcon = item.icon;
                    return (
                        <div key={item.name} className="dropdown-item">
                            <Link
                                className={`dropdown-link ${a}`}
                                to={generate(item.routeName, item.routeParams || {})}
                            >
                                <span className="dropdown-icon">{SubIcon && <SubIcon />}</span>
                                <span className="dropdown-text">{t(`nav.${item.name}`)}</span>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Dropdown;
