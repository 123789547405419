import { Col, Form, Row, Select, Spin } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { findRecordBy } from "src/api/containers/record";
import { getAnswerDataListForAssignment } from "src/api/containers/statistic";
import { useValues } from "src/hooks";
import QuestionNumberList from "src/modules/components/NumberList/QuestionNumberList";

import AnswerDetail from "../../components/AnswerDetail";
import ExamChartController from "../../components/ExamChartController";
import "./Student.scss";

const Student = ({
    assignmentData = {},
    selectedCourseClassId = "",
    isFetchingStudents = false,
    optionListStudent = [],
    studentId = "",
}) => {
    const params = useParams();
    const [form] = Form.useForm();
    const [selectedStudentId, setSelectedStudentId] = useState("");
    const [selectedStudentData, setSelectedStudentData] = useState({});
    const [selectedFilterByType, setSelectedFilterByType] = useState("all");
    const [values, setValues] = useValues({
        loading: false,
        recordData: {},
        answerDataForStatistic: [],
    });

    const [answerStats, timeStats, timeMaxValue] = useMemo(() => {
        const generateChartAnswerStatistics = () => {
            const answerStats = [];
            const timeStats = [];
            let timeMaxValue = 0;
            values.answerDataForStatistic.forEach((d) => {
                const numString = (d.order || 0).toString();
                answerStats.push(
                    { xLabel: numString, itemType: "correct", yColValue: d.correct },
                    { xLabel: numString, itemType: "correct_nearly", yColValue: d.part_correct },
                    { xLabel: numString, itemType: "incorrect", yColValue: d.wrong },
                    { xLabel: numString, itemType: "mark_by_hand", yColValue: d.hand_dots },
                    { xLabel: numString, itemType: "skip", yColValue: d.skip }
                );
                timeStats.push({ xLabel: numString, yLineValue: Math.round(d.sum_time || 0) });
                if (timeMaxValue < (d.sum_time || 0)) {
                    timeMaxValue = d.sum_time;
                }
            });
            timeMaxValue = Math.round(timeMaxValue * 2);
            return [answerStats, timeStats, timeMaxValue];
        };
        return generateChartAnswerStatistics();
    }, [values.answerDataForStatistic]);

    const [filteredRecordList] = useMemo(() => {
        // Properties:
        const qTypes = {
            correct: "correct",
            correct_nearly: "part_correct",
            incorrect: "wrong",
            mark_by_hand: "hand_dots",
            skip: "skip",
        };
        // Handle filtering:
        let filteredRecordList = [];
        if (selectedFilterByType === "all") {
            // filteredRecordList = values.recordData.history || [];
        } else {
            for (let i = 0; i < values.recordData.history?.length; i++) {
                if (values.recordData.history[i].type === "passage") {
                    if (
                        selectedFilterByType === "correct" ||
                        selectedFilterByType === "correct_nearly" ||
                        selectedFilterByType === "incorrect"
                    ) {
                        /**
                         * If the current passage question is "skip", do nothing:
                         */
                        let isBreak = true;
                        for (let j = i; j < values.answerDataForStatistic.length; j++) {
                            if (
                                values.recordData.history[i].order ===
                                Math.floor(values.answerDataForStatistic[j].order)
                            ) {
                                if (values.answerDataForStatistic[j][qTypes["skip"]] === 0) {
                                    isBreak = false;
                                    break;
                                }
                            }
                        }
                        if (isBreak === true) {
                            break;
                        }
                        /**
                         * Check the current passage question:
                         * - If its grade = 0, it is incorrect.
                         * - If its grade > 0 and grade < totalScore, it is correct_nearly.
                         * - If its grade = totalScore, it is correct.
                         */
                        let countScore = 0;
                        let totalScore = values.recordData.history[i].score;
                        values.recordData.history[i].paragraphs?.forEach((pItem, pIndex) => {
                            pItem.question_details?.forEach((qItem, qIndex) => {
                                countScore += qItem.score_submit || qItem.score_teacher_submit || 0;
                            });
                        });
                        if (selectedFilterByType === "correct" && countScore === totalScore) {
                            filteredRecordList.push(values.recordData.history[i]);
                        } else if (
                            selectedFilterByType === "correct_nearly" &&
                            countScore > 0 &&
                            countScore < totalScore
                        ) {
                            filteredRecordList.push(values.recordData.history[i]);
                        } else if (selectedFilterByType === "incorrect" && countScore === 0) {
                            filteredRecordList.push(values.recordData.history[i]);
                        }
                    } else {
                        for (let j = i; j < values.answerDataForStatistic.length; j++) {
                            if (
                                values.recordData.history[i].order ===
                                Math.floor(values.answerDataForStatistic[j].order)
                            ) {
                                if (values.answerDataForStatistic[j][qTypes[selectedFilterByType]] > 0) {
                                    filteredRecordList.push(values.recordData.history[i]);
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    for (let j = i; j < values.answerDataForStatistic.length; j++) {
                        if (values.recordData.history[i].order === Math.floor(values.answerDataForStatistic[j].order)) {
                            if (values.answerDataForStatistic[j][qTypes[selectedFilterByType]] > 0) {
                                filteredRecordList.push(values.recordData.history[i]);
                                break;
                            }
                        }
                    }
                    /**
                     * If current question is mark-by-hand, check:
                     * - If its grade = 0, it is incorrect.
                     * - If its grade > 0 and grade < totalScore, it is correct_nearly.
                     * - If its grade = totalScore, it is correct.
                     */
                    // for (let j = i; j < values.answerDataForStatistic.length; j++) {
                    //     if (values.recordData.history[i].order === Math.floor(values.answerDataForStatistic[j].order)) {
                    //         if (values.answerDataForStatistic[j][qTypes["mark_by_hand"]] > 0) {
                    //             const temp = values.recordData.history[i];
                    //             if (selectedFilterByType === "incorrect" && temp.score_teacher_submit === 0) {
                    //                 filteredRecordList.push(values.recordData.history[i]);
                    //                 break;
                    //             } else if (
                    //                 selectedFilterByType === "correct_nearly" &&
                    //                 temp.score_teacher_submit > 0 &&
                    //                 temp.score_teacher_submit < temp.score
                    //             ) {
                    //                 filteredRecordList.push(values.recordData.history[i]);
                    //                 break;
                    //             } else if (
                    //                 selectedFilterByType === "correct" &&
                    //                 temp.score_teacher_submit === temp.score
                    //             ) {
                    //                 filteredRecordList.push(values.recordData.history[i]);
                    //                 break;
                    //             }
                    //         }
                    //         if (values.answerDataForStatistic[j][qTypes[selectedFilterByType]] > 0) {
                    //             filteredRecordList.push(values.recordData.history[i]);
                    //             break;
                    //         }
                    //     }
                    // }
                }
            }
        }
        return [filteredRecordList];
    }, [selectedFilterByType, values.recordData, values.answerDataForStatistic]);

    const handleSelectStudent = (studentId) => {
        if (studentId) {
            const studentData = optionListStudent.filter((p) => p.user?._id === studentId);
            setSelectedStudentId(studentId);
            if (studentData.length > 0) {
                setSelectedStudentData(studentData[0]);
            }
        } else {
            setSelectedStudentId("");
            setSelectedStudentData({});
            setValues({
                ...values,
                recordData: {},
            });
        }
    };

    const getStatusContent = () => {
        if (values.recordData.is_teacher_submit === true) {
            return t("report.is_teacher_submitted");
        } else if (values.recordData.is_student_submit === true) {
            return t("report.is_student_submitted");
        } else {
            return t("report.is_student_not_started");
        }
    };

    const handleSelectFilterStudentsByType = (typeValue) => {
        setSelectedFilterByType(typeValue);
    };

    useEffect(() => {
        if (studentId) {
            const studentData = optionListStudent.filter((p) => p.user?._id === studentId);
            setSelectedStudentId(studentId);
            form.setFieldsValue({ student_id: studentId });
            if (studentData.length > 0) {
                setSelectedStudentData(studentData[0]);
            }
        } else {
            // setSelectedStudentId(studentId);
            // form.setFieldsValue({ student_id: undefined });
            // setSelectedStudentData({});
        }
    }, [studentId]);

    useEffect(() => {
        if (selectedStudentId) {
            if (params.assignmentId) {
                // Get data for chart:
                let selectedStudentRecordId = "";
                for (let i = 0; i < optionListStudent.length; i++) {
                    if (selectedStudentId === optionListStudent[i].user?._id) {
                        selectedStudentRecordId = optionListStudent[i].user?.user_records[0]?._id;
                        break;
                    }
                }
                // Make API calls:
                setValues({ ...values, loading: true });
                Promise.all([
                    findRecordBy(params.assignmentId, selectedStudentId, { is_show_user_info: 1 }),
                    selectedStudentRecordId
                        ? getAnswerDataListForAssignment(params.assignmentId, { recordIds: [selectedStudentRecordId] })
                        : undefined,
                ]).then((res) => {
                    if (res[0].status === true) {
                        setValues({
                            ...values,
                            loading: false,
                            recordData: res[0].data.length > 0 ? res[0].data[0] : {},
                            ...(selectedStudentRecordId
                                ? { answerDataForStatistic: res[1].data || [] }
                                : { answerDataForStatistic: [] }),
                        });
                    } else {
                        setValues({
                            ...values,
                            loading: false,
                            recordData: {},
                            answerDataForStatistic: [],
                        });
                    }
                });
            }
        } else {
            setValues({
                ...values,
                loading: false,
                recordData: {},
                answerDataForStatistic: [],
            });
        }
    }, [selectedStudentId]);

    useEffect(() => {
        setSelectedStudentId("");
        form.setFieldsValue({ student_id: undefined });
        setSelectedStudentData({});
    }, [selectedCourseClassId]);

    const renderRecordSummaryInfo = () => {
        let actualScore = 0;
        let maxScore = 0;
        let duration = "_:_";
        let status = getStatusContent();
        let submittedTime = values.recordData.end_time
            ? moment(values.recordData.end_time).format("DD/MM/YYYY HH:mm:ss")
            : "_";
        values.recordData.history?.forEach((h) => {
            if (h.type === "passage") {
                h.paragraphs?.forEach((p) => {
                    p.question_details?.forEach((q) => {
                        actualScore += q.score_submit || q.score_teacher_submit || 0;
                        maxScore += q.score || 0;
                    });
                });
            } else {
                actualScore += h.score_submit || h.score_teacher_submit || 0;
                maxScore += h.score || 0;
            }
        });
        if (values.recordData.start_time && values.recordData.end_time) {
            const timeStart = new Date(values.recordData.start_time);
            const timeEnd = new Date(values.recordData.end_time);
            const timeSpent = timeEnd - timeStart;
            const m = Math.floor(new Date(timeSpent) / 1000 / 60);
            const s = Math.floor((new Date(timeSpent) / 1000) % 60);
            duration = `${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
        }
        return (
            <>
                <h3 className="total_score">{t("question.total_score")}</h3>
                <h1>
                    {selectedStudentId ? (
                        <>
                            <span>{`${values?.recordData.score || 0}`}</span>/
                            <span>{`${values?.recordData?.max_score || 0}`}</span>
                        </>
                    ) : (
                        "_ /_"
                    )}
                </h1>
                <p>{t("course.duration")}:</p>
                <h4>
                    <strong>{selectedStudentId ? duration : "_:_"}</strong>
                </h4>
                <p>{t("shared.status")}:</p>
                <h4>
                    <strong>{selectedStudentId ? status : "_"}</strong>
                </h4>
                <p>{t("statistical.submit_at")}:</p>
                <h4>
                    <strong>{submittedTime}</strong>
                </h4>
            </>
        );
    };

    return (
        <div className="report-student">
            <Form
                form={form}
                onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                    }
                }}
            >
                <Row gutter={[20, 20]}>
                    <Col lg={18} xs={24}>
                        <div className="report-student-chart">
                            <Spin spinning={isFetchingStudents || values.loading}>
                                {!isFetchingStudents &&
                                !values.loading &&
                                selectedStudentId &&
                                values.answerDataForStatistic.length > 0 ? (
                                    <ExamChartController
                                        lineColor="#0077FF"
                                        propsColor={{
                                            correct: "#75B136",
                                            correct_nearly: "#0077ff",
                                            incorrect: "#E56965",
                                            mark_by_hand: "#F8CC5A",
                                            skip: "#DDDDDD",
                                        }}
                                        propsLabel={{
                                            correct: t("assignment.correct"),
                                            correct_nearly: t("assignment.correct_nearly"),
                                            incorrect: t("assignment.incorrect"),
                                            mark_by_hand: t("assignment.mark_by_hand"),
                                            skip: t("assignment.skip"),
                                        }}
                                        yLineTitle={t("assignment.time_spent")}
                                        examData={answerStats}
                                        timeData={timeStats}
                                        yLineMaxValue={timeMaxValue}
                                        limitYColEachPage={10}
                                        yLabel={{
                                            visible: true,
                                            labelLeft: t("report.number_of_students"),
                                            labelRight: t("report.total_time_spent_in_seconds"),
                                        }}
                                    />
                                ) : null}
                            </Spin>
                            {!isFetchingStudents && !values.loading && !selectedStudentId ? (
                                <span className="chart-null">{t("report.please_select_student")}</span>
                            ) : null}
                            {!isFetchingStudents &&
                            !values.loading &&
                            selectedStudentId &&
                            values.answerDataForStatistic.length === 0 ? (
                                <span className="chart-null">{t("report.empty_data")}</span>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} xs={24}>
                        <div className="report-student-detail">
                            <Spin spinning={isFetchingStudents}>
                                <div className="detail-data">
                                    <Form.Item name="student_id">
                                        <Select
                                            className="app-select show-arrow"
                                            placeholder={t("report.select_student")}
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                            notFoundContent={undefined}
                                            onChange={(val) => handleSelectStudent(val)}
                                        >
                                            {optionListStudent.map((opt, i) => {
                                                if (opt.user) {
                                                    return (
                                                        <Select.Option key={`student${i}`} value={opt.user._id}>
                                                            {opt.user.name || `${t("shared.unknown")} (${i + 1})`}
                                                        </Select.Option>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </Form.Item>

                                    {renderRecordSummaryInfo()}
                                </div>
                            </Spin>
                        </div>
                    </Col>
                </Row>

                <div className="detail-student-answer">
                    <div className="filter-menu-wrapper">
                        {values.answerDataForStatistic.length ? (
                            <div className="filter">
                                <span>{t("statistical.filter_by_status")}</span>
                                <Select
                                    className="app-select"
                                    showArrow
                                    defaultValue={"all"}
                                    values={selectedFilterByType}
                                    onChange={(val) => {
                                        handleSelectFilterStudentsByType(val);
                                    }}
                                >
                                    <Select.Option value="all">{t("report.all")}</Select.Option>
                                    <Select.Option value="correct">{t("assignment.correct")}</Select.Option>
                                    <Select.Option value="correct_nearly">
                                        {t("assignment.correct_nearly")}
                                    </Select.Option>
                                    <Select.Option value="incorrect">{t("assignment.incorrect")}</Select.Option>
                                    <Select.Option value="mark_by_hand">{t("assignment.mark_by_hand")}</Select.Option>
                                    <Select.Option value="skip">{t("assignment.skip")}</Select.Option>
                                </Select>
                            </div>
                        ) : null}

                        {(selectedFilterByType === "all" ? values.recordData.history : filteredRecordList)?.length >
                            0 && (
                            <>
                                <QuestionNumberList
                                    type="horizontal"
                                    itemType="link"
                                    numberOfQuestions={
                                        (selectedFilterByType === "all"
                                            ? values.recordData.history
                                            : filteredRecordList
                                        )?.length || 0
                                    }
                                    itemConfig={{ itemHtmlElementId: "answer-record-" }}
                                ></QuestionNumberList>
                            </>
                        )}
                    </div>

                    <div className="answer-record-list-wrapper">
                        {(selectedFilterByType === "all" ? values.recordData.history : filteredRecordList)?.map(
                            (recordItem, i) => {
                                return (
                                    <div className="answer-record-wrapper" key={`record-${i}`}>
                                        <AnswerDetail
                                            htmlId={`answer-record-${i + 1}`}
                                            className="answer-record"
                                            tab="question"
                                            infoData={selectedStudentData}
                                            recordData={recordItem}
                                        />
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Student;
