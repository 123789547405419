import api from "..";

// Users:
export const fetchUsers = (fetchParams) => {
    const filters = [];
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`${fetchParams.slug}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${["name", "phone", "username", "gender", "active", "role", "class_user_status"]}`,
    };
    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

// Teachers:
export const fetchTeachers = (fetchParams = {}) => {
    const filters = ["role:teacher"];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.gender !== undefined && filters.push(`gender:${fetchParams.gender}`);
    fetchParams.active !== undefined && filters.push(`active:${fetchParams.active}`);
    fetchParams.user_organization !== undefined && filters.push(`user_organization:${fetchParams.user_organization}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${["role", "name", "phone", "gender", "active", "avatar"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

/**
 *
 * @returns The list student of teacher
 */
export const getListStudentOfTeacher = (fetchParams = {}) => {
    const filters = [];
    fetchParams.course_id && filters.push(`course_id:${fetchParams.course_id}`);
    const params = {
        ...(fetchParams.course_id && { course_id: fetchParams.course_id }),
    };

    return api
        .get(`/student`, params)
        .then(({ status, data, message }) => ({ status, data, message }))
        .catch(() => ({ status: false, message }));
};

//Students
export const fetchStudents = (fetchParams) => {
    const filters = [];
    fetchParams.role && filters.push(`role:${fetchParams.role}`);
    fetchParams.filters && filters.push(`${fetchParams.filters}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    fetchParams.course_id && filters.push(`course_id:${fetchParams.course_id}`);

    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${["name", "phone", "username", "gender", "active", "role", "personal_number"]}`,
        // Pagination:
        ...(fetchParams.perPage && {
            _perPage: fetchParams.perPage,
        }),
    };
    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            pagination: {
                showSizeChanger: false,
                current: meta.pagination.page,
                pageSize: meta.pagination.perPage,
                total: meta.pagination.total,
                lastPage: meta.pagination.lastPage,
            },
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchTeacherOfStudent = (fetchParams = {}) => {
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);

    return api
        .get("/my_teacher")
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchAllStudents = () => {
    const params = {
        _filter: "role:student",
        _user_fields: `${["name"]}`,
        _pagination: 0,
    };
    return api
        .get("/user", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const findUser = (id) => {
    const params = {
        _user_fields: `${["username", "name", "active"]}`,
    };
    return api
        .get(`/user/${id}`, params)
        .then(({ status, data }) => ({ status, data }))
        .catch(() => ({ status: false }));
};

export const updateUser = (id, data) => {
    return api
        .put(`/user/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const createUser = (data) => {
    return api
        .post("/user", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const deleteUser = (id) => {
    return api
        .delete(`/user/${id}`)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const changePasswordUser = (id, data) => {
    return api
        .post("/user/change_password", data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const skipHelpModal = () => {
    return api
        .put("/skip", { _user_fields: "is_new_user" })
        .then(({ status, data }) => {
            return { status, is_new_user: data?.is_new_user };
        })
        .catch(() => ({ status: false }));
};

/**
 * (VERIFIED) Get user list.
 * @param {Object} fetchParams Fetch params.
 * @returns Object.
 */
export const fetchUserList = (fetchParams = {}) => {
    // Filter:
    const filters = [];

    fetchParams.role && filters.push(`role:${fetchParams.role}`);
    fetchParams.active && filters.push(`active:${fetchParams.active}`);
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    // Fetch params:
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _user_fields: `${["role", "username", "active", "first_name", "last_name", "name"]} `,
        ...(fetchParams.noPagination && {
            _noPagination: 1,
        }),
    };
    // Fetch:
    return api
        .get("/user", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const resetStudentsToken = (student_ids, assignment_id) => {
    return api
        .post("/device/reset", { student_ids: student_ids, assignment_id })
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};

export const updateUserExam = (id, data) => {
    return api
        .put(`/update_info_student/${id}`, data)
        .then(({ status, message }) => ({ status, message }))
        .catch(() => ({ status: false }));
};
