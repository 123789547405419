import React from "react";
import { Col, Row } from "antd";
import { t } from "i18next";
import QuestionContent from "src/modules/components/QuestionContent";
import DefaultAvatar from "src/assets/images/no-avatar.png";
import "./AnswerDetail.scss";

const AnswerDetail = (props) => {
    const { htmlId = "", tab = "", infoData = { user: {} }, recordData = {} } = props;

    const getRecordInfoOfPassageQuestion = () => {
        let actualScore = 0;
        let maxScore = recordData.score || 0;
        let teacherComment = recordData.note || "";
        let duration = 0;
        recordData.paragraphs?.forEach((p, pIndex) => {
            p.question_details?.forEach((q, qIndex) => {
                actualScore += q.score_submit || q.score_teacher_submit || 0;
                duration += q.time || 0;
            });
        });
        return [actualScore, maxScore, teacherComment, duration];
    };

    const renderRecordInfoPanel = (currTab = "") => {
        let actualScore = 0;
        let maxScore = 0;
        let teacherComment = "";
        let duration = 0;
        if (recordData.type === "passage") {
            [actualScore, maxScore, teacherComment, duration] = getRecordInfoOfPassageQuestion();
        } else {
            actualScore = recordData.score_submit || recordData.score_teacher_submit || 0;
            maxScore = recordData.score || 0;
            teacherComment = recordData.note || "";
            duration = recordData.time || 0;
        }
        return (
            <div className="result-record-detail">
                <div className="result-record-info">
                    {currTab === "question" && (
                        <p className="avatar">
                            <span>
                                <img src={infoData.user?.avatar || DefaultAvatar} alt="" />
                            </span>
                            <strong>{infoData.user?.name || "Unknown"}</strong>
                        </p>
                    )}
                    <p>
                        <strong>{t("statistical.question_grade")}</strong>
                    </p>
                    <p className="score">
                        <span>{actualScore}</span>
                        <span>{maxScore}</span>
                    </p>
                    <p className="duration">
                        <strong>
                            {t("course.duration")}: <span>{duration}</span>s
                        </strong>
                    </p>
                    <p>
                        <strong>{t("exam_result.teacher_comment")}</strong>
                    </p>
                    <textarea className="teacher-comment" value={teacherComment} readOnly={true} />
                </div>
            </div>
        );
    };

    return (
        <div id={htmlId} className="answer-record-detail-wrapper">
            <Row gutter={[20, 20]}>
                <Col lg={18} xs={24}>
                    <div className="answer-record-detail">
                        <QuestionContent
                            type="review"
                            questionInfo={recordData}
                            showResults={true}
                            // Question number:
                            questionNumber={recordData.order || 0}
                            showQuestionNumber={true}
                            styleShowQuestion="default"
                            // Question display type:
                            displayType="question-panel"
                        />
                    </div>
                </Col>
                <Col lg={6} xs={24}>
                    {renderRecordInfoPanel(tab)}
                </Col>
            </Row>
        </div>
    );
};

export default AnswerDetail;
