import { Checkbox } from "antd";
import { t } from "i18next";
import React, { useContext } from "react";

import { ReactComponent as SvgTick } from "src/assets/images/app-icons/app-tick.svg";
import configs from "src/configs";
import { default as CustomButton } from "src/modules/components/Button";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";

import "./ExamNotes.scss";

const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;

function ExamNotes({ value, onChange, onSubmit }) {
    const proctoring = useContext(ProctoringContext);
    const examOptions = proctoring?.examCheckInInfo?.assignment?.options;

    const notes = {
        [ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_MICROPHONE]:
            examOptions[ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_MICROPHONE],
        [ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_CAMERA]:
            examOptions[ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_CAMERA],
        [ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_EXTEND_MONITOR]:
            examOptions[ANTI_CHEATING_KEYS.RESET_PERMISSION.RESET_EXTEND_MONITOR],

        [ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE]: examOptions[ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE]
            ? {
                  record_camera: examOptions.record_camera,
                  record_microphone: examOptions.record_microphone,
                  record_system_audio: examOptions.record_system_audio,
              }
            : undefined,
        [ANTI_CHEATING_KEYS.EXTEND_MONITOR]: examOptions[ANTI_CHEATING_KEYS.EXTEND_MONITOR],
        [ANTI_CHEATING_KEYS.DISABLE_COPY_PASTE]: examOptions[ANTI_CHEATING_KEYS.DISABLE_COPY_PASTE],
        [ANTI_CHEATING_KEYS.ALERT_USER_LEAVE_PAGE]: examOptions[ANTI_CHEATING_KEYS.ALERT_USER_LEAVE_PAGE],
        [ANTI_CHEATING_KEYS.DISABLE_SCREEN_SHRINKING]: examOptions[ANTI_CHEATING_KEYS.DISABLE_SCREEN_SHRINKING],
        [ANTI_CHEATING_KEYS.OPEN_TAB]: examOptions[ANTI_CHEATING_KEYS.OPEN_TAB],
        [ANTI_CHEATING_KEYS.EXIT_FULL_SCREEN]: examOptions[ANTI_CHEATING_KEYS.EXIT_FULL_SCREEN],
    };

    const handleChange = (e) => {
        if (onChange instanceof Function) {
            onChange(e);
        }
    };

    const handleSubmit = () => {
        if (onSubmit instanceof Function) {
            onSubmit();
        }
    };

    const renderNote = (noteKey, noteValue, noteIndex) => {
        return (
            <li key={noteIndex}>
                {t(`exam_proctoring.notes.${noteKey}`)}
                <ul>
                    {Object.keys(noteValue).map((opt, optIndex) => {
                        if (noteValue[opt] === true) {
                            return <li key={optIndex}>{t(`exam_proctoring.notes.${opt}`)}</li>;
                        }
                        if (typeof noteValue[opt] === "number") {
                            return (
                                <li key={noteIndex}>
                                    {t(`exam_proctoring.notes.${opt}`)}: {noteValue[opt]}{" "}
                                    {t(`exam_proctoring.notes.suffix_${opt}`)}
                                </li>
                            );
                        }
                        if (typeof noteValue[opt] === "object") {
                            return renderNote(noteValue[opt], optIndex);
                        }
                        return null;
                    })}
                </ul>
            </li>
        );
    };

    const renderNotes = () => {
        return (
            <ul>
                {Object.keys(notes).map((note, noteIndex) => {
                    if (notes[note] === true) {
                        return <li key={noteIndex}>{t(`exam_proctoring.notes.${note}`)}</li>;
                    }
                    if (typeof notes[note] === "number") {
                        return (
                            <li key={noteIndex}>
                                {t(`exam_proctoring.notes.${note}`)}: {notes[note]}{" "}
                                {t(`exam_proctoring.notes.suffix_${note}`)}
                            </li>
                        );
                    }
                    if (typeof notes[note] === "object") {
                        return renderNote(note, notes[note], noteIndex);
                    }
                    return null;
                })}
            </ul>
        );
    };

    return (
        <div className="exam-notes-wrapper">
            <div className="exam-notes">
                <div className="info-title">
                    <div className="title-head">{t("exam_proctoring.rules_reminder")}</div>
                    <div className="title-desc">{t("exam_proctoring.descr_rules_reminder")}</div>
                </div>
            </div>
            <div className="notes">
                {renderNotes()}
                <Checkbox checked={value} onChange={handleChange}>
                    {t("exam_proctoring.agree_rules")}
                </Checkbox>
            </div>
            <div className="notes-actions">
                <CustomButton
                    type="ghost"
                    icon={<SvgTick />}
                    title={t("shared.confirm")}
                    isDisabled={!value}
                    onClick={handleSubmit}
                ></CustomButton>
            </div>
        </div>
    );
}

export default ExamNotes;
