import { CaretDownOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Dropdown, Spin } from "antd";
import Table from "src/modules/components/Table";
import { t } from "i18next";
import "./ActivityTable.scss";
import { AssignmentsGradebookData } from "../../helpers";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ListItemMobile from "../ListItemMobile";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import ModalPersonalLearningProgress from "src/modules/containers/TeacherCourses/components/ModalPersonalLearningProgress";
import ModalGeneralLearningProgress from "src/modules/containers/TeacherCourses/components/ModalGeneralLearningProgress";
import ModalMarkAssignment from "src/modules/containers/TeacherCourses/components/ModalMarkAssignment";
import ModalAssignExam from "src/modules/containers/TeacherCourses/components/ModalAssignExam";
import Contact from "src/assets/images/contact.svg";
import Monitor from "src/assets/images/monitor.svg";
import Icon from "src/modules/components/Icon";
import { useRouting } from "src/utils/router";
import PopConfirm from "src/modules/components/Modal/PopConfirm";
import { getAllSearchParams, objectToSearchParamsString } from "src/utils/helpers";

const ActivityTable = ({ assignmentFetch, assignments, setFiter, filterParams, mediaWidth }) => {
    const [loading, data, pagination, fetchAssignment, reFetchAssignment] = assignmentFetch;
    const [levels, setLevels] = useState([]);
    const [assignmentMobile, setAssignmentMobile] = useValues({
        data: [],
        page: 0,
    });
    const [searchParams] = useSearchParams();
    const [values, setValues] = useValues({
        courseData: {},
        isConfirmModal: false,
        pendingDeleteItem: null,
        loading: false,
        filterStudentTable: {},
        filterExamTable: {},
        markingAssignmentId: "",
        studentNumber: undefined,
        viewProgressAssignment: {},
        viewProgressCourse: {},
        viewProgressLevel: {},
        viewProgressIsUnit: true,
        confirmRedo: false,
        pathRedo: "",
    });

    const [isAssignExamModal, setIsAssignExamModal] = useState(false);
    const [isMarkingModal, setIsMarkingModal] = useState(false);
    const [isPersonalLearningProgress, setIsPersonalLearningProgress] = useState(false);
    const [isGeneralLearningProgress, setIsGeneralLearningProgress] = useState(false);
    const [studentInfo, setStudentInfo] = useState({});

    const user = useSelector((state) => state.auth.user);
    const lang = useSelector((state) => state.general.lang);
    const navigate = useNavigate();
    const { generate } = useRouting();
    let newAssignments = [];
    newAssignments = AssignmentsGradebookData(assignments);

    const handleLoadMore = () => {
        fetchAssignment(
            {
                page: pagination.current + 1,
            },
            true
        );
    };

    const handleCloseModalGeneralLearningProgress = useCallback(() => {
        setIsGeneralLearningProgress(false);
    }, []);

    const handleCloseModalPersonalLearningProgress = useCallback(() => {
        setIsPersonalLearningProgress(false);
    }, []);

    useEffect(() => {
        if (assignments?.length) {
            if (pagination?.current === 1) {
                setAssignmentMobile({
                    data: AssignmentsGradebookData(assignments),
                    page: pagination.current,
                });
            } else if (pagination.current > assignmentMobile.page) {
                setAssignmentMobile({
                    ...assignmentMobile,
                    page: pagination.current,
                    data: [...assignmentMobile.data, ...AssignmentsGradebookData(assignments)],
                });
            }
        }
    }, [assignments, pagination.current]);

    useEffect(() => {
        // fetchLevels({ noPagination: true }).then((res) => {
        //     let levels = res?.data;
        //     if (levels) {
        //         let newLevel = levels.map((item) => {
        //             return { name: item.name, value: item.id };
        //         });
        //         levels = [{ name: "All", value: undefined }, ...newLevel];
        //         setLevels(levels);
        //     }
        // });
    }, []);

    const renderStudentActions = (record, text) => {
        const sParams = getAllSearchParams(searchParams);
        sParams.prev = "gradebook";

        let btn = {
            name: t("gradebook.view_score"),
            path: `/${lang}/score/${record.record_id}${objectToSearchParamsString(sParams)}`,
        };
        let btn2 = {
            name: t("gradebook.redo"),
            path: `/${lang}/test/${record.id}${objectToSearchParamsString(sParams)}`,
        };
        if (record.status === "doing") {
            btn = {
                name: t("gradebook.do_continue"),
                path: `/${lang}/test/${record.id}${objectToSearchParamsString(sParams)}`,
            };
        } else if (record.status === "expired") {
            btn = { name: t("gradebook.expired"), path: "#", status: false };
        } else if (record.status === "donot") {
            btn = { name: t("gradebook.do"), path: `/${lang}/test/${record.id}${objectToSearchParamsString(sParams)}` };
        } else if (record.status === "submited") {
            btn = {
                name: t("gradebook.review"),
                path: `/${lang}/score/${record.record_id}${objectToSearchParamsString(sParams)}`,
            };
        }

        return (
            <div className="table-action-button">
                <CustomButton
                    type="primary"
                    title={btn.name}
                    onClick={() => navigate(btn.path)}
                    disabled={btn?.status === false}
                    style={{ minWidth: "90px" }}
                ></CustomButton>
                {record.status === "marked" && (
                    <CustomButton
                        type="primary"
                        title={btn2.name}
                        onClick={() => {
                            // navigate(btn2.path)
                            setValues({ confirmRedo: true, pathRedo: btn2.path });
                        }}
                        disabled={btn?.status === false}
                        style={{ minWidth: "90px" }}
                    ></CustomButton>
                )}
            </div>
        );
    };

    const columns = [
        // {
        //     align: "left",
        //     dataIndex: "order",
        //     title: t("gradebook.order"),
        //     width: "auto",
        // },
        {
            align: "left",
            dataIndex: "name",
            title: t("gradebook.activity"),
            width: "auto",
        },
        {
            align: "left",
            dataIndex: "level",
            width: "auto",
            title: () => <span>{t("gradebook.level")}</span>,
            // title: () => (
            //     <Dropdown
            //         overlay={
            //             <TableColumnFilterMenu
            //                 selectedFilterKey={filterParams.level}
            //                 filterKeys={levels}
            //                 onChangeFilterKey={setFiter}
            //                 className="gradebook-table-col-filter"
            //             />
            //         }
            //     >
            //         <div className="owner-ship-wrapper">
            //             <span>{t("gradebook.level")}</span> <CaretDownOutlined />
            //         </div>
            //     </Dropdown>
            // ),
        },
        {
            title: t("gradebook.score"),
            align: "left",
            dataIndex: "score",
            width: "auto",
        },
        {
            title: "%",
            align: "left",
            dataIndex: "percent",
            width: "auto",
        },
        {
            title: t("gradebook.last_taken"),
            align: "left",
            dataIndex: "last_taken",
            width: "auto",
        },
        {
            title: t("gradebook.attempts"),
            align: "left",
            dataIndex: "attempts",
            width: "auto",
        },
        {
            title: t("gradebook.time_spend"),
            align: "left",
            dataIndex: "time_spend",
            width: "auto",
        },
    ];

    if (user?.role === "student") {
        columns.push({
            title: "",
            align: "center",
            dataIndex: "action",
            render: (text, record) => {
                return renderStudentActions(record, text);
            },
        });
    }

    const teacher_columns = [
        {
            align: "left",
            dataIndex: "name",
            title: t("gradebook.activity"),
            width: "auto",
        },
        {
            align: "left",
            dataIndex: "level",
            width: "auto",
            title: () => <span>{t("gradebook.level")}</span>,
            // title: () => (
            //     <Dropdown
            //         overlay={
            //             <TableColumnFilterMenu
            //                 selectedFilterKey={filterParams.level}
            //                 filterKeys={levels}
            //                 onChangeFilterKey={setFiter}
            //                 className="gradebook-table-col-filter"
            //             />
            //         }
            //     >
            //         <div className="owner-ship-wrapper">
            //             <span>{t("gradebook.level")}</span> <CaretDownOutlined />
            //         </div>
            //     </Dropdown>
            // ),
        },
        {
            title: t("gradebook.begin_date"),
            align: "left",
            dataIndex: "begin_date",
            width: "auto",
        },
        {
            title: t("gradebook.end_date"),
            align: "left",
            dataIndex: "end_date",
            width: "auto",
        },
        {
            title: t("gradebook.max_score"),
            align: "left",
            dataIndex: "max_score",
            width: "auto",
        },
        {
            title: t("gradebook.count_submit"),
            align: "left",
            dataIndex: "count_submit",
            width: "auto",
        },
        {
            title: t("gradebook.count_marked"),
            align: "left",
            dataIndex: "count_marked",
            width: "auto",
        },
    ];

    const examActions = [
        {
            title: t("course.mark_assignment"),
            onClick: (record) => {
                setValues({
                    markingAssignmentId: record.id,
                    viewProgressCourse: {
                        id: record?.course_id,
                        name: record?.course,
                    },
                });
                setIsMarkingModal(true);
                setValues({
                    viewProgressAssignment: {
                        id: record.id,
                        name: record.name,
                    },
                    viewProgressCourse: {
                        id: record?.course_id,
                        name: record?.course,
                    },
                    ...(record?.level
                        ? {
                              viewProgressLevel: {
                                  id: record?.level?.id,
                                  name: record?.level?.name,
                              },
                          }
                        : {}),
                    viewProgressIsUnit: false, // Current tab is assignments manager
                });
            },
        },
        {
            tooltip: t("course.learning_progress"),
            icon: Contact,
            onClick: (record) => {
                setValues({
                    viewProgressAssignment: {
                        id: record?.id,
                        name: record?.name,
                    },
                    viewProgressCourse: {
                        id: record?.course_id,
                        name: record?.course,
                    },
                    ...(record?.level
                        ? {
                              viewProgressLevel: {
                                  id: record?.level_id,
                                  name: record?.level,
                              },
                          }
                        : {}),
                    viewProgressIsUnit: false,
                });
                setIsGeneralLearningProgress(true);
            },
        },
        {
            tooltip: t("course.report"),
            icon: Monitor,
            onClick: (record) => {
                navigate(generate("assignment_report", { courseId: record?.course_id, assignmentId: record?.id }));
            },
        },
    ];

    return (
        <Spin spinning={loading}>
            <div className={`gradebook-table  ${user.role !== "student" ? "teacher" : ""}`}>
                {mediaWidth != 991 ? (
                    <Table
                        columns={user.role == "student" ? columns : teacher_columns}
                        dataSource={newAssignments}
                        pagination={{
                            showSizeChanger: false,
                            ...pagination,
                            onChange: (page) => {
                                fetchAssignment({ page: page });
                            },
                        }}
                        // loading={loading}
                        size="middle"
                        scroll={{ x: "auto" }}
                        actions={
                            ["teacher", "employee", "employee_crm", "admin"].includes(user.role) ? examActions : null
                        }
                    />
                ) : (
                    <ListItemMobile
                        assignments={assignmentMobile.data}
                        pagination={pagination}
                        onLoadMore={handleLoadMore}
                        actions={
                            ["teacher", "employee", "employee_crm", "admin"].includes(user.role) ? examActions : null
                        }
                        studentActions={renderStudentActions}
                    />
                )}

                <ModalAssignExam
                    isLoading={loading}
                    visible={isAssignExamModal}
                    onOk={() => {
                        reFetchAssignment();
                        setIsAssignExamModal(false);
                    }}
                    onCancel={() => setIsAssignExamModal(false)}
                    levelList={values.courseData?.level ? [values.courseData?.level] : []}
                    destroyOnClose
                    width="calc(100vw - 40px)"
                    style={{
                        maxWidth: "940px",
                        margin: "20px 0",
                    }}
                />
                {/* 
                <ModalDetailExam
                    onlyAssignment={true}
                    isLoading={loading}
                    visible={isAssignExamModal}
                    isOnMobile={values.isOnMobile}
                    isShowFormSelect={true}
                    // editingAssignment={editingAssignment}
                    examType={"exam"}
                    setValuesExam={setValues}
                    onOk={() => {
                        reFetchAssignment();
                        setIsAssignExamModal(false);
                        setValues({
                            isShowForm: false,
                        });
                    }}
                    onCancel={() => {
                        setIsAssignExamModal(false);
                        setValues({
                            isShowForm: false,
                            examType: "",
                        });
                    }}
                    destroyOnClose
                ></ModalDetailExam> */}

                {isMarkingModal && (
                    <ModalMarkAssignment
                        className="course-detail-modal detail-marking"
                        centered
                        title={t("course.mark_assignment")}
                        footer={null}
                        visible={isMarkingModal}
                        onCancel={() => setIsMarkingModal(false)}
                        destroyOnClose
                        width="calc(100vw - 40px)"
                        height="calc(100vh - 40px)"
                        style={{
                            maxWidth: "1400px",
                            maxHeight: "1400px",
                            margin: "20px 0",
                        }}
                        closeIcon={<Icon name="icon-cross-thin" />}
                        markingAssignmentId={values.markingAssignmentId}
                        setIsPersonalLearningProgress={setIsPersonalLearningProgress}
                        setStudentInfo={setStudentInfo}
                    />
                )}

                {isGeneralLearningProgress && (
                    <ModalGeneralLearningProgress
                        className="course-detail-modal detail-learning-progress"
                        centered
                        title={t("report.general_learning_progress")}
                        footer={null}
                        visible={isGeneralLearningProgress}
                        onCancel={handleCloseModalGeneralLearningProgress}
                        destroyOnClose
                        width="calc(100vw - 40px)"
                        height="calc(100vh - 40px)"
                        style={{
                            maxWidth: "1400px",
                            maxHeight: "1400px",
                            margin: "20px 0",
                        }}
                        closeIcon={<Icon name="icon-cross-thin" />}
                        viewProgressAssignment={values.viewProgressAssignment}
                        viewProgressLevel={values.viewProgressLevel}
                        viewProgressCourse={values.viewProgressCourse}
                        viewProgressIsUnit={true}
                    />
                )}

                {isPersonalLearningProgress && (
                    <ModalPersonalLearningProgress
                        title={t("report.personal_learning_progress")}
                        visible={isPersonalLearningProgress}
                        viewProgressCourse={values.viewProgressCourse}
                        viewProgressLevel={values.viewProgressLevel}
                        viewProgressIsUnit={true}
                        onCancel={handleCloseModalPersonalLearningProgress}
                        viewProgressStudent={studentInfo}
                    />
                )}
            </div>
            <PopConfirm
                visible={values.confirmRedo}
                title=""
                type={"action"}
                onCancel={() => setValues({ confirmRedo: false })}
                onOk={() => {
                    navigate(values.pathRedo);
                }}
            >
                <h1>{t("shared.msg_confirm_redo")}</h1>
            </PopConfirm>
        </Spin>
    );
};

const TableColumnFilterMenu = (props) => {
    const {
        cateName = "",
        cateNameNotFrom = -1,
        columnName = "",
        className = "",
        filterKeys = [],
        selectedFilterKey,
        onChangeFilterKey = () => {},
    } = props;
    const [level, setLevel] = useState();

    useEffect(() => {
        setLevel(selectedFilterKey);
    }, [selectedFilterKey]);
    return (
        <ul className={`table-column-filter-menu ${className}`}>
            {filterKeys.map((keyItem, keyIndex) => {
                return (
                    <li
                        key={`${columnName}-${keyIndex}`}
                        className={level === keyItem.value ? "active" : ""}
                        onClick={() => {
                            setLevel(keyItem.value);
                            onChangeFilterKey({ level: keyItem.value });
                        }}
                    >
                        {cateNameNotFrom === -1 || keyIndex < cateNameNotFrom ? (
                            <>{cateName ? t(`${cateName}.${keyItem.name}`) : t(`${keyItem.name}`)}</>
                        ) : (
                            <>{t(`${keyItem.name}`)}</>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default ActivityTable;
