import { FieldTimeOutlined, LoginOutlined, VideoCameraOutlined } from "@ant-design/icons";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import { notification } from "antd";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fetchRecordByAssignmentId } from "src/api/containers/record";
import configs from "src/configs";
import { useValues } from "src/hooks";
import { default as CustomButton } from "src/modules/components/Button";
import { ProctoringContext } from "src/modules/containers/ExamProctoring";
import { ScreenCamMicRecorderContext } from "src/modules/containers/ExamProctoring/components/ScreenCamMicRecorder";
import { openFullscreen } from "src/utils/AntiCheating";
import { useRouting } from "src/utils/router";

import ExamNotes from "./ExamNotes";
import "./StartTakingExam.scss";

const ANTI_CHEATING_KEYS = configs.ANTI_CHEATING_KEYS;

function StartTakingExam() {
    const proctoring = useContext(ProctoringContext);
    const recording = useContext(ScreenCamMicRecorderContext);
    const idAssignment = proctoring?.examCheckInInfo?.assignment?.id;
    const examOptions = proctoring?.examCheckInInfo?.assignment?.options;
    const examInfo = proctoring?.examCheckInInfo?.assignment;
    const setExamRecordData = proctoring?.setExamRecordData;

    const navigate = useNavigate();
    const { generate } = useRouting();

    const proctoringList = {
        [ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE]: examOptions[ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE],
        [ANTI_CHEATING_KEYS.DISABLE_SCREEN_SHRINKING]: examOptions[ANTI_CHEATING_KEYS.DISABLE_SCREEN_SHRINKING],
    };
    const hasScreenRecording = proctoringList[ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE];

    const [currentTab, setCurrentTab] = useState(""); // Values: "notes-form", "recording-form", "start-form".
    const [values, setValues] = useValues({
        redirecting: false,
        checkedRules: false,
        waitingProctoringItem: false, // Values: (proctoringList item), false.
    });
    const [startInfo, setStartInfo] = useValues({
        displayTimer: false,
        current_date: null,
        begin_date: null,
        end_date: null,
        homework_time_minute: null,
    });

    const handleDisplayTab = (direction = "next") => {
        const tabs = {};

        tabs["notes-form"] = !values.checkedRules;
        if (hasScreenRecording) {
            tabs["recording-form"] = !["waiting-for-recording", "recording"].includes(recording?.recorderStatus);
        }
        tabs["start-form"] = !tabs["notes-form"] && !tabs["recording-form"];

        if (currentTab) {
            switch (direction) {
                case "next": {
                    const tKeys = Object.keys(tabs);
                    for (let kIndex = 0; kIndex < tKeys.length; kIndex++) {
                        if (tKeys[kIndex] === currentTab) {
                            for (let tIndex = kIndex + 1; tIndex < tKeys.length; tIndex++) {
                                if (tabs[tKeys[tIndex]] === true) {
                                    setCurrentTab(tKeys[tIndex]);
                                }
                                break;
                            }
                            break;
                        }
                    }
                }
                case "prev": {
                    const tKeys = Object.keys(tabs);
                    for (let kIndex = tKeys.length - 1; kIndex >= 0; kIndex--) {
                        if (tKeys[kIndex] === currentTab) {
                            for (let tIndex = kIndex - 1; tIndex >= 0; tIndex--) {
                                if (tabs[tKeys[tIndex]] === true) {
                                    setCurrentTab(tKeys[tIndex]);
                                }
                                break;
                            }
                            break;
                        }
                    }
                }
                default:
                    break;
            }
        } else {
            const tKeys = Object.keys(tabs);
            for (let kIndex = 0; kIndex < tKeys.length; kIndex++) {
                if (tabs[tKeys[kIndex]] === true) {
                    setCurrentTab(tKeys[kIndex]);
                    break;
                }
            }
        }
    };

    const handleChangeAgreeRules = (e) => {
        setValues({
            checkedRules: e.target.checked,
        });
    };

    const handleRunProctoringItem = (proctoringItem, params) => {
        if (!proctoringList[proctoringItem]) {
            return;
        }
        switch (proctoringItem) {
            case ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE: {
                if (recording?.startRecording instanceof Function) {
                    recording?.startRecording(params?.step);
                }
                break;
            }
            case ANTI_CHEATING_KEYS.DISABLE_SCREEN_SHRINKING: {
                openFullscreen();
                break;
            }
            default:
                break;
        }
    };

    const handleConfirmExamNotes = () => {
        handleDisplayTab("next");
    };

    const handleConfirmRecording = () => {
        handleRunProctoringItem(ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE, { step: 1 });
    };

    const handleCompleteTimer = () => {
        setStartInfo({
            displayTimer: false,
            current_date: null,
        });
    };

    const handleSubmit = () => {
        if (!idAssignment || !(setExamRecordData instanceof Function)) {
            return;
        }

        setExamRecordData({
            loading: true,
            status: false,
            data: {},
        });

        setValues({ redirecting: true });

        fetchRecordByAssignmentId({ assignment_id: idAssignment })
            .then((res) => {
                if (res.status) {
                    setExamRecordData({
                        loading: false,
                        status: true,
                        data: res.data,
                    });

                    setValues({ redirecting: true });

                    handleRunProctoringItem(ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE, { step: 2 });
                    handleRunProctoringItem(ANTI_CHEATING_KEYS.DISABLE_SCREEN_SHRINKING);

                    setTimeout(() => {
                        navigate(generate("test", { id: idAssignment }));
                    }, 3000);
                } else {
                    notification.error({
                        message: res.message || t("message.sth_wrong"),
                    });

                    setExamRecordData({
                        loading: false,
                        status: false,
                        data: {},
                    });

                    setValues({ redirecting: false });
                }
            })
            .catch((err) => {
                const errMsg = err.toString();
                notification.error({
                    message: errMsg || t("message.sth_wrong"),
                });

                setExamRecordData({
                    loading: false,
                    status: false,
                    data: {},
                });

                setValues({ redirecting: false });
            });
    };

    useEffect(() => {
        handleDisplayTab("next");
    }, []);

    useEffect(() => {
        const _startInfo = {};

        _startInfo.begin_date = examInfo?.begin_date ? new Date(examInfo?.begin_date) : null;
        _startInfo.end_date = examInfo?.end_date ? new Date(examInfo?.end_date) : null;
        _startInfo.homework_time_minute = examInfo?.homework_time_minute;

        setStartInfo(_startInfo);
    }, [examInfo]);

    useEffect(() => {
        if (hasScreenRecording) {
            switch (recording?.recorderStatus) {
                case "stopped": {
                    setValues({
                        waitingProctoringItem: false,
                    });
                    handleDisplayTab("prev");
                    break;
                }
                case "granting-permissions": {
                    setValues({
                        waitingProctoringItem: ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE,
                    });
                    break;
                }
                case "waiting-for-recording":
                case "recording": {
                    setValues({
                        waitingProctoringItem: false,
                    });
                    handleDisplayTab("next");
                    break;
                }
                default:
                    break;
            }
        }
    }, [recording?.recorderStatus]);

    useEffect(() => {
        if (currentTab === "start-form") {
            const _startInfo = {};

            _startInfo.current_date = new Date();

            const timeToWait = _startInfo.current_date - startInfo.begin_date;
            if (!isNaN(timeToWait) && timeToWait > 0) {
                _startInfo.displayTimer = false;
            } else {
                _startInfo.displayTimer = true;
            }

            setStartInfo(_startInfo);
        } else {
            const _startInfo = {};

            _startInfo.current_date = null;
            _startInfo.displayTimer = false;

            setStartInfo(_startInfo);
        }
    }, [currentTab]);

    return (
        <div className="start-taking-exam">
            <div className="access-title">
                <div className="title-icon">
                    <FieldTimeOutlined />
                </div>
                <div className="title-content">
                    <div className="content-main">{t("exam_checkin.taking_exam")}</div>
                </div>
            </div>
            {currentTab === "notes-form" && (
                <div className="access-form">
                    <ExamNotes
                        value={values.checkedRules}
                        onChange={handleChangeAgreeRules}
                        onSubmit={handleConfirmExamNotes}
                    />
                </div>
            )}
            {currentTab === "recording-form" && (
                <div className="access-form">
                    <div className="record-info-wrapper">
                        <div className="record-info">
                            <div className="info-title">
                                <div>{t("exam_proctoring.ask_recording")}</div>
                                <div>{t("exam_proctoring.descr_ask_recording")}</div>
                            </div>
                        </div>
                        <CustomButton
                            type="ghost"
                            icon={<VideoCameraOutlined />}
                            title={t("shared.confirm")}
                            isLoading={values.waitingProctoringItem === ANTI_CHEATING_KEYS.RECORD_SCREEN_SHARE}
                            onClick={handleConfirmRecording}
                        ></CustomButton>
                    </div>
                </div>
            )}
            {currentTab === "start-form" && (
                <div className="access-form">
                    <div className="start-info-wrapper">
                        <div className="start-info">
                            <div className="info-title">
                                <div>
                                    {t("assignment.name")}: {examInfo?.name || "_"}
                                </div>
                                {startInfo.begin_date && (
                                    <div>
                                        {t("assignment.begin_date")}: {startInfo.begin_date.toLocaleString()}
                                    </div>
                                )}
                                {startInfo.end_date && (
                                    <div>
                                        {t("assignment.end_date")}: {startInfo.end_date.toLocaleString()}
                                    </div>
                                )}
                                {startInfo.homework_time_minute && (
                                    <div>
                                        {t("course.duration")}:{" "}
                                        {`${startInfo.homework_time_minute} ${t("course.input_time")}`}
                                    </div>
                                )}
                            </div>
                            {startInfo.displayTimer && (
                                <div className="info-timer">
                                    <div>{t("exam_proctoring.starttime_notreached")}</div>
                                    <div>
                                        <FlipClockCountdown to={startInfo.begin_date} onComplete={handleCompleteTimer}>
                                            <div>{t("exam_proctoring.starttime_reached")}</div>
                                        </FlipClockCountdown>
                                    </div>
                                </div>
                            )}
                        </div>
                        <CustomButton
                            htmlType="submit"
                            type="primary"
                            icon={<LoginOutlined />}
                            title={
                                values.redirecting
                                    ? `${t("shared.redirecting")}...`
                                    : t("exam_checkin.start_taking_exam")
                            }
                            isLoading={values.redirecting}
                            isDisabled={startInfo.displayTimer === true}
                            onClick={handleSubmit}
                        ></CustomButton>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StartTakingExam;
