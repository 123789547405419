import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Breadcrumb from "src/modules/components/Breadcrumb";
import OrganizationUserList from "./components/OrganizationUserList";
import "./Organization.scss";

function Organization(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();

    if (user && user.organization?.is_organization_owner === true) {
        return (
            <div className="organization-wrapper">
                <Breadcrumb
                    routes={[
                        {
                            name: t("header.organization"),
                        },
                    ]}
                />
                <OrganizationUserList />
            </div>
        );
    } else {
        return null;
    }
}

export default Organization;
