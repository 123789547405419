import api from "..";

export const fetchLevels = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _level_fields: `${["name"]}`,
        ...(fetchParams.noPagination === true && {
            _noPagination: 1,
        }),
    };
    return api
        .get("/level", params)
        .then(({ status, data, meta }) => ({
            status,
            data,
            ...(!fetchParams.noPagination && {
                pagination: {
                    showSizeChanger: false,
                    current: meta.pagination.page,
                    pageSize: meta.pagination.perPage,
                    total: meta.pagination.total,
                },
            }),
        }))
        .catch(() => ({ status: false, data: [] }));
};

export const fetchLevelsFilter = (fetchParams) => {
    const filters = [];
    fetchParams.slug && filters.push(`slug:${fetchParams.slug}`);
    const params = {
        _page: fetchParams.page,
        _filter: filters.join(";") || undefined,
        _level_fields: `${["name"]}`,
        noPagination: true,
    };
    return api
        .get("/level", params)
        .then(({ status, data }) => ({
            status,
            data,
        }))
        .catch(() => ({ status: false, data: [] }));
};
