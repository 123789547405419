import EmptyImg from "src/assets/images/Empty_1.png";
import "./CustomizeRenderEmpty.scss";
import { t } from "i18next";

function CustomizeRenderEmpty({ nodata_title }) {
    return (
        <div className="customize-render-empty">
            <img src={EmptyImg} />
            <span>{nodata_title ? nodata_title : t("message.empty_data")}</span>
        </div>
    );
}

export default CustomizeRenderEmpty;
