import React from "react";
import { t } from "i18next";
import "./CourseTopic.scss";
import { ReactComponent as SvgArrowRight } from "src/assets/images/app-icons/arrow-right.svg";
import { Tooltip } from "antd";

function CourseTopic({
    role,
    className = "",
    topicInfo = {},
    isActive = false,
    onClick = () => {},
    actions = null,
    showActions = true,
    showProgress = false,
    ...rest
}) {
    return (
        <div className={`course-topic-info-panel${className ? " " + className : ""}`} {...rest}>
            <div className="course-topic-info">
                <div className={"topic-quick-info" + (isActive ? " active" : "")}>
                    <Tooltip title={topicInfo?.name || "_"}>
                        <span className="topic-label">
                            <span className="label-content app-txt-h3">{topicInfo?.name || "_"}</span>
                        </span>
                    </Tooltip>
                    <span className="topic-title">
                        <span className="title-content app-txt-h3">{topicInfo?.title || "_"}</span>
                        {showActions === true ? (
                            <span className="title-actions">
                                {actions}

                                <span className="action-btn view-detail-btn" onClick={() => onClick(topicInfo)}>
                                    <span>
                                        {showProgress && (topicInfo?.progress || topicInfo?.progress === 0)
                                            ? topicInfo?.progress + "%"
                                            : t("assigmment_topic.view_detail")}
                                    </span>
                                    <SvgArrowRight />
                                </span>
                            </span>
                        ) : null}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CourseTopic;
