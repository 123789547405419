import { InputNumber, Tabs } from "antd";
import clsx from "clsx";
import { t } from "i18next";
import { getQuestionTypesExclude } from "src/api/helpers/question";
import QuestionContent from "../..";
import QuestionContentDetail from "../ContentDetail";
import "./PassageResult.scss";
const { TabPane } = Tabs;

const Passage = (props) => {
    const {
        questionInfo = null,
        formData = null,
        isShowScore = true,
        isMarking,
        handleMarkWritingInPassage = () => {},
    } = props;

    return (
        <div className="PassageResult_wrapper">
            <div className="PassageResult_inner">
                <Tabs type="card" className="PassageResult_tabs">
                    {questionInfo?.paragraphs?.map((paragraph, index) => {
                        return (
                            <TabPane tab={`${t("q.passage")} ${index + 1}`} key={index}>
                                <div className="PassageResult_itemQuestion">
                                    <div className="title">{paragraph.title}</div>
                                    <div className="passage_content">
                                        <QuestionContentDetail value={{ question: paragraph.content }} />
                                    </div>
                                    <Tabs className="PassageResult_answer">
                                        {paragraph?.question_details?.map((questionDetail, i) => {
                                            questionDetail.answered =
                                                questionInfo.answered?.[questionDetail.id || questionDetail._id];

                                            return (
                                                <TabPane tab={`${t("q.question")} ${i + 1}`} key={i}>
                                                    <QuestionContent questionInfo={questionDetail} />
                                                    {!isMarking && isShowScore && (
                                                        <div
                                                            className={clsx(
                                                                "student-view-mark passage",
                                                                (questionDetail?.score_teacher_submit ??
                                                                    questionDetail?.score_submit) &&
                                                                    "correct"
                                                            )}
                                                        >
                                                            <strong>{t("shared.score")}:</strong>
                                                            <span>
                                                                {(questionDetail?.score_teacher_submit ??
                                                                    questionDetail?.score_submit) ||
                                                                    0}
                                                                /{questionDetail?.score || 0}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {isMarking &&
                                                        getQuestionTypesExclude(["passage"]).includes(
                                                            questionDetail.type
                                                        ) && (
                                                            <div className="teacher-mark passage">
                                                                <strong>{t("shared.score")}:</strong>
                                                                <InputNumber
                                                                    className="app-input"
                                                                    defaultValue={
                                                                        questionDetail?.score_teacher_marking ??
                                                                        questionDetail?.score_teacher_submit ??
                                                                        questionDetail?.score_submit
                                                                    }
                                                                    onChange={(value) =>
                                                                        handleMarkWritingInPassage(
                                                                            value,
                                                                            questionDetail?.score_teacher_submit ??
                                                                                questionDetail?.score_submit,
                                                                            questionInfo.id,
                                                                            questionDetail.id
                                                                        )
                                                                    }
                                                                    min={0}
                                                                    max={questionDetail?.score}
                                                                />
                                                                <span>
                                                                    / {questionDetail?.score}
                                                                    {questionDetail?.score_teacher_submit !==
                                                                        questionDetail?.score_submit &&
                                                                        `- ${t("scored_manually")}`}
                                                                </span>
                                                            </div>
                                                        )}
                                                </TabPane>
                                            );
                                        })}
                                    </Tabs>
                                </div>
                            </TabPane>
                        );
                    })}
                </Tabs>
            </div>
        </div>
    );
};

export default Passage;
