import { useMemo } from "react";
import { t } from "i18next";
import HTMLDisplayer from "src/modules/components/Displayers/HTMLDisplayer";
import parser from "src/utils/parser";
import QuestionContentDetail from "../ContentDetail";
import "./Writing.scss";

const Writing = (props) => {
    const { question, answered } = props;

    const answeredInString = useMemo(() => {
        if (answered && Array.isArray(answered)) return parser.parseMathField(answered[0]);
        if (answered && !Array.isArray(answered)) return parser.parseMathField(answered);
    }, []);

    return (
        <div className="q-result q-writing">
            <div className="q-title">{t("q.question")}</div>

            <div className="q-content-title">
                <QuestionContentDetail isReadonly={true} value={{ question }} />
            </div>

            <div className="q-content-section">
                <div className="view-writing">
                    <HTMLDisplayer htmlString={answeredInString} />
                </div>
            </div>
        </div>
    );
};

export default Writing;
