import { Modal } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import { useFetch } from "src/hooks";
import { getGeneralProgress } from "src/api/containers/statistic";
import ArrowCompareIcon from "src/assets/images/arrow-compare-icon.svg";
import { t } from "i18next";
import { Alert, Dropdown, Spin, Table, Tag } from "antd";
import { getColorForPercentChart } from "src/utils/helpers";
import FilterMenu from "src/modules/containers/GeneralLearningProgress/components/FilterMenu";
import Button from "src/modules/components/Button";
import FilterIcon from "src/assets/images/filter-icon.svg";

const ModalGeneralLearningProgress = (props) => {
    const {
        title,
        visible,
        viewProgressAssignment,
        viewProgressCourse,
        viewProgressLevel,
        viewProgressIsUnit,
        onCancel,
        ...rest
    } = props;

    const [
        loadingGeneralProgress,
        dataGeneralProgress,
        paginationGeneralProgress,
        fetchGeneralProgress,
        refetchGeneralProgress,
    ] = useFetch({ page: 1, slug: "" }, getGeneralProgress);

    const [filterSettings, setFilterSettings] = useState({
        isFilterMenuVisible: false,
        filterParams: (() => {
            const result = [];
            Object.keys(viewProgressAssignment || {}).length &&
                result.push({
                    name: "assignment_id",
                    value: viewProgressAssignment.id,
                    labelName: t("report.assignment"),
                    labelValue: viewProgressAssignment.name,
                }),
                Object.keys(viewProgressCourse || {}).length &&
                    result.push({
                        name: "course_id",
                        value: viewProgressCourse.id,
                        labelName: t("report.course"),
                        labelValue: viewProgressCourse.name,
                    }),
                Object.keys(viewProgressLevel || {}).length &&
                    result.push({
                        name: "level_id",
                        value: viewProgressLevel.id,
                        labelName: t("report.level"),
                        labelValue: viewProgressLevel.name,
                    });

            // result.push({
            //     name: "is_unit",
            //     value: viewProgressIsUnit,
            //     labelName: t("report.type_of_excercise"),
            //     labelValue: viewProgressIsUnit ? t("report.unit") : t("report.assignment"),
            // });

            return result;
        })(),
    });

    const renderArrow = (value) => {
        switch (value) {
            case "bigger":
                return <img src={ArrowCompareIcon} className="bigger" />;
            case "smaller":
                return <img src={ArrowCompareIcon} className="smaller" />;
            case "equal":
                return <img src={ArrowCompareIcon} className="equal" />;
        }
    };

    const handleRemoveFilterParam = (e, paramName) => {
        e.preventDefault();
        const newFilterParams = filterSettings.filterParams.filter((item) => {
            return !(paramName === item.name);
        });
        setFilterSettings({ ...filterSettings, filterParams: newFilterParams });
    };

    const columns = useMemo(() => {
        const result = [
            {
                title: t("report.student_name"),
                dataIndex: "studentName",
                align: "center",
            },
            {
                title: t("report.course"),
                dataIndex: "course",
                align: "center",
            },
            {
                title: t("report.assignment"),
                dataIndex: "tests",
                align: "center",
                children: dataGeneralProgress?.assignment_names?.map((assignment) => {
                    return {
                        title: assignment?.name,
                        dataIndex: ["tests", `test${assignment?._id}`],
                        render: (value) => {
                            const color = getColorForPercentChart(value?.score);
                            return (
                                // <Tooltip placement="top" title={value?.name}>
                                <div className="reportTable_scoreBar" style={{ backgroundColor: color }}>
                                    {value?.score && value?.score + "%"}
                                </div>
                                // </Tooltip>
                            );
                        },
                    };
                }),
            },
        ];

        // Handle hidden socre column.
        if (dataGeneralProgress?.max_count_assignment >= 2) {
            result.splice(2, 0, {
                title: t("report.score"),
                dataIndex: "score",
                align: "center",
                render: (value) => {
                    const colorItem1 = getColorForPercentChart(value[0]);
                    const colorItem2 = getColorForPercentChart(value[1]);
                    let typeArrow = "";

                    if (value[0] >= 0 && value[1] >= 0) {
                    } else {
                    }

                    if (value[0] < value[1]) {
                        typeArrow = "bigger";
                    } else if (value[0] > value[1]) {
                        typeArrow = "smaller";
                    } else if (value[0] === value[1]) {
                        typeArrow = "equal";
                    }

                    const heightItem1 = (value[0] / (value[0] + value[1])) * 100;
                    const heightItem2 = (value[1] / (value[0] + value[1])) * 100;

                    return (
                        <div className="reportTable_scoreCompare">
                            <div className="reportTable_scoreBox">
                                <div className="reportTable_scoreItem">
                                    <div
                                        className="reportTable_scoreItemContent"
                                        style={{
                                            backgroundColor: colorItem1,
                                            height: `${heightItem1}%`,
                                        }}
                                    ></div>
                                </div>
                                {value[1] !== null && value[1] >= 0 && (
                                    <div className="reportTable_scoreItem">
                                        <div
                                            className="reportTable_scoreItemContent"
                                            style={{
                                                backgroundColor: colorItem2,
                                                height: `${heightItem2}%`,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            </div>

                            {value[1] !== null && value[1] >= 0 && (
                                <div className="reportTable_arrow">{renderArrow(typeArrow)}</div>
                            )}
                        </div>
                    );
                },
            });
        }

        return result;
    }, [dataGeneralProgress]);

    const data = useMemo(() => {
        const result = dataGeneralProgress?.student?.map((student) => {
            const tests = {};
            student?.records_without_history.forEach((record, index) => {
                tests[`test${record.assignment_id}`] = {
                    score: record.score_percent,
                    name: record.assignment.name,
                };
            });

            return {
                key: student?._id,
                studentName: student?.name,
                course: student?.course?.name,
                score: [
                    student?.evaluate_assignment?.score_percent_first,
                    student?.evaluate_assignment?.score_percent_end,
                ],
                tests,
            };
        });

        return result;
    }, [dataGeneralProgress]);

    useEffect(() => {
        fetchGeneralProgress(
            {
                course_id:
                    filterSettings.filterParams.find((filterParam) => filterParam.name === "course_id")?.value || "",
                assignment_id:
                    filterSettings.filterParams.find((filterParam) => filterParam.name === "assignment_id")?.value ||
                    "",
                is_unit: viewProgressIsUnit,
            },
            true
        );
    }, [filterSettings.filterParams]);

    return (
        <Modal
            title={title}
            visible={visible}
            footer={null}
            onCancel={onCancel}
            width={"80%"}
            bodyStyle={{ padding: "0px" }}
            destroyOnClose
            {...rest}
            className={`add-exam-question-modal modal-mark-assignment-table${
                props.className ? " " + props.className : ""
            }`}
        >
            <div className="generalLearningProgress_wrapper">
                <div className="generalLearningProgress_box m-0">
                    {loadingGeneralProgress && <Spin tip={`${t("shared.loading")}...`}></Spin>}
                    {!loadingGeneralProgress && (
                        <>
                            {dataGeneralProgress?.student ? (
                                <>
                                    {
                                        // count evaluate assignment
                                        dataGeneralProgress?.count_evaluate_assignment && (
                                            <>
                                                <div className="generalLearningProgress_dashboard">
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content info">
                                                            <div>
                                                                {dataGeneralProgress?.count_evaluate_assignment?.good}
                                                            </div>{" "}
                                                            <div className="arrowCompare">{renderArrow("bigger")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content warning">
                                                            <div>
                                                                {dataGeneralProgress?.count_evaluate_assignment?.equal}
                                                            </div>{" "}
                                                            <div className="arrowCompare ">{renderArrow("equal")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content danger">
                                                            <div>
                                                                {dataGeneralProgress?.count_evaluate_assignment?.bad}
                                                            </div>{" "}
                                                            <div className="arrowCompare">{renderArrow("smaller")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content disable">
                                                            <div>
                                                                {
                                                                    dataGeneralProgress?.count_evaluate_assignment
                                                                        ?.unknown
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        // count evaluate assignment
                                        dataGeneralProgress?.count_evaluate_one_assignment && (
                                            <>
                                                <div className="generalLearningProgress_dashboard">
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content very_good">
                                                            <div>
                                                                {
                                                                    dataGeneralProgress?.count_evaluate_one_assignment
                                                                        ?.very_good
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content good">
                                                            <div>
                                                                {
                                                                    dataGeneralProgress?.count_evaluate_one_assignment
                                                                        ?.good
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content medium">
                                                            <div>
                                                                {
                                                                    dataGeneralProgress?.count_evaluate_one_assignment
                                                                        ?.medium
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content bad">
                                                            <div>
                                                                {
                                                                    dataGeneralProgress?.count_evaluate_one_assignment
                                                                        ?.bad
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_item">
                                                        <div className="dashboard_content failed">
                                                            <div>
                                                                {
                                                                    dataGeneralProgress?.count_evaluate_one_assignment
                                                                        ?.failed
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    <div className="generalLearningProgress_table">
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            pagination={{
                                                pageSize: 9,
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <Alert
                                    description={t("report.message_no_one_has_done_the_homework")}
                                    type="warning"
                                    style={{ marginTop: 20 }}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(ModalGeneralLearningProgress);
