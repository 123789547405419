import React, { useEffect, useRef } from "react";
import { notification } from "antd";
import { t } from "i18next";
import { useValues } from "src/hooks";
import { CheckCircleOutlined, CloseCircleOutlined, ControlOutlined, ReloadOutlined } from "@ant-design/icons";
import { default as CustomButton } from "src/modules/components/Button";
import "./WebcamCheck.scss";

function WebcamCheck({
    disabled = false,
    value, // Values: undefined, "pending", true, false.
    onChange,
}) {
    const htmlElementVideoRef = useRef(null);

    const webcamMediaStreamRef = useRef(null);

    const [values, setValues] = useValues({
        loading: false,
        isFirstTime: true,
        // Connection:
        conn_status: false,
        conn_message: "",
        conn_description: "",
    });

    const webcamConnectionStatus = {
        fail: t("exam_checkin.webcam_info.fail"),
        success: t("exam_checkin.webcam_info.success"),
    };

    const webcamConnectionDescription = {
        connecting: t("exam_checkin.webcam_info.descr_connecting"),
        fail: t("exam_checkin.webcam_info.descr_fail"),
        "NotAllowedError: Permission dismissed": t("exam_checkin.webcam_info.descr_permission_dismissed"),
        "NotAllowedError: Permission denied": t("exam_checkin.webcam_info.descr_permission_denied"),
    };

    const permissionStatusRef = useRef(null);
    const checkingChangeRef = useRef(() => {});

    const handleChange = (newValue) => {
        if (onChange instanceof Function) {
            onChange(newValue);
        }
    };

    const handleCloseCurrentWebcamMediaStream = () => {
        if (webcamMediaStreamRef.current instanceof MediaStream) {
            webcamMediaStreamRef.current.getTracks().forEach((track) => {
                track.stop();
            });
        }
        webcamMediaStreamRef.current = null;
    };

    const handleStartCheckingChange = () => {
        if ("permissions" in navigator) {
            navigator.permissions
                .query({ name: "camera" })
                .then((permissionStatus) => {
                    permissionStatusRef.current = permissionStatus;
                    checkingChangeRef.current = () => {
                        switch (permissionStatus.state) {
                            case "granted": {
                                break;
                            }
                            case "denied": {
                                setValues({
                                    loading: false,
                                    conn_status: false,
                                    conn_message: webcamConnectionStatus.fail,
                                    conn_description: webcamConnectionDescription["NotAllowedError: Permission denied"],
                                });
                                handleChange(false);
                                break;
                            }
                            case "prompt": {
                                setValues({
                                    loading: false,
                                    conn_status: false,
                                    conn_message: webcamConnectionStatus.fail,
                                    conn_description:
                                        webcamConnectionDescription["NotAllowedError: Permission dismissed"],
                                });
                                handleChange(false);
                                break;
                            }
                            default:
                                break;
                        }
                    };

                    permissionStatus.addEventListener("change", checkingChangeRef.current);
                })
                .catch((err) => {
                    notification.error({
                        message: err.toString(),
                    });
                });
        }
    };

    const handleStopCheckingChange = () => {
        if (permissionStatusRef.current) {
            permissionStatusRef.current.removeEventListener("change", checkingChangeRef.current);
        }
    };

    const handleStartChecking = () => {
        const md = navigator.mediaDevices;

        if (!md?.getUserMedia) {
            setValues({
                conn_status: false,
                conn_message: webcamConnectionStatus.fail,
                conn_description: webcamConnectionDescription.fail,
            });
            handleChange(false);
            handleCloseCurrentWebcamMediaStream();
            handleStopCheckingChange();
        } else {
            setValues({
                loading: true,
                isFirstTime: false,
                conn_status: false,
                conn_message: "",
                conn_description: webcamConnectionDescription.connecting,
            });
            handleChange("pending");
            handleCloseCurrentWebcamMediaStream();
            handleStopCheckingChange();

            const constraints = {
                audio: false,
                video: true,
            };
            md.getUserMedia(constraints)
                .then((stream) => {
                    htmlElementVideoRef.current.srcObject = stream;
                    setValues({
                        loading: false,
                        conn_status: true,
                        conn_message: webcamConnectionStatus.success,
                        conn_description: "",
                    });
                    handleChange(true);
                    webcamMediaStreamRef.current = stream;

                    handleStartCheckingChange();
                })
                .catch((err) => {
                    const errMsg = err.toString();
                    setValues({
                        loading: false,
                        conn_status: false,
                        conn_message: webcamConnectionStatus.fail,
                        conn_description: webcamConnectionDescription[errMsg] || errMsg,
                    });
                    handleChange(false);
                });
        }
    };

    const handleClickCheck = () => {
        handleStartChecking();
    };

    useEffect(() => {
        return () => {
            handleCloseCurrentWebcamMediaStream();
            handleStopCheckingChange();
        };
    }, []);

    useEffect(() => {
        if (!disabled && !values.loading && values.isFirstTime) {
            handleStartChecking();
        }
    }, [disabled, values.loading, values.isFirstTime]);

    return (
        <div className="webcam-check">
            <div className="result-display">
                <video ref={htmlElementVideoRef} autoPlay className="webcam-check-displayer"></video>
            </div>
            <div className="check-text">
                <div className="check-title">{t("exam_checkin.webcam_check")}</div>
                <div className="check-result-info">
                    {values.conn_message && (
                        <div className="check-result-message">
                            <span>{t("exam_checkin.check_result")}: </span>
                            <span className={"result-tag" + (values.conn_status ? " success" : " danger")}>
                                {values.conn_status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}{" "}
                                {values.conn_message}
                            </span>
                        </div>
                    )}
                    {values.conn_description && (
                        <div className={"check-result-desctiption" + (values.conn_status ? " success" : " danger")}>
                            {values.conn_description}
                        </div>
                    )}
                </div>
            </div>
            <div className="check-actions">
                <CustomButton
                    htmlType="submit"
                    type="ghost"
                    icon={values.isFirstTime ? <ControlOutlined /> : <ReloadOutlined />}
                    title={
                        values.isFirstTime
                            ? t("shared.check")
                            : values.loading
                            ? `${t("shared.checking")}...`
                            : t("shared.try_again")
                    }
                    isLoading={values.loading}
                    isDisabled={disabled}
                    onClick={handleClickCheck}
                ></CustomButton>
            </div>
        </div>
    );
}

export default WebcamCheck;
