import clsx from "clsx";
import { useEffect, useMemo, useRef } from "react";
import Editor from "../Editor";
import configEditor from "src/utils/configEditor";
import "./InputEditor.scss";

const InputEditor = ({ suffix, value, onChange, placeholder, className, isSuffix = false, disabled, ...rest }) => {
    const editorRef = useRef(null);
    const editorConfigInput = useRef(configEditor.input());

    useEffect(() => {
        if (disabled) {
            document.activeElement.blur();
        }
    }, [disabled]);

    return (
        <div
            ref={editorRef}
            className={clsx("input-editor", className, disabled && "input-editor--disabled")}
            onMouseDown={(e) => {
                if (disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
        >
            <div className="input-editor__editor">
                <Editor
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    config={editorConfigInput.current}
                />
            </div>
            {isSuffix && <div className="input-editor__suffix">{suffix}</div>}
        </div>
    );
};

export default InputEditor;
