import MathQuill from "mathquill-node";
import parser from "src/utils/parser";
const MQ = MathQuill.getInterface(2);

/**
 * Working with input/textarea tag.
 * Placing the caret (insertion point).
 * @param {Object} htmlElem HTML element.
 * @param {Number} caretPos Position.
 */
export const focusAndSetCaretPosition = (htmlElem, caretPos) => {
    htmlElem.focus();
    if (htmlElem.setSelectionRange && isNaN(caretPos) === false) {
        htmlElem.setSelectionRange(caretPos, caretPos);
    }
};

/**
 * Validate, correct an HTML string and then insert it into a HTML element.
 * @param {Object} htmlElement HTML element.
 * @param {string} htmlString HTML string to be inserted into the HTML element.
 */
export const insertHtmlStringIntoHtmlElement = (htmlElement, htmlString) => {
    // 1. Validate and modify the htmlString:
    let htmlStringValid;
    // - Deal with LaTeX (<math-static>): turn htmlString to a valid one.
    htmlStringValid = parser.parseMathField(htmlString);

    // 2. Validate and modify the htmlElement:
    htmlElement.innerHTML = htmlStringValid;
    // - Deal with LaTeX (<math-static>):
    htmlElement.querySelectorAll("math-static").forEach((mathItem) => {
        MQ.StaticMath(mathItem);
    });
};

/**
 * Validate, correct an expression string and then insert it into a HTML element.
 * @param {Object} htmlElement HTML element.
 * @param {string} exprString Expression string (in LaTeX/Number) to be inserted into the HTML element.
 */
export const insertExprStringIntoHtmlElement = (htmlElement, exprString) => {
    // 1. Modify the exprString:
    // - Deal with LaTeX (LaTeX code or number): turn exprString to htmlString.
    const htmlString = parser.generateMathStatic(exprString);

    // 2. Insert htmlString into htmlElement.
    insertHtmlStringIntoHtmlElement(htmlElement, htmlString);
};
