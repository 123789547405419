import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import IconLanguageFlag, { languageList } from "src/modules/components/Language";
import { changeLang } from "src/reducers/general";
import "./LanguageMenu.scss";

function LanguageMenu(props) {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.general.lang);
    const dispatch = useDispatch();

    const handleChangeLanguage = (newLang) => {
        dispatch(changeLang(newLang));
    };

    return (
        <Menu
            className="langlist-menu"
            items={languageList.map((langItem, index) => {
                return {
                    key: `lang${index}`,
                    label: (
                        <span className="langlist-menu-item" onClick={() => handleChangeLanguage(langItem.key)}>
                            <span className="lang-icon">
                                <IconLanguageFlag name={langItem.key} />
                            </span>
                            <span className="lang-name">{t(`language.${langItem.key}`)}</span>
                        </span>
                    ),
                    disabled: lang === langItem.key ? true : false,
                    icon: null,
                };
            })}
        />
    );
}

export default LanguageMenu;
