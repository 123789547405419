import React, { useEffect } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, notification, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useValues } from "src/hooks";
import { fetchExams } from "src/api/containers/exam";
import {
    assignmentTypes,
    createAssignment,
    findAssignment,
    getRecommendedUnitAssignmentOrder,
    updateAssignment,
} from "src/api/containers/assignment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { default as CustomTooltip } from "src/modules/components/Tooltip";
import Icon from "src/modules/components/Icon";
import { default as CustomButton } from "src/modules/components/Button";
import "./ModalAssignExam.scss";
import { animationId } from "src/reducers/animation";
import ExamCard from "src/modules/components/ModalDetailExam/components/ExamCard";
import BaiKiemtraGif from "src/assets/images/gif/baitap.gif";
import BaiTapGif from "src/assets/images/gif/thuchanh.gif";
import { ReactComponent as SendIcon } from "src/assets/images/form-icon/sending.svg";

const ModalAssignExam = (props) => {
    const {
        type = "exam", // type: "exam" || "assignment".
        isLoading,
        levelId = "",
        levelList = [],
        extraSubmitData = {},
        assignmentId = undefined,
        isOnMobile,
        setValuesExam,
        isShowFormSelect,
        onlyAssignment = false,
        language_id,
        isFechedData,
        ...rest
    } = props;

    const typeList = {
        exam: "exam",
        assignment: "assignment",
    };

    const { t } = useTranslation();
    const [form] = useForm();
    const params = useParams();
    const user = useSelector((state) => state.auth.user);
    const [values, setValues] = useValues({
        loading: false,
        optionListExams: [],
        optionListExamsDefault: [],
        exam_question_id: "",
    });
    const dispatch = useDispatch();

    const handleCancel = () => {
        if (type === typeList.exam) {
            form.setFieldsValue({
                level_id: undefined,
                exam_question_id: undefined,
                name: undefined,
                begin_date: undefined,
                end_date: undefined,
                homework_time_minute: undefined,
            });
        } else {
            form.setFieldsValue({
                level_id: undefined,
                exam_question_id: undefined,
                name: undefined,
                description: undefined,
                type: undefined,
                order: undefined,
            });
        }
        if (props.onCancel instanceof Function) {
            props.onCancel();
        }
    };

    const handleSubmitForm = () => {
        const formData = form.getFieldsValue();
        if (!formData.name || !formData.exam_question_id) {
            return;
        }

        if (type === typeList.exam && formData.begin_date > formData.end_date) {
            notification.error({
                message: t("message.err_begin_date_is_greater"),
            });
            return;
        }

        formData.course_id = params.id;
        formData.begin_date = formData.begin_date ? formData.begin_date.format("YYYY-MM-DD HH:mm:ss") : undefined;
        formData.end_date = formData.end_date ? formData.end_date.format("YYYY-MM-DD HH:mm:ss") : undefined;

        if (extraSubmitData.courseId && extraSubmitData.unitId) {
            formData.course_id = extraSubmitData.courseId;
            formData.unit_id = extraSubmitData.unitId;
        }

        // Check is the action is creating or updating:
        if (!assignmentId) {
            createAssignment(formData).then(({ status, message, id }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", add: id }));

                    notification.success({
                        message: t("message.add_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.add_error"),
                    });
                }
            });
        } else {
            updateAssignment(assignmentId, formData).then(({ status, message }) => {
                if (status) {
                    dispatch(animationId({ name: "assignment", update: assignmentId }));
                    notification.success({
                        message: t("message.update_success"),
                    });
                    form.resetFields();
                    props.onOk();
                } else {
                    notification.error({
                        message: message || t("message.update_error"),
                    });
                }
            });
        }
    };

    const handleChangeLevel = (levelId) => {
        if (levelId) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                exam_question_id: undefined,
                name: undefined,
            });
            setValues({ ...values, loading: true });
            fetchExams({
                level_id: levelId,
                ...(user.role !== "employee" ? { user_id: user.id } : {}),
                ...{ is_show: true },
                noPagination: true,
                language_id: language_id,
            }).then((res) => {
                setValues({
                    ...values,
                    loading: false,
                    optionListExams: res.data || [],
                });
            });
        } else {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                exam_question_id: undefined,
                name: undefined,
            });
            setValues({
                ...values,
                optionListExams: values.optionListExamsDefault,
            });
        }
    };

    const handleChangeExam = (value) => {
        form.setFieldsValue({
            name: values.optionListExams.find((exam) => exam.id === value).name,
        });
        setValues({
            exam_question_id: value,
        });
    };

    useEffect(() => {
        /**
         * Get all exams created by the user if levelId is undefined.
         */
        if (!levelId && isFechedData?.current) {
            fetchExams({
                ...(user.role !== "employee" ? { user_id: user.id } : {}),
                ...{ is_show: true },
                noPagination: true,
                language_id: language_id,
            }).then((resExams) => {
                if (resExams.status) {
                    setValues({
                        optionListExams: resExams.data || [],
                        optionListExamsDefault: resExams.data || [],
                    });
                }
            });
        }
    }, [isFechedData?.current]);

    useEffect(() => {
        if (assignmentId && isFechedData?.current) {
            setValues({ ...values, loading: true });
            findAssignment(assignmentId, { relations: ["level"] }).then((res) => {
                if (res.status === true) {
                    const lvId = res.data.level?.id;
                    const asgmtId = res.data.exam?.id;
                    fetchExams({
                        level_id: lvId,
                        ...(user.role !== "employee" ? { user_id: user.id } : {}),
                        ...{ is_show: true },
                        noPagination: true,
                        language_id: language_id,
                    }).then((resExams) => {
                        setValues({
                            ...values,
                            loading: false,
                            optionListExams: resExams.data || [],
                        });
                        form.setFieldsValue({
                            level_id: lvId,
                            exam_question_id: asgmtId,
                            name: res.data.name || "",
                            description: res.data.description || "",
                            type: res.data.type || undefined,
                            begin_date: res.data.begin_date ? moment(res.data.begin_date) : "",
                            end_date: res.data.begin_date ? moment(res.data.end_date) : "",
                            homework_time_minute: res.data.homework_time_minute || 0,
                            ...(type === typeList.assignment ? { order: res.data.order || undefined } : {}),
                        });
                    });
                } else {
                    setValues({ ...values, loading: false });
                }
            });
        }
    }, [assignmentId, isFechedData?.current]);

    useEffect(() => {
        if (levelId && isFechedData?.current) {
            handleChangeLevel(levelId);
        }
    }, [levelId, isFechedData?.current]);

    useEffect(() => {
        if (props.visible === true && extraSubmitData && !assignmentId) {
            setValues({ ...values, loading: true });
            getRecommendedUnitAssignmentOrder(extraSubmitData.unitId).then((res) => {
                if (res.status) {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        order: res.data.recommend_order || undefined,
                    });
                } else {
                }
                setValues({ ...values, loading: false });
            });
        }
    }, [props.visible]);

    return (
        <Modal
            centered
            // title={type === typeList.exam ? t("course.add_exam") : t("assignment.add_assignment")}
            closeIcon={<Icon name="icon-cross-thin" />}
            footer={null}
            width="auto"
            onCancel={handleCancel}
            className={`app-modal type-basic flexible-height${
                props.className ? " " + props.className : ""
            } modal-detail-exam`}
            {...rest}
        >
            <Spin spinning={values.loading || isLoading}>
                <div className="modal-detail-exam__skeleton">
                    {(!isOnMobile || !isShowFormSelect) && (
                        <div className="modal-detail-exam__left">
                            <div className="modal-detail-exam__left-header">
                                {type === typeList.exam ? t("course.add_exam") : t("assignment.add_assignment")}
                            </div>
                            <div className="modal-detail-exam_list">
                                {!onlyAssignment && (
                                    <ExamCard
                                        active={type === "exam"}
                                        title={t("assignment.exam")}
                                        src={BaiKiemtraGif}
                                        onClick={() => {
                                            setValuesExam({ type: "exam", isShowForm: true });
                                            form.resetFields();
                                        }}
                                    />
                                )}

                                <ExamCard
                                    active={type === "assignment"}
                                    title={t("course.assignment")}
                                    src={BaiTapGif}
                                    onClick={() => {
                                        if (!onlyAssignment) {
                                            setValuesExam({ type: "assignment", isShowForm: true });
                                            form.resetFields();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {/* Right section */}
                    {(!isOnMobile || isShowFormSelect) && (
                        <div className="modal-detail-exam__right">
                            <div className="detail-exam-form">
                                <Form form={form}>
                                    {type === typeList.assignment && (
                                        <Form.Item
                                            label={t("unit.order")}
                                            name="order"
                                            rules={[{ required: true, message: t("unit.input_course_order") }]}
                                        >
                                            <InputNumber placeholder={t("unit.input_course_order")} />
                                        </Form.Item>
                                    )}

                                    {type === typeList.exam && (
                                        <Form.Item
                                            label={t("course.level")}
                                            name="level_id"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("course.select_level")}
                                                onChange={(value) => handleChangeLevel(value)}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {levelList
                                                    .filter((lvItem) => !!lvItem)
                                                    .map((item, index) => (
                                                        <Select.Option key={`level-${index}`} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    )}

                                    {type === typeList.assignment && (
                                        <Form.Item
                                            label={t("assignment.type")}
                                            name="type"
                                            rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Select
                                                className="app-select"
                                                placeholder={t("assignment.select_type")}
                                                onChange={(value) => {}}
                                                optionFilterProp="children"
                                                showSearch
                                                allowClear
                                            >
                                                {assignmentTypes.map((item, index) => (
                                                    <Select.Option key={`type-${index}`} value={item.value}>
                                                        {t(`assignment.type_${item.name}`)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        label={
                                            type === typeList.exam
                                                ? t("course.select_exam")
                                                : t("course.select_assignment")
                                        }
                                        name="exam_question_id"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Select
                                            className="app-select"
                                            placeholder={
                                                type === typeList.exam
                                                    ? t("course.select_exam")
                                                    : t("course.select_assignment")
                                            }
                                            onChange={(value) => handleChangeExam(value)}
                                            optionFilterProp="children"
                                            showSearch
                                            allowClear
                                        >
                                            {values.optionListExams.map((exam) => (
                                                <Select.Option key={exam.id} value={exam.id}>
                                                    {exam.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            type === typeList.exam ? (
                                                <>
                                                    <span>{t("course.exam_name")}</span>
                                                    <CustomTooltip
                                                        type="question-mark"
                                                        placement="right"
                                                        title={t("assignment.exam_name_description")}
                                                        style={{ order: "4", marginLeft: "4px" }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <span>{t("course.assignment_name")}</span>
                                                    <CustomTooltip
                                                        type="question-mark"
                                                        placement="right"
                                                        title={t("assignment.exam_name_description")}
                                                        style={{ order: "4", marginLeft: "4px" }}
                                                    />
                                                </>
                                            )
                                        }
                                        name="name"
                                        rules={[{ required: true, message: t("message.required") }]}
                                    >
                                        <Input
                                            className="app-input"
                                            placeholder={
                                                type === typeList.exam
                                                    ? t("course.input_exam_name")
                                                    : t("course.input_assignment_name")
                                            }
                                            maxLength={100}
                                        />
                                    </Form.Item>

                                    {type === typeList.assignment && (
                                        <Form.Item
                                            label={
                                                type === typeList.exam
                                                    ? t("course.exam_description")
                                                    : t("course.assignment_description")
                                            }
                                            name="description"
                                            // rules={[{ required: true, message: t("message.required") }]}
                                        >
                                            <Input.TextArea
                                                className="app-input"
                                                placeholder={
                                                    type === typeList.exam
                                                        ? t("course.input_exam_description")
                                                        : t("course.input_assignment_description")
                                                }
                                                showCount
                                                maxLength={200}
                                                autoSize={{ minRows: 1, maxRows: 2 }}
                                            />
                                        </Form.Item>
                                    )}

                                    {type === typeList.exam && (
                                        <>
                                            <Form.Item
                                                label={t("course.begin_date")}
                                                name="begin_date"
                                                // rules={[{ required: true, message: t("course.input_begin_date") }]}
                                            >
                                                <DatePicker
                                                    format="DD-MM-YYYY HH:mm"
                                                    showTime={{ format: "HH:mm" }}
                                                    placeholder={t("course.input_begin_date")}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={t("course.end_date")}
                                                name="end_date"
                                                // rules={[{ required: true, message: t("course.input_end_date") }]}
                                            >
                                                <DatePicker
                                                    format="DD-MM-YYYY HH:mm"
                                                    showTime={{ format: "HH:mm" }}
                                                    placeholder={t("course.input_end_date")}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={t("course.duration")}
                                                name="homework_time_minute"
                                                // rules={[{ required: true, message: t("message.required") }]}
                                            >
                                                <InputNumber
                                                    className="app-input"
                                                    placeholder={t("course.input_time")}
                                                />
                                            </Form.Item>
                                        </>
                                    )}

                                    <div className="btn-group">
                                        <CustomButton
                                            type="green"
                                            htmlType="submit"
                                            icon={<SendIcon />}
                                            title={t("action.save")}
                                            onClick={handleSubmitForm}
                                            style={{ width: "100%", maxWidth: "unset" }}
                                        ></CustomButton>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </div>
            </Spin>
        </Modal>
    );
};

export default ModalAssignExam;
